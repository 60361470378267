import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import styled from "styled-components";
import { Typography } from "@mui/material";
import UserRoleButton from "../../MyPortalComponents/SmallComponents/UserRoleButton";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllConnectionRequests,
  approveConnectionRequest,
  declineConnectionRequest,
  getAllFriends,
  removeConnection,
  removeDeleteMessage,
  updateStatusRead,
} from "../../../actions";
import { toast } from "react-toastify";
import FriendOptions from "./FriendOptions";
import ConfirmationModel from "../../ConfirmationModel";
import { notificationMessage } from "../../../utils/toastNotifications";
import socketIo from "socket.io-client";
import { userStatusSocket } from "../../../socket";
import { GlobalContext } from "../../../context/GlobalContext";
import Loader from "../../Elements/Loader";
import Error from "../../Elements/Error";
import MailIcon from "../../icons/mail.svg";
import CollaborationMessageModel from "./CollaborationMessageModel";
import { useHistory } from "react-router-dom";
import config from "../../../config";
import { useMediaQuery } from "react-responsive";

const ENDPOINT = config.sockets.socketBackendUrl;
let socket;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const FRIENDOPTIONS = [
  { icon: <i class="far fa-comment"></i>, text: "Message" },
  { icon: <i className="fas fa-user-times"></i>, text: "Delete Conversation" },
  { icon: <i class="fas fa-user-times"></i>, text: "Disconnect" },
];

const ChatList = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const { approveMessage, approveError, declineMessage, declineError } =
    useSelector((state) => state.connection);
  const {
    loading,
    requests,
    getError,
    friends,
    friendsLoading,
    removeMessage,
    removeError,
  } = useSelector((state) => state.connection);

  const user = useSelector((state) => state.user);
  const [showDisconnectConfirmation, setShowDisconnectConfirmation] =
    useState(false);
  const [selectedWarriorId, setSelectedWarriorId] = useState(null);
  const [selectedCollaboratorId, setSelectedCollaboratorId] = useState(null);
  const [selectedConnectionId, setSelectedConnectionId] = useState(null);
  const [showDeleteConversation, setShowDeleteConversation] = useState(null);

  const [activeUser, setActiveUser] = useState([]);
  const [requestCount, setRequestCount] = useState();
  let x;
  const chatCount = useSelector((state) => state.chatCount);
  const activeUserData = useSelector((state) => state.activeUser);
  const {
    setShowChatDetails,
    showChatList,
    setShowChatList,
    setChatTab,
    chatTab,
  } = useContext(GlobalContext);
  const [showCollaborationMessageModel, setShowCollaborationMessageModel] =
    useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  useEffect(() => {
    setActiveUser(activeUserData);

    if (showChatList && chatTab === 0) {
      dispatch(getAllFriends());
    }

    if (showChatList && chatTab === 1) {
      dispatch(getAllConnectionRequests());
    }

    if (approveMessage) {
      dispatch(getAllFriends());
      setChatTab(0);
    }

    if (declineMessage) {
      dispatch(getAllConnectionRequests());
    }

    if (removeMessage || removeError) {
      dispatch(getAllFriends());
    }
  }, [
    showChatList,
    chatTab,
    approveMessage,
    declineMessage,
    removeMessage,
    removeError,
  ]);

  useEffect(async () => {
    if (approveMessage) {
      toast.success(approveMessage);
    }

    if (approveError) {
      toast.error(approveError);
    }

    if (declineMessage) {
      toast.success(declineMessage);
    }

    if (declineError) {
      toast.error(declineError);
    }
  }, [approveMessage, approveError, declineMessage, declineError]);

  const approveConnectRequestHandler = (id) => {
    dispatch(
      approveConnectionRequest({
        collaboratorId: id,
      })
    );
  };

  const declineConnectionRequestHandler = (id) => {
    dispatch(
      declineConnectionRequest({
        collaboratorId: id,
      })
    );
  };

  const removeConnectionHandler = (warriorId, collaboratorId) => {
    dispatch(
      removeConnection({
        warriorId,
        collaboratorId,
        connectionId: selectedConnectionId,
      })
    );

    toast.error(notificationMessage.connectionRemoved);
    setSelectedWarriorId(null);
    setSelectedConnectionId(null);
    setSelectedWarriorId(null);
  };

  const removeDeleteConversation = (id) => {
    dispatch(removeDeleteMessage(id, user.id));

    toast.success(notificationMessage.conversationRemoved);
    setSelectedConnectionId(null);
  };

  const getUserRole = (userrole) => {
    switch (userrole) {
      case 0:
        return "Freeloader";
      case 1:
        return "CO-OP";
      case 2:
        return "Warrior";
      default:
        return "Freeloader";
    }
  };

  const isBadgeOrAvatar = (user) => {
    const image = !user.avatar
      ? user.userSettings.VisitorBadge.imgPath
      : user.avatar;
    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <div className="imageBadge">
          <img className="badge" src={image} alt="" />
        </div>
      );
    } else {
      return (
        <img
          className="imageAvatar"
          src={config.aws.CLOUDFRONT_URL + image}
          alt=""
        />
      );
    }
  };

  useEffect(() => {
    socket = socketIo(ENDPOINT, { transports: ["websocket"] });
    socket.on("userStatus", (data) => {
      setActiveUser(data);
    });

    socket.on("pendingRequest", (data) => {
      setRequestCount(data);
    });
  }, [showChatList]);

  const handleChatOpen = async (connection, friends) => {
    const senderId = JSON.parse(localStorage.getItem("user")).id;
    const receiverId =
      JSON.parse(localStorage.getItem("user")).id === connection.collaborator.id
        ? connection.warrior.id
        : connection.collaborator.id;

    const username =
      user.id === connection.collaborator.id
        ? connection.warrior.username
        : connection.collaborator.username;
    localStorage.setItem("username", username);
    localStorage.setItem("senderId", senderId);
    localStorage.setItem("receiverId", receiverId);
    localStorage.setItem("collaboratorId", connection.collaborator.id);
    localStorage.setItem("connectionId", connection.id);
    await dispatch(
      updateStatusRead(receiverId, senderId, user.id, connection.id)
    );
    socket.emit("lastReadMessage", friends);

    setShowChatDetails(true);
  };

  const handleChatOpenMobile = async (connection, friends) => {
    const senderId = JSON.parse(localStorage.getItem("user")).id;
    const receiverId =
      JSON.parse(localStorage.getItem("user")).id === connection.collaborator.id
        ? connection.warrior.id
        : connection.collaborator.id;

    const username =
      user.id === connection.collaborator.id
        ? connection.warrior.username
        : connection.collaborator.username;
    localStorage.setItem("username", username);
    localStorage.setItem("senderId", senderId);
    localStorage.setItem("receiverId", receiverId);
    localStorage.setItem("collaboratorId", connection.collaborator.id);
    localStorage.setItem("connectionId", connection.id);
    await dispatch(
      updateStatusRead(receiverId, senderId, user.id, connection.id)
    );
    socket.emit("lastReadMessage", friends);

    // redirect logic
    history.push(`/chat/${receiverId}`);
    setShowChatList(false);
  };

  const visitProfileHandler = (username) => {
    setShowChatList(false);
    history.push(`/channel/${username}`);
    return;
  };

  return (
    <>
      <ChatMessangerStyled>
        <div className="tab-size">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="tabs">
                <div
                  onClick={() => {
                    setChatTab(0);
                  }}
                  className={`tabs__item ${chatTab === 0 && "activeTab"}`}
                >
                  Connections
                </div>
                <div
                  onClick={() => {
                    setChatTab(1);
                  }}
                  className={`tabs__item alignNumber ${
                    chatTab === 1 && "activeTab"
                  }`}
                >
                  Requests{" "}
                  {requestCount ? (
                    requestCount.pendingRequest.filter(
                      (data) => user.id === data.WarriorUserId
                    ).length === 0 ? (
                      ""
                    ) : (
                      <div className="requestNumber">
                        <p className="numberText">
                          {
                            requestCount.pendingRequest.filter(
                              (data) => user.id === data.WarriorUserId
                            ).length
                          }
                        </p>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Box>
            <TabPanel value={chatTab} index={0}>
              <FriendsList>
                {friendsLoading && <Loader text="Please wait..." />}

                {!friendsLoading && getError && (
                  <Error text="No friends found!" />
                )}

                {!friendsLoading &&
                  friends &&
                  friends.length > 0 &&
                  friends.map((item, i) => (
                    <>
                      <div className="item">
                        <div
                          className="left"
                          onClick={() => {
                            if (isMobile) {
                              handleChatOpenMobile(item, friends);
                              return;
                            }

                            handleChatOpen(item, friends);
                          }}
                        >
                          {activeUser.length !== 0 ? (
                            activeUser.map((userData) =>
                              userData.isActive &&
                              user.id !== userData.userId ? (
                                item.warrior.id === userData.userId ||
                                item.collaborator.id === userData.userId ? (
                                  <div className="avatarV2">
                                    {user.id === item.warrior.id && (
                                      <>{isBadgeOrAvatar(item.collaborator)}</>
                                    )}

                                    {user.id === item.collaborator.id && (
                                      <>{isBadgeOrAvatar(item.warrior)}</>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )
                              ) : user.id !== userData.userId ? (
                                item.warrior.id === userData.userId ||
                                item.collaborator.id === userData.userId ? (
                                  <div className="noavatarv2">
                                    {user.id === item.warrior.id && (
                                      <>{isBadgeOrAvatar(item.collaborator)}</>
                                    )}

                                    {user.id === item.collaborator.id && (
                                      <>{isBadgeOrAvatar(item.warrior)}</>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )
                            )
                          ) : (
                            <div className="noavatarv2">
                              {user.id === item.warrior.id && (
                                <>{isBadgeOrAvatar(item.collaborator)}</>
                              )}

                              {user.id === item.collaborator.id && (
                                <>{isBadgeOrAvatar(item.warrior)}</>
                              )}
                            </div>
                          )}

                          <div className="userMeta">
                            <p>
                              {user.id === item.warrior.id && (
                                <>{item.collaborator.username}</>
                              )}

                              {user.id === item.collaborator.id && (
                                <>{item.warrior.username}</>
                              )}
                            </p>
                            {activeUser.length !== 0 ? (
                              activeUser.map((userData) => (
                                <span>
                                  {userData.isActive &&
                                  user.id !== userData.userId
                                    ? item.warrior.id === userData.userId ||
                                      item.collaborator.id === userData.userId
                                      ? `Online`
                                      : ``
                                    : user.id !== userData.userId
                                    ? userData.userId === item.warrior.id ||
                                      userData.userId === item.collaborator.id
                                      ? `Offline`
                                      : ""
                                    : ``}
                                </span>
                              ))
                            ) : (
                              <span>Offline</span>
                            )}
                          </div>
                        </div>

                        <div className="right">
                          <div className="lastMessage">
                            {chatCount ? (
                              user.id === item.warrior.id ? (
                                chatCount.filter(
                                  (chat) =>
                                    chat.senderId === item.collaborator.id
                                ).length === 0 ? (
                                  ""
                                ) : (
                                  <div className="num">
                                    {
                                      chatCount.filter(
                                        (chat) =>
                                          chat.senderId ===
                                            item.collaborator.id &&
                                          chat.isRead === false
                                      ).length
                                    }
                                  </div>
                                )
                              ) : chatCount.filter(
                                  (chat) =>
                                    chat.receiverId === item.collaborator.id &&
                                    chat.isRead === false
                                ).length === 0 ? (
                                ""
                              ) : chatCount.filter(
                                  (chat) =>
                                    chat.receiverId === item.collaborator.id &&
                                    chat.senderId === item.warrior.id &&
                                    chat.isRead === false
                                ).length === 0 ? (
                                ""
                              ) : (
                                <div className="num">
                                  {" "}
                                  {
                                    chatCount.filter(
                                      (chat) =>
                                        chat.receiverId ===
                                          item.collaborator.id &&
                                        chat.senderId === item.warrior.id &&
                                        chat.isRead === false
                                    ).length
                                  }
                                </div>
                              )
                            ) : (
                              ""
                            )}
                          </div>

                          <UserRoleButton
                            className="userRole"
                            type={
                              user.id === item.warrior.id
                                ? item.collaborator.userrole
                                : user.id === item.collaborator.id
                                ? item.warrior.userrole
                                : null
                            }
                          >
                            {user.id === item.warrior.id && (
                              <div>
                                {getUserRole(item.collaborator.userrole)}
                              </div>
                            )}

                            {user.id === item.collaborator.id && (
                              <div>{getUserRole(item.warrior.userrole)}</div>
                            )}
                          </UserRoleButton>
                          <FriendOptions
                            className="options"
                            options={FRIENDOPTIONS}
                            setShowDisconnectConfirmation={
                              setShowDisconnectConfirmation
                            }
                            setSelectedWarriorId={setSelectedWarriorId}
                            setSelectedCollaboratorId={
                              setSelectedCollaboratorId
                            }
                            setShowDeleteConversation={
                              setShowDeleteConversation
                            }
                            setSelectedConnectionId={setSelectedConnectionId}
                            warriorId={item.warrior.id}
                            collaboratorId={item.collaborator.id}
                            username={
                              user.id === item.collaborator.id
                                ? item.warrior.username
                                : item.collaborator.username
                            }
                            connectionId={item.id}
                          />
                        </div>
                      </div>
                    </>
                  ))}
              </FriendsList>
            </TabPanel>
            <TabPanel value={chatTab} index={1}>
              <FriendsList>
                {loading && <Loader text="Please wait..." />}

                {!loading && getError && (
                  <Error text="No connection requests found!" />
                )}

                {!loading &&
                  requests &&
                  requests.length > 0 &&
                  requests.map((item, i) => (
                    <div className="item">
                      <div className="left">
                        <div className="icons">
                          <div
                            onClick={() =>
                              declineConnectionRequestHandler(
                                item.collaborator.id
                              )
                            }
                            className="removeIcon"
                          >
                            <i class="fas fa-minus"></i>
                          </div>

                          <div
                            onClick={() =>
                              approveConnectRequestHandler(item.collaborator.id)
                            }
                            className="addIcon"
                          >
                            <i class="fas fa-plus"></i>
                          </div>
                        </div>

                        <div
                          onClick={() =>
                            visitProfileHandler(item?.collaborator?.username)
                          }
                          className="avatar"
                        >
                          {isBadgeOrAvatar(item.collaborator)}
                        </div>

                        <div
                          onClick={() =>
                            visitProfileHandler(item?.collaborator?.username)
                          }
                          className="username"
                        >
                          {item.collaborator.username}
                        </div>
                      </div>

                      <div className="right">
                        <div className="collaborationMessageBox">
                          <div className="num">+1</div>
                          <img
                            onClick={() => {
                              setSelectedRequest(item);
                              setShowCollaborationMessageModel(true);
                            }}
                            className="collaborationMessageIcon"
                            src={MailIcon}
                          />
                        </div>

                        <UserRoleButton type={item.collaborator.userrole}>
                          {getUserRole(item.collaborator.userrole)}
                        </UserRoleButton>
                      </div>
                    </div>
                  ))}
              </FriendsList>
            </TabPanel>
          </Box>
        </div>
      </ChatMessangerStyled>

      {showDeleteConversation && selectedConnectionId && (
        <>
          <ConfirmationModel
            open={showDeleteConversation}
            closeHandler={() => setShowDeleteConversation(false)}
            deleteHandler={() => removeDeleteConversation(selectedConnectionId)}
            title={`Are you sure you want to delete conversation`}
          ></ConfirmationModel>
        </>
      )}

      {showDisconnectConfirmation &&
        selectedWarriorId &&
        selectedCollaboratorId &&
        selectedConnectionId && (
          <ConfirmationModel
            open={showDisconnectConfirmation}
            closeHandler={() => setShowDisconnectConfirmation(false)}
            deleteHandler={() =>
              removeConnectionHandler(selectedWarriorId, selectedCollaboratorId)
            }
            title={`Are you sure you want to disconnect with user. This cannot be undone and a new connection request will need to be sent and approved before you can reconnect again.`}
          />
        )}

      {showCollaborationMessageModel && selectedRequest && (
        <CollaborationMessageModel
          close={() => {
            setSelectedRequest(null);
            setShowCollaborationMessageModel(false);
          }}
          selectedRequest={selectedRequest}
          approveRequest={() => {
            approveConnectRequestHandler(selectedRequest.collaborator.id);
            setSelectedRequest(null);
            setShowCollaborationMessageModel(false);
            setChatTab(0);
          }}
        />
      )}
    </>
  );
};

export default ChatList;

const ChatMessangerStyled = styled.div`
  .tabs {
    display: flex;
    align-items: center;
    padding: 1.5rem 0;
    border-bottom: 1px solid rgba(242, 242, 247, 0.2);

    .alignNumber {
      display: flex;
      align-items: center;
    }
    .numberText {
      background: -webkit-${(props) => props.theme.primaryGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      // -webkit-text-fill-color: transparent;
    }
    .requestNumber {
      margin-left: 5px;
      font-size: 15px;
      font-weight: 500;
      line-height: 1;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${(props) => props.theme.primaryGradient};
    }

    &__item {
      padding: 0.5rem 2rem;
      text-transform: capitalize;
      font-size: 1rem;
      position: relative;
      cursor: pointer;

      &:before {
        position: absolute;
        content: "";
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
        height: 3px;
        background: #fff;
      }
    }

    .activeTab {
      background: -webkit-${(props) => props.theme.primaryGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      &:before {
        background: ${(props) => props.theme.primaryGradient};
      }
    }
  }

  @media screen and (max-width: 480px) {
    .tab-bar-section {
      margin: 0 1%;
    }
    .tab-size {
      padding: 5px 2%;
    }
  }
`;

const FriendsList = styled.div`
  margin: 1rem 0 2rem 0;
  font-family: ${(props) => props.theme.montserrat};

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .left {
      display: flex;
      align-items: center;
      width: 90%;

      .icons {
        display: flex;
        align-items: center;

        .addIcon,
        .removeIcon {
          height: 2rem;
          width: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 2px solid #fff;
          margin-right: 0.5rem;
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover {
            background-color: #fff;

            i {
              color: #000;
            }
          }

          i {
            font-size: 0.8rem;
          }
        }
      }

      .userMeta {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span {
          font-size: 0.85rem;
          font-weight: 300;
          color: #999;
        }
      }

      .avatarV2 {
        border: 2px solid #2fdd92;
      }

      .avatar,
      .avatarV2,
      .noavatarv2 {
        cursor: pointer;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        margin-right: 1.5rem;
        position: relative;

        .imageAvatar {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }

        .imageBadge {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .badge {
            height: 60px;
            width: auto;
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .options {
        margin-left: 0.5rem;
        cursor: pointer;
      }

      .collaborationMessageIcon {
        height: 1.2rem;
        width: auto;
        margin-right: 1.5rem;
        cursor: pointer;
      }

      .collaborationMessageBox {
        position: relative;

        .num {
          position: absolute;
          top: -0.5rem;
          right: 0.8rem;
          font-size: 0.62rem;
          font-weight: 300;
          line-height: 1;
          height: 1.05rem;
          width: 1.05rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: ${(props) => props.theme.primaryGradient};

          span {
            padding-top: 2px;
          }
        }
      }

      .userRole {
        margin-right: 0.5rem;
      }

      .lastMessage {
        margin-right: 2rem;
        display: flex;
        align-items: center;

        .time {
          font-size: 0.95rem;
          font-weight: 300;
          color: #cfcfcf;
          margin-right: 0.5rem;
        }

        .num {
          height: 1.4rem;
          width: 1.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: 50%;
          background: ${(props) => props.theme.primaryGradient};
          font-size: 0.9rem;
          font-weight: 600;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .item {
      .left {
        .icons {
          .addIcon,
          .removeIcon {
            height: 1.5rem;
            width: 1.5rem;

            i {
              font-size: 0.7rem;
            }
          }
        }

        .userMeta {
          span {
            font-size: 0.75rem;
          }
          p {
            font-size: 0.9rem;
          }
        }

        .avatar,
        .avatarV2,
        .noavatarv2 {
          height: 50px;
          width: 50px;
          margin-right: 0.5rem;

          .imageBadge {
            .badge {
              height: 50px;
            }
          }
        }
      }

      .right {
        .lastMessage {
          margin-right: 1rem;

          .time {
            font-size: 0.8rem;
            margin-right: 0.25rem;
          }

          .num {
            height: 1.2rem;
            width: 1.2rem;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
`;
