import React from "react";
import styled from "styled-components";
import SkeletonElement from "../SkeletonElement";
import SkeletonShimmer from "../SkeletonShimmer";
import { Row, Col } from "react-grid-system";
import { useMediaQuery } from "react-responsive";

const SeriesWatch = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  return (
    <SeriesWatchSkeletonStyled>
      <Row>
        <Col md={8} sm={12}>
          <div className="thumbnail">
            <SkeletonShimmer />
          </div>

          <div className="row">
            <div className="actionsTab">
              {Array.from({ length: 3 }).map((item) => (
                <div key={item} className="actionsTab__item">
                  <SkeletonElement type="avatarSM" />
                </div>
              ))}
            </div>

            <div className="actionsBtn">
              <SkeletonElement type="buttonMD" />
            </div>
          </div>

          <div className="divider"></div>

          <div className="row">
            <SkeletonElement type="avatarXL" />
            <SkeletonElement type="buttonMD" />
          </div>

          <div className="divider"></div>

          {!isMobile && (
            <div className="desc">
              <SkeletonElement type="title" mb="1rem" varient="dark" />
              <SkeletonElement type="paragraph" mb=".5rem" varient="dark" />
              <SkeletonElement type="paragraph" mb=".5rem" varient="dark" />
              <SkeletonElement type="paragraph" varient="dark" />
            </div>
          )}
        </Col>

        <Col md={4} sm={12}>
          <div className="videos">
            {Array.from({ length: 5 }).map((item, i) => (
              <div className="videos__item" key={i}>
                <div className="videos__item--thumbnail">
                  <SkeletonShimmer />
                </div>

                <div className="videos__item--info">
                  <SkeletonElement type="title" mb=".6rem" />
                  <SkeletonElement type="paragraph" />
                </div>

                <SkeletonShimmer />
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </SeriesWatchSkeletonStyled>
  );
};

export default SeriesWatch;

const SeriesWatchSkeletonStyled = styled.div`
  width: 90%;
  margin: 0 auto 5rem auto;
  padding: 1.5rem 0;

  .thumbnail {
    width: 100%;
    height: 22rem;
    background-color: #202020;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 1.5rem;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem;
  }

  .actionsTab {
    display: flex;
    align-items: center;

    &__item {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.04);
    margin-bottom: 1rem;
  }

  .desc {
    background: #202020;
    padding: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    margin-top: 2rem;
  }

  .videos {
    background-color: #202020;
    padding: 1rem;
    border-radius: 5px;

    &__item {
      background-color: #181818;
      padding: 0.5rem;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.09);
      position: relative;
      overflow: hidden;

      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &--thumbnail {
        width: 6.5rem;
        height: 4rem;
        background-color: #202020;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.05);
      }

      &--info {
        width: calc(100% - 7rem);
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    width: 95%;

    .thumbnail {
      height: 17rem;
      margin-bottom: 1rem;
    }

    .actionsTab {
      &__item {
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    .videos {
      padding: 0.5rem;

      &__item {
        padding: 0.35rem;
      }
    }
  }

  @media screen and (max-width: 414px) {
    .actionsTab {
      &__item {
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
  }
`;
