import React, { useState } from "react";
import styled from "styled-components";
import LinkIcon from "../icons/link.svg";
import CloseIcon from "../icons/close.svg";
import Button from "../Elements/Button";
import { toast } from "react-toastify";

const AddSources = ({ sources, setSources }) => {
  const [url, setUrl] = useState("");

  const isValidUrl = (str) => {
    const pattern = new RegExp(
      "^([a-zA-Z]+:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", // fragment locator
      "i"
    );
    return pattern.test(str);
  };

  const addSourceHandler = () => {
    if (!url) {
      toast.error("Please add url first.");
      return;
    }

    if (!isValidUrl(url)) {
      toast.error("URL must be valid.");
      return;
    }

    setSources([...sources, url]);

    setUrl("");
  };

  const removeSourceHandler = (url) => {
    const filteredUrls = sources.filter((item) => item !== url);
    setSources(filteredUrls);
    return;
  };

  return (
    <AddSourcesStyled>
      <div className="addUrlSource">
        {sources && sources.length > 0 && (
          <div className="addUrlSource__list">
            {sources.map((item, i) => (
              <div title={item} key={i} className="addUrlSource__list--item">
                <div
                  onClick={() => removeSourceHandler(item)}
                  className="close"
                >
                  <img src={CloseIcon} alt="" />
                </div>
                <a target="_blank" href={item}>
                  {item?.length > 50 ? item.substring(0, 45) + "..." : item}
                </a>
              </div>
            ))}
          </div>
        )}

        <input
          name="url"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          className="addUrlSource__input"
          placeholder="Add url"
        />

        <Button
          onClick={addSourceHandler}
          mode="secondary"
          iconLeft
          size="medium"
          variant="round"
          className="addUrlSource__btn"
        >
          <img src={LinkIcon} alt="" /> Add
        </Button>
      </div>
    </AddSourcesStyled>
  );
};

export default AddSources;

const AddSourcesStyled = styled.div`
  .addUrlSource {
    &__btn {
      padding: 0.5rem 2rem;

      img {
        margin-right: 0.5rem;
      }
    }

    &__input {
      background-color: #202020;
      margin-bottom: 0.5rem;
      width: 50%;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);
      padding: 0.4rem 0.6rem;
      border-radius: 4px;
      font-family: ${(props) => props.theme.montserrat};
      font-size: 0.9rem;
      font-weight: 400;
      color: #fff;

      &::placeholder {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.75rem;
      }
    }

    &__list {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 1rem;

      &--item {
        height: 2rem;
        padding-right: 0.6rem;
        background: ${(props) => props.theme.gradient};
        border-radius: 3px;
        line-height: 1;
        font-size: 400;
        font-size: 0.86rem;
        display: flex;
        align-items: center;
        border: 1px solid rgba(255, 255, 255, 0.09);
        overflow: hidden;

        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }

        .close {
          img {
            height: 0.7rem;
            width: auto;
          }
          height: 100%;
          width: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #202020;
          cursor: pointer;
          margin-right: 1rem;
        }
      }
    }
  }
`;
