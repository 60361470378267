import {
  GET_VIDEOS_REQUESTV2,
  GET_VIDEOS_SUCCESSV2,
  GET_VIDEOS_FAILV2,
  SHOW_FILTERED_VIDEOS,
  CLEAR_FILTERED_VIDEOS,
  PUSH_UPLOADED_VIDEOV2,
} from "../actions/types";

const videos = (
  state = {
    videos: [],
    filteredVideos: [],
    hasMore: false,
    isFetching: true,
    isFiltering: false,
  },
  action
) => {
  switch (action.type) {
    case GET_VIDEOS_REQUESTV2:
      return {
        isFetching: true,
        filteredVideos:
          state.filteredVideos && state.filteredVideos.length > 0
            ? [...state.filteredVideos]
            : [],
        isFiltering: false,
        videos:
          state.videos && state.videos.length > 0 ? [...state.videos] : [],
      };
    case GET_VIDEOS_SUCCESSV2:
      const allVideos = [...state.videos, ...action.payload.videos];
      const uniqueVideos = [
        ...new Map(allVideos.map((item) => [item["id"], item])).values(),
      ];

      return {
        isFetching: false,
        videos: uniqueVideos,
        hasMore: action.payload.hasMore,
        isFiltering: false,
        filteredVideos: [],
      };
    case PUSH_UPLOADED_VIDEOV2:
      return {
        isFetching: false,
        videos: [action.payload.video, ...state.videos],
        hasMore: state.hasMore,
        isFiltering: false,
        filteredVideos: [],
      };
    case SHOW_FILTERED_VIDEOS:
      const allvideos = [...state.filteredVideos, ...action.payload.videos];
      const uniqueFilteredVideos = [
        ...new Map(allvideos.map((item) => [item["id"], item])).values(),
      ];

      return {
        isFetching: false,
        filteredVideos: uniqueFilteredVideos,
        hasMore: action.payload.hasMore,
        isFiltering: action.payload.isFiltering,
      };

    case GET_VIDEOS_FAILV2:
      return {
        isFetching: false,
        error: action.payload,
        isFiltering: false,
        filteredVideos:
          state.filteredVideos && state.filteredVideos.length > 0
            ? [...state.filteredVideos]
            : [],
        videos:
          state.videos && state.videos.length > 0 ? [...state.videos] : [],
        hasMore: false,
      };
    case CLEAR_FILTERED_VIDEOS:
      return {
        ...state,
        filteredVideos: [],
      };

    default:
      return state;
  }
};

export default videos;
