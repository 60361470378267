import {
  PROFILE_ELEMENT_SERIES,
  PROFILE_ELEMENT_SERIES_SECOND
} from "../actions/types";


const profileElementsSeries = (state = { seriesloading: true }, action) => {
  switch (action.type) {
    case PROFILE_ELEMENT_SERIES:
      return {
        seriesloading: false,
        userseries: action.payload,
        totalPage: action.totalPage
      };
    case PROFILE_ELEMENT_SERIES_SECOND:
      const allSeries = [...state.userseries, ...action.payload];
      return {
        seriesloading: false,
        userseries: allSeries,
        totalPage: action.totalPage
      };
    default:
      return state;
  }
};

export default profileElementsSeries;
