import styled from "styled-components";
import config from "../../../config";

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100vw;
  height: auto;
  overflow-x: hidden;
  flex-direction: column;
  padding-bottom: 5rem;
`;

export const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40vh;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  justify-content: end;
  align-items: center;
  justify-content: space-evenly;
  align-items: end;
  flex-direction: column;
  justify-content: end;
  background-image: ${(props) => (props.cover ? `url(${props.cover})` : null)};
  background-position: center;

  > .editBtn {
    position: fixed;
    left: 13px;
    button {
      margin-bottom: 13px;
      font-size: 10px;
      color: #f2f2f7;
      font-family: montserrat;
    }
  }

  .add-to-stream {
    position: absolute;
    left: 0px;
    button {
      margin-bottom: 13px;
      font-size: 10px;
      color: #f2f2f7;
      font-family: montserrat !important;
    }
  }
`;
export const ShareBtn = styled.div`
  height: 26px;
  width: 76px;
  font-size: 10px;
  background-color: white;
  border-radius: 30px 0px 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.primaryColor1};
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 10px 10px 22px 2px rgba(122, 115, 115, 0.39);
  -webkit-box-shadow: 10px 10px 22px 2px rgba(122, 115, 115, 0.39);
  -moz-box-shadow: 10px 10px 22px 2px rgba(122, 115, 115, 0.39);
  @media (max-width: 640px) {
    height: 26px;
    width: 76px;
    font-size: 10px;
  }
  p {
    font-size: 10px;
    font-family: "Montserrat";
    font-weight: bold;
    letter-spacing: 1.64px;
    line-height: 13px;
  }
`;

export const CoopBtn = styled.div`
  height: 38px;
  width: 126px;
  font-size: 10px;
  background-color: ${(props) => props.theme.primaryColor1};
  border-radius: 30px 0px 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -19px;
  font-weight: 500;
  letter-spacing: 0.1em;
  cursor: pointer;
  @media (max-width: 640px) {
    height: 38px;
    width: 126px;
    font-size: 10px;
  }
  p {
    font-size: 10px;
    font-family: "Montserrat";
    font-weight: bold;
    letter-spacing: 1.64px;
    line-height: 13px;
  }
`;

export const ConnectBtn = styled.div`
  height: 42px;
  min-width: 140px;
  width: auto;
  padding: 0 1.5rem;
  font-size: 10px;
  background: ${(props) => props.theme.primaryGradient} !important;
  border-radius: 30px 0px 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -19px;
  font-weight: 500;
  letter-spacing: 0.1em;
  cursor: pointer;
  @media (max-width: 640px) {
    height: 38px;
    width: 126px;
    font-size: 10px;
  }
  p {
    font-size: 12px;
    text-transform: uppercase;
    font-family: "Montserrat";
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 13px;
  }

  img {
    height: 0.8rem;
    width: auto;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    border-right: 1px solid rgba(255, 255, 255, 0.6);
  }
`;

export const AvtarContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 158px;
  width: 190px;
  height: auto;
  position: relative;
  bottom: 60px;
  flex-direction: column;
  align-items: center;

  > .avatar {
    cursor: pointer;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    position: relative;
    margin-bottom: 0.5rem;

    .imageBadge {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .badge {
        height: 5rem;
        width: auto;
      }
    }

    .imageAvatar {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  @media (max-width: 640px) {
    > .avatar {
      height: 75px;
      width: 75px;
    }
  }
  > .userrole {
    height: 22px;
    color: white;
    line-height: 8px;
    padding: 0.25rem 0.85rem;
    font-size: 12px;
    font-family: "brother-1816", sans-serif;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: 500;
    background: ${(props) => props.theme.primaryGradient} !important;
    border-radius: 4px;
    border: none;
  }
`;

export const AvtarItem = styled.div`
  height: 100px;
  width: 100px;
  background-color: #f9903d;
  border-radius: 50%;
`;

export const AddToStreamBtn = styled.div`
  padding-right: 6px;
  height: 42px;
  width: 165px;
  font-family: Montserrat;
  text-transform: uppercase;
  font-size: 12px;
  background: ${(props) => props.theme.primaryGradient} !important;
  border-radius: 0px 30px 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -19px;
  font-weight: 600;
  letter-spacing: 0.1em;
  cursor: pointer;
  @media (max-width: 640px) {
    height: 38px;
    width: 126px;
    font-size: 10px;
  }
  p {
    font-size: 12px;
    text-transform: uppercase;
    font-family: "Montserrat";
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 13px;
  }
`;
