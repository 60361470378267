import React, { createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  // general
  const [authState, setAuthState] = useState("CHECK_EMAIL"); // CHECK_EMAIL, LOGIN, SIGNUP, FORGOT_PASSWORD
  const [signupStep, setSignupStep] = useState(1);
  const [welcomeUsername, setWelcomeUsername] = useState(null);
  const TOTAL_STEPS = 6;
  const [badgeObj, setBadgeObj] = useState(null);
  const [storageObj, setStorageObj] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showAgreementPage, setShowAgreementPage] = useState(false);

  // personal information
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");

  // membership
  const [choosePackage, setChoosePackage] = useState("");

  // badge
  const [badges, setBadges] = useState(null);
  const [badgesLoading, setBadgesLoading] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState(null);

  // storage
  const [selectedStorage, setSelectedStorage] = useState(null);

  // usersettings
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [anonymous, setAnonymous] = useState(false);
  const [proContentProducer, setProContentProducer] = useState(false);
  const [partnerContentCreator, setPartnerContentCreator] = useState(false);
  const [contactViaEmail, setContactViaEmail] = useState(false);
  const [agreedToLicense, setAgreedToLicense] = useState(false);

  // validation error
  const [validationError, setValidationError] = useState(null);

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
        signupStep,
        setSignupStep,
        TOTAL_STEPS,
        welcomeUsername,
        setWelcomeUsername,
        badgeObj,
        setBadgeObj,
        storageObj,
        setStorageObj,
        loader,
        setLoader,
        showAgreementPage,
        setShowAgreementPage,

        firstname,
        setFirstname,
        lastname,
        setLastname,
        username,
        setUsername,
        email,
        setEmail,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        referralCode,
        setReferralCode,
        choosePackage,
        setChoosePackage,
        badges,
        setBadges,
        badgesLoading,
        setBadgesLoading,
        selectedBadge,
        setSelectedBadge,
        selectedStorage,
        setSelectedStorage,
        country,
        setCountry,
        state,
        setState,
        city,
        setCity,
        anonymous,
        setAnonymous,
        proContentProducer,
        setProContentProducer,
        partnerContentCreator,
        setPartnerContentCreator,
        contactViaEmail,
        setContactViaEmail,
        agreedToLicense,
        setAgreedToLicense,
        validationError,
        setValidationError,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
