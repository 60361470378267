import React from "react";
import styled from "styled-components";
import ArrowLeft from "../icons/arrow-left.svg";

const Title = ({
  children,
  className,
  viewAllAction,
  viewAllText,
  buttonClassName,
  sliderController,
  showSlider,
}) => {
  return (
    <TitleStyled className={className}>
      {children}

      {viewAllText && (
        <div
          onClick={viewAllAction}
          className={`viewAll ${buttonClassName ? buttonClassName : null}`}
        >
          {viewAllText}
        </div>
      )}

      {showSlider && (
        <div className="sliderController">
          {sliderController?.lastIndex === 0 ||
          sliderController?.lastIndex ===
            sliderController?.defaultSliderItem ? null : (
            <div
              onClick={sliderController.prevHandler}
              className="sliderController__leftIcon"
            >
              <img src={ArrowLeft} alt="" />
            </div>
          )}

          <div
            onClick={sliderController.nextHandler}
            className="sliderController__rightIcon"
          >
            <img src={ArrowLeft} alt="" />
          </div>
        </div>
      )}
    </TitleStyled>
  );
};

export default Title;

const TitleStyled = styled.div`
  font-family: brother-1816, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .viewAll {
    cursor: pointer;
    line-height: 1;
    font-family: brother-1816, sans-serif;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: 500;
    background: ${(props) => props.theme.primaryGradient};
    border-radius: 3px;
  }

  .sliderController {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    img {
      height: 1.2rem;
      width: auto;
    }

    &__leftIcon {
      margin-right: 1rem;
      img {
        transform: rotate(180deg);
      }
    }

    &__leftIcon,
    &__rightIcon {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  @media screen and (max-width: 480px) {
    font-size: 1.2rem;

    .viewAll {
      padding: 0.4rem 0.8rem;
      font-size: 0.68rem;
      border-radius: 2px;
    }

    .sliderController {
      img {
        height: 1rem;
      }

      &__leftIcon {
        margin-right: 0.5rem;
      }

      &__leftIcon,
      &__rightIcon {
        height: 2.2rem;
        width: 2.2rem;
      }
    }
  }

  @media screen and (max-width: 375px) {
    font-size: 1rem;

    .viewAll {
      padding: 0.4rem 0.7rem;
      font-size: 0.62rem;
      border-radius: 1px;
    }
  }
`;
