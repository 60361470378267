import React from "react";
import styled, { css } from "styled-components";

const Button = ({
  children,
  size = "medium",
  variant,
  width,
  mode = "primary",
  className,
  iconLeft,
  onClick,
  type,
  bg,
  disabled,
}) => {
  return (
    <ButtonStyled
      disabled={disabled}
      style={{
        background: bg,
      }}
      type={type}
      onClick={onClick}
      className={className}
      size={size}
      variant={variant}
      width={width}
      mode={mode}
      iconLeft={iconLeft}
    >
      {children}
    </ButtonStyled>
  );
};

export default Button;

const ButtonStyled = styled.button`
  border: none;
  text-transform: uppercase;
  font-family: "brother-1816", sans-serif;
  line-height: 1;
  cursor: pointer;
  position: relative;
  font-weight: 500;
  transition: all 0.5s ease;

  /* mode */
  ${(props) =>
    props.mode &&
    props.mode === "primary" &&
    css`
      background: ${(props) => props.theme.button};
      color: #fff;
    `}

  ${(props) =>
    props.mode &&
    props.mode === "secondary" &&
    css`
      background: #181818;
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.08);
    `}

    ${(props) =>
    props.mode &&
    props.mode === "tertiary" &&
    css`
      background: #202020;
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.08);
    `}

    
    /* size */
    ${(props) =>
    props.size &&
    props.size === "small" &&
    css`
      font-size: 0.625rem;
      padding: 0.5rem 0.8rem;
      border-radius: 2.5px;
      letter-spacing: 0.65px;
    `}

    ${(props) =>
    props.size &&
    props.size === "medium" &&
    css`
      font-size: 0.825rem;
      padding: 0.6rem 1rem;
      border-radius: 4px;
      display: flex;
      align-items: center;
      letter-spacing: 0.75px;

      img {
        height: 1rem;
        width: auto;
      }

      @media screen and (max-width: 480px) {
        font-size: 0.8rem;
      }
    `}


    ${(props) =>
    props.size &&
    props.size === "big" &&
    css`
      font-size: 1.1rem;
      padding: 0.7rem 1.25rem;
      border-radius: 6px;
      display: flex;
      align-items: center;
      letter-spacing: 1.5px;

      img {
        height: 1rem;
        width: auto;
      }
    `}


    ${(props) =>
    props.width &&
    props.width === "fluid" &&
    css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      ${(props) =>
        props.size &&
        props.size === "medium" &&
        css`
          padding: 0.8rem 0;

          img {
            height: 0.8rem;
            width: auto;
          }
        `}
    `}


    ${(props) =>
    props.iconLeft &&
    css`
      img {
        margin-right: 1rem;
      }
    `}

    ${(props) =>
    props.iconRight &&
    css`
      img {
        margin-left: 1rem;
      }
    `}


    // variant
    ${(props) =>
    props.variant &&
    props.variant === "round" &&
    css`
      border-radius: 15rem;
    `}

    ${(props) =>
    props.variant &&
    props.variant === "box-round" &&
    css`
      border-radius: 10rem;

      ${(props) =>
        props.size &&
        props.size === "small" &&
        css`
          padding: 0.5rem 1.2rem;
        `}

      ${(props) =>
        props.size &&
        props.size === "medium" &&
        css`
          padding: 0.6rem 1.5rem;
        `}

        ${(props) =>
        props.size &&
        props.size === "big" &&
        css`
          padding: 0.7rem 2rem;
        `}
    `}

    
    ${(props) =>
    props.variant &&
    props.variant === "outline" &&
    css`
      color: white;
      background: transparent !important;
      border: 1px solid white;

      ${(props) =>
        props.size &&
        props.size === "small" &&
        css`
          padding: 0.5rem 1.2rem;
          display: inline-flex;
          align-items: center;
          gap: 0.35;

          img {
            width: 0.55rem;
            height: auto;
          }

          &:hover {
            img {
              filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(82deg)
                brightness(103%) contrast(101%);
            }
          }
        `}

      &:hover {
        background: white !important;
        color: ${(props) => props.theme.primaryColor1};
      }
    `}

    ${(props) =>
    props.variant &&
    props.variant === "round-outline" &&
    css`
      color: white;
      background: transparent !important;
      border-radius: 10rem;
      border: 1px solid white;

      &:hover {
        background: white !important;
        color: ${(props) => props.theme.primaryColor1};
      }
    `}

    ${(props) =>
    props.variant &&
    props.variant === "box-round-outline" &&
    css`
      color: white;
      background: transparent !important;
      border-radius: 10rem;
      border: 1px solid white;

      &:hover {
        background: white !important;
        color: ${(props) => props.theme.primaryColor1};
      }

      ${(props) =>
        props.size &&
        props.size === "small" &&
        css`
          padding: 0.5rem 1.2rem;
        `}

      ${(props) =>
        props.size &&
        props.size === "medium" &&
        css`
          padding: 0.6rem 1.5rem;
        `}

        ${(props) =>
        props.size &&
        props.size === "big" &&
        css`
          padding: 0.7rem 2rem;
        `}
    `}
`;
