import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AdminSidebar from "../AdminSidebar";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { IoTrashSharp } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import Pagination from "../Pagination";
import { adminAllSeriesData, deleteAdminSeries } from "../../actions";
import ConfirmationModel from "../../components/ConfirmationModel";
import config from "../../config";
import SeriesEditTable from "./SeriesEditTable";

const SeriesTable = ({ close, moment, midDrag }) => {
  const seriesData = useSelector((state) => state.seriesTableData.seriesData);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [seriesFormData, setSeriesFormData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("title");
  const [sortOrder, setSortOrder] = useState("asc");
  const itemsPerPage = 5;
  const history = useHistory();
  useEffect(() => {
    dispatch(adminAllSeriesData()).then(() => {});
  }, [dispatch]);

  //   serching------------------
  const filterData = () => {
    return seriesData
      ? seriesData.filter((row) => {
          const lowerSearch = search.toLowerCase();
          return (
            (row.userId && row.userId.toLowerCase().includes(lowerSearch)) ||
            (row.title && row.title.toLowerCase().includes(lowerSearch)) ||
            (row.description &&
              row.description.toLowerCase().includes(lowerSearch))
          );
        })
      : [];
  };
  // Pagination -------------
  const filteredData = filterData();
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //   Edit ------------------------
  const handleEdit = (row) => {
    setIsEditing(true);
    setSeriesFormData({
      id: row.id,
      title: row.title,
      description: row.description,
      userId: row.userId,
      thumbnail: row.thumbnail,
      price: row.price,
      createdAt: row.createdAt,
    });
  };

  //   Delete-------------------
  const handleDelete = (row) => {
    setShowDeleteConfirmation(true);
    setSeriesFormData({ id: row.id });
  };
  const handleFormChange = (field, value) => {
    setSeriesFormData({ ...seriesFormData, [field]: value });
  };

  const deleteVideoHandler = async () => {
    try {
      await dispatch(deleteAdminSeries(seriesFormData.id));
      // console.log(seriesFormData.id);
      setShowDeleteConfirmation(false);
      dispatch(adminAllSeriesData());
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  // Sorting data ----------------------
  const sortData = () => {
    const sortedData = [...filteredData];
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortColumn].localeCompare(b[sortColumn]);
      } else {
        return b[sortColumn].localeCompare(a[sortColumn]);
      }
    });
    return sortedData;
  };
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="tableHeader">SR.No</TableCell>
          <TableCell
            className="tableHeader"
            onClick={() => handleSort("title")}
          >
            Title
            {sortColumn === "title" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell
            className="tableHeader"
            onClick={() => handleSort("title")}
          >
            Description{" "}
            {sortColumn === "title" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell
            className="tableHeader"
            onClick={() => handleSort("title")}
          >
            UserId{" "}
            {sortColumn === "title" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}{" "}
          </TableCell>
          <TableCell className="tableHeader">Thumbnail</TableCell>
          <TableCell className="tableHeader">Price</TableCell>
          <TableCell className="tableHeader">Created At </TableCell>
          <TableCell className="tableHeader">Action </TableCell>
        </TableRow>
      </TableHead>
    );
  };
  return (
    <>
      <SeriesDashboard>
        <AdminSidebar />
        <TableContainer component={Paper} className="tableContainer">
          <div>
            <h1 className="headingText">Search The Series Table</h1>
            <input
              type="text"
              placeholder="Search The Series Table"
              className="searchBox"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <Table className="table">
            {renderTableHeader()}
            <TableBody>
              {sortData()
                .slice(startIndex, endIndex)
                .map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell className="tableCell">
                      {startIndex + index + 1}
                    </TableCell>
                    <TableCell className="tableCell">{row.title}</TableCell>
                    <TableCell className="tableCell">
                      {row.description}
                    </TableCell>
                    <TableCell className="tableCell">{row.userId}</TableCell>
                    <TableCell className="tableCellmuxId" align="center">
                      <img
                        src={config.aws.CLOUDFRONT_URL + row.thumbnail}
                        alt="Thumbnail"
                        onClick={() => {
                          history.push(`/series/details/${row.id}`);
                        }}
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    </TableCell>
                    <TableCell className="tableCell">{row.price}</TableCell>
                    <TableCell className="tableCell">{row.createdAt}</TableCell>

                    <TableCell className="tableCell">
                      <FiEdit
                        style={{ margin: "8px" }}
                        className="editButton"
                        size={28}
                        onClick={() => handleEdit(row)}
                      />
                      <IoTrashSharp
                        style={{ margin: "8px" }}
                        className="deleteButton"
                        size={28}
                        onClick={() => handleDelete(row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div style={{ marginTop: "7px", marginBottom: "10%" }}>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={seriesData ? seriesData.length : 0}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </TableContainer>
      </SeriesDashboard>
      {isEditing && (
        <SeriesEditTable
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          seriesFormData={seriesFormData}
          onChange={handleFormChange}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationModel
          closeHandler={() => setShowDeleteConfirmation(false)}
          title="Are you sure you want to delete this series Table ? You can't undo this action."
          deleteHandler={deleteVideoHandler}
        />
      )}
    </>
  );
};

export default SeriesTable;

const SeriesDashboard = styled.div`
  display: flex;

  .tableContainer {
    max-width: 100%;
    height: 100vh;
    background: #212123;
    padding-left: 2%;
    padding-right: 2%;
  }
  .table {
    background: #212123;
  }
  .tableHeader {
    background: #212123;
    color: white;
    border: 1px solid;
    font-size: 13px;
  }
  .tableCell {
    color: white;
    border: 1px solid;
    font-size: 13px;
  }
  .tableCellmuxId {
    color: white;
    border: 1px solid;
    font-size: 13px;
    word-break: break-all;
  }
  .editButton {
    color: #4f92f7;
    marginleft: 10px;
    transition: transform 0.2s;
  }
  .editButton:hover {
    transform: scale(1.3);
  }

  .deleteButton {
    color: #f54e4e;
    marginleft: 10px;
    transition: transform 0.2s;
  }
  .deleteButton:hover {
    transform: scale(1.3);
  }

  .headingText {
    font-size: 18px;
    margin-top: 10px;
    color: white;
  }
  .searchBox {
    background: #2c2c2e !important;
    color: white;
    margin-bottom: 2%;
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: #cdc5c5;
    }
  }

  /* Responsive styling for the search box */
  @media (max-width: 768px) {
    .SearchBox {
      width: 100%;
    }
  }
`;
