import React, { useState } from "react";
import styled from "styled-components";
import EyeOpenIcon from "../icons/eyeopen.svg";
import EyeCloseIcon from "../icons/eyeclose.svg";
import LoaderIcon from "../icons/loader.svg";
import CheckmarkIcon from "../icons/checkmark.svg";
import CloseIcon from "../icons/close.svg";

const Input = ({
  label,
  name,
  id,
  placeholder,
  type,
  value,
  autofocus,
  onChange,
  infoObj,
  emailExistHandler,

  // valid username check props
  usernameValidate,
  usernameCheckLoading,
  usernameStatus,
  referralvalidate,
  referralCheckLoading,
  referralStatus,
}) => {
  const [inputType, setInputType] = useState(type);

  return (
    <InputStyled>
      {/* eye icon for password */}
      {(name === "password" || name === "confirmPassword") && (
        <div className="eye">
          {inputType === "password" ? (
            <img onClick={() => setInputType("text")} src={EyeOpenIcon} />
          ) : inputType === "text" ? (
            <img onClick={() => setInputType("password")} src={EyeCloseIcon} />
          ) : null}
        </div>
      )}
      <form onSubmit={emailExistHandler} className="form-style">
        <label htmlFor={id}>{label}</label>
        <input
          autoComplete="off"
          name={name}
          id={id}
          placeholder={placeholder}
          type={inputType}
          value={value}
          onChange={onChange}
          autoFocus={autofocus ? autofocus : false}
        />
      </form>
      {infoObj && infoObj.text && (
        <span
          className={`infoText ${
            infoObj.type === "success"
              ? "infoText__success"
              : infoObj.type === "error"
              ? "infoText__error"
              : null
          }`}
        >
          {infoObj.text}
        </span>
      )}

      {usernameValidate && (
        <div className="username__validator">
          {value.length > 3 && value.length < 20 && usernameCheckLoading && (
            <div className="username__loader">
              <div className="icon">
                <img src={LoaderIcon} alt="" />
              </div>
              <div className="text">Checking if username is available</div>
            </div>
          )}

          {value.length > 3 &&
            value.length < 20 &&
            !usernameCheckLoading &&
            usernameStatus && (
              <div className="username__success">
                <div className="icon">
                  <img src={CheckmarkIcon} alt="" />
                </div>
                <div className="text">Great! Username is available</div>
              </div>
            )}

          {value.length <= 3 && value && (
            <div className="username__error">
              <div className="text">
                Sorry! Username must be greater than or equal to 4 characters
              </div>
            </div>
          )}

          {value.length > 20 && value && (
            <div className="username__error">
              <div className="text">
                Sorry! Username must be less than or equal to 20 characters
              </div>
            </div>
          )}

          {usernameCheckLoading === false && usernameStatus === false && (
            <div className="username__error">
              <div className="icon">
                <img src={CloseIcon} alt="" />
              </div>
              <div className="text">Sorry! Username is not available</div>
            </div>
          )}
        </div>
      )}
      {referralvalidate && (
        <div className="username__validator">
          {value.length > 3 && value.length < 20 && referralCheckLoading && (
            <div className="username__loader">
              <div className="icon">
                <img src={LoaderIcon} alt="" />
              </div>
              <div className="text">Checking if referral code is available</div>
            </div>
          )}

          {value.length > 3 &&
            value.length < 20 &&
            !referralCheckLoading &&
            referralStatus && (
              <div className="username__error">
                <div className="icon">
                  <img src={CloseIcon} alt="" />
                </div>
                <div className="text">
                  Only Warrior users can receive referrals. Please enter a valid
                  Warrior user name.
                </div>
              </div>
            )}

          {referralCheckLoading === false && referralStatus === false && (
            <div className="username__success">
              <div className="icon">
                <img src={CheckmarkIcon} alt="" />
              </div>
              <div className="text">
                Warrior user found! We'll send them your $2.50 referral. Thank
                you!
              </div>
            </div>
          )}
        </div>
      )}
    </InputStyled>
  );
};

export default Input;

const InputStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  position: relative;

  label {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 0.4rem;
  }

  input {
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;

    &:hover {
      border: 3px solid ${(props) => props.theme.primaryColor1} !important;
    }

    &:focus {
      border: 3px solid ${(props) => props.theme.primaryColor1} !important;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .infoText {
    font-size: 0.7rem;
    font-weight: 400;

    &__success {
      color: #00c897;
    }

    &__error {
      color: #eb4747;
    }
  }
  .form-style {
    width: 100%;
  }
  .eye {
    position: absolute;
    bottom: 0.3rem;
    right: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }

    img {
      height: 1rem;
      width: auto;
    }
  }

  /* username validation styling */
  .username {
    &__validator {
      display: flex;
      align-items: center;
      padding-top: 5px;
      font-size: 0.9rem;
      font-weight: 300;
      padding-top: 5px;
    }

    &__loader {
      display: flex;
      align-items: center;

      .icon {
        margin-top: 5px;

        img {
          height: 1rem;
          width: auto;
          margin-right: 0.5rem;
        }
      }

      .text {
      }
    }

    &__success {
      display: flex;
      align-items: center;
      color: #00ffab;

      .icon {
        margin-top: 5px;

        img {
          filter: brightness(0) saturate(100%) invert(80%) sepia(34%)
            saturate(2098%) hue-rotate(98deg) brightness(99%) contrast(103%);
          height: 1rem;
          width: auto;
          margin-right: 0.5rem;
        }
      }
    }

    &__error {
      display: flex;
      align-items: center;
      color: #e64848;

      .icon {
        margin-top: 5px;

        img {
          filter: brightness(0) saturate(100%) invert(55%) sepia(54%)
            saturate(6763%) hue-rotate(335deg) brightness(92%) contrast(95%);
          height: 1rem;
          width: auto;
          margin-right: 0.5rem;
        }
      }
    }
  }
`;
