import React, { useEffect } from "react";
import styled from "styled-components";
import SeriesCardSmall from "../components/SeriesComponents/SeriesCardSmall";
import ContactBanner from "../components/Footer/ContactBanner";
import Footer from "../components/Footer/Footer";
import Skeleton from "../skeletons/General/Page";
import Error from "../components/Elements/Error";
import SeriesCard from "../components/Cards/SeriesCard";
import { Col, Row } from "react-grid-system";
import { getAllSeries } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { SERIES_OTHER } from "../utils/menuOptions";
import { useLocation } from "react-router-dom";
import Title from "../components/Elements/Title";

const Series = () => {
  const dispatch = useDispatch();
  const routePath = useLocation();
  const { loading: getSeriesLoading, series: allSeries } = useSelector(
    (state) => state.getAllSeries
  );
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useEffect(() => {
    dispatch(getAllSeries());
  }, [dispatch]);

  if (getSeriesLoading) {
    return <Skeleton />;
  }

  return (
    <>
      <SeriesWrapper>
        {!getSeriesLoading && (!allSeries || !allSeries.length) && (
          <Error text="" />
        )}
        <Row className="seriesWrapper">
          <Title>Series Home</Title>
          {allSeries && allSeries.length > 0
            ? allSeries.map((item, i) => (
                <Col
                  key={i}
                  className="seriesWrapper__item"
                  md={6}
                  sm={12}
                  lg={3}
                >
                  {/* <SeriesCardSmall item={item} show /> */}
                  <SeriesCard
                    item={item}
                    isSelf={false}
                    menuOptions={SERIES_OTHER}
                  />
                </Col>
              ))
            : ""}
        </Row>
      </SeriesWrapper>

      <ContactBanner />
      <Footer />
    </>
  );
};

export default Series;

const SeriesWrapper = styled.div`
  padding: 1.3rem;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 3rem;

  .seriesWrapper {
    margin: 2rem 0;

    &__item {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }

  @media screen and (max-width: 991px) {
    .seriesWrapper {
      &__item {
        margin-bottom: 1.5rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;
