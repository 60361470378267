import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AdminSidebar from "../AdminSidebar";
import InputV2 from "../../components/Elements/InputV2";
import CreatorsTable from "./CreatorsTable";
import api from "../../services/api";
import SeriesTable from "./SeriesTable";
import ThreadsTable from "./ThreadsTable";

const Featured = () => {
  const [tab, setTab] = useState("CREATORS");
  const [featuredCreators, setFeaturedCreators] = useState(null);
  const [featuredSeries, setFeaturedSeries] = useState(null);
  const [featuredThreads, setFeaturedThreads] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      if (tab === "CREATORS") {
        await fetchFeaturedCreators();
      }

      if (tab === "SERIES") {
        await fetchFeaturedSeries();
      }

      if (tab === "THREADS") {
        await fetchFeaturedThreads();
      }
    })();
  }, [tab]);

  const fetchFeaturedCreators = async () => {
    setLoading(true);
    try {
      const res = await api.get("admin/creator/featured");
      setFeaturedCreators(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(`No featured creators found`);
    }
  };

  const fetchFeaturedSeries = async () => {
    setLoading(true);
    try {
      const res = await api.get("admin/series/featured");
      setFeaturedSeries(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(`No featured series found`);
    }
  };

  const fetchFeaturedThreads = async () => {
    setLoading(true);
    try {
      const res = await api.get("admin/thread/featured/admin");
      setFeaturedThreads(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(`No featured threads found`);
    }
  };

  return (
    <FeaturedStyled>
      <AdminSidebar />

      <div className="content">
        <div className="content__header">
          <div className="content__header--types">
            <div
              onClick={() => setTab("CREATORS")}
              className={`item ${tab === "CREATORS" && "active"}`}
            >
              {tab === "CREATORS" && <img src="/icons/tick.svg" alt="" />}
              featured creators
            </div>
            <div
              onClick={() => setTab("SERIES")}
              className={`item ${tab === "SERIES" && "active"}`}
            >
              {tab === "SERIES" && <img src="/icons/tick.svg" alt="" />}
              featured series
            </div>{" "}
            <div
              onClick={() => setTab("THREADS")}
              className={`item ${tab === "THREADS" && "active"}`}
            >
              {tab === "THREADS" && <img src="/icons/tick.svg" alt="" />}
              featured threads
            </div>
          </div>
        </div>

        {/* <div className="content__search">
          <InputV2
            type={"search"}
            label={`Search ${
              tab === "CREATORS"
                ? "Creators"
                : tab === "MOMENTS"
                ? "Moments"
                : tab === "THREADS"
                ? "Threads"
                : ""
            }`}
            placeholder={`Type your query...`}
          />
        </div> */}

        <div className="content__table">
          {tab === "CREATORS" && (
            <CreatorsTable
              tab={tab}
              setData={setFeaturedCreators}
              data={featuredCreators}
              loading={loading}
              error={error}
            />
          )}

          {tab === "SERIES" && (
            <SeriesTable
              tab={tab}
              setData={setFeaturedSeries}
              data={featuredSeries}
              loading={loading}
              error={error}
            />
          )}

          {tab === "THREADS" && (
            <ThreadsTable
              tab={tab}
              setData={setFeaturedThreads}
              data={featuredThreads}
              loading={loading}
              error={error}
            />
          )}

          {/* {(tab === "MOMENTS" || tab === "THREADS") && (
            <div className="content__table--pending">work is pending</div>
          )} */}
        </div>
      </div>
    </FeaturedStyled>
  );
};

export default Featured;

const FeaturedStyled = styled.div`
  display: flex;
  overflow-x: hidden;
  background: #212123;
  height: auto;
  z-index: 1000;

  .content {
    padding: 3rem;
    width: 100%;
    margin-bottom: 3.5rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__header {
      &--types {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;

        .item {
          font-family: brother-1816, sans-serif;
          font-size: 0.725rem;
          font-size: 300;
          text-transform: uppercase;
          border: 1px solid rgba(255, 255, 255, 0.75);
          padding: 0.45rem 1.25rem;
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;

          img {
            width: 0.85rem;
            height: auto;
          }
        }

        .active {
          border: 1px solid transparent;
          color: white;
          background: ${(props) => props.theme.primaryGradient};
          text-decoration: underline;
        }
      }
    }

    &__search {
      input {
        font-size: 0.9rem;
        padding: 0.6rem 0.7rem;
        border-radius: 0.25rem;
      }
    }

    &__table {
      margin-top: 2rem;
    }
  }
`;
