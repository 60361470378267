import React, { useEffect } from "react";
import styled from "styled-components";
import Title from "../../Elements/Title";
import VideoCard from "../../Cards/VideoCard";
import VideosSkeleton from "../../../skeletons/General/Section";
import Error from "../../Elements/Error";
import { Row, Col } from "react-grid-system";
import { VIDEO_OTHER } from "../../../utils/menuOptions";
import { useInView } from "react-intersection-observer";

const Clips = ({
  loadMoreHandler,
  isFetching,
  videos,
  hasMore,
  isFiltered,
}) => {
  const { ref: trackerRef, inView: trackerIsVisible } = useInView();

  useEffect(() => {
    if (hasMore && trackerIsVisible && !isFetching) {
      loadMoreHandler();
    }
  }, [trackerIsVisible, hasMore, isFetching]);

  const filteredVideos = videos.filter((clip) => clip.keyVideoAccess !== 4);

  return (
    <ClipsStyled>
      <Title>Latest Content</Title>

      {!isFetching && !filteredVideos?.length && (
        <Error text="No videos found" />
      )}

      <Row>
        {filteredVideos &&
          filteredVideos.length > 0 &&
          filteredVideos.map((clip) => (
            <Col
              key={clip.id}
              md={3}
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
            >
              <VideoCard item={clip} menuOptions={VIDEO_OTHER} isSelf={false} />
            </Col>
          ))}

        {hasMore === false && !isFiltered && (
          <div className="noMore">
            You've reached the end of video results. Try another video category
            or select "All" from the category filter above
          </div>
        )}

        {hasMore === false && isFiltered && (
          <div className="noMore">
            Try another video category or select "All" from the category filter
            above
          </div>
        )}
      </Row>

      {isFetching && filteredVideos && (
        <VideosSkeleton numItems={8} noTitle fullWidth sectionName="videos" />
      )}

      {!isFetching && <div ref={trackerRef} className="tracker" />}
    </ClipsStyled>
  );
};

export default Clips;

const ClipsStyled = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  .tracker {
    width: 100%;
    height: 5rem;
    background: transparent;
    opacity: 0;
  }

  .noMore {
    width: 100%;
    text-align: center;
    font-weight: 300;
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  }
`;
