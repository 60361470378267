const initialState = {
  videoData: null,
  videoCategoryData: null,
  videoCategoryRequestData: null,
  videoSubCategoriesData: null,
  subCategoryOneData: null,
  subCategoryTwoData: null,
  updateVideoCategoryData: null,
  updateVideoSubCategoryData: null,
  updateSubCategoryOneData: null,
  updateSubCategoryTwoData: null,
  error: null,
};

const videoTableData = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_VIDEO_DATA_SUCCESS":
      return {
        ...state,
        videoData: action.payload,
        error: null,
      };
    case "UPDATE_VIDEO_DATA":
      const updatedData = action.payload;
      const updatedIndex = state.videoData.findIndex(
        (item) => item.id === updatedData.id
      );

      if (updatedIndex !== -1) {
        const updatedVideoData = [...state.videoData];
        updatedVideoData[updatedIndex] = updatedData;

        return {
          ...state,
          videoData: updatedVideoData,
          error: null,
        };
      }
      return state;
    case "FETCH_VIDEO_DATA_FAILURE":
      return {
        ...state,
        videoData: null,
        error: action.error,
      };
    case "FETCH_VIDEO_CATEGORY_SUCCESS":
      return {
        ...state,
        videoCategoryData: action.payload,
        error: null,
      };
    case "FETCH_VIDEO_CATEGORY_FAILURE":
      return {
        ...state,
        videoCategoryData: null,
        error: action.error,
      };
    case "FETCH_VIDEO_CATEGORY_REQUEST_SUCCESS":
      return {
        ...state,
        videoCategoryRequestData: action.payload,
        error: null,
      };
    case "FETCH_VIDEO_CATEGORY_REQUEST_FAILURE":
      return {
        ...state,
        videoCategoryRequestData: null,
        error: action.error,
      };
    case "FETCH_VIDEO_SUB_CATEGORY_SUCCESS":
      return {
        ...state,
        videoSubCategoriesData: action.payload,
        error: null,
      };
    case "FETCH_VIDEO_SUB_CATEGORY_FAILURE":
      return {
        ...state,
        videoSubCategoriesData: null,
        error: action.error,
      };
    case "FETCH_SUB_CATEGORY_ONE_SUCCESS":
      return {
        ...state,
        subCategoryOneData: action.payload,
        error: null,
      };
    case "FETCH_SUB_CATEGORY_ONE_FAILURE":
      return {
        ...state,
        subCategoryOneData: null,
        error: action.error,
      };
    case "FETCH_SUB_CATEGORY_TWO_SUCCESS":
      return {
        ...state,
        subCategoryTwoData: action.payload,
        error: null,
      };
    case "FETCH_SUB_CATEGORY_TWO_FAILURE":
      return {
        ...state,
        subCategoryTwoData: null,
        error: action.error,
      };
    case "UPDATE_VIDEO_CATEGORY_SUCCESS":
      return {
        ...state,
        updateVideoCategoryData: action.payload,
        error: null,
      };
    case "UPDATE_VIDEO_CATEGORY_FAILURE":
      return {
        ...state,
        updateVideoCategoryData: null,
        error: action.error,
      };
    case "UPDATE_VIDEO_SUB_CATEGORY_SUCCESS":
      return {
        ...state,
        updateVideoSubCategoryData: action.payload,
        error: null,
      };
    case "UPDATE_VIDEO_SUB_CATEGORY_FAILURE":
      return {
        ...state,
        updateVideoSubCategoryData: null,
        error: action.error,
      };
    case "UPDATE_SUB_CATEGORY_ONE_SUCCESS":
      return {
        ...state,
        updateSubCategoryOneData: action.payload,
        error: null,
      };
    case "UPDATE_SUB_CATEGORY_ONE_FAILURE":
      return {
        ...state,
        updateSubCategoryOneData: null,
        error: action.error,
      };
    case "UPDATE_SUB_CATEGORY_TWO_SUCCESS":
      return {
        ...state,
        updateSubCategoryTwoData: action.payload,
        error: null,
      };
    case "UPDATE_SUB_CATEGORY_TWO_FAILURE":
      return {
        ...state,
        updateSubCategoryTwoData: null,
        error: action.error,
      };

    default:
      return state;
  }
};

export default videoTableData;
