import React, { useEffect } from "react";
import axios from "axios";
import api from "../services/api";
import config from "../config";
import ImageNotfoundPlaceholder from "../assets/image_notfound_placeholder.svg";
import { notificationMessage } from "../utils/toastNotifications";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const timeSince = (timestamp) => {
  const seconds = Math.floor((new Date() - new Date(timestamp)) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " years";
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months";
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days";
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours";
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes";
  }

  return Math.floor(seconds) + " seconds";
};

export const upload = async (type, file, videoLength, userId) => {
  const API_URL = `${config.api.url}`;
  let url = "/" + `${file.name}`;
  let publicId = "";
  let muxid = "";
  let toastId = toast(`Please Wait, Upload in Progress...`, {
    hideProgressBar: true,
    closeOnClick: false,
  });
  const formData = new FormData();
  formData.append("upload_preset", "isutra");
  formData.append("cloud_name", "isutra");
  formData.append("file", file);
  formData.append("videoLength", videoLength);
  formData.append("userId", userId);
  const response = await axios.post(
    `${API_URL}fileoperation/fileUpload`,
    formData,
    {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          toast.update(toastId, {
            render: `Please Wait, Upload in Progress... ${percent}%`,
            hideProgressBar: true,
            closeOnClick: false,
          });
        }
        if (percent == 100) {
          toast.update(toastId, {
            render: `Please Wait, Upload in Progress... ${percent}%`,
            hideProgressBar: true,
            closeOnClick: false,
          });
        }
      },
    }
  );

  publicId = "0";

  if (response && response.data && response.data.asset) {
    toast.dismiss(toastId);
    muxid = response.data.asset.id;
    return {
      url,
      muxid,
      videoLength,
    };
  }
  return {
    url,
    publicId,
    // duration,
    videoLength,
  };
};

export const uploadCover = async (resourceType, file) => {
  const API_URL = `${config.api.url}`;
  let url = "/" + `${file.name}`;
  let publicId = "";
  const formData = new FormData();
  formData.append("upload_preset", "isutra");
  formData.append("cloud_name", "isutra");
  formData.append("file", file);
  const response = await axios.post(
    `${API_URL}fileoperation/fileUpload`,
    formData
  );

  publicId = "0";

  if (response && response.data) {
    return {
      url,
    };
  }
};

export const uploadImage = async (file) => {
  const API_URL = `${config.api.url}`;
  let url = "/" + `${file.name}`;
  let publicId = "";
  const formData = new FormData();
  formData.append("upload_preset", "isutra");
  formData.append("cloud_name", "isutra");
  formData.append("file", file);
  const response = await axios.post(
    `${API_URL}fileoperation/imageUpload`,
    formData
  );

  publicId = "0";

  if (response && response.data) {
    toast.dismiss();
    return {
      url,
    };
  }
  return {
    url,
    publicId,
  };
};
export const uploadVistorBadge = async (resourceType, file) => {
  const API_URL = `${config.api.url}`;
  let url = "/" + `${file.name}`;
  let publicId = "";
  const formData = new FormData();
  formData.append("upload_preset", "isutra");
  formData.append("cloud_name", "isutra");
  formData.append("file", file);
  const response = await axios.post(
    `${API_URL}fileoperation/vistorBadge`,
    formData
  );

  publicId = "0";

  if (response && response.data) {
    toast.dismiss();
    return {
      url,
    };
  }
  return {
    url,
    publicId,
  };
};

export const authenticate = async (endpoint, data) => {
  const backendUrl = config.api.url;

  try {
    const tokenRes = await axios.post(`${backendUrl}auth/${endpoint}`, data);

    const conf = {
      headers: { Authorization: `Bearer ${tokenRes.data.data}` },
    };

    const userRes = await axios.get(`${backendUrl}auth/me`, conf);
    const user = { ...userRes.data.data, token: tokenRes.data.data };
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${tokenRes.data.data}`;
    localStorage.setItem("user", JSON.stringify(user));
    return user;
  } catch (err) {
    if (
      err.response.data &&
      err.response.data.message === "The email is already taken"
    ) {
      toast.error(notificationMessage.emailTaken);
    }
  }
};

// userrole auth without needing to refresh token
export const whoisme = async () => {
  const backendUrl = config.api.url;
  const token = JSON.parse(localStorage.getItem("user"));
  try {
    const conf = {
      headers: { Authorization: `Bearer ${token.token}` },
    };

    const userRes = await axios.get(`${backendUrl}auth/me`, conf);
    const userlocalstorage = JSON.parse(localStorage.getItem("user"));
    const user = {
      ...userlocalstorage,
      ...userRes.data.data,
    };

    localStorage.setItem("user", JSON.stringify(user));

    return user;
  } catch (err) {}
};

export const removeChannelLocalSt = (channelId) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const updated = {
    ...user,
    channels: user.channels.filter((channel) => channel.id !== channelId),
  };

  localStorage.setItem("user", JSON.stringify(updated));
};

export const addChannelLocalSt = (channel) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const updated = {
    ...user,
    channels: [channel, ...user.channels],
  };

  localStorage.setItem("user", JSON.stringify(updated));
};

export const getImgPath = (video) => {
  const loggedInUserRole = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).userrole
    : 0;
  if (
    !video ||
    !video.VideoAccessOverlay ||
    loggedInUserRole === 2 ||
    loggedInUserRole === 1
  ) {
    return "";
  }
  return loggedInUserRole > 0
    ? video.VideoAccessOverlay.imgPathMember
    : video.VideoAccessOverlay.imgPathFreeLoader;
};

export const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

export function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay;
}

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["X-CSCAPI-KEY"] = token;
  } else {
    delete axios.defaults.headers.common["X-CSCAPI-KEY"];
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const toHHMMSS = (videoLength) => {
  var sec_num = parseInt(videoLength, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  const result = [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");

  return (
    result +
    `${hours && !minutes ? " hour" : minutes && !hours ? " min" : " sec"}`
  );
};

export const addDefaultImage = (e, url) => {
  e.target.src = url;
  return;
};

export const isBadgeOrAvatar = (image) => {
  if (image && (image.includes(".png") || image.includes(".svg"))) {
    return (
      <div className="imageBadge">
        <img className="badge" src={image} alt="" />
      </div>
    );
  } else {
    return (
      <img
        onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
        className="imageAvatar"
        src={config.aws.CLOUDFRONT_URL + image}
        alt=""
      />
    );
  }
};

export const calculatePercentage = (smallNum, bigNum) => {
  return (smallNum / bigNum) * 100;
};

export const elementScrollToTop = (id) => {
  const element = document.getElementById(id);
  element.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const validURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }

  return str.slice(0, num);
};
