import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
} from "@material-ui/core";
import Pagination from "../Pagination";
import AdminSidebar from "../AdminSidebar";
import UserEditForm from "./UserEditForm";
import { adminAllUserData, deleteAdminUser } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModel from "../../components/ConfirmationModel";
import { IoTrashSharp } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";

const AdminDashboard = ({ close }) => {
  const history = useHistory();
  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();
  const adminUserData = useSelector((state) => state.adminUserData.userData);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [userFormData, setUserFormData] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [sortColumn, setSortColumn] = useState("email");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    dispatch(adminAllUserData());
  }, []);

  useEffect(() => {
    if (adminUserData) {
      setUserData(adminUserData);
    }
  }, [adminUserData]);

  const tableHeadingStyle = {
    color: "white",
    border: "1px solid white",
  };

  const handleFormChange = (field, value) => {
    setUserFormData({ ...userFormData, [field]: value });
  };
  const handleEdit = (row) => {
    setIsEditing(true);
    setUserFormData({
      id: row.id,
      email: row.email,
      username: row.username,
      userrole: row.userrole,
      firstname: row.firstname,
      lastname: row.lastname,
    });
  };

  const handleDelete = (row) => {
    setShowDeleteConfirmation(true);
    setUserFormData({ id: row.id });
  };

  const deleteVideoHandler = async () => {
    try {
      await dispatch(deleteAdminUser(userFormData.id));
      setShowDeleteConfirmation(false);
      dispatch(adminAllUserData());
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const startingIndex = (currentPage - 1) * itemsPerPage + 1;

  const filteredData = userData.filter(
    (row) =>
      row.username.toLowerCase().includes(search.toLowerCase()) ||
      row.email.toLowerCase().includes(search.toLowerCase()) ||
      row.firstname.toLowerCase().includes(search.toLowerCase()) ||
      row.lastname.toLowerCase().includes(search.toLowerCase())
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  function getUserRoleName(userRole) {
    switch (userRole) {
      case 0:
        return "Freeloader";
      case 1:
        return "Co-op Member";
      case 2:
        return "Warrior";
      default:
        return "Unknown";
    }
  }

  // Sorting data ----------------------
  const sortData = () => {
    const sortedData = [...filteredData];
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortColumn].localeCompare(b[sortColumn]);
      } else {
        return b[sortColumn].localeCompare(a[sortColumn]);
      }
    });
    return sortedData;
  };
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };
  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            style={tableHeadingStyle}
            onClick={() => handleSort("email")}
          >
            SR.No
          </TableCell>
          <TableCell
            style={tableHeadingStyle}
            onClick={() => handleSort("email")}
          >
            Email
            {sortColumn === "email" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell
            style={tableHeadingStyle}
            onClick={() => handleSort("email")}
          >
            User Name{" "}
            {sortColumn === "email" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell style={tableHeadingStyle}>User Role</TableCell>
          <TableCell style={tableHeadingStyle}> First Name</TableCell>
          <TableCell style={tableHeadingStyle}> Last Name</TableCell>
          <TableCell style={tableHeadingStyle}> Action</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      <AdminDasboard>
        <AdminSidebar />
        <TableContainer component={Paper} className="responsive-table">
          <h1 className="headingText">Search The User</h1>
          <input
            type="text"
            placeholder="Search The User"
            value={search}
            className="searchBox"
            onChange={(e) => setSearch(e.target.value)}
            style={{ color: search ? "white" : "white" }}
          />
          <Table>
            {renderTableHeader()}
            <TableBody>
              {sortData()
                .slice(indexOfFirstItem, indexOfLastItem)
                .map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell style={tableHeadingStyle}>
                      {startingIndex + index}
                    </TableCell>
                    <TableCell style={tableHeadingStyle}>{row.email}</TableCell>
                    <TableCell
                      className="table-row"
                      onClick={() => {
                        history.push(`/channel/${row.username}`);
                      }}
                    >
                      {row.username}
                    </TableCell>
                    <TableCell style={tableHeadingStyle}>
                      {getUserRoleName(row.userrole)}
                    </TableCell>
                    <TableCell style={tableHeadingStyle}>
                      {row.firstname}
                    </TableCell>
                    <TableCell style={tableHeadingStyle}>
                      {row.lastname}
                    </TableCell>
                    <TableCell style={tableHeadingStyle}>
                      <FiEdit
                        style={{ margin: "10px" }}
                        className="edit-button"
                        onClick={() => handleEdit(row)}
                        size={28}
                      />

                      <IoTrashSharp
                        className="delete-button"
                        style={{ margin: "10px" }}
                        onClick={() => handleDelete(row)}
                        size={28}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div
            style={{ marginLeft: "7%", marginTop: "7px", marginBottom: "15%" }}
          >
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filteredData.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </TableContainer>
      </AdminDasboard>
      {isEditing && (
        <UserEditForm
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          userFormData={userFormData}
          onChange={handleFormChange}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationModel
          closeHandler={() => setShowDeleteConfirmation(false)}
          title="Are you sure you want to delete this user? You can't undo this action."
          deleteHandler={deleteVideoHandler}
        />
      )}
    </>
  );
};

export default AdminDashboard;

const AdminDasboard = styled.div`
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
  // background: #333;
  background: #212123;
  // height: auto;
  height: 120vh;
  
.table-row{
    color: white;
    border: 1px solid white;
  }
  .table-row:hover{
    color: #2F81F7;
  text-decoration: underline;
  cursor: pointer;
  }
  
  .dasboard {
    margin-top: 1%;
    padding-left: 1%;
  }
  .table_box{
    width: 86%,
    margin-left: 7%,
    margin-top: 2%,
  }
  .edit-button:hover {
    transform: scale(1.3);
  }
  .edit-button {
    color: #4f92f7;
    marginleft: 10px;
    transition: transform 0.2s;
  }

  .delete-button:hover {
    transform: scale(1.3);
  }

  .delete-button {
    color: #f54e4e;
    margin: 10px;
    transition: transform 0.2s;
  }
  @media (max-width: 768px) {
    .responsive-table {
      height: 88vh;
    }
  }
  .searchBox {
    background: #2c2c2e !important;
    color: white;
    margin-bottom: 2%;
    width: 86%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    margin-left: 7%;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: #cdc5c5;
    }
  }

  /* Responsive styling for the search box */
  @media (max-width: 768px) {
    .SearchBox {
      width: 100%;
    }
  }

  .headingText {
    margin-left: 7%;
    font-size: 18px;
    margin-top: 10px;
    color: white;
  }
  .responsive-table {
    overflow-x: auto;
    max-width: 100%;
    // background-color: #333
    background: #212123
  }
  .responsive-table table {
    width: 86%;
    margin-left: 7%;
    color: "white";
  }
  .responsive-table th, .responsive-table td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    // max-width: 100px; 
  }

`;
