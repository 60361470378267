import {
  GET_SINGLE_HASHTAG_REQUEST,
  GET_SINGLE_HASHTAG_SUCCESS,
  GET_SINGLE_HASHTAG_FAIL,
  RESET_SINGLE_HASHTAG_STATE,
} from "../actions/hashtag/type";

const hashtag = (
  state = {
    hashtag: null,
    videos: [],
    moments: [],
    threads: [],
    hasMoreVideos: true,
    hasMoreMoments: true,
    hasMoreThreads: true,
    isFetching: true,
  },
  action
) => {
  switch (action.type) {
    case GET_SINGLE_HASHTAG_REQUEST:
      return {
        isFetching: true,
        hashtag: state.hashtag ? state.hashtag : null,
        videos:
          state.videos && state.videos.length > 0 ? [...state.videos] : [],
        moments:
          state.moments && state.moments.length > 0 ? [...state.moments] : [],
        threads:
          state.threads && state.threads.length > 0 ? [...state.threads] : [],
      };
    case GET_SINGLE_HASHTAG_SUCCESS:
      const allVideos =
        action.payload?.videos?.length > 0
          ? [...state.videos, ...action.payload.videos]
          : [...state.videos];
      const uniqueVideos = [
        ...new Map(allVideos.map((item) => [item["id"], item])).values(),
      ];

      const allMoments =
        action.payload?.moments?.length > 0
          ? [...state.moments, ...action.payload.moments]
          : [...state.moments];
      const uniqueMoments = [
        ...new Map(allMoments.map((item) => [item["id"], item])).values(),
      ];

      const allThreads =
        action.payload?.threads?.length > 0
          ? [...state.threads, ...action.payload.threads]
          : [...state.threads];
      const uniqueThreads = [
        ...new Map(allThreads.map((item) => [item["id"], item])).values(),
      ];

      return {
        hashtag: state.hashtag ? state.hashtag : action.payload.hashtag,
        videos: uniqueVideos,
        moments: uniqueMoments,
        threads: uniqueThreads,
        isFetching: false,
        hasMoreVideos: action.payload.hasMoreVideos,
        hasMoreMoments: action.payload.hasMoreMoments,
        hasMoreThreads: action.payload.hasMoreThreads,
      };
    case GET_SINGLE_HASHTAG_FAIL:
      return {
        isFetching: false,
        error: action.payload,
        hashtag: state.hashtag ? state.hashtag : null,
        videos:
          state.videos && state.videos.length > 0 ? [...state.videos] : [],
        moments:
          state.moments && state.moments.length > 0 ? [...state.moments] : [],
        threads:
          state.threads && state.threads.length > 0 ? [...state.threads] : [],
        hasMoreVideos: false,
        hasMoreMoments: false,
        hasMoreThreads: false,
      };

    case RESET_SINGLE_HASHTAG_STATE: {
      return {};
    }
    default:
      return state;
  }
};

export default hashtag;
