import React, { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = (props) => {
  const [siteTheme, setSiteTheme] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showEditProfileModel, setShowEditProfileModel] = useState(false);
  const [showTipPopups, setShowTipPopups] = useState(false);
  const [homeCategoryModel, setHomeCategoryModel] = useState(false);
  const [uploadVideoModal, setUploadVideoModal] = useState(false);
  const [newCategoryRequestModel, setNewCategoryRequestModel] = useState(false);
  const [showVideoReportModel, setShowVideoReportModel] = useState(false);
  const [singleVideoFormattedTimestamp, setSingleVideoFormattedTimestamp] =
    useState(null);
  const [pauseVideoForReport, setPauseVideoForReport] = useState(false);
  const [showPlaylistModel, setShowPlaylistModel] = useState(false);
  const [playlistModelType, setPlaylistModelType] = useState("create");
  const [deletePlaylistConfirmationModel, setDeletePlaylistConfirmationModel] =
    useState(false);
  const [editPlaylistModel, setEditPlaylistModel] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [createCourseModel, setCreateCourseModel] = useState(false);
  const [deleteSeriesConfirmationModel, setDeleteSeriesConfirmationModel] =
    useState(false);
  const [editSeriesModel, setEditSeriesModel] = useState(false);
  const [addVideosToSeriesModel, setAddVideosToSeriesModel] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [showMyAccount, setShowMyAccount] = useState(false);
  const [showMyPortal, setShowMyPortal] = useState(false);
  const [portalUsername, setPortalUsername] = useState(null);
  const [showPlaylistModelV2, setShowPlaylistModelV2] = useState(false);
  const [showCreateSeriesModel, setShowCreateSeriesModel] = useState(false);
  const [showUploadMomentModel, setShowUploadMomentModel] = useState(false);
  const [showUploadClipModel, setShowUploadClipModel] = useState(false);
  const [showGlobalPlaylistModel, setShowGlobalPlaylistModel] = useState(false);
  const [showEditClipModel, setShowEditClipModel] = useState(false);
  const [showUploadModel, setShowUploadModel] = useState(false);
  const [showChatList, setShowChatList] = useState(false);
  const [showChatDetails, setShowChatDetails] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showEditMomentModel, setShowEditMomentModel] = useState(false);
  const [showChooseProfilePicModel, setShowChooseProfilePicModel] =
    useState(false);
  const [showNotifactionModal, setShowNotificationModal] = useState(false);
  const [showSearchModel, setShowSearchModel] = useState(false);
  const [showAuthenticationModel, setShowAuthenticationModel] = useState(false);
  const [deleteVideoConfirmationModel, setDeleteVideoConfirmationModel] =
    useState(false);
  const [seriesEditMode, setSeriesEditMode] = useState("DETAILS"); // DETAILS, VIDEOS
  const [deleteMomentConfirmationModel, setDeleteMomentConfirmationModel] =
    useState(false);
  const [videoForSeriesUpload, setVideoForSeriesUpload] = useState({
    series: null,
    showSeriesList: true,
  });
  const [showMomentDetailsModel, setShowMomentDetailsModel] = useState(false);
  const [selectedMoment, setSelectedMoment] = useState(null);
  const [showManageSubscription, setShowManageSubscription] = useState(false);
  const [myMomentsCount, setMyMomentsCount] = useState(null);
  const [portalTab, setPortalTab] = useState(0);
  const [chatTab, setChatTab] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [playlistModelShowType, setPlaylistModelShowType] =
    useState("CREATE_FORM");
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [showUploadThreadModel, setShowUploadThreadModel] = useState(false);
  const [selectedThread, setSelectedThread] = useState(null);
  const [deleteThreadConfirmationModel, setDeleteThreadConfirmationModel] =
    useState(false);
  const [showUpdateThreadModel, setShowUpdateThreadModel] = useState(false);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [showKarmaModel, setShowKarmaModel] = useState(false);
  const [showPPVKarmaModel, setShowPPVKarmaModel] = useState(false);
  const [showAddAdditionalContentModel, setShowAddAdditionalContentModel] =
    useState(false);
  const [showEditAdditionalContentModel, setShowEditAdditionalContentModel] =
    useState(false);

  return (
    <GlobalContext.Provider
      value={{
        siteTheme,
        setSiteTheme,
        showSidebar,
        setShowSidebar,
        showEditProfileModel,
        setShowEditProfileModel,
        showTipPopups,
        setShowTipPopups,
        homeCategoryModel,
        setHomeCategoryModel,
        uploadVideoModal,
        setUploadVideoModal,
        newCategoryRequestModel,
        setNewCategoryRequestModel,
        showVideoReportModel,
        setShowVideoReportModel,
        singleVideoFormattedTimestamp,
        setSingleVideoFormattedTimestamp,
        pauseVideoForReport,
        setPauseVideoForReport,
        showPlaylistModel,
        setShowPlaylistModel,
        playlistModelType,
        setPlaylistModelType,
        deletePlaylistConfirmationModel,
        setDeletePlaylistConfirmationModel,
        editPlaylistModel,
        setEditPlaylistModel,
        selectedVideoId,
        setSelectedVideoId,
        createCourseModel,
        setCreateCourseModel,
        deleteSeriesConfirmationModel,
        setDeleteSeriesConfirmationModel,
        editSeriesModel,
        setEditSeriesModel,
        addVideosToSeriesModel,
        setAddVideosToSeriesModel,
        selectedSeries,
        setSelectedSeries,
        showMyAccount,
        setShowMyAccount,
        showMyPortal,
        setShowMyPortal,
        showPlaylistModelV2,
        setShowPlaylistModelV2,
        showCreateSeriesModel,
        setShowCreateSeriesModel,
        showUploadMomentModel,
        setShowUploadMomentModel,
        portalUsername,
        setPortalUsername,
        showUploadClipModel,
        setShowUploadClipModel,
        showEditClipModel,
        setShowEditClipModel,
        showUploadModel,
        setShowUploadModel,
        showGlobalPlaylistModel,
        setShowGlobalPlaylistModel,
        showChatList,
        setShowChatList,
        showChatDetails,
        setShowChatDetails,
        showEditProfile,
        setShowEditProfile,
        showEditMomentModel,
        setShowEditMomentModel,
        showChooseProfilePicModel,
        setShowChooseProfilePicModel,
        showNotifactionModal,
        setShowNotificationModal,
        showSearchModel,
        setShowSearchModel,
        showAuthenticationModel,
        setShowAuthenticationModel,
        deleteVideoConfirmationModel,
        setDeleteVideoConfirmationModel,
        seriesEditMode,
        setSeriesEditMode,
        deleteMomentConfirmationModel,
        setDeleteMomentConfirmationModel,
        videoForSeriesUpload,
        setVideoForSeriesUpload,
        showMomentDetailsModel,
        setShowMomentDetailsModel,
        selectedMoment,
        setSelectedMoment,
        showManageSubscription,
        setShowManageSubscription,
        myMomentsCount,
        setMyMomentsCount,
        portalTab,
        setPortalTab,
        chatTab,
        setChatTab,
        isLoggedIn,
        setIsLoggedIn,
        playlistModelShowType,
        setPlaylistModelShowType,
        selectedPlaylist,
        setSelectedPlaylist,
        showUploadThreadModel,
        setShowUploadThreadModel,
        selectedThread,
        setSelectedThread,
        deleteThreadConfirmationModel,
        setDeleteThreadConfirmationModel,
        showUpdateThreadModel,
        setShowUpdateThreadModel,
        showWelcomeScreen,
        setShowWelcomeScreen,
        showKarmaModel,
        setShowKarmaModel,
        showPPVKarmaModel,
        setShowPPVKarmaModel,
        showAddAdditionalContentModel,
        setShowAddAdditionalContentModel,
        showEditAdditionalContentModel,
        setShowEditAdditionalContentModel,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
