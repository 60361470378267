import React, { useState, useContext } from "react";
import Popover from "@mui/material/Popover";
import styled from "styled-components";
import { styled as MUIStyled } from "@mui/material";
import { GlobalContext } from "../../../context/GlobalContext";

const PopoverStyled = MUIStyled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    borderRadius: `5px`,
    background: `#202020`,
    color: "#fff",
    border: "1px solid rgba(255,255,255, .1)",
  },

  "& .MuiPaper-root": {
    boxShadow: "none",

    "&:not(:last-child)": {
      borderBottom: "1px solid #fff",
    },
  },

  "& .MuiList-root": {
    padding: "0.4rem .35rem .1rem .35rem",
  },
}));

const FriendOptions = ({
  options,
  className,
  setShowDisconnectConfirmation,
  setSelectedWarriorId,
  setSelectedCollaboratorId,
  setSelectedConnectionId,
  warriorId,
  collaboratorId,
  username,
  connectionId,
  setShowDeleteConversation,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { setShowChatDetails } = useContext(GlobalContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const itemClickHandler = (optionItem) => {
    if (optionItem.text === "Disconnect") {
      setSelectedWarriorId(warriorId);
      setSelectedCollaboratorId(collaboratorId);
      setShowDisconnectConfirmation(true);
      setSelectedConnectionId(connectionId);
      localStorage.setItem("connectionId", connectionId);
      handleClose();
    } else if (optionItem.text === "Message") {
      const senderId = JSON.parse(localStorage.getItem("user")).id;
      const receiverId =
        JSON.parse(localStorage.getItem("user")).id === collaboratorId
          ? warriorId
          : collaboratorId;
      localStorage.setItem("username", username);
      localStorage.setItem("senderId", senderId);
      localStorage.setItem("receiverId", receiverId);
      localStorage.setItem("collaboratorId", collaboratorId);
      localStorage.setItem("connectionId", connectionId);
      handleClose();
      return;
    } else {
      setShowDeleteConversation(true);
      setSelectedConnectionId(connectionId);
      handleClose();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <MenuOptionsStyled>
      <div
        className="options-menu-right"
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <i className="fa fa-ellipsis-v"></i>
      </div>
      <PopoverStyled
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {options &&
          options.length > 0 &&
          options.map((item, i) => (
            <ItemStyled key={i} onClick={() => itemClickHandler(item)}>
              {!item.icon ? (
                <i className="fa fa-share-alt" aria-hidden="true"></i>
              ) : (
                item.icon
              )}
              {item.text === "Message" ? (
                <div className="text" onClick={() => setShowChatDetails(true)}>
                  {item.text}
                </div>
              ) : item.text === "Disconnect" ? (
                <div className="text">{item.text}</div>
              ) : (
                <div className="text">{item.text}</div>
              )}
            </ItemStyled>
          ))}
      </PopoverStyled>
    </MenuOptionsStyled>
  );
};

export default FriendOptions;

const ItemStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:hover {
    background: ${(props) => props.theme.gradient};
  }

  i {
    margin-right: 0.7rem;
  }

  img {
    height: 1rem;
    width: auto;
    margin-right: 0.7rem;
  }

  .text {
    text-transform: capitalize;
    font-size: 0.85rem;
  }
`;

const MenuOptionsStyled = styled.div`
  .options-menu-right {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    border: 1px solid transparent;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
`;
