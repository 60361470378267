import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Axios from "axios";
import Badge from "../components/SignupComponents/Badge";
import { connect, useDispatch } from "react-redux";
import { getUserById, getAllStorage } from "../actions";
import UserSettings from "../components/SignupComponents/UserSettings";
import config from "../config";
import api from "../services/api";
import { UPDATE_USER_BADGE } from "../actions/types";

const UpdateSubscription = ({ userRole, getUserById, getAllStorage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams();
  const [formStep, setFormStep] = useState(1);
  const [badges, setBadges] = useState([]);
  const [badgeSelected, setBadgeSelected] = useState(null);
  const [badgeLoading, setBadgeLoading] = useState(null);

  const [storageSelected, setStorageSelected] = useState(null);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [anonymous, setAnonymous] = useState(false);
  const [proContentProducer, setProContentProducer] = useState(false);
  const [partnerContentCreator, setPartnerContentCreator] = useState(false);
  const [contactViaEmail, setContactViaEmail] = useState(false);
  const [agreedToLicense, setAgreedToLicense] = useState(false);

  useEffect(() => {
    if (userRole >= 0) {
      if (userRole >= 0) {
        if (userRole === 0) {
          fetchBadgesHandler("free");
        } else if (userRole === 1) {
          fetchBadgesHandler("basic");
        } else if (userRole === 2) {
          fetchBadgesHandler("premium");
        }
      }
      if (userRole) {
        if (userRole === 2) {
          getAllStorage();
        }
      }
    }
  }, [getAllStorage, userRole]);

  const fetchBadgesHandler = async (badgeParam) => {
    try {
      setBadgeLoading(true);
      const { data } = await Axios.get(
        `${config.api.url}badge/${badgeParam}`
      );
      setBadges(data.badges);

      setBadgeLoading(false);
      localStorage.removeItem("lastUserRole");
    } catch (e) {
      setBadgeLoading(false);
    }
  };

  useEffect(() => {
    getUserById(userId);
  }, [getUserById, history.location.pathname, userId]);

  const userSignupHandlerForNotPremium = async () => {
    const { data } = await Axios.post(
      `${config.api.url}userSettings/updateVisitorBadge/`,
      {
        userId,
        visitorBadgeId: badgeSelected,
      }
    );
    if (data.status === "success") {
      updateUserBadgeInStore();
    }
    history.push("/home");
  };
  const getUserTypeByRole = (userRole) => {
    if (userRole === 0) return "free";
    if (userRole === 1) return "basic";
    if (userRole === 2) return "premium";
  };
  const userSignupHandlerForPremium = async () => {
    const userSettingPayload = {
      outOfThisWorld: anonymous,
      country,
      city,
      state,
      proContentProducer: proContentProducer,
      partnerContentCreator: partnerContentCreator,
      contactViaEmail: contactViaEmail,
      visitorBadgeId: badgeSelected,
      userId,
    };
    const { data } = await Axios.post(
      `${config.api.url}userSettings/updateSettings/`,
      userSettingPayload
    );
    if (data.status === "success") {
      updateUserBadgeInStore();
      history.push("/home");
    }
  };

  const updateUserBadgeInStore = async () => {
    const userType = getUserTypeByRole(userRole);
    const badgesDataByRole = await api.get(`badge/${userType}`);

    if (badgesDataByRole.data.badges) {
      const badgeFound = badgesDataByRole.data.badges.find((badge) => {
        return badge.id == badgeSelected;
      });
      dispatch({
        type: UPDATE_USER_BADGE,
        payload: badgeFound.imgPath,
      });
    }
  };

  const handleBadgeSubmit = (value) => {
    setBadgeSelected(value);
  };

  window.scrollTo(0, 0);

  if (formStep === 1) {
    return (
      <Badge
        stepper={setFormStep}
        badges={badges}
        choosePackage={
          userRole !== 0 ? (userRole !== 1 ? "PREMIUM" : "BASIC") : "FREE"
        }
        badgeLoading={badgeLoading}
        badgeSelected={badgeSelected}
        page="updateSubscription"
        handleBadgeSubmit={handleBadgeSubmit}
        completeSignup={userSignupHandlerForNotPremium}
      />
    );
  } else if (formStep === 2) {
    setFormStep(3);
  } else if (formStep === 3) {
    return (
      <UserSettings
        stepper={setFormStep}
        country={country}
        setCountry={setCountry}
        state={state}
        setState={setState}
        city={city}
        setCity={setCity}
        anonymous={anonymous}
        setAnonymous={setAnonymous}
        proContentProducer={proContentProducer}
        setProContentProducer={setProContentProducer}
        partnerContentCreator={partnerContentCreator}
        setPartnerContentCreator={setPartnerContentCreator}
        contactViaEmail={contactViaEmail}
        setContactViaEmail={setContactViaEmail}
        completeSignup={userSignupHandlerForPremium}
        setAgreedToLicense={setAgreedToLicense}
        page="updateSubscription"
      />
    );
  } else {
    return null;
  }
};

const mapStateToProps = ({ user }) => ({
  userRole: user.userrole,
});

export default connect(mapStateToProps, {
  getUserById,
  getAllStorage,
})(UpdateSubscription);
