import React from "react";
import styled from "styled-components";
import Input from "../../../components/Elements/Input";
import Button from "../../../components/Elements/Button";
import { Row, Col } from "react-grid-system";

import { useDispatch } from "react-redux";
import {
  adminVideoSubCategoriesData,
  updateAdminVideoSubCategory,
} from "../../../actions";

const VideoSubCategoriesEdit = ({ isOpen, onClose, formData, onChange }) => {
  const dispatch = useDispatch();

  const handleUpdateAdminUser = async () => {
    try {
      await dispatch(updateAdminVideoSubCategory(formData));
      await dispatch(adminVideoSubCategoriesData());
      onClose();
    } catch (error) {
      console.error("Error updating video type:", error);
    }
  };
  return (
    <VideoSubCategoriesEditModal open={isOpen}>
      <VideoSubCategoriesEditContent>
        <div className="title">Edit video Sub Categories Type</div>
        <div className="field">
          <Row className="row row__one">
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Id"
                  name="id"
                  id="id"
                  placeholder="id"
                  type="id"
                  value={formData.id}
                  onChange={(e) => onChange("id", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="videoId"
                  name="videoId"
                  id="videoId"
                  placeholder="videoId"
                  type="text"
                  value={formData.videoId}
                  onChange={(e) => onChange("videoId", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="momentId"
                  name="momentId"
                  id="momentId"
                  placeholder="momentId"
                  type="text"
                  value={formData.momentId}
                  onChange={(e) => onChange("momentId", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="seriesId"
                  name="seriesId"
                  id="seriesId"
                  placeholder="seriesId"
                  type="text"
                  value={formData.seriesId}
                  onChange={(e) => onChange("seriesId", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="threadId"
                  name="threadId"
                  id="threadId"
                  placeholder="threadId"
                  type="text"
                  value={formData.threadId}
                  onChange={(e) => onChange("threadId", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="videoCategoryId"
                  name="videoCategoryId"
                  id="videoCategoryId"
                  placeholder="videoCategoryId"
                  type="text"
                  value={formData.videoCategoryId}
                  onChange={(e) => onChange("videoCategoryId", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="videoContentType"
                  name="videoContentType"
                  id="videoContentType"
                  placeholder="videoContentType"
                  type="text"
                  value={formData.videoContentType}
                  onChange={(e) => onChange("videoContentType", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="subCategOneId"
                  name="subCategOneId"
                  id="subCategOneId"
                  placeholder="subCategOneId"
                  type="text"
                  value={formData.subCategOneId}
                  onChange={(e) => onChange("subCategOneId", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="subCategTwoId"
                  name="subCategTwoId"
                  id="subCategTwoId"
                  placeholder="subCategTwoId"
                  type="text"
                  value={formData.subCategTwoId}
                  onChange={(e) => onChange("subCategTwoId", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="CreatedAt"
                  name="createdAt"
                  id="createdAt"
                  placeholder="createdAt"
                  type="text"
                  value={formData.createdAt}
                  onChange={(e) => onChange("createdAt", e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Row className="row row__one">
          <Col md={6} sm={6} xs={6}>
            <Button mode={"secondary"} className="submit" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <Button className="submit" onClick={handleUpdateAdminUser}>
              Save
            </Button>
          </Col>
        </Row>
      </VideoSubCategoriesEditContent>
    </VideoSubCategoriesEditModal>
  );
};

export default VideoSubCategoriesEdit;

const VideoSubCategoriesEditModal = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoSubCategoriesEditContent = styled.div`
  background: #2c2c2e;
  color: white;
  border: 1px solid white;
  padding: 20px;
  max-width: 800px;
  width: 100%;

  .title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .submit {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdownBox {
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    margin-top: 3px;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;
