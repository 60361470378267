import React, { useState, useEffect, useContext } from "react";
import Button from "../components/Elements/Button";
import PasswordStrengthBar from "react-password-strength-bar";
import styled from "styled-components";
import NotFoundIcon from "../components/icons/notfound.svg";
import Input from "../components/Elements/Input";
import { toast } from "react-toastify";
import { notificationMessage } from "../utils/toastNotifications";
import { Row, Col } from "react-grid-system";
import { useMediaQuery } from "react-responsive";
import { GlobalContext } from "../context/GlobalContext";
import { AuthContext } from "../context/AuthContext";
import {
  changePasswordRequest,
  checkPWResetTokenExpiry,
  clearResetPasswordError,
} from "../actions";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

const ResetPassword = ({
  isLoading,
  isPasswordChanged,
  error,
  clearResetPasswordError,
  changePasswordRequest,
  checkPWResetTokenExpiry,
}) => {
  const { setShowAuthenticationModel } = useContext(GlobalContext);
  const { setAuthState } = useContext(AuthContext);
  const { token } = useParams();
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);

  const [state, setstate] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    checkPWResetTokenExpiry(token);
  }, [checkPWResetTokenExpiry, token]);
  useEffect(() => {
    if (isPasswordChanged) {
      toast.success(notificationMessage.passwordResetSuccess);
    }
  }, [isPasswordChanged]);
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      clearResetPasswordError();
    };
  }, [clearResetPasswordError]);

  const onChangeScore = (score) => {
    setPasswordStrengthScore(score);
  };

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  const passwordRegEx = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );

  const validateResetPasswordForm = (email, password, confirmPassword) => {
    if (!email.trim() || !password.trim()) {
      return "Please fill in all fields.";
    }

    if (password.length <= 7) {
      return "Password must be at least 8 characters long, contain at least 1 Number, 1 Upper case letter & 1 Special character.";
    }
    if (passwordStrengthScore < 2 || !passwordRegEx.test(password)) {
      return "Password must be at least 8 characters long, contain at least 1 Number, 1 Upper case letter & 1 Special character.";
    }
    if (password !== confirmPassword) {
      return "Password does not match.";
    }
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email.trim()
      )
    ) {
      return "Please enter a valid email address";
    }
    return null;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password, confirmPassword } = state;
    try {
      let error = validateResetPasswordForm(email, password, confirmPassword);
      if (!!error) {
        return toast.error(error);
      }

      changePasswordRequest(email, password, token);
    } catch (err) {}
  };

  const redirectToLogin = () => {
    setShowAuthenticationModel(true);
  };

  return (
    <ResetPasswordStyled>
      <div className="content">
        {error && (
          <div className="content__error">
            <img src={NotFoundIcon} alt="" />
            <p>
              {error && "Your password reset token is expired. "} Click the link
              below to reset your password.
            </p>
            <Button
              onClick={() => {
                redirectToLogin();
                setAuthState("FORGOT_PASSWORD");
              }}
              className="actionButton"
              mode="primary"
              width="fluid"
              variant="round"
              size="medium"
            >
              Reset Password
            </Button>
          </div>
        )}

        {!error && !isPasswordChanged && (
          <div className="content__form">
            <div className="title">Reset Password</div>

            <Row className="row">
              <Col md={12}>
                <div className="field">
                  <Input
                    label="Email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    type="text"
                    value={state.email}
                    onChange={(e) =>
                      setstate({
                        ...state,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col md={6} sm={6} xs={12}>
                <div className="field">
                  <Input
                    label="Password"
                    name="password"
                    id="password"
                    placeholder="Enter strong password"
                    type="text"
                    value={state.password}
                    onChange={(e) =>
                      setstate({
                        ...state,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />

                  {state.password && (
                    <div className="passwordStrength">
                      <PasswordStrengthBar
                        password={state.password}
                        onChangeScore={onChangeScore}
                      />
                    </div>
                  )}
                </div>
              </Col>

              <Col md={6} sm={6} xs={12}>
                <div className="field">
                  <Input
                    label="Confirm Password"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Confirm your password"
                    type="text"
                    value={state.confirmPassword}
                    onChange={(e) =>
                      setstate({
                        ...state,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
            </Row>

            <Button
              onClick={handleSubmit}
              className="actionButton"
              mode="primary"
              width="fluid"
              variant="round"
              size="medium"
            >
              {isLoading ? "Please wait..." : "Reset password"}
            </Button>
          </div>
        )}

        {isPasswordChanged && (
          <div className="content__success">
            <p>
              You have successfully changed your password. Please login to
              continue.
            </p>
            <Button
              onClick={redirectToLogin}
              className="actionButton"
              mode="primary"
              width="fluid"
              variant="round"
              size="medium"
            >
              Login
            </Button>
          </div>
        )}
      </div>
    </ResetPasswordStyled>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.resetPW.isLoading,
    isPasswordChanged: state.resetPW.isPasswordChanged,
    error: state.resetPW.error,
  };
};

export default connect(mapStateToProps, {
  changePasswordRequest,
  clearResetPasswordError,
  checkPWResetTokenExpiry,
})(ResetPassword);

const ResetPasswordStyled = styled.div`
  min-height: 100vh;
  width: 100vw;
  font-family: ${(props) => props.theme.montserrat};

  .content {
    width: 45vw;
    margin: 0 auto;
    padding: 2rem 1.5rem 1.5rem 1.5rem;
    background-color: #202020;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.04);

    .actionButton {
      font-weight: 500;
      padding: 0.6rem 0;
    }

    .title {
      font-size: 1.3rem;
      font-weight: 400;
      margin-bottom: 1rem;
      border-bottom: 2px dashed rgba(255, 255, 255, 0.1);
    }

    .passwordStrength {
      width: 60%;
      margin-top: 1rem;
      padding: 1rem 1rem 0.5rem 1rem;
      background-color: #181818;
      border-radius: 0.6rem;
      border: 1px solid rgba(255, 255, 255, 0.1);
      font-weight: 500;
      text-transform: capitalize;
      font-size: 0.7rem;

      p {
        color: #fff !important;
      }
    }

    &__error,
    &__success {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        height: 4rem;
        width: auto;
        margin-bottom: 1rem;
      }

      p {
        margin-bottom: 1rem;
        font-size: 0.9rem;
        font-weight: 400;
        text-align: center;
      }
    }

    &__form {
      .field {
        margin-bottom: 1rem;
      }

      .row {
        margin-bottom: 1rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      width: 100%;
      padding: 2rem 1rem 3rem 1rem;
    }
  }
`;
