import React, { useEffect, useState, useCallback, useContext } from "react";
import styled, { keyframes } from "styled-components";
import FiltersIcon from "../components/icons/filters.svg";
import RetryIcon from "../components/icons/retry.svg";
import VideoCard from "../components/Cards/VideoCard";
import MomentCard from "../components/Cards/MomentCard";
import CloseIcon from "../components/icons/close.svg";
import api from "../services/api";
import Loader from "../components/Elements/Loader";
import Error from "../components/Elements/Error";
import Button from "../components/Elements/Button";
import ChevronDownIcon from "../components/icons/chevron-down.svg";
import SeriesCard from "../components/Cards/SeriesCard";
import Title from "../components/Elements/Title";
import VideosSkeleton from "../skeletons/General/Section";
import MomentsSkeleton from "../skeletons/General/Section";
import SeriesSkeleton from "../skeletons/General/Section";
import ThreadCardSmall from "../components/Cards/ThreadCardSmall";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-grid-system";
import { MOMENTS_OTHER, SERIES_OTHER, VIDEO_OTHER } from "../utils/menuOptions";
import { GlobalContext } from "../context/GlobalContext";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";

const NewSearchResults = () => {
  const params = useParams();
  const [filterExpanded, setFilterExpanded] = useState(false);
  const { setShowSearchModel } = useContext(GlobalContext);
  const user = useSelector((state) => state.user);

  // tracker
  const { ref: clipsTrackerRef, inView: clipsTrackerIsVisible } = useInView();
  const { ref: momentsTrackerRef, inView: momentsTrackerIsVisible } =
    useInView();
  const { ref: seriesTrackerRef, inView: seriesTrackerIsVisible } = useInView();
  const { ref: threadsTrackerRef, inView: threadsTrackerIsVisible } =
    useInView();

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  // search states
  const [searchLoading, setSearchLoading] = useState(true);
  const [searchError, setSearchError] = useState(null);
  const [searchVideos, setSearchVideos] = useState([]);
  const [searchMoments, setSearchMoments] = useState([]);
  const [searchSeries, setSearchSeries] = useState([]);
  const [searchThreads, setSearchThreads] = useState([]);

  console.log(searchVideos, "lets see");

  // pagination states
  const [videosPage, setVideosPage] = useState(1);
  const [videosLimit, setVideosLimit] = useState(4);
  const [momentsPage, setMomentsPage] = useState(1);
  const [momentsLimit, setMomentsLimit] = useState(4);
  const [seriesPage, setSeriesPage] = useState(1);
  const [seriesLimit, setSeriesLimit] = useState(4);
  const [threadsPage, setThreadsPage] = useState(1);
  const [threadsLimit, setThreadsLimit] = useState(4);
  const [hasMoreVideos, setHasMoreVideos] = useState(false);
  const [hasMoreMoments, setHasMoreMoments] = useState(false);
  const [hasMoreSeries, setHasMoreSeries] = useState(false);
  const [hasMoreThreads, setHasMoreThreads] = useState(false);
  const [showVideosLoading, setShowVideosLoading] = useState(false);
  const [showMomentsLoading, setShowMomentsLoading] = useState(false);
  const [showSeriesLoading, setShowSeriesLoading] = useState(false);
  const [showThreadsLoading, setShowThreadsLoading] = useState(false);

  // filter states
  const [filterContentType, setFilterContentType] = useState(null);
  const [showVideoFilter, setShowVideoFilter] = useState(false);
  const [filterDate, setFilterDate] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [showAppliedFilters, setShowAppliedFilters] = useState(false);

  // useEffect(() => {
  //   if (filterContentType && filterContentType === "video") {
  //     setShowVideoFilter(true);
  //   } else {
  //     setShowVideoFilter(false);
  //   }
  // }, [filterContentType]);

  useEffect(() => {
    if (filterContentType || filterDate) {
      setVideosPage(1);
      setMomentsPage(1);
      setSeriesPage(1);
      setMomentsPage(1);
    }
  }, [filterContentType, filterDate]);

  // load more clips
  useEffect(() => {
    if (
      hasMoreVideos &&
      clipsTrackerIsVisible &&
      !showVideosLoading &&
      filterContentType === "video"
    ) {
      loadMoreVideosHandler();
    }
  }, [clipsTrackerIsVisible, hasMoreVideos, showVideosLoading]);

  // load more moments
  useEffect(() => {
    if (
      hasMoreMoments &&
      momentsTrackerIsVisible &&
      !showMomentsLoading &&
      filterContentType === "moment"
    ) {
      loadMoreMomentsHandler();
    }
  }, [momentsTrackerIsVisible, hasMoreMoments, showMomentsLoading]);

  // load more series
  useEffect(() => {
    if (
      hasMoreSeries &&
      seriesTrackerIsVisible &&
      !showSeriesLoading &&
      filterContentType === "series"
    ) {
      loadMoreSeriesHandler();
    }
  }, [seriesTrackerIsVisible, hasMoreSeries, showSeriesLoading]);

  // load more threads
  useEffect(() => {
    if (
      hasMoreThreads &&
      threadsTrackerIsVisible &&
      !showThreadsLoading &&
      filterContentType === "thread"
    ) {
      loadMoreThreadsHandler();
    }
  }, [threadsTrackerIsVisible, hasMoreThreads, showThreadsLoading]);

  useEffect(() => {
    document.addEventListener("keydown", skeywordHandler, false);

    return () => {
      document.removeEventListener("keydown", skeywordHandler, false);
    };
  }, []);

  useEffect(() => {
    if (params.searchterm) {
      searchHandler(params.searchterm);
    }
  }, [params.searchterm]);

  const searchHandler = async (
    searchText,
    filterContentType,
    filterDate,
    cVideosPage,
    cMomentsPage,
    cSeriesPage,
    cThreadsPage
  ) => {
    setSearchLoading(true);
    setSearchVideos([]);
    setSearchMoments([]);
    setSearchSeries([]);
    setSearchThreads([]);
    setSearchError(null);
    try {
      const obj = {
        searchterm: searchText,
        videosPage: cVideosPage || videosPage,
        videosLimit,
        momentsPage: cMomentsPage || momentsPage,
        momentsLimit,
        seriesPage: cSeriesPage || seriesPage,
        seriesLimit,
        threadsPage: cThreadsPage || threadsPage,
        threadsLimit,

        // filter part
        contentType: filterContentType,
        filterDate,
      };

      let query = null;
      if (!user?.id) {
        query = `users/search-content`;
      } else {
        query = `users/search-content?loggedInUserId=${user?.id}`;
      }

      const res = await api.post(query, obj);

      if (res.data) {
        setSearchLoading(false);
        setSearchVideos(res.data.data.videos || []);
        setSearchMoments(res.data.data.moments || []);
        setSearchSeries(res.data.data.series || []);
        setSearchThreads(res.data.data.threads || []);

        setHasMoreVideos(res.data.data.hasVideos);
        setHasMoreMoments(res.data.data.hasMoments);
        setHasMoreSeries(res.data.data.hasSeries);
        setHasMoreThreads(res.data.data.hasThreads);
      }
    } catch (error) {
      if (error) {
        setSearchLoading(false);
        setSearchError("No content found! Change your search text.");
      }
    }
  };

  const skeywordHandler = useCallback((event) => {
    if (event.keyCode === 83) {
      setShowSearchModel(true);
    }
  });

  const loadMoreVideosHandler = async () => {
    setShowVideosLoading(true);
    setVideosPage(videosPage + 1);
    try {
      const obj = {
        searchterm: params.searchterm,
        videosPage: videosPage + 1,
        videosLimit,
      };

      const res = await api.post(`users/search-videos`, obj);

      if (res.data) {
        setSearchVideos([...searchVideos, ...res.data.data.videos]);
        setHasMoreVideos(res.data.data.hasVideos);
        setShowVideosLoading(false);
      }
    } catch (error) {
      if (error) {
        setShowVideosLoading(false);
      }
    }
  };

  const loadMoreMomentsHandler = async () => {
    setShowMomentsLoading(true);
    setMomentsPage(momentsPage + 1);
    try {
      const obj = {
        searchterm: params.searchterm,
        momentsPage: momentsPage + 1,
        momentsLimit,
      };

      const res = await api.post(`users/search-moments`, obj);

      if (res.data) {
        setSearchMoments([...searchMoments, ...res.data.data.moments]);
        setHasMoreMoments(res.data.data.hasMoments);
        setShowMomentsLoading(false);
      }
    } catch (error) {
      if (error) {
        setShowMomentsLoading(false);
      }
    }
  };

  const loadMoreSeriesHandler = async () => {
    setShowSeriesLoading(true);
    setSeriesPage(seriesPage + 1);
    try {
      const obj = {
        searchterm: params.searchterm,
        seriesPage: seriesPage + 1,
        seriesLimit,
      };

      const res = await api.post(`users/search-series`, obj);

      if (res.data) {
        setSearchSeries([...searchSeries, ...res.data.data.series]);
        setHasMoreSeries(res.data.data.hasSeries);
        setShowSeriesLoading(false);
      }
    } catch (error) {
      if (error) {
        setShowSeriesLoading(false);
      }
    }
  };

  const loadMoreThreadsHandler = async () => {
    setShowThreadsLoading(true);
    setThreadsPage(threadsPage + 1);
    try {
      const obj = {
        searchterm: params.searchterm,
        threadsPage: threadsPage + 1,
        threadsLimit,
      };

      let query = null;
      if (!user?.id) {
        query = `users/search-threads`;
      } else {
        query = `users/search-threads?loggedInUserId=${user?.id}`;
      }

      const res = await api.post(query, obj);

      if (res.data) {
        setSearchThreads([...searchThreads, ...res.data.data.threads]);
        setHasMoreThreads(res.data.data.hasThreads);
        setShowThreadsLoading(false);
      }
    } catch (error) {
      if (error) {
        setShowThreadsLoading(false);
      }
    }
  };

  return (
    <SearchResultsStyled>
      <div className="filters">
        <div className="filter__header">
          <div
            onClick={() => setFilterExpanded(!filterExpanded)}
            className="filters__toggler"
          >
            <img src={FiltersIcon} alt="" className="icon" />
            <span>Filters</span>
          </div>

          <div
            onClick={() => setShowSearchModel(true)}
            className="filters__searchAgain"
          >
            <img src={RetryIcon} alt="" className="icon" />
            <span>Search Again</span>
          </div>

          <div className="infoText">Tip: Enter S to open search modal.</div>
        </div>

        {filterExpanded && (
          <div className={`filters__expanded`}>
            <Row>
              <Col
                lg={4}
                md={6}
                sm={12}
                className="filters__expanded--item"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <div className="filters__expanded--item-box">
                  <div className="filterTitle">Type</div>

                  <div
                    onClick={() => {
                      setFilterContentType("video");
                    }}
                    className={`item ${
                      filterContentType === "video" ? "active" : ""
                    }`}
                  >
                    {filterContentType === "video" && (
                      <img src={CloseIcon} alt="" className="icon" />
                    )}
                    Videos
                  </div>
                  <div
                    onClick={() => {
                      setFilterContentType("moment");
                    }}
                    className={`item ${
                      filterContentType === "moment" ? "active" : ""
                    }`}
                  >
                    {filterContentType === "moment" && (
                      <img src={CloseIcon} alt="" className="icon" />
                    )}
                    Moment
                  </div>
                  <div
                    onClick={() => {
                      setFilterContentType("series");
                    }}
                    className={`item ${
                      filterContentType === "series" ? "active" : ""
                    }`}
                  >
                    {filterContentType === "series" && (
                      <img src={CloseIcon} alt="" className="icon" />
                    )}
                    Series
                  </div>
                  <div
                    onClick={() => {
                      setFilterContentType("thread");
                    }}
                    className={`item ${
                      filterContentType === "thread" ? "active" : ""
                    }`}
                  >
                    {filterContentType === "thread" && (
                      <img src={CloseIcon} alt="" className="icon" />
                    )}
                    Threads
                  </div>
                </div>
              </Col>

              {showVideoFilter && (
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  className="filters__expanded--item"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <div className="filters__expanded--item-box">
                    <div className="filterTitle">Sort By</div>

                    <div
                      onClick={() => setSortBy("views")}
                      className={`item ${sortBy === "views" ? "active" : ""}`}
                    >
                      {sortBy === "views" && (
                        <img src={CloseIcon} alt="" className="icon" />
                      )}
                      View Count
                    </div>
                    <div
                      onClick={() => setSortBy("dateDESC")}
                      className={`item ${
                        sortBy === "dateDESC" ? "active" : ""
                      }`}
                    >
                      {sortBy === "dateDESC" && (
                        <img src={CloseIcon} alt="" className="icon" />
                      )}
                      Upload Date
                    </div>
                  </div>
                </Col>
              )}

              <Col
                lg={4}
                md={6}
                sm={12}
                className="filters__expanded--item"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <div className="filters__expanded--item-box">
                  <div className="filterTitle">Upload Date</div>

                  <div
                    onClick={() => {
                      setFilterDate(1);
                    }}
                    className={`item ${filterDate === 1 ? "active" : ""}`}
                  >
                    {filterDate === 1 && (
                      <img src={CloseIcon} alt="" className="icon" />
                    )}
                    Today
                  </div>
                  <div
                    onClick={() => {
                      setFilterDate(7);
                    }}
                    className={`item ${filterDate === 7 ? "active" : ""}`}
                  >
                    {filterDate === 7 && (
                      <img src={CloseIcon} alt="" className="icon" />
                    )}
                    This Week
                  </div>
                  <div
                    onClick={() => {
                      setFilterDate(30);
                    }}
                    className={`item ${filterDate === 30 ? "active" : ""}`}
                  >
                    {filterDate === 30 && (
                      <img src={CloseIcon} alt="" className="icon" />
                    )}
                    This Month
                  </div>
                  <div
                    onClick={() => {
                      setFilterDate(365);
                    }}
                    className={`item ${filterDate === 365 ? "active" : ""}`}
                  >
                    {filterDate === 365 && (
                      <img src={CloseIcon} alt="" className="icon" />
                    )}
                    This Year
                  </div>
                </div>
              </Col>
            </Row>

            {filterContentType || filterDate ? (
              <Button
                onClick={() => {
                  setFilterExpanded(false);
                  setShowAppliedFilters(true);
                  searchHandler(
                    params.searchterm,
                    filterContentType,
                    filterDate,
                    1,
                    1,
                    1,
                    1
                  );
                }}
                mode="primary"
                size="small"
                variant="round"
                className="filters__expanded--apply"
              >
                Apply
              </Button>
            ) : null}
          </div>
        )}
      </div>

      {showAppliedFilters && (filterContentType || filterDate) ? (
        <div className="appliedFilters">
          <div className="appliedFilters__title">Applied filters:</div>
          <div className="appliedFilters__list">
            {filterContentType && (
              <div className="appliedFilters__list--item">
                Content Type: {filterContentType}
              </div>
            )}
            {filterDate && (
              <div className="appliedFilters__list--item">
                Date:{" "}
                {filterDate === 1
                  ? "Today"
                  : filterDate === 7
                  ? "This Week"
                  : filterDate === 30
                  ? "This Month"
                  : filterDate === 365
                  ? "This Year"
                  : null}
              </div>
            )}

            <div
              onClick={() => {
                setFilterContentType(null);
                setFilterDate(null);
                searchHandler(params.searchterm, null, null);
              }}
              className="appliedFilters__list--clear"
            >
              clear all
            </div>
          </div>
        </div>
      ) : null}

      <div className="results">
        <div className="title">
          Showing search results for:
          <div className="title__focus">{params.searchterm}</div>
        </div>

        {searchLoading && !searchError && (
          <Loader text="Please wait while we fetch content for you." />
        )}

        {!searchVideos?.length &&
          !searchMoments?.length &&
          !searchSeries?.length &&
          !searchThreads?.length &&
          !searchLoading &&
          !searchError && (
            <Error text={`No results found for ${params.searchterm}`} />
          )}

        {!searchLoading && searchError && (
          <Error text={`No results found for ${params.searchterm}`} />
        )}

        {!searchLoading &&
          !searchError &&
          searchVideos &&
          searchVideos.length > 0 && (
            <>
              <Row className="results__row">
                <Title buttonClassName="sectionButton" className="sectionTitle">
                  Videos
                </Title>

                {searchVideos.map((item, i) => (
                  <Col
                    key={i}
                    lg={3}
                    md={6}
                    sm={12}
                    className="results__col"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <VideoCard key={i} item={item} menuOptions={VIDEO_OTHER} />
                  </Col>
                ))}

                {filterContentType !== "video" &&
                  !showVideosLoading &&
                  hasMoreVideos && (
                    <Button
                      className="loadMoreButton"
                      onClick={loadMoreVideosHandler}
                      iconLeft
                      width="fluid"
                      variant="box"
                      mode="tertiary"
                      size="medium"
                    >
                      <img src={ChevronDownIcon} alt="" />
                      Load more content
                    </Button>
                  )}

                {showVideosLoading && (
                  <VideosSkeleton
                    numItems={4}
                    noTitle
                    fullWidth
                    sectionName="videos"
                  />
                )}

                {!hasMoreVideos && (
                  <div className="noMore">
                    You have reached the end of video results.
                  </div>
                )}

                {filterContentType === "video" && (
                  <div ref={clipsTrackerRef} className="tracker" />
                )}
              </Row>
            </>
          )}

        {!searchLoading &&
          !searchError &&
          searchMoments &&
          searchMoments.length > 0 && (
            <Row className="results__row">
              <Title buttonClassName="sectionButton" className="sectionTitle">
                Moments
              </Title>

              {searchMoments.map((item, i) => (
                <Col
                  key={i}
                  lg={3}
                  md={3}
                  sm={4}
                  xs={4}
                  className="results__col"
                  style={{
                    paddingLeft: isMobile ? "4px" : "10px",
                    paddingRight: isMobile ? "4px" : "10px",
                  }}
                >
                  <MomentCard key={i} item={item} menuOptions={MOMENTS_OTHER} />
                </Col>
              ))}

              {filterContentType !== "moment" &&
                !showMomentsLoading &&
                hasMoreMoments && (
                  <Button
                    className="loadMoreButton"
                    onClick={loadMoreMomentsHandler}
                    iconLeft
                    width="fluid"
                    variant="box"
                    mode="tertiary"
                    size="medium"
                  >
                    <img src={ChevronDownIcon} alt="" />
                    Load more moments
                  </Button>
                )}

              {showMomentsLoading && (
                <MomentsSkeleton
                  sectionName="featured_moments"
                  noTitle
                  numItems={4}
                  fullWidth
                />
              )}

              {!hasMoreMoments && (
                <div className="noMore">
                  You have reached the end of video results
                </div>
              )}

              {filterContentType === "moment" && (
                <div ref={momentsTrackerRef} className="tracker" />
              )}
            </Row>
          )}

        {!searchLoading &&
          !searchError &&
          searchSeries &&
          searchSeries.length > 0 && (
            <Row className="results__row">
              <Title buttonClassName="sectionButton" className="sectionTitle">
                Series
              </Title>

              {searchSeries.map((item, i) => (
                <Col
                  key={i}
                  lg={3}
                  md={6}
                  sm={12}
                  className="results__col"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <SeriesCard key={i} item={item} menuOptions={SERIES_OTHER} />
                </Col>
              ))}

              {filterContentType !== "series" &&
                !showSeriesLoading &&
                hasMoreSeries && (
                  <Button
                    className="loadMoreButton"
                    onClick={loadMoreSeriesHandler}
                    iconLeft
                    width="fluid"
                    variant="box"
                    mode="tertiary"
                    size="medium"
                  >
                    <img src={ChevronDownIcon} alt="" />
                    Load more series
                  </Button>
                )}

              {showSeriesLoading && (
                <SeriesSkeleton
                  fullWidth
                  noTitle
                  numItems={4}
                  sectionName="videos"
                />
              )}

              {!hasMoreSeries && (
                <div className="noMore">
                  You have reached the end of video results
                </div>
              )}

              {filterContentType === "series" && (
                <div ref={seriesTrackerRef} className="tracker" />
              )}
            </Row>
          )}

        {!searchLoading &&
          !searchError &&
          searchThreads &&
          searchThreads.length > 0 && (
            <>
              <Row className="results__row">
                <Title buttonClassName="sectionButton" className="sectionTitle">
                  threads
                </Title>

                {searchThreads.map((item, i) => (
                  <Col
                    key={i}
                    lg={4}
                    md={6}
                    sm={12}
                    className="results__col"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <ThreadCardSmall
                      key={i}
                      item={item}
                      type={item?.attachedType}
                    />
                  </Col>
                ))}

                {filterContentType !== "thread" &&
                  !showThreadsLoading &&
                  hasMoreThreads && (
                    <Button
                      className="loadMoreButton"
                      onClick={loadMoreThreadsHandler}
                      iconLeft
                      width="fluid"
                      variant="box"
                      mode="tertiary"
                      size="medium"
                    >
                      <img src={ChevronDownIcon} alt="" />
                      Load more threads
                    </Button>
                  )}

                {showThreadsLoading && (
                  <VideosSkeleton
                    numItems={4}
                    noTitle
                    fullWidth
                    sectionName="videos"
                  />
                )}

                {!hasMoreThreads && (
                  <div className="noMore">
                    You have reached the end of threads results.
                  </div>
                )}

                {filterContentType === "thread" && (
                  <div ref={threadsTrackerRef} className="tracker" />
                )}
              </Row>
            </>
          )}
      </div>
    </SearchResultsStyled>
  );
};

export default NewSearchResults;

const FilterAnimation = keyframes`
  from {
    transform: translateY(-150%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const backgroundPan = keyframes`
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
`;

const SearchResultsStyled = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 2rem 0 7rem 0;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  .tracker {
    width: 100%;
    height: 5rem;
    background: transparent;
    opacity: 0;
    margin-top: 5rem;
  }

  .filters {
    margin-bottom: 0.5rem;

    &__header {
      display: flex;
      align-items: center;
    }

    &__toggler,
    &__searchAgain {
      display: inline-flex;
      align-items: center;
      padding: 0.4rem 2rem;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 5rem;
      cursor: pointer;
      font-size: 0.8rem;
      text-transform: capitalize;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      font-weight: 400;

      .icon {
        height: 0.9rem;
        width: auto;
        margin-right: 0.7rem;
      }
    }

    &__searchAgain {
      margin-left: 1rem;
    }

    &__expanded {
      background-color: #202020;
      padding: 1rem 0.5rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      transition: all 0.3s ease;
      animation: ${FilterAnimation} 0.5s ease;

      &--apply {
        margin-top: 1.25rem;
        font-size: 0.72rem;
        padding: 0.35rem 1.2rem;
      }

      &--item {
        padding: 0 1rem;

        &-box {
          width: 90%;
          margin: 0 auto;

          .filterTitle {
            text-transform: uppercase;
            font-size: 0.75rem;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.6);
            margin-bottom: 1rem;
          }

          .item {
            text-transform: capitalize;
            font-size: 0.725rem;
            font-weight: 400;
            cursor: pointer;
            color: rgba(255, 255, 255, 0.6);

            &:not(:last-child) {
              margin-bottom: 0.15rem;
            }
          }

          .active {
            color: #fff;

            .icon {
              height: 0.6rem;
              width: auto;
              padding: 0 0.5rem;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }

  .appliedFilters {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-family: brother-1816, sans-serif;

    &__title {
      font-size: 0.7rem;
      text-transform: uppercase;
      font-weight: 600;
      background: linear-gradient(
        to right,
        ${(props) => props.theme.primaryColor1},
        ${(props) => props.theme.primaryColor2},
        ${(props) => props.theme.primaryColor1}
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: ${backgroundPan} 5s linear infinite;
      background-size: 200%;
      margin-right: 0.5rem;
    }

    &__list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 0.25rem;
      row-gap: 0.5rem;

      &--item {
        font-size: 0.5rem;
        padding: 0.3rem 0.7rem;
        border-radius: 10rem;
        background-color: #202020;
        border: 1px solid rgba(255, 255, 255, 0.09);
        font-weight: 500;
        color: rgba(255, 255, 255, 0.65);
        text-transform: uppercase;
        line-height: 1;
        letter-spacing: 0.3px;
        transition: all 0.5s ease;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          color: #fff;
          background: ${(props) => props.theme.gradient};
        }
      }

      &--clear {
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 600;
        text-decoration: underline;
        color: rgba(255, 255, 255, 0.6);
        margin-left: 0.5rem;
        cursor: pointer;
      }
    }
  }

  .results {
    &__row {
      margin-bottom: 5rem;
    }
  }

  .actionButton {
    margin-bottom: 3rem;
  }

  .sectionTitle {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
  }

  .sectionButton {
    font-size: 0.76rem !important;
    padding: 0.5rem 1rem !important;
  }

  .noMore {
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 0.85rem;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
    margin-top: 1rem;
  }

  .title {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    font-weight: 500;

    &__focus {
      background: -webkit-${(props) => props.theme.primaryGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding: 0 0.5rem;
    }
  }

  .infoText {
    font-weight: 400;
    font-size: 0.7rem;
    color: rgba(255, 255, 255, 0.6);
    padding-top: 5px;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  @media screen and (max-width: 480px) {
    .filters {
      &__toggler,
      &__searchAgain {
        padding: 0.3rem 1rem;
        font-size: 0.75rem;

        .icon {
          margin-right: 0.5rem;
        }
      }

      &__searchAgain {
        margin-left: 0.5rem;
      }
    }

    .sectionTitle {
      font-size: 0.95rem;
    }

    .sectionButton {
      font-size: 0.7rem !important;
      padding: 0.4rem 1rem !important;
    }

    .loadMoreButton {
      font-size: 0.72rem !important;
      padding: 0.6rem 0 !important;

      img {
        height: 0.5rem !important;
      }
    }

    .actionButton {
      font-size: 0.72rem !important;
      padding: 0.6rem 0 !important;

      img {
        height: 0.9rem !important;
      }
    }
  }
`;
