import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import InputV2 from "../../components/Elements/InputV2";
import FileUpload from "../../components/Elements/FileUpload";
import Button from "../../components/Elements/Button";
import config from "../../config";
import { MarketingPageStyles } from "./Styles";
import { FormikProvider, useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addMarketingWhyusSection } from "../../actions/marketing";
import { RESET_MARKETING_PAGE_STATE } from "../../actions/marketing/type";

const Schema = Yup.object().shape({
  title1: Yup.string().required("Title is required."),
  text1: Yup.string().required("Text is required."),
  title2: Yup.string().required("Title is required."),
  text2: Yup.string().required("Text is required."),
  title3: Yup.string().required("Title is required."),
  text3: Yup.string().required("Text is required."),
});

const WhyusForm = () => {
  const dispatch = useDispatch();
  const {
    data: marketingPageData,
    saveLoading,
    saveSuccess,
    saveError,
  } = useSelector((state) => state.marketingPage);
  const [icon1, setIcon1] = useState(null);
  const [icon2, setIcon2] = useState(null);
  const [icon3, setIcon3] = useState(null);

  const formik = useFormik({
    initialValues: {
      title1: "",
      text1: "",
      title2: "",
      text2: "",
      title3: "",
      text3: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      await saveFormHandler(values);
    },
  });

  useEffect(() => {
    if (saveSuccess) {
      toast.success("Content updated successfully.", {
        toastId: "whyusSuccess",
      });
    }

    if (saveError) {
      toast.success("Something went worng. Please try again!", {
        toastId: "whyusError",
      });
    }

    dispatch({
      type: RESET_MARKETING_PAGE_STATE,
    });
  }, [saveSuccess, saveError]);

  useEffect(() => {
    if (marketingPageData) {
      const whyusData = marketingPageData?.["why choose us?"];

      if (whyusData?.length > 0) {
        const whyus1 = whyusData[0];
        const whyus2 = whyusData[1];
        const whyus3 = whyusData[2];

        formik.setValues({
          title1: whyus1.title,
          text1: whyus1.text,
          title2: whyus2.title,
          text2: whyus2.text,
          title3: whyus3.title,
          text3: whyus3.text,
        });

        setIcon1({
          filePreview: false,
          previewUrl: whyus1.icon,
        });

        setIcon2({
          filePreview: false,
          previewUrl: whyus2.icon,
        });

        setIcon3({
          filePreview: false,
          previewUrl: whyus3.icon,
        });
      }
    }
  }, [marketingPageData]);

  const handleIconUpload = async (e, target) => {
    const file = e.target.files[0];

    if (file) {
      const previewUrl = URL.createObjectURL(file);

      if (target === "first") {
        setIcon1({
          previewUrl,
          file,
          filePreview: true,
        });
      }

      if (target === "second") {
        setIcon2({
          previewUrl,
          file,
          filePreview: true,
        });
      }

      if (target === "third") {
        setIcon3({
          previewUrl,
          file,
          filePreview: true,
        });
      }

      return;
    }
  };

  const saveFormHandler = async (values) => {
    if (saveLoading) {
      return;
    }

    try {
      const whyusData = marketingPageData?.["why choose us?"];
      if (whyusData?.length) {
        const why1 = whyusData[0];
        const why2 = whyusData[1];
        const why3 = whyusData[2];

        const objArray = [
          {
            id: why1?.id,
            file: icon1?.file || null,
            title: values.title1,
            text: values.text1,
          },

          {
            id: why2?.id,
            file: icon2?.file || null,
            title: values.title2,
            text: values.text2,
          },

          {
            id: why3?.id,
            file: icon3?.file || null,
            title: values.title3,
            text: values.text3,
          },
        ];

        const promises = objArray.map((item) => {
          dispatch(addMarketingWhyusSection(item));
        });
        await Promise.all(promises);
      } else {
        // it means add all three of them
        if (!icon1?.file || !icon2?.file || !icon3?.file) {
          toast.error("Please provide icons as well.");
          return;
        }

        const objArray = [
          {
            id: null,
            file: icon1?.file || null,
            title: values.title1,
            text: values.text1,
          },
          {
            id: null,
            file: icon1?.file || null,
            title: values.title2,
            text: values.text2,
          },
          {
            id: null,
            file: icon1?.file || null,
            title: values.title3,
            text: values.text3,
          },
        ];
        const promises = objArray.map((item) => {
          dispatch(addMarketingWhyusSection(item));
        });
        await Promise.all(promises);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!");
    }
  };

  return (
    <FormikProvider value={formik}>
      <MarketingPageStyles>
        <div className="form">
          <div className="form__whyus">
            {/* section 1 */}
            <div className="form__whyus--section form__whyus--section-1">
              <div className="form__whyus--section-title">section one</div>

              <div className="form__item">
                <FileUpload
                  id={"iconFirst"}
                  title={"Icon"}
                  explain={"Upload icon for the section"}
                  onChange={(e) => handleIconUpload(e, "first")}
                  display={{
                    url: icon1?.previewUrl,
                    filePreview: icon1?.filePreview,
                    noBg: false,
                    width: "50%",
                  }}
                />
              </div>

              <div className="form__item">
                <InputV2
                  name="title1"
                  type={"text"}
                  placeholder={"Add Title"}
                  id={"title1"}
                  label={"Title"}
                  value={formik.values.title1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={formik.touched.title1 && formik.errors.title1}
                />
              </div>

              <div className="form__item">
                <InputV2
                  name="text1"
                  type={"text"}
                  placeholder={"Add Text"}
                  id={"text1"}
                  label={"Text"}
                  value={formik.values.text1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={formik.touched.text1 && formik.errors.text1}
                />
              </div>
            </div>

            {/* section 2 */}
            <div className="form__whyus--section form__whyus--section-2">
              <div className="form__whyus--section-title">section two</div>

              <div className="form__item">
                <FileUpload
                  id={"iconSecond"}
                  title={"Icon"}
                  explain={"Upload icon for the section"}
                  onChange={(e) => handleIconUpload(e, "second")}
                  display={{
                    url: icon2?.previewUrl,
                    filePreview: icon2?.filePreview,
                    noBg: false,
                    width: "50%",
                  }}
                />
              </div>

              <div className="form__item">
                <InputV2
                  name="title2"
                  type={"text"}
                  placeholder={"Add Title"}
                  id={"title2"}
                  label={"Title"}
                  value={formik.values.title2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={formik.touched.title2 && formik.errors.title2}
                />
              </div>

              <div className="form__item">
                <InputV2
                  name="text2"
                  type={"text"}
                  placeholder={"Add Text"}
                  id={"text2"}
                  label={"Text"}
                  value={formik.values.text2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={formik.touched.text2 && formik.errors.text2}
                />
              </div>
            </div>

            {/* section 3 */}
            <div className="form__whyus--section form__whyus--section-1">
              <div className="form__whyus--section-title">section three</div>

              <div className="form__item">
                <FileUpload
                  id={"iconThird"}
                  title={"Icon"}
                  explain={"Upload icon for the section"}
                  onChange={(e) => handleIconUpload(e, "third")}
                  display={{
                    url: icon3?.previewUrl,
                    filePreview: icon3?.filePreview,
                    noBg: false,
                    width: "50%",
                  }}
                />
              </div>

              <div className="form__item">
                <InputV2
                  name="title3"
                  type={"text"}
                  placeholder={"Add Title"}
                  id={"title3"}
                  label={"Title"}
                  value={formik.values.title3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={formik.touched.title3 && formik.errors.title3}
                />
              </div>

              <div className="form__item">
                <InputV2
                  name="text3"
                  type={"text"}
                  placeholder={"Add Text"}
                  id={"text3"}
                  label={"Text"}
                  value={formik.values.text3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={formik.touched.text3 && formik.errors.text3}
                />
              </div>
            </div>

            <div className="form__whyus--section">
              <Button
                onClick={() => formik.handleSubmit()}
                className="form__whyus--btn"
                mode="primary"
                width="fluid"
                variant="round"
              >
                {saveLoading ? "Please wait..." : "Save Changes"}
              </Button>
            </div>
          </div>
        </div>
      </MarketingPageStyles>
    </FormikProvider>
  );
};

export default WhyusForm;
