import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../components/LandingPageComponents/Footer";
import Hero from "../components/LandingPageComponents/Hero";
import ScrollToTop from "../components/LandingPageComponents/ScrollToTop";
import AboutUs from "../components/LandingPageComponents/Sections/AboutUs";
import Creators from "../components/LandingPageComponents/Sections/Creators";
import HowItWorks from "../components/LandingPageComponents/Sections/HowItWorks";
import Membership from "../components/LandingPageComponents/Sections/Membership";
import Qna from "../components/LandingPageComponents/Sections/Qna";
import WelcomeScreen from "../components/WelcomeScreen";
import Stats from "../components/LandingPageComponents/Sections/Stats";
import { useDispatch, useSelector } from "react-redux";
import { getMarketingPage } from "../actions/marketing";
import { getHomeFeaturedCreators } from "../actions";
import { AnimatePresence } from "framer-motion";

const MarketingPage = () => {
  const dispatch = useDispatch();
  const {
    data: marketingPageData,
    loading: marketingPageLoading,
    order: marketingPageOrder,
  } = useSelector((state) => state.marketingPage);
  const { data: featuredCreators, loading: featuredCreatorsLoading } =
    useSelector((state) => state.featuredCreators);

  useEffect(() => {
    if (!marketingPageData) {
      dispatch(getMarketingPage());
    }
    if (!featuredCreators?.length) {
      dispatch(getHomeFeaturedCreators());
    }
  }, []);

  return (
    <>
      <AnimatePresence
        initial={true}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {(marketingPageLoading || featuredCreatorsLoading) && <WelcomeScreen />}
      </AnimatePresence>

      {!marketingPageLoading && !featuredCreatorsLoading && (
        <>
          <MarketingPageStyled>
            <Hero />
            {marketingPageOrder?.length ? (
              <>
                {marketingPageOrder.map((item, i) => {
                  if (item?.section_name === "about us") {
                    return (
                      <AboutUs data={marketingPageData["about us"]?.[0]} />
                    );
                  }

                  if (item?.section_name === "stats") {
                    return <Stats data={marketingPageData["stats"]} />;
                  }

                  if (item?.section_name === "pricing") {
                    return <Membership data={marketingPageData["pricing"]} />;
                  }

                  if (item?.section_name === "faq") {
                    return <Qna data={marketingPageData["faq"]} />;
                  }

                  if (item?.section_name === "why choose us?") {
                    return (
                      <HowItWorks data={marketingPageData["why choose us?"]} />
                    );
                  }

                  if (item?.section_name === "creators") {
                    return <Creators data={featuredCreators} />;
                  }
                })}
              </>
            ) : (
              <>
                <AboutUs data={marketingPageData["about us"]?.[0]} />
                <HowItWorks data={marketingPageData["why choose us?"]} />
                <Creators data={featuredCreators} />
                <Stats data={marketingPageData["stats"]} />
                <Membership data={marketingPageData["pricing"]} />
                <Qna data={marketingPageData["faq"]} />
              </>
            )}

            <Footer data={marketingPageData["footer"]?.[0]} />
          </MarketingPageStyled>
        </>
      )}

      <ScrollToTop />
    </>
  );
};

export default MarketingPage;

const MarketingPageStyled = styled.div`
  font-family: ${(props) => props.theme.montserrat}, sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  background-color: #1d1d1f;
`;
