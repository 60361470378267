import React, { useEffect } from "react";
import styled from "styled-components";
import Input from "../../components/Elements/Input";
import Button from "../../components/Elements/Button";
import { Row, Col } from "react-grid-system";
import { adminAllMomentsData, updateAdminMoment } from "../../actions";
import { useDispatch } from "react-redux";

const MomentEditTable = ({ isOpen, onClose, momentFormData, onChange }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminAllMomentsData());
  }, [dispatch]);

  const handleUpdateAdminUser = async () => {
    try {
      await dispatch(updateAdminMoment(momentFormData));
      await dispatch(adminAllMomentsData());
      onClose();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  return (
    <UserEditModal open={isOpen}>
      <MomentEditContent>
        <div className="title">Edit Moment Table</div>
        <div className="field">
          <Row className="row row__one">
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Caption"
                  name="caption"
                  id="caption"
                  placeholder="caption"
                  type="text"
                  value={momentFormData.caption}
                  onChange={(e) => onChange("caption", e.target.value)}
                />
              </div>
            </Col>

            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="userId"
                  name="userId"
                  id="userId"
                  placeholder="userId"
                  type="text"
                  value={momentFormData.userId}
                  onChange={(e) => onChange("userId", e.target.value)}
                />
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="muxid"
                  name="muxid"
                  id="muxid"
                  placeholder="muxid"
                  type="text"
                  value={momentFormData.muxid}
                  onChange={(e) => onChange("muxid", e.target.value)}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="createdAt"
                  name="createdAt"
                  id="createdAt"
                  placeholder="createdAt"
                  type="text"
                  value={momentFormData.createdAt}
                  onChange={(e) => onChange("createdAt", e.target.value)}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="updatedAt"
                  name="updatedAt"
                  id="updatedAt"
                  placeholder="updatedAt"
                  type="text"
                  value={momentFormData.updatedAt}
                  onChange={(e) => onChange("updatedAt", e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Row className="row row__one">
          <Col md={6} sm={6} xs={6}>
            <Button mode={"secondary"} className="submit" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <Button
              mode={"primary"}
              className="submit"
              onClick={handleUpdateAdminUser}
            >
              Save
            </Button>
          </Col>
        </Row>
      </MomentEditContent>
    </UserEditModal>
  );
};

export default MomentEditTable;

const UserEditModal = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MomentEditContent = styled.div`
  background: #2c2c2e;
  color: white;
  border: 1px solid white;
  padding: 20px;
  max-width: 800px;
  width: 100%;

  .title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .submit {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdownBox {
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    margin-top: 3px;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;
