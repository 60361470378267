import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { isBadgeOrAvatar } from "../../utils";

const WarriorCard = ({ item }) => {
  const username = item?.User?.username;

  const avatar = item?.User?.avatar
    ? item.User.avatar
    : item?.User?.badge
    ? item.User.badge
    : null;

  return (
    <WarriorCardStyled>
      <Link to={`/channel/${username}`}>
        <div className="avatar">{isBadgeOrAvatar(avatar)}</div>

        <div className="name">
          {username.length > 10 ? username.substring(0, 10) + "..." : username}
        </div>
      </Link>
    </WarriorCardStyled>
  );
};

export default WarriorCard;

const WarriorCardStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .avatar {
    cursor: pointer;
    height: 5.5rem;
    width: 5.5rem;
    border-radius: 50%;
    position: relative;
    margin-bottom: 0.5rem;

    .imageBadge {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .badge {
        height: 5rem;
        width: auto;
      }
    }

    .imageAvatar {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .name {
    text-align: center;
    font-family: ${(props) => props.theme.montserrat};
    font-size: 1rem;
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    .avatar {
      height: 5rem;
      width: 5rem;

      .imageBadge {
        .badge {
          height: 4.5rem;
        }
      }
    }

    .name {
      font-size: 0.95rem;
    }
  }

  @media screen and (max-width: 480px) {
    .avatar {
      height: 4rem;
      width: 4rem;

      .imageBadge {
        .badge {
          height: 3.5rem;
        }
      }
    }

    .name {
      font-size: 0.85rem;
    }
  }

  @media screen and (max-width: 390px) {
    .avatar {
      height: 3.5rem;
      width: 3.5rem;

      .imageBadge {
        .badge {
          height: 3rem;
        }
      }
    }

    .name {
      font-size: 0.7rem;
    }
  }
`;
