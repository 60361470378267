import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  postion:relative;
    .selectedFilters {
      border: 1px solid #77889952;
      position: absolute;
      z-index: 4;
      background: #202020;
      top: 126px;
      height: 10rem;
      border-radius: 5px;
      width: 20rem;
      overflow-y: auto;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        border-bottom: 1px solid #77889952;
        padding: 5px 1rem;
        .icon-container {
          margin-right: -5px;
          .icon {
            color:white;
            padding:5px;
          }
          .close {
            color: red !important;
          }
        }
      }
      .content {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem;
      }
      /* width */
      ::-webkit-scrollbar {
        width: 5px;
        height:5px;
        border-radius: 10rem;
      }
    
      /* Track */
      ::-webkit-scrollbar-track {
        background: #000;
        border-radius: 10px;
        height: 5px;
    
      }
    
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #202020;
      }
    
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: rgb(246, 92, 139);
      }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  padding: 10px;
  min-height: 52px;
  width: -webkit-fill-available;
  justify-items: flex-start;
  white-space: nowrap;
  .filterIconBtn {
    color:white;
  }

  overflow: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height:5px;
    border-radius: 10rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000;
    border-radius: 10px;
    height: 5px;

  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #202020;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(246, 92, 139);
  }
`;
export const SubFilterContainer = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
`;

export const LinkContainer = styled.div`
  height: 50px;
  width: 50px;
  margin-right: 1rem;
  padding-top: 10px;
  img {
    height: 2rem;
  }
  span {
    cursor: pointer;
  }
`;

export const StyledParagraph = styled.p`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  justify-content: center;
`;
