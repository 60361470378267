import React, { useState, useEffect, useContext, useCallback } from "react";
import styled from "styled-components";
import MenuOptions from "./MenuOptions";
import Upvote from "../Elements/Upvote";
import Slider from "../Elements/Carousel";
import VideoCard from "./VideoCard";
import SeriesCard from "./SeriesCard";
import MomentCard from "./MomentCard";
import ShareIcon from "../icons/share.svg";
import Moment from "react-moment";
import debounce from "lodash.debounce";
import api from "../../services/api";
import config from "../../config";
import CommentIcon from "../icons/comment.svg";
import ImageNotfoundPlaceholder from "../../assets/image_notfound_placeholder.svg";
import ImageViewerModel from "react-images-viewer";
import URLSource from "./URLSource";
import ReactHTMLParser from "react-html-parser";
import { isBadgeOrAvatar } from "../../utils";
import { notificationMessage } from "../../utils/toastNotifications";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { GlobalContext } from "../../context/GlobalContext";
import { useCopyToClipboard } from "usehooks-ts";
import { THREAD_SELF, THREAD_OTHER } from "../../utils/menuOptions";
import { addDefaultImage } from "../../utils";
import { useHistory } from "react-router-dom";
import { MentionsInput } from "react-mentions";
import Hashtags from "../Shared/Hashtags";

const ThreadCard = ({ type, item }) => {
  const history = useHistory();
  const { setShowAuthenticationModel } = useContext(GlobalContext);
  const user = useSelector((state) => state.user);

  // general states
  const [showMore, setShowMore] = useState(false);
  const [viewImage, setViewImage] = useState(false);
  const [threadBody, setThreadBody] = useState("");
  // upvote states
  const [upvote, setUpvote] = useState(null);
  const [totalUpvotes, setTotalUpvotes] = useState(null);
  const [readyToCallUpvoteApi, setReadyToCallUpvoteApi] = useState(false);
  const [showFinalAnimation, setShowFinalAnimation] = useState(false);
  const [isThreadLiked, setIsThreadLiked] = useState(false);
  // share url state
  const [threadUrl, setThreadUrl] = useState(
    `${config.frontend.baseUrl}/thread/${item?.id}`
  );
  const [copiedUrl, copyUrlHandler] = useCopyToClipboard();

  useEffect(async () => {
    if (item?.id) {
      setUpvote(item?.myUpvote?.upvote || null);
      setTotalUpvotes(item?.upvotes || 0);
      setIsThreadLiked(false);
      setThreadBody(item?.threadBody);

      threadBodyModifyHandler(item?.threadBody);

      if (item?.myUpvote?.upvote) {
        setIsThreadLiked(true);
      }
    }
  }, [item]);

  useEffect(async () => {
    if (readyToCallUpvoteApi) {
      await upvoteApiCallHandler(item?.id, upvote);
      setReadyToCallUpvoteApi(false);
    }

    if (upvote > 0 && !isThreadLiked) {
      setIsThreadLiked(true);
      setTotalUpvotes(totalUpvotes + 1);
    }

    if (upvote === 0 && isThreadLiked) {
      setIsThreadLiked(false);
      setTotalUpvotes(totalUpvotes - 1);
    }
  }, [readyToCallUpvoteApi, upvote]);

  const upvoteApiCallHandler = useCallback(
    debounce(async (threadId, upvote) => {
      const obj = {
        upvote,
      };

      await api.post(`thread/${threadId}/upvoteThread`, obj);
    }, 1000),
    []
  );

  const upvoteHandler = () => {
    if (!user?.id) {
      return toast.error(notificationMessage.loginRequired);
    }

    setShowFinalAnimation(true);

    if (upvote < 3) {
      setUpvote(upvote + 1);
    } else {
      setUpvote(0);
    }

    setReadyToCallUpvoteApi(true);
  };

  const creatorImage = item?.User?.avatar
    ? item.User.avatar
    : item?.User?.badge
    ? item.User.badge
    : null;

  const shareThreadHandler = () => {
    copyUrlHandler(threadUrl);
    toast.success(notificationMessage.copyThreadUrl);
    return;
  };

  const redirectToLogin = () => {
    toast.error("Please login or create an account to access this feature.");
    setShowAuthenticationModel(true);
  };

  const threadBodyModifyHandler = (threadBody) => {
    // regex
    const tagUserRegex = new RegExp(/@\[(.*?)]\((.*?)\)/g);
    const hashTagRegex1 = new RegExp(/\B\#([\w\-]+)/gim);
    const hashTagRegex2 = new RegExp(/#\[(.*?)]\((.*?)\)/g);
    const hashTagFinalRegex = new RegExp(
      "(" + hashTagRegex1.source + ")|(" + hashTagRegex2.source + ")"
    );

    // for hash tags
    // parse tags from here and store it
    const addedHashTags = threadBody?.match(hashTagRegex1) || [];

    let hashTagString = threadBody;
    hashTagString = hashTagString?.substring(1);
    var hashTagsArray = hashTagString?.match(hashTagRegex2) || [];
    var pickedHashTags = [];

    if (hashTagsArray?.length > 0) {
      for (var a of hashTagsArray) {
        var value = a.substring(a.lastIndexOf("[") + 1, a.lastIndexOf("]"));
        var key = a.substring(a.lastIndexOf("(") + 1, a.lastIndexOf(")"));

        if (value) {
          pickedHashTags.push(`#${value}`);
        }
      }
    }

    const finalHashTags = [...new Set([...pickedHashTags, ...addedHashTags])];

    // for tag user
    let tagUserString = threadBody;
    tagUserString = tagUserString?.substring(1);
    var tagUsersArray = tagUserString?.match(tagUserRegex) || [];
    var tagUsersResult = [];

    for (var a of tagUsersArray) {
      var value = a.substring(a.lastIndexOf("[") + 1, a.lastIndexOf("]"));
      var key = a.substring(a.lastIndexOf("(") + 1, a.lastIndexOf(")"));

      if (value) {
        tagUsersResult.push(value);
      }
    }

    const threadBodyArray = threadBody?.split(" ");

    let result;
    if (threadBodyArray?.length > 0) {
      result = threadBodyArray.map((item, i) => {
        if (item.match(hashTagFinalRegex)) {
          const hashtag = finalHashTags.splice(0, 1);

          return `<a target="_blank" href="${
            config.frontend.baseUrl
          }/hashtag/${item
            .replace(hashTagFinalRegex, hashtag)
            .substring(1)}" class="hastag">${hashtag}</a>`;
        }

        if (item.match(tagUserRegex)) {
          const username = tagUsersResult.splice(0, 1);

          return `<a target="_blank" href="${
            config.frontend.baseUrl
          }/channel/${item.replace(
            tagUserRegex,
            username
          )}" class="username">${username}</a>`;
        }

        return item;
      });

      setThreadBody(result?.join(" "));
      return;
    } else {
      setThreadBody(threadBody);
      return;
    }
  };

  return (
    <>
      <ThreadCardStyled
        onClick={() => {
          history.push(`/thread/${item?.id}`);
        }}
      >
        <div className="header">
          <div
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/channel/${item?.User?.username}`);
            }}
            className="header__avatar"
          >
            {isBadgeOrAvatar(creatorImage)}
          </div>

          <div className="header__info">
            <div
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/channel/${item?.User?.username}`);
              }}
              className="header__info--username"
            >
              {item?.User?.username}
            </div>
            <span className="header__info--date">
              <Moment format="MMMM Do YYYY">{item?.createdAt}</Moment>
            </span>
          </div>

          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="header__menu"
          >
            {!user?.id ? (
              <MenuOptions item={item} options={THREAD_OTHER} />
            ) : (
              <MenuOptions
                item={item}
                options={
                  item?.User?.id === user?.id ? THREAD_SELF : THREAD_OTHER
                }
              />
            )}
          </div>
        </div>

        {item?.hashtags && item.hashtags.length > 0 && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="hashtags"
          >
            <Hashtags type="THREADS" hashtags={item.hashtags} />
          </div>
        )}

        <div className="main">
          <div className="main__sidebar">
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="main__sidebar--item"
            >
              <Upvote
                size="small"
                upvote={upvote}
                upvoteHandler={() => {
                  if (!user?.id) {
                    redirectToLogin();
                    return;
                  }

                  upvoteHandler();
                }}
                total={totalUpvotes}
                showFinalAnimation={showFinalAnimation}
              />
            </div>

            <div
              onClick={(e) => {
                e.stopPropagation();
                shareThreadHandler();
              }}
              className="main__sidebar--item"
            >
              <div className="icon">
                <img src={ShareIcon} alt="" />
              </div>
              <span className="text">Share</span>
            </div>

            <div
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/thread/${item?.id}?scrollTo=comments`);

                const queryParams = new URLSearchParams(window.location.search);
                const scrollToComments = queryParams.get("scrollTo");
                if (scrollToComments) {
                  const commentId = document.getElementById("comments");
                  if (commentId) {
                    commentId.scrollIntoView({ behavior: "smooth" });
                  }
                }
              }}
              title="Comments"
              className="main__sidebar--item"
            >
              <div className="icon">
                <img src={CommentIcon} alt="" />
              </div>
              <span className="text">Comment</span>
            </div>
          </div>

          <div className="main__content">
            {threadBody && (
              <div
                onClick={() => {
                  history.push(`/threads/${item?.id}`);
                  return;
                }}
                className="main__content--body"
              >
                {ReactHTMLParser(threadBody)}
              </div>
            )}

            {type === "video" && (
              <div className="main__content--shareVideo">
                <VideoCard
                  item={{
                    id: item?.attachedItem?.video?.id,
                    title: item?.attachedItem?.video?.title,
                    User: {
                      username: item?.User?.username,
                      avatar: item?.User?.avatar,
                      badge: item?.User?.badge,
                    },
                    createdAt: item?.attachedItem?.video?.createdAt,
                    views: item?.attachedItem?.video?.views,
                    thumbnail: item?.attachedItem?.video?.thumbnail,
                    videoLength: item?.attachedItem?.video?.videoLength,
                    VideoAccessOverlay: {
                      name: item?.attachedItem?.video?.VideoAccessOverlay?.name,
                    },
                    amount: item?.attachedItem?.video?.amount,
                    HasRewards: item?.attachedItem?.video?.HasRewards,
                  }}
                />
              </div>
            )}

            {type === "image" && (
              <>
                {item?.attachedType === "image" &&
                  item?.attachedItem?.length === 1 && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();

                        setViewImage(true);
                      }}
                      className="main__content--image"
                    >
                      <img
                        onError={(e) =>
                          addDefaultImage(e, ImageNotfoundPlaceholder)
                        }
                        src={
                          config.aws.CLOUDFRONT_URL + item?.attachedItem[0]?.url
                        }
                        alt=""
                      />
                    </div>
                  )}

                {item?.attachedType === "image" &&
                  item?.attachedItem?.length > 1 && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="main__content--slider"
                    >
                      <Slider items={item.attachedItem} />
                    </div>
                  )}
              </>
            )}

            {type === "series" && (
              <div className="main__content--shareSeries">
                <SeriesCard
                  item={{
                    id: item?.attachedItem?.series?.id,
                    title: item?.attachedItem?.series?.title,
                    User: {
                      username: item?.User?.username,
                      avatar: item?.User?.avatar,
                      badge: item?.User?.badge,
                    },
                    createdAt: item?.attachedItem?.series?.createdAt,
                    thumbnail: item?.attachedItem?.series?.thumbnail,
                    numVideos: item?.attachedItem?.series?.numVideos,
                    price: item?.attachedItem?.series?.price,
                    videos: item?.attachedItem?.series?.videos,
                  }}
                />
              </div>
            )}

            {type === "moment" && (
              <div className="main__content--shareMoment">
                <MomentCard
                  item={{
                    id: item?.attachedItem?.moment?.id,
                    coverImgUrl: item?.attachedItem?.moment?.coverImgUrl,
                    caption: item?.attachedItem?.moment?.caption,
                    User: {
                      username: item?.User?.username,
                      avatar: item?.User?.avatar,
                      badge: item?.User?.badge,
                    },
                  }}
                />
              </div>
            )}
          </div>
        </div>

        {/* url sources */}
        {item?.URLSources &&
          item.URLSources.length > 0 &&
          item.URLSources.map((urlItem, i) => (
            <div className="urlSources">
              <URLSource key={i} item={urlItem} />
            </div>
          ))}
      </ThreadCardStyled>

      {/* image viewer */}
      <ImageViewerModel
        imgs={[
          {
            src: config.aws.CLOUDFRONT_URL + item?.attachedItem[0]?.url,
          },
        ]}
        isOpen={viewImage}
        onClose={() => setViewImage(false)}
      />
    </>
  );
};

export default ThreadCard;

const ThreadCardStyled = styled.div`
  padding: 0.8rem;
  background-color: #202020;
  width: 100%;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.04);
  font-family: ${(props) => props.theme.montserrat}, sans-serif;
  overflow: hidden;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
    position: relative;
    cursor: pointer;

    &__avatar {
      cursor: pointer;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      position: relative;
      margin-right: 0.5rem;
      transition: all 0.2s ease;
      outline: 1px solid transparent;

      &:hover {
        outline: 1px solid rgba(255, 255, 255, 0.5);
      }

      .imageAvatar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        border-radius: 50%;
      }

      .imageBadge {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .badge {
          height: 38px;
          width: auto;
        }
      }
    }

    &__info {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;

      &--username,
      &--date {
        line-height: 1.2;
      }

      &--username {
        font-weight: 500;
        font-size: 0.9rem;
        background: -webkit-${(props) => props.theme.primaryGradient};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        position: relative;
        margin-bottom: 0.35rem;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: -2px;
          height: 2px;
          background: ${(props) => props.theme.primaryGradient};
          width: 0%;
          transition: all 0.4s ease;
          border-radius: 5px;
        }

        &:hover:before {
          width: 100%;
        }
      }

      &--date {
        font-weight: 300;
        font-size: 0.75rem;
      }
    }

    &__menu {
      position: absolute;
      top: 0;
      right: 0;
    }

    &__info {
      width: calc(100% - 5rem);
    }
  }

  .hashtags {
    margin-bottom: 0.5rem;
  }

  .urlSources {
    margin-top: 1rem;
  }

  .main {
    display: flex;
    align-items: flex-start;

    &__sidebar {
      width: 3.5rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 7px;
      margin-right: 0.5rem;

      &--item {
        padding: 0.5rem 0;
        border-bottom: 1px solid transparent;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.08);
        flex-direction: column;
        cursor: pointer;

        .icon {
          line-height: 1;
          height: 3rem;
          width: 3rem;
          border: 1px solid rgba(255, 255, 255, 0.08);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          img {
            height: 1.5rem;
            width: auto;
          }
        }

        .text {
          text-transform: uppercase;
          font-size: 0.65rem;
          font-weight: 500;
          color: #fff;
          margin-top: 0.25rem;
        }

        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }

    &__content {
      width: calc(100% - 4rem);

      &--body {
        padding: 0.7rem 1rem;
        background-color: #181818;
        border-radius: 7px;
        line-height: 1.6;
        font-size: 0.87rem;
        font-weight: 300;

        .username {
          text-decoration: underline;
          padding: 2px;
          color: #1da1f2;
          font-weight: 500;
        }

        .hastag {
          text-decoration: underline;
          padding: 2px;
          color: rgb(246, 92, 139);
          font-weight: 500;
        }

        &-more {
          padding-left: 0.5rem;
          font-weight: 500;
          text-transform: uppercase;
          color: rgba(255, 255, 255, 0.5);
          text-decoration: underline;
          font-size: 0.75rem;
          cursor: pointer;
          font-family: brother-1816, sans-serif;
        }
      }

      &--image,
      &--slider {
        width: 100%;
        height: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      &--image {
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center;
          object-fit: cover;
          border-radius: 7px;
        }
      }

      &--shareMoment,
      &--shareVideo,
      &--shareSeries {
        width: 50%;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .divider {
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.08);
        margin: 0 0 2rem 0;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .header {
      margin-bottom: 0.3rem;

      &__avatar {
        height: 2rem;
        width: 2rem;
      }

      &__info {
        &--username {
          font-size: 0.7rem;
        }

        &--date {
          font-size: 0.55rem;
        }
      }

      &__menu {
        position: absolute;
        top: -0.5rem;
        right: -1rem;
      }
    }

    .main {
      flex-direction: column;

      &__content {
        width: 100%;

        &--body {
          font-size: 0.65rem;
          padding: 0.5rem 0.8rem;

          &-more {
            padding-left: 0.25rem;
            font-size: 0.55rem;
          }
        }

        &--shareMoment,
        &--shareVideo,
        &--shareSeries {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }

        &--shareVideo,
        &--shareSeries {
          width: 100%;
        }
      }

      &__sidebar {
        width: 100%;
        flex-direction: row;
        border-radius: 0;
        border-bottom: none;
        margin-right: 0;

        &--item {
          border-bottom: none;
          width: auto;
          padding: 0.5rem;

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;
