import React, { useState } from "react";
import styled from "styled-components";
import FeaturedVideoCard from "../../Cards/FeaturedVideoCard";
import Slider from "react-slick";
import Title from "../../Elements/Title";
import { useMediaQuery } from "react-responsive";
import Error from "../../Elements/Error";

const SliderSettingsV1 = {
  arrows: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplaySpeed: 3000,
  centerPadding: "20px",
  centerMode: true,
  speed: 500,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SliderSettingsV2 = {
  arrows: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplaySpeed: 3000,
  speed: 500,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Wrapper = styled.div`
  padding: 2rem 0;
  border-bottom: 1px solid rgba(242, 242, 247, 0.2);

  .sectionTitle {
    width: 95%;
    margin: 0 auto;
  }

  .parent {
    position: relative;
    top: 0;
    left: 0;
  }

  .row {
    width: 97%;
    margin: 0 auto;
  }

  .video-info-container {
    display: flex;
    flex-direction: column;
    margin-top: 0.3rem;
  }

  .channel-avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    .avatar-image {
      height: 5rem;
      width: auto;
    }

    .png-img {
      width: 5rem;
      height: auto;
    }

    .custom-image {
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
    }
  }

  .user {
    background: -webkit-linear-gradient(#ff4883, #fdb769);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    letter-spacing: 0.02rem;
    text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.3);
    cursor: pointer;
  }

  .width-video {
    width: 499px;
  }
  .video-info span {
    font-size: 0.9rem;
    padding-right: 0.1rem;
  }
  .options-menu-right {
    float: right;
    padding: 0px 13px;
    color: white;
    background-color: unset;
    box-shadow: none;
  }
  .title {
    color: #f2f2f7;
    font-family: Montserrat;
    font-size: 20px;
    margin-left: 3%;
    font-weight: 600;
  }
`;

const FeaturedVideos = ({ videos }) => {
  const [midDrag, setMidDrag] = useState(false);

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  const featuredVideos = videos;

  return (
    <>
      {!featuredVideos.featuredVideos?.length ||
      featuredVideos.featuredVideos === null ? null : (
        <Wrapper>
          <>
            <Title className="sectionTitle">Featured Content</Title>
            {!featuredVideos.featuredVideos?.length ? (
              <Error text="No featured content was found" />
            ) : (
              <div className="video-info-container">
                <div className={`video-info`}>
                  {!isMobile ? (
                    <>
                      {featuredVideos.featuredVideos?.length > 3 ? (
                        <>
                          <Slider
                            className="slider"
                            {...SliderSettingsV1}
                            beforeChange={() => setMidDrag(true)}
                            afterChange={() => setMidDrag(false)}
                          >
                            {featuredVideos.featuredVideos &&
                              featuredVideos.featuredVideos.length > 0 &&
                              featuredVideos.featuredVideos.map((item, i) => (
                                <div key={i} className="slider__item">
                                  <FeaturedVideoCard
                                    item={item}
                                    midDrag={midDrag}
                                    setMidDrag={setMidDrag}
                                  />
                                </div>
                              ))}
                          </Slider>
                        </>
                      ) : (
                        <div className="row">
                          <Slider
                            className="slider"
                            {...SliderSettingsV2}
                            beforeChange={() => setMidDrag(true)}
                            afterChange={() => setMidDrag(false)}
                          >
                            {featuredVideos.featuredVideos &&
                              featuredVideos.featuredVideos.length > 0 &&
                              featuredVideos.featuredVideos.map((item, i) => (
                                <div key={i} className="slider__item">
                                  <FeaturedVideoCard
                                    item={item}
                                    midDrag={midDrag}
                                    setMidDrag={setMidDrag}
                                  />
                                </div>
                              ))}
                          </Slider>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <>
                        {featuredVideos.featuredVideos?.length > 1 ? (
                          <>
                            <Slider
                              className="slider"
                              {...SliderSettingsV1}
                              beforeChange={() => setMidDrag(true)}
                              afterChange={() => setMidDrag(false)}
                            >
                              {featuredVideos.featuredVideos &&
                                featuredVideos.featuredVideos.length > 0 &&
                                featuredVideos.featuredVideos.map((item, i) => (
                                  <div key={i} className="slider__item">
                                    <FeaturedVideoCard
                                      item={item}
                                      midDrag={midDrag}
                                      setMidDrag={setMidDrag}
                                    />
                                  </div>
                                ))}
                            </Slider>
                          </>
                        ) : (
                          <div className="row">
                            <Slider
                              className="slider"
                              {...SliderSettingsV2}
                              beforeChange={() => setMidDrag(true)}
                              afterChange={() => setMidDrag(false)}
                            >
                              {featuredVideos.featuredVideos &&
                                featuredVideos.featuredVideos.length > 0 &&
                                featuredVideos.featuredVideos.map((item, i) => (
                                  <div key={i} className="slider__item">
                                    <FeaturedVideoCard
                                      item={item}
                                      midDrag={midDrag}
                                      setMidDrag={setMidDrag}
                                    />
                                  </div>
                                ))}
                            </Slider>
                          </div>
                        )}
                      </>
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        </Wrapper>
      )}
    </>
  );
};

export default FeaturedVideos;
