import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import MenuOptions from "./MenuOptions";
import Moment from "react-moment";
import CloseIcon from "../../assets/Icons/close.svg";
import { toast } from "react-toastify";
import { removeContentFromElement } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { notificationMessage } from "../../utils/toastNotifications";
import { useHistory } from "react-router-dom";
import { CardStyles } from "./CardStyles";

import LockIcon from "../icons/lock.svg";
import UnlockIcon from "../icons/unlock.svg";
import ReactTooltip from "react-tooltip";
import api from "../../services/api";
import { addDefaultImage } from "../../utils";
import ImageNotfoundPlaceholder from "../../assets/image_notfound_placeholder.svg";
import config from "../../config";

const SeriesCard = ({
  item,
  removeable,
  elementId,
  menuOptions,
  isSelf,
  setSelectedSeries,
  setSeriesId,
  makePublicPrivateUI,
  midDrag,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { profile } = useSelector((state) => state);
  const [published, setPublished] = useState(item?.published || false);

  const SERIES_OPTIONS = [
    { icon: "", text: "Manage Series" },
    { icon: "", text: "Share Series" },
  ];

  const isBadgeOrAvatar = () => {
    const image = item?.User?.avatar
      ? item.User.avatar
      : item?.User?.badge
      ? item.User.badge
      : item?.User?.userSettings?.VisitorBadge?.imgPath
      ? item.User.userSettings.VisitorBadge.imgPath
      : null;

    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <div className="imageBadge">
          <img className="badge" src={image} alt="" />
        </div>
      );
    } else {
      return (
        <img
          onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
          className="imageAvatar"
          src={config.aws.CLOUDFRONT_URL + image}
          alt=""
        />
      );
    }
  };

  const togglePrivacyHandler = async () => {
    setPublished(!published);
    const res = await api.post(`series/toggle-privacy/${item.id}`);

    if (!res.data) {
      toast.error(notificationMessage.unexpectedServerError);
    }
  };

  return (
    <CardStyles>
      <div
        onClick={(e) => {
          e.stopPropagation();

          if (midDrag) {
            e.stopPropagation();
            return;
          }

          if (item?.videos?.length > 0) {
            history.push(`/series/details/${item.id}`);
          } else {
            toast.error(notificationMessage.seriesAddVideos);
          }
        }}
        className={`thumbnail`}
      >
        <img
          onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
          src={config.aws.CLOUDFRONT_URL + item?.thumbnail}
          alt={item?.title}
        />

        <div className="numVideos">
          <span>
            {item?.videos ? item.videos.length : item.numVideos} Videos
          </span>
        </div>

        <div className="price">
          {item?.price > 0 ? (
            <span>${parseFloat(item?.price).toFixed(2)}</span>
          ) : (
            <span>free</span>
          )}
        </div>
      </div>

      <div className="metaInfo">
        <div className="metaInfo__left">
          {!isSelf && (
            <div
              onClick={() => {
                history.push(`/channel/${item?.User?.username}`);
              }}
              className="metaInfo__left--avatar"
            >
              {isBadgeOrAvatar()}
            </div>
          )}

          <div className={`metaInfo__left--videoMeta `}>
            <div
              onClick={() => {
                if (item.videos.length > 0) {
                  history.push(
                    `/series/watch/${item.id}/${item.videos[0].videoId}`
                  );
                } else {
                  toast.error(notificationMessage.seriesAddVideos);
                }
              }}
              className="title"
            >
              {item?.title}
            </div>

            <div className="seperatorInfo">
              <div
                onClick={() => {
                  history.push(
                    `/channel/${
                      profile?.username ? profile.username : item?.User.username
                    }`
                  );
                }}
                className="bold"
              >
                {profile?.username ? profile.username : item?.User?.username}
              </div>

              <div className="seperator"></div>

              <div className="light">
                <Moment format="MMMM Do YYYY">{item?.createdAt}</Moment>
              </div>
            </div>
          </div>
        </div>

        <div className="menu">
          <MenuOptions
            item={item}
            setSeriesId={setSeriesId}
            setSelectedSeries={setSelectedSeries}
            options={menuOptions ? menuOptions : SERIES_OPTIONS}
          />
        </div>
      </div>

      {removeable && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            dispatch(removeContentFromElement(elementId, item.contentId));
            toast.success(notificationMessage.removedFromFeatured);
          }}
          className="close"
        >
          <img src={CloseIcon} alt="" />
        </div>
      )}

      {isSelf && makePublicPrivateUI && (
        <div className="seriesPrivacy">
          <div
            className="seriesPrivacy__tooltip"
            data-html={true}
            data-tip={ReactDOMServer.renderToString(
              <div className="seriesPrivacy__tooltip--floating">
                Your series is currently {published ? "Public" : "Private"},
                <div className="imp">Click here</div> to make it{" "}
                {published ? "Private" : "Public"}.
              </div>
            )}
          >
            <div onClick={togglePrivacyHandler} className="seriesPrivacy__box">
              <img
                src={published ? UnlockIcon : LockIcon}
                alt=""
                className="icon"
              />
            </div>
          </div>
          <ReactTooltip
            effect="solid"
            arrowColor="#fff"
            borderColor="#fff"
            backgroundColor="#fff"
            textColor="#202020"
          />
        </div>
      )}
    </CardStyles>
  );
};

export default SeriesCard;
