import React from "react";
import styled from "styled-components";
import Creators from "../components/NewHomeComponents/FeaturedSection/Creators";

const UITests = () => {
  return (
    <UITestsStyled>
      <Creators />
    </UITestsStyled>
  );
};

export default UITests;

const UITestsStyled = styled.div`
  height: 100vh;
  width: 100vw;
  padding-top: 5rem;
  margin-bottom: 15rem;
`;
