import React, { useRef, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import BackIcon from "../../../assets/Icons/back.svg";
import FilterIcon from "../../../assets/Icons/filter.svg";
import SortIcon from "../../../assets/Icons/caret-down.svg";
import useOnclickOutside from "react-cool-onclickoutside";
import TransactionSortModel from "./TransactionSortModel";
import TransactionFilterModel from "./TransactionFilterModel";
import {
  addOrEditKarmaSetting,
  getKarmaFilterSetting,
  getUserTransactions,
} from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../config";
import STRINGS from "../../../utils/Strings";
import { GlobalContext } from "../../../context/GlobalContext";

// import TribeLogo from "../../../assets/Icons/logo-2.png";

const TransactionHistory = ({
  close,
  transactions,
  isLoading,
  loadMoreTransactions,
  handleFilterUpdate,
  fetchingFilteredData,
}) => {
  const dispatch = useDispatch();
  const [showTransactionFilterModel, setShowTransactionFilterModel] =
    useState(false);
  const [showTransactionSortModel, setShowTransactionSortModel] =
    useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const listInnerRef = useRef();
  const [sortBy, setSortBy] = useState("mostRecent");
  const [sorted, setSorted] = useState([]);
  const [savingFilter, setSavingFilter] = useState(false);
  const user = useSelector((state) => state.user);

  const { setShowMyPortal, setPortalUsername } = useContext(GlobalContext);

  const karmaFilters = useSelector((state) => state.KarmaFilterSetting);

  useEffect(() => {
    dispatch(getUserTransactions());
  }, []);

  useEffect(() => {
    const sortArray = (type) => {
      let sorted;
      if (type === "mostExp") {
        sorted = [...transactions].sort(
          (a, b) => b.amount / 100 - a.amount / 100
        );
        setSorted(sorted);
      }
      if (type === "lessExp") {
        sorted = [...transactions].sort(
          (a, b) => a.amount / 100 - b.amount / 100
        );
        setSorted(sorted);
      } else if (type === "mostRecent") {
        sorted = [...transactions].sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setSorted(sorted);
      } else if (type === "lessRecent") {
        sorted = [...transactions].sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
        setSorted(sorted);
      } else {
        setSorted(transactions);
      }
      setShowTransactionSortModel(false);
    };
    sortArray(sortBy);
  }, [sortBy, transactions]);

  useEffect(() => {
    const filterType = 1; // 1. Transaction filters 2. Karam Filters
    dispatch(getKarmaFilterSetting(filterType));
  }, []);

  const sortRef = useOnclickOutside(() => {
    setShowTransactionSortModel(false);
  });
  const filterRef = useOnclickOutside(() => {
    setShowTransactionFilterModel(false);
  });

  const getUserName = (row) => {
    if (row && row.PaymentToUser) return row.PaymentToUser.username;
    else return "";
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setRowsPerPage(rowsPerPage + 10);
        loadMoreTransactions(transactions.length);
      }
    }
  };

  const onUpdateFilters = async (selectedFilters) => {
    setSavingFilter(true);
    updateKarmaEditSettings(selectedFilters).then((res) => {
      setSavingFilter(false);
      setShowTransactionFilterModel(false);
      return handleFilterUpdate(0, STRINGS.FILTER_TYPES.TRANSACTIONS_FILTERS); // offset = 0
    });
  };

  const updateKarmaEditSettings = (selectedFilters) =>
    new Promise((resolve) => {
      // FilterType: 1 = transactions , 2 = karma
      const selectedFilterSetting = {
        ...selectedFilters,
        filterType: STRINGS.FILTER_TYPES.TRANSACTIONS_FILTERS,
      };
      const res = dispatch(addOrEditKarmaSetting(selectedFilterSetting));
      resolve(res);
    });

  const getSortBy = (sortByTerm) => {
    switch (sortByTerm) {
      case "mostRecent":
        return "Most Recent";
      case "lessRecent":
        return "Less Recent";
      case "mostExp":
        return "Most Expensive";
      case "lessExp":
        return "Less Expensive";
      default:
        return "Most Recent";
    }
  };
  const getTransactionTitle = (transaction) => {
    const transactionType = STRINGS.TRANSACTION_TYPES;
    switch (transaction.type) {
      case transactionType.ISUTRA_MEMBERSHIP:
        let storagePackageName = transaction.storagePackageName
          ? transaction.storagePackageName
          : "";
        if (!storagePackageName && transaction.amount == 5)
          storagePackageName = "Tribe";

        return <div className="isutra-memebership">Membership</div>;
      case transactionType.PURCHASED_PPV:
        return (
          <a href={`${config.frontend.baseUrl}watch/${transaction.Video.id}`}>
            <div className="pay-per-view-section">
              <div className="purchased-ppv">Pay Per View </div>
              {transaction.Video?.title}
            </div>
          </a>
        );
      case transactionType.PURCHASED_SERIES:
        return (
          <div className="series-section">
            <div className="purchased-series">Series </div>
            {transaction.Series?.title}
          </div>
        );
      default:
        return <div className="other-badge">Stripe Transaction </div>;
    }
  };
  return (
    <TransactionHistoryStyled>
      <div className="wrapper">
        <div className="wrapper__header">
          <div onClick={close} className="backIcon">
            <img src={BackIcon} alt="" />
          </div>
          <div className="name">Manage Transaction</div>
        </div>

        <p className="infoText">A ledger of all your iSutra transactions</p>

        <div className="wrapper__transactions">
          {showTransactionSortModel && (
            <TransactionSortModel
              doNotClose={sortRef}
              close={() => setShowTransactionSortModel(false)}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          )}
          {showTransactionFilterModel && (
            <TransactionFilterModel
              isFetching={karmaFilters?.isFetching || false}
              filters={karmaFilters?.filters || {}}
              error={karmaFilters?.error || ""}
              doNotClose={filterRef}
              close={() => setShowTransactionFilterModel(false)}
              onUpdateFilters={onUpdateFilters}
              savingFilter={savingFilter}
            />
          )}

          <div className="wrapper__transactions--filterBox">
            <div className="left">
              <img
                ref={filterRef}
                onClick={() => {
                  setShowTransactionFilterModel(!showTransactionFilterModel);
                }}
                src={FilterIcon}
                alt=""
              />
            </div>

            <div
              ref={sortRef}
              onClick={() =>
                setShowTransactionSortModel(!showTransactionSortModel)
              }
              className="right"
            >
              <span>{getSortBy(sortBy)}</span>
              <img src={SortIcon} alt="" />
            </div>
          </div>

          <div
            className="wrapper__transactions--list"
            ref={listInnerRef}
            onScroll={onScroll}
          >
            {(isLoading || fetchingFilteredData) && (
              <span>Fetching records...</span>
            )}

            {!isLoading &&
              !fetchingFilteredData &&
              sorted?.length == 0 &&
              "No records found"}
            {sorted?.length > 0 &&
              sorted.map((transaction, index) => (
                <div className="item" key={index}>
                  <div className="left">
                    <a
                      href={`${config.frontend.baseUrl}channel/${getUserName(
                        transaction
                      )}`}
                    >
                      <div className="lightText">
                        {transaction.PaymentToUser?.avatar ? (
                          <img
                            src={transaction.PaymentToUser.avatar}
                            width="40px"
                            className="image-rounded"
                          />
                        ) : (
                          ""
                        )}
                        <div className="user-name">
                          {getUserName(transaction)}
                        </div>
                      </div>
                    </a>
                    <div className="title">
                      <div className="titleBadge">
                        {getTransactionTitle(transaction)}{" "}
                        {transaction.storagePackageName
                          ? transaction.storagePackageName
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="right">
                    <div className="date">
                      {" "}
                      {new Date(transaction?.createdAt).toDateString()}
                    </div>
                    {
                      <div
                        className="amount amountGoing"
                        style={{ color: "rgb(47, 221, 146)" }}
                      >
                        -$
                        <span>
                          {Math.round(
                            (transaction.amount + Number.EPSILON) * 100
                          ) / 100}
                        </span>
                      </div>
                    }
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </TransactionHistoryStyled>
  );
};

export default TransactionHistory;

const TransactionHistoryStyled = styled.div`
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    font-family: ${(props) => props.theme.montserrat};
    background-color: #1c1c1e;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 150;

    .imageBadge {
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .badge {
        height: 38px;
        width: auto;
      }
    }
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #3a3a3c;
      border-radius: 7px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(246, 92, 139);
    }

    &__header {
      display: flex;
      align-items: center;
      padding: 1rem 5rem;
      border-bottom: 1px solid rgba(112, 112, 112, 0.4);

      .backIcon {
        margin-right: 1rem;
        cursor: pointer;

        img {
          height: 1rem;
          width: auto;
        }
      }

      .name {
        font-size: 1.2rem;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    .infoText {
      font-size: 0.85rem;
      font-weight: 300;
      color: #f2f2f7;
      text-align: center;
      padding: 1rem 0;
    }

    .pay-per-view-section {
      display: flex;
      align-items: center;
    }
    .series-section {
      display: flex;
      align-items: center;
    }

    .purchased-ppv,
    .purchased-series,
    .isutra-memebership {
      text-transform: uppercase;
      font-family: brother-1816, sans-serif;
      padding: 0px 5px 0px 5px;
      background: ${(props) => props.theme.gradient};
      font-size: 0.575rem;
      text-align: center;
      align-items: center;
      display: flex;
      border-radius: 3px;
      margin-right: 10px;
      font-weight: 500;
    }

    .other-badge {
      padding: 0px 10px 0px 5px;
      background: #181818;
      font-size: 12px;
      text-align: center;
      align-items: center;
      display: flex;
      border-radius: 10px;
      margin-right: 10px;
    }

    &__transactions {
      width: 45%;
      margin: 0 auto 6rem auto;
      min-width: 480px;
      position: relative;

      &--filterBox {
        height: 2.5rem;
        width: 100%;
        background-color: #3a3a3c;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(112, 112, 112, 0.25);

        .right {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            height: 0.5rem;
            width: auto;
          }
        }

        .left {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            height: 1.2rem;
            width: auto;
          }
        }

        span {
          padding-right: 0.5rem;
          font-size: 0.85rem;
          color: #c7c7cc;
          font-weight: 500;
        }
      }

      &--list {
        background-color: #2c2c2e;
        padding: 0 3rem 2rem 3rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        max-height: 26rem;
        min-height: 26rem;
        overflow-y: scroll;

        /* width */
        ::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #3a3a3c;
          border-radius: 7px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: rgb(246, 92, 139);
        }

        .lightText {
          font-size: 0.8rem;
          color: #f2f2f7;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }

        .image-rounded {
          border-radius: 50%;
          height: 24px;
          width: 24px;
        }

        .item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgba(112, 112, 112, 0.25);
          padding: 0.7rem 0;

          .title {
            font-size: 1rem;
            font-weight: 500;
            color: #f2f2f7;
            display: "flex" j;
          }
          .titleBadge {
            align-items: center;
            display: flex;
          }

          .date {
            text-transform: uppercase;
            font-weight: 300;
            font-size: 0.75rem;
            color: #d1d1d6;
          }

          .amount {
            color: #f2f2f7;
            font-size: 0.9rem;
            font-weight: 200;

            span {
              font-weight: 600;
              padding-left: 3px;
            }
          }

          .amountComing {
            color: #f9903d !important;
          }

          .amountGoing {
            color: #f2f2f7;
          }
        }
      }
      .showMoreBtn {
        width: 100%;
        display: flex;
        justify-content: "flex-end !important";
        .btn {
          width: 100%;
          padding: 0.7rem 0;
          font-size: 0.9rem;
          color: #fff;
          font-weight: 500;
          border-radius: 4px;
          background: linear-gradient(to right bottom, #f9903d, #f75b8c);
          border: none;
          outline: none;
          font-family: Montserrat;
          text-transform: capitalize;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .wrapper {
      &__header {
        padding: 0.8rem 2rem;
      }

      &__transactions {
        min-width: 95%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .wrapper {
      &__header {
        padding: 0.5rem 1.5rem;

        .name {
          font-size: 1rem;
        }
      }

      &__transactions {
        &--list {
          padding: 0 1.5rem 2rem 1.5rem;
        }

        &--filterBox {
          .right {
            img {
              height: 0.5rem;
              width: auto;
            }
          }
        }

        .item {
          .left {
            .purchased-ppv,
            .purchased-series,
            .isutra-memebership {
              font-size: 0.48rem;
              font-weight: 400;
              border-radius: 1.5px;
            }

            .titleBadge {
              font-size: 0.78rem;
              line-height: 1.35;
            }
          }

          .right {
            .date {
              font-size: 0.58rem !important;
            }

            .amount {
              font-size: 0.7rem !important;
            }
          }
        }
      }
    }
  }
`;
