import styled from "styled-components";

const BigText = styled.h1`
  font-size: 3.2rem;
  color: #1d1d1f;
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;
  font-family: brother-1816, sans-serif;
  font-style: italic;

  @media screen and (max-width: 480px) {
    font-size: 2.7rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 2.3rem;
  }
`;

export default BigText;
