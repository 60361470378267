import React from "react";
import styled from "styled-components";
import LinkIcon from "../icons/link.svg";

const URLSource = ({ item }) => {
  if (!item?.url && !item?.title) {
    return null;
  }

  return (
    <URLSourceStyled title={item?.url}>
      <a href={item?.url} target="_blank">
        {item?.image ? (
          <>
            <div className="thumbnail">
              <img src={item?.image} alt={item?.title} />
            </div>

            <div className="info">
              <div className="info__title">
                {item?.title?.length > 50
                  ? item?.title.substring(0, 45) + "..."
                  : item?.title}
              </div>
              <div className="info__desc">
                {item?.description?.length > 150
                  ? item?.description.substring(0, 120) + "..."
                  : item?.description}
              </div>

              {item?.url && (
                <div title={item?.url} className="info__url">
                  <img src={LinkIcon} alt="" />
                  {item?.url?.length > 50
                    ? item?.url.substring(0, 45) + "..."
                    : item?.url}
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="infoV2">
              <div className="info__title">
                {item?.title?.length > 50
                  ? item?.title.substring(0, 45) + "..."
                  : item?.title}
              </div>
              <div className="info__desc">
                {item?.description?.length > 150
                  ? item?.description.substring(0, 120) + "..."
                  : item?.description}
              </div>

              {item?.url && (
                <div title={item?.url} className="info__url">
                  <img src={LinkIcon} alt="" />
                  {item?.url?.length > 50
                    ? item?.url.substring(0, 45) + "..."
                    : item?.url}
                </div>
              )}
            </div>
          </>
        )}
      </a>
    </URLSourceStyled>
  );
};

export default URLSource;

const URLSourceStyled = styled.div`
  font-family: ${(props) => props.theme.montserrat}, sans-serif;
  cursor: pointer;
  margin-bottom: 0.6rem;

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.8rem;
    overflow: hidden;
    height: 8rem;
  }

  .thumbnail {
    width: 10rem;
    height: 100%;

    img {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
    }
  }

  .infoV2 {
    width: calc(100% - 10rem);
    padding: 1rem;
  }

  .info {
    width: calc(100% - 10rem);
    padding: 1rem;

    &__title {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      line-height: 1;
    }

    &__desc {
      font-size: 0.8rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      line-height: 1.5;
      text-align: left;
    }

    &__url {
      display: flex;
      align-items: center;
      font-size: 0.7rem;
      font-weight: 500;
      background: -webkit-${(props) => props.theme.primaryGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      img {
        height: 0.6rem;
        width: auto;
        margin-right: 0.5rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    a {
      height: 5.5rem;
      border-radius: 0.5rem;
    }

    .thumbnail {
      width: 7rem;
    }

    .info {
      width: calc(100% - 7rem);
      padding: 0.5rem;

      &__title {
        font-size: 0.8rem;
        margin-bottom: 0.25rem;
      }

      &__desc {
        font-size: 0.6rem;
      }
    }
  }
`;
