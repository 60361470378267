import React, { useEffect, useContext, useState } from "react";
import PersonalInformation from "./PersonalInformation";
import { SignupStyles } from "./SignupStyles";
import Storage from "./Storage";
import Membership from "./Membership";
import Badge from "./Badge";
import UserSettings from "./UserSettings";
import Payment from "./Payment";
import { AuthContext } from "../../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { signupUser } from "../../../actions";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";

const Signup = ({ setProgress, progress }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    firstname,
    setFirstname,
    lastname,
    setLastname,
    username,
    setUsername,
    email,
    setEmail,
    password,
    setPassword,
    setConfirmPassword,
    choosePackage,
    setChoosePackage,
    agreedToLicense,
    setAgreedToLicense,
    referralCode,
    setReferralCode,
    selectedBadge,
    setSelectedBadge,
    anonymous,
    country,
    city,
    state,
    proContentProducer,
    partnerContentCreator,
    contactViaEmail,
    setValidationError,
    setAuthState,
    setStorageObj,
    setBadgeObj,
    setAnonymous,
    setProContentProducer,
    setPartnerContentCreator,
    setContactViaEmail,
    setLoader,
    setSignupStep,
    storageObj,
    setSelectedStorage,
  } = useContext(AuthContext);
  const { setShowAuthenticationModel, setShowWelcomeScreen } =
    useContext(GlobalContext);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user?.id) {
      history.push("/home");
      clearForm();
    }

    if (user?.signupLoading) {
      setShowWelcomeScreen(true);
    }
  }, [user]);

  const signupCompleteFree = () => {
    if (!agreedToLicense) {
      return setValidationError({
        type: "error",
        message: "Please agreed to our license to get started.",
      });
    }

    const payload = {
      firstname,
      lastname,
      username,
      email,
      password,
      userrole:
        choosePackage === "FREE"
          ? 0
          : choosePackage === "BASIC"
          ? 1
          : choosePackage === "PREMIUM"
          ? 2
          : 0,
      agreedToLicense,
      code: referralCode,
    };

    const userSettingPayload = {
      outOfThisWorld: null,
      country: null,
      city: null,
      state: null,
      proContentProducer: false,
      partnerContentCreator: false,
      contactViaEmail: false,
      visitorBadgeId: selectedBadge,
    };

    dispatch(signupUser(payload, userSettingPayload, history));
  };

  const signupCompletePremium = (paymentMethodId) => {
    if (!agreedToLicense) {
      return setValidationError({
        type: "error",
        message: "Please agreed to our license to get started.",
      });
    }

    const payload = {
      firstname,
      lastname,
      username,
      email,
      password,
      userrole:
        choosePackage === "FREE"
          ? 0
          : choosePackage === "BASIC"
          ? 1
          : choosePackage === "PREMIUM"
          ? 2
          : 0,
      agreedToLicense,
      code: referralCode,
      paymentMethodId,
      storagePackageId: choosePackage === "PREMIUM" ? storageObj.id : null,
    };
    const userSettingsObj = {
      outOfThisWorld: anonymous,
      country,
      city,
      state,
      proContentProducer,
      partnerContentCreator,
      contactViaEmail,
      visitorBadgeId: selectedBadge,
    };

    dispatch(signupUser(payload, userSettingsObj, history));
  };

  const clearForm = () => {
    setFirstname("");
    setLastname("");
    setUsername("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setChoosePackage("");
    setAgreedToLicense("");
    setReferralCode("");
    setSelectedBadge(null);
    setValidationError(null);
    setStorageObj(null);
    setBadgeObj(null);
    setAnonymous(false);
    setPartnerContentCreator(false);
    setProContentProducer(false);
    setContactViaEmail(false);
    setLoader(false);
    setSignupStep(1);
    setAuthState("CHECK_EMAIL");
    setShowAuthenticationModel(false);
    setSelectedStorage(null);
  };

  return (
    <>
      <SignupStyles>
        {progress === 1 && <PersonalInformation setProgress={setProgress} />}
        {progress === 2 && <Membership setProgress={setProgress} />}
        {progress === 3 && <Badge setProgress={setProgress} />}
        {progress === 4 && <Storage setProgress={setProgress} />}
        {progress === 5 && <UserSettings setProgress={setProgress} />}
        {progress === 6 && (
          <Payment
            setProgress={setProgress}
            signupCompleteFree={signupCompleteFree}
            signupCompletePremium={signupCompletePremium}
          />
        )}
      </SignupStyles>
    </>
  );
};

export default Signup;
