import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Button from "../components/Elements/Button";
import Footer from "../components/LandingPageComponents/Footer";
import SearchOutlineIcon from "../components/icons/searchOutline.svg";
import CloseIcon from "../components/icons/close.svg";
import MomentIcon from "../components/icons/moment.svg";
import ThreadIcon from "../components/icons/post.svg";
import SeriesIcon from "../components/icons/video.svg";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    document.addEventListener("keydown", searchHandler, false);

    return () => {
      document.removeEventListener("keydown", searchHandler, false);
    };
  }, [searchText]);

  const searchHandler = (event) => {
    if (event.key === "Enter" && searchText !== "") {
      history.push(`/search/query=${searchText}`);
      setSearchText("");
    }
  };

  return (
    <NotFoundStyled>
      <div className="wrapper">
        <div className="content">
          <div className="content__title">404</div>
          <div className="content__notifyText">
            The page you are looking for doesn't exist or has been moved
          </div>
          <Button
            onClick={() => {
              history.push("/home");
            }}
            mode="primary"
            size="medium"
            className="content__button"
          >
            Go Home
          </Button>

          <div className="divider">
            <div className="divider__left"></div>
            <div className="divider__center">or</div>
            <div className="divider__right"></div>
          </div>

          <div className="content__search">
            <div className="content__search--input">
              <input
                type="text"
                placeholder="Search Content"
                name="searchText"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />

              <img src={SearchOutlineIcon} alt="" className="searchIcon" />
              <img
                onClick={() => {
                  setSearchText("");
                }}
                src={CloseIcon}
                alt=""
                className="closeIcon"
              />
            </div>

            <div className="content__search--infoText">
              Press S Key to open the search.
            </div>
          </div>

          <div className="content__explore">
            <div className="content__explore--title">Explore</div>

            <div className="content__explore--list">
              <div
                onClick={() => {
                  history.push("/moments");
                }}
                className="content__explore--item"
              >
                <img className="icon" src={MomentIcon} alt="" />
                <div className="text">Moment</div>
              </div>

              <div
                onClick={() => {
                  history.push("/threads");
                }}
                className="content__explore--item"
              >
                <img className="icon" src={ThreadIcon} alt="" />
                <div className="text">Thread</div>
              </div>

              <div
                onClick={() => {
                  history.push("/series");
                }}
                className="content__explore--item"
              >
                <img className="icon" src={SeriesIcon} alt="" />
                <div className="text">Series</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </NotFoundStyled>
  );
};

export default NotFound;

const NotFoundStyled = styled.div`
  .wrapper {
    min-height: 100vh;
    width: 100vw;
    font-family: ${(props) => props.theme.montserrat}, sans-serif;

    .content {
      width: 45%;
      margin: 0 auto;
      padding: 7rem 0 5rem 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      &__title {
        font-family: brother-1816, sans-serif;
        text-transform: uppercase;
        font-size: clamp(4rem, 10vw, 10rem);
        font-weight: 600;
        line-height: 1;
        margin-bottom: 1rem;
        position: relative;
      }

      &__notifyText {
        font-size: 1.05rem;
        color: rgba(255, 255, 255, 0.6);
      }

      &__button {
        margin-top: 1.5rem;
        border-radius: 2px;
        padding: 0.5rem 2rem;
      }

      &__search {
        width: 100%;

        &--infoText {
          font-size: 0.68rem;
          float: right;
          font-weight: 300;
          color: rgba(255, 255, 255, 0.6);
        }

        &--input {
          width: 100%;
          height: 2.8rem;
          background-color: #202020;
          border-radius: 3px;
          border: 1px solid rgba(255, 255, 255, 0.09);
          position: relative;
          transition: all 0.4s ease;

          input {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: transparent;
            border: none;
            outline: none;
            padding: 0 2.5rem;
            color: #fff;
            cursor: pointer;
            font-size: 0.92rem;
            font-family: ${(props) => props.theme.montserrat}, sans-serif;
            font-weight: 400;

            &::placeholder {
              color: rgba(255, 255, 255, 0.6);
              font-family: brother-1816, sans-serif;
              text-transform: uppercase;
              font-weight: 200;
              font-size: 0.82rem;
            }
          }

          .searchIcon,
          .closeIcon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.2s ease;
          }

          .searchIcon {
            left: 0.75rem;
          }

          .closeIcon {
            right: 0.75rem;
          }

          img {
            height: 1rem;
            width: auto;
          }
        }
      }

      &__explore {
        width: 100%;
        margin-top: 2rem;

        &--title {
          font-family: brother-1816, sans-serif;
          text-transform: uppercase;
          line-height: 1;
          margin-bottom: 1.5rem;
          font-weight: 300;
          font-size: 1.1rem;
          color: rgba(255, 255, 255, 0.7);
          position: relative;
          padding-left: 0.5rem;
          margin-bottom: 1.5rem;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 3px;
            background: ${(props) => props.theme.gradient};
          }
        }

        &--list {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }

        &--item {
          padding: 1rem 2rem;
          border: 1px solid rgba(255, 255, 255, 0.06);
          background-color: #181818;
          margin-bottom: 1rem;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          transition: all 0.4s ease;
          position: relative;

          &:hover {
            background: ${(props) => props.theme.gradient};
            cursor: pointer;

            .text {
              color: #fff;
            }
          }

          .addedIcon {
            height: 0.9rem;
            width: auto;
            margin-right: 0.5rem;
          }

          .icon {
            height: 1.25rem;
            width: auto;
            margin-bottom: 0.5rem;
          }

          .text {
            text-transform: uppercase;
            font-size: 0.7rem;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.6);
            line-height: 1;
            transition: all 0.4s ease;
            display: flex;
            align-items: center;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }

      .divider {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 1.5rem 0;

        &__left,
        &__right {
          height: 1px;
          width: 47%;
          background: rgba(255, 255, 255, 0.2);
        }

        &__center {
          font-family: brother-1816, sans-serif;
          text-transform: uppercase;
          font-size: 0.8rem;
          font-weight: 400;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .wrapper {
      .content {
        width: 90%;
        padding: 5rem 0;

        &__title {
          font-size: clamp(4rem, 20vw, 10rem);
        }

        &__notifyText {
          font-size: 0.9rem;
          text-align: center;
        }

        &__button {
          padding: 0.5rem 1.5rem;
          font-size: 0.82rem;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .wrapper {
      .content {
        width: 95%;

        &__title {
          font-size: clamp(4rem, 20vw, 10rem);
        }

        &__notifyText {
          font-size: 0.9rem;
          text-align: center;
        }

        &__button {
          padding: 0.5rem 1.5rem;
          font-size: 0.82rem;
        }

        &__search {
          &--input {
            height: 2.5rem;
            border-radius: 2px;

            input {
              font-size: 0.9rem;

              &::placeholder {
                font-size: 0.78rem;
              }
            }

            img {
              height: 0.8rem;
            }
          }
        }

        &__explore {
          &--item {
            padding: 1rem 1.5rem;
            margin-bottom: 0.5rem;

            .icon {
              height: 1.1rem;
            }

            .text {
              font-size: 0.68rem;
            }

            &:not(:last-child) {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
`;
