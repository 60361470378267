import React, { useEffect } from "react";
import styled from "styled-components";
import BigText from "../StyleComponents/BigText";
import SmallText from "../StyleComponents/SmallText";

const SectionHeader = ({ subTitle = "subtitle", title = "title" }) => {
  return (
    <SectionHeaderStyled>
      <SmallText className="subTitle">{subTitle}</SmallText>
      <BigText className="title">{title}</BigText>
    </SectionHeaderStyled>
  );
};

export default SectionHeader;

const SectionHeaderStyled = styled.div`
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .subTitle {
    margin-bottom: 0.5rem;
    color: rgba(255, 255, 255, 0.8);
    letter-spacing: 2px;
  }

  .title {
    color: #fff;
    font-style: normal;
    position: relative;
    font-size: 2.8rem;

    &:before {
      content: "";
      position: absolute;
      bottom: -1rem;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      width: 6rem;
      background-color: #fff;
    }
  }

  @media screen and (max-width: 480px) {
    width: 90%;

    .subTitle {
      font-size: 0.6rem;
      text-align: center;
    }

    .title {
      text-align: center;
      font-size: 2rem;

      &:before {
        bottom: -0.5rem;
      }
    }
  }
`;
