import {
  GET_HOME_FEATURED_THREADS_REQUEST,
  GET_HOME_FEATURED_THREADS_SUCCESS,
  GET_HOME_FEATURED_THREADS_FAIL,
} from "../actions/thread/type";

const featuredThreads = (state = {}, action) => {
  switch (action.type) {
    case GET_HOME_FEATURED_THREADS_REQUEST:
      return {
        loading: true,
      };
    case GET_HOME_FEATURED_THREADS_SUCCESS:
      return {
        loading: false,
        featuredThreads: action.payload,
      };
    case GET_HOME_FEATURED_THREADS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default featuredThreads;
