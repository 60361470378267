import React from "react";
import styled from "styled-components";
import SkeletonElement from "../SkeletonElement";
import SkeletonShimmer from "../SkeletonShimmer";
import { useMediaQuery } from "react-responsive";

const ThreadCard = ({ className }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  return (
    <ThreadCardSkeleton className={className}>
      <div className="header">
        <div className="header__left">
          <SkeletonElement
            className="header__left--avatar"
            type="avatarMD"
            varient="dark"
            mr="1rem"
          />

          <div className="header__left--info">
            <SkeletonElement type="paragraph" varient="dark" mb=".3rem" />
            <SkeletonElement type="paragraph" varient="dark" />
          </div>
        </div>

        <div className="header__right"></div>
      </div>

      <div className="content">
        <div className="content__sidebar">
          {isMobile && (
            <>
              <div className="content__sidebar--item">
                <SkeletonElement type="avatarSM" varient="dark" mr=".5rem" />
              </div>

              <div className="content__sidebar--item">
                <SkeletonElement type="avatarSM" varient="dark" mr=".5rem" />
              </div>

              <div className="content__sidebar--item">
                <SkeletonElement type="avatarSM" varient="dark" />
              </div>
            </>
          )}

          {!isMobile && <SkeletonShimmer />}
        </div>
        <div className="content__main">
          <div className="content__main--text">
            <SkeletonShimmer />
          </div>

          <div className="content__main--attachedItem">
            <SkeletonElement type="video" mb="1rem" />
            <div className="infoBox">
              <div className="infoBox__left">
                <SkeletonElement type="avatarSM" mr="1rem" className="avatar" />
              </div>
              <div className="infoBox__right">
                <SkeletonElement type="paragraph" mb=".5rem" />
                <SkeletonElement type="paragraph" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThreadCardSkeleton>
  );
};

export default ThreadCard;

const ThreadCardSkeleton = styled.div`
  padding: 1rem;
  border-radius: 7px;
  background-color: #202020;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;

    &__left {
      display: flex;
      align-items: center;
      width: 100%;

      &--info {
        width: 50%;
      }
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &__sidebar {
      width: 3.5rem;
      border-radius: 4px;
      background-color: #181818;
      position: relative;
      overflow: hidden;
      border: 1px solid rgba(255, 255, 255, 0.09);
      align-self: stretch;
      position: relative;
      overflow: hidden;
    }

    &__main {
      width: calc(100% - 4.5rem);

      &--text {
        height: 5rem;
        border-radius: 7px;
        background-color: #181818;
        position: relative;
        overflow: hidden;
        border: 1px solid rgba(255, 255, 255, 0.09);
      }

      &--attachedItem {
        margin-top: 1rem;
        width: 50%;
        padding: 1rem;
        background-color: #181818;
        border-radius: 7px;

        .infoBox {
          display: flex;
          align-items: flex-start;
          margin-bottom: 1rem;
          width: 100%;

          &__right {
            width: calc(100% - 3.5rem);
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .header {
      &__left {
        &--info {
          width: calc(100% - 4rem);
        }
      }
    }

    .content {
      flex-direction: column;

      &__sidebar {
        width: 100%;
        height: 3.5rem;
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
      }

      &__main {
        width: 100%;
        margin-top: 1rem;

        &--attachedItem {
          width: 100%;
        }
      }
    }
  }
`;
