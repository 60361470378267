import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../../components/Elements/Input";
import Button from "../../components/Elements/Button";
import { Row, Col } from "react-grid-system";
import {
  adminVideoCategoryData,
  updateAdminVideoCategory,
} from "../../actions";
import { useDispatch } from "react-redux";
import { uploadImage } from "../../utils";
import { toast } from "react-toastify";

const VideoCategoriesEdit = ({ isOpen, onClose, formData, onChange }) => {
  const dispatch = useDispatch();
  const [updatedImageUrl, setUpdatedImageUrl] = useState(null);

  useEffect(() => {
    dispatch(adminVideoCategoryData());
  }, [dispatch]);

  const UpdateVideoCateg = async () => {
    try {
      const updatedFormData = {
        ...formData,
        ...updatedImageUrl,
      };
      await dispatch(updateAdminVideoCategory(updatedFormData));
      await dispatch(adminVideoCategoryData());
      onClose();
    } catch (error) {
      console.error("Error updating video type:", error);
    }
  };

  const handleIconChange = async (e, field) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").pop();
    const allowedFileTypes = ["jpg", "jpeg", "png"];

    if (!allowedFileTypes.includes(fileExtension)) {
      toast.error(
        `Image extension does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
      return;
    }
    toast.info("Uploading...");

    if (file) {
      try {
        const { url } = await uploadImage("image", file);
        toast.success("Image uploaded successfully");
        setUpdatedImageUrl((prevUrls) => ({
          ...prevUrls,
          [field]: url,
        }));
        onChange(field, url);
      } catch (error) {
        toast.error("Image upload failed. Please try again.");
      }
    }
  };

  return (
    <VideoCategoriesEditModal open={isOpen}>
      <VideoCategoriesEditContent>
        <div className="title">Edit video Categories Type</div>
        <div className="field">
          <Row className="row row__one">
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Id"
                  name="id"
                  id="id"
                  placeholder="id"
                  type="id"
                  value={formData.id}
                  onChange={(e) => onChange("id", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Name"
                  name="name"
                  id="name"
                  placeholder="name"
                  type="name"
                  value={formData.name}
                  onChange={(e) => onChange("name", e.target.value)}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Order"
                  name="order"
                  id="order"
                  placeholder="order"
                  type="text"
                  value={formData.order}
                  onChange={(e) => onChange("order", e.target.value)}
                />
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="IconPath"
                  name="iconPath"
                  id="iconPath"
                  placeholder="iconPath"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleIconChange(e, "iconPath")}
                />
                <label style={{ color: "#bec3c7" }}>{formData.iconPath}</label>
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="CreatedAt"
                  name="createdAt"
                  id="createdAt"
                  placeholder="createdAt"
                  type="text"
                  value={formData.createdAt}
                  onChange={(e) => onChange("createdAt", e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Row className="row row__one">
          <Col md={6} sm={6} xs={6}>
            <Button  mode={'secondary'} className="submit" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <Button  mode={'primary'} className="submit" onClick={UpdateVideoCateg}>
              Save
            </Button>
          </Col>
        </Row>
      </VideoCategoriesEditContent>
    </VideoCategoriesEditModal>
  );
};

export default VideoCategoriesEdit;

const VideoCategoriesEditModal = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoCategoriesEditContent = styled.div`
  background: #2c2c2e;
  color: white;
  border: 1px solid white;
  padding: 20px;
  max-width: 800px;
  width: 100%;

  .title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .submit {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdownBox {
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    margin-top: 3px;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;
