import React, { useEffect } from "react";
import styled from "styled-components";
import Input from "../../components/Elements/Input";
import Button from "../../components/Elements/Button";
import { Row, Col } from "react-grid-system";
import { adminAllUserData, updateAdminUser } from "../../actions";
import { useDispatch } from "react-redux";

const UserEditForm = ({ isOpen, onClose, userFormData, onChange }) => {
  const dispatch = useDispatch();
  const userRoleOptions = [
    { value: "0", label: "Freeloader" },
    { value: "1", label: "Co-op Member" },
    { value: "2", label: "Warrior" },
  ];
  useEffect(() => {
    dispatch(adminAllUserData());
  }, [dispatch]);

  const handleUpdateAdminUser = async () => {
    try {
      dispatch(updateAdminUser(userFormData));
      dispatch(adminAllUserData());
      onClose();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  return (
    <UserEditModal open={isOpen}>
      <UserEditContent>
        <div className="title">Edit User details</div>
        <div className="field">
          <Row className="row row__one">
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="First Name"
                  name="firstname"
                  id="firstname"
                  placeholder="firstname"
                  type="text"
                  value={userFormData.firstname}
                  onChange={(e) => onChange("firstname", e.target.value)}
                />
              </div>
            </Col>

            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Last Name"
                  name="lastname"
                  id="lastname"
                  placeholder="lastname"
                  type="text"
                  value={userFormData.lastname}
                  onChange={(e) => onChange("lastname", e.target.value)}
                />
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Email"
                  name="email"
                  id="email"
                  placeholder="email"
                  type="text"
                  value={userFormData.email}
                  onChange={(e) => onChange("email", e.target.value)}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="User Name"
                  name="username"
                  id="username"
                  placeholder="username"
                  type="text"
                  value={userFormData.username}
                  onChange={(e) => onChange("username", e.target.value)}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <label>User Role</label>
                <select
                  label="User Role"
                  name="userrole"
                  value={userFormData.userrole}
                  className="dropdownBox"
                  onChange={(e) => onChange("userrole", e.target.value)}
                >
                  {userRoleOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="row row__one">
          <Col md={6} sm={6} xs={6}>
            <Button mode={"secondary"} className="submit" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <Button
              mode={"primary"}
              className="submit"
              onClick={handleUpdateAdminUser}
            >
              Save
            </Button>
          </Col>
        </Row>
      </UserEditContent>
    </UserEditModal>
  );
};

export default UserEditForm;

const UserEditModal = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserEditContent = styled.div`
  background: #2c2c2e;
  color: white;
  border: 1px solid white;
  padding: 20px;
  max-width: 800px;
  width: 100%;

  .title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .field {
    /* Style   */
  }

  .submit {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdownBox {
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    margin-top: 3px;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;
