import axios from "axios";
import config from "../config";
export const axiosRequest = axios.CancelToken.source();
const api = axios.create({
  baseURL: config.api.url,
  cancelToken: axiosRequest.token,
});

export const setAuthHeader = async () => {
  let token;
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    token = user.token || null;
  }
  api.defaults.headers.Authorization = `Bearer ${token}`;
};
setAuthHeader();

export default api;
