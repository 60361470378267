import React from "react";
import styled from "styled-components";
import SkeletonElement from "./SkeletonElement";
import SkeletonShimmer from "./SkeletonShimmer";
import { Row, Col } from "react-grid-system";

const HomeSkeleton = ({
  showCategoryFilter,
  showMarketingFilter,
  showMomentsFilter,
  showVideosFilter,
  showFeaturedVideosFilter,
}) => {
  return (
    <HomeSkeletonStyled>
      {/* filter category */}
      {showCategoryFilter && (
        <div className="filterBoxes">
          {Array.from({ length: 12 }).map((item) => (
            <SkeletonElement type="category" />
          ))}
        </div>
      )}

      {/* marketing banner */}
      {showMarketingFilter && (
        <div className="banners">
          <SkeletonElement type="banner" />
        </div>
      )}

      {/* featured videos */}
      {showFeaturedVideosFilter && (
        <div className="cardBox">
          <SkeletonElement type="title" mb="2rem" />
          <Row>
            {Array.from({ length: 3 }).map((item) => (
              <Col
                md={4}
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                }}
              >
                <SkeletonElement type="featuredVideo" />
                <div className="infoBox__center">
                  <div className="infoBox__center--top">
                    <div className="avatar"></div>
                  </div>
                  <div className="infoBox__center--bottom">
                    <SkeletonElement type="paragraph" mb=".5rem" />
                    <SkeletonElement type="paragraph" />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}

      {/* moments */}
      {showMomentsFilter && (
        <div className="cardBox">
          <SkeletonElement type="title" mb="2rem" />
          <Row>
            {Array.from({ length: 4 }).map((item) => (
              <Col
                md={3}
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                }}
              >
                <SkeletonElement type="moment" mb="1rem" />
              </Col>
            ))}
          </Row>
        </div>
      )}

      {/* videos */}
      {showVideosFilter && (
        <div className="cardBox">
          <SkeletonElement type="title" mb="2rem" />
          <Row>
            {Array.from({ length: 8 }).map((item) => (
              <Col
                md={3}
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  marginBottom: "1rem",
                }}
              >
                <SkeletonElement type="video" mb="1rem" />
                <div className="infoBox">
                  <div className="infoBox__left">
                    <div className="avatar"></div>
                  </div>
                  <div className="infoBox__right">
                    <SkeletonElement type="paragraph" mb=".5rem" />
                    <SkeletonElement type="paragraph" />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </HomeSkeletonStyled>
  );
};

export default HomeSkeleton;

const HomeSkeletonStyled = styled.div`
  padding: 1.5rem 0;
  margin-bottom: 5rem;

  .filterBoxes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 96%;
    margin: 0 auto 2rem auto;
    position: relative;
    overflow: hidden;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  .banners {
    width: 90%;
    margin: 0 auto 4rem auto;
  }

  .cardBox {
    width: 90%;
    margin: 0 auto 4rem auto;

    .infoBox {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1rem;
      width: 100%;

      &__right {
        width: calc(100% - 3.5rem);
      }

      .avatar {
        height: 2.5rem;
        width: 2.5rem;
        margin-right: 1rem;
        background-color: #202020;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.03);
      }

      &__center {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
        transform: translateY(-2rem);

        .avatar {
          height: 3.5rem;
          width: 3.5rem;
          margin-right: 1rem;
          background-color: #202020;
          border-radius: 50%;
          border: 1px solid rgba(255, 255, 255, 0.1);
          margin-bottom: 0.5rem;
        }

        &--bottom {
          width: 100%;
        }
      }
    }
  }
`;
