import React, { useState } from "react";
import styled from "styled-components";
import ThreadCardSmall from "../../Cards/ThreadCardSmall";
import Title from "../../Elements/Title";
import Slider from "react-slick";
import {
  FEATURED_THREAD_SLIDERSETTING,
  FEATURED_THREAD_SLIDERSETTING_SWIPEDISABLED,
} from "../../../utils/sliderSettings";
import { Row, Col } from "react-grid-system";
import { useMediaQuery } from "react-responsive";
import Error from "../../Elements/Error";

const Threads = ({ threads }) => {
  const [midDrag, setMidDrag] = useState(false);
  const [stopSlider, setStopSlider] = useState(false);

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  return (
    <>
      {!threads?.length || threads === null ? null : (
        <ThreadsStyled>
          <Title className="sectionTitle">Featured threads</Title>
          {!threads?.length ? (
            <Error text="No featured threads" />
          ) : (
            <>
              {!isMobile ? (
                <>
                  {threads?.length > 4 ? (
                    <>
                      {!stopSlider ? (
                        <Slider
                          className="slider"
                          {...FEATURED_THREAD_SLIDERSETTING}
                          beforeChange={() => setMidDrag(true)}
                          afterChange={() => setMidDrag(false)}
                        >
                          {threads &&
                            threads.length > 0 &&
                            threads.map((item, i) => (
                              <div key={i} className="slider__item">
                                <ThreadCardSmall
                                  type={item.attachedType}
                                  item={item}
                                  midDrag={midDrag}
                                  stopSwipeParentSlider={setStopSlider}
                                />
                              </div>
                            ))}
                        </Slider>
                      ) : (
                        <Slider
                          className="slider"
                          {...FEATURED_THREAD_SLIDERSETTING_SWIPEDISABLED}
                          beforeChange={() => setMidDrag(true)}
                          afterChange={() => setMidDrag(false)}
                        >
                          {threads &&
                            threads.length > 0 &&
                            threads.map((item, i) => (
                              <div key={i} className="slider__item">
                                <ThreadCardSmall
                                  type={item.attachedType}
                                  item={item}
                                  midDrag={midDrag}
                                  stopSwipeParentSlider={setStopSlider}
                                />
                              </div>
                            ))}
                        </Slider>
                      )}
                    </>
                  ) : (
                    <>
                      <Row
                        style={{
                          width: "95%",
                          margin: "0 auto",
                        }}
                        className="row"
                      >
                        {threads &&
                          threads.length > 0 &&
                          threads.map((item, i) => (
                            <Col
                              style={{
                                paddingLeft: "5px",
                                paddingRight: "5px",
                              }}
                              key={i}
                              md={3}
                            >
                              <ThreadCardSmall
                                type={item.attachedType}
                                item={item}
                              />
                            </Col>
                          ))}
                      </Row>
                    </>
                  )}
                </>
              ) : (
                <>
                  <>
                    {threads?.length > 1 ? (
                      <>
                        {!stopSlider ? (
                          <Slider
                            className="slider"
                            {...FEATURED_THREAD_SLIDERSETTING}
                            beforeChange={() => setMidDrag(true)}
                            afterChange={() => setMidDrag(false)}
                          >
                            {threads &&
                              threads.length > 0 &&
                              threads.map((item, i) => (
                                <div key={i} className="slider__item">
                                  <ThreadCardSmall
                                    type={item.attachedType}
                                    item={item}
                                    midDrag={midDrag}
                                    stopSwipeParentSlider={setStopSlider}
                                  />
                                </div>
                              ))}
                          </Slider>
                        ) : (
                          <Slider
                            className="slider"
                            {...FEATURED_THREAD_SLIDERSETTING_SWIPEDISABLED}
                            beforeChange={() => setMidDrag(true)}
                            afterChange={() => setMidDrag(false)}
                          >
                            {threads &&
                              threads.length > 0 &&
                              threads.map((item, i) => (
                                <div key={i} className="slider__item">
                                  <ThreadCardSmall
                                    type={item.attachedType}
                                    item={item}
                                    midDrag={midDrag}
                                    stopSwipeParentSlider={setStopSlider}
                                  />
                                </div>
                              ))}
                          </Slider>
                        )}
                      </>
                    ) : (
                      <>
                        <Row
                          style={{
                            width: "95%",
                            margin: "0 auto",
                          }}
                          className="row"
                        >
                          {threads &&
                            threads.length > 0 &&
                            threads.map((item, i) => (
                              <Col
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                }}
                                key={i}
                                sm={12}
                              >
                                <ThreadCardSmall
                                  type={item.attachedType}
                                  item={item}
                                />
                              </Col>
                            ))}
                        </Row>
                      </>
                    )}
                  </>
                </>
              )}
            </>
          )}
        </ThreadsStyled>
      )}
    </>
  );
};

export default Threads;

const ThreadsStyled = styled.div`
  padding: 2rem 0;
  border-bottom: 1px solid rgba(242, 242, 247, 0.2);
  width: 100%;

  .sectionTitle {
    width: 95%;
    margin: 0 auto 1rem auto;
  }

  .slider {
    &__item {
      padding: 0 0.3rem;
    }
  }
`;
