import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import Error from "../../Elements/Error";
import ThreadCardSmall from "../../Cards/ThreadCardSmall";
import ThreadsSkeleton from "../../../skeletons/General/Section";
import { getThreadsOfUser } from "../../../actions/thread";
import styled from "styled-components";

const ThreadsTab = () => {
  const [threads, setThreads] = useState(null);
  const [hasMoreThreads, setHasMoreThreads] = useState(true);
  const [threadsLoading, setThreadsLoading] = useState(false);
  const [threadsError, setThreadsError] = useState(null);
  const threadsState = useSelector((state) => state.threads);
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const history = useHistory();

  const { ref: threadsTrackerRef, inView: threadsTrackIsVisible } = useInView();

  const HandleHome = () => {
    history.push("/home");
  };

  useEffect(() => {
    setThreadsLoading(threadsState?.isFetching);
    setThreads(threadsState?.userThreads);
    setThreadsError(threadsState?.error);
    setHasMoreThreads(threadsState?.hasMoreStreamsThreads);
  }, [threadsState]);

  useEffect(() => {
    dispatch(getThreadsOfUser(profile?.username));
  }, []);
  return (
    <ThreadsTabStyled>
      {threads && threads.length ? (
        <div className="threads">
          <Row>
            {threads.map((thread, i) => (
              <Col
                key={i}
                style={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                md={3}
                sm={6}
                xs={12}
              >
                <div className="threads__item">
                  <ThreadCardSmall
                    key={i}
                    item={thread}
                    type={thread?.attachedType}
                  />
                </div>
              </Col>
            ))}
          </Row>

          {threadsLoading && threads && (
            <ThreadsSkeleton
              numItems={3}
              noTitle
              fullWidth
              sectionName="featured_series"
            />
          )}

          {hasMoreThreads === false && (
            <div className="noMore">
              You have reached the end of threads results
            </div>
          )}

          {!threadsLoading && (
            <div ref={threadsTrackerRef} className="tracker" />
          )}
        </div>
      ) : (
        <div className="threads">
          {threadsLoading && (
            <ThreadsSkeleton
              numItems={3}
              fullWidth
              sectionName="featured_series"
            />
          )}

          {!threadsLoading && (!threads?.length || threadsError) && (
            <Error
              text="You Dont Have any Threads plese subscribe To AnyChannel"
              actionButtonText="Home"
              actionButton={HandleHome}
            />
          )}
        </div>
      )}
    </ThreadsTabStyled>
  );
};

export default ThreadsTab;

const ThreadsTabStyled = styled.div`
  .tracker {
    width: 100%;
    height: 5rem;
    background: transparent;
    opacity: 0;
  }

  .threads {
    width: 90%;
    margin: 2rem auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
    padding-bottom: 2rem;

    &__item {
      padding: 0 5px;
      margin-bottom: 1rem;
    }
  }

  .noMore {
    width: 100%;
    text-align: center;
    font-weight: 300;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1;
    margin: 1rem 0;
  }
`;
