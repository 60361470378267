import React, { useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import config from "../../config";
import ImageNotfoundPlaceholder from "../../assets/image_notfound_placeholder.svg";
import { addDefaultImage } from "../../utils";
import { toast } from "react-toastify";

const Carousel = ({ items, stopSwipeParentSlider }) => {
  const [currentCarousel, setCurrentCarousel] = useState(1);

  const SLIDERSETTINGS = {
    customPaging: function (i) {
      return <div className="carousel__dot" />;
    },
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 7000,
    adaptiveHeight: true,
    dots: true,
    pauseOnHover: true,
    swipeToSlide: true,
    beforeChange: (current, next) => setCurrentCarousel(next + 1),
  };

  if (!items?.length) {
    return null;
  }

  return (
    <CarouselStyled
      onMouseEnter={() => {
        if (stopSwipeParentSlider) {
          stopSwipeParentSlider(true);
        }
      }}
      onMouseLeave={() => {
        if (stopSwipeParentSlider) {
          stopSwipeParentSlider(false);
        }
      }}
    >
      <div className="progress">
        <div className="progress__active">{currentCarousel}</div>
        <div className="progress__total">/ {items.length}</div>
      </div>

      <Slider className="carousel" {...SLIDERSETTINGS}>
        {items.map((item, i) => (
          <div key={item?.id} className="carousel__item">
            <img
              onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
              className="carousel__item--banner"
              src={item?.url ? config.aws.CLOUDFRONT_URL + item.url : item}
              alt=""
            />
          </div>
        ))}
      </Slider>
    </CarouselStyled>
  );
};

export default Carousel;

const CarouselStyled = styled.div`
  margin-bottom: 1.5rem;
  position: relative;

  div {
    line-height: 1 !important;
  }

  .progress {
    z-index: 1;
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
    padding: 0.3rem 0.6rem;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 0.72rem;

    &__active {
      font-weight: 600;
    }
    &__total {
      font-weight: 300;
      padding-left: 3px;
    }
  }

  .carousel {
    position: relative;

    &__item {
      width: 100%;
      outline: none;

      &--banner {
        height: auto;
        width: 100%;
        background-size: cover;
        background-position: center;
        object-fit: cover;

        border-radius: 7px;
      }
    }

    &__dot {
      height: 0.5rem;
      width: 1rem;
      transform: skew(-25deg, 0);
      background: #fff;
      transition: all 0.4s ease;
    }

    .slick-dots {
      bottom: -1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        height: auto;
        width: auto;
        margin: 0 4px;
      }
    }

    .slick-active .carousel__dot {
      background: ${(props) => props.theme.primaryGradient} !important;
      width: 1.5rem;
    }
  }
`;
