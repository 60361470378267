import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import ArrowIcon from "../../../assets/Icons/arrow.svg";
import PlaylistCategory from "./PlaylistCategory";
import Skeleton from "../../../skeletons/PlaylistSkeleton";
import { GlobalContext } from "../../../context/GlobalContext";
import { getAllPlaylists, getAllPlaylistsHasmore } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Elements/Loader";
import Error from "../../Elements/Error";
import { useInView } from "react-intersection-observer";

const PlaylistsTab = ({ switchTab }) => {
  const dispatch = useDispatch();
  const {
    loading: playlistsLoading,
    length: totalpage,
    error: playlistsError,
    playlists,
  } = useSelector((state) => state.getPlaylists);

  const { info: createPlaylistInfo } = useSelector(
    (state) => state.createPlaylist
  );
  const profile = useSelector((state) => state.profile);
  
  const { message: editPlaylistMessage } = useSelector(
    (state) => state.editPlaylist
  );

  const { setShowPlaylistModelV2 } = useContext(GlobalContext);
  const isSelf = useSelector((state) => state.profile.isMe);
  const user = useSelector((state) => state.user);
  const [playlistPage, setPlaylistPage] = useState(1)
  const [playlistLimit, setPlaylistLimit] = useState(1)
  const { ref: trackerRef, inView: trackerIsVisible } = useInView();

  useEffect(() => {
    const playlistObj = {
      page: playlistPage,
      limit: playlistLimit
    }
    dispatch(getAllPlaylists(user.id, playlistObj));
  }, [createPlaylistInfo, editPlaylistMessage]);

  useEffect(() => {
    if (trackerIsVisible)

      setPlaylistPage(playlistPage + 1);
    const playlistObj = {
      page: playlistPage + 1,
      limit: playlistLimit,
    };
    const pageSize = Math.ceil(totalpage)

    if (pageSize) {
      if (playlistPage < pageSize) {
        dispatch(getAllPlaylistsHasmore(profile?.id, playlistObj));
      }
    }
  }, [trackerIsVisible])

  useEffect(() => {
    dispatch(getAllPlaylists(user.id, {}))
  }, [])
  return (
    <PlaylistsTabStyled>
      <div className="container">
        {isSelf && (
          <div
            onClick={() => setShowPlaylistModelV2(true)}
            className="container__createButton"
          >
            <div className="text">
              <div className="light">Create</div>
              <div className="bold">Playlist</div>
            </div>

            <img className="icon" src={ArrowIcon} alt="" />
          </div>
        )}

        <div className="container__row">
          {playlistsLoading && <Loader text="Please wait..." />}

          {!playlistsLoading && playlistsError && (
            <Error text="No playlists available." />
          )}

          {playlists &&
            playlists.length > 0 &&
            playlists.map((playlist, i) => {
              return (
                <PlaylistCategory
                  key={i}
                  isSelf={isSelf}
                  playlist={playlist}
                  switchTab={switchTab}
                />
              );
            })}

        </div>
        {!playlistsLoading && <div ref={trackerRef} className="tracker" />}
      </div>
    </PlaylistsTabStyled>
  );
};

export default PlaylistsTab;

const PlaylistsTabStyled = styled.div`
  min-height: 100vh;
  padding: 2.5rem 0;
  font-family: ${(props) => props.theme.montserrat};

  .container {
    width: 90%;
    min-width: 480px;
    margin: 0 auto;

    &__createButton {
      width: 50%;
      margin: 0 auto 4rem auto;
      background-color: #fff;
      padding: 0.8rem 1rem;
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #2c2c2e;
      font-size: 0.95rem;
      cursor: pointer;

      .text {
        display: flex;
        align-items: center;
        text-transform: capitalize;

        .light {
          font-weight: 400;
          padding-right: 0.5rem;
        }

        .bold {
          font-weight: 600;
        }
      }

      .icon {
        height: 1rem;
        width: auto;
      }
    }

    &__row {
      margin-bottom: 2rem;
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      &__createButton {
        width: 80%;
        font-size: 0.95rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 1rem 0;

    .container {
      min-width: 100%;

      &__createButton {
        width: 90%;
        font-size: 0.9rem;
        margin-bottom: 3rem;
      }
    }
  }
`;
