import { ISCHANNEL_SUBSCRIBED } from "../actions/types";

const initialState = {
  isFetching: true,
  isSubscribe: []
};

const channelSubscribed = (state = initialState, action) => {
  switch (action.type) {
    case ISCHANNEL_SUBSCRIBED:
      return action.payload;
    default:
      return state;
  }
};

export default channelSubscribed;
