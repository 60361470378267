import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ArrowIcon from "../../../assets/Icons/arrow.svg";
import SeriesCategory from "./SeriesCategory";
import Loader from "../../Elements/Loader";
import Error from "../../Elements/Error";
import PurchasedSeriesCard from "../../Cards/PurchasedSeriesCard";
import { notificationMessage } from "../../../utils/toastNotifications";
import { toast } from "react-toastify";
import {
  ADD_VIDEO_TO_SERIES_RESET,
  REMOVE_VIDEO_FROM_SERIES_RESET,
  EDIT_SERIES_RESET,
  DELETE_SERIES_RESET,
  CREATE_NEW_SERIES_RESET,
} from "../../../actions/types";
import { GlobalContext } from "../../../context/GlobalContext";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllSeriesOfUser,
  getUserVideos,
  getAllPurchasedSeries,
  getAllSeriesOfUserHasmore,
} from "../../../actions";
import { Row, Col } from "react-grid-system";
import { useHistory } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const SeriesTab = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    loading: getSeriesLoading,
    length: totalpage,
    series: allSeries,
    error: getSeriesError,
  } = useSelector((state) => state.getAllSeriesOfUser);
  const {
    loading: getPurchasedSeriesLoading,
    series: allPurchasedSeries,
    error: getPurchasedSeriesError,
  } = useSelector((state) => state.purchasedSeries);

  const { profile } = useSelector((state) => state);
  const isSelf = useSelector((state) => state.profile.isMe);
  const { message: addVideoToSeriesMessage } = useSelector(
    (state) => state.addVideoToSeries
  );
  const { message: removeVideoFromSeriesMessage } = useSelector(
    (state) => state.removeVideoFromSeries
  );
  const {
    setShowCreateSeriesModel,
    editSeriesModel,
    showCreateSeriesModel,
    deleteSeriesConfirmationModel,
  } = useContext(GlobalContext);
  const { message: editMessage } = useSelector((state) => state.editSeries);
  const { message: deleteMessage } = useSelector((state) => state.deleteSeries);
  const { info: createMessage } = useSelector((state) => state.createSeries);

  // states
  const [showPurchasedSeries, setShowPurchasedSeries] = useState(false);
  const [seriesPage, setSeriesPage] = useState(1)
  const [seriesLimit, setSeriesLimit] = useState(2)
  const { ref: trackerRef, inView: trackerIsVisible } = useInView();

  const queryParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (queryParams.get("showPurchasedSeries")) {
      setShowPurchasedSeries(true);
    }
  }, [queryParams]);

  useEffect(() => {
    const seriesObj = {
      page: seriesPage,
      limit: seriesLimit,
    };
    dispatch(getAllSeriesOfUser(profile?.username, false, seriesObj));
    dispatch(
      getUserVideos({
        showAll: true,
      })
    );
  }, []);

  useEffect(() => {
    if (showPurchasedSeries) {
      dispatch(getAllPurchasedSeries());
    }
  }, [showPurchasedSeries]);

  useEffect(() => {
    if (
      editSeriesModel === false &&
      (addVideoToSeriesMessage || removeVideoFromSeriesMessage)
    ) {
      dispatch(getAllSeriesOfUser(profile?.username));

      dispatch({
        type: ADD_VIDEO_TO_SERIES_RESET,
      });
      dispatch({
        type: REMOVE_VIDEO_FROM_SERIES_RESET,
      });
    }

    if (editSeriesModel === false && editMessage) {
      dispatch(getAllSeriesOfUser(profile?.username));

      dispatch({
        type: EDIT_SERIES_RESET,
      });
    }

    if (deleteSeriesConfirmationModel === false && deleteMessage) {
      dispatch(getAllSeriesOfUser(profile?.username));

      dispatch({
        type: DELETE_SERIES_RESET,
      });
    }

    if (showCreateSeriesModel === false && createMessage) {
      dispatch(getAllSeriesOfUser(profile?.username));
      toast.success(notificationMessage.seriesCreated);

      dispatch({
        type: CREATE_NEW_SERIES_RESET,
      });
    }
  }, [
    editSeriesModel,
    removeVideoFromSeriesMessage,
    addVideoToSeriesMessage,
    editMessage,
    deleteMessage,
    createMessage,
  ]);

  useEffect(() => {
    if (trackerIsVisible) {

      console.log('here')
      setSeriesPage(seriesPage + 1);
      const seriesObj = {
        page: seriesPage + 1,
        limit: seriesLimit,
      };
      const pageSize = Math.ceil(totalpage)
      console.log(pageSize)
      if (pageSize) {
        if (seriesObj.page <= pageSize) {
          dispatch(
            getAllSeriesOfUserHasmore(
              profile?.username,
              false,
              seriesObj
            )
          );
        }
      }
    }
  }, [trackerIsVisible]);

  return (
    <>
      <SeriesTabStyled>
        <div className="container">
          {isSelf && (
            <>
              <div
                onClick={() => setShowCreateSeriesModel(true)}
                className="container__createButton"
              >
                <div className="text">
                  <div className="light">Create</div>
                  <div className="bold">Series</div>
                </div>

                <img className="icon" src={ArrowIcon} alt="" />
              </div>

              <div
                onClick={() => setShowPurchasedSeries(!showPurchasedSeries)}
                className="container__goToPurchases"
              >
                <div className="text">
                  <div className="light">Go to your</div>
                  <div className="bold">
                    {showPurchasedSeries ? "Series" : "Purchased Series"}
                  </div>
                </div>

                <img className="icon" src={ArrowIcon} alt="" />
              </div>
            </>
          )}

          <div className="container__row">
            {showPurchasedSeries ? (
              <>
                {getPurchasedSeriesLoading && <Loader text="Please wait..." />}
                {!getPurchasedSeriesLoading && getPurchasedSeriesError && (
                  <Error
                    text="No purchased series found!"
                    actionButton={() => {
                      history.push(`/series`);
                    }}
                  />
                )}

                {allPurchasedSeries && allPurchasedSeries.length > 0 && (
                  <>
                    <Row>
                      {allPurchasedSeries.map((item, i) => (
                        <Col
                          key={i}
                          lg={3}
                          md={6}
                          sm={12}
                          xs={12}
                          className="container__videos--col"
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <PurchasedSeriesCard item={item} />
                        </Col>
                      ))}
                    </Row>
                  </>
                )}

              </>
            ) : (
              <>
                {getSeriesLoading && <Loader text="Please wait..." />}

                {!getSeriesLoading && getSeriesError && (
                  <Error text="No series found!" />
                )}

                {!isSelf &&
                  !getSeriesLoading &&
                  allSeries?.filter((item) => item.published === true).length ===
                  0 && <Error text="No series found!" />}

                {allSeries && allSeries.length > 0 && (
                  <SeriesCategory isSelf={isSelf} allSeries={allSeries} />
                )}
                {!getSeriesLoading && <div ref={trackerRef} className="tracker" />}
              </>
            )}
          </div>
        </div>
      </SeriesTabStyled>
    </>
  );
};

export default SeriesTab;

const SeriesTabStyled = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 2.5rem 0;
  font-family: ${(props) => props.theme.montserrat};

  .container {
    width: 90%;
    margin: 0 auto;

    &__createButton,
    &__goToPurchases {
      user-select: none;
      width: 50%;
      padding: 0.8rem 1rem;
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.95rem;
      cursor: pointer;

      .text {
        display: flex;
        align-items: center;

        .light {
          font-weight: 400;
          padding-right: 0.5rem;
        }

        .bold {
          font-weight: 600;
        }
      }

      .icon {
        height: 1rem;
        width: auto;
      }
    }

    &__createButton {
      margin: 0 auto 1rem auto;
      background-color: #fff;
      color: #2c2c2e;
    }

    &__goToPurchases {
      margin: 0 auto 4rem auto;
      background-color: #3a3a3c;
      color: #fff;
    }

    &__row {
      margin-bottom: 2rem;
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      width: 90%;
      margin: 0 auto;

      &__createButton,
      &__goToPurchases {
        width: 80%;
        font-size: 0.9rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 1rem 0;

    .container {
      width: 100%;

      &__createButton,
      &__goToPurchases {
        width: 100%;
        font-size: 0.85rem;
      }

      &__goToPurchases {
        margin: 0 auto 3rem auto;
      }
    }
  }
`;
