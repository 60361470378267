import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AdminSidebar from "../AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { IoTrashSharp } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import Pagination from "../Pagination";
import { adminAllMomentsData, deleteAdminMoment } from "../../actions";
import ConfirmationModel from "../../components/ConfirmationModel";
import MomentEditTable from "./MomentEditTable";
import config from "../../config";

const MomentTable = ({ close, moment, midDrag }) => {
  const momentData = useSelector((state) => state.momentTableData.momentData);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [momentFormData, setMomentFormData] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [sortColumn, setSortColumn] = useState("caption");
  const [sortOrder, setSortOrder] = useState("asc");
  const itemsPerPage = 5;

  useEffect(() => {
    dispatch(adminAllMomentsData()).then(() => {});
  }, [dispatch]);

  //   serching------------------
  const filterData = () => {
    return momentData
      ? momentData.filter((row) => {
          const lowerSearch = search.toLowerCase();
          return (
            (row.caption && row.caption.toLowerCase().includes(lowerSearch)) ||
            (row.userId && row.userId.toLowerCase().includes(lowerSearch)) ||
            (row.muxid && row.muxid.toLowerCase().includes(lowerSearch))
          );
        })
      : [];
  };
  // Pagination -------------
  const filteredData = filterData();
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //   Edit ------------------------
  const handleEdit = (row) => {
    setIsEditing(true);
    setMomentFormData({
      id: row.id,
      caption: row.caption,
      userId: row.userId,
      muxid: row.muxid,
      createdAt: row.createdAt,
      updatedAt: row.updatedAt,
    });
  };
  const handleFormChange = (field, value) => {
    setMomentFormData({ ...momentFormData, [field]: value });
  };

  //   Delete-------------------
  const handleDelete = (row) => {
    setShowDeleteConfirmation(true);
    setMomentFormData({ id: row.id });
  };
  const deleteVideoHandler = async () => {
    // console.log(momentFormData.id, "form moment");
    try {
      await dispatch(deleteAdminMoment(momentFormData.id));
      // console.log(momentFormData.id);
      setShowDeleteConfirmation(false);
      dispatch(adminAllMomentsData());
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };
  // Sorting data ----------------------
  const sortData = () => {
    const sortedData = [...filteredData];
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortColumn].localeCompare(b[sortColumn]);
      } else {
        return b[sortColumn].localeCompare(a[sortColumn]);
      }
    });
    return sortedData;
  };
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };
  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="tableHeader">SR.No</TableCell>
          <TableCell
            className="tableHeader"
            onClick={() => handleSort("caption")}
          >
            Caption{" "}
            {sortColumn === "caption" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell
            className="tableHeader"
            onClick={() => handleSort("caption")}
          >
            User ID{" "}
            {sortColumn === "caption" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell className="tableHeader">Mux ID</TableCell>
          <TableCell className="tableHeader">Cover Img URL</TableCell>

          <TableCell
            className="tableHeader"
            onClick={() => handleSort("caption")}
          >
            Craeted At{" "}
            {sortColumn === "caption" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell
            className="tableHeader"
            onClick={() => handleSort("caption")}
          >
            Update At{" "}
            {sortColumn === "caption" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell className="tableHeader">Action</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      <MomentDashboard>
        <AdminSidebar />
        <TableContainer component={Paper} className="tableContainer">
          <div>
            <h1 className="headingText">Search The Moments</h1>
            <input
              type="text"
              placeholder="Search The Moment Table"
              className="searchBox"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <Table className="table">
            {renderTableHeader()}
            <TableBody>
              {sortData()
                .slice(startIndex, endIndex)
                .map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell className="tableCell">
                      {startIndex + index + 1}
                    </TableCell>
                    <TableCell className="tableCell">{row.caption}</TableCell>
                    <TableCell className="tableCell">{row.userId}</TableCell>
                    <TableCell className="tableCellmuxId">
                      {row.muxid}
                    </TableCell>
                    <TableCell className="tableCellmuxId" align="center">
                      <img
                        src={config.aws.CLOUDFRONT_URL + row.coverImgUrl}
                        alt="Thumbnail"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    </TableCell>
                    <TableCell className="tableCell">{row.createdAt}</TableCell>
                    <TableCell className="tableCell">{row.updatedAt}</TableCell>

                    <TableCell className="tableCell">
                      <FiEdit
                        style={{ margin: "8px" }}
                        className="editButton"
                        size={28}
                        onClick={() => handleEdit(row)}
                      />
                      <IoTrashSharp
                        className="deleteButton"
                        style={{ margin: "8px" }}
                        size={28}
                        onClick={() => handleDelete(row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div style={{ marginTop: "7px", marginBottom: "10%" }}>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={momentData ? momentData.length : 0}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </TableContainer>
      </MomentDashboard>
      {isEditing && (
        <MomentEditTable
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          momentFormData={momentFormData}
          onChange={handleFormChange}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationModel
          closeHandler={() => setShowDeleteConfirmation(false)}
          title="Are you sure you want to delete this Moment? You can't undo this action."
          deleteHandler={deleteVideoHandler}
        />
      )}
    </>
  );
};

export default MomentTable;

const MomentDashboard = styled.div`
  display: flex;

  .tableContainer {
    max-width: 100%;
    height: 100vh;
    background: #212123;
    padding-left: 2%;
    padding-right: 2%;
  }
  .table {
    background: #212123;
  }
  .tableHeader {
    background: #212123;
    color: white;
    border: 1px solid;
    font-size: 13px;
    cursor: pointer;
  }
  .tableCell {
    color: white;
    border: 1px solid;
    font-size: 13px;
  }
  .tableCellmuxId {
    color: white;
    border: 1px solid;
    font-size: 13px;
    word-break: break-all;
  }
  .editButton {
    color: #4f92f7;
    marginleft: 10px;
    transition: transform 0.2s;
  }
  .editButton:hover {
    transform: scale(1.3);
  }

  .deleteButton {
    color: #f54e4e;
    marginleft: 10px;
    transition: transform 0.2s;
  }
  .deleteButton:hover {
    transform: scale(1.3);
  }

  .headingText {
    font-size: 18px;
    margin-top: 10px;
    color: white;
  }
  .searchBox {
    background: #2c2c2e !important;
    color: white;
    margin-bottom: 2%;
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: #cdc5c5;
    }
  }

  /* Responsive styling for the search box */
  @media (max-width: 768px) {
    .SearchBox {
      width: 100%;
    }
  }
`;
