import styled from "styled-components";

export const SignupStyles = styled.div`
  .title {
    font-size: 1.1rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 2rem;
    font-weight: 500;
  }

  .alternateOption {
    font-size: 0.75rem;
    text-decoration: underline;
    font-weight: 300;
    cursor: pointer;
    display: block;
  }

  .field {
    margin-bottom: 1rem;

    label {
      font-size: 0.9rem;
      font-weight: 500;
      line-height: 1;
      margin-bottom: 0.4rem;
    }
  }

  .passwordStrength {
    width: 30%;
    margin-top: 1rem;
    padding: 1rem 1rem 0.5rem 1rem;
    background-color: #181818;
    border-radius: 0.6rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    font-weight: 500;
    text-transform: capitalize;
    font-size: 0.7rem;

    p {
      color: #fff !important;
    }
  }

  .toggleBox {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;

    span {
      margin-left: 0.7rem;
      font-size: 0.85rem;
      line-height: 1.4;
      color: #999;
      font-weight: 500;
    }

    .react-switch-bg {
      text-transform: uppercase !important;
      font-weight: 600;
      font-size: 0.7rem;
      .yes,
      .no {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .checkBox {
    p {
      font-size: 0.8rem;
      font-weight: 500;
      text-decoration: underline;
      color: #2192ff;
    }
  }

  .row {
    &__one {
      margin-bottom: 2rem;
    }

    &__two {
      margin-bottom: 2rem;
    }
  }

  .paragraph {
    font-weight: 300;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 2rem;
  }

  .submit {
    margin-top: 2rem;
    width: 100%;
  }

  .actionRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #202020;
    padding: 1rem 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);

    &__submit {
      display: inline-block;
    }
  }

  /* membership styles */
  .membership {
    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;

      &--item {
        width: 80%;
        margin: 0 auto 2rem auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;
        border-radius: 5px;
        cursor: pointer;
        position: relative;

        .indicator {
          position: absolute;
          top: 0.75rem;
          right: 0.75rem;
          height: 2.2rem;
          width: 2.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: #000;

          &__icon {
            height: 1rem;
            width: auto;
          }
        }

        .header {
          margin-bottom: 2.05rem;
          display: flex;
          flex-direction: column;
          align-items: center;

          &__name {
            text-transform: uppercase;
            font-weight: 600;
            color: #000;
            line-height: 1;
            font-size: 1.2rem;
          }

          &__small {
            line-height: 1;
            padding-top: 2px;
            font-size: 0.8rem;
            text-transform: capitalize;
            color: rgba(0, 0, 0, 0.5);
          }
        }

        .paragraph {
          color: #202020;
          font-weight: 400;
          text-align: center;
          line-height: 1.4;
          margin-bottom: 2rem;
          width: 70%;
          font-size: 0.9rem;

          .bold {
            font-weight: 600;
            font-size: 0.75rem;
            margin-top: 3px;
          }
        }

        .button {
          &__tribe {
            color: #fff;
            font-weight: 500;

            &:hover {
              color: ${(props) => props.theme.primaryColor1};
            }
          }

          &__premium {
            color: #fff;
            font-weight: 500;

            &:hover {
              color: ${(props) => props.theme.primaryColor1};
            }
          }
        }
      }

      .basic {
        background-color: #f2f2f7;

        .bold {
          color: ${(props) => props.theme.primaryColor1};
        }
      }

      .tribe,
      .premium {
        .paragraph {
          color: white;
          text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
        }

        .header {
          &__name {
            color: #fff;
            margin-bottom: 3px;
          }

          &__small {
            color: #fde5b8;
            font-weight: 500;
            font-size: 0.7rem;
          }
        }
      }

      .tribe {
        background-color: ${(props) => props.theme.primaryColor1};
      }
      .premium {
        background: ${(props) => props.theme.primaryGradient};
      }
    }
  }

  /* badge */
  .badge {
    &__list {
      display: flex;
      flex-direction: column;

      .selected {
        position: absolute;
        top: 0;
        right: 1rem;
        padding: 6px 8px;
        color: #202020;
        background-color: #fff;
        font-size: 0.7rem;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        &__item {
          background: ${(props) => props.theme.primaryGradient} !important;
        }
      }

      &--item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        border-radius: 6px;
        transition: all 0.5s ease;
        margin-bottom: 1rem;
        position: relative;

        &:hover {
          background: ${(props) => props.theme.primaryGradient};
          cursor: pointer;
        }

        .icon {
          height: 5rem;
          width: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .info {
          width: calc(100% - 6rem);
          padding: 0 0.5rem;

          .name {
            font-size: 1.1rem;
            font-weight: 500;
          }
        }
      }
    }
  }

  /* storage */
  .storage {
    &__list {
      .selectedCard {
        background-image: ${(props) => props.theme.primaryGradient};
        color: #fff !important;

        h2 {
          background: linear-gradient(to right, #fff, #fff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .main {
          .icon {
            background-image: linear-gradient(145deg, #fff 0%, #fff 100%);
            i {
              color: #999;
            }
          }
        }

        .title {
          color: #fff;
        }
      }

      &--item {
        background-color: #fff6dc;
        margin: 0 0 1.5rem 0;
        border-radius: 0.4rem;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-out;
        cursor: pointer;
        position: relative;
        padding: 1.5rem 1rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: #000;

        .Selected {
          position: absolute;
          bottom: 1rem;
          right: 1rem;
          font-size: 1.5rem;
          transition: all 0.3s ease;
        }

        span {
          text-transform: uppercase;
          font-size: 1.1rem;
          font-weight: 600;
          margin-bottom: 1rem;
          text-align: center;
          line-height: 1.3;
        }

        .main {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;
        }

        h2 {
          font-size: 2.2rem;
          font-weight: 600;
          background: ${(props) => props.theme.primaryGradient};
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .icon {
          height: 4rem;
          width: 4rem;
          border-radius: 50%;
          background-image: ${(props) => props.theme.primaryGradient};
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.2s ease;

          i {
            font-size: 2rem;
          }
        }

        .title {
          display: flex;
          align-items: center;
          color: rgba(0, 0, 0, 0.8);
          margin-bottom: 0;

          p {
            padding-bottom: 0.8rem;
            font-size: 0.9rem;
            line-height: 1.2;
          }

          h1 {
            font-size: 2rem;
            font-weight: 500;
          }
        }

        &:hover {
          background-image: ${(props) => props.theme.primaryGradient};
          color: #fff !important;

          h2 {
            background: linear-gradient(to right, #fff, #fff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .title {
            color: #fff;
          }
        }

        &:hover {
          .main {
            .icon {
              background-image: linear-gradient(145deg, #fff 0%, #fff 100%);
              i {
                color: #999;
              }
            }
          }
        }
      }
    }
  }

  /* payment */
  .payment {
    &__summary {
      background: ${(props) => props.theme.primaryGradient};
      padding: 1rem;
      border-radius: 5px;
      margin-bottom: 2rem;

      .title {
        margin-bottom: 1rem;
        font-size: 1.8rem;
        text-transform: uppercase;
        line-height: 1;
        font-family: brother-1816, sans-serif;
      }

      .item {
        display: flex;
        align-items: center;
        font-size: 0.95rem;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 1rem;
        padding-left: 0.5rem;

        &__icon {
          height: 1.2rem;
          width: auto;
          margin-right: 0.5rem;
        }

        &__bold {
          font-weight: 600;
          padding-left: 0.5rem;
        }

        &__switch {
          padding-left: 0.5rem;
          text-decoration: underline;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 0.65rem;
          cursor: pointer;
        }
      }
    }

    &__withoutCheckout {
      margin-top: 3rem;

      &--submit {
        width: 100%;
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  /* responsive */
  @media screen and (max-width: 480px) {
    .membership {
      &__list {
        &--item {
          width: 100%;
        }
      }
    }

    .payment {
      &__summary {
        .title {
          font-size: 1.5rem;
        }

        .item {
          font-size: 0.8rem;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
        }
      }
    }
  }
`;
