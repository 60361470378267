import {
  GET_USER_STREAMS_REQUEST,
  GET_USER_STREAMS_SUCCESS,
  GET_USER_STREAMS_FAIL,
} from "../actions/types";

const streams = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_STREAMS_REQUEST:
      return {
        loading: true,
      };
    case GET_USER_STREAMS_SUCCESS:
      return {
        loading: false,
        streams: action.payload,
      };
    case GET_USER_STREAMS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default streams;
