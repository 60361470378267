const initialState = {
  momentData: null,
  error: null,
};

const momentTableData = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_MOMENT_DATA_SUCCESS":
      return {
        ...state,
        momentData: action.payload,
        error: null,
      };
    case "FETCH_MOMENT_DATA_FAILURE":
      return {
        ...state,
        momentData: null,
        error: action.error,
      };
    case "UPDATE_MOMENT":
      const updatedIndex = state.momentData.findIndex(
        (moment) => moment.id === action.payload.id
      );

      if (updatedIndex !== -1) {
        const updatedMomentData = [...state.momentData];
        updatedMomentData[updatedIndex] = action.payload;

        return {
          ...state,
          momentData: updatedMomentData,
        };
      }
      return state;

    default:
      return state;
  }
};

export default momentTableData;
