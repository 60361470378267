import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../../components/Elements/Input";
import Button from "../../components/Elements/Button";
import { Row, Col } from "react-grid-system";
import { adminGetBannerSlider, updateWidgetBanner } from "../../actions";
import { useDispatch } from "react-redux";
import { uploadVistorBadge } from "../../utils";
import { toast } from "react-toastify";

const EditWidegetBanner = ({ isOpen, onClose, formData, onChange }) => {
  const dispatch = useDispatch();
  const [updatedImageUrl, setUpdatedImageUrl] = useState(null);

  useEffect(() => {
    dispatch(adminGetBannerSlider());
  }, [dispatch]);

  const UpdateWidegtBanner = async () => {
    try {
      const updatedFormData = {
        ...formData,
        ...updatedImageUrl,
      };
      await dispatch(updateWidgetBanner(updatedFormData));
      await dispatch(adminGetBannerSlider());
      onClose();
    } catch (error) {
      console.error("Error updating widget banner:", error);
    }
  };
  const handleIconChange = async (e, field) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").pop();
    const allowedFileTypes = ["jpg", "jpeg", "png"];

    if (!allowedFileTypes.includes(fileExtension)) {
      toast.error(
        `Image extension does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
      return;
    }
    toast.info("Uploading...");

    if (file) {
      try {
        const { url } = await uploadVistorBadge("image", file);
        toast.success("Image uploaded successfully");
        setUpdatedImageUrl((prevUrls) => ({
          ...prevUrls,
          [field]: url,
        }));
        onChange(field, url);
      } catch (error) {
        toast.error("Image upload failed. Please try again.");
      }
    }
  };

  return (
    <WidegtBannerEditModal open={isOpen}>
      <WidegetBannerEditContent>
        <div className="title">Edit Wideget Banner Slider </div>
        <div className="field">
          <Row className="row row__one">
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Title"
                  name="title"
                  id="title"
                  placeholder="title"
                  type="text"
                  value={formData.title}
                  onChange={(e) => onChange("title", e.target.value)}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="description"
                  name="description"
                  id="description"
                  placeholder="description"
                  type="text"
                  value={formData.description}
                  onChange={(e) => onChange("description", e.target.value)}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="userId"
                  name="userId"
                  id="userId"
                  placeholder="userId"
                  type="text"
                  value={formData.userId}
                  onChange={(e) => onChange("userId", e.target.value)}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="categoryId"
                  name="categoryId"
                  id="categoryId"
                  placeholder="categoryId"
                  type="text"
                  value={formData.categoryId}
                  onChange={(e) => onChange("categoryId", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="bannerLocation"
                  name="bannerLocation"
                  id="bannerLocation"
                  placeholder="bannerLocation"
                  type="text"
                  value={formData.bannerLocation}
                  onChange={(e) => onChange("bannerLocation", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="order"
                  name="order"
                  id="order"
                  placeholder="order"
                  type="text"
                  value={formData.order}
                  onChange={(e) => onChange("order", e.target.value)}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="bannerImgPathInternal_XL_1920x400"
                  name="bannerImgPathInternal_XL_1920x400"
                  id="bannerImgPathInternal_XL_1920x400"
                  placeholder="bannerImgPathInternal_XL_1920x400"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleIconChange(e, "bannerImgPathInternal_XL_1920x400")
                  }
                />
                <label style={{ color: "#bec3c7" }}>
                  {formData.bannerImgPathInternal_XL_1920x400}{" "}
                </label>
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="bannerImgPathInternal_L_1366x400"
                  name="bannerImgPathInternal_L_1366x400"
                  id="bannerImgPathInternal_L_1366x400"
                  placeholder="bannerImgPathInternal_L_1366x400"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  // onChange={handleIconChange}
                  onChange={(e) =>
                    handleIconChange(e, "bannerImgPathInternal_L_1366x400")
                  }
                />
                <label style={{ color: "#bec3c7" }}>
                  {formData.bannerImgPathInternal_L_1366x400}{" "}
                </label>
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="bannerImgPathInternal_L_1280x400"
                  name="bannerImgPathInternal_L_1280x400"
                  id="bannerImgPathInternal_L_1280x400"
                  placeholder="bannerImgPathInternal_L_1280x400"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleIconChange(e, "bannerImgPathInternal_L_1280x400")
                  }
                />
                <label style={{ color: "#bec3c7" }}>
                  {formData.bannerImgPathInternal_L_1280x400}{" "}
                </label>
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="bannerImgPathInternal_L_1024x400"
                  name="bannerImgPathInternal_L_1024x400"
                  id="bannerImgPathInternal_L_1024x400"
                  placeholder="bannerImgPathInternal_L_1024x400"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleIconChange(e, "bannerImgPathInternal_L_1024x400")
                  }
                />
                <label style={{ color: "#bec3c7" }}>
                  {formData.bannerImgPathInternal_L_1024x400}{" "}
                </label>
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="bannerImgPathInternal_MD_834x400"
                  name="bannerImgPathInternal_MD_834x400"
                  id="bannerImgPathInternal_MD_834x400"
                  placeholder="bannerImgPathInternal_MD_834x400"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleIconChange(e, "bannerImgPathInternal_MD_834x400")
                  }
                />
                <label style={{ color: "#bec3c7" }}>
                  {formData.bannerImgPathInternal_MD_834x400}{" "}
                </label>
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="bannerImgPathInternal_MD_768x400"
                  name="bannerImgPathInternal_MD_768x400"
                  id="bannerImgPathInternal_MD_768x400"
                  placeholder="bannerImgPathInternal_MD_768x400"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleIconChange(e, "bannerImgPathInternal_MD_768x400")
                  }
                />
                <label style={{ color: "#bec3c7" }}>
                  {formData.bannerImgPathInternal_MD_768x400}{" "}
                </label>
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="bannerImgPathInternal_SM_428x250"
                  name="bannerImgPathInternal_SM_428x250"
                  id="bannerImgPathInternal_SM_428x250"
                  placeholder="bannerImgPathInternal_SM_428x250"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleIconChange(e, "bannerImgPathInternal_SM_428x250")
                  }
                />
                <label style={{ color: "#bec3c7" }}>
                  {formData.bannerImgPathInternal_SM_428x250}{" "}
                </label>
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="bannerImgPathInternal_SM_414x250"
                  name="bannerImgPathInternal_SM_414x250"
                  id="bannerImgPathInternal_SM_414x250"
                  placeholder="bannerImgPathInternal_SM_414x250"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleIconChange(e, "bannerImgPathInternal_SM_414x250")
                  }
                />
                <label style={{ color: "#bec3c7" }}>
                  {formData.bannerImgPathInternal_SM_414x250}{" "}
                </label>
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="bannerImgPathInternal_SM_375x250"
                  name="bannerImgPathInternal_SM_375x250"
                  id="bannerImgPathInternal_SM_375x250"
                  placeholder="bannerImgPathInternal_SM_375x250"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleIconChange(e, "bannerImgPathInternal_SM_375x250")
                  }
                />
                <label style={{ color: "#bec3c7" }}>
                  {formData.bannerImgPathInternal_SM_375x250}{" "}
                </label>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="row row__one">
          <Col md={6} sm={6} xs={6}>
            <Button mode={"secondary"} className="submit" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <Button className="submit" onClick={UpdateWidegtBanner}>
              Save
            </Button>
          </Col>
        </Row>
      </WidegetBannerEditContent>
    </WidegtBannerEditModal>
  );
};

export default EditWidegetBanner;

const WidegtBannerEditModal = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WidegetBannerEditContent = styled.div`
  background: #2c2c2e;
  color: white;
  border: 1px solid white;
  padding: 20px;
  max-width: 800px;
  width: 100%;
  height: 100vh;
  overflow: scroll;

  .title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .submit {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdownBox {
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    margin-top: 3px;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;
