export const GET_MARKETING_PAGE_REQUEST = "GET_MARKETING_PAGE_REQUEST";
export const GET_MARKETING_PAGE_SUCCESS = "GET_MARKETING_PAGE_SUCCESS";
export const GET_MARKETING_PAGE_FAIL = "GET_MARKETING_PAGE_FAIL";

export const ADD_MARKETING_FAQ_ITEM_REQUEST = "ADD_MARKETING_FAQ_ITEM_REQUEST";
export const ADD_MARKETING_FAQ_ITEM_SUCCESS = "ADD_MARKETING_FAQ_ITEM_SUCCESS";
export const ADD_MARKETING_FAQ_ITEM_FAIL = "ADD_MARKETING_FAQ_ITEM_FAIL";
export const REMOVE_MARKETING_FAQ_ITEM_SUCCESS =
  "REMOVE_MARKETING_FAQ_ITEM_SUCCESS";

export const ADD_MARKETING_WHYUS_SECTION_REQUEST =
  "ADD_MARKETING_WHYUS_SECTION_REQUEST";
export const ADD_MARKETING_WHYUS_SECTION_SUCCESS =
  "ADD_MARKETING_WHYUS_SECTION_SUCCESS";
export const ADD_MARKETING_WHYUS_SECTION_FAIL =
  "ADD_MARKETING_WHYUS_SECTION_FAIL";

export const ADD_MARKETING_PRICING_SECTION_REQUEST =
  "ADD_MARKETING_PRICING_SECTION_REQUEST";
export const ADD_MARKETING_PRICING_SECTION_SUCCESS =
  "ADD_MARKETING_PRICING_SECTION_SUCCESS";
export const ADD_MARKETING_PRICING_SECTION_FAIL =
  "ADD_MARKETING_PRICING_SECTION_FAIL";

export const ADD_MARKETING_STATS_SECTION_REQUEST =
  "ADD_MARKETING_STATS_SECTION_REQUEST";
export const ADD_MARKETING_STATS_SECTION_SUCCESS =
  "ADD_MARKETING_STATS_SECTION_SUCCESS";
export const ADD_MARKETING_STATS_SECTION_FAIL =
  "ADD_MARKETING_STATS_SECTION_FAIL";

export const ADD_MARKETING_ABOUT_SECTION_REQUEST =
  "ADD_MARKETING_ABOUT_SECTION_REQUEST";
export const ADD_MARKETING_ABOUT_SECTION_SUCCESS =
  "ADD_MARKETING_ABOUT_SECTION_SUCCESS";
export const ADD_MARKETING_ABOUT_SECTION_FAIL =
  "ADD_MARKETING_ABOUT_SECTION_FAIL";

export const ADD_MARKETING_FOOTER_SECTION_REQUEST =
  "ADD_MARKETING_FOOTER_SECTION_REQUEST";
export const ADD_MARKETING_FOOTER_SECTION_SUCCESS =
  "ADD_MARKETING_FOOTER_SECTION_SUCCESS";
export const ADD_MARKETING_FOOTER_SECTION_FAIL =
  "ADD_MARKETING_FOOTER_SECTION_FAIL";

export const RESET_MARKETING_PAGE_STATE = "RESET_MARKETING_PAGE_STATE";
