import React, { useContext, useEffect } from "react";
import Button from "../../Elements/Button";
import ChevronLeftIcon from "../../icons/chevronleft.svg";
import Loader from "../../Elements/Loader";
import { AuthContext } from "../../../context/AuthContext";
import { elementScrollToTop } from "../../../utils";

const Badge = ({ setProgress }) => {
  const {
    choosePackage,
    badges,
    badgesLoading,
    selectedBadge,
    setSelectedBadge,
    setValidationError,
    setBadgeObj,
  } = useContext(AuthContext);

  useEffect(() => {
    elementScrollToTop("authContent");
  }, []);

  const nextHandler = () => {
    if (!selectedBadge) {
      return setValidationError({
        type: "error",
        message:
          "Please choose a badge. Note: you can change it anytime from your profile.",
      });
    }

    if (choosePackage === "FREE" || choosePackage === "BASIC") {
      setValidationError(null);
      setProgress(6);
    } else {
      setValidationError(null);
      setProgress(4);
    }
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextHandler();
      document.getElementById("triggerBtn").click();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, []);

  return (
    <>
      <div className="actionRow">
        <Button
          size="small"
          variant="outline"
          mode="secondary"
          iconLeft={true}
          onClick={() => {
            setProgress(2);
            setSelectedBadge(null);
            setBadgeObj(null);
          }}
          className="actionRow__back"
        >
          <img src={ChevronLeftIcon} alt="" className="icon" />
          <span>Edit Membership</span>
        </Button>

        <Button
          onClick={nextHandler}
          className="actionRow__submit"
          id="triggerBtn"
        >
          Continue
        </Button>
      </div>

      <div className="paddingBox">
        <div className="title">Choose your badge</div>

        {badgesLoading && <Loader text="Fetching badges..." />}
        {!badgesLoading && badges && badges.length > 0 && (
          <div className="badge__list">
            {badges.map((item, i) => (
              <div
                onClick={() => {
                  setSelectedBadge(item.id);
                  setBadgeObj(item);
                }}
                key={item.id}
                className={`badge__list--item ${
                  selectedBadge === item.id ? "selected__item" : null
                }`}
              >
                {selectedBadge === item.id && (
                  <div className="selected">selected</div>
                )}

                <div className="icon">
                  <img src={item.imgPath} alt={item.name} />
                </div>

                <div className="info">
                  <div className="name">{item.name}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Badge;
