import {
  addCommentToStore,
  clearNotifications,
  getNotificationData,
  incrementNotificationCount,
  getVideos,
  filterVideos,
  getFeatured,
  getTrendingVideos,
  getStaffPickVideos,
  findLatest,
  viewChat,
  userStatus,
  getAllActiveUser,
  loginUser,
} from "../actions";
import socketIo from "socket.io-client";
import { WEB_SOCKET_EVENTS } from "./socket-keys";
import store from "./../store";
import config from "../config";
const ENDPOINT = config.sockets.socketBackendUrl;
let socket;

let webSocketConnection;

export const initiateSocket = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.id) {
    webSocketConnection = new WebSocket(
      `${config.sockets.webSocketBackendUrl}?userId=${user.id}`
    );

    webSocketConnection.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data && data.webSocketAction) {
        if (
          data.webSocketAction ===
          WEB_SOCKET_EVENTS.INCREMENT_NOTIFICATION_COUNT
        ) {
          store.dispatch(clearNotifications());
          store.dispatch(incrementNotificationCount());
          store.dispatch(getNotificationData(5));
          socket.emit("userStatus");
        } else if (data.webSocketAction === WEB_SOCKET_EVENTS.ADD_COMMENT) {
          store.dispatch(addCommentToStore(data));
        }
      }
    };
  }
};

// export const disconnectSocket = () => {
//   if (webSocketConnection) {
//     //webSocketConnection.close();
//   }
// };

// socket events

socket = socketIo(ENDPOINT, { transports: ["websocket"] });
export const disconnectSocket = () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  socket.emit("disconnected", userData);
  // store.dispatch(login)
  socket.emit("userStatus");
};

socket.on("triggernotification", (data) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  store.dispatch(clearNotifications());
  store.dispatch(incrementNotificationCount());
  store.dispatch(getNotificationData(5));
});

socket.on("updateVideosAfterUpload", () => {
  store.dispatch(findLatest());
});
