import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import MomentIcon from "./icons/moment.svg";
import ClipIcon from "./icons/clip.svg";
import LiveIcon from "./icons/live.svg";
import PostIcon from "./icons/post.svg";
import ThreadsSkeleton from "../../../skeletons/General/Section";
import VideosSkeleton from "../../../skeletons/General/Section";
import MomentsSkeleton from "../../../skeletons/General/Section";
import MomentsSkeletonV2 from "../../../skeletons/General/Card";
import Button from "../../Elements/Button";
import ThreadCardSmall from "../../Cards/ThreadCardSmall";
import Slider from "react-slick";
import NotifyText from "../../Elements/NotifyText";
import Error from "../../Elements/Error";
import MomentCard from "../../Cards/MomentCard";
import VideoCard from "../../Cards/VideoCard";
import Title from "../../Elements/Title";
import MomentCardV2 from "../../MomentsComponents/MomentCardV2";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { notificationMessage } from "../../../utils/toastNotifications";
import {
  getStreamsVideos,
  getStreamsMoments,
  getVideosV2,
  getAllMoments,
  clearFilteredVideos,
  clearFilteredStreamVideos,
} from "../../../actions";
import {
  clearThreads,
  getStreamsThreads,
  getThreads,
} from "../../../actions/thread";
import { Row, Col } from "react-grid-system";
import { MOMENTS_OTHER, VIDEO_OTHER } from "../../../utils/menuOptions";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";
import { RESET_GET_ALL_MOMENTS } from "../../../actions/types";
import "./filter.css";
import HomeCategoryFilter from "../../HomeCategoryFilter";
import { STATIC_CATEGORIES } from "../../../utils/common";
import { useFetch } from "../../../hooks/useFetch";
import CategoryFilterSkeleton from "../../../skeletons/Home/CategoryFilter";

import configMerged from "../../../config";

export const CONSTANTS = {
  threadsShowMain: 4,
  threadsShow768: 2,
  threadsShowMobile: 1,
  clipsShowMain: 4,
  clipsShow768: 2,
  clipsShowMobile: 1,
  momentsShowMain: 5,
  momentsShowMobile: 3,
};

const FilterBox = () => {
  const { ref: clipsTrackerRef, inView: clipsTrackIsVisible } = useInView();
  const { ref: threadsTrackerRef, inView: threadsTrackIsVisible } = useInView();
  const { ref: momentsTrackerRef, inView: momentsTrackIsVisible } = useInView();
  const threadSliderRef = useRef();
  const momentSliderRef = useRef();
  const clipSliderRef = useRef();

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const isTablet = useMediaQuery({
    query: "(max-width: 768px)",
  });

  //  states
  const [videoPage, setVideoPage] = useState(1);
  const [videoLimit, setVideoLimit] = useState(5);
  const [threadsPage, setThreadsPage] = useState(1);
  const [threadsLimit, setThreadsLimit] = useState(5);
  const [momentPage, setMomentPage] = useState(1);
  const [momentLimit, setMomentLimit] = useState(5);

  const [clipFilterActive, setClipFilterActive] = useState(false);
  const [liveFilterActive, setLiveFilterActive] = useState(false);
  const [postFilterActive, setPostFilterActive] = useState(false);
  const [momentFilterActive, setMomentFilterActive] = useState(false);
  const [midDrag, setMidDrag] = useState(false);
  const [threadsLastIndex, setThreadsLastIndex] = useState(0);
  const [clipsLastIndex, setClipsLastIndex] = useState(0);
  const [momentsLastIndex, setMomentsLastIndex] = useState(0);

  // This will swap streams content to homepage content
  const [changeContent, setChangeContent] = useState(true);

  // videos data
  const [videosLoading, setVideosLoading] = useState(false);
  const [videos, setVideos] = useState(null);
  const [hasMoreVideos, setHasMoreVideos] = useState(true);
  const [videosError, setVideosError] = useState(null);

  // threads data
  const [threadsLoading, setThreadsLoading] = useState(false);
  const [threads, setThreads] = useState(null);
  const [hasMoreThreads, setHasMoreThreads] = useState(true);
  const [threadsError, setThreadsError] = useState(null);

  // moments data
  const [momentsLoading, setMomentsLoading] = useState(false);
  const [moments, setMoments] = useState(null);
  const [hasMoreMoments, setHasMoreMoments] = useState(true);
  const [momentsError, setMomentsError] = useState(null);

  // filters
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [currentMainCategory, setCurrentMainCategory] = useState({
    id: -1,
    name: "ALL",
  });
  const [currentStaticFilterId, setCurrentStaticFilterId] = useState(
    STATIC_CATEGORIES.ALL
  );
  const [clearSubFilters, setClearSubFilters] = useState(false);

  const notify = () => {
    toast.success(notificationMessage.streamFilterComingSoon, {
      position: toast.POSITION.TOP_CENTER,
      className: "toast-message",
      autoClose: 3000,
    });
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const streamsVideosState = useSelector(
    (state) => state.channel_stream_videos
  );
  const homeVideosState = useSelector((state) => ({
    videos: state.videos,
    filteredVideos: state.videos.filteredVideos,
  }));
  const threadsState = useSelector((state) => state.threads);
  const momentsState = useSelector((state) => state.moments);

  useEffect(() => {
    const freeloaderStreamsOptionsSettings = JSON.parse(
      localStorage.getItem("freeloaderStreamsOptionsSettings")
    );

    if (freeloaderStreamsOptionsSettings) {
      setClipFilterActive(
        freeloaderStreamsOptionsSettings?.isClipSelected || false
      );
      setLiveFilterActive(
        freeloaderStreamsOptionsSettings?.isLiveSelected || false
      );
      setPostFilterActive(
        freeloaderStreamsOptionsSettings?.isPostSelected || false
      );
      setMomentFilterActive(
        freeloaderStreamsOptionsSettings?.isMomentSelected || false
      );
      setChangeContent(freeloaderStreamsOptionsSettings?.changeContent);
    }

    // reset moment state
    dispatch({
      type: RESET_GET_ALL_MOMENTS,
    });
  }, []);

  useEffect(() => {
    const obj = {
      isClipSelected: clipFilterActive,
      isLiveSelected: liveFilterActive,
      isPostSelected: postFilterActive,
      isMomentSelected: momentFilterActive,
      changeContent,
    };
    localStorage.setItem(
      "freeloaderStreamsOptionsSettings",
      JSON.stringify(obj)
    );
  }, [
    clipFilterActive,
    liveFilterActive,
    postFilterActive,
    momentFilterActive,
    changeContent,
  ]);

  useEffect(() => {
    if (changeContent) {
      setVideosLoading(streamsVideosState?.isFetching);
      setVideos(
        streamsVideosState?.isFiltering
          ? streamsVideosState.filteredVideos
          : streamsVideosState?.videos
      );
      setHasMoreVideos(streamsVideosState?.hasMore);
      setVideosError(streamsVideosState?.error);
    }

    if (!changeContent) {
      setVideosLoading(homeVideosState?.videos?.isFetching);
      setVideos(
        homeVideosState?.videos?.isFiltering
          ? homeVideosState?.videos?.filteredVideos
          : homeVideosState?.videos.videos
      );
      setHasMoreVideos(homeVideosState?.videos?.hasMore);
      setVideosError(homeVideosState?.videos?.error);
    }
  }, [streamsVideosState, homeVideosState]);

  useEffect(() => {
    if (changeContent) {
      setThreadsLoading(threadsState?.isFetching);
      setThreads(threadsState?.streamsThreads);
      setThreadsError(threadsState?.error);
      setHasMoreThreads(threadsState?.hasMoreStreamsThreads);
    }

    if (!changeContent) {
      setThreadsLoading(threadsState?.isFetching);
      setThreads(threadsState?.threads);
      setThreadsError(threadsState?.error);
      setHasMoreThreads(threadsState?.hasMore);
    }
  }, [threadsState]);

  useEffect(() => {
    if (changeContent) {
      setMomentsLoading(momentsState?.streamsLoading);
      setMoments(momentsState?.streamsMoments);
      setMomentsError(momentsState?.streamsError);
      setHasMoreMoments(momentsState?.hasMoreStreamsMoments);
    }

    if (!changeContent) {
      setMomentsLoading(momentsState?.loading);
      setMoments(momentsState?.moments);
      setMomentsError(momentsState?.error);
      setHasMoreMoments(momentsState?.hasMoreAllMoments);
    }
  }, [momentsState]);

  useEffect(() => {
    setVideoPage(1);
    setMomentPage(1);
    setThreadsPage(1);
  }, [changeContent]);

  useEffect(() => {
    const videoObj = {
      page: 1,
      limit: videoLimit,
      categoryList: selectedCategory,
    };

    if (clipFilterActive && changeContent) {
      dispatch(getStreamsVideos(videoObj));
    }

    if (clipFilterActive && !changeContent) {
      dispatch(getVideosV2(videoObj));
    }
  }, [clipFilterActive, changeContent, selectedCategory]);

  useEffect(() => {
    const momentObj = {
      page: 1,
      limit: momentLimit,
      categoryList: selectedCategory,
    };

    if (momentFilterActive && changeContent) {
      dispatch(getStreamsMoments(momentObj));
    }

    if (momentFilterActive && !changeContent) {
      dispatch(getAllMoments(1, momentLimit, selectedCategory));
    }
  }, [momentFilterActive, changeContent, selectedCategory]);

  useEffect(() => {
    if (postFilterActive && changeContent) {
      dispatch(getStreamsThreads(1, threadsLimit, selectedCategory));
    }

    if (postFilterActive && !changeContent) {
      dispatch(getThreads(1, threadsLimit, selectedCategory));
    }
  }, [postFilterActive, changeContent, selectedCategory]);

  // load more on slider
  useEffect(() => {
    if (
      postFilterActive &&
      hasMoreThreads &&
      !threadsLoading &&
      (threadsLastIndex >= threads?.length - 1 || threadsLastIndex === 0)
    ) {
      loadMoreThreadsHandler();
    }
  }, [threadsLastIndex]);

  useEffect(() => {
    if (
      clipFilterActive &&
      hasMoreVideos &&
      !videosLoading &&
      (clipsLastIndex >= videos?.length - 1 || clipsLastIndex === 0)
    ) {
      loadMoreVideosHandler();
    }
  }, [clipsLastIndex]);

  useEffect(() => {
    if (
      momentFilterActive &&
      hasMoreMoments &&
      !momentsLoading &&
      (momentsLastIndex >= moments?.length - 1 || momentsLastIndex === 0)
    ) {
      loadMoreMomentsHandler();
    }
  }, [momentsLastIndex]);

  // infinity style load more
  useEffect(() => {
    if (hasMoreVideos && clipsTrackIsVisible && !videosLoading) {
      loadMoreVideosHandler();
    }

    if (hasMoreThreads && threadsTrackIsVisible && !threadsLoading) {
      loadMoreThreadsHandler();
    }

    if (hasMoreMoments && momentsTrackIsVisible && !momentsLoading) {
      loadMoreMomentsHandler();
    }
  }, [
    clipsTrackIsVisible,
    hasMoreVideos,
    videosLoading,
    threadsTrackIsVisible,
    hasMoreThreads,
    threadsLoading,
    momentsTrackIsVisible,
    hasMoreMoments,
    momentsLoading,
  ]);

  const HandleHome = () => {
    history.push("/home");
  };

  const activateClipFilter = () => {
    setClipFilterActive(!clipFilterActive);
  };

  const activateThreadFilter = () => {
    setPostFilterActive(!postFilterActive);
  };

  const activeMomentFilter = () => {
    setMomentFilterActive(!momentFilterActive);
  };

  const loadMoreVideosHandler = () => {
    setThreadsPage(1);

    setVideoPage(videoPage + 1);
    const videoObj = {
      page: videoPage + 1,
      limit: videoLimit,
      categoryList: selectedCategory,
    };

    if (changeContent) {
      // show streams videos
      dispatch(getStreamsVideos(videoObj));
    } else {
      // show homepage videos
      dispatch(getVideosV2(videoObj));
    }
  };

  const loadMoreThreadsHandler = () => {
    setThreadsPage(1);
    setThreadsPage(threadsPage + 1);

    if (changeContent) {
      dispatch(
        getStreamsThreads(threadsPage + 1, threadsLimit, selectedCategory)
      );
    } else {
      dispatch(getThreads(threadsPage + 1, threadsLimit, selectedCategory));
    }
  };

  const loadMoreMomentsHandler = () => {
    setMomentPage(momentPage + 1);
    const momentObj = {
      page: momentPage + 1,
      limit: momentLimit,
    };

    if (changeContent) {
      dispatch(getStreamsMoments(momentObj));
    } else {
      dispatch(getAllMoments(momentPage + 1, momentLimit));
    }
  };

  const goToNextMomentHandler = (currentMoment) => {
    const nextMoment = document.getElementById(`moment@${currentMoment + 1}`);
    nextMoment.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  // settings
  const THREADS_SLIDER_SETTING = {
    infinite: false,
    arrows: false,
    dots: false,
    slidesToShow: CONSTANTS.threadsShowMain,
    slidesToScroll: CONSTANTS.threadsShowMain,
    speed: 500,

    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: CONSTANTS.threadsShow768,
          slidesToScroll: CONSTANTS.threadsShow768,
        },
      },

      {
        breakpoint: 481,
        settings: {
          slidesToShow: CONSTANTS.threadsShowMobile,
          slidesToScroll: CONSTANTS.threadsShowMobile,
        },
      },
    ],
  };

  // Main Category
  const handleCategory = (mainCategory) => {
    setCurrentStaticFilterId(null);
    if (currentMainCategory !== mainCategory) {
      dispatch(clearFilteredVideos());
      dispatch(clearFilteredStreamVideos());
      dispatch({
        type: RESET_GET_ALL_MOMENTS,
      });
      dispatch(clearThreads());
    }
    setCurrentMainCategory(mainCategory);
    const filtered = selectedCategory.filter(
      (sc) => sc.videoCategoryId === mainCategory.id
    );
    setSelectedCategory([
      ...filtered,
      {
        videoCategoryId: mainCategory.id,
        subCategOneId: null,
        subCategTwoId: null,
      },
    ]);
  };

  const CLIPS_SLIDER_SETTING = {
    infinite: false,
    arrows: false,
    dots: false,
    slidesToShow: CONSTANTS.clipsShowMain,
    slidesToScroll: CONSTANTS.clipsShowMain,
    speed: 500,

    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: CONSTANTS.clipsShow768,
          slidesToScroll: CONSTANTS.clipsShow768,
        },
      },

      {
        breakpoint: 481,
        settings: {
          slidesToShow: CONSTANTS.clipsShowMobile,
          slidesToScroll: CONSTANTS.clipsShowMobile,
        },
      },
    ],
  };

  const MOMENTS_SLIDER_SETTING = {
    infinite: false,
    arrows: false,
    dots: false,
    slidesToShow: CONSTANTS.momentsShowMain,
    slidesToScroll: CONSTANTS.momentsShowMain,
    speed: 500,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: CONSTANTS.momentsShowMobile,
          slidesToScroll: CONSTANTS.momentsShowMobile,
        },
      },
    ],
  };

  // categories
  const { loading: filterCategoriesLoading, data: filterCategories } = useFetch(
    `${configMerged.api.url}videos/category`
  );
  const getAllVideos = () => {
    setCurrentStaticFilterId(STATIC_CATEGORIES.ALL);
    setSelectedCategory([]);
    setCurrentMainCategory([]);
    setClearSubFilters(true);
  };
  const renderCategoryFilters = () => (
    <div className="filters">
      {filterCategoriesLoading && !filterCategories?.data ? (
        <CategoryFilterSkeleton />
      ) : (
        <HomeCategoryFilter
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          currentMainCategory={currentMainCategory}
          handleCategory={handleCategory}
          currentStaticFilterId={currentStaticFilterId}
          getAllVideos={getAllVideos}
          clearSubFilters={clearSubFilters}
          categoryList={filterCategories}
        />
      )}
    </div>
  );
  const categoryFiltersVisible =
    momentFilterActive || postFilterActive || clipFilterActive ? true : false;
  return (
    <FilterBoxStyled categoryFiltersVisible>
      {categoryFiltersVisible && renderCategoryFilters()}
      <motion.div
        initial={{ opacity: 0, y: "-20rem" }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "spring", duration: 0.25, stiffness: 150 }}
        whileHover={{ scale: 1.05 }}
        whileTap={{
          scale: 1,
        }}
        className="wrapper"
      >
        <div onClick={activeMomentFilter} className="wrapper__item clipBox">
          <div className={`tearShape ${momentFilterActive && "active"}`}>
            <img className="icon" src={ClipIcon} alt="" />
          </div>
        </div>

        <div onClick={notify} className="wrapper__item liveBox">
          <div className={`tearShape ${liveFilterActive && "active"}`}>
            <img className="icon" src={LiveIcon} alt="" />
          </div>
        </div>

        <div onClick={activateThreadFilter} className="wrapper__item postBox">
          <div className={`tearShape ${postFilterActive && "active"}`}>
            <img className="icon" src={PostIcon} alt="" />
          </div>
        </div>

        <div onClick={activateClipFilter} className="wrapper__item momentBox">
          <div className={`tearShape ${clipFilterActive && "active"}`}>
            <img className="icon" src={MomentIcon} alt="" />
          </div>
        </div>
      </motion.div>

      <Wrapper>
        <div className="changeContent">
          <div
            className={`changeContent__text ${
              !changeContent ? "changeContent__activeLeft" : null
            }`}
          >
            General Content
          </div>

          <div
            onClick={() => setChangeContent(!changeContent)}
            className="changeContent__toggle"
          >
            <input
              className={`${changeContent && "inputChecked"}`}
              type="checkbox"
              checked={changeContent}
            />
            <span
              className={`slider ${changeContent && "sliderChecked"}`}
            ></span>
          </div>

          <div
            className={`changeContent__text ${
              changeContent ? "changeContent__activeRight" : null
            }`}
          >
            Your Streams
          </div>
        </div>

        {!clipFilterActive &&
        !liveFilterActive &&
        !momentFilterActive &&
        !postFilterActive ? (
          <>
            <div className="contentNotFound">
              <NotifyText
                text={() => {
                  return (
                    <>
                      <div className="magic">
                        Please
                        <div className="magic__text">select some filter</div>
                        from above to see respective streams.
                      </div>
                    </>
                  );
                }}
              />
            </div>
          </>
        ) : (
          <>
            {!moments?.length &&
            !videos.length &&
            !threads?.length &&
            !momentsLoading &&
            !videosLoading &&
            !threadsLoading &&
            selectedCategory?.length &&
            !changeContent ? (
              <div className="contentNotFound">
                <NotifyText
                  text={() => {
                    return (
                      <>
                        No content matches your current filters! <br />
                        Please select another category filter or choose "All"{" "}
                        <br /> in the Category Filter to see all content. to see
                        their contents.
                      </>
                    );
                  }}
                />

                <Button
                  className="contentNotFound__button"
                  variant="box"
                  mode="primary"
                  size="medium"
                  onClick={() => HandleHome()}
                >
                  Explore More
                </Button>
              </div>
            ) : !moments?.length &&
              !videos?.length &&
              !threads?.length &&
              !momentsLoading &&
              !videosLoading &&
              !threadsLoading ? (
              <div className="contentNotFound">
                <NotifyText
                  text={() => {
                    return (
                      <>
                        You haven't added anyone to your streams yet! Please
                        <div className="magic">
                          <div className="magic__text">
                            add some users to your streams
                          </div>
                        </div>
                        to see their contents.
                      </>
                    );
                  }}
                />

                <Button
                  className="contentNotFound__button"
                  variant="box"
                  mode="primary"
                  size="medium"
                  onClick={() => HandleHome()}
                >
                  Explore More
                </Button>
              </div>
            ) : (
              <>
                {clipFilterActive && (
                  <>
                    {videos && videos.length ? (
                      <div className="clips">
                        <Title
                          showSlider={
                            clipFilterActive &&
                            (postFilterActive ||
                              momentFilterActive ||
                              liveFilterActive)
                              ? true
                              : false
                          }
                          sliderController={{
                            numItems: videos.length,
                            lastIndex: clipsLastIndex,
                            defaultSliderItem: isMobile
                              ? CONSTANTS?.clipsShowMobile
                              : isTablet
                              ? CONSTANTS?.clipsShow768
                              : CONSTANTS?.clipsShowMain,
                            nextHandler: () => {
                              if (clipsLastIndex >= videos?.length) {
                                if (videosLoading) {
                                  return toast.success("Fetching videos...");
                                }

                                return toast.error(
                                  "You reached the end of the video results!"
                                );
                              }

                              clipSliderRef.current.slickNext();
                            },
                            prevHandler: () =>
                              clipSliderRef.current.slickPrev(),
                          }}
                        >
                          Videos
                        </Title>

                        {clipFilterActive &&
                        (postFilterActive ||
                          liveFilterActive ||
                          momentFilterActive) ? (
                          <Slider
                            ref={clipSliderRef}
                            {...CLIPS_SLIDER_SETTING}
                            beforeChange={(prev, next) => {
                              setMidDrag(true);
                              setClipsLastIndex(
                                isMobile
                                  ? CONSTANTS.clipsShowMobile + next
                                  : isTablet
                                  ? CONSTANTS.clipsShow768 + next
                                  : CONSTANTS.clipsShowMain + next
                              );
                            }}
                            afterChange={() => setMidDrag(false)}
                          >
                            {videos.map((clip) => (
                              <div key={clip.id} className="clips__item">
                                <VideoCard
                                  // key={i}
                                  item={clip}
                                  menuOptions={VIDEO_OTHER}
                                  isSelf={false}
                                  midDrag={midDrag}
                                />
                              </div>
                            ))}
                          </Slider>
                        ) : (
                          <Row>
                            {videos.map((clip, i) => (
                              <Col
                                key={i}
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                }}
                                md={3}
                                sm={6}
                                xs={12}
                              >
                                <div className="clips__item">
                                  <VideoCard
                                    item={clip}
                                    menuOptions={VIDEO_OTHER}
                                    isSelf={false}
                                  />
                                </div>
                              </Col>
                            ))}
                          </Row>
                        )}

                        {videosLoading && videos && (
                          <VideosSkeleton
                            noTitle
                            fullWidth
                            numItems={4}
                            sectionName="videos"
                          />
                        )}

                        {hasMoreVideos === false && (
                          <div className="noMore">
                            You have reached the end of clips results
                          </div>
                        )}

                        {!momentFilterActive &&
                          !postFilterActive &&
                          !liveFilterActive &&
                          !videosLoading && (
                            <div ref={clipsTrackerRef} className="tracker" />
                          )}
                      </div>
                    ) : (
                      <div className="clips">
                        {videosLoading && (
                          <VideosSkeleton
                            numItems={4}
                            fullWidth
                            sectionName="videos"
                          />
                        )}

                        {!videosLoading && (!videos?.length || videosError) && (
                          <Error
                            text="No videos found. Plese subscribe to any channel to populate video content."
                            actionButtonText="Home"
                            actionButton={HandleHome}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}

                {momentFilterActive && (
                  <>
                    {moments && moments.length ? (
                      <div id="momentsBox" className="moments">
                        <Title
                          showSlider={
                            momentFilterActive &&
                            (postFilterActive ||
                              clipFilterActive ||
                              liveFilterActive)
                              ? true
                              : false
                          }
                          sliderController={{
                            numItems: moments.length,
                            lastIndex: momentsLastIndex,
                            defaultSliderItem: isMobile
                              ? CONSTANTS?.momentsShowMobile
                              : CONSTANTS?.momentsShowMain,
                            nextHandler: () => {
                              if (momentsLastIndex >= moments?.length) {
                                if (momentsLoading) {
                                  return toast.success("moments fetching...");
                                }

                                return toast.error(
                                  "You reached the end of moments!"
                                );
                              }
                              momentSliderRef.current.slickNext();
                            },
                            prevHandler: () =>
                              momentSliderRef.current.slickPrev(),
                          }}
                        >
                          Moments
                        </Title>

                        {momentFilterActive &&
                        (postFilterActive ||
                          liveFilterActive ||
                          clipFilterActive) ? (
                          <Slider
                            ref={momentSliderRef}
                            {...MOMENTS_SLIDER_SETTING}
                            beforeChange={(prev, next) => {
                              setMidDrag(true);
                              setMomentsLastIndex(
                                isMobile
                                  ? CONSTANTS.momentsShowMobile + next
                                  : CONSTANTS.momentsShowMain + next
                              );
                            }}
                            afterChange={() => setMidDrag(false)}
                          >
                            {moments.map((moment) => (
                              <div key={moment.id} className="moments__item">
                                <MomentCard
                                  item={moment}
                                  menuOptions={MOMENTS_OTHER}
                                  isSelf={false}
                                  midDrag={midDrag}
                                />
                              </div>
                            ))}
                          </Slider>
                        ) : (
                          <>
                            <div className="moments__wrapper">
                              {moments.map((item, i) => (
                                <div
                                  id={`moment@${i}`}
                                  key={i}
                                  className="moments__wrapper--item"
                                >
                                  <MomentCardV2
                                    moment={item}
                                    nextMomentHandler={goToNextMomentHandler}
                                    index={i}
                                  />
                                </div>
                              ))}

                              {!clipFilterActive &&
                                !postFilterActive &&
                                !liveFilterActive &&
                                !momentsLoading && (
                                  <div className="moments__wrapper--itemV2">
                                    <div
                                      ref={momentsTrackerRef}
                                      className="tracker"
                                    />
                                  </div>
                                )}

                              {momentsLoading &&
                                moments &&
                                momentFilterActive &&
                                !postFilterActive &&
                                !liveFilterActive &&
                                !clipFilterActive && (
                                  <div className="moments__wrapper--item">
                                    <MomentsSkeletonV2 cardName="moment" />
                                  </div>
                                )}
                            </div>
                          </>
                        )}

                        {momentsLoading &&
                          moments &&
                          momentFilterActive &&
                          (postFilterActive ||
                            liveFilterActive ||
                            clipFilterActive) && (
                            <MomentsSkeleton
                              fullWidth
                              noTitle
                              numItems={isMobile ? 3 : 4}
                              sectionName="featured_moments"
                            />
                          )}

                        {hasMoreMoments === false && (
                          <div className="noMore">
                            You have reached the end of moments results
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="moments">
                        {momentsLoading &&
                          momentFilterActive &&
                          (postFilterActive ||
                            liveFilterActive ||
                            clipFilterActive) && (
                            <MomentsSkeleton
                              fullWidth
                              noTitle
                              numItems={isMobile ? 3 : 4}
                              sectionName="featured_moments"
                            />
                          )}

                        {momentsLoading &&
                          momentFilterActive &&
                          !postFilterActive &&
                          !liveFilterActive &&
                          !clipFilterActive && (
                            <MomentsSkeletonV2 cardName="moment" />
                          )}

                        {!momentsLoading &&
                          (!moments?.length || momentsError) && (
                            <Error
                              text="You Dont Have any Moments plese subscribe To Any Channel"
                              actionButtonText="Home"
                              actionButton={HandleHome}
                            />
                          )}
                      </div>
                    )}
                  </>
                )}

                {postFilterActive && (
                  <>
                    {threads && threads.length ? (
                      <div className="threads">
                        <Title
                          showSlider={
                            postFilterActive &&
                            (momentFilterActive ||
                              clipFilterActive ||
                              liveFilterActive)
                              ? true
                              : false
                          }
                          sliderController={{
                            numItems: threads.length,
                            lastIndex: threadsLastIndex,
                            defaultSliderItem: isMobile
                              ? CONSTANTS?.threadsShowMobile
                              : isTablet
                              ? CONSTANTS?.threadsShow768
                              : CONSTANTS?.threadsShowMain,
                            nextHandler: () => {
                              if (threadsLastIndex >= threads?.length) {
                                if (threadsLoading) {
                                  return toast.success("threads fetching...");
                                }

                                return toast.error(
                                  "You reached the end of Threads!"
                                );
                              }

                              threadSliderRef.current.slickNext();
                            },
                            prevHandler: () =>
                              threadSliderRef.current.slickPrev(),
                          }}
                        >
                          Threads
                        </Title>

                        {postFilterActive &&
                        (clipFilterActive ||
                          liveFilterActive ||
                          momentFilterActive) ? (
                          <>
                            <Slider
                              ref={threadSliderRef}
                              {...THREADS_SLIDER_SETTING}
                              beforeChange={(prev, next) => {
                                setMidDrag(true);
                                setThreadsLastIndex(
                                  isMobile
                                    ? CONSTANTS.threadsShowMobile + next
                                    : isTablet
                                    ? CONSTANTS.threadsShow768 + next
                                    : CONSTANTS.threadsShowMain + next
                                );
                              }}
                              afterChange={() => setMidDrag(false)}
                            >
                              {threads.map((thread) => (
                                <div key={thread.id} className="threads__item">
                                  <ThreadCardSmall
                                    item={thread}
                                    type={thread?.attachedType}
                                    midDrag={midDrag}
                                  />
                                </div>
                              ))}
                            </Slider>
                          </>
                        ) : (
                          <Row>
                            {threads.map((thread, i) => (
                              <Col
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                }}
                                md={3}
                                sm={6}
                                xs={12}
                              >
                                <div className="threads__item">
                                  <ThreadCardSmall
                                    key={i}
                                    item={thread}
                                    type={thread?.attachedType}
                                  />
                                </div>
                              </Col>
                            ))}
                          </Row>
                        )}

                        {threadsLoading && threads && (
                          <ThreadsSkeleton
                            numItems={3}
                            noTitle
                            fullWidth
                            sectionName="featured_series"
                          />
                        )}

                        {hasMoreThreads === false && (
                          <div className="noMore">
                            You have reached the end of threads results
                          </div>
                        )}

                        {!clipFilterActive &&
                          !momentFilterActive &&
                          !liveFilterActive &&
                          !threadsLoading && (
                            <div ref={threadsTrackerRef} className="tracker" />
                          )}
                      </div>
                    ) : (
                      <div className="threads">
                        {threadsLoading && (
                          <ThreadsSkeleton
                            numItems={3}
                            fullWidth
                            sectionName="featured_series"
                          />
                        )}

                        {!threadsLoading &&
                          (!threads?.length || threadsError) && (
                            <Error
                              text="You Dont Have any Threads plese subscribe To AnyChannel"
                              actionButtonText="Home"
                              actionButton={HandleHome}
                            />
                          )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Wrapper>
    </FilterBoxStyled>
  );
};

export default FilterBox;

const Wrapper = styled.div`
  margin-bottom: 2rem;
  margin-top: 4rem;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  .tracker {
    width: 100%;
    height: 5rem;
    background: transparent;
    opacity: 0;
  }

  .changeContent {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 1rem;

    &__activeRight {
      font-weight: 400 !important;
      color: ${(props) => props.theme.primaryColor2} !important;

      &:before {
        width: 50% !important;
        background-color: ${(props) => props.theme.primaryColor2} !important;
      }
    }

    &__activeLeft {
      font-weight: 400 !important;
      color: ${(props) => props.theme.primaryColor1} !important;

      &:before {
        width: 50% !important;
        background-color: ${(props) => props.theme.primaryColor1} !important;
      }
    }

    &__text {
      font-size: 0.72rem;
      font-weight: 300;
      text-transform: uppercase;
      font-family: brother-1816, sans-serif;
      color: rgba(255, 255, 255, 0.5);
      transition: all 0.4s ease;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0%;
        transition: all 0.4s ease;
      }
    }

    &__toggle {
      position: relative;
      width: 100px;
      height: 30px;
      cursor: pointer;
      margin: 0 0.5rem;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      input:checked + .slider {
        border: 2px solid ${(props) => props.theme.primaryColor2};
      }

      input:checked + .slider:before {
        background-color: ${(props) => props.theme.primaryColor2};
        transform: translateX(68px) translateY(-50%);
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 10rem;
        border: 2px solid ${(props) => props.theme.primaryColor1};

        &:before {
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
          content: "";
          height: 17px;
          width: 17px;
          background-color: ${(props) => props.theme.primaryColor1};
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 50%;
        }
      }
    }
  }

  .threads,
  .clips,
  .moments {
    width: 90%;
    margin: 2rem auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
    padding-bottom: 2rem;

    &__item {
      padding: 0 5px;
      margin-bottom: 1rem;
    }
  }

  .moments {
    &__wrapper {
      height: calc(100vh - 75px - 24px * 3);
      width: calc(calc(100vh - 75px - 24px * 3) * 0.5625);
      overflow-y: auto;
      scroll-snap-type: y mandatory;
      margin: 0 auto 2rem auto;

      &--item {
        height: 100%;
        width: 100%;
        scroll-snap-align: start;
        margin-bottom: 2rem;
      }

      &__itemV2 {
        width: 100%;
        height: auto;
        margin-top: auto;
      }
    }
  }

  .contentNotFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${(props) => props.theme.montserrat}, sans-serif;
    padding: 2.5rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
    width: 90%;
    margin: 0 auto;

    &__button {
      margin-top: 1.5rem;
      font-weight: 500;
    }
  }

  .loadMoreButton {
    margin-top: 1.5rem;
  }

  .noMore {
    width: 100%;
    text-align: center;
    font-weight: 300;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1;
    margin: 1rem 0;
  }

  @media screen and (max-width: 480px) {
    .threads {
      width: 95%;
    }

    .moments {
      &__wrapper {
        width: 100%;
        height: auto;
        aspect-ratio: 9 / 16;
      }
    }

    .loadMoreButton {
      font-size: 0.72rem !important;
      padding: 0.6rem 0 !important;

      img {
        height: 0.5rem !important;
      }
    }

    .changeContent {
      &__text {
        font-size: 0.62rem;
      }

      &__toggle {
        width: 70px;
        height: 25px;
        margin: 0 0.35rem;

        input:checked + .slider:before {
          transform: translateX(45px) translateY(-50%);
        }

        .slider {
          &:before {
            left: 3px;
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 414px) {
    .changeContent {
      &__text {
        font-size: 0.58rem;
      }

      &__toggle {
        width: 55px;
        height: 22px;
        margin: 0 0.3rem;

        input:checked + .slider:before {
          transform: translateX(32px) translateY(-50%);
        }

        .slider {
          &:before {
            left: 3px;
            height: 13px;
            width: 13px;
          }
        }
      }
    }
  }
`;

const FilterBoxStyled = styled.div`
  width: 100%;
  padding-bottom: 25px;
  .filters {
    margin-bottom: 1rem;
    transition: max-height 2s ease-in-out;
    overflow: hidden;
    margin-top: -30px;
    max-height: ${({ categoryFiltersVisible }) =>
      categoryFiltersVisible ? "500px" : "0px"};
  }
  .wrapper {
    height: 16rem;
    width: 16rem;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;

    &__item {
      position: absolute;
      color: #fff;
      cursor: pointer;

      .tearShape {
        align-items: center;
        display: flex;
        justify-content: center;
        border: 2px solid transparent;
        border-radius: 8px 50% 50% 50%;
        height: 100px;
        width: 100px;
        background-color: #2c2c2e;
        transition: all 0.2s ease;
      }

      .active {
        background: ${(props) => props.theme.primaryGradient};
      }
    }

    .clipBox {
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      .tearShape {
        transform: rotate(225deg);

        .icon {
          transform: rotate(225deg);
          width: 3rem;
          height: auto;
        }
      }
    }

    .liveBox {
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      .tearShape {
        transform: rotate(-40deg);

        .icon {
          transform: rotate(40deg);
          width: 3rem;
          height: auto;
        }
      }
    }

    .postBox {
      bottom: 0.5rem;
      left: 50%;
      transform: translateX(-50%);

      .tearShape {
        transform: rotate(45deg);

        .icon {
          transform: rotate(-45deg);
          width: 2rem;
          height: auto;
        }
      }
    }

    .momentBox {
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      .tearShape {
        transform: rotate(130deg);

        .icon {
          transform: rotate(140deg);
          width: 2rem;
          height: auto;
        }
      }
    }
  }
`;
