import React from "react";
import styled from "styled-components";
import SectionHeader from "../../SectionHeader";
import MembershipCard from "./MembershipCard";
import { Row, Col } from "react-grid-system";
import { useSelector } from "react-redux";

const Membership = ({ data }) => {
  const { data: siteBranding } = useSelector((state) => state.branding);

  if (!data?.length) {
    return null;
  }

  return (
    <MembershipStyled>
      <SectionHeader
        title="Membership"
        subTitle={`Subscribe to one of the membership and enjoy ${siteBranding?.site_name}'s content`}
      />

      <div className="content">
        <Row>
          {data?.map((item, i) => (
            <Col key={i} lg={4} sm={12}>
              <div className="content__item">
                <MembershipCard
                  data={{
                    ...item,
                    index: i,
                    role: i + 1,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </MembershipStyled>
  );
};

export default Membership;

const MembershipStyled = styled.div`
  padding-bottom: 7rem;

  .content {
    width: 90%;
    margin: 5rem auto 0 auto;
  }
`;
