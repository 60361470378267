import {
  CREATE_THREAD_REQUEST,
  CREATE_THREAD_SUCCESS,
  CREATE_THREAD_FAIL,
  RESET_THREAD_STATE,
  DELETE_THREAD_REQUEST,
  DELETE_THREAD_SUCCESS,
  DELETE_THREAD_FAIL,
  GET_SINGLE_THREAD_REQUEST,
  GET_SINGLE_THREAD_SUCCESS,
  GET_SINGLE_THREAD_FAIL,
  UPDATE_THREAD_REQUEST,
  UPDATE_THREAD_SUCCESS,
  UPDATE_THREAD_FAIL,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAIL,
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAIL,
  REPLY_COMMENT_REQUEST,
  REPLY_COMMENT_SUCCESS,
  REPLY_COMMENT_FAIL,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAIL,
  DELETE_REPLY_COMMENT_REQUEST,
  DELETE_REPLY_COMMENT_SUCCESS,
  DELETE_REPLY_COMMENT_FAIL,
  UPDATE_COMMENT_REQUEST,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAIL,
  REPLY_UPDATE_COMMENT_REQUEST,
  REPLY_UPDATE_COMMENT_SUCCESS,
  REPLY_UPDATE_COMMENT_FAIL,
  RESET_COMMENT_STATE,
} from "../actions/thread/type";

const thread = (state = {}, action) => {
  switch (action.type) {
    case CREATE_THREAD_REQUEST:
      return {
        createLoading: true,
      };
    case CREATE_THREAD_SUCCESS:
      return {
        createLoading: false,
        createData: action.payload,
      };
    case CREATE_THREAD_FAIL:
      return {
        createLoading: false,
        createError: action.payload,
      };
    case DELETE_THREAD_REQUEST:
      return {
        deleteLoading: true,
      };
    case DELETE_THREAD_SUCCESS:
      return {
        deleteLoading: false,
        deleteMessage: "Thread deleted!",
      };
    case DELETE_THREAD_FAIL:
      return {
        deleteLoading: false,
        deleteError: action.payload,
      };
    case GET_SINGLE_THREAD_REQUEST:
      return {
        ...state,
        threadLoading: true,
      };
    case GET_SINGLE_THREAD_SUCCESS:
      return {
        ...state,
        threadLoading: false,
        thread: action.payload,
      };
    case GET_SINGLE_THREAD_FAIL:
      return {
        ...state,
        threadLoading: false,
        threadError: action.payload,
      };
    case UPDATE_THREAD_REQUEST:
      return {
        updateLoading: true,
      };
    case UPDATE_THREAD_SUCCESS:
      return {
        updateLoading: false,
        updateMessage: action.payload,
      };
    case UPDATE_THREAD_FAIL:
      return {
        updateLoading: false,
        updateError: action.payload,
      };
    case ADD_COMMENT_REQUEST:
      return {
        ...state,
        addCommentLoading: true,
      };
    case ADD_COMMENT_SUCCESS:
      const newAddCommentObj = {
        ...action.payload,
        replies: [],
      };

      return {
        ...state,
        addCommentLoading: false,
        addCommentData: newAddCommentObj,
        comments: {
          comments:
            state?.comments?.comments?.length > 0
              ? [newAddCommentObj, ...state.comments.comments]
              : [newAddCommentObj],
        },
      };
    case ADD_COMMENT_FAIL:
      return {
        ...state,
        addCommentLoading: false,
        addCommentError: action.payload,
      };
    case REPLY_COMMENT_REQUEST:
      return {
        ...state,
        replyCommentLoading: true,
      };
    case REPLY_COMMENT_SUCCESS:
      const parentComment = state?.comments?.comments.filter(
        (item) => item.id === action.payload.parent_id
      )[0];

      parentComment.replies =
        parentComment?.replies?.length > 0
          ? [action.payload, ...parentComment.replies]
          : [action.payload];

      const newComments = state?.comments?.comments.map((item, i) => {
        if (item.id === action.payload.parent_id) {
          return parentComment;
        } else {
          return item;
        }
      });

      return {
        ...state,
        replyCommentLoading: false,
        replyCommentData: action.payload,
        comments: {
          comments: newComments,
          ...state.comments,
        },
      };
    case REPLY_COMMENT_FAIL:
      return {
        ...state,
        replyCommentLoading: false,
        replyCommentError: action.payload,
      };
    case GET_COMMENTS_REQUEST:
      return {
        ...state,
        getCommentsLoading: true,
      };
    case GET_COMMENTS_SUCCESS:
      return {
        ...state,
        getCommentsLoading: false,
        comments: action.payload,
      };
    case GET_COMMENTS_FAIL:
      return {
        ...state,
        getCommentsLoading: false,
        getCommentsError: action.payload,
      };
    case DELETE_COMMENT_REQUEST:
      return {
        ...state,
        deleteCommentLoading: true,
      };
    case DELETE_COMMENT_SUCCESS: {
      const commetsAfterDelete = state?.comments?.comments.filter(
        (item) => item.id !== action.payload
      );

      return {
        ...state,
        deleteCommentLoading: false,
        deleteCommentMessage: "Comment deleted!",
        comments: {
          ...state.comments,
          comments: commetsAfterDelete,
        },
      };
    }
    case DELETE_COMMENT_FAIL:
      return {
        ...state,
        deleteCommentLoading: false,
        deleteCommentError: action.payload,
      };
    case DELETE_REPLY_COMMENT_REQUEST:
      return {
        ...state,
        deleteReplyCommentLoading: true,
      };
    case DELETE_REPLY_COMMENT_SUCCESS:
      const commentsAfterReplyDelete = state?.comments?.comments.map(
        (element) => {
          return {
            ...element,
            replies: element.replies.filter(
              (subElement) => subElement.id !== action.payload
            ),
          };
        }
      );

      return {
        ...state,
        deleteReplyCommentLoading: false,
        deleteReplyCommentMessage: "Replied deleted!",
        comments: {
          ...state.comments,
          comments: commentsAfterReplyDelete,
        },
      };
    case DELETE_REPLY_COMMENT_FAIL:
      return {
        ...state,
        deleteReplyCommentLoading: false,
        deleteReplyCommentError: action.payload,
      };
    case UPDATE_COMMENT_REQUEST:
      return {
        ...state,
        updateCommentLoading: true,
      };
    case UPDATE_COMMENT_SUCCESS:
      const commetsAfterUpdate = state?.comments?.comments.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...action.payload,
            replies: item.replies,
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        updateCommentLoading: false,
        updateCommentMessage: "Comment updated!",
        comments: {
          ...state.comments,
          comments: commetsAfterUpdate,
        },
      };
    case UPDATE_COMMENT_FAIL:
      return {
        ...state,
        updateCommentLoading: false,
        updateCommentError: action.payload,
      };
    case REPLY_UPDATE_COMMENT_REQUEST:
      return {
        ...state,
        replyUpdateCommentLoading: true,
      };
    case REPLY_UPDATE_COMMENT_SUCCESS:
      const commentsAfterReplyUpdate = state?.comments?.comments.map(
        (element) => {
          return {
            ...element,
            replies: element.replies.map((subElement) => {
              if (subElement.id === action.payload.id) {
                return {
                  ...action.payload,
                };
              }

              return subElement;
            }),
          };
        }
      );


      return {
        ...state,
        replyUpdateCommentLoading: false,
        replyUpdateCommentMessage: "Reply updated!",
        comments: {
          ...state.comments,
          comments: commentsAfterReplyUpdate,
        },
      };
    case REPLY_UPDATE_COMMENT_FAIL:
      return {
        replyUpdateCommentLoading: false,
        replyUpdateCommentError: action.payload,
      };
    case RESET_COMMENT_STATE:
      return {
        ...state,
        addCommentData: null,
        addCommentError: null,
        replyCommentData: null,
        replyCommentError: null,
        deleteCommentError: null,
        deleteCommentMessage: null,
        updateCommentMessage: null,
        updateCommentError: null,
        replyUpdateCommentMessage: null,
        replyUpdateCommentError: null,
      };
    case RESET_THREAD_STATE:
      return {};
    default:
      return state;
  }
};

export default thread;
