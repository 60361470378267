import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Navbar from "../Navbar";
import FreeloaderLogo from "../../../assets/logo/logoOnly.svg";
import Tilt from "react-parallax-tilt";
import BigText from "../StyleComponents/BigText";
import SmallText from "../StyleComponents/SmallText";
import config from "../../../config";
import { useSelector } from "react-redux";

const Hero = () => {
  const { data: siteBranding } = useSelector((state) => state.branding);
  const [shrinkNav, setShrinkNav] = useState(false);

  useEffect(() => {
    glMouseTrackHandler();
  }, []);

  useEffect(() => {
    const handler = () => {
      const pos = document.documentElement.scrollTop;

      if (pos > 100) {
        setShrinkNav(true);
      } else {
        setShrinkNav(false);
      }
    };

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  const glMouseTrackHandler = (event) => {
    const blob = document.getElementById("blob");
    const hero = document.getElementById("hero");

    hero.onpointermove = (event) => {
      const { clientX, clientY } = event;

      blob.animate(
        {
          left: `${clientX}px`,
          top: `${clientY}px`,
        },
        { duration: 3000, fill: "forwards" }
      );
    };
  };

  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  let interval = null;

  const brandName = document.querySelector("#brandName");
  if (brandName) {
    brandName.onmouseover = (event) => {
      let iteration = 0;

      clearInterval(interval);

      interval = setInterval(() => {
        event.target.innerText = event.target.innerText
          .split("")
          .map((letter, index) => {
            if (index < iteration) {
              return event.target.dataset.value[index];
            }

            return letters[Math.floor(Math.random() * 26)];
          })
          .join("");

        if (iteration >= event.target.dataset.value.length) {
          clearInterval(interval);
        }

        iteration += 1 / 3;
      }, 30);
    };
  }

  return (
    <HeroStyled id="hero">
      <div id="blob"></div>
      <div id="blur"></div>

      <div className="heroContent">
        <Navbar shrinkNav={shrinkNav} />

        <div className="heroContent__box">
          <Tilt
            perspective={1000}
            className="parallax-effect"
            trackOnWindow={true}
          >
            <div className="heroContent__box--image inner-element">
              <img
                src={
                  siteBranding?.loadingScreenLogo
                    ? config.aws.CLOUDFRONT_URL + siteBranding.loadingScreenLogo
                    : FreeloaderLogo
                }
                alt={siteBranding?.site_name}
              />
            </div>
          </Tilt>

          <BigText
            id="brandName"
            data-value={siteBranding?.site_name}
            className="heroContent__box--title"
          >
            {siteBranding?.site_name}
          </BigText>
          <SmallText className="heroContent__box--subTitle">
            A Social Co-Op, A Better Way to Watch
          </SmallText>
        </div>
      </div>
    </HeroStyled>
  );
};

export default Hero;

const pulse = keyframes`
  from {
    transform: scale(.96);
  }

 to {
    transform: scale(1.08);
  }
`;

const HeroStyled = styled.div`
  width: 100%;
  min-height: 105vh;
  position: relative;
  overflow: hidden;
  margin-bottom: 10rem;

  #blob {
    background-color: white;
    height: 34vmax;
    aspect-ratio: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    border-radius: 50%;
    background: ${(props) => props.theme.primaryGradient};
    animation: rotate 20s infinite;
    opacity: 0.8;
  }

  #blur {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    backdrop-filter: blur(15vmax);
  }

  .heroContent {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 3;

    &__box {
      width: 50%;
      margin: 0 auto;
      padding: 2rem 0;
      margin-top: 7rem;
      display: flex;
      align-items: center;
      flex-direction: column;

      &--image {
        height: 25rem;
        width: auto;
        animation: ${pulse} 2.5s infinite;
        animation-direction: alternate;

        img {
          height: 100%;
          width: 100%;
        }
      }

      &--title {
        color: #fff;
        margin-top: 1rem;
        font-style: normal;
        font-size: clamp(2rem, 5.5vw, 6rem);
        font-weight: 600;
      }

      &--subTitle {
        margin-top: 0.2rem;
        letter-spacing: 3px;
      }
    }
  }

  .glare-wrapper {
    .glare {
      background: none !important;
    }
  }

  .parallax-effect {
    transform-style: preserve-3d;

    .inner-element {
      transform: translateZ(60px);
    }
  }

  @media screen and (max-width: 480px) {
    .heroContent {
      &__box {
        width: 90%;
        padding: 2rem 0;
        margin-top: 6rem;

        &--image {
          height: 22rem;
        }

        &--title {
          font-size: clamp(2rem, 12vw, 6rem);
        }

        &--subTitle {
          margin-top: 0.5rem;
          letter-spacing: 2px;
          text-align: center;
        }
      }
    }
  }

  @media screen and (max-width: 414px) {
    .heroContent {
      &__box {
        width: 100%;
        margin-top: 5rem;

        &--image {
          height: 20rem;
        }

        &--title {
          font-size: clamp(2rem, 12vw, 5rem);
        }

        &--subTitle {
          font-size: 0.6rem;
        }
      }
    }
  }
`;
