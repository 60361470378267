import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminAllVideoData, deleteAdminVideo } from "../../actions";
import styled from "styled-components";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
} from "@material-ui/core";
import AdminSidebar from "../AdminSidebar";
import { IoTrashSharp } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import Pagination from "../Pagination";
import VideoEditTable from "./VideoEditTable";
import config from "../../config";
import ConfirmationModel from "../../components/ConfirmationModel";
import { useHistory } from "react-router-dom";

const VideoTable = ({ close }) => {
  const history = useHistory();

  const videoData = useSelector((state) => state.videoTableData.videoData);
  const dispatch = useDispatch();
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [videoFormData, setVideoFormData] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [sortColumn, setSortColumn] = useState("title");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    dispatch(adminAllVideoData()).then(() => {});
  }, [dispatch]);

  const tableHeadingStyle = {
    color: "white",
    border: "1px solid white",
    fontSize: "13px",
  };

  // Pagintaion Logic========================
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Edit logic============================
  const handleFormChange = (field, value) => {
    setVideoFormData({ ...videoFormData, [field]: value });
  };
  const handleEdit = (row) => {
    setIsEditing(true);
    setVideoFormData({
      id: row.id,
      title: row.title,
      description: row.description,
      userId: row.userId,
      muxid: row.muxid,
      createdAt: row.createdAt,
      amount: row.amount,
    });
  };
  // Delete Logic
  const handleDelete = (row) => {
    setShowDeleteConfirmation(true);
    setVideoFormData({ id: row.id });
  };

  const deleteVideoHandler = async () => {
    try {
      await dispatch(deleteAdminVideo(videoFormData.id));
      setShowDeleteConfirmation(false);
      dispatch(adminAllVideoData());
    } catch (error) {
      console.error(error);
    }
  };
  // Function to filter data based on the search input
  const filterData = () => {
    return videoData
      ? videoData.filter((row) => {
          const lowerSearch = search.toLowerCase();
          return (
            row.title.toLowerCase().includes(search.toLowerCase()) ||
            row.description.toLowerCase().includes(search.toLowerCase()) ||
            row.userId.toLowerCase().includes(search.toLowerCase()) ||
            row.muxid.toLowerCase().includes(search.toLowerCase())
          );
        })
      : [];
  };
  const filteredData = filterData();
  // Sorting data ----------------------
  const sortData = () => {
    const sortedData = [...filteredData];
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortColumn].localeCompare(b[sortColumn]);
      } else {
        return b[sortColumn].localeCompare(a[sortColumn]);
      }
    });
    return sortedData;
  };
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell style={tableHeadingStyle}>SR.No </TableCell>
          <TableCell
            style={tableHeadingStyle}
            onClick={() => handleSort("title")}
          >
            Title{" "}
            {sortColumn === "title" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell
            style={tableHeadingStyle}
            onClick={() => handleSort("title")}
          >
            Description{" "}
            {sortColumn === "title" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>

          <TableCell
            style={tableHeadingStyle}
            onClick={() => handleSort("title")}
          >
            User Id{" "}
            {sortColumn === "title" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell style={tableHeadingStyle}> Muxid</TableCell>
          <TableCell style={tableHeadingStyle}>createdAt</TableCell>
          <TableCell style={tableHeadingStyle}>Thumbnail</TableCell>
          <TableCell style={tableHeadingStyle}> Amount</TableCell>
          <TableCell style={tableHeadingStyle}>Action</TableCell>
        </TableRow>
      </TableHead>
    );
  };
  return (
    <>
      <VideoDasboard>
        <AdminSidebar />
        <TableContainer component={Paper} className="responsive-table">
          <h1 className="headingText">Search The Video</h1>
          <input
            type="text"
            placeholder="Search The Video Table"
            value={search}
            className="searchBox"
            onChange={(e) => setSearch(e.target.value)}
            style={{ color: search ? "white" : "white" }}
          />
          <Table>
            {renderTableHeader()}
            <TableBody>
              {sortData()
                .slice(startIndex, endIndex)
                .map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell style={tableHeadingStyle}>
                      {startIndex + index + 1}
                    </TableCell>
                    <TableCell style={tableHeadingStyle}>{row.title}</TableCell>
                    <TableCell style={tableHeadingStyle}>
                      {row.description}
                    </TableCell>
                    <TableCell style={tableHeadingStyle}>
                      {row.userId}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        border: "1px solid white",
                        fontSize: "13px",
                        wordBreak: "break-all",
                      }}
                    >
                      {row.muxid}
                    </TableCell>
                    <TableCell style={tableHeadingStyle}>
                      {row.createdAt}
                    </TableCell>
                    <TableCell
                      style={tableHeadingStyle}
                      align="center"
                      onClick={() => {
                        history.push(`/watch/${row.id}`);
                      }}
                    >
                      <img
                        src={config.aws.CLOUDFRONT_URL + row.thumbnail}
                        alt={row.thumbnail}
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    </TableCell>
                    <TableCell style={tableHeadingStyle} align="center">
                      {row.amount}
                    </TableCell>
                    <TableCell style={tableHeadingStyle}>
                      <FiEdit
                        style={{ margin: "8px" }}
                        className="edit-button"
                        size={28}
                        onClick={() => handleEdit(row)}
                      />
                      <IoTrashSharp
                        className="delete-button"
                        style={{ margin: "8px" }}
                        size={28}
                        onClick={() => handleDelete(row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div style={{ marginTop: "7px", marginBottom: "10%" }}>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={videoData ? videoData.length : 0}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </TableContainer>
      </VideoDasboard>
      {isEditing && (
        <VideoEditTable
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          videoFormData={videoFormData}
          onChange={handleFormChange}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationModel
          closeHandler={() => setShowDeleteConfirmation(false)}
          title="Are you sure you want to delete this Video Table? You can't undo this action."
          deleteHandler={deleteVideoHandler}
        />
      )}
    </>
  );
};

export default VideoTable;

const VideoDasboard = styled.div`
  display: flex;
  overflow-x: hidden;
  // background: #333;
  background: #212123;
  height: auto;
  z-index: 1000;

  .edit-button:hover {
    transform: scale(1.3);
  }
  .edit-button {
    color: #4f92f7;
    marginleft: 10px;
    transition: transform 0.2s;
  }

  .delete-button:hover {
    transform: scale(1.3);
  }

  .delete-button {
    color: #f54e4e;
    margin: 10px;
    transition: transform 0.2s;
  }
  @media (max-width: 768px) {
    .responsive-table th,
    .responsive-table td {
      max-width: fit-content !important;
    }
    .responsive-table {
      overflow: scroll !important;
      max-width: 86%;
      max-height: 84vh;
    }
  }

  @media screen and (max-width: 375px) {
    .responsive-table th,
    .responsive-table td {
      max-width: fit-content !important;
    }
    .responsive-table {
      overflow: scroll !important;
      max-width: 86%;
      max-height: 84vh;
    }
  }
  @media screen and (max-width: 414px) {
    .responsive-table th,
    .responsive-table td {
      max-width: fit-content !important;
    }
    .responsive-table {
      overflow: scroll !important;
      max-width: 86%;
      max-height: 84vh;
    }
  }
  @media screen and (max-width: 480px) {
    .responsive-table th,
    .responsive-table td {
      max-width: fit-content !important;
    }
    .responsive-table {
      overflow: scroll !important;
      max-width: 86%;
      max-height: 84vh;
    }
  }
  @media screen and (max-width: 1023px) {
    .responsive-table th,
    .responsive-table td {
      max-width: fit-content !important;
    }
    .responsive-table {
      overflow: scroll !important;
      max-width: 86%;
      max-height: 84vh;
    }
  }
  @media screen and (width: 1440px) and (height: 845px) {
    .responsive-table th,
    .responsive-table td {
      max-width: fit-content !important;
    }
    .responsive-table {
      overflow: scroll !important;
      max-width: 86%;
      max-height: 84vh;
    }
  }

  .searchBox {
    background: #2c2c2e !important;
    color: white;
    margin-bottom: 2%;
    width: 98%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: #cdc5c5;
    }
  }

  /* Responsive styling for the search box */
  @media (max-width: 768px) {
    .SearchBox {
      width: 100%;
    }
  }

  .headingText {
    // margin-left: 2%;
    font-size: 18px;
    margin-top: 10px;
    color: white;
  }
  .responsive-table {
    max-width: 86%;
    // background-color: #333
    background: #212123;
    height: 150vh;
    overflow: hidden;
    padding-left: 3%;
    padding-right: 1%;
  }
  .responsive-table table {
    width: 98%;
    // margin-left: 2%;
    color: "white";
  }
  .responsive-table th,
  .responsive-table td {
    overflow: hidden;
    max-width: 10px;
  }
`;
