import React from "react";
import styled from "styled-components";
import SkeletonElement from "../SkeletonElement";
import { Row, Col } from "react-grid-system";

const Page = () => {
  return (
    <PageSkeletonStyled>
      <SkeletonElement type="title" mb="2rem" />
      <Row>
        {Array.from({ length: 8 }).map((item) => (
          <Col
            md={3}
            style={{
              paddingLeft: "8px",
              paddingRight: "8px",
              marginBottom: "1rem",
            }}
          >
            <SkeletonElement type="video" mb="1rem" />
            <div className="infoBox">
              <div className="infoBox__left">
                <SkeletonElement type="avatarSM" mr="1rem" />
              </div>
              <div className="infoBox__right">
                <SkeletonElement type="paragraph" mb=".5rem" />
                <SkeletonElement type="paragraph" />
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </PageSkeletonStyled>
  );
};

export default Page;

const PageSkeletonStyled = styled.div`
  width: 90%;
  margin: 6rem auto 2rem auto;

  .infoBox {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    width: 100%;

    &__right {
      width: calc(100% - 3.5rem);
    }
  }
`;
