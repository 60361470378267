import React from "react";
import styled, { keyframes } from "styled-components";

const SkeletonShimmer = ({ varient }) => {
  return (
    <SkeletonShimmerStyled>
      <div
        className="shimmer"
        style={{
          background:
            varient === "moreWhite"
              ? "rgba(255, 255, 255, 0.08)"
              : "rgba(255, 255, 255, 0.02)",
        }}
      ></div>
    </SkeletonShimmerStyled>
  );
};

export default SkeletonShimmer;

const loading = keyframes`
    0% {
        transform: translateX(-200%);
    }
    50% {
        transform: translateX(-60%);
    }
    100% {
        transform: translateX(200%);
    }
`;

const SkeletonShimmerStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${loading} 2.5s infinite;

  .shimmer {
    width: 40%;
    height: 100%;

    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.02);
  }
`;
