import React, { useState, useContext } from "react";
import Popover from "@mui/material/Popover";
import config from "../../config";
import styled, { css } from "styled-components";
import { styled as MUIStyled } from "@mui/material";
import { GlobalContext } from "../../context/GlobalContext";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useCopyToClipboard } from "usehooks-ts";
import { notificationMessage } from "../../utils/toastNotifications";
import {
  getProfile,
  subscribeChannel,
  unsubscribeChannel,
} from "../../actions";
import {
  deleteComment as deleteThreadComment,
  deleteReplyComment as deleteThreadReplyComment,
} from "../../actions/thread";
import {
  SUBSCRIBE_FROM_VIDEO,
  UNSUBSCRIBE_FROM_VIDEO,
} from "../../actions/types";

const PopoverStyled = MUIStyled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    borderRadius: `5px`,
    background: `#202020`,
    color: "#fff",
    border: "1px solid rgba(255,255,255, .1)",
  },

  "& .MuiPaper-root": {
    boxShadow: "none",

    "&:not(:last-child)": {
      borderBottom: "1px solid #fff",
    },
  },

  "& .MuiList-root": {
    padding: "0.4rem .35rem .1rem .35rem",
  },
}));

const MenuOptions = ({
  options,
  setSelectedVideo,
  item,
  setVideoId,
  setSelectedSeries,
  setSeriesId,
  type,
  setSelectedMoment,
  setMomentId,
  setSelectedPlaylist,
  hideBorder,
  threadId,
  replyComment,
  setSelectedComment,
  setSelectedReply,
  channel,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    setShowEditClipModel,
    setDeleteVideoConfirmationModel,
    setShowEditProfile,
    setSelectedVideoId,
    setPlaylistModelType,
    setShowPlaylistModel,
    setDeleteSeriesConfirmationModel,
    setEditSeriesModel,
    setSeriesEditMode,
    setShowEditMomentModel,
    setDeleteMomentConfirmationModel,
    setShowMomentDetailsModel,
    setSelectedMoment: globalSetSelectedMoment,
    showEditProfile,
    setPortalTab,
    setPlaylistModelShowType,
    setDeletePlaylistConfirmationModel,
    setSelectedThread,
    setDeleteThreadConfirmationModel,
    setShowUpdateThreadModel,
  } = useContext(GlobalContext);
  const user = useSelector((state) => state.user);
  const [videoUrl, setVideoUrl] = useState(
    `${config.frontend.baseUrl}watch/${item?.id}`
  );
  const [seriesUrl, setSeriesUrl] = useState(
    `${config.frontend.baseUrl}series/details/${item?.id}`
  );
  const [momentUrl, setMomentUrl] = useState(
    `${config.frontend.baseUrl}moment/${item?.id}`
  );
  const [threadUrl, setThreadUrl] = useState(
    `${config.frontend.baseUrl}/thread/${item?.id}`
  );
  const [copiedUrl, copyUrlHandler] = useCopyToClipboard();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const itemClickHandler = (e, optionItem) => {
    e.stopPropagation();

    handleClose();
    switch (optionItem.type) {
      case "editVideo":
        if (showEditProfile) {
          setShowEditProfile(false);
          setPortalTab(1);
          return;
        }

        setVideoId(item.id);
        setSelectedVideo(item);
        setShowEditClipModel(true);
        return;
      case "deleteVideo":
        if (showEditProfile) {
          setShowEditProfile(false);
          setPortalTab(1);
          return;
        }

        // activate confirmation model
        setSelectedVideo(item);
        setDeleteVideoConfirmationModel(true);
        return;
      case "visitCreatorPage":
        history.push(
          `/channel/${
            item?.User?.username ? item.User.username : item.user.username
          }`
        );
        return;
      case "removeFromFeatured":
        setShowEditProfile(true);
        return;
      case "saveVideoToPlaylist":
        setSelectedVideoId(item.id);
        setPlaylistModelType("save");
        setShowPlaylistModel(true);
        return;

      case "deleteSeries":
        if (showEditProfile) {
          setShowEditProfile(false);
          setPortalTab(5);
          return;
        }

        setSeriesId(item.id);
        setSelectedSeries(item);
        setDeleteSeriesConfirmationModel(true);
        return;
      case "editSeries":
        if (showEditProfile) {
          setShowEditProfile(false);
          setPortalTab(5);
          return;
        }

        setSeriesEditMode("DETAILS");
        setSeriesId(item.id);
        setSelectedSeries(item);
        setEditSeriesModel(true);
        return;
      case "manageSeriesVideos":
        if (showEditProfile) {
          setShowEditProfile(false);
          setPortalTab(5);
          return;
        }

        setSeriesEditMode("VIDEOS");
        setSeriesId(item.id);
        setSelectedSeries(item);
        setEditSeriesModel(true);
        return;
      case "editMoment":
        setMomentId(item.id);
        setSelectedMoment(item);
        setShowEditMomentModel(true);

        return;
      case "deleteMoment":
        setMomentId(item.id);
        setSelectedMoment(item);
        setDeleteMomentConfirmationModel(true);
        return;
      case "viewMoment":
        setSelectedMoment(item);
        globalSetSelectedMoment(item);
        setShowMomentDetailsModel(true);
        return;

      case "editPlaylist":
        setSelectedPlaylist(item);
        setPlaylistModelShowType("UPDATE_FORM");
        return;
      case "deletePlaylist":
        setSelectedPlaylist(item);
        setDeletePlaylistConfirmationModel(true);
        return;

      case "addToStreams":
        dispatch(
          subscribeChannel({
            channel: {
              avatar: item.User.avatar,
              id: item.User.id,
              username: item.User.username,
              visitorBadge: {
                imgPath: item.User.badge,
              },
            },
            type: SUBSCRIBE_FROM_VIDEO,
          })
        );
        return;

      case "removeFromStreams":
        dispatch(
          unsubscribeChannel({
            username: channel ? channel.username : item.User.username,
            channelId: channel ? channel.id : item.User.id,
            type: UNSUBSCRIBE_FROM_VIDEO,
          })
        );
        dispatch(getProfile(user.username));
        // alert("work pending");
        return;

      // threads
      case "deleteThread":
        setSelectedThread(item);
        setDeleteThreadConfirmationModel(true);
        return;
      case "editThread":
        setSelectedThread(item);
        setShowUpdateThreadModel(true);
        return;
      case "deleteThreadComment":
        if (replyComment) {
          dispatch(deleteThreadReplyComment(threadId, item?.id));
          return;
        }
        dispatch(deleteThreadComment(threadId, item?.id));
        return;
      case "editThreadComment":
        if (replyComment) {
          setSelectedReply(item);
          return;
        }
        setSelectedComment(item);
        return;
      case "reportThreadComment":
        alert("Work pending");
        return;
      default:
        return;
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <MenuOptionsStyled hideBorder={hideBorder}>
        {JSON.stringify(user) === "{}" ? (
          ""
        ) : (
          <div
            onClick={handleClick}
            aria-describedby={id}
            className="options-menu-right"
            variant="contained"
          >
            <i className="fa fa-ellipsis-v"></i>
          </div>
        )}
        <PopoverStyled
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: `${type === "moment" ? "top" : "bottom"}`,
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: `${type === "moment" ? "top" : "bottom"}`,
            horizontal: "right",
          }}
        >
          {options &&
            options.length > 0 &&
            options.map((option, i) => {
              if (
                option.type === "shareVideo" ||
                option.type === "shareSeries" ||
                option.type === "shareMoment" ||
                option.type === "shareThread"
              ) {
                return (
                  <ItemStyled
                    key={i}
                    onClick={(e) => {
                      e.stopPropagation();

                      handleClose();

                      if (option.type === "shareVideo") {
                        copyUrlHandler(videoUrl);
                        toast.success(notificationMessage.copyVideoUrl);
                      } else if (option.type === "shareSeries") {
                        copyUrlHandler(seriesUrl);
                        toast.success(notificationMessage.copySeriesUrl);
                      } else if (option.type === "shareMoment") {
                        copyUrlHandler(momentUrl);
                        toast.success(notificationMessage.copyMomentUrl);
                      } else if (option.type === "shareThread") {
                        copyUrlHandler(threadUrl);
                        toast.success(notificationMessage.copyThreadUrl);
                      } else {
                        return;
                      }
                    }}
                  >
                    {!option.icon ? (
                      <i className="fa fa-share-alt" aria-hidden="true"></i>
                    ) : option.imgIcon ? (
                      <img src={option.icon} />
                    ) : (
                      option.icon
                    )}
                    <div className="text">{option.text}</div>
                  </ItemStyled>
                );
              } else {
                return (
                  <ItemStyled
                    key={i}
                    onClick={(e) => itemClickHandler(e, option)}
                  >
                    {!option.icon ? (
                      <i className="fa fa-share-alt" aria-hidden="true"></i>
                    ) : option.imgIcon ? (
                      <img src={option.icon} />
                    ) : (
                      option.icon
                    )}
                    <div className="text">{option.text}</div>
                  </ItemStyled>
                );
              }
            })}
        </PopoverStyled>
      </MenuOptionsStyled>
    </>
  );
};

export default MenuOptions;

const ItemStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:hover {
    background: ${(props) => props.theme.primaryGradient};
  }

  i {
    margin-right: 0.7rem;
  }

  img {
    height: 1rem;
    width: auto;
    margin-right: 0.7rem;
  }

  .text {
    text-transform: capitalize;
    font-size: 0.85rem;
  }
`;

const MenuOptionsStyled = styled.div`
  .options-menu-right {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    border: 1px solid transparent;
    transition: all 0.3s ease;

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  ${(props) =>
    props.hideBorder &&
    css`
     .options-menu-right {
      &:hover {
        border: 1px solid transparent;
      }
    `}
`;
