import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import Button from "../../Elements/Button";
import ChevronLeftIcon from "../../icons/chevronleft.svg";
import MembershipIcon from "../../icons/membership.svg";
import StorageIcon from "../../icons/storage.svg";
import ReferrelIcon from "../../icons/referrel.svg";
import PersonIcon from "../../icons/person.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import LoaderIcon from "../../icons/loader.svg";
import CheckoutForm from "../../CheckoutForm";
import { getSingleStorage } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import config from "../../../config";
import DollarIcon from "../../icons/dollar.svg";
import styled from "styled-components";
import { elementScrollToTop } from "../../../utils";

const stripePromise = loadStripe(`${config.stripe.STRIPE_PUBLIC_KEY}`);

const useStyles = makeStyles((theme) => ({
  Checkbox: {
    "& .MuiSvgIcon-root": {
      fill: "rgb(249,154,45)",
    },
  },
}));

const Payment = ({
  setProgress,
  signupCompleteFree,
  signupCompletePremium,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const singleStorage = useSelector((state) => state.singleStorage);
  const {
    choosePackage,
    agreedToLicense,
    setAgreedToLicense,
    setBadgeObj,
    badgeObj,
    setStorageObj,
    storageObj,
    loader,
    setLoader,
    selectedStorage,
    setShowAgreementPage,
    referralCode,
  } = useContext(AuthContext);

  useEffect(() => {
    elementScrollToTop("authContent");
  }, []);

  useEffect(() => {
    if (selectedStorage) {
      dispatch(getSingleStorage(selectedStorage));
    }
  }, [selectedStorage]);

  const backHandler = () => {
    if (choosePackage === "FREE" || choosePackage === "BASIC") {
      return setProgress(3);
    }

    setProgress(5);
  };

  const calculateStoragePrice = () => {
    if (singleStorage.length && choosePackage === "PREMIUM") {
      const storagePrice = singleStorage[0].cost;
      return storagePrice + 5; // includes $5/year Co-Op fee for Warriors
    } else if (choosePackage === "BASIC") {
      return 5;
    } else {
      return 0;
    }
  };

  return (
    <PaymentStyled>
      <div className="actionRow">
        <Button
          size="small"
          variant="outline"
          mode="secondary"
          onClick={backHandler}
          className="actionRow__back"
        >
          <img src={ChevronLeftIcon} alt="" className="icon" />
          <span>
            Edit{" "}
            {choosePackage === "FREE" || choosePackage === "BASIC"
              ? "Badges"
              : "User Settings"}
          </span>
        </Button>
      </div>

      <div className="paddingBox">
        <div className="title">Complete your signup</div>

        <div className="payment">
          <div className="payment__summary">
            <div className="title">Summary</div>

            <>
              <div className="item">
                <img src={PersonIcon} alt="" className="item__icon" />
                Personal Information
                <div className="item__bold">Completed</div>
                <div onClick={() => setProgress(1)} className="item__switch">
                  (change)
                </div>
              </div>

              <div className="item">
                <img src={MembershipIcon} alt="" className="item__icon" />
                Selected Membership
                <div className="item__bold">
                  {choosePackage === "FREE"
                    ? "Free"
                    : choosePackage === "BASIC"
                    ? "CO-OP"
                    : "Warrior"}
                </div>
                <div
                  onClick={() => {
                    setBadgeObj(null);
                    setStorageObj(null);
                    setProgress(2);
                  }}
                  className="item__switch"
                >
                  (change)
                </div>
              </div>

              <div className="item">
                <img src={PersonIcon} alt="" className="item__icon" />
                Selected Badge:{" "}
                <div className="item__bold">{badgeObj?.name}</div>
                <div onClick={() => setProgress(3)} className="item__switch">
                  (change)
                </div>
              </div>

              {choosePackage === "PREMIUM" && (
                <div className="item">
                  <img src={StorageIcon} alt="" className="item__icon" />
                  Selected Storage:{" "}
                  <div className="item__bold">
                    ${calculateStoragePrice() - 5}&nbsp;Monthly&nbsp;
                    {storageObj?.name}
                  </div>
                  <div onClick={() => setProgress(4)} className="item__switch">
                    (change)
                  </div>
                </div>
              )}

              {choosePackage === "PREMIUM" && (
                <div className="item">
                  <img src={PersonIcon} alt="" className="item__icon" />
                  User Display Settings:{" "}
                  <div className="item__bold">Completed</div>
                  <div onClick={() => setProgress(5)} className="item__switch">
                    (change)
                  </div>
                </div>
              )}
              {(choosePackage === "BASIC" || choosePackage === "PREMIUM") && (
                <div className="item">
                  <img src={ReferrelIcon} alt="" className="item__icon" />
                  $2.50 Referral sent to: {referralCode}
                </div>
              )}

              {choosePackage === "PREMIUM" && (
                <div className="item">
                  <img src={MembershipIcon} alt="" className="item__icon" />
                  $5 yearly co-op fee.
                </div>
              )}

              {choosePackage === "PREMIUM" && (
                <div className="item">
                  <img src={DollarIcon} alt="" className="item__icon" />
                  Data Plan Amount recurring monthly:
                  <div className="item__bold">
                    ${calculateStoragePrice() - 5}
                  </div>
                </div>
              )}
              {choosePackage === "PREMIUM" && (
                <div className="item">
                  <img src={DollarIcon} alt="" className="item__icon" />
                  Amount due today:
                  <div className="item__bold">${calculateStoragePrice()}</div>
                </div>
              )}

              {choosePackage === "BASIC" && (
                <div className="item">
                  <img src={MembershipIcon} alt="" className="item__icon" />
                  $5 yearly co-op fee.
                </div>
              )}

              {choosePackage === "BASIC" && (
                <div className="item">
                  <img src={DollarIcon} alt="" className="item__icon" />
                  Total Amount To Pay:
                  <div className="item__bold">$ 5</div>
                </div>
              )}
            </>
          </div>

          {choosePackage !== "FREE" && (
            <div className="payment__checkout">
              {choosePackage === "BASIC" && (
                <div className="checkBox" style={{ marginBottom: "2rem" }}>
                  <FormControlLabel
                    className={classes.Checkbox}
                    control={
                      <Checkbox
                        checked={agreedToLicense}
                        onChange={() => setAgreedToLicense(!agreedToLicense)}
                        name="agreedToLicense"
                      />
                    }
                    label="Accept Terms"
                  />

                  <p onClick={() => setShowAgreementPage(true)}>
                    Please agree to the iSutra Bill of Rights
                  </p>
                </div>
              )}

              <Elements stripe={stripePromise}>
                <CheckoutForm
                  completeSignup={signupCompletePremium}
                  choosePackage={choosePackage}
                  page="signup"
                  storagePrice={calculateStoragePrice()}
                />
              </Elements>
            </div>
          )}

          {choosePackage === "FREE" && (
            <div className="payment__withoutCheckout">
              <div className="checkBox">
                <FormControlLabel
                  className={classes.Checkbox}
                  control={
                    <Checkbox
                      checked={agreedToLicense}
                      onChange={() => setAgreedToLicense(!agreedToLicense)}
                      name="agreedToLicense"
                    />
                  }
                  label="Accept Terms"
                />

                <p onClick={() => setShowAgreementPage(true)}>
                  Please agree to the iSutra Bill of Rights
                </p>
              </div>

              <Button
                mode="primary"
                width="fluid"
                variant="round"
                size="medium"
                iconLeft={true}
                onClick={() => {
                  setLoader(true);
                  signupCompleteFree();
                }}
                className="payment__withoutCheckout--submit"
              >
                {loader ? (
                  <>
                    <img src={LoaderIcon} alt="" className="imgIcon" />
                    Creating Account...
                  </>
                ) : (
                  "COMPLETE PROCESS"
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
    </PaymentStyled>
  );
};

export default Payment;

const PaymentStyled = styled.div`
  .animationBox {
    padding: 2rem;
  }
`;
