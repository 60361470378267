import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-grid-system";
import SkeletonShimmer from "../SkeletonShimmer";
import SkeletonElement from "../SkeletonElement";
import { useMediaQuery } from "react-responsive";

const WatchVideo = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  return (
    <WatchVideoSkeletonStyled>
      <Row>
        <Col md={8} sm={12}>
          <div className="thumbnail">
            <SkeletonShimmer />
          </div>

          <div className="row">
            <div className="actionsTab">
              {Array.from({ length: 3 }).map((item) => (
                <div key={item} className="actionsTab__item">
                  <SkeletonElement type="avatarSM" />
                </div>
              ))}
            </div>

            <div className="actionsBtn">
              <SkeletonElement type="buttonMD" />
            </div>
          </div>

          <div className="divider"></div>

          <div className="row">
            <SkeletonElement type="avatarXL" />
            <SkeletonElement type="buttonMD" />
          </div>

          {!isMobile && (
            <>
              <div className="divider"></div>

              <div className="comments">
                <SkeletonElement type="title" mb="2rem" />

                {Array.from({ length: 5 }).map((item) => (
                  <div className="comments__item">
                    <div className="left">
                      <SkeletonElement type="avatarSM" varient="dark" />
                    </div>

                    <div className="right">
                      <SkeletonElement
                        type="paragraph"
                        mb=".5rem"
                        varient="dark"
                      />
                      <SkeletonElement
                        type="paragraph"
                        mb=".5rem"
                        varient="dark"
                      />

                      <div className="actions">
                        <SkeletonElement
                          type="avatarXS"
                          mr=".6rem"
                          varient="dark"
                        />
                        <SkeletonElement
                          type="avatarXS"
                          mr=".6rem"
                          varient="dark"
                        />
                        <SkeletonElement type="avatarXS" varient="dark" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </Col>

        <Col md={4} sm={12}>
          <div className="sidebar">
            <SkeletonElement type="title" mb="1rem" />

            <div className="sidebar__videos">
              {Array.from({ length: 4 }).map((item) => (
                <div className="sidebar__videos--item">
                  <SkeletonElement type="video" mb="1rem" />
                  <div className="infoBox">
                    <div className="infoBox__left">
                      <SkeletonElement type="avatarSM" mr="1rem" />
                    </div>
                    <div className="infoBox__right">
                      <SkeletonElement type="paragraph" mb=".5rem" />
                      <SkeletonElement type="paragraph" />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {isMobile && (
              <>
                <div className="divider"></div>

                <div className="comments">
                  <SkeletonElement type="title" mb="2rem" />

                  {Array.from({ length: 5 }).map((item) => (
                    <div className="comments__item">
                      <div className="left">
                        <SkeletonElement type="avatarSM" varient="dark" />
                      </div>

                      <div className="right">
                        <SkeletonElement
                          type="paragraph"
                          mb=".5rem"
                          varient="dark"
                        />
                        <SkeletonElement
                          type="paragraph"
                          mb=".5rem"
                          varient="dark"
                        />

                        <div className="actions">
                          <SkeletonElement
                            type="avatarXS"
                            mr=".6rem"
                            varient="dark"
                          />
                          <SkeletonElement
                            type="avatarXS"
                            mr=".6rem"
                            varient="dark"
                          />
                          <SkeletonElement type="avatarXS" varient="dark" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </WatchVideoSkeletonStyled>
  );
};

export default WatchVideo;

const WatchVideoSkeletonStyled = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 1.5rem 0;

  .thumbnail {
    width: 100%;
    height: 22rem;
    background-color: #202020;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 1.5rem;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem;
  }

  .actionsTab {
    display: flex;
    align-items: center;

    &__item {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.04);
    margin-bottom: 1rem;
  }

  .comments {
    margin-top: 2rem;
    margin-bottom: 5rem;

    &__item {
      padding: 1rem;
      border-radius: 5px;
      background-color: #202020;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      .right {
        width: calc(100% - 4rem);

        .actions {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .sidebar {
    margin-bottom: 5rem;

    &__videos {
      &--item {
        .infoBox {
          display: flex;
          align-items: flex-start;
          margin-bottom: 1rem;
          width: 100%;

          &__right {
            width: calc(100% - 3.5rem);
          }

          .avatar {
            height: 2.5rem;
            width: 2.5rem;
            margin-right: 1rem;
            background-color: #202020;
            border-radius: 50%;
            border: 1px solid rgba(255, 255, 255, 0.03);
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    width: 95%;

    .thumbnail {
      height: 17rem;
      margin-bottom: 1rem;
    }

    .actionsTab {
      &__item {
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    .sidebar {
      margin-bottom: 5rem;

      &__videos {
        &--item {
          &:last-child {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 414px) {
    .actionsTab {
      &__item {
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
  }
`;
