import React from "react";
import styled from "styled-components";
import SkeletonElement from "../SkeletonElement";
import SkeletonShimmer from "../SkeletonShimmer";

const Card = ({ cardName }) => {
  if (cardName === "featured_series")
    return (
      <CardStyled>
        <SkeletonElement type="featuredVideo" />
        <div className="infoBox__full">
          <div className="infoBox__full--top">
            <SkeletonElement type="avatarMD" />
          </div>
          <div className="infoBox__full--bottom">
            <SkeletonElement type="paragraph" mb=".5rem" />
            <SkeletonElement type="paragraph" />
          </div>
        </div>
      </CardStyled>
    );

  if (cardName === "videos") {
    return (
      <CardStyled>
        <SkeletonElement type="video" mb="1rem" />
        <div className="infoBox">
          <div className="infoBox__left">
            <SkeletonElement type="avatarSM" mr="1rem" />
          </div>
          <div className="infoBox__right">
            <SkeletonElement type="paragraph" mb=".5rem" />
            <SkeletonElement type="paragraph" />
          </div>
        </div>
      </CardStyled>
    );
  }

  if (cardName === "moment") {
    return (
      <CardStyled>
        <div className="moment">
          <SkeletonShimmer />

          <div className="moment__info">
            <div className="left">
              <SkeletonElement type="avatarSM" mr="1rem" varient="dark" />
            </div>

            <div className="right">
              <SkeletonElement
                className="title"
                type="title"
                varient="dark"
                mb=".5rem"
              />
              <SkeletonElement type="paragraph" varient="dark" />
            </div>
          </div>
        </div>
      </CardStyled>
    );
  }

  if (cardName === "chatItem") {
    return (
      <CardStyled>
        <div className="chatItem">
          <div className="left">
            <SkeletonElement type="avatarSM" varient="dark" />
          </div>

          <div className="right">
            <SkeletonElement type="paragraph" mb=".5rem" varient="dark" />

            <div className="actions">
              <SkeletonElement type="avatarXS" mr=".6rem" varient="dark" />
              <SkeletonElement type="avatarXS" mr=".6rem" varient="dark" />
              <SkeletonElement type="avatarXS" varient="dark" />
            </div>
          </div>
        </div>
      </CardStyled>
    );
  }

  if (cardName === "hashtag") {
    return (
      <CardStyled>
        <div className="hashtag">
          <div className="hashtag__top">
            <div className="left">
              <SkeletonElement type="title" varient="dark" />
            </div>

            <div className="right">
              <SkeletonElement type="avatarSM" varient="dark" />
            </div>
          </div>

          <div className="hashtag__type">
            <div className="hashtag__type--item">
              <SkeletonElement type="paragraph" varient="dark" />
            </div>

            <div className="hashtag__type--item">
              <SkeletonElement type="paragraph" varient="dark" />
            </div>

            <div className="hashtag__type--item">
              <SkeletonElement type="paragraph" varient="dark" />
            </div>
          </div>

          <div className="hashtag__bottom">
            <SkeletonElement
              className="hashtag__bottom--btn"
              type="buttonMD"
              varient="dark"
            />
          </div>
        </div>
      </CardStyled>
    );
  }

  return null;
};

export default Card;

const CardStyled = styled.div`
  .moment {
    height: calc(100vh - 75px - 24px * 3);
    width: calc(calc(100vh - 75px - 24px * 3) * 0.5625);
    margin: 0 auto;
    min-height: 560px;
    min-width: 320px;
    background-color: #202020;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.09);

    &__info {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 5rem;
      width: 100%;
      padding: 0 1rem;
      display: flex;
      align-items: center;

      .right {
        width: calc(100% - 4.5rem);
      }

      .title {
        width: 100%;
      }
    }
  }

  .infoBox {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    width: 100%;

    &__right {
      width: calc(100% - 3.5rem);
    }

    &__full {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0 auto;
      padding: 1rem 0;

      &--top {
        margin-right: 1rem;
      }

      &--bottom {
        width: calc(100% - 4rem);
      }
    }
  }

  .chatItem {
    padding: 1rem;
    border-radius: 5px;
    background-color: #202020;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .right {
      width: calc(100% - 4rem);

      .actions {
        display: flex;
        align-items: center;
      }
    }
  }

  .hashtag {
    background-color: #202020;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.05);

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        width: 100%;
      }
    }

    &__type {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: nowrap;
      padding: 1rem 0;

      &--item {
        min-width: 30%;
      }
    }

    &__bottom {
      &--btn {
        width: 100%;
        margin: 0 auto;
        border-radius: 10rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .moment {
      width: 100%;
      height: auto;
      aspect-ratio: 9 / 16;
    }
  }
`;
