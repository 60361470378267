import {
  GET_CHAT,
  POST_MESSAGE,
} from '../actions/types';

// const initialState = {
//   chat: [],
//   isFetching: true,
// };

const chat = (state = [], action) => {
  switch (action.type) {
    case GET_CHAT:
      return action.payload
    case POST_MESSAGE:
      return action.payload;
    default:
      return state;
  }
};

export default chat;
