import React from "react";
import styled from "styled-components";
import SkeletonShimmer from "./SkeletonShimmer";

const SkeletonElement = ({
  type,
  style,
  mb,
  mt,
  ml,
  mr,
  varient,
  className,
  border,
}) => {
  const classes = `${className} skeleton skeleton__${type}`;

  return (
    <SkeletonElementStyled>
      <div
        style={{
          ...style,
          marginBottom: mb,
          marginTop: mt,
          marginLeft: ml,
          marginRight: mr,
          backgroundColor: varient === "dark" ? "#181818" : "#202020",
          border:
            varient === "dark" ? "1px solid rgba(255, 255, 255, 0.09)" : null,
        }}
        className={classes}
      >
        <SkeletonShimmer />
      </div>
    </SkeletonElementStyled>
  );
};

export default SkeletonElement;

const SkeletonElementStyled = styled.div`
  .skeleton {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.03);
    height: 1.5rem;
    width: 100%;

    position: relative;
    overflow: hidden;

    &__title {
      height: 2rem;
      width: 40%;
    }
    &__paragraph {
      height: 1.2rem;
      border-radius: 2px;
      width: 100%;
    }
    &__category {
      height: 1.8rem;
      width: 4.5rem;
    }
    &__menu {
      height: 1.8rem;
      width: 6rem;
    }
    &__banner {
      width: 100%;
      height: 22rem;
      min-height: 40vh;
    }
    &__bannerSmall {
      width: 100%;
      height: 18rem;
      min-height: 36vh;
    }
    &__moment {
      padding-bottom: 177.78%;
      height: 0;
      width: 100%;
    }
    &__video {
      height: 12rem;
      min-height: 22vh;
    }
    &__featuredVideo {
      height: 16rem;
      min-height: 32vh;
    }
    &__avatarXS {
      height: 1.8rem;
      width: 1.8rem;
      border-radius: 50%;
    }
    &__avatarSM {
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
    }
    &__avatarMD {
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
    }
    &__avatarLG {
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
    }
    &__avatarXL {
      height: 6rem;
      width: 6rem;
      border-radius: 50%;
    }
    &__avatarXXL {
      height: 7rem;
      width: 7rem;
      border-radius: 50%;
    }
    &__buttonMD {
      height: 2.2rem;
      width: 8rem;
      border-radius: 5px;
    }
  }

  @media screen and (max-width: 480px) {
    .skeleton {
      &__banner {
        height: 15rem;
      }

      &__bannerSmall {
        height: 12rem;
      }
    }
  }
`;
