import React, { useContext, useState, useEffect, useRef } from "react";
import BackIcon from "../../assets/Icons/back.svg";
import AddIcon from "../../assets/Icons/add.svg";
import Player from "../Player";
import useInput from "../../hooks/useInput";
import path from "path";
import api from "../../services/api";
import ReactTags from "react-tag-autocomplete";
import VideoCategories from "./VideoCategory";
import styled from "styled-components";
import PriceIcon from "../icons/price.svg";
import TimeIcon from "../icons/time.svg";
import VideoIcon from "../icons/video.svg";
import { GlobalContext } from "../../context/GlobalContext";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { upload, uploadCover } from "../../utils";
import {
  uploadVideoV2,
  getVideoCategories,
  getVideoAccessOverlays,
  getHashtags,
  getUserVideos,
  getAllSeriesOfUser,
  addVideoToSeries,
  getUserStorageInfo,
  addAdditionalContent,
} from "../../actions";
import "../../styles/react-tag.css";
import { Video } from "video-metadata-thumbnails";
import { notificationMessage } from "../../utils/toastNotifications";
import Loader from "../Elements/Loader";
import Error from "../Elements/Error";
import Moment from "react-moment";
import { VIDEO_UPLOAD_RESET } from "../../actions/types";
import config from "../../config";
import { addDefaultImage } from "../../utils";
import ImageNotfoundPlaceholder from "../../assets/image_notfound_placeholder.svg";
import AddAdditionalContent from "../Models/AddAdditionalContent";

const UploadClipForm = ({ close }) => {
  const videoCategories = useSelector((state) => state.video.videoCategories);
  const [defaultCategories, setDefaultCategories] = useState([]);
  const videoAccessOverlays = useSelector(
    (state) => state.videoOverlay.videoAccessOverlays
  );
  const videoHashtags = useSelector((state) => state.video.videoHashtags);
  const user = useSelector((state) => state.user);
  const profile = useSelector((state) => state.profile);
  const {
    loading: getSeriesLoading,
    series: allSeries,
    error: getSeriesError,
  } = useSelector((state) => state.getAllSeriesOfUser);
  const { videoUploadInfo, videoUploadLoading } = useSelector(
    (state) => state.video
  );

  const title = useInput("");
  const ppvAmount = useInput("");
  const description = useInput("");
  const category = "1";
  const [keyVideoAccess, setKeyVideoAccess] = useState(0);
  const [tab, setTab] = useState("FORM");
  const [previewVideo, setPreviewVideo] = useState();
  const [url, setUrl] = useState("");
  const [publicId, setPublicId] = useState("");
  const [muxid, setMuxid] = useState("");
  const [videoLength, setVideoLength] = useState(null);
  const [thumbnail, setThumbnail] = useState("");
  const [filesize, setVideoSize] = useState("");
  const [file, setFile] = useState(null);
  const [customThumbnail, setCustomThumbnail] = useState({
    url: "",
    publicId: "",
  });
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [documentSize, setDocumentSize] = useState("");
  const [documentPath, setDocumentPath] = useState("");

  const {
    setNewCategoryRequestModel,
    setShowUploadClipModel,
    showUploadClipModel,
    videoForSeriesUpload,
    setShowManageSubscription,
    setShowUploadModel,
    showAddAdditionalContentModel,
    setShowAddAdditionalContentModel,
    setShowCreateSeriesModel,
  } = useContext(GlobalContext);

  const [hashTags, sethashTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState([]);
  const [documents, setDocuments] = useState(null);
  const [rewardDescription, setRewardDescription] = useState();
  const [isCheckedHasReward, setIsCheckedHasReward] = useState();
  const [rewardAmount, setRewardAmount] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);

  const reactTags = React.createRef();

  const dispatch = useDispatch();

  useEffect(async () => {
    if (videoForSeriesUpload?.series?.id) {
      await getSeriesCategories(videoForSeriesUpload.series.id);
    }

    if (selectedSeries?.id) {
      await getSeriesCategories(selectedSeries.id);
    }
  }, [videoForSeriesUpload, selectedSeries]);

  const videoObj = {
    page: 1,
    limit: 8,
  };

  useEffect(() => {
    if (videoUploadInfo) {
      // reset state
      resetHandler();

      // update storage information
      dispatch(getUserStorageInfo());

      setShowUploadClipModel(false);
      dispatch(
        getUserVideos(
          {
            showAll: false,
          },
          profile?.id || user?.id,
          videoObj
        )
      );
      //dispatch(getUserVideos({ showAll: true },videoObj, profile?.id || user?.id));
      toast.success(notificationMessage.congratsVideoUploaded);

      // if keyVideoAccess is 4 then add that video to series
      if (videoUploadInfo.keyVideoAccess === 4 && selectedSeries) {
        dispatch(
          addVideoToSeries(selectedSeries?.id, {
            videoId: videoUploadInfo.id,
            order: selectedSeries.videos.length + 1,
          })
        );

        dispatch({
          type: VIDEO_UPLOAD_RESET,
        });
      }

      dispatch({
        type: VIDEO_UPLOAD_RESET,
      });
    }
  }, [videoUploadInfo]);

  useEffect(() => {
    if (keyVideoAccess === 4 && !videoForSeriesUpload.series) {
      // fetch all available series of user
      dispatch(getAllSeriesOfUser(user?.username));
    }

    if (videoForSeriesUpload.series) {
      setSelectedSeries(videoForSeriesUpload.series);
    }
  }, [keyVideoAccess, videoForSeriesUpload]);

  useEffect(() => {
    (async () => {
      if (file && videoLength) {
        if (videoLength > 120 && keyVideoAccess === 5) {
          toast.error(
            "Channel preview video cannot be greater than 2 min length"
          );
          setTab("UPLOAD");
          setVideoLength(null);
          setFile(null);
          return;
        } else {
          try {
            setTab("PREVIEW");
            const userData = JSON.parse(localStorage.getItem("user"));
            const res = await api.get(`/users/space-left/${userData.id}`);
            if (!res.data.data) {
              return toast.error(CustomToastWithLink);
            } else {
              if (file) {
                setVideoSize(file.size);
                const size = file.size / 1000000;

                if (size > 10000) {
                  setShowUploadClipModel(false);
                  return toast.error(notificationMessage.fileSizeLimit);
                }
                const url = URL.createObjectURL(file);
                setPreviewVideo(url);

                const data = await upload(
                  "video",
                  file,
                  videoLength,
                  userData?.id
                );
                toast("Please wait, Uploading thumbnail...", {
                  autoClose: false,
                });

                setUrl(data.url);
                setPublicId(data.publicId);
                setMuxid(data.muxid);
                const ext = path.extname(data.url);
                if (!!customThumbnail.url) {
                  // if custom thumbnail is selected
                  setThumbnail(customThumbnail.url);
                } else {
                  const video = new Video(file);
                  autoGenerateThumbnail(video, file, ext);
                }
              }
            }

            // setVideoLength(null);
            setFile(null);
          } catch (e) {
            // setVideoLength(null);
            setFile(null);
            setTab("FORM");
            toast.dismiss();
            if (
              e.response?.data?.message ===
              notificationMessage.exceedsUploadLimit
            ) {
              return toast.error(notificationMessage.exceedsUploadLimit);
            } else return toast.error(notificationMessage.oopsTryAgain);
          }
        }
      }
    })();
  }, [file, videoLength]);

  const tempFunc = useRef();
  const newFunc = () => {
    dispatch(getVideoAccessOverlays());
    dispatch(getVideoCategories());
    dispatch(getHashtags());
  };
  tempFunc.current = newFunc;
  useEffect(() => {
    tempFunc.current();
  }, []);

  const handleVideoAccessChange = (event) => {
    setKeyVideoAccess(+event.target.value);
  };
  const tempDataFunc = useRef();
  const newDataFunc = () => {
    if (thumbnail) {
      setTab("COMPLETE");
    }
  };
  tempDataFunc.current = newDataFunc;
  useEffect(() => {
    tempDataFunc.current();
  }, [thumbnail]);

  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform =
        window.navigator?.userAgentData?.platform || window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (/Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  }
  const [selectedOption, setSelectedOption] = useState("");
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  // additonal content download

  const handleTab = async () => {
    if (tab === "FORM") {
      setShowAddAdditionalContentModel(false);
      const payPerViewAmount = ppvAmount.value.trim()
        ? (+ppvAmount.value.trim()).toFixed(2)
        : "";
      ppvAmount.setValue(payPerViewAmount);
      if (!title.value.trim()) {
        return toast.error(notificationMessage.videoTitle);
      }
      if (title.length > 100) {
        return toast.error(notificationMessage.limitExceede);
      }
      if (getOS() == "iOS") {
        if (!customThumbnail.url) {
          return toast.error("Please add a cover photo to continue uploading!");
        }
      }
      if (!selectedCategory.length && keyVideoAccess !== 5) {
        return toast.error(notificationMessage.selectCategory);
      }
      if (!description.value.trim()) {
        return toast.error(notificationMessage.videoDescription);
      }
      if (keyVideoAccess === 2) {
        if (!payPerViewAmount) {
          return toast.error(notificationMessage.payPerView);
        }
        if (payPerViewAmount <= 1.49) {
          return toast.error(notificationMessage.minimumPayPerview);
        }
      }

      if (keyVideoAccess === 4 && !selectedSeries) {
        return toast.error(
          "Please select a series where you want to place this video."
        );
      }
      const files = [];
      for (var i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];

        const data = await upload("video", file);
        files.push({
          documentname: file.name,
          path: data.url,
          Contentfilesize: file.size,
        });
      }
      setSelectedFiles(files);
      setTab("UPLOAD");
    }
  };
  const completeVideoUpload = async () => {
    setShowUploadModel(false);

    let obj = null;
    if (keyVideoAccess === 5) {
      obj = {
        title: title.value,
        description: description.value,
        url,
        publicId,
        customThumbnail: customThumbnail.url,
        thumbnail,
        keyVideoAccess: +keyVideoAccess,
        filesize,
        hashTags: hashTags,
        ppvAmount: +ppvAmount.value,
        videoLength,
        rewardDescription,
        selectedFiles,
        isCheckedHasReward,
        rewardAmount,
        muxid,
      };
    } else {
      obj = {
        title: title.value,
        description: description.value,
        url,
        publicId,
        customThumbnail: customThumbnail.url,
        thumbnail,
        categoryId: +category,
        categoryList: selectedCategory,
        keyVideoAccess: +keyVideoAccess,
        filesize,
        hashTags: hashTags,
        ppvAmount: +ppvAmount.value,
        videoLength,
        rewardDescription,
        selectedFiles,
        isCheckedHasReward,
        rewardAmount,
        muxid,
      };
    }

    dispatch(uploadVideoV2(obj));
    setTab("COMPLETE");
  };

  const handleManageSubscription = () => {
    setShowUploadModel(false);
    return setShowManageSubscription(true);
  };

  const history = useHistory();
  const myAccountLink = () => {
    setShowUploadClipModel(false);
    history.push(`/channel/${user.username}?tab=myaccount`);
  };

  const CustomToastWithLink = () => (
    <div onClick={myAccountLink}>
      <p onClick={() => handleManageSubscription()}>
        You've used up all your video storage! Please upgrade to a larger video
        package OR remove content from your channel. Click this message to
        upgrade your subscription.
      </p>
    </div>
  );

  const autoGenerateThumbnail = (video, file, ext) => {
    try {
      video
        .getThumbnails({
          quality: 1,
          scale: 0.8,
        })
        .then(async (thumbnails) => {
          const blobthird = thumbnails[3].blob;

          const newfile = new File(
            [blobthird],
            file.name.replace(ext, ".jpg"),
            { type: "image/jpeg" }
          );
          const { url } = await uploadCover("image", newfile);
          setThumbnail(url);
        });
    } catch (error) {
      throw error;
    }
  };

  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];

    var video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      setVideoLength(Math.round(video.duration));
    };

    video.src = URL.createObjectURL(file);
    setFile(file);
  };

  const onDelete = (i) => {
    const selectedHashtags = hashTags.slice(0);
    selectedHashtags.splice(i, 1);
    sethashTags(selectedHashtags);
  };

  const onAddition = (tag) => {
    const selectedHashtags = [].concat(hashTags, tag);
    sethashTags(selectedHashtags);
  };

  const onValidate = (tag) => {
    if (tag.name.length > 140) {
      toast.error(notificationMessage.hashTagLimit);
      return false;
    }

    if (/[^a-zA-Z0-9]/.test(tag.name)) {
      toast.error(notificationMessage.removeInvalidCharacter);
      return false;
    }

    if (hashTags.length > 0) {
      if (hashTags.length >= 20) {
        toast.error(notificationMessage.addHashtagUpto20);
        return false;
      }
      for (var i = 0; i < hashTags.length; i++) {
        if (
          hashTags[i].name.toString().toLowerCase() ===
          tag.name.toString().toLowerCase()
        ) {
          toast.error(notificationMessage.videoHashtag);
          return false;
        }
      }
    }
    return true;
  };

  const handleCustomThumbnailUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const { publicId, url } = await uploadCover("image", file);
      setCustomThumbnail({ url, publicId });
    }
  };

  const resetHandler = () => {
    setSelectedSeries(null);
    setThumbnail("");
    setVideoSize("");
    title.setValue("");
    description.setValue("");
    ppvAmount.setValue("");
    setKeyVideoAccess(0);
    setTab("FORM");
    setPreviewVideo(null);
    setVideoLength(null);
    setSelectedCategory([]);
    setDefaultCategories([]);
    setThumbnail("");
    setCustomThumbnail({ url: "", publicId: "" });
    setSelectedSeries(null);
    sethashTags([]);
    setDocuments(null);
    setRewardDescription();
    setIsCheckedHasReward();
    setRewardAmount();
    setSelectedFiles([]);
  };

  const getSeriesCategories = async (seriesId) => {
    const categoryResponse = await api.get(
      `videos/getCategoryByVideo/${seriesId}`
    );
    if (categoryResponse.data.success) {
      setSelectedCategory(categoryResponse.data.videoSubCategories);
      setDefaultCategories(categoryResponse.data.videoSubCategories);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleVideoUpload({ target: { files: [file] } });
  };

  return (
    <>
      <UploadClipFormStyled
        className={`${showUploadClipModel && "ShowUploadClipForm"}`}
      >
        <div className="header">
          <div
            onClick={() => {
              resetHandler();
              close();
            }}
            className="close"
          >
            <img src={BackIcon} alt="" />
          </div>

          <div className="title">
            {tab === "FORM" && "Give Video Details"}
            {tab === "UPLOAD" && "Choose Your Video"}
          </div>

          {tab === "FORM" && (
            <div className="button" onClick={handleTab}>
              next
            </div>
          )}

          {tab === "UPLOAD" && (
            <div className="button" onClick={() => setTab("FORM")}>
              Go Back
            </div>
          )}
          {tab === "PREVIEW" && <div className="button">Uploading...</div>}
          {tab === "COMPLETE" && (
            <div className="group">
              <div
                className="button"
                onClick={() => {
                  setTab("UPLOAD");
                  setVideoSize("");
                  setPreviewVideo("");
                  setUrl("");
                  setPublicId("");
                  setThumbnail("");
                }}
              >
                Cancel
              </div>

              <div className="button" onClick={completeVideoUpload}>
                {videoUploadLoading ? "Please Wait..." : "Post Video"}
              </div>
            </div>
          )}
        </div>

        {tab === "UPLOAD" && (
          <ShowUploadIcon>
            <div
              className="content__addFiles"
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={(e) => e.preventDefault()}
              onDragLeave={(e) => e.preventDefault()}
              onDrop={handleDrop}
            >
              <div className="item">
                <label htmlFor="video-upload">
                  <div className="icon-box">
                    <i className="fas fa-upload" />
                  </div>
                  {/* <span>
                    Upload VideoDrag & drop, or Upload Video your video
                  </span> */}
                  <div className="content__addFiles--dragdrop-text">
                    Drag & drop, or{" "}
                    <span className="highlight">Upload Video</span>
                  </div>
                  <h4>Note: Maximum video file size is 10GB</h4>
                </label>

                <input
                  style={{ display: "none" }}
                  id="video-upload"
                  type="file"
                  accept="video/*"
                  onChange={handleVideoUpload}
                />
              </div>
            </div>
          </ShowUploadIcon>
        )}

        {previewVideo
          ? (tab === "PREVIEW" || tab === "COMPLETE") && (
              <div className="videoPreviewBox">
                <Player previewUrl={previewVideo} />
              </div>
            )
          : null}

        {tab === "FORM" && (
          <div className="form">
            <div className="form--fieldGroup">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                id="title"
                placeholder="Video title"
                value={title.value}
                onChange={title.onChange}
              />
            </div>

            {keyVideoAccess !== 5 && (
              <div className="form--fieldGroup">
                <label htmlFor="">Choose category</label>
                <VideoCategories
                  disabled={
                    videoForSeriesUpload?.series?.id || selectedSeries?.id
                      ? true
                      : false
                  }
                  videoCategories={videoCategories}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  defaultCategories={defaultCategories}
                />

                <button
                  onClick={() => {
                    setNewCategoryRequestModel(true);
                  }}
                  className="requestNewCategory"
                >
                  Request New Category
                </button>
              </div>
            )}

            <div className="form--fieldGroup">
              <label htmlFor="">Video Access</label>

              {videoForSeriesUpload.series ? (
                <select value={4} onChange={handleVideoAccessChange}>
                  <option value={4}>Series Video</option>
                </select>
              ) : (
                <select
                  value={keyVideoAccess}
                  onChange={handleVideoAccessChange}
                >
                  {videoAccessOverlays &&
                    videoAccessOverlays.length &&
                    videoAccessOverlays.map((vao, index) => {
                      return (
                        <option value={vao.keyVideoAccess} key={index}>
                          {vao.name}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>

            {videoForSeriesUpload.series && (
              <div
                onClick={() => setSelectedSeries(videoForSeriesUpload.series)}
                className={`seriesItem ${
                  selectedSeries?.id === videoForSeriesUpload.series?.id
                    ? "seriesItemActive"
                    : null
                }`}
              >
                <img
                  onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
                  src={
                    config.aws.CLOUDFRONT_URL +
                    videoForSeriesUpload?.series?.thumbnail
                  }
                  alt={videoForSeriesUpload.series?.title}
                  className="seriesItem__thumbnail"
                />

                <div className="seriesItem__info">
                  <div className="seriesItem__info--title">
                    {videoForSeriesUpload.series?.title}
                  </div>

                  <div className="seriesItem__info--list">
                    <div className="item">
                      <img src={PriceIcon} alt="" className="item__icon" />
                      <span className="item__text">
                        {videoForSeriesUpload.series?.price > 0 ? (
                          <>
                            $
                            {parseInt(
                              videoForSeriesUpload.series?.price
                            ).toFixed(2)}
                          </>
                        ) : (
                          "FREE"
                        )}
                      </span>
                    </div>

                    <div className="dot"></div>

                    <div className="item">
                      <img src={VideoIcon} alt="" className="item__icon" />
                      <span className="item__text">
                        {videoForSeriesUpload.series?.videos?.length} Videos
                      </span>
                    </div>

                    <div className="dot"></div>

                    <div className="item">
                      <img src={TimeIcon} alt="" className="item__icon" />
                      <span className="item__text">
                        <Moment fromNow>
                          {videoForSeriesUpload.series?.createdAt}
                        </Moment>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {keyVideoAccess === 4 && videoForSeriesUpload.showSeriesList && (
              <>
                <div className="form--fieldGroup">
                  <div className="title">Available Series</div>

                  {getSeriesLoading && (
                    <Loader text="Please Wait while we are fetching all of your series" />
                  )}

                  {!getSeriesLoading && getSeriesError && (
                    <Error text="No series found! Please create ONE first" />
                  )}

                  {allSeries && allSeries.length > 0 && (
                    <>
                      {allSeries.map((item, i) => (
                        <div
                          onClick={() => setSelectedSeries(item)}
                          className={`seriesItem ${
                            selectedSeries?.id === item.id
                              ? "seriesItemActive"
                              : null
                          }`}
                        >
                          <img
                            onError={(e) =>
                              addDefaultImage(e, ImageNotfoundPlaceholder)
                            }
                            src={config.aws.CLOUDFRONT_URL + item?.thumbnail}
                            alt={item.title}
                            className="seriesItem__thumbnail"
                          />

                          <div className="seriesItem__info">
                            <div className="seriesItem__info--title">
                              {item.title}
                            </div>

                            <div className="seriesItem__info--list">
                              <div className="item">
                                <img
                                  src={PriceIcon}
                                  alt=""
                                  className="item__icon"
                                />
                                <span className="item__text">
                                  {item.price > 0 ? (
                                    <>${parseInt(item.price).toFixed(2)}</>
                                  ) : (
                                    "FREE"
                                  )}
                                </span>
                              </div>

                              <div className="dot"></div>

                              <div className="item">
                                <img
                                  src={VideoIcon}
                                  alt=""
                                  className="item__icon"
                                />
                                <span className="item__text">
                                  {item.videos.length} Videos
                                </span>
                              </div>

                              <div className="dot"></div>

                              <div className="item">
                                <img
                                  src={TimeIcon}
                                  alt=""
                                  className="item__icon"
                                />
                                <span className="item__text">
                                  <Moment fromNow>{item.createdAt}</Moment>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  <button
                    onClick={() => {
                      close();
                      setShowUploadModel(true);
                      history.push(`/channel/${user.username}?tab=4`);
                      // setShowUploadClipModel(true);
                      setDefaultCategories([]);
                      setSelectedCategory([]);
                      setShowCreateSeriesModel(true);
                    }}
                    className="requestNewCategory"
                  >
                    Create Series
                  </button>
                </div>
              </>
            )}

            {keyVideoAccess && keyVideoAccess === 2 ? (
              <div className="form--fieldGroup">
                <label>
                  Pay Per View Amount
                  <span className="asterisk"> *</span>
                </label>
                <input
                  type="number"
                  placeholder="Enter Amount (Minimum $1.50)"
                  value={ppvAmount.value}
                  onChange={ppvAmount.onChange}
                />
              </div>
            ) : null}

            <div className="form--fieldGroup">
              <label>Video Hashtags</label>
              <ReactTags
                ref={reactTags}
                tags={hashTags}
                suggestions={videoHashtags}
                onDelete={onDelete.bind(this)}
                onAddition={onAddition.bind(this)}
                onValidate={onValidate.bind(this)}
                placeholderText="Enter new hashtag"
                allowNew={true}
              />
            </div>

            <div className="form--fieldGroup">
              <div className="title">Cover</div>

              <label htmlFor="uploadThumbnail" className="uploadThumbnail">
                <img src={AddIcon} alt="Add Icon" className="icon" />

                <p>
                  Upload any custom image for your Video! We'll auto-generate a
                  thumbnail from your video if you don't have one.
                </p>
              </label>

              <input
                style={{ display: "none" }}
                id="uploadThumbnail"
                type="file"
                accept="image/*"
                onChange={handleCustomThumbnailUpload}
              />

              {!!customThumbnail.url && (
                <div className="uploadedCoverPreview">
                  <img
                    src={config.aws.CLOUDFRONT_URL + customThumbnail.url}
                    alt=""
                  />
                </div>
              )}
            </div>

            <div className="form--fieldGroup">
              <label htmlFor="description">Description</label>

              <textarea
                id="description"
                placeholder="Tell viewers about your video"
                value={description.value}
                onChange={description.onChange}
              />
            </div>
            {keyVideoAccess !== 4 && keyVideoAccess !== 5 && (
              <div>
                <div
                  onClick={() => {
                    setShowAddAdditionalContentModel(true);
                    window.scrollTo(0, 0);
                  }}
                  className="aditionalContent"
                >
                  <div className="contentButton">
                    <div>
                      <img
                        src={AddIcon}
                        alt="Add Icon"
                        className="icon"
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                    <div style={{ marginTop: "-4px" }}>
                      Add Additional Content
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </UploadClipFormStyled>

      {showAddAdditionalContentModel && (
        <AddAdditionalContent
          setRewardAmount={setRewardAmount}
          rewardAmount={rewardAmount}
          isCheckedHasReward={isCheckedHasReward}
          setIsCheckedHasReward={setIsCheckedHasReward}
          handleTab={handleTab}
          setRewardDescription={setRewardDescription}
          rewardDescription={rewardDescription}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
      )}
    </>
  );
};

export default UploadClipForm;

const UploadClipFormStyled = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #1c1c1e;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
  opacity: 0;
  transform: translateX(-100%);
  transition: all 1s ease;
  font-family: ${(props) => props.theme.montserrat};

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 5rem;
    border-bottom: 1px solid rgba(112, 112, 112, 0.4);
    margin-bottom: 2rem;
    background-color: #1c1c1e;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 2rem;
        height: auto;
        cursor: pointer;
      }
    }

    .title {
      font-size: 1.2rem;
      font-weight: 400;
      text-transform: capitalize;
    }

    .group {
      display: flex;
      align-items: center;

      .button:not(:last-child) {
        margin-right: 0.5rem;
      }
    }

    .button {
      border: 2px solid #fff;
      padding: 0.2rem 1.5rem;
      border-radius: 10rem;
      font-size: 1rem;
      background-color: transparent;
      color: #fff;
      font-family: ${(props) => props.theme.montserrat};
      font-weight: 400;
      text-transform: capitalize;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }

  .videoPreviewBox {
    width: 50%;
    margin: 0 auto 5rem auto;
  }
  .aditionalContent {
    text-align: center;
    background-color: #3a3a3c;
    font-family: ${(props) => props.theme.montserrat};
    font-weight: 400;
    text-transform: capitalize;
    transition: all 0.2s ease;
    color: #f2f2f7;
    padding: 10px;
    margin-bottom: 3.5%;
    border-radius: 1rem;
  }
  .contentButton {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .form {
    width: 50%;
    margin: 0 auto 5rem auto;

    &--fieldGroup {
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .requestNewCategory {
        cursor: pointer;
        line-height: 1;
        font-family: brother-1816, sans-serif;
        padding: 0.4rem 1rem;
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 1px;
        font-weight: 500;
        color: #fff;
        border: none;
        outline: none;
        margin-top: 1rem;
        background: ${(props) => props.theme.primaryGradient};
        border-radius: 3px;
      }

      .uploadedCoverPreview {
        width: 100%;
        margin-top: 2rem;

        img {
          width: 100%;
          height: auto;
          border-radius: 1rem;
          background-size: cover;
          background-position: center;
          object-fit: cover;
        }
      }

      label {
        padding: 0 0 0.7rem 1rem;
        font-size: 1rem;
        font-weight: 400;
        text-transform: capitalize;
        color: #f2f2f7;
        letter-spacing: 1px;
      }

      input,
      textarea,
      select {
        width: 100%;
        padding: 1rem;
        background-color: #3a3a3c;
        color: #f2f2f7;
        border-radius: 0.5rem;
        border: none;
        outline: none;
        font-size: 1rem;
        font-weight: 400;
        color: #f2f2f7;
        font-family: ${(props) => props.theme.montserrat};
        transition: all 0.25s ease;
        border: 3px solid transparent;

        &::placeholder {
          font-weight: 300;
          color: #f2f2f7;
          letter-spacing: 0.3px;
        }

        &:focus {
          border: 3px solid #f88946;
        }
      }

      textarea {
        resize: none;
        height: 15rem;
      }

      .uploadThumbnail {
        width: 100%;
        min-height: 7rem;
        background-color: #3a3a3c;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        padding: 0 2rem;
        height: auto;

        img {
          height: 2.5rem;
          width: auto;
          margin-top: 2rem;
          margin-bottom: 1rem;
        }

        p {
          font-size: 0.85rem;
          font-weight: 300;
          color: #f2f2f7;
          line-height: 1.4;
          margin-bottom: 2rem;
          text-align: center;
        }
      }

      .title {
        padding: 0 0 0.7rem 1rem;
        font-size: 1rem;
        font-weight: 400;
        text-transform: capitalize;
        color: #f2f2f7;
        letter-spacing: 1px;
      }
    }

    .seriesItemActive {
      background: ${(props) => props.theme.gradient} !important;
      border: 1px solid transparent !important;
    }

    .seriesItem {
      width: 100%;
      height: 5rem;
      background: #202020;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 7px;
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      font-family: ${(props) => props.theme.montserrat}, sans-serif;
      cursor: pointer;

      &__thumbnail {
        width: 8rem;
        height: 100%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        margin-right: 1rem;
      }

      &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;
        width: 100%;

        &--title {
          font-size: 0.85rem;
          line-height: 1.3;
          margin-top: 0.5rem;
        }

        &--list {
          display: flex;
          align-items: center;
          padding-bottom: 0.25rem;

          .item {
            display: flex;
            align-items: center;

            &__icon {
              height: 0.9rem;
              width: auto;
              margin-right: 0.25rem;
            }

            &__text {
              font-family: brother-1816, sans-serif;
              text-transform: uppercase;
              font-size: 0.75rem;
            }
          }

          .dot {
            height: 6px;
            width: 6px;
            background-color: #fff;
            border-radius: 50%;
            margin: 0 0.7rem;
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .header {
      padding: 1rem 2rem;
      margin-bottom: 1.5rem;

      .title {
        font-size: 1rem;
      }

      .button {
        font-size: 0.9rem;
      }
    }

    .form {
      width: 90%;

      &__description {
        .title {
          font-size: 1rem;
        }
      }

      input,
      textarea {
        font-size: 0.9rem;
      }

      textarea {
        height: 12rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .header {
      margin-bottom: 1rem;

      .button {
        font-size: 0.8rem;
      }
    }

    .videoPreviewBox {
      width: 90%;
    }

    .form {
      width: 90%;

      textarea {
        height: 10rem;
      }
    }
  }
  .checkboxCustomStyle {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: -webkit-linear-gradient(#ff4883, #fdb769);
    // border-radius: 50%;
    border: none;
    position: relative;
    top: 3px;
    margin-right: 5px;
    cursor: pointer;
  }

  input[type="checkbox"]:checked + .checkboxCustomStyle::after {
    content: "";
    display: block;
    width: 11px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
  }
  .content {
    width: 80%;
    margin: 0 auto;

    &__addFiles {
      height: 18rem;
      width: 70%;
      background-color: #202020;
      min-height: 22vh;
      border-radius: 0.35rem;
      outline: 2px dashed rgba(255, 255, 255, 0.45);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;
      &--dragdrop {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        &-text {
          width: 100%;
          text-align: center;
          font-family: ingra,sans-serif;
          font-weight: 500;
          font-style: normal;

          .highlight {
            cursor: pointer;
            display: inline-block;
            padding: 0;
            margin: 0 0.35rem;
            font-weight: 500;
            background: linear-gradient(
              to right,
              rgb(249, 154, 45),
              rgb(246, 92, 139),
              rgb(249, 154, 45)
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: brother-1816, sans-serif;
            // text-transform: uppercase;
            position: relative;

            &:after {
              position: absolute;
              content: "";
              left: 0;
              bottom: 0;
              height: 1.5px;
              width: 15%;
              background-image: ${(props) => props.theme.gradient};
              transition: all 0.3s ease;
            }

            &:hover:after {
              width: 100%;
            }
          }
        }
      }  
      }
    }

  }
  @media screen and (max-width: 414px) {
    .content {
      width: 90%;

      &__addFiles {
        height: 10rem;
        &--dragdrop {
          width: 90%;
          &-text {
            font-size: 0.8rem;

            .highlight {
              margin: 0 0.3rem;
            }
          }
        }
       
      }
    }
  }
  @media screen and (max-width: 480px) {
    .content {
      width: 90%;

      &__addFiles {
        height: 12rem;
        width: 21rem;
        &--dragdrop {
          width: 90%;
          &-text {
            font-size: 17px;

            .highlight {
              margin: 0 0.25rem;
            }
          }
        }
       
      }
    }
  }
`;

// const FeaturedCheckbox = styled.div`
//   .checkBoxItem {
//     display: block;
//     position: relative;
//     cursor: pointer;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     padding-left: 2.5rem;
//     margin-bottom: 0.5rem;
//     font-size: 1.1rem;
//     font-weight: 500;
//     transform: translateY(0.6rem);
//   }
//   .checkBoxItem:hover input ~ .checkmark {
//     background-color: #fff;
//     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
//   }
//   .checkBoxItem input:checked ~ .checkmark {
//     background-image: linear-gradient(to bottom, #ff4883, #fdb769);
//   }
//   .checkBoxItem input {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
//     height: 0;
//     width: 0;
//   }
//   .checkBoxItem .checkmark {
//     position: absolute;
//     top: -0.2rem;
//     left: 0;
//     height: 27px;
//     width: 27px;
//     background-color: #fff;
//     border: 1px solid rgba(0, 0, 0, 0.5);
//     border-radius: 3px;
//     outline: none;
//   }
//   .checkBoxItem .checkmark:after {
//     content: "";
//     position: absolute;
//     display: none;
//   }
//   .checkBoxItem input:checked ~ .checkmark:after {
//     display: block;
//   }
//   .checkBoxItem .checkmark:after {
//     left: 9px;
//     top: 2px;
//     width: 8px;
//     height: 15px;
//     border: solid white;
//     border-width: 0 2px 2px 0;
//     -webkit-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     transform: rotate(45deg);
//   }
//   p {
//     font-size: 0.85rem;
//     font-weight: 500;
//     color: #f2f2f7;
//     line-height: 1.4;
//     margin-bottom: 2rem;
//     text-align: left;
//   }
// `;

const ShowUploadIcon = styled.div`
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  .item {
    label {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .icon-box {
    cursor: pointer;
    height: 5rem;
    width: 5rem;
    background-color: #fff;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    i {
      font-size: 2.5rem;
      color: #777;
    }
  }
  span {
    font-size: 1.2rem;
    color: #fff;
    font-weight: 555;
  }
`;
