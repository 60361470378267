import React from "react";
import Moment from "react-moment";
import styled from "styled-components";
import MenuOptions from "./MenuOptions";
import ImageNotfoundPlaceholder from "../../assets/image_notfound_placeholder.svg";
import { addDefaultImage } from "../../utils";
import { useHistory } from "react-router-dom";
import { PURCHASED_SERIES_OPTIONS } from "../../utils/menuOptions";
import { isBadgeOrAvatar } from "../../utils";

const PurchasedSeriesCard = ({ item }) => {
  const history = useHistory();
  const avatar = item?.User?.avatar
    ? item.User.avatar
    : item?.User?.badge
    ? item.User.badge
    : item?.User?.userSettings?.VisitorBadge?.imgPath
    ? item.User.userSettings.VisitorBadge.imgPath
    : null;
  return (
    <PurchasedSeriesCardStyled>
      <div className="thumbnail">
        <img
          onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
          src={item?.seriesDetails?.thumbnail}
          alt={item?.seriesDetails?.title}
          onClick={() => {
            history.push(
              `/series/watch/${item?.seriesDetails?.id}/${item?.seriesVideos[0]?.id}`
            );
          }}
        />

        {item?.seriesVideos.length !== 0 && (
          <div className="numVideos">
            <span>{item?.seriesVideos.length} Videos</span>
          </div>
        )}

        <div className="price">
          {item?.seriesDetails?.price > 0 ? (
            <span>${parseFloat(item?.seriesDetails?.price).toFixed(2)}</span>
          ) : (
            <span>free</span>
          )}
        </div>

        <div className="progressBar">
          <div
            style={{
              width: `${
                item.seriesProgress ? item.seriesProgress?.percentage : "0"
              }%`,
            }}
            className="progressBar__completed"
          />
          <span className="progressBar__completed--text">
            {item.seriesProgress ? item.seriesProgress?.percentage : "0"}%
            completed
          </span>
        </div>
      </div>

      <div className="metaInfo">
        <div className="metaInfo__left">
          <div
            onClick={() => {
              history.push(`/channel/${item?.User?.username}`);
            }}
            className="metaInfo__left--avatar"
          >
            {isBadgeOrAvatar(avatar)}
          </div>

          <div className={`metaInfo__left--seriesMeta `}>
            <div
              onClick={() => {
                history.push(
                  `/series/watch/${item?.seriesDetails?.id}/${item?.seriesVideos[0]?.id}`
                );
              }}
              className="title"
            >
              {item?.seriesDetails?.title}
            </div>

            <div className="seperatorInfo">
              <div
                onClick={() => {
                  history.push(`/channel/${item?.User?.username}`);
                }}
                className="bold"
              >
                {item?.User?.username}
              </div>

              <div className="seperator"></div>

              <div className="light">
                <Moment fromNow>{item?.seriesDetails?.createdAt}</Moment>
              </div>
            </div>
          </div>
        </div>

        <div className="menu">
          <MenuOptions item={item} options={PURCHASED_SERIES_OPTIONS} />
        </div>
      </div>
    </PurchasedSeriesCardStyled>
  );
};

export default PurchasedSeriesCard;

const PurchasedSeriesCardStyled = styled.div`
  font-family: ${(props) => props.theme.montserrat}, sans-serif;
  margin-bottom: 1.5rem;

  .thumbnail {
    width: 100%;
    height: 10rem;
    position: relative;
    margin-bottom: 2rem;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
      cursor: pointer;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .progressBar {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      margin: 0 auto;
      height: 1.5rem;
      margin-bottom: 1.5rem;
      background: rgba(0, 0, 0, 0.09);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background-repeat: no-repeat;

      &__completed {
        height: 100%;
        background: ${(props) => props.theme.gradient};
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        &--text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-transform: uppercase;
          color: #fff;
          font-size: 0.6rem;
          font-weight: 500;
        }
      }
    }

    .price,
    .numVideos {
      font-family: brother-1816, sans-serif;
      position: absolute;
      bottom: 0.5rem;
      font-size: 0.7rem;
      padding: 0.4rem 0.5rem;
      color: #fff;
      z-index: 2;
      background-color: rgba(28, 28, 30, 1);
      border-radius: 0.3rem;
      cursor: pointer;
      text-transform: capitalize;
      line-height: 1;
    }

    .price {
      left: 0.5rem;
    }

    .numVideos {
      right: 0.5rem;
    }
  }

  .metaInfo {
    padding: 0 0.25rem;
    font-family: ${(props) => props.theme.montserrat};
    position: relative;

    .menu {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }

    &__left {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      &--avatar {
        cursor: pointer;
        height: 2.2rem;
        width: 2.2rem;
        margin-right: 0.5rem;
        border-radius: 50%;
        position: relative;

        .imageAvatar {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          object-fit: cover;
        }

        .imageBadge {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .badge {
            height: 38px;
            width: auto;
          }
        }
      }

      &--seriesMeta {
        cursor: pointer;
        width: calc(100% - 2.5rem);
        padding-right: 0.5rem;

        .title {
          font-size: 0.9rem;
          font-weight: 400;
          line-height: 1.3;
          margin-bottom: 0.2rem;
          overflow-wrap: break-word;
        }

        .seperatorInfo {
          font-size: 0.7rem;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .seperator {
            height: 4px;
            width: 4px;
            border-radius: 50%;
            margin: 0 0.35rem;
            background-color: #fff;
          }

          .bold {
            font-weight: 600;
            text-decoration: underline;
            background: -webkit-linear-gradient(#ff4883, #fdb769);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            letter-spacing: 0.02rem;
            cursor: pointer;
            text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.3);
            position: relative;

            &:before {
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              height: 2px;
              background: ${(props) => props.theme.gradient};
              width: 0%;
              transition: all 0.4s ease;
            }

            &:hover:before {
              width: 100%;
            }
          }

          .light {
            color: rgba(174, 174, 178, 1);
            font-weight: 400;
            font-size: 0.6rem;
          }

          .boldV2 {
            color: rgba(174, 174, 178, 1);
            font-weight: 500;
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .thumbnail {
      .numVideos,
      .price {
        font-size: 0.6rem;
      }
    }
  }
`;
