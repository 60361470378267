import React from "react";
import styled from "styled-components";
import Section from "../General/Section";

const History = () => {
  return (
    <HistroySkeletonStyled>
      <Section sectionName="videos" />
    </HistroySkeletonStyled>
  );
};

export default History;

const HistroySkeletonStyled = styled.div`
  margin: 7rem 0 5rem 0;
  width: 100%;
`;
