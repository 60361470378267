import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AdminSidebar from "../AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { IoTrashSharp } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import Pagination from "../Pagination";
import { adminGetBannerSlider, deleteWidgetBannerSlider } from "../../actions";
import config from "../../config";
import EditWidegetBanner from "./EditwidBanner";
import AddwidBanner from "./AddwidBanner";
import ConfirmationModel from "../../components/ConfirmationModel";
import Button from "../../components/Elements/Button";

const WidAdminBanner = ({ close, moment, midDrag }) => {
  const widbannerData = useSelector(
    (state) => state.widgetBannerTable.widBannerSlider
  );
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("title");
  const [sortOrder, setSortOrder] = useState("asc");
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const itemsPerPage = 5;

  useEffect(() => {
    dispatch(adminGetBannerSlider());
  }, [dispatch]);

  //   serching------------------
  const filterData = () => {
    return widbannerData
      ? widbannerData.filter((row) => {
          const lowerSearch = search.toLowerCase();
          return (
            (row.title && row.title.toLowerCase().includes(lowerSearch)) ||
            (row.description &&
              row.description.toString().toLowerCase().includes(lowerSearch)) ||
            (row.order &&
              row.order.toString().toLowerCase().includes(lowerSearch))
          );
        })
      : [];
  };
  //   // Pagination -------------
  const filteredData = filterData();
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleClick = (row) => {
    setIsOpenModel(true);
  };

  //   //   Edit ------------------------
  const handleEdit = (row) => {
    setIsEditing(true);
    setFormData({
      id: row.id,
      title: row.title,
      description: row.description,
      order: row.order,
      userId: row.userId,
      categoryId: row.categoryId,
      bannerLocation: row.bannerLocation,
      bannerImgPathInternal_XL_1920x400: row.bannerImgPathInternal_XL_1920x400,
      bannerImgPathInternal_L_1366x400: row.bannerImgPathInternal_L_1366x400,
      bannerImgPathInternal_L_1280x400: row.bannerImgPathInternal_L_1280x400,
      bannerImgPathInternal_L_1024x400: row.bannerImgPathInternal_L_1024x400,
      bannerImgPathInternal_MD_834x400: row.bannerImgPathInternal_MD_834x400,
      bannerImgPathInternal_MD_768x400: row.bannerImgPathInternal_MD_768x400,
      bannerImgPathInternal_SM_428x250: row.bannerImgPathInternal_SM_428x250,
      bannerImgPathInternal_SM_414x250: row.bannerImgPathInternal_SM_414x250,
      bannerImgPathInternal_SM_375x250: row.bannerImgPathInternal_SM_375x250,
    });
  };
  const handleFormChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };
  //   //   Delete-------------------
  const handleDelete = (row) => {
    setShowDeleteConfirmation(true);
    setFormData({ id: row.id });
  };
  const deleteVideoHandler = async () => {
    try {
      await dispatch(deleteWidgetBannerSlider(formData.id));
      setShowDeleteConfirmation(false);
      await dispatch(adminGetBannerSlider());
    } catch (error) {
      console.error("Error deleting Video Categories:", error);
    }
  };

  // Sorting data ----------------------
  const sortData = () => {
    const sortedData = [...filteredData];
    sortedData.sort((a, b) => {
      const aValue = a[sortColumn] || "";
      const bValue = b[sortColumn] || "";

      if (sortOrder === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });
    return sortedData;
  };
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="tableHeader">SR. No </TableCell>
          <TableCell
            className="tableHeader"
            onClick={() => handleSort("title")}
          >
            Title
            {sortColumn === "title" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell className="tableHeader">description </TableCell>
          <TableCell className="tableHeader">order </TableCell>
          <TableCell
            className="tableHeader"
            onClick={() => handleSort("title")}
          >
            bannerImgPathInternal_XL_1920x400
            {sortColumn === "title" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>

          <TableCell
            className="tableHeader"
            onClick={() => handleSort("title")}
          >
            bannerImgPathInternal_L_1366x400
            {sortColumn === "title" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell className="tableHeader">
            bannerImgPathInternal_L_1280x400
          </TableCell>
          <TableCell className="tableHeader">
            bannerImgPathInternal_L_1024x400
          </TableCell>
          <TableCell className="tableHeader">
            bannerImgPathInternal_MD_834x400
          </TableCell>
          <TableCell className="tableHeader">
            bannerImgPathInternal_MD_768x400
          </TableCell>
          <TableCell className="tableHeader">
            bannerImgPathInternal_SM_428x250
          </TableCell>
          <TableCell className="tableHeader">
            bannerImgPathInternal_SM_414x250
          </TableCell>
          <TableCell className="tableHeader">
            bannerImgPathInternal_SM_375x250
          </TableCell>
          <TableCell className="tableHeader">bannerImgPathBackup </TableCell>
          <TableCell className="tableHeader">bannerLocation </TableCell>
          <TableCell className="tableHeader">categoryId </TableCell>
          <TableCell className="tableHeader">customBannerUrl </TableCell>
          <TableCell className="tableHeader">userId </TableCell>
          <TableCell className="tableHeader">url </TableCell>
          <TableCell className="tableHeader">Action </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      <WidgetBannerDashboard>
        <AdminSidebar />
        <TableContainer component={Paper} className="tableContainer">
          <div>
            <h1 className="headingText">
              Search Type Of Wideget banner Sliders
            </h1>
            <input
              type="text"
              placeholder="Search Wideget banner Sliders"
              className="searchBox"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="addData">
            <Button onClick={() => handleClick()}>Add Data</Button>
          </div>

          <Table className="table">
            {renderTableHeader()}
            <TableBody>
              {sortData()
                .slice(startIndex, endIndex)
                .map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell className="tableCell">
                      {startIndex + index + 1}
                    </TableCell>

                    <TableCell className="tableCell">{row.title}</TableCell>
                    <TableCell className="tableCellWordWrap">
                      {row.description}
                    </TableCell>
                    <TableCell className="tableCellWordWrap">
                      {row.order}
                    </TableCell>

                    <TableCell className="tableCellWordWrap">
                      {row.bannerImgPathInternal_XL_1920x400 && (
                        <img
                          src={
                            row.bannerImgPathInternal_XL_1920x400.includes(
                              "/assets/banners/"
                            )
                              ? row.bannerImgPathInternal_XL_1920x400
                              : row.bannerImgPathInternal_XL_1920x400.includes(
                                  "/"
                                )
                              ? `${config.aws.CLOUDFRONT_URL}/vistorBadge${row.bannerImgPathInternal_XL_1920x400}`
                              : `${config.aws.CLOUDFRONT_URL}/vistorBadge/${row.bannerImgPathInternal_XL_1920x400}`
                          }
                          alt={row.bannerImgPathInternal_XL_1920x400}
                          style={{ maxHeight: "100px" }}
                        />
                      )}
                    </TableCell>

                    <TableCell className="tableCellWordWrap">
                      {row.bannerImgPathInternal_L_1366x400 && (
                        <img
                          src={
                            row.bannerImgPathInternal_L_1366x400.includes(
                              "/assets/banners/"
                            )
                              ? row.bannerImgPathInternal_L_1366x400
                              : row.bannerImgPathInternal_L_1366x400.includes(
                                  "/"
                                )
                              ? `${config.aws.CLOUDFRONT_URL}/vistorBadge${row.bannerImgPathInternal_L_1366x400}`
                              : `${config.aws.CLOUDFRONT_URL}/vistorBadge/${row.bannerImgPathInternal_L_1366x400}`
                          }
                          alt={row.bannerImgPathInternal_L_1366x400}
                          style={{ maxHeight: "100px" }}
                        />
                      )}
                    </TableCell>

                    <TableCell className="tableCellWordWrap">
                      {row.bannerImgPathInternal_L_1280x400 && (
                        <img
                          src={
                            row.bannerImgPathInternal_L_1280x400.includes(
                              "/assets/banners/"
                            )
                              ? row.bannerImgPathInternal_L_1280x400
                              : row.bannerImgPathInternal_L_1280x400.includes(
                                  "/"
                                )
                              ? `${config.aws.CLOUDFRONT_URL}/vistorBadge${row.bannerImgPathInternal_L_1280x400}`
                              : `${config.aws.CLOUDFRONT_URL}/vistorBadge/${row.bannerImgPathInternal_L_1280x400}`
                          }
                          alt={row.bannerImgPathInternal_L_1280x400}
                          style={{ maxHeight: "100px" }}
                        />
                      )}
                    </TableCell>

                    <TableCell className="tableCellWordWrap">
                      {row.bannerImgPathInternal_L_1024x400 && (
                        <img
                          src={
                            row.bannerImgPathInternal_L_1024x400.includes(
                              "/assets/banners/"
                            )
                              ? row.bannerImgPathInternal_L_1024x400
                              : row.bannerImgPathInternal_L_1024x400.includes(
                                  "/"
                                )
                              ? `${config.aws.CLOUDFRONT_URL}/vistorBadge${row.bannerImgPathInternal_L_1024x400}`
                              : `${config.aws.CLOUDFRONT_URL}/vistorBadge/${row.bannerImgPathInternal_L_1024x400}`
                          }
                          alt={row.bannerImgPathInternal_L_1024x400}
                          style={{ maxHeight: "100px" }}
                        />
                      )}
                    </TableCell>

                    <TableCell className="tableCellWordWrap">
                      {row.bannerImgPathInternal_MD_834x400 && (
                        <img
                          src={
                            row.bannerImgPathInternal_MD_834x400.includes(
                              "/assets/banners/"
                            )
                              ? row.bannerImgPathInternal_MD_834x400
                              : row.bannerImgPathInternal_MD_834x400.includes(
                                  "/"
                                )
                              ? `${config.aws.CLOUDFRONT_URL}/vistorBadge${row.bannerImgPathInternal_MD_834x400}`
                              : `${config.aws.CLOUDFRONT_URL}/vistorBadge/${row.bannerImgPathInternal_MD_834x400}`
                          }
                          alt={row.bannerImgPathInternal_MD_834x400}
                          style={{ maxHeight: "100px" }}
                        />
                      )}
                    </TableCell>

                    <TableCell className="tableCellWordWrap">
                      {row.bannerImgPathInternal_MD_768x400 && (
                        <img
                          src={
                            row.bannerImgPathInternal_MD_768x400.includes(
                              "/assets/banners/"
                            )
                              ? row.bannerImgPathInternal_MD_768x400
                              : row.bannerImgPathInternal_MD_768x400.includes(
                                  "/"
                                )
                              ? `${config.aws.CLOUDFRONT_URL}/vistorBadge${row.bannerImgPathInternal_MD_768x400}`
                              : `${config.aws.CLOUDFRONT_URL}/vistorBadge/${row.bannerImgPathInternal_MD_768x400}`
                          }
                          alt={row.bannerImgPathInternal_MD_768x400}
                          style={{ maxHeight: "100px" }}
                        />
                      )}
                    </TableCell>

                    <TableCell className="tableCellWordWrap">
                      {row.bannerImgPathInternal_SM_428x250 && (
                        <img
                          src={
                            row.bannerImgPathInternal_SM_428x250.includes(
                              "/assets/banners/"
                            )
                              ? row.bannerImgPathInternal_SM_428x250
                              : row.bannerImgPathInternal_SM_428x250.includes(
                                  "/"
                                )
                              ? `${config.aws.CLOUDFRONT_URL}/vistorBadge${row.bannerImgPathInternal_SM_428x250}`
                              : `${config.aws.CLOUDFRONT_URL}/vistorBadge/${row.bannerImgPathInternal_SM_428x250}`
                          }
                          alt={row.bannerImgPathInternal_SM_428x250}
                          style={{ maxHeight: "100px" }}
                        />
                      )}
                    </TableCell>

                    <TableCell className="tableCellWordWrap">
                      {row.bannerImgPathInternal_SM_414x250 && (
                        <img
                          src={
                            row.bannerImgPathInternal_SM_414x250.includes(
                              "/assets/banners/"
                            )
                              ? row.bannerImgPathInternal_SM_414x250
                              : row.bannerImgPathInternal_SM_414x250.includes(
                                  "/"
                                )
                              ? `${config.aws.CLOUDFRONT_URL}/vistorBadge${row.bannerImgPathInternal_SM_414x250}`
                              : `${config.aws.CLOUDFRONT_URL}/vistorBadge/${row.bannerImgPathInternal_SM_414x250}`
                          }
                          alt={row.bannerImgPathInternal_SM_414x250}
                          style={{ maxHeight: "100px" }}
                        />
                      )}
                    </TableCell>

                    <TableCell className="tableCellWordWrap">
                      {row.bannerImgPathInternal_SM_375x250 && (
                        <img
                          src={
                            row.bannerImgPathInternal_SM_375x250.includes(
                              "/assets/banners/"
                            )
                              ? row.bannerImgPathInternal_SM_375x250
                              : row.bannerImgPathInternal_SM_375x250.includes(
                                  "/"
                                )
                              ? `${config.aws.CLOUDFRONT_URL}/vistorBadge${row.bannerImgPathInternal_SM_375x250}`
                              : `${config.aws.CLOUDFRONT_URL}/vistorBadge/${row.bannerImgPathInternal_SM_375x250}`
                          }
                          alt={row.bannerImgPathInternal_SM_375x250}
                          style={{ maxHeight: "100px" }}
                        />
                      )}
                    </TableCell>
                    <TableCell className="tableCellWordWrap">
                      {row.bannerImgPathBackup}
                    </TableCell>

                    <TableCell className="tableCellWordWrap">
                      {row.bannerLocation}
                    </TableCell>

                    <TableCell className="tableCellWordWrap">
                      {row.categoryId}
                    </TableCell>
                    <TableCell className="tableCellWordWrap">
                      {row.customBannerUrl}
                    </TableCell>

                    <TableCell className="tableCellWordWrap">
                      {row.userId}
                    </TableCell>
                    <TableCell className="tableCellWordWrap">
                      {row.url}
                    </TableCell>

                    <TableCell className="tableCell">
                      <FiEdit
                        className="editButton"
                        size={28}
                        onClick={() => handleEdit(row)}
                      />
                      <IoTrashSharp
                        className="deleteButton"
                        size={28}
                        onClick={() => handleDelete(row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div style={{ marginTop: "7px", marginBottom: "18%" }}>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={widbannerData ? widbannerData.length : 0}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </TableContainer>
      </WidgetBannerDashboard>
      {isEditing && (
        <EditWidegetBanner
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          formData={formData}
          onChange={handleFormChange}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationModel
          closeHandler={() => setShowDeleteConfirmation(false)}
          title="Are you sure you want to delete this Wideget Banner Table ? You can't undo this action."
          deleteHandler={deleteVideoHandler}
        />
      )}
      {isOpenModel && (
        <AddwidBanner
          isOpen={isOpenModel}
          onClose={() => setIsOpenModel(false)}
        />
      )}
    </>
  );
};

export default WidAdminBanner;

const WidgetBannerDashboard = styled.div`
  display: flex;
  .addData {
    margin-bottom: 10px;
  }
  .tableContainer {
    max-width: 100%;
    height: 85vh;
    background: #212123;
    padding-left: 2%;
    padding-right: 2%;
  }
  .tableContainer::-webkit-scrollbar {
    width: 20px;
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: rgb(83 81 81 / 87%); 
    border: 2px solid
    border-radius: 6px; 
  }

  .tableContainer::-webkit-scrollbar-track {
    background-color: #212123; 
  }

  .table {
    background: #212123;
  }
  .tableHeader {
    background: #212123;
    color: white;
    border: 1px solid;
    font-size: 13px;
  }
  .tableCell {
    color: white;
    border: 1px solid;
    font-size: 13px;
  }
  .tableCellWordWrap {
    color: white;
    border: 1px solid;
    font-size: 13px;
    word-break: break-all;
  }
  .editButton {
    color: #4f92f7;
    marginleft: 10px;
    transition: transform 0.2s;
  }
  .editButton:hover {
    transform: scale(1.3);
  }

  .deleteButton {
    color: #f54e4e;
    marginleft: 10px;
    transition: transform 0.2s;
  }
  .deleteButton:hover {
    transform: scale(1.3);
  }

  .headingText {
    font-size: 18px;
    margin-top: 10px;
    color: white;
  }
  .searchBox {
    background: #2c2c2e !important;
    color: white;
    margin-bottom: 2%;
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: #cdc5c5;
    }
  }

  /* Responsive styling for the search box */
  @media (max-width: 768px) {
    .SearchBox {
      width: 100%;
    }
  }
`;
