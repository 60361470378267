import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../../components/Elements/Button";
import Input from "../Elements/Input";
import api from "../../services/api";
import LoaderIcon from "../icons/loader.svg";
import { AuthContext } from "../../context/AuthContext";
import { validateEmail } from "../../utils";

const FirstStep = ({ switchStep }) => {
  const { email, setEmail, setValidationError, setWelcomeUsername, authState } =
    useContext(AuthContext);
  const [checkLoading, setCheckLoading] = useState(null);

  useEffect(() => {
    document.getElementById("user-name").focus();
  }, [authState]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!email) {
      return setValidationError({
        type: "error",
        message: "Email / Username is required field",
      });
    }

    const isValid = validateEmail(email);

    if (isValid) {
      await emailExistHandler();
    } else {
      await usernameExistHandler();
    }
  };

  const usernameExistHandler = async () => {
    try {
      setCheckLoading(true);
      const res = await api.get(`auth/isUserNameExist?userName=${email}`);

      if (res.data) {
        // then username is exists so send them to login page
        setWelcomeUsername(res.data?.data?.username);
        setCheckLoading(false);
        setValidationError(null);
        switchStep("LOGIN");
      }
    } catch (error) {
      if (error) {
        // then email not exists so send them to signup page
        setCheckLoading(false);
        setValidationError({
          type: "error",
          message:
            "We can't find any account related with this username. Please provide valid email address to get started.",
        });
        setEmail("");
      }
    }
  };

  const emailExistHandler = async () => {
    try {
      setCheckLoading(true);
      const res = await api.get(`auth/isEmailExist?email=${email}`);

      if (res.data) {
        // then email is exists so send them to login page
        setWelcomeUsername(res.data?.data?.username);
        setCheckLoading(false);
        setValidationError(null);
        switchStep("LOGIN");
      }
    } catch (error) {
      if (error) {
        // then email not exists so send them to signup page
        setCheckLoading(false);
        setValidationError(null);
        switchStep("SIGNUP");
      }
    }
  };

  return (
    <FirstStepStyled>
      <div className="paddingBox">
        <div className="title">Sign in or create an account</div>

        <div className="field">
          <Input
            label="Email / Username"
            name="email"
            id="user-name"
            placeholder="Enter your Email or Username"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            emailExistHandler={submitHandler}
          />

          <div
            onClick={() => {
              switchStep("FORGOT_PASSWORD");
            }}
            className="alternativeOption"
          >
            Forgot Password ?
          </div>
        </div>

        <Button
          mode="primary"
          width="fluid"
          variant="round"
          size="medium"
          onClick={submitHandler}
          className="submit"
          iconLeft={true}
        >
          {checkLoading ? (
            <>
              <img src={LoaderIcon} alt="" className="imgIcon" />
              Checking...
            </>
          ) : (
            "Continue with Email / Username"
          )}
        </Button>
      </div>
    </FirstStepStyled>
  );
};

export default FirstStep;

const FirstStepStyled = styled.div`
  .title {
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }

  .submit {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .alternativeOption {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 500;
    text-decoration: underline;
    color: rgba(255, 255, 255, 0.6);
    font-family: brother-1816, sans-serif;
    margin-top: 0.35rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
`;
