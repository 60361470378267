import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getConnectionStatus } from "../../../actions";
import { GlobalContext } from "../../../context/GlobalContext";
import { notificationMessage } from "../../../utils/toastNotifications";
import { connect } from "react-redux";
import { checkAddToStram, SubscribeNewChannel } from "../../../actions";
import Button from "../../../styles/Button";
import EditBtn from "../../../styles/EditBtn";
import CoopReferralModal from "../CoopReferralModal";
import ConnectWithWarrior from "../Models/ConnectWithWarriorModel";
import PortalTabs from "../PortalTabs";
import { useLocation } from "react-router-dom";
import config from "../../../config";
import LockIcon from "../../icons/lock.svg";

import {
  AvtarContainer,
  BannerContainer,
  ConnectBtn,
  CoopBtn,
  AddToStreamBtn,
  MainContainer,
  ShareBtn,
} from "./styles";
import Helmet from "react-helmet";

function MyPortal({ SubscribeNewChannel, checkAddToStram }) {
  const profile = useSelector((state) => state.profile);
  const user = useSelector((state) => state.user);
  const {
    setShowEditProfile,
    setShowChatList,
    setChatTab,
    setShowAuthenticationModel,
    setShowManageSubscription,
  } = useContext(GlobalContext);
  const dispatch = useDispatch();
  const routePath = useLocation();

  const [open, setOpen] = useState(false);
  const [showConnectModel, setShowConnectModel] = useState(false);
  const [channelName, setChannelName] = useState("");
  const {
    loading: connectionStatusLoading,
    error: connectionStatusError,
    text: connectionStatusText,
  } = useSelector((state) => state.connectionStatus);
  const userData = useSelector((state) => state.user);
  const isSubscribe = useSelector((state) => state.channelSubscribed);

  useEffect(() => {
    // it means user is logged in
    if (user && user.id) {
      if (profile?.id) {
        const queryParams = window.location.pathname;
        const splitURL = queryParams.split("/");
        setChannelName(splitURL[2]);
        checkAddToStram(splitURL[2], userData.id);
        // dispatch(getProfile(userData.id)) // this line causes my portal page to load non stop
        dispatch(
          getConnectionStatus({
            warriorId: profile.id,
            userId: user.id,
          })
        );
      }
    }
  }, [profile, showConnectModel, user, channelName]);

  const handleCoopRefer = () => {
    setOpen(true);
  };
  const { username } = profile;
  const getUserRole = () => {
    switch (profile.userrole) {
      case 0:
        return "Freeloader";
      case 1:
        return "CO-OP";
      case 2:
        return "Warrior";
      default:
        return "Freeloader";
    }
  };
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  });
  const handleSubscribe = () => {
    SubscribeNewChannel(channelName, userData.id);
    checkAddToStram(channelName, userData.id);
  };
  const handleUnsubscribe = () => {
    SubscribeNewChannel(channelName, userData.id);
    checkAddToStram(channelName, userData.id);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const isBadgeOrAvatar = () => {
    const image = !profile.avatar ? profile.badge : profile.avatar;

    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <div className="imageBadge">
          <img className="badge" src={image} alt="" />
        </div>
      );
    } else {
      return (
        <img
          className="imageAvatar"
          src={config.aws.CLOUDFRONT_URL + image}
          alt=""
        />
      );
    }
  };
  let location;
  if (profile && profile?.userrole === 2) {
    location = profile?.userSettings?.outOfThisWorld
      ? "Out of this world"
      : `${
          profile?.userSettings?.city !== null && profile?.userSettings?.city
        }, ${
          profile?.userSettings?.state !== null && profile?.userSettings?.state
        }`;
  }

  return (
    <>
      <MainContainer>
        <BannerContainer cover={config.aws.CLOUDFRONT_URL + profile?.cover}>
          {!profile?.isFetching && (
            <>
              {profile?.isMe && (
                <div className="editBtn">
                  {user.userrole === 2 && (
                    <EditBtn onClick={() => setShowEditProfile(true)}>
                      Edit
                    </EditBtn>
                  )}
                </div>
              )}
            </>
          )}
          {userData.id ? (
            <>
              {userData.username != profile.username ? (
                <>
                  <div className="add-to-stream">
                    {isSubscribe.isFetching && isSubscribe.isSubscribe && (
                      <AddToStreamBtn onClick={() => handleSubscribe()}>
                        Add to Streams
                      </AddToStreamBtn>
                    )}

                    {!isSubscribe.isFetching && isSubscribe.isSubscribe && (
                      <AddToStreamBtn onClick={() => handleUnsubscribe()}>
                        Remove Stream
                      </AddToStreamBtn>
                    )}

                    {!isSubscribe.isFetching && !isSubscribe.isSubscribe && (
                      <AddToStreamBtn onClick={() => handleSubscribe()}>
                        Add to Streams
                      </AddToStreamBtn>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {!profile?.isFetching && (
            <>
              {profile?.isMe ? (
                <>
                  <ShareBtn onClick={handleCoopRefer}>
                    <p>Share</p>
                  </ShareBtn>

                  <CoopBtn onClick={handleCoopRefer}>
                    <p>COOP-REFER</p>
                  </CoopBtn>
                </>
              ) : (
                <>
                  {profile?.id !== user?.id &&
                    (profile?.userrole === 1 || profile?.userrole === 2) && (
                      <ConnectBtn
                        onClick={() => {
                          if (user?.userrole === 0) {
                            toast.error(
                              "Chat connections with other Freeloaders is exclusive to Co-op and Warrior users. Please upgrade your account! It's only $5/year... thats a cup of coffee!",
                              {
                                onClick: () => {
                                  setShowManageSubscription(true);
                                },
                                autoClose: false,
                                closeOnClick: true,
                              }
                            );
                            return;
                          }

                          if (!user?.id) {
                            return (
                              document.getElementById("user-name").focus(),
                              setShowAuthenticationModel(true)
                            );
                          }

                          if (connectionStatusText === "Connect Pending...") {
                            return toast.error(
                              `Your connection request is pending. We will send notification to the ${profile?.username} about your request. `
                            );
                          }

                          if (connectionStatusText === "message") {
                            setShowChatList(true);
                            setChatTab(0);
                            return;
                          }

                          if (
                            connectionStatusText === "Connect Request Received"
                          ) {
                            setShowChatList(true);
                            toast.error(
                              "This user already sent you connection request."
                            );
                            setChatTab(1);
                            return;
                          }

                          setShowConnectModel(!showConnectModel);
                        }}
                      >
                        {user?.userrole === 0 && <img src={LockIcon} alt="" />}
                        <p>
                          {connectionStatusText
                            ? connectionStatusText
                            : "Connect"}
                        </p>
                      </ConnectBtn>
                    )}
                </>
              )}
            </>
          )}
        </BannerContainer>

        <AvtarContainer>
          {profile?.id && (
            <>
              <div className="avatar">{isBadgeOrAvatar()}</div>
              <p>{username}</p>
              <Helmet>
                <meta property="og:type" content="website" />
                <meta property="og:title" Content={username} />
                <meta name="twitter:card" content="summary" />
                <meta property="twitter:title" Content={username} />
              </Helmet>
              {location ? (
                <span className="secondary" style={{ fontSize: "13px" }}>
                  {location}
                </span>
              ) : null}
              <Button className="userrole">{getUserRole()}</Button>
            </>
          )}
        </AvtarContainer>
        <Helmet>
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={`${config.frontend.baseUrl}${profile.badge}`}
            // content={config.aws.CLOUDFRONT_URL + profile?.cover}
          />
          <meta name="twitter:card" content="summary" />
          <meta
            property="twitter:image"
            content={`${config.frontend.baseUrl}${profile.badge}`}
            // content={config.aws.CLOUDFRONT_URL + profile?.cover}
          />
        </Helmet>
        <PortalTabs />
      </MainContainer>
      {open && (
        <div className="modal-container">
          <CoopReferralModal close={closeModal} />
        </div>
      )}

      {showConnectModel && (
        <div className="modal-container">
          <ConnectWithWarrior
            close={() => setShowConnectModel(false)}
            username={profile?.username}
            id={profile?.id}
          />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  SubscribeNewChannel,
  checkAddToStram,
})(MyPortal);
