import {
  GET_ALL_HASHTAGS_REQUEST,
  GET_ALL_HASHTAGS_SUCCESS,
  GET_ALL_HASHTAGS_FAIL,
} from "../actions/hashtag/type";

const hashtags = (
  state = {
    hashtags: [],
    hasMore: true,
    isFetching: true,
  },
  action
) => {
  switch (action.type) {
    case GET_ALL_HASHTAGS_REQUEST:
      return {
        isFetching: true,
        hashtags:
          state.hashtags && state.hashtags.length > 0
            ? [...state.hashtags]
            : [],
      };
    case GET_ALL_HASHTAGS_SUCCESS:
      const allHashtags = [...state.hashtags, ...action.payload.hashtags];
      const uniqueHashtags = [
        ...new Map(allHashtags.map((item) => [item["id"], item])).values(),
      ];

      return {
        isFetching: false,
        hashtags: uniqueHashtags,
        hasMore: action.payload.hasMore,
      };
    case GET_ALL_HASHTAGS_FAIL:
      return {
        isFetching: false,
        error: action.payload,
        hashtags:
          state.hashtags && state.hashtags.length > 0
            ? [...state.hashtags]
            : [],
        hasMore: false,
      };

    default:
      return state;
  }
};

export default hashtags;
