import {
  SET_CURRENT_SUBSCRIPTION,
  SUBSCRIPTION_PLANS_FAILED,
  SUBSCRIPTION_PLANS_REQUEST,
  SUBSCRIPTION_PLANS_SUCCESS,
  UPDATE_SUBSCRIPTION_CLEANUP,
  UPDATE_SUBSCRIPTION_PLANS_FAILED,
  UPDATE_SUBSCRIPTION_PLANS_REQUEST,
  UPDATE_SUBSCRIPTION_PLANS_SUCCESS,
} from "../actions/types";

const initialState = {
  isFetching: false,
  plans: [],
  currentSubscription: {},
  error: "",
  isUpdating: false,
  planUpdated: false,
};

const subscriptionPlans = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_PLANS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isUpdating: false,
        plans: action.payload.plans,
        currentSubscription: action.payload.currentSubscription,
        coOpSubscription: action.payload.coOpSubscription,
        error: "",
      };
    case SUBSCRIPTION_PLANS_FAILED:
      return {
        ...state,
        isFetching: false,
        isUpdating: false,
        error: action.payload,
      };
    case UPDATE_SUBSCRIPTION_PLANS_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        planUpdated: true,
        isUpdating: false,
        plans: action.payload.plans,
        currentSubscription: action.payload.currentSubscription,
        error: "",
      };
    case UPDATE_SUBSCRIPTION_CLEANUP:
      return {
        ...state,
        planUpdated: false,
      };

    case UPDATE_SUBSCRIPTION_PLANS_FAILED:
      return {
        ...state,
        isUpdating: false,
        isFetching: false,
        error: action.payload,
      };
    case SET_CURRENT_SUBSCRIPTION:
      return {
        ...state,
        currentSubscription: action.payload,
      };
    default:
      return state;
  }
};

export default subscriptionPlans;
