import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Hashtags = ({ hashtags, type }) => {
  const history = useHistory();

  const visitHashtagHandler = (hashtagId) => {
    history.push(`/hashtag/${hashtagId}?activeTab=${type ? type : "CLIPS"}`);
  };

  return (
    <HashtagsStyled>
      <div className="hashtags">
        {hashtags.map((item, i) => (
          <div
            onClick={() => visitHashtagHandler(item.name)}
            key={item.id}
            className="hashtags__item"
          >
            #{item.name}
          </div>
        ))}
      </div>
    </HashtagsStyled>
  );
};

export default Hashtags;

const HashtagsStyled = styled.div`
  .hashtags {
    display: flex;
    align-items: center;
    row-gap: 0.25rem;
    column-gap: 0.5rem;

    &__item {
      font-size: 0.6rem;
      padding: 0.3rem 0.65rem;
      border-radius: 10rem;
      background-color: #202020;
      border: 1px solid rgba(255, 255, 255, 0.09);
      font-family: brother-1816, sans-serif;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      text-transform: uppercase;
      line-height: 1;
      transition: all 0.5s ease;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: #fff;
        background: ${(props) => props.theme.primaryGradient};
      }
    }
  }
`;
