import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import Comments from "../components/Comments";
import VideoCardMoreFrom from "../components/VideoCardMoreFrom";
import Button from "../components/Elements/Button";
import Player from "../components/Player";
import Skeleton from "../skeletons/WatchVideo";
import VideoGridTrending from "../styles/VideoGridTrending";
import Moment from "react-moment";
import VideoReportModel from "../components/VideoReportModel";
import ContactBanner from "../components/Footer/ContactBanner";
import Footer from "../components/Footer/Footer";
import FeaturedVideoCard from "../components/FeaturedVideoCard";
import MenuOptions from "../components/Cards/MenuOptions";
import Upvote from "../components/Elements/Upvote";
import debounce from "lodash.debounce";
import api from "../services/api";
import PlaylistIcon from "../components/icons/playlist.svg";
import ReportIcon from "../components/icons/flag.svg";
import config from "../config";
import Error from "../components/Elements/Error";
import Karma from "../components/Models/Karma";
import styled, { css, keyframes } from "styled-components";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useModal } from "../components/Modal";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  getRecommendations,
  clearVideo,
  getVideo,
  unsubscribeChannel,
  subscribeChannel,
  clearNotFound,
  moreFromUser,
  getVideosByCategory,
  getUserById,
  getFlagTypesAction,
  getSaveTATVUnlockInfo,
} from "../actions";
import { SUBSCRIBE_FROM_VIDEO, UNSUBSCRIBE_FROM_VIDEO } from "../actions/types";
import { useSelector, useDispatch } from "react-redux";
import { ScrollToTop } from "../utils/index";
import { GlobalContext } from "../context/GlobalContext";
import { VIDEO_OTHER } from "../utils/menuOptions";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PayPerView from "../components/Models/PayPerView";
import Hashtags from "../components/Shared/Hashtags";
import RewardIcon from "../components/icons/reward.svg";
import PDFIcon from "../components/icons/pdf.svg";
import LockIcon from "../components/icons/lock.svg";
import Helmet from "react-helmet";
import DynamicMetaTags from "./DynamicMetaTags";

const stripePromise = loadStripe(config.stripe.STRIPE_PUBLIC_KEY);

const backgroundPan = keyframes`
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
`;

const Wrapper = styled.div`
  /* video description */
  .videoDescription {
    margin-top: 1rem;
    background-color: #202020;
    padding: 1.2rem;
    border-radius: 0.35rem;
    font-family: ${(props) => props.theme.montserrat}, sans-serif;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &__rewards {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.06);

      &--title {
        font-family: brother-1816, sans-serif;
        font-size: 0.95rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        color: rgba(255, 255, 255, 0.9);
        line-height: 1;
        margin-bottom: 1rem;
      }

      &--list {
        display: flex;
        flex-direction: column;
        max-width: 480%;
        width: 45%;

        &-item {
          background-color: #181818;
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 4rem;
          color: #fff;
          border-radius: 0.25rem;
          border: 1px solid rgba(255, 255, 255, 0.07);
          padding: 0.5rem 1rem 0.5rem 0;
          cursor: pointer;
          position: relative;

          &:not(:last-child) {
            margin-bottom: 0.5rem;
          }

          .lock {
            position: absolute;
            top: 0;
            right: 0;
            height: 1.75rem;
            width: 1.75rem;
            border-top-right-radius: 0.25rem;
            background: ${(props) => props.theme.primaryGradient};
            display: flex;
            align-items: center;
            justify-content: center;

            &__icon {
              height: 0.8rem;
              width: auto;
            }
          }

          &-left {
            width: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid rgba(255, 255, 255, 0.2);

            .icon {
              height: 1.35rem;
              width: auto;
            }
          }

          &-right {
            width: calc(100% - 4rem);
            padding-right: 1.75rem;

            .name {
              text-transform: capitalize;
              font-size: 0.82rem;
              font-weight: 500;
              line-height: 1;
              margin-bottom: 0.25rem;
            }

            .url {
              color: rgba(255, 255, 255, 0.5);
              font-size: 0.65rem;
              font-weight: 500;
            }
          }
        }
      }
    }

    &__description {
      &--title {
        font-family: brother-1816, sans-serif;
        font-size: 0.95rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        color: rgba(255, 255, 255, 0.9);
        // line-height: 1;
        // margin-bottom: 1rem;
      }

      &--content {
        font-size: 0.8rem;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        line-height: 2.85;
      }
    }
  }

  .hyper-link-class {
    color: #4b4bbb;
    word-break: break-all;
  }

  display: grid;
  grid-template-columns: 70% 1fr;
  grid-gap: 2rem;
  padding: 1.3rem 0;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 3rem;

  .video-container .video-info {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .overlay-container {
    position: relative;
  }

  .video-overlays {
    position: absolute;
    width: -webkit-fill-available;
    z-index: 1;
  }
  .overlay-button {
    z-index: 23;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .channel-info-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .channel-info-meta {
    margin-left: 0.5rem;
  }

  .iconBox {
    height: 2.2rem;
    width: 2.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
      height: 1.2rem;
      width: 1.2rem;
      cursor: pointer;
    }
  }

  .hashtags {
    margin-top: 0.5rem;
  }

  .video-info {
    position: relative;

    .menu {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }

  .video-info-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    font-family: ${(props) => props.theme.montserrat}, sans-serif;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        line-height: 1;
        text-transform: capitalize;
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.85rem;
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }

      &__rewardBtn {
        margin-top: 0.5rem;
        padding: 0.35rem 0.5rem;
        display: flex;
        align-items: center;
        border-radius: 4px;
        color: white;
        line-height: 1;
        position: relative;
        transition: all 0.3s ease;
        z-index: 1;
        cursor: pointer;

        &:after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 3px;
          width: 50%;
          background-image: ${(props) => props.theme.gradientV2};
          transition: all 0.3s ease;
          border-radius: 2px;
        }

        &:hover:after {
          height: 100%;
          z-index: -1;
          background-image: ${(props) => props.theme.gradientV2};
          width: 100%;
        }

        &--icon {
          margin-right: 0.5rem;

          img {
            height: 0.75rem;
            width: auto;
          }
        }

        &--text {
          text-transform: uppercase;
          font-family: brother-1816, sans-serif;
          font-size: 0.68rem;
          font-weight: bold;
        }
      }
    }

    .btnPill {
      padding: 0 1rem;
      display: flex;
      align-items: center;
      font-family: ${(props) => props.theme.montserrat}, sans-serif;
      border-radius: 8rem;
      border: 1px solid rgba(255, 255, 255, 0.08);
      transition: all 0.3s ease;
      margin-bottom: 0 !important;
      user-select: none;
      cursor: pointer;

      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.5);
      }

      img {
        padding-right: 0.25rem;
        border-radius: 1px solid rgba(255, 255, 255, 0.08);
        height: 0.8rem;
        width: auto;
      }

      .title {
        text-transform: uppercase;
        font-size: 0.64rem;
        line-height: 1;
        font-weight: 400;
      }
    }
  }

  .videoStatsMobileV2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    &__item {
      text-transform: capitalize;

      &:not(:last-child) {
        margin-right: 0.8rem;
      }
    }

    .btnPill {
      padding: 0 1rem;
      display: flex;
      align-items: center;
      font-family: ${(props) => props.theme.montserrat}, sans-serif;
      border-radius: 8rem;
      border: 1px solid rgba(255, 255, 255, 0.08);
      transition: all 0.3s ease;
      margin-bottom: 0.5rem;

      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.5);
      }

      img {
        padding-right: 0.25rem;
        border-radius: 1px solid rgba(255, 255, 255, 0.08);
        height: 0.8rem;
        width: auto;
      }

      .title {
        text-transform: uppercase;
        font-size: 0.64rem;
        line-height: 1;
      }
    }

    @media screen and (max-width: 375px) {
      &__item {
        font-size: 0.6rem !important;
      }

      .btnPill {
        padding: 0 0.7rem;

        img {
          height: 0.7rem;
          margin-right: 0.15rem;
        }

        .title {
          font-size: 0.55rem;
        }
      }
    }
  }

  .videoStatsMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;

    &__item {
      display: flex;
      align-items: center;
      flex-direction: column;

      &--rewardBtn {
        margin-top: 0.5rem;
        padding: 0.35rem 0.5rem;
        display: flex;
        align-items: center;
        border-radius: 4px;
        color: white;
        line-height: 1;
        position: relative;
        transition: all 0.3s ease;
        z-index: 1;
        cursor: pointer;

        &:after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 3px;
          width: 50%;
          background-color: #6617cb;
          background-image: ${(props) =>
            props.theme.primaryGradient} !important;
          transition: all 0.3s ease;
          border-radius: 2px;
        }

        &:hover:after {
          height: 100%;
          z-index: -1;
          background-color: #6617cb;
          background-image: ${(props) =>
            props.theme.primaryGradient} !important;
          width: 100%;
        }

        &-icon {
          margin-right: 0.5rem;

          img {
            height: 0.75rem;
            width: auto;
          }
        }

        &-text {
          text-transform: uppercase;
          font-family: brother-1816, sans-serif;
          font-size: 0.68rem;
          font-weight: bold;
        }
      }
    }
  }

  .channel-info-flex button {
    font-size: 0.9rem;
  }

  .channel-info {
    .avatar2 {
      cursor: pointer;
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      position: relative;
      margin-right: 0.5rem;
      transition: all 0.2s ease;
      outline: 1px solid transparent;

      &:hover {
        outline: 1px solid rgba(255, 255, 255, 0.5);
      }

      .imageAvatar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        border-radius: 50%;
      }

      .imageBadge {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .badge {
          height: 38px;
          width: auto;
        }
      }
    }
  }

  .related-videos {
    .sidebar-title {
      margin-bottom: 1rem;

      .gradient {
        font-size: 1.2rem;
        margin-left: 0.5rem;
        text-transform: capitalize;
        background: -webkit-${(props) => props.theme.primaryGradient};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 500;
        letter-spacing: 0.02rem;
        text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.3);
        cursor: pointer;
      }
    }

    .banner-section {
      width: 100%;
      padding: 1rem;
      background-color: #222;
      border-radius: 0.5rem;
      margin-bottom: 1.5rem;

      .banner {
        width: 100%;
        height: 5rem;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        border-radius: 0.5rem;
      }

      .avatar-box {
        margin-top: -1.5rem;
        margin-left: 0.5rem;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        .avatar {
          cursor: pointer;
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
          position: relative;
          margin-right: 0.5rem;
          transition: all 0.2s ease;
          outline: 1px solid transparent;

          &:hover {
            outline: 1px solid rgba(255, 255, 255, 0.5);
          }

          .imageAvatar {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
            border-radius: 50%;
          }

          .imageBadge {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .badge {
              height: 38px;
              width: auto;
            }
          }
        }

        h1 {
          font-size: 1rem;
          cursor: pointer;
          transition: all 0.2s ease;
          /* margin-top: .7rem; */

          &:hover {
            background: -webkit-${(props) => props.theme.primaryGradient};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.3);
          }
        }
      }

      p {
        font-size: 0.8rem;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .png-img2 {
    border-radius: 50%;
  }

  .related-videos img {
    height: 200px;
  }

  .related-videos div {
    margin-bottom: 1rem;
  }

  .css-1wnsr1i {
    background-color: #000 !important;
  }
  svg {
    fill: ${(props) => props.theme.darkGrey};
  }

  ${(props) =>
    props.isVideoLocked === false &&
    css`
      .vjs-big-play-button {
        z-index: 22;
      }
      .vjs-control-bar {
        z-index: 22;
      }
    `}

  ${(props) =>
    props.filledLike &&
    css`
      .like svg {
        fill: ${(props) => props.theme.blue};
      }
    `}

  ${(props) =>
    props.filledDislike &&
    css`
      .dislike svg {
        fill: ${(props) => props.theme.blue};
      }
    `}



    /* media queries */
    @media screen and (max-width: 480px) {
    .videoDescription {
      padding: 1rem;

      &__rewards {
        &--title {
          font-size: 0.85rem;
        }

        &--list {
          max-width: 100%;
          width: 100%;
        }
      }

      &__description {
        &--title {
          font-size: 0.85rem;
        }

        &--content {
          font-size: 0.725rem;
          line-height: 1.8;
        }
      }
    }
  }

  @media screen and (max-width: 930px) {
    grid-template-columns: 90%;
    .related-videos {
      display: none;
    }
    .overlay-button {
      top: 60%;
    }
  }

  @media screen and (max-width: 930px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 425px) {
    .overlay-button {
      top: 80%;
    }
  }
`;

const VideoSuggestionBox = styled.div`
  padding: 1rem 2rem;
  border-top: 0.3px solid rgba(238, 238, 238, 0.3);
  margin-bottom: 5rem;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const WatchVideo = ({
  isFetching,
  clearVideo,
  getVideo,
  getRecommendations,
  subscribeChannel,
  unsubscribeChannel,
  notfound,
  clearNotFound,
  getFlagTypesAction,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const routePath = useLocation();
  const { videoId } = useParams();
  const { toggle } = useModal();
  const [setShowReplayResume] = useState(0);
  const [isKarmaModalOpen, setKarmaModalOpen] = useState(false);
  const [dataVideo, setDataVideo] = useState();
  const moreVideos = useSelector((state) => state.moreVideos);
  const categoryVideos = useSelector((state) => state.categoryVideos);
  const video = useSelector((state) => state.video);
  const userById = useSelector((state) => state.userById);
  const user = useSelector((state) => state.user);
  const [upvote, setUpvote] = useState(null);
  const [totalUpvotes, setTotalUpvotes] = useState(null);
  const [readyToCallUpvoteApi, setReadyToCallUpvoteApi] = useState(false);
  const [showFinalAnimation, setShowFinalAnimation] = useState(false);
  const [isMomentLiked, setIsMomentLiked] = useState(false);

  const {
    showVideoReportModel,
    setShowVideoReportModel,
    setPauseVideoForReport,
    setShowPlaylistModel,
    setPlaylistModelType,
    setSelectedVideoId,
    setShowAuthenticationModel,
    setShowKarmaModel,
    showKarmaModel,
    showPPVKarmaModel,
  } = useContext(GlobalContext);

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  useEffect(() => {
    clearVideo();
  }, [clearVideo, videoId]);

  useEffect(async () => {
    if (readyToCallUpvoteApi) {
      await upvoteApiCallHandler(videoId, upvote);
      setReadyToCallUpvoteApi(false);
    }

    if (upvote > 0 && !isMomentLiked) {
      setIsMomentLiked(true);
      setTotalUpvotes(totalUpvotes + 1);
    }

    if (upvote === 0 && isMomentLiked) {
      setIsMomentLiked(false);
      setTotalUpvotes(totalUpvotes - 1);
    }
  }, [isMomentLiked, readyToCallUpvoteApi, totalUpvotes, upvote, videoId]);

  const upvoteApiCallHandler = useCallback(
    debounce(async (videoId, upvote) => {
      const obj = {
        upvote,
      };

      await api.post(`videos/${videoId}/upvote-video`, obj);
    }, 1000),
    []
  );

  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  });
  const tempFunction = useRef();
  const imgFunction = () => {
    if (user.username) {
      getFlagTypesAction();
    }
  };
  tempFunction.current = imgFunction;

  useEffect(() => {
    tempFunction.current();
  }, []);

  const tempFun = useRef();
  const imgFun = async () => {
    if (user?.id) {
      if (video?.keyVideoAccess === 2) {
        // PPV Video, keyVideoAccess == 2
        setDataVideo(video);
      } else {
        // getSaveTATVUnlockInfo gives video info for the type TipAfter2 only
        // TA2 has keyVideoAccess == 3;
        const vidData = dispatch(getSaveTATVUnlockInfo(videoId, user.id));
        setDataVideo(vidData);
      }
    }
    if (video?.categoryId) {
      dispatch(getVideosByCategory(video?.categoryId));
    }
    if (video && video.userId) {
      if (user.username) {
        dispatch(moreFromUser(video.userId));
        dispatch(getUserById(video.userId));
      } else {
        dispatch(moreFromUser(video.userId));
      }
    }

    if (video && video.id) {
      setUpvote(video?.myUpvote?.upvote || null);
      setTotalUpvotes(video?.upvotes || 0);

      if (video?.myUpvote?.upvote) {
        setIsMomentLiked(true);
      }
    }

    return () => {
      toast.dismiss();
    };
  };
  tempFun.current = imgFun;
  useEffect(() => {
    tempFun.current();
  }, [video]);

  useEffect(() => {
    if (user?.id) {
      getVideo(videoId, user.id);
    } else {
      getVideo(videoId);
    }
    getRecommendations();

    return () => {
      clearNotFound();
      clearVideo();
    };
  }, [videoId, clearVideo, getRecommendations, getVideo, clearNotFound, user]);

  if (notfound) {
    return (
      <Error
        text="No video found with that ID."
        actionButton={() => history.push("/home")}
        actionButtonText="Go Home"
      />
    );
  }

  if (isFetching) {
    return <Skeleton />;
  }

  const filteredMoreVideos = () => {
    if (moreVideos && moreVideos.length > 0 && video && video.id) {
      const filtered = moreVideos.filter((item) => item.id !== video.id);
      return filtered;
    }
  };

  const filteredCategoryVideos = () => {
    if (
      categoryVideos &&
      categoryVideos.videos &&
      categoryVideos.videos.length > 0 &&
      video &&
      video.id
    ) {
      const filtered = categoryVideos.videos.filter(
        (item) => item.id !== video.id
      );
      return filtered;
    }
  };

  const filteredMore = filteredMoreVideos();
  const filteredCategory = filteredCategoryVideos();

  const videoBadge = video.userSettings.VisitorBadge.imgPath;

  const upvoteHandler = () => {
    if (!user?.id) {
      return setShowAuthenticationModel(true);
    }

    setShowFinalAnimation(true);

    if (upvote < 3) {
      setUpvote(upvote + 1);
    } else {
      setUpvote(0);
    }

    setReadyToCallUpvoteApi(true);
  };

  const loginHandler = () => {
    if (JSON.stringify(user) === "{}") {
      setShowAuthenticationModel(true);
    } else {
      setShowVideoReportModel(true);
      setPauseVideoForReport(true);
    }
  };

  const loginSaveHandler = () => {
    if (JSON.stringify(user) === "{}") {
      setShowAuthenticationModel(true);
    } else {
      setSelectedVideoId(video.id);
      setPlaylistModelType("save");
      setShowPlaylistModel(true);
    }
  };
  const isBadgeOrAvatar = () => {
    const image = video?.User?.avatar
      ? video.User.avatar
      : video?.User?.badge
      ? video.User.badge
      : video?.userSettings?.VisitorBadge?.imgPath
      ? video.userSettings.VisitorBadge.imgPath
      : null;

    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <div className="imageBadge">
          <img className="badge" src={image} alt="" />
        </div>
      );
    } else {
      return (
        <img
          className="imageAvatar"
          src={config.aws.CLOUDFRONT_URL + image}
          alt=""
        />
      );
    }
  };

  const URL_REGEX = /(https?:\/\/[^\s]+)/g;
  function CheckHyperLinks(description) {
    const desc = description.replace(/\n/g, " <br/> ");
    const words = desc.split(" ");
    const results = [];
    words.forEach((index) => {
      if (index.match(URL_REGEX)) {
        results.push(
          `<a class="hyper-link-class" href=${index} target="_blank">${index}</a>`
        );
      } else {
        results.push(`${index}`);
      }
    });
    const mainstring = results.toString();
    return mainstring.replace(/\,/g, " ");
  }

  const rewardsButtonClickHandler = () => {
    toast.success(
      "This video contains downloadable content as a reward for those who Give Karma! Check the video description for details about the rewards."
    );

    return;
  };

  const downloadFile = (filepath, filename) => {
    const file = config.aws.CLOUDFRONT_URL + filepath;

    const aTag = document.createElement("a");
    aTag.href = file;
    aTag.setAttribute("download", filename);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  return (
    <>
      <DynamicMetaTags
        title={`${video.title}`}
        description={`${video.description}`}
        imageUrl={`${config.aws.CLOUDFRONT_URL + video.thumbnail}`}
      />
      <Elements stripe={stripePromise}>
        <ScrollToTop />

        <Wrapper
          filledLike={video && video.isLiked}
          filledDislike={video && video.isDisliked}
          isVideoLocked={video && video.isVideoLocked}
        >
          <div className="video-container">
            <div className="video">
              {!isFetching && (
                <div
                  className="overlay-container"
                  onClick={() => {
                    video.keyVideoAccess !== 0 &&
                      setShowAuthenticationModel(true);
                  }}
                >
                  <Player dataVideo={dataVideo} />
                </div>
              )}
            </div>

            {video?.hashTags && video.hashTags.length > 0 && (
              <div className="hashtags">
                <Hashtags hashtags={video.hashTags} />
              </div>
            )}

            <div className="video-info">
              <div className="menu">
                <MenuOptions item={video} options={VIDEO_OTHER} />
              </div>
              <h3>{video.title}</h3>

              {!isMobile && (
                <div className="video-info-stats">
                  <div className="item">
                    <Upvote
                      upvote={upvote}
                      upvoteHandler={upvoteHandler}
                      total={totalUpvotes}
                      showFinalAnimation={showFinalAnimation}
                    />
                  </div>

                  <div className="item">
                    <span>
                      <Moment fromNow>{video.createdAt}</Moment>
                    </span>
                  </div>

                  <div
                    onClick={() => {
                      loginHandler();
                    }}
                    className="item"
                  >
                    <div className="btnPill">
                      <div className="iconBox">
                        <img
                          className="iconBox__icon"
                          src={ReportIcon}
                          alt=""
                        />
                      </div>
                      <div className="title">Report</div>
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      loginSaveHandler();
                    }}
                    className="item"
                  >
                    <div className="btnPill">
                      <div className="iconBox">
                        <img
                          src={PlaylistIcon}
                          className="iconBox__icon"
                          alt=""
                        />
                      </div>
                      <div className="title">Add to Playlist</div>
                    </div>
                  </div>

                  <div className="item">
                    <Button
                      onClick={() => {
                        if (!user?.id) {
                          setShowAuthenticationModel(true);
                          return;
                        }
                        setShowKarmaModel(true);
                      }}
                    >
                      Give Karma
                    </Button>

                    {video?.HasRewards && (
                      <div
                        onClick={() => {
                          rewardsButtonClickHandler();
                        }}
                        className="item__rewardBtn"
                      >
                        <div className="item__rewardBtn--icon">
                          <img src={RewardIcon} alt="" />
                        </div>
                        <div className="item__rewardBtn--text">Rewards</div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {isMobile && (
                <>
                  <div className="videoStatsMobile">
                    <div className="videoStatsMobile__item">
                      <Upvote
                        upvote={upvote}
                        upvoteHandler={upvoteHandler}
                        total={totalUpvotes}
                        showFinalAnimation={showFinalAnimation}
                      />
                    </div>

                    <div className="videoStatsMobile__item">
                      <Button
                        onClick={() => {
                          if (!user?.id) {
                            setShowAuthenticationModel(true);
                            return;
                          }

                          setShowKarmaModel(true);
                        }}
                      >
                        Give Karma
                      </Button>

                      {video?.HasRewards && (
                        <div
                          onClick={() => {
                            rewardsButtonClickHandler();
                          }}
                          className="videoStatsMobile__item--rewardBtn"
                        >
                          <div className="videoStatsMobile__item--rewardBtn-icon">
                            <img src={RewardIcon} alt="" />
                          </div>
                          <div className="videoStatsMobile__item--rewardBtn-text">
                            Rewards
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="videoStatsMobileV2">
                    <div className="videoStatsMobileV2__item">
                      <span>
                        <Moment fromNow>{video.createdAt}</Moment>
                      </span>
                    </div>

                    <div
                      onClick={() => {
                        loginHandler();
                      }}
                      className="videoStatsMobileV2__item"
                    >
                      <div className="btnPill">
                        <div className="iconBox">
                          <img
                            className="iconBox__icon"
                            src={ReportIcon}
                            alt=""
                          />
                        </div>
                        <div className="title">Report</div>
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        loginSaveHandler();
                      }}
                      className="videoStatsMobileV2__item"
                    >
                      <div className="btnPill">
                        <div className="iconBox">
                          <img
                            src={PlaylistIcon}
                            className="iconBox__icon"
                            alt=""
                          />
                        </div>
                        <div className="title">Add to Playlist</div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="channel-info-description">
              <div className="channel-info-flex">
                <div className="channel-info flex-row">
                  <div className="avatar2">{isBadgeOrAvatar()}</div>

                  <div className="channel-info-meta">
                    <h4>
                      <Link to={`/channel/${video.User?.username}`}>
                        {video.User?.username}
                      </Link>
                    </h4>
                  </div>
                </div>
                {!video.isVideoMine && !video.isSubscribed && (
                  <Button
                    onClick={() =>
                      subscribeChannel({
                        channel: {
                          id: video.User.id,
                          avatar: video.User.avatar,
                          username: video.User.username,
                          visitorBadge: {
                            imgPath: videoBadge,
                          },
                        },
                        type: SUBSCRIBE_FROM_VIDEO,
                      })
                    }
                  >
                    Add to Streams
                  </Button>
                )}
                {/* testiong */}
                {!video.isVideoMine && video.isSubscribed && (
                  <Button
                    grey
                    onClick={() =>
                      unsubscribeChannel({
                        type: UNSUBSCRIBE_FROM_VIDEO,
                        channelId: video.userId,
                      })
                    }
                  >
                    Remove Stream
                  </Button>
                )}
              </div>

              <div className="videoDescription">
                <div className="videoDescription__description">
                  <div className="videoDescription__description--title">
                    Video Description
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: CheckHyperLinks(video.description),
                    }}
                    className="videoDescription__description--content"
                  ></div>
                </div>
              </div>

              {video?.AdditionalContent && (
                <div className="videoDescription">
                  {video.rewardDescription && (
                    <div className="">
                      <div className="videoDescription__description--title">
                        Reward Details
                      </div>

                      {video?.rewardDescription && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: CheckHyperLinks(video.rewardDescription),
                          }}
                          className="videoDescription__description--content"
                        ></div>
                      )}
                    </div>
                  )}

                  {video?.AdditionalContent && (
                    <div className="videoDescription__rewards">
                      <div className="videoDescription__rewards--title">
                        Reward Downloads
                      </div>
                      {video.AdditionalContent &&
                        video.AdditionalContent.map((videoContent) => (
                          <div className="videoDescription__rewards--list">
                            <div
                              className="videoDescription__rewards--list-item"
                              onClick={() =>
                                video.User.id === user.id ||
                                video.isVideoAdditionalContent.filter(
                                  (isAdditionalContent) =>
                                    isAdditionalContent.userId === user.id
                                ).length != 0
                                  ? downloadFile(
                                      videoContent.filePath,
                                      videoContent.fileName
                                    )
                                  : ""
                              }
                            >
                              <div className="videoDescription__rewards--list-item-left">
                                <img src={PDFIcon} alt="" className="icon" />
                              </div>
                              <div className="videoDescription__rewards--list-item-right">
                                <div className="name">
                                  {videoContent.fileName}
                                </div>
                                <div className="url">
                                  {videoContent.filePath}
                                </div>
                              </div>
                              {video.User.id === user.id ||
                              video.isVideoAdditionalContent.filter(
                                (isAdditionalContent) =>
                                  isAdditionalContent.userId === user.id
                              ).length != 0 ? (
                                ""
                              ) : (
                                <div
                                  onClick={() => rewardsButtonClickHandler()}
                                  className="lock"
                                >
                                  <img
                                    src={LockIcon}
                                    alt=""
                                    className="lock__icon"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            <Comments videoId={videoId} />
          </div>

          <div className="related-videos">
            {/* when user is logedout */}

            <>
              {userById.id ? (
                <div className="banner-section">
                  <img
                    className="banner"
                    alt="banner"
                    src={config.aws.CLOUDFRONT_URL + userById.cover}
                  />
                  <div className="avatar-box">
                    <div className="avatar">{isBadgeOrAvatar()}</div>

                    <Link to={`/channel/${video.User.id}`}>
                      <h1>{video.User.username}</h1>
                    </Link>
                  </div>

                  {video.User.userrole === 2 ? (
                    <p>{video.User.tagline}</p>
                  ) : null}
                </div>
              ) : (
                <div className="banner-section">
                  <img
                    className="banner"
                    alt="banner"
                    src={config.aws.CLOUDFRONT_URL + video.User.cover}
                  />
                  <div className="avatar-box">
                    <div className="avatar">{isBadgeOrAvatar()}</div>

                    <Link to={`/channel/${video.User.username}`}>
                      <h1>{video.User.username}</h1>
                    </Link>
                  </div>

                  {video.User.userrole === 2 ? (
                    <p>{video.User.tagline}</p>
                  ) : null}
                </div>
              )}

              <h3 className="sidebar-title">
                More From
                <Link
                  to={`/channel/${video.User.username}`}
                  className="gradient"
                >
                  {video.User.username}
                </Link>
              </h3>

              {filteredMore &&
                filteredMore.length > 0 &&
                filteredMore
                  .splice(0, 3)
                  .map((vid) => (
                    <VideoCardMoreFrom
                      key={vid.id}
                      hideavatar={true}
                      video={vid}
                    />
                  ))}
            </>
          </div>
        </Wrapper>

        {filteredCategory && filteredCategory.length > 0 && (
          <VideoSuggestionBox>
            <h3>You may also like</h3>
            <VideoGridTrending>
              {!categoryVideos.isFetching &&
                filteredCategory.slice(0, 4).map((video) => (
                  <div key={video.id}>
                    <FeaturedVideoCard
                      featuredVideo={video}
                      parent="WatchVideo"
                    />
                  </div>
                ))}
            </VideoGridTrending>
          </VideoSuggestionBox>
        )}

        <ContactBanner />
        <Footer />

        {showVideoReportModel && (
          <VideoReportModel
            closeHandler={() => setShowVideoReportModel(false)}
            open={showVideoReportModel}
            video={video}
            uploaderUser={userById}
            user={user}
          />
        )}

        {showKarmaModel && (
          <Karma
            creator={{
              ...video.User,
              badge: video?.userSettings?.VisitorBadge?.imgPath,
            }}
            video={video}
          />
        )}
        {showPPVKarmaModel && (
          <PayPerView
            creator={{
              ...video.User,
              badge: video?.userSettings?.VisitorBadge?.imgPath,
            }}
            video={video}
          />
        )}
      </Elements>
    </>
  );
};

const mapStateToProps = ({ notfound, video, recommendation, user }) => ({
  isFetching: video.isFetching || recommendation.isFetching,
  video,
  next: recommendation.videos,
  notfound,
  user,
});

export default connect(mapStateToProps, {
  clearVideo,
  getVideo,
  getRecommendations,
  subscribeChannel,
  unsubscribeChannel,
  clearNotFound,
  getFlagTypesAction,
})(WatchVideo);

const HyperLink = styled.div`
  .hyper-link {
    color: #4b4bbb;
  }
`;

{
  /* <Helmet
encodeSpecialCharacters={true}
titleTemplate="%s | Freeloader.com"
defer={false}
onChangeClientState={(newState, addedTags, removedTags) =>
  console.log(newState, addedTags, removedTags)
}
>
<title>{`${video.User.username}: ${video.title}`}</title>
<meta name="description" content={`${video.description}`} />

<meta property="og:type" content="video.other" />
<meta
  property="og:url"
  content={`${config.frontend.baseUrl}/watch/${video.id}`}
/>
<meta
  property="og:title"
  content={`${video.User.username}: ${video.title}`}
/>
<meta property="og:description" content={`${video.description}`} />
<meta
  property="og:image"
  content={`${config.aws.CLOUDFRONT_URL + video.thumbnail}`}
/>

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:creator" content={video.User.username} />
<meta
  name="twitter:title"
  content={`${video.User.username}: ${video.title}`}
/>
<meta name="twitter:description" content={`${video.description}`} />
<meta
  name="twitter:image"
  content={`${config.aws.CLOUDFRONT_URL + video.thumbnail}`}
/>

<meta property="og:site_name" content="MySite.com" />
<meta property="og:type" content="website" />
<meta
  property="og:title"
  content={`${video.User.username}: ${video.title}`}
/>
<meta property="og:description" content={`${video.description}`} />
<meta
  property="og:image"
  content={`${config.aws.CLOUDFRONT_URL + video.thumbnail}`}
/>
<meta
  property="og:url"
  content={`${config.frontend.baseUrl}/watch/${video.id}`}
/>
<meta property="og:image:width" content="1200" />
<meta property="og:image:height" content="630" />
</Helmet> */
}
