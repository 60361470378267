import React from "react";
import styled, { css } from "styled-components";
import EnterIcon from "../../icons/enter.svg";
import config from "../../../config";
import { useSelector } from "react-redux";

const Navbar = ({ shrinkNav }) => {
  const { data: siteBranding } = useSelector((state) => state.branding);

  return (
    <NavbarStyled shrinkNav={shrinkNav}>
      <div className="left">
        <div className="logo">
          <div className="logo__left">
            <img
              src={config.aws.CLOUDFRONT_URL + siteBranding?.logo_light}
              alt=""
            />

            <h1>{siteBranding?.site_name}</h1>
          </div>
        </div>
      </div>

      <div className="right">
        <button className="button">
          <img src={EnterIcon} alt="" />
          Join Now
        </button>
      </div>
    </NavbarStyled>
  );
};

export default Navbar;

const NavbarStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 5rem;
  z-index: 100;
  background-color: transparent;
  padding: 1.5rem 3rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .logo {
    display: flex;
    align-items: center;

    &__left {
      margin-right: 1rem;
      display: flex;
      align-items: center;
      gap: 0.75rem;

      img {
        height: 4rem;
        width: auto;
      }

      h1 {
        font-family: "Montserrat", sans-serif;
        font-size: 1.75rem;
        text-transform: lowercase;
        color: white;
        font-weight: 400;
        letter-spacing: -1px;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    &__right {
      img {
        height: 4rem;
        width: auto;
      }
    }
  }

  .button {
    border-radius: 2rem;
    transform: scale(1);
    transition: transform 1.8s cubic-bezier(0.19, 1, 0.22, 1);
    font-family: brother-1816, sans-serif;
    font-size: 0.85rem;
    text-transform: uppercase;
    font-weight: 400;
    background-color: transparent;
    height: 2.5rem;
    padding: 0 2rem;
    color: #fff;
    border: 1px solid #fff;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;

    img {
      height: 0.9rem;
      width: auto;
      margin-right: 0.8rem;
    }

    &::before {
      content: "";
      display: inline-block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all 0.4s;
      background-color: transparent;
      border-radius: 2rem;
    }

    &:hover::before {
      opacity: 0;
      transform: scaleX(1.5) scaleY(1.7);
      background: #fff;
    }
  }

  ${(props) =>
    props.shrinkNav &&
    props.shrinkNav === true &&
    css`
      background: rgba(0, 0, 0, 0.7);
      padding: 1rem 3rem;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    `}

  @media screen and (max-width: 480px) {
    padding: 1rem;
    width: 100vw;

    .logo {
      &__left {
        margin-right: 0.5rem;

        img {
          height: 2.8rem;
        }
      }

      &__right {
        img {
          height: 2.8rem;
        }
      }
    }

    .button {
      font-size: 0.72rem;
      height: 2rem;
      padding: 0 1.2rem;

      img {
        height: 0.7rem;
        margin-right: 0.4rem;
      }
    }
  }

  @media screen and (max-width: 414px) {
    padding: 0.7rem;

    .logo {
      &__left {
        margin-right: 0.25rem;

        img {
          height: 2rem;
        }
      }

      &__right {
        img {
          height: 2rem;
        }
      }
    }

    .button {
      font-size: 0.65rem;
      height: 1.7rem;
      padding: 0 1rem;

      img {
        height: 0.5rem;
      }
    }
  }
`;
