import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Moment from "react-moment";
import Button from "../styles/Button";
import ContactBanner from "../components/Footer/ContactBanner";
import Footer from "../components/Footer/Footer";
import Skeleton from "../skeletons/SeriesWatch";
import VideoReportModel from "../components/VideoReportModel";
import ReactPlayer from "react-player";
import api from "../services/api";
import TimeIcon from "../components/icons/time.svg";
import { Row, Col } from "react-grid-system";
import { LikeIcon, DislikeIcon } from "../components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../components/Modal";
import {
  getSingleSeries,
  subscribeChannel,
  unsubscribeChannel,
  likeVideo,
  cancelLike,
  dislikeVideo,
  cancelDislike,
  getUserById,
  getFlagTypesAction,
  updateSeriesProgress,
  createSeriesProgress,
  getMuxSeriesUrls,
} from "../actions";
import {
  SUBSCRIBE_FROM_VIDEO,
  UNSUBSCRIBE_FROM_VIDEO,
  GET_SERIES_PROGRESS_RESET,
} from "../actions/types";
import { useParams, Link, useHistory } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import "video.js/dist/video-js.css";
import { toast } from "react-toastify";
import { notificationMessage } from "../utils/toastNotifications";
import { addDefaultImage } from "../utils";
import ImageNotfoundPlaceholder from "../assets/image_notfound_placeholder.svg";
import CompletedIcon from "../components/icons/completed.svg";
import config from "../config";

const CourseWatch = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const seriesId = params.seriesId;
  const videoId = params.videoId;
  const {
    info: seriesInfo,
    videos: seriesVideos,
    loading: getSeriesVideosLoading,
    progressUpdatedMessage,
  } = useSelector((state) => state.singleSeries);
  const userById = useSelector((state) => state.userById);
  const user = useSelector((state) => state.user);

  const [playingVideo, setPlayingVideo] = useState(null);
  const [toggleLike, setToggleLike] = useState(false);
  const [toggleDislike, setToggleDislike] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState("");
  const [progressVideos, setProgressVideos] = useState(null);
  const [loadingProgressVideos, setLoadingProgressVideos] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const { showVideoReportModel, setShowVideoReportModel } =
    useContext(GlobalContext);

  useEffect(() => {
    dispatch(getSingleSeries(seriesId));
  }, [dispatch, seriesId]);

  useEffect(() => {
    if (progressUpdatedMessage) {
      fetchProgressVideosHandler(seriesInfo?.seriesProgress);

      dispatch({
        type: GET_SERIES_PROGRESS_RESET,
      });
    }
  }, [progressUpdatedMessage]);

  useEffect(() => {
    if (seriesVideos && seriesVideos.length > 0) {
      const videoToPlay = seriesVideos.find(
        (item) => item?.singleDetailedVideo?.id === videoId
      );
      const indexToPlay = seriesVideos.findIndex(
        (item) => item?.singleDetailedVideo?.id === videoId
      );
      setPlayingVideo(videoToPlay?.singleDetailedVideo);
      setCurrentVideoIndex(indexToPlay);
    }
  }, [seriesVideos, videoId]);

  useEffect(() => {
    if (playingVideo) {
      setToggleDislike(playingVideo?.isDisliked);
      setToggleLike(playingVideo?.isLiked);

      if (!playingVideo.isVideoMine && playingVideo.isSubscribed) {
        setIsSubscribed("SUBSCRIBED");
      } else if (!playingVideo.isVideoMine && !playingVideo.isSubscribed) {
        setIsSubscribed("UNSUBSCRIBED");
      } else if (playingVideo.isVideoMine) {
        setIsSubscribed("ISMINE");
      }
    }
  }, [playingVideo]);

  useEffect(() => {
    if (playingVideo && playingVideo.userId) {
      dispatch(getUserById(playingVideo.userId));
    }
  }, [dispatch, playingVideo]);

  useEffect(async () => {
    dispatch(getFlagTypesAction());

    if (seriesInfo?.seriesProgress) {
      await fetchProgressVideosHandler(seriesInfo?.seriesProgress);
    } else {
      // series progess is missing create one
      dispatch(createSeriesProgress(seriesId));
    }
  }, [seriesId, seriesInfo]);

  const videoBadge = playingVideo?.userSettings.VisitorBadge.imgPath;

  const handleLike = () => {
    if (playingVideo.isLiked) {
      dispatch(cancelLike(playingVideo.id));
    } else {
      dispatch(likeVideo(playingVideo));

      if (playingVideo.isDisliked) {
        dispatch(cancelDislike(playingVideo.id));
      }
    }
  };

  const handleDislike = () => {
    if (playingVideo.isDisliked) {
      dispatch(cancelDislike(playingVideo.id));
    } else {
      dispatch(dislikeVideo(playingVideo.id));

      if (playingVideo.isLiked) {
        dispatch(cancelLike(playingVideo.id));
      }
    }
  };

  const decodeStreamingUrl = (encodedUrl) => {
    return atob(encodedUrl);
  };
  useEffect(() => {
    if (playingVideo && playingVideo.muxid) {
      getMuxSeriesUrls(playingVideo.muxid)
        .then((playbackUrls) => {
          const encodedHLSUrl = playbackUrls.hls;
          const decodedHLSUrl = decodeStreamingUrl(encodedHLSUrl);

          setVideoUrl(decodedHLSUrl || playbackUrls.dash || playbackUrls.mp4);
        })
        .catch((error) => {
          console.error("Error fetching Mux URLs:", error.message);
          setVideoUrl(config.aws.CLOUDFRONT_URL + playingVideo.url);
        });
    } else if (playingVideo) {
      setVideoUrl(config.aws.CLOUDFRONT_URL + playingVideo.url);
    }
  }, [playingVideo]);
  const isVideoPurchased = (videoId) => {
    if (progressVideos) {
      return progressVideos.includes(videoId);
    }
    return false;
  };

  const videoEndHandler = () => {
    const isLastVideo =
      seriesVideos[seriesVideos.length - 1].singleDetailedVideo.id ===
      playingVideo.id;

    if (!isLastVideo) {
      const indexOfCurrentVideo = seriesVideos.findIndex(
        (item) => item.singleDetailedVideo.id === playingVideo.id
      );
      const nextVideo = seriesVideos[indexOfCurrentVideo + 1];

      if (isVideoPurchased(nextVideo.singleDetailedVideo.id)) {
        setPlayingVideo(nextVideo.singleDetailedVideo);
      } else {
        console.log("Next video is not purchased. Handle accordingly.");
      }
    }

    if (isLastVideo) {
      toast.success(notificationMessage.seriesCompleted);
    }

    dispatch(updateSeriesProgress(seriesId, playingVideo.id));
  };

  const toHHMMSS = (videoLength) => {
    var sec_num = parseInt(videoLength, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    const result = [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");

    return (
      result +
      `${hours && !minutes ? " hour" : minutes && !hours ? " min" : " sec"}`
    );
  };
  const isBadgeOrAvatar = () => {
    if (seriesVideos && seriesVideos.length > 0) {
      const videoToPlay = seriesVideos.find(
        (item) => item?.singleDetailedVideo?.id === videoId
      );
      const badge =
        videoToPlay.singleDetailedVideo.userSettings.VisitorBadge.imgPath;

      if (seriesInfo?.userInfo.avatar === null) {
        return (
          <div className="">
            <img className="uploadedImg" src={badge} alt="" />
          </div>
        );
      }
    }

    const image = seriesInfo?.userInfo.avatar;
    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <div className="">
          <img className="uploadedImg" src={image} alt="" />
        </div>
      );
    } else {
      return (
        <img
          className="uploadedImg"
          src={config.aws.CLOUDFRONT_URL + image}
          alt=""
        />
      );
    }
  };

  const fetchProgressVideosHandler = async (seriesProgressId) => {
    try {
      setLoadingProgressVideos(true);
      const obj = {
        seriesProgressId,
      };
      const res = await api.post(`series/seriesProgress/watchedVideos`, obj);
      // console.log(res, "watchedVideos");

      if (res.data.data && res.data.data.length > 0) {
        const modified = res.data.data.map((item) => {
          return item.videoId;
        });
        setProgressVideos([...modified]);
      }
      setLoadingProgressVideos(false);
    } catch (e) {
      setLoadingProgressVideos(false);
    }
  };

  if (getSeriesVideosLoading || (!seriesInfo && !seriesInfo?.id)) {
    return <Skeleton />;
  }

  if (!seriesInfo?.isPurchased && currentVideoIndex > 0) {
    return (
      <NotPurchased>
        <h1>You haven't Purchased This Series.</h1>
        <Button
          onClick={() => history.push(`/series/details/${seriesId}`)}
          className="purchaseBtn"
        >
          Purchase Series
        </Button>
      </NotPurchased>
    );
  }

  if (
    !getSeriesVideosLoading &&
    playingVideo &&
    seriesVideos &&
    seriesVideos.length > 0 &&
    seriesInfo
  ) {
    return (
      <>
        <CourseWatchWrapper>
          <Row className="course">
            <Col className="colItem" md={12} lg={8}>
              <div className="course__videoWatch">
                <ReactPlayer
                  playing={true}
                  // url={config.aws.CLOUDFRONT_URL + playingVideo.url}
                  url={
                    videoUrl.includes("mux.com")
                      ? videoUrl
                      : config.aws.CLOUDFRONT_URL + playingVideo.url
                  }
                  width="100%"
                  height="100%"
                  controls={true}
                  className="VideoPlayer"
                  onEnded={videoEndHandler}
                />

                <div className="course__videoWatch--videoInfo">
                  <h3 className="course__videoWatch--videoInfo-title">
                    {playingVideo.title}
                  </h3>

                  <div className="course__videoWatch--videoInfo-stats">
                    <span className="date">
                      <Moment fromNow>{playingVideo.createdAt}</Moment>
                    </span>

                    <div className="videoReportIcon">
                      <img
                        onClick={() => {
                          setShowVideoReportModel(true);
                        }}
                        className="videoReportIcon__icon"
                        src="/assets/icons/flag.svg"
                        alt="Flat"
                      />
                    </div>
                  </div>
                </div>

                <div className="course__videoWatch--channelInfo">
                  <Link to={`/channel/${playingVideo?.User.username}`}>
                    <div className="course__videoWatch--channelInfo-authorBox">
                      <div className="uploadedImg">{isBadgeOrAvatar()}</div>
                      {/* <img
                        className="uploadedImg"
                        src={seriesInfo?.userInfo.avatar}
                        alt=""
                      />  */}
                      <p className="name">{seriesInfo?.userInfo.username}</p>
                    </div>
                  </Link>

                  {isSubscribed !== "ISMINE" &&
                    isSubscribed === "UNSUBSCRIBED" && (
                      <Button
                        grey
                        className={`${
                          isSubscribed === "UNSUBSCRIBED" &&
                          "subscribeButtonActive"
                        }`}
                        onClick={() => {
                          setIsSubscribed("SUBSCRIBED");
                          dispatch(
                            subscribeChannel({
                              channel: {
                                id: playingVideo.User.id,
                                avatar: playingVideo.User.avatar,
                                username: playingVideo.User.username,
                                visitorBadge: {
                                  imgPath: videoBadge,
                                },
                              },
                              type: SUBSCRIBE_FROM_VIDEO,
                            })
                          );
                        }}
                      >
                        Add to Streams
                      </Button>
                    )}
                  {isSubscribed !== "ISMINE" &&
                    isSubscribed === "SUBSCRIBED" && (
                      <Button
                        grey
                        onClick={() => {
                          setIsSubscribed("UNSUBSCRIBED");
                          dispatch(
                            unsubscribeChannel({
                              type: UNSUBSCRIBE_FROM_VIDEO,
                              channelId: playingVideo.User.id,
                            })
                          );
                        }}
                      >
                        Remove Stream
                      </Button>
                    )}
                </div>

                {seriesInfo?.description && (
                  <div className="course__videoWatch--videoDesc">
                    <p>{seriesInfo.description}</p>
                  </div>
                )}
              </div>
            </Col>

            <Col className="colItem" md={12} lg={4}>
              <div className="course__videosList">
                {seriesVideos.map((item, i) => {
                  return (
                    <Link
                      to={`/series/watch/${seriesId}/${item?.singleDetailedVideo.id}`}
                      onClick={() => setPlayingVideo(item?.singleDetailedVideo)}
                    >
                      <div
                        style={{
                          backgroundColor:
                            progressVideos?.includes(
                              item?.singleDetailedVideo.id
                            ) && "rgba(0, 0, 0, 0.2)",
                        }}
                        key={i}
                        className={`course__videosList--item`}
                      >
                        {progressVideos?.includes(
                          item?.singleDetailedVideo.id
                        ) ? (
                          <div
                            title="You already watched this video"
                            className="watchedIcon"
                          >
                            <img src={CompletedIcon} alt="" />
                          </div>
                        ) : null}

                        <div className="thumbnail">
                          <img
                            src={
                              config.aws.CLOUDFRONT_URL +
                              item?.singleDetailedVideo?.thumbnail
                            }
                            alt={item?.singleDetailedVideo?.title}
                            onError={(e) =>
                              addDefaultImage(e, ImageNotfoundPlaceholder)
                            }
                          />

                          {playingVideo.id === item?.singleDetailedVideo.id && (
                            <div className="watching">Watching</div>
                          )}
                        </div>

                        <div className="metaInfo">
                          <h3 className="metaInfo__title">
                            {item?.singleDetailedVideo?.title.length > 60
                              ? item?.singleDetailedVideo?.title.substring(
                                  0,
                                  60
                                ) + "..."
                              : item?.singleDetailedVideo?.title}
                          </h3>

                          {item?.singleDetailedVideo?.videoLength && (
                            <span className="metaInfo__duration">
                              <img
                                src={TimeIcon}
                                alt=""
                                className="metaInfo__duration--icon"
                              />{" "}
                              {toHHMMSS(item.singleDetailedVideo.videoLength)}
                            </span>
                          )}
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </Col>
          </Row>
        </CourseWatchWrapper>

        <ContactBanner />
        <Footer />

        {showVideoReportModel && (
          <VideoReportModel
            closeHandler={() => setShowVideoReportModel(false)}
            open={showVideoReportModel}
            video={playingVideo}
            uploaderUser={userById}
            user={user}
          />
        )}
      </>
    );
  }

  return null;
};

export default CourseWatch;

const CourseWatchWrapper = styled.div`
  padding: 2rem 0;
  width: 92%;
  margin: 0 auto;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  .colItem {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .subscribeButtonActive {
    background: ${(props) => props.theme.gradient} !important;
    color: ${(props) => props.theme.white} !important;
  }

  .course {
    margin-bottom: 3rem;

    &__videoWatch {
      margin-bottom: 1rem;

      &--videoInfo {
        &-title {
          font-size: 1.2rem;
          color: #fff;
          line-height: 1.4;
          margin-top: 0.5rem;
        }

        &-stats {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 1rem;
          padding-bottom: 1.5rem;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          margin-bottom: 1rem;

          svg {
            fill: ${(props) => props.theme.darkGrey};
          }

          .likedislike {
            display: flex;
            align-items: center;

            .liked {
              svg {
                fill: ${(props) => props.theme.blue} !important;
              }
            }

            .disliked {
              svg {
                fill: ${(props) => props.theme.blue} !important;
              }
            }
          }

          .date {
            color: ${(props) => props.theme.secondaryColor};
            font-size: 0.9rem;
            font-weight: 300;
          }

          .videoReportIcon {
            height: 2.2rem;
            width: 2.2rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            &__icon {
              height: 1.2rem;
              width: 1.2rem;
              cursor: pointer;
            }
          }

          .saveVideo {
            display: flex;
            align-items: center;
            cursor: pointer;

            h5 {
              font-size: 0.9rem;
              color: #888;
              margin-left: 0.2rem;
            }
          }
        }
      }

      /* channel info */
      &--channelInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;

        &-authorBox {
          display: flex;
          align-items: center;

          .uploadedImg {
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
            margin-right: 1rem;
          }

          .name {
            font-weight: 500;
            font-size: 0.95rem;
          }
        }
      }

      /* video desc */
      &--videoDesc {
        font-size: 0.9rem;
        font-weight: 300;
        line-height: 1.6;
        color: #fff;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        margin-bottom: 2rem;

        .showMore {
          padding-left: 0.25rem;
          background: ${(props) => props.theme.gradient};
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }

    &__videosList {
      width: 100%;
      padding: 0.5rem;
      background-color: #222;
      border-radius: 0.5rem;
      margin-bottom: 3rem;
      max-height: 25rem;
      height: 25rem;
      overflow: auto;

      &--item {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        border: 1px solid rgba(255, 255, 255, 0.05);
        border-radius: 5px;
        padding: 0.5rem;
        margin-bottom: 0.5rem;

        .watchedIcon {
          height: 1.5rem;
          width: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.5rem;
          border-radius: 50%;
          position: absolute;
          top: 0.4rem;
          right: 0;
          z-index: 3;

          img {
            height: 1rem;
            width: auto;
          }
        }

        .thumbnail {
          aspect-ratio: 16 / 9;
          width: 6.5rem;
          margin-right: 1rem;
          border-radius: 5px;
          overflow: hidden;
          position: relative;

          img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
          }

          .watching {
            position: absolute;
            top: 0.2rem;
            left: 0.2rem;
            z-index: 3;
            background: ${(props) => props.theme.gradient};
            color: #fff;
            font-size: 0.55rem;
            font-weight: 500;
            padding: 0.3rem;
            border-radius: 2px;
            text-transform: uppercase;
            line-height: 1;
          }
        }

        .metaInfo {
          &__title {
            font-size: 0.9rem;
            line-height: 1;
            margin-bottom: 0.5rem;
            font-weight: 300;
          }

          &__duration {
            font-size: 0.8rem;
            color: ${(props) => props.theme.secondaryColor};
            font-weight: 200;
            display: flex;
            align-items: center;

            &--icon {
              height: 1rem;
              width: auto;
              margin-right: 0.5rem;
            }
          }
        }
      }

      /* scrollbar */
      /* width */
      ::-webkit-scrollbar {
        width: 8px;
        border-radius: 10rem;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.7);
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #333;
        border-radius: 10rem;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: rgb(246, 92, 139);
        cursor: pointer;
      }
    }

    &__courseList {
      &--title {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 1rem;
        line-height: 1;
      }

      &--item {
        margin-bottom: 2rem;
        padding: 7px;
        border: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
`;

const NotPurchased = styled.div`
  padding: 2rem 0;
  width: 92%;
  margin: 0 auto;

  h1 {
    margin-bottom: 1rem;
  }
`;
