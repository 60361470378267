import { combineReducers } from "redux";
import user from "./user";
import chat from "./chatMessages";
import feed from "./feed";
import video from "./video";
import channel_stream_videos from "./myStreams";
import profile from "./profile";
import sidebar from "./sidebar";
import recommendation from "./recommendation";
import channelRecommendation from "./channelRecommendation";
import channelSubscribed from "./subscribedChannel";
import searchResult from "./searchResult";
import trending from "./trending";
import likedVideo from "./likedVideo";
import latest from "./latest";
import staffPick from "./staffPick";
import categoryFeatured from "./categoryFeatured";
import categoryStaffpick from "./categoryStaffpick";
import categoryVideos from "./categoryVideos";
import storage from "./storage";
import history from "./history";
import userById from "./userByID";
import moreFromUser from "./moreFromUser";
import notfound from "./notfound";
import stripePayment from "./stripePayment";
import transfers from "./transfers";
import notificationCategory from "./notificationCategory";
import notification from "./notification";
import featured from "./recommendationFeatured";
import { LOGOUT } from "../actions/types";
import singleStorage from "./singleStorage";
import badgesUserrole from "./badgesUserrole";
import resetPW from "./resetPW";
import categoryById from "./categoryById";
import userStorage from "./userStorage";
import userSettingByUserId from "./userSettingByUserId";
import updateUserSetting from "./updateUserSetting";
import userTransactions from "./userTransactions";
import slider from "./slider";
import featuredWarriors from "./featuredWarriors";
import contactUs from "./contactUs";
import library from "./library";
import marketingBanner from "./marketingBanner";
import newCategoryRequest from "./newCategoryRequest";
import contentFlag from "./contentFlag";
import getFlagTypes from "./getFlagTypes";
import createPlaylist from "./createPlaylist";
import getPlaylists from "./getPlaylists";
import singlePlaylist from "./singlePlaylist";
import deletePlaylist from "./deletePlaylist";
import editPlaylist from "./editPlaylist";
import addVideoToPlaylist from "./addVideoToPlaylist";
import removeVideoFromPlaylist from "./removeVideoFromPlaylist";
import createSeries from "./createSeries";
import getAllSeries from "./getAllSeries";
import singleSeries from "./singleSeries";
import deleteSeries from "./deleteSeries";
import editSeries from "./editSeries";
import addVideoToSeries from "./addVideoToSeries";
import removeVideoFromSeries from "./removeVideoFromSeries";
import dragDropSeriesVideos from "./dragDropSeriesVideos";
import getAllSeriesOfUser from "./getAllSeriesOfUser";
import getPurchasedSeries from "./getPurchasedSeries";
import videos from "./videos";
import createLiveSchedule from "./createLiveSchedule";
import getLiveSchedule from "./getLiveSchedule";
import connection from "./connection";
import connectionStatus from "./connectionStatus";
import moment from "./moment";
import moments from "./moments";
import momentTag from "./momentTag";
import getMomentTags from "./getMomentTags";
import liveClassCategory from "./liveClassCategory";
import KarmaFilterSetting from "./karmaFilterSetting";
import profileElement from "./profileElement";
import profileElements from "./profileElements";
import subscriptionPlans from "./subscriptionPlans";
import userVideos from "./userVideos";
import activeUser from "./activeUser";
import chatCount from "./chatCount";
import featuredSeries from "./featuredSeries";
import thread from "./thread";
import threads from "./threads";
import featuredThreads from "./featuredThreads";
import userNotificationSettings from "./userNotificationSettings";
import streams from "./streams";
import userChannels from "./userChannels";
import profileElementsMoment from "./profileElementsMoment";
import profileElementsVideo from "./profileElementsVideo";
import profileElementsSeries from "./profileElementsSeries";
import profileElementsThreads from "./profileElementsThreads";
import profileElementsBanners from "./profileElementsBanners";
import hashtags from "./hashtags";
import hashtag from "./hashtag";
import videoOverlay from "./videoOverlay";
// Add admin reducers
import adminUserData from "./adminUserData";
import videoTableData from "./videoTableData";
import momentTableData from "./momentTable";
import seriesTableData from "./seriesTable";
import notificationTypesData from "./notificationTypes";
import threadsTable from "./threadsTable";
import vistorBadgeTable from "./vistorBadgeTable";
import acessOverlayTable from "./acessOverlayTable";
import widgetBannerTable from "./widgetBannerTable";
import branding from "./branding";
import featuredCreators from "./featuredCreators";
import marketingPage from "./marketingPage";

const rootReducer = combineReducers({
  hashtag,
  hashtags,
  moments,
  library,
  user,
  streams,
  channel_stream_videos,
  featuredThreads,
  thread,
  threads,
  purchasedSeries: getPurchasedSeries,
  videos,
  featuredSeries,
  chatCount,
  activeUser,
  userStorage,
  userVideos,
  feed,
  videoOverlay,
  video,
  profile,
  sidebar,
  recommendation,
  featured,
  channelRecommendation,
  searchResult,
  trending,
  latest,
  storage,
  staffPick,
  categoryFeatured,
  categoryStaffpick,
  categoryVideos,
  likedVideo,
  history,
  notfound,
  moreVideos: moreFromUser,
  userById,
  stripePayment,
  transfers,
  singleStorage,
  categoryById,
  notificationCategory,
  notification,
  badgesUserrole,
  resetPW,
  userSettingByUserId,
  updateUserSetting,
  userTransactions,
  slider,
  featuredWarriors,
  contactUs,
  marketingBanner,
  newCategoryRequest,
  contentFlag,
  getFlagTypes,
  createPlaylist,
  getPlaylists,
  liveClassCategory,
  singlePlaylist,
  deletePlaylist,
  editPlaylist,
  addVideoToPlaylist,
  removeVideoFromPlaylist,
  createSeries,
  getAllSeries,
  singleSeries,
  deleteSeries,
  editSeries,
  addVideoToSeries,
  removeVideoFromSeries,
  dragDropSeriesVideos,
  getAllSeriesOfUser,
  createLiveSchedule,
  liveSchedules: getLiveSchedule,
  connection,
  connectionStatus,
  moment,
  momentTag,
  getMomentTags,
  KarmaFilterSetting,
  profileElement,
  profileElements,
  subscriptionPlans,
  chat,
  channelSubscribed,
  userNotificationSettings,
  userChannels,
  profileElementsMoment,
  profileElementsVideo,
  profileElementsSeries,
  profileElementsThreads,
  profileElementsBanners,
  featuredCreators,
  // Add admin reducers
  adminUserData,
  videoTableData,
  momentTableData,
  seriesTableData,
  notificationTypesData,
  threadsTable,
  vistorBadgeTable,
  acessOverlayTable,
  widgetBannerTable,
  branding,
  marketingPage,
});

export default (state, action) => rootReducer(state, action);
