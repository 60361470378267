import {
  LOGIN_REQUEST,
  LOGIN,
  SIGNUP_REQUEST,
  SIGNUP,
  LOGOUT,
  ADD_CHANNEL,
  REMOVE_CHANNEL,
  UPDATE_USER,
  LOGIN_FAILED,
  SIGNUP_EMAIL_EXIST,
  SIGNUP_USERNAME_EXIST,
  REFERRAL_CODE_EXIST,
  UPDATE_USER_ROLE,
  UPDATE_USER_PAYMENT_METHOD,
  ADD_NEW_PAYMENT_METHOD,
  UPDATE_USER_BADGE,
  SET_DEFAULT_PAYMENT_METHOD,
  UPDATE_SUBSCRIPTION_ID,
  UPDATE_PROFILE_SUCCESS,
  LOGIN_RESET,
} from "../actions/types";

const localSt = JSON.parse(localStorage.getItem("user"));
const initialState = localSt ? localSt : {};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        signupLoading: true,
      };
    case SIGNUP:
      return {
        signupLoading: false,
        ...action.payload,
      };
    case SIGNUP_EMAIL_EXIST:
      return {
        ...action.payload,
      };

    case SIGNUP_USERNAME_EXIST:
      return {
        ...action.payload,
      };
    case REFERRAL_CODE_EXIST:
      return {
        ...action.payload,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loginLoading: true,
      };
    case LOGIN:
      return {
        loginLoading: false,
        ...action.payload,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loginError: action.payload,
        loginLoading: false,
      };
    case LOGIN_RESET:
      return {
        ...state,
        loginError: null,
        loginLoading: false,
      };
    case ADD_CHANNEL:
      return {
        ...state,
        channels: [action.payload, ...state.channels],
      };
    case REMOVE_CHANNEL:
      return {
        ...state,
        channels: state.channels.filter(
          (channel) => channel.id !== action.payload
        ),
      };

    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_USER_ROLE:
      return {
        ...state,
        userrole: action.payload,
      };
    case UPDATE_USER_PAYMENT_METHOD:
      const existingPMs = state.paymentMethods.data;
      if (existingPMs.length) {
        const fileteredPaymentMethods = existingPMs.filter(
          (pm) => pm.id !== action.payload.id
        );
        let paymentMethodsClone = { ...state.paymentMethods };
        const mergeUpdatedPaymentMethod = [
          ...fileteredPaymentMethods,
          action.payload,
        ];
        paymentMethodsClone.data = mergeUpdatedPaymentMethod;
        return { ...state, paymentMethods: paymentMethodsClone };
      } else return state;
    case ADD_NEW_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case UPDATE_SUBSCRIPTION_ID:
      return {
        ...state,
        subscriptionId: action.payload,
      };
    case UPDATE_USER_BADGE:
      return {
        ...state,
        badge: action.payload,
      };
    case SET_DEFAULT_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          default_payment_method: action.payload,
        },
      };

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case LOGOUT:
      return {};
    default:
      return state;
  }
};

export default user;
