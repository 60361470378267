import React, { useState, useContext, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import BackIcon from "../assets/Icons/back.svg";
import CaretRightIcon from "../assets/Icons/caret-right.svg";
import ManageSubscription from "../components/MyAccountComponents/ManageSubscription/ManageSubscription";
import KarmaHistory from "../components/MyAccountComponents/KarmaHistory/KarmaHistory";
import TransactionHistory from "../components/MyAccountComponents/TransactionHistory/TransactionHistory";
import { GlobalContext } from "../context/GlobalContext";
import config from "../config";
import { useDispatch, useSelector } from "react-redux";
import {
  getKarmaReceivedByUser,
  getKarmaSentByUser,
  getUserTransactions,
  getUserStorageInfo,
} from "../actions";
import api from "../services/api";
import { SET_FILTERED_DATA, SET_KARMA_FILTERED_DATA } from "../actions/types";
import STRINGS from "../utils/Strings";
import { Link } from "@material-ui/core";
import NewConnectWallatModel from "../components/MyPortalComponents/Models/NewConnectwalletModel";
import useOnclickOutside from "react-cool-onclickoutside";
import Progressbar from "../components/Progressbar";
import NotificationTab from "../components/MyPortalComponents/NotificationTab/NotificationTab";
import { toast } from "react-toastify";
import AccountSetting from "../components/MyAccountComponents/AccountSetting/AccountSetting";
import { useHistory } from "react-router-dom";

const MyAccount = ({ close }) => {
  const history = useHistory();
  const { data: siteBranding } = useSelector((state) => state.branding);
  const [openConnectModal, setOpenConnectModal] = useState(false);
  const [showKarmaHistory, setShowKarmaHistory] = useState(false);
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);
  const [showNotificationSettings, setShowNotificationSettings] =
    useState(false);
  const [showAccountSettings, setShowAccountSettings] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [fetchingFilteredData, setFetchingData] = useState(false);
  const [fetchingAccountInfo, setFetchingAccountInfo] = useState(false);
  const [isStripeAccountConnected, setIsStripeAccountConnected] =
    useState(false);
  const [remainingUploadSeconds, setRemainingUploadSeconds] = useState(null);

  const { showMyAccount, setShowManageSubscription, showManageSubscription } =
    useContext(GlobalContext);
  const { data: transactions, isLoading } = useSelector(
    (state) => state.userTransactions
  );
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (showMyAccount) {
      dispatch(getUserStorageInfo());
      getWalletConnectionInfo();
    }
  }, [showMyAccount]);

  useEffect(() => {
    if (user) {
      getRemainingUploadSecs();
    }
  }, [user]);

  async function getRemainingUploadSecs() {
    try {
      const res = await api.get(`/users/upload-seconds-left/${user.id}`);
      if (res.status == 200) {
        setRemainingUploadSeconds(res.data.data.monthlyUploadedSeconds);
      }
    } catch (e) {
      toast.error("Failed to get remaining upload seconds data");
    }
  }

  const getWalletConnectionInfo = async () => {
    try {
      setFetchingAccountInfo(true);
      const response = await api.get("/stripe/checkStripeAccountStatus");
      setIsStripeAccountConnected(response.data.isConnected);
      setFetchingAccountInfo(false);
    } catch (error) {
      toast.error(error.message);
      setFetchingAccountInfo(false);
    }
  };

  const handleFilterUpdate = async (offset, filterType) => {
    try {
      setFetchingData(true);
      if (filterType === STRINGS.FILTER_TYPES.TRANSACTIONS_FILTERS) {
        const transactionsResponse = await api.get(
          `stripe/transactions/${offset}`
        );
        dispatch({
          type: SET_FILTERED_DATA,
          payload: {
            data: transactionsResponse.data,
          },
        });
        setFetchingData(false);
      } else if (filterType === STRINGS.FILTER_TYPES.KARMA_FILTERS) {
        const karmaSentResponse = await api.get(`stripe/karmaSent/${offset}`);

        const karmaReceivedResponse = await api.get(
          `stripe/karmaReceived/${offset}`
        );

        dispatch({
          type: SET_KARMA_FILTERED_DATA,
          payload: {
            karmaSent: karmaSentResponse.data,
            karmaReceived: karmaReceivedResponse.data,
          },
        });
        setFetchingData(false);
      }
    } catch (err) {}
  };

  const loadMoreTransactions = (offset) => {
    try {
      dispatch(getUserTransactions(offset));
    } catch (err) {}
  };
  const connectAccountRef = useOnclickOutside(() => {
    setOpenConnectModal(false);
  });

  const loadMoreKarmaTransactions = (offset, karmaType) => {
    try {
      if (karmaType === "SENT") dispatch(getKarmaSentByUser(offset));
      else dispatch(getKarmaReceivedByUser(offset));
    } catch (err) {}
  };

  if (showManageSubscription) {
    return (
      <ManageSubscription
        close={() => setShowManageSubscription(false)}
        user={user}
      />
    );
  }
  if (showAccountSettings) {
    return (
      // <ManageSubscription
      //   user={user}
      //   close={() => setShowManageSubscription(false)}
      // />
      <AccountSetting close={() => setShowAccountSettings(false)} />
    );
  }

  if (showKarmaHistory) {
    return (
      <KarmaHistory
        close={() => setShowKarmaHistory(false)}
        isLoading={isLoading}
        loadMoreTransactions={loadMoreKarmaTransactions}
        handleFilterUpdate={handleFilterUpdate}
        fetchingFilteredData={fetchingFilteredData}
      />
    );
  }

  if (showNotificationSettings) {
    return <NotificationTab close={() => setShowNotificationSettings(false)} />;
  }

  if (showTransactionHistory) {
    return (
      <TransactionHistory
        close={() => setShowTransactionHistory(false)}
        transactions={transactions}
        isLoading={isLoading}
        loadMoreTransactions={loadMoreTransactions}
        handleFilterUpdate={handleFilterUpdate}
        fetchingFilteredData={fetchingFilteredData}
      />
    );
  }

  if (showProgressBar) {
    return <Progressbar close={() => setShowProgressBar(false)} />;
  }

  const url = `${config.stripe.REACT_APP_STRIPE_ONBOARDING}&&stripe_user[first_name]=${user.firstname}&&stripe_user[last_name]=${user.lastname}&&stripe_user[email]=${user.email}&&stripe_user[url]=${config.frontend.baseUrl}/channel/${user.username}`;

  const handleConnectAccountModal = () => {
    setOpenConnectModal(true);
  };

  return (
    <MyAccountStyled>
      <div className={`wrapper ${showMyAccount && "ShowMyAccountPage"}`}>
        <div className="wrapper__header">
          <div onClick={close} className="backIcon">
            <img src={BackIcon} alt="" />
          </div>
          <div className="connect">
            <div className="name">My Account</div>
          </div>
        </div>

        <div className="wrapper__list">
          <div
            onClick={() => setShowManageSubscription(true)}
            className="wrapper__list--item"
          >
            <div className="left">
              <p className="title">Manage Subscription</p>
              <p className="infoText">
                View/Upgrade your {siteBranding?.site_name} subscription
              </p>
            </div>

            <div className="right">
              <img src={CaretRightIcon} alt="" />
            </div>
          </div>

          {user?.userrole === 2 ||
            (remainingUploadSeconds > 0 && (
              <div
                onClick={() => {
                  if (!fetchingAccountInfo) {
                    if (isStripeAccountConnected) {
                      handleConnectAccountModal();
                    } else {
                      window.open(url);
                    }
                  }
                }}
                className={`wrapper__list--item  ${
                  !fetchingAccountInfo && !isStripeAccountConnected
                    ? "wrapper__list--item-highlight wrapper__list--item-animation"
                    : ""
                }`}
              >
                <div className="left">
                  {!fetchingAccountInfo && isStripeAccountConnected ? (
                    <p className="title">Edit Wallet</p>
                  ) : (
                    <p className="title">Connect Wallet</p>
                  )}

                  <p className="infoText">
                    Manage your connected account for receiving payments
                  </p>
                </div>

                <div className="right">
                  <img src={CaretRightIcon} alt="" />
                </div>
              </div>
            ))}

          <div
            onClick={() => setShowAccountSettings(true)}
            className="wrapper__list--item"
          >
            <div className="left">
              <p className="title">Account Settings</p>
              <p className="infoText">Change your Email, Name & Password.</p>
            </div>

            <div className="right">
              <img src={CaretRightIcon} alt="" />
            </div>
          </div>

          <div
            onClick={() => setShowKarmaHistory(true)}
            className="wrapper__list--item"
          >
            <div className="left">
              <p className="title">Karma History</p>
              <p className="infoText">
                A history of all Karma you have sent and received
              </p>
            </div>

            <div className="right">
              <img src={CaretRightIcon} alt="" />
            </div>
          </div>

          <div
            onClick={() => setShowTransactionHistory(true)}
            className="wrapper__list--item"
          >
            <div className="left">
              <p className="title">Transaction History</p>
              <p className="infoText">Check all your transactions on iSutra</p>
            </div>

            <div className="right">
              <img src={CaretRightIcon} alt="" />
            </div>
          </div>
          {user.userrole === 2 || remainingUploadSeconds ? (
            <div
              onClick={() => setShowProgressBar(true)}
              className="wrapper__list--item"
            >
              <div className="left">
                <p className="title">Minutes Utilization</p>
                <p className="infoText">
                  Check how many minutes you’ve used this month
                </p>
              </div>

              <div className="right">
                <img src={CaretRightIcon} alt="" />
              </div>
            </div>
          ) : null}
          <div
            onClick={() => setShowNotificationSettings(true)}
            className="wrapper__list--item"
          >
            <div className="left">
              <p className="title">Notification Settings</p>
              <p className="infoText">Manage your Bell & Email notifications</p>
            </div>

            <div className="right">
              <img src={CaretRightIcon} alt="" />
            </div>
          </div>
        </div>
        {openConnectModal && (
          <NewConnectWallatModel
            doNotClose={connectAccountRef}
            close={() => setOpenConnectModal(false)}
          />
        )}
      </div>
    </MyAccountStyled>
  );
};

export default MyAccount;

const highlightAnimation = keyframes`
  0% {
    outline: 1px solid rgba(255,255,255, .2);
  }

  100% {
    outline: 5px solid rgba(255,255,255, .8);
  }
`;

const MyAccountStyled = styled.div`
  .wrapper {
    height: 100vh;
    width: 100%;
    background-color: #1c1c1e;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    z-index: 150;
    opacity: 0;
    transform: translateX(-100%);
    transition: all 1s ease;

    &__header {
      display: flex;
      align-items: center;
      padding: 1rem 5rem;
      border-bottom: 1px solid rgba(112, 112, 112, 0.4);

      .backIcon {
        margin-right: 1rem;
        cursor: pointer;

        img {
          height: 1rem;
          width: auto;
        }
      }

      .name {
        font-size: 1.2rem;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    &__list {
      width: 45%;
      margin: 2rem auto;
      min-width: 480px;

      &--item {
        margin-bottom: 1rem;
        padding: 1rem 2rem;
        border-radius: 0.5rem;
        background-color: #2c2c2e;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &-highlight {
          background: ${(props) => props.theme.primaryGradient} !important;

          .infoText {
            color: #fff !important;
          }
        }

        &-animation {
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            border-radius: 0.5rem;
            animation: ${highlightAnimation} 1.5s ease infinite alternate;
          }
        }

        p {
          font-family: ${(props) => props.theme.montserrat};
        }

        .title {
          color: #f2f2f7;
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 1.3;
        }

        .infoText {
          font-size: 0.8rem;
          font-weight: 300;
          color: #d1d1d6;
        }

        .right {
          img {
            height: 0.8rem;
            width: auto;
          }
        }
      }
    }
  }
  .connect {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .ConnectLink {
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: capitalize;
  }
  .editbtn {
    background: none;
    border: none;
    display: inline-block;
    cursor: pointer;
  }
  .edit-wallet {
    font-size: 1.2rem;
    font-weight: 400;
    color: #ffffff;
  }

  @media screen and (max-width: 600px) {
    .wrapper__list--item {
      width: 100%;
    }
    .wrapper {
      &__header {
        padding: 0.8rem 2rem;
      }

      &__list {
        width: 90%;
        min-width: 90%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .wrapper {
      &__header {
        padding: 0.5rem 1.5rem;

        .name {
          font-size: 1rem;
        }
      }

      &__list {
        &--item {
          .title {
            font-size: 1rem;
          }

          .infoText {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
`;
