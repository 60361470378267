import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import Button from "../../Elements/Button";
import ChevronLeftIcon from "../../icons/chevronleft.svg";
import { useSelector } from "react-redux";
import { Row, Col } from "react-grid-system";
import { elementScrollToTop } from "../../../utils";

const Storage = ({ setProgress }) => {
  const storage = useSelector((state) => state.storage);
  const {
    selectedStorage,
    setSelectedStorage,
    setValidationError,
    setStorageObj,
  } = useContext(AuthContext);

  useEffect(() => {
    elementScrollToTop("authContent");
  }, []);

  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(1);
  };

  const convertSize = (mb) => {
    if (!mb) {
      return alert("Please provide size");
    }

    let size;
    if (mb >= 1000000) {
      size = mb / 1000000;
    } else {
      size = mb / 1000;
    }

    return size;
  };

  const nextHandler = () => {
    if (!selectedStorage) {
      return setValidationError({
        type: "error",
        message: "Please select storage package.",
      });
    }

    setValidationError(null);
    setProgress(5);
  };

  return (
    <>
      <div className="actionRow">
        <Button
          size="small"
          variant={"outline"}
          mode="secondary"
          onClick={() => setProgress(3)}
          iconLeft={true}
          className="actionRow__back"
        >
          <img src={ChevronLeftIcon} alt="" className="icon" />
          <span>Edit Badge</span>
        </Button>

        <Button onClick={nextHandler} className="actionRow__submit">
          Continue
        </Button>
      </div>

      <div className="paddingBox">
        <div className="title">Choose Data-Package</div>

        <div className="storage">
          <Row className="storage__list">
            {!storage.isFetching &&
              storage.storages &&
              storage.storages.length > 0 &&
              storage.storages.map((item, i) => (
                <Col
                  md={6}
                  sm={12}
                  style={{ paddingLeft: "8px", paddingRight: "8px" }}
                >
                  <div
                    onClick={() => {
                      setSelectedStorage(item.id);
                      setStorageObj(item);
                    }}
                    className={`storage__list--item ${
                      selectedStorage === item.id ? "selectedCard" : null
                    }`}
                  >
                    <span>{item.name}</span>

                    <div className="main">
                      <div className="icon">
                        <i className="fas fa-database" />
                      </div>
                      <h2>
                        {addDecimals(convertSize(item.size))}
                        {item.size >= 1000000 ? "TB" : "GB"}
                      </h2>
                      <div className="title">
                        <h1>{parseInt(item.cost)}$/</h1>
                        <p>{item.period}</p>
                      </div>
                    </div>

                    {selectedStorage === item.id ? (
                      <i className="Selected fas fa-check-circle" />
                    ) : null}
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </div>
    </>
  );
};

export default Storage;
