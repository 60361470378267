import React, { useState } from "react";
import styled from "styled-components";
import SectionHeader from "../../SectionHeader";
import Slider from "react-slick";
import FeaturedCreatorCard from "../../../Cards/FeaturedCreatorCard";
import VideoPlayer from "../../../Models/VideoPlayer";

const SLIDERSETTINGS = {
  customPaging: function (i) {
    return <div className="slider__dot" />;
  },
  arrows: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: false,
  speed: 1000,
  autoplaySpeed: 7000,
  adaptiveHeight: true,
  dots: true,
  pauseOnHover: true,
  swipeToSlide: true,

  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Creators = ({ data }) => {
  const [showIntro, setShowIntro] = useState(false);
  const [introUrl, setIntroUrl] = useState(null);

  if (!data?.length) {
    return null;
  }

  return (
    <>
      <CreatorsStyled>
        <SectionHeader subTitle="Our top rated creators" title="Creators" />

        <div className="content">
          <Slider className="slider" {...SLIDERSETTINGS}>
            {data?.map((item, i) => (
              <div className="slider__item">
                <FeaturedCreatorCard
                  item={item}
                  setShowIntro={setShowIntro}
                  setIntroUrl={setIntroUrl}
                />
              </div>
            ))}
          </Slider>
        </div>
      </CreatorsStyled>

      <VideoPlayer
        isOpen={showIntro}
        onClose={() => setShowIntro(false)}
        url={introUrl}
      />
    </>
  );
};

export default Creators;

const CreatorsStyled = styled.div`
  padding-bottom: 7rem;

  .content {
    width: 80%;
    margin: 0 auto;
    padding-top: 4rem;
  }

  .slider {
    cursor: pointer;

    &__item {
      width: 100%;
      outline: none;
      padding: 0 1rem;
    }

    &__dot {
      height: 8px;
      width: 8px;
      background: rgba(255, 255, 255, 0.5);
      transition: all 0.4s ease;
      border-radius: 50%;
    }

    .slick-dots {
      bottom: -2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        height: auto;
        width: auto;
        margin: 0 4px;
      }
    }

    .slick-active .slider__dot {
      background: #fff;
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      width: 100%;
      margin: 3rem 0 0 0;

      .slick-dots {
        bottom: -1.5rem;
      }
    }
  }
`;
