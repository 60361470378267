import React, { useContext, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import MomentCard from "./MomentCard";
import { useHistory } from "react-router-dom";
import Title from "../../Elements/Title";
import Error from "../../Elements/Error";

const SLIDERSETTINGS = {
  arrows: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 2,
  autoplay: false,
  speed: 1000,
  autoplaySpeed: 5000,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ],
};

const Moments = ({ moments }) => {
  const history = useHistory();
  const [midDrag, setMidDrag] = useState(false);

  return (
    <>
      {!moments?.length || moments === null ? null : (
        <MomentsStyled>
          <Title
            viewAllAction={() => {
              history.push(`/moments?skip=10`);
            }}
            viewAllText="View all moments"
          >
            Moments
          </Title>
          {!moments?.length ? (
            <Error text="No moments found" />
          ) : (
            <Slider
              className="slider"
              {...SLIDERSETTINGS}
              beforeChange={() => setMidDrag(true)}
              afterChange={() => setMidDrag(false)}
            >
              {moments.map((item, i) => (
                <div key={i} className="slider__item">
                  <MomentCard
                    moment={item}
                    midDrag={midDrag}
                    setMidDrag={setMidDrag}
                  />
                </div>
              ))}
            </Slider>
          )}
        </MomentsStyled>
      )}
    </>
  );
};

export default Moments;

const MomentsStyled = styled.section`
  width: 100%;
  padding: 2rem 5rem;
  border-bottom: 1px solid rgba(242, 242, 247, 0.2);

  .sectionTitle {
    margin-bottom: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-family: brother-1816, sans-serif;
      font-size: 1.7rem;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  .slider {
    cursor: pointer;

    .slick-slide {
      padding: 0 0.5rem;
    }

    .slick-track {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 2rem 1rem;

    .sectionTitle {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .slider {
      .slick-slide {
        padding: 0 0.2rem;
      }
    }
  }
`;
