import React, { useState, useRef, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import styled from "styled-components";
import AddIcon from "../../../assets/Icons/add.svg";
import ChooseProfileImage from "../Models/ChooseProfileImage";
import { AddFriendIcon, DeleteCircle, MoveElement } from "../../Icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { GlobalContext } from "../../../context/GlobalContext";
import { useSelector, useDispatch } from "react-redux";
import { uploadCover } from "../../../utils";
import {
  getProfileElements,
  createProfileElement,
  removeProfileElement,
  updateProfileElement,
  getUserProfileElemtMoments,
  getUserProfileElemtSeries,
  getUserProfileElemtVideos,
  getUserProfileElemtThreads,
} from "../../../actions";
import { toast } from "react-toastify";
import AddFeaturedContent from "../Models/AddFeaturedContent";
import VideoCard from "../../Cards/VideoCard";
import SeriesCard from "../../Cards/SeriesCard";
import MomentCard from "../../Cards/MomentCard";
import { Row, Col } from "react-grid-system";
import { RESET_PROFILE_ELEMENT } from "../../../actions/types";
import BannerCard from "../../Cards/BannerCard";
import { notificationMessage } from "../../../utils/toastNotifications";
import DisplaySettingForm from "../../EditProfileComponents/DisplaySettingForm";
import { Modal } from "../../Modal";
import EditIcon from "../../icons/EditIcon";
import { updateUserSettings } from "../../../actions";
import { styled as MUIStyled } from "@mui/material";
import PlusIcon from "../../icons/plus.svg";
import { SERIES_SELF, VIDEO_SELF } from "../../../utils/menuOptions";
import config from "../../../config";
import ThreadCardSmall from "../../Cards/ThreadCardSmall";
import Loader from "../../Elements/Loader";

const PopoverStyled = MUIStyled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    borderRadius: `5px`,
    background: `#202020`,
    color: "#fff",
    border: "1px solid rgba(255,255,255, .1)",
  },

  "& .MuiPaper-root": {
    boxShadow: "none",

    "&:not(:last-child)": {
      borderBottom: "1px solid #fff",
    },
  },

  "& .MuiList-root": {
    padding: "0.4rem .35rem .1rem .35rem",
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const UserEditProfile = ({
  avatar,
  setAvatar,
  cover,
  setCover,
  tagline,
  setTagline,
  bio,
  setBio,
  visitorBadgeId,
  setVisitorBadgeId,
  badge,
  setBadge,
  showProfilePicType,
  setShowProfilePicType,
}) => {
  const dispatch = useDispatch();
  const { setShowChooseProfilePicModel, showEditProfile } =
    useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addFeaturedContentModel, setAddFeaturedContentModel] = useState(false);
  const open = Boolean(anchorEl);
  const dragListRef = useRef(null);

  const profile = useSelector((state) => state.profile);
  const {
    createData,
    createLoading,
    deleteLoading,
    deleteMessage,
    removeContentMessage,
    addContentMessage,
  } = useSelector((state) => state.profileElement);
  const {
    getData: profileElements,
    getError,
    getLoading,
  } = useSelector((state) => state.profileElements);
  const userData = useSelector((state) => state.user);
  // states
  const [showTaglineInput, setShowTaglineInput] = useState(false);
  const [showBioInput, setShowBioInput] = useState(false);
  const [elements, setElements] = useState([]);
  const [type, setType] = useState("");
  const [showDropdown, setShowDropdown] = useState(true);
  const [selectedProfileElement, setSelectedProfileElement] = useState(null);

  useEffect(() => {
    if (profile && profile.id) {
      setTagline(profile?.tagline);
      setCover(profile?.cover);
      setAvatar(profile?.avatar);
      setBadge(profile?.badge);
      setBio(profile?.bio);
    }
    if (profile && profile.userSettings) {
      setCity(profile?.userSettings?.city);
      setState(profile?.userSettings?.state);
      setCountry(profile?.userSettings?.country);
      setOutOfThisWorld(profile?.userSettings?.outOfThisWorld);
    }
  }, [showEditProfile]);

  useEffect(() => {
    if (createData && dragListRef?.current) {
      dragListRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [elements, createData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userData?.id === profile?.id) {
      dispatch(getProfileElements(userData.username, userData?.id));
      dispatch(getUserProfileElemtVideos(userData.username, userData?.id));
      dispatch(getUserProfileElemtMoments(userData.username, userData?.id));
      dispatch(getUserProfileElemtSeries(userData.username, userData?.id));
      dispatch(getUserProfileElemtThreads(userData.username, userData?.id));
    }
    if (removeContentMessage || addContentMessage) {
      dispatch({
        type: RESET_PROFILE_ELEMENT,
      });
    }
  }, [
    userData,
    profile,
    createData,
    deleteMessage,
    removeContentMessage,
    addContentMessage,
  ]);

  useEffect(() => {
    if (createLoading) {
      toast.success(notificationMessage.elementCreating);
    }
    if (deleteLoading) {
      toast.success(notificationMessage.elementDeleting);
    }
  }, [createLoading, deleteLoading]);

  useEffect(() => {
    if (profileElements && profileElements.length > 0) {
      setElements(profileElements);
    }

    if (getError) {
      setElements([]);
    }
  }, [profileElements, getError]);

  useEffect(() => {
    if (elements && elements.length > 0) {
      const video = elements.filter((e) => e.type === "video").length > 0;
      const series = elements.filter((e) => e.type === "series").length > 0;
      const moment = elements.filter((e) => e.type === "moment").length > 0;
      const post = elements.filter((e) => e.type === "post").length > 0;

      if (video && series && moment && post) {
        setShowDropdown(false);
      } else {
        setShowDropdown(true);
      }
    }
  }, [elements]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeSection = (elementId) => {
    dispatch(removeProfileElement(elementId));
  };

  const addSection = (type) => {
    const obj = {
      type,
      order: elements.length + 1,
    };

    dispatch(createProfileElement(obj));
    handleClose();
  };

  const isBadgeOrAvatar = () => {
    if (avatar && showProfilePicType === "avatar") {
      return (
        <img
          className="imageAvatar"
          src={config.aws.CLOUDFRONT_URL + avatar}
          alt=""
        />
      );
    }

    if (badge && showProfilePicType === "badge") {
      return (
        <div className="imageBadge">
          <img className="badge" src={badge} alt="" />
        </div>
      );
    }
  };

  const handleCoverUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const { publicId, url } = await uploadCover("image", file);

      setCover(url);
    }
  };

  const onDragEndHandler = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      elements,
      result.source.index,
      result.destination.index
    );

    setElements(items);

    // setting the database
    const promises = items.map(async (item, index) => {
      let order = index + 1;

      dispatch(
        updateProfileElement(item.id, {
          order,
        })
      );
    });

    await Promise.all(promises);

    toast.success(notificationMessage.elementReorder);
  };

  const isElementAlreadyExists = (type) => {
    const item = elements.find((item) => item.type === type);

    if (item) {
      return true;
    }

    return false;
  };

  const [openPublicDisplaySetting, setOpenPublicDisplaySetting] =
    useState(false);
  const [country, setCountry] = useState(profile?.userSettings?.country);
  const [state, setState] = useState(profile?.userSettings?.state);
  const [city, setCity] = useState(profile?.userSettings?.city);
  const [outOfThisWorld, setOutOfThisWorld] = useState(
    profile?.userSettings?.outOfThisWorld
  );
  const handleOpenPublicDisplaySettings = () => {
    setOpenPublicDisplaySetting(true);
  };

  let location = "";
  if (profile) {
    location = profile?.userSettings?.outOfThisWorld
      ? "Out of this world"
      : `${
          profile?.userSettings?.city !== null && profile?.userSettings?.city
        }, ${
          profile?.userSettings?.state !== null && profile?.userSettings?.state
        }`;
  }

  const savePublicDisplaySettings = () => {
    const obj = {
      city,
      state,
      country,
      outOfThisWorld,
    };

    dispatch(updateUserSettings(profile?.userSettings?.id, obj));

    toast.success(notificationMessage.profileUpdateSuccessfull);
    setOpenPublicDisplaySetting(false);
  };

  return (
    <>
      <UserEditProfileStyled>
        {openPublicDisplaySetting && (
          <Modal
            isShowing={openPublicDisplaySetting}
            hide={() => setOpenPublicDisplaySetting(false)}
            style={{ background: "#202020" }}
          >
            <DisplaySettingForm
              country={country}
              setCountry={setCountry}
              state={state}
              setState={setState}
              city={city}
              setCity={setCity}
              outOfThisWorld={outOfThisWorld}
              setOutOfThisWorld={setOutOfThisWorld}
              savePublicDisplaySettings={savePublicDisplaySettings}
            />
          </Modal>
        )}
        <div className="topSection">
          <div className="topSection__banner">
            <img
              src={
                cover
                  ? config.aws.CLOUDFRONT_URL + cover
                  : "https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg"
              }
              alt=""
            />

            <label htmlFor="cover-upload" className="overlay"></label>
            <img src={AddIcon} alt="" className="icon" />

            <input
              id="cover-upload"
              type="file"
              accept="image/*"
              onChange={handleCoverUpload}
              style={{ display: "none" }}
            />
          </div>

          {(profile?.avatar || profile?.badge) && (
            <div
              onClick={() => setShowChooseProfilePicModel(true)}
              className="topSection__avatar"
            >
              {isBadgeOrAvatar()}

              <div className="overlay"></div>
              <img src={AddIcon} alt="" className="icon" />
            </div>
          )}
        </div>

        <div className="metaInfo">
          <div className="username">{profile?.username}</div>
          <div
            className="secondary"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="secondary" style={{ fontSize: "13px" }}>
              {location}
            </span>
            <span style={{ marginBottom: "9px" }}>
              <Button onClick={handleOpenPublicDisplaySettings}>
                <EditIcon fill="#CC0000" />
              </Button>
            </span>
          </div>
          {profile?.userrole && (
            <div className="role">
              {profile.userrole === 0
                ? "freeloader"
                : profile.userrole === 1
                ? "CO-OP"
                : profile.userrole === 2
                ? "warrior"
                : null}
            </div>
          )}
        </div>
        {showDropdown && (
          <div className="addSectionButton">
            <Button
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className="btn"
            >
              <AddFriendIcon width={25} />
              <p>Add new element</p>
            </Button>
            <PopoverStyled
              id={open ? "add-section" : undefined}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {!isElementAlreadyExists("video") && (
                <ItemStyled onClick={() => addSection("video")}>
                  <img src={PlusIcon} /> Featured Videos
                </ItemStyled>
              )}

              {!isElementAlreadyExists("moment") && (
                <ItemStyled onClick={() => addSection("moment")}>
                  <img src={PlusIcon} /> Featured Moments
                </ItemStyled>
              )}

              {!isElementAlreadyExists("series") && (
                <ItemStyled onClick={() => addSection("series")}>
                  <img src={PlusIcon} /> Featured Series
                </ItemStyled>
              )}
              {!isElementAlreadyExists("banner") && (
                <ItemStyled onClick={() => addSection("banner")}>
                  <img src={PlusIcon} /> Featured Banner
                </ItemStyled>
              )}

              {!isElementAlreadyExists("thread") && (
                <ItemStyled onClick={() => addSection("thread")}>
                  <img src={PlusIcon} /> Featured Threads
                </ItemStyled>
              )}
            </PopoverStyled>
          </div>
        )}
        <div className="cards">
          <div className="item">
            <h2>Tagline</h2>
            {showTaglineInput ? (
              <div className="item__input">
                <input
                  autoFocus={true}
                  type="text"
                  placeholder="Pleceholder text"
                  value={tagline}
                  onChange={(e) => setTagline(e.target.value)}
                />

                <div
                  onClick={() => setShowTaglineInput(false)}
                  className="tickIcon"
                >
                  <i className="fas fa-check"></i>
                </div>
              </div>
            ) : (
              <div
                onClick={() => setShowTaglineInput(true)}
                className="item__section"
              >
                {tagline ? tagline : "Add Tagline..."}
              </div>
            )}
          </div>
          <div className="item">
            <h2>Description</h2>
            {showBioInput ? (
              <div className="item__input">
                <textarea
                  autoFocus={true}
                  type="text"
                  placeholder="Tell us about yourself..."
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  className="desctext"
                />

                <div
                  onClick={() => setShowBioInput(false)}
                  className="tickIcon"
                >
                  <i className="fas fa-check"></i>
                </div>
              </div>
            ) : (
              <div
                onClick={() => setShowBioInput(true)}
                className="item__section"
              >
                {bio ? bio : "Tell us about yourself..."}
              </div>
            )}
          </div>
        </div>

        <DragDropContext onDragEnd={(result) => onDragEndHandler(result)}>
          <Droppable droppableId="droppableId">
            {(provided, snapshot) => (
              <div
                className="dragList"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {elements && elements.length > 0 ? (
                  elements.map((item, i) => {
                    return (
                      <div ref={dragListRef} className="dragList__container">
                        <h2 className="dragList__itemType">
                          {item.type === "video"
                            ? "Featured Videos"
                            : item.type === "series"
                            ? "Featured Series"
                            : "Featured " + item.type + "s"}
                        </h2>
                        <Draggable
                          key={item.type}
                          draggableId={item.type}
                          index={i}
                        >
                          {(provided, snapshot) => {
                            if (item.type === "video") {
                              return (
                                <div
                                  className="dragItem"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={{
                                    userSelect: "none",
                                    background: snapshot.isDragging
                                      ? "#383838"
                                      : null,
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <div className="dragItem__icon moveIcon">
                                    <div className="feature">
                                      <MoveElement />
                                    </div>
                                  </div>

                                  <div className="dragItem__section">
                                    {item.featuredDetailedContents &&
                                    item.featuredDetailedContents.length > 0 ? (
                                      <>
                                        <Row className="row">
                                          {item.featuredDetailedContents.map(
                                            (video, i) => (
                                              <Col
                                                key={i}
                                                lg={4}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                                className="item"
                                                style={{
                                                  paddingLeft: "10px",
                                                  paddingRight: "10px",
                                                }}
                                              >
                                                <VideoCard
                                                  key={i}
                                                  item={video}
                                                  removeable
                                                  elementId={item.id}
                                                  isSelf={true}
                                                  menuOptions={VIDEO_SELF}
                                                />
                                              </Col>
                                            )
                                          )}

                                          <div
                                            onClick={() => {
                                              setType(item.type);
                                              setSelectedProfileElement(item);
                                              setAddFeaturedContentModel(true);
                                            }}
                                            className="dragItem__section--cardPlaceholder"
                                          >
                                            <div className="icon">
                                              <img src={AddIcon} alt="" />
                                            </div>
                                          </div>
                                        </Row>
                                      </>
                                    ) : (
                                      <div
                                        onClick={() => {
                                          setType(item.type);
                                          setSelectedProfileElement(item);
                                          setAddFeaturedContentModel(true);
                                        }}
                                        className="dragItem__section--cardPlaceholder"
                                      >
                                        <div className="icon">
                                          <img src={AddIcon} alt="" />
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  <div
                                    onClick={() => removeSection(item.id)}
                                    className="dragItem__icon deleteIcon"
                                  >
                                    <DeleteCircle />
                                  </div>
                                </div>
                              );
                            }

                            if (item.type === "series") {
                              return (
                                <div
                                  className="dragItem"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={{
                                    userSelect: "none",
                                    background: snapshot.isDragging
                                      ? "#383838"
                                      : null,
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <div className="dragItem__icon moveIcon">
                                    <MoveElement />
                                  </div>

                                  <div className="dragItem__section">
                                    {item.featuredDetailedContents &&
                                    item.featuredDetailedContents.length > 0 ? (
                                      <>
                                        <Row className="row">
                                          {item.featuredDetailedContents.map(
                                            (series, i) => (
                                              <Col
                                                key={i}
                                                lg={4}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                                className="item"
                                                style={{
                                                  paddingLeft: "10px",
                                                  paddingRight: "10px",
                                                }}
                                              >
                                                <SeriesCard
                                                  key={i}
                                                  item={series}
                                                  removeable
                                                  elementId={item.id}
                                                  isSelf={true}
                                                  menuOptions={SERIES_SELF}
                                                />
                                              </Col>
                                            )
                                          )}

                                          <div
                                            onClick={() => {
                                              setType(item.type);
                                              setSelectedProfileElement(item);
                                              setAddFeaturedContentModel(true);
                                            }}
                                            className="dragItem__section--cardPlaceholder"
                                          >
                                            <div className="icon">
                                              <img src={AddIcon} alt="" />
                                            </div>
                                          </div>
                                        </Row>
                                      </>
                                    ) : (
                                      <div
                                        onClick={() => {
                                          setType(item.type);
                                          setSelectedProfileElement(item);
                                          setAddFeaturedContentModel(true);
                                        }}
                                        className="dragItem__section--cardPlaceholder"
                                      >
                                        <div className="icon">
                                          <img src={AddIcon} alt="" />
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  <div
                                    onClick={() => removeSection(item.id)}
                                    className="dragItem__icon deleteIcon"
                                  >
                                    <DeleteCircle />
                                  </div>
                                </div>
                              );
                            }

                            if (item.type === "moment") {
                              return (
                                <div
                                  className="dragItem"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={{
                                    userSelect: "none",
                                    background: snapshot.isDragging
                                      ? "#383838"
                                      : null,
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <div className="dragItem__icon moveIcon">
                                    <MoveElement />
                                  </div>

                                  <div className="dragItem__section">
                                    {item.featuredDetailedContents &&
                                    item.featuredDetailedContents.length > 0 ? (
                                      <>
                                        <Row className="row">
                                          {item.featuredDetailedContents.map(
                                            (moment, i) => (
                                              <Col
                                                key={i}
                                                lg={3}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                                className="item"
                                                style={{
                                                  paddingLeft: "10px",
                                                  paddingRight: "10px",
                                                }}
                                              >
                                                <MomentCard
                                                  key={i}
                                                  item={moment}
                                                  removeable
                                                  elementId={item.id}
                                                />
                                              </Col>
                                            )
                                          )}

                                          <div
                                            onClick={() => {
                                              setType(item.type);
                                              setSelectedProfileElement(item);
                                              setAddFeaturedContentModel(true);
                                            }}
                                            className="dragItem__section--cardPlaceholder"
                                          >
                                            <div className="icon">
                                              <img src={AddIcon} alt="" />
                                            </div>
                                          </div>
                                        </Row>
                                      </>
                                    ) : (
                                      <div
                                        onClick={() => {
                                          setType(item.type);
                                          setSelectedProfileElement(item);
                                          setAddFeaturedContentModel(true);
                                        }}
                                        className="dragItem__section--cardPlaceholder"
                                      >
                                        <div className="icon">
                                          <img src={AddIcon} alt="" />
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  <div
                                    onClick={() => removeSection(item.id)}
                                    className="dragItem__icon deleteIcon"
                                  >
                                    <DeleteCircle />
                                  </div>
                                </div>
                              );
                            }
                            if (item.type === "banner") {
                              return (
                                <div
                                  className="dragItem"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={{
                                    userSelect: "none",
                                    background: snapshot.isDragging
                                      ? "#383838"
                                      : null,
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <div className="dragItem__icon moveIcon">
                                    <MoveElement />
                                  </div>

                                  <div className="dragItem__section">
                                    {item.featuredDetailedContents &&
                                    item.featuredDetailedContents.length > 0 ? (
                                      <>
                                        <Row className="row">
                                          {item.featuredDetailedContents.map(
                                            (customBanner, i) => (
                                              <Col
                                                key={i}
                                                lg={3}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                                className="item"
                                                style={{
                                                  paddingLeft: "10px",
                                                  paddingRight: "10px",
                                                }}
                                              >
                                                <BannerCard
                                                  key={i}
                                                  item={customBanner}
                                                  removeable
                                                  elementId={item.id}
                                                />
                                              </Col>
                                            )
                                          )}

                                          <div
                                            onClick={() => {
                                              setType(item.type);
                                              setSelectedProfileElement(item);
                                              setAddFeaturedContentModel(true);
                                            }}
                                            className="dragItem__section--cardPlaceholder"
                                          >
                                            <div className="icon">
                                              <img src={AddIcon} alt="" />
                                            </div>
                                          </div>
                                        </Row>
                                      </>
                                    ) : (
                                      <div
                                        onClick={() => {
                                          setType(item.type);
                                          setSelectedProfileElement(item);
                                          setAddFeaturedContentModel(true);
                                        }}
                                        className="dragItem__section--cardPlaceholder"
                                      >
                                        <div className="icon">
                                          <img src={AddIcon} alt="" />
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  <div
                                    onClick={() => removeSection(item.id)}
                                    className="dragItem__icon deleteIcon"
                                  >
                                    <DeleteCircle />
                                  </div>
                                </div>
                              );
                            }

                            if (item.type === "thread") {
                              return (
                                <div
                                  className="dragItem"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={{
                                    userSelect: "none",
                                    background: snapshot.isDragging
                                      ? "#383838"
                                      : null,
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <div className="dragItem__icon moveIcon">
                                    <div className="feature">
                                      <MoveElement />
                                    </div>
                                  </div>

                                  <div className="dragItem__section">
                                    {item.featuredDetailedContents &&
                                    item.featuredDetailedContents.length > 0 ? (
                                      <>
                                        <Row className="row">
                                          {item.featuredDetailedContents.map(
                                            (thread, i) => (
                                              <Col
                                                key={i}
                                                lg={4}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                                className="item"
                                                style={{
                                                  paddingLeft: "10px",
                                                  paddingRight: "10px",
                                                }}
                                              >
                                                <ThreadCardSmall
                                                  item={thread}
                                                  type={thread?.attachedType}
                                                  removeable
                                                  elementId={item?.id}
                                                />
                                              </Col>
                                            )
                                          )}

                                          <div
                                            onClick={() => {
                                              setType(item.type);
                                              setSelectedProfileElement(item);
                                              setAddFeaturedContentModel(true);
                                            }}
                                            className="dragItem__section--cardPlaceholder"
                                          >
                                            <div className="icon">
                                              <img src={AddIcon} alt="" />
                                            </div>
                                          </div>
                                        </Row>
                                      </>
                                    ) : (
                                      <div
                                        onClick={() => {
                                          setType(item.type);
                                          setSelectedProfileElement(item);
                                          setAddFeaturedContentModel(true);
                                        }}
                                        className="dragItem__section--cardPlaceholder"
                                      >
                                        <div className="icon">
                                          <img src={AddIcon} alt="" />
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  <div
                                    onClick={() => removeSection(item.id)}
                                    className="dragItem__icon deleteIcon"
                                  >
                                    <DeleteCircle />
                                  </div>
                                </div>
                              );
                            }

                            return null;
                          }}
                        </Draggable>
                      </div>
                    );
                  })
                ) : (
                  <div className="info">
                    {/* {getLoading
                      ? "Fetching Profile Elements"
                      : getError
                      ? getError
                      : "Add Elements for your profile"} */}
                    {getLoading ? (
                      <>
                        <Loader text="Fetching Profile Elements..." />
                      </>
                    ) : getError ? (
                      getError
                    ) : (
                      "Add Elements for your profile"
                    )}
                  </div>
                )}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </UserEditProfileStyled>

      <ChooseProfileImage
        avatar={avatar}
        setAvatar={setAvatar}
        visitorBadgeId={visitorBadgeId}
        setVisitorBadgeId={setVisitorBadgeId}
        setBadge={setBadge}
        setShowProfilePicType={setShowProfilePicType}
        close={() => setShowChooseProfilePicModel(false)}
      />

      {type && selectedProfileElement && (
        <AddFeaturedContent
          type={type}
          selectedProfileElement={selectedProfileElement}
          setSelectedProfileElement={setSelectedProfileElement}
          addFeaturedContentModel={addFeaturedContentModel}
          close={() => setAddFeaturedContentModel(false)}
          open={addFeaturedContentModel}
        />
      )}
    </>
  );
};

export default UserEditProfile;

const UserEditProfileStyled = styled.div`
  .info {
    font-family: brother-1816, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1;
    text-transform: uppercase;
    padding: 2rem;
  }

  .desctext {
    width: 95%;
    background: #3a3a3c;
    border: 1px solid #3a3a3c;
    color: ${(props) => props.theme.primaryColor};
    padding: 0.5rem 1rem;
    margin-bottom: 1.2rem;
    border-radius: 0.5rem;
    height: 30vh;
  }

  .topSection {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    position: relative;
    height: 15rem;
    margin-bottom: 3.5rem;

    &__banner {
      height: 100%;
      width: 100%;
      position: relative;
      cursor: pointer;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        transition: all 0.5s ease;
        cursor: pointer;
      }

      .icon {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        height: 1.5rem;
        width: auto;
      }

      img {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
      }
    }

    &__avatar {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);

      cursor: pointer;
      height: 5.5rem;
      width: 5.5rem;
      border-radius: 50%;
      position: relative;
      margin-bottom: 0.5rem;

      .icon {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        height: 1.5rem;
        width: auto;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.3);
        transition: all 0.5s ease;
      }

      .imageBadge {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .badge {
          height: 5rem;
          width: auto;
        }
      }

      .imageAvatar {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  .metaInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;

    .username {
      font-size: 1.25rem;
      display: flex;
      align-items: center;
      font-weight: 400;
      cursor: pointer;
      line-height: 1;
      margin-bottom: 0.5rem;
    }

    .role {
      height: 22px;
      color: white;
      line-height: 8px;
      padding: 0.25rem 0.85rem;
      font-size: 12px;
      font-family: "brother-1816", sans-serif;
      text-transform: uppercase;
      letter-spacing: 4px;
      font-weight: 500;
      background: ${(props) => props.theme.primaryGradient} !important;
      border-radius: 4px;
      border: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .addSectionButton {
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;

    .btn {
      background-color: rgb(58, 58, 60);
      padding: 0.5rem 1rem;

      p {
        font-family: ${(props) => props.theme.montserrat};
        text-transform: capitalize;
        color: #fff;
        margin-left: 0.5rem;
        font-weight: 300;
      }
    }
  }

  .cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 1.5rem;

    .item {
      width: 100%;
      transition: all 0.2s ease;

      &:first-child {
        margin-bottom: 1.5rem;
      }

      &__section {
        width: 100%;
        padding: 1.2rem 1rem;
        border-radius: 0.5rem;
        background-color: #3a3a3c;
        cursor: pointer;
        color: #fff;
        font-family: ${(props) => props.theme.montserrat};
        font-weight: 400;
        line-height: 1.6;
        border: 3px solid transparent;
      }

      &__input {
        width: 100%;
        position: relative;

        .tickIcon {
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
          height: 2rem;
          width: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 2px solid #f88946;
          transition: all 0.2s ease;
          cursor: pointer;

          i {
            font-size: 1rem;
            color: #f88946;
            transition: all 0.2s ease;
          }

          &:hover {
            background-color: #f88946;

            i {
              color: #fff;
            }
          }
        }

        input {
          background-color: #3a3a3c;
          border: 3px solid transparent;
          outline: none;
          color: #fff;
          font-family: ${(props) => props.theme.montserrat};
          font-weight: 400;
          width: 100%;
          padding: 1.2rem 5rem 1.2rem 1rem;
          border-radius: 0.5rem;

          &::placeholder {
            color: #fff;
          }

          &:focus {
            border: 3px solid #f88946;
          }
        }
      }
    }
  }

  .dragList {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 80%;
      margin: 0 auto;
    }
    &__itemType {
      text-transform: capitalize;
      margin-left: 3rem;
    }
  }

  .dragItem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    transition: all 0.2s ease;

    &__icon {
      height: 3rem;
      width: 3.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .moveIcon img {
      width: 1.25rem;
      height: auto;
    }

    .deleteIcon img {
      width: 1.8rem;
      height: auto;
    }

    &__section {
      width: 100%;
      padding: 1.25rem 1rem;
      border-radius: 0.5rem;
      background-color: #3a3a3c;
      cursor: pointer;
      color: #fff;
      font-family: ${(props) => props.theme.montserrat};
      font-weight: 400;
      line-height: 1.6;
      border: 3px solid transparent;

      &--cardPlaceholder {
        width: 8rem;
        height: 8rem;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background-color: #2c2c2e;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          img {
            height: 1.6rem;
            width: auto;
          }
        }
      }
    }

    &__input {
      width: 100%;
      position: relative;

      .tickIcon {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        height: 2rem;
        width: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid #f88946;
        transition: all 0.2s ease;
        cursor: pointer;

        i {
          font-size: 1rem;
          color: #f88946;
          transition: all 0.2s ease;
        }

        &:hover {
          background-color: #f88946;

          i {
            color: #fff;
          }
        }
      }

      input {
        background-color: #3a3a3c;
        border: 3px solid transparent;
        outline: none;
        color: #fff;
        font-family: ${(props) => props.theme.montserrat};
        font-weight: 400;
        width: 100%;
        padding: 1.25rem 5rem 1.25rem 1rem;
        border-radius: 0.5rem;

        &::placeholder {
          color: #fff;
        }

        &:focus {
          border: 3px solid #f88946;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .topSection {
      height: 10rem;
      margin-bottom: 3rem;

      &__banner {
        .icon {
          height: 1.25rem;
        }
      }

      &__avatar {
        height: 5rem;
        width: 5rem;

        .icon {
          height: 1.25rem;
        }

        .imageBadge {
          .badge {
            height: 4.5rem;
          }
        }
      }
    }

    .dragList {
      &__container {
        width: 97%;
      }
    }

    .cards {
      width: 92%;

      .item {
        &__input {
          .tickIcon {
            height: 1.8rem;
            width: 1.8rem;

            i {
              font-size: 0.9rem;
            }
          }

          input {
            padding: 1.25rem 3rem 1.25rem 1rem;
          }
        }
      }
    }

    .addSectionButton {
      margin-bottom: 1rem;
    }

    .dragItem {
      margin-bottom: 1rem;

      &__icon {
        height: 2.5rem;
        width: 2.5rem;
      }

      .moveIcon img {
        width: 1.1rem;
      }

      .deleteIcon img {
        width: 1.3rem;
      }

      &__section {
        &--cardPlaceholder {
          width: 11rem;
          height: 7rem;

          .icon {
            img {
              height: 1.4rem;
              width: auto;
            }
          }
        }
      }

      &__input {
        .tickIcon {
          height: 1.8rem;
          width: 1.8rem;

          i {
            font-size: 0.9rem;
          }
        }

        input {
          padding: 1.25rem 3rem 1.25rem 1rem;
        }
      }
    }
  }
`;

const ItemStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:hover {
    background: ${(props) => props.theme.primaryGradient};
  }

  i {
    margin-right: 0.7rem;
  }

  img {
    height: 1rem;
    width: auto;
    margin-right: 0.7rem;
  }

  .text {
    text-transform: capitalize;
    font-size: 0.85rem;
  }
`;
