import React, { useContext, useState, useEffect } from "react";
import BackIcon from "../../../assets/Icons/back.svg";
import UploadIcon from "../../../assets/Icons/upload-icon-white.svg";
import PlayIcon from "../../../assets/Icons/play-white.svg";
import AddIcon from "../../../assets/Icons/add.svg";
import VideoCardSelect from "./VideoCardSelect";
import { uploadCover } from "../../../utils";
import { GlobalContext } from "../../../context/GlobalContext";
import { Col, Row } from "react-grid-system";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewSeries,
  addVideoToSeries,
  getSingleSeries,
  updateVideoV2,
  deleteVideoV2,
  deleteVideoS3,
  getUserVideos,
  removeVideoFromSeries,
  getUserStorageInfo,
  getVideoCategories,
} from "../../../actions";
import { notificationMessage } from "../../../utils/toastNotifications";
import { SeriesStyles } from "./SeriesStyles";
import Loader from "../../Elements/Loader";
import Error from "../../Elements/Error";
import EditClipForm from "../../UploadClipComponent/EditClipForm";
import ConfirmationModel from "../../ConfirmationModel";
import { VIDEO_DELETE_RESET, VIDEO_UPDATE_RESET } from "../../../actions/types";
import VideoCategory from "../../UploadClipComponent/VideoCategory";
import config from "../../../config";

const CreateSeriesModel = () => {
  const dispatch = useDispatch();

  const {
    showCreateSeriesModel,
    setShowCreateSeriesModel,
    setShowUploadClipModel,
    setVideoForSeriesUpload,
    setShowEditClipModel,
    setDeleteVideoConfirmationModel,
    deleteVideoConfirmationModel,
    setNewCategoryRequestModel,
  } = useContext(GlobalContext);
  const {
    videos: userVideos,
    loading: userVideosLoading,
    error: userVideosError,
  } = useSelector((state) => state.userVideos);
  const videoCategories = useSelector((state) => state.video.videoCategories);
  const profile = useSelector((state) => state.profile);

  const {
    info: createInfo,
    error: createError,
    loading: createLoading,
  } = useSelector((state) => state.createSeries);

  const { videoDeleteInfo } = useSelector((state) => state.video);

  const [selectedVideos, setSelectedVideos] = useState([]);
  const [step, setStep] = useState(0); // 0 -> select form, 1 -> videos
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState("");
  const [customThumbnail, setCustomThumbnail] = useState({
    url: "",
  });
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [defaultCategories, setDefaultCategories] = useState([]);

  useEffect(() => {
    dispatch(getVideoCategories());
  }, []);

  useEffect(() => {
    if (showCreateSeriesModel) {
      dispatch(getUserVideos({ showAll: true }, profile?.id));
    }

    // fetch user videos after delete and edit operation on video
    if (videoDeleteInfo) {
      dispatch(getUserVideos({ showAll: true }, profile?.id));

      // update storage information
      dispatch(getUserStorageInfo());

      dispatch({
        type: VIDEO_DELETE_RESET,
      });
    }
  }, [videoDeleteInfo, step, showCreateSeriesModel]);

  useEffect(() => {
    if (createError) {
      toast.error(notificationMessage.unexpectedServerError);
    }

    if (createInfo) {
      setStep(1);
      toast.success(notificationMessage.addSomeVideos);
    }
  }, [createError, createInfo]);

  useEffect(() => {
    if (step === 1) {
      dispatch(getSingleSeries(createInfo?.id));
    }
  }, [step]);

  // const selectVideoHandler = (item) => {
  //   if (selectedVideos && selectedVideos.length > 0) {
  //     const isAlreadyAdded = selectedVideos.filter((val) => val === item.id);

  //     if (isAlreadyAdded.length > 0) {
  //       const newArr = selectedVideos.filter((val) => val !== item.id);
  //       setSelectedVideos(newArr);

  //       return;
  //     }
  //   }

  //   setSelectedVideos([...selectedVideos, item]);
  // };
  const selectVideoHandler = (item) => {
    const isAlreadyAdded = selectedVideos.some(
      (video) => video.id === item.id && video.muxid === item.muxid
    );

    if (isAlreadyAdded) {
      const updatedSelectedVideos = selectedVideos.filter(
        (video) => video.id !== item.id || video.muxid !== item.muxid
      );
      setSelectedVideos(updatedSelectedVideos);
    } else {
      setSelectedVideos((prevSelectedVideos) => [...prevSelectedVideos, item]);
    }
  };

  const coverUploadHandler = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const { url } = await uploadCover("image", file);
      setCustomThumbnail({ url });
    }
  };

  const createNewSeriesHandler = () => {
    if (!title) {
      return toast.error(notificationMessage.enterSeriesTitle);
    }
    if (!customThumbnail.url) {
      return toast.error(notificationMessage.seriesThumbnail);
    }

    if (!selectedCategory || !selectedCategory.length) {
      return toast.error(notificationMessage.categoryNeededError);
    }

    const data = {
      title,
      price: price > 0 ? price : null,
      thumbnail: customThumbnail.url,
      description: desc,
      categoryList: selectedCategory,
    };

    dispatch(createNewSeries(data));

    setStep(1);
  };
  const addVideosToSeriesHandler = () => {
    if (!selectedVideos.length) {
      return toast.error(notificationMessage.selectVideoToAdd);
    }
    selectedVideos.forEach((item, index) => {
      console.log("item:", item);
      dispatch(
        addVideoToSeries(createInfo?.id, {
          videoId: item.id,
          muxid: item.muxid,
          order: index + 1,
        })
      );
      // also edit video after adding to the series
      dispatch(
        updateVideoV2(item, {
          keyVideoAccess: 4,
        })
      );
    });

    setSelectedVideos([]);
    setShowCreateSeriesModel(false);
    setTitle("");
    setDesc("");
    setPrice(null);
    setCustomThumbnail({
      url: "",
    });
    setStep(0);
  };

  const deleteVideoHandler = () => {
    const filenamesplit = selectedVideo.url.split("/");
    const videoname = filenamesplit[filenamesplit.length - 1];
    const thumbnailsplit = selectedVideo.thumbnail.split("/");
    const thumbnailname = thumbnailsplit[thumbnailsplit.length - 1];

    const removeObj = {
      videoId: selectedVideo.id,
    };

    dispatch(deleteVideoV2(selectedVideo.id));
    dispatch(deleteVideoS3(videoname));
    dispatch(deleteVideoS3(thumbnailname));
    dispatch(removeVideoFromSeries(createInfo?.id, removeObj));

    setDeleteVideoConfirmationModel(false);
  };

  return (
    <>
      <SeriesStyles
        className={`${showCreateSeriesModel && "createSeriesModelActive"}`}
      >
        <div className="header">
          <div className="left">
            <div
              onClick={() => setShowCreateSeriesModel(false)}
              className="backIcon"
            >
              <img src={BackIcon} alt="" />
            </div>
            <div className="name">
              {step === 0
                ? "Provide Series Information"
                : step === 1
                ? "Select Videos"
                : null}
            </div>
          </div>

          <div className="right">
            {step === 0 ? (
              <button
                onClick={() => {
                  createNewSeriesHandler();
                }}
                className="formState"
              >
                {createLoading ? "Creating..." : "next"}
              </button>
            ) : step === 1 ? (
              <button
                onClick={() => {
                  addVideosToSeriesHandler();
                }}
                className="formState"
              >
                save
              </button>
            ) : null}
          </div>
        </div>

        <div className="content">
          {step === 1 ? (
            <>
              <div className="content__uploadBox">
                <div className="icon">
                  <img src={UploadIcon} alt="" />
                </div>
                <div
                  onClick={() => {
                    setVideoForSeriesUpload({
                      series: createInfo,
                      showSeriesList: false,
                    });
                    setShowUploadClipModel(true);
                  }}
                  className="box"
                >
                  <div className="box__left">
                    <p className="boldText">Upload</p>
                    <p className="lightText">some videos for your series</p>
                  </div>

                  <div className="box__right">
                    <img src={PlayIcon} alt="" />
                  </div>
                </div>
              </div>

              <div className="infoBlock">
                <p className="lightText">or select from</p>
                <p className="boldText">Videos List</p>
              </div>

              <div className="content__videos">
                {userVideosLoading && (
                  <Loader text="Please wait while we fetch all videos for you" />
                )}

                {!userVideosLoading && !userVideos?.length && (
                  <Error text="No videos found. Upload Some!" />
                )}

                <Row className="content__videos--row">
                  {userVideos &&
                    userVideos.length > 0 &&
                    userVideos.map((item, i) => {
                      return (
                        <Col
                          key={i}
                          lg={3}
                          md={6}
                          sm={6}
                          xs={6}
                          className="content__videos--col"
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <VideoCardSelect
                            selectVideoHandler={selectVideoHandler}
                            item={item}
                            muxid={item.muxid}
                            isSelected={selectedVideos.includes(item.id)}
                            selectedVideos={selectedVideos}
                            setVideoId={setVideoId}
                            setSelectedVideo={setSelectedVideo}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </>
          ) : step === 0 ? (
            <form className="content__seriesForm">
              <div className="content__seriesForm--fieldGroup">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  id="title"
                  placeholder="Series title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="content__seriesForm--fieldGroup">
                <label htmlFor="desc">Description</label>
                <textarea
                  type="text"
                  id="desc"
                  placeholder="Series description"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </div>

              <div className="content__seriesForm--fieldGroup">
                <label htmlFor="price">Price</label>
                <input
                  type="number"
                  id="price"
                  placeholder="Series price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>

              <div className="content__seriesForm--fieldGroup">
                <div className="title">Choose category</div>
                <VideoCategory
                  videoCategories={videoCategories}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  defaultCategories={defaultCategories}
                />

                <button
                  onClick={() => setNewCategoryRequestModel(true)}
                  className="requestNewCategory"
                >
                  Request New Category
                </button>
              </div>

              <div className="content__seriesForm--fieldGroup">
                <div className="title">Cover</div>
                <label
                  htmlFor="addSeriesCover"
                  className="seriesUploadThumbnail"
                >
                  <img src={AddIcon} alt="Add Icon" className="icon" />
                </label>

                <input
                  style={{ display: "none" }}
                  id="addSeriesCover"
                  type="file"
                  accept="image/*"
                  onChange={coverUploadHandler}
                />

                {!!customThumbnail.url && (
                  <div className="uploadedCoverPreview">
                    <img
                      src={config.aws.CLOUDFRONT_URL + customThumbnail.url}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </form>
          ) : null}
        </div>
      </SeriesStyles>

      {selectedVideo && videoId && (
        <EditClipForm
          setSelectedVideo={setSelectedVideo}
          selectedVideo={selectedVideo}
          videoId={videoId}
          close={() => setShowEditClipModel(false)}
        />
      )}

      {selectedVideo && deleteVideoConfirmationModel && (
        <ConfirmationModel
          closeHandler={() => setDeleteVideoConfirmationModel(false)}
          title="Are you sure you want to delete this video ? You can't undo this action."
          deleteHandler={deleteVideoHandler}
        />
      )}
    </>
  );
};

export default CreateSeriesModel;
