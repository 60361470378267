import {
  GET_USER_VIDEOS_REQUEST,
  GET_USER_VIDEOS_SUCCESS,
  GET_USER_VIDEOS_FAIL,
  GET_USER_VIDEOS_RESET,
  GET_USER_VIDEOS_SUCCESS_SECOND
} from "../actions/types";

const userVideos = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_VIDEOS_REQUEST:
      return {
        loading: true,
      };
    case GET_USER_VIDEOS_SUCCESS:
      return {
        loading: false,
        totalpage: action.length,
        videos: action.payload,
      };
    case GET_USER_VIDEOS_SUCCESS_SECOND:
      const allVideos = [...state.videos, ...action.payload];
      return {
        loading: false,
        totalpage: action.length,
        videos: allVideos,
      };
    case GET_USER_VIDEOS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case GET_USER_VIDEOS_RESET:
      return {
        loading: false,
        error: null,
        videos: null,
      };
    default:
      return state;
  }
};

export default userVideos;
