import "react-toastify/dist/ReactToastify.css";
import React, { useContext, useEffect } from "react";
import GlobalStyle from "./styles/GlobalStyle";
import Router from "./Router";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import { darkThemeProvider } from "./styles/theme";
import { useSelector } from "react-redux";
import { GlobalContext } from "./context/GlobalContext";

const App = () => {
  const sidebar = useSelector((state) => state.sidebar);
  const { data: siteBranding } = useSelector((state) => state.branding);
  const { showTipPopups, siteTheme, setSiteTheme } = useContext(GlobalContext);

  useEffect(() => {
    const darkTheme = darkThemeProvider();
    setSiteTheme(darkTheme);
  }, []);

  useEffect(() => {
    if (siteBranding?.primaryGradient) {
      const regex = /#[0-9a-fA-F]{6}\b/g;
      const primaryColorsArr = siteBranding.primaryGradient.match(regex);

      setSiteTheme({
        ...siteTheme,
        button: siteBranding.primaryGradient,
        primaryGradient: siteBranding.primaryGradient,
        primaryColor1: primaryColorsArr[0],
        primaryColor2: primaryColorsArr[1],
      });
    }
  }, [siteBranding]);

  return (
    <ThemeProvider
      theme={{
        ...siteTheme,
      }}
    >
      <GlobalStyle />
      <ToastContainer
        autoClose={6300}
        position="top-right"
        closeButton={false}
        style={{ left: sidebar && showTipPopups && "14rem" }}
      />
      <Router />
    </ThemeProvider>
  );
};

export default App;
