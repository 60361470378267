import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Switch,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Typography,
  createStyles,
  IconButton,
} from "@material-ui/core";
import styled from "styled-components";
import {
  getNotificationCategories,
  getNotificationSettings,
  updateNotificationStatus,
} from "../actions";
import Loader from "./SeriesComponents/Loader";
import FormControlLabel from "@mui/material/FormControlLabel";
import InfoIcon from "@material-ui/icons/Info";
import InfoModal from "./MyAccountComponents/NotificationSettings/InfoModal";
import useOnclickOutside from "react-cool-onclickoutside";

const styles = createStyles({
  formControlLabel: {
    color: "white",
    fontSize: "0.7rem",
    fontWeight: 300,
  },
});

const NotificationSwitchButton = () => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const dispatch = useDispatch();
  const {
    notifications,
    userNotificationSettings,
    user,
    isLoading,
    isLoadingSettings,
  } = useSelector(
    ({ notificationCategory, userNotificationSettings, user }) => {
      return {
        notifications: notificationCategory.notificationData,
        isLoading: notificationCategory.isFetching,
        userNotificationSettings,
        isLoadingSettings: userNotificationSettings.isLoading,
        user,
      };
    }
  );

  useEffect(() => {
    dispatch(getNotificationCategories(user.userrole));
    dispatch(getNotificationSettings());
  }, [dispatch, notifications.length, user.userrole]);

  const setEmailNotificationValue = (notificationId) => {
    let emailNotification = false;
    if (userNotificationSettings && userNotificationSettings.data.length) {
      userNotificationSettings.data.forEach((element) => {
        if (element.categoryId === notificationId) {
          emailNotification = element.emailNotification;
        }
      });
    }
    return emailNotification;
  };
  // Bell notification state
  const setNotificationValue = (notificationId) => {
    let status = false;
    if (userNotificationSettings && userNotificationSettings.data.length) {
      userNotificationSettings.data.forEach((element) => {
        if (element.categoryId === notificationId) {
          status = element.status;
        }
      });
    }
    return status;
  };

  //Bell notification handler
  const handleEditNotificationStatus = async (
    notificationId,
    notificationStatus
  ) => {
    localStorage.setItem("notif_settings", "myaccount");
    const data = { status: notificationStatus };
    dispatch(
      updateNotificationStatus({
        categoryId: notificationId,
        data: data,
      })
    );
  };
  // Email Notification handler
  const handleEditEmaillNotification = async (
    notificationId,
    emailNotificationStatus
  ) => {
    const data = { emailNotification: emailNotificationStatus };
    dispatch(
      updateNotificationStatus({
        categoryId: notificationId,
        data: data,
      })
    );
  };
  const infoModalRef = useOnclickOutside(() => {
    setShowInfoModal(false);
  });
  const onhandleInfo = (item) => {
    setCurrentItem(item);
    setShowInfoModal(true);
  };
  const renderModel = useMemo(
    () => (
      <InfoModal
        item={currentItem}
        close={() => setShowInfoModal(false)}
        closeRef={infoModalRef}
      />
    ),
    [currentItem]
  );

  if (isLoading) {
    return <p style={{ marginLeft: "2rem" }}>Loading...</p>;
  }

  // console.log("notifications", notifications);
  // console.log("user notification settings", userNotificationSettings);
  return (
    <Wrapper>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell className="text-color">#</TableCell>
              <TableCell className="text-color">Notification</TableCell>
              <TableCell className="text-colors">on/off</TableCell>
            </TableRow>
          </TableHead>
          {/* ///align/// */}
          <TableBody>
            {notifications?.map((notification, index) => (
              <TableRow key={index} style={{ position: "relative" }}>
                <TableCell className="text-color">{index + 1}</TableCell>
                <TableCell className="text-color">
                  {notification.displayName
                    ? notification.displayName
                    : notification.name}
                  <span style={{ margin: "-5px" }}>
                    <IconButton
                      style={{ color: "#fff" }}
                      onClick={() => onhandleInfo(notification)}
                    >
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </span>
                </TableCell>
                <TableCell>
                  <div className="notification-controls">
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          checked={
                            isLoadingSettings
                              ? false
                              : setNotificationValue(notification.id)
                          }
                          onChange={(event) => {
                            handleEditNotificationStatus(
                              notification.id,
                              event.target.checked
                            );
                          }}
                          color="secondary"
                          name={`checked${index}`}
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      }
                      label={
                        <Typography style={styles.formControlLabel}>
                          Bell
                        </Typography>
                      }
                      labelPlacement="start"
                    />

                    <FormControlLabel
                      label={
                        <Typography style={styles.formControlLabel}>
                          Email
                        </Typography>
                      }
                      value="start"
                      control={
                        <Switch
                          checked={
                            isLoadingSettings
                              ? false
                              : setEmailNotificationValue(notification.id)
                          }
                          onChange={(event) => {
                            handleEditEmaillNotification(
                              notification.id,
                              event.target.checked,
                              notification.categoryId
                            );
                          }}
                        />
                      }
                      labelPlacement="start"
                    />
                  </div>
                </TableCell>
                {showInfoModal &&
                  currentItem &&
                  currentItem.id == notification.id &&
                  renderModel}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
};
export default React.memo(NotificationSwitchButton);
const Wrapper = styled.div`
  margin-top: 20px;
  padding: 0 4rem;
  .text-color {
    color: ${(props) => props.theme.white};
  }
  .text-colors {
    color: ${(props) => props.theme.white};
    padding-left: 5rem;
  }
  .MuiSwitch-track {
    background-color: ${(props) => props.theme.white};
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-image: ${(props) => props.theme.primaryGradient};
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    background-color: ${(props) => props.theme.primaryGradient};
  }
  .notification-controls {
    @media (max-width: 546px) {
      width: 118px;
      height: auto;
      display: flex;
      flex-direction: column;
    }
  }
  @media screen and (max-width: 546px) {
    padding: 0 1rem;
  }
`;
