import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  FaBell,
  FaChartBar,
  FaImages,
  FaPhotoVideo,
  FaRegIdBadge,
  FaShapes,
  FaTable,
  FaUserFriends,
  FaYoutube,
  FaBroom,
  FaBook,
  FaPager,
} from "react-icons/fa";
import { PiVideo, PiVideoCamera, PiVideoDuotone } from "react-icons/pi";
import { IoCaretForwardCircleOutline } from "react-icons/io5";
import { FiFilm } from "react-icons/fi";
import { MdCardMembership } from "react-icons/md";

const AdminSidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const history = useHistory();
  const [videoDropdown, setVideoDropdown] = useState(false);
  const handleSeriesDropdownClick = () => {
    setVideoDropdown(!videoDropdown);
  };

  return (
    <AdminStyled className={sidebarOpen ? "SidebarOpened" : ""}>
      <div className="menus">
        <div className="menus__item">
          <FaBroom size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/admin/site-branding");
            }}
          >
            <div className="name">Site Branding</div>
          </div>
          <div className="menus__item--right">
            <FaBroom
              size={22}
              style={{ alignItems: "center", marginTop: 9 }}
              onClick={() => {
                history.push("/admin/site-branding");
              }}
            />
          </div>
        </div>
        <div className="menus__item">
          <FaPager size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/admin/marketing-page");
            }}
          >
            <div className="name">Marketing Page</div>
          </div>
          <div className="menus__item--right">
            <FaPager
              size={22}
              style={{ alignItems: "center", marginTop: 9 }}
              onClick={() => {
                history.push("/admin/marketing-page");
              }}
            />
          </div>
        </div>

        <div className="menus__item">
          <MdCardMembership size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/admin/manage-membership");
            }}
          >
            <div className="name">Memberships</div>
          </div>
          <div className="menus__item--right">
            <MdCardMembership
              size={22}
              style={{ alignItems: "center", marginTop: 9 }}
              onClick={() => {
                history.push("/admin/manage-membership");
              }}
            />
          </div>
        </div>

        <div className="menus__item">
          <FaUserFriends size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/adminDashboard");
            }}
          >
            <div className="name">User Table</div>
          </div>
          <div className="menus__item--right">
            <FaUserFriends
              size={22}
              style={{ alignItems: "center", marginTop: 9 }}
              onClick={() => {
                history.push("/adminDashboard");
              }}
            />
          </div>
        </div>

        <div className="menus__item">
          <FaBook size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/admin/featured");
            }}
          >
            <div className="name">Featured Content</div>
          </div>
          <div className="menus__item--right">
            <FaBook
              size={22}
              style={{ alignItems: "center", marginTop: 9 }}
              onClick={() => {
                history.push("/admin/featured");
              }}
            />
          </div>
        </div>

        <div className="menus__item">
          <PiVideo size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/videoTable");
            }}
          >
            <div className="name">Video Table</div>
          </div>
          <div
            className="menus__item--right"
            onClick={() => {
              history.push("/videoTable");
            }}
          >
            <PiVideo size={25} style={{ alignItems: "center", marginTop: 9 }} />
          </div>
        </div>
        <div className="menus__item">
          <IoCaretForwardCircleOutline size={20} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/momentTable");
            }}
          >
            <div className="name">Moment Table</div>
          </div>
          <div
            className="menus__item--right"
            onClick={() => {
              history.push("/momentTable");
            }}
          >
            <IoCaretForwardCircleOutline
              size={25}
              style={{ alignItems: "center", marginTop: 9 }}
            />
          </div>
        </div>
        <div className="menus__item">
          <FaTable size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/seriesTable");
            }}
          >
            <div className="name">Series Table</div>
          </div>
          <div
            className="menus__item--right"
            onClick={() => {
              history.push("/seriesTable");
            }}
          >
            <FaTable size={25} style={{ alignItems: "center", marginTop: 9 }} />
          </div>
        </div>
        <div className="menus__item">
          <FaYoutube size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/seriesVideoTable");
            }}
          >
            <div className="name">Series Video </div>
          </div>
          <div
            className="menus__item--right"
            onClick={() => {
              history.push("/seriesVideoTable");
            }}
          >
            <FaYoutube
              size={25}
              style={{ alignItems: "center", marginTop: 9 }}
            />
          </div>
        </div>
        <div className="menus__item">
          <FaBell size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/notificationTypes");
            }}
          >
            <div className="name">Notification Types</div>
          </div>
          <div
            className="menus__item--right"
            onClick={() => {
              history.push("/notificationTypes");
            }}
          >
            <FaBell size={25} style={{ alignItems: "center", marginTop: 9 }} />
          </div>
        </div>
        <div className="menus__item">
          <FaShapes size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/notificationCategory");
            }}
          >
            <div className="name">Notification Category</div>
          </div>
          <div
            className="menus__item--right"
            onClick={() => {
              history.push("/notificationCategory");
            }}
          >
            <FaShapes
              size={25}
              style={{ alignItems: "center", marginTop: 9 }}
            />
          </div>
        </div>
        <div className="menus__item" onClick={handleSeriesDropdownClick}>
          <FiFilm size={18} />
          <div className="menus__item--left">
            <div className="name">Video Categories</div>
          </div>
          <div className="menus__item--right">
            <FiFilm size={25} style={{ alignItems: "center", marginTop: 9 }} />
          </div>
        </div>

        {/* Video dropedoemn list  */}
        {videoDropdown && (
          <div className="menus__dropdown">
            <div
              onClick={() => {
                history.push("/VideoCategories");
              }}
              className="menus__dropdown--item"
            >
              <div className="menus__item--left">
                <div className="hoverArrow"></div>
                <div className="name">Video category</div>
              </div>
              <div className="menus__item--right">
                <FaPhotoVideo
                  size={25}
                  style={{
                    alignItems: "center",
                    marginTop: 9,
                    marginLeft: "-10px",
                  }}
                />
              </div>
            </div>
            <div
              onClick={() => {
                history.push("/VideoSubCategories");
              }}
              className="menus__dropdown--item"
            >
              <div className="menus__item--left">
                <div className="hoverArrow"></div>
                <div className="name">Video Sub Category</div>
              </div>
              <div className="menus__item--right">
                <FaYoutube
                  size={25}
                  style={{
                    alignItems: "center",
                    marginTop: 9,
                    marginLeft: "-10px",
                  }}
                />
              </div>
            </div>
            <div
              onClick={() => {
                history.push("/SubCategoryOne");
              }}
              className="menus__dropdown--item"
            >
              <div className="menus__item--left">
                <div className="hoverArrow"></div>
                <div className="name">Sub Category one </div>
              </div>
              <div className="menus__item--right">
                <PiVideoCamera
                  size={25}
                  style={{
                    alignItems: "center",
                    marginTop: 9,
                    marginLeft: "-10px",
                  }}
                />
              </div>
            </div>
            <div
              className="menus__dropdown--item"
              onClick={() => {
                history.push("/SubCategoryTwo");
              }}
            >
              <div className="menus__item--left">
                <div className="hoverArrow"></div>
                <div className="name">Sub Category two</div>
              </div>
              <div className="menus__item--right">
                <FaPhotoVideo
                  size={25}
                  style={{
                    alignItems: "center",
                    marginTop: 9,
                    marginLeft: "-10px",
                  }}
                />
              </div>
            </div>
            <div
              className="menus__dropdown--item"
              onClick={() => {
                history.push("/VideoCategoryRequest");
              }}
            >
              <div className="menus__item--left">
                <div className="hoverArrow"></div>
                <div className="name">Video Category Request</div>
              </div>
              <div className="menus__item--right">
                <PiVideoDuotone
                  size={25}
                  style={{
                    alignItems: "center",
                    marginTop: 9,
                    marginLeft: "-10px",
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <div className="menus__item">
          <FaChartBar size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/threadsTable");
            }}
          >
            <div className="name">Threads </div>
          </div>
          <div
            className="menus__item--right"
            onClick={() => {
              history.push("/threadsTable");
            }}
          >
            <FaChartBar
              size={25}
              style={{ alignItems: "center", marginTop: 9 }}
            />
          </div>
        </div>
        <div className="menus__item">
          <FaImages size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/threadsImages");
            }}
          >
            <div className="name">Threads Images</div>
          </div>
          <div
            className="menus__item--right"
            onClick={() => {
              history.push("/threadsImages");
            }}
          >
            <FaImages
              size={25}
              style={{ alignItems: "center", marginTop: 9 }}
            />
          </div>
        </div>
        <div className="menus__item">
          <FaRegIdBadge size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/vistorBadgeTable");
            }}
          >
            <div className="name">Vistor Badge</div>
          </div>
          <div
            className="menus__item--right"
            onClick={() => {
              history.push("/vistorBadgeTable");
            }}
          >
            <FaRegIdBadge
              size={25}
              style={{ alignItems: "center", marginTop: 9 }}
            />
          </div>
        </div>
        <div className="menus__item">
          <PiVideoDuotone size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/videoAccesOverlayTable");
            }}
          >
            <div className="name">Video Access Overlay</div>
          </div>
          <div
            className="menus__item--right"
            onClick={() => {
              history.push("/videoAccesOverlayTable");
            }}
          >
            <PiVideoDuotone
              size={25}
              style={{ alignItems: "center", marginTop: 9 }}
            />
          </div>
        </div>
        <div className="menus__item">
          <PiVideoDuotone size={18} />
          <div
            className="menus__item--left"
            onClick={() => {
              history.push("/widAdminBanner");
            }}
          >
            <div className="name">Banner</div>
          </div>
          <div
            className="menus__item--right"
            onClick={() => {
              history.push("/widAdminBanner");
            }}
          >
            <PiVideoDuotone
              size={25}
              style={{ alignItems: "center", marginTop: 9 }}
            />
          </div>
        </div>
      </div>
    </AdminStyled>
  );
};

export default AdminSidebar;

const AdminStyled = styled.div`
  top: 0;
  left: 0;
  height: auto;
  width: 250px;
  background: #2c2c2e;
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 1.5rem;
  transition: all 0.3s;
  //   z-index: 1000;
  padding: 2rem 0 1rem 0;

  @media (max-width: 768px) {
    width: 15%;
    transform: translateX(0);
    .menus__item--left {
      display: none;
    }
    .menus__item--left .name {
      display: none;
    }
    .menus__item--right {
      display: block !important;
    }
    .inputBox {
      width: 262%;
    }
  }
  .menus__item--right {
    display: none;
  }
  @media (max-width: 768px) {
    .menus__item {
      justify-content: center;
    }
  }
  .menus {
    width: 90%;
    margin: 0 auto 5rem auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__dropdown {
      background-color: #3a3a3c;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      animation: 0.2s ease;
      transition: all 0.2s ease;

      padding: 1rem;
      width: 100%;
      border-radius: 0.3rem;
      margin-bottom: 1rem;

      &--loader {
        font-size: 1rem;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1;
        font-family: brother-1816, sans-serif;
        color: rgb(246, 92, 139);
      }

      &--item:not(:last-child) {
        margin-bottom: 1rem;
      }

      &--item {
        display: flex;
        align-items: center;
        cursor: pointer;

        .name {
          font-size: 0.8rem;
          font-weight: 500;
          text-transform: capitalize;
          line-height: 1;
        }

        .hoverArrow {
          height: 4px;
          border-radius: 2px;
          width: 0;
          background: transparent
            linear-gradient(
              130deg,
              var(--profile-icon-bg) 14%,
              #f88946 23%,
              #f8795f 37%,
              #f75e87 55%,
              #f75b8c 57%
            )
            0% 0% no-repeat padding-box;
          background: transparent
            linear-gradient(
              130deg,
              #f9903d 14%,
              #f88946 23%,
              #f8795f 37%,
              #f75e87 55%,
              #f75b8c 57%
            )
            0% 0% no-repeat padding-box;
          margin-right: 0.25rem;
          transition: all 0.2s ease;
        }
      }

      &--item:hover .hoverArrow {
        width: 1.5rem;
        margin-right: 0.5rem;
      }
    }

    &__item {
      margin-bottom: 1rem;
      background-color: #3a3a3c;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      height: 2.8rem;
      padding: 0 1rem;
      width: 100%;
      border-radius: 0.3rem;
      cursor: pointer;

      &--left {
        display: flex;
        align-items: center;

        .icon {
          height: 1.2rem;
          width: auto;
          margin-right: 0.6rem;
        }

        .name {
          font-size: 12px;
          font-weight: 500;
          text-transform: capitalize;
          margin-left: 15px;
        }
      }

      &--right {
        .arrow {
          height: 0.8rem;
          width: auto;
          transition: all 0.2s ease;
        }
      }
    }
  }
`;
