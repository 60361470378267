import React, { useEffect, useState, useContext } from "react";
import styled, { keyframes } from "styled-components";
import HomeOutlineIcon from "./icons/homeOutline.svg";
import SearchOutlineIcon from "./icons/searchOutline.svg";
import SearchFilledIcon from "./icons/searchFilled.svg";
import StreamOutlineIcon from "./icons/streamOutline.svg";
import LibraryOutlineIcon from "./icons/libraryOutline.svg";
import LibraryFilledIcon from "./icons/libraryFilled.svg";
import HomeFilledIcon from "./icons/homeFilled.svg";
import StreamFilledIcon from "./icons/streamFilled.svg";
import NewUploadModel from "../../NewUploadModel/NewUploadModel";
import UploadContentIcon from "../../icons/upload.svg";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import { toast } from "react-toastify";
import PlaylistModal from "../../NewUploadModel/PlaylistModal";
import { getUserStorageInfo } from "../../../actions";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
      type: "spring",
      stiffness: 260,
      damping: 20,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const BottomBar = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserStorageInfo());
  }, []);
  const [percentage, setPercentage] = useState(null);
  const [packageSize, setPackageSize] = useState(null);
  const [remaining, setRemaining] = useState(null);
  const { setShowManageSubscription } = useContext(GlobalContext);
  const [isStorageFull, setIsStorageFull] = useState(false);

  const { loading, storageInfo } = useSelector((state) => state.userStorage);

  useEffect(() => {
    if (storageInfo) {
      if (
        (storageInfo.usedVideoLength || storageInfo.usedVideoLength === 0) &&
        storageInfo.totalFileSize
      ) {
        const videoSizeInMins =
          (parseFloat(storageInfo.usedVideoLength / 60) * 100) /
          storageInfo.totalFileSize;

        setPercentage(+videoSizeInMins.toFixed(2));
        setPackageSize(storageInfo.totalFileSize);
        setRemaining(parseInt(storageInfo.usedVideoLength / 60));
      }
    }
  }, [storageInfo]);
  const user = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState(null);

  const {
    showUploadModel,
    setShowUploadModel,
    setShowSearchModel,
    showGlobalPlaylistModel,
    setShowGlobalPlaylistModel,
    showSearchModel,
    setShowMomentDetailsModel,
    setShowAuthenticationModel,
  } = useContext(GlobalContext);
  const myError = () => {
    toast.error(
      "Your storage is full! Upgrade your data plan under My Account or remove some content from your channel to continue posting."
    );
  };
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/home" || location.pathname === "/") {
      setActiveTab("HOME");
    } else if (location.pathname === "/search") {
      setActiveTab("SEARCH");
    } else if (location.pathname === "/streams") {
      setActiveTab("STREAM");
    } else if (location.pathname === "/library") {
      setActiveTab("LIBRARY");
    }
  }, [location.pathname]);

  const SetTostForLogin = () => {
    document.getElementById("user-name").focus();
    setShowAuthenticationModel(true);
  };

  const handleLibraryClick = () => {
    setShowSearchModel(false);
    setShowUploadModel(false);

    if (user.userrole === 0) {
      return toast.error(
        "You must be a Tribe or Warrior to access the iSutra Library, Tribe Co-op Membership is only $5/Year, thats a cup of coffee! Upgrade your account now to access the Library"
      );
    }
    if (user.username) {
      history.push("/library");
      setActiveTab("LIBRARY");
      toast.dismiss();
    } else {
      SetTostForLogin();
    }
  };
  const handleUploadClick = () => {
    if (!user?.id) {
      return SetTostForLogin();
    }

    if (isStorageFull) {
      myError();
    } else {
      setShowUploadModel(true);
      setShowSearchModel(false);
    }
  };
  useEffect(() => {
    if (percentage >= 100) {
      setIsStorageFull(true);
    } else {
      setIsStorageFull(false);
    }
  }, [percentage]);

  return (
    <>
      <BottomBarStyled>
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
          className="menus"
        >
          <motion.div
            onClick={() => {
              setShowSearchModel(false);
              setShowUploadModel(false);

              if (user.username) {
                history.push("/home");
                setShowSearchModel(false);
                setActiveTab("HOME");
                toast.dismiss();
              } else {
                history.push("/");
                setActiveTab("HOME");
                setShowMomentDetailsModel(false);
                toast.dismiss();
              }
            }}
            variants={item}
            className={`menus__item homeItem active ${
              (history.location.pathname === "/home" ||
                history.location.pathname === "/") &&
              !showSearchModel &&
              "active"
            }`}
          >
            <div className="icon">
              <img
                src={activeTab === "HOME" ? HomeFilledIcon : HomeOutlineIcon}
                alt=""
              />
            </div>
            <div className="name">Home</div>
          </motion.div>
          <motion.div
            onClick={() => {
              if (user?.id) {
                setShowUploadModel(false);
                setShowSearchModel(!showSearchModel);
                setActiveTab("SEARCH");
                toast.dismiss();
              } else {
                SetTostForLogin();
              }
            }}
            variants={item}
            className={`menus__item searchItem ${showSearchModel && "active"}`}
          >
            <div className="icon">
              <img
                src={showSearchModel ? SearchFilledIcon : SearchOutlineIcon}
                alt=""
              />
            </div>
            <div className="name">Search</div>
          </motion.div>
          {isStorageFull ? (
            <motion.div
              className="uploadItem disabled"
              style={{ opacity: "0.5", cursor: "not-allowed" }}
              onClick={myError}
            >
              <img src={UploadContentIcon} alt="" className="icon" />
            </motion.div>
          ) : (
            <motion.div onClick={handleUploadClick} className="uploadItem ">
              <img src={UploadContentIcon} alt="" className="icon" />
            </motion.div>
          )}

          <motion.div
            onClick={() => handleLibraryClick()}
            variants={item}
            className={`menus__item libraryItem ${
              history.location.pathname === "/library" && "active"
            } ${user?.userrole === 0 && "locked"}`}
          >
            <div className="icon">
              <img
                src={
                  activeTab === "LIBRARY"
                    ? LibraryFilledIcon
                    : LibraryOutlineIcon
                }
                alt=""
              />
            </div>
            <div className="name">Library</div>
          </motion.div>
          <motion.div
            onClick={() => {
              setShowSearchModel(false);
              setShowUploadModel(false);

              if (user.username) {
                history.push("/streams");
                setActiveTab("STREAM");
                toast.dismiss();
              } else {
                SetTostForLogin();
              }
            }}
            variants={item}
            className={`menus__item streamItem active ${
              history.location.pathname === "/streams" &&
              "active" &&
              toast.dismiss()
            }`}
          >
            <div className="icon">
              <img
                src={
                  activeTab === "STREAM" ? StreamFilledIcon : StreamOutlineIcon
                }
                alt=""
              />
            </div>
            <div className="name">Streams</div>
          </motion.div>
        </motion.div>
      </BottomBarStyled>

      <NewUploadModel
        show={showUploadModel}
        close={() => setShowUploadModel(false)}
      />
      <PlaylistModal
        show={showGlobalPlaylistModel}
        close={() => setShowGlobalPlaylistModel(false)}
      />
    </>
  );
};

export default BottomBar;

const backgroundPan = keyframes`
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
`;

const BottomBarStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 3.5rem;
  z-index: 102;
  background-color: #212123;
  border-top: 1px solid rgba(112, 112, 112, 0.5);

  .locked {
    cursor: not-allowed !important;
  }

  .menus {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin: 0 auto;

    &__item {
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;

      .icon {
        margin: 0;
        padding: 0;
        transform: translateY(5px);

        img {
          height: 1.1rem;
          width: auto;
        }
      }

      .name {
        font-family: ${(props) => props.theme.montserrat};
        line-height: 1;
        font-size: 0.7rem;
        font-weight: lighter;
        letter-spacing: 0.1px;
        font-family: brother-1816, sans-serif;
        text-transform: capitalize;
      }
    }

    .active .name {
      font-weight: 400;
      background: linear-gradient(
        to right,
        ${(props) => props.theme.primaryColor1},
        ${(props) => props.theme.primaryColor2},
        ${(props) => props.theme.primaryColor1}
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: brother-1816, sans-serif;
      text-transform: capitalize;
      letter-spacing: 0.1px;
      animation: ${backgroundPan} 3s linear infinite;
      background-size: 200%;
    }

    .uploadItem {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.35rem 1.4rem;
      border-radius: 10rem;
      background: ${(props) => props.theme.primaryGradient};

      .icon {
        height: 1rem;
        width: auto;
      }
    }
  }

  @media screen and (max-width: 375px) {
    .menus {
      width: 95%;

      &__item {
        img {
          width: 1.1rem;
        }
      }

      .name {
        font-size: 0.7rem;
      }

      .uploadItem {
        padding: 0.4rem 1.2rem;

        .icon {
          height: 0.75rem;
        }
      }
    }
  }
`;
