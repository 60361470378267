import {
  CREATE_THREAD_REQUEST,
  CREATE_THREAD_SUCCESS,
  CREATE_THREAD_FAIL,
  GET_ALL_THREADS_REQUEST,
  GET_ALL_THREADS_SUCCESS,
  GET_ALL_THREADS_FAIL,
  DELETE_THREAD_REQUEST,
  DELETE_THREAD_SUCCESS,
  DELETE_THREAD_FAIL,
  GET_SINGLE_THREAD_REQUEST,
  GET_SINGLE_THREAD_SUCCESS,
  GET_SINGLE_THREAD_FAIL,
  GET_HOME_FEATURED_THREADS_REQUEST,
  GET_HOME_FEATURED_THREADS_SUCCESS,
  GET_HOME_FEATURED_THREADS_FAIL,
  GET_SINGLE_THREAD_REQUEST_V2,
  GET_SINGLE_THREAD_SUCCESS_V2,
  GET_SINGLE_THREAD_FAIL_V2,
  UPDATE_THREAD_REQUEST,
  UPDATE_THREAD_SUCCESS,
  UPDATE_THREAD_FAIL,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAIL,
  REPLY_COMMENT_REQUEST,
  REPLY_COMMENT_SUCCESS,
  REPLY_COMMENT_FAIL,
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAIL,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAIL,
  UPDATE_COMMENT_REQUEST,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAIL,
  DELETE_REPLY_COMMENT_REQUEST,
  DELETE_REPLY_COMMENT_SUCCESS,
  DELETE_REPLY_COMMENT_FAIL,
  REPLY_UPDATE_COMMENT_REQUEST,
  REPLY_UPDATE_COMMENT_SUCCESS,
  REPLY_UPDATE_COMMENT_FAIL,
  GET_THREADS_OF_USER_REQUEST,
  GET_THREADS_OF_USER_SUCCESS,
  GET_THREADS_OF_USER_FAIL,
  GET_STREAMS_THREADS_REQUEST,
  GET_STREAMS_THREADS_SUCCESS,
  GET_STREAMS_THREADS_FAIL,
  GET_LIKED_THREADS_REQUEST,
  GET_LIKED_THREADS_SUCCESS,
  GET_LIKED_THREADS_FAIL,
  CLEAR_THREADS,
} from "./type";
import api from "../../services/api";
import { toast } from "react-toastify";

export const createThread =
  (threadObj, attachedObj, type, users, fileSize) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_THREAD_REQUEST,
      });

      api
        .post(`thread`, threadObj)
        .then((threadRes) => {
          if (type === "imageThread") {

            const data = {
              url: attachedObj.image,
              fileSize: fileSize.size
            };

            api.post(`thread/${threadRes.data.data.id}/image`, data);
          }

          if (type === "sliderThread") {
            const data = {
              slider: attachedObj.sliderImages,
            };

            api.post(`thread/${threadRes.data.data.id}/image`, data);
          }

          if (type === "contentThread") {
            const data = {
              contentId: attachedObj.contentId,
              contentType: attachedObj.contentType,
            };

            api.post(`thread/${threadRes.data.data.id}/content`, data);
          }

          return threadRes;
        })
        .then((threadRes) => {
          api.post(`thread/${threadRes.data.data.id}/tagUser`, users);

          return threadRes;
        })
        .then((res) => {
          dispatch({
            type: CREATE_THREAD_SUCCESS,
            payload: res.data.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: CREATE_THREAD_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          });
        });
    } catch (error) {
      dispatch({
        type: CREATE_THREAD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createThreadV2 = (threadObj, users) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_THREAD_REQUEST,
    });

    api
      .post(`thread`, threadObj)
      .then((threadRes) => {
        api.post(`thread/${threadRes.data.data.id}/tagUser`, users);

        return threadRes;
      })
      .then((res) => {
        dispatch({
          type: CREATE_THREAD_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_THREAD_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      });
  } catch (error) {
    dispatch({
      type: CREATE_THREAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getThreads =
  (page = 1, limit = 1, selectedCategory = []) =>
    async (dispatch) => {
      let selectedCategoryStr;
      if (selectedCategory?.length) {
        selectedCategoryStr = encodeURIComponent(
          JSON.stringify(selectedCategory)
        );
      }
      try {
        dispatch({
          type: GET_ALL_THREADS_REQUEST,
        });
        let query = `thread?page=${page}&limit=${limit}`;
        if (selectedCategory?.length) {
          query = query + `&categoryList=${selectedCategoryStr}`;
        }
        const res = await api.get(query);
        dispatch({
          type: GET_ALL_THREADS_SUCCESS,
          payload: {
            threads: res.data.data.threads,
            hasMore: res.data.data.hasMore,
            isFiltering: res.data.data.filtering
          },
        });
      } catch (error) {
        dispatch({
          type: GET_ALL_THREADS_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

export const clearThreads = () => (dispatch) => {
  dispatch({
    type: CLEAR_THREADS
  });
}

export const deleteThread = (threadId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_THREAD_REQUEST,
    });

    const res = await api.delete(`thread/${threadId}`);

    dispatch({
      type: DELETE_THREAD_SUCCESS,
      payload: threadId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_THREAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getThread = (threadId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SINGLE_THREAD_REQUEST,
    });

    const res = await api.get(`thread/${threadId}`);

    dispatch({
      type: GET_SINGLE_THREAD_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_THREAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getThreadsGuest =
  (page = 1, limit = 1) =>
    async (dispatch) => {
      try {
        dispatch({
          type: GET_ALL_THREADS_REQUEST,
        });

        const res = await api.get(`thread/guest?page=${page}&limit=${limit}`);

        dispatch({
          type: GET_ALL_THREADS_SUCCESS,
          payload: {
            threads: res.data.data.threads,
            hasMore: res.data.data.hasMore,
          },
        });
      } catch (error) {
        dispatch({
          type: GET_ALL_THREADS_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

export const getThreadGuest = (threadId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SINGLE_THREAD_REQUEST,
    });

    const res = await api.get(`thread/${threadId}/guest`);

    dispatch({
      type: GET_SINGLE_THREAD_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_THREAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getHomeFeaturedThreads =
  (selectedCategory) => async (dispatch) => {
    try {
      let selectedCategoryStr;
      if (selectedCategory?.length) {
        selectedCategoryStr = encodeURIComponent(
          JSON.stringify(selectedCategory)
        );
      }
      dispatch({
        type: GET_HOME_FEATURED_THREADS_REQUEST,
      });

      let query = `admin/thread/featured`;
      if (selectedCategory?.length) {
        query = query + `?categoryList=${selectedCategoryStr}`;
      }

      const res = await api.get(query);

      dispatch({
        type: GET_HOME_FEATURED_THREADS_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_HOME_FEATURED_THREADS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getHomeFeaturedThreadsGuest =
  (selectedCategory) => async (dispatch) => {
    try {
      let selectedCategoryStr;
      if (selectedCategory?.length) {
        selectedCategoryStr = encodeURIComponent(
          JSON.stringify(selectedCategory)
        );
      }

      dispatch({
        type: GET_HOME_FEATURED_THREADS_REQUEST,
      });
      let query = `admin/thread/featured/guest`;
      if (selectedCategory?.length) {
        query = query + `?categoryList=${selectedCategoryStr}`;
      }

      const res = await api.get(query);

      dispatch({
        type: GET_HOME_FEATURED_THREADS_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_HOME_FEATURED_THREADS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getThreadV2 = (threadId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SINGLE_THREAD_REQUEST_V2,
    });

    const res = await api.get(`thread/${threadId}`);

    dispatch({
      type: GET_SINGLE_THREAD_SUCCESS_V2,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_THREAD_FAIL_V2,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateThread =
  (threadObj, threadId, users) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_THREAD_REQUEST,
      });

      api
        .patch(`thread/${threadId}`, threadObj)
        .then((threadRes) => {
          api.post(`thread/${threadId}/tagUser`, users);

          return threadRes;
        })
        .then((res) => {
          dispatch({
            type: UPDATE_THREAD_SUCCESS,
            payload: res.data.message,
          });
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_THREAD_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_THREAD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateThreadV2 =
  (threadObj, threadId, attachedObj, type, users) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_THREAD_REQUEST,
      });

      api
        .patch(`thread/${threadId}`, threadObj)
        .then((threadRes) => {
          if (type === "imageThread") {
            const data = {
              url: attachedObj.image,
            };

            api.post(`thread/${threadId}/image`, data);
          }

          if (type === "sliderThread") {
            const data = {
              slider: attachedObj.sliderImages,
            };

            api.post(`thread/${threadId}/image`, data);
          }

          if (type === "contentThread") {
            const data = {
              contentId: attachedObj.contentId,
              contentType: attachedObj.contentType,
            };

            api.post(`thread/${threadId}/content`, data);
          }

          return threadRes;
        })
        .then((threadRes) => {
          api.post(`thread/${threadId}/tagUser`, users);

          return threadRes;
        })
        .then((res) => {
          dispatch({
            type: UPDATE_THREAD_SUCCESS,
            payload: res.data.message,
          });
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_THREAD_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_THREAD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteImageAttachment = (images, threadId) => async (dispatch) => {
  try {
    await api.patch(`thread/${threadId}/image`, images);
  } catch (error) {
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const deleteContentAttachment = (attachmentId) => async (dispatch) => {
  try {
    await api.delete(`thread/content/${attachmentId}`);
  } catch (error) {
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const addComment = (threadId, data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_COMMENT_REQUEST,
    });
    const res = await api.post(`thread/${threadId}/comment`, data);

    dispatch({
      type: ADD_COMMENT_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: ADD_COMMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const replyComment = (threadId, data) => async (dispatch) => {
  try {
    dispatch({
      type: REPLY_COMMENT_REQUEST,
    });
    const res = await api.post(`thread/${threadId}/comment`, data);

    dispatch({
      type: REPLY_COMMENT_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: REPLY_COMMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getComments = (threadId, paramsObj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMMENTS_REQUEST,
    });
    const res = await api.get(
      `thread/${threadId}/comment?page=${paramsObj?.page}&limit=${paramsObj?.limit}&replyPage=${paramsObj?.replyPage}&replyLimit=${paramsObj?.replyLimit}`
    );
    dispatch({
      type: GET_COMMENTS_SUCCESS,
      payload: {
        comments: res.data.data.comments,
        commentsHasMore: res.data.data.hasMore,
      },
    });
  } catch (error) {
    dispatch({
      type: GET_COMMENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteComment = (threadId, commentId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_COMMENT_REQUEST,
    });

    await api.delete(`thread/${threadId}/comment/${commentId}`);

    dispatch({
      type: DELETE_COMMENT_SUCCESS,
      payload: commentId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_COMMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteReplyComment = (threadId, commentId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_REPLY_COMMENT_REQUEST,
    });

    await api.delete(`thread/${threadId}/comment/${commentId}`);

    dispatch({
      type: DELETE_REPLY_COMMENT_SUCCESS,
      payload: commentId,
    });
  } catch (error) {
    dispatch({
      type: DELETE_REPLY_COMMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateComment =
  (threadId, commentId, data) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_COMMENT_REQUEST,
      });
      const res = await api.patch(
        `thread/${threadId}/comment/${commentId}`,
        data
      );
      dispatch({
        type: UPDATE_COMMENT_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_COMMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const replyUpdateComment =
  (threadId, commentId, data) => async (dispatch) => {
    try {
      dispatch({
        type: REPLY_UPDATE_COMMENT_REQUEST,
      });
      const res = await api.patch(
        `thread/${threadId}/comment/${commentId}`,
        data
      );
      dispatch({
        type: REPLY_UPDATE_COMMENT_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: REPLY_UPDATE_COMMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getThreadsOfUser =
  (username, page = 1, limit = 10) =>
    async (dispatch) => {
      try {
        dispatch({
          type: GET_THREADS_OF_USER_REQUEST,
        });

        const res = await api.get(
          `thread/userThreads/${username}?page=${page}&limit=${limit}`
        );

        dispatch({
          type: GET_THREADS_OF_USER_SUCCESS,
          payload: {
            threads: res.data.data.threads,
            hasMore: res.data.data.hasMore,
          },
        });
      } catch (error) {
        dispatch({
          type: GET_THREADS_OF_USER_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

export const getStreamsThreads =
  (page = 1, limit = 10, selectedCategory = []) =>
    async (dispatch) => {

      let selectedCategoryStr;
      if (selectedCategory?.length) {
        selectedCategoryStr = encodeURIComponent(
          JSON.stringify(selectedCategory)
        );
      }

      try {
        dispatch({
          type: GET_STREAMS_THREADS_REQUEST,
        });
        let query = `subscriptionPay/getStreamsThreads?page=${page}&limit=${limit}`;
        if (selectedCategory?.length) {
          query = query + `&categoryList=${selectedCategoryStr}`;
        }
        const res = await api.post(query);


        dispatch({
          type: GET_STREAMS_THREADS_SUCCESS,
          payload: {
            threads: res.data.data.threads,
            hasMore: res.data.data.hasMore,
            isFiltering: res.data.data.filtering
          },
        });
      } catch (error) {
        dispatch({
          type: GET_STREAMS_THREADS_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

export const getLikedThreads = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_LIKED_THREADS_REQUEST,
    });

    const res = await api.get(`thread/liked-threads`);

    dispatch({
      type: GET_LIKED_THREADS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_LIKED_THREADS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const rewardVideo = (rewardAmount, videoId) => async (dispatch) => {
  try {
    const data = {
      rewardAmount: rewardAmount
    }
    const res = await api.post(`videos/rewardVideo/${videoId}`, data)

    return res
  } catch (error) {

  }
}
