import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { createChat, updateStatusRead, viewChat } from "../../../actions";
import { BackChatIcon, UserChatIcon } from "../../Icons";
import ScrollableFeed from "react-scrollable-feed";
import socketIo from "socket.io-client";
import { GlobalContext } from "../../../context/GlobalContext";
import user from "../../../reducers/user";
import config from "../../../config";
import Button from "../../Elements/Button";

let socket;
const ENDPOINT = config.sockets.socketBackendUrl;

const Message = (imgPath) => {
  const receiverId = localStorage.getItem("receiverId");
  const senderId = localStorage.getItem("senderId");
  const collaboratorId = localStorage.getItem("collaboratorId");
  const connectionId = localStorage.getItem("connectionId");
  const [message, setMessage] = useState("");
  const [writeStatus, setWriteStatus] = useState(false);
  const [callStatus, setCallStatus] = useState(true);
  const userId = JSON.parse(localStorage.getItem("user"));
  // const ChatSelector = useSelector(state => state.chat)
  const [chat, setChat] = useState([]);
  const scrollableFeedRef = useRef(null);

  const [viewChatRead, setViewChatRead] = useState(false);
  const { friendsError, friends, friendsLoading } = useSelector(
    (state) => state.connection
  );

  const { setShowEditClipModel, setShowChatDetails } =
    useContext(GlobalContext);

  const dispatch = useDispatch();

  const changeDetectionFilter = (previousProps, newProps) => {
    const prevChildren = previousProps.children;
    const newChildren = newProps.children;

    return (
      prevChildren !== newChildren &&
      prevChildren[prevChildren.length - 1] !==
        newChildren[newChildren.length - 1]
    );
  };
  if (callStatus) {
    socket = socketIo(ENDPOINT, { transports: ["websocket"] });
  }

  setTimeout(() => {
    setCallStatus(false);
  }, 1000);

  useEffect(async () => {
    socket.on("viewChat", (data) => {
      setChat(data);
    });

    socket.emit("lastReadMessage", friends);
    socket.emit("userTyping", null, null, null);
  }, [writeStatus, setShowChatDetails]);

  useEffect(() => {
    socket = socketIo(ENDPOINT, { transports: ["websocket"] });
    socket.on("viewChat", (data) => {
      setChat(data);
    });
    setTimeout(() => {
      setViewChatRead(false);
    }, [5000]);
  }, [viewChatRead]);

  const handleClick = async (e) => {
    setViewChatRead(true);
    // setCallStatus(true);
    if (!message) {
      return;
    }

    if (e.key === "Enter") {
      const data = {
        connectionId,
        receiverId,
        senderId,
        message,
      };

      await dispatch(createChat(data));
      socket.on("viewChat", (data) => {
        setChat(data);
      });
      socket.emit("lastReadMessage", friends);
      socket.emit("userTyping", null, null, null);
      setMessage("");
      setWriteStatus(true);
      setTimeout(() => {
        setWriteStatus(false);
      }, 100);
    }
  };

  const handleSend = () => {
    setViewChatRead(true);
    if (!message) {
      return;
    }

    const data = {
      connectionId,
      receiverId,
      senderId,
      message,
    };

    dispatch(createChat(data));
    socket.on("viewChat", (data) => {
      setChat(data);
    });
    socket.emit("userTyping", null, null, null);
    socket.emit("lastReadMessage", friends);
    setMessage("");
    setWriteStatus(true);
    setTimeout(() => {
      setWriteStatus(false);
    }, 100);
  };

  const handleChange = async (e) => {
    // setCallStatus(true);
    setViewChatRead(true);
    setMessage(e.target.value);
    socket.emit("userTyping", senderId, receiverId, userId);
    // setWriteStatus(true);
    const timeOutId = setTimeout(async () => {
      await dispatch(
        updateStatusRead(receiverId, senderId, userId.id, connectionId)
      );
      socket.emit("userTyping", null, null, null);
    }, 1000);
    return () => clearTimeout(timeOutId);
  };

  const handleReadStatusUpdate = async () => {
    await dispatch(
      updateStatusRead(receiverId, senderId, userId.id, connectionId)
    );
    socket.emit("lastReadMessage", friends);
  };
  useEffect(() => {
    if (scrollableFeedRef.current) {
      const scrollableContainer = scrollableFeedRef.current.container.current;
      scrollableContainer.scrollTop = scrollableContainer.scrollHeight;
    }
  }, [chat]);

  return (
    <MessageStyled>
      {userId ? (
        <>
          <div className="height-message">
            {/* <ScrollableFeed
              changeDetectionFilter={() => changeDetectionFilter}
              style={{
                height: "93% !important",
                overflowY: "scroll !important",
                backgroundColor: "#f5f5f5 !important",
              }}
            >
            {chat.map((data) => (
                <>
                  {userId.id === data.senderId &&
                    receiverId === data.receiverId ? (
                    data.isHidden ? (
                      data.isHidden === userId.id ? (
                        ""
                      ) : (
                        <div className="user-message-body">
                          <div className="sender-message"> {data.message}</div>
                        </div>
                      )
                    ) : (
                      <div className="user-message-body">
                        <div className="sender-message"> {data.message}</div>
                      </div>
                    )
                  ) : receiverId === data.senderId &&
                    senderId === data.receiverId ? (
                    data.isHidden ? (
                      data.isHidden === userId.id ? (
                        ""
                      ) : (
                        <div className="friend-message-body">
                          <img src={imgPath.imgPath} className="user-avatar" />
                          <div className="message-text">{data.message}</div>
                        </div>
                      )
                    ) : (
                      <div className="friend-message-body">
                        <img src={imgPath.imgPath} className="user-avatar" />
                        <div className="message-text">{data.message}</div>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </>
              ))}
            </ScrollableFeed> */}
            <ScrollableFeed
              changeDetectionFilter={() => changeDetectionFilter}
              style={{
                height: "93% !important",
                overflowY: "scroll !important",
                backgroundColor: "#f5f5f5 !important",
              }}
            >
              {chat.map((data) => (
                <>
                  {userId.id === data.senderId &&
                  receiverId === data.receiverId ? (
                    data.isHidden ? (
                      data.isHidden === userId.id ? (
                        ""
                      ) : (
                        <div className="user-message-body">
                          <div className="sender-message"> {data.message}</div>
                        </div>
                      )
                    ) : (
                      <div className="user-message-body">
                        <div className="sender-message"> {data.message}</div>
                      </div>
                    )
                  ) : receiverId === data.senderId &&
                    senderId === data.receiverId ? (
                    data.isHidden ? (
                      data.isHidden === userId.id ? (
                        ""
                      ) : (
                        <div className="friend-message-body">
                          <img src={imgPath.imgPath} className="user-avatar" />
                          <div className="message-text">{data.message}</div>
                        </div>
                      )
                    ) : (
                      <div className="friend-message-body">
                        <img src={imgPath.imgPath} className="user-avatar" />
                        <div className="message-text">{data.message}</div>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </>
              ))}
            </ScrollableFeed>
          </div>

          <div className="message-input-section">
            <input
              type="search"
              name="message"
              value={message}
              className="message-input"
              placeholder="Send Message"
              onClick={handleReadStatusUpdate}
              onKeyDown={handleClick}
              onChange={handleChange}
            />

            <Button
              onClick={handleSend}
              className="send-button"
              mode="primary"
              size="medium"
            >
              Send
            </Button>
          </div>
        </>
      ) : (
        ""
      )}
    </MessageStyled>
  );
};

export default Message;

const MessageStyled = styled.div`
  height: 100vh;
  .friend-message-body {
    margin: 2%;
    display: flex;
  }
  .message-container {
    // height: 93%;
    overflow-y: scroll;
    // background-color: #f5f5f5;
  }
  .message-text {
    margin-left: 15px;
    background: #3a3a3c;
    padding: 7px 15px;
    border-radius: 10px;
    font-family: Montserrat;
  }
  .user-message-body {
    margin-top: 10px;
    margin-left: auto;

    border-radius: 10px;
    padding: 4px;
    margin-right: 1%;
    padding-right: 15px;
    font-family: Montserrat;
    display: flex;
    justify-content: right;
  }
  .sender-message {
    background: ${(props) => props.theme.gradient};
    margin-left: 15px;
    padding: 4px 12.5px;
    border-radius: 5px;
  }
  .height-message {
    height: 100%;
    width: 80vw;
    padding: 1rem 2rem 10rem 2rem;
    overflow-y: auto;
  }
  .message-input-section {
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100% - 22rem);
    height: 5rem;
    z-index: 9999;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.09);
    background-color: #202020;
    font-size: 0.88rem;

    .send-button {
      width: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
    }
    .message-input {
      width: calc(100% - 7rem);
      padding: 10px;
      font-family: "Montserrat";
      background: #3a3a3c;
      border: none;
      border-radius: 5px;
      color: #fff;
      bottom: 10px;

      &::placeholder {
        color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .user-avatar {
    width: 40px;
    border-radius: 50%;
  }
`;
