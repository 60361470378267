import React from "react";
import styled from "styled-components";
import config from "../../../config";
import Button from "../../../styles/Button";

const ConnectWalletModal = ({ doNotClose, close }) => {
  const stripeConnect = () => {
    const url = `${config.stripe.REACT_APP_STRIPE_ONBOARDING}`;
    window.open(url, "_self");
  };
  return (
    <ConnectWalletModalStyled ref={doNotClose}>
      <div onClick={close} className="confirmHeader">
        <div className="description">
          Changing your connected payout account will clear all of your
          transaction history associated with that bank account or debit card.
          The new account will require verification again. All pending payouts
          will still be sent to your currently connected account but any future
          payments received will go to your new account. Are you sure you want
          to change your connect payouts account?
        </div>
        <div className="buttonsWrapper">
          <Button className="buttonsWrapper__cancelBtn" grey onClick={close}>
            Cancel
          </Button>
          <Button
            className="buttonsWrapper__confirmBtn"
            onClick={() => stripeConnect()}
          >
            Yes, Continue
          </Button>
        </div>
      </div>
    </ConnectWalletModalStyled>
  );
};

export default ConnectWalletModal;

const ConnectWalletModalStyled = styled.div`
  position: absolute;
  top: 9rem;
  right: 5rem;
  margin: -100px 0 0 -150px;
  z-index: 5;
  width: 407px;
  border-radius: 1rem;
  background-color: #4c4c4e;
  padding: 1rem 2rem;
  -webkit-animation: jrgDjZ 0.65s ease-out;
  -webkit-animation: jrgDjZ 0.65s ease-out;
  animation: jrgDjZ 0.65s ease-out;
  border-top: 1pxsolidrgba (112, 112, 112, 0.25);
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0;

  .confirmHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    .closeIcon {
      height: 0.5rem;
      width: auto;
      margin-right: 1rem;
    }

    .description {
      font-size: 0.9rem;
      font-weight: 500;
      color: #f2f2f7;
    }
    .buttonsWrapper {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: inherit;
      margin-top: 1rem;
    }
  }

  .options {
    width: 100%;

    &__item {
      padding: 0.5rem 0;
      font-size: 0.85rem;
      font-weight: 600;
      color: #636366;
      border-bottom: 2px solid rgba(99, 99, 102, 0.5);
      cursor: pointer;
    }

    .active {
      color: #f2f2f7;
    }
  }

  @media screen and (max-width: 480px) {
    .header {
      .description {
        font-size: 0.8rem;
      }
    }

    .options {
      &__item {
        padding: 0.5rem 0;
        font-size: 0.8rem;
      }
    }
  }
`;
