import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BackIcon from "../../../assets/Icons/back.svg";
import VideoCardSelect from "./VideoCardSelect";
import MomentCardSelect from "./MomentCardSelect";
import SeriesCardSelect from "./SeriesCardSelect";
import { Col, Row } from "react-grid-system";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllSeriesOfUser,
  getMyMoments,
  addContentToElement,
  getMyBanners,
  addNewCustomBanner,
  getUserVideos,
} from "../../../actions";
import { getThreadsOfUser } from "../../../actions/thread";
import { toast } from "react-toastify";
import api from "../../../services/api";
import UploadCustomBanner from "./UploadCustomBanner";
import { notificationMessage } from "../../../utils/toastNotifications";
import Loader from "../../Elements/Loader";
import Error from "../../Elements/Error";
import ThreadCardSmall from "../../Cards/ThreadCardSmall";

const AddFeaturedContent = ({
  addFeaturedContentModel,
  close,
  type,
  selectedProfileElement,
  setSelectedProfileElement,
  open,
}) => {
  const dispatch = useDispatch();
  const {
    loading: getSeriesLoading,
    series: allSeries,
    error: getSeriesError,
  } = useSelector((state) => state.getAllSeriesOfUser);
  const {
    myMoments,
    loading: myMomentsLoading,
    error: myMomentsError,
  } = useSelector((state) => state.moments);
  const {
    videos: userVideos,
    loading: userVideosLoading,
    error: userVideosError,
  } = useSelector((state) => state.userVideos);
  const userCustomBanners = useSelector(
    (state) => state.marketingBanner.userCustomBanners
  );
  const {
    userThreads,
    isFetching: userThreadsLoading,
    error: userThreadsError,
  } = useSelector((state) => state.threads);

  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [selectedMoments, setSelectedMoments] = useState([]);
  const [selectedBanners, setSelectedBanners] = useState([]);
  const [selectedThreads, setSelectedThreads] = useState([]);
  const [notAddedContents, setNotAddedContents] = useState([]);
  const profile = useSelector((state) => state.profile);

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  useEffect(() => {
    if (type === "series" && open) {
      dispatch(getAllSeriesOfUser(profile?.username));
    }
    if (type === "moment" && open) {
      dispatch(getMyMoments(profile?.id));
    }
    if (type === "banner" && open) {
      dispatch(getMyBanners());
    }

    if (type === "video" && open) {
      dispatch(
        getUserVideos(
          {
            showAll: false,
          },
          profile?.id
        )
      );
    }

    if (type === "thread" && open) {
      dispatch(getThreadsOfUser(profile?.username));
    }
  }, [type, open]);

  useEffect(() => {
    if (
      selectedProfileElement &&
      type === "moment" &&
      myMoments &&
      myMoments.length > 0
    ) {
      const result = myMoments.filter(function (item) {
        if (selectedProfileElement.featuredDetailedContents.length > 0) {
          return (
            selectedProfileElement.featuredDetailedContents.filter(function (
              item2
            ) {
              return item.id === item2.id;
            }).length === 0
          );
        } else {
          return item;
        }
      });

      setNotAddedContents(result);
    }

    if (
      selectedProfileElement &&
      type === "series" &&
      allSeries &&
      allSeries.length > 0
    ) {
      const result = allSeries.filter(function (item) {
        if (selectedProfileElement.featuredDetailedContents.length > 0) {
          return (
            selectedProfileElement.featuredDetailedContents.filter(function (
              item2
            ) {
              return item.id === item2.id;
            }).length === 0
          );
        } else {
          return item;
        }
      });

      setNotAddedContents(result);
    }

    if (
      selectedProfileElement &&
      type === "video" &&
      userVideos &&
      userVideos.length > 0
    ) {
      const result = userVideos.filter(function (item) {
        if (selectedProfileElement.featuredDetailedContents.length > 0) {
          return (
            selectedProfileElement.featuredDetailedContents.filter(function (
              item2
            ) {
              return item.id === item2.id;
            }).length === 0
          );
        } else {
          return item;
        }
      });

      setNotAddedContents(result);
    }

    if (
      selectedProfileElement &&
      type === "banner" &&
      userCustomBanners &&
      userCustomBanners.length > 0
    ) {
      const result = userCustomBanners.filter(function (item) {
        if (selectedProfileElement.featuredDetailedContents.length > 0) {
          return (
            selectedProfileElement.featuredDetailedContents.filter(function (
              item2
            ) {
              return item.id === item2.id;
            }).length === 0
          );
        } else {
          return item;
        }
      });

      setNotAddedContents(result);
    }

    if (
      selectedProfileElement &&
      type === "thread" &&
      userThreads &&
      userThreads.length > 0
    ) {
      const result = userThreads.filter(function (item) {
        if (selectedProfileElement.featuredDetailedContents.length > 0) {
          return (
            selectedProfileElement.featuredDetailedContents.filter(function (
              item2
            ) {
              return item.id === item2.id;
            }).length === 0
          );
        } else {
          return item;
        }
      });

      setNotAddedContents(result);
    }
  }, [
    myMoments,
    open,
    selectedProfileElement,
    type,
    allSeries,
    userVideos,
    userCustomBanners,
    userThreads,
  ]);

  const selectVideoHandler = (item) => {
    if (selectedVideos && selectedVideos.length > 0) {
      const isAlreadyAdded = selectedVideos.filter((val) => val === item.id);

      if (isAlreadyAdded.length > 0) {
        const newArr = selectedVideos.filter((val) => val !== item.id);
        setSelectedVideos(newArr);

        return;
      }
    }

    setSelectedVideos([...selectedVideos, item.id]);
  };

  const selectBannerHandler = (item) => {
    if (selectedBanners && selectedBanners.length > 0) {
      const isAlreadyAdded = selectedBanners.filter((val) => val === item.id);

      if (isAlreadyAdded.length > 0) {
        const newArr = selectedBanners.filter((val) => val !== item.id);
        setSelectedBanners(newArr);
        return;
      }
    }

    setSelectedBanners([...selectedBanners, item.id]);
  };

  const selectSeriesHandler = (item) => {
    if (selectedSeries && selectedSeries.length > 0) {
      const isAlreadyAdded = selectedSeries.filter((val) => val === item.id);

      if (isAlreadyAdded.length > 0) {
        const newArr = selectedSeries.filter((val) => val !== item.id);
        setSelectedSeries(newArr);

        return;
      }
    }

    setSelectedSeries([...selectedSeries, item.id]);
  };

  const selectMomentHandler = (item) => {
    if (selectedMoments && selectedMoments.length > 0) {
      const isAlreadyAdded = selectedMoments.filter((val) => val === item.id);

      if (isAlreadyAdded.length > 0) {
        const newArr = selectedMoments.filter((val) => val !== item.id);
        setSelectedMoments(newArr);

        return;
      }
    }

    setSelectedMoments([...selectedMoments, item.id]);
  };

  const selectThreadHandler = (item) => {
    if (selectedThreads && selectedThreads.length > 0) {
      const isAlreadyAdded = selectedThreads.filter((val) => val === item.id);

      if (isAlreadyAdded.length > 0) {
        const newArr = selectedThreads.filter((val) => val !== item.id);
        setSelectedThreads(newArr);

        return;
      }
    }

    setSelectedThreads([...selectedThreads, item.id]);
  };

  const saveFeaturedContentHandler = async () => {
    let promises;
    if (type === "video") {
      promises = selectedVideos.map(async (item, index) => {
        dispatch(
          addContentToElement(selectedProfileElement?.id, {
            order: selectedProfileElement.featuredDetailedContents.length
              ? selectedProfileElement.featuredDetailedContents.length
              : index + 1,
            videoId: item,
          })
        );
      });
    }
    if (type === "series") {
      promises = selectedSeries.map(async (item, index) => {
        dispatch(
          addContentToElement(selectedProfileElement?.id, {
            order: selectedProfileElement.featuredDetailedContents.length
              ? selectedProfileElement.featuredDetailedContents.length
              : index + 1,
            seriesId: item,
          })
        );
      });
    }
    if (type === "moment") {
      promises = selectedMoments.map(async (item, index) => {
        dispatch(
          addContentToElement(selectedProfileElement?.id, {
            order: selectedProfileElement.featuredDetailedContents.length
              ? selectedProfileElement.featuredDetailedContents.length
              : index + 1,
            momentId: item,
          })
        );
      });
    }
    if (type === "banner") {
      promises = selectedBanners.map(async (item, index) => {
        dispatch(
          addContentToElement(selectedProfileElement?.id, {
            order: selectedProfileElement.featuredDetailedContents.length
              ? selectedProfileElement.featuredDetailedContents.length
              : index + 1,
            bannerId: item,
          })
        );
      });
    }
    if (type === "thread") {
      promises = selectedThreads.map(async (item, index) => {
        dispatch(
          addContentToElement(selectedProfileElement?.id, {
            order: selectedProfileElement.featuredDetailedContents.length
              ? selectedProfileElement.featuredDetailedContents.length
              : index + 1,
            threadId: item,
          })
        );
      });
    }
    await Promise.all(promises);
    setSelectedVideos([]);
    setSelectedMoments([]);
    setSelectedSeries([]);
    setSelectedBanners([]);
    setSelectedThreads([]);
    close();
    toast.success(notificationMessage.elementContentAdded);
  };

  const onBannerUpload = async (bannerUrlsArray) => {
    try {
      const customBannerUrl = bannerUrlsArray[0];
      const res = await api.post(`users/addCustomBanner`, {
        customBannerUrl,
        categoryId: 1,
        order: 1,
      });
      dispatch(addNewCustomBanner(res.data.data));
      setSelectedBanners([...selectedBanners, res.data.data.id]);
      toast.success(notificationMessage.elementCustomBanner);
    } catch (err) {
      toast.error(notificationMessage.somethingWentWrong);
    }
  };

  return (
    <AddFeaturedContentStyled
      className={`${addFeaturedContentModel && "ShowAddFeaturedContentModel"}`}
    >
      <div className="addFeaturedContentHeader">
        <div className="left">
          <div
            onClick={() => {
              if (
                selectedVideos.length ||
                selectedMoments.length ||
                selectedSeries.length
              ) {
                return toast.error(notificationMessage.saveChanges);
              }

              close();
              setSelectedProfileElement(null);
            }}
            className="backIcon"
          >
            <img src={BackIcon} alt="" />
          </div>
          <div className="name">Choose your {type ? type : "content"}</div>
        </div>

        <div className="right">
          <button onClick={saveFeaturedContentHandler} className="button">
            save
          </button>
        </div>
      </div>

      <div className="content">
        <>
          {type === "series" && (
            <>
              {getSeriesLoading && <Loader text="Please wait..." />}

              {!getSeriesLoading &&
                (getSeriesError || !notAddedContents?.length) && (
                  <Error text="No series found!" />
                )}
            </>
          )}

          {type === "moment" && (
            <>
              {myMomentsLoading && <Loader text="Please wait..." />}

              {!myMomentsLoading &&
                (myMomentsError || !notAddedContents?.length) && (
                  <Error text="No moment found!" />
                )}
            </>
          )}

          {type === "video" && (
            <>
              {userVideosLoading && <Loader text="Please wait..." />}

              {!userVideosLoading &&
                (userVideosError || !notAddedContents?.length) && (
                  <Error text="No videos found!" />
                )}
            </>
          )}

          {type === "thread" && (
            <>
              {userThreadsLoading && <Loader text="Please wait..." />}

              {!userThreadsLoading &&
                (userThreadsError || !notAddedContents?.length) && (
                  <Error text="No threads found!" />
                )}
            </>
          )}

          <div className="content__items">
            {type === "video" && (
              <Row className="content__items--row">
                {notAddedContents &&
                  notAddedContents.length > 0 &&
                  notAddedContents.map((item, i) => (
                    <Col
                      key={i}
                      lg={3}
                      md={6}
                      sm={6}
                      xs={6}
                      className="content__items--col"
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <VideoCardSelect
                        selectVideoHandler={selectVideoHandler}
                        isSelected={selectedVideos.includes(item.id)}
                        item={item}
                        editMode={true}
                      />
                    </Col>
                  ))}
              </Row>
            )}

            {type === "moment" && (
              <Row className="content__items--row">
                {notAddedContents &&
                  notAddedContents.length > 0 &&
                  notAddedContents.map((item, i) => (
                    <Col
                      key={i}
                      lg={3}
                      md={3}
                      sm={4}
                      xs={4}
                      className="content__items--col"
                      style={{
                        paddingLeft: isMobile ? "4px" : "10px",
                        paddingRight: isMobile ? "4px" : "10px",
                      }}
                    >
                      <MomentCardSelect
                        selectMomentHandler={selectMomentHandler}
                        isSelected={selectedMoments.includes(item.id)}
                        item={item}
                      />
                    </Col>
                  ))}
              </Row>
            )}

            {type === "series" && (
              <Row className="content__items--row">
                {notAddedContents &&
                  notAddedContents.length > 0 &&
                  notAddedContents.map((item, i) => (
                    <Col
                      key={i}
                      lg={3}
                      md={3}
                      sm={4}
                      xs={4}
                      className="content__items--col"
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <SeriesCardSelect
                        selectSeriesHandler={selectSeriesHandler}
                        isSelected={selectedSeries.includes(item.id)}
                        item={item}
                      />
                    </Col>
                  ))}
              </Row>
            )}
            {type === "banner" && (
              <Row className="content__items--row">
                {notAddedContents.length == 0 && (
                  <UploadCustomBanner
                    close={close}
                    customBanners={notAddedContents}
                    onBannerUpload={onBannerUpload}
                    selectBannerHandler={selectBannerHandler}
                    selectedBanners={selectedBanners}
                    saveFeaturedContentHandler={saveFeaturedContentHandler}
                  />
                )}
                {notAddedContents?.length > 0 &&
                  notAddedContents.map((item, i) => (
                    <Col
                      key={i}
                      lg={3}
                      md={3}
                      sm={4}
                      xs={4}
                      className="content__items--col"
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <UploadCustomBanner
                        close={close}
                        customBanners={notAddedContents}
                        onBannerUpload={onBannerUpload}
                        selectBannerHandler={selectBannerHandler}
                        selectedBanners={selectedBanners}
                        saveFeaturedContentHandler={saveFeaturedContentHandler}
                      />
                    </Col>
                  ))}
              </Row>
            )}

            {type === "thread" && (
              <Row className="content__items--row">
                {notAddedContents &&
                  notAddedContents.length > 0 &&
                  notAddedContents.map((item, i) => (
                    <Col
                      key={i}
                      lg={3}
                      md={3}
                      sm={4}
                      xs={4}
                      className="content__items--col"
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <ThreadCardSmall
                        selectThreadHandler={selectThreadHandler}
                        isSelected={selectedThreads.includes(item.id)}
                        type={item.attachedType}
                        item={item}
                      />
                    </Col>
                  ))}
              </Row>
            )}
          </div>
        </>
      </div>
    </AddFeaturedContentStyled>
  );
};

export default AddFeaturedContent;

const AddFeaturedContentStyled = styled.div`
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #202020;
  border-radius: 0.3rem;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.4), 0px 0px 4px rgba(0, 0, 0, 0.25);
  transform: translateY(-100%);
  transition: all 1s ease;
  font-family: ${(props) => props.theme.montserrat};

  .addFeaturedContentHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 5rem;
    border-bottom: 1px solid rgba(112, 112, 112, 0.4);

    .backIcon {
      margin-right: 1rem;
      cursor: pointer;

      img {
        height: 1rem;
        width: auto;
      }
    }

    .name {
      font-size: 1.2rem;
      font-weight: 500;
      color: #f2f2f7;
      text-transform: capitalize;
    }

    .left {
      display: flex;
      align-items: center;
    }

    .button {
      border: 3px solid #fff;
      padding: 0.3rem 1.2rem;
      border-radius: 10rem;
      font-size: 1rem;
      background-color: transparent;
      color: #fff;
      font-family: ${(props) => props.theme.montserrat};
      font-weight: 400;
      text-transform: capitalize;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }

  .content {
    width: 90%;
    margin: 2rem auto;

    .loading,
    .error {
      font-family: brother-1816, sans-serif;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    &__items {
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
  }

  @media screen and (max-width: 768px) {
    .addFeaturedContentHeader {
      padding: 1rem 2.5rem;

      .name {
        font-size: 1.1rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .addFeaturedContentHeader {
      padding: 1rem 1.5rem;

      .name {
        font-size: 0.9rem;
      }

      .button {
        font-size: 0.85rem;
      }
    }

    .content {
      &__items {
        &--col {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
`;
