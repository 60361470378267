import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ChatIcon from "./icons/chat.svg";
import NotificationBell from "./Notification";
import ManageSubscription from "../../MyAccountComponents/ManageSubscription/ManageSubscription";
import Button from "../../Elements/Button";
import socketIo from "socket.io-client";
import config from "../../../config";
import Logo from "../../Elements/Logo";
import LockIcon from "../../icons/lock.svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { GlobalContext } from "../../../context/GlobalContext";
import { useHistory } from "react-router-dom";
import { stripeOnboard } from "../../../actions";
import { CHATCOUNT } from "../../../actions/types";

const ENDPOINT = config.sockets.socketBackendUrl;
let socket;
const Navbar = ({ close }) => {
  const user = useSelector((state) => state.user);
  const { setShowSidebar, setShowChatList, setShowAuthenticationModel } =
    useContext(GlobalContext);
  const [showManageSubscription, setShowManageSubscription] = useState(false);
  const [chatCount, setChatCount] = useState([]);
  const [requestCount, setRequestCount] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const tempFunc = useRef();
  const newdataFunc = () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const connectCode = url.searchParams.get("code");

    if (url.href.indexOf("signup") !== -1 && connectCode && user.id) {
      history.push("/home");
      return;
    }
    if (connectCode) {
      onBoarding(connectCode);
    }
  };
  tempFunc.current = newdataFunc;
  useEffect(async () => {
    tempFunc.current();
    socket = socketIo(ENDPOINT, { transports: ["websocket"] });
    socket.on("userUnreadMessage", (data) => {
      setChatCount(data);
      dispatch({ type: CHATCOUNT, payload: data });
    });
    socket.on("pendingRequest", (data) => {
      setRequestCount(data);
    });
  }, []);

  const onBoarding = (connectCode) => {
    if (!connectCode) {
      return false;
    } else {
      const payload = {
        code: connectCode,
        id: user.id,
      };
      dispatch(stripeOnboard(payload));
      // toast.success("Account connected! You are ready to start earning!");
    }
  };

  const isBadgeOrAvatar = () => {
    if (user.userrole !== undefined) {
      const image = !user.avatar ? user.badge : user.avatar;

      if (image && (image.includes(".png") || image.includes(".svg"))) {
        return (
          <div className="imageBadge">
            <img className="badge" src={image} alt="" />
          </div>
        );
      } else {
        return (
          <img
            className="imageAvatar"
            src={config.aws.CLOUDFRONT_URL + image}
            alt=""
          />
        );
      }
    } else {
      return "isutra-icon";
    }
  };

  if (showManageSubscription) {
    return (
      <ManageSubscription
        close={() => setShowManageSubscription(false)}
        user={user}
      />
    );
  }

  const loginHandler = () => {
    setShowAuthenticationModel(true);
    const usernameInput = document.getElementById("user-name");
    if (usernameInput) {
      usernameInput.focus();
    }
  };

  return (
    <NavbarWrapper>
      <div className="wrapper">
        <div className="wrapper__left">
          {!user?.id ? (
            <div className="wrapper__left--logo">
              <Logo
                onClick={() => {
                  history.push("/home");
                }}
              />
            </div>
          ) : (
            <div
              onClick={() => setShowSidebar(true)}
              className="wrapper__left--avatar"
            >
              {isBadgeOrAvatar()}
            </div>
          )}

          {user?.id && (
            <>
              <div
                onClick={() => {
                  if (!user.userrole) {
                    document.getElementById("user-name").focus();
                    setShowAuthenticationModel(true);
                  } else {
                    setShowSidebar(true);
                  }
                }}
                className="wrapper__left--role"
              >
                {user.userrole === 0
                  ? "freeloader"
                  : user.userrole === 1
                  ? "CO-OP"
                  : user.userrole === 2
                  ? "warrior"
                  : null}
              </div>
              {user.userrole === 0 && (
                <div
                  className="upgrade-account"
                  onClick={() => {
                    toast.error(
                      "Upgrade your account to get exclusive features of freeloader!"
                    );
                    setShowManageSubscription(true);
                  }}
                >
                  <div className="padLockV2">
                    <img src={LockIcon} alt="" />
                  </div>
                  Upgrade
                </div>
              )}
            </>
          )}
        </div>
        <div
          className="wrapper__right"
          style={{
            transform: user?.id && "translateY(7px)",
          }}
        >
          {(user.userrole === 0) |
          (user.userrole === 1) |
          (user.userrole === 2) ? (
            <>
              <div className="wrapper__right--notification">
                <NotificationBell />
              </div>
              <div className="wrapper__right--message">
                <div
                  onClick={() => {
                    if (user.userrole === 0) {
                      return toast.error(
                        "Chat connections with other Freeloaders is exclusive to Co-op and Warrior users. Please upgrade your account! It's only $5/year... thats a cup of coffee!"
                      );
                    }

                    setShowChatList(true);
                  }}
                >
                  <img src={ChatIcon} alt="Chats" />

                  {user?.userrole === 0 && (
                    <div className="padLock">
                      <img src={LockIcon} alt="" />
                    </div>
                  )}
                </div>
                {chatCount ? (
                  chatCount.receiverMessage ? (
                    chatCount.receiverMessage.filter(
                      (chat) => chat.receiverId === user.id
                    ).length === 0 ? (
                      requestCount === [] ? (
                        ""
                      ) : requestCount?.pendingRequest ? (
                        requestCount?.pendingRequest?.filter(
                          (request) => request.WarriorUserId === user.id
                        ).length === 0 ? (
                          ""
                        ) : (
                          <div
                            className="num"
                            onClick={() => setShowChatList(true)}
                          >
                            {
                              requestCount?.pendingRequest.filter(
                                (request) => request.WarriorUserId === user.id
                              ).length
                            }
                          </div>
                        )
                      ) : (
                        ""
                      )
                    ) : (
                      <div
                        className="num"
                        onClick={() => setShowChatList(true)}
                      >
                        {" "}
                        {chatCount.receiverCount &&
                        requestCount.pendingRequestCount
                          ? Number(
                              chatCount.receiverMessage.filter(
                                (chat) => chat.receiverId === user.id
                              ).length
                            ) +
                            Number(
                              requestCount.pendingRequest.filter(
                                (request) => request.WarriorUserId === user.id
                              ).length
                            )
                          : ""}
                      </div>
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <Button
              className="wrapper__left--role"
              onClick={() => loginHandler()}
            >
              LOG IN
            </Button>
          )}
        </div>
      </div>
    </NavbarWrapper>
  );
};

export default Navbar;

const NavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(242, 242, 247, 0.2);
  z-index: 100;
  background-color: ${(props) => props.theme.bg};

  .wrapper {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;

      &--logo {
        height: 2.5rem;
      }

      &--avatar {
        cursor: pointer;
        height: 43px;
        width: 43px;
        border-radius: 50%;
        margin-right: 0.8rem;
        position: relative;

        .imageAvatar {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }

        .imageBadge {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .badge {
            height: 38px;
            width: auto;
          }
        }
      }

      &--role {
        cursor: pointer;
        line-height: 1;
        font-family: brother-1816, sans-serif;
        padding: 0.4rem 1rem;
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: 2px;
        font-weight: 500;
        background: ${(props) => props.theme.primaryGradient};
        border-radius: 3px;
      }
      .upgrade-account {
        position: absolute;
        bottom: -3px;
        left: 13.5rem;
        z-index: 2;
        font-size: 0.8rem;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: #50affb;
        display: flex;
        align-items: center;
        cursor: pointer;

        .padLockV2 {
          height: 0.9rem;
          width: 0.8rem;
          transform: translateY(-4px);

          img {
            height: 0.65rem;
            width: auto;
          }
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;

      div:first-child {
        margin-right: 1rem;
      }

      &--notification {
        transform: translateY(-5px);
        position: relative;
      }

      &--message {
        position: relative;
        cursor: pointer;

        .padLock {
          position: absolute;
          top: 0.2rem;
          left: -0.7rem;
          height: 1.3rem;
          width: 1.3rem;
          transform: translateY(-50%);
          background: ${(props) => props.theme.gradient};
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #202020;
          border-radius: 50%;

          img {
            height: 0.68rem;
            width: auto;
          }
        }

        img {
          height: 1.8rem;
          width: auto;
        }

        .num {
          position: absolute;
          top: -0.5rem;
          left: -0.5rem;
          font-size: 0.7rem;
          font-weight: 300;
          line-height: 1;
          height: 1.3rem;
          width: 1.3rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: ${(props) => props.theme.primaryGradient};

          span {
            padding-top: 2px;
          }
        }
      }
    }

    @media screen and (max-width: 480px) {
      &__left {
        &--avatar {
          height: 35px;
          width: 35px;
          margin-right: 0.5rem;
        }

        &--role {
          font-size: 0.9rem;
        }
      }

      &__right {
        div:first-child {
          margin-right: 0.5rem;
        }

        &--message {
          img {
            height: 1.5rem;
          }

          .num {
            font-size: 0.65rem;
            height: 1.2rem;
            width: 1.2rem;
          }
        }
      }
    }
  }
`;
