import React from "react";
import styled from "styled-components";
import { CloseIcon } from "../Icons";

const VideoCardTipOverlay = ({
  close,
  handleKarmaModal,
  keyVideoAccess,
  isTATVideoLocked,
}) => {
  const renderText = (keyVideoAccess) => {
    if (keyVideoAccess === 3 && isTATVideoLocked) {
      return (
        <>
          This content requires Karma to continue watching.
          <br />
          <span onClick={() => handleKarmaModal()}>Give Karma</span> &nbsp; to
          resume playback.
        </>
      );
    } else if (keyVideoAccess === 0 || keyVideoAccess === 1) {
      return (
        <>
          <span onClick={() => handleKarmaModal()}> Give Karma</span>&nbsp; back
          to the creator
        </>
      );
    }
  };

  if (keyVideoAccess === 3 && !isTATVideoLocked) {
    return null;
  }

  return (
    <Wrapper>
      <div className="overlay">
        <p>{renderText(keyVideoAccess)}</p>
        <div className="close">
          <CloseIcon onClick={close} />
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .overlay {
    display: flex;
    max-width: fit-content;
    flex-wrap: wrap;
    line-height: normal;
    font-size: medium;
    padding: 10px 20px;
    text-align: center;
    justify-content: center;
    border-radius: 8px;
    margin: 0 auto;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 1;
    bottom: 2.5rem;
    background: linear-gradient(
        130deg,
        #f9903d 14%,
        #f88946 23%,
        #f8795f 37%,
        #f75e87 55%,
        #f75b8c 57%
      )
      0% 0% no-repeat padding-box;
    color: #fff;
    opacity: 0.8;
    animation: slide-up 0.5s ease-out;
    p {
        padding: 2px 5px;
        span {
            text-decoration: underline;
            cursor: pointer;
            font-weight:600;
        }
    }
    .close {
      width: 18px;
      margin: 2px;
      position: absolute;
      right: 0;
      top: 0;
      svg {
        fill: white;
      }
    }}
  }
  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(10%);
    }
  }
`;
export default VideoCardTipOverlay;
