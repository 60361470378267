import React, { useState, useEffect } from "react";
import InputV2 from "../../components/Elements/InputV2";
import Button from "../../components/Elements/Button";
import FileUpload from "../../components/Elements/FileUpload";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { MarketingPageStyles } from "./Styles";
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { addMarketingAboutSection } from "../../actions/marketing";
import { toast } from "react-toastify";
import { RESET_MARKETING_PAGE_STATE } from "../../actions/marketing/type";

const Schema = Yup.object().shape({
  title: Yup.string().required("About us title is required."),
  paragraph1: Yup.string().required("paragraph1 is required."),
  paragraph2: Yup.string().required("paragraph1 is required."),
  buttonText: Yup.string().required("button text is required."),
  buttonUrl: Yup.string().required("button url is required."),
  videoUrl: Yup.string().required("video url is required."),
});

const AboutusForm = () => {
  const dispatch = useDispatch();
  const {
    data: marketingPageData,
    saveLoading,
    saveSuccess,
    saveError,
  } = useSelector((state) => state.marketingPage);

  const [thumbnail, setThumbnail] = useState({
    previewUrl: null,
    file: null,
    filePreview: true,
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      paragraph1: "",
      paragraph2: "",
      buttonText: "",
      buttonUrl: "",
      videoUrl: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      await saveFormHandler(values);
    },
  });

  useEffect(() => {
    if (saveSuccess) {
      toast.success("Content updated successfully.", {
        toastId: "aboutSuccess",
      });
    }

    if (saveError) {
      toast.success("Something went worng. Please try again!", {
        toastId: "aboutError",
      });
    }

    dispatch({
      type: RESET_MARKETING_PAGE_STATE,
    });
  }, [saveSuccess, saveError]);

  useEffect(() => {
    if (marketingPageData) {
      const aboutUsSectionArr = marketingPageData?.["about us"];

      if (aboutUsSectionArr?.length > 0) {
        const aboutUsSection = aboutUsSectionArr[0];

        formik.setValues({
          title: aboutUsSection?.title,
          paragraph1: aboutUsSection?.first_paragraph,
          paragraph2: aboutUsSection?.second_paragraph,
          buttonText: aboutUsSection?.button_text,
          buttonUrl: aboutUsSection?.button_url,
          videoUrl: aboutUsSection?.video_url,
        });

        setThumbnail({
          previewUrl: aboutUsSection?.thumbnail,
          filePreview: false,
        });
      }
    }
  }, [marketingPageData]);

  const handleThumbnailUpload = async (e, type) => {
    const file = e.target.files[0];

    if (file) {
      const previewUrl = URL.createObjectURL(file);

      setThumbnail({
        previewUrl,
        file,
        filePreview: true,
      });
      return;
    }
  };

  const saveFormHandler = async (values) => {
    if (saveLoading) {
      return;
    }

    try {
      const obj = {
        title: values.title,
        first_paragraph: values.paragraph1,
        second_paragraph: values.paragraph2,
        button_text: values.buttonText,
        button_url: values.buttonUrl,
        video_url: values.videoUrl,
        file: thumbnail?.file || null,
      };

      dispatch(addMarketingAboutSection(obj));
    } catch (error) {
      toast.error("Something went wrong. Please try again!");
    }
  };

  return (
    <FormikProvider value={formik}>
      <MarketingPageStyles>
        <div className="form">
          <div className="form__item">
            <InputV2
              name="title"
              type={"text"}
              placeholder={"About us title"}
              id={"title"}
              label={"Title"}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formikError={formik.touched.title && formik.errors.title}
            />
          </div>

          <div className="form__item">
            <InputV2
              name="paragraph1"
              type={"textarea"}
              placeholder={"Add first paragraph"}
              id={"paragraph1"}
              label={"First Paragraph"}
              value={formik.values.paragraph1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formikError={
                formik.touched.paragraph1 && formik.errors.paragraph1
              }
            />
          </div>

          <div className="form__item">
            <InputV2
              name="paragraph2"
              type={"textarea"}
              placeholder={"Add second paragraph"}
              id={"paragraph2"}
              label={"Second Paragraph"}
              value={formik.values.paragraph2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formikError={
                formik.touched.paragraph2 && formik.errors.paragraph2
              }
            />
          </div>

          <Row className="form__item">
            <Col md={6} sm={12}>
              <InputV2
                name="buttonText"
                type={"text"}
                placeholder={"Button Text"}
                id={"buttonText"}
                label={"Button Text"}
                value={formik.values.buttonText}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formikError={
                  formik.touched.buttonText && formik.errors.buttonText
                }
              />
            </Col>

            <Col md={6} sm={12}>
              <InputV2
                name="buttonUrl"
                type={"text"}
                placeholder={"Button URL"}
                id={"buttonUrl"}
                label={"Button URL"}
                value={formik.values.buttonUrl}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formikError={
                  formik.touched.buttonUrl && formik.errors.buttonUrl
                }
              />
            </Col>
          </Row>

          <div className="form__item">
            <InputV2
              name="videoUrl"
              type={"text"}
              placeholder={"Video URL"}
              id={"videoUrl"}
              label={"Video URL"}
              value={formik.values.videoUrl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formikError={formik.touched.videoUrl && formik.errors.videoUrl}
            />
          </div>

          <div className="form__item">
            <FileUpload
              id={"thumbnailUpload"}
              title={"Upload Thumbnail"}
              explain={"Upload thumbnail of the about section."}
              onChange={handleThumbnailUpload}
              display={{
                url: thumbnail.previewUrl,
                filePreview: thumbnail.filePreview,
                noBg: true,
              }}
            />
          </div>

          <div className="form__item">
            <Button
              onClick={() => formik.handleSubmit()}
              type={"submit"}
              className="form__item--submit"
              mode="primary"
              width="fluid"
              variant="round"
              size="medium"
            >
              {saveLoading ? "Please wait..." : "save changes"}
            </Button>
          </div>
        </div>
      </MarketingPageStyles>
    </FormikProvider>
  );
};

export default AboutusForm;
