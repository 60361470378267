import {
  GET_ALL_THREADS_REQUEST,
  GET_ALL_THREADS_SUCCESS,
  GET_ALL_THREADS_FAIL,
  DELETE_THREAD_SUCCESS,
  GET_SINGLE_THREAD_SUCCESS_V2,
  GET_THREADS_OF_USER_REQUEST,
  GET_THREADS_OF_USER_SUCCESS,
  GET_THREADS_OF_USER_FAIL,
  GET_STREAMS_THREADS_REQUEST,
  GET_STREAMS_THREADS_SUCCESS,
  GET_STREAMS_THREADS_FAIL,
  GET_LIKED_THREADS_REQUEST,
  GET_LIKED_THREADS_SUCCESS,
  GET_LIKED_THREADS_FAIL,
  CLEAR_THREADS,
} from "../actions/thread/type";

const threads = (
  state = {
    threads: [],
    streamsThreads:[],
    filteredThreads: [],
    isFiltering: false,
    hasMore: true,
    isFetching: true,
  },
  action
) => {
  switch (action.type) {
    case GET_ALL_THREADS_REQUEST:
      return {
        isFetching: true,
        threads:
          state.threads && state.threads.length > 0 ? [...state.threads] : [],
      };
    case GET_ALL_THREADS_SUCCESS:
      const isFiltering = action.payload.isFiltering;
      const allThreads = [...state.threads, ...action.payload.threads];
      const uniqueThreads = [
        ...new Map(allThreads.map((item) => [item["id"], item])).values(),
      ];

      return {
        isFetching: false,
        threads: uniqueThreads,
        hasMore: action.payload.hasMore,
        isFiltering,
      };
    case CLEAR_THREADS:
      return {
        isFetching: true,
        threads:[],
        hasMore: false,
        streamsThreads:[]
      }
    case GET_ALL_THREADS_FAIL:
      return {
        isFetching: false,
        error: action.payload,
        threads:
          state.threads && state.threads.length > 0 ? [...state.threads] : [],
        hasMore: false,
      };

    // update state after thread deleted
    case DELETE_THREAD_SUCCESS:
      const filteredThreads = state.threads.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        threads: filteredThreads,
      };

    // update state after thread created
    case GET_SINGLE_THREAD_SUCCESS_V2:
      const allThreadsV2 = [action.payload, ...state.threads];
      const uniqueThreadsV2 = [
        ...new Map(allThreadsV2.map((item) => [item["id"], item])).values(),
      ];

      return {
        ...state,
        threads: uniqueThreadsV2,
      };

    case GET_THREADS_OF_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        userThreads:
          state.userThreads && state.userThreads.length > 0
            ? [...state.userThreads]
            : [],
      };
    case GET_THREADS_OF_USER_SUCCESS:
      const allUserThreads = [...state.userThreads, ...action.payload.threads];
      const uniqueUserThreads = [
        ...new Map(allUserThreads.map((item) => [item["id"], item])).values(),
      ];

      return {
        ...state,
        isFetching: false,
        userThreads: uniqueUserThreads,
        hasMore: action.payload.hasMore,
      };
    case GET_THREADS_OF_USER_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        userThreads:
          state.userThreads && state.userThreads.length > 0
            ? [...state.userThreads]
            : [],
        hasMore: false,
      };
    case GET_STREAMS_THREADS_REQUEST:
      return {
        ...state,
        isFetching: true,
        streamsThreads:
          state.streamsThreads && state.streamsThreads.length > 0
            ? [...state.streamsThreads]
            : [],
      };
    case GET_STREAMS_THREADS_SUCCESS:
      const allStreamsThreads = [
        ...state.streamsThreads,
        ...action.payload.threads,
      ];
      const uniqueStreamsThreads = [
        ...new Map(
          allStreamsThreads.map((item) => [item["id"], item])
        ).values(),
      ];

      return {
        ...state,
        isFetching: false,
        streamsThreads: uniqueStreamsThreads,
        hasMoreStreamsThreads: action.payload.hasMore,
        isFiltering: action.payload.isFiltering,
      };
    case GET_STREAMS_THREADS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        streamsThreads:
          state.streamsThreads && state.streamsThreads.length > 0
            ? [...state.streamsThreads]
            : [],
        hasMoreStreamsThreads: false,
      };

    // liked threads
    case GET_LIKED_THREADS_REQUEST:
      return {
        ...state,
        likedThreadsLoading: true,
      };
    case GET_LIKED_THREADS_SUCCESS:
      return {
        ...state,
        likedThreadsLoading: false,
        likedThreads: action.payload,
      };
    case GET_LIKED_THREADS_FAIL:
      return {
        ...state,
        likedThreadsLoading: false,
        likedThreadsError: action.payload,
      };
    default:
      return state;
  }
};

export default threads;
