import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LinearProgress, Box, Typography } from "@material-ui/core";
import { notificationMessage } from "../utils/toastNotifications";
import BackIcon from "../assets/Icons/back.svg";
import Loader from "./Elements/Loader";
import { GlobalContext } from "../context/GlobalContext";

const Progressbar = ({ close }) => {
  const [percentage, setPercentage] = useState(null);
  const [packageSize, setPackageSize] = useState(null);
  const [remaining, setRemaining] = useState(null);
  const { setShowManageSubscription } = useContext(GlobalContext);
  const { loading, storageInfo } = useSelector((state) => state.userStorage);

  useEffect(() => {
    if (storageInfo) {
      if (
        (storageInfo.usedVideoLength || storageInfo.usedVideoLength === 0) &&
        storageInfo.totalFileSize
      ) {
        const uploadedMinutesPercent =
          (parseFloat(storageInfo.usedVideoLength / 60) * 100) /
          storageInfo.totalFileSize;
        setPercentage(+uploadedMinutesPercent.toFixed(2));
        setPackageSize(storageInfo.totalFileSize);
        setRemaining(parseInt(storageInfo.usedVideoLength / 60));
      }
    }
  }, [storageInfo]);

  const handleManageSubscription = () => {
    close();
    return setShowManageSubscription(true);
  };

  useEffect(() => {
    if (percentage >= 100) {
      toast.error(notificationMessage.videoStorageUtilizationIsOver, {
        autoClose: false,
        onClick: () => handleManageSubscription(),
      });
    }
  }, [percentage]);

  return (
    <>
      <ProgressBarStyled>
        <div className="wrapper">
          <div className="wrapper__header">
            <div onClick={close} className="backIcon">
              <img src={BackIcon} alt="" />
            </div>
            <div className="name">Minutes Utilization</div>
          </div>

          <p className="infoText">
            Minutes utilization based on your Current Subscription
          </p>

          <div className="wrapper__subscriptionBox">
            {loading && (
              <Loader text="Fetching minutes information, please wait..." />
            )}

            {!loading && storageInfo && (
              <>
                <h4>MINUTES UTILIZATION </h4>
                <p>
                  {remaining} min / {packageSize} min
                </p>
                <Box display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <LinearProgress
                      className={"bar"}
                      variant="determinate"
                      value={percentage >= 100 ? 100 : percentage}
                      color="secondary"
                    />
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2">{`${
                      percentage >= 100 ? 100 : percentage
                    }%`}</Typography>
                  </Box>
                </Box>
              </>
            )}
          </div>
        </div>
      </ProgressBarStyled>
    </>
  );
};

export default Progressbar;

const ProgressBarStyled = styled.div`
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    font-family: ${(props) => props.theme.montserrat};
    background-color: #1c1c1e;
    overflow-y: scroll;
    z-index: 1000;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #3a3a3c;
      border-radius: 7px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(246, 92, 139);
    }

    &__header {
      display: flex;
      align-items: center;
      padding: 1rem 5rem;
      border-bottom: 1px solid rgba(112, 112, 112, 0.4);

      .backIcon {
        margin-right: 1rem;
        cursor: pointer;

        img {
          height: 1rem;
          width: auto;
        }
      }

      .name {
        font-size: 1.2rem;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    .infoText {
      font-size: 0.85rem;
      font-weight: 300;
      color: #f2f2f7;
      text-align: center;
      padding: 1rem 0;
    }

    &__subscriptionBox {
      width: 45%;
      min-width: 480px;
      margin: 0 auto 8rem auto;
      background-color: #2c2c2e;
      padding: 1.5rem 2rem;
      border-radius: 0.5rem;
      position: relative;

      h4 {
        font-size: 1.3rem;
      }
      p {
        width: 90%;
        font-size: 0.8rem;
      }

      &--currentSubscription {
        width: 100%;
        border-radius: 0.5rem;
        background: linear-gradient(to right bottom, #f9903d, #f75b8c);
        padding: 1.5rem 3rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 2rem;

        .role {
          font-family: brother-1816, sans-serif;
          font-size: 1.5rem;
          text-transform: uppercase;
          color: #fff;
          font-weight: 500;
          line-height: 1;
        }

        .userType {
          line-height: 1.3;
          font-size: 0.8rem;
          font-weight: 300;
        }

        .infoText {
          color: #fde5b8;
          font-size: 0.9rem;
          font-weight: 500;
          line-height: 1.2;
        }

        .plan {
          background-color: #fff;
          padding: 0.7rem 1.5rem;
          border-radius: 0.5rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          span {
            color: #d1d1d6;
            font-weight: 400;
            font-size: 0.65rem;
          }

          p {
            color: #f46a6a;
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1;
          }
          .subscription-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .subscription-text-small {
            cursor: pointer;
            font-size: small;
            font-weight: 300;
            margin-top: 5px;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &--options {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      &--optionCard {
        background-color: #3a3a3c;
        width: 48%;
        border-radius: 0.25rem;
        padding: 1.5rem;
        position: relative;
        cursor: pointer;
        margin: 2.5px;

        .title {
          color: #fff;
          font-size: 0.75rem;
          font-weight: 500;
        }
        .cardInfo {
          display: flex;
          font-size: small;
          justify-content: space-between;
          .default-card {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }

        .secondary-text {
          font-size: 0.9rem;
          font-weight: 300;
        }

        .actionText {
          color: #f9903d;
          font-weight: 500;
          font-size: 0.7rem;
        }

        .arrow {
          position: absolute;
          bottom: 0;
          right: 1rem;

          img {
            height: 0.8rem;
            width: auto;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .wrapper {
      &__header {
        padding: 0.8rem 2rem;
      }

      &__subscriptionBox {
        padding: 1rem;
        min-width: 95%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .wrapper {
      &__header {
        padding: 0.5rem 1.5rem;

        .name {
          font-size: 1rem;
        }
      }
    }
  }
`;
