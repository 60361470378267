import React, { useContext, useState, useEffect, useCallback } from "react";
import Input from "../../Elements/Input";
import Button from "../../Elements/Button";
import debounce from "lodash.debounce";
import api from "../../../services/api";
import PasswordStrengthBar from "react-password-strength-bar";
import { Row, Col } from "react-grid-system";
import { useMediaQuery } from "react-responsive";
import { AuthContext } from "../../../context/AuthContext";
import { elementScrollToTop } from "../../../utils";

const PersonalInformation = ({ setProgress }) => {
  const {
    setEmail,
    firstname,
    setFirstname,
    lastname,
    setLastname,
    username,
    setUsername,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    referralCode,
    setReferralCode,
    setValidationError,
    setAuthState,
  } = useContext(AuthContext);

  const [isValidUsername, setIsValidUsername] = useState(null);
  const [isValidReferral, setIsValidReferral] = useState(null);
  const [checkUsernameLoading, setCheckUsernameLoading] = useState(null);
  const [checkReferralLoading, setCheckReferralLoading] = useState(null);
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  useEffect(() => {
    document.getElementById("firstName").focus();
    elementScrollToTop("authContent");
  }, []);

  useEffect(() => {
    if (username && username.length >= 4) {
      checkUsername(username);
    }
  }, [username]);

  useEffect(() => {
    if (referralCode && referralCode.length >= 4) {
      checkReferralCode(referralCode);
    }
  }, [referralCode]);
  const nextHandler = () => {
    const passwordRegEx = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    );

    if (!firstname || !lastname || !username || !password) {
      return setValidationError({
        type: "error",
        message: "All fields are required.",
      });
    }

    const onlyLettersRegex = /^[a-zA-Z]+$/;
    const usernameRegex = /^[a-z0-9]([._-](?![._-])|[a-z0-9]){1,20}[a-z0-9]$/i;

    if (!onlyLettersRegex.test(firstname) || !onlyLettersRegex.test(lastname)) {
      return setValidationError({
        type: "error",
        message: "Only letters allowed in the firstname and lastname",
      });
    }

    if (password.length <= 7) {
      return setValidationError({
        type: "error",
        message: "Password must be greater than or equal to 8 characters.",
      });
    }

    if (passwordStrengthScore < 2 || !passwordRegEx.test(password)) {
      return setValidationError({
        type: "error",
        message:
          "Password must be at least 8 characters long, contain at least 1 Number, 1 Upper case letter & 1 Special character.",
      });
    }

    if (password !== confirmPassword) {
      return setValidationError({
        type: "error",
        message: "Passwords do not match.",
      });
    }

    if (!usernameRegex.exec(username)) {
      return setValidationError({
        type: "error",
        message:
          "Usernames can be made up of only letters & numbers (underscores or periods between words is fine!)",
      });
    }

    if (!isValidUsername) {
      return setValidationError({
        type: "error",
        message: "Username is already picked, Please choose another one. ",
      });
    }

    setValidationError(null);
    setProgress(2);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextHandler();
      document.getElementById("triggerBtn").click();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, []);

  // Hit the database for username match after each debounced change
  // useCallback is required for debounce to work

  const checkReferralCode = useCallback(
    debounce(async (checkReferralCode) => {
      if (checkReferralCode.length >= 4) {
        try {
          setCheckReferralLoading(true);
          setIsValidReferral(false);

          const res = await api.get(
            `auth/isReferralCodeExist?referralCode=${checkReferralCode}`
          );
          if (res.data.data && res.data.role === 2) {
            setCheckReferralLoading(false);
            setIsValidReferral(false);
          } else {
            setCheckReferralLoading(false);
            setIsValidReferral(true);
          }
        } catch (error) {
          if (error) {
            setCheckReferralLoading(false);
            setIsValidReferral(true);
          }
        }
      }
    }, 600),
    []
  );

  const checkUsername = useCallback(
    debounce(async (checkUsername) => {
      if (checkUsername.length >= 4) {
        try {
          setCheckUsernameLoading(true);
          setIsValidUsername(false);

          const res = await api.get(
            `auth/isUserNameExist?userName=${checkUsername}`
          );

          if (res.data) {
            setCheckUsernameLoading(false);
            setIsValidUsername(false);
          }
        } catch (error) {
          if (error) {
            setCheckUsernameLoading(false);
            setIsValidUsername(true);
          }
        }
      }
    }, 600),
    []
  );

  return (
    <div className="paddingBox">
      <div className="title">
        Enter your details
        <div
          onClick={() => {
            setAuthState("CHECK_EMAIL");
            setEmail("");
            setFirstname("");
            setLastname("");
            setUsername("");
            setPassword("");
            setConfirmPassword("");
            setReferralCode("");
          }}
          className="alternateOption"
        >
          Use another email ?
        </div>
      </div>

      <Row className="row row__one">
        <Col
          md={6}
          sm={6}
          xs={6}
          style={{
            paddingRight: `${isMobile ? "7px" : "12px"}`,
          }}
        >
          <div className="field">
            <Input
              label="First name"
              name="firstName"
              id="firstName"
              placeholder="Enter your first name"
              type="text"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
          </div>
        </Col>

        <Col
          md={6}
          sm={6}
          xs={6}
          style={{
            paddingLeft: `${isMobile ? "7px" : "12px"}`,
          }}
        >
          <div className="field">
            <Input
              label="Last name"
              name="lastName"
              id="lastName"
              placeholder="Enter your last name"
              type="text"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>
        </Col>

        <Col md={12}>
          <div className="field">
            <Input
              label="Username"
              name="username"
              id="username"
              placeholder="Choose your Username (min length: 4 digits)"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              usernameValidate={true}
              usernameCheckLoading={checkUsernameLoading}
              usernameStatus={isValidUsername}
            />
          </div>
        </Col>
      </Row>

      <div className="row row__two">
        <div className="subTitle">Create password</div>
        <p className="paragraph">
          Use a minimum of 10 characters including uppercase letters, lowercase
          letters and numbers
        </p>

        <div className="field">
          <Input
            label="Password"
            name="password"
            id="password"
            placeholder="Enter password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          {password && (
            <div className="passwordStrength">
              <PasswordStrengthBar
                password={password}
                onChangeScore={(score) => setPasswordStrengthScore(score)}
              />
            </div>
          )}
        </div>
        <div className="field">
          <Input
            label="Confirm Password"
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Confirm your password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <div className="field">
          <Input
            label="Referral Code"
            name="referralCode"
            id="referralCode"
            placeholder="Enter any Freeloader’s username to reward them! (Optional)"
            type="text"
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
            referralvalidate={true}
            referralCheckLoading={checkReferralLoading}
            referralStatus={isValidReferral}
          />
        </div>

        {!isValidReferral ? (
          <Button
            mode="primary"
            width="fluid"
            variant="round"
            size="medium"
            onClick={nextHandler}
            className="submit"
            id="triggerBtn"
          >
            Continue
          </Button>
        ) : (
          <Button
            mode="primary"
            width="fluid"
            variant="round"
            size="medium"
            className="submit"
            disabled
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default PersonalInformation;
