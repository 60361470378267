export const momentDetailsLeftVarients = {
  hidden: {
    x: "-100vw",
  },
  visible: {
    x: 0,
    transition: {
      type: "spring",
      duration: 1.2,
    },
  },
};

export const momentDetailsRightVarients = {
  hidden: {
    x: "100vw",
  },
  visible: {
    x: 0,
    transition: {
      type: "spring",
      duration: 1.2,
    },
  },
};

export const momentDetailsCenterVarients = {
  hidden: {
    opacity: 0,
    y: "100vh",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 1.2,
    },
  },
};
