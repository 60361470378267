import {
  GET_MARKETING_PAGE_REQUEST,
  GET_MARKETING_PAGE_SUCCESS,
  GET_MARKETING_PAGE_FAIL,
  ADD_MARKETING_WHYUS_SECTION_REQUEST,
  ADD_MARKETING_WHYUS_SECTION_SUCCESS,
  ADD_MARKETING_WHYUS_SECTION_FAIL,
  ADD_MARKETING_PRICING_SECTION_REQUEST,
  ADD_MARKETING_PRICING_SECTION_SUCCESS,
  ADD_MARKETING_PRICING_SECTION_FAIL,
  ADD_MARKETING_FAQ_ITEM_REQUEST,
  ADD_MARKETING_FAQ_ITEM_SUCCESS,
  ADD_MARKETING_FAQ_ITEM_FAIL,
  REMOVE_MARKETING_FAQ_ITEM_SUCCESS,
  ADD_MARKETING_STATS_SECTION_REQUEST,
  ADD_MARKETING_STATS_SECTION_SUCCESS,
  ADD_MARKETING_STATS_SECTION_FAIL,
  ADD_MARKETING_ABOUT_SECTION_REQUEST,
  ADD_MARKETING_ABOUT_SECTION_SUCCESS,
  ADD_MARKETING_ABOUT_SECTION_FAIL,
  ADD_MARKETING_FOOTER_SECTION_REQUEST,
  ADD_MARKETING_FOOTER_SECTION_SUCCESS,
  ADD_MARKETING_FOOTER_SECTION_FAIL,
  RESET_MARKETING_PAGE_STATE,
} from "../actions/marketing/type";

const marketingPage = (state = {}, action) => {
  switch (action.type) {
    case GET_MARKETING_PAGE_REQUEST:
      return {
        loading: true,
      };
    case GET_MARKETING_PAGE_SUCCESS:
      return {
        loading: false,
        data: action.payload.data,
        order: action.payload.order,
      };
    case GET_MARKETING_PAGE_FAIL:
      return {
        loading: false,
        data: null,
      };
    case ADD_MARKETING_WHYUS_SECTION_REQUEST:
    case ADD_MARKETING_PRICING_SECTION_REQUEST:
    case ADD_MARKETING_FAQ_ITEM_REQUEST:
    case ADD_MARKETING_STATS_SECTION_REQUEST:
    case ADD_MARKETING_ABOUT_SECTION_REQUEST:
    case ADD_MARKETING_FOOTER_SECTION_REQUEST:
      return {
        saveLoading: true,
        data: state.data,
      };
    case ADD_MARKETING_WHYUS_SECTION_SUCCESS:
      const whyus = state.data["why choose us?"];

      const updatedWhyus = whyus?.length
        ? whyus.map((item) => {
            if (item?.id === action.payload.id) {
              return action.payload;
            } else {
              return item;
            }
          })
        : [action.payload];

      return {
        saveLoading: false,
        saveSuccess: true,
        data: {
          ...state.data,
          "why choose us?": updatedWhyus,
        },
      };

    case ADD_MARKETING_WHYUS_SECTION_FAIL:
      return {
        saveLoading: false,
        saveError: action.payload,
        data: state.data,
      };

    case ADD_MARKETING_PRICING_SECTION_FAIL:
    case ADD_MARKETING_FAQ_ITEM_FAIL:
    case ADD_MARKETING_STATS_SECTION_FAIL:
    case ADD_MARKETING_ABOUT_SECTION_FAIL:
    case ADD_MARKETING_FOOTER_SECTION_FAIL:
      return {
        saveLoading: false,
        saveError: action.payload,
        data: state.data,
      };
    case ADD_MARKETING_FAQ_ITEM_SUCCESS:
      const faqs = state.data["faq"];
      const updatedFaqs = faqs?.length
        ? [...faqs, action.payload]
        : [action.payload];

      return {
        saveLoading: false,
        saveSuccess: true,
        data: {
          ...state.data,
          faq: updatedFaqs,
        },
      };

    case REMOVE_MARKETING_FAQ_ITEM_SUCCESS:
      const faqsV2 = state.data["faq"];
      const updatedFaqsV2 = faqsV2.filter((el) => el.id !== action.payload);

      return {
        removeSuccess: true,
        data: {
          ...state.data,
          faq: updatedFaqsV2,
        },
      };

    case ADD_MARKETING_STATS_SECTION_SUCCESS:
      const stats = state.data["stats"];
      const updatedStats = stats?.length
        ? stats.map((item) => {
            if (item.id === action.payload.id) {
              return action.payload;
            } else {
              return item;
            }
          })
        : [action.payload];

      return {
        saveLoading: false,
        saveSuccess: true,
        data: {
          ...state.data,
          stats: updatedStats,
        },
      };

    case ADD_MARKETING_PRICING_SECTION_SUCCESS:
      const pricing = state.data["pricing"];
      const updatedPricing = pricing?.length
        ? pricing.map((item) => {
            if (item.id === action.payload.id) {
              return action.payload;
            } else {
              return item;
            }
          })
        : [action.payload];
      return {
        saveLoading: false,
        saveSuccess: true,
        data: {
          ...state.data,
          pricing: updatedPricing,
        },
      };

    case ADD_MARKETING_ABOUT_SECTION_SUCCESS:
      const updatedAbout = action.payload;

      return {
        saveLoading: false,
        saveSuccess: true,
        data: {
          ...state.data,
          "about us": updatedAbout,
        },
      };

    case ADD_MARKETING_FOOTER_SECTION_SUCCESS:
      const updatedFooter = action.payload;

      return {
        saveLoading: false,
        saveSuccess: true,
        data: {
          ...state.data,
          footer: updatedFooter,
        },
      };

    case RESET_MARKETING_PAGE_STATE:
      return {
        data: state.data,
      };
    default:
      return state;
  }
};

export default marketingPage;
