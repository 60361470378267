import {
    GET_USER_CHANNEL_REQUEST,
    GET_USER_CHANNELS_SUCCESS,
    GET_USER_CHANNELS_HASMORE
  } from "../actions/types";
  
  const userChannels = (state = { loading: true }, action) => {
    switch (action.type) {
      case GET_USER_CHANNEL_REQUEST:
        return {
          loading: true,
        };
        case GET_USER_CHANNELS_SUCCESS :
        return {
            loading : false,
            channels : action.payload,
            totalpage: action.length
        }
        case GET_USER_CHANNELS_HASMORE : 
        const allChannels = [...state.channels, ...action.payload];
      return {
        loading: false,
        channels: allChannels,
        totalpage: action.length
      };
      default:
        return state;
    }
  };
  
  export default userChannels;
  