import {
  GET_ALL_SERIES_OF_USER_SUCCESS,
  GET_ALL_SERIES_OF_USER_FAIL,
  GET_ALL_SERIES_OF_USER_REQUEST,
  GET_ALL_SERIES_OF_USER_RESET,
  GET_ALL_SERIES_OF_USER_SUCCESS_SECOND,
} from "../actions/types";

const getAllSeriesOfUser = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_SERIES_OF_USER_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_SERIES_OF_USER_SUCCESS:
      return {
        loading: false,
        length: action.length,
        series: action.payload,
      };
    case GET_ALL_SERIES_OF_USER_SUCCESS_SECOND:
      const allSeries = [...state.series, ...action.payload]
      return {
        loading: false,
        length: action.length,
        series: allSeries,
      };
    case GET_ALL_SERIES_OF_USER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case GET_ALL_SERIES_OF_USER_RESET:
      return {
        loading: false,
        error: null,
        series: null,
      };
    default:
      return state;
  }
};

export default getAllSeriesOfUser;
