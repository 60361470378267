import {
  PROFILE_ELEMENT_MOMENTS,
  PROFILE_ELEMENT_MOMENTS_SECOND
} from "../actions/types";


const profileElementsMoment = (state = { loading: true }, action) => {
  switch (action.type) {
    case PROFILE_ELEMENT_MOMENTS:
      return {
        loading: false,
        usermoments: action.payload,
        momenttotalpage: action.length,
      };
    case PROFILE_ELEMENT_MOMENTS_SECOND:
      const allMoments = [...state.usermoments, ...action.payload];
      return {
        loading: false,
        usermoments: allMoments,
        momenttotalpage: action.length,
      };
    default:
      return state;
  }
};

export default profileElementsMoment;
