import React, { useState, useContext, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { styled } from "@material-ui/core/styles";
import HomeTab from "../HomeTab";
import VideosTab from "../VideosTab/VideosTab";
import ChannelsTab from "../ChannelsTab/ChannelsTab";
import PlaylistsTab from "../PlaylistsTab/PlaylistsTab";
import SeriesTab from "../SeriesTab/SeriesTab";
import MomentsTab from "../MomentsTab/MomentsTab";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../../context/GlobalContext";

import ThreadsTab from "../ThreadsTab/ThreadsTab";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#1C1C1E",
    color: "white",
    position: "relative",
    bottom: "4rem",
    [theme.breakpoints.up("xs")]: {
      bottom: "3rem",
    },
  },
  tab: {
    textDecoration: "none",
    textTransform: "none",
    [theme.breakpoints.between(0, 500)]: {
      padding: "6px 5px",
      fontSize: "10px",
      minWidth: "15%",
      fontFamily: "Montserrat",
      color: "#F2F2F7",
      lineHeight: "13px",
    },
    [theme.breakpoints.between(500, "sm")]: {
      padding: "6px 5px",
      fontSize: "10px",
      minWidth: "10%",
      fontFamily: "Montserrat",
      color: "#F2F2F7",
      lineHeight: "13px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "6px 5px",
      minWidth: "10%",
      fontFamily: "Montserrat",
      color: "#F2F2F7",
      lineHeight: "13px",
    },
  },

  tabPanel: {
    backgroundColor: "red !important",
  },
}));

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 37,
    width: "100%",
    backgroundColor: "white",
  },
  "& .MuiTabs-fixed": {
    height: 37,
  },
});

function PortalTabs() {
  const classes = useStyles();
  const profile = useSelector((state) => state.profile);
  const { portalTab, setPortalTab } = useContext(GlobalContext);
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setPortalTab(newValue);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get("tab");

    if (tabParam && !isNaN(tabParam)) {
      const tabIndex = parseInt(tabParam);
      if (tabIndex >= 0 && tabIndex <= 5) {
        setPortalTab(tabIndex);
      } else {
        setPortalTab(0);
      }
    } else {
      setPortalTab(0);
    }
  }, [location.search, setPortalTab]);

  if (profile.isFetching) {
    return null;
  }

  if (profile?.userrole === 0) {
    return (
      <Paper className={classes.root}>
        <StyledTabs
          value={portalTab}
          onChange={handleChange}
          indicatorColor="secondary"
          centered
        >
          <Tab
            label="Channels"
            {...a11yProps(0)}
            classes={{ root: classes.tab }}
          />
          <Tab
            label="Playlists"
            {...a11yProps(1)}
            classes={{ root: classes.tab }}
          />
        </StyledTabs>

        <TabPanel value={portalTab} index={0}>
          <ChannelsTab />
        </TabPanel>
        <TabPanel value={portalTab} index={1}>
          <PlaylistsTab switchTab={setPortalTab} />
        </TabPanel>
      </Paper>
    );
  }

  if (profile?.userrole === 1) {
    return (
      <>
        <Paper className={classes.root}>
          <StyledTabs
            value={portalTab}
            onChange={handleChange}
            indicatorColor="secondary"
            centered
          >
            <Tab
              label="Moments"
              {...a11yProps(0)}
              classes={{ root: classes.tab }}
            />
            <Tab
              label="Streams"
              {...a11yProps(1)}
              classes={{ root: classes.tab }}
            />
            <Tab
              label="Playlists"
              {...a11yProps(2)}
              classes={{ root: classes.tab }}
            />
          </StyledTabs>

          <TabPanel value={portalTab} index={0}>
            <MomentsTab />
          </TabPanel>
          <TabPanel value={portalTab} index={1}>
            <ChannelsTab />
          </TabPanel>
          <TabPanel value={portalTab} index={2}>
            <PlaylistsTab switchTab={setPortalTab} />
          </TabPanel>
        </Paper>
      </>
    );
  }

  return (
    <>
      <Paper className={classes.root}>
        <StyledTabs
          value={portalTab}
          onChange={handleChange}
          indicatorColor="secondary"
          centered
        >
          <Tab label="Home" {...a11yProps(0)} classes={{ root: classes.tab }} />
          <Tab
            label="Videos"
            {...a11yProps(1)}
            classes={{ root: classes.tab }}
          />
          <Tab
            label="Moments"
            {...a11yProps(2)}
            classes={{ root: classes.tab }}
            disabled={profile?.userrole === 0 ? true : false}
          />
          <Tab
            label="Threads"
            {...a11yProps(3)}
            classes={{ root: classes.tab }}
            disabled={profile?.userrole === 0 ? true : false}
          />
          <Tab
            label="Series"
            {...a11yProps(4)}
            classes={{ root: classes.tab }}
          />
          <Tab
            label="Streams"
            {...a11yProps(5)}
            classes={{ root: classes.tab }}
          />
        </StyledTabs>
        <TabPanel value={portalTab} index={0}>
          <HomeTab />
        </TabPanel>
        <TabPanel value={portalTab} index={1}>
          <VideosTab />
        </TabPanel>
        <TabPanel value={portalTab} index={2}>
          <MomentsTab />
        </TabPanel>
        <TabPanel value={portalTab} index={3}>
          <ThreadsTab />
        </TabPanel>
        <TabPanel value={portalTab} index={4}>
          <SeriesTab />
        </TabPanel>
        <TabPanel value={portalTab} index={5}>
          <ChannelsTab />
        </TabPanel>
      </Paper>
    </>
  );
}

export default PortalTabs;
