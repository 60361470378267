import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Input from "../../components/Elements/Input";
import Button from "../../components/Elements/Button";
import { Row, Col } from "react-grid-system";
import { addVideoOverlay } from "../../actions";
import { uploadImage } from "../../utils";
import { toast } from "react-toastify";

const AddAccessOverlay = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState("");
  const [videoAccesData, setVideoAccesData] = useState({
    keyVideoAccess: "",
    name: "",
    description: "",
    keyVideoAccess: "",
    imgPathMember: "",
    imgPathFreeLoader: "",
  });
  const handleImageChange = async (e, imagePathType) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "jpeg", "png"];

    if (!allowedFileTypes.includes(fileExtension)) {
      toast.error(
        `Image extension does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
      return;
    }
    toast.info("Uploading...");
    setSelectedImage(file.name);

    if (file) {
      try {
        const { url } = await uploadImage("image", file);
        setVideoAccesData({
          ...videoAccesData,
          [imagePathType]: file.name,
        });

        toast.success("Image uploaded successfully");
      } catch (error) {
        toast.error("Image upload failed. Please try again.");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVideoAccesData({ ...videoAccesData, [name]: value });
  };
  const handleSubmit = () => {
    dispatch(addVideoOverlay(videoAccesData));
    onClose();
  };

  return (
    <AddVideoAccessModal open={isOpen}>
      <AddVideoAccessEditContent>
        <div className="title">Add Video Access Overlay</div>
        <div className="field">
          <Row className="row row__one">
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Name"
                  name="name"
                  id="name"
                  placeholder="name"
                  type="text"
                  value={videoAccesData.name}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="description"
                  name="description"
                  id="description"
                  placeholder="description"
                  type="text"
                  value={videoAccesData.description}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="keyVideoAccess"
                  name="keyVideoAccess"
                  id="keyVideoAccess"
                  placeholder="keyVideoAccess"
                  type="number"
                  value={videoAccesData.keyVideoAccess}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <input
                  label="imgPathFreeLoader"
                  name="imgPathFreeLoader"
                  id="imgPathFreeLoader"
                  placeholder="/assets/icons/image.jpg&.svg"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, "imgPathFreeLoader")}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <input
                  label="imgPathMember"
                  name="imgPathMember"
                  id="imgPathMember"
                  placeholder="/assets/icons/image.jpg&.svg"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, "imgPathMember")}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Row className="row row__one">
          <Col md={6} sm={6} xs={6}>
            <Button mode={"secondary"} className="submit" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <Button mode={"primary"} className="submit" onClick={handleSubmit}>
              Save
            </Button>
          </Col>
        </Row>
      </AddVideoAccessEditContent>
    </AddVideoAccessModal>
  );
};

export default AddAccessOverlay;

const AddVideoAccessModal = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddVideoAccessEditContent = styled.div`
  background: #2c2c2e;
  color: white;
  border: 1px solid white;
  padding: 20px;
  max-width: 800px;
  width: 100%;

  .imgaeInput {
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px;
    width: 100%;
    color: #2c2c2e;
    margin-bottom: 0px;
    background: aliceblue;
    margin-top: 10px;
  }

  .title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .submit {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdownBox {
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    margin-top: 3px;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;
