import {
  GET_KARMA_SENT_REQ,
  GET_KARMA_SENT_TRANSACTIONS,
  GET_KARMA_SENT_TRANSACTIONS_FAILED,
  GET_USER_TRANSACTIONS,
  GET_USER_TRANSACTIONS_FAILED,
  GET_USER_TRANSACTIONS_REQ,
  KARMA_RECEIVED_FAILED,
  KARMA_RECEIVED_REQUEST,
  KARMA_RECEIVED_SUCCESS,
  SET_FILTERED_DATA,
  SET_KARMA_FILTERED_DATA,
} from "../actions/types";

const initialState = {
  isLoading: false,
  data: [],
  karmaSent: [],
  karmaReceived: [],
};

const userTransactions = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_TRANSACTIONS_REQ:
      return { ...state, isLoading: true };
    case GET_USER_TRANSACTIONS:
      let updatedData = state.data.concat(action.payload);
      function getUniqueListBy(arr, key) {
        return [...new Map(arr.map((item) => [item[key], item])).values()];
      }
      const uniqueTransactions = getUniqueListBy(updatedData, "id");
      return { ...state, data: uniqueTransactions, isLoading: false };
    case GET_USER_TRANSACTIONS_FAILED:
      return { ...state, isLoading: false };
    case GET_KARMA_SENT_REQ:
      return { ...state, isLoading: true };
    case GET_KARMA_SENT_TRANSACTIONS:
      let updatedKarmaData = state.karmaSent.concat(action.payload);

      const arrNew = getUniqueList(updatedKarmaData, "id");
      return { ...state, karmaSent: arrNew, isLoading: false };
    case GET_KARMA_SENT_TRANSACTIONS_FAILED:
      return { ...state, isLoading: false };
    case SET_FILTERED_DATA:
      return { ...state, data: action.payload.data, isLoading: false };
    case KARMA_RECEIVED_REQUEST:
      return { ...state, isLoading: true };
    case KARMA_RECEIVED_SUCCESS:
      let receivedKarma = state.karmaReceived.concat(action.payload);
      const karmaReceivedUnique = getUniqueList(receivedKarma, "id");
      return { ...state, karmaReceived: karmaReceivedUnique, isLoading: false };
    case KARMA_RECEIVED_FAILED:
      return { ...state, isLoading: false };
    case SET_KARMA_FILTERED_DATA:
      return {
        ...state,
        karmaSent: action.payload.karmaSent,
        karmaReceived: action.payload.karmaReceived,
        isLoading: false,
      };

    default:
      return state;
  }
  function getUniqueList(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }
};

export default userTransactions;
