export const CREATE_THREAD_REQUEST = "CREATE_THREAD_REQUEST";
export const CREATE_THREAD_SUCCESS = "CREATE_THREAD_SUCCESS";
export const CREATE_THREAD_FAIL = "CREATE_THREAD_FAIL";

export const DELETE_THREAD_REQUEST = "DELETE_THREAD_REQUEST";
export const DELETE_THREAD_SUCCESS = "DELETE_THREAD_SUCCESS";
export const DELETE_THREAD_FAIL = "DELETE_THREAD_FAIL";

export const RESET_THREAD_STATE = "RESET_THREAD_STATE";

export const GET_ALL_THREADS_REQUEST = "GET_ALL_THREADS_REQUEST";
export const GET_ALL_THREADS_SUCCESS = "GET_ALL_THREADS_SUCCESS";
export const GET_ALL_THREADS_FAIL = "GET_ALL_THREADS_FAIL";
export const CLEAR_THREADS = "CLEAR_THREADS";


export const GET_SINGLE_THREAD_REQUEST = "GET_SINGLE_THREAD_REQUEST";
export const GET_SINGLE_THREAD_SUCCESS = "GET_SINGLE_THREAD_SUCCESS";
export const GET_SINGLE_THREAD_FAIL = "GET_SINGLE_THREAD_FAIL";

export const GET_HOME_FEATURED_THREADS_REQUEST =
  "GET_HOME_FEATURED_THREADS_REQUEST";
export const GET_HOME_FEATURED_THREADS_SUCCESS =
  "GET_HOME_FEATURED_THREADS_SUCCESS";
export const GET_HOME_FEATURED_THREADS_FAIL = "GET_HOME_FEATURED_THREADS_FAIL";

export const GET_SINGLE_THREAD_REQUEST_V2 = "GET_SINGLE_THREAD_REQUEST_v2";
export const GET_SINGLE_THREAD_SUCCESS_V2 = "GET_SINGLE_THREAD_SUCCESS_V2";
export const GET_SINGLE_THREAD_FAIL_V2 = "GET_SINGLE_THREAD_FAIL_V2";

export const UPDATE_THREAD_REQUEST = "UPDATE_THREAD_REQUEST";
export const UPDATE_THREAD_SUCCESS = "UPDATE_THREAD_SUCCESS";
export const UPDATE_THREAD_FAIL = "UPDATE_THREAD_FAIL";

export const ADD_COMMENT_REQUEST = "ADD_COMMENT_REQUEST";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAIL = "ADD_COMMENT_FAIL";

export const REPLY_COMMENT_REQUEST = "REPLY_COMMENT_REQUEST";
export const REPLY_COMMENT_SUCCESS = "REPLY_COMMENT_SUCCESS";
export const REPLY_COMMENT_FAIL = "REPLY_COMMENT_FAIL";

export const GET_COMMENTS_REQUEST = "GET_COMMENTS_REQUEST";
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS";
export const GET_COMMENTS_FAIL = "GET_COMMENTS_FAIL";

export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAIL = "DELETE_COMMENT_FAIL";

export const UPDATE_COMMENT_REQUEST = "UPDATE_COMMENT_REQUEST";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAIL = "UPDATE_COMMENT_FAIL";

export const REPLY_UPDATE_COMMENT_REQUEST = "REPLY_UPDATE_COMMENT_REQUEST";
export const REPLY_UPDATE_COMMENT_SUCCESS = "REPLY_UPDATE_COMMENT_SUCCESS";
export const REPLY_UPDATE_COMMENT_FAIL = "REPLY_UPDATE_COMMENT_FAIL";

export const DELETE_REPLY_COMMENT_REQUEST = "DELETE_REPLY_COMMENT_REQUEST";
export const DELETE_REPLY_COMMENT_SUCCESS = "DELETE_REPLY_COMMENT_SUCCESS";
export const DELETE_REPLY_COMMENT_FAIL = "DELETE_REPLY_COMMENT_FAIL";

export const GET_THREADS_OF_USER_REQUEST = "GET_THREADS_OF_USER_REQUEST";
export const GET_THREADS_OF_USER_SUCCESS = "GET_THREADS_OF_USER_SUCCESS";
export const GET_THREADS_OF_USER_FAIL = "GET_THREADS_OF_USER_FAIL";

export const GET_STREAMS_THREADS_REQUEST = "GET_STREAMS_THREADS_REQUEST";
export const GET_STREAMS_THREADS_SUCCESS = "GET_STREAMS_THREADS_SUCCESS";
export const GET_STREAMS_THREADS_FAIL = "GET_STREAMS_THREADS_FAIL";

export const RESET_COMMENT_STATE = "RESET_COMMENT_STATE";

export const GET_LIKED_THREADS_REQUEST = "GET_LIKED_THREADS_REQUEST";
export const GET_LIKED_THREADS_SUCCESS = "GET_LIKED_THREADS_SUCCESS";
export const GET_LIKED_THREADS_FAIL = "GET_LIKED_THREADS_FAIL";
