import React, { useEffect, useContext, useState } from "react";
import MarketingBanner from "../../NewHomeComponents/MarketingBanner/MarketingBanner";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrendingVideos,
  getMarketingBanners,
  getAllSeriesOfUser,
  getProfileElements,
  getUserProfileElemtMoments,
  getUserProfileElemtMomentsHasMore,
  getUserProfileElemtVideos,
  getUserProfileElemtVideosHasMore,
  getUserProfileElemtSeries,
  getUserProfileElemtSeriesHasMore,
  getUserProfileElemtThreads,
  getUserProfileElemtThreadsHasMore,
  getUserProfileElemtBanners,
  getUserProfileElemtBannersHasMore,
} from "../../../actions";
import {
  TabHomeComponent,
  TaglineAndBioSection,
  FeaturedSection,
} from "./style";
import MomentCard from "../../Cards/MomentCard";
import VideoCard from "../../Cards/VideoCard";
import EditClipForm from "../../UploadClipComponent/EditClipForm";
import EditMomentForm from "../../UploadMomentComponents/EditMomentForm";
import Error from "../../Elements/Error";
import SeriesCard from "../../Cards/SeriesCard";
import EditSeriesModel from "../Models/EditSeriesModel";
import ProfileElementsSkeleton from "../../../skeletons/General/ProfileElements";
import { GlobalContext } from "../../../context/GlobalContext";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { notificationMessage } from "../../../utils/toastNotifications";
import { RESET_MOMENT, EDIT_SERIES_RESET } from "../../../actions/types";
import { useInView } from "react-intersection-observer";
import config from "../../../config";
import {
  SLIDERSETTINGV1,
  SLIDERSETTING_MOBILE,
  SLIDERSETTING_MOMENT,
  SLIDERSETTING_MOMENT_MOBILE,
  SLIDERSETTING_MOMENT_MOBILE_V1,
  SLIDERSETTING_MOMENT_MOBILE_V2,
} from "../../../utils/sliderSettings";
import {
  VIDEO_SELF_PORTAL,
  VIDEO_OTHER,
  MOMENT_SELF_PORTAL,
  MOMENTS_OTHER,
  SERIES_OTHER,
  SERIES_SELF_PORTAL,
} from "../../../utils/menuOptions";
import { useMediaQuery } from "react-responsive";
import ThreadCardSmall from "../../Cards/ThreadCardSmall";
import Helmet from "react-helmet";
import ChannelPreviewVideo from "../ChannelPreviewVideo";

function HomeTab() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  const { userIdOrUserName } = useParams();
  const { banners } = useSelector((state) => state.marketingBanner);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [selectedMoment, setSelectedMoment] = useState(null);
  const [momentId, setMomentId] = useState(null);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [seriesId, setSeriesId] = useState(null);
  const [midDrag, setMidDrag] = useState(false);
  const { ref: trackerRef, inView: trackerIsVisible } = useInView();
  const { ref: trackerRefVideo, inView: trackerIsVisibleVideo } = useInView();
  const { ref: trackerRefSeries, inView: trackerIsVisibleSeries } = useInView();
  const { ref: trackerRefThreads, inView: trackerIsVisibleThreads } =
    useInView();
  const { ref: trackerRefBanners, inView: trackerIsVisibleBanners } =
    useInView();

  const { setShowEditClipModel, setShowEditMomentModel, setEditSeriesModel } =
    useContext(GlobalContext);

  const {
    getData: profileElements,
    getError,
    getLoading,
  } = useSelector((state) => state.profileElements);

  const {
    usermoments: profileElementsMoment,
    loading: MomentsLoading,
    momenttotalpage: momenttotalpage,
  } = useSelector((state) => state.profileElementsMoment);

  const {
    uservideos: profileElementsVideo,
    loading: videoloading,
    totalPage: videototalpage,
  } = useSelector((state) => state.profileElementsVideo);

  const {
    userseries: profileElementsSeries,
    loading: seriesloading,
    totalPage: seriestotalpage,
  } = useSelector((state) => state.profileElementsSeries);

  const {
    usersthreads: profileElementsThreads,
    loading: threadsloading,
    totalPage: threadstotalpage,
  } = useSelector((state) => state.profileElementsThreads);
  const {
    usersbanners: profileElementsBanners,
    loading: bannersloading,
    totalPage: bannerstotalpage,
  } = useSelector((state) => state.profileElementsBanners);

  const user = useSelector((state) => state.user);
  const { updateMessage: momentUpdateMessage } = useSelector(
    (state) => state.moment
  );
  const { message: seriesUpdateMessage } = useSelector(
    (state) => state.editSeries
  );

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  const [momentPage, setMomentPage] = useState(1);
  const [momentLimit, setMomentLimit] = useState(4);

  const [videoPage, setVideoPage] = useState(1);
  const [videotLimit, setVideoLimit] = useState(4);

  const [seriesPage, setSeriesPage] = useState(1);
  const [seriesLimit, setSeriesLimit] = useState(4);

  const [threadsPage, setThreadsPage] = useState(1);
  const [threadsLimit, setThreadsLimit] = useState(4);

  const [bannersPage, setBannersPage] = useState(1);
  const [bannersLimit, setBannersLimit] = useState(4);

  useEffect(() => {
    if (momentUpdateMessage) {
      toast.success(notificationMessage.momentEdited);
      dispatch({
        type: RESET_MOMENT,
      });

      setShowEditMomentModel(false);

      if (profile && profile?.userrole === 2) {
        dispatch(getProfileElements(userIdOrUserName, user?.id));
      }
    }

    if (seriesUpdateMessage) {
      dispatch({
        type: EDIT_SERIES_RESET,
      });

      setEditSeriesModel(false);

      if (profile && profile?.userrole === 2) {
        dispatch(getProfileElements(userIdOrUserName, user?.id));
      }
    }
  }, [momentUpdateMessage, seriesUpdateMessage]);

  useEffect(() => {
    if (profile && profile?.userrole === 2) {
      const momentObj = {
        skip: momentPage,
        limit: momentLimit,
      };
      dispatch(
        getUserProfileElemtMoments(userIdOrUserName, user?.id, momentObj)
      );

      const videoovj = {
        skip: videoPage,
        limit: videotLimit,
      };
      dispatch(getUserProfileElemtVideos(userIdOrUserName, user?.id, videoovj));

      const seriesobj = {
        skip: seriesPage,
        limit: seriesLimit,
      };
      dispatch(
        getUserProfileElemtSeries(userIdOrUserName, user?.id, seriesobj)
      );

      const threadsobj = {
        skip: threadsPage,
        limit: threadsLimit,
      };
      dispatch(
        getUserProfileElemtThreads(userIdOrUserName, user?.id, threadsobj)
      );

      const bannersobj = {
        skip: bannersPage,
        limit: bannersLimit,
      };
      dispatch(
        getUserProfileElemtBanners(userIdOrUserName, user?.id, bannersobj)
      );
    }
  }, []);

  useEffect(() => {
    dispatch(getMarketingBanners());
    dispatch(getTrendingVideos());
  }, []);

  useEffect(() => {
    if (profile && profile.userrole === 2) {
      dispatch(getAllSeriesOfUser(profile?.username));
    }
  }, [profile]);

  const URL_REGEX = /(https?:\/\/[^\s]+)/g;
  function CheckHyperLinks(description) {
    const desc = description.replace(/\n/g, " <br/> ");
    const words = desc.split(" ");
    const results = [];
    words.forEach((index) => {
      if (index.match(URL_REGEX)) {
        results.push(
          `<a href=${index} target="_blank" style="color:#4b4bbb">${index}</a>`
        );
      } else {
        results.push(`${index}`);
      }
    });
    const mainstring = results.toString();
    return mainstring.replace(/\,/g, " ");
  }

  useEffect(() => {
    if (trackerIsVisible) {
      setMomentPage(momentPage + 1);
      const momentObj = {
        skip: momentPage + 1,
        limit: momentLimit,
      };
      const pageSize = Math.ceil(momenttotalpage);
      if (pageSize) {
        if (momentPage < pageSize) {
          dispatch(
            getUserProfileElemtMomentsHasMore(
              userIdOrUserName,
              user?.id,
              momentObj
            )
          );
        }
      }
    }
  }, [trackerIsVisible]);

  useEffect(() => {
    if (trackerIsVisibleVideo) {
      setVideoPage(momentPage + 1);
      const videoovj = {
        skip: videoPage + 1,
        limit: videotLimit,
      };

      const pageSize = Math.ceil(videototalpage);
      if (pageSize) {
        if (videoPage < pageSize) {
          dispatch(
            getUserProfileElemtVideosHasMore(
              userIdOrUserName,
              user?.id,
              videoovj
            )
          );
        }
      }
    }
  }, [trackerIsVisibleVideo]);

  useEffect(() => {
    if (trackerIsVisibleSeries) {
      setSeriesPage(momentPage + 1);
      const seriesobj = {
        skip: seriesPage + 1,
        limit: seriesLimit,
      };
      const pageSize = Math.ceil(seriestotalpage);
      if (pageSize) {
        if (seriesPage < pageSize) {
          dispatch(
            getUserProfileElemtSeriesHasMore(
              userIdOrUserName,
              user?.id,
              seriesobj
            )
          );
        }
      }
    }
  }, [trackerIsVisibleSeries]);

  useEffect(() => {
    if (trackerIsVisibleThreads) {
      setThreadsPage(threadsPage + 1);
      const threadsobj = {
        skip: threadsPage + 1,
        limit: threadsLimit,
      };
      const pageSize = Math.ceil(threadstotalpage);
      if (pageSize) {
        if (threadsPage < pageSize) {
          dispatch(
            getUserProfileElemtThreadsHasMore(
              userIdOrUserName,
              user?.id,
              threadsobj
            )
          );
        }
      }
    }
  }, [trackerIsVisibleThreads]);

  useEffect(() => {
    if (trackerIsVisibleBanners) {
      setBannersPage(threadsPage + 1);
      const bannerssobj = {
        skip: bannersPage + 1,
        limit: bannersLimit,
      };
      const pageSize = Math.ceil(bannerstotalpage);
      if (pageSize) {
        if (bannersPage < pageSize) {
          dispatch(
            getUserProfileElemtBannersHasMore(
              userIdOrUserName,
              user?.id,
              bannerssobj
            )
          );
        }
      }
    }
  }, [trackerIsVisibleBanners]);
  const image = !profile.avatar ? profile.badge : profile.avatar;

  function getTitleContent(profile) {
    if (profile.tagline) {
      return profile.tagline;
    } else if (profile.channelDescription) {
      return profile.channelDescription;
    } else {
      return profile.username;
    }
  }
  function getBioContent(profile) {
    if (profile?.bio) {
      return profile?.bio;
    } else if (profile.channelDescription) {
      return profile.channelDescription;
    } else {
      return profile.username;
    }
  }
  function getImageUrl(profile, config) {
    if (profile.avatar) {
      return config.aws.CLOUDFRONT_URL + profile.avatar;
    } else {
      return `${config.frontend.baseUrl}${profile.badge}`;
      // return `${config.aws.CLOUDFRONT_URL + profile?.cover}`;
    }
  }

  return (
    <>
      <TabHomeComponent>
        {(profile?.tagline || profile?.bio) && (
          <TaglineAndBioSection>
            {profile?.tagline && <p className="bio">{profile.tagline}</p>}
            {profile?.bio && (
              <h2
                dangerouslySetInnerHTML={{
                  __html: CheckHyperLinks(profile?.bio),
                }}
              />
            )}
          </TaglineAndBioSection>
        )}
        <Helmet>
          {/* for google */}
          <title>{`${profile.username}: ${getTitleContent(profile)}`}</title>
          <meta
            name="title"
            content={`${profile.username}: ${getTitleContent(profile)}`}
          />
          <meta name="description" content={getBioContent(profile)} />

          {/* for facebook */}
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={`${profile.username}: ${getTitleContent(profile)}`}
          />
          <meta property="og:description" content={getBioContent(profile)} />
          <meta property="og:image" Content={getImageUrl(profile, config)} />

          {/* for twitter */}
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:card"
            content={`${profile.username}: ${profile.tagline}`}
          />
          <meta
            property="twitter:description"
            content={getBioContent(profile)}
          />
          <meta
            property="twitter:image"
            Content={getImageUrl(profile, config)}
          />
        </Helmet>

        {/* {profile?.userrole === 2 && (
          <>
            {getLoading && <ProfileElementsSkeleton />}

            {!getLoading && getError && (
              <Error text=" Server Error While Fetching Contents. Sorry!" />
            )}

            {profileElements &&
              profileElements.length > 0 &&
              profileElements.map((item, i) => {
                if (
                  item.featuredDetailedContents &&
                  item.featuredDetailedContents.length > 0
                ) {
                  return (
                    <>
                      <FeaturedSection>
                        <div className="sectionTitle">Featured {item.type}</div>

                        {item.type === "series" && (
                          <>
                            {isMobile ? (
                              <Slider
                                {...SLIDERSETTING_MOBILE}
                                className="slider"
                                beforeChange={() => setMidDrag(true)}
                                afterChange={() => setMidDrag(false)}
                              >
                                {item.featuredDetailedContents.map(
                                  (series, i) => (
                                    <SeriesCard
                                      key={i}
                                      isSelf={profile?.isMe}
                                      item={series}
                                      menuOptions={
                                        profile?.isMe
                                          ? SERIES_SELF_PORTAL
                                          : SERIES_OTHER
                                      }
                                      setSelectedSeries={setSelectedSeries}
                                      setSeriesId={setSeriesId}
                                      midDrag={midDrag}
                                      setMidDrag={setMidDrag}
                                    />
                                  )
                                )}
                              </Slider>
                            ) : (
                              <Slider
                                {...SLIDERSETTINGV1}
                                className="slider"
                                beforeChange={() => setMidDrag(true)}
                                afterChange={() => setMidDrag(false)}
                              >
                                {item.featuredDetailedContents.map(
                                  (series, i) => (
                                    <SeriesCard
                                      key={i}
                                      isSelf={profile?.isMe}
                                      item={series}
                                      menuOptions={
                                        profile?.isMe
                                          ? SERIES_SELF_PORTAL
                                          : SERIES_OTHER
                                      }
                                      setSelectedSeries={setSelectedSeries}
                                      setSeriesId={setSeriesId}
                                      midDrag={midDrag}
                                      setMidDrag={setMidDrag}
                                    />
                                  )
                                )}
                              </Slider>
                            )}
                          </>
                        )}

                        {item.type === "moment" && (
                          <>
                            {isMobile ? (
                              <>
                                {item.featuredDetailedContents.length > 3 ? (
                                  <Slider
                                    className="slider"
                                    {...SLIDERSETTING_MOMENT_MOBILE_V1}
                                    beforeChange={() => setMidDrag(true)}
                                    afterChange={() => setMidDrag(false)}
                                  >
                                    {item.featuredDetailedContents.map(
                                      (moment, i) => (
                                        <div key={i} className="slider__item">
                                          <MomentCard
                                            key={i}
                                            item={moment}
                                            setSelectedMoment={
                                              setSelectedMoment
                                            }
                                            setMomentId={setMomentId}
                                            menuOptions={
                                              profile?.isMe
                                                ? MOMENT_SELF_PORTAL
                                                : MOMENTS_OTHER
                                            }
                                            midDrag={midDrag}
                                          />
                                        </div>
                                      )
                                    )}
                                  </Slider>
                                ) : (
                                  <Slider
                                    className="slider"
                                    {...SLIDERSETTING_MOMENT_MOBILE_V2}
                                    beforeChange={() => setMidDrag(true)}
                                    afterChange={() => setMidDrag(false)}
                                  >
                                    {item.featuredDetailedContents.map(
                                      (moment, i) => (
                                        <div key={i} className="slider__item">
                                          <MomentCard
                                            key={i}
                                            item={moment}
                                            setSelectedMoment={
                                              setSelectedMoment
                                            }
                                            setMomentId={setMomentId}
                                            menuOptions={
                                              profile?.isMe
                                                ? MOMENT_SELF_PORTAL
                                                : MOMENTS_OTHER
                                            }
                                            midDrag={midDrag}
                                          />
                                        </div>
                                      )
                                    )}
                                  </Slider>
                                )}
                              </>
                            ) : (
                              <Slider
                                className="slider"
                                {...SLIDERSETTING_MOMENT}
                                beforeChange={() => setMidDrag(true)}
                                afterChange={() => setMidDrag(false)}
                              >
                                {item.featuredDetailedContents.map(
                                  (moment, i) => (
                                    <div key={i} className="slider__item">
                                      <MomentCard
                                        key={i}
                                        item={moment}
                                        setSelectedMoment={setSelectedMoment}
                                        setMomentId={setMomentId}
                                        menuOptions={
                                          profile?.isMe
                                            ? MOMENT_SELF_PORTAL
                                            : MOMENTS_OTHER
                                        }
                                        midDrag={midDrag}
                                      />
                                    </div>
                                  )
                                )}
                              </Slider>
                            )}
                          </>
                        )}

                        {item.type === "video" && (
                          <>
                            {isMobile ? (
                              <Slider
                                {...SLIDERSETTING_MOBILE}
                                className="slider"
                                beforeChange={() => setMidDrag(true)}
                                afterChange={() => setMidDrag(false)}
                              >
                                {item.featuredDetailedContents.map(
                                  (video, i) => (
                                    <VideoCard
                                      key={i}
                                      item={video}
                                      isSelf={profile?.isMe}
                                      setSelectedVideo={setSelectedVideo}
                                      setVideoId={setVideoId}
                                      menuOptions={
                                        profile?.isMe
                                          ? VIDEO_SELF_PORTAL
                                          : VIDEO_OTHER
                                      }
                                      midDrag={midDrag}
                                      setMidDrag={setMidDrag}
                                    />
                                  )
                                )}
                              </Slider>
                            ) : (
                              <Slider
                                {...SLIDERSETTINGV1}
                                className="slider"
                                beforeChange={() => setMidDrag(true)}
                                afterChange={() => setMidDrag(false)}
                              >
                                {item.featuredDetailedContents.map(
                                  (video, i) => (
                                    <VideoCard
                                      key={i}
                                      item={video}
                                      isSelf={profile?.isMe}
                                      setSelectedVideo={setSelectedVideo}
                                      setVideoId={setVideoId}
                                      menuOptions={
                                        profile?.isMe
                                          ? VIDEO_SELF_PORTAL
                                          : VIDEO_OTHER
                                      }
                                      midDrag={midDrag}
                                      setMidDrag={setMidDrag}
                                    />
                                  )
                                )}
                              </Slider>
                            )}
                          </>
                        )}

                        {item.type === "thread" && (
                          <>
                            {isMobile ? (
                              <Slider
                                {...SLIDERSETTING_MOBILE}
                                className="slider"
                                beforeChange={() => setMidDrag(true)}
                                afterChange={() => setMidDrag(false)}
                              >
                                {item.featuredDetailedContents.map(
                                  (thread, i) => (
                                    <ThreadCardSmall
                                      key={i}
                                      item={thread}
                                      midDrag={midDrag}
                                      setMidDrag={setMidDrag}
                                    />
                                  )
                                )}
                              </Slider>
                            ) : (
                              <Slider
                                {...SLIDERSETTINGV1}
                                className="slider"
                                beforeChange={() => setMidDrag(true)}
                                afterChange={() => setMidDrag(false)}
                              >
                                {item.featuredDetailedContents.map(
                                  (thread, i) => (
                                    <ThreadCardSmall
                                      key={i}
                                      item={thread}
                                      type={thread?.attachedType}
                                      midDrag={midDrag}
                                      setMidDrag={setMidDrag}
                                    />
                                  )
                                )}
                              </Slider>
                            )}
                          </>
                        )}

                        {item.type === "banner" &&
                          (item.featuredDetailedContents.length ? (
                            <MarketingBanner
                              banners={item.featuredDetailedContents}
                              page="myPortal"
                              profileElement
                            />
                          ) : (
                            <MarketingBanner
                              banners={banners}
                              page="myPortal"
                            />
                          ))}
                      </FeaturedSection>
                      <FeaturedSection>
                        {item.type === "moment" && (
                            <>
                              {isMobile ? (
                                <>
                                  {item.featuredDetailedContents.length > 3 ? (
                                    <Slider
                                      className="slider"
                                      {...SLIDERSETTING_MOMENT_MOBILE_V1}
                                      beforeChange={() => setMidDrag(true)}
                                      afterChange={() => setMidDrag(false)}
                                    >
                                      {item.featuredDetailedContents.map(
                                        (moment, i) => (
                                          <div key={i} className="slider__item">
                                            <MomentCard
                                              key={i}
                                              item={moment}
                                              setSelectedMoment={
                                                setSelectedMoment
                                              }
                                              setMomentId={setMomentId}
                                              menuOptions={
                                                profile?.isMe
                                                  ? MOMENT_SELF_PORTAL
                                                  : MOMENTS_OTHER
                                              }
                                              midDrag={midDrag}
                                            />
                                          </div>
                                        )
                                      )}
                                    </Slider>
                                  ) : (
                                    <Slider
                                      className="slider"
                                      {...SLIDERSETTING_MOMENT_MOBILE_V2}
                                      beforeChange={() => setMidDrag(true)}
                                      afterChange={() => setMidDrag(false)}
                                    >
                                      {item.featuredDetailedContents.map(
                                        (moment, i) => (
                                          <div key={i} className="slider__item">
                                            <MomentCard
                                              key={i}
                                              item={moment}
                                              setSelectedMoment={
                                                setSelectedMoment
                                              }
                                              setMomentId={setMomentId}
                                              menuOptions={
                                                profile?.isMe
                                                  ? MOMENT_SELF_PORTAL
                                                  : MOMENTS_OTHER
                                              }
                                              midDrag={midDrag}
                                            />
                                          </div>
                                        )
                                      )}
                                    </Slider>
                                  )}
                                </>
                              ) : (
                                <Slider
                                  className="slider"
                                  {...SLIDERSETTING_MOMENT}
                                  beforeChange={() => setMidDrag(true)}
                                  afterChange={() => setMidDrag(false)}
                                >
                                  {item.featuredDetailedContents.map(
                                    (moment, i) => (
                                      <div key={i} className="slider__item">
                                        <MomentCard
                                          key={i}
                                          item={moment}
                                          setSelectedMoment={setSelectedMoment}
                                          setMomentId={setMomentId}
                                          menuOptions={
                                            profile?.isMe
                                              ? MOMENT_SELF_PORTAL
                                              : MOMENTS_OTHER
                                          }
                                          midDrag={midDrag}
                                        />
                                      </div>
                                    )
                                  )}
                                </Slider>
                              )}
                            </>
                          )}
                      </FeaturedSection>
                      {<div ref={trackerRefMoment} className="tracker" />}
                    </>
                  );
                } else {
                  if (item.type === "banner") {
                    return (
                      <MarketingBanner banners={banners} page="myPortal" />
                    );
                  }
                }
              })}
          </>
        )} */}

        {profile?.userrole === 2 && (
          <>
            {/* Channel Preview Video */}
            {profile?.introVideo && (
              <ChannelPreviewVideo data={profile.introVideo} />
            )}

            <>
              {MomentsLoading && <ProfileElementsSkeleton />}
              {profileElementsMoment &&
                profileElementsMoment.length > 0 &&
                profileElementsMoment.map((item, i) => {
                  if (
                    item.featuredDetailedContents &&
                    item.featuredDetailedContents.length > 0
                  ) {
                    return (
                      <React.Fragment key={i}>
                        <FeaturedSection>
                          <div className="sectionTitle">
                            Featured {item.type}s
                          </div>
                          {item.type === "moment" && (
                            <>
                              {isMobile ? (
                                <>
                                  {item.featuredDetailedContents.length > 3 ? (
                                    <Slider
                                      className="slider"
                                      {...SLIDERSETTING_MOMENT_MOBILE_V1}
                                      beforeChange={() => setMidDrag(true)}
                                      afterChange={() => setMidDrag(false)}
                                    >
                                      {item.featuredDetailedContents.map(
                                        (moment, i) => (
                                          <div key={i} className="slider__item">
                                            <MomentCard
                                              key={i}
                                              item={moment}
                                              setSelectedMoment={
                                                setSelectedMoment
                                              }
                                              setMomentId={setMomentId}
                                              menuOptions={
                                                profile?.isMe
                                                  ? MOMENT_SELF_PORTAL
                                                  : MOMENTS_OTHER
                                              }
                                              midDrag={midDrag}
                                            />
                                          </div>
                                        )
                                      )}
                                    </Slider>
                                  ) : (
                                    <Slider
                                      className="slider"
                                      {...SLIDERSETTING_MOMENT_MOBILE_V2}
                                      beforeChange={() => setMidDrag(true)}
                                      afterChange={() => setMidDrag(false)}
                                    >
                                      {item.featuredDetailedContents.map(
                                        (moment, i) => (
                                          <div key={i} className="slider__item">
                                            <MomentCard
                                              key={i}
                                              item={moment}
                                              setSelectedMoment={
                                                setSelectedMoment
                                              }
                                              setMomentId={setMomentId}
                                              menuOptions={
                                                profile?.isMe
                                                  ? MOMENT_SELF_PORTAL
                                                  : MOMENTS_OTHER
                                              }
                                              midDrag={midDrag}
                                            />
                                          </div>
                                        )
                                      )}
                                    </Slider>
                                  )}
                                </>
                              ) : (
                                <Slider
                                  className="slider"
                                  {...SLIDERSETTING_MOMENT}
                                  beforeChange={() => setMidDrag(true)}
                                  afterChange={() => setMidDrag(false)}
                                >
                                  {item.featuredDetailedContents.map(
                                    (moment, i) => (
                                      <div key={i} className="slider__item">
                                        <MomentCard
                                          key={i}
                                          item={moment}
                                          setSelectedMoment={setSelectedMoment}
                                          setMomentId={setMomentId}
                                          menuOptions={
                                            profile?.isMe
                                              ? MOMENT_SELF_PORTAL
                                              : MOMENTS_OTHER
                                          }
                                          midDrag={midDrag}
                                        />
                                      </div>
                                    )
                                  )}
                                </Slider>
                              )}
                            </>
                          )}
                        </FeaturedSection>
                        {<div ref={trackerRef} className="tracker" />}
                      </React.Fragment>
                    );
                  } else {
                    if (item.type === "banner") {
                      return (
                        <MarketingBanner banners={banners} page="myPortal" />
                      );
                    }
                  }
                })}
            </>

            {/* videos */}
            <>
              {videoloading && <ProfileElementsSkeleton />}
              {profileElementsVideo &&
                profileElementsVideo.length > 0 &&
                profileElementsVideo.map((item, i) => {
                  if (
                    item.featuredDetailedContents &&
                    item.featuredDetailedContents.length > 0
                  ) {
                    return (
                      <React.Fragment key={i}>
                        <FeaturedSection>
                          <div className="sectionTitle">
                            Featured {item.type}s
                          </div>
                          {item.type === "video" && (
                            <>
                              {isMobile ? (
                                <Slider
                                  {...SLIDERSETTING_MOBILE}
                                  className="slider"
                                  beforeChange={() => setMidDrag(true)}
                                  afterChange={() => setMidDrag(false)}
                                >
                                  {item.featuredDetailedContents.map(
                                    (video, i) => (
                                      <VideoCard
                                        key={i}
                                        item={video}
                                        isSelf={profile?.isMe}
                                        setSelectedVideo={setSelectedVideo}
                                        setVideoId={setVideoId}
                                        menuOptions={
                                          profile?.isMe
                                            ? VIDEO_SELF_PORTAL
                                            : VIDEO_OTHER
                                        }
                                        midDrag={midDrag}
                                        setMidDrag={setMidDrag}
                                      />
                                    )
                                  )}
                                </Slider>
                              ) : (
                                <Slider
                                  {...SLIDERSETTINGV1}
                                  className="slider"
                                  beforeChange={() => setMidDrag(true)}
                                  afterChange={() => setMidDrag(false)}
                                >
                                  {item.featuredDetailedContents.map(
                                    (video, i) => (
                                      <VideoCard
                                        key={i}
                                        item={video}
                                        isSelf={profile?.isMe}
                                        setSelectedVideo={setSelectedVideo}
                                        setVideoId={setVideoId}
                                        menuOptions={
                                          profile?.isMe
                                            ? VIDEO_SELF_PORTAL
                                            : VIDEO_OTHER
                                        }
                                        midDrag={midDrag}
                                        setMidDrag={setMidDrag}
                                      />
                                    )
                                  )}
                                </Slider>
                              )}
                            </>
                          )}
                        </FeaturedSection>
                        {<div ref={trackerRefVideo} className="tracker" />}
                      </React.Fragment>
                    );
                  } else {
                    if (item.type === "banner") {
                      return (
                        <MarketingBanner banners={banners} page="myPortal" />
                      );
                    }
                  }
                })}
            </>

            {/* series */}

            <>
              {seriesloading && <ProfileElementsSkeleton />}
              {profileElementsSeries &&
                profileElementsSeries.length > 0 &&
                profileElementsSeries.map((item, i) => {
                  if (
                    item.featuredDetailedContents &&
                    item.featuredDetailedContents.length > 0
                  ) {
                    return (
                      <React.Fragment key={i}>
                        <FeaturedSection>
                          <div className="sectionTitle">
                            Featured {item.type}
                          </div>
                          {item.type === "series" && (
                            <>
                              {isMobile ? (
                                <Slider
                                  {...SLIDERSETTING_MOBILE}
                                  className="slider"
                                  beforeChange={() => setMidDrag(true)}
                                  afterChange={() => setMidDrag(false)}
                                >
                                  {item.featuredDetailedContents.map(
                                    (series, i) => (
                                      <SeriesCard
                                        key={i}
                                        isSelf={profile?.isMe}
                                        item={series}
                                        menuOptions={
                                          profile?.isMe
                                            ? SERIES_SELF_PORTAL
                                            : SERIES_OTHER
                                        }
                                        setSelectedSeries={setSelectedSeries}
                                        setSeriesId={setSeriesId}
                                        midDrag={midDrag}
                                        setMidDrag={setMidDrag}
                                      />
                                    )
                                  )}
                                </Slider>
                              ) : (
                                <Slider
                                  {...SLIDERSETTINGV1}
                                  className="slider"
                                  beforeChange={() => setMidDrag(true)}
                                  afterChange={() => setMidDrag(false)}
                                >
                                  {item.featuredDetailedContents.map(
                                    (series, i) => (
                                      <SeriesCard
                                        key={i}
                                        isSelf={profile?.isMe}
                                        item={series}
                                        menuOptions={
                                          profile?.isMe
                                            ? SERIES_SELF_PORTAL
                                            : SERIES_OTHER
                                        }
                                        setSelectedSeries={setSelectedSeries}
                                        setSeriesId={setSeriesId}
                                        midDrag={midDrag}
                                        setMidDrag={setMidDrag}
                                      />
                                    )
                                  )}
                                </Slider>
                              )}
                            </>
                          )}
                        </FeaturedSection>
                        {<div ref={trackerRefSeries} className="tracker" />}
                      </React.Fragment>
                    );
                  } else {
                    if (item.type === "banner") {
                      return (
                        <MarketingBanner banners={banners} page="myPortal" />
                      );
                    }
                  }
                })}
            </>

            {/* Threads */}
            <>
              {threadsloading && <ProfileElementsSkeleton />}
              {profileElementsThreads &&
                profileElementsThreads.length > 0 &&
                profileElementsThreads.map((item, i) => {
                  if (
                    item.featuredDetailedContents &&
                    item.featuredDetailedContents.length > 0
                  ) {
                    return (
                      <React.Fragment key={i}>
                        <FeaturedSection>
                          <div className="sectionTitle">
                            Featured {item.type}s
                          </div>
                          {item.type === "thread" && (
                            <>
                              {isMobile ? (
                                <Slider
                                  {...SLIDERSETTING_MOBILE}
                                  className="slider"
                                  beforeChange={() => setMidDrag(true)}
                                  afterChange={() => setMidDrag(false)}
                                >
                                  {item.featuredDetailedContents.map(
                                    (thread, i) => (
                                      <ThreadCardSmall
                                        key={i}
                                        item={thread}
                                        midDrag={midDrag}
                                        setMidDrag={setMidDrag}
                                      />
                                    )
                                  )}
                                </Slider>
                              ) : (
                                <Slider
                                  {...SLIDERSETTINGV1}
                                  className="slider"
                                  beforeChange={() => setMidDrag(true)}
                                  afterChange={() => setMidDrag(false)}
                                >
                                  {item.featuredDetailedContents.map(
                                    (thread, i) => (
                                      <ThreadCardSmall
                                        key={i}
                                        item={thread}
                                        type={thread?.attachedType}
                                        midDrag={midDrag}
                                        setMidDrag={setMidDrag}
                                      />
                                    )
                                  )}
                                </Slider>
                              )}
                            </>
                          )}
                        </FeaturedSection>
                        {<div ref={trackerRefThreads} className="tracker" />}
                      </React.Fragment>
                    );
                  } else {
                    if (item.type === "banner") {
                      return (
                        <MarketingBanner banners={banners} page="myPortal" />
                      );
                    }
                  }
                })}
            </>

            {/* Banners */}

            <>
              {bannersloading && <ProfileElementsSkeleton />}
              {profileElementsBanners &&
                profileElementsBanners.length > 0 &&
                profileElementsBanners.map((item, i) => {
                  if (
                    item.featuredDetailedContents &&
                    item.featuredDetailedContents.length > 0
                  ) {
                    return (
                      <React.Fragment key={i}>
                        <FeaturedSection>
                          <div className="sectionTitle">
                            Featured {item.type}s
                          </div>
                          <>
                            {item.type === "banner" &&
                              (item.featuredDetailedContents.length ? (
                                <MarketingBanner
                                  banners={item.featuredDetailedContents}
                                  page="myPortal"
                                  profileElement
                                />
                              ) : (
                                <MarketingBanner
                                  banners={banners}
                                  page="myPortal"
                                />
                              ))}
                          </>
                        </FeaturedSection>
                        {<div ref={trackerRefBanners} className="tracker" />}
                      </React.Fragment>
                    );
                  } else {
                    if (item.type === "banner") {
                      return (
                        <MarketingBanner banners={banners} page="myPortal" />
                      );
                    }
                  }
                })}
            </>
          </>
        )}
      </TabHomeComponent>

      {/* edit clip form */}
      {selectedVideo && videoId && (
        <EditClipForm
          videoUpdate={selectedVideo}
          videoId={videoId}
          close={() => setShowEditClipModel(false)}
        />
      )}

      {/* edit moment form */}
      {selectedMoment && momentId && (
        <EditMomentForm
          selectedMoment={selectedMoment}
          close={() => setShowEditMomentModel(false)}
        />
      )}

      {/* edit series form */}
      {selectedSeries && seriesId && (
        <EditSeriesModel selectedSeries={selectedSeries} seriesId={seriesId} />
      )}
    </>
  );
}

export default HomeTab;
