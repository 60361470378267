import React, { useState } from "react";
import styled from "styled-components";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Chip } from "@material-ui/core";
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';

const SubFilter = ({ mainCategory, handleCheck, currentActiveItemId, top, left, handleCurrentSubMenu }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentSubFilter, setCurrentSubfilter] = useState(null);

  const handleClick = (event, item) => {
    // if (item.id == currentSubFilter?.id) {
    //   setCurrentSubfilter(null)
    //   return;
    // }
    setCurrentSubfilter(item);
    setAnchorEl(event.currentTarget);
    handleCurrentSubMenu(item);
  };


  return (
    <>
      <StyledSubFilter top={top} left={left}>
        {mainCategory &&
          mainCategory.subCategOne &&
          mainCategory.subCategOne.length &&
          mainCategory.subCategOne.map((item, index) => (
            <StyledItem
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => handleClick(e, item)}
            >
              <SpanContainer>
                <p>{item.name}</p>
              </SpanContainer>

              {currentSubFilter && item.id == currentSubFilter.id &&
                currentSubFilter.subCategTwo.map((item) => {
                  return (
                    <ListItem>
                      <Checkbox
                        disabled={
                          currentSubFilter.allowOnlyOneSubCat &&
                          currentActiveItemId !== null &&
                          currentActiveItemId !== item.id
                        }
                        onChange={(e) =>
                          handleCheck(
                            !item.isSelected,
                            mainCategory,
                            currentSubFilter,
                            item,
                            // currentSubFilter.allowOnlyOneSubCat
                          )
                        } type="checkbox" checked={item.isSelected} />
                      <Label onClick={(e) =>
                        handleCheck(
                          !item.isSelected,
                          mainCategory,
                          currentSubFilter,
                          item,
                          // currentSubFilter.allowOnlyOneSubCat
                        )
                      }>{item.name}</Label>
                    </ListItem>
                  );
                })}
            </StyledItem>
          ))}
      </StyledSubFilter>
    </>

  );
};

export default SubFilter;
const StyledSubFilter = styled.div`
  display: flex;
  background: #383838e0;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: column;
  position:absolute;
  z-index:4;
  top:${(props) => props.top}px;
  left:${(props) => props.left}px;
  box-shadow: 0px 10px 15px 7px rgba(0,0,0,0.2);
  padding-right:1rem;
  max-width: 20rem;
  padding-bottom:1rem;
  border-radius: 5px;
  padding-top: 10px;

  .selectedItem {
    height:3rem;
    width:100%;
    border:1px solid red;
  }
`;

const StyledItem = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 15px;
  font-size: 10px;
  align-items: flex-start;
  flex-direction: column;
  p {
    display: flex;
    color: lightgray;
    font-size: initial;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  span {
    display: flex;
    flex-dirextion: column;
  }
  > .muiChip {
    display: flex;
    jusify-content: center;
    align-items: center;
  }
`;

const SpanContainer = styled.div`
  display: flex;
  p {
    text-decoration:underline;
  }
`;
const ListItem = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Checkbox = styled.input`
  margin-right: 10px;
  cursor:pointer;
  &:focus {
    outline: none;
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
  }
  /* Hide the default checkbox */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* Create a custom checkbox */
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 2px solid white;
  position: relative;
  overflow: hidden;

  /* Change the checkbox color when checked */
  &:checked {
    &::before {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: rgb(249,154,45);
      border-radius: 50%;
    }
    & + label {
         font-weight: bold;
         background: linear-gradient( to right, rgb(249,154,45), rgb(246,92,139), rgb(249,154,45) );
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
      }
    
  }
`;

const Label = styled.label`
  font-size: 16px;
  cursor: pointer;
  :hover {
    font-weight:600;
  }
`;