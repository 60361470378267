import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import AdminSidebar from "../AdminSidebar";
import AboutusForm from "./AboutusForm";
import Button from "../../components/Elements/Button";
import GoBackIcon from "../../components/icons/goback.svg";
import FaqForm from "./FaqForm";
import WhyusForm from "./WhyusForm";
import StatsForm from "./StatsForm";
import PricingForm from "./PricingForm";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector, useDispatch } from "react-redux";
import { getMarketingPage } from "../../actions/marketing";
import Loader from "../../components/Elements/Loader";
import api from "../../services/api";
import FooterForm from "./FooterForm";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const MarketingPageAdmin = () => {
  const {
    loading: marketingPageLoading,
    data: marketingPageData,
    order: marketingPageOrder,
  } = useSelector((state) => state.marketingPage);
  const dispatch = useDispatch();
  const dragListRef = useRef(null);
  const [sections, setSections] = useState([]);
  const [expandedItem, setExpandedItem] = useState();

  useEffect(() => {
    if (!marketingPageData && !marketingPageLoading) {
      dispatch(getMarketingPage());
    }
  }, []);

  useEffect(() => {
    if (marketingPageData && !marketingPageOrder?.length) {
      const sections = Object.keys(marketingPageData);
      const formattedSections = sections.map((item) => {
        return {
          name: item,
        };
      });

      const formattedSectionsAfterFooter = formattedSections?.filter(
        (el) => el.name !== "footer"
      );

      setSections(formattedSectionsAfterFooter);
    }

    if (marketingPageOrder?.length) {
      const formattedSections = marketingPageOrder.map((el) => {
        return {
          name: el.section_name,
        };
      });
      const formattedSectionsAfterFooter = formattedSections?.filter(
        (el) => el.name !== "footer"
      );

      setSections(formattedSectionsAfterFooter);
    }
  }, [marketingPageData, marketingPageOrder]);

  const onDragEndHandler = async (result) => {
    if (!result.destination) {
      return;
    }

    const updatedItems = reorder(
      sections,
      result.source.index,
      result.destination.index
    );

    setSections(updatedItems);

    // setting the database
    const promises = updatedItems.map(async (item, index) => {
      let order = index + 1;
      const obj = {
        section_name: item.name,
        section_order: order,
      };

      await api.post(`marketing/drag-drop`, obj);
    });

    await Promise.all(promises);

    toast.success("updated");
  };

  return (
    <MarketingPageAdminStyled>
      <AdminSidebar />
      <div className="content">
        {!marketingPageData && marketingPageLoading && (
          <Loader text="Please wait while we fetch marketing page..." />
        )}

        {marketingPageData && !marketingPageLoading && !expandedItem && (
          <div className="sections">
            <div className="sections__item sections__item--header">
              <div className="sections__item--title">header</div>
              {/* <span className="sections__item--text">Click to edit content</span> */}
            </div>

            {/* draggable start */}
            <p className="sections__info">
              Drag & drop sections below to update positions 👇
            </p>
            <DragDropContext onDragEnd={(result) => onDragEndHandler(result)}>
              <Droppable droppableId="droppableId">
                {(provided, snapshot) => (
                  <div
                    className="sections__dragList"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {sections?.map((item, i) => (
                      <div
                        className="sections__dragList--box"
                        key={i}
                        ref={dragListRef}
                      >
                        <Draggable
                          key={item?.name}
                          draggableId={item?.name}
                          index={i}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                onClick={() => {
                                  if (item?.name === "creators") {
                                    return;
                                  }
                                  setExpandedItem(item);
                                }}
                                className="sections__dragList--item"
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                style={{
                                  userSelect: "none",
                                  background: snapshot.isDragging
                                    ? "#383838"
                                    : null,
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <div className="sections__dragList--item-drag">
                                  <img src="/icons/drag.svg" alt="" />
                                </div>
                                <div className="sections__dragList--item-title">
                                  {item?.name}
                                </div>

                                {item?.name !== "creators" && (
                                  <span className="sections__dragList--item-text">
                                    Click to edit content
                                  </span>
                                )}
                              </div>
                            );
                          }}
                        </Draggable>
                      </div>
                    ))}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <p className="sections__info">
              Drag & drop sections up to update positions 👆
            </p>
            {/* draggable end */}

            <div
              onClick={() =>
                setExpandedItem({
                  name: "footer",
                })
              }
              className="sections__item sections__item--footer"
            >
              <div className="sections__item--title">footer</div>
              <span className="sections__item--text">
                Click to edit content
              </span>
            </div>
          </div>
        )}

        {expandedItem && (
          <Button
            onClick={() => setExpandedItem(null)}
            className="goBackBtn"
            iconLeft
            mode="secondary"
            size="medium"
          >
            <img src={GoBackIcon} alt="" /> Go Back
          </Button>
        )}

        {expandedItem?.name === "about us" && <AboutusForm />}
        {expandedItem?.name === "faq" && <FaqForm />}
        {expandedItem?.name === "why choose us?" && <WhyusForm />}
        {expandedItem?.name === "stats" && <StatsForm />}
        {expandedItem?.name === "pricing" && <PricingForm />}
        {expandedItem?.name === "footer" && <FooterForm />}
      </div>
    </MarketingPageAdminStyled>
  );
};

export default MarketingPageAdmin;

const backgroundPan = keyframes`
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
`;

const MarketingPageAdminStyled = styled.div`
  display: flex;
  overflow-x: hidden;
  background: #212123;
  height: auto;
  z-index: 1000;

  .goBackBtn {
    margin-bottom: 1.2rem;
    padding: 0.5rem 1.2rem;
  }

  .content {
    padding: 3rem;
    width: 60%;
    min-width: 480px;
    margin: 0 auto;
    margin-bottom: 3.5rem;
    font-family: "Montserrat", sans-serif;

    .sections {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.25rem;

      &__info {
        font-size: 0.9rem;
        font-weight: 300;
        text-transform: lowercase;
        color: rgba(255, 255, 255, 0.75);

        &::first-letter {
          text-transform: uppercase;
        }
      }

      &__item {
        background-color: ${(props) => props.theme.bg};
        border-radius: 0.6rem;
        padding: 1.5rem 1rem 1rem 1rem;
        width: 100%;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &--title {
          font-family: "brother-1816", sans-serif;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 2.25rem;
          line-height: 1;
          background: linear-gradient(
            to right,
            ${(props) => props.theme.primaryColor1},
            ${(props) => props.theme.primaryColor2},
            ${(props) => props.theme.primaryColor1}
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          animation: ${backgroundPan} 3s linear infinite;
          background-size: 200%;
        }

        &--text {
          font-size: 0.8rem;
          color: rgba(255, 255, 255, 0.45);
          text-decoration: underline;
        }
      }

      &__dragList {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        width: 90%;

        &--box {
          width: 100%;
        }

        &--item {
          background-color: ${(props) => props.theme.bg};
          border-radius: 0.6rem;
          padding: 1.5rem 1rem 1rem 1rem;
          width: 100%;
          cursor: pointer;
          position: relative;

          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          &-title {
            font-family: "brother-1816", sans-serif;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 1;
            background: linear-gradient(
              to right,
              ${(props) => props.theme.primaryColor1},
              ${(props) => props.theme.primaryColor2},
              ${(props) => props.theme.primaryColor1}
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: ${backgroundPan} 3s linear infinite;
            background-size: 200%;
          }

          &-text {
            font-size: 0.75rem;
            color: rgba(255, 255, 255, 0.45);
            padding-top: 0.25rem;
            text-decoration: underline;
          }

          &-drag {
            position: absolute;
            top: 50%;
            left: 1rem;
            transform: translateY(-50%);
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${(props) => props.theme.primaryGradient};
            opacity: 0.8;

            img {
              width: 0.85rem;
              height: auto;
            }
          }
        }
      }
    }
  }
`;
