import React from "react";
import styled, { keyframes } from "styled-components";
import Button from "../../../styles/Button";

const DefaultCardModal = ({
  close,
  cardLast4,
  setDefaultCreditCard,
  selectedPaymentMethodId,
}) => {
  return (
    <DefaultCardModalStyled>
      <div onClick={close} className="confirmHeader">
        <div className="description">{`Are you sure you want to set this card(XXXX XXXX XXXX ${cardLast4}) as default payment card?`}</div>
        <div className="buttonsWrapper">
          <Button className="buttonsWrapper__cancelBtn" grey onClick={close}>
            Cancel
          </Button>
          <Button
            className="buttonsWrapper__confirmBtn"
            onClick={() => setDefaultCreditCard(selectedPaymentMethodId)}
          >
            Confirm
          </Button>
        </div>
      </div>
    </DefaultCardModalStyled>
  );
};

export default DefaultCardModal;

const openAnimation = keyframes`
  from {
    transform: translatex(5rem);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const DefaultCardModalStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 41%;
  margin: -100px 0 0 -150px;
  bottom: 0;
  z-index: 5;
  width: 407px;
  border-radius: 1rem;
  height: 8rem;
  background-color: #4c4c4e;
  padding: 1rem 2rem;
  -webkit-animation: jrgDjZ 0.65s ease-out;
  -webkit-animation: jrgDjZ 0.65s ease-out;
  animation: jrgDjZ 0.65s ease-out;
  border-top: 1pxsolidrgba (112, 112, 112, 0.25);
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0;

  .confirmHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    .closeIcon {
      height: 0.5rem;
      width: auto;
      margin-right: 1rem;
    }

    .description {
      font-size: 0.9rem;
      font-weight: 500;
      color: #f2f2f7;
    }
    .buttonsWrapper {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: inherit;
      margin-top: 1rem;
    }
  }

  .options {
    width: 100%;

    &__item {
      padding: 0.5rem 0;
      font-size: 0.85rem;
      font-weight: 600;
      color: #636366;
      border-bottom: 2px solid rgba(99, 99, 102, 0.5);
      cursor: pointer;
    }

    .active {
      color: #f2f2f7;
    }
  }

  @media screen and (max-width: 480px) {
    .header {
      .description {
        font-size: 0.8rem;
      }
    }

    .options {
      &__item {
        padding: 0.5rem 0;
        font-size: 0.8rem;
      }
    }
  }
`;
