import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../icons/close.svg";
import MessageIcon from "../icons/mail.svg";
import styled, { keyframes } from "styled-components";
import Switch from "react-switch";
import SavedCreditCard from "../Cards/SavedCreditCard";
import { GlobalContext } from "../../context/GlobalContext";
import { isBadgeOrAvatar } from "../../utils";
import { toast } from "react-toastify";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Row, Col } from "react-grid-system";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import {
  acceptPay,
  savePPVUnlockInformation,
  transfers,
  unlockCategoryFeaturedVideo,
  unlockCategoryStaffPickVideo,
  unlockCategoryVideo,
  unlockFeaturedVideo,
  unlockLatestVideo,
  unlockLikeVideo,
  unlockMoreVideo,
  unlockProfileVideo,
  unlockSearchVideo,
  unlockStaffPickVideo,
  unlockTrendingVideo,
  unlockWatchVideo,
} from "../../actions";
import { notificationMessage } from "../../utils/toastNotifications";

const cardStyle = {
  style: {
    base: {
      color: "#000",
      backgroundColor: "#fff",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "16px",
      "::placeholder": {
        color: "rgba(0,0,0,0.65)",
        textTransfer: "uppercase",
        fontWeight: 300,
      },
    },
    invalid: {
      color: "#f00",
      iconColor: "#f00",
    },
  },
};

const PayPerView = ({ creator, video }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { showPPVKarmaModel, setShowPPVKarmaModel } = useContext(GlobalContext);
  const [savePM, setSavePM] = useState(true);
  const [savePMToLocalstorage, setSavePMToLocalstorage] = useState(false);
  const [selectedPM, setSelectedPM] = useState(null);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user);
  const { paymentMethods } = user;

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  const { amount = null } = video;

  useEffect(() => {
    if (savePMToLocalstorage) {
      const obj = {
        isSavePM: savePM,
      };

      localStorage.setItem("freeloaderUserSettings", JSON.stringify(obj));
    }
  }, [savePM, setSelectedPM]);

  useEffect(() => {
    const freeloaderUserSettings = JSON.parse(
      localStorage.getItem("freeloaderUserSettings")
    );

    if (freeloaderUserSettings) {
      setSavePM(freeloaderUserSettings?.isSavePM);
    }
  }, [showPPVKarmaModel]);

  const avatar = creator?.avatar
    ? creator.avatar
    : creator?.badge
    ? creator.badge
    : null;

  const submitHandler = async () => {
    setLoading(true);
    const amountToPay = parseFloat(amount);
    try {
      let res;
      if (!selectedPM) {
        res = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
        });
      }

      let payload = {
        amount: amountToPay,
        currency: "usd",
        metadata: {
          integration_check: "accept_a_payment",
        },
        savePaymentMethod: !selectedPM ? savePM : false,
      };

      if (selectedPM !== null) {
        payload.customer = user.stripe_customer_id;
      }

      if (selectedPM === null && res?.paymentMethod?.id) {
        payload.paymentMethodId = res.paymentMethod.id;
        payload.customer = user.stripe_customer_id;
      }

      const result = await dispatch(acceptPay(payload));

      if (result?.data?.data?.client_secret) {
        await stripeAcceptPayment(result.data.data.client_secret);
      } else {
        toast.error(
          "This card details already exits in your saved cards. Please select from below to make a payment or use another card which is not saved."
        );
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );

      setLoading(false);
      toast.error(notificationMessage.somethingWentWrong);
    }
  };

  const stripeAcceptPayment = async (clientId) => {
    const amountToPay = Number.parseFloat(amount).toFixed(2);
    try {
      if (!stripe || !elements) {
        return;
      }
      let newPaymentMethod = {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: user.firstName,
          email: user.email,
        },
      };

      const result = await stripe.confirmCardPayment(clientId, {
        payment_method: selectedPM !== null ? selectedPM.id : newPaymentMethod,
      });

      if (result.error) {
        setLoading(false);
        if (result.error?.code === "incomplete_number") {
          return toast.error(
            "Please provide your card details or choose from saved cards!"
          );
        }
      } else {
        if (result.paymentIntent.status === "succeeded") {
          if (video.User.stripe_account_id) {
            const finalPrice = (result.paymentIntent.amount * 6) / 10000 + 0.3;
            const creatorAmount = amountToPay - finalPrice;
            await dispatch(
              transfers({
                amount: creatorAmount,
                currency: "usd",
                destination: video.User.stripe_account_id,
                toUserId: video.User.id,
                videoId: video.id,
              })
            );
            toast.success(notificationMessage.paymentSuccessfull);
            toast.success(
              `$${creatorAmount.toFixed(2)} USD was sent to ${
                creator?.username
              } successfully! Thank you for giving Karma back to the Content Creator!`
            );
            elements.getElement(CardNumberElement).clear();
            elements.getElement(CardExpiryElement).clear();
            elements.getElement(CardCvcElement).clear();
            await savePPVUnlockDetail(); // Save PayPerView information
            setSelectedPM(null);
            setShowPPVKarmaModel(false);
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.log(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );

      setLoading(false);
      toast.error(notificationMessage.somethingWentWrong);
    }
  };

  const savePPVUnlockDetail = async () => {
    dispatch(
      savePPVUnlockInformation({
        videoId: video.id,
        userId: user.id, // The current user who pays for the PPV video
      })
    );
    dispatch(unlockFeaturedVideo(video.id));
    dispatch(unlockTrendingVideo(video.id));
    dispatch(unlockLatestVideo(video.id));
    dispatch(unlockStaffPickVideo(video.id));
    dispatch(unlockWatchVideo(video.id));
    dispatch(unlockCategoryFeaturedVideo(video.id));
    dispatch(unlockCategoryVideo(video.id));
    dispatch(unlockCategoryStaffPickVideo(video.id));
    dispatch(unlockMoreVideo(video.id));
    dispatch(unlockLikeVideo(video.id));
    dispatch(unlockSearchVideo(video.id));
    dispatch(unlockProfileVideo(video.id));
  };
  const renderKarma = () => parseFloat(amount).toFixed(2);

  return (
    <KarmaStyled className={`${showPPVKarmaModel ? "ShowKarmaModel" : null}`}>
      <div className="content">
        <div
          onClick={() => {
            setShowPPVKarmaModel(false);
          }}
          className="close"
        >
          <img src={CloseIcon} alt="" />
        </div>

        <div className="content__title">
          <span className="uppercase">Give Karma to </span>{" "}
          <div className="lowercase">{creator?.username}</div>
        </div>

        <div className="content__main">
          <div className="content__main--user">
            <div className="left">
              <div className="avatar">{isBadgeOrAvatar(avatar)}</div>

              <div className="info">
                <div className="username">{creator?.username}</div>
                <div className="karma">
                  <div className="bold">Karma</div> ${renderKarma()}
                </div>
              </div>
            </div>

            <div className="right">
              <div className="sendMessage">
                <img src={MessageIcon} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="content__payment">
          <div className="content__payment--title">Add a payment details</div>
          <div className="content__payment--form">
            <Row>
              <Col md={12}>
                <div className="fieldItem">
                  <CardNumberElement options={cardStyle} />
                </div>
              </Col>
              <Col
                style={{
                  paddingRight: !isMobile ? "4px" : "15px",
                }}
                md={6}
                sm={12}
              >
                <div className="fieldItem">
                  <CardExpiryElement options={cardStyle} />
                </div>
              </Col>
              <Col
                style={{
                  paddingLeft: !isMobile ? "4px" : "15px",
                }}
                md={6}
                sm={12}
              >
                <div className="fieldItem">
                  <CardCvcElement options={cardStyle} />
                </div>
              </Col>
            </Row>

            <label className="toggleBox">
              <Switch
                onColor="#28df99"
                offColor="#cc0000"
                checked={savePM}
                onChange={(e) => {
                  setSavePMToLocalstorage(true);
                  setSavePM(!savePM);
                }}
              />
              <span>Save this card for future payments</span>
            </label>
          </div>

          {paymentMethods?.data?.length > 0 && (
            <>
              <div className="content__payment--title">
                OR used saved cards!
              </div>

              <div className="content__payment--savedCards">
                {paymentMethods.data.map((item, i) => (
                  <div key={i} className="item">
                    <SavedCreditCard
                      selectedPM={selectedPM}
                      setSelectedPM={setSelectedPM}
                      item={item}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        <div className="content__desc">
          By giving karma to the creator, you are supporting them to create more
          good content in upcoming days. <span className="link">Read more</span>
        </div>

        <div className="content__footer">
          <button className="content__footer--button" onClick={submitHandler}>
            {loading ? "loading..." : <>Send ${renderKarma()} Karma</>}
          </button>
        </div>
      </div>
    </KarmaStyled>
  );
};

export default PayPerView;

const backgroundPan = keyframes`
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
`;

const KarmaStyled = styled.div`
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  transform-origin: center;
  opacity: 0;
  scale: 0;
  visibility: hidden;
  z-index: -1;
  transition: all 0.4s ease;
  background: linear-gradient(
    180deg,
    rgba(56, 56, 56, 0.06),
    rgba(56, 56, 56, 0.06)
  );
  backdrop-filter: blur(15px);
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #202020;
    padding: 2rem;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.09);
    color: #fff;
    border-radius: 0.8rem;
    min-width: 480px;
    width: 45%;
    height: 80vh;
    overflow-y: auto;

    .close {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      height: 1.8rem;
      width: 1.8rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 1rem;
        width: auto;
      }
    }

    &__title {
      font-size: 1.1rem;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;

      .uppercase {
        text-transform: uppercase;
        padding-right: 0.6rem;
        font-size: 1rem;
        letter-spacing: 0.5px;
      }

      .lowercase {
        font-weight: 500;
        background: linear-gradient(
          to right,
          ${(props) => props.theme.primaryColor1},
          ${(props) => props.theme.primaryColor2},
          ${(props) => props.theme.primaryColor1}
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: ${backgroundPan} 3s linear infinite;
        background-size: 200%;
      }
    }

    &__desc {
      background-color: #181818;
      padding: 0.8rem 1.2rem;
      border-radius: 0.4rem;
      color: rgba(255, 255, 255, 0.65);
      font-size: 0.75rem;
      font-weight: 300;
      border: 1px solid rgba(255, 255, 255, 0.09);

      .link {
        color: ${(props) => props.theme.blue};
        text-decoration: underline;
      }
    }

    &__main {
      &--user {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
        }

        .right {
          .sendMessage {
            height: 2.7rem;
            width: 2.7rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid rgba(255, 255, 255, 0.09);
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
              border: 1px solid rgba(255, 255, 255, 0.6);

              img {
                transform: scale(1.15);
              }
            }

            img {
              height: 0.9rem;
              width: auto;
              transition: all 0.2s ease;
            }
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .username {
            font-weight: 400;
            font-size: 0.9rem;
            cursor: pointer;
            line-height: 1;
            margin-bottom: 0.2rem;
          }

          .karma {
            padding: 0.2rem 1.2rem;
            border-radius: 5rem;
            background: ${(props) => props.theme.gradient};
            font-size: 0.65rem;
            font-weight: 300;
            display: flex;
            align-items: center;
            line-height: 1;

            .bold {
              font-weight: 400;
              font-family: brother-1816, sans-serif;
              text-transform: uppercase;
              padding-right: 0.4rem;
              font-size: 0.625;
            }
          }
        }

        .avatar {
          cursor: pointer;
          height: 2.2rem;
          width: 2.2rem;
          border-radius: 50%;
          position: relative;
          margin-right: 0.5rem;
          outline: 1px solid rgba(255, 255, 255, 0.5);

          .imageAvatar {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
            border-radius: 50%;
          }

          .imageBadge {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .badge {
              height: 38px;
              width: auto;
            }
          }
        }
      }

      &--slider {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;

        .sliderInput {
          -webkit-appearance: none;
          outline: 0;
          border: 0;
          border-radius: 10rem;
          width: calc(100% - 5.5rem);
          height: 1rem;
          background: linear-gradient(
            270deg,
            #e62117,
            #e62117,
            #e62117,
            #e62117,
            #d63d65,
            #d63d65,
            #e68231,
            #e68231,
            #f7ca50,
            #f7ca50,
            #1de9b6,
            #00e5ff
          );

          &::-webkit-slider-thumb {
            appearance: none;
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 50%;
            background: #fff;
            cursor: pointer;
            transition: background 0.15s ease-in-out;
            outline: 5px solid rgba(255, 255, 255, 0.4);

            &:hover {
              background: ${(props) => props.theme.gradient};
            }
          }

          &::-moz-slider-thumb {
            appearance: none;
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 50%;
            background: #fff;
            cursor: pointer;
            transition: background 0.15s ease-in-out;
            outline: 5px solid rgba(255, 255, 255, 0.4);

            &:hover {
              background: ${(props) => props.theme.gradient};
            }
          }
        }
      }
    }

    &__payment {
      margin-bottom: 2rem;
      padding: 1rem;
      background-color: #181818;
      border-radius: 0.5rem;
      border: 1px solid rgba(255, 255, 255, 0.09);

      &--title {
        text-transform: uppercase;
        font-size: 0.925rem;
        color: rgba(255, 255, 255, 0.65);
        font-weight: 500;
        margin-bottom: 1rem;
      }

      &--form {
        .fieldItem {
          padding: 0.6rem 1rem;
          border-radius: 0.35rem;
          background-color: #fff;
          margin-bottom: 0.5rem;
          border: 3px solid transparent;
          transition: all 0.3s ease;

          &:hover {
            border: 3px solid ${(props) => props.theme.primaryColor1};
          }
        }

        .react-switch-bg {
          svg {
            height: 30px;
            width: 30px;
          }

          span {
            color: #fff !important;
          }
          .yes {
            margin-left: 0.3rem !important;
          }
          .no {
            margin-left: 0.3rem !important;
          }
        }

        .toggleBox {
          margin-top: 0.8rem;
          margin-bottom: 2rem;
          display: flex;
          align-items: center;

          span {
            margin-left: 0.7rem;
            font-size: 0.75rem;
            color: #999;
          }
        }
      }

      &--savedCards {
        .item {
          &:not(:last-child) {
            margin-bottom: 0.7rem;
          }
        }
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      margin-top: 2rem;

      &--button {
        font-family: ${(props) => props.theme.montserrat};
        font-weight: 600;
        font-size: 0.95rem;
        padding: 0.7rem 2rem;
        border-radius: 10rem;
        background-color: #fff;
        color: #f88946;
        text-transform: uppercase;
        border: none;
        outline: none;
        transition: all 0.5s ease;
        letter-spacing: 2px;

        &:hover {
          background-color: #f88946;
          color: #fff;
          letter-spacing: 0;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      min-width: 95%;
      width: 95%;
      padding: 1.5rem 1rem;

      .close {
        top: 0.4rem;
        right: 0.4rem;
        height: 1.5rem;
        width: 1.5rem;

        img {
          height: 0.9rem;
        }
      }

      &__title {
        font-size: 0.95rem;
        margin-bottom: 1.2rem;

        .uppercase {
          padding-right: 0.5rem;
          font-size: 0.9rem;
        }
      }

      &__desc {
        padding: 0.8rem 1rem;
        border-radius: 0.3rem;
        font-size: 0.7rem;
      }
      &__payment {
        padding: 0.5rem;

        &--title {
          font-size: 0.85rem;
        }

        &--form {
          .fieldItem {
            padding: 0.4rem 1rem;
          }
        }
      }

      &__footer {
        &--button {
          font-size: 0.75rem;
          padding: 0.5rem 1.8rem;
        }
      }
    }
  }
`;
