import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Button from "../../Elements/Button";
import Input from "../../Elements/Input";
import LoaderIcon from "../../icons/loader.svg";
import { AuthContext } from "../../../context/AuthContext";
import {
  resetPasswordRequest,
  setEmailExistsFalse,
  clearResetPasswordError,
} from "../../../actions";
import { toast } from "react-toastify";
import { notificationMessage } from "../../../utils/toastNotifications";
import { useDispatch, useSelector } from "react-redux";

const ForgotPassword = ({ switchStep }) => {
  const dispatch = useDispatch();
  const { setValidationError } = useContext(AuthContext);
  const { isLoading, error, emailExists } = useSelector(
    (state) => state.resetPW
  );
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (emailExists) {
      setValidationError({
        type: "success",
        message:
          "A password reset link has been sent to your email. Please note that it will expire in 24 Hours.",
      });

      dispatch(setEmailExistsFalse());
      setEmail("");
      switchStep("CHECK_EMAIL");

      return;
    }

    if (error) {
      toast.error(
        error ||
          "Server error occured while sending reset email. Please try again later."
      );

      dispatch(clearResetPasswordError());
      return;
    }
  }, [emailExists, error]);

  const submitHandler = () => {
    if (!email) {
      return setValidationError({
        type: "error",
        message: "Email is required field",
      });
    }

    if (!validateEmail(email)) {
      return setValidationError({
        type: "error",
        message: "Email is not valid",
      });
    }

    toast.success(notificationMessage.sendingPassword);
    dispatch(resetPasswordRequest(email));
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <ForgotPasswordStyled>
      <div className="paddingBox">
        <div className="title">
          Forgot Your Password ?<div className="title__imp">Reset It.</div>
        </div>

        <div className="field">
          <Input
            label="Enter email"
            name="email"
            id="email"
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <div
            onClick={() => {
              switchStep("CHECK_EMAIL");
            }}
            className="alternativeOption"
          >
            LOGIN / SIGNUP ?
          </div>
        </div>

        <Button
          mode="primary"
          width="fluid"
          variant="round"
          size="medium"
          onClick={() => submitHandler()}
          className="submit"
          iconLeft={true}
        >
          {isLoading ? (
            <>
              <img src={LoaderIcon} alt="" className="imgIcon" />
              Please Wait...
            </>
          ) : (
            "SEND INSTRUCTIONS"
          )}
        </Button>
      </div>
    </ForgotPasswordStyled>
  );
};

export default ForgotPassword;

const ForgotPasswordStyled = styled.div`
  .title {
    font-size: 1.1rem;
    display: flex;
    align-items: center;

    &__imp {
      background: -webkit-${(props) => props.theme.primaryGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-left: 0.5rem;
    }
  }

  .field {
    margin-top: 2rem;
  }

  .submit {
    margin-top: 2rem;
    width: 100%;
  }

  .alternativeOption {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 500;
    text-decoration: underline;
    color: rgba(255, 255, 255, 0.6);
    font-family: brother-1816, sans-serif;
    margin-top: 0.35rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
`;
