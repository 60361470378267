import {
  GET_ALL_MOMENT_REQUEST,
  GET_ALL_MOMENT_SUCCESS,
  GET_ALL_MOMENT_FAIL,
  GET_MY_MOMENT_REQUEST,
  GET_MY_MOMENT_SUCCESS,
  GET_MY_MOMENT_FAIL,
  GET_MY_MOMENT_RESET,
  GET_LIKED_MOMENTS_REQUEST,
  GET_LIKED_MOMENTS_SUCCESS,
  GET_LIKED_MOMENTS_FAIL,
  GET_STREAMS_MOMENTS_REQUEST,
  GET_STREAMS_MOMENTS_SUCCESS,
  GET_STREAMS_MOMENTS_FAIL,
  RESET_GET_ALL_MOMENTS,
  GET_HASMORE_MOMENTS,
} from "../actions/types";

const moments = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_MOMENT_REQUEST:
      return {
        ...state,
        loading: true,
        moments:
          state.moments && state.moments.length > 0 ? [...state.moments] : [],
      };
    case GET_ALL_MOMENT_SUCCESS:
      // const allMoments = [...state.moments, ...action.payload.moments];
      const allMoments = [...action.payload.moments, ...state.moments];
      const uniqueMoments = [
        ...new Map(allMoments.map((item) => [item["id"], item])).values(),
      ];
      return {
        ...state,
        loading: false,
        moments: uniqueMoments,
        hasMoreAllMoments: action.payload.hasMore,
      };
    case GET_ALL_MOMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        moments:
          state.moments && state.moments.length > 0 ? [...state.moments] : [],
        hasMoreAllMoments: false,
      };
    case GET_MY_MOMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MY_MOMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        length: action.length,
        myMoments: action.payload,
      };
    case GET_HASMORE_MOMENTS: {
      const newMyMoments = [...state.myMoments, ...action.payload];
      return {
        ...state,
        loading: false,
        length: action.length,
        myMoments: newMyMoments,
      };
    }
    case GET_MY_MOMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...state.moments,
      };
    case GET_LIKED_MOMENTS_REQUEST:
      return {
        ...state,
        likedMomentsLoading: true,
      };
    case GET_LIKED_MOMENTS_SUCCESS:
      return {
        ...state,
        likedMomentsLoading: false,
        likedMoments: action.payload,
      };
    case GET_LIKED_MOMENTS_FAIL:
      return {
        ...state,
        likedMomentsLoading: false,
        likedMomentsError: action.payload,
      };
    case GET_MY_MOMENT_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        myMoments: null,
      };
    case GET_STREAMS_MOMENTS_REQUEST:
      return {
        ...state,
        streamsLoading: true,
        streamsMoments:
          state.streamsMoments && state.streamsMoments.length > 0
            ? [...state.streamsMoments]
            : [],
      };
    case GET_STREAMS_MOMENTS_SUCCESS:
      const { isFiltering } = action.payload;
      const moments = isFiltering
        ? [...action.payload.moments]
        : [...state.streamsMoments, ...action.payload.moments];
      const allStreamsMoments = moments;
      const uniqueStreamsMoments = [
        ...new Map(
          allStreamsMoments.map((item) => [item["id"], item])
        ).values(),
      ];

      return {
        ...state,
        streamsLoading: false,
        streamsMoments: uniqueStreamsMoments,
        hasMoreStreamsMoments: action.payload.hasMore,
      };
    case GET_STREAMS_MOMENTS_FAIL:
      return {
        ...state,
        streamsLoading: false,
        streamsError: action.payload,
        streamsMoments:
          state.streamsMoments && state.streamsMoments.length > 0
            ? [...state.streamsMoments]
            : [],
        hasMoreStreamsMoments: false,
      };

    case RESET_GET_ALL_MOMENTS:
      return {
        ...state,
        moments: [],
      };
    default:
      return state;
  }
};

export default moments;
