import styled from "styled-components";

const SmallText = styled.span`
  font-size: 0.68rem;
  color: currentColor;
  text-transform: uppercase;
  font-weight: lighter;
  font-family: brother-1816, sans-serif;
  letter-spacing: 0.08px;
`;

export default SmallText;
