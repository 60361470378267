import React from "react";
import styled from "styled-components";
import BackIcon from "../../../assets/Icons/back.svg";
import NotificationSwitchButton from "../../NotificationSwitchButton";
import SetAllNoification from "../../SetAllNoification";

function NotificationTab({ close }) {
  return (
    <NotificationTabStyled>
      <div className="wrapper">
        <div className="wrapper__header">
          <div onClick={close} className="backIcon">
            <img src={BackIcon} alt="" />
          </div>
          <div className="name">Notifications Settings</div>
        </div>

        <Header>NOTIFICATIONS</Header>
        <Header3>
          <div className="small-text">
            Control the items you would like to receive notifications by
            adjusting the swtiches on/off.
          </div>
        </Header3>
        {/* <Header2>
          Global Notifications
          <SetAllNoification />
        </Header2> */}
        <NotificationSwitchButton />
      </div>
    </NotificationTabStyled>
  );
}

export default NotificationTab;

const NotificationTabStyled = styled.div`
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    font-family: ${(props) => props.theme.montserrat};
    background-color: #1c1c1e;
    overflow-y: scroll;
    z-index: 150;
    padding-bottom: 5rem;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #3a3a3c;
      border-radius: 7px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(246, 92, 139);
    }

    &__header {
      display: flex;
      align-items: center;
      padding: 1rem 5rem;
      border-bottom: 1px solid rgba(112, 112, 112, 0.4);

      .backIcon {
        margin-right: 1rem;
        cursor: pointer;

        img {
          height: 1rem;
          width: auto;
        }
      }

      .name {
        font-size: 1.2rem;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    @media screen and (max-width: 480px) {
      &__header {
        padding: 1rem;

        .backIcon {
          margin-right: 0.75rem;

          img {
            height: 0.85rem;
          }
        }

        .name {
          font-size: 1rem;
        }
      }
    }
  }
`;
const Header = styled.h4`
  padding: 3em 4rem 0;
  font-size: 1rem;
  color: #a6a6a6;
  @media screen and (max-width: 480px) {
    padding: 3em 1rem;
  }
`;

const Header3 = styled.h3`
  padding: 0 4rem;
  .small-text {
    width: 90%;
    font-size: 0.8rem;
    color: #a6a6a6;
    padding-bottom: 1rem;
  }
  @media screen and (max-width: 480px) {
    padding: 0 1rem;
  }
`;
