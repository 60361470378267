import EditIcon from "../components/icons/edit.svg";
import TrashIcon from "../components/icons/trash.svg";
import PlaylistIcon from "../components/icons/playlist.svg";
import StreamIcon from "../components/icons/Stream-icon.png"
import EyeIcon from "../components/icons/eye.svg";
import HeartOutlineIcon from "../components/icons/heart-outline.svg";
import ReportIcon from "../components/icons/flag.svg";
import React from "react";

export const VIDEO_SELF = [
  {
    icon: EditIcon,
    imgIcon: true,
    type: "editVideo",
    text: "Edit Video",
  },

  { icon: TrashIcon, imgIcon: true, type: "deleteVideo", text: "Delete Video" },
];

export const VIDEO_SELF_PORTAL = [
  {
    icon: EditIcon,
    imgIcon: true,
    type: "editVideo",
    text: "Edit Video",
  },
  {
    icon: TrashIcon,
    imgIcon: true,
    type: "removeFromFeatured",
    text: "Remove from Featured",
  },
];

export const VIDEO_OTHER = [
  {
    icon: <i class="fas fa-share"></i>,
    type: "shareVideo",
    text: "Share Video",
  },
  {
    icon: <i class="fas fa-user-alt"></i>,
    type: "visitCreatorPage",
    text: "Go to Creator Profile",
  },
  {
    icon: PlaylistIcon,
    imgIcon: true,
    type: "saveVideoToPlaylist",
    text: "Save to Playlist",
  },
];

export const REMOVE_FROM_STREAMS_OPTION = {
  icon: TrashIcon,
  imgIcon: true,
  type: "removeFromStreams",
  text: "Remove from Streams",
}

export const ADD_TO_STREAMS_OPTION = {
  icon: StreamIcon,
  imgIcon: true,
  type: "addToStreams",
  text: "Add to Streams",
}

export const SERIES_SELF = [
  {
    icon: EditIcon,
    imgIcon: true,
    type: "editSeries",
    text: "Edit Series",
  },

  {
    icon: TrashIcon,
    imgIcon: true,
    type: "deleteSeries",
    text: "Delete Series",
  },

  {
    icon: PlaylistIcon,
    imgIcon: true,
    type: "manageSeriesVideos",
    text: "Manage Videos",
  },
];

export const SERIES_OTHER = [
  {
    icon: <i class="fas fa-share"></i>,
    type: "shareSeries",
    text: "Share Series",
  },
  {
    icon: <i class="fas fa-user-alt"></i>,
    type: "visitCreatorPage",
    text: "Go to Creator Profile",
  },
];

export const MOMENTS_OTHER = [
  {
    icon: <i class="fas fa-share"></i>,
    type: "shareMoment",
    text: "Share Moment",
  },
  {
    icon: <i class="fas fa-user-alt"></i>,
    type: "visitCreatorPage",
    text: "Go to Creator Profile",
  },
  {
    icon: EyeIcon,
    imgIcon: true,
    type: "viewMoment",
    text: "View Moment",
  },
];

export const MOMENT_SELF = [
  {
    icon: EditIcon,
    imgIcon: true,
    type: "editMoment",
    text: "Edit Moment",
  },

  {
    icon: TrashIcon,
    imgIcon: true,
    type: "deleteMoment",
    text: "Delete Moment",
  },
];

export const MOMENT_SELF_PORTAL = [
  {
    icon: EditIcon,
    imgIcon: true,
    type: "editMoment",
    text: "Edit Moment",
  },
  {
    icon: TrashIcon,
    imgIcon: true,
    type: "removeFromFeatured",
    text: "Remove from Featured",
  },
  {
    icon: HeartOutlineIcon,
    imgIcon: true,
    type: "saveToLibrary",
    text: "Save to Library",
  },
];

export const SERIES_SELF_PORTAL = [
  {
    icon: EditIcon,
    imgIcon: true,
    type: "editSeries",
    text: "Edit Series",
  },
  {
    icon: TrashIcon,
    imgIcon: true,
    type: "removeFromFeatured",
    text: "Remove from Featured",
  },
  {
    icon: HeartOutlineIcon,
    imgIcon: true,
    type: "saveToLibrary",
    text: "Save to Library",
  },
];

export const PLAYLIST_SELF = [
  {
    icon: EditIcon,
    imgIcon: true,
    type: "editPlaylist",
    text: "Edit Playlist",
  },

  {
    icon: TrashIcon,
    imgIcon: true,
    type: "deletePlaylist",
    text: "Delete Playlist",
  },
];

export const PLAYLIST_OTHER = [
  {
    icon: <i class="fas fa-share"></i>,
    type: "sharePlaylist",
    text: "Share Playlist",
  },
  {
    icon: <i class="fas fa-user-alt"></i>,
    type: "visitCreatorPage",
    text: "Go to Creator Profile",
  },
  {
    icon: EyeIcon,
    imgIcon: true,
    type: "viewPlaylist",
    text: "View Playlist",
  },
];

export const STREAMS_OPTIONS = [
  {
    icon: <i className="far fa-trash-alt"></i>,
    text: "Remove from Streams",
    type: "removeFromStreams",
  },
  {
    icon: <i className="fas fa-user-alt" />,
    text: "Go To Create Profile",
    type: "visitCreatorPage",
  },
];

export const PURCHASED_SERIES_OPTIONS = [
  {
    icon: <i className="far fa-trash-alt"></i>,
    text: "Remove Series",
    type: "removePurchasedSeries",
  },
  {
    icon: <i class="fas fa-share"></i>,
    text: "Share Series",
    type: "sharePurchasedSeries",
  },
  {
    icon: <i className="fas fa-user-alt" />,
    text: "Go To Create Profile",
    type: "visitCreatorPage",
  },
];

export const THREAD_SELF = [
  {
    icon: <i className="far fa-trash-alt"></i>,
    text: "Delete Thread",
    type: "deleteThread",
  },
  {
    icon: EditIcon,
    imgIcon: true,
    type: "editThread",
    text: "Edit Thread",
  },
];

export const THREAD_OTHER = [
  {
    icon: <i class="fas fa-share"></i>,
    type: "shareThread",
    text: "Share Thread",
  },
  {
    icon: <i class="fas fa-user-alt"></i>,
    type: "visitCreatorPage",
    text: "Go to Creator Profile",
  },
  {
    icon: EyeIcon,
    imgIcon: true,
    type: "viewThread",
    text: "View Thread",
  },
];

export const COMMENT_SELF = [
  {
    icon: <i className="far fa-trash-alt"></i>,
    text: "Delete Comment",
    type: "deleteThreadComment",
  },
  {
    icon: EditIcon,
    imgIcon: true,
    type: "editThreadComment",
    text: "Edit Comment",
  },
];

export const COMMENT_OTHER = [
  {
    icon: ReportIcon,
    imgIcon: true,
    type: "reportThreadComment",
    text: "Report Comment",
  },
];
