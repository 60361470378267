import React, { useState, memo, useEffect, useContext } from "react";
import styled from "styled-components";
import BackIcon from "../../../assets/Icons/back.svg";
import CansilIcon from "../../../assets/Icons/cansil2.png";
import moment from "moment";
import UpdateSubscriptionModal from "./UpdateSubscriptonModal";
import useOnclickOutside from "react-cool-onclickoutside";
import UpdatePaymentMethodModal from "./UpdatePaymentMethodModal";
import { getSubscriptionPlans } from "../../../actions";
import { useSelector, useDispatch } from "react-redux";
import AddCardModal from "./AddCardModal";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  SET_CURRENT_SUBSCRIPTION,
  SET_DEFAULT_PAYMENT_METHOD,
  UPDATE_USER_ROLE,
} from "../../../actions/types";
import { GlobalContext } from "../../../context/GlobalContext";
import DefaultCardModal from "./DefaultCardModal";

import "./remove.css";
import { notificationMessage } from "../../../utils/toastNotifications";
import ConfirmationModel from "../../ConfirmationModel";

const ManageSubscription = ({ close, user }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: siteBranding } = useSelector((state) => state.branding);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState();
  const [paymentModalRef, setPaymentModalRef] = useState(false);
  const [openAddCardModal, setOpenAddCardModal] = useState(false);
  const [showCancelSubscrioptionModal, setCancelSubscriptionModal] =
    useState(false);
  const [showDefaultCardModal, setShowDefaultCardModal] = useState(false);
  const [selectedDefaultCard, setSelectedDefaultCard] = useState(null);
  const [cancelling, setCancelling] = useState(false);
  const [isProcessingDefaultCard, setDefaultCardProcessing] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [defaultPMId, setDefaultPMId] = useState(
    user?.paymentMethods?.default_payment_method || ""
  );

  const {
    isFetching,
    plans,
    error,
    currentSubscription,
    coOpSubscription,
    isUpdating,
  } = useSelector((state) => state.subscriptionPlans);
  const { setShowSidebar, setShowMyAccount } = useContext(GlobalContext);

  const hasPaymentMethodAttached =
    user?.paymentMethods?.data.length > 0 ? true : false;

  useEffect(() => {
    dispatch(getSubscriptionPlans());
  }, []);

  const getUserType = (userrole) => {
    switch (userrole) {
      case 0:
        return "Community Member";
      case 1:
        return "Co-op Member";
      case 2:
        return "Warrior Creator";
    }
  };
  const handleSubscriptionModal = () => {
    setShowSubscriptionModal(!showSubscriptionModal);
  };
  const handlePaymentModal = (paymentMethod) => {
    setShowPaymentModal(paymentMethod);
  };

  const updateSubscriptionRef = useOnclickOutside(() => {
    setShowSubscriptionModal(false);
  });
  const updatePaymentRef = useOnclickOutside(() => {
    setPaymentModalRef(false);
  });
  const addCardRef = useOnclickOutside(() => {
    setOpenAddCardModal(false);
  });

  const getCurrentPlan = (plans) => {
    if (plans.length && !!currentSubscription.plan) {
      const currentPlan = plans.find(
        (plan) => plan.default_price === currentSubscription.plan.id
      );
      return currentPlan;
    } else return null;
  };

  const nextRenewalDate = (currentSubscription) => {
    const t = new Date(1970, 0, 1).setSeconds(
      currentSubscription.current_period_end
    ); // Epoch
    return new Date(t).toDateString();
  };
  const handleCancelSubscriptionModal = () => {
    setCancelSubscriptionModal(!showCancelSubscrioptionModal);
  };

  const handleCancelSubscription = async () => {
    if (currentSubscription && currentSubscription.id) {
      setCancelling(true);
      deleteSubscription()
        .then((result) => {
          setCancelling(false);
          toast.success(notificationMessage.cancelledSubscription);
          dispatch({
            type: SET_CURRENT_SUBSCRIPTION,
            payload: result.data.subscription,
          });
          // set userrole to 0 i.e freeloader
          dispatch({
            type: UPDATE_USER_ROLE,
            payload: 0,
          });
          close(true);
          setShowMyAccount(false);
          setShowSidebar(false);
          history.push(`/update_subscription/${user.id}`);
        })
        .catch((error) => {
          console.log(error);
          setCancelling(false);
          toast.error(notificationMessage.somethingWentWrong);
        });
    }
  };

  const deleteSubscription = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const cancelSubscriptionResponse = await api.post(
          "stripe/cancelSubscription",
          {
            data: { subscriptionId: currentSubscription.id },
          }
        );
        resolve(cancelSubscriptionResponse);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleDefaultCard = (paymentMethod) => {
    setShowDefaultCardModal(true);
    setSelectedDefaultCard(paymentMethod);
  };

  const setDefaultCreditCard = async (paymentMethodId) => {
    try {
      setDefaultCardProcessing(true);
      await api.post("stripe/updateDefaultCreditCard", {
        data: { paymentMethodId },
      });
      setDefaultCardProcessing(false);
      setAsDefaultPM(paymentMethodId); // update Redux store
      toast.success(notificationMessage.updateDefaultCreditCard);
    } catch (error) {
      setDefaultCardProcessing(false);
      toast.error(notificationMessage.somethingWentWrong);
    }
  };

  const setAsDefaultPM = (paymentMethodId) => {
    dispatch({
      type: SET_DEFAULT_PAYMENT_METHOD,
      payload: paymentMethodId,
    });
    setDefaultPMId(paymentMethodId);
    setSelectedDefaultCard(null);
  };

  const deleteModelHandler = () => {
    console.log("Delete Button CLocked ");
    setIsVisible(false);
    setModalOpen(false);
  };
  // const handleDeleteCard = (paymentMethod) => {
  //   // setModalOpen(true);
  //   // dispatch(deleteCard(paymentMethod.customer, paymentMethod.id))
  // }

  return (
    <ManageSubscriptionStyled>
      <div className="wrapper">
        <div className="wrapper__header">
          <div onClick={close} className="backIcon">
            <img src={BackIcon} alt="" />
          </div>
          <div className="name">Manage Subscription</div>
        </div>

        <p className="infoText">Your {siteBranding?.site_name} Subscription</p>

        <div className="wrapper__subscriptionBox">
          {showSubscriptionModal && (
            <UpdateSubscriptionModal
              doNotClose={updateSubscriptionRef}
              close={() => setShowSubscriptionModal(false)}
              plans={plans}
              isFetching={isFetching}
              currentSubscription={currentSubscription}
              isUpdating={isUpdating}
              closeMangeSubscription={close}
              hasPaymentMethodAttached={hasPaymentMethodAttached}
              setOpenAddCardModal={setOpenAddCardModal}
              coOpSubscription
            />
          )}

          {showPaymentModal && (
            <UpdatePaymentMethodModal
              showPaymentModal={showPaymentModal}
              doNotClose={updatePaymentRef}
              setShowPaymentModal={setShowPaymentModal}
              close={() => setShowPaymentModal(false)}
              setAsDefaultPM={setAsDefaultPM}
              defaultPMId={defaultPMId}
              currentPaymentMethodId={showPaymentModal.id || ""}
            />
          )}
          {openAddCardModal && (
            <AddCardModal
              doNotClose={addCardRef}
              close={() => setOpenAddCardModal(false)}
              setAsDefaultPM={setAsDefaultPM}
              user={user}
            />
          )}
          {showCancelSubscrioptionModal && (
            <CancelSubscriptionModal
              currentPlanName={
                getCurrentPlan(plans) ? getCurrentPlan(plans).name : ""
              }
              close={() => setCancelSubscriptionModal(false)}
              handleCancelSubscription={handleCancelSubscription}
              title="Are you sure you want to Cancel Subscription"
            />
          )}
          {showDefaultCardModal && (
            <DefaultCardModal
              close={() => setShowDefaultCardModal(false)}
              cardLast4={selectedDefaultCard.card.last4}
              setDefaultCreditCard={setDefaultCreditCard}
              selectedPaymentMethodId={selectedDefaultCard.id}
            />
          )}

          <div className="wrapper__subscriptionBox--currentSubscription">
            <div className="role">
              {getCurrentPlan(plans) ? getCurrentPlan(plans).name : ""}
            </div>
            <div className="userType">
              {user ? getUserType(user.userrole) : ""}
            </div>
            <div className="infoText">
              {getCurrentPlan(plans) ? getCurrentPlan(plans).description : ""}{" "}
            </div>
            <div className="plan">
              {user.userrole != 0 &&
                currentSubscription &&
                currentSubscription.id && (
                  <span>
                    Renews on &nbsp;
                    {currentSubscription && currentSubscription.id
                      ? nextRenewalDate(currentSubscription)
                      : ""}
                  </span>
                )}
              {!isFetching && !currentSubscription?.id && (
                <span
                  className="renew-now"
                  onClick={() => handleSubscriptionModal()}
                >
                  Renew now
                </span>
              )}

              {isFetching && (
                <span
                  className="renew-now"
                  onClick={() => handleSubscriptionModal()}
                >
                  Please wait
                </span>
              )}

              {!isFetching && (
                <p onClick={() => handleSubscriptionModal()}>
                  {currentSubscription && currentSubscription.id
                    ? `$ ${currentSubscription.plan.amount / 100} / ${
                        currentSubscription.plan.interval
                      }`
                    : ""}
                </p>
              )}

              {cancelling && !isFetching ? (
                <p className="subscription-text-small">
                  Cancelling Subscription
                </p>
              ) : (
                currentSubscription &&
                currentSubscription.id &&
                user.userrole !== 0 && (
                  <p
                    className="subscription-text-small"
                    onClick={() => handleCancelSubscriptionModal()}
                  >
                    Cancel Subscription
                  </p>
                )
              )}
              {user.userrole === 0 && user.userrole != 0 && (
                <>
                  <p
                    onClick={() => handleSubscriptionModal()}
                    style={{
                      color: "#d1d1d6",
                      fontWeight: "400",
                      fontSize: "13px",
                      marginTop: "3px",
                    }}
                  >
                    Upgrade package
                  </p>
                  <p
                    className="subscription-text-small"
                    onClick={() => handleCancelSubscriptionModal()}
                  >
                    Cancel Subscription
                  </p>
                </>
              )}
              {user.userrole == 0 && (
                <p
                  className="subscription-text-small"
                  onClick={() => handleSubscriptionModal()}
                >
                  Upgrade
                </p>
              )}
            </div>
          </div>
          <div className="wrapper__subscriptionBox--options">
            {user?.paymentMethods?.data.length > 0 &&
              user.paymentMethods.data.map((paymentMethod, index) => (
                <div
                  className="wrapper__subscriptionBox--optionCard renewal"
                  style={{ display: isVisible ? "block" : "none" }}
                  key={index}
                >
                  <div className="cardInfo">
                    <div className="cardInfo__cardNumber">
                      <div className="title">Card Number</div>
                      <div className="secondary-text">{`xxxxxxxxx${paymentMethod.card.last4}`}</div>
                    </div>
                    <div className="cardInfo">
                      {" "}
                      <div className="actionText">
                        {isProcessingDefaultCard &&
                          paymentMethod.id == selectedDefaultCard.id && (
                            <p className="default-card">processing...</p>
                          )}
                        {!isProcessingDefaultCard &&
                        paymentMethod.id === defaultPMId ? (
                          <p>Default</p>
                        ) : (
                          <p
                            className="default-card"
                            onClick={() => handleDefaultCard(paymentMethod)}
                            style={{ marginLeft: "4px" }}
                          >
                            Set as Default
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="title">Expiry date</div>
                  <div className="secondary-text">
                    {moment(`${paymentMethod.card.exp_month}`, "M").format(
                      "MMMM"
                    )}
                    ,&nbsp;{paymentMethod.card.exp_year}
                  </div>
                  <div
                    id={index}
                    className="actionText__action"
                    onClick={() => handlePaymentModal(paymentMethod)}
                  >
                    Update
                  </div>
                  <div
                    id={index}
                    className="actionText__action actionText__action--delete"
                    onClick={() => handlePaymentModal(paymentMethod)}
                  >
                    delete
                  </div>
                </div>
              ))}
            {/* Add Payment Method */}
            <div className="wrapper__subscriptionBox--optionCard renewal">
              <div
                className="actionText__action"
                onClick={() => setOpenAddCardModal(!openAddCardModal)}
              >
                Add payment method
              </div>
            </div>
          </div>
          {modalOpen && (
            <>
              <ConfirmationModel
                closeHandler={() => setModalOpen(false)}
                deleteHandler={deleteModelHandler}
              />
            </>
          )}
        </div>
      </div>
    </ManageSubscriptionStyled>
  );
};

export default memo(ManageSubscription);

const ManageSubscriptionStyled = styled.div`
  .wrapper__subscriptionBox--optionCard {
    line-height: 1.8 !important;
  }
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    font-family: ${(props) => props.theme.montserrat};
    background-color: #1c1c1e;
    overflow-y: scroll;
    z-index: 150;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #3a3a3c;
      border-radius: 7px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(246, 92, 139);
    }

    &__header {
      display: flex;
      align-items: center;
      padding: 1rem 5rem;
      border-bottom: 1px solid rgba(112, 112, 112, 0.4);

      .backIcon {
        margin-right: 1rem;
        cursor: pointer;

        img {
          height: 1rem;
          width: auto;
        }
      }

      .name {
        font-size: 1.2rem;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    .infoText {
      font-size: 0.85rem;
      font-weight: 300;
      color: #f2f2f7;
      text-align: center;
      padding: 1rem 0;
    }

    &__subscriptionBox {
      width: 45%;
      min-width: 480px;
      margin: 0 auto 8rem auto;
      background-color: #2c2c2e;
      padding: 1.5rem 2rem;
      border-radius: 0.5rem;
      position: relative;

      &--currentSubscription {
        width: 100%;
        border-radius: 0.5rem;
        background: ${(props) => props.theme.primaryGradient};
        padding: 1.5rem 3rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 2rem;

        .role {
          font-family: brother-1816, sans-serif;
          font-size: 1.5rem;
          text-transform: uppercase;
          color: #fff;
          font-weight: 500;
          line-height: 1;
        }

        .userType {
          line-height: 1.3;
          font-size: 0.8rem;
          font-weight: 300;
        }

        .infoText {
          color: #fde5b8;
          font-size: 0.9rem;
          font-weight: 500;
          line-height: 1.2;
        }

        .plan {
          background-color: #fff;
          padding: 0.7rem 1.5rem;
          border-radius: 0.5rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          span {
            color: rgba(0, 0, 0, 0.75);
            font-weight: 400;
            font-size: 0.65rem;
          }
          .renew-now {
            color: ${(props) => props.theme.primaryColor1};
            font-weight: 400;
            font-size: 0.85rem;
            text-decoration: underline;
            cursor: pointer;
            text-transform: uppercase;
          }

          p {
            color: ${(props) => props.theme.primaryColor1};
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1;
          }
          .subscription-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .subscription-text-small {
            cursor: pointer;
            font-size: small;
            font-weight: 300;
            margin-top: 5px;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &--options {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        max-height: 15rem;
        overflow-y: overlay;
        /* width */
        ::-webkit-scrollbar {
          width: 5px;
        }
        /* Track */
        ::-webkit-scrollbar-track {
          background: #3a3a3c;
          border-radius: 7px;
        }
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: rgb(246, 92, 139);
        }
      }

      &--optionCard {
        background-color: #3a3a3c;
        width: 48%;
        border-radius: 0.25rem;
        padding: 1.5rem;
        position: relative;
        cursor: pointer;
        margin: 2.5px;

        .title {
          color: #fff;
          font-size: 0.75rem;
          font-weight: 500;
        }
        .cardInfo {
          display: flex;
          font-size: small;
          justify-content: space-between;
          .default-card {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }

        .secondary-text {
          font-size: 0.9rem;
          font-weight: 300;
        }

        .actionText {
          color: ${(props) => props.theme.primaryColor1};
          font-weight: 500;
          font-size: 0.7rem;

          &__action {
            text-transform: uppercase;
            font-family: "brother-1816", sans-serif;
            text-decoration: underline;
            color: ${(props) => props.theme.primaryColor1};
            font-size: 0.725rem;

            &--delete {
              color: #d24545;
            }
          }
        }

        .arrow {
          position: absolute;
          bottom: 0;
          right: 1rem;

          img {
            height: 0.8rem;
            width: auto;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .wrapper {
      &__header {
        padding: 0.8rem 2rem;
      }

      &__subscriptionBox {
        min-width: 90%;
        width: 90%;
        padding: 1rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .wrapper {
      &__header {
        padding: 0.5rem 1.5rem;

        .name {
          font-size: 1rem;
        }
      }

      &__subscriptionBox {
        width: 100%;

        &--optionCard {
          width: 100%;
          margin-bottom: 0.25rem;
        }
      }
    }
  }
`;
