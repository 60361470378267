import React, { useEffect, useRef, useState, useContext } from "react";
import styled from "styled-components";
import classnames from "classnames";
import Button from "../../components/Elements/Button";
import { usePopper } from "react-popper";
import { HexColorPicker } from "react-colorful";
import { Row, Col } from "react-grid-system";
import { darkThemeProvider } from "../../styles/theme";
import { GlobalContext } from "../../context/GlobalContext";

const AVAILABLE_GRADIENTS = [
  "linear-gradient(29deg, #f99a2d, #f65c8b 71%)",
  "linear-gradient(120deg, #f093fb 0%, #f5576c 100%)",
  "linear-gradient(135deg, #667eea 0%, #764ba2 100%)",
  "linear-gradient(to right, #6a11cb 0%, #2575fc 100%)",
  "linear-gradient(to top, #c471f5 0%, #fa71cd 100%)",
  "linear-gradient(-225deg, #A445B2 0%, #D41872 52%, #FF0066 100%)",
  "linear-gradient(-225deg, #65379B 0%, #886AEA 53%, #6457C6 100%)",
  "linear-gradient(-60deg, #ff5858 0%, #f09819 100%)",
  "linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%)",
  "linear-gradient(-225deg, #AC32E4 0%, #7918F2 48%, #4801FF 100%)",
  "linear-gradient(-225deg, #3D4E81 0%, #5753C9 48%, #6E7FF3 100%)",
];

const GradientPicker = ({ value, setValue }) => {
  const boxRef = useRef();
  const colorPickerRef = useRef();
  const { setSiteTheme } = useContext(GlobalContext);
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [showButtonStyles, setShowButtonStyles] = useState(true);

  // useEffect(() => {
  //   if (value && openColorPicker === false) {
  //     const regex = /#[0-9a-fA-F]{6}\b/g;
  //     const primaryColorsArr = value.match(regex);

  //     const updatedTheme = darkThemeProvider({
  //       button: value,
  //       primaryGradient: value,
  //       primaryColor1: primaryColorsArr[0],
  //       primaryColor2: primaryColorsArr[1],
  //     });

  //     setSiteTheme(updatedTheme);
  //   }
  // }, [value, openColorPicker]);

  const { styles, attributes } = usePopper(
    boxRef.current,
    colorPickerRef.current,
    {
      placement: "right",
    }
  );

  return (
    <GradientPickerStyled>
      <div className="title">choose brand color</div>

      <div
        onClick={() => setOpenColorPicker(!openColorPicker)}
        ref={boxRef}
        className="gradientBox"
        style={{
          background: value,
        }}
      >
        <div className="gradientBox__pickColor">
          {openColorPicker ? "click to close" : "pick color"}
        </div>
      </div>

      <div
        style={styles.popper}
        {...attributes.popper}
        ref={colorPickerRef}
        className={classnames("colorPicker", {
          colorPicker__hidden: !openColorPicker,
        })}
      >
        <HexColorPicker
          color={"#fff"}
          onChange={(val) => {
            const gradient = `linear-gradient(to right, ${val}, ${val})`;
            setValue(gradient);
          }}
        />
      </div>

      <div className="availableGradientsOptions">
        <div className="availableGradientsOptions__title">
          available gradient options
        </div>

        <div className="availableGradientsOptions__list">
          {AVAILABLE_GRADIENTS.map((item, i) => (
            <div
              key={i}
              onClick={() => {
                setValue(item);
              }}
              style={{
                background: item,
              }}
              className={`availableGradientsOptions__list--item ${
                item === value && "availableGradientsOptions__list--active"
              }`}
            >
              {item === value && <img src="/icons/tick-black.svg" alt="" />}
            </div>
          ))}
        </div>
      </div>

      <div className="buttonLayouts">
        <div className="buttonLayouts__header">
          <div className="buttonLayouts__title">Button Styles</div>
          <div
            onClick={() => setShowButtonStyles(!showButtonStyles)}
            className="buttonLayouts__toggle"
          >
            {showButtonStyles ? "close" : "open"}
          </div>
        </div>

        {showButtonStyles && (
          <div className="buttonLayouts__list">
            <Row className="buttonLayouts__list--row-1">
              <Col sm={6} xs={12}>
                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    mode={"primary"}
                    variant={"box"}
                    size={"small"}
                  >
                    Button demo
                  </Button>
                </div>

                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    mode={"primary"}
                    variant={"box"}
                    size={"medium"}
                  >
                    Button demo
                  </Button>
                </div>

                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    mode={"primary"}
                    variant={"box"}
                    size={"big"}
                  >
                    Button demo
                  </Button>
                </div>
              </Col>

              <Col sm={6} xs={12}>
                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    mode={"primary"}
                    variant={"outline"}
                    size={"small"}
                  >
                    Button demo
                  </Button>
                </div>

                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    mode={"primary"}
                    variant={"outline"}
                    size={"medium"}
                  >
                    Button demo
                  </Button>
                </div>

                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    mode={"primary"}
                    variant={"outline"}
                    size={"big"}
                  >
                    Button demo
                  </Button>
                </div>
              </Col>
            </Row>

            <Row className="buttonLayouts__list--row-1">
              <Col sm={6} xs={12}>
                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    mode={"primary"}
                    variant={"box-round"}
                    size={"small"}
                  >
                    Button demo
                  </Button>
                </div>

                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    mode={"primary"}
                    variant={"box-round"}
                    size={"medium"}
                  >
                    Button demo
                  </Button>
                </div>

                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    mode={"primary"}
                    variant={"box-round"}
                    size={"big"}
                  >
                    Button demo
                  </Button>
                </div>
              </Col>

              <Col sm={6} xs={12}>
                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    mode={"primary"}
                    variant={"box-round-outline"}
                    size={"small"}
                  >
                    Button demo
                  </Button>
                </div>

                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    mode={"primary"}
                    variant={"box-round-outline"}
                    size={"medium"}
                  >
                    Button demo
                  </Button>
                </div>

                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    mode={"primary"}
                    variant={"box-round-outline"}
                    size={"big"}
                  >
                    Button demo
                  </Button>
                </div>
              </Col>
            </Row>

            <Row className="buttonLayouts__list--row-2">
              <Col sm={12}>
                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    width={"fluid"}
                    mode={"primary"}
                    variant={"round"}
                    size={"small"}
                  >
                    Button demo
                  </Button>
                </div>
              </Col>

              <Col sm={12}>
                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    width={"fluid"}
                    mode={"primary"}
                    variant={"round"}
                    size={"medium"}
                  >
                    Button demo
                  </Button>
                </div>
              </Col>

              <Col sm={12}>
                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    width={"fluid"}
                    mode={"primary"}
                    variant={"round"}
                    size={"big"}
                  >
                    Button demo
                  </Button>
                </div>
              </Col>
            </Row>

            <Row className="buttonLayouts__list--row-2">
              <Col sm={12}>
                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    width={"fluid"}
                    mode={"primary"}
                    variant={"round-outline"}
                    size={"small"}
                  >
                    Button demo
                  </Button>
                </div>
              </Col>

              <Col sm={12}>
                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    width={"fluid"}
                    mode={"primary"}
                    variant={"round-outline"}
                    size={"medium"}
                  >
                    Button demo
                  </Button>
                </div>
              </Col>

              <Col sm={12}>
                <div className="buttonLayouts__list--item">
                  <Button
                    disabled={true}
                    bg={value}
                    width={"fluid"}
                    mode={"primary"}
                    variant={"round-outline"}
                    size={"big"}
                  >
                    Button demo
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </GradientPickerStyled>
  );
};

export default GradientPicker;

const GradientPickerStyled = styled.div`
  background-color: #3a3a3c;
  padding: 1.75rem 2rem;
  border-radius: 1rem;

  .title {
    color: white;
    font-size: 1rem;
    text-transform: capitalize;
    font-family: "brother-1816", sans-serif;
    margin-bottom: 1.25rem;
  }

  .gradientBox {
    height: 4rem;
    width: 8rem;
    border-radius: 0.75rem;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &__pickColor {
      font-weight: 500;
      font-family: "brother-1816", sans-serif;
      font-size: 0.675rem;
      text-transform: uppercase;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .colorPicker {
    margin-left: 1rem;
    color: black;
    z-index: 1000;

    &__hidden {
      visibility: hidden;
      pointer-events: none;
    }
  }

  .availableGradientsOptions {
    margin-top: 2rem;

    &__title {
      font-size: 0.7rem;
      text-transform: uppercase;
      opacity: 0.8;
      text-decoration: underline;
    }

    &__list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.75rem;
      margin-top: 0.5rem;

      &--item {
        height: 2rem;
        width: 2rem;
        border-radius: 0.35rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${(props) => props.theme.gradient};
        cursor: pointer;

        img {
          width: 0.85rem;
          height: auto;
        }
      }

      &--active {
        border: 1px solid black;
      }
    }
  }

  .buttonLayouts {
    margin-top: 2rem;

    &__header {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
    }

    &__toggle {
      font-size: 0.55rem;
      padding: 0.35rem 0.8rem;
      line-height: 1;
      font-family: "brother-1816", sans-serif;
      text-transform: uppercase;
      font-weight: 500;
      border-radius: 10rem;
      border: 1px solid white;
      opacity: 0.6;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    &__title {
      font-size: 0.7rem;
      text-transform: uppercase;
      opacity: 0.8;
      text-decoration: underline;
    }

    &__list {
      &--item {
        margin-bottom: 1rem;
      }
    }
  }
`;
