import {
    PROFILE_ELEMENT_BANNERS,
    PROFILE_ELEMENT_BANNERS_SECOND
  } from "../actions/types";
  
  
  const profileElementsBanners = (state = {bannersloading : true}, action) => {
    switch (action.type) {
      case PROFILE_ELEMENT_BANNERS:
        return {
            bannersloading: false,
            usersbanners: action.payload,
            totalPage : action.totalPage
        };
        case PROFILE_ELEMENT_BANNERS_SECOND:
            const allBanners = [...state.usersbanners, ...action.payload];
            return {
                bannersloading: false,
                usersbanners: allBanners,
                totalPage : action.totalPage
            };
      default:
        return state;
    }
  };
  
  export default profileElementsBanners;
  