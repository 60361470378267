import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../Elements/Button";
import Input from "../../Elements/Input";
import LoaderIcon from "../../icons/loader.svg";
import WaitAnimation from "../../Elements/WaitAnimation";
import Logo from "../../Elements/Logo";
import { AuthContext } from "../../../context/AuthContext";
import { GlobalContext } from "../../../context/GlobalContext";
import { loginUser } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { notificationMessage } from "../../../utils/toastNotifications";
import { toast } from "react-toastify";
import { LOGIN_RESET } from "../../../actions/types";

const Login = ({ switchStep }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    welcomeUsername,
    setValidationError,
    setAuthState,
    loader,
    setLoader,
    setEmail,
  } = useContext(AuthContext);
  const { setShowAuthenticationModel, setShowWelcomeScreen } =
    useContext(GlobalContext);
  const user = useSelector((state) => state.user);
  const [password, setPassword] = useState("");

  useEffect(() => {
    document.getElementById("password").focus();
  }, []);

  useEffect(() => {
    if (user?.id) {
      clearForm();
      // history.push("/home");
      setAuthState("CHECK_EMAIL");
      setShowAuthenticationModel(false);
      setValidationError(null);
    }

    if (user?.loginLoading) {
      setShowWelcomeScreen(true);
    }

    if (user?.loginError) {
      setShowWelcomeScreen(false);
      setPassword("");
      setLoader(false);
      toast.error(notificationMessage.invalidError);
      dispatch({
        type: LOGIN_RESET,
      });
      document.getElementById("password").focus();
    }
  }, [user]);

  const loginHandler = (e) => {
    e.preventDefault();
    if (!password) {
      return setValidationError({
        type: "error",
        message: "Please enter your password",
      });
    }

    dispatch(
      loginUser({
        username: welcomeUsername,
        password,
      })
    );
  };

  const clearForm = () => {
    setEmail(null);
    setLoader(false);
    setPassword(null);
  };

  return (
    <LoginStyled>
      <div className="paddingBox">
        <div className="title">
          Welcome back,{" "}
          <div className="title__imp">
            {welcomeUsername ? welcomeUsername : "Isutra User"}
          </div>
        </div>
        <div
          onClick={() => switchStep("CHECK_EMAIL")}
          className="alternateOption"
        >
          This isn't you? Switch Email
        </div>

        <div className="field">
          <Input
            label="Enter password"
            name="password"
            id="password"
            type="password"
            placeholder="*******"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            emailExistHandler={loginHandler}
          />
        </div>
        <div
          onClick={() => {
            switchStep("FORGOT_PASSWORD");
          }}
          className="alternativeOptionV2"
        >
          Forgot Password ?
        </div>

        <Button
          mode="primary"
          width="fluid"
          variant="round"
          size="medium"
          onClick={(e) => {
            setLoader(true);
            loginHandler(e);
          }}
          className="submit"
          iconLeft={true}
        >
          {loader ? (
            <>
              <img src={LoaderIcon} alt="" className="imgIcon" />
              Please Wait...
            </>
          ) : (
            "LOG IN"
          )}
        </Button>
      </div>
    </LoginStyled>
  );
};

export default Login;

const LoginStyled = styled.div`
  .title {
    font-size: 1.1rem;
    display: flex;
    align-items: center;

    &__imp {
      background: -webkit-${(props) => props.theme.primaryGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-left: 0.5rem;
    }
  }

  .alternateOption {
    font-size: 0.75rem;
    text-decoration: underline;
    font-weight: 300;
    cursor: pointer;
  }

  .field {
    margin-top: 2rem;
  }

  .submit {
    margin-top: 2rem;
    width: 100%;
  }

  .animationBox {
    padding: 2rem;
  }

  .alternativeOptionV2 {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 500;
    text-decoration: underline;
    color: rgba(255, 255, 255, 0.6);
    font-family: brother-1816, sans-serif;
    margin-top: 0.35rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
`;
