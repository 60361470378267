import {
    PROFILE_ELEMENT_THREADS,
    PROFILE_ELEMENT_THREADS_SECOND
  } from "../actions/types";
  
  
  const profileElementsThreads = (state = {threadsloading : true}, action) => {
    switch (action.type) {
      case PROFILE_ELEMENT_THREADS:
        return {
            threadsloading: false,
            usersthreads: action.payload,
            totalPage : action.totalPage
        };
        case PROFILE_ELEMENT_THREADS_SECOND:
            const allThreads = [...state.usersthreads, ...action.payload];
            return {
                threadsloading: false,
                usersthreads: allThreads,
                totalPage : action.totalPage
            };
      default:
        return state;
    }
  };
  
  export default profileElementsThreads;
  