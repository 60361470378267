import React from "react";
import styled from "styled-components";
import Section from "./Section";

const ProfileElements = () => {
  return (
    <ProfileElementsSkeletonStyled>
      <Section sectionName="element_videos" />
      <Section sectionName="element_videos" />
      <Section sectionName="element_videos" />
    </ProfileElementsSkeletonStyled>
  );
};

export default ProfileElements;

const ProfileElementsSkeletonStyled = styled.div``;
