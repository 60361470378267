require("dotenv").config();

export default {
  sockets: {
    socketBackendUrl: "https://backend.isutra.online/",
    webSocketBackendUrl:
      "wss://619somw006.execute-api.us-east-1.amazonaws.com/production",
  },
  api: {
    url: process.env.REACT_APP_BACKEND_URL,
  },
  frontend: {
    baseUrl: process.env.REACT_APP_BASE_URL,
  },
  stripe: {
    SUBSCRIPTION_PLAN_5: "price_1Igiy1FrBwhwu8k51yjbmsie",
    STRIPE_PUBLIC_KEY: "pk_test_nrAHktA2rUOx22zahIeu8AGg007EgPdvGG",
    REACT_APP_STRIPE_ONBOARDING:
      "https://dashboard.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_IxepBgqQH3H3zWKcFBHKgh8JlP3sAOZq&scope=read_write&redirect_uri=https://isutra.online",
  },
  aws: {
    CLOUDFRONT_URL: "https://d19ozhxk4iq3bz.cloudfront.net",
  },
  mux: {
    API_KEY: process.env.REACT_APP_MUX_API_KEY,
    MUX_ASSET_ID: "https://api.mux.com/video/v1/assets",
    MUX_STREAMING_URL: "https://stream.mux.com",
    MUX_THUMBNIL: "https://image.mux.com",
  },
  csc: {
    //country/state/city
    REACT_APP_CSC_BASE_URL: "https://api.countrystatecity.in/v1",
    REACT_APP_CSC_TOKEN: process.env.REACT_APP_CSC_TOKEN,
  },
  social: {
    google: {
      REACT_APP_GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    },
    facebook: {
      REACT_APP_FACEBOOK_ID: process.env.REACT_APP_FACEBOOK_ID,
    },
  },
  env: "production",
  log: "debug",
};
