import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HashtagCard from "../components/Cards/HashtagCard";
import Error from "../components/Elements/Error";
import Card from "../skeletons/General/Card";
import { useDispatch, useSelector } from "react-redux";
import { getHashtags } from "../actions/hashtag";
import { useInView } from "react-intersection-observer";
import { Row, Col } from "react-grid-system";
import { RESET_SINGLE_HASHTAG_STATE } from "../actions/hashtag/type";

const Hashtags = () => {
  const { ref: trackerRef, inView: trackerIsVisible } = useInView();

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1);

  const { hashtags, hasMore, isFetching } = useSelector(
    (state) => state.hashtags
  );

  useEffect(() => {
    dispatch(getHashtags(page, limit));
    dispatch({
      type: RESET_SINGLE_HASHTAG_STATE,
    });
  }, []);

  useEffect(() => {
    if (hasMore && trackerIsVisible && !isFetching) {
      loadMoreHashtagsHandler();
    }
  }, [trackerIsVisible, hasMore, isFetching]);

  const loadMoreHashtagsHandler = () => {
    setPage(page + 1);
    dispatch(getHashtags(page + 1, limit));
  };

  return (
    <HashtagsStyled>
      {!isFetching && !hashtags?.length && <Error text="No hashtags found" />}

      <Row className="hashtags">
        {hashtags &&
          hashtags.length > 0 &&
          hashtags.map((item, i) => (
            <Col md={3} sm={6} xs={12} className="hashtags__item">
              <HashtagCard key={i} item={item} />
            </Col>
          ))}

        {isFetching && (
          <>
            {Array.from({ length: 4 }).map((item, i) => (
              <Col md={3} sm={6} xs={12}>
                <Card cardName="hashtag" />
              </Col>
            ))}
          </>
        )}
      </Row>

      {hasMore === false && (
        <div className="noMore">
          You have reached the end of hashtags results
        </div>
      )}

      {!isFetching && <div ref={trackerRef} className="tracker" />}
    </HashtagsStyled>
  );
};

export default Hashtags;

const HashtagsStyled = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 4rem;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  .hashtags {
    &__item {
      cursor: pointer;
    }
  }

  .tracker {
    width: 100%;
    height: 5rem;
    background: transparent;
    opacity: 0;
    margin-top: 5rem;
  }

  .noMore {
    width: 100%;
    font-weight: 300;
    text-align: center;
    font-size: 0.85rem;
    color: rgba(255, 255, 255, 0.5);
    line-height: 1;
    padding-top: 2.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  }
`;
