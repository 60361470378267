import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import styled from "styled-components";
import CloseIcon from "../icons/close.svg";
import PlayIcon from "../icons/play.svg";
import PauseIcon from "../icons/pause.svg";
import SoundOn from "../icons/sound-on.svg";
import SoundOff from "../icons/sound-off.svg";
import ReactPlayer from "react-player";
import Loader from "../Elements/Loader";
import ChevronDownIcon from "../icons/chevron-down.svg";
import Upvote from "../Elements/Upvote";
import debounce from "lodash.debounce";
import api from "../../services/api";
import NextArrowIcon from "../icons/next-arrow.svg";
import PrevArrowIcon from "../icons/prev-arrow.svg";
import BottomBar from "../NewHomeComponents/BottomBar/BottomBar";
import MenuOptions from "../Cards/MenuOptions";
import { useMediaQuery } from "react-responsive";
import { GlobalContext } from "../../context/GlobalContext";
import { useHistory } from "react-router-dom";
import {
  checkAddToStram,
  getMuxPlaybackUrls,
  SubscribeNewChannel,
  unsubscribeChannel,
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { notificationMessage } from "../../utils/toastNotifications";
import { toast } from "react-toastify";
import { MOMENTS_OTHER } from "../../utils/menuOptions";
import config from "../../config";
import { motion } from "framer-motion";
import {
  momentDetailsLeftVarients,
  momentDetailsRightVarients,
  momentDetailsCenterVarients,
} from "../../utils/animations";
import { useSwipeable } from "react-swipeable";
import { addDefaultImage } from "../../utils";
import ImageNotfoundPlaceholder from "../../assets/image_notfound_placeholder.svg";
import { UNSUBSCRIBE_FROM_VIDEO } from "../../actions/types";

const MomentDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    showMomentDetailsModel,
    setShowMomentDetailsModel,
    selectedMoment,
    setSelectedMoment,
    portalTab,
    showAuthenticationModel,
    setShowAuthenticationModel,
  } = useContext(GlobalContext);
  const { moments, myMoments, streamsMoments } = useSelector(
    (state) => state.moments
  );
  const { getData: profileElements } = useSelector(
    (state) => state.profileElements
  );

  const user = useSelector((state) => state.user);
  const profile = useSelector((state) => state.profile);
  const isSubscribe = useSelector((state) => state.channelSubscribed);
  const playerRef = useRef();
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [played, setPlayed] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [started, setStarted] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  // const [videoUrl, setVideoUrl] = useState(
  //   "https://res.cloudinary.com/dq87imngy/video/upload/v1670420385/shorts-03_ubczh3.mp4"
  // );
  const [videoThumbnail, setVideoThumbnail] = useState(
    "https://www.cheatsheet.com/wp-content/uploads/2020/11/Marvel-star-Robert-Downey-Jr.jpg"
  );
  const [isSubscribed, setIsSubscribed] = useState(""); // IS_MINE, SUBSCRIBED, UNSUBSCRIBED
  const [showNextArrow, setShowNextArrow] = useState(false);
  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [upvote, setUpvote] = useState(null);
  const [totalUpvotes, setTotalUpvotes] = useState(null);
  const [readyToCallUpvoteApi, setReadyToCallUpvoteApi] = useState(false);
  const [showFinalAnimation, setShowFinalAnimation] = useState(false);
  const [fetchUpvoteInfoLoading, setFetchUpvoteInfoLoading] = useState(null);
  const [isMomentLiked, setIsMomentLiked] = useState(false);
  const [momentsToLoad, setMomentsToLoad] = useState(null); //  profile_moments, all_moments
  const [gestureAnimation, setGestureAnimation] = useState("center");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (history) {
      if (
        history.location.pathname === "/" ||
        history.location.pathname === "/home" ||
        history.location.pathname.match("/moments")
      ) {
        setMomentsToLoad("all_moments");
      }

      if (history.location.pathname.match("/streams")) {
        setMomentsToLoad("streams_moments");
      }

      if (
        history.location.pathname.match("/channel/") &&
        portalTab === 0 &&
        profile?.userrole === 1
      ) {
        setMomentsToLoad("my_moments");
      }

      if (
        history.location.pathname.match("/channel/") &&
        portalTab === 2 &&
        profile?.userrole === 2
      ) {
        setMomentsToLoad("my_moments");
      }

      if (
        history.location.pathname.match("/channel/") &&
        portalTab === 0 &&
        profile?.userrole === 2
      ) {
        setMomentsToLoad("profile_moments");
      }
    }
  }, [history, portalTab, profile]);
  const decodeStreamingUrl = (encodedUrl) => {
    return atob(encodedUrl);
  };
  useEffect(() => {
    const fetchMuxVideoUrls = async () => {
      setLoading(true);

      if (selectedMoment && selectedMoment.muxid) {
        try {
          const playbackUrls = await getMuxPlaybackUrls(selectedMoment.muxid);

          if (playbackUrls && playbackUrls.hls) {
            const decodedHLSUrl = decodeStreamingUrl(playbackUrls.hls);
            setVideoUrl(decodedHLSUrl);
            // setVideoUrl(playbackUrls.hls);
          } else {
            setVideoUrl(config.aws.CLOUDFRONT_URL + selectedMoment.videoUrl);
          }
        } catch (error) {
          console.error("Error loading Mux playback URL:", error);
          setVideoUrl(config.aws.CLOUDFRONT_URL + selectedMoment.videoUrl);
        }
      } else {
        setVideoUrl(
          config.aws.CLOUDFRONT_URL +
            (selectedMoment && selectedMoment.videoUrl)
        );
      }

      setLoading(false);
    };

    fetchMuxVideoUrls();
  }, [selectedMoment]);
  const isMobileTablet = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  useEffect(async () => {
    if (showMomentDetailsModel && readyToCallUpvoteApi) {
      await upvoteApiCallHandler(selectedMoment?.id, upvote);
      setReadyToCallUpvoteApi(false);
    }

    if (fetchUpvoteInfoLoading === false) {
      if (upvote > 0 && !isMomentLiked) {
        setIsMomentLiked(true);
        setTotalUpvotes(totalUpvotes + 1);
      }

      if (upvote === 0 && isMomentLiked) {
        setIsMomentLiked(false);
        setTotalUpvotes(totalUpvotes - 1);
      }
    }
  }, [
    upvote,
    showMomentDetailsModel,
    readyToCallUpvoteApi,
    isMomentLiked,
    fetchUpvoteInfoLoading,
  ]);

  useEffect(() => {
    if (
      showMomentDetailsModel &&
      momentsToLoad &&
      momentsToLoad === "all_moments"
    ) {
      if (moments && moments.length > 0) {
        const currentIndex = moments.findIndex(
          (item) => item?.id === selectedMoment?.id
        );

        if (currentIndex === moments.length - 1) {
          setShowNextArrow(false);
        } else {
          setShowNextArrow(true);
        }

        if (currentIndex === 0) {
          setShowPrevArrow(false);
        } else {
          setShowPrevArrow(true);
        }
      }
    }

    if (
      showMomentDetailsModel &&
      momentsToLoad &&
      momentsToLoad === "profile_moments"
    ) {
      const momentsProfileElements = profileElements?.filter(
        (item) => item.type === "moment"
      );

      if (
        momentsProfileElements[0].featuredDetailedContents &&
        momentsProfileElements[0].featuredDetailedContents.length > 0
      ) {
        const currentIndex =
          momentsProfileElements[0].featuredDetailedContents.findIndex(
            (item) => item?.id === selectedMoment?.id
          );

        if (
          currentIndex ===
          momentsProfileElements[0].featuredDetailedContents.length - 1
        ) {
          setShowNextArrow(false);
        } else {
          setShowNextArrow(true);
        }

        if (currentIndex === 0) {
          setShowPrevArrow(false);
        } else {
          setShowPrevArrow(true);
        }
      }
    }

    if (
      showMomentDetailsModel &&
      momentsToLoad &&
      momentsToLoad === "my_moments"
    ) {
      if (myMoments && myMoments.length > 0) {
        const currentIndex = myMoments.findIndex(
          (item) => item?.id === selectedMoment?.id
        );

        if (currentIndex === myMoments.length - 1) {
          setShowNextArrow(false);
        } else {
          setShowNextArrow(true);
        }

        if (currentIndex === 0) {
          setShowPrevArrow(false);
        } else {
          setShowPrevArrow(true);
        }
      }
    }

    if (
      showMomentDetailsModel &&
      momentsToLoad &&
      momentsToLoad === "streams_moments"
    ) {
      if (streamsMoments && streamsMoments.length > 0) {
        const currentIndex = streamsMoments.findIndex(
          (item) => item?.id === selectedMoment?.id
        );

        if (currentIndex === streamsMoments.length - 1) {
          setShowNextArrow(false);
        } else {
          setShowNextArrow(true);
        }

        if (currentIndex === 0) {
          setShowPrevArrow(false);
        } else {
          setShowPrevArrow(true);
        }
      }
    }
  }, [momentsToLoad, showMomentDetailsModel, selectedMoment]);

  useEffect(async () => {
    if (selectedMoment) {
      await fetchUpvoteInfoHandler(selectedMoment?.id);

      setShowFinalAnimation(false);
    }
  }, [selectedMoment]);

  useEffect(() => {
    if (selectedMoment && selectedMoment.id) {
      if (user.id) {
        dispatch(checkAddToStram(selectedMoment.User.username, user.id));
      }
      setVideoUrl(selectedMoment?.videoUrl);
      setVideoThumbnail(selectedMoment?.coverImgUrl);
      setPlaying(true);

      if (!selectedMoment?.isMine && selectedMoment?.isSubscribed) {
        setIsSubscribed("SUBSCRIBED");
      } else if (!selectedMoment?.isMine && !selectedMoment?.isSubscribed) {
        setIsSubscribed("UNSUBSCRIBED");
      } else if (selectedMoment?.isMine) {
        setIsSubscribed("ISMINE");
      }
    }
  }, [selectedMoment]);

  const closeHandler = () => {
    setIsMomentLiked(false);
    setSelectedMoment(null);
    setShowMomentDetailsModel(false);
  };

  const handleProgress = (progressState) => {
    setPlayed(progressState.played);
    setLoaded(progressState.loaded);
  };

  const isBadgeOrAvatar = () => {
    const image = selectedMoment?.User?.avatar
      ? selectedMoment.User.avatar
      : selectedMoment?.User?.badge
      ? selectedMoment.User.badge
      : null;

    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <div className="imageBadge">
          <img className="badge" src={image} alt="" />
        </div>
      );
    } else {
      return (
        <img
          onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
          className="imageAvatar"
          src={config.aws.CLOUDFRONT_URL + image}
          alt=""
        />
      );
    }
  };

  const addToStreamsHandler = (username) => {
    if (!user.id) {
      // it means user is not logged in
      setShowAuthenticationModel(true);

      return;
    }

    setIsSubscribed("SUBSCRIBED");
    if (user.id) {
      dispatch(SubscribeNewChannel(username, user.id));
      dispatch(checkAddToStram(username, user.id));
    }
  };

  const removeFromStreamsHandler = (username) => {
    if (!user.id) {
      // it means user is not logged in
      toast.error(notificationMessage.loginRequired);

      return;
    }

    setIsSubscribed("UNSUBSCRIBED");
    if (user.id) {
      dispatch(
        unsubscribeChannel({
          type: UNSUBSCRIBE_FROM_VIDEO,
          channelId: selectedMoment?.User?.id,
          username,
        })
      );
      dispatch(checkAddToStram(username, user.id));
    }
  };

  const nextMomentHandler = () => {
    setFetchUpvoteInfoLoading(null);
    setIsMomentLiked(false);

    if (momentsToLoad === "all_moments") {
      if (moments && moments.length > 0) {
        const currentIndex = moments.findIndex(
          (item) => item?.id === selectedMoment?.id
        );

        if (currentIndex === moments.length - 1) {
          setSelectedMoment(null);
          setShowMomentDetailsModel(false);
          history.push(`/moments?skip=10`);
          return;
        }

        setSelectedMoment(moments[currentIndex + 1]);
      } else {
        return;
      }
    }

    if (momentsToLoad === "profile_moments") {
      const momentsProfileElements = profileElements?.filter(
        (item) => item.type === "moment"
      );

      if (
        momentsProfileElements[0].featuredDetailedContents &&
        momentsProfileElements[0].featuredDetailedContents.length > 0
      ) {
        const currentIndex =
          momentsProfileElements[0].featuredDetailedContents.findIndex(
            (item) => item?.id === selectedMoment?.id
          );

        if (
          currentIndex ===
          momentsProfileElements[0].featuredDetailedContents.length - 1
        ) {
          setSelectedMoment(null);
          setShowMomentDetailsModel(false);
          return;
        }

        setSelectedMoment(
          momentsProfileElements[0].featuredDetailedContents[currentIndex + 1]
        );
      }
    }

    if (momentsToLoad === "my_moments") {
      if (myMoments && myMoments.length > 0) {
        const currentIndex = myMoments.findIndex(
          (item) => item?.id === selectedMoment?.id
        );

        if (currentIndex === myMoments.length - 1) {
          setSelectedMoment(null);
          setShowMomentDetailsModel(false);
          return;
        }

        setSelectedMoment(myMoments[currentIndex + 1]);
      }
    }

    if (momentsToLoad === "streams_moments") {
      if (streamsMoments && streamsMoments.length > 0) {
        const currentIndex = streamsMoments.findIndex(
          (item) => item?.id === selectedMoment?.id
        );

        if (currentIndex === streamsMoments.length - 1) {
          setSelectedMoment(null);
          setShowMomentDetailsModel(false);
          return;
        }

        setSelectedMoment(streamsMoments[currentIndex + 1]);
      }
    }
  };

  const prevMomentHandler = () => {
    setFetchUpvoteInfoLoading(null);
    setIsMomentLiked(false);

    if (momentsToLoad === "all_moments") {
      if (moments && moments.length > 0) {
        const currentIndex = moments.findIndex(
          (item) => item.id === selectedMoment.id
        );

        if (currentIndex === 0) {
          history.push(`/moments?skip=10`);
          setSelectedMoment(null);
          setShowMomentDetailsModel(false);
          return;
        }

        setSelectedMoment(moments[currentIndex - 1]);
      } else {
        return;
      }
    }

    if (momentsToLoad === "profile_moments") {
      const momentsProfileElements = profileElements?.filter(
        (item) => item.type === "moment"
      );

      if (
        momentsProfileElements[0].featuredDetailedContents &&
        momentsProfileElements[0].featuredDetailedContents.length > 0
      ) {
        const currentIndex =
          momentsProfileElements[0].featuredDetailedContents.findIndex(
            (item) => item.id === selectedMoment.id
          );

        if (currentIndex === 0) {
          setSelectedMoment(null);
          setShowMomentDetailsModel(false);
          return;
        }

        setSelectedMoment(
          momentsProfileElements[0].featuredDetailedContents[currentIndex - 1]
        );
      }
    }

    if (momentsToLoad === "my_moments") {
      if (myMoments && myMoments.length > 0) {
        const currentIndex = myMoments.findIndex(
          (item) => item.id === selectedMoment.id
        );

        if (currentIndex === 0) {
          setSelectedMoment(null);
          setShowMomentDetailsModel(false);
          return;
        }

        setSelectedMoment(myMoments[currentIndex - 1]);
      }
    }

    if (momentsToLoad === "streams_moments") {
      if (streamsMoments && streamsMoments.length > 0) {
        const currentIndex = streamsMoments.findIndex(
          (item) => item.id === selectedMoment.id
        );

        if (currentIndex === 0) {
          setSelectedMoment(null);
          setShowMomentDetailsModel(false);
          return;
        }

        setSelectedMoment(streamsMoments[currentIndex - 1]);
      }
    }
  };

  const upvoteHandler = () => {
    if (!user?.id) {
      return (
        document.getElementById("user-name").focus(),
        setShowAuthenticationModel(true)
      );
    }

    setShowFinalAnimation(true);

    if (upvote < 3) {
      setUpvote(upvote + 1);
    } else {
      setUpvote(0);
    }

    setReadyToCallUpvoteApi(true);
  };

  const upvoteApiCallHandler = useCallback(
    debounce(async (momentId, upvote) => {
      const obj = {
        upvote,
      };
      await api.post(`moment/${momentId}/upvote-moment`, obj);
    }, 1000),
    []
  );

  const momentEndHandler = () => {
    if (momentsToLoad === "profile_moments") {
      const momentsProfileElements = profileElements?.filter(
        (item) => item.type === "moment"
      );

      if (
        momentsProfileElements[0].featuredDetailedContents &&
        momentsProfileElements[0].featuredDetailedContents.length === 1
      ) {
        setSelectedMoment(null);
        setShowMomentDetailsModel(false);
        return;
      }

      if (
        momentsProfileElements[0].featuredDetailedContents &&
        momentsProfileElements[0].featuredDetailedContents.length > 1
      ) {
        nextMomentHandler();
        return;
      }
    }

    if (momentsToLoad === "all_moments") {
      if (moments && moments.length === 1) {
        setSelectedMoment(null);
        setShowMomentDetailsModel(false);
        return;
      }

      if (moments && moments.length > 1) {
        nextMomentHandler();
        return;
      }
    }

    if (momentsToLoad === "my_moments") {
      if (myMoments && myMoments.length === 1) {
        setSelectedMoment(null);
        setShowMomentDetailsModel(false);
        return;
      }

      if (myMoments && myMoments.length > 1) {
        nextMomentHandler();
        return;
      }
    }

    if (momentsToLoad === "streams_moments") {
      if (streamsMoments && streamsMoments.length === 1) {
        setSelectedMoment(null);
        setShowMomentDetailsModel(false);
        return;
      }

      if (streamsMoments && streamsMoments.length > 1) {
        nextMomentHandler();
        return;
      }
    }
  };

  const fetchUpvoteInfoHandler = async (momentId) => {
    if (!user?.id) {
      setFetchUpvoteInfoLoading(false);
      return;
    }

    setFetchUpvoteInfoLoading(true);
    const res = await api.get(`moment/get-upvoteInfo/${momentId}`);
    setUpvote(res.data?.data?.myUpvote?.upvote || null);
    setTotalUpvotes(res.data?.data?.upvotes || 0);

    if (res.data?.data?.myUpvote?.upvote) {
      setIsMomentLiked(true);
    }

    setFetchUpvoteInfoLoading(false);
  };

  const loginHandler = () => {
    document.getElementById("user-name").focus();
    setShowAuthenticationModel(true);
  };

  const swipeHandler = useSwipeable({
    onSwipedLeft: (e) => {
      nextMomentHandler();
    },
    onSwipedRight: (e) => {
      prevMomentHandler();
    },
  });

  const URL_REGEX = /(https?:\/\/[^\s]+)/g;
  function CheckHyperLinks(description) {
    const desc = description.replace(/\n/g, " <br/> ");
    const words = desc.split(" ");
    const results = [];
    words.forEach((index) => {
      if (index.match(URL_REGEX)) {
        results.push(
          `<a href=${index} target="_blank" style="color:#4b4bbb">${index}</a>`
        );
      } else {
        results.push(`${index}`);
      }
    });
    const mainstring = results.toString();
    return mainstring.replace(/\,/g, " ");
  }

  return (
    <MomentDetailsStyled
      className={`${
        showMomentDetailsModel ? "showMomentsDetailsModel__Opened" : null
      }`}
      {...swipeHandler}
      as={motion.div}
      initial={momentDetailsCenterVarients.hidden}
      animate={momentDetailsCenterVarients.visible}
    >
      {!selectedMoment ? (
        <div className="loadingBox">Please Wait...</div>
      ) : (
        <>
          <div className="momentDetails__left">
            {!isMobile && showPrevArrow && (
              <div
                onClick={prevMomentHandler}
                className="momentDetails__left--prevMoment"
              >
                <img src={PrevArrowIcon} alt="" />
              </div>
            )}

            {!isMobile && showNextArrow && (
              <div
                onClick={nextMomentHandler}
                className="momentDetails__left--nextMoment"
              >
                <img src={NextArrowIcon} alt="" />
              </div>
            )}

            <div onClick={closeHandler} className="momentDetails__left--close">
              {isMobileTablet ? (
                <>
                  <img src={ChevronDownIcon} alt="" />
                  <div className="text">Close</div>
                </>
              ) : (
                <>
                  <img src={CloseIcon} alt="" />
                </>
              )}
            </div>

            <div className="momentDetails__left--overlay"></div>
            <div className="momentDetails__left--video">
              <div onClick={() => setPlaying(!playing)} className="player">
                {loading ? (
                  <div className="loadingBox"></div>
                ) : (
                  <ReactPlayer
                    {...swipeHandler}
                    ref={playerRef}
                    width="100%"
                    height="100%"
                    // url={config.aws.CLOUDFRONT_URL + videoUrl}
                    url={
                      videoUrl.includes("mux.com")
                        ? videoUrl
                        : config.aws.CLOUDFRONT_URL + videoUrl
                    }
                    playing={playing}
                    muted={muted}
                    onStart={() => setStarted(true)}
                    onProgress={handleProgress}
                    onEnded={momentEndHandler}
                    loop={false}
                    playsinline={true}
                  />
                )}
              </div>

              {started && (
                <div className="controls">
                  <div className="controls__left">
                    <div onClick={() => setPlaying(!playing)} className="icon">
                      <img src={playing ? PauseIcon : PlayIcon} alt="" />
                    </div>
                  </div>

                  <div className="controls__right">
                    <div onClick={() => setMuted(!muted)} className="icon">
                      <img src={muted ? SoundOff : SoundOn} alt="" />
                    </div>
                  </div>
                </div>
              )}

              {!started && !playing && (
                <div className="thumbnail">
                  <img
                    src={config.aws.CLOUDFRONT_URL + videoThumbnail}
                    alt=""
                  />
                </div>
              )}

              {!started && (
                <div onClick={() => setPlaying(true)} className="bigPlayIcon">
                  <img src={PlayIcon} alt="" />
                </div>
              )}

              <div className="videoProgress">
                <div className="videoProgress__progressBar">
                  <div
                    style={{
                      width: `${loaded * 100}%`,
                    }}
                    className="videoProgress__progressBar--loaded"
                  ></div>

                  <div
                    style={{
                      width: `${played * 100}%`,
                    }}
                    className="videoProgress__progressBar--played"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="momentDetails__right">
            <div className="momentDetails__right--user">
              <div
                onClick={() => {
                  setShowMomentDetailsModel(false);
                  history.push(`/channel/${selectedMoment?.User?.username}`);
                }}
                className="momentDetails__right--user--left"
              >
                <div className="avatar">{isBadgeOrAvatar()}</div>
                <div className="username">{selectedMoment?.User?.username}</div>
              </div>

              {JSON.stringify(user) === "{}" ? (
                <div className="followBtn" onClick={() => loginHandler()}>
                  add to streams
                </div>
              ) : (
                user?.id !== selectedMoment?.User?.id && (
                  <div className="momentDetails__right--user--right">
                    {isSubscribe.isSubscribe === [] ||
                      (isSubscribe.isSubscribe && (
                        <div
                          className="followBtnGray"
                          onClick={() =>
                            removeFromStreamsHandler(
                              selectedMoment?.User?.username
                            )
                          }
                        >
                          remove from streams
                        </div>
                      ))}
                    {isSubscribe.isSubscribe === false && (
                      <div
                        className="followBtn"
                        onClick={() =>
                          addToStreamsHandler(selectedMoment?.User?.username)
                        }
                      >
                        add to streams
                      </div>
                    )}
                  </div>
                )
              )}
            </div>

            <p
              className="momentDetails__right--caption"
              dangerouslySetInnerHTML={{
                __html: CheckHyperLinks(selectedMoment?.caption),
              }}
            />

            <div className="momentDetails__right--actionStats">
              {fetchUpvoteInfoLoading === false && (
                <Upvote
                  upvote={upvote}
                  upvoteHandler={upvoteHandler}
                  total={totalUpvotes}
                  showFinalAnimation={showFinalAnimation}
                />
              )}
            </div>

            <div className="momentDetails__right--comments"></div>
          </div>

          {/* show bottom bar */}
          {isMobile && (
            <div className="bottomBar">
              <BottomBar />
            </div>
          )}
        </>
      )}

      {/* show user info */}
      {isMobile && (
        <div className="momentMobile">
          <div className="momentMetaMobile">
            <div className="momentMetaMobile__user">
              <div
                onClick={() => {
                  setShowMomentDetailsModel(false);
                  history.push(`/channel/${selectedMoment?.User?.username}`);
                }}
                className="momentMetaMobile__user--left"
              >
                <div className="avatar">{isBadgeOrAvatar()}</div>
                <div className="username">{selectedMoment?.User?.username}</div>
              </div>

              {JSON.stringify(user) === "{}" ? (
                <div className="followBtn" onClick={() => loginHandler()}>
                  add to streams
                </div>
              ) : (
                user?.id !== selectedMoment?.User?.id && (
                  <div className="momentMetaMobile__user--right">
                    {isSubscribe.isSubscribe === [] ||
                      (isSubscribe.isSubscribe && (
                        <div
                          className="followBtnGray"
                          onClick={() =>
                            removeFromStreamsHandler(
                              selectedMoment?.User?.username
                            )
                          }
                        >
                          remove from streams
                        </div>
                      ))}
                    {isSubscribe.isSubscribe === false && (
                      <div
                        className="followBtn"
                        onClick={() =>
                          addToStreamsHandler(selectedMoment?.User?.username)
                        }
                      >
                        add to streams
                      </div>
                    )}
                  </div>
                )
              )}
            </div>

            <pre className="momentMetaMobile__caption">
              {selectedMoment?.caption}
            </pre>
          </div>

          <div className="momentActionMobile">
            <div className="momentActionMobile__item">
              {fetchUpvoteInfoLoading === false && (
                <Upvote
                  upvote={upvote}
                  upvoteHandler={upvoteHandler}
                  total={totalUpvotes}
                  showFinalAnimation={showFinalAnimation}
                />
              )}
            </div>

            <div className="momentActionMobile__item">
              <MenuOptions options={MOMENTS_OTHER} item={selectedMoment} />
            </div>
          </div>
        </div>
      )}
    </MomentDetailsStyled>
  );
};

export default MomentDetails;

const MomentDetailsStyled = styled.div`
  z-index: 899;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  height: auto;
  width: 100vw;
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;
  overflow: hidden;
  background: #181818;

  .loadingBox {
    padding: 2rem;
    width: 100%;
  }

  .momentDetails {
    &__left {
      width: 60%;
    }
    &__right {
      width: 40%;
    }

    &__left {
      position: relative;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;

      &--nextMoment,
      &--prevMoment {
        user-select: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: #181818;
        z-index: 5;
        height: 3.5rem;
        padding: 0 1rem;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: #fff;
        text-transform: uppercase;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-weight: 300;
        transition: all 0.3s ease;
        letter-spacing: 0.5px;

        img {
          height: 1.5rem;
          width: auto;
        }

        &:hover {
          background: ${(props) => props.theme.gradient};
        }
      }

      &--nextMoment {
        right: 0;
        border-top-left-radius: 10rem;
        border-bottom-left-radius: 10rem;
      }

      &--prevMoment {
        left: 0;
        border-top-right-radius: 10rem;
        border-bottom-right-radius: 10rem;
      }

      &--close {
        position: absolute;
        top: 1rem;
        left: 2rem;
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(255, 255, 255, 0.4);
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
          border: 1px solid transparent;
          background: ${(props) => props.theme.primaryGradient};
        }

        img {
          height: 1.3rem;
          width: auto;
        }
      }

      &--overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        z-index: -1;
      }

      &--video {
        margin: 0 auto;
        height: calc(100vh - 0px - 0px * 3);
        width: calc(calc(100vh - 0px - 0px * 3) * 0.5625);
        min-height: 560px;
        min-width: 320px;
        overflow: hidden;
        position: relative;

        .player {
          z-index: 5;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }

        .bigPlayIcon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          z-index: 5;

          img {
            height: 5rem;
            width: auto;
          }
        }

        .thumbnail {
          z-index: 4;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;

          img {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
          }
        }

        .videoProgress {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.5),
            50%,
            transparent
          );
          z-index: 5;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &__progressBar {
            height: 4px;
            width: 100%;
            background-color: #fff;
            transition: all 0.2s ease;
            position: relative;
            overflow: hidden;

            &--loaded {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              height: 100%;
              width: 0;
              background: rgba(0, 0, 0, 0.4);
            }

            &--played {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              height: 100%;
              width: 0;
              background: ${(props) => props.theme.primaryGradient};
            }
          }
        }

        .controls {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.5rem 1rem 1.5rem 1rem;
          z-index: 5;
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.5),
            60%,
            transparent
          );

          .icon {
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 100;
            border: 1px solid transparent;
            transition: all 0.3s ease;
            cursor: pointer;
            scale: 0;
            transform-origin: center;
            visibility: hidden;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */

            &:hover {
              border: 1px solid rgba(255, 255, 255, 0.1);
            }

            img {
              height: 1.1rem;
              width: auto;
            }
          }
        }
      }

      &:hover .controls {
        &__progressBar {
          opacity: 1;
          transform: translateY(-10%);
        }

        .icon {
          scale: 1;
          visibility: visible;
        }
      }
    }

    &__right {
      background-color: #202020;
      height: 100vh;
      padding: 2rem;

      &--user {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .avatar {
          cursor: pointer;
          height: 4rem;
          width: 4rem;
          border-radius: 50%;
          margin-right: 1rem;
          position: relative;

          .imageAvatar {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
            height: 100%;
            width: 100%;
          }

          .imageBadge {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .badge {
              height: 38px;
              width: auto;
            }
          }
        }

        .username {
          color: rgba(255, 255, 255, 0.8);
          font-weight: 400;
          font-size: 1.2rem;
          cursor: pointer;
        }

        .followBtn,
        .followBtnGray {
          cursor: pointer;
          line-height: 1;
          font-family: brother-1816, sans-serif;
          padding: 0.5rem 1rem;
          text-transform: uppercase;
          font-size: 0.85rem;
          font-weight: 500;
          border-radius: 3px;
        }

        .followBtn {
          background: ${(props) => props.theme.primaryGradient};
        }

        .followBtnGray {
          background-color: #181818;
          border: 1px solid rgba(255, 255, 255, 0.2);
        }

        &--left {
          display: flex;
          align-items: center;
        }
      }

      &--caption {
        font-weight: 300;
        line-height: 1.5;
        color: rgba(255, 255, 255, 0.8);
        padding-bottom: 1.5rem;
      }

      &--actionStats {
        padding: 0 0 1.5rem 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }

      &--comments {
        padding: 1rem 0;
      }
    }
  }

  /* responsive */
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    overflow-y: scroll;

    .momentDetails {
      &__left,
      &__right {
        width: 100%;
      }

      &__left {
        &--nextMoment,
        &--prevMoment {
          height: 2.5rem;
          font-size: 0.85rem;
          padding: 0 0.7rem;
        }

        &--close {
          top: 0;
          left: 0;
          height: 3rem;
          width: 100%;
          background: #181818;
          border-radius: 0;
          border: none;

          .text {
            margin-left: 1rem;
            text-transform: uppercase;
            font-size: 0.8rem;
            font-weight: 500;
          }

          img {
            width: 1rem;
            height: auto;
          }
        }

        &--video {
          height: calc(100vh - 40px - 0px * 3);
          width: calc(calc(100vh - 0px - 0px * 3) * 0.5625);

          margin-top: 3rem;
        }
      }

      &__right {
        padding: 2rem 1rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    overflow-y: hidden;

    .momentDetails {
      background-color: rgba(0, 0, 0, 0.4);

      &__left {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100vh - 3.5rem);
        overflow: hidden;
        z-index: -1;

        &--video {
          z-index: 3;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }

      &__right {
        display: none;
      }

      .bottomBar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }

    .momentMetaMobile {
      width: calc(100% - 5rem);
      padding: 0 1rem;

      &__user {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        .avatar {
          cursor: pointer;
          height: 3.5rem;
          width: 3.5rem;
          border-radius: 50%;
          margin-right: 0.5rem;
          position: relative;

          .imageAvatar {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
            height: 100%;
            width: 100%;
          }

          .imageBadge {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .badge {
              height: 38px;
              width: auto;
            }
          }
        }

        .username {
          color: rgba(255, 255, 255, 0.8);
          font-weight: 500;
          font-size: 1rem;
          cursor: pointer;
        }

        .followBtn,
        .followBtnGray {
          cursor: pointer;
          line-height: 1;
          font-family: brother-1816, sans-serif;
          padding: 0.4rem 0.8rem;
          text-transform: uppercase;
          font-size: 0.72rem;
          font-weight: 500;
          border-radius: 3px;
        }

        .followBtn {
          background: ${(props) => props.theme.primaryGradient};
        }

        .followBtnGray {
          background-color: #181818;
          border: 1px solid rgba(255, 255, 255, 0.2);
        }

        &--left {
          display: flex;
          align-items: center;
        }
      }

      &__caption {
        font-weight: 400;
        line-height: 1.5;
        color: rgba(255, 255, 255, 0.8);
        padding-bottom: 1.5rem;
        font-size: 1.05rem;
        font-family: ${(props) => props.theme.montserrat}, sans-serif !important;
      }
    }

    .momentActionMobile {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 5rem;
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__item {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }

    .momentMobile {
      position: absolute;
      bottom: 3.5rem;
      left: 0;
      width: 100%;
      z-index: 2;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.8), 80%, transparent);
    }
  }

  @media screen and (max-width: 414px) {
    .momentMetaMobile {
      padding: 0 0.5rem;

      &__user {
        .avatar {
          height: 3rem;
          width: 3rem;
          margin-right: 0.2rem;

          .imageBadge {
            .badge {
              height: 2.5rem;
            }
          }
        }

        .username {
          font-size: 0.85rem;
        }

        .followBtn,
        .followBtnGray {
          padding: 0.3rem 0.6rem;
          font-size: 0.65rem;
          border-radius: 1px;
        }
      }

      &__caption {
        font-weight: 400;
        line-height: 1.5;
        color: rgba(255, 255, 255, 0.8);
        padding-bottom: 1.5rem;
        font-size: 1.05rem;
      }
    }
  }
`;
