import React from "react";
import styled, { css } from "styled-components";
import SkeletonElement from "../SkeletonElement";
import { Row, Col } from "react-grid-system";
import { useMediaQuery } from "react-responsive";

const Section = ({ sectionName, fullWidth, noTitle, numItems }) => {
  const isMobile = useMediaQuery({
    query: "max-width: 480px",
  });

  if (sectionName === "featured_videos") {
    return (
      <SectionStyled fullWidth={fullWidth}>
        <SkeletonElement type="title" mb="2rem" />
        <Row>
          {Array.from({ length: 3 }).map((item, index) => (
            <Col
              key={index}
              md={4}
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
            >
              <SkeletonElement type="featuredVideo" />
              <div className="infoBox__center">
                <div className="infoBox__center--top">
                  <SkeletonElement type="avatarMD" mb=".5rem" />
                </div>
                <div className="infoBox__center--bottom">
                  <SkeletonElement type="paragraph" mb=".5rem" />
                  <SkeletonElement type="paragraph" />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </SectionStyled>
    );
  }

  if (sectionName === "featured_series") {
    return (
      <SectionStyled fullWidth={fullWidth}>
        {!noTitle && <SkeletonElement type="title" mb="2rem" />}
        <Row>
          {Array.from({ length: numItems ? numItems : 3 }).map(
            (item, index) => (
              <Col
                key={index}
                md={4}
                style={{
                  paddingLeft: isMobile ? "2px" : "8px",
                  paddingRight: isMobile ? "2px" : "8px",
                }}
              >
                <SkeletonElement type="featuredVideo" />
                <div className="infoBox__full">
                  <div className="infoBox__full--top">
                    <SkeletonElement type="avatarMD" />
                  </div>
                  <div className="infoBox__full--bottom">
                    <SkeletonElement type="paragraph" mb=".5rem" />
                    <SkeletonElement type="paragraph" />
                  </div>
                </div>
              </Col>
            )
          )}
        </Row>
      </SectionStyled>
    );
  }

  if (sectionName === "featured_moments") {
    return (
      <SectionStyled fullWidth={fullWidth}>
        {!noTitle && <SkeletonElement type="title" mb="2rem" />}
        <Row>
          {Array.from({ length: numItems ? numItems : 4 }).map(
            (item, index) => (
              <Col
                key={index}
                md={3}
                sm={4}
                xs={4}
                style={{
                  paddingLeft: isMobile ? "3px" : "8px",
                  paddingRight: isMobile ? "3px" : "8px",
                }}
              >
                <SkeletonElement type="moment" mb="1rem" />
              </Col>
            )
          )}
        </Row>
      </SectionStyled>
    );
  }

  if (sectionName === "videos") {
    return (
      <SectionStyled fullWidth={fullWidth}>
        {!noTitle && <SkeletonElement type="title" mb="2rem" />}

        <Row>
          {Array.from({ length: numItems ? numItems : 8 }).map(
            (item, index) => (
              <Col
                key={index}
                md={3}
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  marginBottom: "1rem",
                }}
              >
                <SkeletonElement type="video" mb="1rem" />
                <div className="infoBox">
                  <div className="infoBox__left">
                    <SkeletonElement type="avatarSM" mr="1rem" />
                  </div>
                  <div className="infoBox__right">
                    <SkeletonElement type="paragraph" mb=".5rem" />
                    <SkeletonElement type="paragraph" />
                  </div>
                </div>
              </Col>
            )
          )}
        </Row>
      </SectionStyled>
    );
  }

  if (sectionName === "element_videos") {
    return (
      <SectionStyled fullWidth={fullWidth}>
        <SkeletonElement type="title" mb="2rem" />
        <Row>
          {Array.from({ length: 4 }).map((item, index) => (
            <Col
              key={index}
              md={3}
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
                marginBottom: "1rem",
              }}
            >
              <SkeletonElement type="video" mb="1rem" />
              <div className="infoBox">
                <div className="infoBox__left">
                  <SkeletonElement type="avatarSM" mr="1rem" />
                </div>
                <div className="infoBox__right">
                  <SkeletonElement type="paragraph" mb=".5rem" />
                  <SkeletonElement type="paragraph" />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </SectionStyled>
    );
  }

  if (sectionName === "comments") {
    return (
      <SectionStyled fullWidth={fullWidth}>
        {!noTitle && <SkeletonElement type="title" mb="2rem" />}

        <div className="comments">
          {Array.from({ length: numItems ? numItems : 5 }).map(
            (item, index) => (
              <div key={index} className="comments__item">
                <div className="left">
                  <SkeletonElement type="avatarSM" varient="dark" />
                </div>

                <div className="right">
                  <SkeletonElement type="paragraph" mb=".5rem" varient="dark" />
                  <SkeletonElement type="paragraph" mb=".5rem" varient="dark" />

                  <div className="actions">
                    <SkeletonElement
                      type="avatarXS"
                      mr=".6rem"
                      varient="dark"
                    />
                    <SkeletonElement
                      type="avatarXS"
                      mr=".6rem"
                      varient="dark"
                    />
                    <SkeletonElement type="avatarXS" varient="dark" />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </SectionStyled>
    );
  }

  if (sectionName === "users") {
    return (
      <SectionStyled>
        <div className="users">
          {Array.from({ length: numItems ? numItems : 4 }).map(
            (item, index) => (
              <div key={index} className="users__item">
                <SkeletonElement type="avatarXXL" mb="1rem" />
                <SkeletonElement
                  className="users__item--title"
                  type="paragraph"
                />
              </div>
            )
          )}
        </div>
      </SectionStyled>
    );
  }

  return null;
};

export default Section;

const SectionStyled = styled.div`
  width: 90%;
  margin: 2rem auto;

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
      margin: 0 !important;
    `}

  .infoBox {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    width: 100%;

    &__right {
      width: calc(100% - 3.5rem);
    }

    &__center {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 80%;
      margin: 0 auto;
      transform: translateY(-2rem);

      &--bottom {
        width: 100%;
      }
    }

    &__full {
      display: flex;
      align-items: center;
      width: 90%;
      margin: 0 auto;
      padding: 1rem 0;

      &--top {
        margin-right: 1rem;
      }

      &--bottom {
        width: calc(100% - 4rem);
      }
    }
  }

  .comments {
    &__item {
      padding: 1rem;
      border-radius: 5px;
      background-color: #202020;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      .right {
        width: calc(100% - 4rem);

        .actions {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .users {
    display: flex;
    align-items: center;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;

      &--title {
        width: 5rem;
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }
`;
