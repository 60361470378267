import {
  GET_MARKETING_BANNERS,
  CLEAR_MARKETING_BANNERS,
  ADD_NEW_CUSTOM_BANNER,
  REMOVE_CUSTOM_BANNER,
} from "../actions/types";

const initialState = { banners: {}, userCustomBanners: {} };

const marketingBanner = (state = initialState, action) => {
  switch (action.type) {
    case GET_MARKETING_BANNERS:
      return {
        ...state,
        banners: action.payload.banners,
        userCustomBanners: action.payload.userCustomBanners,
      };
    case ADD_NEW_CUSTOM_BANNER:
      return {
        ...state,
        userCustomBanners: [
          ...state.userCustomBanners,
          action.payload.newCustomBanner,
        ],
      };
    case REMOVE_CUSTOM_BANNER:
      const filtered = state.userCustomBanners.filter(
        (banner) => banner.id !== action.payload.bannerId
      );
      return { ...state, userCustomBanners: filtered };
    case CLEAR_MARKETING_BANNERS:
      return {};
    default:
      return state;
  }
};

export default marketingBanner;
