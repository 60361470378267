import {
  GET_VIDEO,
  CLEAR_VIDEO,
  ADD_COMMENT,
  SUBSCRIBE_FROM_VIDEO,
  UNSUBSCRIBE_FROM_VIDEO,
  LIKE,
  DISLIKE,
  CANCEL_LIKE,
  CANCEL_DISLIKE,
  UPDATE_VIDEO,
  DELETE_VIDEO,
  GET_VIDEO_CATEGORIES,
  GET_HASHTAGS,
  UNLOCK_WATCH_VIDEO,
  UNLOCK_TAT_VIDEO,
  VIDEO_UPLOAD_REQUEST,
  VIDEO_UPLOAD_FAIL,
  VIDEO_UPLOAD_SUCCESS,
  VIDEO_UPLOAD_RESET,
  VIDEO_UPDATE_REQUEST,
  VIDEO_UPDATE_SUCCESS,
  VIDEO_UPDATE_FAIL,
  VIDEO_UPDATE_RESET,
  VIDEO_DELETE_REQUEST,
  VIDEO_DELETE_SUCCESS,
  VIDEO_DELETE_FAIL,
  VIDEO_DELETE_RESET,
  UNLOCK_ADDITIONAL_CONTENT,
} from "../actions/types";

const initialState = {
  isFetching: true,
};

const video = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEO:
      return action.payload;
    case CLEAR_VIDEO:
      return { isFetching: true };
    case UPDATE_VIDEO:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_VIDEO:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_COMMENT:
      if (state.isFetching) {
        return { ...state };
      }
      return {
        ...state,
        comments: [action.payload, ...(state.comments ? state.comments : [])],
      };
    case SUBSCRIBE_FROM_VIDEO:
      return {
        ...state,
        isSubscribed: !state.isSubscribed,
      };
    case UNSUBSCRIBE_FROM_VIDEO:
      return {
        ...state,
        isSubscribed: !state.isSubscribed,
      };
    case LIKE:
      return {
        ...state,
        isLiked: !state.isLiked,
        likesCount: state.likesCount + 1,
      };
    case DISLIKE:
      return {
        ...state,
        isDisliked: !state.isDisliked,
        dislikesCount: state.dislikesCount + 1,
      };
    case CANCEL_LIKE:
      return {
        ...state,
        isLiked: !state.isLiked,
        likesCount: state.likesCount - 1,
      };
    case CANCEL_DISLIKE:
      return {
        ...state,
        isDisliked: !state.isDisliked,
        dislikesCount: state.dislikesCount - 1,
      };
    case GET_VIDEO_CATEGORIES:
      return {
        ...state,
        ...action.payload,
      };
    case GET_HASHTAGS:
      return {
        ...state,
        ...action.payload,
      };
    case UNLOCK_WATCH_VIDEO:
      return {
        ...state,
        isVideoLocked:
          state.id === action.payload.videoId
            ? false
            : typeof state.isVideoLocked !== "boolean" || false,
      };
    case UNLOCK_TAT_VIDEO:
      return {
        ...state,
        isTATVideoLocked:
          state.id === action.payload.videoId
            ? false
            : typeof state.isTATVideoLocked !== "boolean" || false,
      };
    case VIDEO_UPLOAD_REQUEST:
      return {
        ...state,
        videoUploadLoading: true,
      };
    case VIDEO_UPLOAD_SUCCESS:
      return {
        ...state,
        videoUploadLoading: false,
        videoUploadInfo: action.payload,
      };
    case VIDEO_UPLOAD_FAIL:
      return {
        ...state,
        videoUploadLoading: false,
        videoUploadError: action.payload,
      };
    case VIDEO_UPLOAD_RESET:
      return {
        ...state,
        videoUploadLoading: false,
        videoUploadError: null,
        videoUploadInfo: null,
      };
    case VIDEO_UPDATE_REQUEST:
      return {
        ...state,
        videoUpdateLoading: true,
      };
    case VIDEO_UPDATE_SUCCESS:
      return {
        ...state,
        videoUpdateLoading: false,
        videoUpdateInfo: action.payload,
      };
    case VIDEO_UPDATE_FAIL:
      return {
        ...state,
        videoUpdateLoading: false,
        videoUpdateError: action.payload,
      };
    case VIDEO_UPDATE_RESET:
      return {
        ...state,
        videoUpdateLoading: false,
        videoUpdateInfo: null,
        videoUpdateError: null,
      };
    case VIDEO_DELETE_REQUEST:
      return {
        ...state,
        videoDeleteLoading: true,
      };
    case VIDEO_DELETE_SUCCESS:
      return {
        ...state,
        videoDeleteLoading: false,
        videoDeleteInfo: action.payload,
      };
    case VIDEO_DELETE_FAIL:
      return {
        ...state,
        videoDeleteLoading: false,
        videoDeleteError: action.payload,
      };
    case VIDEO_DELETE_RESET:
      return {
        ...state,
        videoDeleteLoading: false,
        videoDeleteInfo: null,
        videoDeleteError: null,
      };
    case UNLOCK_ADDITIONAL_CONTENT:
      return {
        ...state,
        isAdditionalContent: action.payload.data.isVideoAdditionalContent === [] ? [] : action.payload.data.isVideoAdditionalContent.userId === state.User.id ? action.payload.data.isVideoAdditionalContent : []
      };
    default:
      return state;
  }
};

export default video;
