import React from "react";
import AdminSidebar from "../AdminSidebar";
import styled from "styled-components";
import MembershipForm from "./MembershipForm";

const ManageMembership = () => {
  return (
    <ManageMembershipStyled>
      <AdminSidebar />
      <div className="content">
        <MembershipForm />
      </div>
    </ManageMembershipStyled>
  );
};

export default ManageMembership;

const ManageMembershipStyled = styled.div`
  display: flex;
  overflow-x: hidden;
  background: #212123;
  height: auto;
  z-index: 1000;

  .content {
    padding: 3rem;
    width: 60%;
    min-width: 480px;
    margin: 0 auto;
    margin-bottom: 3.5rem;
  }
`;
