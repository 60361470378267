import React from "react";
import styled, { keyframes } from "styled-components";
import TickIcon from "../../../assets/Icons/tick.svg";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { addDefaultImage } from "../../../utils";
import ImageNotfoundPlaceholder from "../../../assets/image_notfound_placeholder.svg";
import { toast } from "react-toastify";
import { notificationMessage } from "../../../utils/toastNotifications";
import { useHistory } from "react-router-dom";
import config from "../../../config";

const SeriesCardSelect = ({ selectSeriesHandler, item, isSelected }) => {
  const history = useHistory();
  const { title, thumbnail, price, videos } = item;
  const { profile } = useSelector((state) => state);

  return (
    <SeriesCardSelectStyled onClick={() => selectSeriesHandler(item)}>
      <div className="thumbnail">
        <img
          onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
          src={config.aws.CLOUDFRONT_URL + thumbnail}
          alt={title}
        />
        <div className="numVideos">
          <span>{videos?.length} Videos</span>
        </div>

        <div className="price">
          {price > 0 ? (
            <span>${parseFloat(price).toFixed(2)}</span>
          ) : (
            <span>free</span>
          )}
        </div>
      </div>

      <div className="metaInfo">
        <div className="metaInfo__left">
          <div className={`metaInfo__left--videoMeta `}>
            <div
              onClick={() => {
                if (item.videos.length > 0) {
                  history.push(
                    `/series/watch/${item.id}/${item.videos[0].videoId}`
                  );
                } else {
                  toast.error(notificationMessage.seriesAddVideos);
                }
              }}
              className="title"
            >
              {item?.title}
            </div>

            <div className="seperatorInfo">
              <div
                onClick={() => {
                  history.push(
                    `/channel/${
                      profile?.username ? profile.username : item?.User.username
                    }`
                  );
                }}
                className="bold"
              >
                {profile?.username ? profile.username : item?.User.username}
              </div>

              <div className="seperator"></div>

              <div className="light">
                <Moment format="MMMM Do YYYY">{item?.createdAt}</Moment>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        onClick={() => selectSeriesHandler(item)}
        className={`selectVideo ${isSelected && "selectVideo__selected"}`}
      >
        {isSelected && <img src={TickIcon} alt="Tick Icon" />}
      </div>
    </SeriesCardSelectStyled>
  );
};

export default SeriesCardSelect;

const TickIconAnimation = keyframes`
  from {
    transform: translateY(7px) scale(0.7);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const SeriesCardSelectStyled = styled.div`
  margin-bottom: 2rem;
  position: relative;
  cursor: pointer;

  .selectVideo {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 4px solid #f9903d;
    background-color: #000;
    cursor: pointer;
    transition: all 0.25s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    &__selected {
      background-color: #f9903d !important;
    }

    img {
      width: 1.6rem;
      height: auto;
      animation: ${TickIconAnimation} 0.2s ease;
      transition: all 0.2s ease;
    }
  }

  .thumbnail {
    width: 100%;
    height: 12rem;
    overflow: hidden;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    position: relative;

    .price,
    .numVideos {
      font-family: brother-1816, sans-serif;
      position: absolute;
      bottom: 0.5rem;
      font-size: 0.7rem;
      padding: 0.4rem 0.5rem;
      color: #fff;
      z-index: 2;
      background-color: rgba(28, 28, 30, 1);
      border-radius: 0.3rem;
      cursor: pointer;
      text-transform: capitalize;
      line-height: 1;
    }

    .price {
      left: 0.5rem;
    }

    .numVideos {
      right: 0.5rem;
    }

    img {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
    }
  }

  .metaInfo {
    padding: 0 0.25rem;
    font-family: ${(props) => props.theme.montserrat};
    position: relative;

    &__left {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      &--videoMeta {
        cursor: pointer;
        width: 100%;

        .title {
          font-size: 0.9rem;
          font-weight: 400;
          line-height: 1.3;
          margin-bottom: 0.2rem;
          overflow-wrap: break-word;
        }

        .seperatorInfo {
          font-size: 0.7rem;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .seperator {
            height: 4px;
            width: 4px;
            border-radius: 50%;
            margin: 0 0.35rem;
            background-color: #fff;
          }

          .bold {
            font-weight: 600;
            text-decoration: underline;
            background: -webkit-${(props) => props.theme.primaryGradient};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            letter-spacing: 0.02rem;
            cursor: pointer;
            text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.3);
            position: relative;

            &:before {
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              height: 2px;
              background: ${(props) => props.theme.primaryGradient};
              width: 0%;
              transition: all 0.4s ease;
            }

            &:hover:before {
              width: 100%;
            }
          }

          .light {
            color: rgba(174, 174, 178, 1);
            font-weight: 400;
            font-size: 0.6rem;
          }

          .boldV2 {
            color: rgba(174, 174, 178, 1);
            font-weight: 500;
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .selectVideo {
      height: 1.8rem;
      width: 1.8rem;
      border: 3px solid #f9903d;

      img {
        width: 1.5rem;
      }
    }

    .thumbnail {
      margin-bottom: 0.5rem;
      height: 9rem;

      .numVideos,
      .price {
        font-size: 0.6rem;
      }
    }

    .metaInfo {
      &__left {
        &--videoMeta {
          width: 100%;

          .title {
            font-size: 0.8rem;
            margin-bottom: 0.2rem;
          }

          .seperatorInfo {
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 390px) {
    .metaInfo {
      &__left {
        &--videoMeta {
          .seperatorInfo {
            font-size: 0.55rem;
          }
        }
      }
    }
  }
`;
