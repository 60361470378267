import React, { useEffect } from "react";
import { useState } from "react";
import styled, { css } from "styled-components";

const InputV2 = ({
  label,
  name,
  className,
  type,
  onChange,
  placeholder,
  value,
  id,
  limit,
  formikError,
  options,
  size,
  inputRef,
  isDisabled = false,
}) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (value?.length >= limit) {
      setError("You character limit is reached!");
    } else {
      setError(null);
    }
  }, [value]);

  return (
    <InputV2Styled size={size} className={className}>
      <label htmlFor={id}>{label}</label>

      {type === "textarea" ? (
        <textarea
          ref={inputRef}
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={isDisabled}
        ></textarea>
      ) : type === "select" ? (
        <>
          <select
            ref={inputRef}
            name={name}
            id={id}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
          >
            <option value="" disabled selected hidden>
              {placeholder}
            </option>
            {options?.map((item, i) => (
              <option key={i} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
        </>
      ) : (
        <input
          ref={inputRef}
          className={`${formikError && "inputError"} ${
            isDisabled && "disabled"
          }`}
          name={name}
          id={id}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={isDisabled}
        />
      )}
      <div className="statBar">
        {error && <div className="statBar__msg statBar__error">{error}</div>}

        {limit && (
          <div className="statBar__limit">
            {value.length} / {limit}
          </div>
        )}
      </div>

      {formikError && <div className="error">{formikError}</div>}
    </InputV2Styled>
  );
};

export default InputV2;

const InputV2Styled = styled.div`
  input,
  textarea,
  select {
    width: 100%;
    padding: 0.7rem 1rem;
    background-color: #3a3a3c;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    font-size: 1rem;
    font-weight: 400;
    color: rgba();
    font-family: ${(props) => props.theme.montserrat};
    transition: all 0.25s ease;
    border: 3px solid transparent;
    color: #fff;

    &::placeholder {
      font-weight: 400;
      color: rgba(255, 255, 255, 0.4);
      letter-spacing: 0.3px;
    }
    :disabled: {
      color: gray;
    }

    &:focus {
      border: 3px solid ${(props) => props.theme.primaryColor1};
    }

    &:hover {
      border: 3px solid ${(props) => props.theme.primaryColor1};
    }
  }
  input:disabled {
    color: ${(props) => props.theme.grey};
    cursor: not-allowed;
    border: none;
  }

  .inputError {
    border: 3px solid ${(props) => props.theme.red};
  }

  textarea {
    resize: none;
    height: 15rem;
    line-height: 1.6;
  }

  .statBar {
    line-height: 1;
    padding: 0 0.25rem;
    position: relative;
    width: 100%;

    &__msg {
      width: 90%;
      font-size: 0.77rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      line-height: 1;
    }

    &__error {
      color: ${(props) => props.theme.red};
    }

    &__limit {
      position: absolute;
      top: 0;
      right: 0;
      background: ${(props) => props.theme.primaryGradient};
      padding: 0.3rem 0.5rem;
      display: inline-block;
      border-radius: 2px;
      font-size: 0.7rem;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .error {
    color: ${(props) => props.theme.red};
    font-size: 0.7rem;
    text-transform: uppercase;
    font-family: brother-1816, sans-serif;
    padding-top: 0.25rem;
  }

  ${(props) =>
    props.size &&
    props.size === "small" &&
    css`
      input,
      textarea,
      select {
        padding: 0.5rem 0.85rem;
        border-radius: 0.25rem;
        font-size: 0.8rem;

        &:focus {
          border: 2px solid ${(props) => props.theme.primaryColor1};
        }

        &:hover {
          border: 2px solid ${(props) => props.theme.primaryColor1};
        }
      }
    `}

  @media screen and (max-width: 480px) {
    input,
    textarea {
      padding: 0.5rem 0.8rem;
      border-radius: 0.4rem;
      font-size: 0.85rem;
    }

    textarea {
      height: 10rem;
    }

    .statBar {
      &__limit {
        font-size: 0.6rem;
      }
    }
  }
`;
