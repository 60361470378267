import React from "react";
import styled from "styled-components";
import avatar_placeholder from "../../assets/avtar_placeholder.png";
import MenuOptions from "../Cards/MenuOptions";
import { Link } from "react-router-dom";
import { VIDEO_OTHER } from "../../utils/menuOptions";
import { useHistory } from "react-router-dom";
import { addDefaultImage, toHHMMSS } from "../../utils";
import config from "../../config";
import ImageNotFoundPlaceholder from "../../assets/image_notfound_placeholder.svg";
import RewardIcon from "../icons/reward.svg";

const FeaturedVideoCard = ({ item, midDrag }) => {
  const history = useHistory();
  const badge = item.userSettings?.VisitorBadge.imgPath;

  const isPngOrJpg = () => {
    const image = !item?.User?.avatar ? badge : item.User.avatar;

    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <img
          className={`${image.includes(".png") ? "imageAvatar" : "imageBadge"}`}
          src={image}
          alt="badge"
        />
      );
    } else {
      return (
        <img
          onError={(e) => addDefaultImage(e, ImageNotFoundPlaceholder)}
          className="imageCustom"
          src={!!image ? config.aws.CLOUDFRONT_URL + image : avatar_placeholder}
          alt="badge"
          style={{
            outline: "5px solid #202020",
          }}
        />
      );
    }
  };

  const handleVideoDetails = (e) => {
    if (midDrag) {
      e.stopPropagation();
      return;
    }

    history.push(`/watch/${item.id}`);
    return;
  };

  return (
    <>
      <FeaturedVideoCardStyled onClick={handleVideoDetails}>
        <img
          onError={(e) => addDefaultImage(e, ImageNotFoundPlaceholder)}
          src={config.aws.CLOUDFRONT_URL + item.thumbnail}
          alt={item.title}
          className="thumbnail"
        />

        {item?.views && item.views > 0 ? (
          <div className="views">{item.views} Views</div>
        ) : null}
        <div className="videoLength">{toHHMMSS(item?.videoLength)}</div>
        <div className="ribbon">
          <p>
            {item?.VideoAccessOverlay?.name}
            <span>
              {+item?.amount > 0 ? ` ($${(+item?.amount).toFixed(2)}) ` : ""}
            </span>
          </p>
        </div>
      </FeaturedVideoCardStyled>
      <MetaInfoStyled>
        <div className="option">
          <MenuOptions item={item} options={VIDEO_OTHER} />
        </div>
        <Link to={`/channel/${item?.User?.username}`}>
          <div className="avatar">{isPngOrJpg()}</div>
        </Link>

        {item?.HasRewards ? (
          <div className="rewards">
            <img src={RewardIcon} alt="" className="rewards__icon" />
            <span className="rewards__text">Contains Rewards</span>
          </div>
        ) : null}

        <h4 className="video-title">{item.title}</h4>
        <Link to={`/channel/${item?.User?.username}`}>
          <p className="user">{item?.User?.username}</p>
        </Link>
      </MetaInfoStyled>
    </>
  );
};

export default FeaturedVideoCard;

const MetaInfoStyled = styled.div`
  position: relative;
  margin-top: -2.5rem;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;
  margin-left: 17px;

  display: flex;
  flex-direction: column;
  align-items: center;

  /* reward badge */
  .rewards {
    font-family: brother-1816, sans-serif;
    padding: 0.25rem 0.4rem;
    color: #fff;
    z-index: 2;
    background-image: ${(props) => props.theme.gradientV2};
    text-transform: uppercase;
    border-radius: 0.125rem;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0.25rem;

    &__icon {
      height: 0.55rem;
      margin-right: 0.35rem;
      transition: all 0.2s ease;
    }

    &__text {
      font-size: 0.475rem !important;
      font-weight: 600;
      transition: all 0.2s ease;
    }
  }

  .option {
    position: absolute;
    top: 2.2rem;
    right: 0;
    cursor: pointer;
  }

  .user {
    font-size: 0.75rem;
    font-weight: 600;
    text-decoration: underline;
    font-weight: 500;
    font-family: "brother-1816", sans-serif;
    background: -webkit-${(props) => props.theme.primaryGradient} !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    letter-spacing: 0.02rem;
    cursor: pointer;
    text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.3);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      background: ${(props) => props.theme.primaryGradient};
      width: 0%;
      transition: all 0.4s ease;
    }

    &:hover:before {
      width: 100%;
    }
  }

  /* avatar */
  .avatar {
    cursor: pointer;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    position: relative;
    margin-bottom: 1rem;

    .imageAvatar,
    .imageCustom {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .imageBadge {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .badge {
        height: 38px;
        width: auto;
      }
    }
  }

  .video-title {
    font-size: 1.05rem;
    text-align: center;
    color: white;
  }

  .video-category {
    margin-top: 0.25rem;
  }
`;

const FeaturedVideoCardStyled = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
  position: relative;
  margin: 0 auto;
  height: 20rem;
  min-height: 35vh;

  .views,
  .videoLength {
    font-family: brother-1816, sans-serif;
    position: absolute;
    bottom: 1rem;
    font-size: 0.7rem;
    padding: 0.4rem 0.5rem;
    color: #fff;
    z-index: 2;
    background-color: rgba(28, 28, 30, 1);
    border-radius: 0.3rem;
    cursor: pointer;
    text-transform: capitalize;
    line-height: 1;
  }

  .views {
    left: 1rem;
  }

  .videoLength {
    right: 1rem;
  }

  .ribbon {
    font-family: "Poppins", sans-serif;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 3px 15px 3px 30px;
    z-index: 98;
    background: ${(props) => props.theme.primaryGradient};
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.3);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 12% 50%);
    transition: all 0.3s ease;
    border-top-right-radius: 5px;

    p {
      font-size: 0.7rem;
      text-transform: capitalize;
      font-weight: 300;
      letter-spacing: 0.1px;
      margin: 0;
      padding: 0;
      transition: all 0.2s ease;

      span {
        font-weight: bold;
      }
    }

    &:hover {
      cursor: pointer;
      border: none !important;
      background: ${(props) => props.theme.primaryGradient} !important;

      p {
        transform: scale(1.1);
      }
    }
  }

  &:hover {
    .ribbon {
      border: none !important;
      background: linear-gradient(
        29deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      );

      p {
        color: #fff;
      }
    }
  }

  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    z-index: -1;
    padding: 10px;
    outline: none;
  }

  @media screen and (max-width: 530px) {
    height: 30vh;
  }
  @media screen and (max-width: 768px) {
  }
`;
