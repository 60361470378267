import React from "react";
import styled, { keyframes } from "styled-components";

const Loader = ({ text = "Loading..." }) => {
  return (
    <LoaderStyled>
      <div className="loader"></div>
      <div className="text">{text}</div>
    </LoaderStyled>
  );
};

export default Loader;

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
  `;

const LoaderStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  .text {
    font-size: 0.9rem;
  }

  .loader {
    width: 1.8rem;
    height: 1.8rem;
    border: 2px solid #fff;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    animation: ${rotation} 1s linear infinite;
    margin-right: 1rem;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 2.3rem;
      height: 2.3rem;
      border-radius: 50%;
      border: 2px solid;
      border-color: rgb(249, 154, 45) transparent;
    }
  }
`;
