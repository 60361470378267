import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Error from "../components/Elements/Error";
import ThreadCardSkeleton from "../skeletons/General/ThreadCard";
import ThreadCard from "../components/Cards/ThreadCard";
import Title from "../components/Elements/Title";
import CommentCard from "../components/Cards/CommentCard";
import Helmet from "react-helmet";
import config from "../config";
import Button from "../components/Elements/Button";
import GoBackIcon from "../components/icons/goback.svg";
import CommentsSkeleton from "../skeletons/General/Section";
import {
  getThread,
  getThreadGuest,
  addComment,
  getComments,
  replyComment,
  updateComment,
} from "../actions/thread";
import { RESET_COMMENT_STATE } from "../actions/thread/type";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DynamicMetaTags from "./DynamicMetaTags";

const ViewThread = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const {
    threadLoading,
    thread,
    threadError,
    updateMessage: threadUpdateMessage,
    addCommentLoading,
    addCommentData,
    addCommentError,
    getCommentsLoading,
    getCommentsError,
    replyCommentLoading,
    replyCommentData,
    replyCommentError,
    deleteCommentError,
    deleteCommentMessage,
    deleteReplyCommentMessage,
    deleteReplyCommentError,
    updateCommentMessage,
    updateCommentError,
    replyUpdateCommentMessage,
    replyUpdateCommentError,
    comments,
  } = useSelector((state) => state.thread);
  const user = useSelector((state) => state.user);
  const queryParams = new URLSearchParams(window.location.search);

  // states
  const [shareImage, setShareImage] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);

  useEffect(() => {
    // if (addCommentData) {
    //   toast.success("Comment added.");
    // }

    if (addCommentError) {
      toast.error(
        addCommentError || "Server error occured while adding comment."
      );
    }

    // if (replyCommentData) {
    //   toast.success("Replied to a comment");
    // }

    if (replyCommentError) {
      toast.error(
        replyCommentError || "Server error occured while adding comment."
      );
    }

    // if (deleteCommentMessage) {
    //   toast.success(deleteCommentMessage || "Comment deleted!");
    // }

    if (deleteCommentError) {
      toast.error(
        deleteCommentError || "Server error occuered while deleting comment"
      );
    }

    // if (deleteReplyCommentMessage) {
    //   toast.success(deleteReplyCommentMessage || "Comment replied deleted!");
    // }

    if (deleteReplyCommentError) {
      toast.error(
        deleteReplyCommentError ||
          "Server error occured while deleting your reply!"
      );
    }

    // if (updateCommentMessage) {
    //   toast.success(updateCommentMessage || "Comment updated!");
    // }

    if (updateCommentError) {
      toast.error(
        updateCommentError ||
          "Server error occured while updating your comment!"
      );
    }

    // if (replyUpdateCommentMessage) {
    //   toast.success(replyUpdateCommentMessage || "Reply comment updated!");
    // }

    if (replyUpdateCommentError) {
      toast.error(
        replyUpdateCommentError ||
          "Server error occured while updating your reply!"
      );
    }

    if (
      addCommentData ||
      addCommentError ||
      replyCommentData ||
      replyCommentError ||
      deleteCommentMessage ||
      deleteCommentError ||
      deleteReplyCommentMessage ||
      deleteReplyCommentError ||
      updateCommentMessage ||
      updateCommentError ||
      replyUpdateCommentMessage ||
      replyUpdateCommentError
    ) {
      dispatch({
        type: RESET_COMMENT_STATE,
      });
    }
  }, [
    addCommentData,
    addCommentError,
    replyCommentData,
    replyCommentError,
    deleteCommentMessage,
    deleteCommentError,
    deleteReplyCommentMessage,
    deleteReplyCommentError,
    updateCommentMessage,
    updateCommentError,
    replyUpdateCommentMessage,
    replyUpdateCommentError,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(getComments(params?.threadId));
  }, []);

  useEffect(() => {
    if (!threadLoading && thread?.id) {
      const scrollToComments = queryParams.get("scrollTo");
      if (scrollToComments) {
        const commentId = document.getElementById("comments");
        if (commentId) {
          commentId.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [thread, params?.threadId]);

  useEffect(() => {
    if (!user?.id) {
      dispatch(getThreadGuest(params.threadId));
    } else {
      dispatch(getThread(params.threadId));
    }
  }, [user, threadUpdateMessage]);

  useEffect(() => {
    if (thread?.id) {
      switch (thread.attachedType) {
        case "series":
          setShareImage(thread.attachedItem.series.thumbnail);
          return;
        case "moment":
          setShareImage(thread.attachedItem.moment.coverImgUrl);
          return;
        case "video":
          setShareImage(thread.attachedItem.video.thumbnail);
          return;
        case "image":
          setShareImage(
            config.aws.CLOUDFRONT_URL + thread?.attachedItem[0]?.url
          );
          return;
        default:
          setShareImage(
            "https://www.cheatsheet.com/wp-content/uploads/2020/11/Marvel-star-Robert-Downey-Jr.jpg"
          );
          return;
      }
    }
  }, [thread]);

  // comment actions
  const addCommentHandler = (data) => {
    dispatch(addComment(thread?.id, data));
  };

  const replyCommentHandler = (data) => {
    dispatch(replyComment(thread?.id, data));
  };

  const updateCommentHandler = (commentId, data) => {
    dispatch(updateComment(thread?.id, commentId, data));
  };

  return (
    <ViewThreadStyled
      initial={{
        y: "100vh",
        scale: 0,
        opacity: 0,
      }}
      animate={{
        y: 0,
        scale: 1,
        opacity: 1,
      }}
      transition={{ type: "spring", duration: 1 }}
    >
      <DynamicMetaTags
        title={
          thread?.threadBody?.length < 150
            ? thread.threadBody
            : thread?.threadBody?.substring(0, 150)
        }
        description={`${thread?.threadBody}`}
        imageUrl={`${shareImage}`}
      />
      <Helmet>
        <title>
          {thread?.threadBody?.length < 150
            ? thread.threadBody
            : thread?.threadBody?.substring(0, 150)}
        </title>
        <meta
          name="title"
          content={
            thread?.threadBody?.length < 150
              ? thread.threadBody
              : thread?.threadBody?.substring(0, 150)
          }
        />
        <meta name="description" content={thread?.threadBody} />

        {/* for facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${config.frontend.baseUrl}/threads/${thread?.id}`}
        />
        <meta
          property="og:title"
          content={
            thread?.threadBody?.length < 150
              ? thread.threadBody
              : thread?.threadBody?.substring(0, 150)
          }
        />
        <meta property="og:description" content={thread?.threadBody} />
        <meta property="og:image" content={shareImage} />

        {/* for twitter */}
        <meta name="twitter:card" content="summary" fullWidth="100px" />
        <meta property="twitter:card" content={shareImage} />
        <meta
          property="twitter:url"
          content={`${config.frontend.baseUrl}/threads/${thread?.id}`}
        />
        <meta
          property="twitter:title"
          content={
            thread?.threadBody?.length < 150
              ? thread.threadBody
              : thread?.threadBody?.substring(0, 150)
          }
        />
        <meta property="twitter:description" content={thread?.threadBody} />
        <meta property="twitter:image" content={shareImage} />
      </Helmet>

      <Button
        onClick={() => {
          history.goBack();
        }}
        className="goBackBtn"
        iconLeft
        mode="secondary"
        size="medium"
      >
        <img src={GoBackIcon} alt="" /> Go Back
      </Button>

      {threadLoading && !threadError && <ThreadCardSkeleton />}
      {!threadLoading && threadError && <Error text="No thread found" />}

      {thread && !threadLoading && (
        <ThreadCard type={thread.attachedType} item={thread} />
      )}

      <div className="commentsSection">
        <Title className="commentsSection__title">
          Comments (
          {comments?.comments?.length ? comments?.comments?.length : "0"})
        </Title>

        <div id="comments" className="commentsSection__comments">
          <CommentCard
            addCommentHandler={addCommentHandler}
            addLoading={addCommentLoading}
            updateCommentHandler={updateCommentHandler}
            addComment
            selectedComment={selectedComment}
            setSelectedComment={setSelectedComment}
          />

          {getCommentsLoading && (
            <CommentsSkeleton
              noTitle
              sectionName="comments"
              numItems={4}
              fullWidth
            />
          )}

          {!getCommentsLoading &&
            comments?.comments &&
            comments?.comments.length > 0 &&
            comments?.comments.map((item, i) => (
              <div key={i} className="commentsSection__comments--item">
                <CommentCard
                  replyCommentHandler={replyCommentHandler}
                  item={item}
                  replyLoading={replyCommentLoading}
                  setSelectedComment={setSelectedComment}
                />
              </div>
            ))}

          {!getCommentsLoading &&
            (getCommentsError || !comments?.comments?.length) && (
              <Error text="No comments found!" />
            )}
        </div>
      </div>
    </ViewThreadStyled>
  );
};

export default ViewThread;

const ViewThreadStyled = styled(motion.div)`
  width: 50%;
  margin: 0 auto;
  padding: 3rem 0 6rem 0;

  .goBackBtn {
    margin-bottom: 1.2rem;
    padding: 0.5rem 1.2rem;
  }

  .commentsSection {
    margin-top: 1.5rem;

    &__title {
      font-size: 1.1rem;
      font-weight: 500;
    }

    &__comments {
      &--item {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    width: 95%;
    padding: 2rem 0 3rem 0;

    .goBackBtn {
      margin-bottom: 1rem;
      font-size: 0.8rem;

      img {
        height: 0.9rem;
        width: auto;
      }
    }
  }
`;
