import {
  GET_HOME_FEATURED_CREATORS_REQUEST,
  GET_HOME_FEATURED_CREATORS_SUCCESS,
  GET_HOME_FEATURED_CREATORS_FAIL,
} from "../actions/types";

const featuredCreators = (state = {}, action) => {
  switch (action.type) {
    case GET_HOME_FEATURED_CREATORS_REQUEST:
      return {
        loading: true,
      };
    case GET_HOME_FEATURED_CREATORS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_HOME_FEATURED_CREATORS_FAIL:
      return {
        loading: false,
        data: null,
      };
    default:
      return state;
  }
};

export default featuredCreators;
