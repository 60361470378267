import React from "react";
import config from "../../config";
import styled from "styled-components";
import { truncateString } from "../../utils";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const FeaturedCreatorCard = ({ item, setShowIntro, setIntroUrl }) => {
  const history = useHistory();

  const isMobile480 = useMediaQuery({
    query: "(max-width: 480px)",
  });

  return (
    <>
      <FeaturedCreatorCardStyled
        className={`${isMobile480 && "FeaturedCreatorCard__active"}`}
      >
        <img
          src={
            item?.introVideo?.thumbnail
              ? config.aws.CLOUDFRONT_URL + item.introVideo.thumbnail
              : `/assets/featured_warriors/DNYHome2000px.jpeg`
          }
          alt={item?.username}
          className="creator"
        />

        <div className="overlay"></div>

        <div className="details">
          <div className="details__top">
            <div className="details__top--count">
              <img
                src="/icons/avatar.svg"
                alt=""
                className="details__top--count-icon"
              />
              {item?.subscribers} subscribers
            </div>

            {item?.introVideo && (
              <div
                onClick={() => {
                  setIntroUrl(
                    config.aws.CLOUDFRONT_URL + item?.introVideo?.url
                  );
                  setShowIntro(true);
                }}
                className="details__top--viewIntro"
              >
                <img src="/assets/icons/play.svg" alt="" />
              </div>
            )}
          </div>

          <div className="details__bottom">
            <div className="details__bottom--username">{item?.username}</div>
            {item?.tagline || item?.bio ? (
              <p className="details__bottom--text">
                {truncateString(item?.tagline || item?.bio, 30)}
              </p>
            ) : (
              <p className="details__bottom--text">No description found!</p>
            )}

            <button
              onClick={() => {
                history.push(`/channel/${item?.username}`);
              }}
              className="details__bottom--btn"
            >
              view channel
            </button>
          </div>
        </div>
      </FeaturedCreatorCardStyled>
    </>
  );
};

export default FeaturedCreatorCard;

const FeaturedCreatorCardStyled = styled.div`
  width: "100%";
  height: 28rem;
  overflow: hidden;
  border-radius: 0.25rem;
  position: relative;
  cursor: pointer;

  .creator {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
  }

  &:hover {
    .overlay {
      opacity: 0.85;
    }

    .details {
      opacity: 1;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to bottom,
      ${(props) => props.theme.primaryColor1} 0,
      #000 100%
    );
    opacity: 0;
    transition: all 0.35s ease;
    z-index: 2;
  }

  .details {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 20;
    padding: 0.75rem 1rem 1.25rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    z-index: 3;
    transition: all 0.35s ease;
    transition-delay: 0.4s;
    opacity: 0;

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--count {
        padding: 0.25rem 0.5rem;
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.75);
        font-family: brother-1816, sans-serif;
        text-transform: uppercase;
        font-size: 0.675rem;
        color: rgba(0, 0, 0, 0.75);
        border-radius: 0.1rem;
        line-height: 1;
        display: flex;
        align-items: center;
        gap: 0.35rem;
        font-weight: 500;

        &-icon {
          width: 0.65rem;
          height: auto;
        }
      }

      &--viewIntro {
        height: 2.25rem;
        width: 2.25rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;

        img {
          width: 1rem;
          height: auto;
          transform: translateX(0.15rem);
        }
      }
    }

    &__bottom {
      font-family: ${(props) => props.theme.montserrat}, sans-serif;

      &--username {
        font-size: 1.35rem;
        font-weight: 600;
        color: white;
      }

      &--text {
        color: rgba(255, 255, 255, 0.75);
        font-size: 0.825rem;
        font-weight: 300;
        line-height: 1.5;
      }

      &--btn {
        font-family: brother-1816, sans-serif;
        text-transform: uppercase;
        font-size: 0.7rem;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.75);
        border: 1.5px solid white;
        padding: 0.65rem 1.25rem;
        line-height: 1;
        border-radius: 0.15rem;
        transition: all 0.25s ease;
        margin-top: 1.75rem;

        &:hover {
          background-color: white;
          color: black;
        }
      }
    }
  }
`;
