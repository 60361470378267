import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import UserEditProfile from "./UserEditProfile";
import { BackChatIcon } from "../../Icons";
import { GlobalContext } from "../../../context/GlobalContext";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../actions";
import { toast } from "react-toastify";
import { notificationMessage } from "../../../utils/toastNotifications";

const UserEditProfileModal = () => {
  const dispatch = useDispatch();
  const { showEditProfile, setShowEditProfile } = useContext(GlobalContext);
  const { updateLoading, updateSuccess, updateError } = useSelector(
    (state) => state.profile
  );

  // states
  const [avatar, setAvatar] = useState(null);
  const [cover, setCover] = useState(null);
  const [visitorBadgeId, setVisitorBadgeId] = useState(null);
  const [badge, setBadge] = useState(null);

  const [tagline, setTagline] = useState(
    "Tagline Tagline Tagline Tagline Tagline Tagline Tagline Tagline"
  );
  const [bio, setBio] = useState(
    "Bio Textline here Bio Textline here Bio Textline here Bio Textline here Bio Textline here Bio Textline here Bio Textline here Bio Textline here Bio Textline here Bio Textline here Bio Textline here Bio Textline here Bio Textline here Bio Textline here Bio Textline here"
  );
  const [showProfilePicType, setShowProfilePicType] = useState(null);

  useEffect(() => {
    if (avatar) {
      setShowProfilePicType("avatar");
    } else {
      setShowProfilePicType("badge");
    }
  }, [avatar]);

  useEffect(() => {
    if (updateSuccess === true) {
      setShowEditProfile(false);
      toast.success(notificationMessage.profileUpdateSuccessfull);
    }

    if (updateError) {
      toast.error("Error while updating the profile.");
      return;
    }
  }, [updateSuccess, updateError]);

  const saveFormHandler = () => {
    const obj1 = {
      avatar,
      cover,
      tagline,
      bio,
    };

    const obj2 = {
      cover,
      tagline,
      bio,
      visitorBadgeId,
      avatar: "",
    };

    dispatch(updateProfile(showProfilePicType === "avatar" ? obj1 : obj2));
  };

  return (
    <>
      <UserEditProfileModalStyled>
        <div className={`wrapper ${showEditProfile && "showEditProfileModel"}`}>
          <div className="wrapper__header">
            <div
              onClick={() => {
                setShowEditProfile(false);
              }}
              className="backIcon"
            >
              <BackChatIcon width={25} />
            </div>
            <div onClick={saveFormHandler} className="button-save">
              <div>{updateLoading ? "Saving..." : "Save"}</div>
            </div>
          </div>
          <UserEditProfile
            avatar={avatar}
            setAvatar={setAvatar}
            cover={cover}
            setCover={setCover}
            tagline={tagline}
            setTagline={setTagline}
            bio={bio}
            setBio={setBio}
            visitorBadgeId={visitorBadgeId}
            setVisitorBadgeId={setVisitorBadgeId}
            badge={badge}
            setBadge={setBadge}
            showProfilePicType={showProfilePicType}
            setShowProfilePicType={setShowProfilePicType}
          />
        </div>
      </UserEditProfileModalStyled>
    </>
  );
};

export default UserEditProfileModal;

const UserEditProfileModalStyled = styled.div`
  .wrapper {
    min-height: 100vh;
    height: 100vh;
    width: 100%;
    background-color: #1c1c1e;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    z-index: 150;
    opacity: 0;
    /* transform: translateX(-100%); */
    visibility: hidden;
    transition: all 1s ease;

    &__header {
      display: flex;
      align-items: center;
      padding: 1rem 5rem;
      border-bottom: 1px solid rgba(112, 112, 112, 0.4);

      .backIcon {
        margin-right: 1rem;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .button-save {
        border: 2px solid #fff;
        padding: 0.1rem 1.5rem;
        border-radius: 10rem;
        font-size: 0.9rem;
        background-color: transparent;
        color: #fff;
        font-family: ${(props) => props.theme.montserrat};
        font-weight: 400;
        text-transform: capitalize;
        transition: all 0.2s ease;
        cursor: pointer;
        margin-left: auto;

        &:hover {
          background-color: #fff;
          color: #000;
        }
      }
    }
    &__content {
      height: 100vh;
    }
  }

  @media screen and (max-width: 600px) {
    .wrapper {
      &__header {
        padding: 0.8rem 2rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .wrapper {
      &__header {
        padding: 0.5rem 1.5rem;

        .name {
          font-size: 1rem;
        }
      }
    }
  }
`;
