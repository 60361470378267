import React, { useEffect, useRef, useCallback, useState } from "react";
import Loader from "../components/Elements/Loader";
import styled from "styled-components";
import CloseIcon from "../components/icons/close.svg";
import PlayIcon from "../components/icons/play.svg";
import PauseIcon from "../components/icons/pause.svg";
import SoundOn from "../components/icons/sound-on.svg";
import SoundOff from "../components/icons/sound-off.svg";
import ChevronDownIcon from "../components/icons/chevron-down.svg";
import ReactPlayer from "react-player";
import api from "../services/api";
import debounce from "lodash.debounce";
import Upvote from "../components/Elements/Upvote";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  getSingleMoment,
  subscribeChannel,
  unsubscribeChannel,
  getGuestSingleMoment,
} from "../actions";
import { SUBSCRIBE_FROM_VIDEO, UNSUBSCRIBE_FROM_VIDEO } from "../actions/types";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { notificationMessage } from "../utils/toastNotifications";
import config from '../config'

const MomentWatch = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { singleMomentLoading, singleMoment, singleMomentError } = useSelector(
    (state) => state.moment
  );
  const user = useSelector((state) => state.user);

  const playerRef = useRef();
  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(false);
  const [played, setPlayed] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [started, setStarted] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(""); // IS_MINE, SUBSCRIBED, UNSUBSCRIBED
  const [upvote, setUpvote] = useState(null);
  const [totalUpvotes, setTotalUpvotes] = useState(null);
  const [readyToCallUpvoteApi, setReadyToCallUpvoteApi] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  useEffect(() => {
    if (!user?.id) {
      // fetch guest moment
      dispatch(getGuestSingleMoment(params.momentId));
    } else {
      dispatch(getSingleMoment(params.momentId));
    }
  }, []);

  useEffect(() => {
    if (singleMoment) {
      setUpvote(singleMoment?.myUpvote?.upvote || null);
      setTotalUpvotes(singleMoment?.upvotes || 0);
      setVideoUrl(singleMoment?.videoUrl);
      setVideoThumbnail(singleMoment?.coverImgUrl);
    }

    if (!singleMoment?.isMine && singleMoment?.isSubscribed) {
      setIsSubscribed("SUBSCRIBED");
    } else if (!singleMoment?.isMine && !singleMoment?.isSubscribed) {
      setIsSubscribed("UNSUBSCRIBED");
    } else if (singleMoment?.isMine) {
      setIsSubscribed("ISMINE");
    }
  }, [singleMoment]);

  useEffect(async () => {
    if (readyToCallUpvoteApi) {
      await upvoteApiCallHandler(singleMoment?.id, upvote);
      setReadyToCallUpvoteApi(false);
    }
  }, [upvote, readyToCallUpvoteApi]);

  const handleProgress = (progressState) => {
    setPlayed(progressState.played);
    setLoaded(progressState.loaded);
  };

  const isBadgeOrAvatar = () => {
    const image = singleMoment?.User?.avatar
      ? singleMoment.User.avatar
      : singleMoment?.User?.badge
        ? singleMoment.User.badge
        : null;

    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <div className="imageBadge">
          <img className="badge" src={config.aws.CLOUDFRONT_URL + image} alt="" />
        </div>
      );
    } else {
      return <img className="imageAvatar" src={config.aws.CLOUDFRONT_URL + image} alt="" />;
    }
  };

  const addToStreamsHandler = () => {
    if (!user?.id) {
      // it means user is not logged in
      toast.error(notificationMessage.loginRequired);

      return;
    }

    setIsSubscribed("SUBSCRIBED");

    dispatch(
      subscribeChannel({
        channel: {
          id: singleMoment?.User?.id,
          avatar: singleMoment?.User?.avatar,
          username: singleMoment?.User?.username,
          visitorBadge: {
            imgPath: singleMoment?.User?.badge,
          },
        },
        type: SUBSCRIBE_FROM_VIDEO,
      })
    );
  };

  const removeFromStreamsHandler = () => {
    if (!user?.id) {
      // it means user is not logged in
      toast.error(notificationMessage.loginRequired);

      return;
    }

    setIsSubscribed("UNSUBSCRIBED");

    dispatch(
      unsubscribeChannel({
        type: UNSUBSCRIBE_FROM_VIDEO,
        channelId: singleMoment?.User?.id,
      })
    );
  };

  const upvoteHandler = () => {
    if (upvote < 3) {
      setUpvote(upvote + 1);
    } else {
      setUpvote(0);
    }

    setReadyToCallUpvoteApi(true);
  };

  const upvoteApiCallHandler = useCallback(
    debounce(async (momentId, upvote) => {
      const obj = {
        upvote,
      };
      await api.post(`moment/${momentId}/upvote-moment`, obj);
    }, 1000),
    []
  );

  return (
    <MomentWatchStyled>
      {singleMomentLoading ? (
        <div className="loadingBox">
          <Loader text="Please Wait..." />
        </div>
      ) : (
        <>
          <div className="momentDetails__left">
            <div
              onClick={() => {
                history.push("/moments");
              }}
              className="momentDetails__left--close"
            >
              {isMobile ? (
                <>
                  <img src={ChevronDownIcon} alt="" />
                  <div className="text">Close</div>
                </>
              ) : (
                <>
                  <img src={CloseIcon} alt="" />
                </>
              )}
            </div>

            <div className="momentDetails__left--overlay"></div>
            <div className="momentDetails__left--video">
              <div onClick={() => setPlaying(!playing)} className="player">
                <ReactPlayer
                  ref={playerRef}
                  width="100%"
                  height="100%"
                  url={config.aws.CLOUDFRONT_URL + videoUrl}
                  playing={playing}
                  muted={muted}
                  onStart={() => setStarted(true)}
                  onProgress={handleProgress}
                  loop={true}
                />
              </div>

              {started && (
                <div className="controls">
                  <div className="controls__left">
                    <div onClick={() => setPlaying(!playing)} className="icon">
                      <img src={playing ? PauseIcon : PlayIcon} alt="" />
                    </div>
                  </div>

                  <div className="controls__right">
                    <div onClick={() => setMuted(!muted)} className="icon">
                      <img src={muted ? SoundOff : SoundOn} alt="" />
                    </div>
                  </div>
                </div>
              )}

              {!started && !playing && (
                <div className="thumbnail">
                  <img src={videoThumbnail} alt="" />
                </div>
              )}

              {!started && (
                <div
                  onClick={() => {
                    setPlaying(true);
                  }}
                  className="bigPlayIcon"
                >
                  <img src={PlayIcon} alt="" />
                </div>
              )}

              <div className="videoProgress">
                <div className="videoProgress__progressBar">
                  <div
                    style={{
                      width: `${loaded * 100}%`,
                    }}
                    className="videoProgress__progressBar--loaded"
                  ></div>

                  <div
                    style={{
                      width: `${played * 100}%`,
                    }}
                    className="videoProgress__progressBar--played"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="momentDetails__right">
            <div className="momentDetails__right--user">
              <div
                onClick={() => {
                  history.push(`/channel/${singleMoment?.User?.username}`);
                }}
                className="momentDetails__right--user--left"
              >
                <div className="avatar">{isBadgeOrAvatar()}</div>
                <div className="username">{singleMoment?.User?.username}</div>
              </div>
              <div className="momentDetails__right--user--right">
                {isSubscribed === "SUBSCRIBED" && (
                  <div
                    className="followBtnGray"
                    onClick={() => {
                      if (!user?.id) {
                        return toast.error(notificationMessage.loginRequired);
                      }

                      removeFromStreamsHandler();
                    }}
                  >
                    remove from streams
                  </div>
                )}

                {isSubscribed === "UNSUBSCRIBED" && (
                  <div
                    className="followBtn"
                    onClick={() => {
                      if (!user?.id) {
                        return toast.error(notificationMessage.loginRequired);
                      }

                      addToStreamsHandler();
                    }}
                  >
                    add to streams
                  </div>
                )}
              </div>
            </div>

            <pre className="momentDetails__right--caption">
              {singleMoment?.caption}
            </pre>

            <div className="momentDetails__right--actionStats">
              <Upvote
                upvote={upvote}
                upvoteHandler={() => {
                  if (!user?.id) {
                    return toast.error(notificationMessage.loginRequired);
                  }

                  upvoteHandler();
                }}
                total={totalUpvotes}
              />
            </div>

            <div className="momentDetails__right--comments"></div>
          </div>
        </>
      )}
    </MomentWatchStyled>
  );
};

export default MomentWatch;

const MomentWatchStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 899;
  height: 100vh;
  width: 100vw;
  color: #fff;
  transition: all 0.7s ease;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;
  overflow: hidden;
  background: #181818;

  .loadingBox {
    padding: 2rem;
    width: 100%;
  }

  .momentDetails {
    &__left {
      width: 60%;
    }
    &__right {
      width: 40%;
    }

    &__left {
      position: relative;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;

      &--close {
        position: absolute;
        top: 1rem;
        left: 2rem;
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(255, 255, 255, 0.4);
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
          border: 1px solid transparent;
          background: ${(props) => props.theme.gradient};
        }

        img {
          height: 1.3rem;
          width: auto;
        }
      }

      &--overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        z-index: -1;
      }

      &--video {
        margin: 0 auto;
        height: calc(100vh - 0px - 0px * 3);
        width: calc(calc(100vh - 0px - 0px * 3) * 0.5625);
        min-height: 560px;
        min-width: 320px;
        overflow: hidden;
        position: relative;

        .player {
          z-index: 5;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }

        .bigPlayIcon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          z-index: 5;

          img {
            height: 5rem;
            width: auto;
          }
        }

        .thumbnail {
          z-index: 4;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;

          img {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
          }
        }

        .videoProgress {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.5),
            50%,
            transparent
          );
          z-index: 5;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &__progressBar {
            height: 4px;
            width: 100%;
            background-color: #fff;
            transition: all 0.2s ease;
            position: relative;
            overflow: hidden;

            &--loaded {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              height: 100%;
              width: 0;
              background: rgba(0, 0, 0, 0.4);
            }

            &--played {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              height: 100%;
              width: 0;
              background: ${(props) => props.theme.gradient};
            }
          }
        }

        .controls {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.5rem 1rem 1.5rem 1rem;
          z-index: 5;
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.5),
            60%,
            transparent
          );

          .icon {
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 100;
            border: 1px solid transparent;
            transition: all 0.3s ease;
            cursor: pointer;
            scale: 0;
            transform-origin: center;
            visibility: hidden;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */

            &:hover {
              border: 1px solid rgba(255, 255, 255, 0.1);
            }

            img {
              height: 1.1rem;
              width: auto;
            }
          }
        }
      }

      &:hover .controls {
        &__progressBar {
          opacity: 1;
          transform: translateY(-10%);
        }

        .icon {
          scale: 1;
          visibility: visible;
        }
      }
    }

    &__right {
      background-color: #202020;
      height: 100vh;
      padding: 2rem;

      &--user {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .avatar {
          cursor: pointer;
          height: 4rem;
          width: 4rem;
          border-radius: 50%;
          margin-right: 1rem;
          position: relative;

          .imageAvatar {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
            height: 100%;
            width: 100%;
          }

          .imageBadge {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .badge {
              height: 38px;
              width: auto;
            }
          }
        }

        .username {
          color: rgba(255, 255, 255, 0.8);
          font-weight: 400;
          font-size: 1.2rem;
          cursor: pointer;
        }

        .followBtn,
        .followBtnGray {
          cursor: pointer;
          line-height: 1;
          font-family: brother-1816, sans-serif;
          padding: 0.5rem 1rem;
          text-transform: uppercase;
          font-size: 0.85rem;
          font-weight: 500;
          border-radius: 3px;
        }

        .followBtn {
          background: transparent
            linear-gradient(
              130deg,
              var(--profile-icon-bg) 14%,
              #f88946 23%,
              #f8795f 37%,
              #f75e87 55%,
              #f75b8c 57%
            )
            0% 0% no-repeat padding-box;
          background: transparent
            linear-gradient(
              130deg,
              #f9903d 14%,
              #f88946 23%,
              #f8795f 37%,
              #f75e87 55%,
              #f75b8c 57%
            )
            0% 0% no-repeat padding-box;
        }

        .followBtnGray {
          background-color: #181818;
          border: 1px solid rgba(255, 255, 255, 0.2);
        }

        &--left {
          display: flex;
          align-items: center;
        }
      }

      &--caption {
        font-weight: 300;
        line-height: 1.5;
        color: rgba(255, 255, 255, 0.8);
        padding-bottom: 1.5rem;
      }

      &--actionStats {
        padding: 0 0 1.5rem 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }

      &--comments {
        padding: 1rem 0;
      }
    }
  }

  /* responsive */
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    overflow-y: scroll;

    .momentDetails {
      &__left,
      &__right {
        width: 100%;
      }

      &__left {
        &--nextMoment,
        &--prevMoment {
          height: 2.5rem;
          font-size: 0.85rem;
          padding: 0 0.7rem;
        }

        &--close {
          top: 0;
          left: 0;
          height: 3rem;
          width: 100%;
          background: #181818;
          border-radius: 0;
          border: none;

          .text {
            margin-left: 1rem;
            text-transform: uppercase;
            font-size: 0.8rem;
            font-weight: 500;
          }

          img {
            width: 1rem;
            height: auto;
          }
        }

        &--video {
          height: calc(100vh - 40px - 0px * 3);
          width: calc(calc(100vh - 0px - 0px * 3) * 0.5625);

          margin-top: 3rem;
        }
      }

      &__right {
        padding: 2rem 1rem;
      }
    }
  }
`;
