import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { keyframes } from "styled-components";
import { GlobalContext } from "../../../context/GlobalContext";
import { BackChatIcon, UserChatIcon } from "../../Icons";
import Message from "./Message";
import socketIo from "socket.io-client";
import config from "../../../config";
import ChatItemSkeleton from "../../../skeletons/General/Card";
import { getAllFriends, updateStatusRead } from "../../../actions";
import { useMediaQuery } from "react-responsive";

const ENDPOINT = config.sockets.socketBackendUrl;
let socket;

const ChatDetailsModal = ({ close }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { showChatDetails } = useContext(GlobalContext);
  const [userTypingStatus, setUserTypingStatus] = useState(null);
  const [activeUser, setActiveUser] = useState([]);
  const [lastMessage, setLastMessage] = useState([]);
  const [senderId, setSenderId] = useState(localStorage.getItem("senderId"));
  const [receiverId, setReceiverId] = useState(
    localStorage.getItem("receiverId")
  );
  const [connectionId, setConnectionId] = useState(
    localStorage.getItem("connectionId")
  );

  const {
    loading,
    requests,
    getError,
    friends,
    friendsLoading,
    removeMessage,
    removeError,
  } = useSelector((state) => state.connection);
  const activeUserData = useSelector((state) => state.activeUser);

  useEffect(() => {
    if (friends?.length) {
      dispatch(getAllFriends());
    }

    setActiveUser(activeUserData);

    socket = socketIo(ENDPOINT, { transports: ["websocket"] });

    socket.on("lastRead", (data) => {
      setLastMessage(data);
    });
    socket.on("userStatus", (data) => {
      setActiveUser(data);
    });
    socket.on("userTypingStatus", (data) => {
      setUserTypingStatus(data);
    });

    // setConnectionId(localStorage.getItem("connectionId"))
  }, []);

  const isBadgeOrAvatar = (user) => {
    const image = !user.avatar
      ? user.userSettings.VisitorBadge.imgPath
      : user.avatar;
    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <div className="imageBadge">
          <img className="badge" src={image} alt="" />
        </div>
      );
    } else {
      return (
        <img
          className="imageAvatar"
          src={config.aws.CLOUDFRONT_URL + image}
          alt=""
        />
      );
    }
  };

  const lastReadShow = (item, id) => {
    if (user.id === item.warrior.id) {
      const data = lastMessage.map((data1) =>
        data1.receiverId === id ? (
          data1.senderId === senderId ? (
            data1.senderId !== senderId && data1.isRead === false ? (
              data1.connectionId === connectionId && (
                <span className="textColor">{data1.message}</span>
              )
            ) : data1.senderId !== senderId && data1.isRead ? (
              data1.message
            ) : (
              data1.message
            )
          ) : (
            data1.receiverId === senderId &&
            data1.senderId !== senderId &&
            data1.message
          )
        ) : data1.senderId === id ? (
          data1.senderId === senderId ? (
            data1.receiverId !== senderId && data1.senderId === senderId ? (
              "wsws"
            ) : (
              data1.message
            )
          ) : data1.senderId === senderId && data1.isRead === false ? (
            <span className="textColor">{data1.message}</span>
          ) : data1.senderId !== senderId &&
            data1.receiverId === senderId &&
            data1.isRead === false ? (
            <span className="textColor">{data1.message}</span>
          ) : data1.senderId !== senderId &&
            data1.receiverId === senderId &&
            data1.isRead ? (
            data1.message
          ) : (
            ""
          )
        ) : (
          ""
          // console.log(data1.senderId, data1.receiverId)
        )
      );
      return data;
    } else {
      const last = lastMessage.map((data1) =>
        data1.receiverId === id ? (
          data1.receiverId === item.collaborator.id ? (
            data1.receiverId !== senderId && data1.isRead === false ? (
              <span className="textColor">{data1.message}</span>
            ) : data1.receiverId !== senderId && data1.isRead ? (
              data1.message
            ) : (
              data1.message
            )
          ) : (
            data1.receiverId !== senderId &&
            data1.senderId === senderId &&
            data1.message
          )
        ) : data1.senderId === id ? (
          data1.receiverId !== senderId ? (
            data1.senderId !== senderId &&
            data1.receiverId === senderId &&
            data1.isRead === false ? (
              <span className="textColor">{data1.message}</span>
            ) : (
              data1.receiverId !== senderId && data1.isRead && data1.message
            )
          ) : data1.senderId !== senderId &&
            data1.receiverId === senderId &&
            data1.isRead === false ? (
            <span className="textColor">{data1.message}</span>
          ) : (
            data1.message
          )
        ) : (
          ""
        )
      );
      return last;
    }
  };

  const handleClickOnUserName = async (id, item) => {
    if (id === item.collaborator.id) {
      localStorage.setItem("username", item.warrior.username);
      localStorage.setItem("senderId", user.id);
      setSenderId(localStorage.getItem("senderId"));
      localStorage.setItem("receiverId", item.warrior.id);
      setReceiverId(localStorage.getItem("receiverId"));
      localStorage.setItem("collaboratorId", item.warrior.id);
      localStorage.setItem("connectionId", item.id);
      setConnectionId(localStorage.getItem("connectionId"));
      await dispatch(
        updateStatusRead(item.warrior.id, user.id, user.id, item.id)
      );
      socket.emit("lastReadMessage", friends);
    } else if (id === item.warrior.id) {
      localStorage.setItem("username", item.collaborator.username);
      localStorage.setItem("senderId", user.id);
      setSenderId(localStorage.getItem("senderId"));
      localStorage.setItem("receiverId", item.collaborator.id);
      setReceiverId(localStorage.getItem("receiverId"));
      localStorage.setItem("collaboratorId", item.collaborator.id);
      localStorage.setItem("connectionId", item.id);
      setConnectionId(localStorage.getItem("connectionId"));
      await dispatch(
        updateStatusRead(item.collaborator.id, user.id, user.id, item.id)
      );
      socket.emit("lastReadMessage", friends);
    }
  };

  return (
    <ChatDetailsModalStyled
      className={`${showChatDetails && "ShowChatDetails"}`}
    >
      <div className="sidebar">
        <div className="sidebar__header">
          <div className="sidebar__header--title">
            Friends ({friends?.length})
          </div>
        </div>

        {friendsLoading && (
          <>
            <div className="sidebar__loader">
              <ChatItemSkeleton cardName="chatItem" />
            </div>
            <div className="sidebar__loader">
              <ChatItemSkeleton cardName="chatItem" />
            </div>
            <div className="sidebar__loader">
              <ChatItemSkeleton cardName="chatItem" />
            </div>
          </>
        )}

        {friends?.length > 0 &&
          friends.map((item, i) => (
            <div
              key={i}
              className="sidebar__item"
              onClick={() => handleClickOnUserName(user.id, item)}
            >
              {item.id === localStorage.getItem("connectionId") ? (
                <div className="sidebar__item--activeBar"></div>
              ) : null}

              <div className="sidebar__item--avatar">
                {user.id === item.warrior.id && (
                  <>{isBadgeOrAvatar(item.collaborator)}</>
                )}

                {user.id === item.collaborator.id && (
                  <>{isBadgeOrAvatar(item.warrior)}</>
                )}
              </div>

              <div className="sidebar__item--info">
                <div className="username">
                  {user.id === item.warrior.id && (
                    <>{item.collaborator.username}</>
                  )}

                  {user.id === item.collaborator.id && (
                    <>{item.warrior.username}</>
                  )}
                </div>
                <div className="stats">
                  {activeUser.length !== 0
                    ? activeUser.map((userData) => (
                        <div
                          className={`itemV2 ${
                            userData.isActive && user.id !== userData.userId
                              ? item.warrior.id === userData.userId ||
                                item.collaborator.id === userData.userId
                                ? "focus"
                                : null
                              : user.id !== userData.userId
                              ? userData.userId === item.warrior.id ||
                                userData.userId === item.collaborator.id
                                ? null
                                : null
                              : null
                          }`}
                        >
                          {userData.isActive && user.id !== userData.userId
                            ? item.warrior.id === userData.userId ||
                              item.collaborator.id === userData.userId
                              ? `Online`
                              : ``
                            : user.id !== userData.userId
                            ? userData.userId === item.warrior.id ||
                              userData.userId === item.collaborator.id
                              ? `Offline`
                              : ""
                            : ``}
                        </div>
                      ))
                    : null}

                  <div className="dot"></div>
                  <div className="item unread">
                    {lastReadShow(
                      item,
                      user.id === item.collaborator.id
                        ? item.warrior.id
                        : item.collaborator.id
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      {friends
        ? friends.map(
            (userData) =>
              userData.id === localStorage.getItem("connectionId") && (
                <div className={`wrapper`}>
                  <div className="wrapper__header">
                    <div onClick={close} className="backIcon">
                      <BackChatIcon width={25} />
                    </div>
                    <div className="user-image-name-status">
                      {userData.collaborator.id === user.id ? (
                        <img
                          src={
                            !userData.warrior.avatar
                              ? userData.warrior.userSettings.VisitorBadge
                                  .imgPath
                              : config.aws.CLOUDFRONT_URL +
                                userData.warrior.avatar
                          }
                          className="user-avatar"
                        />
                      ) : (
                        <img
                          src={
                            !userData.collaborator.avatar
                              ? userData.collaborator.userSettings.VisitorBadge
                                  .imgPath
                              : config.aws.CLOUDFRONT_URL +
                                userData.collaborator.avatar
                          }
                          className="user-avatar"
                        />
                      )}
                      <div className="user-name-status">
                        <span className="friend-username">
                          {localStorage.getItem("username")}
                        </span>
                        <span className="friend-status">
                          {localStorage.getItem("username") === userTypingStatus
                            ? "typing"
                            : "Active now"}
                        </span>
                      </div>
                    </div>
                    <div className="wrapper__message">
                      <Message
                        imgPath={
                          userData.collaborator.id === user.id
                            ? !userData.warrior.avatar
                              ? userData.warrior.userSettings.VisitorBadge
                                  .imgPath
                              : config.aws.CLOUDFRONT_URL +
                                userData.warrior.avatar
                            : !userData.collaborator.avatar
                            ? userData.collaborator.userSettings.VisitorBadge
                                .imgPath
                            : config.aws.CLOUDFRONT_URL +
                              userData.collaborator.avatar
                        }
                      />
                    </div>
                  </div>
                  <Message
                    imgPath={
                      userData.collaborator.id === user.id
                        ? !userData.warrior.avatar
                          ? userData.warrior.userSettings.VisitorBadge.imgPath
                          : config.aws.CLOUDFRONT_URL + userData.warrior.avatar
                        : !userData.collaborator.avatar
                        ? userData.collaborator.userSettings.VisitorBadge
                            .imgPath
                        : config.aws.CLOUDFRONT_URL +
                          userData.collaborator.avatar
                    }
                  />
                </div>
              )
          )
        : ""}
    </ChatDetailsModalStyled>
  );
};

export default ChatDetailsModal;

const backgroundPan = keyframes`
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
`;

const ChatDetailsModalStyled = styled.div`
  opacity: 0;
  transform: translateX(-100%);
  z-index: 150;
  transition: all 1s ease;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: flex-start;
  overflow: hidden;

  .textColor {
    font-weight: 800;
    background: linear-gradient(
      to right,
      #f9903d 23%,
      #f8795f 37%,
      #f75e87 55%,
      #f75b8c 57%
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }

  .sidebar {
    height: 100%;
    overflow-y: auto;
    width: 22rem;
    background-color: #181818;
    font-family: ${(props) => props.theme.montserrat}, sans-serif;
    border-right: 4px solid rgba(255, 255, 255, 0.2);

    &__header {
      padding: 0.7rem 1rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 2rem;

      &--title {
        font-family: brother-1816, sans-serif;
        font-size: 1.1rem;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    &__loader {
      width: 95%;
      margin: 0 auto;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    &__item {
      width: 100%;
      padding: 1rem 2rem;
      background-color: #202020;
      border-top: 1px solid rgba(255, 255, 255, 0.04);
      border-bottom: 1px solid rgba(255, 255, 255, 0.04);
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;

      &--activeBar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0.5rem;
        background: ${(props) => props.theme.gradient};
        box-shadow: 0 0 12.5px rgb(249, 154, 45, 0.5),
          -2.5px 0 20px rgb(246, 92, 139, 0.5),
          2.5px 0 20px rgb(249, 154, 45, 0.5);
      }

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      &--avatar {
        cursor: pointer;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        position: relative;
        margin-right: 0.5rem;
        transition: all 0.2s ease;
        outline: 1px solid transparent;

        &:hover {
          outline: 1px solid rgba(255, 255, 255, 0.5);
        }

        .imageAvatar {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center;
          object-fit: cover;
          border-radius: 50%;
        }

        .imageBadge {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .badge {
            height: 38px;
            width: auto;
          }
        }
      }

      &--info {
        .username {
          font-size: 0.85rem;
          font-weight: 500;
          letter-spacing: 0.09px;
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 0.5rem;
          line-height: 1;
        }

        .stats {
          font-size: 0.66rem;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          line-height: 1;

          .dot {
            height: 4px;
            width: 4px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.6);
            margin: 0 0.5rem;
          }

          .item {
            color: rgba(255, 255, 255, 0.6);
          }

          .itemV2 {
            font-family: brother-1816, sans-serif;
            text-transform: uppercase;
            letter-spacing: 0.1px;
            font-size: 0.6rem;
          }

          .unread {
            font-weight: 500;
            color: rgba(255, 255, 255, 0.8);
          }

          .focus {
            background: linear-gradient(
              to right,
              rgb(249, 154, 45),
              rgb(246, 92, 139),
              rgb(249, 154, 45)
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: ${backgroundPan} 3s linear infinite;
            background-size: 200%;
            font-weight: 500;
          }
        }
      }
    }
  }

  .wrapper {
    width: calc(100% - 22rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: #202020;

    &__header {
      display: flex;
      align-items: center;
      padding: 0 2rem;
      border-bottom: 1px solid rgba(112, 112, 112, 0.4);
      height: 4rem;
      width: 100%;

      .backIcon {
        margin-right: 0.5rem;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .button-save {
        border: 2px solid #fff;
        border-radius: 20px;
        padding: 0px 18px;
        font-family: "Montserrat";
        font-size: 14px;
        margin-left: auto;
      }
    }
    &__message {
      height: calc(100% - 9rem);
      width: 100%;
      overflow-y: auto;
    }
  }
  .user-image-name-status {
    display: flex;
    margin-left: 25px;
    align-items: center;
  }
  .user-name-status {
    display: grid;
    margin-left: 20px;
  }
  .friend-username {
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 600;
  }
  .user-avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  .friend-status {
    font-size: 12px;
    font-family: Montserrat;
  }
`;
