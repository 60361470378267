import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import BackIcon from "../../../assets/Icons/back.svg";
import UploadIcon from "../../../assets/Icons/upload-icon-black.svg";
import { motion } from "framer-motion";
import { GlobalContext } from "../../../context/GlobalContext";
import { getBadgesByUserrole } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { uploadCover } from "../../../utils";

const BadgeContainerVariants = {
  hidden: {
    opacity: 0,
    y: "-5rem",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 120,
      duration: 0.5,
    },
  },
};

const ChooseProfileImage = ({
  close,
  avatar,
  setAvatar,
  visitorBadgeId,
  setVisitorBadgeId,
  setBadge,
  setShowProfilePicType,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const badgesUserrole = useSelector((state) => state.badgesUserrole);
  const { showChooseProfilePicModel, setShowChooseProfilePicModel } =
    useContext(GlobalContext);

  useEffect(() => {
    if (user && user.userrole === 0) {
      dispatch(getBadgesByUserrole("free"));
    }
    if (user && user.userrole === 1) {
      dispatch(getBadgesByUserrole("basic"));
    }
    if (user && user.userrole === 2) {
      dispatch(getBadgesByUserrole("premium"));
    }
  }, [user]);

  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];

    const fileExtension = file.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
      toast.error(
        `Image extension does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
      return false;
    }

    if (file) {
      const { url } = await uploadCover("image", file);
      setAvatar(url);
      setShowProfilePicType("avatar");
      setShowChooseProfilePicModel(false);
    }
  };

  return (
    <>
      <ChooseProfileImageStyled
        className={`${
          showChooseProfilePicModel && "ShowChooseProfileImageModel"
        }`}
      >
        <div className="header">
          <div className="left">
            <div onClick={close} className="backIcon">
              <img src={BackIcon} alt="" />
            </div>
            <div className="name">Choose your profile avatar</div>
          </div>

          <div className="right">
            <button onClick={close} className="button">
              choose
            </button>
          </div>
        </div>

        <motion.div
          variants={BadgeContainerVariants}
          initial="hidden"
          animate="visible"
          className="badges"
        >
          <label htmlFor="avatar-upload" className="customAvatar">
            <div className="text">
              <div className="light">Upload</div>
              <div className="bold">Custom Avatar</div>
            </div>

            <img className="icon" src={UploadIcon} alt="" />
          </label>

          <input
            id="avatar-upload"
            type="file"
            accept="image/*"
            onChange={handleAvatarUpload}
            style={{ display: "none" }}
          />

          {badgesUserrole &&
            badgesUserrole.length > 0 &&
            badgesUserrole.map((item, i) => (
              <div
                key={item.id}
                onClick={() => {
                  setBadge(item.imgPath);
                  setVisitorBadgeId(item.id);
                  setShowProfilePicType("badge");
                }}
                className={`badges__item ${
                  visitorBadgeId === item.id && "selected"
                }`}
              >
                <img src={item.imgPath} alt={item.name} className="icon" />
                <div className="name">
                  {item.name.length > 10
                    ? item.name.substring(0, 10) + "..."
                    : item.name}
                </div>
              </div>
            ))}

          {!badgesUserrole.length && (
            <div className="loading">Fetching badges! Please wait...</div>
          )}
        </motion.div>
      </ChooseProfileImageStyled>
    </>
  );
};

export default ChooseProfileImage;

const ChooseProfileImageStyled = styled.div`
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #202020;
  border-radius: 0.3rem;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.4), 0px 0px 4px rgba(0, 0, 0, 0.25);
  transform: translateY(-100%);
  transition: all 1s ease;
  font-family: ${(props) => props.theme.montserrat};

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 5rem;
    border-bottom: 1px solid rgba(112, 112, 112, 0.4);

    .backIcon {
      margin-right: 1rem;
      cursor: pointer;

      img {
        height: 1rem;
        width: auto;
      }
    }

    .name {
      font-size: 1.2rem;
      font-weight: 500;
      color: #f2f2f7;
      text-transform: capitalize;
    }

    .left {
      display: flex;
      align-items: center;
    }

    .button {
      border: 3px solid #fff;
      padding: 0.3rem 1.2rem;
      border-radius: 10rem;
      font-size: 1rem;
      background-color: transparent;
      color: #fff;
      font-family: ${(props) => props.theme.montserrat};
      font-weight: 400;
      text-transform: capitalize;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }

  .loading,
  .error {
    font-family: brother-1816, sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .badges {
    margin: 2rem auto;
    width: 50%;
    min-width: 480px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    .customAvatar {
      width: 100%;
      margin-bottom: 2rem;
      background-color: #fff;
      padding: 0.8rem 1rem;
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #2c2c2e;
      font-size: 0.95rem;
      cursor: pointer;

      .text {
        display: flex;
        align-items: center;
        text-transform: capitalize;

        .light {
          font-weight: 400;
          padding-right: 0.5rem;
        }

        .bold {
          font-weight: 600;
        }
      }

      .icon {
        height: 1.5rem;
        width: auto;
      }
    }

    &__item {
      width: 8rem;
      flex-basis: 20%;
      -ms-flex: auto;
      position: relative;
      padding: 10px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 2rem;

      .icon {
        width: 4.5rem;
        height: auto;
        margin-bottom: 0.5rem;
      }

      .name {
        text-align: center;
        line-height: 1;
        font-family: brother-1816, sans-serif;
        font-weight: 300;
        text-transform: uppercase;
        font-size: 0.7rem;
        padding: 0.2rem 0.4rem;
        transition: all 0.5s ease;
      }
    }

    .selected .name {
      background: ${(props) => props.theme.primaryGradient};
      border-radius: 3px;
      color: #fff;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 768px) {
    .header {
      padding: 1rem 2.5rem;

      .name {
        font-size: 1.1rem;
      }
    }

    .badges {
      width: 80%;

      &__item {
        flex-basis: 33.33%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .header {
      padding: 1rem 1.5rem;

      .name {
        font-size: 0.9rem;
      }

      .button {
        font-size: 0.85rem;
      }
    }

    .badges {
      width: 90%;
      min-width: 90%;

      &__item {
        .icon {
          width: 4rem;
        }
      }
    }
  }
`;
