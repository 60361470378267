import {
  SIGNUP,
  LOGIN,
  LOGOUT,
  GET_FEED,
  GET_VIDEO,
  CLEAR_VIDEO,
  ADD_COMMENT,
  GET_PROFILE,
  CLEAR_PROFILE,
  UPDATE_PROFILE,
  GET_NOTIFICATIONS_CATEGORIES,
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_COMMENT_ID,
  GET_CHANNEL_STREAM_VIDEOS,
  SET_FETCHING,
  CLEAR_COMMENT_ID,
  NOTIFICATIONS_READ_COUNT,
  PROFILE_ELEMENT_VIDEOS,
  PROFILE_ELEMENT_VIDEOS_SECOND,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  ISCHANNEL_SUBSCRIBED,
  GET_RECOMMENDATIONS,
  GET_FEATURED,
  GET_CHANNEL_RECOMMENDATIONS,
  LIKE,
  GET_USER_CHANNELS_SUCCESS,
  GET_USER_CHANNELS_HASMORE,
  GET_USER_CHANNEL_REQUEST,
  PROFILE_ELEMENT_BANNERS,
  PROFILE_ELEMENT_BANNERS_SECOND,
  DISLIKE,
  CANCEL_LIKE,
  CANCEL_DISLIKE,
  GET_SEARCH_RESULTS,
  CLEAR_SEARCH_RESULTS,
  PROFILE_ELEMENT_MOMENTS_SECOND,
  GET_TRENDING,
  ADD_CHANNEL,
  PROFILE_ELEMENT_MOMENTS,
  GET_HASMORE_MOMENTS,
  PROFILE_ELEMENT_SERIES,
  PROFILE_ELEMENT_SERIES_SECOND,
  GET_USER_VIDEOS_SUCCESS_SECOND,
  REMOVE_CHANNEL,
  GET_LIKED_VIDEOS,
  UPDATE_USER,
  ADD_TO_LIKED_VIDEOS,
  REMOVE_FROM_LIKED_VIDEOS,
  ADD_TO_RECOMMENDATIONS,
  GET_HISTORY,
  SHOW_NOT_FOUND,
  PROFILE_ELEMENT_THREADS,
  CLEAR_NOT_FOUND,
  ADD_TO_HISTORY,
  UPDATE_VIDEO,
  DELETE_VIDEO,
  DELETE_VIDEO_S3,
  GET_VIDEO_CATEGORIES,
  GET_HASHTAGS,
  STRIPE_ONBOARD,
  ACCEPT_PAYMENT_SUCCESS,
  TRANSFERS_AMOUNT,
  CREATE_CUSTOMER,
  GET_VIDEO_ACCESS_OVERLAYS,
  GET_LATEST_VIDEOS,
  GET_STAFF_PICK,
  GET_VIDEOS_FEATURED,
  GET_VIDEOS_STAFF_PICK,
  GET_VIDEOS_BY_CATEGORY,
  INCREMENT_NOTIFICATION_COUNT,
  CLEAR_NOTIFICATIONS,
  GET_ALL_STORAGE,
  // GET_ALL_USER_SETTINGS,
  GET_USER_SETTING_BY_USERID,
  // GET_ALL_BADGES,
  LOGIN_FAILED,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  SIGNUP_EMAIL_EXIST,
  SIGNUP_USERNAME_EXIST,
  MORE_FROM_USER,
  GET_CATEGORY_BY_ID,
  GET_USER_BY_ID,
  GET_BADGES_BY_USERROLE,
  SET_EMAIL_EXISTS_FALSE,
  VISIT_STRIPE_CUSTOMER_PORTAL_REQUEST,
  VISIT_STRIPE_CUSTOMER_PORTAL_SUCCESS,
  GET_SCHEDULE_FAIL,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_FAIL,
  UPDATE_SCHEDULE_REQUEST,
  UPDATE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAIL,
  DELETE_SCHEDULE_REQUEST,
  DELETE_SCHEDULE_SUCCESS,
  GET_SINGLE_STORAGE,
  UPDATE_USER_SETTING,
  UPDATE_USER_SETTING_REQUEST,
  UNLOCK_WATCH_VIDEO,
  UNLOCK_FEATURED_VIDEO,
  UNLOCK_TRENDING_VIDEO,
  UNLOCK_LATEST_VIDEO,
  UNLOCK_STAFF_PICK_VIDEO,
  UNLOCK_CATEGORY_FEATURED_VIDEO,
  UNLOCK_CATEGORY_VIDEO,
  UNLOCK_CATEGORY_STAFF_PICK_VIDEO,
  UNLOCK_MORE_VIDEO,
  UNLOCK_LIKE_VIDEO,
  UNLOCK_SEARCH_VIDEO,
  UNLOCK_PROFILE_VIDEO,
  GET_USER_TRANSACTIONS,
  GET_USER_TRANSACTIONS_REQ,
  GET_USER_TRANSACTIONS_FAILED,
  GET_WARRIOR_SLIDER,
  GET_FEATURED_WARRIORS,
  SEND_CONTACT_US_EMAIL_FAILED,
  SEND_CONTACT_US_EMAIL_SUCCESS,
  SEND_CONTACT_US_EMAIL_REQ,
  RESET_CONTACT_US,
  GET_LIBRARY_VIDEOS,
  GET_MARKETING_BANNERS,
  UNLOCK_TAT_VIDEO,
  GET_KARMA_SENT_REQ,
  GET_KARMA_SENT_TRANSACTIONS,
  GET_KARMA_SENT_TRANSACTIONS_FAILED,
  REFERRAL_CODE_EXIST,
  CLEAR_MARKETING_BANNERS,

  // CHECK_EXPIRED_PW_RESET_TOKEN,
  PW_RESET_TOKEN_EXPIRED,
  CLEAR_RESET_PASSWORD_ERROR,
  NEW_CATEGORY_REQUEST,
  NEW_CATEGORY_SUCCESS,
  NEW_CATEGORY_FAIL,
  NEW_CONTENT_FLAG_FAIL,
  NEW_CONTENT_FLAG_REQUEST,
  NEW_CONTENT_FLAG_SUCCESS,
  GET_FLAG_TYPES_FAIL,
  GET_FLAG_TYPES_REQUEST,
  GET_FLAG_TYPES_SUCCESS,

  // playlist
  CREATE_NEW_PLAYLIST_SUCCESS,
  CREATE_NEW_PLAYLIST_FAIL,
  CREATE_NEW_PLAYLIST_REQUEST,
  GET_ALL_PLAYLIST_SUCCESS,
  GET_ALL_PLAYLIST_FAIL,
  GET_ALL_PLAYLIST_REQUEST,
  GET_SINGLE_PLAYLIST_SUCCESS,
  GET_SINGLE_PLAYLIST_FAIL,
  GET_SINGLE_PLAYLIST_REQUEST,
  DELETE_PLAYLIST_SUCCESS,
  DELETE_PLAYLIST_FAIL,
  DELETE_PLAYLIST_REQUEST,
  EDIT_PLAYLIST_SUCCESS,
  EDIT_PLAYLIST_FAIL,
  EDIT_PLAYLIST_REQUEST,
  ADD_VIDEO_TO_PLAYLIST_SUCCESS,
  ADD_VIDEO_TO_PLAYLIST_FAIL,
  ADD_VIDEO_TO_PLAYLIST_REQUEST,
  REMOVE_VIDEO_FROM_PLAYLIST_SUCCESS,
  REMOVE_VIDEO_FROM_PLAYLIST_FAIL,
  REMOVE_VIDEO_FROM_PLAYLIST_REQUEST,

  // series
  CREATE_NEW_SERIES_SUCCESS,
  CREATE_NEW_SERIES_FAIL,
  CREATE_NEW_SERIES_REQUEST,
  GET_ALL_SERIES_OF_USER_SUCCESS,
  GET_ALL_SERIES_OF_USER_FAIL,
  GET_ALL_SERIES_OF_USER_REQUEST,
  GET_SINGLE_SERIES_FAIL,
  GET_SINGLE_SERIES_REQUEST,
  GET_SINGLE_SERIES_SUCCESS,
  DELETE_SERIES_FAIL,
  DELETE_SERIES_REQUEST,
  DELETE_SERIES_SUCCESS,
  EDIT_SERIES_FAIL,
  EDIT_SERIES_REQUEST,
  EDIT_SERIES_SUCCESS,
  ADD_VIDEO_TO_SERIES_FAIL,
  ADD_VIDEO_TO_SERIES_REQUEST,
  ADD_VIDEO_TO_SERIES_SUCCESS,
  REMOVE_VIDEO_FROM_SERIES_FAIL,
  REMOVE_VIDEO_FROM_SERIES_REQUEST,
  REMOVE_VIDEO_FROM_SERIES_SUCCESS,
  DRAG_DROP_SERIES_VIDEOS,
  GET_ALL_SERIES_SUCCESS,
  GET_ALL_SERIES_FAIL,
  GET_ALL_SERIES_REQUEST,
  GET_ALL_PURCHASED_SERIES_REQUEST,
  GET_ALL_PURCHASED_SERIES_FAIL,
  GET_ALL_PURCHASED_SERIES_SUCCESS,

  // schedule
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_FAIL,
  CREATE_SCHEDULE_REQUEST,
  GET_CATEGORY_BY_CLASS_ID,

  // connections
  SEND_CONNECTION_REQUEST,
  SEND_CONNECTION_SUCCESS,
  SEND_CONNECTION_FAIL,
  GET_CONNECTIONS_SUCCESS,
  GET_CONNECTIONS_FAIL,
  GET_CONNECTIONS_REQUEST,
  APPROVE_CONNECTION_FAIL,
  APPROVE_CONNECTION_REQUEST,
  APPROVE_CONNECTION_SUCCESS,
  DECLINE_CONNECTION_FAIL,
  DECLINE_CONNECTION_REQUEST,
  DECLINE_CONNECTION_SUCCESS,
  GET_FRIENDS_SUCCESS,
  GET_FRIENDS_REQUEST,
  GET_CONNECTION_STATUS_REQUEST,
  GET_CONNECTION_STATUS_SUCCESS,
  GET_CONNECTION_STATUS_FAIL,
  REMOVE_CONNECTION_REQUEST,
  REMOVE_CONNECTION_SUCCESS,
  REMOVE_CONNECTION_FAIL,
  GET_KARMA_FILTER_SETTING_REQUEST,
  GET_KARMA_FILTER_SETTING_SUCCESS,
  GET_KARMA_FILTER_SETTING_FAILED,
  UPLOAD_MOMENT_SUCCESS,
  UPLOAD_MOMENT_REQUEST,
  UPLOAD_MOMENT_FAIL,
  UPDATE_MOMENT_FAIL,
  UPDATE_MOMENT_REQUEST,
  UPDATE_MOMENT_SUCCESS,
  DELETE_MOMENT_FAIL,
  DELETE_MOMENT_REQUEST,
  DELETE_MOMENT_SUCCESS,
  GET_SINGLE_MOMENT_FAIL,
  GET_SINGLE_MOMENT_REQUEST,
  GET_SINGLE_MOMENT_SUCCESS,
  GET_ALL_MOMENT_FAIL,
  GET_ALL_MOMENT_REQUEST,
  GET_ALL_MOMENT_SUCCESS,
  TAG_USER_IN_MOMENT_REQUEST,
  TAG_USER_IN_MOMENT_SUCCESS,
  TAG_USER_IN_MOMENT_FAIL,
  GET_TAGGED_USERS_OF_MOMENT_SUCCESS,
  GET_TAGGED_USERS_OF_MOMENT_REQUEST,
  GET_TAGGED_USERS_OF_MOMENT_FAIL,
  GET_MY_MOMENT_REQUEST,
  GET_MY_MOMENT_SUCCESS,
  GET_MY_MOMENT_FAIL,
  KARMA_RECEIVED_REQUEST,
  KARMA_RECEIVED_SUCCESS,
  KARMA_RECEIVED_FAILED,
  CREATE_PROFILE_ELEMENT_REQUEST,
  CREATE_PROFILE_ELEMENT_SUCCESS,
  CREATE_PROFILE_ELEMENT_FAIL,
  DELETE_PROFILE_ELEMENT_REQUEST,
  DELETE_PROFILE_ELEMENT_SUCCESS,
  DELETE_PROFILE_ELEMENT_FAIL,
  GET_PROFILE_ELEMENTS_REQUEST,
  GET_PROFILE_ELEMENTS_SUCCESS,
  GET_PROFILE_ELEMENTS_FAIL,
  DRAG_DROP_PROFILE_ELEMENTS,
  ADD_CONTENT_TO_ELEMENT,
  REMOVE_CONTENT_FROM_ELEMENT,
  CHAT_MESSAGE_FAIL,
  CHAT_MESSAGE_SUCCESS,
  SUBSCRIPTION_PLANS_REQUEST,
  SUBSCRIPTION_PLANS_SUCCESS,
  SUBSCRIPTION_PLANS_FAILED,
  UPDATE_SUBSCRIPTION_PLANS_REQUEST,
  UPDATE_SUBSCRIPTION_PLANS_SUCCESS,
  UPDATE_SUBSCRIPTION_PLANS_FAILED,
  UPDATE_PAYMET_METHOD_SUCCESS,
  UPDATE_PAYMET_METHOD_FAILED,
  UPDATE_USER_ROLE,
  UPDATE_USER_PAYMENT_METHOD,

  //chats
  GET_CHAT,
  POST_MESSAGE,
  GET_MY_BANNERS_REQUEST,
  ADD_NEW_CUSTOM_BANNER,
  REMOVE_CUSTOM_BANNER,
  UPDATE_USER_SETTINGS,
  ACTIVEUSER,
  GET_USER_VIDEOS_REQUEST,
  GET_USER_VIDEOS_SUCCESS,
  GET_USER_VIDEOS_FAIL,
  VIDEO_UPLOAD_FAIL,
  VIDEO_UPLOAD_SUCCESS,
  VIDEO_UPLOAD_REQUEST,
  VIDEO_UPDATE_REQUEST,
  VIDEO_UPDATE_SUCCESS,
  VIDEO_UPDATE_FAIL,
  VIDEO_DELETE_REQUEST,
  VIDEO_DELETE_SUCCESS,
  VIDEO_DELETE_FAIL,
  USER_STORAGE_INFO_REQUEST,
  USER_STORAGE_INFO_SUCCESS,
  USER_STORAGE_INFO_FAIL,
  CHATCOUNT,
  GET_FEATURED_SERIES_REQUEST,
  GET_FEATURED_SERIES_SUCCESS,
  GET_FEATURED_SERIES_FAIL,
  GET_VIDEOS_REQUEST,
  GET_VIDEOS_SUCCESS,
  GET_VIDEOS_FAIL,
  GET_VIDEOS_REQUESTV2,
  GET_VIDEOS_SUCCESSV2,
  GET_VIDEOS_FAILV2,
  GET_CHANNEL_STREAM_VIDEOS_REQUEST,
  GET_CHANNEL_STREAM_VIDEOS_SUCCESS,
  GET_CHANNEL_STREAM_VIDEOS_FAIL,
  GET_SERIES_PROGRESS_REQUEST,
  GET_SERIES_PROGRESS_FAIL,
  GET_SERIES_PROGRESS_SUCCESS,
  SHOW_FILTERED_VIDEOS,
  CLEAR_FILTERED_VIDEOS,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  PUSH_UPLOADED_VIDEOV2,
  CHANNEL_PROFILE,
  GET_NOTIFICATION_SETTING_REQUEST,
  GET_NOTIFICATION_SETTING_SUCCESS,
  GET_NOTIFICATION_SETTING_FAIL,
  UPDATE_NOTIFICATION_SETTING,
  GET_USER_STREAMS_REQUEST,
  GET_USER_STREAMS_SUCCESS,
  GET_USER_STREAMS_FAIL,
  GET_LIKED_MOMENTS_REQUEST,
  GET_LIKED_MOMENTS_SUCCESS,
  GET_LIKED_MOMENTS_FAIL,
  LOGIN_REQUEST,
  SIGNUP_REQUEST,
  GET_STREAMS_MOMENTS_REQUEST,
  GET_STREAMS_MOMENTS_SUCCESS,
  GET_STREAMS_MOMENTS_FAIL,
  GET_ALL_SERIES_OF_USER_SUCCESS_SECOND,
  GET_ALL_PLAYLIST_SUCCESS_SECOND,
  SHOW_CHANNEL_STREAM_FILTERED_VIDEOS,
  CLEAR_FILTERED_STREAM_VIDEOS,
  // Add additionl conent
  UNLOCK_ADDITIONAL_CONTENT,
  // Admin------
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_ADMIN_USER,
  FETCH_USER_DATA_FAILURE,
  FETCH_USER_DATA_SUCCESS,
  FETCH_VIDEO_DATA_SUCCESS,
  FETCH_VIDEO_DATA_FAILURE,
  FETCH_MOMENT_DATA_SUCCESS,
  FETCH_MOMENT_DATA_FAILURE,
  FETCH_SERIES_DATA_SUCCESS,
  FETCH_SERIES_DATA_FAILURE,
  FETCH_SERIES_VIDEO_DATA_SUCCESS,
  FETCH_SERIES_VIDEO_DATA_FAILURE,
  FETCH_NOTIFICATION_TYPE_SUCCESS,
  FETCH_NOTIFICATION_TYPE_FAILURE,
  FETCH_NOTIFICATION_CATEGORY_SUCCESS,
  FETCH_NOTIFICATION_CATEGORY_FAILURE,
  FETCH_VIDEO_CATEGORY_SUCCESS,
  FETCH_VIDEO_CATEGORY_FAILURE,
  ADD_VIDEO_CATEGORY_SUCCESS,
  ADD_VIDEO_CATEGORY_FAILURE,
  ADD_SUB_CATEGORY_ONE_SUCCESS,
  ADD_SUB_CATEGORY_ONE_FAILURE,
  FETCH_VIDEO_SUB_CATEGORY_SUCCESS,
  FETCH_VIDEO_SUB_CATEGORY_FAILURE,
  FETCH_SUB_CATEGORY_ONE_SUCCESS,
  FETCH_SUB_CATEGORY_ONE_FAILURE,
  FETCH_VIDEO_CATEGORY_REQUEST_FAILURE,
  FETCH_VIDEO_CATEGORY_REQUEST_SUCCESS,
  FETCH_SUB_CATEGORY_TWO_FAILURE,
  FETCH_SUB_CATEGORY_TWO_SUCCESS,
  FETCH_THREAD_IMAGES_TABLE_FAILURE,
  FETCH_THREAD_IMAGES_TABLE_SUCCESS,
  FETCH_THREADS_TABLE_FAILURE,
  FETCH_THREADS_TABLE_SUCCESS,
  FETCH_ADMIN_VISTOR_BADGE_FAILURE,
  FETCH_ADMIN_VISTOR_BADGE,
  ADD_VISTOR_BADGE_SUCCESS,
  FETCH_VIDEO_ACCESS_OVERLAY_FAILURE,
  FETCH_VIDEO_ACCESS_OVERLAY_SUCCESS,
  FETCH_ADMIN_BANNER_SLIDER,
  FETCH_ADMIN_BANNER_SLIDER_FAILURE,
  ADD_WIDGET_BANNER_SUCCESS,
  GET_HOME_FEATURED_CREATORS_REQUEST,
  GET_HOME_FEATURED_CREATORS_SUCCESS,
  GET_HOME_FEATURED_CREATORS_FAIL,
} from "./types";
import { toast } from "react-toastify";
import api, { setAuthHeader } from "../services/api";
import {
  addChannelLocalSt,
  removeChannelLocalSt,
  authenticate,
  whoisme,
} from "../utils";
import config from "../config";
import { notificationMessage } from "../utils/toastNotifications";
import axios from "axios";
const creatUserSettings = async (payload) => {
  try {
    const { data } = await api.post(`${config.api.url}userSettings`, payload);

    if (data) {
      toast.success(notificationMessage.welcomeFreeloader);
    }
  } catch (e) {}
};

export const isEmailExist = (email) => async (dispatch) => {
  const res = await api.get(`auth/isEmailExist?email=${email}`);
  dispatch({ type: SIGNUP_EMAIL_EXIST, isEmailExist: res });
  return res;
};

export const isUserNameExist = (userName) => async (dispatch) => {
  const res = await api.get(`auth/isUserNameExist?userName=${userName}`);
  dispatch({ type: SIGNUP_USERNAME_EXIST, isEmailExist: res });
  return res;
};

export const isReferralCodeExist = (referralCode) => async (dispatch) => {
  const res = await api.get(
    `auth/isReferralCodeExist?referralCode=${referralCode}`
  );
  dispatch({ type: REFERRAL_CODE_EXIST, referralCode: res });
  return res;
};

export const signupUser =
  (payload, userSettingPayload, history) => async (dispatch) => {
    dispatch({
      type: SIGNUP_REQUEST,
    });

    const user = await authenticate("signup", payload);

    setAuthHeader();
    if (user && payload.code) {
      let refAmt = 0;
      if (user.userrole === 2) refAmt = 2.5;
      if (user.userrole === 1) refAmt = 2.5;
      if (user.userrole) {
        toast.success(
          `$${refAmt} of your membership was just sent to ${payload.code}. Thank you so much! 🙏`
        );
      }
    }

    // create user settings here
    userSettingPayload.userId = user?.id;
    await creatUserSettings(userSettingPayload, history);
    // const { data } = await api.get(`userSettings/userId/${user.id}`);

    if (user) {
      userSettingPayload.userId = user.id;
      const { data } = await api.get(`userSettings/userId/${user.id}`);
      const badge = data.userSetting[0].VisitorBadge.imgPath;
      user["badge"] = badge;

      await api.post("users/activeUser", user);

      dispatch({ type: SIGNUP, payload: user });
    }
  };

export const whoismeaction = () => async (dispatch) => {
  const user = await whoisme();

  if (user) {
    dispatch({ type: LOGIN, payload: user });
  }
};

export const loginUserErrorMsgRefresh = () => async (dispatch) => {
  dispatch({
    type: LOGIN_FAILED,
    payload: {
      isLogin: true,
    },
  });
};

export const loginUser = (payload) => async (dispatch) => {
  dispatch({
    type: LOGIN_REQUEST,
  });

  const user = await authenticate("login", payload);

  setAuthHeader();
  if (user) {
    await api.post("users/activeUser", user);
    dispatch({ type: LOGIN, payload: user });
  } else {
    sessionStorage.setItem("isTATVideoUnlocked", true);
    dispatch({
      type: LOGIN_FAILED,
      payload: "Incorrect information provided. Please try again!",
    });
  }
  return user;
};

export const getAllActiveUser = () => async (dispatch) => {
  const allactiveuser = await api.post("users/getActiveUser");
  dispatch({ type: ACTIVEUSER, payload: allactiveuser.data });
};

export const loginWithGoogle = (payload) => async (dispatch) => {
  const user = await authenticate("login/google", payload);
  setAuthHeader();
  if (user) {
    dispatch({ type: LOGIN, payload: user });
  } else {
    dispatch({
      type: LOGIN_FAILED,
      payload: {
        isLogin: false,
      },
    });
  }
  return user;
};

export const loginWithFacebook = (payload) => async (dispatch) => {
  const user = await authenticate("login/facebook", payload);
  setAuthHeader();
  if (user) {
    dispatch({ type: LOGIN, payload: user });
  } else {
    dispatch({
      type: LOGIN_FAILED,
      payload: {
        isLogin: false,
      },
    });
  }
  return user;
};

export const logoutUser = () => async (dispatch) => {
  const user = localStorage.getItem("user");
  const data = {
    user: user,
    status: false,
  };
  if (user) {
    await api.post("users/status", data);
  }

  localStorage.removeItem("user");
  localStorage.removeItem("lastUserRole");

  dispatch({
    type: LOGOUT,
  });
};

export const updateStatus = (user, status) => async (dispatch) => {
  const data = {
    user: JSON.stringify(user),
    status: status,
  };
  const updateStatusData = await api.post("users/status", data);

  return updateStatusData.data;
};

export const getRecommendations = () => async (dispatch) => {
  const res = await api.get("videos");

  dispatch({
    type: GET_RECOMMENDATIONS,
    payload: {
      isFetching: false,
      videos: res.data.data,
    },
  });
};

export const getFeatured = () => async (dispatch) => {
  const res = await api.get("videos?featured=true");
  dispatch({
    type: GET_VIDEOS_SUCCESS,
    payload: res.data.data,
  });
};
export const getFeaturedVideosForSelectedCategories =
  (categoryList) => async (dispatch) => {
    const res = await api.post("videos/featured", {
      categoryList,
      featured: true,
    });
    dispatch({
      type: GET_FEATURED,
      payload: {
        featuredFetching: false,
        featuredVideos: res.data.data,
      },
    });
  };

export const getStaffPick = () => async (dispatch) => {
  const res = await api.get("videos/staffPick");
  const videos = res.data.data;

  dispatch({
    type: GET_STAFF_PICK,
    payload: {
      isFetching: false,
      videos,
      success: res.data.success,
    },
  });
};

export const getTrending = () => async (dispatch) => {
  const res = await api.get("videos");

  const videos = res.data.data;
  videos.sort((a, b) => b.views - a.views);

  dispatch({
    type: GET_TRENDING,
    payload: {
      isFetching: false,
      videos,
    },
  });
};

export const getLatest = (user) => async (dispatch) => {
  const res = await api.get(`videos?userId=${user ? user.id : -1}`);

  const videos = res.data.data;
  videos.sort((a, b) => b.createdAt - a.createdAt);

  dispatch({
    type: GET_LATEST_VIDEOS,
    payload: {
      isFetching: false,
      videos,
    },
  });
  dispatch({
    type: GET_VIDEOS_SUCCESS,
    payload: videos,
  });
};
export const getVideos = (user, selectedCategory) => async (dispatch) => {
  // featured Videos

  let url =
    selectedCategory && selectedCategory.length
      ? `videos?featured=true&videoCategoryId=${selectedCategory[0].videoCategoryId}&subCategOneId=${selectedCategory[0].subCategOneId}&subCategTwoId=${selectedCategory[0].subCategTwoId}`
      : `videos?featured=true`;
  await api
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_FEATURED,
        payload: {
          featuredFetching: false,
          featuredVideos: res.data.data,
        },
      });
    })
    // .then(async () => {
    //   //fetch trending
    //   await api.get("videos").then((res) => {
    //     const trendingVideos = res.data.data;
    //     trendingVideos.sort((a, b) => b.views - a.views);

    //     dispatch({
    //       type: GET_TRENDING,
    //       payload: {
    //         isFetching: false,
    //         videos: trendingVideos,
    //       },
    //     });
    //   });
    // })
    .then(async () => {
      // fetch latest

      await api.get(`videos?userId=${user ? user.id : "-1"}`).then((res) => {
        const latestVideos = res.data.data;
        latestVideos.sort((a, b) => b.createdAt - a.createdAt);

        dispatch({
          type: GET_LATEST_VIDEOS,
          payload: {
            isFetching: false,
            videos: latestVideos,
          },
        });
        dispatch({
          type: GET_VIDEOS_SUCCESS,
          payload: latestVideos,
        });
      });
    })
    .catch((err) => {});
};
export const fetchMuxVideo = async (muxid) => {
  try {
    const response = await axios.get(
      `${config.api.url}videos/getMuxVideo?muxid=${muxid}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const findLatest = () => async (dispatch) => {
  await api
    .get("videos")
    .then((res) => {
      const findLatest = res.data.data;
      findLatest.sort((a, b) => b.createdAt - a.createdAt);
      dispatch({
        type: GET_VIDEOS_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {});
};

export const filterVideos = (categoryArray, user) => async (dispatch) => {
  await api
    .post("videos/filterVideos", {
      data: categoryArray,
      userId: user ? user.id : "-1",
    })
    .then((res) => {
      dispatch({
        type: GET_VIDEOS_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {});
};

export const getTrendingVideos = () => async (dispatch) => {
  const res = await api.get("videos");

  const videos = res.data.data;
  videos.sort((a, b) => b.views - a.views);

  dispatch({
    type: GET_VIDEOS_SUCCESS,
    payload: res.data.data,
  });
};

export const getStaffPickVideos = () => async (dispatch) => {
  const res = await api.get("videos/staffPick");

  const videos = res.data.data;
  videos.sort((a, b) => b.views - a.views);

  dispatch({
    type: GET_VIDEOS_SUCCESS,
    payload: res.data.data,
  });
};

export const getFeaturedByCategory = (id) => async (dispatch) => {
  const res = await api.get(`videos/featured/${id}`);
  dispatch({
    type: GET_VIDEOS_FEATURED,
    payload: {
      isFetching: false,
      featuredVideos: res.data.data,
    },
  });
};

export const getStaffPickByCategory = (id) => async (dispatch) => {
  const res = await api.get(`videos/staffPick/${id}`);
  dispatch({
    type: GET_VIDEOS_STAFF_PICK,
    payload: {
      isFetching: false,
      staffPick: res.data.data,
    },
  });
};

export const getVideosByCategory = (id) => async (dispatch) => {
  const res = await api.get(`videos/getVideosByCategory/${id}`);
  dispatch({
    type: GET_VIDEOS_BY_CATEGORY,
    payload: {
      isFetching: false,
      videos: res.data.data,
    },
  });
};

export const getChannelRecommendations = (data) => async (dispatch) => {
  const res = await api.get("users", data);

  dispatch({
    type: GET_CHANNEL_RECOMMENDATIONS,
    payload: {
      isFetching: false,
      channels: res.data.data,
    },
  });
};

export const getFeed = () => async (dispatch) => {
  const res = await api.get("users/feed");

  dispatch({
    type: GET_FEED,
    payload: {
      isFetching: false,
      videos: res.data.data,
    },
  });
};

export const getVideo = (videoId, userId) => async (dispatch) => {
  try {
    const res = await api.get(`videos/${videoId}/${userId ? userId : -1}`);

    dispatch({
      type: GET_VIDEO,
      payload: {
        isFetching: false,
        ...res.data.data,
      },
    });

    dispatch({
      type: UNLOCK_ADDITIONAL_CONTENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SHOW_NOT_FOUND,
    });
  }
};

export const getUserStreams = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_STREAMS_REQUEST,
    });

    const res = await api.get(`subscriptionPay/subscribetostreams/${userId}`);

    dispatch({
      type: GET_USER_STREAMS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_STREAMS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const clearVideo = () => ({ type: CLEAR_VIDEO });

export const addComment =
  ({ videoId, text, parentId }) =>
  async (dispatch) => {
    const response = await api.post(`videos/${videoId}/comment`, {
      text: text ? text.trim() : "",
      parentId: parentId !== "" ? parentId : null,
    });
    return dispatch({
      type: ADD_COMMENT,
      payload: response.data.data,
    });
  };

export const addCommentToStore = (data) => (dispatch) => {
  return dispatch({
    type: ADD_COMMENT,
    payload: data,
  });
};

export const getProfile = (userId) => async (dispatch) => {
  try {
    setAuthHeader();
    api.get(`users/${userId}`).then(async (res) => {
      const profile = res.data.data;

      const introVideoRes = await api.post(`videos/getIntroVideo`, {
        userId: profile?.id,
      });

      if (introVideoRes?.data?.success === true) {
        profile.introVideo = introVideoRes?.data?.data;
      } else {
        throw new Error();
      }

      dispatch({
        type: GET_PROFILE,
        payload: {
          isFetching: false,
          ...profile,
        },
      });
    });
  } catch (err) {
    dispatch({
      type: SHOW_NOT_FOUND,
    });
  }
};

export const getVideoInformation = (userId) => async (dispatch) => {
  try {
    const res = await api.post(`users/videosInformation/${userId}`);
  } catch (e) {}
};

export const clearProfile = () => ({ type: CLEAR_PROFILE });

export const updateProfile = (data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PROFILE_REQUEST,
    });
    dispatch({
      type: UPDATE_PROFILE,
      payload: data,
    });

    const res = await api.put("users", data);

    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserSettings = (id, data) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_SETTINGS,
    payload: data,
  });

  await api.patch(`userSettings/${id}`, data);
};

export const getNotificationCategories = (userRole) => async (dispatch) => {
  const res = await api.get(`notifications/categories/${userRole}`);
  dispatch({
    type: GET_NOTIFICATIONS_CATEGORIES,
    payload: {
      isFetching: false,
      notificationData: res.data.data,
    },
  });
};

export const updateNotificationStatus =
  ({ categoryId, data, profileState }) =>
  async (dispatch) => {
    const response = await api.put(
      `users/update-notification/${categoryId}`,
      data
    );
    dispatch({
      type: UPDATE_NOTIFICATION_SETTING,
      payload: response.data.data,
    });
  };

export const updateGlobalBellNotificationStatus =
  ({ data, profileState }) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_PROFILE,
      payload: profileState,
    });
    await api.put(`users/setAllGlobalNotificationsOfUser`, data);
  };

export const updateGlobalEmailNotificationStatus =
  ({ data, profileState }) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_PROFILE,
      payload: profileState,
    });
    await api.put(`users/setAllGlobalNotificationsOfUser`, data);
  };

export const getNotificationData = (limit) => async (dispatch) => {
  dispatch({
    type: SET_FETCHING,
  });

  const res = await api.get(`notifications?limit=${limit}`);

  dispatch({
    type: GET_NOTIFICATIONS,
    payload: {
      isFetching: false,
      ...res.data.data,
    },
  });
};

export const readNotification =
  ({ index, notificationId, data }) =>
  async (dispatch) => {
    dispatch({
      type: NOTIFICATIONS_READ_COUNT,
      payload: index,
    });
    await api.put(`notifications/mark-read/${notificationId}`, data);
  };

export const markAllReadNotification = (notification) => async (dispatch) => {
  try {
    notification.notifications.rows.forEach((element) => {
      if (!element.readstatus) {
        element.readstatus = true;
      }
    });
    notification["counter"] = 0;

    await api.get("notifications/mark-all-read");

    dispatch({
      type: GET_NOTIFICATIONS,
      payload: {
        isFetching: false,
        ...notification,
      },
    });
  } catch (err) {}
};

export const getNotificationCommentId = (index) => async (dispatch) => {
  dispatch({
    type: GET_NOTIFICATION_COMMENT_ID,
    payload: index,
  });
};

export const clearCommentId = () => async (dispatch) => {
  dispatch({
    type: CLEAR_COMMENT_ID,
  });
};

export const updateCoverPhoto = (data) => async (dispatch) => {
  const res = await api.put("users/editCoverPhoto", data);

  dispatch({
    type: UPDATE_PROFILE,
    payload: res.data.data,
  });
};

export const updateAvatar = (data) => async (dispatch) => {
  const res = await api.put("users/editAvatar", data);

  dispatch({
    type: UPDATE_PROFILE,
    payload: res.data.data,
  });
};

export const updateVideo = (videoId, data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_VIDEO,
      payload: data,
    });

    await api.put(`videos/${videoId}`, data);
  } catch (err) {}
};

export const deleteVideo = (videoId) => async (dispatch) => {
  try {
    const { data } = await api.delete(`videos/${videoId}`);

    dispatch({
      type: DELETE_VIDEO,
      payload: data,
    });
  } catch (err) {}
};

export const deleteVideoV2 = (videoId) => async (dispatch) => {
  try {
    dispatch({
      type: VIDEO_DELETE_REQUEST,
    });

    const res = await api.delete(`videos/${videoId}`);

    dispatch({
      type: VIDEO_DELETE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: VIDEO_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteVideoS3 = (filename) => async (dispatch) => {
  try {
    const { data } = await api.delete(`fileoperation/deleteFile/${filename}`);

    dispatch({
      type: DELETE_VIDEO_S3,
      payload: data,
    });
  } catch (err) {}
};

export const getSearchResults = (searchterm) => async (dispatch) => {
  const userRes = await api.get(`users/search?searchterm=${searchterm}`);
  const videoRes = await api.get(`videos/search?searchterm=${searchterm}`);

  dispatch({
    type: GET_SEARCH_RESULTS,
    payload: {
      isFetching: false,
      users: userRes.data.data,
      videos: videoRes.data.data,
    },
  });
};

export const clearSearchResults = () => ({ type: CLEAR_SEARCH_RESULTS });
export const subscribeChannel =
  ({ channel, type }) =>
  async (dispatch) => {
    await api.get(`users/${channel.id}/togglesubscribe`);

    dispatch({
      type,
      payload: channel,
    });

    addChannelLocalSt(channel);

    dispatch({
      type: ADD_CHANNEL,
      payload: channel,
    });
    toast.success(`${channel.username} ${notificationMessage.addToStream}`);
  };

export const getSubscriberIds = (userId) => async (dispatch) => {
  await api
    .get(`subscriptionPay/subscribetostreams/${userId}`)
    .then((res) => {
      const videos = res.data.data;
      videos.sort((a, b) => b.createdAt - a.createdAt);
      dispatch({
        type: GET_CHANNEL_STREAM_VIDEOS,
        payload: {
          isFetching: false,
          videos: videos,
        },
      });
    })
    .catch((err) => {});
};

export const getStreamsMoments = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_STREAMS_MOMENTS_REQUEST,
    });

    const res = await api.post(`subscriptionPay/getStreamsMoments`, obj);

    dispatch({
      type: GET_STREAMS_MOMENTS_SUCCESS,
      payload: {
        moments: res.data.data.moments,
        hasMore: res.data.data.hasMore,
        isFiltering: res.data.data.filtering,
      },
    });
  } catch (error) {
    dispatch({
      type: GET_STREAMS_MOMENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const checkAddToStram = (channelName, userId) => async (dispatch) => {
  const res = await api.get(
    `subscriptionPay/checkSubscription/${channelName}/${userId}`
  );
  dispatch({
    type: ISCHANNEL_SUBSCRIBED,
    payload: {
      isFetching: false,
      isSubscribe: res.data.data.isSubscribe,
    },
  });
};

export const SubscribeNewChannel =
  (channelName, userId) => async (dispatch) => {
    const data = {
      channelName: channelName,
      userId: userId,
    };
    const res = await api.post("subscriptionPay/subscribetostream", data);
    const restwo = await api.get(
      `subscriptionPay/checkSubscription/${channelName}/${userId}`
    );

    dispatch({
      type: ISCHANNEL_SUBSCRIBED,
      payload: {
        isFetching: false,
        isSubscribe: restwo.data.data.isSubscribe,
      },
    });
    toast.success(`${channelName} ${notificationMessage.addToStream}`);
  };

export const unsubscribeChannel =
  ({ username, channelId, type }) =>
  async (dispatch) => {
    await api.get(`users/${channelId}/togglesubscribe`);
    dispatch({
      type,
      payload: channelId,
    });
    removeChannelLocalSt(channelId);
    dispatch({
      type: REMOVE_CHANNEL,
      payload: channelId,
    });
    toast.success(`${username} ${notificationMessage.removeFromStream}`);
  };

export const likeVideo = (video) => async (dispatch) => {
  dispatch({
    type: LIKE,
  });

  dispatch({
    type: ADD_TO_LIKED_VIDEOS,
    payload: video,
  });

  await api.get(`videos/${video.id}/like`);
};

export const cancelLike = (videoId) => async (dispatch) => {
  dispatch({
    type: CANCEL_LIKE,
  });

  dispatch({
    type: REMOVE_FROM_LIKED_VIDEOS,
    payload: videoId,
  });

  await api.get(`videos/${videoId}/like`);
};

export const dislikeVideo = (videoId) => async (dispatch) => {
  dispatch({
    type: DISLIKE,
  });

  await api.get(`videos/${videoId}/dislike`);
};

export const cancelDislike = (videoId) => async (dispatch) => {
  dispatch({
    type: CANCEL_DISLIKE,
  });

  await api.get(`videos/${videoId}/dislike`);
};

export const getLikedVideos = () => async (dispatch) => {
  const res = await api.get("users/likedVideos");

  dispatch({
    type: GET_LIKED_VIDEOS,
    payload: {
      isFetching: false,
      videos: res.data.data,
    },
  });
};

export const updateUser = (data) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const updatedUser = { ...user, ...data };

  localStorage.setItem("user", JSON.stringify(updatedUser));

  dispatch({
    type: UPDATE_USER,
    payload: data,
  });
};

export const uploadVideo = (video) => async (dispatch) => {
  const res = await api.post("videos", video);
  const { id, avatar, username } = JSON.parse(localStorage.getItem("user"));
  const newVideo = res.data.data;
  newVideo.views = 0;
  newVideo.User = { id, avatar, username };
  dispatch({ type: ADD_TO_RECOMMENDATIONS, payload: newVideo });
};

export const uploadVideoV2 = (video) => async (dispatch) => {
  try {
    dispatch({
      type: VIDEO_UPLOAD_REQUEST,
    });

    const res = await api.post("videos", video);

    const { id, avatar, username, badge } = JSON.parse(
      localStorage.getItem("user")
    );
    const newVideo = res.data.data;
    newVideo.views = 0;
    newVideo.User = { id, avatar, username, badge };

    dispatch({
      type: VIDEO_UPLOAD_SUCCESS,
      payload: newVideo,
    });

    dispatch({
      type: PUSH_UPLOADED_VIDEOV2,
      payload: { video: newVideo },
    });

    dispatch({ type: ADD_TO_RECOMMENDATIONS, payload: newVideo });
  } catch (error) {
    dispatch({
      type: VIDEO_UPLOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateVideoV2 = (videoId, data) => async (dispatch) => {
  try {
    dispatch({
      type: VIDEO_UPDATE_REQUEST,
    });
    console.log(data.selectedFiles);

    const res = await api.put(`videos/${videoId}`, data);

    dispatch({
      type: VIDEO_UPDATE_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: VIDEO_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getHistory = () => async (dispatch) => {
  const res = await api.get("users/history");

  dispatch({
    type: GET_HISTORY,
    payload: {
      isFetching: false,
      videos: res.data.data,
    },
  });
};

export const addToHistory = (video) => async (dispatch) => {
  dispatch({
    type: ADD_TO_HISTORY,
    payload: video,
  });

  await api.post(`videos/${video.id}/view`);
};

export const clearNotFound = () => ({ type: CLEAR_NOT_FOUND });
export const openSidebar = () => ({ type: OPEN_SIDEBAR });
export const closeSidebar = () => ({ type: CLOSE_SIDEBAR });

export const getVideoCategories = () => async (dispatch) => {
  const res = await api.get("videos/category");
  dispatch({
    type: GET_VIDEO_CATEGORIES,
    payload: {
      videoCategories: res.data.data,
    },
  });
};

export const getHashtags = () => async (dispatch) => {
  const res = await api.get("videos/hashtag");
  dispatch({
    type: GET_HASHTAGS,
    payload: {
      videoHashtags: res.data.data,
    },
  });
};

export const stripeOnboard = (payload) => async (dispatch) => {
  const res = await api.post(`stripe/stripeOnboarding`, { payload });

  if (res) {
    dispatch({
      type: STRIPE_ONBOARD,
      payload: res.data.data.payload,
    });
    window.location = "/";
  }
};

export const acceptPay = (payload) => async (dispatch) => {
  const res = await api.post(`stripe/secret`, { payload });

  dispatch({
    type: ACCEPT_PAYMENT_SUCCESS,
    payload: res.data.data,
  });
  return res;
};
export const transfers = (payload) => async (dispatch) => {
  const res = await api.post(`stripe/transfers`, { payload });
  dispatch({
    type: INCREMENT_NOTIFICATION_COUNT,
  });
  dispatch({
    type: TRANSFERS_AMOUNT,
    payload: res.data.data.amount,
  });
};

export const seriesTransfers = (payload) => async (dispatch) => {
  const res = await api.post(`stripe/seriesTransfers`, { payload });

  dispatch({
    type: TRANSFERS_AMOUNT,
    payload: res.data.data.amount,
  });
};

export const createCustomer = (payload) => async (dispatch) => {
  const res = await api.post(`subscriptionPay`, { payload });

  dispatch({
    type: CREATE_CUSTOMER,
    payload: res,
  });
};

export const createLiveSchedule = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SCHEDULE_REQUEST,
    });
    const res = await api.post(`liveClassSchedule`, payload);

    if (res.status == 200)
      toast.success(notificationMessage.addedClassSchedule);
    dispatch({
      type: CREATE_SCHEDULE_SUCCESS,
      payload: res.data,
    });
    dispatch(getLiveScheduleByMonth(payload.month));
  } catch (error) {
    toast.error(notificationMessage.failedClassSchedule);
    dispatch({
      type: CREATE_SCHEDULE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getLiveScheduleByMonth = (month) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SCHEDULE_REQUEST,
    });
    const res = await api.get(`liveClassSchedule/${month}`);

    if (res.status == 200)
      toast.success(notificationMessage.fetchLiveClassSchedule);
    dispatch({
      type: GET_SCHEDULE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SCHEDULE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateScheduleById = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SCHEDULE_REQUEST,
    });
    const res = await api.patch(`liveClassSchedule/${id}`, data);

    if (res.status == 200)
      toast.success(notificationMessage.updatedClassSchedule);
    dispatch({
      type: UPDATE_SCHEDULE_SUCCESS,
      payload: res.data,
    });
    dispatch(getLiveScheduleByMonth(data.month));
  } catch (error) {
    dispatch({
      type: UPDATE_SCHEDULE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteScheduleById = (id, month) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_SCHEDULE_REQUEST,
    });
    const res = await api.delete(`liveClassSchedule/${id}`);

    if (res.status == 200)
      toast.success(notificationMessage.deleteLiveClassSchedule);
    dispatch({
      type: DELETE_SCHEDULE_SUCCESS,
      payload: res.data,
    });
    dispatch(getLiveScheduleByMonth(month));
  } catch (error) {
    dispatch({
      type: DELETE_SCHEDULE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getVideoAccessOverlays = () => async (dispatch) => {
  const res = await api.get("videos/videoAccessOverlays");
  dispatch({
    type: GET_VIDEO_ACCESS_OVERLAYS,
    payload: {
      isFetching: false,
      videoAccessOverlays: res.data.data,
    },
  });
};

export const incrementNotificationCount = () => async (dispatch) => {
  dispatch({
    type: INCREMENT_NOTIFICATION_COUNT,
  });
};

// export const userStatus = () => async (dispatch) => {
//   dispatch({
//     type: ACTIVEUSER
//   })
// }

export const clearNotifications = () => async (dispatch) => {
  dispatch({
    type: CLEAR_NOTIFICATIONS,
  });
};

export const getAllStorage = () => async (dispatch) => {
  const res = await api.get("storage/get-all");
  const storages = res.data.data;

  dispatch({
    type: GET_ALL_STORAGE,
    payload: {
      isFetching: false,
      storages,
    },
  });
};

export const getSingleStorage = (id) => async (dispatch) => {
  if (!id) {
    return null;
  }
  const res = await api.get(`storage/${id}`);
  const storage = res.data.storage;

  dispatch({
    type: GET_SINGLE_STORAGE,
    payload: storage,
  });
};

export const getUserSettingByUserId = (userId) => async (dispatch) => {
  if (userId) {
    const res = await api.get(`userSettings/userId/${userId}`);
    dispatch({
      type: GET_USER_SETTING_BY_USERID,
      payload: res.data.userSetting[0],
    });
  }
};

export const updateUserSettingById = (id, data) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_SETTING_REQUEST,
  });

  const res = await api.patch(`userSettings/${id}`, data);
  dispatch({
    type: UPDATE_USER_SETTING,
    payload: res.data,
  });
};

export const moreFromUser = (id) => async (dispatch) => {
  const res = await api.get(`users/userVideos/${id}`);
  dispatch({
    type: MORE_FROM_USER,
    payload: res.data.data,
  });
};

export const verifyEmail = (email_verify_token) => async (dispatch) => {
  const user = await api.post("users/verifyEmail", { email_verify_token });
  if (user.data.success) {
    await localStorage.setItem("user", JSON.stringify(user.data.data));
    dispatch({
      type: UPDATE_USER,
      payload: user.data.data,
    });
  }
};

export const resetPasswordRequest = (email) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });
    const res = await api.post("users/forgot_password", { email });
    if (res.data.success) {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: {
          emailExists: true,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: RESET_PASSWORD_FAILED,
      payload: {
        error: "This Email does not exists",
      },
    });
  }
};

export const changePasswordRequest =
  (email, password, password_reset_token) => async (dispatch) => {
    try {
      dispatch({
        type: CHANGE_PASSWORD_REQUEST,
      });
      const res = await api.post("users/reset_password", {
        email,
        password,
        password_reset_token,
      });
      if (res.data.success) {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          payload: {
            isPasswordChanged: true,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: CHANGE_PASSWORD_FAILED,
        payload: {
          error: "This Email does not exists",
        },
      });
    }
  };
export const getCategoryByID = (id) => async (dispatch) => {
  const res = await api.get(`videos/category/${id}`);
  dispatch({
    type: GET_CATEGORY_BY_ID,
    payload: res.data.category,
  });
};

export const getCategoryByClassId = (id) => async (dispatch) => {
  const res = await api.get(`liveClassSchedule/getCategoryById/${id}`);
  dispatch({
    type: GET_CATEGORY_BY_CLASS_ID,
    payload: res.data.videoSubCategories,
  });
};

export const getUserById = (id) => async (dispatch) => {
  const res = await api.get(`users/${id}`);
  dispatch({
    type: GET_USER_BY_ID,
    payload: res.data.data,
  });
};

export const getBadgesByUserrole = (type) => async (dispatch) => {
  const res = await api.get(`badge/${type}`);
  dispatch({
    type: GET_BADGES_BY_USERROLE,
    payload: res.data.badges,
  });
};

export const setEmailExistsFalse = () => async (dispatch) => {
  dispatch({
    type: SET_EMAIL_EXISTS_FALSE,
  });
};
export const visitStripeCustomerPortal =
  (userId, stripe_customer_id) => async (dispatch) => {
    try {
      dispatch({
        type: VISIT_STRIPE_CUSTOMER_PORTAL_REQUEST,
      });
      const res = await api.post("stripe/customerPortal", {
        stripe_customer_id,
        userId,
      });
      if (res.status === 200) {
        dispatch({
          type: VISIT_STRIPE_CUSTOMER_PORTAL_SUCCESS,
          payload: {
            portal_url: res.data.data.url,
          },
        });
      }
    } catch (err) {}
  };

export const savePPVUnlockInformation = (payload) => async (dispatch) => {
  try {
    await api.post(`videos/savePPVUnlockInformation`, payload);
  } catch (err) {}
};

export const unlockWatchVideo = (videoId) => (dispatch) => {
  try {
    dispatch({
      type: UNLOCK_WATCH_VIDEO,
      payload: { videoId },
    });
  } catch (err) {}
};

export const saveTATVUnlockInfo = (payload) => async (dispatch) => {
  try {
    await api.post(`videos/saveTipAfterTwoInfo`, payload);
  } catch (err) {}
};

export const getSaveTATVUnlockInfo = (videoId, userId) => async (dispatch) => {
  try {
    const res = await api.get(
      `videos/getSaveTipAfterTwoInfo/${videoId}/${userId}`
    );

    return res;
  } catch (err) {}
};

export const unlockTATVideo = (videoId) => (dispatch) => {
  try {
    dispatch({
      type: UNLOCK_TAT_VIDEO,
      payload: {
        videoId,
      },
    });
  } catch (err) {}
};

export const unlockFeaturedVideo = (videoId) => (dispatch) => {
  try {
    dispatch({
      type: UNLOCK_FEATURED_VIDEO,
      payload: { videoId },
    });
  } catch (err) {}
};

export const unlockCategoryFeaturedVideo = (videoId) => (dispatch) => {
  try {
    dispatch({
      type: UNLOCK_CATEGORY_FEATURED_VIDEO,
      payload: { videoId },
    });
  } catch (err) {}
};

export const unlockTrendingVideo = (videoId) => (dispatch) => {
  try {
    dispatch({
      type: UNLOCK_TRENDING_VIDEO,
      payload: { videoId },
    });
  } catch (err) {}
};

export const unlockLatestVideo = (videoId) => (dispatch) => {
  try {
    dispatch({
      type: UNLOCK_LATEST_VIDEO,
      payload: { videoId },
    });
  } catch (err) {}
};

export const unlockStaffPickVideo = (videoId) => (dispatch) => {
  try {
    dispatch({
      type: UNLOCK_STAFF_PICK_VIDEO,
      payload: { videoId },
    });
  } catch (err) {}
};

export const unlockCategoryVideo = (videoId) => (dispatch) => {
  try {
    dispatch({
      type: UNLOCK_CATEGORY_VIDEO,
      payload: { videoId },
    });
  } catch (err) {}
};

export const unlockCategoryStaffPickVideo = (videoId) => (dispatch) => {
  try {
    dispatch({
      type: UNLOCK_CATEGORY_STAFF_PICK_VIDEO,
      payload: { videoId },
    });
  } catch (err) {}
};

export const unlockMoreVideo = (videoId) => (dispatch) => {
  try {
    dispatch({
      type: UNLOCK_MORE_VIDEO,
      payload: { videoId },
    });
  } catch (err) {}
};

export const unlockLikeVideo = (videoId) => (dispatch) => {
  try {
    dispatch({
      type: UNLOCK_LIKE_VIDEO,
      payload: { videoId },
    });
  } catch (err) {}
};

export const unlockSearchVideo = (videoId) => (dispatch) => {
  try {
    dispatch({
      type: UNLOCK_SEARCH_VIDEO,
      payload: { videoId },
    });
  } catch (err) {}
};

export const unlockProfileVideo = (videoId) => (dispatch) => {
  try {
    dispatch({
      type: UNLOCK_PROFILE_VIDEO,
      payload: { videoId },
    });
  } catch (err) {}
};

export const getUserTransactions = (offset) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_TRANSACTIONS_REQ,
    });
    let pageOffset;
    if (!offset) {
      pageOffset = 0;
    } else {
      pageOffset = offset;
    }
    const res = await api.get(`stripe/transactions/${pageOffset}`);
    dispatch({
      type: GET_USER_TRANSACTIONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_TRANSACTIONS_FAILED,
    });
  }
};

export const getKarmaSentByUser =
  (offset = 0) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_KARMA_SENT_REQ,
      });
      const res = await api.get(`stripe/karmaSent/${offset}`);
      dispatch({
        type: GET_KARMA_SENT_TRANSACTIONS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_KARMA_SENT_TRANSACTIONS_FAILED,
      });
    }
  };
export const getKarmaReceivedByUser =
  (offset = 0) =>
  async (dispatch) => {
    try {
      dispatch({
        type: KARMA_RECEIVED_REQUEST,
      });
      const res = await api.get(`stripe/karmaReceived/${offset}`);
      dispatch({
        type: KARMA_RECEIVED_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: KARMA_RECEIVED_FAILED,
      });
    }
  };

export const getWarriorSlider = () => async (dispatch) => {
  const res = await api.get(`users/warrior_banner`);

  dispatch({
    type: GET_WARRIOR_SLIDER,
    payload: res.data.data,
  });
};

export const getMarketingBanners = (selectedCategory) => async (dispatch) => {
  let videoCategoryId;
  let endpoint = "users/marketing_banner";
  if (selectedCategory?.length) {
    videoCategoryId = selectedCategory[0].videoCategoryId;
    endpoint = endpoint + "?videoCategoryId=" + videoCategoryId;
  }
  const res = await api.get(endpoint);
  dispatch({
    type: GET_MARKETING_BANNERS,
    payload: {
      banners: res.data.data,
      userCustomBanners: res.data.userCustomBanners
        ? res.data.userCustomBanners
        : [],
    },
  });
};
export const addNewCustomBanner = (newCustomBanner) => async (dispatch) => {
  dispatch({
    type: ADD_NEW_CUSTOM_BANNER,
    payload: { newCustomBanner },
  });
};
export const removeCustomBanner = (bannerId, bannerUrl) => async (dispatch) => {
  const res = await api.delete(`users/deleteCustomBannerById/${bannerId}`);
  // delete banner from s3 bucket
  const filenamesplit = bannerUrl.split("/");
  const filename = filenamesplit[filenamesplit.length - 1];
  await api.delete(`fileoperation/deleteFile/${filename}`);
  dispatch({
    type: REMOVE_CUSTOM_BANNER,
    payload: { bannerId },
  });
};

export const getFeaturedWarriors = () => async (dispatch) => {
  const res = await api.get(`users/featured_warrior`);

  dispatch({
    type: GET_FEATURED_WARRIORS,
    payload: res.data.data,
  });
};

export const contactIsutra =
  (Name, Email, Subject, Message) => async (dispatch) => {
    try {
      dispatch({
        type: SEND_CONTACT_US_EMAIL_REQ,
      });
      let url = config.api.url + "users/contact_us";
      let res = await api.post(url, {
        name: Name,
        email: Email,
        subject: Subject,
        message: Message,
      });
      if (res.status === 200) {
        dispatch({
          type: SEND_CONTACT_US_EMAIL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: SEND_CONTACT_US_EMAIL_FAILED,
        });
      }
    } catch (e) {
      dispatch({
        type: SEND_CONTACT_US_EMAIL_FAILED,
      });
    }
  };

export const resetContactUs = () => (dispatch) => {
  dispatch({
    type: RESET_CONTACT_US,
  });
};

export const getLibraryVideos = (userId, offset) => async (dispatch) => {
  const res = await api.get("users/libraryVideos", {
    params: {
      userId,
      offset,
    },
  });
  dispatch({
    type: GET_LIBRARY_VIDEOS,
    payload: {
      isFetching: false,
      videos: res.data.videos,
      hasMore: res.data.hasMore,
    },
  });
};

export const saveTipAfterTwoInfo = (payload) => async (dispatch) => {
  try {
    await api.post(`videos/saveTipAfterTwoInfo`, payload);
  } catch (err) {}
};
export const deleteUser = (currentLoggedInUser) => async (dispatch) => {
  const userId = currentLoggedInUser.id;
  try {
    let url = config.api.url + "users/delete_user";
    let res = await api.post(url, {
      user_id: userId,
    });
    if (res.status === 200) {
      toast.success(notificationMessage.deleteAccount);
      dispatch(logoutUser());
    }
  } catch (e) {}
};
export const clearMarketingBanner = () => ({ type: CLEAR_MARKETING_BANNERS });
export const checkPWResetTokenExpiry = (pwResetToken) => async (dispatch) => {
  api
    .post(`users/checkResetPWTokenExpiry`, {
      reset_pw_token: pwResetToken,
    })
    .then((res) => {})
    .catch((err) => {
      toast.error(err.response.data);
      dispatch({
        type: PW_RESET_TOKEN_EXPIRED,
        payload: err.response.data,
      });
    });
};

export const clearResetPasswordError = () => (dispatch) => {
  dispatch({
    type: CLEAR_RESET_PASSWORD_ERROR,
  });
};

export const newCategoryRequestAction = (data) => async (dispatch) => {
  try {
    dispatch({
      type: NEW_CATEGORY_REQUEST,
    });

    const res = await api.post("users/newCategoryRequest", data);

    dispatch({
      type: NEW_CATEGORY_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: NEW_CATEGORY_FAIL,
      payload: "Internal server error occurred!",
    });
  }
};

export const videoReportAction = (data) => async (dispatch) => {
  try {
    dispatch({
      type: NEW_CONTENT_FLAG_REQUEST,
    });

    const res = await api.post("users/videoReportByUser", data);

    dispatch({
      type: NEW_CONTENT_FLAG_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: NEW_CONTENT_FLAG_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getFlagTypesAction = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FLAG_TYPES_REQUEST,
    });

    const res = await api.get("users/getFlagTypes", data);

    dispatch({
      type: GET_FLAG_TYPES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_FLAG_TYPES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// playlist work
export const createNewPlaylist = (data) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_NEW_PLAYLIST_REQUEST,
    });

    const res = await api.post("playlist", data);

    dispatch({
      type: CREATE_NEW_PLAYLIST_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_NEW_PLAYLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllPlaylists = (userId, playlistObj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PLAYLIST_REQUEST,
    });

    // const res = await api.get("playlist");
    const res = await api.get(
      `playlist/${userId}?limit=${playlistObj.limit}&page=${playlistObj.page}`
    );

    dispatch({
      type: GET_ALL_PLAYLIST_SUCCESS,
      length: res.data.length,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PLAYLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllPlaylistsHasmore =
  (userId, playlistObj) => async (dispatch) => {
    try {
      const res = await api.get(
        `playlist/${userId}?limit=${playlistObj.limit}&page=${playlistObj.page}`
      );

      dispatch({
        type: GET_ALL_PLAYLIST_SUCCESS_SECOND,
        length: res.data.length,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_PLAYLIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getSinglePlaylist =
  (id, sortDate, sortPopularity) => async (dispatch) => {
    try {
      dispatch({
        type: GET_SINGLE_PLAYLIST_REQUEST,
      });

      const res = await api.get(
        `playlist/${id}?sort=${sortDate}&popularity=${sortPopularity}`
      );

      dispatch({
        type: GET_SINGLE_PLAYLIST_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SINGLE_PLAYLIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deletePlaylistById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_PLAYLIST_REQUEST,
    });

    const res = await api.delete(`playlist/${id}`);

    dispatch({
      type: DELETE_PLAYLIST_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PLAYLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editPlaylistById = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_PLAYLIST_REQUEST,
    });

    const res = await api.patch(`playlist/${id}`, data);

    dispatch({
      type: EDIT_PLAYLIST_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: EDIT_PLAYLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addVideoToPlaylist = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_VIDEO_TO_PLAYLIST_REQUEST,
    });

    const res = await api.post(`playlist/${id}/addVideo`, data);

    dispatch({
      type: ADD_VIDEO_TO_PLAYLIST_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: ADD_VIDEO_TO_PLAYLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeVideoFromPlaylist = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_VIDEO_FROM_PLAYLIST_REQUEST,
    });

    const res = await api.post(`playlist/${id}/removeVideo`, data);

    dispatch({
      type: REMOVE_VIDEO_FROM_PLAYLIST_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_VIDEO_FROM_PLAYLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// series work
export const createNewSeries = (data) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_NEW_SERIES_REQUEST,
    });

    const res = await api.post("series", data);

    dispatch({
      type: CREATE_NEW_SERIES_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_NEW_SERIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllSeriesOfUser =
  (username, published, seriesObj) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_SERIES_OF_USER_REQUEST,
      });

      let res;
      if (published) {
        res = await api.get(
          `series/getUserSeries/${username}?published=${published}`
        );
      } else if (seriesObj) {
        res = await api.get(
          `series/getUserSeries/${username}?limit=${seriesObj.limit}&page=${seriesObj.page}`
        );
      } else {
        res = await api.get(`series/getUserSeries/${username}`);
      }
      dispatch({
        type: GET_ALL_SERIES_OF_USER_SUCCESS,
        length: res.data.length,
        payload: res.data.series,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_SERIES_OF_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getAllSeriesOfUserHasmore =
  (username, published, seriesObj) => async (dispatch) => {
    try {
      let res;
      if (published) {
        res = await api.get(
          `series/getUserSeries/${username}?published=${published}`
        );
      } else {
        res = await api.get(
          `series/getUserSeries/${username}?limit=${seriesObj.limit}&page=${seriesObj.page}`
        );
      }
      dispatch({
        type: GET_ALL_SERIES_OF_USER_SUCCESS_SECOND,
        length: res.data.length,
        payload: res.data.series,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_SERIES_OF_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getSingleSeries = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SINGLE_SERIES_REQUEST,
    });

    const res = await api.get(`series/${id}`);

    dispatch({
      type: GET_SINGLE_SERIES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_SERIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getGuestSingleSeries = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SINGLE_SERIES_REQUEST,
    });

    const res = await api.get(`series/guest-series/${id}`);

    dispatch({
      type: GET_SINGLE_SERIES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_SERIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteSeriesById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_SERIES_REQUEST,
    });

    const res = await api.delete(`series/${id}`);

    dispatch({
      type: DELETE_SERIES_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: DELETE_SERIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editSeriesById = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_SERIES_REQUEST,
    });

    const res = await api.patch(`series/${id}`, data);

    dispatch({
      type: EDIT_SERIES_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: EDIT_SERIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addVideoToSeries = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_VIDEO_TO_SERIES_REQUEST,
    });

    const res = await api.post(`series/${id}/addVideo`, data);

    dispatch({
      type: ADD_VIDEO_TO_SERIES_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: ADD_VIDEO_TO_SERIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeVideoFromSeries = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_VIDEO_FROM_SERIES_REQUEST,
    });

    const res = await api.post(`series/${id}/removeVideo`, data);

    dispatch({
      type: REMOVE_VIDEO_FROM_SERIES_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_VIDEO_FROM_SERIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const dragDropSeriesVideos = (id, data) => async (dispatch) => {
  const res = await api.post(`series/${id}/dragDrop`, data);

  dispatch({
    type: DRAG_DROP_SERIES_VIDEOS,
    payload: res.data.message,
  });
};

export const getAllSeries = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_SERIES_REQUEST,
    });

    const res = await api.get("series/get-series");

    dispatch({
      type: GET_ALL_SERIES_SUCCESS,
      payload: res.data.series,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_SERIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getMuxSeriesUrls = async (muxid) => {
  try {
    const response = await axios.get(
      `${config.api.url}series/getMuxSeriesVideo?muxid=${muxid}`
    );
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw new Error("Failed to fetch Mux playback URLs");
  }
};

export const getAllPurchasedSeries = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PURCHASED_SERIES_REQUEST,
    });

    const res = await api.get("series/purchasedSeries");

    dispatch({
      type: GET_ALL_PURCHASED_SERIES_SUCCESS,
      payload: res.data.allSeries,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PURCHASED_SERIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createSeriesProgress = (seriesId) => async (dispatch) => {
  try {
    await api.post(`series/seriesProgress/${seriesId}`);
  } catch (err) {}
};

export const updateSeriesProgress = (seriesId, videoId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SERIES_PROGRESS_REQUEST,
    });

    const res = await api.patch(`series/seriesProgress/${seriesId}/${videoId}`);

    dispatch({
      type: GET_SERIES_PROGRESS_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: GET_SERIES_PROGRESS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const sendConnectionRequest = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_CONNECTION_REQUEST,
    });

    const res = await api.post("connection", data);

    dispatch({
      type: SEND_CONNECTION_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: SEND_CONNECTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllConnectionRequests = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CONNECTIONS_REQUEST,
    });

    const res = await api.get("connection");

    dispatch({
      type: GET_CONNECTIONS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_CONNECTIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const approveConnectionRequest = (data) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVE_CONNECTION_REQUEST,
    });

    const res = await api.post("connection/approved", data);

    dispatch({
      type: APPROVE_CONNECTION_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: APPROVE_CONNECTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const declineConnectionRequest = (data) => async (dispatch) => {
  try {
    dispatch({
      type: DECLINE_CONNECTION_REQUEST,
    });

    const res = await api.post("connection/declined", data);

    dispatch({
      type: DECLINE_CONNECTION_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: DECLINE_CONNECTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllFriends = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_FRIENDS_REQUEST,
    });

    const res = await api.get("connection/friends");

    dispatch({
      type: GET_FRIENDS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_CONNECTIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getConnectionStatus = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CONNECTION_STATUS_REQUEST,
    });

    const res = await api.post("connection/status", data);
    dispatch({
      type: GET_CONNECTION_STATUS_SUCCESS,
      payload: res.data.buttonText,
    });
  } catch (error) {
    dispatch({
      type: GET_CONNECTION_STATUS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeConnection = (data) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_CONNECTION_REQUEST,
    });
    const connectionId = localStorage.getItem("connectionId");
    const deleteData = { connectionId: connectionId };
    await api.post("chat/deleteMessage", deleteData);
    const res = await api.post("connection/remove", data);

    dispatch({
      type: REMOVE_CONNECTION_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_CONNECTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeDeleteMessage = (id, userid) => async (dispatch) => {
  try {
    const deleteData = {
      connectionId: id,
      userId: userid,
    };
    const deleteMessage = await api.post("chat/deleteMessage", deleteData);

    return deleteMessage;
  } catch (error) {}
};

export const addOrEditKarmaSetting = (filters) => async (dispatch) => {
  try {
    dispatch({
      type: GET_KARMA_FILTER_SETTING_REQUEST,
    });
    const res = await api.post("users/createOrEditKarmaFilterSetting", filters);
    dispatch({
      type: GET_KARMA_FILTER_SETTING_SUCCESS,
      payload: res.data,
    });

    toast.success(notificationMessage.updatedKarmaFilter);
  } catch (err) {}
};

export const getKarmaFilterSetting = (filterType) => async (dispatch) => {
  try {
    dispatch({
      type: GET_KARMA_FILTER_SETTING_REQUEST,
    });
    const res = await api.get(
      `users/getKarmaFilterSettingByUserId/${filterType}`
    );
    dispatch({
      type: GET_KARMA_FILTER_SETTING_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_KARMA_FILTER_SETTING_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const uploadMoment = (data, selectedCategory) => async (dispatch) => {
  try {
    dispatch({
      type: UPLOAD_MOMENT_REQUEST,
    });

    const res = await api.post("moment", data);
    dispatch({
      type: UPLOAD_MOMENT_SUCCESS,
      payload: res.data.data,
    });
    dispatch(getAllMoments(1, 10, selectedCategory));
  } catch (error) {
    dispatch({
      type: UPLOAD_MOMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateMoment = (momentId, data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_MOMENT_REQUEST,
    });

    const res = await api.patch(`moment/${momentId}`, data);

    dispatch({
      type: UPDATE_MOMENT_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_MOMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteMoment = (momentId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_MOMENT_REQUEST,
    });

    const res = await api.delete(`moment/${momentId}`);

    dispatch({
      type: DELETE_MOMENT_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: DELETE_MOMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSingleMoment = (momentId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SINGLE_MOMENT_REQUEST,
    });

    const res = await api.get(`moment/${momentId}`);

    dispatch({
      type: GET_SINGLE_MOMENT_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_MOMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getGuestSingleMoment = (momentId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SINGLE_MOMENT_REQUEST,
    });

    const res = await api.get(`moment/guest-moment/${momentId}`);

    dispatch({
      type: GET_SINGLE_MOMENT_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_MOMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllMoments =
  (page, limit, selectedCategory) => async (dispatch) => {
    try {
      let selectedCategoryStr;

      dispatch({
        type: GET_ALL_MOMENT_REQUEST,
      });

      let query;
      query = `moment?limit=${limit}&page=${page}`;
      if (selectedCategory?.length) {
        selectedCategoryStr = encodeURIComponent(
          JSON.stringify(selectedCategory)
        );
        if (query === "moment") {
          query = query + `?categoryList=${selectedCategoryStr}`;
        } else {
          query = query + `&categoryList=${selectedCategoryStr}`;
        }
      }

      const res = await api.get(query);
      dispatch({
        type: GET_ALL_MOMENT_SUCCESS,
        payload: {
          moments: res.data.data.moments,
          hasMore: res.data.data.hasMore,
          isFiltering: res.data.data.filtering,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_MOMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const getMuxPlaybackUrls = async (muxid) => {
  try {
    const response = await axios.get(
      `${config.api.url}moment/getMuxMomentVideo?muxid=${muxid}`
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error.message);
    throw new Error("Failed to fetch Mux playback URLs");
  }
};

export const getGuestMoments =
  (limit = 5, offset, selectedCategory) =>
  async (dispatch) => {
    try {
      let selectedCategoryStr;
      dispatch({
        type: GET_ALL_MOMENT_REQUEST,
      });

      let query;

      if (limit) {
        query = `moment/guest-moments?limit=${limit}`;
      } else if (offset) {
        query = `moment/guest-moments?offset=${offset}`;
      } else if (limit && offset) {
        query = `moment/guest-moments?limit=${limit}&offset=${offset}`;
      } else {
        query = `moment`;
      }

      if (selectedCategory?.length) {
        selectedCategoryStr = encodeURIComponent(
          JSON.stringify(selectedCategory)
        );
        if (query === "moment") {
          query = query + `?categoryList=${selectedCategoryStr}`;
        } else {
          query = query + `&categoryList=${selectedCategoryStr}`;
        }
      }

      const res = await api.get(query);

      dispatch({
        type: GET_ALL_MOMENT_SUCCESS,
        payload: {
          moments: res.data.data.moments,
          hasMore: res.data.data.hasMore,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_MOMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const tagUserInMoment = (momentId, tagUserId) => async (dispatch) => {
  try {
    dispatch({
      type: TAG_USER_IN_MOMENT_REQUEST,
    });

    const res = await api.put(`moment/${momentId}/tagUser`, {
      tagUser: tagUserId,
    });

    dispatch({
      type: TAG_USER_IN_MOMENT_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: TAG_USER_IN_MOMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getTaggedUsersInMoment = (momentId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TAGGED_USERS_OF_MOMENT_REQUEST,
    });

    const res = await api.get(`moment/${momentId}/tagUsers`);

    dispatch({
      type: GET_TAGGED_USERS_OF_MOMENT_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_TAGGED_USERS_OF_MOMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getMyMoments = (userId, momentObj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MY_MOMENT_REQUEST,
    });

    var res;

    if (momentObj) {
      res = await api.get(
        `moment/my-moments?userId=${userId}&limit=${momentObj.limit}&skip=${momentObj.page}`
      );
    } else {
      res = await api.get(`moment/my-moments?userId=${userId}`);
    }

    dispatch({
      type: GET_MY_MOMENT_SUCCESS,
      length: res.data.length,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_MY_MOMENT_FAIL,
    });
  }
};

export const getMyMomentsHasMore = (userId, momentObj) => async (dispatch) => {
  try {
    const res = await api.get(
      `moment/my-moments?userId=${userId}&limit=${momentObj.limit}&skip=${momentObj.page}`
    );

    dispatch({
      type: GET_HASMORE_MOMENTS,
      length: res.data.length,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_MY_MOMENT_FAIL,
    });
  }
};

export const getMyBanners = () => async (dispatch) => {
  try {
    const res = await api.get(`users/marketing_banner`);
    dispatch({
      type: GET_MARKETING_BANNERS,
      payload: {
        banners: res.data.data,
        userCustomBanners: res.data.userCustomBanners,
      },
    });
  } catch (error) {}
};

export const createChat = (chatData) => async (dispatch) => {
  try {
    if (!chatData.receiverId) {
      chatData.receiverId = 0;
    }
    const createChat = await api.post("chat/createChat", chatData);
    dispatch({
      type: POST_MESSAGE,
      payload: createChat,
    });
  } catch (error) {
    dispatch({
      type: CHAT_MESSAGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateStatusRead =
  (receiverId, senderId, userId, connectionId) => async (dispatch) => {
    try {
      const data = {
        receiverId,
        senderId,
        userId,
        connectionId,
      };

      const updateStatus = await api.post(`chat/updateStatus`, data);
      dispatch({
        type: CHAT_MESSAGE_SUCCESS,
        payload: updateStatus,
      });
    } catch (error) {
      dispatch({
        type: CHAT_MESSAGE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const viewChat = () => async (dispatch) => {
  try {
    const chatData = await api.get("chat/viewChat");
    dispatch({
      type: GET_CHAT,
      payload: chatData.data.message,
      isFetching: false,
      data: chatData.data,
    });
    return chatData;
  } catch (error) {
    dispatch({
      type: CHAT_MESSAGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSubscriptionPlans = () => async (dispatch) => {
  try {
    dispatch({
      type: SUBSCRIPTION_PLANS_REQUEST,
    });
    const res = await api.get("stripe/getSubscriptionPlans");
    dispatch({
      type: SUBSCRIPTION_PLANS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SUBSCRIPTION_PLANS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createProfileElement = (data) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PROFILE_ELEMENT_REQUEST,
    });

    const res = await api.post(`profile-element`, data);

    dispatch({
      type: CREATE_PROFILE_ELEMENT_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PROFILE_ELEMENT_FAIL,
    });
  }
};
export const updateSubscriptionPlans = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SUBSCRIPTION_PLANS_REQUEST,
    });
    const res = await api.post("stripe/updateSubscriptionPlans", payload);
    dispatch({
      type: UPDATE_SUBSCRIPTION_PLANS_SUCCESS,
      payload: res.data,
    });
    dispatch({
      type: UPDATE_USER_ROLE,
      payload: res.data.updatedUserRole,
    });
    toast.success(notificationMessage.updateSubscription, { autoClose: 2000 });
  } catch (error) {
    toast.error(notificationMessage.failedUpdateSubscription);
    dispatch({
      type: UPDATE_SUBSCRIPTION_PLANS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeProfileElement = (elementId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_PROFILE_ELEMENT_REQUEST,
    });

    const res = await api.delete(`profile-element/${elementId}`);

    dispatch({
      type: DELETE_PROFILE_ELEMENT_SUCCESS,
      payload: res.data.message,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PROFILE_ELEMENT_FAIL,
    });
  }
};

export const updatePaymentMethod = (data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PAYMET_METHOD_SUCCESS,
      payload: data,
    });
    const res = await api.post("stripe/updatePaymetMethod", data);
    dispatch({
      type: UPDATE_USER_PAYMENT_METHOD,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PAYMET_METHOD_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getProfileElements = (userName, userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROFILE_ELEMENTS_REQUEST,
    });
    if (userName) {
      let query = null;

      if (!userId) {
        query = `profile-element/${userName}`;
      } else {
        query = `profile-element/${userName}?loggedInUserId=${userId}`;
      }

      const res = await api.get(query);

      dispatch({
        type: GET_PROFILE_ELEMENTS_SUCCESS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_PROFILE_ELEMENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateProfileElement = (elementId, data) => async (dispatch) => {
  const res = await api.patch(`profile-element/${elementId}`, data);

  dispatch({
    type: DRAG_DROP_PROFILE_ELEMENTS,
    payload: res.data.message,
  });
};

export const addContentToElement = (elementId, data) => async (dispatch) => {
  await api.post(`profile-element/${elementId}/add-content`, data);

  dispatch({
    type: ADD_CONTENT_TO_ELEMENT,
    payload: "Content added to element",
  });
};

export const removeContentFromElement =
  (elementId, contentId) => async (dispatch) => {
    await api.delete(
      `profile-element/${elementId}/remove-content/${contentId}`
    );

    dispatch({
      type: REMOVE_CONTENT_FROM_ELEMENT,
      payload: "Content removed from element",
    });
  };

export const getUserVideos = (data, userId, videoObj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_VIDEOS_REQUEST,
    });
    const videosData = {
      ...data,
      videoObj,
    };

    const res = await api.post(`videos/getMyVideos/${userId}`, videosData);
    dispatch({
      type: GET_USER_VIDEOS_SUCCESS,
      length: res.data.length,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_VIDEOS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserProfileElemtMoments =
  (userName, userId, momentObj) => async (dispatch) => {
    try {
      if (userName) {
        let query = null;

        if (!userId) {
          query = `profile-element/moments/${userName}`;
        } else {
          query = `profile-element/moments/${userName}?loggedInUserId=${userId}`;
        }

        const res = await api.post(query, momentObj);
        dispatch({
          type: PROFILE_ELEMENT_MOMENTS,
          payload: res.data.data,
          momenttotalpage: res.data,
          length: res.data.totalPage,
        });
      }
    } catch (error) {}
  };

export const getUserProfileElemtVideos =
  (userName, userId, videoovj) => async (dispatch) => {
    try {
      if (userName) {
        let query = null;

        if (!userId) {
          query = `profile-element/videos/${userName}`;
        } else {
          query = `profile-element/videos/${userName}?loggedInUserId=${userId}`;
        }

        const res = await api.post(query, videoovj);
        dispatch({
          type: PROFILE_ELEMENT_VIDEOS,
          payload: res.data.data,
          videoloding: false,
          totalPage: res.data.totalPage,
        });
      }
    } catch (error) {}
  };

export const getUserProfileElemtSeries =
  (userName, userId, videoovj) => async (dispatch) => {
    try {
      if (userName) {
        let query = null;

        if (!userId) {
          query = `profile-element/series/${userName}`;
        } else {
          query = `profile-element/series/${userName}?loggedInUserId=${userId}`;
        }

        const res = await api.post(query, videoovj);
        dispatch({
          type: PROFILE_ELEMENT_SERIES,
          payload: res.data.data,
          seriesloading: false,
          totalPage: res.data.totalPage,
        });
      }
    } catch (error) {}
  };

export const getUserProfileElemtThreads =
  (userName, userId, videoovj) => async (dispatch) => {
    try {
      if (userName) {
        let query = null;

        if (!userId) {
          query = `profile-element/threads/${userName}`;
        } else {
          query = `profile-element/threads/${userName}?loggedInUserId=${userId}`;
        }

        const res = await api.post(query, videoovj);
        dispatch({
          type: PROFILE_ELEMENT_THREADS,
          payload: res.data.data,
          seriesloading: false,
          totalPage: res.data.totalPage,
        });
      }
    } catch (error) {}
  };

export const getUserProfileElemtBanners =
  (userName, userId, videoovj) => async (dispatch) => {
    try {
      if (userName) {
        let query = null;

        if (!userId) {
          query = `profile-element/banners/${userName}`;
        } else {
          query = `profile-element/banners/${userName}?loggedInUserId=${userId}`;
        }

        const res = await api.post(query, videoovj);
        dispatch({
          type: PROFILE_ELEMENT_BANNERS,
          payload: res.data.data,
          bannersloading: false,
          totalPage: res.data.totalPage,
        });
      }
    } catch (error) {}
  };

export const getUserProfileElemtBannersHasMore =
  (userName, userId, videoovj) => async (dispatch) => {
    try {
      if (userName) {
        let query = null;

        if (!userId) {
          query = `profile-element/banners/${userName}`;
        } else {
          query = `profile-element/banners/${userName}?loggedInUserId=${userId}`;
        }

        const res = await api.post(query, videoovj);
        dispatch({
          type: PROFILE_ELEMENT_BANNERS_SECOND,
          payload: res.data.data,
          bannersloading: false,
          totalPage: res.data.totalPage,
        });
      }
    } catch (error) {}
  };

export const getUserProfileElemtThreadsHasMore =
  (userName, userId, videoovj) => async (dispatch) => {
    try {
      if (userName) {
        let query = null;

        if (!userId) {
          query = `profile-element/threads/${userName}`;
        } else {
          query = `profile-element/threads/${userName}?loggedInUserId=${userId}`;
        }

        const res = await api.post(query, videoovj);
        dispatch({
          type: PROFILE_ELEMENT_THREADS,
          payload: res.data.data,
          seriesloading: false,
          totalPage: res.data.totalPage,
        });
      }
    } catch (error) {}
  };

export const getUserProfileElemtSeriesHasMore =
  (userName, userId, videoovj) => async (dispatch) => {
    try {
      if (userName) {
        let query = null;

        if (!userId) {
          query = `profile-element/series/${userName}`;
        } else {
          query = `profile-element/series/${userName}?loggedInUserId=${userId}`;
        }

        const res = await api.post(query, videoovj);
        dispatch({
          type: PROFILE_ELEMENT_SERIES_SECOND,
          payload: res.data.data,
          seriesloading: false,
          totalPage: res.data.totalPage,
        });
      }
    } catch (error) {}
  };

export const getUserProfileElemtVideosHasMore =
  (userName, userId, videoovj) => async (dispatch) => {
    try {
      if (userName) {
        let query = null;

        if (!userId) {
          query = `profile-element/videos/${userName}`;
        } else {
          query = `profile-element/videos/${userName}?loggedInUserId=${userId}`;
        }

        const res = await api.post(query, videoovj);
        dispatch({
          type: PROFILE_ELEMENT_VIDEOS_SECOND,
          payload: res.data.data,
          videoloding: false,
          totalPage: res.data.totalPage,
        });
      }
    } catch (error) {}
  };

export const getUserProfileElemtMomentsHasMore =
  (userName, userId, momentObj) => async (dispatch) => {
    try {
      if (userName) {
        let query = null;

        if (!userId) {
          query = `profile-element/moments/${userName}`;
        } else {
          query = `profile-element/moments/${userName}?loggedInUserId=${userId}`;
        }
        const res = await api.post(query, momentObj);
        dispatch({
          type: PROFILE_ELEMENT_MOMENTS_SECOND,
          payload: res.data.data,
          length: res.data.totalPage,
        });
      }
    } catch (error) {}
  };

export const getUserChannels = (channelObj, username) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_CHANNEL_REQUEST,
    });
    const res = await api.post(
      `users/primary-channels/${username}`,
      channelObj
    );
    dispatch({
      type: GET_USER_CHANNELS_SUCCESS,
      payload: res.data.data,
      length: res.data.length,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_VIDEOS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const userHasMoreChannels =
  (channelObj, username) => async (dispatch) => {
    try {
      const res = await api.post(
        `users/primary-channels/${username}`,
        channelObj
      );
      dispatch({
        type: GET_USER_CHANNELS_HASMORE,
        payload: res.data.data,
        length: res.data.length,
      });
    } catch (error) {
      dispatch({
        type: GET_USER_VIDEOS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserVideosss = (data, userId, videoObj) => async (dispatch) => {
  try {
    // dispatch({
    //   type: GET_USER_VIDEOS_SUCCESS_SECOND,
    // });
    const videosData = {
      data,
      videoObj,
    };

    const res = await api.post(`videos/getMyVideos/${userId}`, videosData);
    dispatch({
      type: GET_USER_VIDEOS_SUCCESS_SECOND,
      length: res.data.length,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_VIDEOS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getUserStorageInfo = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_STORAGE_INFO_REQUEST,
    });

    const res = await api.get(`users/storage-info`);

    dispatch({
      type: USER_STORAGE_INFO_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: USER_STORAGE_INFO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getChatCount = (userId) => async (dispatch) => {
  try {
    const data = {
      userId: userId.id,
    };
    const res = await api.post(`chat/chatCount`, data);
    dispatch({ type: CHATCOUNT, payload: res.data });
    return res.data;
  } catch (error) {}
};

export const getFeaturedSeries = (selectedCategory) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FEATURED_SERIES_REQUEST,
    });

    const res = await api.post(`admin/series/featuredSeries`, {
      categoryList: selectedCategory?.length ? selectedCategory : [], // array of selected category objects
    });

    dispatch({
      type: GET_FEATURED_SERIES_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_FEATURED_SERIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getVideosV2 = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_VIDEOS_REQUESTV2,
    });

    const res = await api.post(`videos/getVideos`, obj);
    if (res.data.data.filtering) {
      dispatch({
        type: SHOW_FILTERED_VIDEOS,
        payload: {
          videos: res.data.data.videos,
          hasMore: res.data.data.hasMore,
          isFiltering: res.data.data.filtering,
        },
      });
    } else {
      dispatch({
        type: GET_VIDEOS_SUCCESSV2,
        payload: {
          videos: res.data.data.videos,
          hasMore: res.data.data.hasMore,
          isFiltering: res.data.data.filtering,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: GET_VIDEOS_FAILV2,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const clearFilteredVideos = () => async (dispatch) => {
  dispatch({
    type: CLEAR_FILTERED_VIDEOS,
  });
};

export const clearFilteredStreamVideos = () => async (dispatch) => {
  dispatch({
    type: CLEAR_FILTERED_STREAM_VIDEOS,
  });
};

export const getStreamsVideos = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CHANNEL_STREAM_VIDEOS_REQUEST,
    });

    const res = await api.post(`subscriptionPay/getStreamsVideos`, obj);
    if (res.data.data.filtering) {
      dispatch({
        type: SHOW_CHANNEL_STREAM_FILTERED_VIDEOS,
        payload: {
          videos: res.data.data.videos,
          hasMore: res.data.data.hasMore,
          isFiltering: res.data.data.filtering,
        },
      });
    } else {
      dispatch({
        type: GET_CHANNEL_STREAM_VIDEOS_SUCCESS,
        payload: {
          videos: res.data.data.videos,
          hasMore: res.data.data.hasMore,
          isFiltering: res.data.data.filtering,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CHANNEL_STREAM_VIDEOS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getNotificationSettings = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_NOTIFICATION_SETTING_REQUEST,
    });

    const res = await api.get(`notifications/notification-settings`);

    dispatch({
      type: GET_NOTIFICATION_SETTING_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_NOTIFICATION_SETTING_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAccountDetails = (userId) => async (dispatch) => {
  try {
    const user = JSON.parse(userId);

    const res = await api.get(`users/getAccountDetails/${user.id}`);

    return res.data.message;
  } catch (error) {}
};

export const updateUserData = (userId, updatedData) => async (dispatch) => {
  try {
    const user = JSON.parse(userId);
    const response = await api.post(
      `users/getAccountSettings/${user.id}`,
      updatedData
    );

    if (!response.data.success) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {}
};

export const getLikedMoments = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_LIKED_MOMENTS_REQUEST,
    });

    const res = await api.get(`moment/liked-moments`);

    dispatch({
      type: GET_LIKED_MOMENTS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_LIKED_MOMENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteAdditionalContent = (fileid) => async (dispatch) => {
  try {
    const data = {
      fileIds: fileid,
    };
    console.log(fileid);
    const response = await api.post("videos/deleteAdditionalFile", data);
  } catch (error) {}
};

// // Admin ALL api User Data api ===============================================================================
// Admin ALL api User Data api ===============================================================================
export const updateUserSuccess = () => {
  return {
    type: UPDATE_USER_SUCCESS,
  };
};
export const updateUserFailure = (error) => {
  return {
    type: UPDATE_USER_FAILURE,
    error: error,
  };
};
export const updateVideoData = (updatedData) => {
  return {
    type: "UPDATE_VIDEO_DATA",
    payload: updatedData,
  };
};
export const updateMomentAdmin = (updatedMoment) => {
  return {
    type: "UPDATE_MOMENT",
    payload: updatedMoment,
  };
};
export const addVideoCategorySuccess = (data) => ({
  type: ADD_VIDEO_CATEGORY_SUCCESS,
  payload: data,
});

export const addVideoCategoryFailure = (error) => ({
  type: ADD_VIDEO_CATEGORY_FAILURE,
  payload: error,
});

export const addSubCategoryOneSuccess = (data) => ({
  type: ADD_SUB_CATEGORY_ONE_SUCCESS,
  payload: data,
});
export const addSubCategoryOneFailure = (data) => ({
  type: ADD_SUB_CATEGORY_ONE_FAILURE,
  payload: data,
});
export const addVistorbadgeSuccess = (data) => ({
  type: ADD_VISTOR_BADGE_SUCCESS,
  payload: data,
});
export const updateVideoAccessOverlay = (updatedData) => {
  return {
    type: "UPDATE_VIDEO_OVERLAT_DATA",
    payload: updatedData,
  };
};
export const addPostWidBannerSuccess = (data) => ({
  type: ADD_WIDGET_BANNER_SUCCESS,
  payload: data,
});

// ----------------------------  Admin User API Data-----------------------------------
// Get Admin User Data
export const adminAllUserData = () => async (dispatch) => {
  dispatch({ type: "FETCH_USER_DATA_REQUEST" });

  try {
    const response = await axios.get(`${config.api.url}users/allUser`);
    dispatch({ type: FETCH_USER_DATA_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error fetching data:", error);
    dispatch({ type: FETCH_USER_DATA_FAILURE, error });
  }
};
// Admin Upadte User Data
export const updateAdminUser = (userFormData) => {
  return async (dispatch) => {
    const updatedAdminUserData = {
      firstname: userFormData.firstname,
      lastname: userFormData.lastname,
      email: userFormData.email,
      username: userFormData.username,
      userrole: userFormData.userrole,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdminUserData),
    };

    try {
      const response = await fetch(
        `${config.api.url}users/updateAdminUser/${userFormData.id}`,
        data
      );

      if (response.ok) {
        toast.success("User Details Update Sucessfully");
        dispatch(updateUserSuccess());
      } else {
        toast.error("User Details Update failed");
        dispatch(updateUserFailure());
      }
    } catch (error) {
      toast.error("Error updating admin user:", error);
      dispatch(updateUserFailure());
    }
  };
};
// Delete an admin user
export const deleteAdminUser = (userId) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${config.api.url}users/deleteAdminUser/${userId}`
    );

    if (response.status === 200) {
      dispatch({
        type: DELETE_ADMIN_USER,
        payload: userId,
      });
      toast.success("User Delete Sucesfully");
    } else {
      console.error("Error deleting user:", response.data.error);
      toast.error(response.data.error);
    }
  } catch (error) {
    toast.error(error);
  }
};

//----------------------------   Admin Video API    -------------------------------
// Admin All Get  Video Data
export const adminAllVideoData = () => async (dispatch) => {
  try {
    const response = await axios.get(`${config.api.url}videos/getAllVideo`);
    dispatch({ type: FETCH_VIDEO_DATA_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error fetching data:", error);
    dispatch({ type: FETCH_VIDEO_DATA_FAILURE, error });
  }
};
// Admin Upadte Video Data
export const updateAdminVideo = (videoFormData, token) => {
  return async (dispatch) => {
    const updatedAdminVideoData = {
      title: videoFormData.title,
      description: videoFormData.description,
      muxid: videoFormData.muxid,
      userId: videoFormData.userId,
      createdAt: videoFormData.createdAt,
      amount: videoFormData.amount,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdminVideoData),
    };

    try {
      const response = await fetch(
        `${config.api.url}videos/updateAdminVideo/${videoFormData.id}`,
        data
      );

      if (response.ok) {
        toast.success("Video Details Update Sucessfully");
        const updatedVideoData = await response.json();
        dispatch(updateVideoData(updatedVideoData));
      } else {
        toast.error("Video Details Update failed");
      }
    } catch (error) {
      toast.error("Video updating admin user:", error);
    }
  };
};
// Delete an admin Video
export const deleteAdminVideo = (videoId) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${config.api.url}videos/deleteAdminVideo/${videoId}`
    );
    if (response.status === 200) {
      toast.success("Video Delete Sucesfully");
    } else {
      console.error("Error deleting Video:", response.data.error);
      toast.error(response.data.error);
    }
  } catch (error) {
    toast.error(error);
  }
};

//-----------------------------         Moment Api    --------------------------------
// Get Admin Moment Video API
export const adminAllMomentsData = () => async (dispatch) => {
  try {
    const response = await axios.get(`${config.api.url}moment/getAllMoments`);
    dispatch({ type: FETCH_MOMENT_DATA_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error fetching data:", error);
    dispatch({ type: FETCH_MOMENT_DATA_FAILURE, error });
  }
};
// update Admin Moment
export const updateAdminMoment = (momentFormData, token) => {
  return async (dispatch) => {
    const updatedAdminMomentData = {
      caption: momentFormData.caption,
      userId: momentFormData.userId,
      muxid: momentFormData.muxid,
      createdAt: momentFormData.createdAt,
      updatedAt: momentFormData.updatedAt,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdminMomentData),
    };

    try {
      const response = await fetch(
        `${config.api.url}moment/updateAdminMoment/${momentFormData.id}`,
        data
      );

      if (response.ok) {
        toast.success("Moment Details Update Sucessfully");
        dispatch(updateMomentAdmin(updatedAdminMomentData));
      } else {
        toast.error("Moment Details Update failed");
      }
    } catch (error) {
      toast.error("Moment updating admin user:", error);
    }
  };
};
// Delete admin Moment----------
export const deleteAdminMoment = (momentId) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${config.api.url}moment/deleteAdminMoment/${momentId}`
    );
    if (response.status === 200) {
      toast.success("Moment Delete Sucesfully");
    } else {
      console.error("Error deleting Moment:", response.data.error);
      toast.error(response.data.error);
    }
  } catch (error) {
    toast.error(error);
  }
};

// -----------------------------       Series API          -----------------------------
// Get Admin Series Api
export const adminAllSeriesData = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${config.api.url}series/getAdminAllSeries`
    );
    dispatch({ type: FETCH_SERIES_DATA_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error fetching data:", error);
    dispatch({ type: FETCH_SERIES_DATA_FAILURE, error });
  }
};
// Update Admin Series API
export const updateAdminSeries = (seriesFormData) => {
  return async (dispatch) => {
    const updatedAdminSeriesData = {
      title: seriesFormData.title,
      description: seriesFormData.description,
      userId: seriesFormData.userId,
      price: seriesFormData.price,
      createdAt: seriesFormData.createdAt,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdminSeriesData),
    };

    try {
      const response = await fetch(
        `${config.api.url}series/updateAdminSeries/${seriesFormData.id}`,
        data
      );

      if (response.ok) {
        toast.success("Series Details Update Sucessfully");
      } else {
        toast.error("Series Details Update failed");
      }
    } catch (error) {
      toast.error("Series updating admin user:", error);
    }
  };
};
// Delete Admin Series
export const deleteAdminSeries = (seriesId) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${config.api.url}series/deleteAdminSeries/${seriesId}`
    );
    if (response.status === 200) {
      toast.success("Series Delete Sucesfully");
    } else {
      console.error("Error deleting Series:", response.data.error);
      toast.error(response.data.error);
    }
  } catch (error) {
    toast.error(error);
  }
};
// -----------------------------     Series Video API         -----------------------------
// Get Admin Series Video API
export const adminSeriesVideoData = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${config.api.url}series/getAdminSeriesVideo`
    );
    dispatch({ type: FETCH_SERIES_VIDEO_DATA_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error series video fetching data:", error);
    dispatch({ type: FETCH_SERIES_VIDEO_DATA_FAILURE, error });
  }
};
// Update series Video  API
export const updateAdminVideoSeries = (seriesData) => {
  return async (dispatch) => {
    const updatedAdminVideoSeriesData = {
      videoId: seriesData.videoId,
      seriesId: seriesData.seriesId,
      order: seriesData.order,
      muxid: seriesData.muxid,
      createdAt: seriesData.createdAt,
      updatedAt: seriesData.updatedAt,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdminVideoSeriesData),
    };

    try {
      const response = await fetch(
        `${config.api.url}series/updateAdminSeriesVideo/${seriesData.id}`,
        data
      );
      if (response.ok) {
        toast.success("Series Video Details Update Sucessfully");
      } else {
        toast.error("Series Video Details Update failed");
      }
    } catch (error) {
      console.error("Error series video update data:", error);
      toast.error("Series video updating admin video:");
    }
  };
};
// Delete Admin Series Video
export const deleteAdminSeriesVideo = (seriesData) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${config.api.url}series/deleteAdminSeriesVideo/${seriesData}`
    );
    if (response.status === 200) {
      toast.success("Series Delete Sucesfully");
    } else {
      console.error("Error deleting Series:", response.data.error);
      toast.error(response.data.error);
    }
  } catch (error) {
    toast.error(error);
  }
};

// -----------------------------      Notification Type API      -----------------------------
// Get Admin Notificaton Type APi
export const adminNotificationTypeData = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${config.api.url}notifications/getAdminNotificationType`
    );
    dispatch({ type: FETCH_NOTIFICATION_TYPE_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error series video fetching data:", error);
    dispatch({ type: FETCH_NOTIFICATION_TYPE_FAILURE, error });
  }
};
// Update Admin Notificaton Type APi start
export const updateAdminNotificatonType = (formData) => {
  return async (dispatch) => {
    const updatedAdminNotificatonTypeData = {
      name: formData.name,
      sentence: formData.sentence,
      description: formData.description,
      createdAt: formData.createdAt,
      updatedAt: formData.updatedAt,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdminNotificatonTypeData),
    };

    try {
      const response = await fetch(
        `${config.api.url}notifications/updateAdminNotificationType/${formData.id}`,
        data
      );
      if (response.ok) {
        toast.success("Notificaton Type Details Update Sucessfully");
      } else {
        toast.error("Notificaton Type Details Update failed");
      }
    } catch (error) {
      console.error("Error Notificaton Type update data:", error);
      toast.error("Notificaton Type updating admin video:");
    }
  };
};
// Delete Admin Notificaton Type APi start
export const deleteAdminNotificatonType = (formData) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${config.api.url}notifications/deleteAdminNotificationType/${formData}`
    );
    if (response.status === 200) {
      toast.success("Notificaton Type Delete Sucesfully");
    } else {
      console.error("Error deleting Moment:", response.data.error);
      toast.error(response.data.error);
    }
  } catch (error) {
    toast.error(error);
  }
};

// -----------------------------    Notification Category Type API     -----------------------------
// Get Admin Notificaton Category APi
export const adminNotificationCategoryData = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${config.api.url}notifications/getAdminNotificationCategory`
    );
    dispatch({
      type: FETCH_NOTIFICATION_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error Notification Category fetching data:", error);
    dispatch({ type: FETCH_NOTIFICATION_CATEGORY_FAILURE, error });
  }
};
// Update Admin Notificaton Category APi
export const updateAdminNotifiatonCategory = (notificationData) => {
  return async (dispatch) => {
    const updatedAdminNotificatonCategoryData = {
      name: notificationData.name,
      displayName: notificationData.displayName,
      description: notificationData.description,
      createdAt: notificationData.createdAt,
      updatedAt: notificationData.updatedAt,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdminNotificatonCategoryData),
    };

    try {
      const response = await fetch(
        `${config.api.url}notifications/updateAdminNotificationCategory/${notificationData.id}`,
        data
      );
      if (response.ok) {
        toast.success("Notificaton Category Details Update Sucessfully");
      } else {
        toast.error("Notificaton Category Details Update failed");
      }
    } catch (error) {
      console.error("Error Notificaton Category update data:", error);
      toast.error("Notificaton Category updating admin video:");
    }
  };
};
// Delete Admin Notificaton Category APi
export const deleteAdminNotificatonCategory =
  (notificationData) => async (dispatch) => {
    try {
      const response = await axios.delete(
        `${config.api.url}notifications/deleteAdminNotificationCategory/${notificationData}`
      );
      if (response.status === 200) {
        toast.success("Notificaton Category Delete Sucesfully");
      } else {
        console.error(
          "Error deleting Notificaton Category:",
          response.data.error
        );
        toast.error(response.data.error);
      }
    } catch (error) {
      toast.error(error);
    }
  };

// -----------------------------  Video Category Type API -----------------------------
// get Category Api
export const adminVideoCategoryData = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${config.api.url}videos/getAllVideoCategories`
    );
    dispatch({ type: FETCH_VIDEO_CATEGORY_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error Notification Category fetching data:", error);
    dispatch({ type: FETCH_VIDEO_CATEGORY_FAILURE, error });
  }
};
// Update Video Categories
export const updateAdminVideoCategory = (formData) => {
  return async (dispatch) => {
    const updatedAdminVideoCategoryData = {
      id: formData.id,
      name: formData.name,
      order: formData.order,
      iconPath: formData.iconPath,
      createdAt: formData.createdAt,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdminVideoCategoryData),
    };

    try {
      const response = await fetch(
        `${config.api.url}videos/updateAdminVideoCategories/${formData.id}`,
        data
      );
      if (response.ok) {
        toast.success("Video Category Details Update Sucessfully");
      } else {
        toast.error("Video Category Details Update failed");
      }
    } catch (error) {
      console.error("Error Video Category update data:", error);
      toast.error("Video Category updating admin video:");
    }
  };
};
// Post Data Video Category
export const addVideoCategory = (categoryData) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${config.api.url}videos/addAdminVideoCategories`,
      categoryData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch(addVideoCategorySuccess(response.data));
    dispatch(adminVideoCategoryData(response.data));

    toast.success("Notificaton Category Data Added Sucesfully");
  } catch (error) {
    dispatch(addVideoCategoryFailure(error));
    toast.error(error, "Notificaton Category Data Not Added");
  }
};
// Delete Video Categories
export const deleteAdminVideoCategory = (formData) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${config.api.url}videos/deleteAdminVideoCategories/${formData}`
    );
    if (response.status === 200) {
      toast.success("Video Category Delete Sucesfully");
    } else {
      console.error("Error deleting Video Category:", response.data.error);
      toast.error(response.data.error);
    }
  } catch (error) {
    toast.error(error);
  }
};

//============================= Video Sub Categories ======================================
// get Video Sub Categories  Api
export const adminVideoSubCategoriesData = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${config.api.url}videos/getVideoSubCategories`
    );
    dispatch({
      type: FETCH_VIDEO_SUB_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error Notification Category fetching data:", error);
    dispatch({ type: FETCH_VIDEO_SUB_CATEGORY_FAILURE, error });
  }
};
// Update Video Sub Categories
export const updateAdminVideoSubCategory = (formData) => {
  return async (dispatch) => {
    const updatedAdminVideoSubCategory = {
      id: formData.id,
      videoId: formData.videoId,
      momentId: formData.momentId,
      seriesId: formData.seriesId,
      threadId: formData.threadId,
      videoCategoryId: formData.videoCategoryId,
      videoContentType: formData.videoContentType,
      subCategOneId: formData.subCategOneId,
      subCategTwoId: formData.subCategTwoId,
      createdAt: formData.createdAt,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdminVideoSubCategory),
    };

    try {
      const response = await fetch(
        `${config.api.url}videos/updateAdminVideoSubCategories/${formData.id}`,
        data
      );
      if (response.ok) {
        toast.success("VideoSubCategory Details Update Sucessfully");
      } else {
        toast.error("VideoSubCategory Details Update failed");
      }
    } catch (error) {
      console.error("Error VideoSubCategory update data:", error);
      toast.error("VideoSubCategory updating admin video:");
    }
  };
};
// Delete Video Categories
export const deleteAdminVideoSubCategory = (formData) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${config.api.url}videos/deleteAdminVideSubCategories/${formData}`
    );
    if (response.status === 200) {
      toast.success("Video Sub Category Delete Sucesfully");
    } else {
      console.error("Error deleting Video Sub Category:", response.data.error);
      toast.error(response.data.error);
    }
  } catch (error) {
    toast.error(error);
  }
};

//============================ Video Sub Categories One ====================================
// get video sub Category one Api
export const adminVideoSubCategoryData = () => async (dispatch) => {
  try {
    const response = await axios.get(`${config.api.url}videos/getSubCategOne`);
    dispatch({ type: FETCH_SUB_CATEGORY_ONE_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error Notification Category fetching data:", error);
    dispatch({ type: FETCH_SUB_CATEGORY_ONE_FAILURE, error });
  }
};
// Update Sub Categories One
export const updateAdminSubCategoryOne = (formData) => {
  return async (dispatch) => {
    const updatedAdminSubCategoryOneData = {
      id: formData.id,
      name: formData.name,
      videoCategoryId: formData.videoCategoryId,
      createdAt: formData.createdAt,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdminSubCategoryOneData),
    };

    try {
      const response = await fetch(
        `${config.api.url}videos/updateAdminSubCategoriesOne/${formData.id}`,
        data
      );
      if (response.ok) {
        toast.success("SubCategoryOne Details Update Sucessfully");
      } else {
        toast.error("SubCategoryOne Details Update failed");
      }
    } catch (error) {
      console.error("Error SubCategoryOne update data:", error);
      toast.error("SubCategoryOne updating admin video:");
    }
  };
};
// Delete subCategoryOne API
export const deleteAdminSubCategoryOne = (formData) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${config.api.url}videos/deleteAdminSubCategoriesOne/${formData}`
    );
    if (response.status === 200) {
      toast.success("SubCategoriesOne Delete Sucesfully");
    } else {
      console.error("Error deleting SubCategoriesOne:", response.data.error);
      toast.error(response.data.error);
    }
  } catch (error) {
    toast.error(error);
  }
};
// ADD subCategoryOne API
export const addSubCategoryOne = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${config.api.url}videos/addAdminSubCategoriesOne`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch(adminVideoSubCategoryData(response.data));
    toast.success("SubCategoryOne Data Added Sucesfully");
  } catch (error) {
    dispatch(addSubCategoryOneFailure(error));
    toast.error(error, "SubCategoryOne Data Not Added");
  }
};

//============================ Video Sub Categories Two ====================================
// get video sub Category two Api
export const adminSubCategoryTwoData = () => async (dispatch) => {
  try {
    const response = await axios.get(`${config.api.url}videos/getSubCategTwo`);
    dispatch({ type: FETCH_SUB_CATEGORY_TWO_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error Notification Category fetching data:", error);
    dispatch({ type: FETCH_SUB_CATEGORY_TWO_FAILURE, error });
  }
};
// Update Sub Categories Two
export const updateAdminSubCategoryTwo = (formData) => {
  return async (dispatch) => {
    const updatedAdminSubCategoryTwoData = {
      id: formData.id,
      name: formData.name,
      subCategOneId: formData.subCategOneId,
      createdAt: formData.createdAt,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdminSubCategoryTwoData),
    };

    try {
      const response = await fetch(
        `${config.api.url}videos/updateAdminSubCategoriesTwo/${formData.id}`,
        data
      );
      if (response.ok) {
        toast.success("SubCategoryTwo Details Update Sucessfully");
      } else {
        toast.error("SubCategoryTwo Details Update failed");
      }
    } catch (error) {
      console.error("Error SubCategoryTwo update data:", error);
      toast.error("SubCategoryTwo updating admin video:");
    }
  };
};
// Delete subCategoryTwo API
export const deleteAdminSubCategoryTwo = (formData) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${config.api.url}videos/deleteAdminSubCategoriesTwo/${formData}`
    );
    if (response.status === 200) {
      toast.success("SubCategoriesTwo Delete Sucesfully");
    } else {
      console.error("Error deleting SubCategoriesTwo:", response.data.error);
      toast.error(response.data.error);
    }
  } catch (error) {
    toast.error(error);
  }
};
// ADD subCategoryTwo API
export const addSubCategoryTwo = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${config.api.url}videos/addAdminSubCategoryTwo`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    toast.success("SubCategoryTwo Data Added Sucesfully");
  } catch (error) {
    dispatch(addSubCategoryOneFailure(error));
    toast.error(error, "SubCategoryTwo Data Not Added");
  }
};

// ======================== Admin Video Categpry Request =======================
// Admin Get Video Category Request
export const adminVideoCategoryRequestData = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${config.api.url}videos/getVideoCategoryRequest`
    );
    dispatch({
      type: FETCH_VIDEO_CATEGORY_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error Notification Category fetching data:", error);
    dispatch({ type: FETCH_VIDEO_CATEGORY_REQUEST_FAILURE, error });
  }
};
// Admin Update Video Category Request
export const updateAdminVideoCategoryRequest = (formData) => {
  return async (dispatch) => {
    const updatedAdminVideoCategoryRequestData = {
      id: formData.id,
      categoryTitle: formData.categoryTitle,
      categoryDescription: formData.categoryDescription,
      requestedUserEmail: formData.requestedUserEmail,
      createdAt: formData.createdAt,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdminVideoCategoryRequestData),
    };

    try {
      const response = await fetch(
        `${config.api.url}videos/updateAdminVideoCategoryRequest/${formData.id}`,
        data
      );
      if (response.ok) {
        toast.success("Video Category Request Details Update Sucessfully");
      } else {
        toast.error("Video Category Request Details Update failed");
      }
    } catch (error) {
      console.error("Error Video Category Request update data:", error);
      toast.error("Video Category Request updating admin video:");
    }
  };
};
// Admin Delete Video Category Request
export const deleteAdminVideoCategoryRequestData =
  (formData) => async (dispatch) => {
    try {
      const response = await axios.delete(
        `${config.api.url}videos/deleteAdminVideoCategoryRequest/${formData}`
      );
      if (response.status === 200) {
        toast.success("VideoCategoryRequest Delete Sucesfully");
      } else {
        console.error(
          "Error deleting VideoCategoryRequest:",
          response.data.error
        );
        toast.error(response.data.error);
      }
    } catch (error) {
      toast.error(error);
    }
  };

//========================= Admin  ThreadData ==========================
// Get Threads Table Data
export const adminThreadsData = () => async (dispatch) => {
  try {
    const response = await axios.get(`${config.api.url}thread/getThreadData`);
    dispatch({ type: FETCH_THREADS_TABLE_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error Notification Category fetching data:", error);
    dispatch({ type: FETCH_THREADS_TABLE_FAILURE, error });
  }
};
// Update Threads Table Data
export const updateAdminThreadsData = (threadsData) => {
  return async (dispatch) => {
    const updatedAdminThreadsData = {
      id: threadsData.id,
      userId: threadsData.userId,
      URLSources: threadsData.URLSources,
      featuredHome: threadsData.featuredHome,
      featuredHomeOrder: threadsData.featuredHomeOrder,
      featuredWarriorPortalOrder: threadsData.featuredWarriorPortalOrder,
      threadBody: threadsData.threadBody,
      createdAt: threadsData.createdAt,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdminThreadsData),
    };

    try {
      const response = await fetch(
        `${config.api.url}thread/updateAdminThreads/${threadsData.id}`,
        data
      );
      if (response.ok) {
        toast.success("thread Details Update Sucessfully");
      } else {
        toast.error("thread Details Update failed");
      }
    } catch (error) {
      console.error("Error thread update data:", error);
      toast.error("Thread updating admin Thread:");
    }
  };
};
// Delete Threads Table Data
export const deleteAdminThreadsData = (threadsData) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${config.api.url}thread/deleteAdminThreads/${threadsData}`
    );
    if (response.status === 200) {
      toast.success("thread Delete Sucesfully");
    } else {
      console.error("Error deleting thread:", response.data.error);
      toast.error(response.data.error);
    }
  } catch (error) {
    toast.error(error);
  }
};

//======================== Admin Thread Images Data ====================
//Get Thread Images Data
export const adminThreadImagesData = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${config.api.url}thread/getAdminThreadImagesData`
    );
    dispatch({
      type: FETCH_THREAD_IMAGES_TABLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error Threads Images fetching data:", error);
    dispatch({ type: FETCH_THREAD_IMAGES_TABLE_FAILURE, error });
  }
};
//Update Threads Images Data
export const updateAdminThreadImagesData = (threadsData) => {
  return async (dispatch) => {
    const updatedAdminThreadImagesData = {
      id: threadsData.id,
      url: threadsData.url,
      threadId: threadsData.threadId,
      order: threadsData.order,
      fileSize: threadsData.fileSize,
      createdAt: threadsData.createdAt,
      updatedAt: threadsData.updatedAt,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedAdminThreadImagesData),
    };

    try {
      const response = await fetch(
        `${config.api.url}thread/updateAdminThreadImages/${threadsData.id}`,
        data
      );
      if (response.ok) {
        toast.success("thread Images Update Sucessfully");
      } else {
        toast.error("thread Images Update failed");
      }
    } catch (error) {
      console.error("Error thread Images update data:", error);
      toast.error("Thread Images updating admin :");
    }
  };
};
// Delete Threads Images Data
export const deleteAdminThreadImagesData =
  (threadsData) => async (dispatch) => {
    try {
      const response = await axios.delete(
        `${config.api.url}thread/deleteAdminThreadImages/${threadsData}`
      );
      if (response.status === 200) {
        toast.success("thread Images Delete Sucesfully");
      } else {
        console.error("Error deleting thread Images", response.data.error);
        toast.error(response.data.error);
      }
    } catch (error) {
      toast.error(error);
    }
  };

//======================== Admin Vistor badge  Data ====================
//Get Video Acces Images Data
export const adminGetBadge = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${config.api.url}badge/adminGetVistorbadge`
    );
    dispatch({
      type: FETCH_ADMIN_VISTOR_BADGE,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error Badge  fetching data:", error);
    dispatch({ type: FETCH_ADMIN_VISTOR_BADGE_FAILURE, error });
  }
};

// Post Data VistorBadge
export const addVistorBadge = (visBdageData) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${config.api.url}badge/addAdminVistorBadge`,
      visBdageData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch(addVistorbadgeSuccess(response.data));
    dispatch(adminGetBadge(response.data));

    toast.success("badge Data Added Sucesfully");
  } catch (error) {
    dispatch(addVideoCategoryFailure(error));
    toast.error(error, "badge Data Not Added");
  }
};

// Update VistorBadge
export const updateAdminVistorBadge = (formData) => {
  return async (dispatch) => {
    const updatedVistorBadgeData = {
      id: formData.id,
      name: formData.name,
      visitorBadgeType: formData.visitorBadgeType,
      // imgPath: formData.imgPath,
      createdAt: formData.createdAt,
      updatedAt: formData.updatedAt,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedVistorBadgeData),
    };

    try {
      const response = await fetch(
        `${config.api.url}badge/updateAdminVistorBadge/${formData.id}`,
        data
      );
      if (response.ok) {
        toast.success("VistorBadge Data  Update Sucessfully");
      } else {
        toast.error("VistorBadge Data Update failed");
      }
    } catch (error) {
      console.error("Error VistorBadge Data update data:", error);
      toast.error(" VistorBadge Data updating admin video:");
    }
  };
};

// Delete VistorBadge
export const deleteVistorBadge = (formData) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${config.api.url}badge/deleteAdminVistorBadge/${formData}`
    );
    if (response.status === 200) {
      toast.success("Vistor Badge Delete Sucesfully");
    } else {
      console.error("Error deleting Vistor Badge:", response.data.error);
      toast.error(response.data.error);
    }
  } catch (error) {
    toast.error(error);
  }
};

//======================== Admin Video Acces overlay Data ====================
//Get Video Acces overlay Data
export const adminVideoAccesOverlay = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${config.api.url}videos/adminAccessOverlays`
    );
    dispatch({
      type: FETCH_VIDEO_ACCESS_OVERLAY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error(" Video Acces overlay Data:", error);
    dispatch({ type: FETCH_VIDEO_ACCESS_OVERLAY_FAILURE, error });
  }
};
//Post Video Acces overlay Data
export const addVideoOverlay = (videoAccesData) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${config.api.url}videos/adminAddNewOverlays`,
      videoAccesData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch(adminVideoAccesOverlay(response.data));
    toast.success("Video Acces overlay Data Added Sucesfully");
  } catch (error) {
    toast.error(error, "Video Acces overlay Data Not Added");
  }
};
//Update Video Acces overlay Data
export const updateVideosAccess = (overlayData, token) => {
  return async (dispatch) => {
    const upAccessoverlayData = {
      name: overlayData.name,
      description: overlayData.description,
      imgPathMember: overlayData.imgPathMember,
      imgPathFreeLoader: overlayData.imgPathFreeLoader,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(upAccessoverlayData),
    };

    try {
      const response = await fetch(
        `${config.api.url}videos/updateVideoAccessOverlay/${overlayData.keyVideoAccess}`,
        data
      );

      if (response.ok) {
        toast.success("OverlayData Details Update Sucessfully");
        const updatedVideoOverlayData = await response.json();
        dispatch(updateVideoAccessOverlay(updatedVideoOverlayData));
      } else {
        toast.error("OverlayData Details Update failed");
      }
    } catch (error) {
      toast.error("OverlayData updating :", error);
    }
  };
};

// Delete Video Acces overlay Data
export const deleteVideoAccesoverlay = (overlayData) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${config.api.url}videos/deleteAccessOverlay/${overlayData}`
    );
    if (response.status === 200) {
      toast.success("Video Acces overlay Delete Sucesfully");
    } else {
      console.error("Error deleting Video Acces overlay:", response.data.error);
      toast.error(response.data.error);
    }
  } catch (error) {
    toast.error(error);
  }
};

//======================== dmin Video Wideget Banner Slide ====================
//Get Video Acces Images Data
export const adminGetBannerSlider = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${config.api.url}profile-element/adminWidgetBannerSlider`
    );
    dispatch({
      type: FETCH_ADMIN_BANNER_SLIDER,
      payload: response.data,
    });
  } catch (error) {
    console.error("Error Badge  fetching data:", error);
    dispatch({ type: FETCH_ADMIN_BANNER_SLIDER_FAILURE, error });
  }
};

// Update Widget Banner slider
export const updateWidgetBanner = (formData) => {
  return async (dispatch) => {
    const updatedWidgetBanner = {
      id: formData.id,
      title: formData.title,
      description: formData.description,
      order: formData.order,
      userId: formData.userId,
      categoryId: formData.categoryId,
      bannerLocation: formData.bannerLocation,
      bannerImgPathInternal_XL_1920x400:
        formData.bannerImgPathInternal_XL_1920x400,

      bannerImgPathInternal_L_1366x400:
        formData.bannerImgPathInternal_L_1366x400,
      bannerImgPathInternal_L_1280x400:
        formData.bannerImgPathInternal_L_1280x400,
      bannerImgPathInternal_L_1024x400:
        formData.bannerImgPathInternal_L_1024x400,
      bannerImgPathInternal_MD_834x400:
        formData.bannerImgPathInternal_MD_834x400,
      bannerImgPathInternal_MD_768x400:
        formData.bannerImgPathInternal_MD_768x400,
      bannerImgPathInternal_SM_428x250:
        formData.bannerImgPathInternal_SM_428x250,
      bannerImgPathInternal_SM_414x250:
        formData.bannerImgPathInternal_SM_414x250,
      bannerImgPathInternal_SM_375x250:
        formData.bannerImgPathInternal_SM_375x250,
    };

    const data = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedWidgetBanner),
    };
    try {
      const response = await fetch(
        `${config.api.url}profile-element/updateAdminBanner/${formData.id}`,
        data
      );
      if (response.ok) {
        toast.success("Wideget Banner  Update Sucessfully");
      } else {
        toast.error("Wideget Banner  Update failed");
      }
    } catch (error) {
      console.error("Error Wideget Banner  update data:", error);
      toast.error(" Wideget Banner  updating admin video:");
    }
  };
};

// Delete Widget Banner Slider
export const deleteWidgetBannerSlider = (formData) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `${config.api.url}profile-element/deleteAdminWidgetBanner/${formData}`
    );
    if (response.status === 200) {
      toast.success("Wideget Banner Delete Sucesfully");
    } else {
      console.error("Error deleting Wideget Banner:", response.data.error);
      toast.error(response.data.error);
    }
  } catch (error) {
    toast.error(error);
  }
};

// Post Widget Banner Slider Data
export const addPostWidgetBanner = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${config.api.url}profile-element/adminPostWidgetBanner`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch(addPostWidBannerSuccess(response.data));
    dispatch(adminGetBannerSlider(response.data));
    toast.success("Widget Banner Slider Added Sucesfully");
  } catch (error) {
    const status = error.response.status;
    toast.error(error, "Widget Banner Slider Not Added");
    if (status === 400) {
      toast.error("Something is wrong UserID");
    } else {
      toast.error(`Error ${status}: Widget Banner Slider Not Added`);
    }
  }
};

// Get featured creators on homepage
export const getHomeFeaturedCreators = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_HOME_FEATURED_CREATORS_REQUEST,
    });

    const res = await api.get("admin/creator/featured");

    dispatch({
      type: GET_HOME_FEATURED_CREATORS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_HOME_FEATURED_CREATORS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
