import React from "react";
import styled from "styled-components";
import SkeletonElement from "../SkeletonElement";
import SkeletonShimmer from "../SkeletonShimmer";

const Moment = () => {
  return (
    <MomentSkeletonStyled>
      <div className="content">
        <div className="content__moment">
          <SkeletonShimmer />

          <div className="content__moment--info">
            <div className="left">
              <SkeletonElement type="avatarSM" mr="1rem" varient="dark" />
            </div>

            <div className="right">
              <SkeletonElement
                className="title"
                type="title"
                varient="dark"
                mb=".5rem"
              />
              <SkeletonElement type="paragraph" varient="dark" />
            </div>
          </div>
        </div>
      </div>
    </MomentSkeletonStyled>
  );
};

export default Moment;

const MomentSkeletonStyled = styled.div`
  .content {
    &__moment {
      height: calc(100vh - 75px - 24px * 3);
      width: calc(calc(100vh - 75px - 24px * 3) * 0.5625);
      margin: 0 auto;
      min-height: 560px;
      min-width: 320px;
      background-color: #202020;
      border-radius: 1rem;
      overflow: hidden;
      position: relative;
      border: 1px solid rgba(255, 255, 255, 0.09);

      &--info {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 5rem;
        width: 100%;
        padding: 0 1rem;
        display: flex;
        align-items: center;

        .right {
          width: calc(100% - 4.5rem);
        }

        .title {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      &__moment {
        width: 95%;
        margin: 0 auto;
        height: 80vh;
        border-radius: 0.5rem;
      }
    }
  }
`;
