import React, { useState } from "react";
import { Row, Col } from "react-grid-system";
import styled, { css } from "styled-components";
import BigText from "../StyleComponents/BigText";
import SmallText from "../StyleComponents/SmallText";
import ArrowRight from "../../icons/arrow-right-black.svg";
import Paragraph from "../StyleComponents/Paragraph";
import LandingPageButton from "../StyleComponents/Button";
import Button from "../../Elements/Button";
import FacebookIcon from "../../icons/facebook.svg";
import TwitterIcon from "../../icons/twitter.svg";
import InstagramIcon from "../../icons/instagram.svg";
import { useSelector } from "react-redux";

const Footer = ({ data }) => {
  const [showContactForm, setShowContactForm] = useState(false);
  const user = useSelector((state) => state.user);
  const { data: siteBranding } = useSelector((state) => state.branding);

  const year = new Date().getFullYear();

  return (
    <FooterStyled marginBottom={user?.id ? true : false}>
      <div className="content">
        <div className="content__top">
          <Row>
            <Col md={6} sm={12}>
              <div className="content__top--left">
                <SmallText>Get in touch</SmallText>
                <BigText className="title">
                  get our <br /> newsletter
                </BigText>

                <div className="inputBox">
                  <div className="inputBox__input">
                    <input type="text" placeholder="Your email" />
                  </div>

                  <div className="inputBox__submitBtn">
                    <img src={ArrowRight} alt="" />
                  </div>
                </div>
              </div>
            </Col>

            <Col md={6} sm={12}>
              <div className="content__top--right">
                <SmallText>Have any questions ?</SmallText>
                <BigText className="title">Contact Us</BigText>
                <Paragraph>
                  Freeloader is here to help you 24*7. Have questions or
                  suggestions? Please drop us a line! We will listen to your
                  feedback.
                </Paragraph>
                {!showContactForm && (
                  <LandingPageButton
                    onClick={() => setShowContactForm(true)}
                    className="contactusBtn"
                  >
                    Contact us
                  </LandingPageButton>
                )}

                <form className={`form ${showContactForm && "formShow"}`}>
                  <div className="form__input">
                    <input type="text" placeholder="Your name" />
                  </div>

                  <div className="form__input">
                    <input type="text" placeholder="Your email" />
                  </div>

                  <div className="form__input">
                    <input type="text" placeholder="Subject" />
                  </div>

                  <div className="form__input">
                    <textarea type="text" placeholder="Message" />
                  </div>

                  <Button
                    mode="primary"
                    width="fluid"
                    variant="round"
                    size="medium"
                    className="form__submit"
                  >
                    Send
                  </Button>

                  <SmallText
                    onClick={() => setShowContactForm(false)}
                    className="form__close"
                  >
                    Close
                  </SmallText>
                </form>
              </div>
            </Col>
          </Row>
        </div>

        <div className="content__bottom">
          <Row>
            <Col md={6} sm={12}>
              <div className="content__bottom--left">
                <SmallText className="item">Privacy Policy</SmallText>
                <SmallText className="item">Disclaimer</SmallText>
                <SmallText className="item">Terms & Conditions</SmallText>
              </div>
            </Col>

            <Col md={6} sm={12}>
              <div className="content__bottom--right">
                {(data?.social_facebook ||
                  data?.social_instagram ||
                  data?.social_twitter) && (
                  <div className="socialMedia">
                    <SmallText className="socialMedia__title">
                      See what we are up to
                    </SmallText>

                    <div className="socialMedia__list">
                      {data?.social_facebook && (
                        <a href={data.social_facebook} target="_blank">
                          <div className="socialMedia__list--item">
                            <img src={FacebookIcon} alt="" />
                          </div>
                        </a>
                      )}

                      {data?.social_instagram && (
                        <a href={data.social_instagram} target="_blank">
                          <div className="socialMedia__list--item">
                            <img src={InstagramIcon} alt="" />
                          </div>
                        </a>
                      )}

                      {data?.social_twitter && (
                        <a href={data.social_twitter} target="_blank">
                          <div className="socialMedia__list--item">
                            <img src={TwitterIcon} alt="" />
                          </div>
                        </a>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>

        <div className="content__copyright">
          <SmallText id="copyright" className="content__copyright--text">
            Copyright © {year}{" "}
            {siteBranding?.copyright_name
              ? siteBranding.copyright_name
              : siteBranding?.site_name}
            . All Rights Reserved.
          </SmallText>
        </div>
      </div>
    </FooterStyled>
  );
};

export default Footer;

const FooterStyled = styled.div`
  min-height: 50vh;
  background: ${(props) => props.theme.primaryGradient};
  margin-top: 3rem;

  ${(props) =>
    props.marginBottom &&
    props.marginBottom === true &&
    css`
      .content {
        margin: 0 auto 3.5rem auto !important;
      }
    `}

  .content {
    width: 80%;
    margin: 0 auto;
    padding-top: 4rem;

    &__top {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding-bottom: 2rem;

      .title {
        margin: 1rem 0;
      }

      .button {
        margin-top: 1.5rem;
      }

      &--left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-right: 2rem;

        .inputBox {
          width: 100%;
          margin-top: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &__input {
            width: calc(100% - 5rem);

            input {
              width: 100%;
              border: none;
              border-bottom: 2px solid #fff;
              outline: none;
              font-size: 0.95rem;
              font-family: ${(props) => props.theme.montserrat}, sans-serif;
              background-color: transparent;
              padding: 0.5rem 0;
              color: #fff;

              &::placeholder {
                color: #fff;
                text-transform: capitalize;
              }
            }
          }

          &__submitBtn {
            height: 3.2rem;
            width: 3.2rem;
            border-radius: 50%;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease;
            cursor: pointer;

            img {
              height: 0.7rem;
              width: auto;
            }
          }
        }
      }

      &--right {
        .contactusBtn {
          margin-top: 2rem;
        }

        .formShow {
          opacity: 1 !important;
          height: auto !important;
          margin-top: 2rem;
          padding: 2rem;
        }

        .form {
          background-color: #202020;

          border-radius: 12px;
          transition: all 0.5s ease;
          opacity: 0;
          height: 0;
          overflow: hidden;

          &__submit {
            font-size: 0.8rem;
            font-weight: 500;
            letter-spacing: 0.09px;
          }

          &__input {
            input,
            textarea {
              width: 100%;
              border: 1px solid rgba(255, 255, 255, 0.09);
              border-bottom: 2px solid rgba(255, 255, 255, 0.4);
              outline: none;
              font-size: 0.85rem;
              font-family: ${(props) => props.theme.montserrat}, sans-serif;
              background-color: #181818;
              padding: 0.7rem 0.5rem;
              color: rgba(255, 255, 255, 0.5);
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;

              &::placeholder {
                color: rgba(255, 255, 255, 0.5);
                text-transform: capitalize;
              }
            }

            textarea {
              resize: none;
              height: 8rem;
            }

            &:not(:last-child) {
              margin-bottom: 1.5rem;
            }
          }

          &__close {
            display: flex;
            justify-content: center;
            color: rgba(255, 255, 255, 0.5);
            font-weight: 500;
            text-decoration: underline;
            margin-top: 0.5rem;
            cursor: pointer;
          }
        }
      }
    }

    &__bottom {
      padding: 1rem 0.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      &--left {
        .item {
          cursor: pointer;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 0%;
            background-color: #fff;
            transition: all 0.3s ease;
          }

          &:hover:before {
            width: 100%;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }

      &--right {
        .socialMedia {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          &__title {
            margin-right: 2rem;
          }

          &__list {
            display: flex;
            align-items: center;

            &--item {
              height: 2.5rem;
              width: 2.5rem;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: transparent;
              position: relative;
              cursor: pointer;
              z-index: 2;

              &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                background-color: #1d1d1f;
                transform-origin: center;
                scale: 0;
                opacity: 0;
                transition: all 0.4s ease;
                z-index: -1;
              }

              &:hover:before {
                scale: 1;
                opacity: 1;
              }

              img {
                height: 0.9rem;
                width: auto;
              }

              &:not(:last-child) {
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
    }

    &__copyright {
      text-align: center;
      padding: 1rem 0;

      &--text {
        font-size: 0.75rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      width: 90%;
      padding-top: 5rem;

      &__top {
        &--left {
          margin-bottom: 3rem;
          padding-right: 0;
        }
      }

      &__bottom {
        &--left {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1.5rem;
        }

        .socialMedia {
          justify-content: space-between;
        }
      }
    }
  }

  @media screen and (max-width: 414px) {
    .content {
      width: 90%;
      padding-top: 5rem;

      &__top {
        &--left {
          .inputBox {
            &__submitBtn {
              height: 2.5rem;
              width: 2.5rem;
            }
          }
        }
      }

      &__bottom {
        padding: 0.7rem 0;

        &--right {
          .socialMedia {
            &__list {
              &--item {
                height: 2rem;
                width: 2rem;

                img {
                  height: 0.6rem;
                }
              }
            }
          }
        }
      }

      &__copyright {
        padding: 0.7rem 0;

        &--text {
          font-size: 0.65rem;
        }
      }
    }
  }
`;
