const initialState = {
  acessOverlaytData: null,
  error: null,
};

const acessOverlayTable = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_VIDEO_ACCESS_OVERLAY_SUCCESS":
      return {
        ...state,
        acessOverlaytData: action.payload.data,
        error: null,
      };
    case "FETCH_VIDEO_ACCESS_OVERLAY_FAILURE":
      return {
        ...state,
        acessOverlaytData: null,
        error: action.error,
      };

    default:
      return state;
  }
};

export default acessOverlayTable;
