import React from "react";
import styled from "styled-components";
import SkeletonElement from "../SkeletonElement";

const MarketingBanner = () => {
  return (
    <MarketingBannerStyled>
      <SkeletonElement type="banner" />
    </MarketingBannerStyled>
  );
};

export default MarketingBanner;

const MarketingBannerStyled = styled.div`
  width: 90%;
  margin: 0 auto 4rem auto;

  @media screen and (max-width: 480px) {
    margin: 0 auto 2rem auto;
  }
`;
