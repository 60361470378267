import React, { useEffect, useState } from "react";
import AdminSidebar from "../AdminSidebar";
import styled from "styled-components";
import InputV2 from "../../components/Elements/InputV2";
import FileUpload from "../../components/Elements/FileUpload";
import * as Yup from "yup";
import Button from "../../components/Elements/Button";
import config from "../../config";
import { FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { postBranding, postBrandingV2 } from "../../actions/branding";
import { SITE_BRANDING_ERROR_RESET } from "../../actions/branding/type";
import GradientPicker from "./GradientPicker";

const Schema = Yup.object().shape({
  siteName: Yup.string().required("Site name is required."),
  marketingPageUrl: Yup.string().required("Marketing page url is required."),
});

const SiteBranding = () => {
  const dispatch = useDispatch();
  const {
    data: siteBranding,
    loading: siteBrandingLoading,
    error: siteBrandingError,
    success: siteBrandingSuccess,
  } = useSelector((state) => state.branding);
  const [logoLight, setLogoLight] = useState({
    previewUrl: null,
    file: null,
    filePreview: true,
  });
  const [logoDark, setLogoDark] = useState({
    previewUrl: null,
    file: null,
    filePreview: true,
  });
  const [loadingScreenLogo, setLoadingScreenLogo] = useState({
    previewUrl: null,
    file: null,
    filePreview: true,
  });

  const [primaryGradient, setPrimaryGradient] = useState(
    "linear-gradient(29deg, #f99a2d, #f65c8b 71%)"
  );

  useEffect(() => {
    if (siteBrandingSuccess) {
      toast.success("Site branding updated successfully.");
      dispatch({
        type: SITE_BRANDING_ERROR_RESET,
      });
    }
  }, [siteBrandingError, siteBrandingSuccess]);
  useEffect(() => {
    if (siteBranding) {
      formik.setValues({
        siteName: siteBranding.site_name,
        copyrightName: siteBranding.copyright_name,
        marketingPageUrl: siteBranding.marketing_page_url,
      });
      setLogoLight({
        filePreview: false,
        previewUrl: siteBranding.logo_light,
      });
      setLogoDark({
        filePreview: false,
        previewUrl: siteBranding.logo_dark,
      });
      setLoadingScreenLogo({
        filePreview: false,
        previewUrl: siteBranding.loadingScreenLogo,
      });
      setPrimaryGradient(siteBranding?.primaryGradient);
    }
  }, [siteBranding]);

  const formik = useFormik({
    initialValues: {
      siteName: "",
      copyrightName: "",
      marketingPageUrl: "",
    },
    validationSchema: Schema,

    onSubmit: async (values) => {
      if (
        !siteBranding?.logo_dark &&
        !siteBranding?.logo_light &&
        !logoDark?.file &&
        !logoLight?.file
      ) {
        toast.error("Please provide Website Logos as well.");
        return;
      }

      const obj1 = {
        site_name: values.siteName,
        copyright_name: values.copyrightName,
        marketing_page_url: values.marketingPageUrl,
        primaryGradient,
        logo_light: logoLight?.previewUrl,
        logo_dark: logoDark?.previewUrl,
        loadingScreenLogo: loadingScreenLogo.previewUrl,
      };

      const obj2 = {
        site_name: values.siteName,
        copyright_name: values.copyrightName,
        logo_light: logoLight.previewUrl,
        logo_dark: logoDark.previewUrl,
        loadingScreenLogo: loadingScreenLogo.previewUrl,
        marketing_page_url: values.marketingPageUrl,
        primaryGradient,
      };

      if (logoDark?.file || logoLight?.file || loadingScreenLogo?.file) {
        dispatch(
          postBranding(
            obj1,
            logoLight?.file,
            logoDark?.file,
            loadingScreenLogo?.file
          )
        );
      } else {
        dispatch(postBrandingV2(obj2));
      }

      // if (logoDark.file || logoLight.file) {
      //   dispatch(postBranding(obj1, logoLight?.file, logoDark?.file));
      // } else if (logoDark.file && logoLight.file) {
      //   dispatch(postBranding(obj1, logoLight.file, logoDark.file));
      // } else {
      //   dispatch(postBrandingV2(obj2));
      // }
    },
  });

  useEffect(() => {
    if (formik.errors?.siteName) {
      window.scrollTo(0, 0);
    }
  }, [formik.errors]);

  const handleLogoUpload = async (e, type) => {
    const file = e.target.files[0];

    if (file) {
      const previewUrl = URL.createObjectURL(file);

      if (type === "light") {
        setLogoLight({
          previewUrl,
          file,
          filePreview: true,
        });
        return;
      }

      if (type === "dark") {
        setLogoDark({
          previewUrl,
          file,
          filePreview: true,
        });
        return;
      }

      if (type === "loadingScreen") {
        setLoadingScreenLogo({
          previewUrl,
          file,
          filePreview: true,
        });
        return;
      }
    }
  };

  return (
    <FormikProvider value={formik}>
      <SiteBrandinStyled>
        <AdminSidebar />
        <div className="content">
          <form onSubmit={formik.handleSubmit}>
            <div className="content__form">
              <div className="content__form--item">
                <InputV2
                  name="siteName"
                  type={"text"}
                  id={"siteName"}
                  label={"Site Name"}
                  placeholder={"Ex. Isutra"}
                  value={formik.values.siteName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={
                    formik.touched.siteName && formik.errors.siteName
                  }
                />
              </div>

              <div className="content__form--item">
                <InputV2
                  type={"text"}
                  id={"copyrightName"}
                  label={"Copyright Name"}
                  placeholder={"Ex. Isutra LLC"}
                  value={formik.values.copyrightName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>

              <div className="content__form--item">
                <InputV2
                  name="marketingPageUrl"
                  type={"text"}
                  id={"marketingPageUrl"}
                  label={"Marketing Page URL"}
                  placeholder={"Ex. /whatisfreeloader"}
                  value={formik.values.marketingPageUrl}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={
                    formik.touched.marketingPageUrl &&
                    formik.errors.marketingPageUrl
                  }
                />
              </div>

              <div className="content__form--item">
                <FileUpload
                  bg={primaryGradient}
                  id={"logoLight"}
                  title={"Logo White"}
                  explain={"Upload white version of your logo."}
                  onChange={(e) => {
                    handleLogoUpload(e, "light");
                  }}
                  display={{
                    url: logoLight.previewUrl,
                    filePreview: logoLight.filePreview,
                    noBg: false,
                  }}
                />
              </div>

              <div className="content__form--item">
                <FileUpload
                  bg={primaryGradient}
                  id={"logoDark"}
                  title={"Logo Dark"}
                  explain={"Upload dark version of your logo."}
                  onChange={(e) => {
                    handleLogoUpload(e, "dark");
                  }}
                  display={{
                    url: logoDark.previewUrl,
                    filePreview: logoDark.filePreview,
                    noBg: false,
                  }}
                />
              </div>

              <div className="content__form--item">
                <FileUpload
                  bg={primaryGradient}
                  id={"loadingScreenLogo"}
                  title={"Loading Screen Logo"}
                  explain={"Upload logo for the loading screen."}
                  onChange={(e) => {
                    handleLogoUpload(e, "loadingScreen");
                  }}
                  display={{
                    url: loadingScreenLogo.previewUrl,
                    filePreview: loadingScreenLogo.filePreview,
                    noBg: false,
                  }}
                />
              </div>

              <div className="content__form--item">
                <GradientPicker
                  value={primaryGradient}
                  setValue={setPrimaryGradient}
                />
              </div>

              <div className="content__form--item">
                <Button
                  bg={primaryGradient}
                  type={"submit"}
                  className="content__form--item-submit"
                  mode="primary"
                  width="fluid"
                  variant="round"
                  size="medium"
                >
                  {siteBrandingLoading ? "Please Wait..." : "Save Changes"}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </SiteBrandinStyled>
    </FormikProvider>
  );
};

export default SiteBranding;

const SiteBrandinStyled = styled.div`
  display: flex;
  overflow-x: hidden;
  background: #212123;
  height: auto;
  z-index: 1000;

  .content {
    padding: 3rem;
    width: 100%;
    margin-bottom: 3.5rem;

    &__form {
      width: 60%;
      margin: 0 auto;

      &--item {
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }

        &-submit {
          margin-top: 2.5rem !important;
        }
      }
    }
  }
`;
