import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import styled from "styled-components";
import SearchOutlineIcon from "../icons/searchOutline.svg";
import { GlobalContext } from "../../context/GlobalContext";
import CloseIcon from "../icons/close.svg";
import HistoryIcon from "../icons/history.svg";
import { useHistory } from "react-router-dom";

const SearchModel = ({ text }) => {
  const { showSearchModel, setShowSearchModel } = useContext(GlobalContext);
  const inputRef = useRef();
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [activeInput, setActiveInput] = useState(false);
  const [searchHistory, setSearchHistory] = useState(
    JSON.parse(localStorage.getItem("search_history"))
  );

  useEffect(() => {
    if (showSearchModel) {
      if (localStorage.getItem("search_history") !== null) {
        const searches = JSON.parse(localStorage.getItem("search_history"));
        setSearchHistory(searches);
      }
    }
  }, [showSearchModel]);

  useEffect(() => {
    document.addEventListener("keydown", escHandler, false);

    return () => {
      document.removeEventListener("keydown", escHandler, false);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", searchHandler, false);

    return () => {
      document.removeEventListener("keydown", searchHandler, false);
    };
  }, [searchText]);

  useEffect(() => {
    if (showSearchModel) {
      activeInputHandler();
    }
  }, [showSearchModel]);

  const activeInputHandler = () => {
    setActiveInput(true);
    inputRef.current.focus();
  };

  const escHandler = useCallback((event) => {
    if (event.key === "Escape") {
      setShowSearchModel(false);
    }
  });

  const closeHandler = () => {
    setShowSearchModel(false);
  };

  const searchHandler = (event) => {
    if (event.key === "Enter" && searchText !== "") {
      // store the search text into localstorage
      if (localStorage.getItem("search_history") === null) {
        const array = [
          {
            text: searchText,
          },
        ];
        localStorage.setItem("search_history", JSON.stringify(array));
      } else {
        const previousSearches = JSON.parse(
          localStorage.getItem("search_history")
        );

        const isAlreadyExist = previousSearches.find(
          (el) => el.text === searchText
        );

        if (!isAlreadyExist) {
          previousSearches.push({
            text: searchText,
          });

          localStorage.setItem(
            "search_history",
            JSON.stringify(previousSearches)
          );
        }
      }

      history.push(`/search/query=${searchText}`);
      closeHandler();
    }
  };

  const removeSavedSearchHandler = (e, text) => {
    e.stopPropagation();

    const newSearches = searchHistory.filter((item) => item.text !== text);
    setSearchHistory(newSearches);
    localStorage.setItem("search_history", JSON.stringify(newSearches));
  };

  return (
    <SearchModelStyled
      className={`${showSearchModel ? "showSearchModel__Opened" : null}`}
    >
      <div className="searchContent">
        <div onClick={closeHandler} className="searchContent__close">
          <img src={CloseIcon} alt="" />
        </div>

        <div className="searchContent__searchBox">
          <div
            className={`searchContent__searchBox--inputDefault ${
              activeInput
                ? "searchContent__searchBox--inputDefault-active"
                : null
            }`}
          >
            <div onClick={activeInputHandler} className="box">
              <img src={SearchOutlineIcon} alt="" className="icon" />
              <input
                ref={inputRef}
                type="text"
                className="input"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>

            <img
              onClick={() => {
                setSearchText("");
                setActiveInput(false);
              }}
              src={CloseIcon}
              alt=""
              className="closeIcon"
            />
          </div>
        </div>

        <div className="searchContent__infoText">
          Press ESC to close the search modal.
        </div>

        {searchHistory && searchHistory.length > 0 && (
          <div className="searchContent__searchHistory">
            <div className="title">Previous Search History</div>

            {searchHistory &&
              searchHistory.length > 0 &&
              searchHistory.map((item, i) => (
                <div
                  onClick={() => {
                    setSearchText("");
                    history.push(`/search/query=${item.text}`);
                    closeHandler();
                  }}
                  key={i}
                  className="searchContent__searchHistory--item"
                >
                  <div className="left">
                    <img src={HistoryIcon} alt="" className="icon" />{" "}
                    <div className="text">{item.text}</div>
                  </div>

                  <div
                    onClick={(e) => removeSavedSearchHandler(e, item.text)}
                    className="right"
                  >
                    <span>remove</span>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </SearchModelStyled>
  );
};

export default SearchModel;

const SearchModelStyled = styled.div`
  position: fixed;
  z-index: 900;
  bottom: 3.5rem;
  height: calc(100vh - 3.5rem);
  width: 100%;
  background-color: transparent;
  color: #fff;
  transition: all 0.7s ease;
  scale: 0.8;
  opacity: 0.8;
  transform: translateY(150%);
  overflow-y: auto;

  .searchContent {
    position: absolute;
    bottom: 0;
    top: 8rem;
    width: 55vw;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 1rem 1.5rem;

    background: linear-gradient(
      180deg,
      rgba(56, 56, 56, 0.906),
      rgba(56, 56, 56, 0.757)
    );
    backdrop-filter: blur(16px);
    font-family: ${(props) => props.theme.montserrat}, sans-serif;

    &__searchBox {
      position: relative;
      height: 3.5rem;
      width: 70%;
      margin: 0 auto;

      &--inputDefault {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        background-color: ${(props) => props.theme.grey};
        cursor: pointer;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        border: 2px solid transparent;
        transition: all 0.3s ease;

        &-active {
          border: 3px solid ${(props) => props.theme.primaryColor1};
        }

        .box {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          transition: all 0.5s ease;
          display: flex;
          width: 100%;
          align-items: center;
          padding: 0 1rem 0 0.2rem;
        }

        .icon {
          padding: 0 0.7rem;
          height: 1rem;
          width: auto;
          border-right: 1px solid #eee;
          margin-right: 0.7rem;
        }

        .input {
          background-color: transparent;
          font-size: 1.1rem;
          border: none;
          outline: none;
          color: #fff;
          width: 100%;

          &::placeholder {
            color: #cbcbcb;
          }
        }

        .closeIcon {
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
          height: 1rem;
          width: auto;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            height: 1.1rem;
          }
        }
      }
    }

    &__infoText {
      width: 70%;
      margin: 0 auto;
      font-weight: 400;
      font-size: 0.7rem;
      text-align: right;
    }

    &__searchHistory {
      margin: 2rem auto;

      &--item:last-child {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }

      &--item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding: 0.25rem;
        cursor: pointer;

        .left {
          display: flex;
          align-items: center;
        }

        .icon {
          height: 1rem;
          width: auto;
          margin-right: 0.5rem;
        }

        .text {
          font-size: 0.9rem;
        }

        span {
          text-transform: uppercase;
          font-size: 0.7rem;
          color: white;
          text-decoration: underline;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }

    &__close {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: ${(props) => props.theme.primaryGradient};
      cursor: pointer;

      img {
        height: 0.8rem;
        width: auto;
      }
    }

    .title {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }
  }

  /* responsive */
  @media screen and (max-width: 768px) {
    .searchContent {
      width: 100vw;
      top: 0;
      overflow-y: auto;

      &__searchBox {
        height: 3.2rem;
        width: 80%;

        &--inputDefault {
          .icon {
            height: 0.9rem;
          }

          .input {
            font-size: 0.9rem;
          }

          .closeIcon {
            &:hover {
              height: 1rem;
            }
          }
        }
      }

      &__infoText {
        width: 80%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .searchContent {
      &__searchBox {
        width: 90%;
        margin: 0 auto 0 0;
      }

      &__infoText {
        text-align: left;
        width: 90%;
        margin: 0 auto 0 0;
      }
    }
  }
`;
