import React, { useState } from "react";
import styled from "styled-components";
import BannerImage from "../../../../assets/girlusinglaptop.jpeg";
import Paragraph from "../../StyleComponents/Paragraph";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Row, Col } from "react-grid-system";

const Stats = ({ data }) => {
  const [didViewCountUp, setDidViewCountUp] = useState(false);

  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };

  if (!data?.length) {
    return null;
  }

  return (
    <StatsStyled>
      <div className="banner">
        <img src={BannerImage} alt="" />
      </div>

      <div className="overlay"></div>

      <div className="content">
        <Row>
          {data?.map((item, i) => (
            <Col lg={4} sm={12}>
              <div className="content__item">
                <div className="content__item--title">
                  <VisibilitySensor onChange={onVisibilityChange} delayedCall>
                    <CountUp
                      start={item?.start_number}
                      end={
                        didViewCountUp ? item?.end_number : item?.start_number
                      }
                    />
                  </VisibilitySensor>
                </div>

                <Paragraph className="content__item--text">
                  <div dangerouslySetInnerHTML={{ __html: item?.text }} />
                </Paragraph>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </StatsStyled>
  );
};

export default Stats;

const StatsStyled = styled.div`
  margin-bottom: 7rem;
  width: 100%;
  height: 28rem;
  position: relative;

  .banner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;

    img {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      object-fit: cover;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    z-index: 3;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;

      &--title {
        font-size: 5rem;
        line-height: 1;
        text-transform: uppercase;
        font-family: brother-1816, sans-serif;
        font-weight: 600;
        margin-bottom: 1rem;

        span {
          color: ${(props) => props.theme.primaryColor1};
        }
      }

      &--text {
        text-align: center;
        font-size: 1.1rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    height: 42rem;

    .content {
      &__item {
        margin-bottom: 2.5rem;

        &--title {
          font-size: 4rem;
        }

        &--text {
          font-size: 0.95rem;
        }
      }
    }
  }
`;
