import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import BackIcon from "../../assets/Icons/back.svg";
import ImageIcon from "../icons/image.svg";
import SliderIcon from "../icons/images.svg";
import VideoIcon from "../icons/video.svg";
import MomentIcon from "../icons/moment.svg";
import PenIcon from "../icons/pen.svg";
import TagPeople from "./TagPeople";
import Carousel from "../Elements/Carousel";
import CheckmarkIcon from "../icons/checkmark.svg";
import Button from "../Elements/Button";
import Loader from "../Elements/Loader";
import VideosSkeleton from "../../skeletons/General/Section";
import MomentsSkeleton from "../../skeletons/General/Section";
import SeriesSkeleton from "../../skeletons/General/Section";
import Error from "../Elements/Error";
import VideoCardSelect from "../MyPortalComponents/Models/VideoCardSelect";
import SeriesCardSelect from "../MyPortalComponents/Models/SeriesCardSelect";
import MomentCardSelect from "../MyPortalComponents/Models/MomentCardSelect";
import VideoCategory from "../UploadClipComponent/VideoCategory";
import SeriesCard from "../Cards/SeriesCard";
import MomentCard from "../Cards/MomentCard";
import VideoCard from "../Cards/VideoCard";
import AddSources from "./AddSources";
import ThreadBody from "./ThreadBody";
import { GlobalContext } from "../../context/GlobalContext";
import { upload, uploadCover } from "../../utils";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserVideos,
  getMyMoments,
  getAllSeriesOfUser,
  getVideoCategories,
  getAllFriends,
  getHashtags,
} from "../../actions";
import {
  createThread,
  getThreadV2,
  createThreadV2,
} from "../../actions/thread";
import { RESET_THREAD_STATE } from "../../actions/thread/type";
import {
  GET_USER_VIDEOS_RESET,
  GET_MY_MOMENT_RESET,
  GET_ALL_SERIES_OF_USER_RESET,
} from "../../actions/types";
import { Row, Col } from "react-grid-system";
import { useHistory } from "react-router-dom";

const UploadThreadForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    showUploadThreadModel,
    setShowUploadThreadModel,
    setShowUploadModel,
  } = useContext(GlobalContext);
  const user = useSelector((state) => state.user);
  const {
    videos: userVideos,
    loading: userVideosLoading,
    error: userVideosError,
  } = useSelector((state) => state.userVideos);
  const {
    loading: userMomentsLoading,
    myMoments,
    error: userMomentsError,
  } = useSelector((state) => state.moments);
  const {
    loading: userSeriesLoading,
    series: userSeries,
    error: userSeriesError,
  } = useSelector((state) => state.getAllSeriesOfUser);
  const videoCategories = useSelector((state) => state.video.videoCategories);
  const {
    createLoading: threadCreateLoading,
    createData: threadCreateData,
    createError: threadCreateError,
  } = useSelector((state) => state.thread);
  const { friendsError, friends, friendsLoading } = useSelector(
    (state) => state.connection
  );
  const videoHashtags = useSelector((state) => state.video.videoHashtags);

  const [show, setShow] = useState("THREAD_TYPE"); // THREAD_TYPE, THREAD_PREVIEW, THREAD_DETAILS, TAG_PEOPLE
  const [threadBody, setThreadBody] = useState("");
  const [attachmentType, setAttachmentType] = useState(null); // image, slider, video, moment, series
  const [attachmentPreview, setAttachmentPreview] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [defaultCategories, setDefaultCategories] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedMoment, setSelectedMoment] = useState(null);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSlider, setSelectedSlider] = useState(null);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [externalUrlSources, setExternalUrlSources] = useState([]);
  const [hashTags, setHashTags] = useState([]);
  const [suggestionUsers, setSuggestionUsers] = useState(null);
  const [suggestionHashtags, setSuggestionHashtags] = useState(null);
  const [fileSize, setFileSize] = useState(0)

  // effects
  // thread create effects
  useEffect(() => {
    if (threadCreateData) {
      setShowUploadThreadModel(false);
      setShowUploadModel(false);
      resetStateHandler();
      history.push("/threads");
      dispatch(getThreadV2(threadCreateData.id));
      toast.success("Thread posted!");
    }

    if (threadCreateError) {
      toast.error(
        threadCreateError ||
        "Server error occured while posting thread! Please try again."
      );
    }
  }, [threadCreateData, threadCreateError]);

  useEffect(() => {
    if (showUploadThreadModel) {
      if (threadCreateData || threadCreateError) {
        dispatch({
          type: RESET_THREAD_STATE,
        });
      }

      // as soon as model open fetch all friends and hashtags to show suggestion
      fetchConnections();
      fetchHashtags();
    }
  }, [showUploadThreadModel]);

  useEffect(() => {
    if (attachmentType && attachmentType === "video") {
      // then fetch all videos
      dispatch(
        getUserVideos(
          {
            showAll: false,
          },
          user?.id
        )
      );
    }

    if (attachmentType && attachmentType === "moment") {
      dispatch(getMyMoments(user?.id));
    }

    if (attachmentType && attachmentType === "series") {
      dispatch(getAllSeriesOfUser(user?.username, true));
    }
  }, [attachmentType]);

  useEffect(() => {
    if (selectedVideo) {
      // add it to preview
      setShow("THREAD_PREVIEW");

      setAttachmentPreview([
        {
          type: "video",
          content: {
            data: selectedVideo,
          },
        },
      ]);
    }
  }, [selectedVideo]);

  useEffect(() => {
    if (selectedSeries) {
      // add it to preview
      setShow("THREAD_PREVIEW");

      setAttachmentPreview([
        {
          type: "series",
          content: {
            data: selectedSeries,
          },
        },
      ]);
    }
  }, [selectedSeries]);

  useEffect(() => {
    if (selectedMoment) {
      // add it to preview
      setShow("THREAD_PREVIEW");

      setAttachmentPreview([
        {
          type: "moment",
          content: {
            data: selectedMoment,
          },
        },
      ]);
    }
  }, [selectedMoment]);

  useEffect(() => {
    if (showUploadThreadModel && !videoCategories?.length) {
      dispatch(getVideoCategories());
    }
  }, [showUploadThreadModel]);

  useEffect(async () => {
    if (show === "THREAD_DETAILS" && selectedImage) {
      // then upload the image asset.
      const res = await uploadCover("image", selectedImage);
      setSelectedImage(res.url);
    }

    if (show === "THREAD_DETAILS" && selectedSlider) {
      await uploadSliderImagesHandler();
    }
  }, [show]);

  const closeHandler = () => {
    if (show === "THREAD_TYPE") {
      setShowUploadThreadModel(false);
    } else if (show === "THREAD_PREVIEW") {
      setShow("THREAD_TYPE");
    } else if (show === "THREAD_DETAILS") {
      setShow("THREAD_PREVIEW");
    } else {
      return;
    }
  };

  const nextHandler = () => {
    if (show === "THREAD_TYPE") {
      if (!attachmentType) {
        onSubmitHandler();
        return;
      }

      // do validation
      if (!attachmentPreview.length) {
        toast.error("Please select an attachment to proceed!");
      } else {
        setShow("THREAD_PREVIEW");
      }
    } else if (show === "THREAD_PREVIEW") {
      setShow("THREAD_DETAILS");
    } else if (show === "THREAD_DETAILS") {
      onSubmitHandler();
      return;
    } else {
      return;
    }
  };

  const nextButton = () => {
    switch (show) {
      case "THREAD_TYPE":
        if (!attachmentType) {
          return threadCreateLoading ? "Saving..." : "Save";
        }

        return "Next";
      case "THREAD_PREVIEW":
        return "Add Details";
      case "THREAD_DETAILS":
        return threadCreateLoading ? "Saving..." : "Save";
      default:
        return "next";
    }
  };

  const attachImageToThreadHandler = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setSelectedImage(file);
      setFileSize(file)

      // set attachment preview
      setShow("THREAD_PREVIEW");
      setAttachmentPreview([
        {
          type: "image",
          content: {
            image: previewUrl,
            data: {
              id: "image",
            },
          },
        },
      ]);
    }
  };

  const attachSliderToThreadHandler = (e) => {
    const files = e.target.files;
    setSelectedSlider(files);

    if (files.length > 10) {
      toast.error(
        "No more than 10 photos can be added to a single Thread post. Please select less photos."
      );
      return setSelectedSlider(null)
    }

    let previewImages = [];
    for (let i = 0; i < files.length; i++) {
      const previewImage = URL.createObjectURL(files[i]);
      previewImages = [previewImage, ...previewImages];
    }

    // set attachment preview
    setShow("THREAD_PREVIEW");
    setAttachmentPreview([
      {
        type: "slider",
        content: {
          images: previewImages,
          data: {
            id: "slider",
          },
        },
      },
    ]);
  };

  const selectContentHandler = (type, item) => {
    if (type === "video") {
      if (item.id === selectedVideo?.id) {
        setSelectedVideo(null);

        // remove it from preview
        const afterItemRemoved = attachmentPreview.filter(
          (item) => item.type !== "video" && item.content?.data?.id !== item.id
        );
        setAttachmentPreview(afterItemRemoved);
      } else {
        setSelectedVideo(item);
      }
      return;
    }

    if (type === "series") {
      if (item.id === selectedSeries?.id) {
        setSelectedSeries(null);

        // remove it from preview
        const afterItemRemoved = attachmentPreview.filter(
          (item) => item.type !== "series" && item.content?.data?.id !== item.id
        );
        setAttachmentPreview(afterItemRemoved);
      } else {
        setSelectedSeries(item);
      }

      return;
    }

    if (type === "moment") {
      if (item.id === selectedMoment?.id) {
        setSelectedMoment(null);

        // remove it from preview
        const afterItemRemoved = attachmentPreview.filter(
          (item) => item.type !== "moment" && item.content?.data?.id !== item.id
        );
        setAttachmentPreview(afterItemRemoved);
      } else {
        setSelectedMoment(item);
      }

      return;
    }
  };

  const removeAttachmentHandler = (type) => {
    setShow("THREAD_TYPE");
    setAttachmentType(null);
    setSelectedVideo(null);
    setSelectedSeries(null);
    setSelectedMoment(null);
    setSelectedImage(null);
    setSelectedSlider(null);

    // remove state as well
    dispatch({
      type: GET_USER_VIDEOS_RESET,
    });
    dispatch({
      type: GET_MY_MOMENT_RESET,
    });
    dispatch({
      type: GET_ALL_SERIES_OF_USER_RESET,
    });

    const afterItemRemoved = attachmentPreview.filter(
      (item) => item.type !== type
    );

    setAttachmentPreview(afterItemRemoved);
  };

  const onSubmitHandler = () => {
    // validation
    if (!threadBody) {
      return toast.error("Please give some thread text.");
    }

    if (!selectedCategory?.length) {
      return toast.error("Please select category.");
    }

    // regex
    const tagUserRegex = /@\[(.*?)]\((.*?)\)/g;
    const hashTagRegex1 = new RegExp(/\B\#([\w\-]+)/gim);
    const hashTagRegex2 = new RegExp(/#\[(.*?)]\((.*?)\)/g);

    // parse tags from here and store it
    const addedHashTags = threadBody.match(hashTagRegex1) || [];

    // for tag users
    let tagUserString = threadBody;
    tagUserString = tagUserString.substring(1);
    var tagUsersArray = tagUserString.match(tagUserRegex) || [];
    var tagUsersResult = [];

    for (var a of tagUsersArray) {
      var value = a.substring(a.lastIndexOf("[") + 1, a.lastIndexOf("]"));
      var key = a.substring(a.lastIndexOf("(") + 1, a.lastIndexOf(")"));

      tagUsersResult.push(value);
    }

    let formattedTaggedUsers;
    if (tagUsersResult?.length > 0) {
      const result = friends.filter((friend) => {
        const friendUsername =
          user.id === friend.warrior.id
            ? friend.collaborator.username
            : user.id === friend.collaborator.id
              ? friend.warrior.username
              : null;

        return tagUsersResult.some((user) => {
          return user === friendUsername;
        });
      });

      formattedTaggedUsers = result.map((item) => {
        return user.id === item.warrior.id
          ? item.collaborator.id
          : item.warrior.id;
      });
    }

    // for hashtags
    let hashTagString = threadBody;
    hashTagString = hashTagString.substring(1);
    var hashTagsArray = hashTagString.match(hashTagRegex2) || [];
    var pickedHashTags = [];

    for (var a of hashTagsArray) {
      var value = a.substring(a.lastIndexOf("[") + 1, a.lastIndexOf("]"));
      var key = a.substring(a.lastIndexOf("(") + 1, a.lastIndexOf(")"));

      if (value) {
        pickedHashTags.push(`#${value}`);
      }
    }

    const finalHashTags = [...new Set([...addedHashTags, ...pickedHashTags])];

    let hashTagsResult;
    if (finalHashTags?.length > 0) {
      hashTagsResult = finalHashTags.map((item, i) => {
        if (videoHashtags.some((obj) => `#${obj["name"]}` === item)) {
          return videoHashtags.find((obj) => `#${obj["name"]}` === item);
        } else {
          return {
            id: null,
            name: item.slice(1),
          };
        }
      });
    }

    const threadObj = {
      threadBody,
      categoryList: selectedCategory,
      hashTags: hashTagsResult,
      URLSources: externalUrlSources,
    };

    let selectedAttachment = null;
    let attachmentType = null;
    if (attachmentPreview?.length > 0) {
      if (
        attachmentPreview[0].type === "video" ||
        attachmentPreview[0].type === "moment" ||
        attachmentPreview[0].type === "series"
      ) {
        selectedAttachment = {
          contentId: attachmentPreview[0].content.data.id,
          contentType: attachmentPreview[0].type,
        };
        attachmentType = "contentThread";
      }

      if (attachmentPreview[0].type === "slider") {
        selectedAttachment = {
          // pending -- needs actual urls
          sliderImages: selectedSlider,
        };
        attachmentType = "sliderThread";
      }

      if (attachmentPreview[0].type === "image") {
        selectedAttachment = {
          image: selectedImage,
        };
        attachmentType = "imageThread";
      }
    }

    const attachedObj = {
      ...selectedAttachment,
    };

    const usersObj = {
      users: formattedTaggedUsers,
    };

    if (!attachmentPreview?.length) {
      dispatch(createThreadV2(threadObj, usersObj));
    } else {
      dispatch(createThread(threadObj, attachedObj, attachmentType, usersObj, fileSize));
    }
  };

  const resetStateHandler = () => {
    setShow("THREAD_TYPE");
    setThreadBody("");
    setAttachmentType(null);
    setAttachmentPreview([]);
    setSelectedCategory([]);
    setDefaultCategories([]);
    setSelectedVideo(null);
    setSelectedMoment(null);
    setSelectedSeries(null);
    setSelectedImage(null);
    setSelectedSlider(null);
    setTaggedUsers([]);
    setExternalUrlSources([]);

    // reset state
    dispatch({
      type: GET_USER_VIDEOS_RESET,
    });
    dispatch({
      type: GET_MY_MOMENT_RESET,
    });
    dispatch({
      type: GET_ALL_SERIES_OF_USER_RESET,
    });
  };

  const uploadSliderImagesHandler = async () => {
    let uploadedImages = [];
    for (let i = 0; i < selectedSlider.length; i++) {
      const res = await uploadCover("image", selectedSlider[i]);

      uploadedImages = [...uploadedImages, res.url];
    }

    setSelectedSlider(uploadedImages);
  };

  const fetchConnections = () => {
    dispatch(getAllFriends());
  };

  const fetchHashtags = () => {
    dispatch(getHashtags());
  };

  return (
    <UploadThreadFormStyled
      className={`${showUploadThreadModel && "ShowUploadThreadModel"}`}
    >
      <div className="header">
        <div onClick={closeHandler} className="close">
          <img src={BackIcon} alt="" />
        </div>

        <div className="title">Create new Thread</div>

        <div onClick={nextHandler} className="button">
          {nextButton()}
        </div>
      </div>

      <div className="content">
        {show === "THREAD_TYPE" && (
          <>
            <div className="content__attachments">
              <div className="sectionTitle">Choose Attachment</div>
              <label
                htmlFor="attachImageToThread"
                onClick={() => {
                  if (attachmentPreview?.length > 0) {
                    setAttachmentPreview([]);
                    setSelectedVideo(null);
                    setSelectedSeries(null);
                    setSelectedMoment(null);
                  }
                  dispatch({
                    type: GET_USER_VIDEOS_RESET,
                  });
                  dispatch({
                    type: GET_MY_MOMENT_RESET,
                  });
                  dispatch({
                    type: GET_ALL_SERIES_OF_USER_RESET,
                  });
                  setAttachmentType("image");
                }}
                className={`content__attachments--item ${attachmentPreview.find((item) => item.type === "image")
                  ? "content__attachments--itemSelected"
                  : null
                  }`}
              >
                <img className="icon" src={ImageIcon} alt="" />
                <div className="text">
                  {attachmentPreview.find((item) => item.type === "image") && (
                    <img src={CheckmarkIcon} alt="" className="addedIcon" />
                  )}
                  Image
                </div>

                <input
                  style={{ display: "none" }}
                  id="attachImageToThread"
                  type="file"
                  accept="image/*"
                  onChange={attachImageToThreadHandler}
                />
              </label>

              <label
                htmlFor="attachSliderToThread"
                onClick={() => {
                  if (attachmentPreview?.length > 0) {
                    setAttachmentPreview([]);
                    setSelectedVideo(null);
                    setSelectedSeries(null);
                    setSelectedMoment(null);
                  }

                  dispatch({
                    type: GET_USER_VIDEOS_RESET,
                  });
                  dispatch({
                    type: GET_MY_MOMENT_RESET,
                  });
                  dispatch({
                    type: GET_ALL_SERIES_OF_USER_RESET,
                  });
                  setAttachmentType("slider");
                }}
                className={`content__attachments--item ${attachmentPreview.find((item) => item.type === "slider")
                  ? "content__attachments--itemSelected"
                  : null
                  }`}
              >
                <img className="icon" src={SliderIcon} alt="" />
                <div className="text">
                  {attachmentPreview.find((item) => item.type === "slider") && (
                    <img src={CheckmarkIcon} alt="" className="addedIcon" />
                  )}
                  Slider
                </div>

                <input
                  style={{ display: "none" }}
                  id="attachSliderToThread"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={attachSliderToThreadHandler}
                />
              </label>

              <div
                onClick={() => {
                  if (attachmentPreview?.length > 0) {
                    setAttachmentPreview([]);
                    setSelectedVideo(null);
                    setSelectedSeries(null);
                    setSelectedMoment(null);
                  }

                  dispatch({
                    type: GET_ALL_SERIES_OF_USER_RESET,
                  });
                  dispatch({
                    type: GET_MY_MOMENT_RESET,
                  });
                  setAttachmentType("video");
                }}
                className={`content__attachments--item ${attachmentPreview.find((item) => item.type === "video") ||
                  userVideos?.length
                  ? "content__attachments--itemSelected"
                  : null
                  }`}
              >
                <img className="icon" src={VideoIcon} alt="" />
                <div className="text">
                  {attachmentPreview.find((item) => item.type === "video") && (
                    <img src={CheckmarkIcon} alt="" className="addedIcon" />
                  )}
                  Video
                </div>
              </div>

              <div
                onClick={() => {
                  if (attachmentPreview?.length > 0) {
                    setAttachmentPreview([]);
                    setSelectedVideo(null);
                    setSelectedSeries(null);
                    setSelectedMoment(null);
                  }

                  dispatch({
                    type: GET_ALL_SERIES_OF_USER_RESET,
                  });
                  dispatch({
                    type: GET_USER_VIDEOS_RESET,
                  });
                  setAttachmentType("moment");
                }}
                className={`content__attachments--item ${attachmentPreview.find((item) => item.type === "moment") ||
                  myMoments?.length
                  ? "content__attachments--itemSelected"
                  : null
                  }`}
              >
                <img className="icon" src={MomentIcon} alt="" />
                <div className="text">
                  {attachmentPreview.find((item) => item.type === "moment") && (
                    <img src={CheckmarkIcon} alt="" className="addedIcon" />
                  )}
                  Moment
                </div>
              </div>

              <div
                onClick={() => {
                  if (attachmentPreview?.length > 0) {
                    setAttachmentPreview([]);
                    setSelectedVideo(null);
                    setSelectedSeries(null);
                    setSelectedMoment(null);
                  }

                  dispatch({
                    type: GET_USER_VIDEOS_RESET,
                  });
                  dispatch({
                    type: GET_MY_MOMENT_RESET,
                  });
                  setAttachmentType("series");
                }}
                className={`content__attachments--item ${attachmentPreview.find((item) => item.type === "series") ||
                  userSeries?.length
                  ? "content__attachments--itemSelected"
                  : null
                  }`}
              >
                <img className="icon" src={SliderIcon} alt="" />
                <div className="text">
                  {attachmentPreview.find((item) => item.type === "series") && (
                    <img src={CheckmarkIcon} alt="" className="addedIcon" />
                  )}
                  Series
                </div>
              </div>
            </div>

            {/* for text only thread show thread details here */}
            {!attachmentType && (
              <>
                <div className="contentV2">
                  <div className="content__form">
                    {/* react mentions */}
                    <div className="content__form--fieldItem">
                      <ThreadBody
                        value={threadBody}
                        setValue={setThreadBody}
                        suggestionUsers={suggestionUsers}
                        setSuggestionUsers={setSuggestionUsers}
                        suggestionHashtags={suggestionHashtags}
                        setSuggestionHashtags={setSuggestionHashtags}
                      />
                    </div>

                    <div className="content__form--fieldItem">
                      <div className="sectionTitle">Select Category</div>

                      <VideoCategory
                        videoCategories={videoCategories}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        defaultCategories={defaultCategories}
                      />
                    </div>
                  </div>

                  <div className="content__form--fieldItem">
                    <div className="sectionTitle">Add Source</div>
                    <AddSources
                      sources={externalUrlSources}
                      setSources={setExternalUrlSources}
                    />
                  </div>

                  {attachmentPreview && attachmentPreview.length > 0 && (
                    <div className="content__attachmentPreview">
                      <div className="sectionTitle">Attachment Previews</div>

                      {attachmentPreview.map((item, i) => {
                        if (item.type === "image") {
                          return (
                            <>
                              <div className="content__attachmentPreview--actionTab">
                                <Button
                                  mode="tertairy"
                                  size="medium"
                                  className="content__attachmentPreview--removeAttachmentBtn"
                                  onClick={() =>
                                    removeAttachmentHandler(item.type)
                                  }
                                >
                                  Choose Another
                                </Button>
                              </div>

                              <div className="content__attachmentPreview--imagePreview">
                                <img src={item.content?.image} alt="" />
                              </div>
                            </>
                          );
                        }

                        if (item.type === "slider") {
                          return (
                            <>
                              <div className="content__attachmentPreview--actionTab">
                                <Button
                                  mode="tertairy"
                                  size="medium"
                                  className="content__attachmentPreview--removeAttachmentBtn"
                                  onClick={() =>
                                    removeAttachmentHandler(item.type)
                                  }
                                >
                                  Choose Another
                                </Button>
                              </div>
                              <div className="content__attachmentPreview--sliderPreview">
                                <Carousel items={item.content?.images} />
                              </div>
                            </>
                          );
                        }

                        if (item.type === "series") {
                          return (
                            <>
                              <div className="content__attachmentPreview--actionTab">
                                <Button
                                  mode="tertairy"
                                  size="medium"
                                  className="content__attachmentPreview--removeAttachmentBtn"
                                  onClick={() =>
                                    removeAttachmentHandler(item.type)
                                  }
                                >
                                  Choose Another
                                </Button>
                              </div>
                              <div className="content__attachmentPreview--seriesPreview">
                                <SeriesCard
                                  isSelf={true}
                                  item={item.content?.data}
                                />
                              </div>
                            </>
                          );
                        }

                        if (item.type === "moment") {
                          return (
                            <>
                              <div className="content__attachmentPreview--actionTab">
                                <Button
                                  mode="tertairy"
                                  size="medium"
                                  className="content__attachmentPreview--removeAttachmentBtn"
                                  onClick={() =>
                                    removeAttachmentHandler(item.type)
                                  }
                                >
                                  Choose Another
                                </Button>
                              </div>
                              <div className="content__attachmentPreview--momentPreview">
                                <MomentCard item={item.content?.data} />
                              </div>
                            </>
                          );
                        }

                        if (item.type === "video") {
                          return (
                            <>
                              <div className="content__attachmentPreview--actionTab">
                                <Button
                                  mode="tertairy"
                                  size="medium"
                                  className="content__attachmentPreview--removeAttachmentBtn"
                                  onClick={() =>
                                    removeAttachmentHandler(item.type)
                                  }
                                >
                                  Choose Another
                                </Button>
                              </div>
                              <div className="content__attachmentPreview--videoPreview">
                                <VideoCard
                                  isSelf={true}
                                  item={item.content?.data}
                                />
                              </div>
                            </>
                          );
                        }
                      })}
                    </div>
                  )}

                  <Button
                    onClick={onSubmitHandler}
                    className="uploadButton"
                    mode="primary"
                    width="fluid"
                    variant="round"
                    size="medium"
                  >
                    {threadCreateLoading ? "Posting...." : "Post Thread"}
                  </Button>
                </div>
              </>
            )}
          </>
        )}

        {show === "THREAD_PREVIEW" && (
          <>
            {attachmentPreview && attachmentPreview.length > 0 && (
              <div className="content__attachmentPreview">
                <div className="sectionTitle">Attachment Previews</div>

                {attachmentPreview.map((item, i) => {
                  if (item.type === "image") {
                    return (
                      <>
                        <div className="content__attachmentPreview--actionTab">
                          <Button
                            mode="tertairy"
                            size="medium"
                            className="content__attachmentPreview--removeAttachmentBtn"
                            onClick={() => removeAttachmentHandler(item.type)}
                          >
                            Remove Attachment
                          </Button>

                          <Button
                            mode="primary"
                            size="medium"
                            className="content__attachmentPreview--nextBtn"
                            onClick={() => setShow("THREAD_DETAILS")}
                          >
                            Next
                          </Button>
                        </div>

                        <div className="content__attachmentPreview--imagePreview">
                          <img src={item.content?.image} alt="" />
                        </div>
                      </>
                    );
                  }

                  if (item.type === "slider") {
                    return (
                      <>
                        <div className="content__attachmentPreview--actionTab">
                          <Button
                            mode="tertairy"
                            size="medium"
                            className="content__attachmentPreview--removeAttachmentBtn"
                            onClick={() => removeAttachmentHandler(item.type)}
                          >
                            Remove Attachment
                          </Button>

                          <Button
                            mode="primary"
                            size="medium"
                            className="content__attachmentPreview--nextBtn"
                            onClick={() => setShow("THREAD_DETAILS")}
                          >
                            Next
                          </Button>
                        </div>
                        <div className="content__attachmentPreview--sliderPreview">
                          <Carousel items={item.content?.images} />
                        </div>
                      </>
                    );
                  }

                  if (item.type === "series") {
                    return (
                      <>
                        <div className="content__attachmentPreview--actionTab">
                          <Button
                            mode="tertairy"
                            size="medium"
                            className="content__attachmentPreview--removeAttachmentBtn"
                            onClick={() => removeAttachmentHandler(item.type)}
                          >
                            Choose Another
                          </Button>

                          <Button
                            mode="primary"
                            size="medium"
                            className="content__attachmentPreview--nextBtn"
                            onClick={() => setShow("THREAD_DETAILS")}
                          >
                            Next
                          </Button>
                        </div>
                        <div className="content__attachmentPreview--seriesPreview">
                          <SeriesCard isSelf={true} item={item.content?.data} />
                        </div>
                      </>
                    );
                  }

                  if (item.type === "moment") {
                    return (
                      <>
                        <div className="content__attachmentPreview--actionTab">
                          <Button
                            mode="tertairy"
                            size="medium"
                            className="content__attachmentPreview--removeAttachmentBtn"
                            onClick={() => removeAttachmentHandler(item.type)}
                          >
                            Choose Another
                          </Button>

                          <Button
                            mode="primary"
                            size="medium"
                            className="content__attachmentPreview--nextBtn"
                            onClick={() => setShow("THREAD_DETAILS")}
                          >
                            Next
                          </Button>
                        </div>
                        <div className="content__attachmentPreview--momentPreview">
                          <MomentCard item={item.content?.data} />
                        </div>
                      </>
                    );
                  }

                  if (item.type === "video") {
                    return (
                      <>
                        <div className="content__attachmentPreview--actionTab">
                          <Button
                            mode="tertairy"
                            size="medium"
                            className="content__attachmentPreview--removeAttachmentBtn"
                            onClick={() => removeAttachmentHandler(item.type)}
                          >
                            Choose Another
                          </Button>

                          <Button
                            mode="primary"
                            size="medium"
                            className="content__attachmentPreview--nextBtn"
                            onClick={() => setShow("THREAD_DETAILS")}
                          >
                            Next
                          </Button>
                        </div>
                        <div className="content__attachmentPreview--videoPreview">
                          <VideoCard isSelf={true} item={item.content?.data} />
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            )}
          </>
        )}
      </div>

      {show === "THREAD_DETAILS" && (
        <div className="content">
          <div className="content__form">
            {/* react mentions */}
            <div className="content__form--fieldItem">
              <ThreadBody
                value={threadBody}
                setValue={setThreadBody}
                suggestionUsers={suggestionUsers}
                setSuggestionUsers={setSuggestionUsers}
                suggestionHashtags={suggestionHashtags}
                setSuggestionHashtags={setSuggestionHashtags}
              />
            </div>

            <div className="content__form--fieldItem">
              <div className="sectionTitle">Select Category</div>

              <VideoCategory
                videoCategories={videoCategories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                defaultCategories={defaultCategories}
              />
            </div>
          </div>

          <div className="content__form--fieldItem">
            <div className="sectionTitle">Add Source(s)</div>
            <AddSources
              sources={externalUrlSources}
              setSources={setExternalUrlSources}
            />
          </div>

          {attachmentPreview && attachmentPreview.length > 0 && (
            <div className="content__attachmentPreview">
              <div className="sectionTitle">Attachment Previews</div>

              {attachmentPreview.map((item, i) => {
                if (item.type === "image") {
                  return (
                    <>
                      <div className="content__attachmentPreview--actionTab">
                        <Button
                          mode="tertairy"
                          size="medium"
                          className="content__attachmentPreview--removeAttachmentBtn"
                          onClick={() => removeAttachmentHandler(item.type)}
                        >
                          Choose Another
                        </Button>
                      </div>

                      <div className="content__attachmentPreview--imagePreview">
                        <img src={item.content?.image} alt="" />
                      </div>
                    </>
                  );
                }

                if (item.type === "slider") {
                  return (
                    <>
                      <div className="content__attachmentPreview--actionTab">
                        <Button
                          mode="tertairy"
                          size="medium"
                          className="content__attachmentPreview--removeAttachmentBtn"
                          onClick={() => removeAttachmentHandler(item.type)}
                        >
                          Choose Another
                        </Button>
                      </div>
                      <div className="content__attachmentPreview--sliderPreview">
                        <Carousel items={item.content?.images} />
                      </div>
                    </>
                  );
                }

                if (item.type === "series") {
                  return (
                    <>
                      <div className="content__attachmentPreview--actionTab">
                        <Button
                          mode="tertairy"
                          size="medium"
                          className="content__attachmentPreview--removeAttachmentBtn"
                          onClick={() => removeAttachmentHandler(item.type)}
                        >
                          Choose Another
                        </Button>
                      </div>
                      <div className="content__attachmentPreview--seriesPreview">
                        <SeriesCard isSelf={true} item={item.content?.data} />
                      </div>
                    </>
                  );
                }

                if (item.type === "moment") {
                  return (
                    <>
                      <div className="content__attachmentPreview--actionTab">
                        <Button
                          mode="tertairy"
                          size="medium"
                          className="content__attachmentPreview--removeAttachmentBtn"
                          onClick={() => removeAttachmentHandler(item.type)}
                        >
                          Choose Another
                        </Button>
                      </div>
                      <div className="content__attachmentPreview--momentPreview">
                        <MomentCard item={item.content?.data} />
                      </div>
                    </>
                  );
                }

                if (item.type === "video") {
                  return (
                    <>
                      <div className="content__attachmentPreview--actionTab">
                        <Button
                          mode="tertairy"
                          size="medium"
                          className="content__attachmentPreview--removeAttachmentBtn"
                          onClick={() => removeAttachmentHandler(item.type)}
                        >
                          Choose Another
                        </Button>
                      </div>
                      <div className="content__attachmentPreview--videoPreview">
                        <VideoCard isSelf={true} item={item.content?.data} />
                      </div>
                    </>
                  );
                }
              })}
            </div>
          )}

          <Button
            onClick={onSubmitHandler}
            className="uploadButton"
            mode="primary"
            width="fluid"
            variant="round"
            size="medium"
          >
            {threadCreateLoading ? "Posting...." : "Post Thread"}
          </Button>
        </div>
      )}

      {userVideosLoading && attachmentType === "video" && (
        <VideosSkeleton sectionName={"videos"} />
      )}
      {userVideosError && attachmentType === "video" && (
        <Error text="No videos found!" />
      )}

      {userMomentsLoading && attachmentType === "moment" && (
        <MomentsSkeleton sectionName={"featured_moments"} />
      )}
      {(userMomentsError || !myMoments?.length) &&
        attachmentType === "moment" && <Error text="No moments found!" />}

      {userSeriesLoading && attachmentType === "series" && (
        <SeriesSkeleton sectionName={"featured_series"} />
      )}
      {userSeriesError && attachmentType === "series" && (
        <Error text="No series found!" />
      )}
      <div className="fluidContent">
        <Row>
          {show === "THREAD_TYPE" &&
            userVideos &&
            userVideos.length > 0 &&
            userVideos.map((item, i) => (
              <Col
                key={i}
                lg={3}
                md={6}
                sm={12}
                xs={12}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <VideoCardSelect
                  isSelected={selectedVideo?.id === item.id ? true : false}
                  selectVideoHandler={() => selectContentHandler("video", item)}
                  item={item}
                  editMode={true}
                />
              </Col>
            ))}

          {show === "THREAD_TYPE" &&
            myMoments &&
            myMoments.length > 0 &&
            myMoments.map((item, i) => (
              <Col
                key={i}
                lg={2}
                md={2}
                sm={3}
                xs={4}
                style={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                <MomentCardSelect
                  isSelected={selectedMoment?.id === item.id ? true : false}
                  selectMomentHandler={() =>
                    selectContentHandler("moment", item)
                  }
                  item={item}
                />
              </Col>
            ))}

          {show === "THREAD_TYPE" &&
            userSeries &&
            userSeries.length > 0 &&
            userSeries.map((item, i) => (
              <Col
                key={i}
                lg={3}
                md={6}
                sm={12}
                xs={12}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <SeriesCardSelect
                  isSelected={selectedSeries?.id === item.id ? true : false}
                  selectSeriesHandler={() =>
                    selectContentHandler("series", item)
                  }
                  item={item}
                />
              </Col>
            ))}
        </Row>
      </div>
    </UploadThreadFormStyled>
  );
};

export default UploadThreadForm;

const UploadThreadFormStyled = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #1c1c1e;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
  opacity: 0;
  transform: translateX(-100%);
  transition: all 1s ease;
  font-family: ${(props) => props.theme.montserrat};

  .sectionTitle {
    width: 100%;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 0.7rem;
    font-size: 1.15rem;
    padding: 0 0.5rem;
    color: rgba(255, 255, 255, 0.9);
    line-height: 1;
  }

  .uploadButton {
    transition: all 0.2s ease;
    font-weight: 500;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5rem;
    border-bottom: 1px solid rgba(112, 112, 112, 0.4);
    margin-bottom: 2rem;
    height: 4rem;
    background-color: #1c1c1e;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 2rem;
        height: auto;
        cursor: pointer;
      }
    }

    .title {
      font-size: 1.2rem;
      font-weight: 400;
      text-transform: capitalize;
    }

    .button {
      border: 2px solid #fff;
      padding: 0.2rem 1.5rem;
      border-radius: 10rem;
      font-size: 1rem;
      background-color: transparent;
      color: #fff;
      font-family: ${(props) => props.theme.montserrat};
      font-weight: 400;
      text-transform: capitalize;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }

  .contentV2 {
    width: 100%;
    margin-top: 1rem;
  }

  .content {
    width: 50%;
    margin: 0 auto;

    &__form {
      &--fieldItem {
        margin-bottom: 3rem;
      }
    }

    &__attachments {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &--itemSelected {
        background: ${(props) => props.theme.primaryGradient} !important;

        .text {
          font-weight: 600 !important;
          color: #fff !important;
        }
      }

      &--item {
        padding: 1rem 2rem;
        border: 1px solid rgba(255, 255, 255, 0.06);
        background-color: #181818;
        margin-bottom: 1rem;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all 0.4s ease;
        position: relative;

        &:hover {
          background: ${(props) => props.theme.primaryGradient};
          cursor: pointer;

          .text {
            color: #fff;
          }
        }

        .addedIcon {
          height: 0.9rem;
          width: auto;
          margin-right: 0.5rem;
        }

        .icon {
          height: 1.25rem;
          width: auto;
          margin-bottom: 0.5rem;
        }

        .text {
          text-transform: uppercase;
          font-size: 0.7rem;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.6);
          line-height: 1;
          transition: all 0.4s ease;
          display: flex;
          align-items: center;
        }

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    &__attachmentPreview {
      margin-bottom: 2rem;

      &--actionTab {
        padding: 1rem 0 0.5rem 0;
        display: flex;
        align-items: center;
      }

      &--removeAttachmentBtn {
        margin-right: 0.8rem;
      }

      &--imagePreview {
        width: 100%;
        height: auto;
        position: relative;

        img {
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center;
          object-fit: cover;
          border-radius: 7px;
        }
      }

      &--sliderPreview {
        position: relative;
      }

      &--seriesPreview,
      &--momentPreview,
      &--videoPreview {
        width: 40%;
      }
    }
  }

  .fluidContent {
    width: 90%;
    margin: 0 auto;
    padding: 2rem 0;
  }

  @media screen and (max-width: 600px) {
    .header {
      padding: 1rem;
      margin-bottom: 2rem;

      .close {
        img {
          width: 1.5rem;
        }
      }

      .title {
        font-size: 1.05rem;
      }

      .button {
        border: 1px solid #fff;
        padding: 0.2rem 1rem;
        font-size: 0.7rem;
      }
    }

    .content {
      width: 90%;
      &__attachments {
        &--item {
          margin-bottom: 0.5rem;

          &:not(:last-child) {
            margin-right: 0.5rem;
          }
        }
      }

      &__attachmentPreview {
        &--seriesPreview,
        &--momentPreview,
        &--videoPreview {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      width: 95%;
    }
  }

  @media screen and (max-width: 480px) {
    .header {
      margin-bottom: 1rem;

      .close {
        img {
          width: 1.2rem;
        }
      }

      .title {
        font-size: 0.85rem;
      }

      .button {
        font-size: 0.6rem;
      }
    }

    .content {
      &__attachments {
        &--item {
          padding: 0.8rem 1.7rem;

          .addedIcon {
            height: 0.75rem;
          }

          .icon {
            height: 1.1rem;
            margin-bottom: 0.25rem;
          }

          .text {
            font-size: 0.65rem;
          }
        }
      }

      &__attachmentPreview {
        &--momentPreview {
          width: 60%;
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    .content {
      &__attachmentPreview {
        &--momentPreview {
          width: 100%;
        }
      }
    }
  }
`;
