import React, { useEffect, useState, useContext, useRef } from "react";
import { connect, useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { addToHistory, fetchMuxVideo } from "../actions";
import { GlobalContext } from "../context/GlobalContext";

import {
  ChatIcon,
  ShareIcon,
  FullscreenIcon,
  PauseIcon,
  CloseChatIcon,
  RemoveFullScreen,
  MinimizeIcon,
  StopStream,
  ReverseCamera,
  MoreStreamIcon,
  SettingIcon,
} from "./Icons";

import config from "../config";
import VideoCardOverlayWithButton from "../components/VideoCardOverlay/VideoCardOverlayWithButton";
import VideoCardTipOverlay from "./VideoCardOverlay/VideoCardTipOverlay";
import OwlLogo from ".././assets/OwlLogo.png";
import ManageSubscription from "./MyAccountComponents/ManageSubscription/ManageSubscription";

const Wrapper = styled.div`
  .chat-icon {
    position: absolute;
    bottom: 10px;
    right: 70px;
    font-size: 25px;
  }
  .thumbnail-preview {
    position: absolute;
    bottom: 135%;
    transform: translateX(-50%);
    display: none;
    max-width: 20%;
  }

  .full-screen-icon {
    position: absolute;
    bottom: 10px;
    right: 20px;
    font-size: 25px;
  }
  .share-icon {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 25px;
  }
  .pause-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
  }
  .minimize-icon {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .viewers-badge {
    position: absolute;
    font-size: 16px;
    padding: 15px;
    border-radius: 10px;
    bottom: 21px;
    left: 20px;
    font-family: Montserrat;
    height: 24px;
    width: auto;
    background: #1c1c1e;
  }
  .height-video-stream {
    height: 92vh !important;
  }
  .video-full .video-js.vjs-fluid {
    max-height: unset;
  }
  .fullscreen-icon {
    position: absolute;
    bottom: 10px;
    right: 25px;
  }
  .error-loading {
    z-index: 23;
    position: absolute;
    top: -0.1vh;
    // right: 5.1%;
    width: 100%;
    height: 101%;
    background-color: #202020;

    border-radius: 5px;
    margin-bottom: 1.5rem;
  }
  .watter-logo {
    width: 100%;
  }
  @media screen and (max-width: 414px) {
    .error-loading {
      width: 101%%;
      top: -0.5vh;
      height: 13rem;
    }
    .watter-logo {
      margin-left: 29px;
    }
    .thumbnail-preview {
      position: absolute;
      bottom: 135%;
      transform: translateX(-50%);
      display: none;
      max-width: 20%;
    }
  }
  .viewers-badge-stream {
    position: absolute;
    font-size: 16px;
    border-radius: 10px;
    bottom: 5%;
    left: 3%;
    font-family: Montserrat;
    height: 30px;
    width: auto;
    background: #1c1c1e;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
  }
  .stop-icon {
    position: absolute;
    bottom: 5%;
    left: 35%;
  }
  .reverse-icon {
    position: absolute;
    top: 10%;
    right: 33%;
  }
  .more-icon {
    position: absolute;
    bottom: 5%;
    right: 33%;
  }
  .live-class-title-stream {
    position: absolute;
    top: 10%;
    font-family: "Montserrat";
    left: 3%;
    color: #f2f2f7;
    font-weight: 500;
    font-size: 16px;
  }

  @media screen and (max-width: 1023px) {
    .chat-icon {
      display: none;
    }
    .height-video {
      height: 300px !important;
    }
    .viewers-badge {
      position: absolute;
      font-size: 8px;
      padding: 15px;
      border-radius: 10px;
      bottom: 21px;
      left: 20px;
      font-family: Montserrat;
      height: 24px;
      width: auto;
      background: #1c1c1e;
    }
    .viewers-badge-stream {
      left: 20px;
      height: 20px;
      font-size: 10px;
    }
  }
  @media screen and (max-width: 480px) {
    .chat-icon {
      display: none;
    }
    .height-video {
      height: 270px !important;
    }
    .viewers-badge {
      position: absolute;
      font-size: 8px;
      padding: 15px;
      border-radius: 10px;
      bottom: 21px;
      left: 20px;
      font-family: Montserrat;
      height: 24px;
      width: auto;
      background: #1c1c1e;
    }
    .viewers-badge-stream {
      left: 20px;
      height: 20px;
      font-size: 10px;
    }
  }

  @media screen and (max-width: 414px) {
    .chat-icon {
      display: none;
    }
    .height-video {
      height: 270px !important;
    }
    .viewers-badge {
      position: absolute;
      font-size: 8px;
      padding: 15px;
      border-radius: 10px;
      bottom: 21px;
      left: 20px;
      font-family: Montserrat;
      height: 24px;
      width: auto;
      background: #1c1c1e;
    }
    .viewers-badge-stream {
      left: 20px;
      height: 20px;
      font-size: 10px;
    }
  }

  @media screen and (max-width: 375px) {
    .chat-icon {
      display: none;
    }
    .height-video {
      height: 270px !important;
    }
    .viewers-badge {
      position: absolute;
      font-size: 8px;
      padding: 15px;
      border-radius: 10px;
      bottom: 21px;
      left: 20px;
      font-family: Montserrat;
      height: 24px;
      width: auto;
      background: #1c1c1e;
    }
    .viewers-badge-stream {
      left: 20px;
      height: 20px;
      font-size: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    .chat-icon {
      display: none;
    }
    .height-video {
      height: 270px !important;
    }
    .viewers-badge {
      position: absolute;
      font-size: 8px;
      padding: 15px;
      border-radius: 10px;
      bottom: 21px;
      left: 20px;
      font-family: Montserrat;
      height: 24px;
      width: auto;
      background: #1c1c1e;
    }
    .viewers-badge-stream {
      left: 20px;
      height: 20px;
      font-size: 10px;
    }
  }
  // .overlay {
  //   position: absolute;
  //   width: -webkit-fill-available;
  //   z-index: 1;
  //   height: 100%;
  // }
  .overlay-text {
    z-index: 23;
    position: absolute;
    top: 37%;
    left: 50%;
    border-radius: 5px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    transform: translate(-50%, -50%);
    padding: 1.5%;

    @media screen and (max-width: 1440px) {
      h3 {
        font-size: 1rem;
        line-height: 1.2;
      }
    }

    @media screen and (max-width: 1366px) {
      top: 35%;
    }

    @media screen and (max-width: 1280px) {
      width: 55%;
    }

    @media screen and (max-width: 1024px) {
      width: 70%;
    }

    @media screen and (max-width: 480px) {
      width: 90%;
      top: 30%;

      h3 {
        font-size: 0.9rem;
      }
    }

    @media screen and (max-width: 414px) {
      top: 30%;

      h3 {
        font-size: 0.8rem;
      }
    }

    @media screen and (max-width: 375px) {
      top: 28%;
      width: 95%;

      h3 {
        font-size: 0.75rem;
      }
    }
  }
  .paymentButton {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 98;
    font-family: brother-1816, sans-serif;
    color: #fff;
    background: linear-gradient(
      29deg,
      rgb(249, 154, 45),
      rgb(246, 92, 139) 71%
    );
    font-size: 0.935rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05px;
    padding: 0.6rem 1.2rem !important;
    border-radius: 4px;
    line-height: 1;
    cursor: pointer;
  }
  .loading-text {
    z-index: 23;
    position: absolute;
    top: 37%;
    left: 50%;
    border-radius: 5px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    transform: translate(-50%, -50%);
    padding: 1.5%;

    @media screen and (max-width: 1440px) {
      h3 {
        font-size: 1rem;
        line-height: 1.2;
      }
    }

    @media screen and (max-width: 1366px) {
      top: 35%;
    }

    @media screen and (max-width: 1280px) {
      width: 55%;
    }

    @media screen and (max-width: 1024px) {
      width: 70%;
    }

    @media screen and (max-width: 480px) {
      width: 90%;
      top: 30%;

      h3 {
        font-size: 0.9rem;
      }
    }

    @media screen and (max-width: 414px) {
      top: 30%;

      h3 {
        font-size: 0.8rem;
      }
    }

    @media screen and (max-width: 375px) {
      top: 28%;
      width: 95%;

      h3 {
        font-size: 0.75rem;
      }
    }
  }
`;
const loading = keyframes`
    0% {
        transform: translateX(-200%);
    }
    50% {
        transform: translateX(-60%);
    }
    100% {
        transform: translateX(200%);
    }
`;

const SkeletonShimmerStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 33%;
  height: 91%;
  animation: ${loading} 2.5s infinite;

  .shimmer {
    width: 40%;
    height: 100%;

    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.02);
  }
`;
const Player = ({
  isViewed,
  previewUrl,
  src,
  poster,
  video,
  addToHistory,
  componentName,
  data,
  channelName,
  stopStream,
  dataVideo,
  varient,
}) => {
  const {
    setShowAuthenticationModel,
    setShowKarmaModel,
    setShowMyAccount,
    setShowManageSubscription,
    showManageSubscription,
  } = useContext(GlobalContext);
  const user = useSelector((state) => state.user);

  const videoData = useSelector((state) => state.video);
  let videoRef;
  let [currentTime, setCurrentTime] = useState(0);
  const { setSingleVideoFormattedTimestamp } = useContext(GlobalContext);
  const [callHistoryApi, setCallHistoryApi] = useState(false);
  const [showKarmaPrompt, setShowKarmaPrompt] = useState(false);
  const [hasReached2Min, setHasReached2Min] = useState(false);
  const [muxPlaybackUrls, setMuxPlaybackUrls] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const player = videojs(videoRef);

    player.on("loadeddata", () => {
      setIsLoading(false);
    });

    return () => {
      player.dispose();
    };
  }, []);

  useEffect(() => {
    const setPlaybackUrls = async () => {
      if (video.muxid) {
        try {
          const data = await fetchMuxVideo(video.muxid);
          // console.log(data.thumbnails);
          setMuxPlaybackUrls(data);
        } catch (error) {
          console.error(error.message);
        }
      } else {
        console.error("Mux ID is not available in the video object.");
      }
    };
    setPlaybackUrls();
  }, [video.muxid]);
  const decodeStreamingUrl = (encodedUrl) => {
    return atob(encodedUrl);
  };

  useEffect(() => {
    const player = videojs(videoRef);
    const timestamp = player.currentTime();
    var date = new Date(null);
    date.setSeconds(timestamp);
    var result = date.toISOString().substr(11, 8);
    setSingleVideoFormattedTimestamp(result);

    if (
      video &&
      (!user.userrole || user.userrole === 0) &&
      video.keyVideoAccess === 1
    ) {
      player.bigPlayButton.hide();
    }
    if (callHistoryApi === false && player.currentTime() >= 5) {
      setCallHistoryApi(true);
    }
  }, [videoRef, setSingleVideoFormattedTimestamp, callHistoryApi]);

  useEffect(() => {
    setCallHistoryApi(false);
  }, []);
  useEffect(() => {
    if (callHistoryApi) {
      addToHistory(video);
    }
  }, [addToHistory, callHistoryApi, video]);

  const tempFunction = useRef();
  const newdatFunction = () => {
    const player = videojs(videoRef);
    // player.poster(poster);
    if (videoData.customThumbnail && muxPlaybackUrls && muxPlaybackUrls.hls) {
      player.src({
        // src: muxPlaybackUrls.hls,
        src: decodeStreamingUrl(muxPlaybackUrls.hls),
        type: "application/x-mpegURL",
      });
      player.poster(poster);
    } else if (
      muxPlaybackUrls &&
      muxPlaybackUrls.hls &&
      muxPlaybackUrls.thumbnail
    ) {
      const posterUrl = decodeStreamingUrl(`${muxPlaybackUrls.thumbnail}`);
      player.poster(posterUrl);
      player.src({
        // src: muxPlaybackUrls.hls,
        src: decodeStreamingUrl(muxPlaybackUrls.hls),
        type: "application/x-mpegURL",
      });
    } else {
      player.src({
        src: src,
        type: "video/mp4",
      });
    }
    // player.src(src);
    if (JSON.stringify(user) === "{}" && video.keyVideoAccess !== 0) {
      player.on("click", () => setShowAuthenticationModel(true));
    } else {
      if (!previewUrl) {
        if (video.isVideoLocked && video.keyVideoAccess === 2) {
          if (user?.id !== video?.User.id) {
            player.bigPlayButton.disable();
          }
        } else if (!video.isVideoLocked && video.keyVideoAccess === 2) {
          player.bigPlayButton.enable();
        }
      } else {
        player.src({ type: "video/mp4", src: previewUrl });
      }
    }
    // >>>>>>>>>> Preview Thumbnails logic functin  >>>>>>>>>>>>>>>>>>>
    const progressBar = player.controlBar.progressControl.el();
    const thumbnailPreview = document.createElement("img");
    thumbnailPreview.className = "thumbnail-preview";
    progressBar.appendChild(thumbnailPreview);
    progressBar.addEventListener("mousemove", (event) => {
      const mousePosition =
        event.clientX - progressBar.getBoundingClientRect().left;
      const progressRatio = mousePosition / progressBar.offsetWidth;
      const videoDuration = player.duration();
      const targetTime = progressRatio * videoDuration;

      if (muxPlaybackUrls && muxPlaybackUrls.thumbnails) {
        let closestThumbnail = null;
        let closestTimeDifference = Infinity;

        for (const thumbnail of muxPlaybackUrls.thumbnails) {
          const timeDifference = Math.abs(targetTime - thumbnail.timestamp);

          if (timeDifference < closestTimeDifference) {
            closestTimeDifference = timeDifference;
            closestThumbnail = thumbnail;
          }
        }

        if (closestThumbnail) {
          const thumbnailImage = new Image();
          thumbnailImage.src = closestThumbnail.url;
          thumbnailImage.onload = () => {
            thumbnailPreview.src = thumbnailImage.src;
            thumbnailPreview.style.display = "block";
            thumbnailPreview.style.left = `${mousePosition}px`;
          };
        } else {
          thumbnailPreview.style.display = "none";
        }
      }
    });

    progressBar.addEventListener("mouseout", () => {
      thumbnailPreview.style.display = "none";
    });

    if (showManageSubscription) {
      return (
        <ManageSubscription
          close={() => setShowManageSubscription(false)}
          user={user}
        />
      );
    }
    player.on("timeupdate", function (e) {
      if (video.keyVideoAccess === 3 && video.isTATVideoLocked) {
        if (user?.id !== video?.User.id) {
          if (player.currentTime() >= 120) {
            if (!localStorage.getItem(`PaymentDone${video.id}`)) {
              player.pause();
            }
            setHasReached2Min(true);
          }
        }
      }
    });

    player.on("seeked", function () {
      if (user?.id !== video?.User.id) {
        if (
          video.keyVideoAccess === 3 &&
          video.isTATVideoLocked &&
          !localStorage.getItem(`PaymentDone${video.id}`)
        ) {
          if (!dataVideo?.data?.data) {
            if (player.currentTime() >= 120 && video.isTATVideoLocked) {
              const delta = player.currentTime() - currentTime;
              if (Math.abs(delta) > 0.01) {
                if (player.currentTime() >= 120 && !player.paused()) {
                  player.currentTime(120);
                  player.pause();
                }
              }
            }
          } else {
            if (
              player.currentTime() >= 120 &&
              dataVideo?.data?.data?.userId === user.id &&
              dataVideo?.data?.data?.videoId === video.id &&
              !video.isTATVideoLocked
            ) {
              const delta = player.currentTime() - currentTime;
              if (Math.abs(delta) > 0.01) {
                player.currentTime(120);
                setHasReached2Min(false);
              }
            }
          }
        }
      }
    });

    player.on("pause", function () {
      if (!this.isScrubbing_) {
        if (user?.id !== video?.User.id) {
          if (!showKarmaPrompt) {
            if (
              video.keyVideoAccess === 3 &&
              !dataVideo?.data?.data &&
              !localStorage.getItem(`PaymentDone${video.id}`)
            ) {
              if (player.currentTime() < 120 && hasReached2Min) {
                setHasReached2Min(false);
              }
            } else if (
              video.keyVideoAccess === 3 &&
              player.currentTime() < 120 // 120 sec == 2 mins
            ) {
              if (hasReached2Min) {
                setHasReached2Min(false);
              }
            }
          }

          if (
            video.keyVideoAccess === 3 &&
            player.currentTime() >= 120 &&
            video.isTATVideoLocked &&
            !localStorage.getItem(`PaymentDone${video.id}`)
          ) {
            player.currentTime(120);
          }
          setShowKarmaPrompt(!showKarmaPrompt);
        }
      }
    });
    player.on("play", function () {
      if (JSON.stringify(user) === "{}" && video.keyVideoAccess !== 0) {
        player.pause();
      } else {
        if (!this.isScrubbing_) {
          if (user?.id !== video?.User.id) {
            if (
              video.keyVideoAccess === 3 &&
              video.isTATVideoLocked &&
              player.currentTime() >= 120
            ) {
              if (
                !dataVideo.data.data &&
                !localStorage.getItem(`PaymentDone${video.id}`)
              ) {
                player.currentTime(120);
                player.pause();
              }
            }
          }
        }
      }
    });
    player.on("play", function () {
      if (video && user.userrole === 0 && video.keyVideoAccess === 1) {
        player.pause();
      } else {
        // player.play();
      }
    });

    player.on("ended", function () {
      if (video.keyVideoAccess === 3) {
        setCurrentTime(0);
        setHasReached2Min(false);
      }
    });
    return () => {
      player.dispose();
    };
  };
  tempFunction.current = newdatFunction;
  useEffect(() => {
    tempFunction.current();
  }, [
    src,
    poster,
    muxPlaybackUrls,
    previewUrl,
    videoRef,
    isViewed,
    addToHistory,
  ]);

  const tempFunc = useRef();
  const newFunc = () => {
    const player = videojs(videoRef);
    if (JSON.stringify(user) === "{}" && video.keyVideoAccess !== 0) {
      player.on("click", () => {
        document.getElementById("user-name").focus();
        setShowAuthenticationModel(true);
      });
      player.bigPlayButton.disable();
    } else {
      if (user?.id === video.User.id || video.keyVideoAccess === 0) {
        player.bigPlayButton.enable();
      } else if (
        video &&
        video.isVideoLocked === false &&
        video.isTATVideoLocked === false
      ) {
        if (!player.src()) {
          player.src(src);
        }
        player.bigPlayButton.enable();
      }
    }
  };
  tempFunc.current = newFunc;
  useEffect(() => {
    tempFunc.current();
  }, [video, video.isVideoLocked, video.isTATVideoLocked]);

  const closeHandler = () => {
    setShowKarmaPrompt(false);
  };

  const handleKarmaModal = () => {
    if (user?.id) {
      setShowKarmaModel(true);
    } else {
      setShowAuthenticationModel(true);
      setShowKarmaPrompt(false);
    }
  };

  const renderVideoEl = ({ controls, className }) => {
    if (muxPlaybackUrls && muxPlaybackUrls.hls && muxPlaybackUrls.thumbnail) {
      return (
        <video
          controls={controls}
          ref={(node) => (videoRef = node)}
          className={className}
          // poster={muxPlaybackUrls.thumbnail}
        >
          <source src={muxPlaybackUrls.hls} type="application/x-mpegURL" />
          <source src={muxPlaybackUrls.dash} type="application/dash+xml" />
          <source src={muxPlaybackUrls.mp4} type="video/mp4" />
        </video>
      );
    } else {
      return (
        <video
          controls={controls}
          playsInline={true}
          ref={(node) => (videoRef = node)}
          className={className}
          poster={poster}
        >
          <source src={src} type="video/mp4" />
        </video>
      );
    }
  };

  return (
    <Wrapper>
      <div>
        <div data-vjs-player>
          {isLoading && (
            <div className="error-loading">
              <SkeletonShimmerStyled>
                <div
                  className="shimmer"
                  style={{
                    background:
                      varient === "moreWhite"
                        ? "rgba(255, 255, 255, 0.08)"
                        : "rgba(255, 255, 255, 0.02)",
                  }}
                ></div>
              </SkeletonShimmerStyled>
            </div>
          )}
          {componentName !== "LiveVideo" &&
            componentName !== "StreamPage" &&
            componentName !== "VideoClip" && (
              <>
                {video?.isVideoLocked && user?.id !== video.User.id ? ( //Pay Per View
                  <>
                    <VideoCardOverlayWithButton
                      componentName={"WatchVideo"}
                      video={video}
                      pageName={"watch-video"}
                    />
                    {renderVideoEl({
                      controls: false,
                      className: "video-js vjs-fluid",
                    })}
                  </>
                ) : (
                  <>
                    {renderVideoEl({
                      controls: true,
                      className: "video-js vjs-fluid vjs-big-play-centered",
                    })}
                    {showKarmaPrompt && video.keyVideoAccess !== 2 && (
                      <VideoCardTipOverlay
                        close={closeHandler}
                        handleKarmaModal={handleKarmaModal}
                        keyVideoAccess={video.keyVideoAccess}
                        isTATVideoLocked={video.isTATVideoLocked}
                      />
                    )}
                  </>
                )}
              </>
            )}
          {componentName === "StreamPage" && (
            <>
              <div className="video-full">
                {renderVideoEl({
                  controls: false,
                  className:
                    "height-video-stream video-js vjs-fluid vjs-big-play-centered",
                })}
                <div className="viewers-badge-stream">0000 viewers</div>
                <div className="stop-icon" onClick={stopStream}>
                  <StopStream width={50} />
                </div>
                <div className="reverse-icon">
                  <ReverseCamera width={30} />
                </div>
                <div className="more-icon">
                  <MoreStreamIcon width={7} />
                </div>
                <div className="live-class-title-stream">
                  <span>{channelName}</span>
                </div>
              </div>
            </>
          )}
          {video && (
            <>
              {!user.userrole && video.keyVideoAccess === 1 && (
                <>
                  <div className="overlay-text">
                    <h3>
                      You must be a Co-op Member to view this content. Relax,
                      it's only $5 a year... That's a cup of coffee!
                    </h3>
                  </div>

                  <div className="paymentButton">Sign Up Now!</div>
                </>
              )}
            </>
          )}

          {video && user.userrole === 0 && video.keyVideoAccess === 1 && (
            <>
              {video?.keyVideoAccess === 1 && (
                <>
                  <div className="overlay-text">
                    <h3>
                      You must be a Co-op Member to view this content. Relax,
                      it's only $5 a year... That's a cup of coffee!
                    </h3>
                  </div>

                  <div
                    className="paymentButton"
                    onClick={() => setShowManageSubscription(true)}
                  >
                    upgrade Membership!
                  </div>
                </>
              )}
            </>
          )}

          {componentName === "LiveVideo" && (
            <>
              {renderVideoEl({
                controls: false,
                className:
                  "height-video video-js vjs-fluid vjs-big-play-centered",
              })}
              <div className="viewers-badge">0000 viewers</div>
              <div className="minimize-icon">
                <MinimizeIcon width={30} />
              </div>
              <div className="pause-icon">
                <PauseIcon />
              </div>
              <div className="share-icon">
                <ShareIcon width={30} />
              </div>
              <div className="chat-icon" onClick={data.handleChat}>
                {data.chat ? <ChatIcon width={30} /> : <CloseChatIcon />}
              </div>
              <div className="full-screen-icon" onClick={data.handleFullScreen}>
                {data.fullscreen ? <RemoveFullScreen /> : <FullscreenIcon />}
              </div>
            </>
          )}
          {componentName === "VideoClip" && (
            <>
              {renderVideoEl({
                controls: false,
                className: "video-js vjs-fluid vjs-big-play-centered",
              })}
              <div className="fullscreen-icon">
                <FullscreenIcon width={30} />
              </div>
              <div className="minimize-icon">
                <MinimizeIcon width={30} />
              </div>
              <div className="pause-icon">
                <PauseIcon />
              </div>
              <div className="share-icon">
                <SettingIcon width={30} />
              </div>
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
};
const mapStateToProps = ({ video }) => ({
  poster: config.aws.CLOUDFRONT_URL + video.thumbnail,
  src: video.muxid
    ? `${config.mux.MUX_STREAMING_URL}/${video.muxid}.mp4`
    : config.aws.CLOUDFRONT_URL + video.url,
  isViewed: video.isViewed,
  video: {
    id: video.id,
    title: video.title,
    description: video.description,
    thumbnail: config.aws.CLOUDFRONT_URL + video.thumbnail,
    views: video.views + 1,
    isVideoLocked: video.isVideoLocked,
    keyVideoAccess: video.keyVideoAccess,
    isTATVideoLocked: video.isTATVideoLocked,
    amount: video.amount,
    muxid: video.muxid,
    User: {
      id: video.User?.id,
      username: video.User?.username,
      avatar: video.User?.avatar,
    },
  },
});

export default connect(mapStateToProps, { addToHistory })(Player);
