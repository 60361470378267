import React, { useState } from "react";
import styled from "styled-components";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Checkbox,
} from "@material-ui/core";
import { IoTrashSharp } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";
import AddItem from "./AddItem";
import Loader from "../../components/Elements/Loader";
import Error from "../../components/Elements/Error";

const tableHeadingStyle = {
  color: "white",
  border: "1px solid white",
};

const renderTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell width={300} align="center" style={tableHeadingStyle}>
          Series
        </TableCell>
        <TableCell align="center" style={tableHeadingStyle}>
          Featured
        </TableCell>
        <TableCell align="center" style={tableHeadingStyle}>
          Order
        </TableCell>
        <TableCell align="center" style={tableHeadingStyle}>
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const SeriesTable = ({ data, setData, loading, error, tab }) => {
  const history = useHistory();
  const [addNewModal, setAddNewModal] = useState(false);

  const deleteHandler = async (seriesId) => {
    try {
      await api.post(`admin/series/featured/remove`, {
        seriesId,
      });

      const filteredData = data.filter((el) => el.id !== seriesId);
      setData(filteredData);
      toast.success("Series removed from featured list.");
    } catch (err) {
      if (err) {
        toast.error("Something went wrong. Please try again!");
      }
    }
  };

  if (loading) {
    return <Loader text="Please wait while we fetch data for you." />;
  }

  if (!loading && error) {
    return <Error text="No data found!" />;
  }

  return (
    <SeriesTableStyled>
      <div onClick={() => setAddNewModal(true)} className="addNew">
        Add New
      </div>

      <Table>
        {renderTableHeader()}
        <TableBody>
          {data?.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                style={{
                  ...tableHeadingStyle,
                  color: "#FB0FC0",
                  textDecoration: "underline",
                  fontWeight: 400,
                  fontFamily: "brother-1816, sans-serif",
                }}
                onClick={() => {
                  history.push(`/series/details/${row.id}`);
                }}
              >
                {row?.title}
              </TableCell>
              <TableCell align="center" style={tableHeadingStyle}>
                <Checkbox checked={true} />
              </TableCell>
              <TableCell align="center" style={tableHeadingStyle}>
                {row?.featuredFormorder || index}
              </TableCell>
              <TableCell align="center" style={tableHeadingStyle}>
                <IoTrashSharp
                  onClick={() => deleteHandler(row.id)}
                  className="delete-button"
                  style={{ margin: "10px" }}
                  size={22}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* add item */}
      <AddItem
        type={tab}
        isOpen={addNewModal}
        onClose={() => setAddNewModal(false)}
        setData={setData}
        data={data}
      />
    </SeriesTableStyled>
  );
};

export default SeriesTable;

const SeriesTableStyled = styled.div`
  .addNew {
    font-family: brother-1816, sans-serif;
    font-size: 0.675rem;
    font-size: 400;
    text-transform: uppercase;
    background: ${(props) => props.theme.gradientV2};
    padding: 0.35rem 1rem;
    border-radius: 0.2rem;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 1rem;
  }
`;
