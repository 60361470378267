import React from "react";
import styled from "styled-components";
import SkeletonElement from "../SkeletonElement";
import { useMediaQuery } from "react-responsive";

const Channel = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const isTablet = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <ChannelSkeletonStyled>
      <div className="hero">
        <div className="hero__banner">
          <SkeletonElement type="bannerSmall" />
        </div>
        <div className="hero__userInfo">
          <SkeletonElement
            className="hero__userInfo--avatar"
            type="avatarXXL"
          />
          <SkeletonElement mt=".5rem" type="paragraph" />
          <SkeletonElement mt=".5rem" type="paragraph" />
        </div>
      </div>

      <div className="tabs">
        <div className="tabs__content">
          {Array.from({ length: isMobile ? 3 : isTablet ? 6 : 8 }).map(
            (item) => (
              <SkeletonElement type="menu" varient="dark" />
            )
          )}
        </div>
      </div>

      <div className="content">
        <div className="content__userInfo">
          <SkeletonElement
            type="title"
            className="content__userInfo--title"
            mb="1rem"
          />
          <SkeletonElement type="paragraph" mb=".3rem" />
          <SkeletonElement type="paragraph" mb=".3rem" />
        </div>
      </div>
    </ChannelSkeletonStyled>
  );
};

export default Channel;

const ChannelSkeletonStyled = styled.div`
  margin-bottom: 8rem;

  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__banner {
      width: 100%;
    }

    &__userInfo {
      transform: translateY(-3.5rem);
      width: 10rem;

      &--avatar {
        margin: 0 auto;
      }
    }
  }

  .tabs {
    width: 100%;
    background-color: #202020;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    padding: 1rem 0;

    &__content {
      width: 90%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      position: relative;
      overflow: hidden;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .content {
    &__userInfo {
      width: 55%;
      margin: 2rem auto;

      &--title {
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .tabs {
      padding: 0.7rem 0;
    }

    .content {
      &__userInfo {
        width: 90%;
        margin: 1.5rem auto;
      }
    }
  }
`;
