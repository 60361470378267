import React from "react";
import styled, { keyframes } from "styled-components";

const CancelSubscriptionModal = ({
  close,
  currentPlanName,
  handleCancelSubscription,
}) => {
  return (
    <CancelSubsModalStyled>
      <div className="confirmationContent">
        <div className="confirmationHeader">
          <h1 className="confirmationHeader__title">
            Are you sure you want to cancel
            <strong>{currentPlanName}</strong> ?
          </h1>
        </div>
        <div className="actionBtn">
          <button onClick={close} className="actionBtn__btn actionBtn__cancel">
            Cancel
          </button>
          <button
            onClick={handleCancelSubscription}
            className="actionBtn__btn actionBtn__confirm"
          >
            Confirm
          </button>
        </div>
      </div>
    </CancelSubsModalStyled>
  );
};

export default CancelSubscriptionModal;

const openModal = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const showContent = keyframes`
  from {
    transform: translateY(50rem);
    opacity: 0;
    scale: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0);
    scale: 1;
  }
`;

const CancelSubsModalStyled = styled.div`
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  border-radius: 0.3rem;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.4), 0px 0px 4px rgba(0, 0, 0, 0.25);
  animation: ${openModal} 0.5s ease-in-out;
  font-family: ${(props) => props.theme.montserrat};
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;

  .confirmationContent {
    width: 450px;
    background-color: #181818;
    border: 1px solid rgba(255, 255, 255, 0.1);
    animation: ${showContent} 0.5s ease;
  }

  .confirmationHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 4rem;
    height: auto;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);

    &__title {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.4;
    }
  }

  .actionBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__btn {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      background-color: transparent;
      padding: 0.7rem 0;
      border: none;
      outline: none;
      color: rgba(255, 255, 255, 0.4);
      font-weight: 200;
      transition: all 0.2s ease;
    }

    &__cancel {
      border-right: 1px solid rgba(255, 255, 255, 0.08);

      &:hover {
        background-color: ${(props) => props.theme.red};
        color: #fff;
      }
    }
    &__confirm {
      border-left: 1px solid rgba(255, 255, 255, 0.08);

      &:hover {
        background: ${(props) => props.theme.primaryGradient};
        color: #fff;
      }
    }
  }
`;
