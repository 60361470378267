import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MentionsInput, Mention } from "react-mentions";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ThreadBody = ({
  value,
  setValue,
  suggestionUsers,
  setSuggestionUsers,
  suggestionHashtags,
  setSuggestionHashtags,
}) => {
  const user = useSelector((state) => state.user);
  const [error, setError] = useState(null);
  const [limit, setLimit] = useState(3000);

  const { friendsError, friends, friendsLoading } = useSelector(
    (state) => state.connection
  );
  const videoHashtags = useSelector((state) => state.video.videoHashtags);

  useEffect(() => {
    if (friends?.length > 0) {
      const formatted = friends.map((item) => {
        const id =
          user.id === item.warrior.id
            ? item.collaborator.username
            : user.id === item.collaborator.id
            ? item.warrior.username
            : null;

        return {
          id,
          display: id,
        };
      });

      setSuggestionUsers(formatted);
    }

    if (videoHashtags?.length > 0) {
      const formatted = videoHashtags.map((item) => {
        const id = item.id;
        const display = item.name;

        return {
          id,
          display,
        };
      });

      setSuggestionHashtags(formatted);
    }
  }, [friends, videoHashtags]);

  useEffect(() => {
    if (value.length >= limit) {
      setError("You character limit is reached!");
    } else {
      setError(null);
    }
  }, [value]);

  return (
    <ThreadBodyStyled>
      <MentionsInput
        id="myTextarea"
        className="threadBody"
        name="threadBody"
        placeholder="Start writting your thread..."
        value={value}
        onChange={(e) => {
          if (e.target.value.length > 3000) {
            toast.dismiss();
            return toast.error("Character max limit reached.");
          }

          setValue(e.target.value);
        }}
      >
        <Mention
          key={"tagUser"}
          markup="@[__display__](__id__)"
          trigger="@"
          displayTransform={(id) => `@${id}`}
          data={suggestionUsers}
          appendSpaceOnAdd={true}
        />
        <Mention
          key={"hashTag"}
          markup="#[__display__](__id__)"
          trigger="#"
          displayTransform={(id) => {
            const display = suggestionHashtags?.find(
              (item) => item.id === id
            )?.display;
            return `#${display}`;
          }}
          data={suggestionHashtags}
          appendSpaceOnAdd={true}
        />
      </MentionsInput>

      <div className="statBar">
        {error && <div className="statBar__msg statBar__error">{error}</div>}

        {limit && (
          <div className="statBar__limit">
            {value.length} / {limit}
          </div>
        )}
      </div>
    </ThreadBodyStyled>
  );
};

export default ThreadBody;

const ThreadBodyStyled = styled.div`
  .threadBody {
    background-color: #3a3a3c;
    border-radius: 0.5rem;
    min-height: 15rem;

    &__input {
      border-radius: 0.5rem;
      width: 100%;
      padding: 0.7rem 1rem;
      border: none;
      outline: none;
      font-size: 1rem;
      font-weight: 400;
      color: rgba();
      font-family: ${(props) => props.theme.montserrat};
      transition: all 0.25s ease;
      border: 3px solid transparent;
      color: #fff;
      line-height: 1.6;
      height: 100%;

      &::placeholder {
        font-weight: 400;
        color: rgba(255, 255, 255, 0.4);
        letter-spacing: 0.3px;
      }

      &:focus {
        border: 3px solid ${(props) => props.theme.primaryColor1};
      }

      &:hover {
        border: 3px solid ${(props) => props.theme.primaryColor1};
      }
    }

    &__suggestions {
      background-color: #181818 !important;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      font-size: 0.84rem;
      overflow: hidden;
      /* top: -0.5rem !important;
      left: 0.27rem !important; */

      &__item {
        padding: 0 1rem;
        height: 2.2rem;
        transition: all 0.4s ease;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:not(:last-child) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }

        &--focused {
          background: ${(props) => props.theme.primaryGradient};
        }
      }
    }
  }

  .statBar {
    margin-top: 0.25rem;
    line-height: 1;
    padding: 0 0.25rem;
    position: relative;
    width: 100%;

    &__msg {
      width: 90%;
      font-size: 0.77rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      line-height: 1;
    }

    &__error {
      color: ${(props) => props.theme.red};
    }

    &__limit {
      position: absolute;
      top: 0;
      right: 0;
      background: ${(props) => props.theme.primaryGradient};
      padding: 0.3rem 0.5rem;
      display: inline-block;
      border-radius: 2px;
      font-size: 0.7rem;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
`;
