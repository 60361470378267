import React from "react";
import styled from "styled-components";

const Checkbox = ({ title, text, value, onChange, name }) => {
  return (
    <CheckboxStyled>
      <label className="checkBoxItem">
        <input
          name={name}
          type="checkbox"
          checked={value}
          value={value}
          onChange={onChange}
        />
        <span className="checkmark"></span>
        {title}
      </label>

      {text && <p>{text}</p>}
    </CheckboxStyled>
  );
};

export default Checkbox;

const CheckboxStyled = styled.div`
  .checkBoxItem {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 2.25rem;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    transform: translateY(0.6rem);
  }
  .checkBoxItem:hover input ~ .checkmark {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .checkBoxItem input:checked ~ .checkmark {
    background-image: ${(props) => props.theme.primaryGradient};
  }
  .checkBoxItem input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkBoxItem .checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 27px;
    width: 27px;
    background-color: #fff;
    border-radius: 5px;
    outline: none;
  }
  .checkBoxItem .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .checkBoxItem input:checked ~ .checkmark:after {
    display: block;
  }
  .checkBoxItem .checkmark:after {
    left: 10px;
    top: 3px;
    width: 8px;
    height: 15px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  p {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.35);
    margin-bottom: 1rem;
  }
`;
