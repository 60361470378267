import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import Button from "../../components/Elements/Button";
import InputV2 from "../../components/Elements/InputV2";
import { FormikProvider, useFormik } from "formik";
import { MarketingPageStyles } from "./Styles";
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { addMarketingPricingSection } from "../../actions/marketing";
import { toast } from "react-toastify";
import { RESET_MARKETING_PAGE_STATE } from "../../actions/marketing/type";

const Schema = Yup.object().shape({
  planName1: Yup.string().required("Plan name is required."),
  price1: Yup.number().required("Price is required."),
  duration1: Yup.string().required("Duration is required."),
  description1: Yup.string().required("Description is required."),
  planName2: Yup.string().required("Plan name is required."),
  price2: Yup.number().required("Price is required."),
  duration2: Yup.string().required("Duration is required."),
  description2: Yup.string().required("Description is required."),
  planName3: Yup.string().required("Plan name is required."),
  price3: Yup.number().required("Price is required."),
  duration3: Yup.string().required("Duration is required."),
  description3: Yup.string().required("Description is required."),
});

const PricingForm = () => {
  const dispatch = useDispatch();
  const {
    data: marketingPageData,
    saveLoading,
    saveSuccess,
    saveError,
  } = useSelector((state) => state.marketingPage);
  const [benefits1, setBenefits1] = useState(null);
  const [benefits2, setBenefits2] = useState(null);
  const [benefits3, setBenefits3] = useState(null);
  const [benefitText1, setBenefitText1] = useState("");
  const [benefitText2, setBenefitText2] = useState("");
  const [benefitText3, setBenefitText3] = useState("");
  const benefitInputRef1 = useRef();
  const benefitInputRef2 = useRef();
  const benefitInputRef3 = useRef();

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        if (document.activeElement === benefitInputRef1?.current) {
          if (!benefitText1) {
            return toast.error("Write something to add.");
          }

          if (benefits1?.length) {
            setBenefits1([...benefits1, benefitText1]);
          } else {
            setBenefits1([benefitText1]);
          }
          setBenefitText1("");
        }

        if (document.activeElement === benefitInputRef2?.current) {
          if (!benefitText2) {
            return toast.error("Write something to add.");
          }

          if (benefits2?.length) {
            setBenefits2([...benefits2, benefitText2]);
          } else {
            setBenefits2([benefitText2]);
          }
          setBenefitText2("");
        }

        if (document.activeElement === benefitInputRef3?.current) {
          if (!benefitText3) {
            return toast.error("Write something to add.");
          }

          if (benefits3?.length) {
            setBenefits3([...benefits3, benefitText3]);
          } else {
            setBenefits3([benefitText3]);
          }
          setBenefitText3("");
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [benefitText1, benefitText2, benefitText3]);

  const formik = useFormik({
    initialValues: {
      planName1: "",
      price1: null,
      duration1: "",
      description1: "",
      planName2: "",
      price2: null,
      duration2: "",
      description2: "",
      planName3: "",
      price3: null,
      duration3: "",
      description3: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      await saveFormHandler(values);
    },
  });

  useEffect(() => {
    if (saveSuccess) {
      toast.success("Content updated successfully.", {
        toastId: "pricingSuccess",
      });
    }

    if (saveError) {
      toast.success("Something went worng. Please try again!", {
        toastId: "pricingError",
      });
    }

    dispatch({
      type: RESET_MARKETING_PAGE_STATE,
    });
  }, [saveSuccess, saveError]);

  useEffect(() => {
    if (marketingPageData) {
      const pricingData = marketingPageData?.["pricing"];

      if (pricingData?.length > 0) {
        const pricing1 = pricingData[0];
        const pricing2 = pricingData[1];
        const pricing3 = pricingData[2];

        formik.setValues({
          planName1: pricing1?.plan_name || "",
          price1: pricing1?.price || 0,
          duration1: pricing1?.duration || "",
          description1: pricing1?.description || "",

          planName2: pricing2?.plan_name || "",
          price2: pricing2?.price || 0,
          duration2: pricing2?.duration || "",
          description2: pricing2?.description || "",

          planName3: pricing3?.plan_name || "",
          price3: pricing3?.price || 0,
          duration3: pricing3?.duration || "",
          description3: pricing3?.description || "",
        });

        setBenefits1(pricing1?.benefits || null);
        setBenefits2(pricing2?.benefits || null);
        setBenefits3(pricing3?.benefits || null);
      }
    }
  }, [marketingPageData]);

  const saveFormHandler = async (values) => {
    if (saveLoading) {
      return;
    }

    try {
      const pricingData = marketingPageData?.["pricing"];
      if (pricingData?.length) {
        const pricing1 = pricingData[0];
        const pricing2 = pricingData[1];
        const pricing3 = pricingData[2];

        const objArray = [
          {
            id: pricing1?.id,
            plan_name: values.planName1,
            price: values.price1,
            duration: values.duration1,
            description: values.description1,
            benefits: benefits1,
          },

          {
            id: pricing2?.id,
            plan_name: values.planName2,
            price: values.price2,
            duration: values.duration2,
            description: values.description2,
            benefits: benefits2,
          },

          {
            id: pricing3?.id,
            plan_name: values.planName3,
            price: values.price3,
            duration: values.duration3,
            description: values.description3,
            benefits: benefits3,
          },
        ];

        const promises = objArray.map((item) => {
          dispatch(addMarketingPricingSection(item));
        });
        await Promise.all(promises);
      } else {
        const objArray = [
          {
            id: null,
            plan_name: values.planName1,
            price: values.price1,
            duration: values.duration1,
            description: values.description1,
            benefits: benefits1,
          },
          {
            id: null,
            plan_name: values.planName2,
            price: values.price2,
            duration: values.duration2,
            description: values.description2,
            benefits: benefits2,
          },
          {
            id: null,
            plan_name: values.planName3,
            price: values.price3,
            duration: values.duration3,
            description: values.description3,
            benefits: benefits3,
          },
        ];
        const promises = objArray.map((item) => {
          dispatch(addMarketingPricingSection(item));
        });
        await Promise.all(promises);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!");
    }
  };

  const removeBenefitItemHandler = (name, number) => {
    if (number === 1) {
      const updatedBenefits1 = benefits1?.filter((el) => el !== name);
      setBenefits1(updatedBenefits1);
    }

    if (number === 2) {
      const updatedBenefits2 = benefits2?.filter((el) => el !== name);
      setBenefits2(updatedBenefits2);
    }

    if (number === 3) {
      const updatedBenefits3 = benefits3?.filter((el) => el !== name);
      setBenefits3(updatedBenefits3);
    }
  };

  return (
    <FormikProvider value={formik}>
      <MarketingPageStyles>
        <div className="form">
          <div className="form__pricing">
            {/* section 1 */}
            <div className="form__pricing--section form__pricing--section-1">
              <div className="form__pricing--section-title">Basic plan</div>

              <div className="form__item">
                <Row>
                  <Col md={6} sm={12}>
                    <InputV2
                      name="planName1"
                      type={"text"}
                      placeholder={"Add Plan Name"}
                      id={"planName1"}
                      label={"Plan Name"}
                      value={formik.values.planName1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formikError={
                        formik.touched.planName1 && formik.errors.planName1
                      }
                    />
                  </Col>

                  <Col md={6} sm={12}>
                    <InputV2
                      name="price1"
                      type={"number"}
                      placeholder={"Add Plan Price"}
                      id={"price1"}
                      label={"Plan Price"}
                      value={formik.values.price1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formikError={
                        formik.touched.price1 && formik.errors.price1
                      }
                    />
                  </Col>
                </Row>
              </div>

              <div className="form__item">
                <InputV2
                  name="duration1"
                  type={"select"}
                  placeholder={"Add Plan Duration"}
                  id={"duration1"}
                  label={"Plan Duration"}
                  options={[
                    {
                      name: "Monthly",
                      value: "monthly",
                    },
                    {
                      name: "Yearly",
                      value: "yearly",
                    },
                  ]}
                  value={formik.values.duration1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={
                    formik.touched.duration1 && formik.errors.duration1
                  }
                />
              </div>

              <div className="form__item">
                <InputV2
                  name="description1"
                  type={"textarea"}
                  placeholder={"Add Package Description"}
                  id={"description1"}
                  label={"Package Description"}
                  value={formik.values.description1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={
                    formik.touched.description1 && formik.errors.description1
                  }
                />
              </div>

              <div className="subTitle">Package benefits</div>

              <div className="form__pricing--section-benefits">
                {benefits1 && benefits1?.length
                  ? benefits1.map((item, i) => (
                      <div
                        onClick={() => removeBenefitItemHandler(item, 1)}
                        key={i}
                        className="item"
                      >
                        {item}
                        <div className="remove">(remove item)</div>
                      </div>
                    ))
                  : null}

                <div className="input">
                  <InputV2
                    inputRef={benefitInputRef1}
                    name="benefitText1"
                    type={"text"}
                    placeholder={"Add Plan Benefit"}
                    id={"addBenefit"}
                    size="small"
                    value={benefitText1}
                    onChange={(e) => {
                      setBenefitText1(e.target.value);
                    }}
                  />
                  <div className="infoText">Hit ENTER to add new Benefit.</div>
                </div>
              </div>
            </div>

            {/* section 2 */}
            <div className="form__pricing--section form__pricing--section-2">
              <div className="form__pricing--section-title">Standard plan</div>

              <div className="form__item">
                <Row>
                  <Col md={6} sm={12}>
                    <InputV2
                      name="planName2"
                      type={"text"}
                      placeholder={"Add Plan Name"}
                      id={"planName2"}
                      label={"Plan Name"}
                      value={formik.values.planName2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formikError={
                        formik.touched.planName2 && formik.errors.planName2
                      }
                    />
                  </Col>

                  <Col md={6} sm={12}>
                    <InputV2
                      name="price2"
                      type={"number"}
                      placeholder={"Add Plan Price"}
                      id={"price2"}
                      label={"Plan Price"}
                      value={formik.values.price2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formikError={
                        formik.touched.price2 && formik.errors.price2
                      }
                    />
                  </Col>
                </Row>
              </div>

              <div className="form__item">
                <InputV2
                  name="duration2"
                  type={"select"}
                  placeholder={"Add Plan Duration"}
                  id={"duration2"}
                  label={"Plan Duration"}
                  options={[
                    {
                      name: "Monthly",
                      value: "monthly",
                    },
                    {
                      name: "Yearly",
                      value: "yearly",
                    },
                  ]}
                  value={formik.values.duration2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={
                    formik.touched.duration2 && formik.errors.duration2
                  }
                />
              </div>

              <div className="form__item">
                <InputV2
                  name="description2"
                  type={"textarea"}
                  placeholder={"Add Package Description"}
                  id={"description2"}
                  label={"Package Description"}
                  value={formik.values.description2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={
                    formik.touched.description2 && formik.errors.description2
                  }
                />
              </div>

              <div className="subTitle">Package benefits</div>

              <div className="form__pricing--section-benefits">
                {benefits2 && benefits2?.length
                  ? benefits2.map((item, i) => (
                      <div
                        onClick={() => removeBenefitItemHandler(item, 2)}
                        key={i}
                        className="item"
                      >
                        {item}
                        <div className="remove">(remove item)</div>
                      </div>
                    ))
                  : null}

                <div className="input">
                  <InputV2
                    inputRef={benefitInputRef2}
                    name="benefitText2"
                    type={"text"}
                    placeholder={"Add Plan Benefit"}
                    id={"benefitText2"}
                    size="small"
                    value={benefitText2}
                    onChange={(e) => setBenefitText2(e.target.value)}
                  />
                  <div className="infoText">Hit ENTER to add new Benefit.</div>
                </div>
              </div>
            </div>

            {/* section 3 */}
            <div className="form__pricing--section form__pricing--section-3">
              <div className="form__pricing--section-title">Exclusive plan</div>

              <div className="form__item">
                <Row>
                  <Col md={6} sm={12}>
                    <InputV2
                      name="planName3"
                      type={"text"}
                      placeholder={"Add Plan Name"}
                      id={"planName3"}
                      label={"Plan Name"}
                      value={formik.values.planName3}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formikError={
                        formik.touched.planName3 && formik.errors.planName3
                      }
                    />
                  </Col>

                  <Col md={6} sm={12}>
                    <InputV2
                      name="price3"
                      type={"number"}
                      placeholder={"Add Plan Price"}
                      id={"price3"}
                      label={"Plan Price"}
                      value={formik.values.price3}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formikError={
                        formik.touched.price3 && formik.errors.price3
                      }
                    />
                  </Col>
                </Row>
              </div>

              <div className="form__item">
                <InputV2
                  name="duration3"
                  type={"select"}
                  placeholder={"Add Plan Duration"}
                  id={"duration3"}
                  label={"Plan Duration"}
                  options={[
                    {
                      name: "Monthly",
                      value: "monthly",
                    },
                    {
                      name: "Yearly",
                      value: "yearly",
                    },
                  ]}
                  value={formik.values.duration3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={
                    formik.touched.duration3 && formik.errors.duration3
                  }
                />
              </div>

              <div className="form__item">
                <InputV2
                  name="description3"
                  type={"textarea"}
                  placeholder={"Add Package Description"}
                  id={"description3"}
                  label={"Package Description"}
                  value={formik.values.description3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={
                    formik.touched.description3 && formik.errors.description3
                  }
                />
              </div>

              <div className="subTitle">Package benefits</div>
              <div className="form__pricing--section-benefits">
                {benefits3 && benefits3?.length
                  ? benefits3.map((item, i) => (
                      <div
                        onClick={() => removeBenefitItemHandler(item, 3)}
                        key={i}
                        className="item"
                      >
                        {item}
                        <div className="remove">(remove item)</div>
                      </div>
                    ))
                  : null}

                <div className="input">
                  <InputV2
                    inputRef={benefitInputRef3}
                    name="benefitText3"
                    type={"text"}
                    placeholder={"Add Plan Benefit"}
                    id={"benefitText3"}
                    size="small"
                    value={benefitText3}
                    onChange={(e) => setBenefitText3(e.target.value)}
                  />
                  <div className="infoText">Hit ENTER to add new Benefit.</div>
                </div>
              </div>
            </div>

            <div className="form__pricing--section">
              <Button
                onClick={() => formik.handleSubmit()}
                className="form__pricing--btn"
                mode="primary"
                width="fluid"
                variant="round"
              >
                {saveLoading ? "Please wait..." : "Save Changes"}
              </Button>
            </div>
          </div>
        </div>
      </MarketingPageStyles>
    </FormikProvider>
  );
};

export default PricingForm;
