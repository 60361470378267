import React, { useState } from "react";
import styled from "styled-components";
import Player from "react-player";
import config from "../../../config";
import { toast } from "react-toastify";

const ChannelPreviewVideo = ({ data }) => {
  const [showVideo, setShowVideo] = useState(false);

  const onEnd = () => {
    setShowVideo(false);
    toast.success(
      "Are you enjoying the channel preview video? Subscribe channel and explore more content of the creator."
    );
  };

  return (
    <ChannelPreviewVideoStyled>
      <div className="container">
        {showVideo ? (
          <Player
            url={config.aws.CLOUDFRONT_URL + data?.url}
            playing={true}
            height={"100%"}
            width={"100%"}
            controls={true}
            className="container__videoPlayer"
            onEnded={onEnd}
          />
        ) : (
          <>
            <img
              className="container__thumbnail"
              src={config.aws.CLOUDFRONT_URL + data?.thumbnail}
              alt="thumbnail"
            />

            <div className="container__overlay"></div>

            <div
              onClick={() => setShowVideo(!showVideo)}
              className="container__playBtn"
            >
              <img src="/icons/play-white.svg" alt="" />
              play video
            </div>

            <div className="container__title">
              Channel <br />
              <div className="container__title--outline">preview video</div>
            </div>
          </>
        )}
      </div>
    </ChannelPreviewVideoStyled>
  );
};

export default ChannelPreviewVideo;

const ChannelPreviewVideoStyled = styled.div`
  margin-bottom: 2.5rem;
  width: 100%;

  .container {
    width: 60%;
    min-width: 480px;
    margin: 0 auto;
    height: auto;
    aspect-ratio: 16/9;
    position: relative;
    background-color: ${(props) => props.theme.darkGrey};
    overflow: hidden;

    &__thumbnail {
      width: 100%;
      position: absolute;
      height: 100%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
    }

    &__overlay {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
      background: linear-gradient(
        to top,
        rgb(252, 0, 77, 0.65) 40%,
        transparent 70%
      );
      transform: scale(1.25);
    }

    &__playBtn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 7.5rem;
      width: 7.5rem;
      border-radius: 50%;
      border: 1px solid #fff;
      background-color: transparent;
      z-index: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-family: brother-1816, sans-serif;
      font-size: 0.675rem;
      font-size: 600;
      gap: 0.5rem;
      cursor: pointer;

      img {
        width: 1.5rem;
        height: auto;
      }
    }

    &__title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      z-index: 2;
      padding: 0 0 1.25rem 1.25rem;
      text-transform: uppercase;
      font-family: brother-1816, sans-serif;
      font-weight: 600;
      font-size: 3.25rem;
      line-height: 1.15;
      color: rgba(255, 255, 255, 0.75);

      &--outline {
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(255, 255, 255, 0.85);
        color: transparent;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .container {
      width: 100%;
      min-width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      &__playBtn {
        height: 5rem;
        width: 5rem;
        font-size: 0.5rem;

        img {
          width: 1rem;
        }
      }

      &__title {
        font-size: 2rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .container {
      &__title {
        font-size: 1.75rem;
        padding: 0 0 1rem 1rem;
      }
    }
  }
`;
