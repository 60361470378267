import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const VideoPlayer = ({ isOpen, url, onClose }) => {
  const onEnd = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <VideoPlayerStyled>
      <div className="container">
        <div onClick={onClose} className="container__close">
          <img src="/icons/close-white.svg" alt="" />
        </div>

        <ReactPlayer
          url={url}
          playing={true}
          height={"100%"}
          width={"100%"}
          controls={true}
          className="container__videoPlayer"
          onEnded={onEnd}
        />
      </div>
    </VideoPlayerStyled>
  );
};

export default VideoPlayer;

const VideoPlayerStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  max-width: 100vw;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .container {
    width: 60%;
    aspect-ratio: 16/9;
    background-color: black;
    border: 1px solid rgba(255, 255, 255, 1);
    position: relative;
    overflow: hidden;

    &__close {
      position: absolute;
      top: 0;
      right: 0;
      height: 2rem;
      width: 2rem;
      border-bottom-left-radius: 0.25rem;
      background: ${(props) => props.theme.gradient};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 5;

      img {
        width: 1rem;
        height: auto;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .container {
      width: 80%;
    }
  }

  @media screen and (max-width: 480px) {
    .container {
      width: 95%;
    }
  }
`;
