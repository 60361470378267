import {
  USER_STORAGE_INFO_FAIL,
  USER_STORAGE_INFO_REQUEST,
  USER_STORAGE_INFO_SUCCESS,
} from "../actions/types";

const userStorage = (state = {}, action) => {
  switch (action.type) {
    case USER_STORAGE_INFO_REQUEST:
      return {
        loading: true,
      };
    case USER_STORAGE_INFO_SUCCESS:
      return {
        loading: false,
        storageInfo: action.payload,
      };
    case USER_STORAGE_INFO_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userStorage;
