import React from "react";
import styled from "styled-components";
import AddIcon from "../../assets/Icons/add.svg";
import config from "../../config";

const FileUpload = ({
  id,
  onChange,
  accept = "image",
  explain,
  title,
  display,
  bg,
}) => {
  return (
    <FileUploadStyled>
      <div className="title">{title}</div>

      <label htmlFor={id} className="upload">
        <img src={AddIcon} alt="Add Icon" className="icon" />
        <p>{explain}</p>
      </label>

      <input
        style={{ display: "none" }}
        id={id}
        type="file"
        accept={`${accept}/*`}
        onChange={onChange}
      />

      {display?.noBg === false && display?.url && (
        <div
          style={{
            background: bg,
          }}
          className="preview"
        >
          <img
            style={{
              width: display?.width,
            }}
            src={
              display.filePreview
                ? display.url
                : config.aws.CLOUDFRONT_URL + display.url
            }
            alt=""
          />
        </div>
      )}

      {display?.noBg === true && display?.url && (
        <div className="previewV2">
          <img
            src={
              display.filePreview
                ? display.url
                : config.aws.CLOUDFRONT_URL + display.url
            }
            alt=""
          />
        </div>
      )}
    </FileUploadStyled>
  );
};

export default FileUpload;

const FileUploadStyled = styled.div`
  .upload {
    width: 100%;
    min-height: 7rem;
    background-color: #3a3a3c;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    height: auto;
    cursor: pointer;

    img {
      height: 2.5rem;
      width: auto;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.85rem;
      font-weight: 300;
      color: #f2f2f7;
      line-height: 1.4;
      margin-bottom: 2rem;
      text-align: center;
    }
  }

  .title {
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    color: #f2f2f7;
  }

  .preview {
    width: 100%;
    margin-top: 1.5rem;
    border-radius: 1.25rem;
    padding: 1.5rem;
    line-height: 1;
    text-align: center;
    background: ${(props) => props.theme.primaryGradient};

    img {
      width: 70%;
      height: auto;
      background-size: cover;
      background-position: center;
      object-fit: cover;
    }
  }

  .previewV2 {
    width: 100%;
    margin-top: 1.5rem;
    border-radius: 1.25rem;
    height: auto;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.1);

    img {
      width: 100%;
      height: auto;
      background-size: cover;
      background-position: center;
      object-fit: cover;
    }
  }
`;
