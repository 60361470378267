import React, { useState } from "react";
import styled from "styled-components";

const SavedCreditCard = ({ item, setSelectedPM, selectedPM }) => {
  const handleToggle = (pm) => {
    if (selectedPM?.id === pm?.id) {
      setSelectedPM(null);
    } else {
      setSelectedPM(pm);
    }
  };

  return (
    <SavedCreditCardStyled>
      <div onClick={() => handleToggle(item)} className="item" key={item.id}>
        <div className="item__checkbox">
          <label>
            <input
              type="checkbox"
              value={selectedPM?.id === item?.id ? true : false}
              onChange={() => handleToggle(item)}
              className={selectedPM?.id === item?.id ? "checked" : ""}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="item__box">
          <div className="item__box--name">
            {item.card.brand.substring(0, 4)}
          </div>
          <div className="item__box--number">
            **** **** **** {item.card.last4}
          </div>
          <div className="item__box--actions">
            <div className="delete">Delete</div>
          </div>
        </div>
      </div>
    </SavedCreditCardStyled>
  );
};

export default SavedCreditCard;

const SavedCreditCardStyled = styled.div`
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    cursor: pointer;

    &__checkbox {
      width: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      line-height: 1;

      input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        width: 1.7rem;
        height: 1.7rem;
        border-radius: 0.15em;
        outline: none;
        cursor: pointer;
        background: #fff;
        position: relative;
      }

      input.checked {
        background: ${(props) => props.theme.primaryGradient};
        position: relative;
      }

      input.checked::before {
        content: "";
        background-color: transparent;

        /* position the checkbox */
        position: absolute;
        left: 12px;
        top: 7px;
        transform: translate(-50%, -50%);

        /* setting the checkbox */
        /* short arm */
        width: 6px;
        border-bottom: 2px solid #fff;
        /* long arm */
        height: 12px;
        border-right: 2px solid #fff;

        /* rotate the mirrored L to make it a checkbox */
        transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }

    &__box {
      width: calc(100% - 3.5rem);
      background: ${(props) => props.theme.primaryGradient};
      border-radius: 0.234rem;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;

      &--actions {
        width: 5rem;
        padding: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: brother-1816, sans-serif;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.68rem;
        user-select: none;
        cursor: pointer;
      }

      &--name {
        width: 5rem;
        padding-left: 1rem;
        font-family: brother-1816, sans-serif;
        font-size: 1.1rem;
        font-weight: 600;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.8);
      }

      &--number {
        width: calc(100% - 10rem);
        text-align: center;
        font-weight: 300;
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.85);
      }
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 480px) {
    .item {
      &__checkbox {
        width: 2.5rem;
        justify-content: center;

        input[type="checkbox"] {
          width: 1.2rem;
          height: 1.2rem;
        }

        input.checked::before {
          left: 8px;
          top: 2px;
        }
      }

      &__box {
        width: calc(100% - 2.5rem);

        &--actions {
          width: 3.5rem;
          padding: 0.8rem 0;
          font-size: 0.55rem;
        }

        &--name {
          width: 3rem;
          padding-left: 0.8rem;
          font-size: 0.9rem;
        }

        &--number {
          width: calc(100% - 7rem);
          font-size: 0.8rem;
        }
      }

      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }
  }
`;
