import api from "../../services/api";
import {
  GET_ALL_HASHTAGS_REQUEST,
  GET_ALL_HASHTAGS_SUCCESS,
  GET_ALL_HASHTAGS_FAIL,
  GET_SINGLE_HASHTAG_REQUEST,
  GET_SINGLE_HASHTAG_SUCCESS,
  GET_SINGLE_HASHTAG_FAIL,
} from "./type";

export const getHashtags =
  (page = 1, limit = 1) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_HASHTAGS_REQUEST,
      });

      const res = await api.get(`hashtag?page=${page}&limit=${limit}`);

      dispatch({
        type: GET_ALL_HASHTAGS_SUCCESS,
        payload: {
          hashtags: res.data.data.hashtags,
          hasMore: res.data.data.hasMore,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_HASHTAGS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getSingleHashtag =
  (
    hashtagId,
    exploringContent,
    loggedInUserId,
    videoPage,
    videoLimit,
    momentPage,
    momentLimit,
    threadPage,
    threadLimit
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_SINGLE_HASHTAG_REQUEST,
      });

      const res = await api.get(
        `hashtag/${hashtagId}?exploringContent=${exploringContent}&loggedInUserId=${loggedInUserId}&videosPage=${videoPage}&videosLimit=${videoLimit}&momentsPage=${momentPage}&momentsLimit=${momentLimit}&threadsPage=${threadPage}&threadsLimit=${threadLimit}`
      );

      dispatch({
        type: GET_SINGLE_HASHTAG_SUCCESS,
        payload: {
          hashtag: res.data.data.hashtag,
          videos: res.data.data.videos,
          moments: res.data.data.moments,
          threads: res.data.data.threads,
          hasMoreVideos: res.data.data.hasMoreVideos,
          hasMoreMoments: res.data.data.hasMoreMoments,
          hasMoreThreads: res.data.data.hasMoreThreads,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_SINGLE_HASHTAG_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
