import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AdminSidebar from "../../AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { IoTrashSharp } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import Pagination from "../../Pagination";
import {
  adminVideoSubCategoryData,
  deleteAdminSubCategoryOne,
} from "../../../actions";
import SubCategoryOneEdit from "./SubCategoryOneEdit";
import AddSubCategoryOne from "./AddSubCategoryOne";
import ConfirmationModel from "../../../components/ConfirmationModel/index";
import Button from "../../../components/Elements/Button";

const SubCategoryOne = () => {
  const subCategoryOne = useSelector(
    (state) => state.videoTableData.subCategoryOneData
  );
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [formData, setFormData] = useState({});
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const itemsPerPage = 5;

  useEffect(() => {
    dispatch(adminVideoSubCategoryData());
  }, [dispatch]);

  //   serching------------------
  const filterData = () => {
    return subCategoryOne
      ? subCategoryOne.filter((row) => {
          const lowerSearch = search.toLowerCase();
          return (
            (row.name && row.name.toLowerCase().includes(lowerSearch)) ||
            (row.videoCategoryId &&
              row.videoCategoryId
                .toString()
                .toLowerCase()
                .includes(lowerSearch)) ||
            (row.id && row.id.toString().toLowerCase().includes(lowerSearch))
          );
        })
      : [];
  };
  // Pagination -------------
  const filteredData = filterData();
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleClick = (row) => {
    setIsOpenModel(true);
  };
  //   Edit ------------------------
  const handleEdit = (row) => {
    setIsEditing(true);
    setFormData({
      id: row.id,
      name: row.name,
      videoCategoryId: row.videoCategoryId,
      createdAt: row.createdAt,
      updatedAt: row.updatedAt,
    });
  };
  const handleFormChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  //   Delete-------------------
  const handleDelete = (row) => {
    setShowDeleteConfirmation(true);
    setFormData({ id: row.id });
  };
  const deleteVideoHandler = async () => {
    try {
      await dispatch(deleteAdminSubCategoryOne(formData.id));
      setShowDeleteConfirmation(false);
      await dispatch(adminVideoSubCategoryData());
    } catch (error) {
      console.error("Error deleting SubCategoryOne ", error);
    }
  };
  // Sorting data ----------------------
  const sortData = () => {
    const sortedData = [...filteredData];
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortColumn].localeCompare(b[sortColumn]);
      } else {
        return b[sortColumn].localeCompare(a[sortColumn]);
      }
    });
    return sortedData;
  };
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="tableHeader">Id</TableCell>
          <TableCell className="tableHeader" onClick={() => handleSort("name")}>
            Name
            {sortColumn === "name" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell className="tableHeader" onClick={() => handleSort("name")}>
            Video Category Id
            {sortColumn === "name" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell className="tableHeader">Created At </TableCell>
          <TableCell className="tableHeader">Updated At </TableCell>
          <TableCell className="tableHeader">Action </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      <SubCategoryOneDashboard>
        <AdminSidebar />
        <TableContainer component={Paper} className="tableContainer">
          <div>
            <h1 className="headingText">Search Type Of Sub Category One</h1>
            <input
              type="text"
              placeholder="Search The Sub Category One"
              className="searchBox"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="addData">
            <Button mode="primary" onClick={() => handleClick()}>
              Add Data
            </Button>
          </div>
          <Table className="table">
            {renderTableHeader()}
            <TableBody>
              {sortData()
                .slice(startIndex, endIndex)
                .map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell className="tableCell">{row.id}</TableCell>
                    <TableCell className="tableCellWordWrap">
                      {row.name}
                    </TableCell>
                    <TableCell className="tableCellWordWrap">
                      {row.videoCategoryId}
                    </TableCell>
                    <TableCell className="tableCellWordWrap">
                      {row.createdAt}
                    </TableCell>
                    <TableCell className="tableCellWordWrap">
                      {row.updatedAt}
                    </TableCell>
                    <TableCell className="tableCell">
                      <FiEdit
                        style={{ margin: "8px" }}
                        className="editButton"
                        size={28}
                        onClick={() => handleEdit(row)}
                      />
                      <IoTrashSharp
                        style={{ margin: "8px" }}
                        className="deleteButton"
                        size={28}
                        onClick={() => handleDelete(row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div style={{ marginTop: "7px", marginBottom: "18%" }}>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={subCategoryOne ? subCategoryOne.length : 0}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </TableContainer>
      </SubCategoryOneDashboard>
      {isEditing && (
        <SubCategoryOneEdit
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          formData={formData}
          onChange={handleFormChange}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationModel
          closeHandler={() => setShowDeleteConfirmation(false)}
          title="Are you sure you want to delete this Sub Category One ? You can't undo this action."
          deleteHandler={deleteVideoHandler}
        />
      )}
      {isOpenModel && (
        <AddSubCategoryOne
          isOpen={isOpenModel}
          onClose={() => setIsOpenModel(false)}
        />
      )}
    </>
  );
};

export default SubCategoryOne;

const SubCategoryOneDashboard = styled.div`
  display: flex;
  .addData {
    margin-bottom: 10px;
  }
  .tableContainer {
    max-width: 100%;
    height: 100vh;
    background: #212123;
    padding-left: 2%;
    padding-right: 2%;
  }
  .table {
    background: #212123;
  }
  .tableHeader {
    background: #212123;
    color: white;
    border: 1px solid;
    font-size: 13px;
  }
  .tableCell {
    color: white;
    border: 1px solid;
    font-size: 13px;
  }
  .tableCellWordWrap {
    color: white;
    border: 1px solid;
    font-size: 13px;
    word-break: break-all;
  }
  .editButton {
    color: #4f92f7;
    marginleft: 10px;
    transition: transform 0.2s;
  }
  .editButton:hover {
    transform: scale(1.3);
  }

  .deleteButton {
    color: #f54e4e;
    marginleft: 10px;
    transition: transform 0.2s;
  }
  .deleteButton:hover {
    transform: scale(1.3);
  }

  .headingText {
    font-size: 18px;
    margin-top: 10px;
    color: white;
  }
  .searchBox {
    background: #2c2c2e !important;
    color: white;
    margin-bottom: 2%;
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: #cdc5c5;
    }
  }

  /* Responsive styling for the search box */
  @media (max-width: 768px) {
    .SearchBox {
      width: 100%;
    }
  }
`;
