import styled from "styled-components";

export const SeriesStyles = styled.div`
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999;
  background: #202020;
  border-radius: 0.3rem;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.4), 0px 0px 4px rgba(0, 0, 0, 0.25);
  transform: translateY(-100%);
  transition: all 1s ease;
  font-family: ${(props) => props.theme.montserrat};

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 5rem;
    border-bottom: 1px solid rgba(112, 112, 112, 0.4);

    .backIcon {
      margin-right: 1rem;
      cursor: pointer;

      img {
        height: 1rem;
        width: auto;
      }
    }

    .name {
      font-size: 1.3rem;
      font-weight: 500;
      color: #f2f2f7;
      text-transform: capitalize;
    }

    .left {
      display: flex;
      align-items: center;
    }

    .formState {
      border: 3px solid #fff;
      padding: 0.3rem 1.2rem;
      border-radius: 10rem;
      font-size: 1rem;
      background-color: transparent;
      color: #fff;
      font-family: ${(props) => props.theme.montserrat};
      font-weight: 400;
      text-transform: capitalize;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }

  .content {
    width: 90%;
    margin: 2rem auto;

    &__uploadBox {
      display: flex;
      align-items: center;
      width: 50%;
      margin: 0 auto 2rem auto;

      p {
        font-size: 1.1rem;
        color: #f2f2f7;
      }

      .lightText {
        font-weight: 400;
        margin-right: 0.5rem;
      }
      .boldText {
        font-weight: 600;
        margin-right: 0.5rem;
      }

      .icon {
        margin-right: 1rem;
        width: 4rem;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #2c2c2e;
        width: 100%;
        padding: 0.8rem 1rem;
        border-radius: 0.4rem;
        cursor: pointer;

        &__left {
          display: flex;
          align-items: center;
        }

        &__right {
          img {
            transform: translateY(4px);
            height: 1.2rem;
            width: auto;
          }
        }
      }
    }

    .infoBlock {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: #fff;
        padding: 0 3px;
        font-size: 1rem;
      }

      .lightText {
        font-weight: 400;
      }

      .boldText {
        font-weight: 600;
      }
    }

    &__videos {
      margin-bottom: 2rem;
      margin-top: 2rem;
    }

    &__seriesForm {
      width: 50%;
      margin: 0 auto 5rem auto;

      &--fieldGroup {
        margin-bottom: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .uploadedCoverPreview {
          width: 100%;
          margin-top: 2rem;

          img {
            width: 100%;
            height: auto;
            border-radius: 1rem;
            background-size: cover;
            background-position: center;
            object-fit: cover;
          }
        }

        .requestNewCategory {
          cursor: pointer;
          line-height: 1;
          font-family: brother-1816, sans-serif;
          padding: 0.4rem 1rem;
          text-transform: uppercase;
          font-size: 0.75rem;
          letter-spacing: 1px;
          font-weight: 500;
          color: #fff;
          border: none;
          outline: none;
          margin-top: 1rem;
          background: ${(props) => props.theme.primaryGradient};
          border-radius: 3px;
        }

        label {
          padding: 0 0 0.7rem 1rem;
          font-size: 1rem;
          font-weight: 400;
          text-transform: capitalize;
          color: #f2f2f7;
          letter-spacing: 1px;
        }

        input,
        textarea {
          width: 100%;
          padding: 1rem;
          background-color: #3a3a3c;
          color: #f2f2f7;
          border-radius: 0.5rem;
          border: none;
          outline: none;
          font-size: 1rem;
          font-weight: 400;
          color: #f2f2f7;
          font-family: ${(props) => props.theme.montserrat};
          transition: all 0.25s ease;
          border: 3px solid transparent;

          &::placeholder {
            font-weight: 300;
            color: #f2f2f7;
            letter-spacing: 0.3px;
          }

          &:focus {
            border: 3px solid #f88946;
          }
        }

        textarea {
          resize: none;
          height: 15rem;
        }

        .seriesUploadThumbnail {
          width: 100%;
          height: 7rem;
          background-color: #3a3a3c;
          border-radius: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          overflow: hidden;

          .icon {
            height: 2.5rem;
            width: auto;
          }
        }

        .title {
          padding: 0 0 0.7rem 1rem;
          font-size: 1rem;
          font-weight: 400;
          text-transform: capitalize;
          color: #f2f2f7;
          letter-spacing: 1px;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .content {
      &__uploadBox {
        width: 70%;
      }

      &__seriesForm {
        width: 70%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .header {
      padding: 1rem 2.5rem;

      .name {
        font-size: 1.1rem;
      }
    }

    .content {
      &__uploadBox {
        width: 90%;
      }

      &__seriesForm {
        width: 90%;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .content {
      &__uploadBox {
        width: 100%;

        .icon {
          margin-right: 0.5rem;
        }

        p {
          font-size: 0.85rem;
        }

        .box {
          padding: 0.6rem 1rem;

          &__right {
            img {
              height: 1rem;
            }
          }
        }
      }

      &__seriesForm {
        width: 100%;
      }

      .infoBlock {
        p {
          font-size: 0.85rem;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .header {
      padding: 1rem 1.5rem;

      .name {
        font-size: 0.9rem;
      }

      .formState {
        font-size: 0.9rem;
      }
    }

    .content {
      &__seriesForm {
        &--fieldGroup {
          margin-bottom: 1.5rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          label {
            padding: 0 0 0.7rem 1rem;
            font-size: 1rem;
            font-weight: 400;
            text-transform: capitalize;
            color: #f2f2f7;
            letter-spacing: 1px;
          }

          input,
          textarea {
            padding: 0.8rem 1rem;
            font-size: 0.9rem;
          }

          textarea {
            height: 12rem;
          }

          .seriesUploadThumbnail {
            height: 5rem;

            .icon {
              height: 2rem;
            }
          }

          .title {
            padding: 0 0 0.5rem 1rem;
            font-size: 0.95rem;
          }
        }
      }
    }
  }
`;
