import React from "react";
import styled from "styled-components";
import Button from "../Elements/Button";
import { useHistory } from "react-router-dom";
import { isBadgeOrAvatar } from "../../utils";
import { useInView } from "react-intersection-observer";

const HashtagCard = ({ item }) => {
  const history = useHistory();

  return (
    <HashtagCardStyled>
      <div className="header">
        <div className="title">
          <div className="number">{item?.count?.all}</div> #{item?.name}
        </div>
        <div className="users">
          {item?.people &&
            item.people?.length > 0 &&
            item.people.map((item, i) => {
              const creatorImage = item?.avatar
                ? item.avatar
                : item?.badge
                ? item.badge
                : null;

              return (
                <div className="users__item">
                  {isBadgeOrAvatar(creatorImage)}
                </div>
              );
            })}

          {/* <div className="users__item">
            <div className="total">+9</div>
          </div> */}
        </div>
      </div>

      <div className="stats">
        <div
          onClick={() => {
            history.push(`/hashtag/${item?.name}?activeTab=CLIPS`);
          }}
          className="stats__item"
        >
          {item?.count?.video} Videos
        </div>
        <div className="stats__seperator"></div>
        <div
          onClick={() => {
            history.push(`/hashtag/${item?.name}?activeTab=MOMENTS`);
          }}
          className="stats__item"
        >
          {item?.count?.moment} Moments
        </div>
        <div className="stats__seperator"></div>
        <div
          onClick={() => {
            history.push(`/hashtag/${item?.name}?activeTab=THREADS`);
          }}
          className="stats__item"
        >
          {item?.count?.thread} Threads
        </div>
      </div>

      <Button
        onClick={() => {
          history.push(`/hashtag/${item?.name}`);
        }}
        className="button"
        mode="primary"
        size="small"
        variant="round"
      >
        Explore Content
      </Button>
    </HashtagCardStyled>
  );
};

export default HashtagCard;

const HashtagCardStyled = styled.div`
  background: #202020;
  padding: 1.2rem;
  border-radius: 0.4rem;
  border: 1px solid rgba(255, 255, 255, 0.09);
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  &:hover .button {
    border: 1px solid transparent;
    color: #fff;
    background: ${(props) => props.theme.gradient};
    font-weight: 600;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-family: brother-1816, sans-serif;
      font-size: 0.9rem;
      text-transform: uppercase;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.7);
      width: 65%;
      display: flex;
      align-items: flex-start;
      line-height: 1;

      .number {
        text-transform: uppercase;
        padding: 0.2rem;
        line-height: 1;
        border-radius: 2px;
        background: ${(props) => props.theme.gradient};
        color: #fff;
        font-weight: 500;
        font-size: 0.55rem;
        margin-right: 0.24rem;
      }
    }

    .users {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;

      &__item {
        cursor: pointer;
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        position: relative;
        margin-right: 0.5rem;
        transition: all 0.2s ease;
        outline: 1px solid transparent;
        margin-right: -0.6rem;
        outline: 1px solid rgba(255, 255, 255, 0.2);

        .imageAvatar {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center;
          object-fit: cover;
          border-radius: 50%;
        }

        .imageBadge {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #181818;

          .badge {
            height: auto;
            width: 100%;
          }
        }

        .total {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: ${(props) => props.theme.gradient};
          color: #fff;
          font-family: brother-1816, sans-serif;
          font-size: 0.65rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .stats {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__item {
      font-size: 0.62rem;
      font-weight: 400;
      text-transform: uppercase;
      color: ${(props) => props.theme.blue};
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &__seperator {
      height: 5px;
      width: 5px;
      border-radius: 50%;
      margin: 0 0.7rem;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  .button {
    width: 100%;
    padding: 0.55rem 0;
    background: #181818;
    border: 1px solid rgba(255, 255, 255, 0.09);
    font-size: 0.68rem;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 400;
    margin-top: 0.5rem;
    margin-left: 0;
    cursor: pointer;
    transition: all 0.3s ease;
  }
`;
