import React, { useState, memo, useEffect, useContext } from "react";
import styled from "styled-components";
import BackIcon from "../../../assets/Icons/back.svg";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Input from "../../Elements/Input";
import Button from "../../Elements/Button";
import { Row, Col } from "react-grid-system";
import { useMediaQuery } from "react-responsive";
import { AuthContext } from "../../../context/AuthContext";

import PasswordStrengthBar from "react-password-strength-bar";
import { getAccountDetails, updateUserData } from "../../../actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AccountSetting = ({ close, user, setOpenModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    email,
    setEmail,
    firstname,
    setFirstname,
    lastname,
    setLastname,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    setValidationError,
  } = useContext(AuthContext);

  const [isValidUsername, setIsValidUsername] = useState(null);
  const [isValidReferral, setIsValidReferral] = useState(null);
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [myLoading, setMyLoading] = useState(true);

  const userId = localStorage.getItem("user");

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  useEffect(async () => {
    setUserData(await dispatch(getAccountDetails(userId)));
    const userData = await dispatch(getAccountDetails(userId));
    setFirstname(userData.firstname);
    setLastname(userData.lastname);
    setEmail(userData.email);
    if (userData.firstname) {
      setMyLoading(false);
      document.getElementById("firstName").focus();
    }
  }, []);

  const nextHandler = async () => {
    if (
      !firstname ||
      !lastname ||
      !email
      // !password ||
      // !newPassword ||
      // !confirmPassword
    ) {
      toast.error("Please fill out all fields.");
      return;
    }
    if (newPassword && confirmPassword !== newPassword) {
      toast.error("New password and confirm password does not match.");
      return;
    }
    setIsLoading(true);
    let updatedata;
    if (password) {
      updatedata = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        oldPassword: password,
        password: newPassword,
      };
    } else {
      updatedata = {
        firstname: firstname,
        lastname: lastname,
        email: email,
      };
    }

    try {
      const checkOldPassword = await dispatch(
        updateUserData(userId, updatedata)
      );

      if (!checkOldPassword.success) {
        toast.error(checkOldPassword.message);
      } else {
        toast.success("User data updated successfully!");
      }

      setIsLoading(false);
      setPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast.error("An error occurred while updating user data");
    }
  };

  return (
    <AccountSettingStyled>
      <div className="wrapper">
        <div className="wrapper__header">
          <div onClick={close} className="backIcon">
            <img src={BackIcon} alt="" />
          </div>
          <div className="name">My account </div>
        </div>

        <p className="infoText">Account Settings</p>
        {myLoading ? (
          <div className="wrapper__subscriptionBox"> Loading... </div>
        ) : (
          <div className="wrapper__subscriptionBox">
            <div className="paddingBox">
              <Row className="row row__one">
                <Col
                  md={6}
                  sm={6}
                  xs={6}
                  style={{
                    paddingRight: `${isMobile ? "7px" : "12px"}`,
                  }}
                >
                  <div className="field">
                    <Input
                      label="First name"
                      name="firstName"
                      id="firstName"
                      placeholder="Enter your first name"
                      type="text"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                  </div>
                </Col>

                <Col
                  md={6}
                  sm={6}
                  xs={6}
                  style={{
                    paddingLeft: `${isMobile ? "7px" : "12px"}`,
                  }}
                >
                  <div className="field">
                    <Input
                      label="Last name"
                      name="lastName"
                      id="lastName"
                      placeholder="Enter your last name"
                      type="text"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <div className="field">
                    <Input
                      label="Email"
                      name="Email"
                      id="Email"
                      placeholder="Change Your Email"
                      type="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>

              <div className="row row__two">
                <div className="field">
                  <Input
                    label="Old Pasword"
                    name="password"
                    id="password"
                    placeholder="Enter Your Old pasword"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="field">
                  <Input
                    label="New Password"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="New password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  {newPassword && (
                    <div className="passwordStrength">
                      <PasswordStrengthBar
                        password={newPassword}
                        onChangeScore={(score) =>
                          setPasswordStrengthScore(score)
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="field">
                  <Input
                    label="Confirm Password"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Confirm your password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div>
                  <Button className="submit" onClick={nextHandler}>
                    {isLoading ? "Loading..." : "Update"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </AccountSettingStyled>
  );
};

export default memo(AccountSetting);

const AccountSettingStyled = styled.div`
  .wrapper__subscriptionBox--optionCard {
    line-height: 1.8 !important;
  }

  .submit {
    margin-top: 2rem;
  }

  @media screen and (max-width: 480px) {
    .submit {
      padding: 10px;
      width: 100%;
      margin-top: 3%;
    }
  }
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    font-family: ${(props) => props.theme.montserrat};
    background-color: #1c1c1e;
    overflow-y: scroll;
    z-index: 150;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #3a3a3c;
      border-radius: 7px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(246, 92, 139);
    }

    &__header {
      display: flex;
      align-items: center;
      padding: 1rem 5rem;
      border-bottom: 1px solid rgba(112, 112, 112, 0.4);

      .backIcon {
        margin-right: 1rem;
        cursor: pointer;

        img {
          height: 1rem;
          width: auto;
        }
      }

      .name {
        font-size: 1.2rem;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    .infoText {
      font-size: 0.85rem;
      font-weight: 300;
      color: #f2f2f7;
      text-align: center;
      padding: 1rem 0;
    }

    &__subscriptionBox {
      width: 45%;
      min-width: 480px;
      margin: 0 auto 8rem auto;
      background-color: #2c2c2e;
      padding: 1.5rem 2rem;
      border-radius: 0.5rem;
      position: relative;

      .field {
        margin-bottom: 0.75rem;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .wrapper {
      &__header {
        padding: 0.8rem 2rem;
      }

      &__subscriptionBox {
        min-width: 90%;
        width: 90%;
        padding: 1rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .wrapper {
      &__header {
        padding: 0.5rem 1.5rem;

        .name {
          font-size: 1rem;
        }
      }
    }
  }
`;
