import React, { useEffect } from "react";
import * as Yup from "yup";
import Button from "../../components/Elements/Button";
import InputV2 from "../../components/Elements/InputV2";
import { FormikProvider, useFormik } from "formik";
import { MarketingPageStyles } from "./Styles";
import { Row, Col } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { addMarketingStatsSection } from "../../actions/marketing";
import { toast } from "react-toastify";
import { RESET_MARKETING_PAGE_STATE } from "../../actions/marketing/type";

const Schema = Yup.object().shape({
  startNumber1: Yup.number(),
  endNumber1: Yup.number(),
  text1: Yup.string().required("Text is required."),
  startNumber2: Yup.number(),
  endNumber2: Yup.number(),
  text2: Yup.string().required("Text is required."),
  startNumber3: Yup.number(),
  endNumber3: Yup.number(),
  text3: Yup.string().required("Text is required."),
});

const StatsForm = () => {
  const dispatch = useDispatch();
  const {
    data: marketingPageData,
    saveLoading,
    saveSuccess,
    saveError,
  } = useSelector((state) => state.marketingPage);
  const formik = useFormik({
    initialValues: {
      startNumber1: 999,
      endNumber1: 0,
      text1: "",
      startNumber2: 999,
      endNumber2: 0,
      text2: "",
      startNumber3: 999,
      endNumber3: 0,
      text3: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      await saveFormHandler(values);
    },
  });

  useEffect(() => {
    if (saveSuccess) {
      toast.success("Content updated successfully.", {
        toastId: "statsSuccess",
      });
    }

    if (saveError) {
      toast.success("Something went worng. Please try again!", {
        toastId: "statsError",
      });
    }

    dispatch({
      type: RESET_MARKETING_PAGE_STATE,
    });
  }, [saveSuccess, saveError]);

  useEffect(() => {
    if (marketingPageData) {
      const statsData = marketingPageData?.["stats"];

      if (statsData?.length > 0) {
        const stats1 = statsData[0];
        const stats2 = statsData[1];
        const stats3 = statsData[2];

        formik.setValues({
          startNumber1: stats1?.start_number || null,
          endNumber1: stats1?.end_number || null,
          text1: stats1?.text || "",

          startNumber2: stats2?.start_number || null,
          endNumber2: stats2?.end_number || null,
          text2: stats2?.text || "",

          startNumber3: stats3?.start_number || null,
          endNumber3: stats3?.end_number || null,
          text3: stats3?.text || "",
        });
      }
    }
  }, [marketingPageData]);

  const saveFormHandler = async (values) => {
    if (saveLoading) {
      return;
    }

    try {
      const statsData = marketingPageData?.["stats"];
      if (statsData?.length) {
        const stats1 = statsData[0];
        const stats2 = statsData[1];
        const stats3 = statsData[2];

        const objArray = [
          {
            id: stats1?.id,
            start_number: values.startNumber1,
            end_number: values.endNumber1,
            text: values.text1,
          },

          {
            id: stats2?.id,
            start_number: values.startNumber2,
            end_number: values.endNumber2,
            text: values.text2,
          },

          {
            id: stats3?.id,
            start_number: values.startNumber3,
            end_number: values.endNumber3,
            text: values.text3,
          },
        ];

        const promises = objArray.map((item) => {
          dispatch(addMarketingStatsSection(item));
        });
        await Promise.all(promises);
      } else {
        const objArray = [
          {
            id: null,
            start_number: values.startNumber1,
            end_number: values.endNumber1,
            text: values.text1,
          },
          {
            id: null,
            start_number: values.startNumber3,
            end_number: values.endNumber3,
            text: values.text3,
          },
          {
            id: null,
            start_number: values.startNumber3,
            end_number: values.endNumber3,
            text: values.text3,
          },
        ];
        const promises = objArray.map((item) => {
          dispatch(addMarketingStatsSection(item));
        });
        await Promise.all(promises);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!");
    }
  };

  return (
    <FormikProvider value={formik}>
      <MarketingPageStyles>
        <div className="form">
          <div className="form__stats">
            {/* section 1 */}
            <div className="form__stats--section form__stats--section-1">
              <div className="form__stats--section-title">section one</div>

              <div className="form__item">
                <InputV2
                  name="text1"
                  type={"text"}
                  placeholder={"Add Text"}
                  id={"text1"}
                  label={"Text"}
                  value={formik.values.text1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={formik.touched.text1 && formik.errors.text1}
                />
              </div>

              <div className="form__item">
                <Row>
                  <Col md={6} sm={12}>
                    <InputV2
                      name="startNumber1"
                      type={"number"}
                      placeholder={"Add Counter Start Number"}
                      id={"startNumber1"}
                      label={"Start Number"}
                      value={formik.values.startNumber1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formikError={
                        formik.touched.startNumber1 &&
                        formik.errors.startNumber1
                      }
                    />
                  </Col>

                  <Col md={6} sm={12}>
                    <InputV2
                      name="endNumber1"
                      type={"number"}
                      placeholder={"Add Counter End Number"}
                      id={"endNumber1"}
                      label={"End Number"}
                      value={formik.values.endNumber1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formikError={
                        formik.touched.endNumber1 && formik.errors.endNumber1
                      }
                    />
                  </Col>
                </Row>
              </div>
            </div>

            {/* section 2 */}
            <div className="form__stats--section form__stats--section-1">
              <div className="form__stats--section-title">section one</div>

              <div className="form__item">
                <InputV2
                  name="text2"
                  type={"text"}
                  placeholder={"Add Text"}
                  id={"text2"}
                  label={"Text"}
                  value={formik.values.text2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={formik.touched.text2 && formik.errors.text2}
                />
              </div>

              <div className="form__item">
                <Row>
                  <Col md={6} sm={12}>
                    <InputV2
                      name="startNumber2"
                      type={"number"}
                      placeholder={"Add Counter Start Number"}
                      id={"startNumber2"}
                      label={"Start Number"}
                      value={formik.values.startNumber2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formikError={
                        formik.touched.startNumber2 &&
                        formik.errors.startNumber2
                      }
                    />
                  </Col>

                  <Col md={6} sm={12}>
                    <InputV2
                      name="endNumber2"
                      type={"number"}
                      placeholder={"Add Counter End Number"}
                      id={"endNumber2"}
                      label={"End Number"}
                      value={formik.values.endNumber2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formikError={
                        formik.touched.endNumber2 && formik.errors.endNumber2
                      }
                    />
                  </Col>
                </Row>
              </div>
            </div>

            {/* section 3 */}
            <div className="form__stats--section form__stats--section-1">
              <div className="form__stats--section-title">section one</div>

              <div className="form__item">
                <InputV2
                  name="text3"
                  type={"text"}
                  placeholder={"Add Text"}
                  id={"text3"}
                  label={"Text"}
                  value={formik.values.text3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={formik.touched.text3 && formik.errors.text3}
                />
              </div>

              <div className="form__item">
                <Row>
                  <Col md={6} sm={12}>
                    <InputV2
                      name="startNumber3"
                      type={"number"}
                      placeholder={"Add Counter Start Number"}
                      id={"startNumber3"}
                      label={"Start Number"}
                      value={formik.values.startNumber3}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formikError={
                        formik.touched.startNumber3 &&
                        formik.errors.startNumber3
                      }
                    />
                  </Col>

                  <Col md={6} sm={12}>
                    <InputV2
                      name="endNumber3"
                      type={"number"}
                      placeholder={"Add Counter End Number"}
                      id={"endNumber3"}
                      label={"End Number"}
                      value={formik.values.endNumber3}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      formikError={
                        formik.touched.endNumber3 && formik.errors.endNumber3
                      }
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <div className="form__stats--section">
              <Button
                onClick={() => formik.handleSubmit()}
                className="form__stats--btn"
                mode="primary"
                width="fluid"
                variant="round"
              >
                {saveLoading ? "Please wait..." : "Save Changes"}
              </Button>
            </div>
          </div>
        </div>
      </MarketingPageStyles>
    </FormikProvider>
  );
};

export default StatsForm;
