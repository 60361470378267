import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import BackIcon from "../components/icons/arrow-left.svg";
import SendIcon from "../components/icons/send.svg";
import socketIo from "socket.io-client";
import config from "../config";
import ImageNotfoundPlaceholder from "../assets/image_notfound_placeholder.svg";
import { useSelector, useDispatch } from "react-redux";
import { GlobalContext } from "../context/GlobalContext";
import { useHistory } from "react-router-dom";
import { getAllFriends, createChat, updateStatusRead } from "../actions";
import { addDefaultImage } from "../utils";

const ENDPOINT = config.sockets.socketBackendUrl;
let socket;

const MobileChat = () => {
  const { setShowChatList } = useContext(GlobalContext);

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const activeUserData = useSelector((state) => state.activeUser);
  const { friends } = useSelector((state) => state.connection);
  const { setShowEditClipModel, setShowChatDetails } =
    useContext(GlobalContext);

  // states
  const [userTypingStatus, setUserTypingStatus] = useState(null);
  const [activeUser, setActiveUser] = useState([]);
  const [senderId, setSenderId] = useState(localStorage.getItem("senderId"));
  const [receiverId, setReceiverId] = useState(
    localStorage.getItem("receiverId")
  );
  const [connectionId, setConnectionId] = useState(
    localStorage.getItem("connectionId")
  );

  const [message, setMessage] = useState("");
  const [writeStatus, setWriteStatus] = useState(false);
  const [callStatus, setCallStatus] = useState(true);
  const [chat, setChat] = useState([]);
  const [viewChatRead, setViewChatRead] = useState(false);

  if (callStatus) {
    socket = socketIo(ENDPOINT, { transports: ["websocket"] });
  }

  setTimeout(() => {
    setCallStatus(false);
  }, 5000);

  // useEffect(async () => {
  //   socket.on("viewChat", (data) => {
  //     setChat(data);
  //   });

  //   socket.emit("lastReadMessage", friends);
  //   socket.emit("userTyping", null, null, null);
  // }, [writeStatus, setShowChatDetails]);

  // useEffect(() => {
  //   socket = socketIo(ENDPOINT, { transports: ["websocket"] });
  //   socket.on("viewChat", (data) => {
  //     setChat(data);
  //   });
  //   setTimeout(() => {
  //     setViewChatRead(false);
  //   }, [5000]);
  // }, [viewChatRead]);

  // setTimeout(() => {
  //   setCallStatus(false);
  // }, 5000);

  // useEffect(async () => {
  //   socket.on("viewChat", (data) => {
  //     setChat(data);
  //   });

  //   socket.emit("lastReadMessage", friends);
  //   socket.emit("userTyping", null, null, null);
  // }, [writeStatus]);

  useEffect(() => {
    socket = socketIo(ENDPOINT, { transports: ["websocket"] });
    socket.on("viewChat", (data) => {
      setChat(data);
    });
    setTimeout(() => {
      setViewChatRead(false);
    }, [1000]);
  }, [viewChatRead]);

  const goBackHandler = () => {
    history.push("/");
    setShowChatList(true);
  };

  const isBadgeOrAvatar = (user) => {
    const image = !user.avatar
      ? user.userSettings.VisitorBadge.imgPath
      : user.avatar;
    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <div className="imageBadge">
          <img className="badge" src={image} alt="" />
        </div>
      );
    } else {
      return (
        <img
          className="imageAvatar"
          src={config.aws.CLOUDFRONT_URL + image}
          alt=""
          onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
        />
      );
    }
  };

  const messageSend = () => {
    setViewChatRead(true);
    if (!message) {
      return;
    }

    const data = {
      connectionId,
      receiverId,
      senderId,
      message,
    };

    dispatch(createChat(data));
    socket.on("viewChat", (data) => {
      setChat(data);
    });
    socket.emit("userTyping", null, null, null);
    socket.emit("lastReadMessage", friends);
    setMessage("");
    setWriteStatus(true);
    setTimeout(() => {
      setWriteStatus(false);
    }, 100);
  };

  const inputTypingHandler = async (e) => {
    setViewChatRead(true);

    if (!message) {
      return;
    }

    if (e.key === "Enter") {
      const data = {
        connectionId,
        receiverId,
        senderId,
        message,
      };

      await dispatch(createChat(data));
      socket.on("viewChat", (data) => {
        setChat(data);
      });
      socket.emit("lastReadMessage", friends);
      socket.emit("userTyping", null, null, null);
      setMessage("");
      setWriteStatus(true);
      setTimeout(() => {
        setWriteStatus(false);
      }, 100);
    }
  };

  const inputClickHandler = async (e) => {
    await dispatch(updateStatusRead(receiverId, senderId, user, connectionId));
    socket.emit("lastReadMessage", friends);
  };

  const inputChangeHandler = (e) => {
    setViewChatRead(true);
    setMessage(e.target.value);

    socket.emit("userTyping", senderId, receiverId, user);

    const timeOutId = setTimeout(async () => {
      await dispatch(
        updateStatusRead(receiverId, senderId, user.id, connectionId)
      );
      socket.emit("userTyping", null, null, null);
    }, 1000);
    return () => clearTimeout(timeOutId);
  };

  return (
    <ChatMobileStyled>
      {friends &&
        friends.map(
          (item) =>
            item.id === localStorage.getItem("connectionId") && (
              <>
                <div className="chatHeader">
                  <div className="chatHeader__left">
                    <div
                      onClick={goBackHandler}
                      className="chatHeader__left--icon"
                    >
                      <img src={BackIcon} alt="" />
                    </div>

                    <div className="chatHeader__left--meta">
                      <div className="left">
                        <div className="avatar">
                          {user.id === item.warrior.id && (
                            <>{isBadgeOrAvatar(item.collaborator)}</>
                          )}

                          {user.id === item.collaborator.id && (
                            <>{isBadgeOrAvatar(item.warrior)}</>
                          )}
                        </div>
                      </div>

                      <div className="right">
                        <div className="name">
                          {localStorage.getItem("username")}
                        </div>
                        <div className="status on">
                          {localStorage.getItem("username") === userTypingStatus
                            ? "typing"
                            : "Active now"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="chatHeader__right"></div>
                </div>
                <div className="chatMessages">
                  {chat &&
                    chat.map((itemV2) => (
                      <>
                        {user.id === itemV2.senderId &&
                        receiverId === itemV2.receiverId ? (
                          itemV2.isHidden ? (
                            itemV2.isHidden === user.id ? (
                              ""
                            ) : (
                              <div className="chatMessages__item chatMessages__right">
                                <div className="chatMessages__item--text">
                                  {itemV2.message}
                                </div>

                                <div className="chatMessages__item--avatar">
                                  {user.id === item.warrior.id && (
                                    <>{isBadgeOrAvatar(item.warrior)}</>
                                  )}

                                  {user.id === item.collaborator.id && (
                                    <>{isBadgeOrAvatar(item.collaborator)}</>
                                  )}
                                </div>
                              </div>
                            )
                          ) : (
                            <div className="chatMessages__item chatMessages__right">
                              <div className="chatMessages__item--text">
                                {itemV2.message}
                              </div>

                              <div className="chatMessages__item--avatar">
                                {user.id === item.warrior.id && (
                                  <>{isBadgeOrAvatar(item.warrior)}</>
                                )}

                                {user.id === item.collaborator.id && (
                                  <>{isBadgeOrAvatar(item.collaborator)}</>
                                )}
                              </div>
                            </div>
                          )
                        ) : receiverId === itemV2.senderId &&
                          senderId === itemV2.receiverId ? (
                          itemV2.isHidden ? (
                            itemV2.isHidden === user.id ? (
                              ""
                            ) : (
                              <div className="chatMessages__item chatMessages__left">
                                <div className="chatMessages__item--avatar">
                                  {user.id === item.warrior.id && (
                                    <>{isBadgeOrAvatar(item.collaborator)}</>
                                  )}

                                  {user.id === item.collaborator.id && (
                                    <>{isBadgeOrAvatar(item.warrior)}</>
                                  )}
                                </div>

                                <div className="chatMessages__item--text">
                                  {itemV2.message}
                                </div>
                              </div>
                            )
                          ) : (
                            <div className="chatMessages__item chatMessages__left">
                              <div className="chatMessages__item--avatar">
                                {user.id === item.warrior.id && (
                                  <>{isBadgeOrAvatar(item.collaborator)}</>
                                )}

                                {user.id === item.collaborator.id && (
                                  <>{isBadgeOrAvatar(item.warrior)}</>
                                )}
                              </div>

                              <div className="chatMessages__item--text">
                                {itemV2.message}
                              </div>
                            </div>
                          )
                        ) : (
                          ""
                        )}
                      </>
                    ))}

                  {/* <div className="chatMessages__item chatMessages__right">
                    <div className="chatMessages__item--text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Doloribus, minima?
                    </div>

                    <div className="chatMessages__item--avatar">
                      <img
                        src="https://e00-marca.uecdn.es/assets/multimedia/imagenes/2023/01/12/16735402991293.jpg"
                        alt=""
                        className="imageAvatar"
                      />
                    </div>
                  </div>

                  <div className="chatMessages__item chatMessages__left">
                    <div className="chatMessages__item--avatar">
                      <img
                        src="https://e00-marca.uecdn.es/assets/multimedia/imagenes/2023/01/12/16735402991293.jpg"
                        alt=""
                        className="imageAvatar"
                      />
                    </div>

                    <div className="chatMessages__item--text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Doloribus, minima?
                    </div>
                  </div>

                  <div className="chatMessages__item chatMessages__right">
                    <div className="chatMessages__item--text">Hey!</div>

                    <div className="chatMessages__item--avatar">
                      <img
                        src="https://e00-marca.uecdn.es/assets/multimedia/imagenes/2023/01/12/16735402991293.jpg"
                        alt=""
                        className="imageAvatar"
                      />
                    </div>
                  </div>

                  <div className="chatMessages__item chatMessages__right">
                    <div className="chatMessages__item--text">
                      How are you doing my friend.
                    </div>

                    <div className="chatMessages__item--avatar">
                      <img
                        src="https://e00-marca.uecdn.es/assets/multimedia/imagenes/2023/01/12/16735402991293.jpg"
                        alt=""
                        className="imageAvatar"
                      />
                    </div>
                  </div> */}
                </div>
                <div className="chatFooter">
                  <div className="chatFooter__input">
                    <input
                      type="text"
                      placeholder="write a message."
                      name="message"
                      value={message}
                      onChange={inputChangeHandler}
                      onKeyDown={inputTypingHandler}
                      onClick={inputClickHandler}
                    />

                    <div
                      onClick={messageSend}
                      className="chatFooter__input--send"
                    >
                      <img src={SendIcon} alt="" />
                    </div>
                  </div>
                </div>
              </>
            )
        )}
    </ChatMobileStyled>
  );
};

export default MobileChat;

const ChatMobileStyled = styled.div`
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  background-color: #202020;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  .chatHeader {
    height: 3.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 0 0 1rem;

    &__right {
      width: 4rem;
    }

    &__left {
      width: calc(100% - 5rem);
      display: flex;
      align-items: center;

      &--icon {
        margin-right: 1rem;

        img {
          height: 1rem;
          width: auto;
        }
      }

      &--meta {
        display: flex;
        align-items: center;

        .avatar {
          cursor: pointer;
          height: 2rem;
          width: 2rem;
          border-radius: 50%;
          position: relative;
          transition: all 0.2s ease;
          outline: 1px solid transparent;
          margin-right: 0.5rem;

          &:hover {
            outline: 1px solid rgba(255, 255, 255, 0.5);
          }

          .imageAvatar {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
            border-radius: 50%;
          }

          .imageBadge {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .badge {
              height: 38px;
              width: auto;
            }
          }
        }

        .name {
          font-size: 0.935rem;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.85);
          line-height: 1;
        }

        .status {
          line-height: 1;
          font-family: brother-1816, sans-serif;
          font-size: 0.525rem;
          text-transform: uppercase;
          margin-top: 0.25rem;
          font-weight: 500;
        }

        .on {
          background: -webkit-linear-gradient(#ff4883, #fdb769);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .off {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  .chatMessages {
    height: calc(100% - 7.5rem);
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1rem 1rem 1.5rem 1rem;

    &__right {
      display: flex;
      align-items: flex-end;
      margin-left: auto;
      width: 80%;
      justify-content: flex-end;

      .chatMessages__item--avatar {
        margin-left: 0.5rem;
      }

      .chatMessages__item--text {
        /* &:before {
          background-color: #0a0a0a;
          right: 10px;
          -webkit-transform: rotate(118deg) skew(-5deg);
          z-index: 1;
        } */
      }
    }

    &__left {
      display: flex;
      align-items: flex-end;
      margin-right: auto;
      width: 80%;
      justify-content: flex-start;

      .chatMessages__item--avatar {
        margin-right: 0.5rem;
      }

      .chatMessages__item--text {
        background: ${(props) => props.theme.gradient};
        color: #fff;
        border: none;

        &:before {
          /* background: rgb(246, 92, 139);
          left: 10px;
          -webkit-transform: rotate(70deg) skew(5deg); */
        }
      }
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 0.7rem;
      }

      &--avatar {
        cursor: pointer;
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        position: relative;
        transition: all 0.2s ease;
        outline: 1px solid transparent;

        &:hover {
          outline: 1px solid rgba(255, 255, 255, 0.5);
        }

        .imageAvatar {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center;
          object-fit: cover;
          border-radius: 50%;
        }

        .imageBadge {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .badge {
            height: 38px;
            width: auto;
          }
        }
      }

      &--text {
        width: calc(100% - 2.5rem);
        background-color: #0a0a0a;
        padding: 0.4rem 0.8rem;
        border-radius: 0.25rem;
        font-size: 0.85rem;
        color: rgba(255, 255, 255, 0.8);
        position: relative;
        line-height: 1.5;

        &:before {
          content: "";
          display: block;
          height: 16px;
          width: 9px;
          position: absolute;
          bottom: -7.5px;
        }
      }
    }
  }

  .chatFooter {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 4rem;
    z-index: 999;
    background-color: #202020;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;

    &__input {
      width: 90%;
      height: 2.7rem;
      border-radius: 10rem;
      background-color: #181818;
      border: 1px solid rgba(255, 255, 255, 0.05);
      position: relative;

      input {
        height: 100%;
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        color: #fff;
        padding: 0 1rem;

        &::placeholder {
          color: rgba(255, 255, 255, 0.55);
          text-transform: capitalize;
          font-size: 0.8rem;
          text-transform: capitalize;
          font-weight: 200;
          font-family: brother-1816, sans-serif;
        }
      }

      &--send {
        position: absolute;
        top: 0.1rem;
        right: 0.1rem;
        bottom: 0.1rem;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${(props) => props.theme.gradient};

        img {
          height: 0.9rem;
          width: auto;
        }
      }
    }
  }
`;
