import { FETCH_ADMIN_VISTOR_BADGE } from "../actions/types";

const initialState = {
  vistorBadgeData: null,
};

const vistorBadgeTable = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_VISTOR_BADGE:
      return {
        ...state,
        vistorBadgeData: action.payload.data,
        error: null,
      };
    default:
      return state;
  }
};

export default vistorBadgeTable;
