import React, { useContext, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import NotFoundIcon from "../../icons/notfound.svg";
import VideoCard from "../../Cards/VideoCard";
import { VIDEO_SELF, VIDEO_OTHER } from "../../../utils/menuOptions";
import { GlobalContext } from "../../../context/GlobalContext";
import { useDispatch } from "react-redux";
import { deleteVideo, deleteVideoS3 } from "../../../actions";
import { notificationMessage } from "../../../utils/toastNotifications";
import { toast } from "react-toastify";
import ConfirmationModel from "../../ConfirmationModel";
import EditClipForm from "../../UploadClipComponent/EditClipForm";
import { useMediaQuery } from "react-responsive";
import {
  SLIDERSETTINGV1,
  SLIDERSETTING_MOBILE,
} from "../../../utils/sliderSettings";

const PlaylistCategory = ({ isSelf, playlist, switchTab }) => {
  const dispatch = useDispatch();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoId, setVideoId] = useState(null);

  const {
    setShowEditClipModel,
    deleteVideoConfirmationModel,
    setDeleteVideoConfirmationModel,
    setPlaylistModelShowType,
    setShowPlaylistModelV2,
    setSelectedPlaylist,
    setDeletePlaylistConfirmationModel,
  } = useContext(GlobalContext);

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  const deleteVideoHandler = () => {
    const filenamesplit = selectedVideo.url.split("/");
    const videoname = filenamesplit[filenamesplit.length - 1];

    dispatch(deleteVideo(selectedVideo.id));
    dispatch(deleteVideoS3(videoname));
    toast.success(notificationMessage.videoDeleted);

    setDeleteVideoConfirmationModel(false);
  };

  const editPlaylistHandler = () => {
    setShowPlaylistModelV2(true);
    setSelectedPlaylist(playlist);
    setPlaylistModelShowType("UPDATE_FORM");
  };

  const deletePlaylistHandler = () => {
    setSelectedPlaylist(playlist);
    setDeletePlaylistConfirmationModel(true);
  };

  return (
    <>
      <PlaylistCategoryStyled>
        <div className="title">
          {playlist.title}

          {isSelf && (
            <div className="actions">
              <div
                onClick={editPlaylistHandler}
                className="actions__edit actions__pill"
              >
                Edit Playlist
              </div>
              <div
                onClick={deletePlaylistHandler}
                className="actions__delete actions__pill"
              >
                Delete Playlist
              </div>
            </div>
          )}
        </div>

        {!playlist.videos.length && (
          <div onClick={() => switchTab(1)} className="notFoundCard">
            <div className="notFoundCard__content">
              <img src={NotFoundIcon} alt="" className="icon" />
              <span>Add Some Videos</span>
            </div>
          </div>
        )}

        {isMobile ? (
          <Slider {...SLIDERSETTING_MOBILE} className="slider">
            {playlist.videos.map((item, i) => (
              <VideoCard
                key={i}
                item={item}
                isSelf={isSelf}
                setSelectedVideo={setSelectedVideo}
                setVideoId={setVideoId}
                menuOptions={isSelf ? VIDEO_SELF : VIDEO_OTHER}
              />
            ))}
          </Slider>
        ) : (
          <Slider {...SLIDERSETTINGV1} className="slider">
            {playlist.videos.map((item, i) => (
              <VideoCard
                key={i}
                item={item}
                isSelf={isSelf}
                setSelectedVideo={setSelectedVideo}
                setVideoId={setVideoId}
                menuOptions={isSelf ? VIDEO_SELF : VIDEO_OTHER}
              />
            ))}
          </Slider>
        )}
      </PlaylistCategoryStyled>

      {selectedVideo && videoId && (
        <EditClipForm
          videoUpdate={selectedVideo}
          videoId={videoId}
          close={() => setShowEditClipModel(false)}
        />
      )}

      {selectedVideo && deleteVideoConfirmationModel && (
        <ConfirmationModel
          closeHandler={() => setDeleteVideoConfirmationModel(false)}
          title="Are you sure you want to delete this video ? You can't undo this action."
          deleteHandler={deleteVideoHandler}
        />
      )}
    </>
  );
};

export default PlaylistCategory;

const PlaylistCategoryStyled = styled.div`
  margin-bottom: 2rem;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  .title {
    font-weight: 500;
    font-size: 1.05rem;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-end;

    .actions {
      display: flex;
      align-items: center;
      margin-left: 2rem;

      &__pill {
        padding: 0.5rem 1.2rem;
        font-size: 0.56rem;
        text-transform: uppercase;
        border-radius: 8rem;
        border: 1px solid rgba(255, 255, 255, 0.4);
        color: rgba(255, 255, 255, 0.5);
        font-weight: 500;
        letter-spacing: 0.6px;
        transition: all 0.5s ease;
        line-height: 1;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 0.6rem;
        }
      }

      &__edit {
        &:hover {
          border: 1px solid transparent;
          background: ${(props) => props.theme.gradient};
          color: #fff;
        }
      }

      &__delete {
        &:hover {
          border: 1px solid transparent;
          background: ${(props) => props.theme.red};
          color: #fff;
        }
      }
    }
  }

  .slider {
    .slick-slide {
      padding: 0 10px;
    }

    .slick-track {
      margin-left: 0;
    }
  }

  .notFoundCard {
    height: 12rem;
    width: 20rem;
    background-color: #181818;
    border-radius: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0.05);
    cursor: pointer;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 0.5s ease;

      .icon {
        height: 2.5rem;
        width: auto;
        margin-bottom: 0.5rem;
      }

      span {
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .title {
      font-size: 0.8rem;

      .actions {
        margin-left: 0.5rem;
        flex-wrap: wrap;

        &__pill {
          padding: 0.2rem .8rem;
          font-size: 0.52rem;
          margin-bottom: 0.25rem;
          transform: translateY(0.25rem);

          &:not(:last-child) {
            margin-right: 0.4rem;
          }
        }
      }
    }

    .notFoundCard {
      width: 100%;
    }
  }

  @media screen and (max-width: 414px) {
    .title {
      font-size: 0.7rem;

      .actions {
        margin-left: 0.25rem;

        &__pill {
          padding: 0.2rem 0.6rem;
          font-size: 0.46rem;

          &:not(:last-child) {
            margin-right: 0.2rem;
          }
        }
      }
    }
  }
`;
