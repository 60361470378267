import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BellIcon from "../components/icons/bell-black.svg";
import BellOnIcon from "../components/icons/bell-on-black.svg";
import VideoCard from "../components/Cards/VideoCard";
import MomentCard from "../components/Cards/MomentCard";
import ThreadCardSmall from "../components/Cards/ThreadCardSmall";
import Banner from "../skeletons/ViewHashtag/banner";
import ClipsSkeleton from "../skeletons/General/Section";
import ThreadsSkeleton from "../skeletons/General/Section";
import MomentsSkeleton from "../skeletons/General/Section";
import Error from "../components/Elements/Error";
import GoBackIcon from "../components/icons/goback.svg";
import Button from "../components/Elements/Button";
import { Row, Col } from "react-grid-system";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { getSingleHashtag } from "../actions/hashtag";
import { useParams, useHistory } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const ViewHashtag = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [notify, setNotify] = useState(false);
  const [tab, setTab] = useState("CLIPS"); // CLIPS, MOMENTS, THREADS
  const [tabFetchReady, setTabFetchReady] = useState(false);
  const {
    isFetching,
    hashtag,
    videos,
    moments,
    threads,
    hasMoreVideos,
    hasMoreMoments,
    hasMoreThreads,
  } = useSelector((state) => state.hashtag);
  const user = useSelector((state) => state.user);
  const queryParams = new URLSearchParams(window.location.search);
  const activeTab = queryParams.get("activeTab");

  // pagination states
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [videoPage, setVideoPage] = useState(1);
  const [videoLimit, setVideoLimit] = useState(1);
  const [momentPage, setMomentPage] = useState(1);
  const [momentLimit, setMomentLimit] = useState(1);
  const [threadPage, setThreadPage] = useState(1);
  const [threadLimit, setThreadLimit] = useState(1);

  // tracker
  const { ref: clipsTrackerRef, inView: clipsTrackerIsVisible } = useInView();
  const { ref: momentsTrackerRef, inView: momentsTrackerIsVisible } =
    useInView();
  const { ref: threadsTrackerRef, inView: threadsTrackerIsVisible } =
    useInView();

  useEffect(() => {
    if (activeTab) {
      setTab(activeTab);

      dispatch(
        getSingleHashtag(
          params?.hashtagId,
          activeTab === "CLIPS"
            ? "video"
            : activeTab === "MOMENTS"
            ? "moment"
            : activeTab === "THREADS"
            ? "thread"
            : null,
          user?.id ? user.id : null,
          videoPage,
          videoLimit,
          momentPage,
          momentLimit,
          threadPage,
          threadLimit
        )
      );

      setTabFetchReady(true);
      return;
    } else {
      dispatch(
        getSingleHashtag(
          params?.hashtagId,
          tab === "CLIPS"
            ? "video"
            : tab === "MOMENTS"
            ? "moment"
            : tab === "THREADS"
            ? "thread"
            : null,
          user?.id ? user.id : null,
          videoPage,
          videoLimit,
          momentPage,
          momentLimit,
          threadPage,
          threadLimit
        )
      );

      setTabFetchReady(true);
    }
  }, []);

  useEffect(() => {
    if (tab && tabFetchReady) {
      dispatch(
        getSingleHashtag(
          params?.hashtagId,
          tab === "CLIPS"
            ? "video"
            : tab === "MOMENTS"
            ? "moment"
            : tab === "THREADS"
            ? "thread"
            : null,
          user?.id ? user.id : null,
          videoPage,
          videoLimit,
          momentPage,
          momentLimit,
          threadPage,
          threadLimit
        )
      );
    }
  }, [tab]);

  // load more clips
  useEffect(() => {
    if (
      hasMoreVideos &&
      clipsTrackerIsVisible &&
      !isFetching &&
      tab === "CLIPS"
    ) {
      loadMoreClipsHandler();
    }
  }, [clipsTrackerIsVisible, hasMoreVideos, isFetching]);

  // load more moments
  useEffect(() => {
    if (
      hasMoreMoments &&
      momentsTrackerIsVisible &&
      !isFetching &&
      tab === "MOMENTS"
    ) {
      loadMoreMomentsHandler();
    }
  }, [momentsTrackerIsVisible, hasMoreMoments, isFetching]);

  // load more threads
  useEffect(() => {
    if (
      hasMoreThreads &&
      threadsTrackerIsVisible &&
      !isFetching &&
      tab === "THREADS"
    ) {
      loadMoreThreadsHandler();
    }
  }, [threadsTrackerIsVisible, hasMoreThreads, isFetching]);

  const TabAnimationVarients = {
    hidden: {
      x: "-120%",
    },
    visible: {
      x: 0,
      transition: { type: "spring", duration: 1 },
    },
    exit: {
      x: "120%",
      opacity: 0,

      transition: {
        duration: 0.5,
      },
    },
  };

  const loadMoreClipsHandler = async () => {
    setVideoPage(videoPage + 1);
    setPaginationLoading(true);
    try {
      await dispatch(
        getSingleHashtag(
          params?.hashtagId,
          tab === "CLIPS"
            ? "video"
            : tab === "MOMENTS"
            ? "moment"
            : tab === "THREADS"
            ? "thread"
            : null,
          user?.id ? user.id : null,
          videoPage + 1,
          videoLimit,
          momentPage,
          momentLimit,
          threadPage,
          threadLimit
        )
      );
    } catch (error) {
      setPaginationLoading(false);
    }
    setPaginationLoading(false);
  };

  const loadMoreMomentsHandler = async () => {
    setMomentPage(momentPage + 1);
    setPaginationLoading(true);
    try {
      await dispatch(
        getSingleHashtag(
          params?.hashtagId,
          tab === "CLIPS"
            ? "video"
            : tab === "MOMENTS"
            ? "moment"
            : tab === "THREADS"
            ? "thread"
            : null,
          user?.id ? user.id : null,
          videoPage,
          videoLimit,
          momentPage + 1,
          momentLimit,
          threadPage,
          threadLimit
        )
      );
    } catch (error) {
      setPaginationLoading(false);
    }
    setPaginationLoading(false);
  };

  const loadMoreThreadsHandler = async () => {
    setThreadPage(threadPage + 1);
    setPaginationLoading(true);
    try {
      await dispatch(
        getSingleHashtag(
          params?.hashtagId,
          tab === "CLIPS"
            ? "video"
            : tab === "MOMENTS"
            ? "moment"
            : tab === "THREADS"
            ? "thread"
            : null,
          user?.id ? user.id : null,
          videoPage,
          videoLimit,
          momentPage,
          momentLimit,
          threadPage + 1,
          threadLimit
        )
      );
    } catch (error) {
      setPaginationLoading(false);
    }
    setPaginationLoading(false);
  };

  return (
    <ViewHashtagStyled>
      {isFetching && !hashtag?.id ? (
        <Banner />
      ) : (
        <>
          <Button
            onClick={() => {
              history.push("/hashtags");
            }}
            className="goBackBtn"
            iconLeft
            mode="secondary"
            size="medium"
          >
            <img src={GoBackIcon} alt="" /> Go Back
          </Button>

          <div className="banner">
            <div className="banner__box">
              <div className="banner__box--left">
                <div className="title">#{hashtag?.name}</div>

                <div className="stats">
                  <div className="stats__item">
                    {hashtag?.count?.video} Videos
                  </div>
                  <div className="stats__seperator"></div>
                  <div className="stats__item">
                    {hashtag?.count?.moment} Moments
                  </div>
                  <div className="stats__seperator"></div>
                  <div className="stats__item">
                    {hashtag?.count?.thread} Threads
                  </div>
                </div>
              </div>

              <div className="banner__box--right">
                <div
                  onClick={() => setNotify(!notify)}
                  className={`follow ${notify ? "followActive" : null}`}
                >
                  <img src={notify ? BellOnIcon : BellIcon} alt="" />
                  <div className="text">
                    {notify ? "Pause Notification" : "Get Notified!"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="tabs__header">
        <div
          onClick={() => setTab("CLIPS")}
          className={`tabs__header--item ${
            tab === "CLIPS" && "tabs__header--itemActive"
          }`}
        >
          Videos
        </div>
        <div
          onClick={() => setTab("MOMENTS")}
          className={`tabs__header--item ${
            tab === "MOMENTS" && "tabs__header--itemActive"
          }`}
        >
          Moments
        </div>
        <div
          onClick={() => setTab("THREADS")}
          className={`tabs__header--item ${
            tab === "THREADS" && "tabs__header--itemActive"
          }`}
        >
          Threads
        </div>
      </div>

      <AnimatePresence
        initial={true}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {tab === "CLIPS" && (
          <motion.div
            variants={TabAnimationVarients}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="tabs__content"
          >
            <Row>
              {videos &&
                videos.length > 0 &&
                videos.map((item, i) => (
                  <Col
                    style={{
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                    key={i}
                    md={3}
                    sm={6}
                    xs={12}
                  >
                    <VideoCard item={item} />
                  </Col>
                ))}
            </Row>

            {!videos?.length && !isFetching && (
              <Error
                text="No videos found on this hashtag!"
                actionButtonText="Explore more hashtags"
                actionButton={() => {
                  history.push("/hashtags");
                }}
                buttonSize="small"
              />
            )}

            {isFetching && !videos?.length && (
              <ClipsSkeleton
                sectionName="videos"
                numItems={4}
                fullWidth
                noTitle
              />
            )}

            {videos?.length && paginationLoading && tab === "CLIPS" ? (
              <ClipsSkeleton
                sectionName="videos"
                numItems={4}
                fullWidth
                noTitle
              />
            ) : null}

            {hasMoreVideos === false && (
              <div className="noMore">
                You have reached the end of the video results.
              </div>
            )}

            <div ref={clipsTrackerRef} className="tracker" />
          </motion.div>
        )}

        {tab === "MOMENTS" && (
          <motion.div
            variants={TabAnimationVarients}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="tabs__content"
          >
            <Row>
              {moments &&
                moments.length > 0 &&
                moments.map((item, i) => (
                  <Col
                    style={{
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                    key={i}
                    md={2}
                    sm={3}
                    xs={4}
                  >
                    <MomentCard item={item} />
                  </Col>
                ))}
            </Row>

            {!moments?.length && !isFetching && (
              <Error
                text="No moments found on this hashtag!"
                actionButtonText="Explore more hashtags"
                actionButton={() => {
                  history.push("/hashtags");
                }}
                buttonSize="small"
              />
            )}

            {isFetching && !moments?.length && (
              <MomentsSkeleton
                sectionName="featured_moments"
                fullWidth
                noTitle
              />
            )}

            {moments?.length && paginationLoading && tab === "MOMENTS" ? (
              <MomentsSkeleton
                sectionName="featured_moments"
                fullWidth
                noTitle
              />
            ) : null}

            {hasMoreMoments === false && (
              <div className="noMore">
                You have reached the end of moments results
              </div>
            )}

            <div ref={momentsTrackerRef} className="tracker" />
          </motion.div>
        )}

        {tab === "THREADS" && (
          <motion.div
            variants={TabAnimationVarients}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="tabs__content"
          >
            <Row>
              {threads &&
                threads.length > 0 &&
                threads.map((item, i) => (
                  <Col
                    style={{
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                    key={i}
                    md={3}
                    sm={6}
                    xs={12}
                  >
                    <ThreadCardSmall type={item.attachedType} item={item} />
                  </Col>
                ))}
            </Row>

            {!threads?.length && !isFetching && (
              <Error
                text="No threads found on this hashtag!"
                actionButtonText="Explore more hashtags"
                actionButton={() => {
                  history.push("/hashtags");
                }}
                buttonSize="small"
              />
            )}

            {isFetching && !threads?.length && (
              <ThreadsSkeleton
                sectionName="videos"
                numItems={4}
                fullWidth
                noTitle
              />
            )}

            {threads?.length && paginationLoading && tab === "THREADS" ? (
              <ThreadsSkeleton
                sectionName="videos"
                numItems={4}
                fullWidth
                noTitle
              />
            ) : null}

            {hasMoreThreads === false && (
              <div className="noMore">
                You have reached the end of threads results
              </div>
            )}

            <div ref={threadsTrackerRef} className="tracker" />
          </motion.div>
        )}
      </AnimatePresence>
    </ViewHashtagStyled>
  );
};

export default ViewHashtag;

const ViewHashtagStyled = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 4rem;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  .goBackBtn {
    margin-bottom: 1.2rem;
    padding: 0.5rem 1.2rem;
  }

  .tracker {
    width: 100%;
    height: 5rem;
    background: transparent;
    opacity: 0;
    margin-top: 5rem;
  }

  .noMore {
    width: 100%;
    font-weight: 300;
    text-align: center;
    font-size: 0.85rem;
    color: rgba(255, 255, 255, 0.5);
    line-height: 1;
    padding-top: 2.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  }

  .banner {
    background: ${(props) => props.theme.gradient};
    border-radius: 0.6rem;
    overflow: hidden;

    &__box {
      background: rgba(255, 255, 255, 0.2);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
      min-height: 5rem;
      border-radius: 0.6rem;
      width: 100%;
      padding: 1.2rem;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &--right {
        .followActive {
          background-color: #fff;
          border: 1px solid transparent !important;
        }

        .follow {
          padding: 0.3rem 1.2rem;
          border: 1px solid rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          border-radius: 0.3rem;
          cursor: pointer;
          user-select: none;
          transition: all 0.3s ease;

          img {
            height: 0.85rem;
            width: auto;
            margin-right: 0.6rem;
          }

          .text {
            font-family: brother-1816, sans-serif;
            font-size: 0.8rem;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
            font-weight: 500;
            cursor: pointer;
          }
        }
      }

      .title {
        font-family: brother-1816, sans-serif;
        font-size: 1.25rem;
        text-transform: uppercase;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        line-height: 1;
      }

      .stats {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &__item {
          font-size: 0.7rem;
          font-weight: 500;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.8);
          transition: all 0.3s ease;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        &__seperator {
          height: 5px;
          width: 5px;
          border-radius: 50%;
          margin: 0 0.7rem;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .tabs {
    &__header {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      margin-top: 1rem;

      &--item {
        font-family: brother-1816, sans-serif;
        background: #202020;
        text-transform: uppercase;
        padding: 0.3rem 1rem;
        font-size: 0.8rem;
        color: rgba(255, 255, 255, 0.7);
        font-weight: 500;
        border-radius: 0.25rem;
        border: 1px solid rgba(255, 255, 255, 0.09);
        cursor: pointer;
        user-select: none;

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }

      &--itemActive {
        background: ${(props) => props.theme.gradient};
        color: #fff;
        border: 1px solid transparent;
      }
    }

    &__content {
      padding: 2rem 0;
      width: 100%;
      min-height: 100vh;
    }
  }

  .loadingBox {
    margin-top: 2rem;
  }

  @media screen and (max-width: 480px) {
    .goBackBtn {
      margin-bottom: 1rem;
      font-size: 0.8rem;

      img {
        height: 0.9rem;
        width: auto;
      }
    }

    .banner {
      &__box {
        padding: 0.8rem;

        &--right {
          .follow {
            padding: 0.2rem 1rem;
            border-radius: 0.2rem;

            img {
              height: 0.7rem;
              margin-right: 0.3rem;
            }

            .text {
              font-size: 0.62rem;
            }
          }
        }

        .title {
          font-size: 1rem;
        }

        .stats {
          &__item {
            font-size: 0.58rem;
          }

          &__seperator {
            height: 4px;
            width: 4px;
            margin: 0 0.3rem;
          }
        }
      }
    }

    .tabs {
      &__header {
        &--item {
          padding: 0.2rem 1rem;
          font-size: 0.75rem;

          &:not(:last-child) {
            margin-right: 0.5rem;
          }
        }
      }

      &__content {
        padding: 1rem 0;
      }
    }
  }

  @media screen and (max-width: 390px) {
    .banner {
      &__box {
        &--right {
          width: 50%;

          .follow {
            padding: 0.2rem 1rem;
            border-radius: 0.1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              height: 0.65rem;
              margin-right: 0.2rem;
            }

            .text {
              font-size: 0.55rem;
            }
          }
        }

        .title {
          font-size: 0.85rem;
        }

        .stats {
          &__item {
            font-size: 0.55rem;
          }

          &__seperator {
            height: 4px;
            width: 4px;
            margin: 0 0.2rem;
          }
        }
      }
    }

    .tabs {
      &__header {
        &--item {
          padding: 0.2rem 0.8rem;
          font-size: 0.65rem;

          &:not(:last-child) {
            margin-right: 0.4rem;
          }
        }
      }
    }
  }
`;
