import { GET_VIDEO_ACCESS_OVERLAYS } from "../actions/types"

const initialState = {
    isFetching: true
}

const videoOverlay = (state = initialState, action) => {
    switch (action.type) {
        case GET_VIDEO_ACCESS_OVERLAYS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state
    }
}

export default videoOverlay