import api from "../../services/api";
import { uploadCover } from "../../utils";
import {
  GET_SITE_BRANDING_FAIL,
  GET_SITE_BRANDING_SUCCESS,
  GET_SITE_BRANDING_REQUEST,
  POST_SITE_BRANDING_FAIL,
  POST_SITE_BRANDING_REQUEST,
  POST_SITE_BRANDING_SUCCESS,
} from "./type";

export const getBranding = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SITE_BRANDING_REQUEST,
    });

    const siteBrandingLocal = localStorage.getItem("isutra_siteBranding");
    if (!siteBrandingLocal) {
      const res = await api.get(`branding`);

      localStorage.setItem(
        "isutra_siteBranding",
        JSON.stringify(res.data.data)
      );

      dispatch({
        type: GET_SITE_BRANDING_SUCCESS,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: GET_SITE_BRANDING_SUCCESS,
        payload: JSON.parse(siteBrandingLocal),
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SITE_BRANDING_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const postBranding =
  (data, lightLogo, darkLogo, loadingScreenLogo) => async (dispatch) => {
    try {
      dispatch({
        type: POST_SITE_BRANDING_REQUEST,
      });

      const files = {
        lightLogo,
        darkLogo,
        loadingScreenLogo,
      };

      const filesArr = Object.values(files);

      const promises = filesArr.map(async (item) => {
        if (item) {
          const res = await uploadCover("image", item);
          return res.url;
        }
        return null;
      });
      Promise.all(promises).then(async (values) => {
        const lightLogoUrl = values[0];
        const darkLogoUrl = values[1];
        const loadingScreenLogoUrl = values[2];

        const brandingObj = {
          ...data,
          logo_light: lightLogoUrl ? lightLogoUrl : data?.logo_light,
          logo_dark: darkLogoUrl ? darkLogoUrl : data?.logo_dark,
          loadingScreenLogo: loadingScreenLogoUrl
            ? loadingScreenLogoUrl
            : data?.loadingScreenLogo,
        };

        await api.post("branding", brandingObj);

        localStorage.setItem(
          "isutra_siteBranding",
          JSON.stringify(brandingObj)
        );

        dispatch({
          type: POST_SITE_BRANDING_SUCCESS,
          payload: brandingObj,
        });
      });

      return;

      if (lightLogo && !darkLogo) {
        uploadCover("image", lightLogo)
          .then(async (lightLogoRes) => {
            const { url: lightLogoUrl } = lightLogoRes;

            const brandingObj = {
              ...data,
              logo_light: lightLogoUrl,
            };

            await api.post("branding", brandingObj);

            localStorage.setItem(
              "isutra_siteBranding",
              JSON.stringify(brandingObj)
            );

            dispatch({
              type: POST_SITE_BRANDING_SUCCESS,
              payload: brandingObj,
            });
          })
          .catch((error) => {
            throw new Error();
          });
      }

      if (darkLogo && !lightLogo) {
        uploadCover("image", darkLogo)
          .then(async (darkLogoRes) => {
            const { url: darkLogoUrl } = darkLogoRes;

            const brandingObj = {
              ...data,
              logo_dark: darkLogoUrl,
            };

            await api.post("branding", brandingObj);

            localStorage.setItem(
              "isutra_siteBranding",
              JSON.stringify(brandingObj)
            );

            dispatch({
              type: POST_SITE_BRANDING_SUCCESS,
              payload: brandingObj,
            });
          })
          .catch((error) => {
            throw new Error();
          });
      }

      if (darkLogo && lightLogo) {
        uploadCover("image", lightLogo)
          .then((lightLogoRes) => {
            const { url: lightLogoUrl } = lightLogoRes;

            uploadCover("image", darkLogo)
              .then(async (darkLogoRes) => {
                const { url: darkLogoUrl } = darkLogoRes;

                const brandingObj = {
                  ...data,
                  logo_dark: darkLogoUrl,
                  logo_light: lightLogoUrl,
                };

                await api.post("branding", brandingObj);

                localStorage.setItem(
                  "isutra_siteBranding",
                  JSON.stringify(brandingObj)
                );

                dispatch({
                  type: POST_SITE_BRANDING_SUCCESS,
                  payload: brandingObj,
                });
              })
              .catch((error) => {
                throw new Error();
              });
          })
          .catch((error) => {
            throw new Error();
          });
      }
    } catch (error) {
      dispatch({
        type: POST_SITE_BRANDING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const postBrandingV2 = (data) => async (dispatch) => {
  try {
    dispatch({
      type: POST_SITE_BRANDING_REQUEST,
    });

    const brandingObj = {
      ...data,
    };

    await api.post("branding", brandingObj);

    localStorage.setItem("isutra_siteBranding", JSON.stringify(brandingObj));

    dispatch({
      type: POST_SITE_BRANDING_SUCCESS,
      payload: brandingObj,
    });
  } catch (error) {
    dispatch({
      type: POST_SITE_BRANDING_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
