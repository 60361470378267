import React from "react";
import styled from "styled-components";
import avatar_placeholder from "../../assets/avtar_placeholder.png";
import MenuOptions from "../Cards/MenuOptions";
import Button from "../Elements/Button";
import imageNotFoundPlaceholder from "../../assets/image_notfound_placeholder.svg";
import Moment from "react-moment";
import { SERIES_OTHER } from "../../utils/menuOptions";
import { useHistory } from "react-router-dom";
import config from "../../config";

const FeaturedSeriesCard = ({ item, midDrag }) => {
  const history = useHistory();
  const badge = item.userSettings?.VisitorBadge.imgPath;

  const isPngOrJpg = () => {
    const image = !item?.User?.avatar ? badge : item.User.avatar;

    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <img
          className={`${image.includes(".png") ? "imageAvatar" : "imageBadge"}`}
          src={image}
          alt="badge"
          style={{
            outline: !image.includes(".png") && "5px solid #202020",
          }}
        />
      );
    } else {
      return (
        <img
          onError={(e) => addDefaultImage(e, imageNotFoundPlaceholder)}
          className="imageCustom"
          src={!!image ? config.aws.CLOUDFRONT_URL + image : avatar_placeholder}
          alt="badge"
        />
      );
    }
  };

  const handleSeriesDetails = (e) => {
    if (midDrag) {
      e.stopPropagation();
      return;
    }

    history.push(`/series/details/${item?.id}`);
    return;
  };

  const addDefaultImage = (e) => {
    e.target.src = imageNotFoundPlaceholder;
    return;
  };

  return (
    <>
      <FeaturedSeriesStyled onClick={handleSeriesDetails}>
        <div>
          <img
            onError={addDefaultImage}
            src={config.aws.CLOUDFRONT_URL + item?.thumbnail}
            alt={item?.title}
            className="thumbnail"
            style={{ cursor: "pointer" }}
          />
        </div>

        <div className="price">
          {item?.price > 0 ? `$${parseFloat(item?.price).toFixed(2)}` : "free"}
        </div>
        {item?.numVideos > 0 && (
          <div className="numVideos">
            {item.numVideos} {item.numVideos === 1 ? "video" : "videos"}
          </div>
        )}
      </FeaturedSeriesStyled>
      <MetaInfoStyled>
        <div className="metaInfo">
          <div className="metaInfo__left">
            <div
              onClick={() => {
                history.push(`/channel/${item?.User?.username}`);
              }}
              className="metaInfo__left--avatar"
            >
              {isPngOrJpg()}
            </div>

            <div className={`metaInfo__left--videoMeta `}>
              <div
                onClick={() => {
                  history.push(`/series/details/${item.id}`);
                }}
                className="title"
              >
                {item?.title}
              </div>

              <div className="seperatorInfo">
                <div
                  onClick={() => {
                    history.push(`/channel/${item?.User?.username}`);
                  }}
                  className="bold"
                >
                  {item?.User?.username}
                </div>

                <div className="seperator"></div>

                <div className="light">
                  <Moment fromNow>{item?.createdAt}</Moment>
                </div>
              </div>
            </div>
          </div>

          <div className="menu">
            <MenuOptions item={item} options={SERIES_OTHER} />
          </div>
        </div>
      </MetaInfoStyled>
    </>
  );
};

export default FeaturedSeriesCard;

const MetaInfoStyled = styled.div`
  .metaInfo {
    padding: 0 0.25rem;
    font-family: ${(props) => props.theme.montserrat};
    position: relative;

    .menu {
      position: absolute;
      top: 0;
      right: 0.5rem;
      cursor: pointer;
    }

    &__left {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      &--avatar {
        cursor: pointer;
        height: 4rem;
        width: 4rem;
        margin-right: 1rem;
        border-radius: 50%;
        position: relative;

        .imageAvatar {
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          object-fit: cover;
          margin-left: 1rem;
        }
        .imageCustom {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          object-fit: cover;
        }

        .imageBadge {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .badge {
            height: 38px;
            width: auto;
          }
        }
      }

      &--videoMeta {
        cursor: pointer;
        width: calc(100% - 5rem);
        padding-right: 0.5rem;

        .title {
          font-size: 0.9rem;
          font-weight: 400;
          line-height: 1.3;
          margin-bottom: 0.2rem;
          overflow-wrap: break-word;
          margin-left: 0;
        }

        .seperatorInfo {
          font-size: 0.7rem;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .seperator {
            height: 4px;
            width: 4px;
            border-radius: 50%;
            margin: 0 0.35rem;
            background-color: #fff;
          }

          .bold {
            font-weight: 600;
            text-decoration: underline;
            background: -webkit-${(props) => props.theme.primaryGradient};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            letter-spacing: 0.02rem;
            cursor: pointer;
            text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.3);
            position: relative;

            &:before {
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              height: 2px;
              background: ${(props) => props.theme.primaryGradient};
              width: 0%;
              transition: all 0.4s ease;
            }

            &:hover:before {
              width: 100%;
            }
          }

          .light {
            color: rgba(174, 174, 178, 1);
            font-weight: 300;
            font-size: 0.65rem;
          }
        }
      }
    }
  }
`;

const FeaturedSeriesStyled = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
  position: relative;
  margin: 0 auto;

  .price,
  .numVideos {
    font-family: brother-1816, sans-serif;
    position: absolute;
    bottom: 1rem;
    font-size: 0.7rem;
    padding: 0.4rem 0.5rem;
    color: #fff;
    z-index: 2;
    background-color: rgba(28, 28, 30, 1);
    border-radius: 0.3rem;
    cursor: pointer;
    text-transform: capitalize;
    line-height: 1;
  }

  .price {
    left: 1rem;
  }

  .numVideos {
    right: 1rem;
  }

  .live-badge {
    font-family: Montserrat;
    position: absolute;
    top: 6%;
    padding: 3px 15px 3px 15px;
    z-index: 98;
    border-radius: 10px;
    left: 5%;
    background: ${(props) => props.theme.primaryGradient} !important;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.3);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 0% 50%);
    transition: all 0.3s ease;

    p {
      font-size: 0.7rem;
      text-transform: capitalize;
      font-weight: 300;
      letter-spacing: 0.1px;
      margin: 0;
      padding: 0;
      transition: all 0.2s ease;

      span {
        font-weight: bold;
      }
    }
  }
  .view-badge {
    font-family: Montserrat;
    position: absolute;
    bottom: 20px;
    padding: 3px 15px 3px 15px;
    z-index: 98;
    border-radius: 10px;
    left: 5%;
    background: #181818;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.3);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 0% 50%);
    transition: all 0.3s ease;
  }
  .thumbnail {
    width: 100%;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    z-index: -1;
    padding: 10px;
    outline: none;
    height: 20rem;
    min-height: 35vh;
  }
  .video-title {
    text-align: center;
    font-family: Montserrat;
  }
  @media screen and (max-width: 530px) {
    .thumbnail {
      height: 30vh;
    }
    .live-badge {
      font-size: 10px;
    }
    .view-badge {
      font-size: 10px;
    }
    .video-title {
      font-size: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    .live-badge {
      font-size: 10px;
    }
    .view-badge {
      font-size: 10px;
    }
    .video-title {
      font-size: 10px;
    }
  }
`;
