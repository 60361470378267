import React, { useState, useEffect, useContext, useRef } from "react";
import FilterButton from "../../styles/FilterButton";
import SubFilter from "./SubFilter";
import { GlobalContext } from "../../context/GlobalContext";
import { Container, FilterContainer, SubFilterContainer } from "./styles";
import { STATIC_CATEGORIES } from "../../utils/common";
import { Chip, IconButton, Tooltip } from "@material-ui/core";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import CloseIcon from "@material-ui/icons/Close";
import FilterNoneOutlinedIcon from "@material-ui/icons/FilterNoneOutlined";

const HomeCategoryFilter = ({
  selectedCategory,
  setSelectedCategory,
  currentMainCategory,
  handleCategory,
  currentStaticFilterId,
  getAllVideos,
  clearSubFilters,
  categoryList,
}) => {
  const [mainCategories, setMainCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentActiveItemId, setCurrentActiveItemId] = useState(null);
  const [divPosition, setDivPosition] = useState({ x: 0, y: 0 });
  const [showComponent, setShowComponent] = useState(true);
  const [showSelectedFilters, setShowSelectedFilters] = useState(false);
  const [currentSubMenu, setCurrentSubMenu] = useState(null);
  const componentRef = useRef(null);
  let videoCategories = categoryList.data;

  // console.log('mainCategries', mainCategories);
  // console.log('categories', categories);

  useEffect(() => {
    if (videoCategories && videoCategories.length) {
      let categs = videoCategories;
      categs.forEach((vc) => {
        vc["isSelected"] = false;
        vc.subCategOne.forEach((sco) => {
          sco.subCategTwo.forEach((sct) => {
            sct["isSelected"] = false;
          });
        });
      });
      setMainCategories(categs);
    }
  }, [videoCategories, clearSubFilters]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowComponent(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mainCategories]);

  const toggleSubFilter = (item, event) => {
    setShowComponent(true);

    const buttonPosition = event.target.getBoundingClientRect();
    const divX = buttonPosition.left;
    const divY = buttonPosition.top + 50;
    setDivPosition({ x: divX, y: divY });

    if (currentMainCategory.id === item.id) {
      const selectedCategs = selectedCategory;
      setSelectedCategory(
        selectedCategs.filter((sc) => sc.videoCategoryId !== item.id)
      );
    } else handleCategory(item);
  };

  const handleCheck = (
    checked,
    mainCat,
    sbCatOne,
    subCatTwo,
    allowOnlyOneSubCat
  ) => {
    try {
      let updatedMainCat = [...mainCategories];
      updatedMainCat.forEach((mc) => {
        mc.subCategOne.forEach((sbo) => {
          sbo.subCategTwo.forEach((sbc) => {
            if (sbc.id === subCatTwo.id) {
              sbc.isSelected = checked;
            }
          });
        });
      });
      setMainCategories(updatedMainCat);

      if (checked) {
        if (allowOnlyOneSubCat) {
          setCurrentActiveItemId(subCatTwo.id);
        }
        let updatedCategory = [...categories];
        updatedCategory.push(subCatTwo.name);
        setCategories(updatedCategory);
        const filtered = selectedCategory.filter(
          (selectedCat) =>
            selectedCat.videoCategoryId === mainCat.id &&
            selectedCat.subCategOneId !== null &&
            selectedCat.subCategTwoId !== null
        );
        setSelectedCategory([
          ...filtered,
          {
            videoCategoryId: mainCat.id,
            subCategOneId: sbCatOne.id,
            subCategTwoId: subCatTwo.id,
          },
        ]);
      } else {
        if (allowOnlyOneSubCat) {
          setCurrentActiveItemId(null);
        }
        let updatedCategory = [...categories];
        updatedCategory = categories.filter((item) => item !== subCatTwo.name);
        setCategories(updatedCategory);

        let selectedCateg =
          selectedCategory.length &&
          selectedCategory.filter((sc) => {
            return sc.subCategTwoId !== subCatTwo.id;
          });

        if (selectedCateg.length === 0) {
          setSelectedCategory([
            {
              videoCategoryId: mainCat.id,
              subCategOneId: null,
              subCategTwoId: null,
            },
          ]);
        } else setSelectedCategory(selectedCateg);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const clearAllFilters = () => {
    setCategories([]);
    setSelectedCategory([]);
    if (videoCategories && videoCategories.length) {
      let categs = videoCategories;
      categs.forEach((vc) => {
        vc["isSelected"] = false;
        vc.subCategOne.forEach((sco) => {
          sco.subCategTwo.forEach((sct) => {
            sct["isSelected"] = false;
          });
        });
      });
      setMainCategories(categs);
    }
    setCurrentActiveItemId(null);
  };

  return (
    <Container>
      <FilterContainer>
        <IconButton
          color="primary"
          className="filterIconBtn"
          onClick={() => setShowSelectedFilters(!showSelectedFilters)}
        >
          <FilterListRoundedIcon fontSize="medium" />
        </IconButton>
        <FilterButton
          grey
          isActive={currentStaticFilterId === STATIC_CATEGORIES.ALL}
          onClick={() => {
            clearAllFilters();
            getAllVideos();
          }}
        >
          All
        </FilterButton>

        {videoCategories &&
          videoCategories.length &&
          videoCategories.map((item, index) => (
            <FilterButton
              key={index}
              grey
              isActive={
                currentMainCategory !== null &&
                item.id === currentMainCategory.id
              }
              onClick={(e) => toggleSubFilter(item, e)}
            >
              {item.name}
            </FilterButton>
          ))}
      </FilterContainer>

      {showComponent &&
        currentMainCategory &&
        currentMainCategory.subCategOne?.length != 0 &&
        currentMainCategory.id !== -1 && (
          <div ref={componentRef}>
            <SubFilter
              left={divPosition.x}
              top={divPosition.y}
              mainCategory={currentMainCategory}
              handleCheck={handleCheck}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              currentActiveItemId={currentActiveItemId}
              handleCurrentSubMenu={(item) => setCurrentSubMenu(item)}
            />
          </div>
        )}
      {showSelectedFilters && (
        <div className="selectedFilters">
          <div className="header">
            <h3>Selected Filters:</h3>
            <div className="icon-container">
              <Tooltip title="Clear all filters">
                <IconButton
                  color="primary"
                  className="icon"
                  onClick={clearAllFilters}
                >
                  <ClearAllIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton
                  color="primary"
                  className="icon close"
                  onClick={() => setShowSelectedFilters(!showSelectedFilters)}
                >
                  <CloseIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="content">
            {categories?.length == 0 && (
              <div>
                <p>No child filters selected.</p>
              </div>
            )}
            {categories?.length > 0 &&
              categories.map((item) => {
                return (
                  <div>
                    <Chip size="small" label={item} style={{ margin: "2px" }} />
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </Container>
  );
};

export default HomeCategoryFilter;
