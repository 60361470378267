import React from "react";
import styled from "styled-components";
import SkeletonElement from "../SkeletonElement";
import SkeletonShimmer from "../SkeletonShimmer";

const SeriesDetails = () => {
  return (
    <SeriesDetailsSkeletonStyled>
      <div className="hero">
        <div className="hero__left">
          <div className="hero__left--box">
            <SkeletonElement type="title" mb="1rem" />
            <SkeletonElement type="paragraph" mb=".5rem" />
            <SkeletonElement type="paragraph" mb="2rem" />

            <div className="stats">
              <SkeletonElement type="avatarSM" mr="1rem" />
              <SkeletonElement type="buttonMD" />
            </div>

            <SkeletonShimmer />
          </div>
        </div>
        <div className="hero__right">
          <SkeletonElement type="avatarXL" varient="dark" />
        </div>
      </div>

      <div className="content">
        <SkeletonElement type="title" varient="dark" mb="2rem" />

        {Array.from({ length: 5 }).map((item, i) => (
          <div key={i} className="content__card">
            <div className="content__card--thumbnail">
              <SkeletonShimmer />
            </div>

            <div className="content__card--info">
              <SkeletonElement type="title" varient="dark" mb=".5rem" />
              <SkeletonElement type="paragraph" varient="dark" mb=".5rem" />
              <SkeletonElement type="paragraph" varient="dark" />
            </div>
          </div>
        ))}
      </div>
    </SeriesDetailsSkeletonStyled>
  );
};

export default SeriesDetails;

const SeriesDetailsSkeletonStyled = styled.div`
  .hero {
    background-color: #202020;
    margin-bottom: 4rem;
    padding: 4rem 2rem;
    height: auto;
    display: flex;
    align-items: center;

    &__left {
      width: 70%;

      &--box {
        border-radius: 0.5rem;
        width: 100%;
        background-color: #181818;
        padding: 2rem;
        border: 1px solid rgba(255, 255, 255, 0.05);

        position: relative;
        overflow: hidden;

        .stats {
          display: flex;
          align-items: center;
        }
      }
    }

    &__right {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .content {
    min-width: 480px;
    width: 55%;
    margin: 0 auto 5rem auto;
    background-color: #202020;
    padding: 1.5rem;

    &__card {
      width: 100%;
      margin-bottom: 1rem;
      display: flex;
      align-items: flex-start;

      &--thumbnail {
        background-color: #181818;
        width: 10rem;
        height: 6rem;
        border-radius: 6px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        margin-right: 1rem;

        position: relative;
        overflow: hidden;
      }

      &--info {
        width: calc(100% - 11rem);
      }
    }
  }

  @media screen and (max-width: 480px) {
    .hero {
      flex-direction: column-reverse;
      padding: 2rem 1rem;

      &__left,
      &__right {
        width: 100%;
      }

      &__left {
        &--box {
            padding: 1.5rem;
        }
      }

      &__right {
        margin-bottom: 2rem;
      }
    }

    .content {
      min-width: 95%;
      margin: 0 auto 2.5rem auto;
      padding: 1rem 0.5rem;

      &__card {
        &--thumbnail {
          width: 7rem;
          height: 5rem;
        }

        &--info {
          width: calc(100% - 8rem);
        }
      }
    }
  }
`;
