import React, { useState } from "react";
import styled from "styled-components";
import Input from "../../components/Elements/Input";
import Button from "../../components/Elements/Button";
import { Row, Col } from "react-grid-system";
import { adminVideoAccesOverlay, updateVideosAccess } from "../../actions";
import { useDispatch } from "react-redux";
import { uploadImage } from "../../utils";
import { toast } from "react-toastify";

const EditVideoAccesTable = ({ isOpen, onClose, overlayData, onChange }) => {
  const dispatch = useDispatch();
  const [updatedImageUrl, setUpdatedImageUrl] = useState(null);
  const handleUpdateAdminUser = async () => {
    try {
      const updatedFormData = {
        ...overlayData,
        ...updatedImageUrl,
      };
      await dispatch(updateVideosAccess(updatedFormData));
      await dispatch(adminVideoAccesOverlay());
      onClose();
    } catch (error) {
      console.error("Error updating Video:", error);
    }
  };
  const handleIconChange = async (e, field) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").pop();
    const allowedFileTypes = ["jpg", "jpeg", "png"];

    if (!allowedFileTypes.includes(fileExtension)) {
      toast.error(
        `Image extension does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
      return;
    }
    toast.info("Uploading...");

    if (file) {
      try {
        const { url } = await uploadImage("image", file);
        toast.success("Image uploaded successfully");
        setUpdatedImageUrl((prevUrls) => ({
          ...prevUrls,
          [field]: url,
        }));
        onChange(field, url);
      } catch (error) {
        toast.error("Image upload failed. Please try again.");
      }
    }
  };

  return (
    <VideoEditModal open={isOpen}>
      <VideoEditContent>
        <div className="title">Edit Video Acces Overlay Data</div>
        <div className="field">
          <Row className="row row__one">
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Name"
                  name="name"
                  id="name"
                  placeholder="name"
                  type="text"
                  value={overlayData.name}
                  onChange={(e) => onChange("name", e.target.value)}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Description"
                  name="description"
                  id="description"
                  placeholder="Description"
                  type="text"
                  value={overlayData.description}
                  onChange={(e) => onChange("description", e.target.value)}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="imgPathFreeLoader"
                  name="imgPathFreeLoader"
                  id="imgPathFreeLoader"
                  placeholder="imgPathFreeLoader"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleIconChange(e, "imgPathFreeLoader")}
                />
                <label style={{ color: "#bec3c7" }}>
                  {overlayData.imgPathFreeLoader}{" "}
                </label>
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="imgPathMember"
                  name="imgPathMember"
                  id="imgPathMember"
                  placeholder="imgPathMember"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleIconChange(e, "imgPathMember")}
                />
                <label style={{ color: "#bec3c7" }}>
                  {overlayData.imgPathMember}{" "}
                </label>
              </div>
            </Col>
            .
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="CreatedAt"
                  name="createdAt"
                  id="createdAt"
                  placeholder="Created At"
                  type="text"
                  value={overlayData.createdAt}
                  onChange={(e) => onChange("createdAt", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Update At"
                  name="updatedAt"
                  id="updatedAt"
                  placeholder="Update At"
                  type="text"
                  value={overlayData.updatedAt}
                  onChange={(e) => onChange("updatedAt", e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Row className="row row__one">
          <Col md={6} sm={6} xs={6}>
            <Button mode={"secondary"} className="submit" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <Button
              mode={"primary"}
              className="submit"
              onClick={handleUpdateAdminUser}
            >
              Save
            </Button>
          </Col>
        </Row>
      </VideoEditContent>
    </VideoEditModal>
  );
};

export default EditVideoAccesTable;

const VideoEditModal = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoEditContent = styled.div`
  background: #2c2c2e;
  color: white;
  border: 1px solid white;
  padding: 20px;
  max-width: 800px;
  width: 100%;
  .imgaeInput {
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px;
    width: 100%;
    color: #2c2c2e;
    margin-bottom: 0px;
    background: aliceblue;
    margin-top: 12px;
  }

  .title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .submit {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdownBox {
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    margin-top: 3px;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;
