import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Input from "../../components/Elements/Input";
import Button from "../../components/Elements/Button";
import { Row, Col } from "react-grid-system";
import { addPostWidgetBanner } from "../../actions";
import { uploadVistorBadge } from "../../utils";
import { toast } from "react-toastify";

const AddwidBanner = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageNames, setSelectedImageNames] = useState({});

  const [widBannerData, setWidBannerData] = useState({
    title: "",
    description: "",
    order: "",
    userId: null,
    categoryId: "",
    bannerLocation: "",
    bannerImgPathInternal_XL_1920x400: "",
    bannerImgPathInternal_L_1366x400: "",
    bannerImgPathInternal_L_1280x400: "",
    bannerImgPathInternal_L_1024x400: "",
    bannerImgPathInternal_MD_834x400: "",
    bannerImgPathInternal_MD_768x400: "",
    bannerImgPathInternal_SM_428x250: "",
    bannerImgPathInternal_SM_414x250: "",
    bannerImgPathInternal_SM_375x250: "",
  });
  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setWidBannerData({ ...widBannerData, [name]: files[0] });
    } else {
      setWidBannerData({ ...widBannerData, [name]: value });
    }
  };
  const handleSubmit = () => {
    dispatch(addPostWidgetBanner(widBannerData));
    onClose();
  };

  // For Image
  const handleIconChange = async (e) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "jpeg", "png"];

    if (!allowedFileTypes.includes(fileExtension)) {
      toast.error(
        `Image extension does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
      return;
    }
    toast.info("Uploading...");
    setSelectedImage(file.name);

    if (file) {
      try {
        const { url } = await uploadVistorBadge("image", file);
        const inputName = e.target.name;
        setWidBannerData({
          ...widBannerData,
          [inputName]: file.name,
        });
        setSelectedImageNames({
          ...selectedImageNames,
          [inputName]: file.name,
        });
        toast.success("Image uploaded successfully");
      } catch (error) {
        toast.error("Image upload failed. Please try again.");
      }
    }
  };

  return (
    <AddWidegetBannerModal open={isOpen}>
      <AddWidegtBannerEditContent>
        <div className="title">Add Widegt Banner sliders</div>
        <div className="field">
          <Row className="row row__one">
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Title"
                  name="title"
                  id="title"
                  placeholder="title"
                  type="text"
                  value={widBannerData.title}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="description"
                  name="description"
                  id="description"
                  placeholder="description"
                  type="text"
                  value={widBannerData.description}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="order"
                  name="order"
                  id="order"
                  placeholder="order"
                  type="number"
                  value={widBannerData.order}
                  onChange={handleInputChange}
                />
              </div>
            </Col>

            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="userId"
                  name="userId"
                  id="userId"
                  placeholder="userId"
                  type="text"
                  value={widBannerData.userId}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="categoryId"
                  name="categoryId"
                  id="categoryId"
                  placeholder="categoryId"
                  type="number"
                  value={widBannerData.categoryId}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="bannerLocation"
                  name="bannerLocation"
                  id="bannerLocation"
                  placeholder="bannerLocation"
                  type="text"
                  value={widBannerData.bannerLocation}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <label>bannerImgPathInternal_XL_1920x400</label>
                <input
                  label="bannerImgPathInternal_XL_1920x400"
                  name="bannerImgPathInternal_XL_1920x400"
                  id="bannerImgPathInternal_XL_1920x400"
                  placeholder="bannerImgPathInternal_XL_1920x400"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={handleIconChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <label>bannerImgPathInternal_L_1366x400</label>
                <input
                  label="bannerImgPathInternal_L_1366x400"
                  name="bannerImgPathInternal_L_1366x400"
                  id="bannerImgPathInternal_L_1366x400"
                  placeholder="bannerImgPathInternal_L_1366x400"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={handleIconChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <label>bannerImgPathInternal_L_1280x400</label>
                <input
                  label="bannerImgPathInternal_L_1280x400"
                  name="bannerImgPathInternal_L_1280x400"
                  id="bannerImgPathInternal_L_1280x400"
                  placeholder="bannerImgPathInternal_L_1280x400"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={handleIconChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <label>bannerImgPathInternal_L_1024x400</label>
                <input
                  label="bannerImgPathInternal_L_1024x400"
                  name="bannerImgPathInternal_L_1024x400"
                  id="bannerImgPathInternal_L_1024x400"
                  placeholder="bannerImgPathInternal_L_1024x400"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={handleIconChange}
                />
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <label>bannerImgPathInternal_MD_834x400</label>
                <input
                  label="bannerImgPathInternal_MD_834x400"
                  name="bannerImgPathInternal_MD_834x400"
                  id="bannerImgPathInternal_MD_834x400"
                  placeholder="bannerImgPathInternal_MD_834x400"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={handleIconChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <label>bannerImgPathInternal_MD_768x400</label>
                <input
                  label="bannerImgPathInternal_MD_768x400"
                  name="bannerImgPathInternal_MD_768x400"
                  id="bannerImgPathInternal_MD_768x400"
                  placeholder="bannerImgPathInternal_MD_768x400"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={handleIconChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <label>bannerImgPathInternal_SM_428x250</label>
                <input
                  label="bannerImgPathInternal_SM_428x250"
                  name="bannerImgPathInternal_SM_428x250"
                  id="bannerImgPathInternal_SM_428x250"
                  placeholder="bannerImgPathInternal_SM_428x250"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={handleIconChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <label>bannerImgPathInternal_SM_414x250</label>
                <input
                  label="bannerImgPathInternal_SM_414x250"
                  name="bannerImgPathInternal_SM_414x250"
                  id="bannerImgPathInternal_SM_414x250"
                  placeholder="bannerImgPathInternal_SM_414x250"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={handleIconChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <label>bannerImgPathInternal_SM_375x250</label>
                <input
                  label="bannerImgPathInternal_SM_375x250"
                  name="bannerImgPathInternal_SM_375x250"
                  id="bannerImgPathInternal_SM_375x250"
                  placeholder="bannerImgPathInternal_SM_375x250"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={handleIconChange}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Row className="row row__one">
          <Col md={6} sm={6} xs={6}>
            <Button mode={"secondary"} className="submit" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <Button className="submit" onClick={handleSubmit}>
              Save
            </Button>
          </Col>
        </Row>
      </AddWidegtBannerEditContent>
    </AddWidegetBannerModal>
  );
};

export default AddwidBanner;

const AddWidegetBannerModal = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddWidegtBannerEditContent = styled.div`
  background: #2c2c2e;
  color: white;
  border: 1px solid white;
  padding: 20px;
  max-width: 800px;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  .imgaeInput {
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px;
    width: 100%;
    color: #2c2c2e;
    margin-bottom: 0px;
    background: aliceblue;
  }
  .title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .submit {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdownBox {
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    margin-top: 3px;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;
