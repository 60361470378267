import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UserRoleButton from "../SmallComponents/UserRoleButton";
import Moment from "react-moment";
import MenuOptions from "../../Cards/MenuOptions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getProfile,
  getUserChannels,
  userHasMoreChannels,
} from "../../../actions";
import { STREAMS_OPTIONS } from "../../../utils/menuOptions";
import config from "../../../config";
import { useInView } from "react-intersection-observer";
import Loader from "../../Elements/Loader";
const ChannelsTab = () => {
  const profile = useSelector((state) => state.profile);
  const history = useHistory();
  const {
    channels: userChannels,
    loading: userChannelLoading,
    totalpage: totalpage,
  } = useSelector((state) => state.userChannels);
  const { ref: trackerRef, inView: trackerIsVisible } = useInView();
  const dispatch = useDispatch();
  const [channelPage, setChannelPage] = useState(1);
  const [channelLimit, setChannelLimit] = useState(4);

  const isBadgeOrAvatar = (item) => {
    const image = !item.avatar ? item.visitorBadge.imgPath : item.avatar;

    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <div className="imageBadge">
          <img className="badge" src={image} alt="" />
        </div>
      );
    } else {
      return (
        <img
          className="imageAvatar"
          src={config.aws.CLOUDFRONT_URL + image}
          alt=""
        />
      );
    }
  };

  const getUserRole = (userrole) => {
    switch (userrole) {
      case 0:
        return "Freeloader";
      case 1:
        return "CO-OP";
      case 2:
        return "Warrior";
      default:
        return "Freeloader";
    }
  };
  const handleUserPortal = (userData) => {
    dispatch(getProfile(userData.id));
    history.push(`/channel/${userData.username}`);
  };

  const channelObj = {
    limit: channelLimit,
    skip: channelPage,
  };
  useEffect(() => {
    dispatch(getUserChannels(channelObj, profile.username));
  }, []);

  useEffect(() => {
    if (trackerIsVisible) {
      console.log("here");
      setChannelPage(channelPage + 1);
      const channelObj = {
        skip: channelPage + 1,
        limit: channelLimit,
      };
      const pageSize = Math.ceil(totalpage);
      if (pageSize) {
        if (channelPage < pageSize) {
          dispatch(userHasMoreChannels(channelObj, profile.username));
        }
      }
    }
  }, [trackerIsVisible]);
  return (
    <>
      <ChannelsTabStyled>
        <div className="container">
          {userChannelLoading && <Loader text="Please wait..." />}
          {!profile?.channels?.length && (
            <div className="notFound">Not subscribed to any channels yet!</div>
          )}

          {!userChannelLoading &&
            userChannels.length > 0 &&
            userChannels.map((item, i) => (
              <div key={i} className="container__item">
                <div className="container__item--left">
                  <div
                    onClick={() => handleUserPortal(item)}
                    className="avatar"
                  >
                    {isBadgeOrAvatar(item)}
                  </div>

                  <div className="stats">
                    <div
                      onClick={() => handleUserPortal(item)}
                      className="stats__username"
                    >
                      {item.username}
                    </div>
                    <div className="stats__joindate">
                      Connected since <Moment fromNow>{item.createdAt}</Moment>
                    </div>
                  </div>
                </div>

                <div className="container__item--right">
                  <div className="button">
                    <UserRoleButton type={item.userrole}>
                      {getUserRole(item.userrole)}
                    </UserRoleButton>
                  </div>

                  <MenuOptions options={STREAMS_OPTIONS} channel={item} />
                </div>
              </div>
            ))}
        </div>
      </ChannelsTabStyled>
      {<div ref={trackerRef} className="tracker" />}
    </>
  );
};

export default ChannelsTab;

const ChannelsTabStyled = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 2.5rem 0;
  font-family: ${(props) => props.theme.montserrat};

  .container {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__item {
      margin-bottom: 2rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &--left {
        display: flex;
        align-items: center;

        .avatar {
          cursor: pointer;
          height: 4rem;
          width: 4rem;
          border-radius: 50%;
          position: relative;
          margin-right: 1rem;
          transition: all 0.2s ease;
          outline: 1px solid transparent;

          &:hover {
            outline: 1px solid rgba(255, 255, 255, 0.5);
          }

          .imageAvatar {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
            border-radius: 50%;
          }

          .imageBadge {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .badge {
              height: 90%;
              width: auto;
            }
          }
        }

        .stats {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          &__username {
            font-size: 1.2rem;
            font-weight: 500;
            color: #f2f2f7;
          }

          &__joindate {
            font-size: 0.85rem;
            font-weight: 300;
            color: #aeaeb2;
          }
        }
      }

      &--right {
        display: flex;
        align-items: center;

        .button {
          margin-right: 0.5rem;
        }

        .options {
          position: relative;

          &__icon {
            i {
              font-size: 1.1rem;
              color: #f2f2f7;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      width: 90%;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 1rem 0;

    .container {
      width: 100%;

      &__item {
        &--left {
          .avatar {
            height: 2.4rem;
            width: 2.4rem;
          }

          .stats {
            &__username {
              font-size: 0.85rem;
            }

            &__joindate {
              font-size: 0.6rem;
            }
          }
        }

        &--right {
          .button {
            margin-right: 0.25rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 390px) {
    .container {
      &__item {
        &--left {
          .avatar {
            height: 2rem;
            width: 2rem;
          }

          .stats {
            &__username {
              font-size: 0.8rem;
            }

            &__joindate {
              font-size: 0.5rem;
            }
          }
        }
      }
    }
  }
`;
