import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../styles/Button";
import Skeleton from "../skeletons/SeriesWatch";
import ReactPlayer from "react-player";
import api from "../services/api";
import { Row, Col } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleSeries,
  getUserById,
  updateSeriesProgress,
  createSeriesProgress,
  getMuxSeriesUrls,
} from "../actions";
import { GET_SERIES_PROGRESS_RESET } from "../actions/types";
import { useParams, useHistory } from "react-router-dom";
import "video.js/dist/video-js.css";
import { toast } from "react-toastify";
import { notificationMessage } from "../utils/toastNotifications";

import config from "../config";

const ModelSeriesPreview = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const seriesId = params.seriesId;
  const videoId = params.videoId;
  const {
    info: seriesInfo,
    videos: seriesVideos,
    loading: getSeriesVideosLoading,
    progressUpdatedMessage,
  } = useSelector((state) => state.singleSeries);

  const [playingVideo, setPlayingVideo] = useState(null);
  const [progressVideos, setProgressVideos] = useState(null);
  const [loadingProgressVideos, setLoadingProgressVideos] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  useEffect(() => {
    dispatch(getSingleSeries(seriesId));
  }, [dispatch, seriesId]);

  useEffect(() => {
    if (progressUpdatedMessage) {
      fetchProgressVideosHandler(seriesInfo?.seriesProgress);

      dispatch({
        type: GET_SERIES_PROGRESS_RESET,
      });
    }
  }, [progressUpdatedMessage]);

  useEffect(() => {
    if (seriesVideos && seriesVideos.length > 0) {
      const videoToPlay = seriesVideos.find(
        (item) => item?.singleDetailedVideo?.id === videoId
      );
      const indexToPlay = seriesVideos.findIndex(
        (item) => item?.singleDetailedVideo?.id === videoId
      );
      setPlayingVideo(videoToPlay?.singleDetailedVideo);
      setCurrentVideoIndex(indexToPlay);
    }
  }, [seriesVideos, videoId]);

  useEffect(() => {
    if (playingVideo && playingVideo.userId) {
      dispatch(getUserById(playingVideo.userId));
    }
  }, [dispatch, playingVideo]);

  useEffect(async () => {
    if (seriesInfo?.seriesProgress) {
      await fetchProgressVideosHandler(seriesInfo?.seriesProgress);
    } else {
      dispatch(createSeriesProgress(seriesId));
    }
  }, [seriesId, seriesInfo]);

  const decodeStreamingUrl = (encodedUrl) => {
    return atob(encodedUrl);
  };
  useEffect(() => {
    if (playingVideo && playingVideo.muxid) {
      getMuxSeriesUrls(playingVideo.muxid)
        .then((playbackUrls) => {
          const encodedHLSUrl = playbackUrls.hls;
          const decodedHLSUrl = decodeStreamingUrl(encodedHLSUrl);

          setVideoUrl(decodedHLSUrl || playbackUrls.dash || playbackUrls.mp4);
        })
        .catch((error) => {
          console.error("Error fetching Mux URLs:", error.message);
          setVideoUrl(config.aws.CLOUDFRONT_URL + playingVideo.url);
        });
    } else if (playingVideo) {
      setVideoUrl(config.aws.CLOUDFRONT_URL + playingVideo.url);
    }
  }, [playingVideo]);
  const isVideoPurchased = (videoId) => {
    if (progressVideos) {
      return progressVideos.includes(videoId);
    }
    return false;
  };

  const videoEndHandler = () => {
    const isLastVideo =
      seriesVideos[seriesVideos.length - 1].singleDetailedVideo.id ===
      playingVideo.id;

    if (!isLastVideo) {
      const indexOfCurrentVideo = seriesVideos.findIndex(
        (item) => item.singleDetailedVideo.id === playingVideo.id
      );
      const nextVideo = seriesVideos[indexOfCurrentVideo + 1];

      if (isVideoPurchased(nextVideo.singleDetailedVideo.id)) {
        setPlayingVideo(nextVideo.singleDetailedVideo);
      } else {
        console.log("Next video is not purchased. Handle accordingly.");
      }
    }

    if (isLastVideo) {
      toast.success(notificationMessage.seriesCompleted);
    }

    dispatch(updateSeriesProgress(seriesId, playingVideo.id));
  };

  const fetchProgressVideosHandler = async (seriesProgressId) => {
    try {
      setLoadingProgressVideos(true);
      const obj = {
        seriesProgressId,
      };
      const res = await api.post(`series/seriesProgress/watchedVideos`, obj);
      console.log(res, "watchedVideos");

      if (res.data.data && res.data.data.length > 0) {
        const modified = res.data.data.map((item) => {
          return item.videoId;
        });
        setProgressVideos([...modified]);
      }
      setLoadingProgressVideos(false);
    } catch (e) {
      setLoadingProgressVideos(false);
    }
  };

  if (getSeriesVideosLoading || (!seriesInfo && !seriesInfo?.id)) {
    return <Skeleton />;
  }

  if (!seriesInfo?.isPurchased && currentVideoIndex > 0) {
    return (
      <NotPurchased>
        <h1>You haven't Purchased This Series.</h1>
        <Button
          onClick={() => history.push(`/series/details/${seriesId}`)}
          className="purchaseBtn"
        >
          Purchase Series
        </Button>
      </NotPurchased>
    );
  }

  if (
    !getSeriesVideosLoading &&
    playingVideo &&
    seriesVideos &&
    seriesVideos.length > 0 &&
    seriesInfo
  ) {
    return (
      <>
        <CourseWatchWrapper>
          <Row className="course">
            <Col className="colItem" md={12} lg={8}>
              <div className="course__videoWatch">
                <ReactPlayer
                  playing={true}
                  url={
                    videoUrl.includes("mux.com")
                      ? videoUrl
                      : config.aws.CLOUDFRONT_URL + playingVideo.url
                  }
                  width="103%"
                  height="111%"
                  controls={true}
                  className="VideoPlayer"
                  style={{ marginTop: "-10%", marginLeft: "-2%" }}
                  onEnded={videoEndHandler}
                />
              </div>
            </Col>
          </Row>
        </CourseWatchWrapper>
      </>
    );
  }

  return null;
};

export default ModelSeriesPreview;

const CourseWatchWrapper = styled.div`
  padding: 2rem 0;
  width: 92%;
  margin: 0 auto;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;
`;

const NotPurchased = styled.div`
  padding: 2rem 0;
  width: 92%;
  margin: 0 auto;

  h1 {
    margin-bottom: 1rem;
  }
`;
