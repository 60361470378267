import React from "react";
import styled, { keyframes } from "styled-components";

const NotifyText = ({ text }) => {
  return <NotifyTextStyled>{text()}</NotifyTextStyled>;
};

export default NotifyText;

const backgroundPan = keyframes`
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
`;

const NotifyTextStyled = styled.div`
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.5;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;
  font-weight: 300;

  .magic {
    &__text {
      display: inline-block;
      animation: ${backgroundPan} 3s linear infinite;
      background: linear-gradient(
        to right,
        ${(props) => props.theme.primaryColor1},
        ${(props) => props.theme.primaryColor2},
        ${(props) => props.theme.primaryColor1}
      );
      background-size: 200%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 500;
      text-transform: uppercase;
      font-family: brother-1816, sans-serif;
      padding: 0 8px;
    }
  }
`;
