import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../components/Elements/Button";
import InputV2 from "../../components/Elements/InputV2";
import Checkbox from "../../components/Elements/Checkbox";
import api from "../../services/api";
import Loader from "../../components/Elements/Loader";
import { FormikProvider, useFormik } from "formik";
import { MarketingPageStyles } from "../MarketingPageAdmin/Styles";
import { Col, Row } from "react-grid-system";
import { toast } from "react-toastify";

const MembershipForm = () => {
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [membershipData, setMembershipData] = useState(null);
  const [stripeProducts, setStripeProducts] = useState(null);

  const formik = useFormik({
    initialValues: { stripeProducts },
    onSubmit: async (values) => {
      await onUpdateProducts(values.stripeProducts);
    },
  });

  useEffect(() => {
    (async () => {
      // await fetchMembershipData();
      await fetchStripeProductDetails();
    })();
  }, []);

  const fetchStripeProductDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get("stripe/getProducts");
      if (response.status == 200) {
        if (response?.data?.products?.length) {
          const prods = response?.data?.products;
          setStripeProducts(prods);
          formik.setValues({ stripeProducts: prods });
        }
      }
    } catch (e) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const onUpdateProducts = async (stripeProducts) => {
    try {
      if (saveLoading) {
        return;
      }
      setSaveLoading(true);
      const response = await api.patch("/stripe/updateStripeProducts", {
        stripeProducts,
      });
      if (response.status == 200) {
        toast.success("Stripe products updated successfully.");
      }
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong");
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <FormikProvider formik>
      <MarketingPageStyles>
        {loading && (
          <Loader text="Please wait while we fetch membership data..." />
        )}
        {!loading && (
          <div className="form">
            <div className="form__pricing">
              {stripeProducts?.length &&
                stripeProducts.map((item, index) => (
                  <div
                    className="form__pricing--section form__pricing--section-1"
                    key={index}
                  >
                    <div className="form__pricing--section-title">
                      {item?.metadata?.productType}
                    </div>

                    <div className="form__item">
                      <Row>
                        <Col md={6} sm={12}>
                          <InputV2
                            name={`stripeProducts[${index}].name`}
                            type={"text"}
                            placeholder={"Add Membership Title"}
                            id={`stripeProducts[${index}].name`}
                            label={"Membership Title"}
                            value={formik.values.stripeProducts[index].name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // formikError={
                            //   formik.touched.stripeProducts?.[index]?.name &&
                            //   formik.errors.stripeProducts?.[index]?.name
                            //     ? formik.errors.stripeProducts[index].name
                            //     : null
                            // }
                          />
                        </Col>

                        <Col md={6} sm={12}>
                          <InputV2
                            name={`stripeProducts[${index}].metadata.subtitle`}
                            type={"text"}
                            placeholder={"Add Membership SubTitle"}
                            id={`stripeProducts[${index}].metadata.subtitle`}
                            label={"Membership SubTitle"}
                            value={
                              formik.values.stripeProducts[index].metadata
                                .subtitle
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // formikError={
                            //   formik.touched.stripeProducts?.[index]?.metadata
                            //     .subtitle &&
                            //   formik.errors.stripeProducts?.[index]?.metadata
                            //     .subtitle
                            //     ? formik.errors.stripeProducts[index].metadata
                            //         .subtitle
                            //     : null
                            // }
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="form__item">
                      {item?.prices.length > 1 ? (
                        <InputV2
                          name={`stripeProducts[${index}].default_price`}
                          type={"select"}
                          placeholder={"Select Membership Price"}
                          id={`stripeProducts[${index}].default_price`}
                          label={"Membership Price"}
                          value={
                            formik.values.stripeProducts[index].default_price.id
                          }
                          onChange={(e) => {
                            const selectedPriceId = e.target.value;
                            const selectedPriceObj =
                              formik.values.stripeProducts[index].prices.find(
                                (item) => item.id === selectedPriceId
                              );
                            formik.setFieldValue(
                              `stripeProducts[${index}].default_price`,
                              selectedPriceObj
                            );
                          }}
                          options={formik.values.stripeProducts[
                            index
                          ].prices.map((item) => {
                            return {
                              name: `${item.nickname} - $${(
                                item.unit_amount / 100
                              ).toFixed(2)}`,
                              value: item.id,
                            };
                          })}
                        />
                      ) : (
                        <InputV2
                          name="price"
                          type={"number"}
                          placeholder={"Select Membership Price"}
                          id={"price"}
                          label={"Membership Price"}
                          value={(
                            formik.values.stripeProducts[index].default_price
                              ?.unit_amount / 100
                          ).toFixed(2)}
                          isDisabled
                        />
                      )}
                    </div>

                    <div className="form__item">
                      <InputV2
                        name={`stripeProducts[${index}].description`}
                        type={"textarea"}
                        placeholder={"Add Membership Description"}
                        id={`stripeProducts[${index}].description`}
                        label={"Membership Description"}
                        value={formik.values.stripeProducts[index].description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // formikError={
                        //   formik.touched.stripeProducts?.[index]?.description &&
                        //   formik.errors.stripeProducts?.[index]?.description
                        //     ? formik.errors.stripeProducts[index].description
                        //     : null
                        // }
                      />
                    </div>

                    <div className="form__item">
                      <InputV2
                        name={`stripeProducts[${index}].metadata.buttonText`}
                        type={"text"}
                        placeholder={"Add Button Text"}
                        id={"buttonText"}
                        label={"Button Text"}
                        value={
                          formik.values.stripeProducts[index].metadata
                            ?.buttonText
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // formikError={
                        //   formik.touched.stripeProducts?.[index]?.metadata
                        //     .buttonText &&
                        //   formik.errors.stripeProducts?.[index]?.metadata
                        //     .buttonText
                        //     ? formik.errors.stripeProducts[index].metadata
                        //         .buttonText
                        //     : null
                        // }
                      />
                    </div>

                    <div className="form__item">
                      <InputV2
                        name="recurring_duration1"
                        type={"text"}
                        placeholder={"Add Membership Recurring Duration"}
                        id={"recurring_duration1"}
                        label={"Membership Recurring Duration"}
                        value={
                          formik.values.stripeProducts[index].default_price
                            .recurring.interval
                        }
                        isDisabled
                      />
                    </div>
                    <div className="form__item">
                      <Checkbox
                        name={`stripeProducts[${index}].metadata.dataPlanIncluded`}
                        title="Data plan included ?"
                        text="You can choose and upgrade storage package by checking this box."
                        value={
                          formik.values.stripeProducts[
                            index
                          ].metadata.dataPlanIncluded.toLowerCase?.() === "true" //stripe prod metadata is string
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            `stripeProducts[${index}].metadata.dataPlanIncluded`,
                            e.target.checked ? "true" : "false"
                          );
                        }}
                        onBlur={formik.handleBlur}
                        // formikError={
                        //   formik.touched.stripeProducts?.[index]?.metadata
                        //     .dataPlanIncluded &&
                        //   formik.errors.stripeProducts?.[index]?.metadata
                        //     .dataPlanIncluded
                        //     ? formik.errors.stripeProducts[index].metadata
                        //         .dataPlanIncluded
                        //     : null
                        // }
                      />
                    </div>
                  </div>
                ))}
              <div className="form__pricing--section">
                <Button
                  onClick={formik.handleSubmit}
                  className="form__pricing--btn"
                  mode="primary"
                  width="fluid"
                  variant="round"
                >
                  {saveLoading ? "please wait..." : "Save Changes"}
                </Button>
              </div>
            </div>
          </div>
        )}
      </MarketingPageStyles>
    </FormikProvider>
  );
};

export default MembershipForm;
