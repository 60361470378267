import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Input from "../../components/Elements/Input";
import Button from "../../components/Elements/Button";
import { Row, Col } from "react-grid-system";
import { addVideoCategory } from "../../actions";
import { uploadImage } from "../../utils";
import { toast } from "react-toastify";

const AddVideoCategory = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState("");
  const [categoryData, setCategoryData] = useState({
    id: "",
    name: "",
    order: "",
    iconPath: "",
  });
  const handleIconChange = async (e) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "jpeg"];

    if (!allowedFileTypes.includes(fileExtension)) {
      toast.error(
        `Image extension does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
      return;
    }
    toast.info("Uploading...");
    setSelectedImage(file.name);

    if (file) {
      try {
        const { url } = await uploadImage("image", file);
        setCategoryData({
          ...categoryData,
          iconPath: file.name,
        });

        toast.success("Image uploaded successfully");
      } catch (error) {
        toast.error("Image upload failed. Please try again.");
      }
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategoryData({ ...categoryData, [name]: value });
  };
  const handleSubmit = () => {
    dispatch(addVideoCategory(categoryData));
    onClose();
  };

  return (
    <AddVideoCategoryModal open={isOpen}>
      <AddVideoCategoryEditContent>
        <div className="title">Add Video Category</div>
        <div className="field">
          <Row className="row row__one">
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Id"
                  name="id"
                  id="id"
                  placeholder="Id"
                  type="number"
                  value={categoryData.id}
                  categoryData
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Name"
                  name="name"
                  id="name"
                  placeholder="name"
                  type="text"
                  value={categoryData.name}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Order"
                  name="order"
                  id="order"
                  placeholder="order"
                  type="number"
                  value={categoryData.order}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <input
                  label="IconPath"
                  name="iconPath"
                  id="iconPath"
                  placeholder="/assets/icons/image.jpg&.svg"
                  className="imgaeInput"
                  type="file"
                  accept="image/*"
                  onChange={handleIconChange}
                />
              </div>
              {selectedImage && <p> {selectedImage}</p>}
            </Col>
          </Row>
        </div>
        <Row className="row row__one">
          <Col md={6} sm={6} xs={6}>
            <Button mode={"secondary"} className="submit" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <Button mode={"primary"} className="submit" onClick={handleSubmit}>
              Save
            </Button>
          </Col>
        </Row>
      </AddVideoCategoryEditContent>
    </AddVideoCategoryModal>
  );
};

export default AddVideoCategory;

const AddVideoCategoryModal = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddVideoCategoryEditContent = styled.div`
  background: #2c2c2e;
  color: white;
  border: 1px solid white;
  padding: 20px;
  max-width: 800px;
  width: 100%;
  .imgaeInput {
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px;
    width: 100%;
    color: #2c2c2e;
    margin-bottom: 0px;
    background: aliceblue;
    margin-top: 10px;
  }

  .title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .submit {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdownBox {
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    margin-top: 3px;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;
