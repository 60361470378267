import React from "react";
import styled, { keyframes, css } from "styled-components";
import UpvoteOutline1Icon from "../icons/upvotes/upvote-outline-1.svg";
import UpvoteOutline2Icon from "../icons/upvotes/upvote-outline-2.svg";
import UpvoteOutline3Icon from "../icons/upvotes/upvote-outline-3.svg";
import UpvoteFill1Icon from "../icons/upvotes/upvote-fill-1.svg";
import UpvoteFill2Icon from "../icons/upvotes/upvote-fill-2.svg";
import UpvoteFill3Icon from "../icons/upvotes/upvote-fill-3.svg";

const Upvote = ({ upvote, upvoteHandler, total, showFinalAnimation, size }) => {
  return (
    <UpvoteStyled size={size}>
      <div className="item">
        <div
          onClick={() => {
            upvoteHandler();
          }}
          className={`upvoteBox ${
            upvote === 3 && showFinalAnimation
              ? "upvoteBox__finalAnimation"
              : null
          }`}
        >
          {Array.from({ length: 50 }).map((item, i) => (
            <i key={i}></i>
          ))}

          {upvote ? (
            <div className="upvoteBox__icons">
              <div className={`upvoteBox__item upvoteBox__item--3 `}>
                {upvote === 3 ? (
                  <img
                    className={`${
                      showFinalAnimation && "upvoteBox__item--showAnimation"
                    }`}
                    src={UpvoteFill3Icon}
                    alt=""
                  />
                ) : (
                  <img src={UpvoteOutline3Icon} alt="" />
                )}
              </div>
              <div className="upvoteBox__item upvoteBox__item--2">
                {upvote === 2 || upvote === 3 ? (
                  <img
                    className={`${
                      showFinalAnimation && "upvoteBox__item--showAnimation"
                    }`}
                    src={UpvoteFill2Icon}
                    alt=""
                  />
                ) : (
                  <img src={UpvoteOutline2Icon} alt="" />
                )}
              </div>
              <div className="upvoteBox__item upvoteBox__item--1">
                {upvote === 1 || upvote === 2 || upvote === 3 ? (
                  <img
                    className={`${
                      showFinalAnimation && "upvoteBox__item--showAnimation"
                    }`}
                    src={UpvoteFill1Icon}
                    alt=""
                  />
                ) : (
                  <img src={UpvoteOutline1Icon} alt="" />
                )}
              </div>
            </div>
          ) : (
            <div className="upvoteBox__icons">
              <div className="upvoteBox__item upvoteBox__item--3">
                <img
                  className={`upvoteBox__item ${
                    upvote === 0 && showFinalAnimation
                      ? "upvoteBox__item--hideAnimation"
                      : null
                  }`}
                  src={UpvoteOutline3Icon}
                  alt=""
                />
              </div>
              <div className="upvoteBox__item upvoteBox__item--2">
                <img
                  className={`upvoteBox__item ${
                    upvote === 0 && showFinalAnimation
                      ? "upvoteBox__item--hideAnimation"
                      : null
                  }`}
                  src={UpvoteOutline2Icon}
                  alt=""
                />
              </div>
              <div className="upvoteBox__item upvoteBox__item--1">
                <img
                  className={`upvoteBox__item ${
                    upvote === 0 && showFinalAnimation
                      ? "upvoteBox__item--hideAnimation"
                      : null
                  }`}
                  src={UpvoteOutline1Icon}
                  alt=""
                />
              </div>
            </div>
          )}
        </div>

        <span className="stats">
          {total} {total <= 1 ? "vote" : "votes"}
        </span>
      </div>
    </UpvoteStyled>
  );
};

export default Upvote;

const animationOff = keyframes`
  0% {
    transform: translateY(25px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const animationOn = keyframes`
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const bang = keyframes`
 from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

const UpvoteStyled = styled.div`
  .item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    .stats {
      font-size: 0.78rem;
      text-transform: uppercase;
      margin-top: 0.2rem;
      color: #fff;
      font-weight: 500;
    }

    .upvoteBox {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      user-select: none;
      height: 3.7rem;
      width: 3.7rem;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.08);
      cursor: pointer;
      position: relative;
      cursor: pointer;

      i {
        position: absolute;
        display: block;
        left: 50%;
        top: 0;
        width: 3px;
        height: 10px;
        background: ${(props) => props.theme.primaryGradient};
        opacity: 0;
      }

      &__icons {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }

      &__finalAnimation {
        i {
          animation: ${bang} 700ms ease-out forwards;
          opacity: 0;
          animation-delay: 0.2s;

          &:nth-child(even) {
            background: ${(props) => props.theme.primaryGradient};
          }

          &:nth-child(odd) {
            background: rgba(255, 255, 255, 0.8);
          }

          &:nth-of-type(2) {
            transform: translate3d(-41px, -53px, 0) rotate(178deg);
          }
          &:nth-of-type(3) {
            transform: translate3d(-46px, 82px, 0) rotate(207deg);
          }
          &:nth-of-type(4) {
            transform: translate3d(-41px, 19px, 0) rotate(357deg);
          }
          &:nth-of-type(5) {
            transform: translate3d(24px, -45px, 0) rotate(208deg);
          }
          &:nth-of-type(6) {
            transform: translate3d(18px, 48px, 0) rotate(240deg);
          }
          &:nth-of-type(7) {
            transform: translate3d(74px, 49px, 0) rotate(340deg);
          }
          &:nth-of-type(8) {
            transform: translate3d(24px, 17px, 0) rotate(210deg);
          }
          &:nth-of-type(9) {
            transform: translate3d(-43px, 61px, 0) rotate(183deg);
          }
          &:nth-of-type(10) {
            transform: translate3d(-68px, 21px, 0) rotate(209deg);
          }
          &:nth-of-type(11) {
            transform: translate3d(-97px, 21px, 0) rotate(333deg);
          }
          &:nth-of-type(12) {
            transform: translate3d(-79px, 1px, 0) rotate(46deg);
          }
          &:nth-of-type(13) {
            transform: translate3d(38px, -33px, 0) rotate(294deg);
          }
          &:nth-of-type(14) {
            transform: translate3d(18px, -4px, 0) rotate(152deg);
          }
          &:nth-of-type(15) {
            transform: translate3d(23px, -90px, 0) rotate(68deg);
          }
          &:nth-of-type(16) {
            transform: translate3d(-62px, -91px, 0) rotate(341deg);
          }
          &:nth-of-type(17) {
            transform: translate3d(35px, -60px, 0) rotate(239deg);
          }
          &:nth-of-type(18) {
            transform: translate3d(-70px, -50px, 0) rotate(78deg);
          }
          &:nth-of-type(19) {
            transform: translate3d(46px, -43px, 0) rotate(358deg);
          }
          &:nth-of-type(20) {
            transform: translate3d(44px, 89px, 0) rotate(209deg);
          }
          &:nth-of-type(21) {
            transform: translate3d(84px, 44px, 0) rotate(257deg);
          }
          &:nth-of-type(22) {
            transform: translate3d(27px, 87px, 0) rotate(148deg);
          }
          &:nth-of-type(23) {
            transform: translate3d(-16px, 52px, 0) rotate(252deg);
          }
          &:nth-of-type(24) {
            transform: translate3d(37px, -55px, 0) rotate(140deg);
          }
          &:nth-of-type(25) {
            transform: translate3d(43px, -16px, 0) rotate(152deg);
          }
          &:nth-of-type(26) {
            transform: translate3d(-62px, 96px, 0) rotate(116deg);
          }
          &:nth-of-type(27) {
            transform: translate3d(79px, 86px, 0) rotate(121deg);
          }
          &:nth-of-type(28) {
            transform: translate3d(16px, -93px, 0) rotate(318deg);
          }
          &:nth-of-type(29) {
            transform: translate3d(-9px, 72px, 0) rotate(93deg);
          }
          &:nth-of-type(30) {
            transform: translate3d(-31px, 98px, 0) rotate(2deg);
          }
          &:nth-of-type(31) {
            transform: translate3d(50px, -52px, 0) rotate(299deg);
          }
          &:nth-of-type(32) {
            transform: translate3d(-13px, 81px, 0) rotate(314deg);
          }
          &:nth-of-type(33) {
            transform: translate3d(-59px, 18px, 0) rotate(1deg);
          }
          &:nth-of-type(34) {
            transform: translate3d(75px, -38px, 0) rotate(65deg);
          }
          &:nth-of-type(35) {
            transform: translate3d(24px, -86px, 0) rotate(105deg);
          }
          &:nth-of-type(36) {
            transform: translate3d(87px, -62px, 0) rotate(317deg);
          }
          &:nth-of-type(37) {
            transform: translate3d(24px, -64px, 0) rotate(9deg);
          }
          &:nth-of-type(38) {
            transform: translate3d(84px, -85px, 0) rotate(135deg);
          }
          &:nth-of-type(39) {
            transform: translate3d(-62px, -99px, 0) rotate(166deg);
          }
          &:nth-of-type(40) {
            transform: translate3d(-82px, 10px, 0) rotate(58deg);
          }
          &:nth-of-type(41) {
            transform: translate3d(42px, 99px, 0) rotate(210deg);
          }
          &:nth-of-type(42) {
            transform: translate3d(-13px, -5px, 0) rotate(5deg);
          }
          &:nth-of-type(43) {
            transform: translate3d(86px, -26px, 0) rotate(50deg);
          }
          &:nth-of-type(44) {
            transform: translate3d(28px, 65px, 0) rotate(57deg);
          }
          &:nth-of-type(45) {
            transform: translate3d(20px, 73px, 0) rotate(119deg);
          }
          &:nth-of-type(46) {
            transform: translate3d(8px, 42px, 0) rotate(142deg);
          }
          &:nth-of-type(47) {
            transform: translate3d(7px, 28px, 0) rotate(133deg);
          }
          &:nth-of-type(48) {
            transform: translate3d(86px, 55px, 0) rotate(293deg);
          }
          &:nth-of-type(49) {
            transform: translate3d(-53px, 62px, 0) rotate(145deg);
          }
          &:nth-of-type(50) {
            transform: translate3d(-11px, 24px, 0) rotate(245deg);
          }
        }
      }

      &__item {
        position: relative;
        line-height: 1;
        transition: all 0.5s ease;

        &--showAnimation {
          animation: ${animationOn} 0.5s cubic-bezier(1, -0.1, 0, 1.5);
        }

        &--hideAnimation {
          animation: ${animationOff} 0.8s cubic-bezier(1, -0.1, 0, 1.5);
        }

        &--1 {
          height: 0.68rem;
          width: auto;
        }

        &--2 {
          height: 0.76rem;
          width: auto;
          margin-bottom: 1px;
        }

        &--3 {
          height: 0.82rem;
          width: auto;
          margin-bottom: 2px;
        }

        img {
          height: 100%;
          width: auto;
        }
      }
    }
  }

  /* size */
  ${(props) =>
    props.size &&
    props.size === "small" &&
    css`
      .item {
        .stats {
          font-size: 0.65rem;
          margin-top: 0.15rem;
        }

        .upvoteBox {
          height: 3rem;
          width: 3rem;

          &__item {
            transform: translateY(-2px);

            &--1 {
              height: 0.55rem;
              width: auto;
            }

            &--2 {
              height: 0.65rem;
              width: auto;
              margin-bottom: 1px;
            }

            &--3 {
              height: 0.7rem;
              width: auto;
              margin-bottom: 2px;
            }
          }
        }
      }
    `}

  @media screen and (max-width: 480px) {
    .item {
      .stats {
        font-size: 0.65rem;
        margin-top: 0.5rem;
        color: #fff;
        font-weight: 500;
      }
    }
  }
`;
