import React, { useState, useEffect, useCallback, useContext } from "react";
import styled, { keyframes } from "styled-components";
import Button from "../styles/Button";
import ReactModel from "react-modal-video";
import ContactBanner from "../components/Footer/ContactBanner";
import Footer from "../components/Footer/Footer";
import Skeleton from "../skeletons/SeriesDetails";
import PurchaseSeriesModel from "../components/SeriesComponents/PurchaseSeriesModel";
import api from "../services/api";
import LockIcon from "../components/icons/LockIcon.js";
import TimeIcon from "../components/icons/time.svg";
import { Row, Col } from "react-grid-system";
import { getSingleSeries, getGuestSingleSeries } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useParams, useHistory } from "react-router-dom";
import { notificationMessage } from "../utils/toastNotifications";
import debounce from "lodash.debounce";
import Upvote from "../components/Elements/Upvote";
import { toHHMMSS } from "../utils";
import { addDefaultImage } from "../utils";
import ImageNotfoundPlaceholder from "../assets/image_notfound_placeholder.svg";
import { GlobalContext } from "../context/GlobalContext";
import config from "../config";
import { useLocation } from "react-router-dom";
import Helmet from "react-helmet";

const SeriesDetails = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const routePath = useLocation();

  const seriesId = params.seriesId;

  const { setShowAuthenticationModel } = useContext(GlobalContext);
  const {
    info: seriesInfo,
    videos: seriesVideos,
    loading: getSeriesVideosLoading,
    error: getSeriesError,
  } = useSelector((state) => state.singleSeries);
  const user = useSelector((state) => state.user);
  const [showIntroModel, setShowIntroModel] = useState(false);
  const [showPurchaseSeriesModel, setShowPurchaseSeriesModel] = useState(null);
  const [upvote, setUpvote] = useState(null);
  const [totalUpvotes, setTotalUpvotes] = useState(null);
  const [readyToCallUpvoteApi, setReadyToCallUpvoteApi] = useState(false);
  const [showFinalAnimation, setShowFinalAnimation] = useState(false);
  const [isSeriesLiked, setIsSeriesLiked] = useState(false);
  const [playingVideoUrl, setPlayingVideoUrl] = useState("");

  useEffect(async () => {
    if (readyToCallUpvoteApi) {
      await upvoteApiCallHandler(seriesId, upvote);
      setReadyToCallUpvoteApi(false);
    }

    if (upvote > 0 && !isSeriesLiked) {
      setIsSeriesLiked(true);
      setTotalUpvotes(totalUpvotes + 1);
    }

    if (upvote === 0 && isSeriesLiked) {
      setIsSeriesLiked(false);
      setTotalUpvotes(totalUpvotes - 1);
    }
  }, [readyToCallUpvoteApi, upvote]);

  const upvoteApiCallHandler = useCallback(
    debounce(async (seriesId, upvote) => {
      const obj = {
        upvote,
      };

      await api.post(`series/${seriesId}/upvote-series`, obj);
    }, 1000),
    []
  );
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  });
  useEffect(() => {
    if (user && user.id) {
      dispatch(getSingleSeries(seriesId));
    } else {
      dispatch(getGuestSingleSeries(seriesId));
    }
  }, [seriesId, user]);

  useEffect(async () => {
    if (seriesInfo?.id) {
      setUpvote(seriesInfo?.myUpvote?.upvote || null);
      setTotalUpvotes(seriesInfo?.upvotes || 0);

      if (seriesInfo?.myUpvote?.upvote) {
        setIsSeriesLiked(true);
      }
    }
  }, [seriesInfo, user]);

  // useEffect(() => {
  //   if (getSeriesError) {
  //     toast.error(getSeriesError);
  //     console.log(getSeriesError, "aksbdf");
  //   }
  // }, [getSeriesError]);

  const upvoteHandler = () => {
    if (!user?.id) {
      return toast.error(notificationMessage.loginRequired);
    }

    setShowFinalAnimation(true);

    if (upvote < 3) {
      setUpvote(upvote + 1);
    } else {
      setUpvote(0);
    }

    setReadyToCallUpvoteApi(true);
  };

  const redirectToLogin = () => {
    toast.error("Please login or create an account to access this feature.");
    setShowAuthenticationModel(true);
  };

  const URL_REGEX = /(https?:\/\/[^\s]+)/g;
  function CheckHyperLinks(description) {
    const desc = description.replace(/\n/g, " <br/> ");
    const words = desc.split(" ");
    const results = [];
    words.forEach((index) => {
      if (index.match(URL_REGEX)) {
        results.push(
          `<a href=${index} target="_blank" style="color:#4b4bbb">${index}</a>`
        );
      } else {
        results.push(`${index}`);
      }
    });
    const mainstring = results.toString();
    return mainstring.replace(/\,/g, " ");
  }
  const isBadgeOrAvatar = () => {
    if (seriesVideos && seriesVideos.length > 0) {
      const videoToPlay = seriesVideos.find(
        (item) => item?.singleDetailedVideo
      );
      const badge =
        videoToPlay.singleDetailedVideo.userSettings.VisitorBadge.imgPath;
      if (seriesInfo?.userInfo.avatar === null) {
        return (
          <div className="">
            <img className="uploadedImg" src={badge} alt="" />
            {console.log(badge)}
          </div>
        );
      }
    }

    const image = seriesInfo?.userInfo.avatar;
    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <div className="">
          <img className="uploadedImg" src={image} alt="" />
        </div>
      );
    } else {
      return (
        <img
          className="uploadedImg"
          src={config.aws.CLOUDFRONT_URL + image}
          alt=""
        />
      );
    }
  };

  if (getSeriesVideosLoading || (!seriesInfo && !seriesInfo?.id)) {
    return <Skeleton />;
  }

  return (
    <>
      <SeriesDetailsWrapper>
        <div className="hero">
          <img
            onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
            src={config.aws.CLOUDFRONT_URL + seriesInfo?.thumbnail}
            alt=""
            className="hero__banner"
          />
          <div className="hero__overlay" />

          <Row
            style={{
              paddingLeft: "5px !important",
              paddingRight: "5px !important",
              minHeight: "18rem",
              maxHeight: "auto",
            }}
          >
            <Col lg={8} md={12}>
              <div className="hero__courseInfo">
                <h1 className="title">{seriesInfo?.title}</h1>
                <div className="author">
                  <Link
                    className="uploadedImg"
                    to={`/channel/${seriesInfo?.userInfo.username}`}
                  >
                    {isBadgeOrAvatar()}
                  </Link>
                  <Link
                    to={`/channel/${seriesInfo?.userInfo.username}`}
                    style={{ marginLeft: "-7px" }}
                  >
                    {seriesInfo?.userInfo?.username}
                  </Link>
                </div>

                {seriesInfo?.price ? (
                  <p className="price">
                    ${parseFloat(seriesInfo.price).toFixed(2)}
                  </p>
                ) : (
                  <p className="price">FREE</p>
                )}

                {seriesInfo?.description && (
                  <p
                    className="excerpt"
                    dangerouslySetInnerHTML={{
                      __html: CheckHyperLinks(seriesInfo.description),
                    }}
                  />
                )}

                <div className="row">
                  <div className="item">
                    <Upvote
                      upvote={upvote}
                      upvoteHandler={() => {
                        if (!user?.id) {
                          redirectToLogin();
                          return;
                        }

                        upvoteHandler();
                      }}
                      total={totalUpvotes}
                      showFinalAnimation={showFinalAnimation}
                    />
                  </div>

                  <div className="item">
                    <Button
                      onClick={() => {
                        if (!user?.id) {
                          redirectToLogin();
                          return;
                        }

                        if (!seriesInfo?.isPurchased) {
                          setShowPurchaseSeriesModel(true);
                          return;
                        }

                        history.push(
                          `/series/watch/${seriesInfo.id}/${seriesVideos[0]?.singleDetailedVideo?.id}`
                        );
                      }}
                      className="purchaseBtn"
                    >
                      {seriesInfo?.isPurchased
                        ? "Start Series"
                        : "Purchase Series"}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="hero__playBox">
                <div
                  onClick={() => {
                    setShowIntroModel(true);
                    setPlayingVideoUrl(
                      `/series/preview/${seriesInfo.id}/${seriesVideos[0]?.singleDetailedVideo?.id}`
                    );
                  }}
                  className="playBtn"
                >
                  <img src="/assets/icons/play.svg" alt="Play Video" />
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {seriesVideos && seriesVideos.length > 0 && (
          <div className="content">
            <h5 className="content__title">Tables of content</h5>
            <div className="content__videos">
              {seriesVideos.map((item, i) => {
                return (
                  <div
                    onClick={() => {
                      if (!user?.id) {
                        redirectToLogin();
                        return;
                      }
                      if (seriesInfo?.isPurchased) {
                        history.push(
                          `/series/watch/${seriesInfo.id}/${item?.singleDetailedVideo?.id}`
                        );
                      }
                      if (i === 0 || seriesInfo?.isPurchased) {
                        setShowIntroModel(true);
                        setPlayingVideoUrl(
                          `/series/preview/${seriesInfo.id}/${seriesVideos[0]?.singleDetailedVideo?.id}`
                        );
                        // history.push(
                        //   `/series/watch/${seriesInfo.id}/${item?.singleDetailedVideo?.id}`
                        // );
                      } else {
                        toast.error(notificationMessage.notPurchasedSeries);
                      }
                    }}
                    key={i}
                    className={`content__videos--item`}
                  >
                    {i !== 0 && !seriesInfo?.isPurchased ? (
                      <LockIcon className="lockIcon" />
                    ) : (
                      <div className="heros__playBoxe">
                        <div className="playBtn">
                          <img
                            src="/assets/icons/play.svg"
                            alt="Play Video"
                            style={{ width: "1rem", marginLeft: "4px" }}
                          />
                        </div>
                      </div>
                    )}

                    <img
                      src={
                        config.aws.CLOUDFRONT_URL +
                        item?.singleDetailedVideo?.thumbnail
                      }
                      alt={item?.singleDetailedVideo?.title}
                      className="thumbnail"
                      onError={(e) =>
                        addDefaultImage(e, ImageNotfoundPlaceholder)
                      }
                    />

                    <div className="metaInfo">
                      <h3 className="metaInfo__title">
                        {item?.singleDetailedVideo?.title.length > 100
                          ? item?.singleDetailedVideo?.title.substring(0, 100) +
                            "..."
                          : item?.singleDetailedVideo?.title}
                      </h3>

                      {item?.singleDetailedVideo?.videoLength && (
                        <span className="metaInfo__duration">
                          <img
                            src={TimeIcon}
                            alt=""
                            className="metaInfo__duration--icon"
                          />{" "}
                          {toHHMMSS(item.singleDetailedVideo.videoLength)}
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <Helmet>
          {/* for google */}
          <title>{`${seriesInfo?.userInfo?.username}: ${seriesInfo?.title}`}</title>
          <meta
            name="title"
            content={`${seriesInfo?.userInfo?.username}: ${seriesInfo?.title}`}
          />
          <meta name="description" content={seriesInfo.description} />

          {/* for facebook */}
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={`${seriesInfo?.userInfo?.username}: ${seriesInfo?.title}`}
          />
          <meta property="og:description" content={seriesInfo.description} />
          <meta
            property="og:image"
            Content={config.aws.CLOUDFRONT_URL + seriesInfo?.thumbnail}
          />

          {/* for twitter */}
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:card"
            content={`${seriesInfo?.userInfo?.username}: ${seriesInfo?.title}`}
          />
          <meta
            property="twitter:description"
            content={seriesInfo.description}
          />
          <meta
            property="twitter:image"
            Content={config.aws.CLOUDFRONT_URL + seriesInfo?.thumbnail}
          />
        </Helmet>
      </SeriesDetailsWrapper>

      <ReactModel
        channel={"custom"}
        url={playingVideoUrl}
        // url={seriesVideos[0]?.singleDetailedVideo?.url}
        isOpen={showIntroModel}
        onClose={() => setShowIntroModel(false)}
      />

      {showPurchaseSeriesModel && (
        <PurchaseSeriesModel
          closeModel={() => setShowPurchaseSeriesModel(false)}
          price={(+seriesInfo.price).toFixed(2)}
          seriesId={seriesInfo.id}
          creatorId={seriesInfo.userId}
          videos={seriesVideos}
        />
      )}

      {/* rendering footer */}
      <ContactBanner />
      <Footer />
    </>
  );
};

export default SeriesDetails;

const ripple = keyframes`
  0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
`;

const SeriesDetailsWrapper = styled.div`
  .hyper-link {
    color: #4b4bbb;
  }
  .heros {
    position: relative;
    padding: 2rem 4rem;
    width: 100%;
    margin-bottom: 2rem;

    &__playBoxe {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: relative;

      .playBtn {
        margin-right: 1rem;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 30px;
        height: 30px;
        background: linear-gradient(44.44deg, #ed2775 7.79%, #ff7448 94.18%);
        border-radius: 50%;

        cursor: pointer;

        imagePlaybtn {
          width: 1rem;
        }
      }
    }
  }
  .hero {
    position: relative;
    padding: 2rem 4rem;
    width: 100%;
    margin-bottom: 2rem;

    &__banner {
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center;
      object-fit: cover;
      height: 100%;
      width: 100%;
      z-index: -1;
    }

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.3);
    }

    &__courseInfo {
      height: 100%;
      border-radius: 1rem;
      width: 100%;
      background-color: rgba(34, 34, 34, 0.7);
      padding: 1.5rem;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.2;
      }

      .author,
      .duration {
        color: rgba(255, 255, 255, 0.6);
        font-size: 0.9rem;
      }

      .author {
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .duration {
        margin-bottom: 0.5rem;
      }

      .row {
        display: flex;
        align-items: center;
        padding-top: 1rem;

        .item {
          &:not(:last-child) {
            margin-right: 2rem;
          }
        }
      }
      .uploadedImg {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        margin-right: 1rem;
      }
      .imageBadge {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .badge {
          height: 38px;
          width: auto;
        }
      }
      .ratingBox {
        margin-bottom: 0.5rem;

        .notRated {
          background: -webkit-linear-gradient(#ff4883, #fdb769);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 0.85rem;
          font-weight: 500;
          text-transform: uppercase;
        }

        .rating {
          display: flex;
          align-items: center;

          &__num {
            font-size: 1.1rem;
            font-weight: 600;
            background: -webkit-linear-gradient(#ff4883, #fdb769);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-right: 0.5rem;
          }

          &__stars {
            i {
              font-size: 0.9rem;
              margin-right: 0.1rem;
              background: -webkit-linear-gradient(#ff4883, #fdb769);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          &__people {
            font-size: 0.9rem;
            color: rgba(255, 225, 225, 0.4);
            font-weight: 200;
            padding: 0 0.4rem;
          }
        }
      }

      .price {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 1rem;
        line-height: 1;
      }

      .excerpt {
        color: rgba(255, 255, 255, 0.9);
        font-size: 1rem;
        line-height: 1.6;
        margin-bottom: 1rem;
      }
    }

    &__playBox {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: relative;

      .playBtn {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5rem;
        height: 5rem;
        z-index: 2;
        -webkit-transform: translateY(-50%) translateX(-50%);
        transform: translateY(-50%) translateX(-50%);
        background: linear-gradient(44.44deg, #ed2775 7.79%, #ff7448 94.18%);
        border-radius: 50%;
        color: #fff;
        -webkit-box-shadow: 15px 25px 35px rgba(38, 42, 55, 0.2);
        box-shadow: 15px 25px 35px rgba(38, 42, 55, 0.2);
        cursor: pointer;

        &::after,
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          z-index: -1;
          bottom: 0;
          left: 0;
          border-radius: 50%;
          border: 1px solid #fff;
        }

        &::before {
          -webkit-animation: ${ripple} 2s linear infinite;
          animation: ${ripple} 2s linear infinite;
        }

        &::after {
          -webkit-animation: ${ripple} 2s linear 1s infinite;
          animation: ${ripple} 2s linear 1s infinite;
        }

        img {
          height: 1.8rem;
          width: 1.8rem;
        }
      }
    }
  }

  .content {
    width: 60%;
    margin: 0 auto;
    background-color: #222;
    border-radius: 0.5rem;
    margin-bottom: 2rem;

    &__title {
      padding: 1rem;
      font-size: 1.4rem;
      font-weight: 400;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    &__videos {
      padding: 1rem 0;
      max-height: 35rem;
      overflow: auto;

      .lockIcon {
        margin-right: 1rem;
      }

      &--item:not(:last-child) {
        margin-bottom: 2rem;
      }

      &--item {
        padding: 0 1.5rem;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        .thumbnail {
          height: auto;
          width: 7rem;
          margin-right: 1rem;
          background-size: cover;
          background-position: center;
          object-fit: cover;
          border-radius: 5px;
        }

        .metaInfo {
          &__title {
            font-size: 1.05rem;
            line-height: 1.3;
            margin-bottom: 0.5rem;
            font-weight: 300;
          }

          &__duration {
            font-size: 0.8rem;
            color: ${(props) => props.theme.secondaryColor};
            font-weight: 200;
            display: flex;
            align-items: center;

            &--icon {
              height: 1rem;
              width: auto;
              margin-right: 0.5rem;
            }
          }
        }
      }

      /* scrollbar */
      /* width */
      ::-webkit-scrollbar {
        width: 8px;
        border-radius: 10rem;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.7);
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #333;
        border-radius: 10rem;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: rgb(246, 92, 139);
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .hero {
      padding: 2rem;

      &__banner {
        margin-bottom: 3rem;
      }

      &__playBox {
        height: 8rem;
      }
    }

    .content {
      width: 80%;
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;
    }
  }

  @media screen and (max-width: 480px) {
    .hero {
      &__courseInfo {
        padding: 1rem;

        .title {
          font-size: 1.7rem;
        }

        .author {
          font-size: 0.8rem;
        }

        .ratingBox {
          .rating {
            &__num {
              font-size: 1rem;
            }
          }
        }

        .price {
          font-size: 1.2rem;
        }

        .excerpt {
          font-size: 0.9rem;
          margin-bottom: 1rem;
        }
      }
    }

    .content {
      width: 95%;

      &__title {
        font-size: 1.2rem;
      }

      &__videos {
        &--item {
          .thumbnail {
            width: 5.5rem;
          }

          .metaInfo {
            &__title {
              font-size: 0.8rem;
            }

            &__duration {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
`;
