import {
  CREATE_PROFILE_ELEMENT_REQUEST,
  CREATE_PROFILE_ELEMENT_SUCCESS,
  CREATE_PROFILE_ELEMENT_FAIL,
  DELETE_PROFILE_ELEMENT_REQUEST,
  DELETE_PROFILE_ELEMENT_SUCCESS,
  DELETE_PROFILE_ELEMENT_FAIL,
  REMOVE_CONTENT_FROM_ELEMENT,
  ADD_CONTENT_TO_ELEMENT,
  RESET_PROFILE_ELEMENT,
} from "../actions/types";

const initialState = {};

const profileElement = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROFILE_ELEMENT_REQUEST:
      return {
        createLoading: true,
      };
    case CREATE_PROFILE_ELEMENT_SUCCESS:
      return {
        createLoading: false,
        createData: action.payload,
      };
    case CREATE_PROFILE_ELEMENT_FAIL:
      return {
        createLoading: false,
        createError: action.payload,
      };

    case DELETE_PROFILE_ELEMENT_REQUEST:
      return {
        deleteLoading: true,
      };
    case DELETE_PROFILE_ELEMENT_SUCCESS:
      return {
        deleteLoading: false,
        deleteMessage: action.payload,
      };
    case DELETE_PROFILE_ELEMENT_FAIL:
      return {
        deleteLoading: false,
        deleteError: action.payload,
      };
    case REMOVE_CONTENT_FROM_ELEMENT:
      return {
        removeContentMessage: action.payload,
      };
    case ADD_CONTENT_TO_ELEMENT:
      return {
        addContentMessage: action.payload,
      };
    case RESET_PROFILE_ELEMENT:
      return {};
    default:
      return state;
  }
};

export default profileElement;
