import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AdminSidebar from "../AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { IoTrashSharp } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import Pagination from "../Pagination";
import ConfirmationModel from "../../components/ConfirmationModel";
import {
  adminThreadImagesData,
  deleteAdminThreadImagesData,
} from "../../actions";
import config from "../../config";
import ThreadImagesEdit from "./ThreadImagesEdit";
const ThreadsImages = ({ close, midDrag }) => {
  const threadImgData = useSelector(
    (state) => state.threadsTable.threadImagesTableData
  );

  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [thredsImgData, setThreadsImgData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    dispatch(adminThreadImagesData());
  }, [dispatch]);

  // serching------------------
  const filterData = () => {
    return threadImgData
      ? threadImgData.filter((row) => {
          const lowerSearch = search.toLowerCase();
          return (
            (row.url && row.url.toLowerCase().includes(lowerSearch)) ||
            (row.threadId &&
              row.threadId.toLowerCase().includes(lowerSearch)) ||
            (row.id && row.id.toString().toLowerCase().includes(lowerSearch)) ||
            (row.fileSize &&
              row.fileSize.toString().toLowerCase().includes(lowerSearch))
          );
        })
      : [];
  };

  // Pagination -------------
  const filteredData = filterData();
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // //   Edit ------------------------
  const handleEdit = (row) => {
    setIsEditing(true);
    setThreadsImgData({
      id: row.id,
      url: row.url,
      threadId: row.threadId,
      order: row.order,
      fileSize: row.fileSize,
      createdAt: row.createdAt,
      updatedAt: row.updatedAt,
    });
  };
  const handleFormChange = (field, value) => {
    setThreadsImgData({ ...thredsImgData, [field]: value });
  };

  // //   Delete-------------------
  const handleDelete = (row) => {
    setShowDeleteConfirmation(true);
    setThreadsImgData({ id: row.id });
  };
  const deleteVideoHandler = async () => {
    try {
      await dispatch(deleteAdminThreadImagesData(thredsImgData.id));
      setShowDeleteConfirmation(false);
      await dispatch(adminThreadImagesData());
    } catch (error) {
      console.error("Error deleting threads Images ", error);
    }
  };

  return (
    <>
      <ThreadsImagesDashboard>
        <AdminSidebar />
        <TableContainer component={Paper} className="tableContainer">
          <div>
            <h1 className="headingText">Search Type Of Thread Images </h1>
            <input
              type="text"
              placeholder="Search The  Thread Images"
              className="searchBox"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell className="tableHeader">SR.No</TableCell>
                <TableCell className="tableHeader">Id</TableCell>
                <TableCell className="tableHeader">url</TableCell>
                <TableCell className="tableHeader">threadId</TableCell>
                <TableCell className="tableHeader">order</TableCell>
                <TableCell className="tableHeader">fileSize</TableCell>
                <TableCell className="tableHeader">createdAt</TableCell>
                <TableCell className="tableHeader">updatedAt</TableCell>
                <TableCell className="tableHeader">Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.slice(startIndex, endIndex).map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell className="tableCell">
                    {startIndex + index + 1}
                  </TableCell>
                  <TableCell className="tableCell">{row.id}</TableCell>
                  <TableCell className="tableCellWordWrap">
                    {row.url && (
                      <img
                        src={
                          row.url.includes("/assets/")
                            ? row.url
                            : row.url.includes("/")
                            ? `${config.aws.CLOUDFRONT_URL + row.url}`
                            : `${config.aws.CLOUDFRONT_URL + row.url}`
                        }
                        alt={row.url}
                        style={{ maxHeight: "100px" }}
                      />
                    )}
                  </TableCell>
                  <TableCell className="tableCell">{row.threadId}</TableCell>
                  <TableCell className="tableCell">{row.order}</TableCell>
                  <TableCell className="tableCell">{row.fileSize}</TableCell>
                  <TableCell className="tableCell">{row.createdAt}</TableCell>
                  <TableCell className="tableCell">{row.updatedAt}</TableCell>

                  <TableCell className="tableCell">
                    <FiEdit
                      style={{ margin: "8px" }}
                      className="editButton"
                      size={28}
                      onClick={() => handleEdit(row)}
                    />
                    <IoTrashSharp
                      style={{ margin: "8px" }}
                      className="deleteButton"
                      size={28}
                      onClick={() => handleDelete(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div style={{ marginTop: "7px", marginBottom: "18%" }}>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={threadImgData ? threadImgData.length : 0}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </TableContainer>
      </ThreadsImagesDashboard>
      {isEditing && (
        <ThreadImagesEdit
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          thredsImgData={thredsImgData}
          onChange={handleFormChange}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationModel
          closeHandler={() => setShowDeleteConfirmation(false)}
          title="Are you sure you want to delete this threads images  ? You can't undo this action."
          deleteHandler={deleteVideoHandler}
        />
      )}
    </>
  );
};

export default ThreadsImages;

const ThreadsImagesDashboard = styled.div`
  display: flex;
  .addData {
    margin-bottom: 10px;
  }
  .tableContainer {
    max-width: 100%;
    height: 100vh;
    background: #212123;
    padding-left: 2%;
    padding-right: 2%;
  }
  .table {
    background: #212123;
  }
  .tableHeader {
    background: #212123;
    color: white;
    border: 1px solid;
    font-size: 13px;
  }
  .tableCell {
    color: white;
    border: 1px solid;
    font-size: 13px;
  }
  .tableCellWordWrap {
    color: white;
    border: 1px solid;
    font-size: 13px;
    word-break: break-all;
  }
  .editButton {
    color: #4f92f7;
    marginleft: 10px;
    transition: transform 0.2s;
  }
  .editButton:hover {
    transform: scale(1.3);
  }

  .deleteButton {
    color: #f54e4e;
    marginleft: 10px;
    transition: transform 0.2s;
  }
  .deleteButton:hover {
    transform: scale(1.3);
  }

  .headingText {
    font-size: 18px;
    margin-top: 10px;
    color: white;
  }
  .searchBox {
    background: #2c2c2e !important;
    color: white;
    margin-bottom: 2%;
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: #cdc5c5;
    }
  }

  /* Responsive styling for the search box */
  @media (max-width: 768px) {
    .SearchBox {
      width: 100%;
    }
  }
`;
