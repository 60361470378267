import React, { useContext, useState, useEffect, createRef } from "react";
import styled from "styled-components";
import BackIcon from "../../assets/Icons/back.svg";
import Slider from "react-slick";
import ChooseCoverImage from "./ChooseCoverImage";
import TagPeople from "./TagPeople";
import PlusIcon from "../../assets/Icons/plus.svg";
import TagPeopleIcon from "../../assets/Icons/tag.svg";
import CaretRightIcon from "../../assets/Icons/caret-right.svg";
import UploadIcon from "../../assets/Icons/upload-icon-white.svg";
import path from "path";
import ReactTags from "react-tag-autocomplete";
import { Row, Col } from "react-grid-system";
import { GlobalContext } from "../../context/GlobalContext";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { upload, uploadCover } from "../../utils";
import { Video } from "video-metadata-thumbnails";
import {
  uploadMoment,
  getHashtags,
  getAllFriends,
  getTaggedUsersInMoment,
  getVideoCategories,
} from "../../actions";
import { notificationMessage } from "../../utils/toastNotifications";
import VideoCategory from "../UploadClipComponent/VideoCategory";
import config from "../../config";

const SliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  autoPlay: false,
  slidesToShow: 6,
  slidesToScroll: 2,
  arrows: false,

  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },

    {
      breakpoint: 481,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
  ],
};

const UploadMomentForm = ({ close }) => {
  const reactTags = createRef();
  const dispatch = useDispatch();
  const videoHashtags = useSelector((state) => state.video.videoHashtags);
  const videoCategories = useSelector((state) => state.video.videoCategories);
  const { uploadLoading, uploadError, moment } = useSelector(
    (state) => state.moment
  );
  const { message: momentTagMessage } = useSelector((state) => state.momentTag);

  const {
    showUploadMomentModel,
    setShowUploadMomentModel,
    setShowUploadModel,
    setNewCategoryRequestModel,
  } = useContext(GlobalContext);
  const [show, setShow] = useState("FORM"); // FORM, COVER, TAG_PEOPLE
  const [title, setTitle] = useState("Upload your Moment");

  // form fields
  const [caption, setCaption] = useState("");
  const [whoCanWatch, setWhoCanWatch] = useState("0");
  const [whoCanMessage, setWhoCanMessage] = useState("0");
  const [videoSize, setVideoSize] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [cover, setCover] = useState({
    url: null,
    id: null,
  });
  const [hashTags, sethashTags] = useState([]);
  const [momentDuration, setMomentDuration] = useState(null);
  const [file, setFile] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [defaultCategories, setDefaultCategories] = useState([]);
  const [muxid, setMuxid] = useState("");

  useEffect(async () => {
    if (file && momentDuration && momentDuration <= 60) {
      // upload the moment file

      if (file) {
        setVideoSize(file.size);
        const size = file.size / 1000000;

        if (size > 10000) {
          setShowUploadMomentModel(false);
          return toast.error(notificationMessage.fileSizeLimit);
        }

        const data = await upload("video", file);
        setVideoUrl(data.url);
        setMuxid(data.muxid);

        const ext = path.extname(data.url);
        if (!!cover.url) {
          setCover({
            url: cover.url,
          });
        } else {
          const video = new Video(file);
          autoGenerateThumbnail(video, file, ext);
        }
      }

      setFile(null);
      return;
    }

    if (momentDuration > 60) {
      // show error message about moment duration
      toast.error(notificationMessage.momentTimeLimitError);
      setMomentDuration(null);
      setFile(null);
      return;
    }
  }, [momentDuration, file]);

  useEffect(() => {
    if (showUploadMomentModel) {
      dispatch(getHashtags());
      dispatch(getAllFriends());
      dispatch(getVideoCategories());
    }
  }, [showUploadMomentModel]);

  useEffect(() => {
    if (uploadError) {
      //toast.error("Please add a cover photo to continue uploading!");
      toast.error("Please add a cover photo to continue uploading!");
    }
  }, [uploadError]);

  useEffect(() => {
    if (showUploadMomentModel) {
      dispatch(getTaggedUsersInMoment(moment?.id));
    }
  }, [momentTagMessage]);

  useEffect(async () => {
    if (moment && moment.id) {
      setShow("TAG_PEOPLE");
      toast.success(notificationMessage.tagSomeConnections);

      setCaption("");
      setVideoUrl(null);
      setCover({
        url: null,
        id: null,
      });
      setVideoSize(null);
      setWhoCanMessage("0");
      setWhoCanWatch("0");
      sethashTags([]);
      setMomentDuration(null);
    }
  }, [moment]);

  const autoGenerateThumbnail = (video, file, ext) => {
    try {
      video
        .getThumbnails({
          quality: 1,
          scale: 0.8,
        })
        .then(async (thumbnails) => {
          const blobthird = thumbnails[3].blob;

          const newfile = new File(
            [blobthird],
            file.name.replace(ext, ".jpg"),
            { type: "image/jpeg" }
          );
          const { url } = await uploadCover("image", newfile);
          setCover({
            url,
          });
        });
    } catch (error) {
      throw error;
    }
  };
  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform =
        window.navigator?.userAgentData?.platform || window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (/Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  }

  const handleUploadVideo = async (e) => {
    try {
      const file = e.target.files[0];

      var video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);

        setMomentDuration(video.duration);
      };

      video.src = URL.createObjectURL(file);
      setFile(file);
    } catch (e) {
      toast.dismiss();
      return toast.error(notificationMessage.oopsTryAgain);
    }
  };

  const handleUploadCover = async (e) => {
    try {
      const file = e.target.files[0];

      if (file) {
        const size = file.size / 1000000;

        if (size > 10000) {
          setShowUploadMomentModel(false);
          return toast.error(notificationMessage.fileSizeLimit);
        }

        const data = await uploadCover("image", file);

        setCover({
          url: data.url,
        });
      }
    } catch (e) {
      toast.dismiss();
      return toast.error(notificationMessage.oopsTryAgain);
    }
  };

  const handleUploadMoment = async () => {
    if (!title) {
      toast.error(notificationMessage.provideMomentCaption);
    }

    if (!videoUrl) {
      toast.error(notificationMessage.provideMomentVideo);
    }
    if (getOS() == "iOS") {
      if (!cover.url) {
        return toast.error("Please add a cover photo to continue uploading!");
      }
    }

    if (!selectedCategory || !selectedCategory.length) {
      return toast.error(notificationMessage.categoryNeededError);
    }

    const obj = {
      caption,
      videoUrl,
      coverImgUrl: cover.url,
      fileSize: videoSize,
      whoCanMessage,
      whoCanWatch,
      hashTags,
      categoryList: selectedCategory,
      videoLength: momentDuration,
      muxid,
    };

    dispatch(uploadMoment(obj));
  };
  const resetState = () => {
    setTitle("Upload your Moment");
    setCaption("");
    setWhoCanWatch("0");
    setWhoCanMessage("0");
    setVideoSize(null);
    setVideoUrl(null);
    setCover({
      url: null,
      id: null,
    });
    sethashTags([]);
    setMomentDuration(null);
    setFile(null);
    setSelectedCategory([]);
    setDefaultCategories([]);
    setMuxid("");
  };

  const onDelete = (i) => {
    const selectedHashtags = hashTags.slice(0);
    selectedHashtags.splice(i, 1);
    sethashTags(selectedHashtags);
  };

  const onAddition = (tag) => {
    const selectedHashtags = [].concat(hashTags, tag);
    sethashTags(selectedHashtags);
  };

  const onValidate = (tag) => {
    if (tag.name.length > 140) {
      toast.error(notificationMessage.hashTagLimit);
      return false;
    }

    if (/[^a-zA-Z0-9]/.test(tag.name)) {
      toast.error(notificationMessage.removeInvalidCharacter);
      return false;
    }

    if (hashTags.length > 0) {
      if (hashTags.length >= 20) {
        toast.error(notificationMessage.addHashtagUpto20);
        return false;
      }
      for (var i = 0; i < hashTags.length; i++) {
        if (
          hashTags[i].name.toString().toLowerCase() ===
          tag.name.toString().toLowerCase()
        ) {
          toast.error(notificationMessage.videoHashtag);
          return false;
        }
      }
    }
    return true;
  };

  return (
    <UploadMomentFormStyled
      className={`${showUploadMomentModel && "ShowUploadMomentForm"}`}
    >
      <div className="header">
        <div
          onClick={() => {
            if (show === "FORM") {
              close();
            }
            if (show === "COVER" || "TAG_PEOPLE") {
              setShow("FORM");
              setTitle("Share your Moment");
            }
          }}
          className="close"
        >
          <img src={BackIcon} alt="" />
        </div>

        <div className="title">{title}</div>

        {show === "TAG_PEOPLE" ? (
          <div
            onClick={() => {
              close();
              setShowUploadModel(false);
              resetState();
              setShow("FORM");
              toast.success(notificationMessage.momentPosted);
            }}
            className="button"
          >
            complete
          </div>
        ) : (
          <div
            onClick={() => {
              handleUploadMoment();
            }}
            className="button"
          >
            {uploadLoading ? "saving..." : "save"}
          </div>
        )}
      </div>

      {show === "FORM" && (
        <div className="form">
          <Row className="form__row">
            <Col md={4} sm={4} xs={4} className="form__item">
              {cover.url ? (
                <div className="showCover">
                  <div className="title">Cover</div>

                  <label htmlFor="uploadCover">
                    <div className="box">
                      <img
                        className="image"
                        src={config.aws.CLOUDFRONT_URL + cover.url}
                        alt=""
                      />

                      <div className="button">Choose Another</div>
                    </div>
                  </label>
                </div>
              ) : (
                <label htmlFor="uploadCover" className="form__left">
                  <div className="form__title">Cover</div>
                  <div className="form__box cover">
                    <img src={PlusIcon} alt="" className="icon" />
                  </div>
                </label>
              )}

              <input
                style={{ display: "none" }}
                id="uploadCover"
                type="file"
                accept="image/*"
                onChange={handleUploadCover}
              />
            </Col>
            <Col md={8} sm={8} xs={8} className="form__item">
              <div className="form__right">
                <label htmlFor="uploadMoment" className="form__card">
                  <div className="left">
                    <img src={UploadIcon} alt="" className="icon" /> Upload
                    Moment
                  </div>
                  <div className="right">
                    <img src={CaretRightIcon} alt="" className="icon" />
                  </div>
                </label>

                <input
                  style={{ display: "none" }}
                  id="uploadMoment"
                  type="file"
                  accept="video/*"
                  onChange={handleUploadVideo}
                />

                <div
                  onClick={() => {
                    if (!moment) {
                      return toast.error(notificationMessage.firstSaveMoment);
                    }

                    setTitle("Tag some People");
                    setShow("TAG_PEOPLE");
                  }}
                  className="form__card"
                >
                  <div className="left">
                    <img src={TagPeopleIcon} alt="" className="icon" />
                    Tag people
                  </div>
                  <div className="right">
                    <img src={CaretRightIcon} alt="" className="icon" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="form__description">
            <div className="title">Caption your Moment</div>
            <textarea
              name="desc"
              id="desc"
              placeholder="Describe this Moment"
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
            ></textarea>
          </div>

          <div className="form__hashtags">
            <div className="title">Enter Hashtags</div>

            <ReactTags
              ref={reactTags}
              tags={hashTags}
              suggestions={videoHashtags}
              onDelete={onDelete.bind(this)}
              onAddition={onAddition.bind(this)}
              onValidate={onValidate.bind(this)}
              placeholderText="Enter new hashtag"
              allowNew={true}
            />
          </div>

          <div className="form__categories">
            <div className="title">Choose category</div>
            <VideoCategory
              videoCategories={videoCategories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              defaultCategories={defaultCategories}
            />

            <button
              onClick={() => setNewCategoryRequestModel(true)}
              className="requestNewCategory"
            >
              Request New Category
            </button>
          </div>

          <div className="options">
            <div className="options__slider">
              <div className="options__slider--title">
                Who can watch this Moment ?
              </div>

              <Slider
                {...SliderSettings}
                className="options__slider--listSlider"
              >
                <div className={`item ${whoCanWatch === "0" && "active"}`}>
                  <button
                    onClick={() => setWhoCanWatch("0")}
                    className="optionBtn"
                  >
                    Everyone
                  </button>
                </div>

                <div className={`item ${whoCanWatch === "1" && "active"}`}>
                  <button
                    onClick={() => setWhoCanWatch("1")}
                    className="optionBtn"
                  >
                    Connections
                  </button>
                </div>

                <div className={`item ${whoCanWatch === "2" && "active"}`}>
                  <button
                    onClick={() => setWhoCanWatch("2")}
                    className="optionBtn"
                  >
                    Only Me
                  </button>
                </div>
              </Slider>
            </div>

            <div className="options__slider">
              <div className="options__slider--title">
                Who can message on this Moment ?
              </div>

              <Slider
                {...SliderSettings}
                className="options__slider--listSlider"
              >
                <div className={`item ${whoCanMessage === "0" && "active"}`}>
                  <button
                    onClick={() => setWhoCanMessage("0")}
                    className="optionBtn"
                  >
                    Everyone
                  </button>
                </div>

                <div className={`item ${whoCanMessage === "1" && "active"}`}>
                  <button
                    onClick={() => setWhoCanMessage("1")}
                    className="optionBtn"
                  >
                    Connections
                  </button>
                </div>

                <div className={`item ${whoCanMessage === "2" && "active"}`}>
                  <button
                    onClick={() => setWhoCanMessage("2")}
                    className="optionBtn"
                  >
                    Only Me
                  </button>
                </div>
              </Slider>
            </div>
          </div>

          <div className="form__submit">
            <button onClick={handleUploadMoment}>
              {uploadLoading ? "Creating Moment..." : "Post Moment"}
            </button>
          </div>
        </div>
      )}

      {show === "COVER" && <ChooseCoverImage />}

      {show === "TAG_PEOPLE" && <TagPeople momentId={moment.id} />}
    </UploadMomentFormStyled>
  );
};

export default UploadMomentForm;

const UploadMomentFormStyled = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #1c1c1e;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 901;
  opacity: 0;
  transform: translateX(-100%);
  transition: all 1s ease;
  font-family: ${(props) => props.theme.montserrat};

  .loading,
  .error {
    font-family: brother-1816, sans-serif;
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  /* mui styles */
  .MuiInputBase-root {
    width: 100%;
    background-color: #3a3a3c;
    color: #f2f2f7;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    font-size: 1rem;
    font-weight: 300;
    color: #f2f2f7;
    font-family: ${(props) => props.theme.montserrat};
    transition: all 0.25s ease;
    border: 3px solid transparent;

    &::placeholder {
      font-weight: 300;
      color: #f2f2f7;
      letter-spacing: 0.3px;
    }

    &:focus,
    &:hover {
      border: 3px solid #f88946 !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    display: none !important;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 5rem;
    border-bottom: 1px solid rgba(112, 112, 112, 0.4);
    margin-bottom: 2rem;
    background-color: #1c1c1e;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 2rem;
        height: auto;
        cursor: pointer;
      }
    }

    .title {
      font-size: 1.2rem;
      font-weight: 400;
      text-transform: capitalize;
    }

    .button {
      border: 2px solid #fff;
      padding: 0.2rem 1.5rem;
      border-radius: 10rem;
      font-size: 1rem;
      background-color: transparent;
      color: #fff;
      font-family: ${(props) => props.theme.montserrat};
      font-weight: 400;
      text-transform: capitalize;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }

  .form {
    width: 80%;
    margin: 0 auto 5rem auto;

    &__title {
      padding: 0 0 0.5rem 1rem;
      font-size: 1.05rem;
      font-weight: 500;
      text-transform: capitalize;
      color: #f2f2f7;
    }
    &__box {
      background-color: #3a3a3c;
      border-radius: 0.5rem;
    }

    .cover {
      height: 28rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;

      .icon {
        height: 2rem;
        width: auto;
      }
    }

    .showCover {
      .title {
        padding: 0 0 0.5rem 1rem;
        font-size: 1.05rem;
        font-weight: 500;
        text-transform: capitalize;
        color: #f2f2f7;
      }

      .box {
        height: 28rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        background-color: #3a3a3c;
        border-radius: 0.5rem;
        overflow: hidden;

        .image {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center;
          object-fit: cover;
        }

        .button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 70%;
          z-index: 2;
          cursor: pointer;
          line-height: 1;
          font-family: brother-1816, sans-serif;
          padding: 0.4rem 0;
          text-transform: uppercase;
          font-size: 0.9rem;
          letter-spacing: 2px;
          font-weight: 500;
          margin: 0 !important;
          text-align: center;
          background: transparent
            linear-gradient(
              130deg,
              var(--profile-icon-bg) 14%,
              #f88946 23%,
              #f8795f 37%,
              #f75e87 55%,
              #f75b8c 57%
            )
            0% 0% no-repeat padding-box;
          background: transparent
            linear-gradient(
              130deg,
              #f9903d 14%,
              #f88946 23%,
              #f8795f 37%,
              #f75e87 55%,
              #f75b8c 57%
            )
            0% 0% no-repeat padding-box;
        }
      }
    }

    &__right {
      margin-top: 3rem;
    }

    &__card {
      background-color: #3a3a3c;
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.8rem 1.5rem;
      margin-bottom: 1.5rem;
      font-size: 0.95rem;
      text-transform: capitalize;
      font-weight: 400;
      color: #f2f2f7;
      cursor: pointer;

      .left {
        display: flex;
        align-items: center;

        .icon {
          margin-right: 0.8rem;
          height: 1.4rem;
          width: auto;
        }
      }

      .right {
        .icon {
          width: auto;
          height: 1rem;
        }
      }
    }

    &__row {
      margin-bottom: 2rem;
    }

    &__description,
    &__categories,
    &__hashtags {
      margin-bottom: 2rem;

      .title {
        padding: 0 0 0.5rem 1rem;
        font-size: 1.05rem;
        font-weight: 500;
        color: #f2f2f7;
      }

      .requestNewCategory {
        cursor: pointer;
        line-height: 1;
        font-family: brother-1816, sans-serif;
        padding: 0.4rem 1rem;
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 1px;
        font-weight: 500;
        color: #fff;
        border: none;
        outline: none;
        margin-top: 1rem;
        background: ${(props) => props.theme.primaryGradient};
        border-radius: 3px;
      }
    }

    input,
    textarea,
    select {
      width: 100%;
      padding: 1rem;
      background-color: #3a3a3c;
      color: #f2f2f7;
      border-radius: 0.5rem;
      border: none;
      outline: none;
      font-size: 1rem;
      font-weight: 300;
      color: #f2f2f7;
      font-family: ${(props) => props.theme.montserrat};
      transition: all 0.25s ease;
      border: 3px solid transparent;

      &::placeholder {
        font-weight: 300;
        color: #f2f2f7;
        letter-spacing: 0.3px;
      }

      &:focus {
        border: 3px solid #f88946;
      }
    }

    textarea {
      resize: none;
      height: 15rem;
    }

    &__submit {
      text-align: center;
      width: 100%;

      button {
        font-family: ${(props) => props.theme.montserrat};
        font-weight: 600;
        font-size: 1rem;
        padding: 1rem 4rem;
        border-radius: 10rem;
        background-color: #fff;
        color: #f88946;
        text-transform: uppercase;
        border: none;
        outline: none;
        transition: all 0.5s ease;
        letter-spacing: 2px;

        &:hover {
          background-color: #f88946;
          color: #fff;
          letter-spacing: 0;
        }
      }
    }
  }

  .options {
    &__slider {
      padding: 0.5rem 0;
      border-bottom: 1px solid rgba(99, 99, 102, 0.5);
      margin-bottom: 2rem;

      &--title {
        font-weight: 600;
        color: #c7c7cc;
        font-size: 1.05rem;
        text-transform: capitalize;
        margin-bottom: 0.7rem;
      }

      &--list {
        display: flex;
        align-items: center;

        .active .optionBtn {
          color: #1c1c1e !important;
          background-color: #f2f2f7 !important;
          font-weight: 600 !important;
        }

        .item {
          margin-right: 1rem;

          .optionBtn {
            width: 100%;
            padding: 0.7rem 1.5rem;
            border-radius: 0.5rem;
            background-color: #636366;
            color: #f2f2f7;
            font-size: 0.85rem;
            font-weight: 300;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.5rem;
            border: none;
            outline: none;
            font-family: ${(props) => props.theme.montserrat};
          }
        }
      }

      &--listSlider {
        .slick-slide {
          padding-right: 1rem;
          outline: none;
        }

        .slick-track {
          margin-left: 0;
        }

        .active .optionBtn {
          color: #1c1c1e !important;
          background-color: #f2f2f7 !important;
          font-weight: 600 !important;
        }

        .item {
          .optionBtn {
            width: 100%;
            padding: 0.7rem 0;
            border-radius: 0.5rem;
            background-color: #636366;
            color: #f2f2f7;
            font-size: 0.85rem;
            font-weight: 300;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.5rem;
            border: none;
            outline: none;
            font-family: ${(props) => props.theme.montserrat};
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .header {
      padding: 1rem 2rem;
      margin-bottom: 1.5rem;

      .title {
        font-size: 1rem;
      }

      .button {
        font-size: 0.9rem;
      }
    }

    .form {
      width: 90%;

      &__item {
        padding-left: 8px !important;
        padding-right: 8px !important;
      }

      &__title {
        font-size: 1rem;
      }

      .cover {
        height: 18rem;
      }

      .showCover {
        .title {
          font-size: 1rem;
        }

        .box {
          height: 18rem;

          .button {
            width: 100%;
            font-size: 0.8rem;
            letter-spacing: 1px;
          }
        }
      }

      &__card {
        padding: 0.6rem 1.5rem;
      }

      &__description {
        .title {
          font-size: 1rem;
        }
      }

      input,
      textarea {
        font-size: 0.9rem;
      }

      textarea {
        height: 12rem;
      }
    }

    .options {
      &__slider {
        margin-bottom: 1.5rem;

        &--title {
          font-size: 1rem;
        }

        &--list {
          .item {
            margin-right: 0.5rem;

            .optionBtn {
              padding: 0.6rem 1.2rem;
            }
          }
        }

        &--listSlider {
          .slick-slide {
            padding-right: 0.5rem;
            outline: none;
          }

          .item {
            .optionBtn {
              padding: 0.6rem 0;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .header {
      margin-bottom: 1rem;

      .button {
        font-size: 0.8rem;
      }
    }

    .form {
      width: 90%;

      .cover {
        height: 15rem;

        .icon {
          height: 1.5rem;
        }
      }

      .showCover {
        .title {
          font-size: 1rem;
        }

        .box {
          height: 15rem;

          .button {
            font-size: 0.6rem;
            font-weight: 300;
            border-radius: 0;
          }
        }
      }

      &__card {
        padding: 0.5rem 1.3rem;

        .left {
          .icon {
            margin-right: 0.5rem;
            height: 1.2rem;
          }
        }

        .right {
          .icon {
            height: 0.8rem;
          }
        }
      }

      textarea {
        height: 10rem;
      }

      &__submit {
        text-align: center;
        width: 100%;

        button {
          padding: 1em 5rem;
          font-size: 0.9rem;
        }
      }
    }

    .options {
      &__slider {
        margin-bottom: 1.5rem;

        &--list {
          .item {
            .optionBtn {
              padding: 0.5rem 1rem;
            }
          }
        }

        &--listSlider {
          .item {
            .optionBtn {
              padding: 0.5rem 0;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
`;
