import {
  GET_FEATURED_SERIES_REQUEST,
  GET_FEATURED_SERIES_SUCCESS,
  GET_FEATURED_SERIES_FAIL,
} from "../actions/types";

const featuredSeries = (state = {}, action) => {
  switch (action.type) {
    case GET_FEATURED_SERIES_REQUEST:
      return {
        loading: true,
      };
    case GET_FEATURED_SERIES_SUCCESS:
      return {
        loading: false,
        featuredSeries: action.payload,
      };
    case GET_FEATURED_SERIES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default featuredSeries;
