import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NoResults from "../components/NoResults";
import PortalTabs from "../components/MyPortalComponents/MyPortal";
import { MainContainer } from "../components/MyPortalComponents/MyPortal/styles";
import {
  getProfile,
  getNotificationCategories,
  getUserSettingByUserId,
  getProfileElements,
  getVideoInformation,
} from "../actions";
import Skeleton from "../skeletons/Channel";
import NewBottomBar from "../components/NewHomeComponents/BottomBar/BottomBar";
import { CLEAR_PROFILE } from "../actions/types";
import Error from "../components/Elements/Error";
import { useHistory } from "react-router-dom";

let managePortalCall = false;
const Channel = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const user = useSelector((state) => state.user);
  const updateUserSetting = useSelector((state) => state.updateUserSetting);
  const notfound = useSelector((state) => state.notfound);
  const { userIdOrUserName } = useParams();
  const dataFetchedRef = useRef(true);
  const [result, setResult] = useState();

  useEffect(() => {
    dispatch(getProfile(userIdOrUserName));
    return () =>
      dispatch({
        type: CLEAR_PROFILE,
      });
  }, [userIdOrUserName]);

  useEffect(() => {
    if (!managePortalCall) {
      // dispatch(getProfile(userIdOrUserName));
      dispatch(getVideoInformation(userIdOrUserName))
      managePortalCall = true;
    }
  }, []);

  useEffect(() => {
    if (profile?.isFetching) {
      if (user && user.userrole) {
        dispatch(getNotificationCategories(user.userrole));
      }

      if (profile?.id === user?.id) {
        dispatch(getUserSettingByUserId(profile.id));
      }

      if (profile && profile.userrole === 2) {
        dispatch(getProfileElements(userIdOrUserName));
      }
      if (!user.id) {
        dispatch(getProfileElements(userIdOrUserName));
      }
    }
  }, [user, profile?.id]);

  if (notfound) {
    return (
      <Error
        text="No user found with that username."
        actionButton={() => history.push("/home")}
        actionButtonText="Go Home"
      />
    );
  }

  if (profile?.isFetching) {
    return <Skeleton />;
  }

  return (
    <>
      <MainContainer>
        <PortalTabs />
      </MainContainer>
    </>
  );
};

export default Channel;
