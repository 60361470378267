import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import PlaylistOptions from "./MenuOptions/PlaylistOptions";
import config from '../../config'

const PlaylistCard = ({ item, handleClick }) => {
  const history = useHistory();
  return (
    <PlaylistCardStyled>
      <div
        className="thumbnail"
        onClick={() => {
          if(handleClick) return handleClick()
          history.push(`/watch/${item.id}`)
        }}>
        <img
          src={config.aws.CLOUDFRONT_URL + item?.videos[0]?.thumbnail}
          alt={item?.title}
        // onError={(e) => {
        //   e.target.onerror = null;
        //   e.target.src =
        //     "https://res.cloudinary.com/dq87imngy/image/upload/v1653667310/defaultImage_smbzwn.png";
        // }}
        />
      </div>

      <div className="metaInfo">
        <div className="metaInfo__left">
          <div className="metaInfo__left--videoMeta">
            <div className="title">{item.title}</div>

            <div className="seperatorInfo">
              <div className="bold">{item?.videos[0]?.User?.username}</div>
              <div className="light"><Moment fromNow>{item?.createdAt}</Moment></div>
            </div>

            <div className="lightText">{item?.videos.length} Videos</div>
          </div>
        </div>
        <div className="metaInfo__right">
          <PlaylistOptions />
        </div>
      </div>

      <div className="numVideos">
        <span>{item?.videos.length} Videos</span>
      </div>
    </PlaylistCardStyled> 
  );
};

export default PlaylistCard;

const PlaylistCardStyled = styled.div`
  margin-bottom: 2rem;
  position: relative;

  .numVideos {
    position: absolute;
    top: 9.5rem;
    left: 1rem;
    font-size: 0.8rem;
    padding: 0.2rem 0.7rem;
    color: #fff;
    z-index: 2;
    font-family: brother-1816, sans-serif;
    background-color: rgba(28, 28, 30, 1);
    text-transform: uppercase;
    border-radius: 0.3rem;
    cursor: pointer;
  }

  .thumbnail {
    width: 100%;
    height: 12rem;
    overflow: hidden;
    margin-bottom: 1rem;
    border-radius: 1rem;
    cursor: pointer;

    img {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
    }
  }

  .metaInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
    font-family: ${(props) => props.theme.montserrat};

    &__left {
      display: flex;
      align-items: center;

      &--videoMeta {
        cursor: pointer;

        .title {
          font-size: 0.95rem;
          font-weight: 500;
          line-height: 1.5;
        }

        .seperatorInfo {
          font-size: 0.75rem;
          display: flex;
          align-items: center;

          & > *:not(:last-child) {
            &::after {
              content: "•";
              margin-left: 8px;
              position: absolute;
              display: inline-block;
              font-weight: 700;
            }
          }
          & > *:not(:first-child) {
            margin-left: 18px;
          }

          .bold {
            font-weight: 500;
            color: rgba(199, 199, 204, 1);
          }

          .light {
            color: rgba(174, 174, 178, 1);
            font-size: 300;
          }
        }

        .lightText {
          font-weight: 400;
          font-size: 0.8rem;
          color: rgba(174, 174, 178, 1);
        }
      }
    }

    &__right {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 480px) {
    .numVideos {
      top: 7rem;
      left: 0.5rem;
      font-size: 0.65rem;
      padding: 0.2rem 0.5rem;
    }

    .thumbnail {
      margin-bottom: 0.5rem;
      height: 9rem;
    }
  }
`;
