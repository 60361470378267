import React, { useContext, useState, useEffect } from "react";
import Button from "../../Elements/Button";
import ChevronLeftIcon from "../../icons/chevronleft.svg";
import Axios from "axios";
import TextField from "@material-ui/core/TextField";
import AutoComplete from "@material-ui/lab/Autocomplete";
import Switch from "react-switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { AuthContext } from "../../../context/AuthContext";
import { setAuthToken } from "../../../utils";
import { Row, Col } from "react-grid-system";
import { makeStyles } from "@material-ui/core/styles";
import { elementScrollToTop } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  InputGroup: {
    "& .MuiAutocomplete-inputRoot": {
      padding: "0 .5rem !important",
      position: "relative",
      backgroundColor: "#fff",
      fontFamily: "Montserrat",
      border: "3px solid transparent",
      transition: "all .3s ease",

      "&:hover": {
        border: "3px solid rgb(249,154,45)",
      },
    },

    "& .Mui-disabled": {
      backgroundColor: "#181818",
      color: "#fff",

      "&::placeholder": {
        fontWeight: 400,
        color: "#fff",
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },

    "& .MuiInputBase-input": {
      margin: "0 !important",

      "&::placeholder": {
        fontWeight: "500",
        color: "#000",
      },
    },
  },

  Checkbox: {
    "& .MuiSvgIcon-root": {
      fill: "rgb(249,154,45)",
    },
  },
}));

const UserSettings = ({ setProgress }) => {
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const CHECKED = <div className="yes">Yes</div>;
  const UNCHECKED = <div className="no">No</div>;

  const {
    country,
    setCountry,
    state,
    setState,
    city,
    setCity,
    anonymous,
    setAnonymous,
    proContentProducer,
    setProContentProducer,
    partnerContentCreator,
    setPartnerContentCreator,
    contactViaEmail,
    setContactViaEmail,
    agreedToLicense,
    setAgreedToLicense,
    choosePackage,
    setValidationError,
    setShowAgreementPage,
  } = useContext(AuthContext);

  useEffect(() => {
    fetchAllCountries();
    elementScrollToTop("authContent");
  }, []);

  useEffect(() => {
    if (country) {
      const newArray = countries.filter(function (el) {
        return el.name === country;
      });

      if (newArray.length > 0) {
        setCountryCode(newArray[0].iso2);
      }
    }

    if (country && countryCode) {
      fetchAllStates();
    }
  }, [country, countryCode]);

  useEffect(() => {
    if (state) {
      const newArray = states.filter(function (el) {
        return el.name === state;
      });

      if (newArray.length > 0) {
        setStateCode(newArray[0].iso2);
      }
    }

    if (state && stateCode) {
      fetchAllCities();
    }
  }, [state, stateCode]);

  const fetchAllCountries = async () => {
    try {
      setAuthToken("Y2dTYVhpVVE3NXBhaW1nMG5WM2Z6REFHT0ZWSjZZVThxbEIxUFFqaA==");
      const { data } = await Axios.get(
        "https://api.countrystatecity.in/v1/countries"
      );
      setCountries(data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAllStates = async () => {
    try {
      setAuthToken("Y2dTYVhpVVE3NXBhaW1nMG5WM2Z6REFHT0ZWSjZZVThxbEIxUFFqaA==");
      const { data } = await Axios.get(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/states`
      );
      const sortedData = data.sort(function (a, b) {
        let name1 = a.name.toUpperCase();
        let name2 = b.name.toUpperCase();

        return name1 === name2 ? 0 : name1 > name2 ? 1 : -1;
      });

      setStates(sortedData);
    } catch (e) {}
  };

  const fetchAllCities = async () => {
    try {
      setAuthToken("Y2dTYVhpVVE3NXBhaW1nMG5WM2Z6REFHT0ZWSjZZVThxbEIxUFFqaA==");
      const { data } = await Axios.get(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/states/${stateCode}/cities`
      );

      const sortedData = data.sort(function (a, b) {
        let name1 = a.name.toUpperCase();
        let name2 = b.name.toUpperCase();

        return name1 === name2 ? 0 : name1 > name2 ? 1 : -1;
      });

      setCities(sortedData);
    } catch (e) {}
  };

  const nextHandler = () => {
    if (!anonymous && (!country || !state || !city)) {
      return setValidationError({
        type: "error",
        message:
          "Please either choose Anonymous or provide your address information",
      });
    }

    if (!agreedToLicense) {
      return setValidationError({
        type: "error",
        message: "Accept to our license to agreement to preceed.",
      });
    }

    setValidationError(null);
    setProgress(6);
  };

  const backHandler = () => {
    if (choosePackage === "BASIC") {
      setProgress(3);
    } else {
      setProgress(4);
    }
  };

  return (
    <>
      <div className="actionRow">
        <Button
          size="small"
          variant="outline"
          mode="secondary"
          onClick={backHandler}
          className="actionRow__back"
        >
          <img src={ChevronLeftIcon} alt="" className="icon" />
          {choosePackage === "BASIC" ? (
            <span>Edit Badge</span>
          ) : (
            <span>Edit Storage</span>
          )}
        </Button>
      </div>

      <div className="paddingBox">
        <div className="title">Enter your public display settings</div>

        <Row className="row row__one">
          <Col md={12}>
            <div className={`${classes.InputGroup} field`}>
              <label htmlFor="country-autocomplete">Country</label>
              <AutoComplete
                freeSolo
                id="country-autocomplete"
                disabled={anonymous ? true : false}
                onChange={(e, newValue) => {
                  setCountry(newValue);
                  setState("");
                  setCity("");
                }}
                options={countries.map((item) => item.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select your country"
                    name="country"
                    value={country}
                    disabled={anonymous ? true : false}
                    onChange={(e) => {
                      setCountry(e.target.value);
                      setState("");
                      setCity("");
                    }}
                  />
                )}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className={`${classes.InputGroup} field`}>
              <label htmlFor="state-autocomplete">State</label>
              <AutoComplete
                freeSolo
                id="state-autocomplete"
                disabled={anonymous ? true : false}
                onChange={(e, newValue) => setState(newValue)}
                options={states.map((item) => item.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select your state"
                    name="state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                )}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className={`${classes.InputGroup} field`}>
              <label htmlFor="city-autocomplete">City</label>
              <AutoComplete
                freeSolo
                id="city-autocomplete"
                disabled={anonymous ? true : false}
                onChange={(e, newValue) => setCity(newValue)}
                options={cities.map((item) => item.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select your city"
                    variant="outlined"
                    name="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <div className="row row__two">
          <label className="toggleBox">
            <Switch
              checkedIcon={CHECKED}
              uncheckedIcon={UNCHECKED}
              onColor="#28df99"
              offColor="#cc0000"
              className={anonymous ? "ToggeSelected" : null}
              onChange={() => setAnonymous(!anonymous)}
              checked={anonymous}
            />
            <span>Out of this world (to remain anonymous)</span>
          </label>

          <label className="toggleBox">
            <Switch
              checkedIcon={CHECKED}
              uncheckedIcon={UNCHECKED}
              onColor="#28df99"
              offColor="#cc0000"
              onChange={() => setProContentProducer(!proContentProducer)}
              checked={proContentProducer}
            />
            <span>
              Are you a professional video or audio content producer ?
            </span>
          </label>

          <label className="toggleBox">
            <Switch
              checkedIcon={CHECKED}
              uncheckedIcon={UNCHECKED}
              onColor="#28df99"
              offColor="#cc0000"
              onChange={() => setPartnerContentCreator(!partnerContentCreator)}
              checked={partnerContentCreator}
            />
            <span>
              Do you wish to be listed as a resource for other content creators
              to partner with you for creating content ?
            </span>
          </label>

          <label className="toggleBox">
            <Switch
              checkedIcon={CHECKED}
              uncheckedIcon={UNCHECKED}
              onColor="#28df99"
              offColor="#cc0000"
              onChange={() => setContactViaEmail(!contactViaEmail)}
              checked={contactViaEmail}
            />
            <span>
              Do you want Co-Op members to be able to contact with you via your
              registered email address ?
            </span>
          </label>
        </div>

        <div className="row row__three">
          <div className="checkBox">
            <FormControlLabel
              className={classes.Checkbox}
              control={
                <Checkbox
                  checked={agreedToLicense}
                  onChange={() => setAgreedToLicense(!agreedToLicense)}
                  name="agreedToLicense"
                />
              }
              label="Accept Terms"
            />

            <p onClick={() => setShowAgreementPage(true)}>
              Please agree to the iSutra Bill of Rights
            </p>
          </div>
        </div>

        <Button
          mode="primary"
          width="fluid"
          variant="round"
          size="medium"
          onClick={nextHandler}
          className="submit"
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default UserSettings;
