import React from "react";
import styled from "styled-components";
import Input from "../../../components/Elements/Input";
import Button from "../../../components/Elements/Button";
import { Row, Col } from "react-grid-system";

import { useDispatch } from "react-redux";
import {
  adminVideoCategoryRequestData,
  updateAdminVideoCategoryRequest,
} from "../../../actions";

const VideoCategoryRequestEdit = ({ isOpen, onClose, formData, onChange }) => {
  const dispatch = useDispatch();

  const handleUpdateAdminUser = async () => {
    try {
      await dispatch(updateAdminVideoCategoryRequest(formData));
      await dispatch(adminVideoCategoryRequestData());
      onClose();
    } catch (error) {
      console.error("Error updating video category request type:", error);
    }
  };
  return (
    <VideoCategoryRequestModel open={isOpen}>
      <VideoCategoryRequestEditContent>
        <div className="title">Edit video Category Request</div>
        <div className="field">
          <Row className="row row__one">
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Id"
                  name="id"
                  id="id"
                  placeholder="id"
                  type="id"
                  value={formData.id}
                  onChange={(e) => onChange("id", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="categoryTitle"
                  name="categoryTitle"
                  id="categoryTitle"
                  placeholder="categoryTitle"
                  type="text"
                  value={formData.categoryTitle}
                  onChange={(e) => onChange("categoryTitle", e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="categoryDescription"
                  name="categoryDescription"
                  id="categoryDescription"
                  placeholder="categoryDescription"
                  type="text"
                  value={formData.categoryDescription}
                  onChange={(e) =>
                    onChange("categoryDescription", e.target.value)
                  }
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="requestedUserEmail"
                  name="requestedUserEmail"
                  id="requestedUserEmail"
                  placeholder="requestedUserEmail"
                  type="text"
                  value={formData.requestedUserEmail}
                  onChange={(e) =>
                    onChange("requestedUserEmail", e.target.value)
                  }
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="CreatedAt"
                  name="createdAt"
                  id="createdAt"
                  placeholder="createdAt"
                  type="text"
                  value={formData.createdAt}
                  onChange={(e) => onChange("createdAt", e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Row className="row row__one">
          <Col md={6} sm={6} xs={6}>
            <Button mode={"secondary"} className="submit" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <Button className="submit" onClick={handleUpdateAdminUser}>
              Save
            </Button>
          </Col>
        </Row>
      </VideoCategoryRequestEditContent>
    </VideoCategoryRequestModel>
  );
};

export default VideoCategoryRequestEdit;

const VideoCategoryRequestModel = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoCategoryRequestEditContent = styled.div`
  background: #2c2c2e;
  color: white;
  border: 1px solid white;
  padding: 20px;
  max-width: 800px;
  width: 100%;

  .title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .submit {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdownBox {
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    margin-top: 3px;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;
