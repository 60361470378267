import {
  GET_SITE_BRANDING_FAIL,
  GET_SITE_BRANDING_REQUEST,
  GET_SITE_BRANDING_SUCCESS,
  POST_SITE_BRANDING_FAIL,
  POST_SITE_BRANDING_REQUEST,
  POST_SITE_BRANDING_SUCCESS,
  SITE_BRANDING_ERROR_RESET,
} from "../actions/branding/type";

const branding = (state = {}, action) => {
  switch (action.type) {
    case GET_SITE_BRANDING_REQUEST:
      return {
        loading: true,
      };
    case GET_SITE_BRANDING_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case GET_SITE_BRANDING_FAIL:
      return {
        loading: false,
        data: null,
        error: action.payload,
      };
    case POST_SITE_BRANDING_REQUEST:
      return {
        loading: true,
        data: state.data,
      };
    case POST_SITE_BRANDING_SUCCESS:
      const newBranding = {
        ...state.data,
        ...action.payload,
      };

      return {
        loading: false,
        data: newBranding,
        success: true,
      };
    case POST_SITE_BRANDING_FAIL:
      return {
        data: state.data,
        loading: false,
        error: action.payload,
      };
    case SITE_BRANDING_ERROR_RESET:
      return {
        data: state.data,
        loading: false,
        error: null,
        success: false,
      };
    default:
      return state;
  }
};

export default branding;
