import React, { useState, useContext, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import TickIcon from "../../../assets/Icons/tick.svg";
import { addDefaultImage } from "../../../utils";
import ImageNotfoundPlaceholder from "../../../assets/image_notfound_placeholder.svg";
import config from "../../../config";

const MomentCardSelect = ({ selectMomentHandler, isSelected, item }) => {
  const { caption, coverImgUrl } = item;

  return (
    <>
      <MomentCardSelectStyled onClick={() => selectMomentHandler(item)}>
        <img
          src={config.aws.CLOUDFRONT_URL + coverImgUrl}
          alt={caption}
          className="thumbnail"
          onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
        />
        <div className="backdrop"></div>

        <div className="metaData">
          <h1 className="metaData__title">{caption}</h1>
        </div>

        <div
          onClick={() => selectMomentHandler(item)}
          className={`selectVideo ${isSelected && "selectVideo__selected"}`}
        >
          {isSelected && <img src={TickIcon} alt="Tick Icon" />}
        </div>
      </MomentCardSelectStyled>
    </>
  );
};

export default MomentCardSelect;

const TickIconAnimation = keyframes`
  from {
    transform: translateY(7px) scale(0.7);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const MomentCardSelectStyled = styled.div`
  padding-bottom: 177.78%;
  height: 0;
  width: 100%;

  overflow: hidden;
  border-radius: 0.5rem;
  position: relative;
  margin: 0 auto;
  position: relative;
  margin-bottom: 2rem;
  cursor: pointer;

  .selectVideo {
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 4px solid #f9903d;
    background-color: #000;
    cursor: pointer;
    transition: all 0.25s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    &__selected {
      background-color: #f9903d !important;
    }

    img {
      width: 1.6rem;
      height: auto;
      animation: ${TickIconAnimation} 0.2s ease;
      transition: all 0.2s ease;
    }
  }

  .Player {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: scale(1.25);
  }

  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    z-index: -1;
  }

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
    transition: all 0.2s ease;
    opacity: 0;
    visibility: hidden;
  }

  .metaData {
    position: absolute;
    z-index: 2;
    bottom: 1.5rem;
    left: 1rem;
    transition: all 0.2s ease;

    &__title {
      font-size: 1.2rem;
      line-height: 1.3;
      width: 90%;
      font-family: ${(props) => props.theme.montserrat}, sans-serif;
      font-weight: 400;
    }
  }

  &:hover {
    .backdrop {
      background-color: rgba(0, 0, 0, 0.4);
      transform: scale(1.2);
      opacity: 1;
      visibility: visible;
    }
  }

  @media screen and (max-width: 768px) {
    height: 18rem;

    .metaData {
      &__title {
        font-size: 1.2rem;
      }

      &__views {
        font-size: 0.7rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    height: 14rem;
    margin-bottom: 1rem;

    .dotMenu {
      height: 1.5rem;
      width: 1.5rem;

      i {
        font-size: 0.8rem;
      }
    }

    .Player {
      transform: scaleX(1.25) scaleY(1.4);
    }

    .metaData {
      left: 0.4rem;
      bottom: 0.8rem;

      &__title {
        font-size: 0.9rem;
        width: 95%;
      }

      &__views {
        font-size: 0.6rem;
      }
    }
  }
`;
