import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import FreeloaderLogo from "../../assets/logo/logoOnly.svg";
import config from "../../config";
import { GlobalContext } from "../../context/GlobalContext";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

const WelcomeScreen = () => {
  const { setShowWelcomeScreen } = useContext(GlobalContext);
  const { data: siteBranding } = useSelector((state) => state.branding);

  const topAnimation = {
    hidden: {
      y: "-100%",
    },
    visible: {
      y: 0,
      transition: {
        duration: 1,
      },
    },
    exit: {
      y: "-100%",

      transition: {
        duration: 1,
      },
    },
  };

  const bottomAnimation = {
    hidden: {
      y: "100%",
    },
    visible: {
      y: 0,
      transition: {
        duration: 1,
      },
    },
    exit: {
      y: "100%",

      transition: {
        duration: 1,
      },
    },
  };

  const logoAnimation = {
    hidden: {
      scale: 0.6,
    },
    visible: {
      scale: 1,
      transition: {
        duration: 2,
        delay: 0.5,
        yoyo: Infinity,
      },
    },
    exit: {
      opacity: 0,
      scale: 0,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <WelcomeScreenStyled>
      <motion.div
        variants={topAnimation}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="top"
      ></motion.div>
      <motion.div
        variants={bottomAnimation}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="bottom"
      ></motion.div>
      <div className="content">
        <motion.div
          variants={logoAnimation}
          initial="hidden"
          animate="visible"
          exit="exit"
          onClick={() => setShowWelcomeScreen(false)}
          className="content__logo"
        >
          <img
            src={
              siteBranding?.loadingScreenLogo
                ? config.aws.CLOUDFRONT_URL + siteBranding.loadingScreenLogo
                : FreeloaderLogo
            }
            alt=""
          />
        </motion.div>
      </div>
    </WelcomeScreenStyled>
  );
};

export default WelcomeScreen;

const WelcomeScreenStyled = styled.div`
  position: fixed !important;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  color: #fff;
  z-index: 9999;
  position: relative;

  .top,
  .bottom {
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 1;
  }

  .top {
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(56, 56, 56, 0.7),
      rgba(56, 56, 56, 0.7)
    );
    backdrop-filter: blur(16px);
  }

  .bottom {
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(56, 56, 56, 0.7),
      rgba(56, 56, 56, 0.7)
    );
    backdrop-filter: blur(16px);
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 60%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__logo {
      height: 25rem;
      width: auto;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
`;
