import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import PlayIcon from "../icons/play.svg";
import PauseIcon from "../icons/pause.svg";
import SoundOn from "../icons/sound-on.svg";
import SoundOff from "../icons/sound-off.svg";
import ReactPlayer from "react-player";
import config from "../../config";
import ImageNotfoundPlaceholder from "../../assets/image_notfound_placeholder.svg";
import { GlobalContext } from "../../context/GlobalContext";
import { useHistory } from "react-router-dom";
import { SubscribeNewChannel, checkAddToStram } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { notificationMessage } from "../../utils/toastNotifications";
import { addDefaultImage, isBadgeOrAvatar } from "../../utils";

const MomentCardV2 = ({ moment, nextMomentHandler, index }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const trackerRef = useRef();
  const playerRef = useRef();
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [played, setPlayed] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [started, setStarted] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observer = new IntersectionObserver(([entry]) => {
    setIsIntersecting(entry.isIntersecting);
  });
  const [isSubscribed, setIsSubscribed] = useState(""); // IS_MINE, SUBSCRIBED, UNSUBSCRIBED

  const { setShowMomentDetailsModel, setSelectedMoment } =
    useContext(GlobalContext);

  useEffect(() => {
    if (moment && moment.id) {
      if (!moment?.isMine && moment?.isSubscribed) {
        setIsSubscribed("SUBSCRIBED");
      } else if (!moment?.isMine && !moment?.isSubscribed) {
        setIsSubscribed("UNSUBSCRIBED");
      } else if (moment?.isMine) {
        setIsSubscribed("ISMINE");
      }
    }
  }, [moment]);

  useEffect(() => {
    observer.observe(trackerRef.current);

    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isIntersecting) {
      setPlaying(true);
    } else {
      setPlaying(false);
      setStarted(false);
    }
  }, [setIsIntersecting, isIntersecting]);

  const [videoUrl, setVideoUrl] = useState(moment?.videoUrl);
  const [videoThumbnail, setVideoThumbnail] = useState(
    config.aws.CLOUDFRONT_URL + moment?.coverImgUrl
  );

  const handleProgress = (progressState) => {
    setPlayed(progressState.played);
    setLoaded(progressState.loaded);
  };

  const handleMomentDetail = () => {
    setPlaying(false);
    setSelectedMoment(moment);
    setShowMomentDetailsModel(true);
  };

  const addToStreamsHandler = (username) => {
    if (!user.id) {
      // it means user is not logged in
      toast.error(notificationMessage.loginRequired);

      return;
    }

    setIsSubscribed("SUBSCRIBED");

    dispatch(SubscribeNewChannel(username, user.id));
    dispatch(checkAddToStram(username, user.id));
  };

  const removeFromStreamsHandler = (username) => {
    if (!user.id) {
      // it means user is not logged in
      toast.error(notificationMessage.loginRequired);

      return;
    }

    setIsSubscribed("UNSUBSCRIBED");

    dispatch(SubscribeNewChannel(username, user.id));
    dispatch(checkAddToStram(username, user.id));
  };

  const image = moment?.User?.avatar
    ? moment.User.avatar
    : moment?.User?.badge
    ? moment.User.badge
    : null;

  return (
    <MomentCardV2Styled>
      <div className="video">
        <div className="player" onClick={handleMomentDetail}>
          <ReactPlayer
            ref={playerRef}
            width="100%"
            height="100%"
            url={config.aws.CLOUDFRONT_URL + videoUrl}
            playing={playing}
            muted={muted}
            onStart={() => setStarted(true)}
            onProgress={handleProgress}
            onEnded={() => nextMomentHandler(index)}
            playsinline={true}
          />
        </div>

        <div className="centerBox" ref={trackerRef}></div>

        <div className="controls">
          <div className="controls__progressBar">
            <div
              style={{
                width: `${loaded * 100}%`,
              }}
              className="controls__progressBar--loaded"
            ></div>

            <div
              style={{
                width: `${played * 100}%`,
              }}
              className="controls__progressBar--played"
            ></div>
          </div>

          <div className="controls__left">
            <div
              onClick={(e) => {
                e.stopPropagation();
                setPlaying(!playing);
              }}
              className="icon"
            >
              <img src={playing ? PauseIcon : PlayIcon} alt="" />
            </div>
          </div>

          <div className="controls__right">
            <div
              onClick={(e) => {
                e.stopPropagation();
                setMuted(!muted);
              }}
              className="icon"
            >
              <img src={muted ? SoundOff : SoundOn} alt="" />
            </div>
          </div>
        </div>

        {!started && !playing && (
          <div onClick={handleMomentDetail} className="thumbnail">
            <img
              onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
              src={videoThumbnail}
              alt=""
            />
          </div>
        )}

        <div className="metaInfo">
          <pre className="metaInfo__title">{moment?.caption}</pre>

          <div className="metaInfo__user">
            <div
              onClick={() => {
                history.push(`/channel/${moment?.User?.username}`);
              }}
              className="metaInfo__user--left"
            >
              <div className="avatar">{isBadgeOrAvatar(image)}</div>
              <div className="username">{moment?.User?.username}</div>
            </div>
            <div className="metaInfo__user--right">
              {isSubscribed === "SUBSCRIBED" && (
                <div
                  className="followBtnGray"
                  onClick={() =>
                    removeFromStreamsHandler(moment?.User?.username)
                  }
                >
                  remove from streams
                </div>
              )}

              {isSubscribed === "UNSUBSCRIBED" && (
                <div
                  className="followBtn"
                  onClick={() => addToStreamsHandler(moment?.User?.username)}
                >
                  add to streams
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </MomentCardV2Styled>
  );
};

export default MomentCardV2;

const MomentCardV2Styled = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  .centerBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 5rem;
    width: 5rem;
    z-index: -1;
    background-color: transparent;
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;

    .player {
      z-index: 5;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .controls {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1rem 1.5rem 1rem;
      z-index: 5;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5),
        60%,
        transparent
      );

      &__progressBar {
        position: absolute;
        top: 0;
        left: 0;
        height: 3.5px;
        width: 100%;
        background-color: #fff;
        transform: translateY(-110%);
        opacity: 0;
        transition: all 0.2s ease;

        &--loaded {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          height: 100%;
          width: 0;
          background: rgba(0, 0, 0, 0.4);
        }

        &--played {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          height: 100%;
          width: 0;
          background: ${(props) => props.theme.gradient};
        }
      }

      .icon {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
        border: 1px solid transparent;
        transition: all 0.3s ease;
        cursor: pointer;
        scale: 0;
        transform-origin: center;
        visibility: hidden;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        &:hover {
          border: 1px solid rgba(255, 255, 255, 0.1);
        }

        img {
          height: 1.1rem;
          width: auto;
        }
      }
    }

    .thumbnail {
      z-index: 4;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
      }
    }

    .metaInfo {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 2rem 1rem 1rem 1rem;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.5), 80%, transparent);
      transition: all 0.5s ease;
      height: 0;
      transform: translateY(100%);
      z-index: 5;

      &__title {
        margin-bottom: 1rem;
        line-height: 1.3;
        font-size: 1.05rem;
        font-weight: 400;
        color: #fff;
        font-family: ${(props) => props.theme.montserrat}, sans-serif !important;
      }

      &__user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .avatar {
          cursor: pointer;
          height: 43px;
          width: 43px;
          border-radius: 50%;
          margin-right: 0.4rem;
          position: relative;

          .imageAvatar {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
            height: 100%;
            width: 100%;
          }

          .imageBadge {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .badge {
              height: 38px;
              width: auto;
            }
          }
        }

        .username {
          color: rgba(255, 255, 255, 0.8);
          font-weight: 400;
          font-size: 0.8rem;
          cursor: pointer;
        }

        .followBtn,
        .followBtnGray {
          cursor: pointer;
          line-height: 1;
          font-family: brother-1816, sans-serif;
          padding: 0.4rem 0.6rem;
          text-transform: uppercase;
          font-size: 0.73rem;
          font-weight: 500;
          border-radius: 3px;
        }

        .followBtn {
          background: ${(props) => props.theme.gradient};
        }

        .followBtnGray {
          background-color: #181818;
          border: 1px solid rgba(255, 255, 255, 0.2);
        }

        &--left {
          display: flex;
          align-items: center;
        }
      }
    }

    &:hover .controls {
      &__progressBar {
        opacity: 1;
        transform: translateY(-10%);
      }

      .icon {
        scale: 1;
        visibility: visible;
      }
    }

    &:hover .metaInfo {
      height: auto;
      transform: translateY(0);
    }
  }
`;
