import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { getSubscriptionPlans } from "../../../actions";
import { ADD_NEW_PAYMENT_METHOD, SET_DEFAULT_PAYMENT_METHOD } from '../../../actions/types';
import api from '../../../services/api';
import { darkTheme } from '../../../styles/theme';
import { notificationMessage } from '../../../utils/toastNotifications';

const AddCardForm = ({ closeAddCardModal, setAsDefaultPM, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [defaultPM, setDefaultPM] = useState(false);
  const dispatch = useDispatch();

  

  const cardStyle = {
    style: {
      base: {
        color: darkTheme.white,
        fontFamily: "ingra, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: darkTheme.white,
        },
      },
      invalid: {
        color: darkTheme.red,
        iconColor: darkTheme.red,
      },
    },
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      
      if (!stripe || !elements) return;
      setIsLoading(true);

      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });
      if (result.error) {
        toast.error(notificationMessage.incorrectPaymentDetails);
        setIsLoading(false);
        setError(result.error);
        return;
      }
      
      
      const addNewPaymentMethodResposne = await api.post(
        "stripe/addNewPaymentMethod",
        {
          paymentMethodId: result.paymentMethod.id,
          paymentMethodToDeleteId: null,
          defaultPM,

        }
      );
        if(addNewPaymentMethodResposne.data?.message === notificationMessage.cardexists){
          setIsLoading(false);
          toast.error(notificationMessage.cardexists);
        }else {
         
          dispatch({
            type: ADD_NEW_PAYMENT_METHOD,
            payload: addNewPaymentMethodResposne.data
          })
          if (defaultPM) {
            setAsDefaultPM(result.paymentMethod.id);
          }
          setIsLoading(false);
          toast.success(notificationMessage.paymentDetailsAdded);
        }
        
        closeAddCardModal();
        
    } catch (e) {
      console.log("Errr", e.message)
      toast.error(notificationMessage.somethingWentWrong);
    }
  };
  if (!stripe || !elements) return "Loading Stripe...";
  return (
    <Container id="add-card-form">
      <form id="add-card-form" onSubmit={handleSubmit}>
        <div>
          <CardNumberElement options={cardStyle} className="card-element" />
          <div className="card-element-row">
            <CardExpiryElement options={cardStyle} className="card-one" />
            <CardCvcElement options={cardStyle} className="card-two" />
          </div>
        </div>
        {error && (
          <ErrorComponent>
            <i className="fas fa-exclamation-circle" />
            {error.message}
          </ErrorComponent>
        )}
        <div>
          <label className="toggleBox">
            <Switch
              onColor="#f88b44"
              offColor="#1c1c1e"
              onChange={() => setDefaultPM(!defaultPM)}
              checked={defaultPM}
            />
            <span>Set as default Card</span>
          </label>
        </div>
        <div type="submit" style={{ marginTop: "10px" }}>
          <button className="stripe-submit" disabled={isLoading}>
            {isLoading ? "Processing..." : "Add Card"}
          </button>
        </div>
      </form>
    </Container>
  );
};

export default AddCardForm;

const Container = styled.div`
  .card-element {
    border-radius: 3px;
    padding: 10px 20px;
    width: 100%;
    background: ${(props) => props.theme.black};
    border: 1px solid ${(props) => props.theme.black};
    border-radius: 10rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .card-element-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .card-one,
    .card-two {
      width: 48%;
      border-radius: 3px;
      padding: 10px 20px;
      background: ${(props) => props.theme.black};
      border: 1px solid ${(props) => props.theme.black};
      color: #bbc5cd;
      border-radius: 10rem;
      margin-bottom: 1rem;
    }
  }

  .stripe-submit {
    width: 100%;
    margin: 0 0 1rem 0;
    border-radius: 10rem;
    padding: 0.7rem 0;
    font-size: 1.1rem;
    color: #fff;
    background-color: ${(props) => props.theme.orange};
    border: none;
    outline: none;
  }
  .note {
    color: #fde5b8;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.2;
  }
  /* toggle */
  .react-switch-bg {
    svg {
      height: 30px;
      width: 30px;
    }

    span {
      color: #fff !important;
    }
    .yes {
      margin-left: 0.3rem !important;
    }
    .no {
      margin-left: 0.3rem !important;
    }
  }

  .toggleBox {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;

    span {
      margin-left: 0.7rem;
      font-size: 0.9rem;
      line-height: 1.2;
      color: #999;
    }
  }
`;

const ErrorComponent = styled.h2`
  font-size: 0.8rem;
  color: #fff;
  padding: 0.4rem 1rem;
  border-radius: 0.3rem;
  margin-bottom: 1rem;

  i {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
`;
