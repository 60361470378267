import React from "react";
import styled from "styled-components";
import FilterBox from "../components/StreamsComponents/FilterBox/FilterBox";

const Streams = () => {
  return (
    <StreamsStyled>
      <FilterBox />
    </StreamsStyled>
  );
};

export default Streams;

const StreamsStyled = styled.div`
  padding-top: 40px;
  .items {
    width: 90%;
    margin: 2rem auto;

    &__row {
      margin-bottom: 5rem;
    }
  }

  @media screen and (max-width: 480px) {
    .items {
      width: 100%;
    }
  }
`;
