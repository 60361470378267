import React from "react";
import styled from "styled-components";
import "./waitAnimation.css";

const WaitAnimation = () => {
  return (
    <WaitAnimationStyled>
      <div class="centered">
        <div class="blob-1"></div>
        <div class="blob-2"></div>
      </div>

      <div className="text">Please wait...</div>
    </WaitAnimationStyled>
  );
};

export default WaitAnimation;

const WaitAnimationStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .text {
    margin-top: 1.5rem;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-family: brother-1816, sans-serif;
    color: rgba(255, 255, 255, 0.5);
  }
`;
