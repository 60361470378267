import {
  GET_NOTIFICATION_SETTING_REQUEST,
  GET_NOTIFICATION_SETTING_SUCCESS,
  GET_NOTIFICATION_SETTING_FAIL,
  UPDATE_NOTIFICATION_SETTING,
} from "../actions/types";

const initialState = {
  isLoading: false,
  data: [],
  error: "",
};
const userNotificationSettings = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_SETTING_REQUEST:
      return {
        isLoading: true,
      };
    case GET_NOTIFICATION_SETTING_SUCCESS:
      return {
        isLoading: false,
        data: action.payload,
      };
    case GET_NOTIFICATION_SETTING_FAIL:
      return {
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_NOTIFICATION_SETTING:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default userNotificationSettings;
