import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import Button from "../../components/Elements/Button";
import InputV2 from "../../components/Elements/InputV2";
import { FormikProvider, useFormik } from "formik";
import { MarketingPageStyles } from "./Styles";
import { useSelector, useDispatch } from "react-redux";
import { addFaqItem, removeFaqItem } from "../../actions/marketing";
import { toast } from "react-toastify";
import { RESET_MARKETING_PAGE_STATE } from "../../actions/marketing/type";

const Schema = Yup.object().shape({
  question: Yup.string().required("Question is required."),
  answer: Yup.string().required("Answer is required."),
});

const FaqForm = () => {
  const dispatch = useDispatch();
  const {
    data: marketingPageData,
    saveLoading,
    saveSuccess,
    saveError,
    removeSuccess,
  } = useSelector((state) => state.marketingPage);
  const [faqs, setFaqs] = useState(null);
  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      await addFaqItemHandler(values);
    },
  });

  useEffect(() => {
    if (saveSuccess) {
      toast.success("FAQ item added successfully.");
    }

    if (saveError) {
      toast.success("Something went worng. Please try again!");
    }

    if (removeSuccess) {
      toast.success("FAQ item removed successfully.");
    }

    dispatch({
      type: RESET_MARKETING_PAGE_STATE,
    });
  }, [saveSuccess, saveError, removeSuccess]);

  useEffect(() => {
    if (marketingPageData) {
      const faqsData = marketingPageData?.["faq"];

      setFaqs(faqsData);
    }
  }, [marketingPageData]);

  const addFaqItemHandler = async (values) => {
    try {
      const obj = {
        question: values.question,
        answer: values.answer,
      };

      dispatch(addFaqItem(obj));
      formik.resetForm();
    } catch (error) {
      toast.error("Something went wrong. Please try again!");
    }
  };

  const removeFaqItemHandler = async (id) => {
    try {
      dispatch(removeFaqItem(id));
    } catch (error) {
      toast.error("Something went wrong. Please try again!");
    }
  };

  return (
    <FormikProvider value={formik}>
      <MarketingPageStyles>
        <div className="form">
          <div className="form__faq">
            <div className="form__faq--list">
              {faqs && faqs?.length
                ? faqs.map((item, i) => (
                    <div
                      onClick={() => {
                        removeFaqItemHandler(item.id);
                      }}
                      key={i}
                      className="form__faq--list-item"
                    >
                      <div className="header">
                        <h2 className="title">{item?.question}</h2>
                        <div className="remove">remove item</div>
                      </div>
                      <p className="text">{item?.answer}</p>
                    </div>
                  ))
                : null}
            </div>

            <div className="form__faq--addItem">
              <div className="form__item">
                <InputV2
                  name="question"
                  type={"text"}
                  placeholder={"Add FAQ Question."}
                  id={"question"}
                  label={"FAQ Question"}
                  value={formik.values.question}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={
                    formik.touched.question && formik.errors.question
                  }
                />
              </div>

              <div className="form__item">
                <InputV2
                  name="answer"
                  type={"text"}
                  placeholder={"Add FAQ Answer."}
                  id={"answer"}
                  label={"FAQ Answer"}
                  value={formik.values.answer}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formikError={formik.touched.answer && formik.errors.answer}
                />
              </div>

              <Button
                onClick={() => formik.handleSubmit()}
                className="form__faq--addItem-btn"
                mode="primary"
                width="fluid"
                variant="round"
              >
                {saveLoading ? "Adding item..." : "Add new Faq"}
              </Button>
            </div>
          </div>
        </div>
      </MarketingPageStyles>
    </FormikProvider>
  );
};

export default FaqForm;
