import React from "react";
import styled from "styled-components";
import GobackIcon from "./icons/back.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import PlaylistsTab from "../MyPortalComponents/PlaylistsTab/PlaylistsTab";

const PlaylistModal = ({ show, close }) => {
    const history = useHistory();
    const user = useSelector((state) => state.user);

    return (
        <PlaylistModalStyled className={`${show && "UploadModelShow"}`}>
            <div className="wrapper">
                <div className="wrapper__header">
                    <div onClick={close} className="backIcon">
                        <img src={GobackIcon} alt="" />
                    </div>
                    <div className="name">Playlist</div>
                </div>

                    <PlaylistsTab />
                <div className="wrapper__content">
                </div>
            </div>
        </PlaylistModalStyled>
    );
};

export default PlaylistModal;

const PlaylistModalStyled = styled.div`
  position: fixed;
  z-index: 900;
  bottom: 3.5rem;
  height: 100%;
  width: 100%;
  background-color: #1c1c1e;
  color: #fff;
  transition: all 0.7s ease;
  scale: 0.8;
  opacity: 0.8;
  transform: translateY(150%);
  overflow-y: auto;

  .wrapper {
    margin: 60px auto 0 auto;
    font-family: ${(props) => props.theme.montserrat};

    &__header {
      display: flex;
      align-items: center;
      padding: 1rem 5rem;
      border-bottom: 1px solid rgba(112, 112, 112, 0.4);

      .backIcon {
        margin-right: 1rem;
        cursor: pointer;

        img {
          height: 1rem;
          width: auto;
        }
      }

      .name {
        font-size: 1.2rem;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .wrapper {
      &__header {
        padding: 0.8rem 1.5rem;

        .name {
          font-size: 1rem;
        }
      }
    }
  }
`;
