import React from "react";
import styled from "styled-components";
import ArrowDownIcon from "../../icons/arrow-down-v2.svg";

const ScrollToTop = () => {
  const calcScrollValue = () => {
    const scrollProgress = document.getElementById("progress");
    const progressValue = document.getElementById("progress-value");
    const pos = document.documentElement.scrollTop;
    const calcHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrollValue = Math.round((pos * 100) / calcHeight);

    if (pos > 100) {
      scrollProgress.style.display = "block";
    } else {
      scrollProgress.style.display = "none";
    }

    scrollProgress.addEventListener("click", () => {
      document.documentElement.scrollTop = 0;
    });

    scrollProgress.style.background = `conic-gradient(rgb(246, 92, 139) ${scrollValue}%,   #fff ${scrollValue}%)`;
  };

  window.onscroll = calcScrollValue;
  window.onload = calcScrollValue;

  return (
    <ScrollToTopStyled>
      <div id="progress" className="progress">
        <div id="progress-value" className="progress__value">
          <img src={ArrowDownIcon} alt="" />
        </div>
      </div>
    </ScrollToTopStyled>
  );
};

export default ScrollToTop;

const ScrollToTopStyled = styled.div`
  .progress {
    display: none;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    z-index: 103;
    cursor: pointer;

    &__value {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: calc(100% - 10px);
      width: calc(100% - 10px);
      border-radius: 50%;
      background-color: #202020;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(255, 255, 255, 0.09);

      img {
        height: 1.5rem;
        width: auto;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .progress {
      bottom: 0.5rem;
      right: 0.5rem;
      height: 3rem;
      width: 3rem;

      &__value {
        height: calc(100% - 8px);
        width: calc(100% - 8px);

        img {
          height: 1.2rem;
        }
      }
    }
  }

  @media screen and (max-width: 414px) {
    .progress {
      height: 2.5rem;
      width: 2.5rem;

      &__value {
        height: calc(100% - 6px);
        width: calc(100% - 6px);

        img {
          height: 1rem;
        }
      }
    }
  }
`;
