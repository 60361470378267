import React, { useRef, useEffect, useContext, useState } from "react";
import styled from "styled-components";
import MomentCard from "../components/MomentsComponents/MomentCard";
import useScrollSnap from "react-use-scroll-snap";
import Error from "../components/Elements/Error";
import Skeleton from "../skeletons/Moment";
import ArrowDownIcon from "../components/icons/arrow-down.svg";
import { getAllMoments, getGuestMoments } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { useMediaQuery } from "react-responsive";

const Moments = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const scrollRef = useRef(null);
  useScrollSnap({ ref: scrollRef, duration: 100, delay: 0 });
  const { loading, moments, error } = useSelector((state) => state.moments);
  const { setShowAuthenticationModel } = useContext(GlobalContext);
  const [skipMoments, setSkipMoments] = useState(query.get("skip"));
  const [startingPosition, setStartingPosition] = useState(0);

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  useEffect(() => {
    if (skipMoments) {
      // fetch all moments
      if (user && user.id) {
        dispatch(getAllMoments(null, +skipMoments));
      }

      if (!user?.id) {
        dispatch(getGuestMoments(null, +skipMoments));
      }

      // auto scroll to top
      window.scrollBy(0, 0);
    } else {
      // fetch all moments
      if (user && user.id) {
        dispatch(getAllMoments());
      }

      if (!user?.id) {
        dispatch(getGuestMoments());
      }

      // auto scroll to top
      window.scrollBy(0, 0);
    }
  }, [skipMoments]);

  useEffect(() => {
    if (moments && moments.length > 0) {
      window.scrollBy(0, "3.8rem");
    }
  }, [moments]);

  const upgradeHandler = () => {
    history.push("/");
    setShowAuthenticationModel(true);
  };

  const scrollToNextMomentHandler = () => {
    const momentCard = document.getElementById("momentCard");
    const momentCardHeight = momentCard?.clientHeight;

    let nodeStyle = window.getComputedStyle(momentCard);
    let momentCardMarginBottom = nodeStyle.getPropertyValue("margin-bottom");
    const pxRemoved = momentCardMarginBottom.replace("px", "");

    setTimeout(
      window.scrollTo({
        top: startingPosition + momentCardHeight + +pxRemoved,
        behavior: "smooth",
      }),
      100
    );

    setStartingPosition(startingPosition + momentCardHeight + +pxRemoved);
  };

  const scrollToPrevMomentHandler = () => {
    const momentCard = document.getElementById("momentCard");
    const momentCardHeight = momentCard?.clientHeight;

    let nodeStyle = window.getComputedStyle(momentCard);
    let momentCardMarginBottom = nodeStyle.getPropertyValue("margin-bottom");
    const pxRemoved = momentCardMarginBottom.replace("px", "");

    window.scrollTo({
      top: startingPosition - momentCardHeight - +pxRemoved,
      behavior: "smooth",
    });

    setStartingPosition(startingPosition + momentCardHeight + +pxRemoved);
  };

  return (
    <MomentsStyled>
      <div className="box">
        {loading && !error && <Skeleton />}
        {!loading && error && <Error text="No Moments found!" />}
      </div>

      <div className="momentsWrapper" ref={scrollRef}>
        {moments &&
          moments?.length > 0 &&
          moments.map((item, i) => (
            <MomentCard
              key={i}
              moment={item}
              nextMomentHandler={scrollToNextMomentHandler}
              prevMomentHandler={scrollToPrevMomentHandler}
            />
          ))}
      </div>

      {moments?.length && !user.id ? (
        <div className="limitedMoments">
          You have reached your moments watch limit. Please log in your account
          to get unlimited access.
          <div onClick={upgradeHandler} className="imp">
            log in
          </div>
        </div>
      ) : null}

      {!isMobile && moments && moments.length > 0 ? (
        <div onClick={scrollToNextMomentHandler} className="viewNextMoment">
          <img src={ArrowDownIcon} alt="" />
        </div>
      ) : null}
    </MomentsStyled>
  );
};

export default Moments;

const MomentsStyled = styled.div`
  padding-bottom: 5rem;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;
  position: relative;

  .viewNextMoment {
    user-select: none;
    position: fixed;
    bottom: 4.5rem;
    right: 1rem;
    background: ${(props) => props.theme.primaryGradient};
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;

    img {
      height: 1.8rem;
      width: auto;
    }
  }

  .limitedMoments {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem 5rem;
    text-align: center;
    margin-top: 5rem;
    background: ${(props) => props.theme.primaryGradient};
    color: #fff;
    font-size: 1.05rem;
    font-weight: 400;

    .imp {
      font-weight: 500;
      margin-left: 1rem;
      padding: 0.3rem 0.8rem;
      border: 1px dashed rgba(0, 0, 0, 0.5);
      display: inline-block;
      border-radius: 5px;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.8);
      }
    }
  }

  .box {
    margin: 5rem auto;
  }

  .momentsWrapper {
    transform: translateY(3.8rem);
    margin-top: -5rem;

    &:last-child {
      margin-bottom: 8rem;
    }
  }

  /* responsive */
  @media screen and (max-width: 480px) {
    .viewNextMoment {
      height: 2.8rem;
      width: 2.8rem;
      right: 0.5rem;
      bottom: 4rem;
      img {
        height: 1.5rem;
        width: auto;
      }
    }

    .limitedMoments {
      padding: 1rem;
      margin-top: 2rem;
      background: ${(props) => props.theme.primaryGradient};
      font-size: 0.75rem;
      font-weight: 400;

      .imp {
        font-weight: 600;
        margin-left: 0.5rem;
        padding: 0.2rem 0.8rem;
      }
    }
  }
`;
