import {
  GET_PROFILE_ELEMENTS_REQUEST,
  GET_PROFILE_ELEMENTS_SUCCESS,
  GET_PROFILE_ELEMENTS_FAIL,
} from "../actions/types";

const initialState = {};

const profileElements = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_ELEMENTS_REQUEST:
      return {
        getLoading: true,
      };
    case GET_PROFILE_ELEMENTS_SUCCESS:
      return {
        getLoading: false,
        getData: action.payload,
      };
    case GET_PROFILE_ELEMENTS_FAIL:
      return {
        getLoading: false,
        getError: action.payload,
      };
    default:
      return state;
  }
};

export default profileElements;
