import styled, { css } from "styled-components";

export const CardStyles = styled.div`
  margin-bottom: 2rem;
  position: relative;

  ${(props) =>
    props.metaPadding &&
    props.metaPadding === true &&
    css`
      .metaInfo {
        padding: 0.75rem 0.25rem 0 0.25rem !important;
      }
    `}

  ${(props) =>
    props.metaPadding &&
    props.metaPadding === false &&
    css`
      .metaInfo {
        padding: 0 0.25rem 0 0.25rem !important;
      }
    `}

  .seriesPrivacy {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;

    &__tooltip {
      &--floating {
        font-family: ${(props) => props.theme.montserrat}, sans-serif;
        font-size: 0.85rem;
        font-weight: 500;
        text-align: center;
        line-height: 1.3;

        .imp {
          font-weight: 600;
          text-transform: uppercase;
          background: -webkit-${(props) => props.theme.primaryGradient};
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    &__box {
      height: 2.2rem;
      width: 2.2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: ${(props) => props.theme.primaryGradient};
      cursor: pointer;

      .icon {
        height: 1.1rem;
        width: auto;
      }
    }
  }

  .close {
    position: absolute;
    top: 0.3rem;
    left: 0.6rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 4px solid #f9903d;
    background-color: #f9903d;
    cursor: pointer;
    transition: all 0.25s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    img {
      height: 0.8rem;
      width: auto;
    }
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .ribbon {
    font-family: "Poppins", sans-serif;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 3px 15px 3px 30px;
    z-index: 98;
    background: ${(props) => props.theme.primaryGradient};
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.3);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 12% 50%);
    transition: all 0.3s ease;

    p {
      font-size: 0.7rem;
      text-transform: capitalize;
      font-weight: 300;
      letter-spacing: 0.1px;
      margin: 0;
      padding: 0;
      transition: all 0.2s ease;

      span {
        font-weight: bold;
      }
    }

    &:hover {
      border: none !important;
      background: ${(props) => props.theme.primaryGradient} !important;

      p {
        transform: scale(1.1);
      }
    }
  }
  &:hover {
    .ribbon {
      border: none !important;
      background: ${(props) => props.theme.primaryGradient};

      p {
        color: #fff;
      }
    }
  }
  .thumbnail {
    width: 100%;
    height: 10rem;
    overflow: hidden;
    border-radius: 0.5rem;
    cursor: pointer;
    position: relative;
    min-height: 22vh;

    img {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
    }

    .views,
    .length,
    .price,
    .numVideos {
      font-family: brother-1816, sans-serif;
      position: absolute;
      bottom: 0.5rem;
      font-size: 0.7rem;
      padding: 0.4rem 0.5rem;
      color: #fff;
      z-index: 2;
      background-color: rgba(28, 28, 30, 1);
      border-radius: 0.3rem;
      cursor: pointer;
      text-transform: capitalize;
      line-height: 1;
    }

    .views,
    .price {
      left: 0.5rem;
    }

    .length,
    .numVideos {
      right: 0.5rem;
    }
  }

  .rewards {
    font-family: brother-1816, sans-serif;
    font-size: 0.475rem;
    padding: 0.25rem 0.4rem;
    color: #fff;
    z-index: 2;
    background-image: ${(props) => props.theme.gradientV2};
    text-transform: uppercase;
    border-radius: 0.125rem;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;

    &__icon {
      height: 0.55rem;
      opacity: 0.7;
      margin-right: 0.35rem;
      transition: all 0.2s ease;
    }

    &__text {
      opacity: 0.7;
      transition: all 0.2s ease;
    }
  }

  &:hover .rewards {
    &__text {
      opacity: 1;
    }
    &__icon {
      opacity: 1;
    }
  }

  .metaInfo {
    font-family: ${(props) => props.theme.montserrat};
    position: relative;

    &__left {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      &--avatar {
        cursor: pointer;
        height: 2.2rem;
        width: 2.2rem;
        margin-right: 0.5rem;
        border-radius: 50%;
        position: relative;

        .imageAvatar {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          object-fit: cover;
        }

        .imageBadge {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .badge {
            height: 38px;
            width: auto;
          }
        }
      }

      &--videoMeta {
        cursor: pointer;
        width: calc(100% - 2.5rem);
        padding-right: 0.5rem;

        .title {
          font-size: 0.9rem;
          font-weight: 400;
          line-height: 1.3;
          overflow-wrap: break-word;
          margin-bottom: 0 !important;
        }

        .seperatorInfo {
          font-size: 0.7rem;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .seperator {
            height: 3.5px;
            width: 3.5px;
            border-radius: 50%;
            margin: 0 0.35rem;
            background-color: rgba(255, 255, 255, 0.85);
          }

          .bold {
            font-weight: 600;
            text-decoration: underline;
            background: -webkit-${props => props.theme.primaryGradient} !important;
            -webkit-background-clip: text !important;
            -webkit-text-fill-color: transparent !important;
            cursor: pointer;
            text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.3);
            position: relative;

            &:before {
              content: "";
              position: absolute;
              left: 0;
              bottom: 2px;
              height: 1px;
              background: ${(props) => props.theme.primaryGradient};
              width: 0%;
              transition: all 0.4s ease;
            }

            &:hover:before {
              width: 100%;
            }
          }

          .light {
            color: rgba(174, 174, 178, 1);
            font-weight: 400;
            font-size: 0.6rem;
          }

          .boldV2 {
            color: rgba(174, 174, 178, 1);
            font-weight: 500;
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .thumbnail {
      height: 12rem;
      margin-bottom: 0.5rem;

      .length,
      .views,
      .numVideos,
      .price {
        font-size: 0.6rem;
      }
    }

    .selectVideo {
      height: 1.8rem;
      width: 1.8rem;
      border: 3px solid #f9903d;

      img {
        width: 1.5rem;
      }
    }

    .metaInfo {
      &__left {
        &--videoMeta {
          width: 100%;

          .title {
            font-size: 0.8rem;
            margin-bottom: 0.2rem;
          }

          .seperatorInfo {
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    .thumbnail {
      height: 11rem;
    }

    .metaInfo {
      &__left {
        &--videoMeta {
          width: 100%;
        }
      }
    }
  }
`;
