import { UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE } from "../actions/types";

const initialState = {
  userData: null,
  loading: false,
  error: null,
};

const adminUserData = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_USER_DATA_SUCCESS":
      return { ...state, userData: action.payload, loading: false };
    case "FETCH_USER_DATA_FAILURE":
      return { ...state, error: action.error, loading: false };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        error: null,
        userData: action.updatedUserData,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default adminUserData;
