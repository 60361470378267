import React from "react";
import styled from "styled-components";
import config from "../../config";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import { useSelector } from "react-redux";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/eye.sutra/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/i.sutra.yoga/" },
];

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { data: siteBranding } = useSelector((state) => state.branding);

  return (
    <FooterComponent>
      <div className="footer--logo">
        <img
          className="footer--logo-icon"
          src={config.aws.CLOUDFRONT_URL + siteBranding?.logo_light}
          alt={siteBranding?.site_name}
        />

        <h1 className="footer--logo-title">{siteBranding?.site_name}</h1>
      </div>

      <p className="footer--copyright">
        Copyright © {currentYear} {siteBranding?.copyright_name}. All Rights
        Reserved.
      </p>
    </FooterComponent>
  );
};

export default Footer;

const FooterComponent = styled.div`
  width: 100%;
  margin-bottom: 3%;
  min-height: 8rem;
  max-height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5rem;

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url("/assets/footer/bg.jpg");
    content: "";
    z-index: 1;
    opacity: 0.5;
  }

  .footer {
    &--logo {
      cursor: pointer;
      transform: translateX(-2rem);
      display: flex;
      align-items: center;
      gap: 0.5rem;
      z-index: 2;

      &-icon {
        height: auto;
        width: 3rem;
      }

      &-title {
        font-size: 1.75rem;
        text-transform: lowercase;
        color: white !important;
        font-weight: 400;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    &--copyright {
      color: #c6c9d8;
      font-size: 0.9rem;
      z-index: 2;
    }
  }

  @media (max-width: 768px) {
    min-height: 7rem;

    .footer {
      &--logo {
        height: 3rem;
      }

      &--copyright {
        font-size: 0.8rem;
      }
    }
    .text {
      &__main {
        height: 1.5rem;
        width: auto;
        margin-bottom: 25px;
        margin-left: 0.1rem;
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 0;

    .footer {
      &--logo {
        height: 3.2rem;
        transform: translateX(0);
        margin-bottom: 0.6rem;
        margin-left: -12px;
      }

      &--copyright {
        font-size: 0.7rem;
      }
    }
    .text {
      &__main {
        height: 1.5rem;
        width: auto;
        margin-bottom: 25px;
        margin-left: 0.1rem;
      }
    }
  }

  @media (max-width: 480px) {
    margin-bottom: 4rem;
    padding: 1rem 0;
  }
  .text {
    &__main {
      height: 1.5rem;
      width: auto;
      margin-bottom: 20px;
      margin-left: -1.3rem;
    }
  }
  @media screen and (max-width: 414px) {
    .text {
      &__main {
        height: 1.5rem;
        width: auto;
        margin-bottom: 25px;
        margin-left: 8px;
      }
    }
  }
`;
