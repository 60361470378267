export const SLIDERSETTINGV1 = {
  infinite: false,
  arrows: false,
  dots: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  speed: 500,

  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 481,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const SLIDERSETTING_MOBILE = {
  infinite: true,
  arrows: false,
  dots: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  centerPadding: "20px",
  centerMode: true,
};

export const SLIDERSETTING_MOMENT = {
  infinite: false,
  arrows: false,
  dots: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  speed: 500,

  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 481,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ],
};

export const SLIDERSETTING_MOMENT_MOBILE_V1 = {
  infinite: true,
  arrows: false,
  dots: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  speed: 500,
  centerPadding: "10px",
  centerMode: true,
};

export const SLIDERSETTING_MOMENT_MOBILE_V2 = {
  infinite: false,
  arrows: false,
  dots: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  speed: 500,
};

export const FEATURED_THREAD_SLIDERSETTING = {
  arrows: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplaySpeed: 3000,
  centerPadding: "20px",
  centerMode: true,
  speed: 500,
  swipe: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const FEATURED_THREAD_SLIDERSETTING_SWIPEDISABLED = {
  arrows: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplaySpeed: 3000,
  centerPadding: "20px",
  centerMode: true,
  speed: 500,
  swipe: false,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
