import styled, { css } from "styled-components";

const Button = styled.button`
  font-size: 0.95rem;
  text-transform: uppercase;
  padding: 0.7rem 2.2rem;
  border-radius: 10rem;
  outline: none;
  font-family: brother-1816, sans-serif;
  font-weight: 500;
  color: #fff;
  background: transparent;
  border: 1px solid #fff;

  ${(props) =>
    props.primary &&
    css`
      border: 1px solid transparent;
      background: ${(props) => props.theme.primaryGradient};
      font-family: brother-1816, sans-serif;
    `}
`;

export default Button;
