import {
  PROFILE_ELEMENT_VIDEOS,
  PROFILE_ELEMENT_VIDEOS_SECOND
} from "../actions/types";


const profileElementsVideo = (state = { videoloading: true }, action) => {
  switch (action.type) {
    case PROFILE_ELEMENT_VIDEOS:
      return {
        videoloading: false,
        uservideos: action.payload,
        totalPage: action.totalPage
      };
    case PROFILE_ELEMENT_VIDEOS_SECOND:
      const allVideos = [...state.uservideos, ...action.payload];
      return {
        videoloading: false,
        uservideos: allVideos,
        totalPage: action.totalPage
      };
    default:
      return state;
  }
};

export default profileElementsVideo;
