import React, { memo, useContext, useState } from "react";
import styled, { keyframes } from "styled-components";
import CaretDownIcon from "../../../assets/Icons/caret-down.svg";
import ConfirmModal from "./ConfirmModal";
import { useSelector, useDispatch } from "react-redux";
import useOnclickOutside from "react-cool-onclickoutside";
import Button from "../../../styles/Button";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import { toast } from "react-toastify";
import {
  SET_CURRENT_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_ID,
  UPDATE_SUBSCRIPTION_PLANS_REQUEST,
  UPDATE_SUBSCRIPTION_PLANS_SUCCESS,
  UPDATE_USER_ROLE,
} from "../../../actions/types";
import api from "../../../services/api";
import { notificationMessage } from "../../../utils/toastNotifications";
import configMerged from "../../../config";

const UpdateSubscriptionModal = ({
  closeMangeSubscription,
  doNotClose,
  close,
  plans,
  isFetching,
  currentSubscription,
  isUpdating,
  hasPaymentMethodAttached,
  coOpSubscription,
}) => {
  const [, setShowConfirmModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const { id, stripeCustomerId, subscriptionId, userrole } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { setShowSidebar, setShowMyAccount } = useContext(GlobalContext);

  const confirmModalRef = useOnclickOutside(() => {
    setShowConfirmModal(false);
  });

  const handleSubscrition = (selectedPlan) => {
    const payload = {
      customerId: stripeCustomerId,
      subscriptionId: currentSubscription.id
        ? currentSubscription.id
        : subscriptionId,
      price: selectedPlan,
    };
    updateSubscription(payload)
      .then((res) => {
        closeMangeSubscription(true);
        setShowMyAccount(false);
        setShowSidebar(false);
        history.push(`/update_subscription/${id}`);
      })
      .catch(console.log());
  };
  const updateSubscription = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: UPDATE_SUBSCRIPTION_PLANS_REQUEST,
        });
        const res = await api.post("stripe/updateSubscriptionPlans", payload);
        dispatch({
          type: UPDATE_SUBSCRIPTION_PLANS_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: UPDATE_USER_ROLE,
          payload: res.data.updatedUserRole,
        });
        dispatch({
          type: UPDATE_SUBSCRIPTION_ID,
          payload: res.data.currentSubscription.id,
        });
        dispatch({
          type: SET_CURRENT_SUBSCRIPTION,
          payload: res.data.currentSubscription,
        });
        toast.success(notificationMessage.subscriptionUpdated, {
          autoClose: 2000,
        });

        resolve(res.data.updatedUserRole);
      } catch (error) {
        reject(notificationMessage.failedToUpdate);
      }
    });
  };
  const hasSubscribed = (plan) => {
    try {
      if (
        userrole &&
        userrole > 0 &&
        plan.id == configMerged.stripe.SUBSCRIPTION_PLAN_5 &&
        coOpSubscription
      ) {
        return true;
      }
      if (
        plan &&
        currentSubscription &&
        plan.id === currentSubscription.plan.id
      )
        return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateSubscription = (plan) => {
    if (hasPaymentMethodAttached && !hasSubscribed(plan)) {
      setCurrentPlan(plan);
    } else {
      toast.error(notificationMessage.subscribePlan);
    }
  };
  const capitalizeFirstLetter = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  };
  const getPlanPriceWithInterval = (plan) => {
    return (
      "$" +
      parseInt(plan.unit_amount / 100) +
      " / " +
      capitalizeFirstLetter(plan.recurring.interval)
    );
  };
  return (
    <UpdateSubscriptionModalStyled ref={doNotClose}>
      <div onClick={close} className="header">
        <img src={CaretDownIcon} alt="" className="closeIcon" />
        <div className="title">Update Subscription</div>
      </div>

      <div className="content">
        {isFetching && "Fetching records..."}

        {plans?.map((plan) => (
          <div className="content__item">
            {currentPlan && plan.id === currentPlan.id && (
              <ConfirmModal
                handleSubscrition={handleSubscrition}
                doNotClose={confirmModalRef}
                close={() => setCurrentPlan()}
                currentPlan={currentPlan}
              />
            )}
            <h2 className="content__item--name">
              {plan.nickname}&nbsp;{getPlanPriceWithInterval(plan)}
            </h2>
            <p className="content__item--description">{plan.description}</p>
            <div className="buttonMarginLeft">
              {
                <Button
                  grey={hasSubscribed(plan) === true}
                  rightAlign
                  onClick={() => handleUpdateSubscription(plan)}
                >
                  {!isUpdating && hasSubscribed(plan)
                    ? "PURCHASED"
                    : "PURCHASE"}
                </Button>
              }
            </div>
          </div>
        ))}
      </div>
    </UpdateSubscriptionModalStyled>
  );
};

export default memo(UpdateSubscriptionModal);

const openAnimation = keyframes`
  from {
    transform: translateY(5rem);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const UpdateSubscriptionModalStyled = styled.div`
  position: absolute;
  bottom: 0;
  display: grid;
  left: 0;
  z-index: 5;
  width: 100%;
  height: calc(100% - 2.5rem);
  background-color: #3a3a3c;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 1rem 2rem;
  animation: ${openAnimation} 0.35s ease-out;
  border-top: 1px solid rgba(112, 112, 112, 0.25);

  .heQSeK {
    padding: 0.4rem 1rem !important;
    background: ${(props) => props.theme.primaryGradient} !important;
    color: #fff !important;
    border: 1px solid rgb(248 139 68) !important;
    border-radius: 3px !important;
    -webkit-letter-spacing: 1.1px !important;
    -moz-letter-spacing: 1.1px !important;
    -ms-letter-spacing: 1.1px !important;
    letter-spacing: 1.1px !important;
    display: block !important;
    margin-left: auto !important;
    line-height: normal;
  }
  .dzZtuT {
    padding: 0.4rem 1rem !important;
    border-radius: 3px !important;
    letter-spacing: 1.1px !important;
    display: block !important;
    margin-left: auto !important;
    background: #383838 !important;
    border: 1px solid #383838 !important;
    color: #aaaaaa !important;
    line-height: normal;
  }
  .fhcQUF .confirmHeader .buttonsWrapper {
    line-height: 1.8 !important;
  }
  .gpibBh {
    letter-spacing: 1.1px !important;
    background: #383838 !important;
    border: 1px solid #383838 !important;
    color: #aaaaaa !important;
  }
  .dNOCRo {
    background: ${(props) => props.theme.primaryGradient} !important;
    color: #fff !important;
    border: 1px solid rgb(248 139 68) !important;
    border-radius: 3px !important;
    letter-spacing: 1.1px !important;
  }

  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(112, 112, 112, 0.5);
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    cursor: pointer;

    .closeIcon {
      height: 0.5rem;
      width: auto;
      margin-right: 1rem;
    }

    .title {
      font-size: 0.9rem;
      font-weight: 500;
      text-transform: capitalize;
      color: #f2f2f7;
    }
  }

  .content {
    width: 100%;
    max-height: 18rem;
    overflow: auto;
    line-height: 1.8 !important;
    font-size: 1rem !important;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #3a3a3c;
      border-radius: 7px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(246, 92, 139);
    }

    &__item {
      position: relative;
      padding: 0.5rem 0;
      font-size: 0.85rem;
      font-weight: 600;
      color: #636366;
      border-bottom: 2px solid rgba(99, 99, 102, 0.5);
      cursor: pointer;
      &--name {
        font-family: brother-1816, sans-serif;
        font-size: 1rem;
        text-transform: uppercase;
        color: #fff;
        font-weight: 500;
        line-height: 3;
      }
      @media screen and (max-width: 480px) {
        font-size: 10px;
      }
      .subscribe {
        cursor: pointer;
        line-height: 1;
        font-family: brother-1816, sans-serif;
        padding: 0.4rem 1rem;
        text-transform: uppercase;
        font-size: 0.9rem;
        -webkit-letter-spacing: 2px;
        -moz-letter-spacing: 2px;
        -ms-letter-spacing: 2px;
        letter-spacing: 2px;
        font-weight: 500;
        margin-bottom: 1rem;
        background: transparent
          linear-gradient(
            130deg,
            #f9903d 14%,
            #f88946 23%,
            #f8795f 37%,
            #f75e87 55%,
            #f75b8c 57%
          )
          0% 0% no-repeat padding-box;
        border-radius: 3px;
        width: 132px;
        display: block;
        margin-left: auto;
        margin-right: 5px;
        color: white;
      }
    }

    .active {
      color: #f2f2f7;
    }
    .confirm {
      width: 50%;
      background-color: white;
    }
  }
  .buttonMarginLeft {
    margin-bottom: 14px;
    margin-right: 16px;
  }
  @media screen and (max-width: 480px) {
    .header {
      .title {
        font-size: 0.8rem;
      }
    }

    .content {
      &__item {
        padding: 0.5rem 0;
        font-size: 0.8rem;
      }
    }
  }
  .buttonMarginLeft {
    margin-bottom: 14px;
    margin-right: 16px;
  }
`;
