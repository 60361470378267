import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import CaretDownIcon from "../../../assets/Icons/caret-down.svg";
import { GlobalContext } from "../../../context/GlobalContext";
import { useHistory } from "react-router-dom";

const CollaborationMessageModel = ({
  selectedRequest,
  close,
  approveRequest,
}) => {
  const history = useHistory();
  const { setShowChatList } = useContext(GlobalContext);

  const onUsernameClickHandler = () => {
    close();
    setShowChatList(false);
    history.push(`/channel/${selectedRequest?.collaborator?.username}`);
    return;
  };

  return (
    <CollaborationMessageModelStyled>
      <div onClick={close} className="header">
        <img src={CaretDownIcon} alt="" className="closeIcon" />
        <div className="title">
          Collaboration Message by{" "}
          <span onClick={onUsernameClickHandler}>
            {selectedRequest?.collaborator?.username}
          </span>
        </div>
      </div>

      <div className="content">
        <p className="content__text">{selectedRequest?.message}</p>

        <button onClick={approveRequest} className="sendMessage">
          Accept request
        </button>
      </div>
    </CollaborationMessageModelStyled>
  );
};

export default CollaborationMessageModel;

const openAnimation = keyframes`
  from {
    transform: translateY(5rem);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CollaborationMessageModelStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  height: calc(100% - 15rem);
  background-color: #3a3a3c;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  animation: ${openAnimation} 0.35s ease-out;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid rgba(112, 112, 112, 0.25);
  font-family: ${(props) => props.theme.montserrat};

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3a3a3c;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(246, 92, 139);
  }

  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(112, 112, 112, 0.5);
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
    padding: 0.5rem 1rem;

    .closeIcon {
      height: 0.5rem;
      width: auto;
      margin-right: 1rem;
    }

    .title {
      font-size: 0.9rem;
      font-weight: 400;
      color: #f2f2f7;

      span {
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .content {
    padding: 0 1.2rem;

    &__text {
      font-size: 0.85rem;
      font-weight: 400;
      color: #f2f2f7;
      line-height: 1.5;
      margin-bottom: 0.5rem;

      width: 100%;
      border-radius: 0.2rem;
      padding: 0.7rem 1rem;
      background-color: #48484a;
      color: #fff;
    }

    .sendMessage {
      width: 100%;
      padding: 0.5rem 0;
      font-size: 0.72rem;
      color: #fff;
      font-weight: 600;
      border-radius: 10rem;
      background: linear-gradient(to right bottom, #f9903d, #f75b8c);
      border: none;
      outline: none;
      font-family: ${(props) => props.theme.montserrat};
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      padding: 0 1rem;

      &__text {
        font-size: 0.75rem;
      }

      .sendMessage {
        font-size: 0.7rem;
      }
    }
  }
`;
