import React, { useState, useContext } from "react";
import styled, { keyframes } from "styled-components";
import MenuIcon from "./icons/menuIcon.svg";
import Arrow from "./icons/arrow.svg";
import MomentIcon from "../../icons/moment.svg";
import ThreadIcon from "../../icons/post.svg";
import { GlobalContext } from "../../../context/GlobalContext";
import { useSelector, useDispatch } from "react-redux";
import { useFetch } from "../../../hooks/useFetch";
import { Link, useHistory } from "react-router-dom";
import { disconnectSocket } from "../../../socket";
import { logoutUser, updateStatus } from "../../../actions";
import { IsutraIcon } from "../../Icons";
import { HistoryShell, SubscriptionIconQuad } from "../../Icons";
import config from "../../../config";
import socketIo from "socket.io-client";
import { PiUserCircleGearFill } from "react-icons/pi";

let socket;
const ENDPOINT = config.sockets.socketBackendUrl;

const Sidebar = ({ close }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [online, setOnline] = useState(true);
  const [categoryDropdown, setCategoryDropdown] = useState(false);
  const [playlistDropdown, setPlaylistDropdown] = useState(false);
  const [otherDropdown, setOtherDropdown] = useState(false);
  const [seriesDropdown, setSeriesDropdown] = useState(false);
  const [activeStatusUser, setActiveUser] = useState([]);

  const activeUser = useSelector((state) => state.activeUser);
  const { loading, data } = useFetch(`${config.api.url}videos/categories`);

  const {
    showSidebar,
    setShowSidebar,
    setShowPlaylistModel,
    setShowGlobalPlaylistModel,
    setShowMyAccount,
    setShowMyPortal,
    setPortalUsername,
    setShowChatList,
    setShowCreateSeriesModel,
  } = useContext(GlobalContext);

  const isBadgeOrAvatar = () => {
    const image = !user.avatar ? user.badge : user.avatar;

    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <div className="imageBadge">
          <img className="badge" src={image} alt="" />
        </div>
      );
    } else {
      return (
        <img
          className="imageAvatar"
          src={config.aws.CLOUDFRONT_URL + image}
          alt=""
        />
      );
    }
  };

  const handleSeriesDropdownClick = () => {
    if (user.userrole === 2) {
      setSeriesDropdown(!seriesDropdown);
    } else {
      setShowSidebar(false);
      history.push(`/series`);
    }
  };

  const handleStatus = async (online) => {
    setOnline(!online);
    // const updatestatusData = dispatch(updateStatus(user, !online));
    socket = socketIo(ENDPOINT, { transports: ["websocket"] });
    socket.on("userStatus", (data) => {
      setActiveUser(data);
    });
  };
  const handleLogout = () => {
    disconnectSocket();
    dispatch(logoutUser());
    localStorage.removeItem("userData");
    sessionStorage.clear();
    setOnline(true);
    setCategoryDropdown(false);
    setPlaylistDropdown(false);
    setOtherDropdown(false);
    setSeriesDropdown(false);
    setActiveUser([]);
    setShowSidebar(false);
    history.push("/");
  };

  return (
    <SidebarStyled className={`${showSidebar && "SidebarOpened"}`}>
      <div className="userMeta">
        {user.userrole === undefined ? (
          <div onClick={() => setShowSidebar(true)}>
            <IsutraIcon />
          </div>
        ) : (
          <div className="userMeta__avatar">{isBadgeOrAvatar()}</div>
        )}

        <div className="userMeta__username">{user.username}</div>

        <div
          onClick={handleLogout}
          // onClick={() => {
          //   setShowSidebar(false);
          //   disconnectSocket();
          //   dispatch(logoutUser());
          //   history.push("/");
          // }}
          className="userMeta__logout"
        >
          Logout
        </div>

        <div className="userMeta__role">
          {user.userrole === 0
            ? "freeloader"
            : user.userrole === 1
            ? "CO-OP"
            : user.userrole === 2
            ? "warrior"
            : "Isutra"}
        </div>
        {user.userrole ? (
          <div className="userMeta__onlineState">
            <label className="userMeta__onlineState--switch">
              <>
                {online ? (
                  <>
                    <input
                      className={`${online && "inputChecked"}`}
                      type="checkbox"
                      checked={online}
                      onChange={() => handleStatus(online)}
                    />
                    <span
                      className={`slider ${online && "sliderChecked"}`}
                    ></span>

                    <div
                      style={{
                        color: `${online ? "#2fdd92" : "#ececec"}`,
                      }}
                      className="userMeta__onlineState--switch-text"
                    >
                      ONLINE
                    </div>
                  </>
                ) : online === false ? (
                  <>
                    <input
                      className={`${online && "inputChecked"}`}
                      type="checkbox"
                      checked={online}
                      onChange={() => handleStatus(online)}
                    />
                    <span
                      className={`slider ${online && "sliderChecked"}`}
                    ></span>

                    <div
                      style={{
                        color: `${online ? "#2fdd92" : "#ececec"}`,
                      }}
                      className="userMeta__onlineState--switch-text"
                    >
                      OFFLINE
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            </label>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="menus">
        {user.isAdmin === true && (
          <div
            className="menus__item"
            onClick={() => {
              history.push("/adminDashboard");
              setShowSidebar(false);
            }}
          >
            <div className="menus__item--left">
              <PiUserCircleGearFill className="icon" size={20} />
              <div className="name">Admin Dashboard</div>
            </div>

            <div className="menus__item--right">
              <img src={Arrow} alt="" className="arrow" />
            </div>
          </div>
        )}
        {user.username ? (
          <Link to={`/channel/${user.username}`} style={{ width: "100%" }}>
            <div
              className="menus__item"
              onClick={() => {
                if (user.username) {
                  setPortalUsername(user.username);
                  setShowMyPortal(true);
                  setShowSidebar(false);
                  close();
                } else {
                  setShowSidebar(false);
                }
              }}
            >
              <div className="menus__item--left">
                <img className="icon" src={MenuIcon} alt="" />
                <div className="name">My Portal</div>
              </div>

              <div className="menus__item--right">
                <img src={Arrow} alt="" className="arrow" />
              </div>
            </div>
          </Link>
        ) : (
          <div
            className="menus__item"
            onClick={() => {
              if (user.username) {
                setPortalUsername(user.username);
                setShowMyPortal(true);
                setShowSidebar(false);
                close();
              } else {
                setShowSidebar(false);
              }
            }}
          >
            <div className="menus__item--left">
              <img className="icon" src={MenuIcon} alt="" />
              <div className="name">My Portal</div>
            </div>

            <div className="menus__item--right">
              <img src={Arrow} alt="" className="arrow" />
            </div>
          </div>
        )}
        <div
          onClick={() => {
            if (user.username) {
              setShowMyAccount(true);
              close();
            } else {
              setShowSidebar(false);
            }
          }}
          className="menus__item"
        >
          <div className="menus__item--left">
            <img className="icon" src={MenuIcon} alt="" />
            <div className="name">My Account</div>
          </div>

          <div className="menus__item--right">
            <img src={Arrow} alt="" className="arrow" />
          </div>
        </div>

        <div
          onClick={() => {
            if (user.username) {
              setPlaylistDropdown(!playlistDropdown);
            } else {
              setShowSidebar(false);
            }
          }}
          className="menus__item"
        >
          <div className="menus__item--left">
            <img className="icon" src={MenuIcon} alt="" />
            <div className="name">Playlists</div>
          </div>

          <div className="menus__item--right">
            <img
              style={{
                transform: `${
                  playlistDropdown ? "rotate(90deg)" : "rotate(0deg)"
                }`,
              }}
              src={Arrow}
              alt=""
              className="arrow"
            />
          </div>
        </div>

        {playlistDropdown && (
          <div className="menus__dropdown">
            <div
              onClick={() => {
                setShowSidebar(false);
                setShowGlobalPlaylistModel(true);
              }}
              className="menus__dropdown--item"
            >
              <div className="hoverArrow"></div>
              <div className="name">My playlists</div>
            </div>
            <div
              onClick={() => {
                if (user.username) {
                  setShowSidebar(false);
                  setShowPlaylistModel(true);
                } else {
                  setShowSidebar(false);
                }
              }}
              className="menus__dropdown--item"
            >
              <div className="hoverArrow"></div>
              <div className="name">Create New Playlist</div>
            </div>
          </div>
        )}

        <div onClick={handleSeriesDropdownClick} className="menus__item">
          <div className="menus__item--left">
            <img className="icon" src={MenuIcon} alt="" />
            <div className="name">Series</div>
          </div>

          <div className="menus__item--right">
            <img
              style={{
                transform: `${
                  seriesDropdown ? "rotate(90deg)" : "rotate(0deg)"
                }`,
              }}
              src={Arrow}
              alt=""
              className="arrow"
            />
          </div>
        </div>

        {user.userrole === 2 && seriesDropdown && (
          <div className="menus__dropdown">
            <div
              onClick={() => {
                setShowSidebar(false);
                history.push(`/series`);
              }}
              className="menus__dropdown--item"
            >
              <div className="hoverArrow"></div>
              <div className="name">Series Home</div>
            </div>
            <div
              onClick={() => {
                setShowSidebar(false);
                history.push(`/channel/${user.username}?tab=4`);
                setShowCreateSeriesModel(true);
              }}
              className="menus__dropdown--item"
            >
              <div className="hoverArrow"></div>
              <div className="name">Create New Series</div>
            </div>
            <div className="menus__dropdown--item">
              <div className="hoverArrow"></div>
              <div
                className="name"
                onClick={() => {
                  setShowSidebar(false);
                }}
              >
                <Link to={`/channel/${user.username}?tab=4`}>
                  Manage Series
                </Link>
              </div>
            </div>
          </div>
        )}

        {/* <div
          onClick={() => {
            if (user.username) {
              setCategoryDropdown(!categoryDropdown);
            } else {
              setShowSidebar(false);
            }
          }}
          className="menus__item"
        >
          <div className="menus__item--left">
            <CategoriesIcon className="icon" />
            <div className="name">Categories</div>
          </div>

          <div className="menus__item--right">
            <img
              style={{
                transform: `${categoryDropdown ? "rotate(90deg)" : "rotate(0deg)"
                  }`,
              }}
              src={Arrow}
              alt=""
              className="arrow"
            />
          </div>
        </div> */}

        {/* {categoryDropdown && (
          <div className="menus__dropdown">
            {loading && (
              <div className="menus__dropdown--loader">Loading...</div>
            )}
            {!loading &&
              data.categories &&
              data.categories.length > 0 &&
              data.categories.map((cat, i) => (
                <div
                  onClick={() => {
                    history.push(`/categoryid/${cat.id}`);
                    setShowSidebar(false);
                  }}
                  key={i}
                  className="menus__dropdown--item"
                >
                  <div className="hoverArrow"></div>
                  <div className="name">{cat.name}</div>
                </div>
              ))}
          </div>
        )} */}

        <div
          onClick={() => {
            if (!user.username) {
              setShowSidebar(false);
            } else {
              setShowSidebar(false);
              history.push("/moments");
            }
          }}
          className="menus__item"
        >
          <div className="menus__item--left">
            <img src={MomentIcon} className="icon" />
            <div className="name">Explore Moments</div>
          </div>

          <div className="menus__item--right">
            <img src={Arrow} alt="" className="arrow" />
          </div>
        </div>

        <div
          onClick={() => {
            if (!user.username) {
              setShowSidebar(false);
            } else {
              setShowSidebar(false);
              history.push("/threads");
            }
          }}
          className="menus__item"
        >
          <div className="menus__item--left">
            <img src={ThreadIcon} className="icon" />
            <div className="name">Explore Threads</div>
          </div>

          <div className="menus__item--right">
            <img src={Arrow} alt="" className="arrow" />
          </div>
        </div>

        <div
          onClick={() => {
            if (!user.username) {
              setShowSidebar(false);
            } else {
              setShowSidebar(false);
              history.push("/feed/history");
            }
          }}
          className="menus__item"
        >
          <div className="menus__item--left">
            <HistoryShell className="icon" />
            <div className="name">History</div>
          </div>

          <div className="menus__item--right">
            <img src={Arrow} alt="" className="arrow" />
          </div>
        </div>

        <div
          onClick={() => {
            if (!user.username) {
              setShowSidebar(false);
            } else {
              setShowSidebar(false);
              setShowChatList(true);
            }
          }}
          className="menus__item"
        >
          <div className="menus__item--left">
            <SubscriptionIconQuad className="icon" />
            <div className="name">Connections</div>
          </div>

          <div className="menus__item--right">
            <img src={Arrow} alt="" className="arrow" />
          </div>
        </div>

        {/* <div
          onClick={() => {
            if (!user.username) {
              setShowSidebar(false);
            } else {
              setOtherDropdown(!otherDropdown);
            }
          }}
          className="menus__item"
        >
          <div className="menus__item--left">
            <img className="icon" src={MenuIcon} alt="" />
            <div className="name">Other</div>
          </div>

          <div className="menus__item--right">
            <img
              style={{
                transform: `${otherDropdown ? "rotate(90deg)" : "rotate(0deg)"
                  }`,
              }}
              src={Arrow}
              alt=""
              className="arrow"
            />
          </div>
        </div>

        {otherDropdown && (
          <div className="menus__dropdown">
            <div
              onClick={() => {
                history.push(`other-page`);
                setShowSidebar(false);
              }}
              className="menus__dropdown--item"
            >
              <div className="hoverArrow"></div>
              <div className="name">Non Important Page</div>
            </div>
          </div>
        )} */}
      </div>

      <div
        onClick={() => {
          if (!user.username) {
            setShowSidebar(false);
          }
        }}
        className="footer"
      >
        {/* <div className="footer__item">
          <i className="icon fas fa-cog"></i>
          <div className="name">Settings</div>
        </div> */}
      </div>
    </SidebarStyled>
  );
};

export default Sidebar;

const openDropdown = keyframes`
  from {
    transform: translateY(-4rem) scale(0.7);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const SidebarStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  transform: translateX(-100%);
  width: 350px;
  background: #2c2c2e;
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 1.5rem;
  transition: all 0.3s;
  z-index: 1000;
  padding: 2rem 0 1rem 0;
  font-family: ${(props) => props.theme.montserrat};
  transition: all 1s ease;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3a3a3c;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.primaryColor1};
  }

  .userMeta {
    width: 90%;
    margin: 0 auto 2.5rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__avatar {
      cursor: pointer;
      height: 5.5rem;
      width: 5.5rem;
      border-radius: 50%;
      position: relative;
      margin-bottom: 0.5rem;

      .imageBadge {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .badge {
          height: 5rem;
          width: auto;
        }
      }

      .imageAvatar {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    &__username {
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      font-weight: 400;
      cursor: pointer;
      line-height: 1;
      margin-bottom: 1rem;

      i {
        margin-left: 0.5rem;
      }
    }

    &__logout {
      cursor: pointer;
      line-height: 1;
      font-family: brother-1816, sans-serif;
      padding: 0.3rem 0.8rem;
      text-transform: uppercase;
      font-size: 0.82rem;
      font-weight: 500;
      margin-bottom: 1rem;
      background-color: #fff;
      color: ${(props) => props.theme.primaryColor1};
      border-radius: 1px;
      animation: ${openDropdown} 0.2s ease;
    }

    &__role {
      cursor: pointer;
      line-height: 1;
      font-family: brother-1816, sans-serif;
      padding: 0.4rem 1rem;
      text-transform: uppercase;
      font-size: 0.9rem;
      letter-spacing: 2px;
      font-weight: 500;
      margin-bottom: 1rem;
      background: ${(props) => props.theme.primaryGradient};
      border-radius: 3px;
    }

    &__onlineState {
      &--switch {
        position: relative;
        display: inline-block;
        width: 100px;
        height: 25px;
        cursor: pointer;

        &-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 0.6rem;
          font-family: brother-1816, sans-serif;
          text-transform: uppercase;
          color: #ececec;
        }

        input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        input:checked + .slider {
          border: 2px solid #2fdd92;
        }

        input:checked + .slider:before {
          background-color: #2fdd92;
          transform: translateX(72px) translateY(-8px);
        }

        input:checked &-text {
          color: #2fdd92 !important;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: transparent;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 10rem;
          border: 2px solid #ececec;

          &:before {
            position: absolute;
            bottom: -6px;
            left: 3px;
            transform: translateY(-50%);
            content: "";
            height: 17px;
            width: 17px;
            background-color: #ececec;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .menus {
    width: 90%;
    margin: 0 auto 5rem auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__dropdown {
      background-color: #3a3a3c;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      animation: ${openDropdown} 0.2s ease;
      transition: all 0.2s ease;

      padding: 1rem;
      width: 100%;
      border-radius: 0.3rem;
      margin-bottom: 1rem;

      &--loader {
        font-size: 1rem;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1;
        font-family: brother-1816, sans-serif;
        color: rgb(246, 92, 139);
      }

      &--item:not(:last-child) {
        margin-bottom: 1rem;
      }

      &--item {
        display: flex;
        align-items: center;
        cursor: pointer;

        .name {
          font-size: 0.8rem;
          font-weight: 500;
          text-transform: capitalize;
          line-height: 1;
        }

        .hoverArrow {
          height: 4px;
          border-radius: 2px;
          width: 0;
          background: ${(props) => props.theme.primaryGradient};
          margin-right: 0.25rem;
          transition: all 0.2s ease;
        }
      }

      &--item:hover .hoverArrow {
        width: 1.5rem;
        margin-right: 0.5rem;
      }
    }

    &__item {
      margin-bottom: 1rem;
      background-color: #3a3a3c;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 2.8rem;
      padding: 0 1rem;
      width: 100%;
      border-radius: 0.3rem;
      cursor: pointer;

      &--left {
        display: flex;
        align-items: center;

        .icon {
          height: 1.2rem;
          width: auto;
          margin-right: 0.6rem;
        }

        .name {
          font-size: 0.9rem;
          font-weight: 500;
          text-transform: capitalize;
        }
      }

      &--right {
        .arrow {
          height: 0.8rem;
          width: auto;
          transition: all 0.2s ease;
        }
      }
    }
  }

  .footer {
    width: 90%;
    margin: 0 auto;

    &__item {
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon {
        margin: 0.5rem;
        font-size: 1.5rem;
      }

      .name {
        font-size: 0.9rem;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }
`;
