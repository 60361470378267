import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Skeleton from "../../../skeletons/VideosSkeleton";
import { Col, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import EditClipForm from "../../UploadClipComponent/EditClipForm";
import { GlobalContext } from "../../../context/GlobalContext";
import VideoCard from "../../Cards/VideoCard";
import ConfirmationModel from "../../ConfirmationModel";
import { useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
import {
  deleteVideoV2,
  deleteVideoS3,
  getUserVideos,
  getUserStorageInfo,
  getUserVideosss,
} from "../../../actions";
import { notificationMessage } from "../../../utils/toastNotifications";
import { toast } from "react-toastify";
import { VIDEO_SELF, VIDEO_OTHER } from "../../../utils/menuOptions";
import Loader from "../../Elements/Loader";
import Error from "../../Elements/Error";
import {
  VIDEO_UPDATE_RESET,
  VIDEO_UPLOAD_RESET,
  VIDEO_DELETE_RESET,
  ADD_VIDEO_TO_SERIES_RESET,
  REMOVE_VIDEO_FROM_SERIES_RESET,
} from "../../../actions/types";

const VideosTab = () => {
  const dispatch = useDispatch();
  const isSelf = useSelector((state) => state.profile.isMe);
  const { ref: trackerRef, inView: trackerIsVisible } = useInView();
  const profile = useSelector((state) => state.profile);
  const {
    videos: userVideos,
    loading: userVideosLoading,
    totalpage: totalpage,
    error: userVideosError,
  } = useSelector((state) => state.userVideos);
  const { videoUploadInfo, videoUpdateInfo, videoDeleteInfo } = useSelector(
    (state) => state.video
  );
  const { message: addVideoToSeriesSuccess } = useSelector(
    (state) => state.addVideoToSeries
  );
  const { message: removeVideoFromSeriesSuccess } = useSelector(
    (state) => state.removeVideoFromSeries
  );


  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const {
    setShowEditClipModel,
    deleteVideoConfirmationModel,
    setDeleteVideoConfirmationModel,
  } = useContext(GlobalContext);
  const [videoPage, setVideoPage] = useState(1);
  const [videoLimit, setVideoLimit] = useState(8);

  useEffect(() => {
    if (profile && profile?.id) {
      const videoObj = {
        page: videoPage,
        limit: videoLimit,
      };
      dispatch(
        getUserVideos(
          {
            showall: false,
          },
          profile?.id,
          videoObj
        )
      );
    }

    if (
      videoUploadInfo ||
      videoUpdateInfo ||
      videoDeleteInfo ||
      addVideoToSeriesSuccess ||
      removeVideoFromSeriesSuccess
    ) {
      if (profile && profile?.id) {
        const videoObj = {
          page: videoPage,
          limit: videoLimit,
        };
        dispatch(
          getUserVideos(
            {
              showall: false,
            },
            profile?.id,
            videoObj
          )
        );
      }

      dispatch({
        type: VIDEO_UPLOAD_RESET,
      });

      dispatch({
        type: VIDEO_UPDATE_RESET,
      });

      dispatch({
        type: ADD_VIDEO_TO_SERIES_RESET,
      });

      dispatch({
        type: REMOVE_VIDEO_FROM_SERIES_RESET,
      });

      if (videoDeleteInfo) {
        toast.success(notificationMessage.videoDeleted);

        // update storage information
        dispatch(getUserStorageInfo());

        dispatch({
          type: VIDEO_DELETE_RESET,
        });
      }
    }
  }, [
    videoUpdateInfo,
    videoUploadInfo,
    videoDeleteInfo,
    addVideoToSeriesSuccess,
    removeVideoFromSeriesSuccess,
    profile,
  ]);

  const deleteVideoHandler = () => {
    const filenamesplit = selectedVideo;
    const videoname = filenamesplit[filenamesplit.length - 1];
    const thumbnailsplit = selectedVideo.thumbnail.split("/");
    const thumbnailname = thumbnailsplit[thumbnailsplit.length - 1];

    dispatch(deleteVideoV2(selectedVideo.id));
    dispatch(deleteVideoS3(filenamesplit.url.substring(1)));
    dispatch(deleteVideoS3(thumbnailname));

    setDeleteVideoConfirmationModel(false);
  };

  useEffect(() => {
    if (trackerIsVisible) {
      setVideoPage(videoPage + 1);
      const videoObj = {
        page: videoPage + 1,
        limit: videoLimit,
      };

      const pageSize = Math.ceil(totalpage);
      if (pageSize) {
        if (videoPage < pageSize) {
          dispatch(
            getUserVideosss(
              {
                showall: false,
              },
              profile?.id,
              videoObj
            )
          );
        }
      }
    }
  }, [trackerIsVisible]);

  return (
    <>
      <VideosTabStyled>
        <div className="container">
          {userVideosLoading && <Loader text="Please wait..." />}

          {!userVideosLoading && !userVideos && (
            <Error text="No videos found." />
          )}

          <div className="container__videos">
            <Row className="container__videos--row">
              {userVideos &&
                userVideos.length > 0 &&
                userVideos.map((item, i) => (
                  <Col
                    key={i}
                    lg={3}
                    md={6}
                    sm={12}
                    xs={12}
                    className="container__videos--col"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <VideoCard
                      key={i}
                      item={item}
                      isSelf={isSelf}
                      setSelectedVideo={setSelectedVideo}
                      setVideoId={setVideoId}
                      menuOptions={isSelf ? VIDEO_SELF : VIDEO_OTHER}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      </VideosTabStyled>
      {userVideosLoading && <Loader text="Please wait..." />}
      {<div ref={trackerRef} className="tracker" />}
      {selectedVideo && videoId && (
        <EditClipForm
          setSelectedVideo={setSelectedVideo}
          selectedVideo={selectedVideo}
          videoId={videoId}
          close={() => setShowEditClipModel(false)}
        />
      )}

      {selectedVideo && deleteVideoConfirmationModel && (
        <ConfirmationModel
          closeHandler={() => setDeleteVideoConfirmationModel(false)}
          title="Are you sure you want to delete this video ? You can't undo this action."
          deleteHandler={deleteVideoHandler}
        />
      )}
    </>
  );
};

export default VideosTab;

const VideosTabStyled = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 2.5rem 0;
  font-family: ${(props) => props.theme.montserrat};

  .container {
    width: 90%;
    margin: 0 auto;

    &__videos {
      margin-bottom: 5rem;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 1rem 0;

    .container {
      width: 100%;
    }
  }
`;
