import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { GlobalContext } from "../../context/GlobalContext";
import DropdownArrow from "./icons/arrow.svg";
import GobackIcon from "./icons/back.svg";
import { useHistory } from "react-router-dom";
import MomentIcon from "./icons/moment.svg";
import ClipIcon from "./icons/clip.svg";
import LiveIcon from "./icons/live.svg";
import PostIcon from "../../assets/Icons/post.svg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LockIcon from "../icons/lock.svg";
import { notificationMessage } from "../../utils/toastNotifications";
import api from "../../services/api";
import config from "../../config";

const NewUploadModel = ({ show, close }) => {
  const [remainingUploadSeconds, setRemainingUploadSeconds] = useState(null);
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const {
    setShowUploadMomentModel,
    setShowUploadClipModel,
    showUploadModel,
    setShowUploadModel,
    setVideoForSeriesUpload,
    setShowManageSubscription,
    myMomentsCount,
    setMyMomentsCount,
    setShowUploadThreadModel,
  } = useContext(GlobalContext);

  useEffect(async () => {
    // fetch moments count is userrole is 1
    if (user && user.userrole === 1) {
      await tribeMomentsCountHandler();
    }
  }, [showUploadModel]);

  useEffect(() => {
    if (user && user.id) {
      getRemainingUploadSecs();
    }
  }, [user]);

  async function getRemainingUploadSecs() {
    try {
      const res = await api.get(`/users/upload-seconds-left/${user.id}`);
      if (res.status == 200) {
        setRemainingUploadSeconds(res.data.data.monthlyRemainingSeconds);
      }
    } catch (e) {
      // toast.error("Failed to get remaining upload seconds data");
    }
  }
  const tribeMomentsCountHandler = async () => {
    const res = await api.get("moment/tribe-moment-info");
    setMyMomentsCount(res.data.data);
  };

  const handleManageSubscription = () => {
    close();
    return setShowManageSubscription(true);
  };

  const menuClickHandler = async (type) => {
    switch (type) {
      case "thread":
        setShowUploadThreadModel(true);
        return;

      case "stripe":
        const stripeConnect = () => {
          const url = `${config.stripe.REACT_APP_STRIPE_ONBOARDING}`;
          window.open(url, "_self");
        };
        if (user?.userrole === 2) {
          toast.error(
            <a onClick={() => stripeConnect()}>
              <div>
                {" "}
                You must connect your wallet in order to start uploading and
                receiving payments for your video content. Click here to connect
                your wallet now or this can also be done from My Account.
              </div>
            </a>
          );
        } else if (
          remainingUploadSeconds == 0 &&
          (user?.userrole == 0 || user?.userrole == 1)
        ) {
          toast.error(notificationMessage.videoStorageUtilizationIsOver);
        } else {
          if (remainingUploadSeconds == 0) {
            toast.error(notificationMessage.videoStorageUtilizationIsOver);
          }
          if (
            remainingUploadSeconds == null &&
            (user?.userrole == 0 || user?.userrole == 1)
          ) {
            toast.error(
              "Only Warrior users can post vidoes. Upgrade your account now!"
            );
          }
        }

        return;

      case "live":
        return toast.error(
          "Live Streaming is coming soon for Warrior users! Check back often as we are always adding new features."
        );

      case "moment":
        setShowUploadMomentModel(true);

        const res = await api.get(`/users/space-left/${user.id}`);

        if (!res.data.data) {
          return toast.error(
            notificationMessage.videoStorageUtilizationIsOver,
            { autoClose: false, onClick: () => handleManageSubscription() }
          );
        } else {
          if (remainingUploadSeconds <= 0) {
            return toast.error(
              notificationMessage.videoStorageUtilizationIsOver,
              { autoClose: false, onClick: () => handleManageSubscription() }
            );
          }

          if (myMomentsCount >= 3 && user?.userrole === 1) {
            toast.error(notificationMessage.momentsUploadReachedError);
            return;
          }

          return;
        }
      case "momentNotUpload":
        if (remainingUploadSeconds <= 0 && user.userrole == 0) {
          return toast.error(
            "Only Tribe Co-op members can post Moments, Upgrade your account now! It’s only 5$ a year"
          );
        } else if (remainingUploadSeconds <= 0) {
          return toast.error(notificationMessage.monthlyUploadLimitReached);
        }
      case "clip":
        setShowUploadClipModel(true);
        const res1 = await api.get(`/users/space-left/${user.id}`);
        if (!res1.data.data) {
          return toast.error(
            notificationMessage.videoStorageUtilizationIsOver,
            { autoClose: false, onClick: () => handleManageSubscription() }
          );
        } else {
          if (remainingUploadSeconds <= 0) {
            return toast.error(
              notificationMessage.videoStorageUtilizationIsOver,
              { autoClose: false, onClick: () => handleManageSubscription() }
            );
          }
          setVideoForSeriesUpload({
            series: null,
            showSeriesList: true,
          });
          return;
        }

      default:
        return;
    }
  };

  const upgradeHandler = (e) => {
    e.stopPropagation();
    setShowUploadModel(false);
    setShowManageSubscription(true);
  };

  return (
    <UploadModelStyled className={`${show && "UploadModelShow"}`}>
      <div className="wrapper">
        <div className="wrapper__header">
          <div onClick={close} className="backIcon">
            <img src={GobackIcon} alt="" />
          </div>
          <div className="name">Upload Content</div>
        </div>

        <div className="wrapper__content">
          <div className="wrapper__content--list">
            <div
              onClick={() => menuClickHandler("live")}
              className="uploadType"
            >
              <div className="icon">
                <img src={LiveIcon} alt="" />
              </div>
              <div
                className={`box ${
                  user?.userrole === 0 ||
                  user?.userrole === 1 ||
                  user?.userrole === 2
                    ? "locked"
                    : null
                }`}
              >
                {(user?.userrole === 0 ||
                  user?.userrole === 1 ||
                  user?.userrole === 2) && (
                  <div className="box__lock">
                    <img src={LockIcon} alt="" />
                  </div>
                )}

                <div className="box__left">
                  <p className="lightText">Start your</p>
                  <p className="boldText">Live Class</p>
                  {(user?.userrole === 0 ||
                    user?.userrole === 1 ||
                    user?.userrole === 2) && (
                    <p // onClick={(e) => upgradeHandler(e)} className="upgrade"
                    >
                      (Coming soon)
                    </p>
                  )}
                </div>

                <div className="box__right">
                  <img src={DropdownArrow} alt="" />
                </div>
              </div>
            </div>

            <div
              // onClick={() => menuClickHandler("moment")}
              onClick={() => {
                if (remainingUploadSeconds > 0) {
                  menuClickHandler("moment");
                } else {
                  menuClickHandler("momentNotUpload");
                }
              }}
              className="uploadType"
            >
              <div className="icon">
                <img src={MomentIcon} alt="" />
              </div>
              <div
                className={`box ${!remainingUploadSeconds ? "locked" : null}`}
              >
                {!remainingUploadSeconds && (
                  <div className="box__lock">
                    <img src={LockIcon} alt="" />
                  </div>
                )}

                <div className="box__left">
                  <p className="lightText">Upload a </p>
                  <p className="boldText">Moment</p>
                  {!remainingUploadSeconds && (
                    <p onClick={(e) => upgradeHandler(e)} className="upgrade">
                      (Upgrade)
                    </p>
                  )}
                </div>

                <div className="box__right">
                  <img src={DropdownArrow} alt="" />
                </div>
              </div>
            </div>

            <div
              onClick={() => {
                if (user.userrole != 2 && !remainingUploadSeconds) {
                  menuClickHandler("stripe");
                } else {
                  if (user.stripe_account_id) {
                    menuClickHandler("clip");
                  } else {
                    menuClickHandler("stripe");
                  }
                }
              }}
              className="uploadType"
            >
              <div className="icon">
                <img src={ClipIcon} alt="" />
              </div>
              <div
                className={`box ${!remainingUploadSeconds ? "locked" : null}`}
              >
                {!remainingUploadSeconds && (
                  <div className="box__lock">
                    <img src={LockIcon} alt="" />
                  </div>
                )}

                <div className="box__left">
                  <p className="lightText">Upload</p>
                  <p className="boldText">Video Content</p>
                  {(user?.userrole === 0 || user?.userrole === 1) &&
                    !remainingUploadSeconds && (
                      <p onClick={(e) => upgradeHandler(e)} className="upgrade">
                        (Upgrade)
                      </p>
                    )}
                </div>

                <div className="box__right">
                  <img src={DropdownArrow} alt="" />
                </div>
              </div>
            </div>

            <div
              onClick={() => menuClickHandler("thread")}
              className="uploadType"
            >
              <div className="icon">
                <img src={PostIcon} alt="" />
              </div>
              <div className={`box`}>
                <div className="box__left">
                  <p className="lightText">Create new</p>
                  <p className="boldText">Thread</p>
                </div>

                <div className="box__right">
                  <img src={DropdownArrow} alt="" />
                </div>
              </div>
            </div>

            {/* commented out for now */}
            {/* <div className="uploadBySchedule">
              <p className="infoText">or check your schedule</p>

              <div className="schedule">
                <div className="icon">
                  <img src={ScheduleIcon} alt="" />
                </div>
                <div className="box">
                  <div className="box__left">
                    <p className="lightText">Start from</p>
                    <p className="boldText">Your Schedule</p>
                  </div>

                  <div className="box__right">
                    <img src={CaretRightIcon} alt="" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </UploadModelStyled>
  );
};

export default NewUploadModel;

const UploadModelStyled = styled.div`
  position: fixed;
  z-index: 900;
  bottom: 3.5rem;
  height: 100%;
  width: 100%;
  background-color: #1c1c1e;
  color: #fff;
  transition: all 0.7s ease;
  scale: 0.8;
  opacity: 0.8;
  transform: translateY(150%);
  overflow-y: auto;

  .wrapper {
    margin: 60px auto 0 auto;
    font-family: ${(props) => props.theme.montserrat};

    &__header {
      display: flex;
      align-items: center;
      padding: 1rem 5rem;
      border-bottom: 1px solid rgba(112, 112, 112, 0.4);

      .backIcon {
        margin-right: 1rem;
        cursor: pointer;

        img {
          height: 1rem;
          width: auto;
        }
      }

      .name {
        font-size: 1.2rem;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    &__content {
      width: 50%;
      max-width: 550px;
      margin: 2rem auto;

      &--list {
        width: 100%;

        .uploadType {
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 1rem;

          p {
            font-size: 0.8rem;
            color: #2c2c2e;
          }

          .lightText {
            font-weight: 400;
            margin-right: 0.25rem;
          }
          .boldText {
            font-weight: 600;
            margin-right: 0.5rem;
          }

          .icon {
            margin-right: 1rem;
            width: 3.5rem;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .locked {
            background-color: #181818 !important;
            border: 1px solid rgba(255, 255, 255, 0.5);
            cursor: not-allowed !important;

            p {
              color: #fff !important;
            }

            .upgrade {
              text-transform: uppercase;
              text-decoration: underline;
              padding-left: 0.25rem;
              background: ${(props) => props.theme.primaryGradient};
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-weight: 600;
              position: relative;
              font-size: 0.68rem !important;
              cursor: pointer !important;

              &:before {
                content: "";
                position: absolute;
                bottom: 0rem;
                left: 50%;
                height: 1px;
                width: 60%;
                transform: translateX(-50%);
                background: ${(props) => props.theme.primaryGradient};
              }
            }
          }

          .box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            width: 100%;
            padding: 0.5rem 1rem;
            border-radius: 0.4rem;
            cursor: pointer;
            position: relative;

            &__lock {
              position: absolute;
              top: 0rem;
              left: -0.5rem;
              height: 1.3rem;
              width: 1.3rem;
              transform: translateY(-50%);
              background: ${(props) => props.theme.primaryGradient};
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #202020;
              border-radius: 50%;

              img {
                height: 0.68rem;
                width: auto;
              }
            }

            &__left {
              display: flex;
              align-items: center;
            }

            &__right {
              img {
                height: 0.8rem;
                width: auto;
              }
            }
          }
        }

        .uploadBySchedule {
          margin-top: 2rem;

          .infoText {
            text-align: center;
            font-size: 0.85rem;
            color: #f2f2f7;
            font-weight: 300;
            opacity: 0.9;
            margin-bottom: 0.25rem;
          }

          .schedule {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 1rem;

            p {
              font-size: 0.8rem;
              color: #f2f2f7;
            }

            .lightText {
              font-weight: 300;
              margin-right: 0.5rem;
            }
            .boldText {
              font-weight: 500;
              margin-right: 0.5rem;
            }

            .icon {
              margin-right: 1rem;
              width: 3.5rem;
              height: auto;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .box {
              display: flex;
              align-items: center;
              justify-content: space-between;
              background-color: #2c2c2e;
              width: 100%;
              padding: 0.5rem 1rem;
              border-radius: 0.4rem;
              cursor: pointer;

              &__left {
                display: flex;
                align-items: center;
              }

              &__right {
                img {
                  height: 0.8rem;
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .wrapper {
      &__header {
        padding: 0.8rem 1.5rem;

        .name {
          font-size: 1rem;
        }
      }

      &__content {
        width: 80%;

        .uploadType {
          margin-bottom: 0.8rem;

          p {
            font-size: 0.7rem;
          }

          .icon {
            width: 3rem;
          }

          .box {
            padding: 0.4rem 1rem;
          }
        }

        .uploadBySchedule {
          margin-top: 1.5rem;

          .infoText {
            font-size: 0.8rem;
          }

          .schedule {
            p {
              font-size: 0.7rem;
            }

            .icon {
              width: 3rem;
            }

            .box {
              padding: 0.4rem 1rem;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 414px) {
    .wrapper {
      &__content {
        width: 90%;

        .uploadType {
          margin-bottom: 0.5rem;

          p {
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    .wrapper {
      &__content {
        width: 90%;

        .uploadType {
          margin-bottom: 0.4rem;

          p {
            font-size: 0.55rem;
          }
        }
      }
    }
  }
`;
