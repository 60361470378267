import React from "react";
import styled from "styled-components";
import NotFoundIcon from "../icons/notfound.svg";
import Button from "./Button";
import ExploreIcon from "../icons/explore.svg";

const Error = ({
  text = "Not Found!",
  actionButton,
  actionButtonText,
  buttonSize,
}) => {
  return (
    <ErrorStyled>
      <img src={NotFoundIcon} alt="" className="errorIcon" />
      <p>{text}</p>
      {actionButton && (
        <Button
          className="btn"
          mode="primary"
          size={buttonSize ? buttonSize : "medium"}
          variant="box"
          onClick={actionButton}
          iconLeft
        >
          {actionButtonText ? (
            actionButtonText
          ) : (
            <>
              <img src={ExploreIcon} alt="" />
              Explore Series
            </>
          )}
        </Button>
      )}
    </ErrorStyled>
  );
};

export default Error;

const ErrorStyled = styled.div`
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  .errorIcon {
    height: 7rem;
    width: auto;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 0.9rem;
    font-weight: 400;
  }

  .btn {
    margin-top: 1rem;
  }
`;
