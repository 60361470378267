export const notificationMessage = {
  welcomeFreeloader: "Welcome to Freeloader! Your sign-up was successful!",
  invalidError: "You have entered an invalid username or password.",
  addedClassSchedule: "Successfully added the live class schedule !",
  updatedClassSchedule: "You have successfully updated live class schedule ",
  failedClassSchedule: "Failed to add live class schedule!",
  fetchLiveClassSchedule: "You are successfully fetched live class schedule!",
  deleteLiveClassSchedule: "Live class schedule deleted successfully",
  deleteAccount: "Your Account was deleted",
  updatedKarmaFilter: "You have updated the Karma filter settings",
  updateSubscription: "Subscription updated successfully!",
  failedUpdateSubscription: "Failed to update the subscription!",
  navigateCustomerPortal: "You are navigating to the customer portal",
  updatedSubscription: "You have successfully updated the Subscription",
  enterAmount: "Please enter amount here ",
  processingFee:
    "Minimum Karma is $1.50. This is to ensure processing fees are covered and Warrior's can receive $1",
  incorrectPaymentDetails:
    "Payment details are incorrect. Please enter valid payment details.",
  paymentDetailsUpdated: "Payment details updated successfully",
  paymentDetailsAdded: "Payment details added successfully",
  somethingWentWrong: "Something went wrong. Please try again later.",
  paymentSuccessfull: "Payment successfull!",
  videoSuccessfullyUnlocked: "Your Video is successfully unlocked",
  videoDeleted: "Your Video is successfully Deleted.",
  videoUpdated: "Video updated!",
  comment: "Please write a comment",
  taglineNotEmpty: "Tagline should not be empty",
  taglineMaxLimit: "Tagline should not be greater than 400 characters",
  taglineMinimumLimit: "Tagline should be minimum of 5 characters long",
  emptyEmail: "Email cannot be empty.",
  emptyFirstname: "Firstname should not be empty.",
  emptyLastname: "Lastname should not be empty",
  profileUpdateSuccessfull: "Your Profile is successfully updated!",
  publicDisplaySetting: "Your Public Display settings have been updated!",
  videoTitle: "Please enter video title.",
  limitExceede:
    "Sorry, you have exceeded the Limit for a video title, Maximum limit is 100 character.",
  videoDescription: "Please enter a video description.",
  payPerView: "Please enter Pay Per View $ amount.",
  minimumPayPerview: "Minimum amount Pay Per View is $1.50",
  hashTagLimit: "Hashtag must be less than 140 characters.",
  removeInvalidCharacter:
    "Please remove invalid character. (Enter only letters and numbers please)",
  addHashtagUpto20: "You can add up to 20 hashtags. Please remove some.",
  videoHashtag: "Video hashtag already added.",
  requaireField: "Please fill all the fields",
  selectCategory: "Please select at least one category to continue.",

  registeredGoogleAccount: "This google account has been registered",
  googlrAccountNotSignup: "This google account is not signed up.",
  facebookAccountNotSignup: "This facebook account is not signed up",
  enterSearchTerm: "Please enter a search term.",
  accountConnected: "Account connected! You are ready to start earning!", // check this one
  videoStorageUtilizationIsOver:
    "Your monthly upload minutes usage exceeds 100%! Consider upgrading to a package with more minutes or removing content from your channel.",
  copied: "Copied!",
  needToGiveTask: "You need to give title for your live class",
  enterSignupDetails: "Enter your details to complete the signup process",
  facebookAccountRegistered: "This facebook account has been registered",
  enterUsername: "Enter a username to complete the signup process",
  registeredFacebookAccount: "This facebook account has been registered",
  nameShouldContainLetters: "Name should contain only letters",
  upgradeAccountToWarrier:
    "Have some video content you'd like to start receiving Karma tips for? Upgrade your account to a Warrior above to begin!",
  waitForInformation: "Wait for a while we are fetching your information",
  provideCategoryTitle: "Please provide category title!",
  receivedVideoCategoryRequest:
    "We received your video category request! Thanks for helping expand this community. Our team will review your submission and notify you if accepted.",
  thanksForFeedback: "Thanks for your Feedback! We will respond shortly",
  cancelledSubscription: "Cancelled Subscription",
  updateDefaultCreditCard: "Default credit card updated",
  subscriptionUpdated: "Your subscription updated successfully!",
  failedToUpdate: "Failed to update subscription",
  subscribePlan: "Please add a payment method to subscribe to this plan",
  alreadySendRequest: "You have already send connection request.",
  requestSend: "Your Connection request sent.",
  unexpectedServerError: "Unexpected server error occurred!",
  playlistDeleted: "Playlist deleted!",
  titleForPlaylist: "Please give title for the playlist",
  playlistCreated: "Playlist created successfully",
  playlistUpdated: "Playlist updated",
  purchasedSeries: "You purchased this series. Enjoy Watching",
  notPurchasedSeries: "You need to purchase series to access!",
  addSomeVideos: "Great! Now add some videos to it.",
  congratesVideoAdded: "Congrats! video added to the series",
  enterSeriesTitle: "Please enter series title.",
  seriesThumbnail: "Please give series a thumbnail",
  seriesCreated: "Series created successfully",
  seriesUpdated: "Series updated successfully",
  seriesDeleted: "Series deleted successfully",
  seriesCompleted: "Congrats! You have successfully completed the series.",
  seriesAddVideos: "Please add some videos to series to watch it. Thank you",
  selectVideoToAdd: "Please select some videos to add!",
  connectionRemoved: "Connection removed successfully!",
  pleaseSaveVideos:
    "You have some video that are not saved yet. Please save them",
  uploadInProgress: "Please Wait, Upload in Progress...",
  reorderingSavedInDB: "Re-ordering saved into database",
  enterValidCardNumber: "Please enter a valid card number",
  videoUnlocked: "Video unlocked successfully",
  enterCompleteCardDetails:
    "Please enter a complete card number or select a saved payment method",
  seriesPurchased: "Series purchased successfully. Thank You",
  selectSomeVideos: "Please select some videos to upload",
  pleaseSetLocation:
    "Please set your location. Or, if you wish to stay anonymous, check the 'Out of this World' option.,",
  addToStream: "was added to your streams",
  removeFromStream: "was removed from your streams",
  termsAndCondition:
    "You must agreed to our terms and condition before creating an account",
  fileSizeLimit:
    "There is a 10GB per file size limit. Please upload a smaller file or use compression to reduce the size of this file",
  oopsTryAgain:
    "Opps... soemthing went wrong with the upload. Please try again.",
  provideMomentCaption: "Please provide moment caption",
  provideMomentVideo: "Please provide moment video",
  whyReportingVideo:
    "please give some information on why you are reporting the video",
  messageLength: "message should be less than 500 characters",
  getBackToYou:
    "Our support team got your report. We will get back to you as soon as possible.",
  sendingPassword: "Sending password reset email....",
  passwordReset: "Password Reset Link sent",
  passwordResetSuccess: "Password reset successfully!",
  momentDeleted: "Moment deleted successfully",
  momentEdited: "Congrats Moment edited successfully",
  momentPosted: "Congrats Moment posted successfully",
  firstSaveMoment: "First save moment to tag users!",
  emailVerified: "Email Verified.",
  emailTaken: "This email is already taken. Please try another one",
  congratsVideoUploaded: "Congrats! Your video uploaded successfully.",
  resumePlayback:
    "This video requires Karma to continue viewing! Please tip your Content Creator any amount to resume playback.",
  tagSomeConnections: "Now tag some connections to your moment",
  toastTipOne: "💰 $1.50 Karma",
  toastTipThree: "💰💰 $3.25 Karma",
  toastTipFive: "💰💰💰 $5.00 Karma",
  toastTipCustom: "💰 CUSTOM TIP 💰",
  toastTipClose: "💔🗑 NO TIP / CLOSE 🗑💔",
  removedFromFeatured: "Content removed from featured list",
  elementContentAdded: "Content added to element",
  elementCustomBanner: "Uploaded your custom banner",
  elementCreating: "Element creating...",
  elementDeleting: "Element deleting...",
  elementReorder: "Re-ordering saved into database",
  saveChanges: "Please save your changes",
  pickAvatar:
    "You haven't picked any avatar. Please pick one or upload your own",
  bannerRemoved: "Banner removed",
  streamFilterComingSoon: "Coming soon!",
  stripeNotConnected:
    "You must connect your wallet to start uploading content on iSutra. This can be done from your My Account settings",
  profileLinkCopied: "Profile link copied",
  referralLinkCopied: "Referral link copied",
  cardexists: "Card already exists.",
  purchasedSeries: "You purchased this series. Enjoy Watching",
  copyVideoUrl:
    "Video URL is copied. Now you can share it with your loved ones.",
  deleteSeries: "Series has be deleted successfully.",
  copySeriesUrl:
    "Series URL is copied. Now you can share it with your loved ones.",
  copyMomentUrl:
    "Moment URL is copied. Now you can share it with your loved ones.",
  momentTimeLimitError:
    "This Moment video is over 60 seconds! Moments are intended to be short form content, please post a shorter Moment",
  momentsUploadReachedError:
    "You’ve reached you Moment limit! Tribe Co-op members are allowed 3 Moment posts per week. Please remove an older moment before uploading again",
  loginRequired:
    "You are not logged in to perform this action. Please log in to get access.",
  conversationRemoved: "Chat Message delete successfully",
  categoryNeededError: 'Please provide related category"',
  copyThreadUrl:
    "Thread URL is copied. Now you can share it with your loved ones.",
  exceedsUploadLimit:
    "Video length exceeds your monthly upload limit.Please upgrade your subscription.",
  monthlyUploadLimitReached:
    "You have reached your monthly upload minutes limit.Please upgrade or wait for the next month",
};

//Files which have hard coded toasts with variables:
// index.js
// Connection request pending message:
// `Your connection request is pending. We will send notification to the ${profile?.username} about your request. `

//         toast.success(
//    `$${refAmt} of your membership was just sent to ${payload.code}. Thank you so much! 🙏`
//

//PriceCheckoutForm.js
// `Thank you! Your payment of ($${priceToPay}) was Successfull.`

// `$${creatorAmount.toFixed(2)} was transferred to ${
//    video.User.username
//  } successfully! Thank you for giving Karma back to the Content Creator!`
//

//PriceCheckoutForm.js
// `Thank you for your payment of ($${priceToPay})!`

// `$${creatorAmount.toFixed(2)} was just sent to ${video.User.username
// } ! Thank you for giving Karma back to the Content Creator!`,

// CheckoutForm.js
// `Thank you, Your payment of ($${priceToPay}) was Successful.`,

//             `$${creatorAmount.toFixed(2)} was transferred to ${
//    videos[0].singleDetailedVideo.User.username
// } successfully! Thank you purchasing series and supporting the creator!`

//EditClipForm.js
//
//     <div onClick={myAccountLink}>
//<p>
//You've used up all your video storage! Please upgrade to a larger video
//package OR remove content from your channel. Click this message to
//upgrade your subscription.
//</p>
//</div>
