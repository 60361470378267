import React from "react";
import styled from "styled-components";
import SectionHeader from "../../SectionHeader";
import Paragraph from "../../StyleComponents/Paragraph";
import config from "../../../../config";
import { useSelector } from "react-redux";

const HowItWorks = ({ data }) => {
  const { data: siteBranding } = useSelector((state) => state.branding);

  if (!data?.length) {
    return null;
  }

  return (
    <HowItWorksStyled>
      <SectionHeader
        subTitle="New way to socialize with people"
        title={`Why use ${siteBranding?.site_name}`}
      />

      <div className="content">
        {data?.map((item, i) => (
          <div className="content__item">
            <div className="content__item--logo">
              <img src={config.aws.CLOUDFRONT_URL + item?.icon} alt="" />
            </div>

            <div className="content__item--title">{item?.title}</div>
            <Paragraph className="content__item--text">{item?.text}</Paragraph>
          </div>
        ))}
      </div>
    </HowItWorksStyled>
  );
};

export default HowItWorks;

const HowItWorksStyled = styled.div`
  padding-bottom: 7rem;

  .content {
    width: 90%;
    margin: 5rem auto 0 auto;
    border-radius: 0.5rem;
    display: grid;
    gap: 0;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    &__item {
      background: #202020;
      color: rgba(255, 255, 255, 0.6);
      padding: 1.5rem 2rem;
      cursor: pointer;
      transition: all 0.3s ease;
      transform-origin: right;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &:not(:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.09);
      }

      &:first-child {
        border-top-left-radius: 0.4rem;
        border-bottom-left-radius: 0.4rem;
      }

      &:last-child {
        border-top-right-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
      }

      &:hover {
        transform: scale(1.05);
        background: ${(props) => props.theme.primaryGradient};
        z-index: 2;
        border-radius: 0.4rem;
        color: #fff;
      }

      &--logo {
        height: 4rem;
        width: auto;
        margin-bottom: 1rem;

        img {
          height: 100%;
          width: 100%;
        }
      }

      &--title {
        font-family: brother-1816, sans-serif;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1.1rem;
        margin-bottom: 1rem;
      }

      &--text {
        line-height: 1.5;
        font-weight: 300;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      &__item {
        &:not(:last-child) {
          border-right: none;
          border-bottom: 1px solid rgba(255, 255, 255, 0.09);
        }

        &:hover {
          transform: scale(1.025);
        }
      }
    }
  }
`;
