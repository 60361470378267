import React from "react";
import styled from "styled-components";
import SkeletonElement from "../SkeletonElement";

const Banner = () => {
  return (
    <BannerStyled>
      <div className="banner">
        <div className="banner__left">
          <div className="banner__left--top">
            <SkeletonElement type="title" varient="dark" />
          </div>

          <div className="banner__left--bottom">
            <div className="banner__left--bottom-item">
              <SkeletonElement type="paragraph" varient="dark" />
            </div>

            <div className="banner__left--bottom-item">
              <SkeletonElement type="paragraph" varient="dark" />
            </div>

            <div className="banner__left--bottom-item">
              <SkeletonElement type="paragraph" varient="dark" />
            </div>
          </div>
        </div>

        <div className="banner__right">
          <SkeletonElement
            className="banner__bottom--btn"
            type="buttonMD"
            varient="dark"
          />
        </div>
      </div>
    </BannerStyled>
  );
};

export default Banner;

const BannerStyled = styled.div`
  .banner {
    background-color: #202020;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &__left {
      width: calc(100% - 10rem);
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &--top {
        min-width: 10rem;
        width: 50%;
        margin-bottom: 1.25rem;
      }

      &--bottom {
        min-width: 10rem;
        width: 35%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;

        &-item {
          min-width: 30%;
        }
      }
    }

    &__right {
      &--btn {
        width: 8rem;
      }
    }
  }
`;
