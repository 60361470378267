import React, { useState, useEffect, useContext } from "react";
import styled, { keyframes } from "styled-components";
import ArrowIcon from "../assets/Icons/arrow.svg";
import CaretRightIcon from "../assets/Icons/caret-right-dark.svg";
import Slider from "react-slick";
import MomentCard from "../components/Cards/MomentCard";
import PlaylistCard from "../components/LibraryComponents/PlaylistCard";
import SeriesCard from "../components/Cards/SeriesCard";
import WarriorCard from "../components/LibraryComponents/WarriorCard";
import VideoCard from "../components/Cards/VideoCard";
import FeaturedSeriesSkeleton from "../skeletons/General/Section";
import FeaturedSeries from "../components/NewHomeComponents/FeaturedSection/Series";
import { GlobalContext } from "../context/GlobalContext";
import { NoContentScreen } from "../components";
import {
  getAllPlaylists,
  // getSubscriberIds,
  getAllPurchasedSeries,
  getHistory,
  getLibraryVideos,
  getFeaturedSeries,
  getLikedMoments,
} from "../actions";
import { getLikedThreads } from "../actions/thread";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import HistoryVideosSkeleton from "../skeletons/General/Section";
import PlaylistSkeleton from "../skeletons/General/Section";
import LibraryVideosSkeleton from "../skeletons/General/Section";
import StreamsSkeleton from "../skeletons/General/Section";
import MomentsSkeleton from "../skeletons/General/Section";
import SeriesSkeleton from "../skeletons/General/Section";
import ThreadCardSmall from "../components/Cards/ThreadCardSmall";
import { PLAYLIST_OTHER } from "../utils/menuOptions";

const MOMENTSSLIDER = {
  arrows: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  speed: 1000,
  autoplaySpeed: 5000,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ],
};

const PLAYLISTSLIDER = {
  infinite: false,
  arrows: false,
  dots: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  speed: 500,

  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 481,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const SERIESSLIDER = {
  infinite: false,
  arrows: false,
  dots: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  speed: 500,

  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 481,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const WARRIORSSLIDER = {
  infinite: false,
  arrows: false,
  dots: false,
  slidesToShow: 6,
  slidesToScroll: 2,
  speed: 500,

  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2,
      },
    },
  ],
};

const NewLibrary = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const routePath = useLocation();
  const userData = useSelector((state) => state.user);
  const {
    playlists,
    loading: playlistsLoading,
    error: playlistsError,
  } = useSelector((state) => state.getPlaylists);
  const karmaWarriors = useSelector((state) => state.channel_stream_videos);
  const {
    loading: seriesLoading,
    series,
    error: seriesError,
  } = useSelector((state) => state.purchasedSeries);
  const {
    likedMomentsLoading: momentsLoading,
    likedMoments: moments,
    likedMomentsError: momentsError,
  } = useSelector((state) => state.moments);
  const { isFetching: recentlyWatchedLoading, videos: recentlyWatchedVideos } =
    useSelector((state) => state.history);
  const {
    isFetching: libraryVideosLoading,
    videos: libraryVideos,
    hasMore: hasMoreLibraryVideos,
    error: libraryVideosError,
  } = useSelector((state) => state.library);
  const { featuredSeries, loading: featuredSeriesLoading } = useSelector(
    (state) => state.featuredSeries
  );
  const {
    likedThreadsLoading: threadsLoading,
    likedThreadsError: threadsError,
    likedThreads: threads,
  } = useSelector((state) => state.threads);

  const { setShowPlaylistModelV2, setPortalTab, setShowGlobalPlaylistModel } = useContext(GlobalContext);
  const [tab, setTab] = useState("LIBRARY");
  const [midDrag, setMidDrag] = useState(false);
  const [currentThread, setCurrentThread] = useState(0);

  const onTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    onTop();
  }, [routePath]);

  useEffect(() => {
    dispatch(getAllPlaylists(userData.id, {}));
    // dispatch(getSubscriberIds(userData.id));
    dispatch(getAllPurchasedSeries(userData.username));
    dispatch(getLikedMoments());
    dispatch(getHistory());
    dispatch(getLibraryVideos(userData.id, 10));
    dispatch(getLikedThreads());

    if (!featuredSeries?.length) {
      dispatch(getFeaturedSeries());
    }
  }, []);

  // thread slider
  const LIVESLIDER = {
    infinite: false,
    arrows: false,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    swipeToSlide: true,
    beforeChange: (current, next) => {},

    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 481,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <NewLibraryStyled>
      <>
        <div className="header">
          <div className="header__tabs">
            <div
              onClick={() => setTab("LIBRARY")}
              className={`header__tabs--item ${tab === "LIBRARY" && "active"}`}
            >
              Your Library
            </div>

            <div
              onClick={() => setTab("SERIES")}
              className={`header__tabs--item ${tab === "SERIES" && "active"}`}
            >
              Top Series
            </div>
          </div>
          <div
            onClick={() => setShowPlaylistModelV2(true)}
            className="header__createButton"
          >
            <div className="text">
              <div className="light">Create</div>
              <div className="bold">Playlist</div>
            </div>

            <img className="icon" src={ArrowIcon} alt="" />
          </div>
        </div>

        {!libraryVideos?.length &&
        !playlists?.length &&
        !series?.length &&
        !moments?.length &&
        !recentlyWatchedVideos?.length &&
        !threads?.length &&
        !libraryVideosLoading &&
        !playlistsLoading &&
        !seriesLoading &&
        !momentsLoading &&
        !recentlyWatchedLoading &&
        !threadsLoading ? (
          <NoContentScreen />
        ) : null}

        <div className="divider"></div>
        {tab === "LIBRARY" && (
          <>
            {libraryVideosLoading || libraryVideos?.length ? (
              <div className="section">
                <div className="section__header">
                  <div className="section__header--title">Your Videos</div>

                  <Link to="/videos" className="section__header--button">
                    View all
                    <img src={CaretRightIcon} alt="" />{" "}
                  </Link>
                </div>

                {libraryVideosLoading ? (
                  <LibraryVideosSkeleton
                    numItems={4}
                    noTitle
                    fullWidth
                    sectionName="videos"
                  />
                ) : (
                  <Slider {...PLAYLISTSLIDER} className="section__playlists">
                    {libraryVideos?.map((item, i) => (
                      <div key={i} className="section__playlists--item">
                        <VideoCard key={item.id} item={item} />
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            ) : null}

            {playlistsLoading || playlists?.length ? (
              <div className="section">
                <div className="section__header">
                  <div className="section__header--title">Your Playlist</div>

                  <div
                    onClick={() => {
                      history.push(`/channel/${userData?.username}`);
                      setPortalTab(
                        userData?.userrole === 1
                          ? 2
                          : userData?.userrole === 2
                          ? 4
                          : null
                      );
                    }}
                    className="section__header--button"
                  >
                    View all
                    <img src={CaretRightIcon} alt="" />{" "}
                  </div>
                </div>

                {playlistsLoading ? (
                  <PlaylistSkeleton
                    numItems={4}
                    noTitle
                    fullWidth
                    sectionName="videos"
                  />
                ) : (
                  <Slider {...PLAYLISTSLIDER} className="section__playlists">
                    {playlists?.map((item, i) => (
                      <div key={i} className="section__playlists--item">
                        <PlaylistCard
                          item={item}
                          menuOptions={PLAYLIST_OTHER}
                          handleClick={() => setShowGlobalPlaylistModel(true)}
                        />
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            ) : null}

            {libraryVideosLoading || libraryVideos?.length ? (
              <div className="section">
                <div className="section__header">
                  <div className="section__header--title">
                    Your karma - warriors
                  </div>
                </div>

                {libraryVideosLoading ? (
                  <StreamsSkeleton
                    numItems={4}
                    noTitle
                    fullWidth
                    sectionName="users"
                  />
                ) : (
                  <Slider className="section__warriors" {...WARRIORSSLIDER}>
                    {libraryVideos?.map((item, i) => (
                      <div key={i} className="section__warriors--item">
                        <WarriorCard key={i} item={item} />
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            ) : null}

            {seriesLoading || series?.length ? (
              <div className="section">
                <div className="section__header">
                  <div className="section__header--title">
                    Your karma - series collection
                  </div>

                  <div
                    onClick={() => {
                      history.push(
                        `/channel/${userData?.username}?showPurchasedSeries=true`
                      );
                      setPortalTab(5);
                    }}
                    className="section__header--button"
                  >
                    View all
                    <img src={CaretRightIcon} alt="" />{" "}
                  </div>
                </div>

                {seriesLoading || !series?.length ? (
                  <SeriesSkeleton
                    numItems={4}
                    noTitle
                    fullWidth
                    sectionName="videos"
                  />
                ) : (
                  <Slider className="section__series" {...SERIESSLIDER}>
                    {series?.map((item, i) => (
                      <div className="section__series--item">
                        <SeriesCard
                          key={i}
                          item={{
                            ...item?.seriesDetails,
                            User: item?.User,
                            progress: item?.seriesProgress,
                            videos: item?.seriesVideos,
                          }}
                        />
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            ) : null}

            {momentsLoading || moments?.length ? (
              <div className="section">
                <div className="section__header">
                  <div className="section__header--title">
                    Moments you appreciated
                  </div>

                  <Link to="/moments" className="section__header--button">
                    View all
                    <img src={CaretRightIcon} alt="" />{" "}
                  </Link>
                </div>

                {momentsLoading || !moments?.length ? (
                  <MomentsSkeleton
                    noTitle
                    fullWidth
                    sectionName="featured_moments"
                  />
                ) : (
                  <Slider className="section__moments" {...MOMENTSSLIDER}>
                    {moments?.map((item, i) => (
                      <div className="section__moments--item">
                        <MomentCard key={i} item={item} />
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            ) : null}

            {threadsLoading || threads?.length ? (
              <div className="section">
                <div className="section__header">
                  <div className="section__header--title">
                    Threads you appreciated
                  </div>

                  <Link to="/threads" className="section__header--button">
                    View all
                    <img src={CaretRightIcon} alt="" />{" "}
                  </Link>
                </div>

                {threadsLoading || !threads?.length ? (
                  <MomentsSkeleton
                    noTitle
                    fullWidth
                    sectionName="videos"
                    numItems={4}
                  />
                ) : (
                  <Slider
                    className="section__moments"
                    {...LIVESLIDER}
                    beforeChange={() => setMidDrag(true)}
                    afterChange={() => setMidDrag(false)}
                  >
                    {threads?.map((item, i) => (
                      <div className="section__moments--item">
                        <ThreadCardSmall
                          key={i}
                          item={item}
                          type={item?.attachedType}
                          midDrag={midDrag}
                        />
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            ) : null}

            {recentlyWatchedLoading || recentlyWatchedVideos?.length ? (
              <div className="section">
                <div className="section__header">
                  <div className="section__header--title">Recently watched</div>

                  <Link className="section__header--button" to="/feed/history">
                    View history
                    <img src={CaretRightIcon} alt="" />{" "}
                  </Link>
                </div>

                {recentlyWatchedLoading ? (
                  <HistoryVideosSkeleton
                    numItems={4}
                    noTitle
                    fullWidth
                    sectionName="videos"
                  />
                ) : (
                  <Slider className="section__clips" {...LIVESLIDER}>
                    {recentlyWatchedVideos?.map((item, i) => (
                      <div className="section__clips--item">
                        <VideoCard key={i} item={item} />
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            ) : null}
          </>
        )}
        {tab === "SERIES" && (
          <>
            {featuredSeriesLoading && !featuredSeries?.length ? (
              <FeaturedSeriesSkeleton sectionName="featured_series" />
            ) : (
              <FeaturedSeries series={featuredSeries} />
            )}
          </>
        )}
      </>
    </NewLibraryStyled>
  );
};

export default NewLibrary;

const activeTabBar = keyframes`
	from {
		opacity: 0;
    transform: translateX(-100%);
	}
	to {
		opacity: 1;
    transform: translateX(0);
	}
`;

const NewLibraryStyled = styled.div`
  padding-top: 30px;
  font-family: ${(props) => props.theme.montserrat};
  margin-bottom: 7rem;

  .header {
    margin: 0 auto;
    width: 45%;
    min-width: 480px;

    &__tabs {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--item {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        line-height: 1;
        text-transform: capitalize;
        font-size: 1rem;
        color: #8e8e93;
        font-weight: 500;
        position: relative;
        height: 100%;
        padding-bottom: 0.35rem;
        cursor: pointer;
        position: relative;
        padding: 0.7rem 0;
      }

      .active {
        color: #fff;

        &:before {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: #fff;
          border-radius: 4px;
          transition: all 0.5s ease;
          animation: ${activeTabBar} 0.5s ease;
        }
      }
    }

    &__createButton {
      background-color: #fff;
      padding: 0.8rem 1rem;
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #2c2c2e;
      font-size: 0.95rem;
      cursor: pointer;
      margin: 2.5rem 0;

      .text {
        display: flex;
        align-items: center;
        text-transform: capitalize;

        .light {
          font-weight: 400;
          padding-right: 0.5rem;
        }

        .bold {
          font-weight: 600;
        }
      }

      .icon {
        height: 1rem;
        width: auto;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(242, 242, 247, 0.2);
  }

  .section {
    margin: 2rem auto 0 auto;
    border-bottom: 1px solid rgba(242, 242, 247, 0.2);
    padding: 0 5rem 2rem 5rem;

    .slick-track {
      margin-left: 0;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;

      &--title {
        font-family: brother-1816, sans-serif;
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.4;
        text-transform: uppercase;
      }

      &--button {
        width: auto;
        min-width: 8rem;
        font-size: 0.85rem;
        font-family: ${(props) => props.theme.montserrat};
        background-color: #fff;
        padding: 0.5rem 1rem;
        color: #2c2c2e;
        border-radius: 10rem;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          margin-left: 0.5rem;
          height: 0.8rem;
          width: auto;
        }
      }
    }

    &__moments,
    &__playlists,
    &__series,
    &__warriors,
    &__lives,
    &__clips {
      &--item {
        padding: 0 0.5rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .header {
      min-width: 90%;
      width: 90%;

      &__createButton {
        padding: 0.6rem 1rem;
        font-size: 0.9rem;
      }
    }

    .section {
      padding: 0 2rem 2rem 2rem;
    }
  }

  @media screen and (max-width: 480px) {
    .header {
      min-width: 90%;
      width: 90%;

      &__createButton {
        margin: 2rem 0;
        padding: 0.6rem 1rem;
        font-size: 0.9rem;
      }
    }

    .section {
      padding: 0 0.5rem 2rem 0.5rem;

      &__header {
        padding: 0 1.5rem;
        margin-bottom: 1rem;

        &--title {
          font-size: 1.05rem;
        }

        &--button {
          font-size: 0.7rem;

          img {
            height: 0.7rem;
            margin-right: 0.25rem;
          }
        }
      }

      &__moments,
      &__playlists,
      &__series,
      &__warriors,
      &__lives,
      &__clips {
        &--item {
          padding: 0 0.25rem;
        }
      }
    }
  }

  @media screen and (max-width: 390px) {
    .section {
      &__header {
        &--title {
          font-size: 0.9rem;
        }

        &--button {
          font-size: 0.6rem;
          min-width: 6rem;

          img {
            margin-right: 0rem;
          }
        }
      }

      &__moments,
      &__playlists,
      &__series,
      &__warriors,
      &__lives,
      &__clips {
        &--item {
          padding: 0 0.2rem;
        }
      }
    }
  }
`;
