import React, { useEffect } from "react";

const DynamicMetaTags = ({ title, description, imageUrl }) => {
  console.log(title, description, imageUrl);
  useEffect(() => {
    document.title = title;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description);
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", title);
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", description);
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute("content", imageUrl);
  }, [title, description, imageUrl]);

  return null;
};

export default DynamicMetaTags;
