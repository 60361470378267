import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Player from "react-player";
import MenuOptions from "../../Cards/MenuOptions";
import { GlobalContext } from "../../../context/GlobalContext";
import { MOMENTS_OTHER } from "../../../utils/menuOptions";
import { addDefaultImage } from "../../../utils";
import ImageNotfoundPlaceholder from "../../../assets/image_notfound_placeholder.svg";
import config from '../../../config'

const MomentCard = ({ moment, midDrag }) => {
  const {
    showEditMomentModel,
    setSelectedMoment,
    setShowMomentDetailsModel,
    showMomentDetailsModel,
  } = useContext(GlobalContext);

  const [playing, setPlaying] = useState(false);
  let timeout;

  const { id, caption, coverImgUrl, videoUrl, User } = moment;

  useEffect(() => {
    if (showEditMomentModel) {
      setPlaying(false);
    }
  }, [showEditMomentModel]);

  useEffect(() => {
    if (showMomentDetailsModel) {
      clearTimeout(timeout);
      setPlaying(false);
    }
  }, [showMomentDetailsModel]);

  const handleMomentDetail = (e) => {
    if (midDrag) {
      e.stopPropagation();
      return;
    }

    setPlaying(false);
    setSelectedMoment(moment);
    setShowMomentDetailsModel(true);
  };

  return (
    <>
      <MomentCardStyled
        onMouseEnter={() => {
          if (!showMomentDetailsModel) {
            timeout = setTimeout(() => {
              setPlaying(true);
            }, [1500]);
          }
        }}
        onMouseLeave={() => {
          clearTimeout(timeout);
          setPlaying(false);
        }}
      >
        <img
          onClick={handleMomentDetail}
          src={config.aws.CLOUDFRONT_URL + coverImgUrl}
          alt={caption}
          className="thumbnail"
          onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
        />
        <div onClick={handleMomentDetail} className="backdrop"></div>

        <div className="metaData">
          <h1 className="metaData__title">
            {caption?.length > 50 ? caption.substring(0, 50) + "..." : caption}
          </h1>

          <p className="metaData__small">{User.username}</p>
        </div>

        {playing && (
          <Player
            onClick={handleMomentDetail}
            playing={playing}
            url={config.aws.CLOUDFRONT_URL + videoUrl}
            width="100%"
            height="100%"
            controls={false}
            className="Player"
            muted={false}
            loop={true}
          />
        )}

        <div className="dotMenu">
          <MenuOptions
            type="moment"
            options={MOMENTS_OTHER}
            item={moment}
            momentId={id}
            setSelectedMoment={setSelectedMoment}
          />
        </div>
      </MomentCardStyled>
    </>
  );
};

export default MomentCard;

const MomentCardStyled = styled.div`
  padding-bottom: 177.78%;
  height: 0;
  width: 100%;

  overflow: hidden;
  border-radius: 0.5rem;
  position: relative;
  margin: 0 auto;
  position: relative;

  .dotMenu {
    z-index: 5;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .Player {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: scale(1.25);
  }

  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    z-index: -1;
  }

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
    transition: all 0.2s ease;
    opacity: 0;
    visibility: hidden;
  }

  .metaData {
    position: absolute;
    z-index: 2;
    bottom: 0rem;
    left: 1rem;
    transition: all 0.2s ease;

    &__title {
      font-size: 1.2rem;
      line-height: 1.3;
      width: 90%;
      font-family: ${(props) => props.theme.montserrat}, sans-serif;
      font-weight: 500;
    }

    &__small {
      font-size: 0.75rem;
      color: #fff;
      font-weight: 400;
      font-family: brother-1816, sans-serif;
      text-transform: uppercase;
      opacity: 0;
      transform: translateY(5px);
      transition: all 0.2s ease;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &:hover {
    .backdrop {
      background-color: rgba(0, 0, 0, 0.4);
      transform: scale(1.2);
      opacity: 1;
      visibility: visible;
    }

    .metaData {
      bottom: 1rem;

      &__title {
        margin-bottom: 0.5rem;
      }

      &__small {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  @media screen and (max-width: 768px) {
    height: 18rem;

    .metaData {
      &__title {
        font-size: 1.2rem;
      }

      &__small {
        font-size: 0.7rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    height: 14rem;

    .dotMenu {
      height: 1.5rem;
      width: 1.5rem;

      i {
        font-size: 0.8rem;
      }
    }

    .Player {
      transform: scaleX(1.25) scaleY(1.4);
    }

    .metaData {
      left: 0.4rem;
      bottom: -0.6rem;

      &__title {
        font-size: 0.9rem;
        width: 95%;
      }

      &__small {
        font-size: 0.6rem;
      }
    }
  }
`;
