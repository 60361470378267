import { ACTIVEUSER } from "../actions/types";

const activeUser = (state = [], action) => {
  switch (action.type) {
    case ACTIVEUSER:
      return action.payload.data;

    default:
      return state;
  }
};

export default activeUser;
