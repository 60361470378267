export const darkThemeProvider = (newValues) => {
  return {
    bg: "#181818",
    primaryColor: "#FFF",
    secondaryColor: "#AAAAAA",
    grey: "#202020",
    darkGrey: "#383838",
    black: "#121212",
    red: "#EE4266",

    gradient: "linear-gradient(29deg, rgb(249,154,45), rgb(246, 92, 139) 71%)",
    gradientV2: "linear-gradient(29deg, #FB0FC0, #4643EF 70%)",
    orange: "rgb(248 139 68)",
    blue: "#3EA6FF",
    white: "#FFF",
    pink: "#F86782",
    purple: "#282A36",
    green: "#28df99",
    font: "ingra",
    specialfont: "flood-std",
    montserrat: "Montserrat",

    // colors
    primaryGradient: "linear-gradient(29deg, #f99a2d, #f65c8b 71%)",
    button: "linear-gradient(29deg, #f99a2d, #f65c8b 71%)",
    primaryColor1: "#f99a2d",
    primaryColor2: "#f65c8b",

    // new branding
    ...newValues,
  };
};

export const darkTheme = {
  bg: "#181818",
  primaryColor: "#FFF",
  secondaryColor: "#AAAAAA",
  grey: "#202020",
  darkGrey: "#383838",
  black: "#121212",
  red: "#CC0000",
  primaryGradient:
    "linear-gradient(29deg, rgb(249,154,45), rgb(246, 92, 139) 71%)",
  gradient: "linear-gradient(29deg, rgb(249,154,45), rgb(246, 92, 139) 71%)",
  gradientV2: "linear-gradient(29deg, #FB0FC0, #4643EF 70%)",
  orange: "rgb(248 139 68)",
  blue: "#3EA6FF",
  white: "#FFF",
  pink: "#F86782",
  purple: "#282A36",
  green: "#28df99",
  font: "ingra",
  specialfont: "flood-std",
  montserrat: "Montserrat",
};
