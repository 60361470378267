import React from "react";
import styled from "styled-components";
import Button from "../Elements/Button";
import { useHistory } from "react-router-dom";

const StyledDiv = styled.div`
  padding: 1rem 1.3rem;
  width: 85%;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 7rem;
  padding-bottom: ${(props) => (props.nopad ? "0.5rem" : "7rem")};
  text-align: center;

  .btnContainer {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 930px) {
    width: 95%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const NoContentScreen = () => {
  const history = useHistory();

  const HandleHome = () => {
    history.push("/home");
  };
  return (
    <StyledDiv>
      <h2>iSutra Library</h2>
      <p className="secondary">
        Your Library is currently empty! Go start watching and Giving Karma on
        content to populate your Library.
      </p>
      <p className="secondary">
        This is your own personal iSutra library. As you start Viewing Content,
        Liking Content, Appreciating Warriors by Giving Karma Tips and
        purchasing Pay Per View & Series Videos, your content is Saved to this
        Library automatically. The more content you view/appreciate, the more
        your library is filled and saved forever.
      </p>

      <div className="btnContainer">
        <Button onClick={() => HandleHome()}>Explore More</Button>
      </div>
    </StyledDiv>
  );
};
