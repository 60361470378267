export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP = "SIGNUP";
export const SIGNUP_EMAIL_EXIST = "SIGNUP_EMAIL_EXIST";
export const SIGNUP_USERNAME_EXIST = "SIGNUP_USERNAME_EXIST";
export const REFERRAL_CODE_EXIST = "REFERRAL_CODE_EXIST";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN = "LOGIN";
export const ACTIVEUSER = "ACTIVEUSER";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_RESET = "LOGIN_RESET";
export const LOGOUT = "LOGOUT";
export const GET_FEED = "GET_FEED";
export const GET_VIDEO = "GET_VIDEO";
export const CLEAR_VIDEO = "CLEAR_VIDEO";
export const UPDATE_VIDEO = "UPDATE_VIDEO";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const DELETE_VIDEO_S3 = "DELETE_VIDEO_S3";
export const ADD_COMMENT = "ADD_COMMENT";
export const GET_PROFILE = "GET_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export const GET_USER_VIDEOS_SUCCESS_SECOND = "GET_USER_VIDEOS_SUCCESS_SECOND";
export const GET_USER_CHANNELS_SUCCESS = "GET_USER_CHANNELS_SUCCESS";
export const GET_USER_CHANNELS_HASMORE = "GET_USER_CHANNELS_HASMORE";
export const GET_USER_CHANNEL_REQUEST = "GET_USER_CHANNEL_REQUEST";
export const GET_CHANNEL_STREAM_VIDEOS = "GET_CHANNEL_STREAM_VIDEOS";
export const GET_HASMORE_MOMENTS = "GET_HASMORE_MOMENTS";
export const GET_CHANNEL_STREAM_VIDEOS_REQUEST =
  "GET_CHANNEL_STREAM_VIDEOS_REQUEST";
export const GET_CHANNEL_STREAM_VIDEOS_SUCCESS =
  "GET_CHANNEL_STREAM_VIDEOS_SUCCESS";
export const GET_CHANNEL_STREAM_VIDEOS_FAIL = "GET_CHANNEL_STREAM_VIDEOS_FAIL";
export const CHANNEL_PROFILE = "CHANNEL_PROFILE";

export const SHOW_CHANNEL_STREAM_FILTERED_VIDEOS =
  "SHOW_CHANNEL_STREAM_FILTERED_VIDEOS";
export const CLEAR_FILTERED_STREAM_VIDEOS = "CLEAR_FILTERED_STREAM_VIDEOS";

export const ISCHANNEL_SUBSCRIBED = "ISCHANNEL_SUBSCRIBED";
export const GET_CHAT = "GET_CHAT";
export const POST_MESSAGE = "POST_MESSAGE";

export const GET_NOTIFICATIONS_CATEGORIES = "GET_NOTIFICATIONS_CATEGORIES";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const GET_NOTIFICATION_COMMENT_ID = "GET_NOTIFICATION_COMMENT_ID";
export const SET_FETCHING = "SET_FETCHING";
export const CLEAR_COMMENT_ID = "CLEAR_COMMENT_ID";
export const INCREMENT_NOTIFICATION_COUNT = "INCREMENT_NOTIFICATION_COUNT";
export const NOTIFICATIONS_READ_COUNT = "NOTIFICATIONS_READ_COUNT";

export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const GET_CHANNEL_RECOMMENDATIONS = "GET_CHANNEL_RECOMMENDATIONS";
export const SUBSCRIBE_FROM_VIDEO = "SUBSCRIBE_FROM_VIDEO";
export const UNSUBSCRIBE_FROM_VIDEO = "UNSUBSCRIBE_FROM_VIDEO";
export const LIKE = "LIKE";
export const CANCEL_LIKE = "UNLIKE";
export const DISLIKE = "DISLIKE";
export const CANCEL_DISLIKE = "CANCEL_DISLIKE";
export const SUBSCRIBE_FROM_PROFILE = "SUBSCRIBE_FROM_PROFILE";
export const UNSUBSCRIBE_FROM_PROFILE = "UNSUBSCRIBE_FROM_PROFILE";
export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";
export const ADD_CHANNEL = "ADD_CHANNEL";
export const REMOVE_CHANNEL = "REMOVE_CHANNEL";
export const GET_LIKED_VIDEOS = "GET_LIKED_VIDEOS";
export const UPDATE_USER = "UPDATE_USER";
export const ADD_TO_LIKED_VIDEOS = "ADD_TO_LIKED_VIDEOS";
export const REMOVE_FROM_LIKED_VIDEOS = "REMOVE_FROM_LIKED_VIDEOS";
export const ADD_TO_RECOMMENDATIONS = "ADD_TO_RECOMMENDATIONS";
export const SUBSCRIBE_FROM_SEARCH_RESULTS = "SUBSCRIBE_FROM_SEARH_RESULTS";
export const UNSUBSCRIBE_FROM_SEARCH_RESULTS = "UNSUBSCRIBE_FROM_SEARH_RESULTS";
export const GET_HISTORY = "GET_HISTORY";
export const ADD_TO_HISTORY = "ADD_TO_HISTORY";
export const SHOW_NOT_FOUND = "SHOW_NOT_FOUND";
export const CLEAR_NOT_FOUND = "CLEAR_NOT_FOUND";

export const STRIPE_ONBOARD = "STRIPE_ONBOARD";
export const ACCEPT_PAYMENT_SUCCESS = "ACCEPT_PAYMENT_SUCCESS";
export const TRANSFERS_AMOUNT = "TRANSFERS_AMOUNT";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";

export const PROFILE_ELEMENT_MOMENTS = "PROFILE_ELEMENT_MOMENTS";
export const PROFILE_ELEMENT_MOMENTS_SECOND = "PROFILE_ELEMENT_MOMENTS_SECOND";
export const PROFILE_ELEMENT_VIDEOS = "PROFILE_ELEMENT_VIDEOS";
export const PROFILE_ELEMENT_VIDEOS_SECOND = "PROFILE_ELEMENT_VIDEOS_SECOND";

export const PROFILE_ELEMENT_SERIES = "PROFILE_ELEMENT_Series";
export const PROFILE_ELEMENT_SERIES_SECOND = "PROFILE_ELEMENT_Series_SECOND";

export const PROFILE_ELEMENT_THREADS = "PROFILE_ELEMENT_THREADS";
export const PROFILE_ELEMENT_THREADS_SECOND = "PROFILE_ELEMENT_THREADS_SECOND";

export const PROFILE_ELEMENT_BANNERS = "PROFILE_ELEMENT_BANNERS";
export const PROFILE_ELEMENT_BANNERS_SECOND = "PROFILE_ELEMENT_BANNERS_SECOND";

export const SUBSCRIBE_FROM_CHANNEL_RECOMMENDATIONS =
  "SUBSCRIBE_FROM_CHANNEL_RECOMMENDATIONS";
export const UNSUBSCRIBE_FROM_CHANNEL_RECOMMENDATIONS =
  "UNSUBSCRIBE_FROM_CHANNEL_RECOMMENDATIONS";

export const GET_VIDEO_CATEGORIES = "GET_VIDEO_CATEGORIES";
export const GET_VIDEO_ACCESS_OVERLAYS = "GET_VIDEO_ACCESS_OVERLAYS";

export const GET_HASHTAGS = "GET_HASHTAGS";

export const GET_LATEST_VIDEOS = "GET_LATEST_VIDEOS";
export const GET_TRENDING = "GET_TRENDING";
export const GET_RECOMMENDATIONS = "GET_RECOMMENDATIONS";
export const GET_FEATURED = "GET_FEATURED";
export const GET_STAFF_PICK = "GET_STAFF_PICK";
export const GET_VIDEOS_FEATURED = "GET_VIDEOS_FEATURED";
export const GET_VIDEOS_STAFF_PICK = "GET_VIDEOS_STAFF_PICK";
export const GET_VIDEOS_BY_CATEGORY = "GET_VIDEOS_BY_CATEGORY";

export const GET_ALL_STORAGE = "GET_ALL_STORAGE";
export const GET_SINGLE_STORAGE = "GET_SINGLE_STORAGE";
export const GET_ALL_USER_SETTINGS = "GET_ALL_USER_SETTINGS";
export const GET_USER_SETTING_BY_USERID = "GET_USER_SETTING_BY_USERID";
export const UPDATE_USER_SETTING = "UPDATE_USER_SETTING";
export const UPDATE_USER_SETTING_REQUEST = "UPDATE_USER_SETTING_REQUEST";
export const GET_ALL_BADGES = "GET_ALL_BADGES";
export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILED = "VERIFY_EMAIL_FAILED";
export const GET_BADGES_BY_USERROLE = "GET_BADGES_BY_USERROLE";
export const GET_USER_BY_ID = "GET_US ER_BY_ID";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const MORE_FROM_USER = "MORE_FROM_USER";
export const GET_CATEGORY_BY_ID = "GET_CATEGORY_BY_ID";
export const SET_EMAIL_EXISTS_FALSE = "SET_EMAIL_EXISTS_FALSE";
export const VISIT_STRIPE_CUSTOMER_PORTAL_REQUEST =
  "VISIT_STRIPE_CUSTOMER_PORTAL_REQUEST";
export const VISIT_STRIPE_CUSTOMER_PORTAL_SUCCESS =
  "VISIT_STRIPE_CUSTOMER_PORTAL_SUCCESS";
export const VISIT_STRIPE_CUSTOMER_PORTAL_FAILED =
  "VISIT_STRIPE_CUSTOMER_PORTAL_FAILED";
export const UNLOCK_WATCH_VIDEO = "UNLOCK_WATCH_VIDEO";
export const UNLOCK_FEATURED_VIDEO = "UNLOCK_FEATURED_VIDEO";
export const UNLOCK_TRENDING_VIDEO = "UNLOCK_TRENDING_VIDEO";
export const UNLOCK_LATEST_VIDEO = "UNLOCK_LATEST_VIDEO";
export const UNLOCK_STAFF_PICK_VIDEO = "UNLOCK_STAFF_PICK_VIDEO";
export const UNLOCK_CATEGORY_FEATURED_VIDEO = "UNLOCK_CATEGORY_FEATURED_VIDEO";
export const UNLOCK_CATEGORY_VIDEO = "UNLOCK_CATEGORY_VIDEO";
export const UNLOCK_CATEGORY_STAFF_PICK_VIDEO =
  "UNLOCK_CATEGORY_STAFF_PICK_VIDEO";
export const UNLOCK_MORE_VIDEO = "UNLOCK_MORE_VIDEO";
export const UNLOCK_LIKE_VIDEO = "UNLOCK_LIKE_VIDEO";
export const UNLOCK_SEARCH_VIDEO = "UNLOCK_SEARCH_VIDEO";
export const UNLOCK_PROFILE_VIDEO = "UNLOCK_PROFILE_VIDEO";
export const GET_USER_TRANSACTIONS_REQ = "GET_USER_TRANSACTIONS_REQ";
export const GET_USER_TRANSACTIONS = "GET_USER_TRANSACTIONS";
export const GET_USER_TRANSACTIONS_FAILED = "GET_USER_TRANSACTIONS_FAILED";

export const GET_KARMA_SENT_REQ = "GET_KARMA_SENT_REQ";
export const GET_KARMA_SENT_TRANSACTIONS = "GET_KARMA_SENT_TRANSACTIONS";
export const GET_KARMA_SENT_TRANSACTIONS_FAILED =
  "GET_KARMA_SENT_TRANSACTIONS_FAILED";

export const SET_FILTERED_DATA = "SET_FILTERED_DATA";

export const GET_WARRIOR_SLIDER = "GET_WARRIOR_SLIDER";
export const GET_FEATURED_WARRIORS = "GET_FEATURED_WARRIORS";

export const SEND_CONTACT_US_EMAIL_REQ = "SEND_CONTACT_US_EMAIL_REQ";
export const SEND_CONTACT_US_EMAIL_SUCCESS = "SEND_CONTACT_US_EMAIL_SUCCESS";
export const SEND_CONTACT_US_EMAIL_FAILED = "SEND_CONTACT_US_EMAIL_FAILED";
export const RESET_CONTACT_US = "RESET_CONTACT_US";

export const GET_LIBRARY_VIDEOS = "GET_LIBRARY_VIDEOS";

export const DELETE_USER = "DELETE_USER";

export const GET_MARKETING_BANNERS = "GET_MARKETING_BANNERS";
export const CLEAR_MARKETING_BANNERS = "CLEAR_MARKETING_BANNERS";
export const ADD_NEW_CUSTOM_BANNER = "ADD_NEW_CUSTOM_BANNER";
export const REMOVE_CUSTOM_BANNER = "REMOVE_CUSTOM_BANNER";

export const UNLOCK_TAT_VIDEO = "UNLOCK_TAT_VIDEO";
export const CHECK_EXPIRED_PW_RESET_TOKEN = "CHECK_EXPIRED_PW_RESET_TOKEN";
export const PW_RESET_TOKEN_EXPIRED = "PW_RESET_TOKEN_EXPIRED";

export const CLEAR_RESET_PASSWORD_ERROR = "CLEAR_RESET_PASSWORD_ERROR";

export const NEW_CATEGORY_REQUEST = "NEW_CATEGORY_REQUEST";
export const NEW_CATEGORY_SUCCESS = "NEW_CATEGORY_SUCCESS";
export const NEW_CATEGORY_FAIL = "NEW_CATEGORY_FAIL";

export const NEW_CONTENT_FLAG_REQUEST = "NEW_CONTENT_FLAG_REQUEST";
export const NEW_CONTENT_FLAG_SUCCESS = "NEW_CONTENT_FLAG_SUCCESS";
export const NEW_CONTENT_FLAG_FAIL = "NEW_CONTENT_FLAG_FAIL";

export const GET_FLAG_TYPES_REQUEST = "GET_FLAG_TYPES_REQUEST";
export const GET_FLAG_TYPES_SUCCESS = "GET_FLAG_TYPES_SUCCESS";
export const GET_FLAG_TYPES_FAIL = "GET_FLAG_TYPES_FAIL";

export const CREATE_NEW_PLAYLIST_REQUEST = "CREATE_NEW_PLAYLIST_REQUEST";
export const CREATE_NEW_PLAYLIST_SUCCESS = "CREATE_NEW_PLAYLIST_SUCCESS";
export const CREATE_NEW_PLAYLIST_FAIL = "CREATE_NEW_PLAYLIST_FAIL";

export const DELETE_PLAYLIST_REQUEST = "DELETE_PLAYLIST_REQUEST";
export const DELETE_PLAYLIST_SUCCESS = "DELETE_PLAYLIST_SUCCESS";
export const DELETE_PLAYLIST_FAIL = "DELETE_PLAYLIST_FAIL";

export const EDIT_PLAYLIST_REQUEST = "EDIT_PLAYLIST_REQUEST";
export const EDIT_PLAYLIST_SUCCESS = "EDIT_PLAYLIST_SUCCESS";
export const EDIT_PLAYLIST_FAIL = "EDIT_PLAYLIST_FAIL";

export const GET_ALL_PLAYLIST_REQUEST = "GET_ALL_PLAYLIST_REQUEST";
export const GET_ALL_PLAYLIST_SUCCESS = "GET_ALL_PLAYLIST_SUCCESS";
export const GET_ALL_PLAYLIST_SUCCESS_SECOND =
  "GET_ALL_PLAYLIST_SUCCESS_SECOND";
export const GET_ALL_PLAYLIST_FAIL = "GET_ALL_PLAYLIST_FAIL";

export const GET_SINGLE_PLAYLIST_REQUEST = "GET_SINGLE_PLAYLIST_REQUEST";
export const GET_SINGLE_PLAYLIST_SUCCESS = "GET_SINGLE_PLAYLIST_SUCCESS";
export const GET_SINGLE_PLAYLIST_FAIL = "GET_SINGLE_PLAYLIST_FAIL";

export const ADD_VIDEO_TO_PLAYLIST_REQUEST = "ADD_VIDEO_TO_PLAYLIST_REQUEST";
export const ADD_VIDEO_TO_PLAYLIST_SUCCESS = "ADD_VIDEO_TO_PLAYLIST_SUCCESS";
export const ADD_VIDEO_TO_PLAYLIST_FAIL = "ADD_VIDEO_TO_PLAYLIST_FAIL";
export const ADD_VIDEO_TO_PLAYLIST_RESET = "ADD_VIDEO_TO_PLAYLIST_RESET";

export const REMOVE_VIDEO_FROM_PLAYLIST_REQUEST =
  "REMOVE_VIDEO_FROM_PLAYLIST_REQUEST";
export const REMOVE_VIDEO_FROM_PLAYLIST_SUCCESS =
  "REMOVE_VIDEO_FROM_PLAYLIST_SUCCESS";
export const REMOVE_VIDEO_FROM_PLAYLIST_FAIL =
  "REMOVE_VIDEO_FROM_PLAYLIST_FAIL";
export const REMOVE_VIDEO_FROM_PLAYLIST_RESET =
  "REMOVE_VIDEO_FROM_PLAYLIST_RESET";

export const CREATE_NEW_SERIES_REQUEST = "CREATE_NEW_SERIES_REQUEST";
export const CREATE_NEW_SERIES_SUCCESS = "CREATE_NEW_SERIES_SUCCESS";
export const CREATE_NEW_SERIES_FAIL = "CREATE_NEW_SERIES_FAIL";
export const CREATE_NEW_SERIES_RESET = "CREATE_NEW_SERIES_RESET";

export const DELETE_SERIES_REQUEST = "DELETE_SERIES_REQUEST";
export const DELETE_SERIES_SUCCESS = "DELETE_SERIES_SUCCESS";
export const DELETE_SERIES_FAIL = "DELETE_SERIES_FAIL";
export const DELETE_SERIES_RESET = "DELETE_SERIES_RESET";

export const EDIT_SERIES_REQUEST = "EDIT_SERIES_REQUEST";
export const EDIT_SERIES_SUCCESS = "EDIT_SERIES_SUCCESS";
export const EDIT_SERIES_FAIL = "EDIT_SERIES_FAIL";
export const EDIT_SERIES_RESET = "EDIT_SERIES_RESET";

export const GET_ALL_SERIES_OF_USER_REQUEST = "GET_ALL_SERIES_OF_USER_REQUEST";
export const GET_ALL_SERIES_OF_USER_SUCCESS = "GET_ALL_SERIES_OF_USER_SUCCESS";
export const GET_ALL_SERIES_OF_USER_SUCCESS_SECOND =
  "GET_ALL_SERIES_OF_USER_SUCCESS_SECOND";
export const GET_ALL_SERIES_OF_USER_FAIL = "GET_ALL_SERIES_OF_USER_FAIL";
export const GET_ALL_SERIES_OF_USER_RESET = "GET_ALL_SERIES_OF_USER_RESET";

export const GET_SINGLE_SERIES_REQUEST = "GET_SINGLE_SERIES_REQUEST";
export const GET_SINGLE_SERIES_SUCCESS = "GET_SINGLE_SERIES_SUCCESS";
export const GET_SINGLE_SERIES_FAIL = "GET_SINGLE_SERIES_FAIL";

export const ADD_VIDEO_TO_SERIES_REQUEST = "ADD_VIDEO_TO_SERIES_REQUEST";
export const ADD_VIDEO_TO_SERIES_SUCCESS = "ADD_VIDEO_TO_SERIES_SUCCESS";
export const ADD_VIDEO_TO_SERIES_FAIL = "ADD_VIDEO_TO_SERIES_FAIL";
export const ADD_VIDEO_TO_SERIES_RESET = "ADD_VIDEO_TO_SERIES_RESET";

export const REMOVE_VIDEO_FROM_SERIES_REQUEST =
  "REMOVE_VIDEO_FROM_SERIES_REQUEST";
export const REMOVE_VIDEO_FROM_SERIES_SUCCESS =
  "REMOVE_VIDEO_FROM_SERIES_SUCCESS";
export const REMOVE_VIDEO_FROM_SERIES_FAIL = "REMOVE_VIDEO_FROM_SERIES_FAIL";
export const REMOVE_VIDEO_FROM_SERIES_RESET = "REMOVE_VIDEO_FROM_SERIES_RESET";

export const DRAG_DROP_SERIES_VIDEOS = "DRAG_DROP_SERIES_VIDEOS";

export const GET_ALL_SERIES_REQUEST = "GET_ALL_SERIES_REQUEST";
export const GET_ALL_SERIES_SUCCESS = "GET_ALL_SERIES_SUCCESS";
export const GET_ALL_SERIES_FAIL = "GET_ALL_SERIES_FAIL";

export const GET_ALL_PURCHASED_SERIES_REQUEST =
  "GET_ALL_PURCHASED_SERIES_REQUEST";
export const GET_ALL_PURCHASED_SERIES_SUCCESS =
  "GET_ALL_PURCHASED_SERIES_SUCCESS";
export const GET_ALL_PURCHASED_SERIES_FAIL = "GET_ALL_PURCHASED_SERIES_FAIL";

export const GET_SERIES_PROGRESS_REQUEST = "GET_SERIES_PROGRESS_REQUEST";
export const GET_SERIES_PROGRESS_SUCCESS = "GET_SERIES_PROGRESS_SUCCESS";
export const GET_SERIES_PROGRESS_FAIL = "GET_SERIES_PROGRESS_FAIL";
export const GET_SERIES_PROGRESS_RESET = "GET_SERIES_PROGRESS_RESET";

export const GET_VIDEOS_REQUESTV2 = "GET_VIDEOS_REQUESTV2";
export const GET_VIDEOS_SUCCESSV2 = "GET_VIDEOS_SUCCESSV2";
export const PUSH_UPLOADED_VIDEOV2 = "PUSH_UPLOADED_VIDEOV2";
export const GET_VIDEOS_FAILV2 = "GET_VIDEOS_FAILV2";
export const SHOW_FILTERED_VIDEOS = "SHOW_FILTERED_VIDEOS";
export const CLEAR_FILTERED_VIDEOS = "CLEAR_FILTERED_VIDEOS";

export const GET_VIDEOS_REQUEST = "GET_VIDEOS_REQUEST";
export const GET_VIDEOS_SUCCESS = "GET_VIDEOS_SUCCESS";
export const GET_VIDEOS_FAIL = "GET_VIDEOS_FAIL";

export const GET_KARMA_FILTER_SETTING_REQUEST =
  "GET_KARMA_FILTER_SETTING_REQUEST";
export const GET_KARMA_FILTER_SETTING_SUCCESS =
  "GET_KARMA_FILTER_SETTING_SUCCESS";
export const GET_KARMA_FILTER_SETTING_FAILED =
  "GET_KARMA_FILTER_SETTING_FAILED";

export const CREATE_SCHEDULE_REQUEST = "CREATE_SCHEDULE_REQUEST";
export const CREATE_SCHEDULE_SUCCESS = "CREATE_SCHEDULE_SUCCESS";
export const CREATE_SCHEDULE_FAIL = "CREATE_SCHEDULE_FAIL";

export const GET_SCHEDULE_REQUEST = "GET_SCHEDULE_REQUEST";
export const GET_SCHEDULE_SUCCESS = "GET_SCHEDULE_SUCCESS";
export const GET_SCHEDULE_FAIL = "GET_SCHEDULE_FAIL";

export const SEND_CONNECTION_REQUEST = "SEND_CONNECTION_REQUEST";
export const SEND_CONNECTION_SUCCESS = "SEND_CONNECTION_SUCCESS";
export const SEND_CONNECTION_FAIL = "SEND_CONNECTION_FAIL";
export const GET_CONNECTIONS_REQUEST = "GET_CONNECTION_REQUESTS";
export const GET_CONNECTIONS_SUCCESS = "GET_CONNECTION_SUCCESS";
export const GET_CONNECTIONS_FAIL = "GET_CONNECTION_FAIL";
export const APPROVE_CONNECTION_REQUEST = "APPROVE_CONNECTION_REQUEST";
export const APPROVE_CONNECTION_SUCCESS = "APPROVE_CONNECTION_SUCCESS";
export const APPROVE_CONNECTION_FAIL = "APPROVE_CONNECTION_FAIL";
export const DECLINE_CONNECTION_REQUEST = "DECLINE_CONNECTION_REQUEST";
export const DECLINE_CONNECTION_SUCCESS = "DECLINE_CONNECTION_SUCCESS";
export const DECLINE_CONNECTION_FAIL = "DECLINE_CONNECTION_FAIL";
export const GET_FRIENDS_REQUEST = "GET_FRIENDS_REQUEST";
export const GET_FRIENDS_SUCCESS = "GET_FRIENDS_SUCCESS";
export const GET_FRIENDS_FAIL = "GET_FRIENDS_REQUEST";
export const GET_CONNECTION_STATUS_REQUEST = "GET_CONNECTION_STATUS_REQUEST";
export const GET_CONNECTION_STATUS_SUCCESS = "GET_CONNECTION_STATUS_SUCCESS";
export const GET_CONNECTION_STATUS_FAIL = "GET_CONNECTION_STATUS_FAIL";
export const REMOVE_CONNECTION_REQUEST = "REMOVE_CONNECTION_REQUEST";
export const REMOVE_CONNECTION_SUCCESS = "REMOVE_CONNECTION_SUCCESS";
export const REMOVE_CONNECTION_FAIL = "REMOVE_CONNECTION_FAIL";

// moments
export const UPLOAD_MOMENT_REQUEST = "UPLOAD_MOMENT_REQUEST";
export const UPLOAD_MOMENT_SUCCESS = "UPLOAD_MOMENT_SUCCESS";
export const UPLOAD_MOMENT_FAIL = "UPLOAD_MOMENT_FAIL";

export const UPDATE_MOMENT_REQUEST = "UPDATE_MOMENT_REQUEST";
export const UPDATE_MOMENT_SUCCESS = "UPDATE_MOMENT_SUCCESS";
export const UPDATE_MOMENT_FAIL = "UPDATE_MOMENT_FAIL";

export const GET_SINGLE_MOMENT_REQUEST = "GET_SINGLE_MOMENT_REQUEST";
export const GET_SINGLE_MOMENT_SUCCESS = "GET_SINGLE_MOMENT_SUCCESS";
export const GET_SINGLE_MOMENT_FAIL = "GET_SINGLE_MOMENT_FAIL";

export const DELETE_MOMENT_REQUEST = "DELETE_MOMENT_REQUEST";
export const DELETE_MOMENT_SUCCESS = "DELETE_MOMENT_SUCCESS";
export const DELETE_MOMENT_FAIL = "DELETE_MOMENT_FAIL";

export const RESET_MOMENT = "RESET_MOMENT";

export const GET_ALL_MOMENT_REQUEST = "GET_ALL_MOMENT_REQUEST";
export const GET_ALL_MOMENT_SUCCESS = "GET_ALL_MOMENT_SUCCESS";
export const GET_ALL_MOMENT_FAIL = "GET_ALL_MOMENT_FAIL";
export const RESET_GET_ALL_MOMENTS = "RESET_GET_ALL_MOMENTS";

export const GET_STREAMS_MOMENTS_REQUEST = "GET_STREAMS_MOMENTS_REQUEST";
export const GET_STREAMS_MOMENTS_SUCCESS = "GET_STREAMS_MOMENTS_SUCCESS";
export const GET_STREAMS_MOMENTS_FAIL = "GET_STREAMS_MOMENTS_FAIL";

export const TAG_USER_IN_MOMENT_REQUEST = "TAG_USER_IN_MOMENT_REQUEST";
export const TAG_USER_IN_MOMENT_SUCCESS = "TAG_USER_IN_MOMENT_SUCCESS";
export const TAG_USER_IN_MOMENT_FAIL = "TAG_USER_IN_MOMENT_FAIL";
export const TAG_USER_IN_MOMENT_RESET = "TAG_USER_IN_MOMENT_RESET";

export const GET_TAGGED_USERS_OF_MOMENT_REQUEST =
  "GET_TAGGED_USERS_OF_MOMENT_REQUEST";
export const GET_TAGGED_USERS_OF_MOMENT_SUCCESS =
  "GET_TAGGED_USERS_OF_MOMENT_SUCCESS";
export const GET_TAGGED_USERS_OF_MOMENT_FAIL =
  "GET_TAGGED_USERS_OF_MOMENT_FAIL";

export const GET_MY_MOMENT_REQUEST = "GET_MY_MOMENT_REQUEST";
export const GET_MY_MOMENT_SUCCESS = "GET_MY_MOMENT_SUCCESS";
export const GET_MY_MOMENT_FAIL = "GET_MY_MOMENT_FAIL";
export const GET_MY_MOMENT_RESET = "GET_MY_MOMENT_RESET";

export const GET_MY_BANNERS_REQUEST = "GET_MY_BANNERS_REQUEST";
export const GET_MY_BANNERS_SUCCESS = "GET_MY_BANNERS_SUCCESS";
export const GET_MY_BANNERS_FAIL = "GET_MY_BANNERS_FAIL";

export const UPDATE_SCHEDULE_REQUEST = "UPDATE_SCHEDULE_REQUEST";
export const UPDATE_SCHEDULE_SUCCESS = "UPDATE_SCHEDULE_SUCCESS";
export const UPDATE_SCHEDULE_FAIL = "UPDATE_SCHEDULE_FAIL";

export const DELETE_SCHEDULE_REQUEST = "DELETE_SCHEDULE_REQUEST";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_FAIL = "DELETE_SCHEDULE_FAIL";

export const GET_CATEGORY_BY_CLASS_ID = "GET_CATEGORY_BY_CLASS_ID";

// profile element
export const CREATE_PROFILE_ELEMENT_REQUEST = "CREATE_PROFILE_ELEMENT_REQUEST";
export const CREATE_PROFILE_ELEMENT_SUCCESS = "CREATE_PROFILE_ELEMENT_SUCCESS";
export const CREATE_PROFILE_ELEMENT_FAIL = "CREATE_PROFILE_ELEMENT_FAIL";

export const DELETE_PROFILE_ELEMENT_REQUEST = "DELETE_PROFILE_ELEMENT_REQUEST";
export const DELETE_PROFILE_ELEMENT_SUCCESS = "DELETE_PROFILE_ELEMENT_SUCCESS";
export const DELETE_PROFILE_ELEMENT_FAIL = "DELETE_PROFILE_ELEMENT_FAIL";

export const GET_PROFILE_ELEMENTS_REQUEST = "GET_PROFILE_ELEMENTS_REQUEST";
export const GET_PROFILE_ELEMENTS_SUCCESS = "GET_PROFILE_ELEMENTS_SUCCESS";
export const GET_PROFILE_ELEMENTS_FAIL = "GET_PROFILE_ELEMENTS_FAIL";

export const DRAG_DROP_PROFILE_ELEMENTS = "DRAG_DROP_PROFILE_ELEMENTS";

export const ADD_CONTENT_TO_ELEMENT = "ADD_CONTENT_TO_ELEMENT";
export const REMOVE_CONTENT_FROM_ELEMENT = "REMOVE_CONTENT_FROM_ELEMENT";

export const RESET_PROFILE_ELEMENT = "RESET_PROFILE_ELEMENT";
export const CHAT_MESSAGE_FAIL = "CHAT_MESSAGE_FAIL";
export const CHAT_MESSAGE_SUCCESS = "CHAT_MESSAGE_SUCCESS";

export const KARMA_RECEIVED_REQUEST = "KARMA_RECEIVED_REQUEST";
export const KARMA_RECEIVED_SUCCESS = "KARMA_RECEIVED_SUCCESS";
export const KARMA_RECEIVED_FAILED = "KARMA_RECEIVED_FAILED";

export const SET_KARMA_FILTERED_DATA = "SET_KARMA_FILTERED_DATA";

export const SUBSCRIPTION_PLANS_REQUEST = "SUBSCRIPTION_PLANS_REQUEST";
export const SUBSCRIPTION_PLANS_SUCCESS = "SUBSCRIPTION_PLANS_SUCCESS";
export const SUBSCRIPTION_PLANS_FAILED = "SUBSCRIPTION_PLANS_FAILED";

export const UPDATE_SUBSCRIPTION_PLANS_REQUEST =
  "UPDATE_SUBSCRIPTION_PLANS_REQUEST";
export const UPDATE_SUBSCRIPTION_PLANS_SUCCESS =
  "UPDATE_SUBSCRIPTION_PLANS_SUCCESS";
export const UPDATE_SUBSCRIPTION_PLANS_FAILED =
  "UPDATE_SUBSCRIPTION_PLANS_FAILED";
export const UPDATE_SUBSCRIPTION_CLEANUP = "UPDATE_SUBSCRIPTION_CLEANUP";

export const UPDATE_PAYMET_METHOD_REQUEST = "UPDATE_PAYMET_METHOD_REQUEST";
export const UPDATE_PAYMET_METHOD_SUCCESS = "UPDATE_PAYMET_METHOD_SUCCESS";
export const UPDATE_PAYMET_METHOD_FAILED = "UPDATE_PAYMET_METHOD_FAILED";

export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
export const UPDATE_USER_PAYMENT_METHOD = "UPDATE_USER_PAYMENT_METHOD";
export const ADD_NEW_PAYMENT_METHOD = "ADD_NEW_PAYMENT_METHOD";

export const UPDATE_USER_BADGE = "UPDATE_USER_BADGE";
export const SET_CURRENT_SUBSCRIPTION = "SET_CURRENT_SUBSCRIPTION";
export const SET_DEFAULT_PAYMENT_METHOD = "SET_DEFAULT_PAYMENT_METHOD";

export const UPDATE_SUBSCRIPTION_ID = "UPDATE_SUBSCRIPTION_ID";

export const GET_USER_VIDEOS_REQUEST = "GET_USER_VIDEOS_REQUEST";
export const GET_USER_VIDEOS_SUCCESS = "GET_USER_VIDEOS_SUCCESS";
export const GET_USER_VIDEOS_FAIL = "GET_USER_VIDEOS_FAIL";
export const GET_USER_VIDEOS_RESET = "GET_USER_VIDEOS_RESET";

export const VIDEO_UPLOAD_REQUEST = "VIDEO_UPLOAD_REQUEST";
export const VIDEO_UPLOAD_SUCCESS = "VIDEO_UPLOAD_SUCCESS";
export const VIDEO_UPLOAD_FAIL = "VIDEO_UPLOAD_FAIL";
export const VIDEO_UPLOAD_RESET = "VIDEO_UPLOAD_RESET";

export const VIDEO_UPDATE_REQUEST = "VIDEO_UPDATE_REQUEST";
export const VIDEO_UPDATE_SUCCESS = "VIDEO_UPDATE_SUCCESS";
export const VIDEO_UPDATE_FAIL = "VIDEO_UPDATE_FAIL";
export const VIDEO_UPDATE_RESET = "VIDEO_UPDATE_RESET";

export const VIDEO_DELETE_REQUEST = "VIDEO_DELETE_REQUEST";
export const VIDEO_DELETE_SUCCESS = "VIDEO_DELETE_SUCCESS";
export const VIDEO_DELETE_FAIL = "VIDEO_DELETE_FAIL";
export const VIDEO_DELETE_RESET = "VIDEO_DELETE_RESET";

export const USER_STORAGE_INFO_REQUEST = "USER_STORAGE_INFO_REQUEST";
export const USER_STORAGE_INFO_SUCCESS = "USER_STORAGE_INFO_SUCCESS";
export const USER_STORAGE_INFO_FAIL = "USER_STORAGE_INFO_FAIL";
export const CHATCOUNT = "CHATCOUNT";

// featured series
export const GET_FEATURED_SERIES_REQUEST = "GET_FEATURED_SERIES_REQUEST";
export const GET_FEATURED_SERIES_SUCCESS = "GET_FEATURED_SERIES_SUCCESS";
export const GET_FEATURED_SERIES_FAIL = "GET_FEATURED_SERIES_FAIL";

// user notification settings
export const GET_NOTIFICATION_SETTING_REQUEST =
  "GET_NOTIFICATION_SETTING_REQUEST";
export const GET_NOTIFICATION_SETTING_SUCCESS =
  "GET_NOTIFICATION_SETTING_SUCCESS";
export const GET_NOTIFICATION_SETTING_FAIL = "GET_NOTIFICATION_SETTING_FAIL";
export const UPDATE_NOTIFICATION_SETTING = "UPDATE_NOTIFICATION_SETTING";
export const GET_USER_STREAMS_REQUEST = "GET_USER_STREAMS_REQUEST";
export const GET_USER_STREAMS_SUCCESS = "GET_USER_STREAMS_SUCCESS";
export const GET_USER_STREAMS_FAIL = "GET_USER_STREAMS_FAIL";

export const GET_LIKED_MOMENTS_REQUEST = "GET_LIKED_MOMENTS_REQUEST";
export const GET_LIKED_MOMENTS_SUCCESS = "GET_LIKED_MOMENTS_SUCCESS";
export const GET_LIKED_MOMENTS_FAIL = "GET_LIKED_MOMENTS_FAIL";
export const UNLOCK_ADDITIONAL_CONTENT = "UNLOCK__ADDITIONAL_CONTENT";

// Admin data
export const FETCH_ADMIN_USER_DATA_SUCCESS = "FETCH_ADMIN_USER_DATA_SUCCESS";
export const FETCH_ADMIN_USER_DATA_FAILURE = "FETCH_ADMIN_USER_DATA_FAILURE";

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const DELETE_ADMIN_USER = "DELETE_ADMIN_USER";

// Admin User Types
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";
export const FETCH_USER_DATA_FAILURE = "FETCH_USER_DATA_FAILURE";

// Admin Video Types
export const FETCH_VIDEO_DATA_SUCCESS = "FETCH_VIDEO_DATA_SUCCESS";
export const FETCH_VIDEO_DATA_FAILURE = "FETCH_VIDEO_DATA_FAILURE";

// Admin Moment Types
export const FETCH_MOMENT_DATA_SUCCESS = "FETCH_MOMENT_DATA_SUCCESS";
export const FETCH_MOMENT_DATA_FAILURE = "FETCH_MOMENT_DATA_FAILURE";

// Admin Series Types
export const FETCH_SERIES_DATA_SUCCESS = "FETCH_SERIES_DATA_SUCCESS";
export const FETCH_SERIES_DATA_FAILURE = "FETCH_SERIES_DATA_FAILURE";

// Admin Series Video Types
export const FETCH_SERIES_VIDEO_DATA_SUCCESS =
  "FETCH_SERIES_VIDEO_DATA_SUCCESS";
export const FETCH_SERIES_VIDEO_DATA_FAILURE =
  "FETCH_SERIES_VIDEO_DATA_FAILURE";

// Admin NotificationType
export const FETCH_NOTIFICATION_TYPE_SUCCESS =
  "FETCH_NOTIFICATION_TYPE_SUCCESS";
export const FETCH_NOTIFICATION_TYPE_FAILURE =
  "FETCH_NOTIFICATION_TYPE_FAILURE";

// Admin Notification Category
export const FETCH_NOTIFICATION_CATEGORY_SUCCESS =
  "FETCH_NOTIFICATION_CATEGORY_SUCCESS";
export const FETCH_NOTIFICATION_CATEGORY_FAILURE =
  "FETCH_NOTIFICATION_CATEGORY_FAILURE";

// Admin Video Category
export const FETCH_VIDEO_CATEGORY_SUCCESS = "FETCH_VIDEO_CATEGORY_SUCCESS";
export const FETCH_VIDEO_CATEGORY_FAILURE = "FETCH_VIDEO_CATEGORY_FAILURE";

// Define an action type
export const ADD_VIDEO_CATEGORY_SUCCESS = "ADD_VIDEO_CATEGORY_SUCCESS";
export const ADD_VIDEO_CATEGORY_FAILURE = "ADD_VIDEO_CATEGORY_FAILURE";

//Admin Sub Category one ADD/Upload
export const ADD_SUB_CATEGORY_ONE_SUCCESS = "ADD_SUB_CATEGORY_ONE_SUCCESS";
export const ADD_SUB_CATEGORY_ONE_FAILURE = "ADD_SUB_CATEGORY_ONE_FAILURE";

// Admin sub Category one
export const FETCH_SUB_CATEGORY_ONE_SUCCESS = "FETCH_SUB_CATEGORY_ONE_SUCCESS";
export const FETCH_SUB_CATEGORY_ONE_FAILURE = "FETCH_SUB_CATEGORY_ONE_FAILURE";

// Admin Video Category
export const FETCH_VIDEO_SUB_CATEGORY_SUCCESS =
  "FETCH_VIDEO_SUB_CATEGORY_SUCCESS";
export const FETCH_VIDEO_SUB_CATEGORY_FAILURE =
  "FETCH_VIDEO_SUB_CATEGORY_FAILURE";

// Admin sub Category Two
export const FETCH_SUB_CATEGORY_TWO_SUCCESS = "FETCH_SUB_CATEGORY_TWO_SUCCESS";
export const FETCH_SUB_CATEGORY_TWO_FAILURE = "FETCH_SUB_CATEGORY_TWO_FAILURE";

//Admin Sub Category Two Update
export const UPDATE_SUB_CATEGORY_TWO_SUCCESS =
  "UPDATE_SUB_CATEGORY_TWO_SUCCESS";
export const UPDATE_SUB_CATEGORY_TWO_FAILURE =
  "UPDATE_SUB_CATEGORY_TWO_FAILURE";

// Admin Video Category rEQUEST
export const FETCH_VIDEO_CATEGORY_REQUEST_SUCCESS =
  "FETCH_VIDEO_CATEGORY_REQUEST_SUCCESS";
export const FETCH_VIDEO_CATEGORY_REQUEST_FAILURE =
  "FETCH_VIDEO_CATEGORY_REQUEST_FAILURE";

// Admin Get Threads Data
export const FETCH_THREADS_TABLE_SUCCESS = "FETCH_THREADS_TABLE_SUCCESS";
export const FETCH_THREADS_TABLE_FAILURE = "FETCH_THREADS_TABLE_FAILURE";

// Admin Update Threads Data
export const UPDATE_THREADS_TABLE_SUCCESS = "UPDATE_THREADS_TABLE_SUCCESS";
export const UPDATE_THREADS_TABLE_FAILURE = "UPDATE_THREADS_TABLE_FAILURE";

// Admin Get Threads Images  Data
export const FETCH_THREAD_IMAGES_TABLE_SUCCESS =
  "FETCH_THREAD_IMAGES_TABLE_SUCCESS";
export const FETCH_THREAD_IMAGES_TABLE_FAILURE =
  "FETCH_THREAD_IMAGES_TABLE_FAILURE";

// Admin Get Threads Images  Data
export const UPDATE_THREAD_IMAGES_TABLE_SUCCESS =
  "UPDATE_THREAD_IMAGES_TABLE_SUCCESS";
export const UPDATE_THREAD_IMAGES_TABLE_FAILURE =
  "UPDATE_THREAD_IMAGES_TABLE_FAILURE";

// Admin Vistor Badge Data
export const FETCH_ADMIN_VISTOR_BADGE = "FETCH_ADMIN_VISTOR_BADGE";
export const FETCH_ADMIN_VISTOR_BADGE_FAILURE =
  "FETCH_ADMIN_VISTOR_BADGE_FAILURE";

// Post Vistor badge
export const ADD_VISTOR_BADGE_SUCCESS = "ADD_VISTOR_BADGE_SUCCESS";
export const ADD_VISTOR_BADGE_FAILURE = "ADD_VISTOR_BADGE_FAILURE";

// Admin Video Aceess Overlay Data
export const FETCH_VIDEO_ACCESS_OVERLAY_SUCCESS =
  "FETCH_VIDEO_ACCESS_OVERLAY_SUCCESS";
export const FETCH_VIDEO_ACCESS_OVERLAY_FAILURE =
  "FETCH_VIDEO_ACCESS_OVERLAY_FAILURE";

// Fetch Admin Widget Banner Slider
export const FETCH_ADMIN_BANNER_SLIDER = "FETCH_ADMIN_BANNER_SLIDER";
export const FETCH_ADMIN_BANNER_SLIDER_FAILURE =
  "FETCH_ADMIN_BANNER_SLIDER_FAILURE";

export const ADD_WIDGET_BANNER_SUCCESS = "ADD_WIDGET_BANNER_SUCCESS";

// Featured Creator
export const GET_HOME_FEATURED_CREATORS_REQUEST =
  "GET_HOME_FEATURED_CREATORS_REQUEST";
export const GET_HOME_FEATURED_CREATORS_SUCCESS =
  "GET_HOME_FEATURED_CREATORS_SUCCESS";
export const GET_HOME_FEATURED_CREATORS_FAIL =
  "GET_HOME_FEATURED_CREATORS_FAIL";
