import {
  FETCH_SERIES_DATA_SUCCESS,
  FETCH_SERIES_DATA_FAILURE,
  FETCH_SERIES_VIDEO_DATA_SUCCESS,
  FETCH_SERIES_VIDEO_DATA_FAILURE,
} from "../actions/types";
const initialState = {
  seriesData: null,
  error: null,
};

const seriesTableData = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SERIES_DATA_SUCCESS:
      return {
        ...state,
        seriesData: action.payload,
        error: null,
      };
    case FETCH_SERIES_DATA_FAILURE:
      return {
        ...state,
        seriesData: null,
        error: action.error,
      };
      case FETCH_SERIES_VIDEO_DATA_SUCCESS:
      return {
        ...state,
        seriesVideoData: action.payload,
        error: null,
      };
    case FETCH_SERIES_VIDEO_DATA_FAILURE:
      return {
        ...state,
        seriesVideoData: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default seriesTableData;
