import React from "react";
import styled from "styled-components";
import SkeletonElement from "../SkeletonElement";
import { useMediaQuery } from "react-responsive";

const CategoryFilter = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const isTablet = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <CategoryFilterStyled>
      {Array.from({
        length: isMobile ? 4 : isTablet ? 6 : 12,
      }).map((item, index) => (
        <SkeletonElement key={index} type="category" />
      ))}
    </CategoryFilterStyled>
  );
};

export default CategoryFilter;

const CategoryFilterStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 96%;
  margin: 1rem auto;
  position: relative;
  overflow: hidden;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }
`;
