import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AdminSidebar from "../../AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
} from "@material-ui/core";
import { IoTrashSharp } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import Pagination from "../../Pagination";
import ConfirmationModel from "../../../components/ConfirmationModel";
import {
  adminVideoCategoryRequestData,
  deleteAdminVideoCategoryRequestData,
} from "../../../actions";
import VideoSubCategoryRequestEdit from "./VideoCategoryRequestEdit";

const VideoCategoryRequest = ({ close, moment, midDrag }) => {
  const videoCategoryRequestData = useSelector(
    (state) => state.videoTableData.videoCategoryRequestData
  );
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [formData, setFormData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    dispatch(adminVideoCategoryRequestData());
  }, [dispatch]);

  //   serching------------------
  const filterData = () => {
    return videoCategoryRequestData
      ? videoCategoryRequestData.filter((row) => {
          const lowerSearch = search.toLowerCase();
          return (
            (row.name && row.name.toLowerCase().includes(lowerSearch)) ||
            (row.iconPath &&
              row.iconPath.toLowerCase().includes(lowerSearch)) ||
            (row.id && row.id.toString().toLowerCase().includes(lowerSearch))
          );
        })
      : [];
  };
  // Pagination -------------
  const filteredData = filterData();
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // //   Edit ------------------------
  const handleEdit = (row) => {
    setIsEditing(true);
    setFormData({
      id: row.id,
      categoryTitle: row.categoryTitle,
      categoryDescription: row.categoryDescription,
      requestedUserEmail: row.requestedUserEmail,
      createdAt: row.createdAt,
    });
  };
  const handleFormChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  // //   Delete-------------------
  const handleDelete = (row) => {
    setShowDeleteConfirmation(true);
    setFormData({ id: row.id });
  };
  const deleteVideoHandler = async () => {
    try {
      await dispatch(deleteAdminVideoCategoryRequestData(formData.id));
      setShowDeleteConfirmation(false);
      await dispatch(adminVideoCategoryRequestData());
    } catch (error) {
      console.error("Error deleting Video Categories:", error);
    }
  };

  return (
    <>
      <VideoCategoryRequestDashboard>
        <AdminSidebar />
        <TableContainer component={Paper} className="tableContainer">
          <div>
            <h1 className="headingText">
              Search Type Of Video Category Request
            </h1>
            <input
              type="text"
              placeholder="Search The Video Category Request"
              className="searchBox"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell className="tableHeader">Id</TableCell>
                <TableCell className="tableHeader">Category Title</TableCell>
                <TableCell className="tableHeader">
                  Category Description
                </TableCell>
                <TableCell className="tableHeader">Requested User</TableCell>
                <TableCell className="tableHeader">
                  Requested UserEmail
                </TableCell>
                <TableCell className="tableHeader">Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.slice(startIndex, endIndex).map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell className="tableCell">{row.id}</TableCell>
                  <TableCell className="tableCell">
                    {row.categoryTitle}
                  </TableCell>
                  <TableCell className="tableCell">
                    {row.categoryDescription}
                  </TableCell>
                  <TableCell className="tableCell">
                    {row.requestedUserEmail}
                  </TableCell>

                  <TableCell className="tableCell">
                    <FiEdit
                      className="editButton"
                      size={28}
                      onClick={() => handleEdit(row)}
                    />
                    <IoTrashSharp
                      className="deleteButton"
                      size={28}
                      onClick={() => handleDelete(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div style={{ marginTop: "7px", marginBottom: "18%" }}>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={
                videoCategoryRequestData ? videoCategoryRequestData.length : 0
              }
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </TableContainer>
      </VideoCategoryRequestDashboard>
      {isEditing && (
        <VideoSubCategoryRequestEdit
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          formData={formData}
          onChange={handleFormChange}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationModel
          closeHandler={() => setShowDeleteConfirmation(false)}
          title="Are you sure you want to delete this Video Category Request ? You can't undo this action."
          deleteHandler={deleteVideoHandler}
        />
      )}
    </>
  );
};

export default VideoCategoryRequest;

const VideoCategoryRequestDashboard = styled.div`
  display: flex;
  .addData {
    margin-bottom: 10px;
  }
  .tableContainer {
    max-width: 100%;
    height: 100vh;
    background: #212123;
    padding-left: 2%;
    padding-right: 2%;
  }
  .table {
    background: #212123;
  }
  .tableHeader {
    background: #212123;
    color: white;
    border: 1px solid;
    font-size: 13px;
  }
  .tableCell {
    color: white;
    border: 1px solid;
    font-size: 13px;
  }
  .tableCellWordWrap {
    color: white;
    border: 1px solid;
    font-size: 13px;
    word-break: break-all;
  }
  .editButton {
    color: #4f92f7;
    marginleft: 10px;
    transition: transform 0.2s;
  }
  .editButton:hover {
    transform: scale(1.3);
  }

  .deleteButton {
    color: #f54e4e;
    marginleft: 10px;
    transition: transform 0.2s;
  }
  .deleteButton:hover {
    transform: scale(1.3);
  }

  .headingText {
    font-size: 18px;
    margin-top: 10px;
    color: white;
  }
  .searchBox {
    background: #2c2c2e !important;
    color: white;
    margin-bottom: 2%;
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: #cdc5c5;
    }
  }

  /* Responsive styling for the search box */
  @media (max-width: 768px) {
    .SearchBox {
      width: 100%;
    }
  }
`;
