import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import Button from "../Elements/Button";
import MenuOptions from "./MenuOptions";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { isBadgeOrAvatar } from "../../utils";
import { COMMENT_OTHER, COMMENT_SELF } from "../../utils/menuOptions";
import { useParams } from "react-router-dom";
import { replyUpdateComment } from "../../actions/thread";
import { useDispatch } from "react-redux";
import { GlobalContext } from "../../context/GlobalContext";

const CommentCard = ({
  addComment,
  addCommentHandler,
  addLoading,
  item,
  replyCommentHandler,
  updateCommentHandler,
  setSelectedComment,
  selectedComment,
}) => {
  const commentInputRef = useRef();
  const replyInputRef = useRef();
  const dispatch = useDispatch();
  const params = useParams();
  const user = useSelector((state) => state.user);
  const [showReplies, setShowReplies] = useState(false);
  const [reply, setReply] = useState(false);
  const [text, setText] = useState();
  const [replyText, setReplyText] = useState("");
  const [selectedReply, setSelectedReply] = useState(null);

  const { setShowAuthenticationModel } = useContext(GlobalContext);

  useEffect(() => {
    if (selectedComment) {
      setText(selectedComment?.text);
    }

    if (selectedReply) {
      setReply(true);
      setReplyText(selectedReply?.text);
    }
  }, [selectedComment, selectedReply]);

  useEffect(() => {
    if (reply) {
      setShowReplies(true);
    }
  }, [reply]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        if (document.activeElement === commentInputRef?.current) {
          if (!text) {
            return toast.error("Comment text is required");
          }

          const data = {
            text,
          };

          if (selectedComment?.id) {
            updateCommentHandler(selectedComment?.id, data);
            setSelectedComment(null);
          } else {
            addCommentHandler(data);
          }

          setText("");
        }

        if (event.key === "Enter") {
          event.preventDefault();

          if (document.activeElement === replyInputRef?.current) {
            if (!replyText) {
              return toast.error("Reply text is required");
            }

            const data = {
              text: replyText,
              parentId: item?.id,
            };

            if (selectedReply) {
              dispatch(
                replyUpdateComment(params?.threadId, selectedReply?.id, data)
              );
              setSelectedReply(null);
            } else {
              replyCommentHandler(data);
            }

            setReplyText("");
          }
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [replyText, text]);

  const showRepliesHandler = () => {
    setShowReplies(!showReplies);
  };

  const replyHandler = () => {
    setReply(!reply);
  };

  const redirectToLogin = () => {
    toast.error("Please login or create an account to access this feature.");
    setShowAuthenticationModel(true);
  };

  const loggedInUserImage = user?.avatar
    ? user?.avatar
    : user?.badge
    ? user?.badge
    : null;
  const commenterImage = item?.User?.avatar
    ? item?.User?.avatar
    : item?.User?.badge
    ? item?.User?.badge
    : null;

  if (addComment) {
    if (!user?.id) {
      return (
        <CommentCardStyled>
          <Button
            onClick={redirectToLogin}
            className="loginRequired"
            mode="primary"
            width="fluid"
            variant="round"
            size="medium"
          >
            Please login to comment on this thread
          </Button>
        </CommentCardStyled>
      );
    }

    return (
      <CommentCardStyled>
        <div className="addComment">
          <div className="addComment__left">
            <div onClick={() => setText("what is this")} className="avatar">
              {isBadgeOrAvatar(loggedInUserImage)}
            </div>
          </div>

          <div className="addComment__right">
            <input
              ref={commentInputRef}
              className="addComment__right--text"
              placeholder="what's your thoughs ?"
              name="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <Button
              onClick={() => {
                if (!text) {
                  return toast.error("Comment text is required");
                }

                const data = {
                  text,
                };

                if (selectedComment?.id) {
                  updateCommentHandler(selectedComment?.id, data);
                  setSelectedComment(null);
                } else {
                  addCommentHandler(data);
                }

                setText("");
              }}
              mode="primary"
              size="medium"
              className="addComment__right--replyBtn"
            >
              {addLoading ? (
                "Loading..."
              ) : (
                <>{selectedComment?.id ? "Save" : "Comment"}</>
              )}
            </Button>
          </div>
        </div>
      </CommentCardStyled>
    );
  }

  return (
    <CommentCardStyled>
      <div className="avatar">{isBadgeOrAvatar(commenterImage)}</div>

      <div className="comment">
        <div className="comment__box">
          <div className="username">{item?.User?.username}</div>

          <div className="text">{item?.text}</div>
        </div>
        <div className="comment__actions">
          {item?.replies?.length > 0 && (
            <>
              <div
                onClick={showRepliesHandler}
                className={`comment__actions--item ${
                  showReplies ? "comment__actions--main" : null
                }`}
              >
                View all replies
              </div>
              <div className="comment__actions--dot"></div>
            </>
          )}
          <div
            onClick={replyHandler}
            className={`comment__actions--item ${
              reply ? "comment__actions--main" : null
            }`}
          >
            Reply
          </div>
          <div className="comment__actions--dot"></div>
          <div className={`comment__actions--item `}>Like</div>
        </div>

        <div className="comment__menu">
          {!user?.id ? (
            <MenuOptions
              hideBorder
              item={item}
              options={COMMENT_OTHER}
              threadId={params?.threadId}
            />
          ) : (
            <MenuOptions
              hideBorder
              item={item}
              options={
                item?.User?.id === user?.id ? COMMENT_SELF : COMMENT_OTHER
              }
              threadId={params?.threadId}
              setSelectedComment={setSelectedComment}
            />
          )}
        </div>

        {reply && (
          <div className="comment__reply">
            <div className="comment__reply--left">
              <div className="replyAvatar">
                {isBadgeOrAvatar(loggedInUserImage)}
              </div>
            </div>

            <div className="comment__reply--right">
              <input
                ref={replyInputRef}
                className="comment__reply--right-text"
                placeholder="what's your reply ?"
                name="replyText"
                value={replyText}
                onChange={(e) => setReplyText(e.target.value)}
              />
              <Button
                onClick={() => {
                  if (!replyText) {
                    return toast.error("Reply text is required");
                  }

                  const data = {
                    text: replyText,
                    parentId: item?.id,
                  };

                  if (selectedReply) {
                    dispatch(
                      replyUpdateComment(
                        params?.threadId,
                        selectedReply?.id,
                        data
                      )
                    );
                    setSelectedReply(null);
                  } else {
                    replyCommentHandler(data);
                  }

                  setReplyText("");
                }}
                mode="primary"
                size="small"
                className="comment__reply--right-replyBtn"
              >
                {selectedReply ? "Save" : "Reply"}
              </Button>
            </div>
          </div>
        )}

        {showReplies && item?.replies && item?.replies?.length > 0 && (
          <div className="comment__replies">
            {item.replies.map((item, i) => {
              const commenterImage = item?.User?.avatar
                ? item?.User?.avatar
                : item?.User?.badge
                ? item?.User?.badge
                : null;

              return (
                <div key={i} className="comment__replies--item">
                  <div className="replyAvatar">
                    {isBadgeOrAvatar(commenterImage)}
                  </div>

                  <div className="replyComment">
                    <div className="replyComment__box">
                      <div className="username">{item?.User?.username}</div>

                      <div className="text">{item?.text}</div>
                    </div>
                  </div>

                  <div className="comment__replies--item-menu">
                    {!user?.id ? (
                      <MenuOptions
                        hideBorder
                        item={item}
                        options={COMMENT_OTHER}
                        threadId={params?.threadId}
                      />
                    ) : (
                      <MenuOptions
                        hideBorder
                        item={item}
                        options={
                          item?.User?.id === user?.id
                            ? COMMENT_SELF
                            : COMMENT_OTHER
                        }
                        threadId={params?.threadId}
                        replyComment
                        setSelectedReply={setSelectedReply}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </CommentCardStyled>
  );
};

export default CommentCard;

const CommentCardStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  .loginRequired {
    transition: all 0.2s ease;
    font-weight: 500;
    margin-bottom: 2rem;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .avatar {
    cursor: pointer;
    height: 2.2rem;
    width: 2.2rem;
    border-radius: 50%;
    position: relative;
    margin-right: 0.5rem;
    transition: all 0.2s ease;
    outline: 1px solid transparent;

    &:hover {
      outline: 1px solid rgba(255, 255, 255, 0.5);
    }

    .imageAvatar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
      border-radius: 50%;
    }

    .imageBadge {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .badge {
        height: 38px;
        width: auto;
      }
    }
  }

  .username {
    font-weight: 600;
    font-size: 0.8rem;
    background: -webkit-${(props) => props.theme.primaryGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    margin-bottom: 0.1rem;
    display: inline-block;
    cursor: pointer;
    line-height: 1;
    padding-bottom: 3px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      background: ${(props) => props.theme.primaryGradient};
      width: 0%;
      transition: all 0.4s ease;
      border-radius: 5px;
    }

    &:hover:before {
      width: 100%;
    }
  }

  .replyAvatar {
    cursor: pointer;
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 50%;
    position: relative;
    margin-right: 0.5rem;
    transition: all 0.2s ease;
    outline: 1px solid transparent;

    &:hover {
      outline: 1px solid rgba(255, 255, 255, 0.5);
    }

    .imageAvatar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
      border-radius: 50%;
    }

    .imageBadge {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .badge {
        height: 38px;
        width: auto;
      }
    }
  }

  .comment {
    width: calc(100% - 3rem);
    position: relative;

    &__menu {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }

    &__box {
      background-color: #202020;
      padding: 0.2rem 0.5rem 0.5rem 0.5rem !important;
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.03);

      .text {
        font-weight: 400;
        font-size: 0.82rem;
      }
    }

    &__actions {
      font-size: 0.6rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 0.15rem;

      &--item {
        color: rgba(255, 255, 255, 0.7);
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
        user-select: none;
      }

      &--main {
        color: #fff;
      }

      &--dot {
        height: 4px;
        width: 4px;
        border-radius: 50%;
        margin: 0 0.6rem;
        background-color: #fff;
      }
    }

    &__reply {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 1rem;

      &--right {
        width: calc(100% - 2.5rem);
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &-text {
          width: 100%;
          margin-bottom: 0.5rem;
          background-color: transparent;
          border: none;
          outline: none;
          color: #fff;
          padding-bottom: 0.6rem;
          padding-top: 0.3rem;
          font-family: ${(props) => props.theme.montserrat}, sans-serif;
          position: relative;
          font-size: 0.85rem;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);

          &::placeholder {
            color: rgba(255, 255, 255, 0.7);
            font-weight: 400;
          }
        }

        &-replyBtn {
          padding: 0.3rem 0.5rem;
          font-size: 0.75rem;
          letter-spacing: 0.5px;
        }
      }
    }

    &__replies {
      margin-top: 1rem;

      &--item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        &:last-child {
          margin-bottom: 1.6rem;
        }

        &-menu {
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }
      }

      .replyComment {
        width: calc(100% - 2.5rem);
        position: relative;

        &__box {
          background-color: #202020;
          padding: 0.2rem 0.5rem 0.5rem 0.5rem !important;
          border-radius: 4px;
          border: 1px solid rgba(255, 255, 255, 0.03);

          .text {
            font-weight: 400;
            font-size: 0.82rem;
          }
        }
      }
    }
  }

  .addComment {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;

    &__right {
      width: calc(100% - 3rem);
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &--text {
        width: 100%;
        margin-bottom: 0.5rem;
        background-color: transparent;
        border: none;
        outline: none;
        color: #fff;
        padding-bottom: 0.6rem;
        padding-top: 0.3rem;
        font-family: ${(props) => props.theme.montserrat}, sans-serif;
        position: relative;
        font-size: 0.85rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);

        &::placeholder {
          color: rgba(255, 255, 255, 0.7);
          font-weight: 400;
        }
      }

      &--replyBtn {
        cursor: pointer;
      }
    }
  }
`;
