import React, { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import BackIcon from "../../../assets/Icons/back.svg";
import UploadIcon from "../../../assets/Icons/upload-icon-black.svg";
import { motion } from "framer-motion";
import { upload, uploadCover } from "../../../utils";
import TickIcon from "../../../assets/Icons/tick.svg";
import CloseIcon from "../../../assets/Icons/close.svg";
import { removeCustomBanner } from "../../../actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { notificationMessage } from "../../../utils/toastNotifications";
import config from "../../../config";

const BadgeContainerVariants = {
  hidden: {
    opacity: 0,
    y: "-5rem",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 120,
      duration: 0.5,
    },
  },
};

const UploadCustomBanner = ({
  close,
  selectedBanners,
  saveFeaturedContentHandler,
  customBanners,
  onBannerUpload,
  selectBannerHandler,
}) => {
  const dispatch = useDispatch();
  const handleBannerUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const { publicId, url } = await uploadCover("image", file);
      onBannerUpload([url]);
    }
  };

  return (
    <>
      <UploadCustomBannerStyled className="ShowChooseProfileImageModel">
        <div className="wrapper__header">
          <div className="backIcon" onClick={close}>
            <img src={BackIcon} alt="" />
          </div>
          <div className="name">Choose your banner</div>
          <div className="button-save">
            <div
              onClick={() => {
                close();
                saveFeaturedContentHandler();
              }}
              // className="button"
            >
              save
            </div>
          </div>
        </div>

        <motion.div
          variants={BadgeContainerVariants}
          initial="hidden"
          animate="visible"
          className="badges"
        >
          <label htmlFor="banner-upload" className="customBanner">
            <div className="text">
              <div className="light">Upload</div>
              <div className="bold">Custom Banner</div>
            </div>

            <img className="icon" src={UploadIcon} alt="" />
          </label>

          <input
            id="banner-upload"
            type="file"
            accept="image/*"
            onChange={handleBannerUpload}
            style={{ display: "none" }}
          />
          <div className="customBannerWrapper">
            {customBanners?.length > 0 &&
              customBanners.map((banner, i) => {
                return (
                  <div key={i} style={{ position: "relative", width: "100%" }}>
                    <img
                      src={config.aws.CLOUDFRONT_URL + banner.customBannerUrl}
                      alt="custom-banner"
                      className="customBannerWrapper__custom-banner"
                    />
                    <div
                      onClick={() => selectBannerHandler({ id: banner.id })}
                      className={`selectBanner ${
                        selectedBanners.includes(banner.id) &&
                        "selectBanner__selected"
                      }`}
                    >
                      {selectedBanners.includes(banner.id) && (
                        <img src={TickIcon} alt="Tick Icon" />
                      )}
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(
                          removeCustomBanner(banner.id, banner.customBannerUrl)
                        );
                        toast.success(notificationMessage.bannerRemoved);
                      }}
                      className="close"
                    >
                      <img src={CloseIcon} alt="" />
                    </div>
                  </div>
                );
              })}
          </div>
        </motion.div>
      </UploadCustomBannerStyled>
    </>
  );
};

export default UploadCustomBanner;

const TickIconAnimation = keyframes`
  from {
    transform: translateY(7px) scale(0.7);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const UploadCustomBannerStyled = styled.div`
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #202020;
  border-radius: 0.3rem;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.4), 0px 0px 4px rgba(0, 0, 0, 0.25);
  transform: translateY(-100%);
  transition: all 1s ease;
  font-family: ${(props) => props.theme.montserrat};
  .wrapper {
    min-height: 100vh;
    height: 100vh;
    width: 100%;
    background-color: #1c1c1e;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    z-index: 150;
    opacity: 0;
    /* transform: translateX(-100%); */
    visibility: hidden;
    transition: all 1s ease;

    &__header {
      display: flex;
      align-items: center;
      padding: 1rem 5rem;
      border-bottom: 1px solid rgba(112, 112, 112, 0.4);

      .backIcon {
        margin-right: 1rem;
        cursor: pointer;

        img {
          height: 1rem;
          width: auto;
        }
      }

      .button-save {
        border: 2px solid #fff;
        padding: 0.1rem 1.5rem;
        border-radius: 10rem;
        font-size: 0.9rem;
        background-color: transparent;
        color: #fff;
        font-family: ${(props) => props.theme.montserrat};
        font-weight: 400;
        text-transform: capitalize;
        transition: all 0.2s ease;
        cursor: pointer;
        margin-left: auto;

        &:hover {
          background-color: #fff;
          color: #000;
        }
      }
      .name {
        text-align: center;
        padding: 0.2rem 0.4rem;
        transition: all 0.5s ease;
      }
    }
    &__content {
      height: 100vh;
    }
  }

  .close {
    position: absolute;
    top: 1rem;
    right: 3.6rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 4px solid #f9903d;
    background-color: #f9903d;
    cursor: pointer;
    transition: all 0.25s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    img {
      height: 0.8rem;
      width: auto;
    }
  }
  .customBannerWrapper {
    height: 12rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    &__custom-banner {
      height: auto;
      width: 100%;
    }
    &__selectVideo {
      position: absolute;
      top: 1rem;
      right: 1rem;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      border: 4px solid #f9903d;
      background-color: #000;
      cursor: pointer;
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  .loading,
  .error {
    font-family: brother-1816, sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .badges {
    margin: 2rem auto;
    width: 50%;
    min-width: 480px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    .customBanner {
      width: 100%;
      margin-bottom: 2rem;
      background-color: #fff;
      padding: 0.8rem 1rem;
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #2c2c2e;
      font-size: 0.95rem;
      cursor: pointer;

      .text {
        display: flex;
        align-items: center;
        text-transform: capitalize;

        .light {
          font-weight: 400;
          padding-right: 0.5rem;
        }

        .bold {
          font-weight: 600;
        }
      }

      .icon {
        height: 1.5rem;
        width: auto;
      }
    }

    &__item {
      width: 8rem;
      flex-basis: 20%;
      -ms-flex: auto;
      position: relative;
      padding: 10px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 2rem;

      .icon {
        width: 4.5rem;
        height: auto;
        margin-bottom: 0.5rem;
      }

      .name {
        text-align: center;
        line-height: 1;
        font-family: brother-1816, sans-serif;
        font-weight: 300;
        text-transform: uppercase;
        font-size: 0.7rem;
        padding: 0.2rem 0.4rem;
        transition: all 0.5s ease;
      }
    }

    .selected .name {
      background: ${(props) => props.theme.primaryGradient};
      border-radius: 3px;
      color: #fff;
      font-weight: 600;
    }
    .selectBanner {
      position: absolute;
      top: 1rem;
      right: 1rem;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      border: 4px solid #f9903d;
      background-color: #000;
      cursor: pointer;
      transition: all 0.25s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      &__selected {
        background-color: #f9903d !important;
      }

      img {
        width: 1.6rem;
        height: auto;
        animation: ${TickIconAnimation} 0.2s ease;
        transition: all 0.2s ease;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .header {
      padding: 1rem 2.5rem;

      .name {
        font-size: 1.1rem;
      }
    }

    .badges {
      width: 80%;

      &__item {
        flex-basis: 33.33%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .header {
      padding: 1rem 1.5rem;

      .name {
        font-size: 0.9rem;
      }

      .button {
        font-size: 0.85rem;
      }
    }

    .badges {
      width: 90%;
      min-width: 90%;

      &__item {
        .icon {
          width: 4rem;
        }
      }
    }
  }
`;
