import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Input from "../../components/Elements/Input";
import Button from "../../components/Elements/Button";
import { Row, Col } from "react-grid-system";
import { addVistorBadge } from "../../actions";
import { uploadVistorBadge } from "../../utils";
import { toast } from "react-toastify";

const AddVistorBadge = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState("");
  const [visBdageData, setVisBdageData] = useState({
    id: "",
    name: "",
    visitorBadgeType: "",
    imgPath: "",
  });
  const handleIconChange = async (e) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "jpeg"];

    if (!allowedFileTypes.includes(fileExtension)) {
      toast.error(
        `Image extension does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
      return;
    }
    toast.info("Uploading...");
    setSelectedImage(file.name);

    if (file) {
      try {
        const { url } = await uploadVistorBadge("image", file);
        setVisBdageData({
          ...visBdageData,
          imgPath: file.name,
        });

        toast.success("Image uploaded successfully");
      } catch (error) {
        toast.error("Image upload failed. Please try again.");
      }
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVisBdageData({ ...visBdageData, [name]: value });
  };
  const handleSubmit = () => {
    dispatch(addVistorBadge(visBdageData));
    onClose();
  };

  return (
    <AddVistorBadgeModal open={isOpen}>
      <AddVistorBadgeEditContent>
        <div className="title">Add Vistor Badge </div>
        <div className="field">
          <Row className="row row__one">
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Id"
                  name="id"
                  id="id"
                  placeholder="Id"
                  type="number"
                  value={visBdageData.id}
                  visBdageData
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Name"
                  name="name"
                  id="name"
                  placeholder="name"
                  type="text"
                  value={visBdageData.name}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="VisitorBadgeType"
                  name="visitorBadgeType"
                  id="visitorBadgeType"
                  placeholder="visitorBadgeType"
                  type="number"
                  value={visBdageData.visitorBadgeType}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="ImgPath"
                  name="imgPath"
                  id="imgPath"
                  placeholder="/assets/icons/image.jpg&.svg"
                  type="file"
                  accept="image/*"
                  onChange={handleIconChange}
                />
              </div>
              {selectedImage && <p> {selectedImage}</p>}
            </Col>
          </Row>
        </div>
        <Row className="row row__one">
          <Col md={6} sm={6} xs={6}>
            <Button mode={"secondary"} className="submit" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <Button className="submit" onClick={handleSubmit}>
              Save
            </Button>
          </Col>
        </Row>
      </AddVistorBadgeEditContent>
    </AddVistorBadgeModal>
  );
};

export default AddVistorBadge;

const AddVistorBadgeModal = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddVistorBadgeEditContent = styled.div`
  background: #2c2c2e;
  color: white;
  border: 1px solid white;
  padding: 20px;
  max-width: 800px;
  width: 100%;

  .title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .submit {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdownBox {
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    margin-top: 3px;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;
