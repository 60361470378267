import React, { useEffect } from "react";
import { connect } from "react-redux";
import { StyledTrending } from "./Trending";
import TrendingCard from "../components/TrendingCard";
import { getHistory } from "../actions";
import Skeleton from "../skeletons/History";
import ContactBanner from "../components/Footer/ContactBanner";
import Footer from "../components/Footer/Footer";
import { ScrollToTop } from "../utils/index";
import VideoCard from "../components/Cards/VideoCard";
import { Col, Row } from "react-grid-system";
import TrashIcon from "../components/icons/trash.svg";

const VIDEOMENUOPTIONS = [
  { icon: TrashIcon, imgIcon: true, text: "Remove from History" },
  { icon: <i class="fas fa-share"></i>, text: "Share Video" },
  { icon: <i class="fas fa-user-alt"></i>, text: "Go to Creator Profile" },
];

const History = ({ nopad, isFetching, videos, getHistory }) => {
  useEffect(() => {
    getHistory();
  }, [videos.length, getHistory]);

  if (isFetching) {
    return <Skeleton />;
  }

  return (
    <>
      <ScrollToTop />
      <StyledTrending nopad={nopad}>
        <h2>History</h2>

        {!isFetching && !videos.length && (
          <p className="secondary">
            Videos that you have watched will show up here
          </p>
        )}

        <Row>
          {videos.map((video, i) => {
            return (
              <Col
                key={i}
                lg={3}
                md={6}
                sm={12}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <VideoCard
                  key={video.id}
                  item={video}
                  menuOptions={VIDEOMENUOPTIONS}
                />
              </Col>
            );
          })}
        </Row>
      </StyledTrending>

      <ContactBanner />
      <Footer />
    </>
  );
};

const mapStateToProps = ({ history }) => ({
  isFetching: history.isFetching,
  videos: history.videos,
});

export default connect(mapStateToProps, { getHistory })(History);
