import React, { useContext, useState } from "react";
import styled from "styled-components";
import SeriesCard from "../../Cards/SeriesCard";
import { SERIES_SELF, SERIES_OTHER } from "../../../utils/menuOptions";
import { GlobalContext } from "../../../context/GlobalContext";
import ConfirmationModel from "../../ConfirmationModel";
import { deleteSeriesById } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { notificationMessage } from "../../../utils/toastNotifications";
import EditSeriesModel from "../Models/EditSeriesModel";
import { Row, Col } from "react-grid-system";

const SeriesCategory = ({ isSelf, allSeries }) => {
  const dispatch = useDispatch();
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [seriesId, setSeriesId] = useState(null);

  const { deleteSeriesConfirmationModel, setDeleteSeriesConfirmationModel } =
    useContext(GlobalContext);

  const deleteSeriesHandler = () => {
    dispatch(deleteSeriesById(seriesId));
    toast.error(notificationMessage.deleteSeries);
    setDeleteSeriesConfirmationModel(false);
  };

  return (
    <>
      <SeriesCategoryStyled>
        {isSelf ? (
          <Row>
            {allSeries.map((item, i) => (
              <Col
                key={i}
                lg={3}
                md={6}
                sm={12}
                xs={12}
                className="container__videos--col"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <SeriesCard
                  key={i}
                  item={item}
                  isSelf={isSelf}
                  menuOptions={isSelf ? SERIES_SELF : SERIES_OTHER}
                  setSelectedSeries={setSelectedSeries}
                  setSeriesId={setSeriesId}
                  makePublicPrivateUI={true}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <Row>
            {allSeries
              .filter((item) => item.published === true)
              .map((item, i) => (
                <Col
                  key={i}
                  lg={3}
                  md={6}
                  sm={12}
                  xs={12}
                  className="container__videos--col"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <SeriesCard
                    key={i}
                    item={item}
                    isSelf={isSelf}
                    menuOptions={isSelf ? SERIES_SELF : SERIES_OTHER}
                    setSelectedSeries={setSelectedSeries}
                    setSeriesId={setSeriesId}
                    makePublicPrivateUI={true}
                  />
                </Col>
              ))}
          </Row>
        )}
      </SeriesCategoryStyled>

      {selectedSeries && deleteSeriesConfirmationModel && (
        <ConfirmationModel
          closeHandler={() => setDeleteSeriesConfirmationModel(false)}
          title="Are you sure you want to delete this series ? You can't undo this action."
          deleteHandler={deleteSeriesHandler}
        />
      )}

      {selectedSeries && seriesId && (
        <EditSeriesModel selectedSeries={selectedSeries} seriesId={seriesId} />
      )}
    </>
  );
};

export default SeriesCategory;

const SeriesCategoryStyled = styled.div`
  .slider {
    .slick-slide {
      padding: 0 10px;
    }

    .slick-track {
      margin-left: 0;
    }
  }
`;
