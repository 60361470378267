import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import avatar_placeholder from "../assets/avtar_placeholder.png";
import OptionMenu from "../pages/OptionMenu";
import MenuOptions from "./Cards/MenuOptions";
import { VIDEO_OTHER } from "../utils/menuOptions";
import config from '../config'


const FeaturedVideoCard = ({ featuredVideo, parent }) => {
  const badge = featuredVideo.userSettings?.VisitorBadge.imgPath;
  const isPngOrJpg = () => {
    const image = featuredVideo.User.avatar === null ? badge : featuredVideo.User.avatar;

    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <img
          className={`${image.includes(".png") ? "png-img" : "avatar-image"}`}
          src={config.aws.CLOUDFRONT_URL + image}
          alt="badge"
        />
      );
    } else {
      return (
        <img
          className="custom-image"
          src={!!image ? config.aws.CLOUDFRONT_URL + image : avatar_placeholder}
          alt="badge"
        />
      );
    }
  };
  return (
    <>
      <FeaturedVideoCardStyled shrink={parent === 'WatchVideo'}>
        <Link to={`/watch/${featuredVideo.id}`}>
          <div>
            <img
              src={config.aws.CLOUDFRONT_URL + featuredVideo.thumbnail}
              alt=""
              className="thumbnail"
            />
          </div>
        </Link>
      </FeaturedVideoCardStyled>
      <LiveClassesDescStyled>
        <div className="option">
          <MenuOptions
            item={featuredVideo}
            options={VIDEO_OTHER}
          />
        </div>
        <Link to={`/channel/${featuredVideo.User.username}`}>
          <div className='video-avatar'>
            {isPngOrJpg()}
          </div>
        </Link>
        <h4 className="video-title">
          {featuredVideo.title}
        </h4>
        <Link to={`/channel/${featuredVideo.User.username}`}>
          <p className="user">{featuredVideo.User.username}</p>
        </Link>
        <p className="video-category">{featuredVideo.category}</p>
      </LiveClassesDescStyled>
    </>
  );
};

export default FeaturedVideoCard;

const LiveClassesDescStyled = styled.div`
  position: relative;
  margin-top: -40px;
  font-family: Montserrat;
  margin-left: 17px;
  .option{
    position: absolute;
    top: 50px;
    right: 0;
    cursor: pointer;
  }
  .user {
    background: -webkit-linear-gradient(#ff4883, #fdb769);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.02rem;
    cursor: pointer;
    text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.3);
  }
  .video-avatar {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    background: #181818;
    margin: 0 auto;
    padding: 4px;
    width: fit-content;
    .avatar-image {
      height: 50px;
      width: 50px;
    }
    order: 1;
    .png-img {
      width: 50px;
      height: 50px;
    }

    .custom-image {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
    }
  }
  .video-title, .video-category {
    font-size: 13px;
    text-align: center;
    letter-spacing: 0px;
    color: #f2f2f7;
  }
  .video-category{
    color: #d1d1d6;
  }
`;

const FeaturedVideoCardStyled = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
  position: relative;
  margin: 0 auto;

  .live-badge {
    font-family: Montserrat;
    position: absolute;
    top: 6%;
    padding: 3px 15px 3px 15px;
    z-index: 98;
    border-radius: 10px;
    left: 5%;
    background: linear-gradient(
      29deg,
      rgb(249, 154, 45),
      rgb(246, 92, 139) 71%
    ) !important;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.3);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 0% 50%);
    transition: all 0.3s ease;

    p {
      font-size: 0.7rem;
      text-transform: capitalize;
      font-weight: 300;
      letter-spacing: 0.1px;
      margin: 0;
      padding: 0;
      transition: all 0.2s ease;

      span {
        font-weight: bold;
      }
    }
  }
  .view-badge {
    font-family: Montserrat;
    position: absolute;
    bottom: 20px;
    padding: 3px 15px 3px 15px;
    z-index: 98;
    border-radius: 10px;
    left: 5%;
    background: #181818;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.3);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 0% 50%);
    transition: all 0.3s ease;
  }
  .thumbnail {
    width: 100%;
    height: ${(prop) => prop.shrink ? '196px' : '40vh'};
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    z-index: -1;
    padding: 10px;
    outline: none;
  }
  .video-title {
    text-align: center;
    font-family: Montserrat;
  }
  @media screen and (max-width: 530px) {
    .thumbnail {
      height: 30vh;
    }
    .live-badge {
      font-size: 10px;
    }
    .view-badge {
      font-size: 10px;
    }
    .video-title {
      font-size: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    .live-badge {
      font-size: 10px;
    }
    .view-badge {
      font-size: 10px;
    }
    .video-title {
      font-size: 10px;
    }
  }
`;
