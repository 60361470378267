import React, { useContext, useState, useEffect } from "react";
import { SeriesStyles } from "./SeriesStyles";
import BackIcon from "../../../assets/Icons/back.svg";
import UploadIcon from "../../../assets/Icons/upload-icon-white.svg";
import PlayIcon from "../../../assets/Icons/play-white.svg";
import AddIcon from "../../../assets/Icons/add.svg";
import { GlobalContext } from "../../../context/GlobalContext";
import { useDispatch, useSelector } from "react-redux";
import { editSeriesById, updateVideoV2 } from "../../../actions";
import { upload, uploadCover } from "../../../utils";
import { notificationMessage } from "../../../utils/toastNotifications";
import { toast } from "react-toastify";
import { Row, Col } from "react-grid-system";
import VideoCardSelect from "./VideoCardSelect";
import {
  addVideoToSeries,
  removeVideoFromSeries,
  deleteVideoV2,
  deleteVideoS3,
  getUserVideos,
  getUserStorageInfo,
  getVideoCategories,
} from "../../../actions";
import Loader from "../../Elements/Loader";
import Error from "../../Elements/Error";
import EditClipForm from "../../UploadClipComponent/EditClipForm";
import ConfirmationModel from "../../ConfirmationModel";
import { VIDEO_UPDATE_RESET, VIDEO_DELETE_RESET } from "../../../actions/types";
import VideoCategory from "../../UploadClipComponent/VideoCategory";
import api from "../../../services/api";
import config from "../../../config";

const EditSeriesModel = ({ seriesId, selectedSeries }) => {
  const dispatch = useDispatch();
  const {
    editSeriesModel,
    setEditSeriesModel,
    seriesEditMode,
    setShowUploadClipModel,
    setVideoForSeriesUpload,
    deleteVideoConfirmationModel,
    setDeleteVideoConfirmationModel,
    setShowEditClipModel,
    setNewCategoryRequestModel,
  } = useContext(GlobalContext);
  const {
    message: editMessage,
    error: editError,
    loading: editLoading,
  } = useSelector((state) => state.editSeries);
  const profile = useSelector((state) => state.profile);
  const {
    videos: userVideos,
    loading: userVideosLoading,
    error: userVideosError,
  } = useSelector((state) => state.userVideos);
  const { videoDeleteInfo } = useSelector((state) => state.video);
  const videoCategories = useSelector((state) => state.video.videoCategories);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState(0);
  const [customThumbnail, setCustomThumbnail] = useState({
    url: null,
  });
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [videoLength, setVideoLength] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [defaultCategories, setDefaultCategories] = useState([]);

  useEffect(async () => {
    if (!videoCategories && videoCategories.length) {
      dispatch(getVideoCategories());
    }

    if (selectedSeries && selectedSeries.id) {
      setTitle(selectedSeries?.title);
      setDesc(selectedSeries?.description);
      setPrice(selectedSeries?.price);
      setCustomThumbnail({
        url: selectedSeries?.thumbnail,
      });
      setVideoLength(selectedSeries?.videos?.length || 0);

      await getCurrentVideoCategory(selectedSeries.id);
    }
  }, [selectedSeries]);

  useEffect(() => {
    // fetch user videos after delete and edit operation on video
    dispatch(getUserVideos({ showAll: true }, profile?.id));

    if (videoDeleteInfo) {
      dispatch(getUserVideos({ showAll: true }, profile?.id));

      if (videoDeleteInfo) {
        toast.success(notificationMessage.videoDeleted);

        // update storage information
        dispatch(getUserStorageInfo());

        dispatch({
          type: VIDEO_DELETE_RESET,
        });
      }
    }
  }, [videoDeleteInfo]);

  useEffect(() => {
    if (selectedSeries?.videos?.length > 0 && userVideos?.length > 0) {
      const result = userVideos.filter(function (obj1) {
        return selectedSeries?.videos.some(function (obj2) {
          return obj1.id === obj2.videoId;
        });
      });

      const alreadySelected = result.map((item) => item.id);

      setSelectedVideos(alreadySelected);
    }
  }, [userVideos, editSeriesModel]);

  useEffect(() => {
    if (editError) {
      toast.error(notificationMessage.unexpectedServerError);
    }

    if (editMessage) {
      toast.success(notificationMessage.seriesUpdated);
      setEditSeriesModel(false);
    }
  }, [editError, editMessage]);

  const closeHandler = () => {
    setEditSeriesModel(false);
  };

  const coverUploadHandler = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const { publicId, url } = await uploadCover("image", file);
      setCustomThumbnail({ url, publicId });
    }
  };

  const editSeriesHandler = () => {
    if (!title) {
      return toast.error(notificationMessage.enterSeriesTitle);
    }
    if (!customThumbnail.url) {
      return toast.error(notificationMessage.seriesThumbnail);
    }

    if (!selectedCategory && !selectedCategory.length) {
      return toast.error(notificationMessage.categoryNeededError);
    }

    const data = {
      title,
      price: price > 0 ? price : null,
      thumbnail: customThumbnail.url,
      description: desc,
      categoryList: selectedCategory,
    };

    dispatch(editSeriesById(seriesId, data));
  };

  const selectVideoHandler = (item) => {
    const isAlreadyAdded = selectedVideos?.filter((val) => val === item.id);

    if (isAlreadyAdded.length > 0) {
      const newArr = selectedVideos.filter((el) => el !== item.id);
      setSelectedVideos(newArr);

      const obj = {
        videoId: item.id,
      };

      dispatch(removeVideoFromSeries(seriesId, obj));
      setVideoLength(videoLength - 1);

      // also edit video after removing from the series
      dispatch(
        updateVideoV2(item.id, {
          keyVideoAccess: 1,
        })
      );

      return;
    } else {
      setVideoLength(videoLength + 1);

      const obj = {
        videoId: item.id,
        order: videoLength + 1,
      };
      dispatch(addVideoToSeries(seriesId, obj));

      // also edit video after adding to the series
      dispatch(
        updateVideoV2(item.id, {
          keyVideoAccess: 4,
        })
      );

      setSelectedVideos([...selectedVideos, item.id]);
      return;
    }
  };

  const addVideosToSeriesHandler = () => {
    setEditSeriesModel(false);
    toast.success(notificationMessage.seriesUpdated);
  };

  const deleteVideoHandler = () => {
    const filenamesplit = selectedVideo.url.split("/");
    const videoname = filenamesplit[filenamesplit.length - 1];
    const thumbnailsplit = selectedVideo.thumbnail.split("/");
    const thumbnailname = thumbnailsplit[thumbnailsplit.length - 1];

    const removeObj = {
      videoId: selectedVideo.id,
    };

    dispatch(deleteVideoV2(selectedVideo.id));
    dispatch(deleteVideoS3(videoname));
    dispatch(deleteVideoS3(thumbnailname));
    dispatch(removeVideoFromSeries(seriesId, removeObj));

    setDeleteVideoConfirmationModel(false);
  };

  const getCurrentVideoCategory = async (videoId) => {
    const categoryResponse = await api.get(
      `videos/getCategoryByVideo/${videoId}`
    );
    if (categoryResponse.data.success) {
      setSelectedCategory(categoryResponse.data.videoSubCategories);
      setDefaultCategories(categoryResponse.data.videoSubCategories);
    }
  };

  if (seriesEditMode === "DETAILS") {
    return (
      <SeriesStyles
        className={`${editSeriesModel ? "editSeriesModelActive" : null}`}
      >
        <div className="header">
          <div className="left">
            <div onClick={closeHandler} className="backIcon">
              <img src={BackIcon} alt="" />
            </div>
            <div className="name">Edit Series Information</div>
          </div>

          <div className="right">
            <button onClick={() => editSeriesHandler()} className="formState">
              {editLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>

        <div className="content">
          <form className="content__seriesForm">
            <div className="content__seriesForm--fieldGroup">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                id="title"
                placeholder="Series title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="content__seriesForm--fieldGroup">
              <label htmlFor="desc">Description</label>
              <textarea
                type="text"
                id="desc"
                placeholder="Series description"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </div>

            <div className="content__seriesForm--fieldGroup">
              <label htmlFor="price">Price</label>
              <input
                type="number"
                id="price"
                placeholder="Series price"
                value={parseFloat(price).toFixed(2)} //{price > 0 ? parseInt(price).toFixed(2) : price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>

            <div className="content__seriesForm--fieldGroup">
              <div className="title">Choose category</div>
              <VideoCategory
                videoCategories={videoCategories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                defaultCategories={defaultCategories}
              />

              <button
                onClick={() => setNewCategoryRequestModel(true)}
                className="requestNewCategory"
              >
                Request New Category
              </button>
            </div>

            <div className="content__seriesForm--fieldGroup">
              <div className="title">Cover</div>
              <label
                htmlFor="editSeriesCover"
                className="seriesUploadThumbnail"
              >
                <img src={AddIcon} alt="Add Icon" className="icon" />
              </label>

              <input
                style={{ display: "none" }}
                id="editSeriesCover"
                type="file"
                accept="image/*"
                onChange={coverUploadHandler}
              />

              {!!customThumbnail.url && (
                <div className="uploadedCoverPreview">
                  <img
                    src={config.aws.CLOUDFRONT_URL + customThumbnail.url}
                    alt=""
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </SeriesStyles>
    );
  }

  if (seriesEditMode === "VIDEOS") {
    return (
      <>
        <SeriesStyles
          className={`${editSeriesModel ? "editSeriesModelActive" : null}`}
        >
          <div className="header">
            <div className="left">
              <div onClick={closeHandler} className="backIcon">
                <img src={BackIcon} alt="" />
              </div>
              <div className="name">Manage Videos</div>
            </div>

            <div className="right">
              <button onClick={addVideosToSeriesHandler} className="formState">
                Update
              </button>
            </div>
          </div>

          <div className="content">
            <div className="content__uploadBox">
              <div className="icon">
                <img src={UploadIcon} alt="" />
              </div>
              <div
                onClick={() => {
                  setVideoForSeriesUpload({
                    series: selectedSeries,
                    showSeriesList: false,
                  });
                  setShowUploadClipModel(true);
                }}
                className="box"
              >
                <div className="box__left">
                  <p className="boldText">Upload</p>
                  <p className="lightText">some videos for your series</p>
                </div>

                <div className="box__right">
                  <img src={PlayIcon} alt="" />
                </div>
              </div>
            </div>

            <div className="infoBlock">
              <p className="lightText">or select from</p>
              <p className="boldText">Videos List</p>
            </div>

            {userVideosLoading && (
              <Loader text="Please wait while we fetch all videos for you" />
            )}

            {!userVideosLoading && !userVideos?.length && (
              <Error text="No videos found. Upload some first!" />
            )}

            <div className="content__videos">
              <Row className="content__videos--row">
                {userVideos &&
                  userVideos.length > 0 &&
                  userVideos.map((item, i) => (
                    <Col
                      key={i}
                      lg={3}
                      md={6}
                      sm={6}
                      xs={6}
                      className="content__videos--col"
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <VideoCardSelect
                        selectVideoHandler={selectVideoHandler}
                        item={item}
                        isSelected={selectedVideos.includes(item.id)}
                        selectedVideos={selectedVideos}
                        setVideoId={setVideoId}
                        setSelectedVideo={setSelectedVideo}
                      />
                    </Col>
                  ))}
              </Row>
            </div>
          </div>
        </SeriesStyles>

        {selectedVideo && videoId && (
          <EditClipForm
            setSelectedVideo={setSelectedVideo}
            selectedVideo={selectedVideo}
            videoId={videoId}
            close={() => setShowEditClipModel(false)}
          />
        )}

        {selectedVideo && deleteVideoConfirmationModel && (
          <ConfirmationModel
            closeHandler={() => setDeleteVideoConfirmationModel(false)}
            title="Are you sure you want to delete this video ? You can't undo this action."
            deleteHandler={deleteVideoHandler}
          />
        )}
      </>
    );
  }
};

export default EditSeriesModel;
