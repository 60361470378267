import {
  GET_CHANNEL_STREAM_VIDEOS_REQUEST,
  GET_CHANNEL_STREAM_VIDEOS_SUCCESS,
  GET_CHANNEL_STREAM_VIDEOS_FAIL,
  SHOW_CHANNEL_STREAM_FILTERED_VIDEOS,
  CLEAR_FILTERED_STREAM_VIDEOS,
} from "../actions/types";

const channel_stream_videos = (
  state = {
    videos: [],
    filteredVideos:[],
    hasMore: false,
    isFetching: true,
    isFiltering: false,

  },
  action
) => {
  switch (action.type) {
    case GET_CHANNEL_STREAM_VIDEOS_REQUEST:
      return {
        isFetching: true,
        filteredVideos:[...state.filteredVideos],
        videos:
          state.videos && state.videos.length > 0 ? [...state.videos] : [],
      };
    case GET_CHANNEL_STREAM_VIDEOS_SUCCESS:
      const allVideos = [...state.videos, ...action.payload.videos];
      const uniqueVideos = [
        ...new Map(allVideos.map((item) => [item["id"], item])).values(),
      ];

      return {
        isFetching: false,
        videos: uniqueVideos,
        hasMore: action.payload.hasMore,
        isFiltering: false,
        filteredVideos: [],

      };
      case SHOW_CHANNEL_STREAM_FILTERED_VIDEOS:
        const allvideos = [...state.filteredVideos, ...action.payload.videos];
        const uniqueFilteredVideos = [
          ...new Map(allvideos.map((item) => [item["id"], item])).values(),
        ];
  
        return {
          isFetching: false,
          filteredVideos: uniqueFilteredVideos,
          videos:[],
          hasMore: action.payload.hasMore,
          isFiltering: action.payload.isFiltering,
        };
    case CLEAR_FILTERED_STREAM_VIDEOS:
      return {
        ...state,
        filteredVideos: [],
      };
    case GET_CHANNEL_STREAM_VIDEOS_FAIL:
      return {
        isFetching: false,
        error: action.payload,
        filteredVideos: [],
        videos:
          state.videos && state.videos.length > 0 ? [...state.videos] : [],
        hasMore: false,
      };

    default:
      return state;
  }
};

export default channel_stream_videos;
