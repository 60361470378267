import React, { useState } from "react";

import { Switch, Typography, createStyles } from "@material-ui/core";

import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  updateGlobalBellNotificationStatus,
  updateGlobalEmailNotificationStatus,
} from "../actions";

function SetAllNoification() {
  const dispatch = useDispatch();

  const [globalBellNotification, setglobalBellNotification] = useState(false);
  const [globalEmailNotification, setglobalEmailNotification] = useState(false);
  const { profile } = useSelector(({ notificationCategory, profile, user }) => {
    return {
      notifications: notificationCategory.notificationData,
      isLoading: notificationCategory.isFetching,
      profile,
      user,
    };
  });
  const styles = createStyles({
    formControlLabel: {
      color: "#b3b4b5",
      fontSize: "0.7rem",
      fontWeight: 400,
    },
  });

  const handleGlobalBellNoification = (isChecked) => {
    setglobalBellNotification(isChecked);
    let profileData = profile;
    let updatedProfileState = profileData.userNotificationStatus.forEach(
      (element) => {
        element.status = isChecked;
      }
    );
    const data = { status: isChecked };
    dispatch(
      updateGlobalBellNotificationStatus({
        data: data,
        profileState: updatedProfileState,
      })
    );
  };

  const handleGlobalEmailNoification = (isChecked) => {
    setglobalEmailNotification(isChecked);
    let profileData = profile;
    let updatedProfileState = profileData.userNotificationStatus.forEach(
      (element) => {
        element.emailNotification = isChecked;
      }
    );
    const data = { emailNotification: isChecked };
    dispatch(
      updateGlobalEmailNotificationStatus({
        data: data,
        profileState: updatedProfileState,
      })
    );
  };

  const globalBellNotificationsChecked = (type) => {
    const userNotifications = profile.userNotificationStatus;
    if (userNotifications?.length) {
      const bellNotifications = userNotifications.filter((notification) => {
        if (type === "bell") {
          return notification.status === false;
        } else {
          return notification.emailNotification === false;
        }
      });
      if (bellNotifications.length > 0) return false;
      else return true; // return true if all bell notifications are true
    }
  };

  return (
    <div className="notification-controls">
      <FormControlLabel
        value="start"
        control={
          <Switch
            checked={globalBellNotificationsChecked("bell")}
            onChange={(e) => handleGlobalBellNoification(e.target.checked)}
            color="secondary"
            name=""
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        }
        label={
          <Typography style={styles.formControlLabel}>
            Get All Bell Notifications
          </Typography>
        }
        labelPlacement="start"
      />
      <FormControlLabel
        label={
          <Typography style={styles.formControlLabel}>
            Get All Email Notifications
          </Typography>
        }
        value="start"
        control={
          <Switch
            checked={globalBellNotificationsChecked("email")}
            onChange={(e) => handleGlobalEmailNoification(e.target.checked)}
          />
        }
        labelPlacement="start"
      />
    </div>
  );
}

export default SetAllNoification;
