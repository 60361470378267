import styled, { keyframes } from "styled-components";

const backgroundPan = keyframes`
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
`;

export const MarketingPageStyles = styled.div`
  .form {
    padding: 1.5rem;
    background-color: ${(props) => props.theme.bg};
    border-radius: 0.5rem;

    .subTitle {
      font-size: 0.7rem;
      text-transform: uppercase;
      opacity: 0.85;
      text-decoration: underline;
      font-family: "brother-1816", sans-serif;
      margin-bottom: 0.75rem;
      font-weight: 500;
    }

    .infoText {
      font-size: 0.7rem;
      color: white;
      opacity: 0.75;
      font-weight: light;
      margin-bottom: 0.3rem;
      line-height: 1.25;
      padding-top: 2px;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      &--submit {
        margin-top: 2.5rem !important;
      }
    }

    &__faq {
      &--list {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.75rem;

        &-item {
          padding: 1rem;
          border-radius: 0.35rem;
          background-color: ${(props) => props.theme.darkGrey};
          width: 100%;

          .header {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin-bottom: 1rem;
          }

          .remove {
            font-size: 0.55rem;
            line-height: 1;
            padding: 0.25rem 0.6rem;
            border-radius: 10rem;
            font-family: "brother-1816", sans-serif;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 1px;
            color: white;
            border: 1px solid white;
            transition: all 0.25s ease;
            opacity: 0.65;

            &:hover {
              opacity: 1;
              color: ${(props) => props.theme.red};
              border: 1px solid ${(props) => props.theme.red};
              cursor: pointer;
            }
          }

          .title {
            font-size: 1.05rem;
            line-height: 1.25;
            color: white;
          }

          .text {
            font-size: 0.85rem;
            font-weight: 300;
            color: rgba(255, 255, 255, 0.65);
            line-height: 1.5;
          }
        }
      }
    }

    &__whyus,
    &__stats,
    &__pricing {
      &--btn {
        margin-top: 2.5rem;
      }

      &--section {
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }

        &-title {
          font-family: "brother-1816", sans-serif;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 0.8rem;
          line-height: 1;
          padding-bottom: 0.5rem;
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);
          text-align: center;
          margin-bottom: 1rem;
          background: linear-gradient(
            to right,
            ${(props) => props.theme.primaryColor1},
            ${(props) => props.theme.primaryColor2},
            ${(props) => props.theme.primaryColor1}
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          animation: ${backgroundPan} 3s linear infinite;
          background-size: 200%;
        }
      }
    }

    &__pricing {
      &--section {
        &-benefits {
          padding: 1.25rem;
          border-radius: 0.5rem;
          border: 1px solid rgba(255, 255, 255, 0.25);
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.5rem;

          .item {
            background: ${(props) => props.theme.primaryGradient};
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 300;
            font-size: 0.85rem;
            width: 100%;
            padding: 0.5rem 0;
            line-height: 1;
            border-radius: 4px;
            text-transform: capitalize;
            cursor: pointer;

            .remove {
              font-family: "brother-1816", sans-serif;
              text-transform: uppercase;
              font-weight: bold;
              text-decoration: underline;
              padding: 0 3px 0 6px;
              font-size: 0.675rem;
              opacity: 0.75;
              transition: all 0.25s ease;
            }

            &:hover .remove {
              opacity: 1;
            }
          }

          .input {
            width: 100%;
            margin-top: 1rem;
          }
        }
      }
    }
  }
`;
