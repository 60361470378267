import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import ThreadCard from "../components/Cards/ThreadCard";
import Error from "../components/Elements/Error";
import ThreadCardSkeleton from "../skeletons/General/ThreadCard";
import ConfirmationModel from "../components/ConfirmationModel";
import { getThreads, deleteThread, getThreadsGuest } from "../actions/thread";
import { useDispatch, useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import { GlobalContext } from "../context/GlobalContext";
import { RESET_THREAD_STATE } from "../actions/thread/type";
import { toast } from "react-toastify";

const Threads = () => {
  const { ref: trackerRef, inView: trackerIsVisible } = useInView();
  const {
    selectedThread,
    deleteThreadConfirmationModel,
    setDeleteThreadConfirmationModel,
  } = useContext(GlobalContext);

  const dispatch = useDispatch();
  const { threads, hasMore, isFetching } = useSelector(
    (state) => state.threads
  );
  const { deleteMessage: threadDeleteMessage, deleteError: threadDeleteError } =
    useSelector((state) => state.thread);
  const user = useSelector((state) => state.user);

  // states
  const [threadPage, setThreadPage] = useState(1);
  const [threadLimit, setThreadLimit] = useState(1);
  const [scrollValue, setScrollValue] = useState(500);

  useEffect(() => {
    if (threadDeleteError) {
      toast.error(threadDeleteError);
    }

    if (threadDeleteMessage) {
      toast.error(threadDeleteMessage);
    }

    if (threadDeleteError || threadDeleteMessage) {
      dispatch({
        type: RESET_THREAD_STATE,
      });
    }
  }, [threadDeleteError, threadDeleteMessage]);

  useEffect(() => {
    if (hasMore && trackerIsVisible && !isFetching) {
      loadMoreThreadsHandler();
    }
  }, [trackerIsVisible, hasMore, isFetching]);

  useEffect(() => {
    if (!user?.id) {
      dispatch(getThreadsGuest(threadPage, threadLimit));
    } else {
      dispatch(getThreads(threadPage, threadLimit));
    }
  }, [user]);

  const loadMoreThreadsHandler = () => {
    setThreadPage(threadPage + 1);

    if (!user?.id) {
      dispatch(getThreadsGuest(threadPage + 1, threadLimit));
    } else {
      dispatch(getThreads(threadPage + 1, threadLimit));
    }
  };

  const deleteThreadHandler = () => {
    dispatch(deleteThread(selectedThread?.id));
    setDeleteThreadConfirmationModel(false);
  };

  return (
    <>
      <ThreadsPageStyled>
        <div className="cards">
          {!isFetching && !threads?.length && <Error text="No threads found" />}

          {threads &&
            threads.length > 0 &&
            threads.map((item, i) => (
              <div key={i} className="cards__item">
                <ThreadCard type={item.attachedType} item={item} />
              </div>
            ))}

          {isFetching && (
            <>
              {Array.from({ length: 3 }).map((item, i) => (
                <ThreadCardSkeleton className="cards__skeletonItem" key={i} />
              ))}
            </>
          )}
        </div>

        {hasMore === false && (
          <div className="noMore">
            You have reached the end of threads results
          </div>
        )}

        {!isFetching && <div ref={trackerRef} className="tracker" />}
      </ThreadsPageStyled>

      {selectedThread && deleteThreadConfirmationModel && (
        <ConfirmationModel
          closeHandler={() => setDeleteThreadConfirmationModel(false)}
          title="Are you sure you want to delete this thread ? You can't undo this action."
          deleteHandler={deleteThreadHandler}
        />
      )}
    </>
  );
};

export default Threads;

const ThreadsPageStyled = styled.div`
  width: 50%;
  margin: 0 auto;
  padding: 3rem 0;
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  .tracker {
    width: 100%;
    height: 5rem;
    background: transparent;
    opacity: 0;
    margin-top: 5rem;
  }

  .noMore {
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 0.85rem;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1;
    padding-top: 2.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  }

  .cards {
    &__item {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    &__skeletonItem {
      margin-bottom: 2rem;
    }
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 2rem 0 5rem 0;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;
