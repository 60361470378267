import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../../components/Elements/Button";
import api from "../../services/api";
import { TextField, Autocomplete } from "@mui/material";
import { toast } from "react-toastify";
import { truncateString } from "../../utils";

const AddItem = ({ isOpen, onClose, type, setData, data }) => {
  const [creatorId, setCreatorId] = useState(null);
  const [seriesId, setSeriesId] = useState(null);
  const [threadId, setThreadId] = useState(null);

  if (!isOpen) {
    return null;
  }

  return (
    <AddItemStyled>
      <div className="container">
        <div onClick={onClose} className="container__close">
          <img src="/icons/close-white.svg" alt="" />
        </div>

        <div className="container__form">
          {type === "CREATORS" && (
            <div className="container__form--item">
              <CreatorForm
                data={data}
                setData={setData}
                creatorId={creatorId}
                setCreatorId={setCreatorId}
                onClose={onClose}
              />
            </div>
          )}

          {type === "SERIES" && (
            <div className="container__form--item">
              <SeriesForm
                data={data}
                setData={setData}
                seriesId={seriesId}
                setSeriesId={setSeriesId}
                onClose={onClose}
              />
            </div>
          )}

          {type === "THREADS" && (
            <div className="container__form--item">
              <ThreadForm
                data={data}
                setData={setData}
                threadId={threadId}
                setThreadId={setThreadId}
                onClose={onClose}
              />
            </div>
          )}
        </div>
      </div>
    </AddItemStyled>
  );
};

export default AddItem;

const CreatorForm = ({ creatorId, setCreatorId, data, setData, onClose }) => {
  const [options, setOptions] = useState(topFilms);
  const [loading, setLoading] = useState(false);
  const [loadingV2, setLoadingV2] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      await fetchAllUsersHandler();
    })();
  }, []);

  const fetchAllUsersHandler = async () => {
    try {
      setLoading(true);
      const res = await api.get("users/allUser");
      setOptions(res.data);
      setLoading(true);
    } catch (error) {
      setError("Couldn't fetch users!");
      setLoading(false);
    }
  };

  const addFeaturedCreatorHandler = async (userId) => {
    try {
      setLoadingV2(true);

      const res = await api.post("admin/creator/featured/add", {
        userId,
      });

      let filteredData = [];
      if (data?.length) {
        filteredData = [...data, res.data.data];
      } else {
        filteredData = [res.data.data];
      }

      setData(filteredData);
      toast.success(res.data.message);
      setCreatorId(null);
      onClose();
    } catch (error) {
      toast.error("Something went wrong. Please try again!");
      setLoadingV2(false);
    }
  };

  return (
    <>
      <Autocomplete
        id="creators"
        sx={{
          width: "100%",
          outline: "none",
          border: "none",
          fontFamily: "Montserrat",

          "& .MuiFormControl-root": {
            backgroundColor: "#fff",
            borderRadius: ".2rem",
          },
        }}
        size="small"
        loading={loading}
        value={creatorId}
        onChange={(_, newValue) => setCreatorId(newValue?.id)}
        options={options}
        getOptionLabel={(item) => {
          return item.username
            ? item.username
            : options?.find((p) => p?.id === item)?.username ?? "";
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder="Select Creator" />
        )}
      />

      <Button
        onClick={() => addFeaturedCreatorHandler(creatorId)}
        className="container__addButton"
        mode="primary"
        width="fluid"
        variant="round"
        size="medium"
      >
        {loadingV2 ? "Please wait..." : "Add Creator"}
      </Button>
    </>
  );
};

const SeriesForm = ({ seriesId, setSeriesId, data, setData, onClose }) => {
  const [options, setOptions] = useState(topFilms);
  const [loading, setLoading] = useState(false);
  const [loadingV2, setLoadingV2] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      await fetchAllSeriesHandler();
    })();
  }, []);

  const fetchAllSeriesHandler = async () => {
    try {
      setLoading(true);
      const res = await api.get("series/getAdminAllSeries");
      setOptions(res.data);
      setLoading(true);
    } catch (error) {
      setError("Couldn't fetch users!");
      setLoading(false);
    }
  };

  const addFeaturedSeriesHandler = async (seriesId) => {
    try {
      setLoadingV2(true);

      const res = await api.post("admin/series/featured/add", {
        seriesId,
      });

      let filteredData = [];
      if (data?.length) {
        filteredData = [...data, res.data.data];
      } else {
        filteredData = [res.data.data];
      }

      setData(filteredData);
      toast.success(res.data.message);
      setSeriesId(null);
      onClose();
    } catch (error) {
      toast.error("Something went wrong. Please try again!");
      setLoadingV2(false);
    }
  };

  return (
    <>
      <Autocomplete
        id="series"
        sx={{
          width: "100%",
          outline: "none",
          border: "none",
          fontFamily: "Montserrat",

          "& .MuiFormControl-root": {
            backgroundColor: "#fff",
            borderRadius: ".2rem",
          },
        }}
        size="small"
        loading={loading}
        value={seriesId}
        onChange={(_, newValue) => setSeriesId(newValue?.id)}
        options={options}
        getOptionLabel={(item) => {
          return item.title
            ? item.title
            : options?.find((p) => p?.id === item)?.title ?? "";
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder="Select Series" />
        )}
      />

      <Button
        onClick={() => addFeaturedSeriesHandler(seriesId)}
        className="container__addButton"
        mode="primary"
        width="fluid"
        variant="round"
        size="medium"
      >
        {loadingV2 ? "Please wait..." : "Add Series"}
      </Button>
    </>
  );
};

const ThreadForm = ({ threadId, setThreadId, data, setData, onClose }) => {
  const [options, setOptions] = useState(topFilms);
  const [loading, setLoading] = useState(false);
  const [loadingV2, setLoadingV2] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      await fetchAllThreadsHandler();
    })();
  }, []);

  const fetchAllThreadsHandler = async () => {
    try {
      setLoading(true);
      const res = await api.get("thread/getThreadData");
      setOptions(res.data);
      setLoading(true);
    } catch (error) {
      setError("Couldn't fetch users!");
      setLoading(false);
    }
  };

  const addFeaturedThreadHandler = async (threadId) => {
    try {
      setLoadingV2(true);

      const res = await api.post("admin/thread/featured/add", {
        threadId,
      });

      let filteredData = [];
      if (data?.length) {
        filteredData = [...data, res.data.data];
      } else {
        filteredData = [res.data.data];
      }

      setData(filteredData);
      toast.success(res.data.message);
      setThreadId(null);
      onClose();
    } catch (error) {
      toast.error("Something went wrong. Please try again!");
      setLoadingV2(false);
    }
  };

  return (
    <>
      <Autocomplete
        id="threads"
        sx={{
          width: "100%",
          outline: "none",
          border: "none",
          fontFamily: "Montserrat",

          "& .MuiFormControl-root": {
            backgroundColor: "#fff",
            borderRadius: ".2rem",
          },
        }}
        size="small"
        loading={loading}
        value={threadId}
        onChange={(_, newValue) => setThreadId(newValue?.id)}
        options={options}
        getOptionLabel={(item) => {
          return item.threadBody
            ? truncateString(item.threadBody, 50) + " ..."
            : truncateString(
                options?.find((p) => p?.id === item)?.threadBody,
                50
              ) + " ..." ?? "";
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder="Select Thread" />
        )}
      />

      <Button
        onClick={() => addFeaturedThreadHandler(threadId)}
        className="container__addButton"
        mode="primary"
        width="fluid"
        variant="round"
        size="medium"
      >
        {loadingV2 ? "Please wait..." : "Add Thread"}
      </Button>
    </>
  );
};

const AddItemStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  max-width: 100vw;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);

  .container {
    background-color: black;
    width: 40%;
    min-width: 480px;
    padding: 3rem 1.25rem 1.25rem 1.25rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 80vh;
    overflow-y: auto;
    border: 1px solid rgba(255, 255, 255, 0.75);
    min-height: 40vh;

    &__close {
      position: absolute;
      top: 0;
      right: 0;
      height: 2rem;
      width: 2rem;
      border-bottom-left-radius: 0.25rem;
      background: ${(props) => props.theme.gradient};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 5;

      img {
        width: 1rem;
        height: auto;
      }
    }

    &__addButton {
      font-size: 0.7rem;
      margin-top: 1.5rem;
      padding: 0.3rem auto;
      font-weight: 500;
    }
  }
`;

const topFilms = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  {
    title: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  {
    title: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
];
