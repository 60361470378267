import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import styled from "styled-components";

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const startPage = Math.max(currentPage - 0, 1);
  const endPage = Math.min(currentPage + 7, pageNumbers.length);

  return (
    <PaginationStyled>
      <div className="pagination" style={{ display: "flex" }}>
        {/* Previous Page Button */}
        <div className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <div
            onClick={() => {
              if (currentPage > 1) {
                paginate(currentPage - 1);
              }
            }}
            className="pagination_button"
          >
            <FaChevronLeft />
          </div>
        </div>

        {/* Page Numbers */}
        {pageNumbers.slice(startPage - 1, endPage).map((pageNumber) => (
          <div
            key={pageNumber}
            className={`page-item ${
              pageNumber === currentPage ? "active" : ""
            }`}
          >
            <div
              onClick={() => paginate(pageNumber)}
              className="pagination_button"
            >
              {pageNumber}
            </div>
          </div>
        ))}

        {/* Next Page Button */}
        <div
          className={`page-item ${
            currentPage === pageNumbers.length ? "disabled" : ""
          }`}
        >
          <div
            onClick={() => {
              if (currentPage < pageNumbers.length) {
                paginate(currentPage + 1);
              }
            }}
            className="pagination_button"
          >
            <FaChevronRight />
          </div>
        </div>
      </div>
    </PaginationStyled>
  );
};

export default Pagination;

const PaginationStyled = styled.div`
  .pagination_button {
    border: 2px solid;
    color: white;
    width: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 42px;
    border-radius: 6px;
    margin: 4px;
  }

  .page-item.active .pagination_button {
    background-color: #49494c;
  }

  .pagination_button.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
`;
