import React from "react";
import styled from "styled-components";
import MenuOptions from "./MenuOptions";
import Moment from "react-moment";
import CloseIcon from "../../assets/Icons/close.svg";
import { toast } from "react-toastify";
import { removeContentFromElement } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { notificationMessage } from "../../utils/toastNotifications";
import config from "../../config";

const SERIESOPTIONS = [
  { icon: "", text: "Manage Video" },
  { icon: "", text: "Share Video" },
];

const BannerCard = ({ item, showMockUp, removeable, elementId, page }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state);

  if (showMockUp) {
    return (
      <BannerCardStyled>
        <div className="thumbnail">
          <img
            src={
              "https://phantom-marca.unidadeditorial.es/c8bbfcfccc1d1c1cd3a66ee4e79bc90a/resize/1320/f/jpg/assets/multimedia/imagenes/2022/05/24/16533667918879.jpg"
            }
            alt={"hello"}
          />
        </div>

        <div className="metaInfo">
          <div className="metaInfo__left">
            <div className="metaInfo__left--videoMeta">
              <div className="title">Some random title goes here</div>

              <div className="seperatorInfo">
                <div className="bold">Warrior</div>
                <div className="light">
                  <Moment fromNow>20 Jun 2022</Moment>
                </div>
              </div>

              <div className="lightText">0 Videos</div>
            </div>
          </div>
          <div className="metaInfo__right">
            <MenuOptions options={SERIESOPTIONS} />
          </div>
        </div>

        <div className="numVideos">
          <span>3 Videos</span>
        </div>

        <div className="price">Free</div>

        {removeable && (
          <div className="close">
            <img src={CloseIcon} alt="" />
          </div>
        )}
      </BannerCardStyled>
    );
  }

  return (
    <BannerCardStyled>
      <div className="thumbnail" page={page}>
        <img
          src={config.aws.CLOUDFRONT_URL + item?.customBannerUrl}
          alt={"custom-banner"}
        />
      </div>
      {removeable && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            dispatch(removeContentFromElement(elementId, item.contentId));
            toast.success(notificationMessage.removedFromFeatured);
          }}
          className="close"
        >
          <img src={CloseIcon} alt="" />
        </div>
      )}
    </BannerCardStyled>
  );
};

export default BannerCard;

const BannerCardStyled = styled.div`
  margin-bottom: 2rem;
  position: relative;

  .close {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 4px solid #f9903d;
    background-color: #f9903d;
    cursor: pointer;
    transition: all 0.25s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    img {
      height: 0.8rem;
      width: auto;
    }
  }

  .numVideos {
    position: absolute;
    top: 9.5rem;
    right: 1rem;
    font-size: 0.8rem;
    padding: 0.2rem 0.7rem;
    color: #fff;
    z-index: 2;
    font-family: brother-1816, sans-serif;
    background-color: rgba(28, 28, 30, 1);
    text-transform: uppercase;
    border-radius: 0.3rem;
    cursor: pointer;
  }

  .price {
    position: absolute;
    top: 9.5rem;
    left: 1rem;
    font-size: 0.8rem;
    padding: 0.2rem 0.7rem;
    color: #fff;
    z-index: 2;
    font-family: brother-1816, sans-serif;
    background-color: rgba(28, 28, 30, 1);
    text-transform: uppercase;
    border-radius: 0.3rem;
    cursor: pointer;
  }

  .thumbnail {
    width: 100%;
    height: ${({ page }) => (page === "portalHome" ? "400px" : "40vh")};
    overflow: hidden;
    margin-bottom: 1rem;
    border-radius: 1rem;

    img {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
      max-height: 400px;
    }
  }

  .metaInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
    font-family: ${(props) => props.theme.montserrat};
    position: relative;

    &__left {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;

      &--videoMeta {
        cursor: pointer;
        width: 100%;

        .title {
          font-size: 0.95rem;
          font-weight: 500;
          line-height: 1.5;
        }

        .seperatorInfo {
          font-size: 0.75rem;
          display: flex;
          align-items: center;

          & > *:not(:last-child) {
            &::after {
              content: "•";
              margin-left: 8px;
              position: absolute;
              display: inline-block;
              font-weight: 700;
            }
          }
          & > *:not(:first-child) {
            margin-left: 18px;
          }

          .bold {
            font-weight: 500;
            color: rgba(199, 199, 204, 1);
          }

          .light {
            color: rgba(174, 174, 178, 1);
            font-size: 300;
          }
        }

        .lightText {
          font-weight: 400;
          font-size: 0.8rem;
          color: rgba(174, 174, 178, 1);
        }
      }
    }

    &__right {
      cursor: pointer;
      position: absolute;
      top: 1rem;
      right: 0;
    }
  }

  @media screen and (max-width: 480px) {
    .numVideos {
      top: 7rem;
      right: 0.5rem;
      font-size: 0.55rem;
      padding: 0.2rem 0.5rem;
    }

    .price {
      top: 7rem;
      left: 0.5rem;
      font-size: 0.65rem;
      padding: 0.2rem 0.5rem;
    }

    .thumbnail {
      margin-bottom: 0.5rem;
      height: 9rem;
    }

    .metaInfo {
      &__left {
        &--videoMeta {
          .title {
            font-size: 0.8rem;
          }

          .seperatorInfo {
            font-size: 0.6rem;

            & > *:not(:last-child) {
              &::after {
                margin-left: 6px;
              }
            }
            & > *:not(:first-child) {
              margin-left: 15px;
            }
          }

          .lightText {
            font-size: 0.65rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 390px) {
    .metaInfo {
      &__left {
        &--videoMeta {
          .seperatorInfo {
            font-size: 0.55rem;
          }
        }
      }
    }
  }
`;
