import React, { useContext, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import CloseIcon from "../icons/close.svg";
import MessageIcon from "../icons/mail.svg";
import DonateIcon from "../icons/donate-black.svg";
import DonateIconWhite from "../icons/donate.svg";
import styled, { keyframes } from "styled-components";
import Input from "../Elements/Input";
import Switch from "react-switch";
import SavedCreditCard from "../Cards/SavedCreditCard";
import STRINGS from "../../utils/Strings";
import { GlobalContext } from "../../context/GlobalContext";
import { isBadgeOrAvatar } from "../../utils";
import { toast } from "react-toastify";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Row, Col } from "react-grid-system";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import {
  acceptPay,
  getVideo,
  savePPVUnlockInformation,
  saveTATVUnlockInfo,
  transfers,
  unlockTATVideo,
} from "../../actions";
import { notificationMessage } from "../../utils/toastNotifications";
import ReactTooltip from "react-tooltip";
import RewardIcon from "../icons/reward.svg";
import TickIcon from "../icons/tick-v2.svg";
import { calculatePercentage } from "../../utils";
import { rewardVideo } from "../../actions/thread";

const KARMA_OPTIONS = [2, 5, 10, 15, 20, 25];

const cardStyle = {
  style: {
    base: {
      color: "#000",
      backgroundColor: "#fff",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "16px",
      "::placeholder": {
        color: "rgba(0,0,0,0.65)",
        textTransfer: "uppercase",
        fontWeight: 300,
      },
    },
    invalid: {
      color: "#f00",
      iconColor: "#f00",
    },
  },
};

const Karma = ({ creator, video }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { showKarmaModel, setShowKarmaModel } = useContext(GlobalContext);
  const [karma, setKarma] = useState(0);
  const [customKarma, setCustomKarma] = useState(null);
  const [showCustomKarma, setShowCustomKarma] = useState(false);
  const [savePM, setSavePM] = useState(true);
  const [savePMToLocalstorage, setSavePMToLocalstorage] = useState(false);
  const [selectedPM, setSelectedPM] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rewardKarma, setRewardKarma] = useState(video.RewardsAmount);
  const [rewardKarmaAnimationPlayed, setRewardKarmaAnimationPlayed] =
    useState(false);

  // console.log(video);

  const user = useSelector((state) => state.user);
  const { paymentMethods } = user;

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  useEffect(() => {
    if (!rewardKarmaAnimationPlayed) {
      if (rewardKarma <= (customKarma || renderKarma())) {
        setRewardKarmaAnimationPlayed(true);
      }
    }
  }, [customKarma, karma]);

  useEffect(() => {
    if (showCustomKarma) {
      document.getElementById("customKarma").focus();
    }
  }, [showCustomKarma]);

  useEffect(() => {
    if (savePMToLocalstorage) {
      const obj = {
        isSavePM: savePM,
      };

      localStorage.setItem("freeloaderUserSettings", JSON.stringify(obj));
    }
  }, [savePM, setSelectedPM]);

  useEffect(() => {
    const freeloaderUserSettings = JSON.parse(
      localStorage.getItem("freeloaderUserSettings")
    );

    if (freeloaderUserSettings) {
      setSavePM(freeloaderUserSettings?.isSavePM);
    }
  }, [showKarmaModel]);

  const avatar = creator?.avatar
    ? creator.avatar
    : creator?.badge
    ? creator.badge
    : null;

  const submitHandler = async () => {
    if (KARMA_OPTIONS[karma] <= 0 && !customKarma) {
      return toast.error(
        "Karma must be greater than 0. Use slider to increase karma points."
      );
    }

    setLoading(true);
    const amount = customKarma > 0 ? customKarma : KARMA_OPTIONS[karma];
    // if there is no saved card
    try {
      let res;
      if (!selectedPM) {
        res = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
        });
      }

      let payload = {
        amount,
        currency: "usd",
        metadata: {
          integration_check: "accept_a_payment",
        },
        savePaymentMethod: savePM,
      };

      if (selectedPM !== null) {
        payload.customer = user.stripe_customer_id;
      }

      if (selectedPM === null && res?.paymentMethod?.id) {
        payload.paymentMethodId = res.paymentMethod.id;
        payload.customer = user.stripe_customer_id;
      }
      if (rewardKarma <= amount) {
        // action for reward
        dispatch(
          savePPVUnlockInformation({
            videoId: video.id,
            userId: user.id,
            videoContentType: 4,
          })
        );
        dispatch(getVideo(video.id, user.id));
      }

      const result = await dispatch(acceptPay(payload));

      if (result?.data?.data?.client_secret) {
        await stripeAcceptPayment(result.data.data.client_secret);
      } else {
        toast.error(
          "This card details already exits in your saved cards. Please select from below to make a payment or use another card which is not saved."
        );
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );

      setLoading(false);
      toast.error(notificationMessage.somethingWentWrong);
    }
  };

  const stripeAcceptPayment = async (clientId) => {
    const amount = customKarma > 0 ? customKarma : KARMA_OPTIONS[karma];

    try {
      if (!stripe || !elements) {
        return;
      }
      let newPaymentMethod = {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: user.firstName,
          email: user.email,
        },
      };

      const result = await stripe.confirmCardPayment(clientId, {
        payment_method: selectedPM !== null ? selectedPM.id : newPaymentMethod,
      });

      if (result.error) {
        setLoading(false);
        console.log(result.error, "error from stripe accept pay");
        if (result.error?.code === "incomplete_number") {
          return toast.error(
            "Please provide your card details or choose from saved cards!"
          );
        }
      } else {
        if (result.paymentIntent.status === "succeeded") {
          if (video.User.stripe_account_id) {
            const finalPrice = (result.paymentIntent.amount * 6) / 10000 + 0.3;
            const creatorAmount = amount - finalPrice;
            await dispatch(
              transfers({
                amount: creatorAmount,
                currency: "usd",
                destination: video.User.stripe_account_id,
                toUserId: video.User.id,
                videoId: video.id,
                action: "additional_content",
              })
            );
            if (video.keyVideoAccess === 3 && !video.isTATVideoUnlocked) {
              saveTA2UnlockDetail(video.id, user.id);
              localStorage.setItem(`PaymentDone${video.id}`, true);
            }
            toast.success(notificationMessage.paymentSuccessfull);
            toast.success(
              `$${creatorAmount.toFixed(2)} USD was sent to ${
                creator?.username
              } successfully! Thank you for giving Karma back to the Content Creator!`
            );

            const karma = renderKarma();
            if (video?.HasRewards && karma >= video?.RewardsAmount) {
              toast.success(
                <div className="toast__body--content">
                  <img src={RewardIcon} alt="" className="icon" />
                  <div className="text">
                    Your Reward are now unlocked and your files can be
                    downloaded.
                  </div>
                </div>,
                {
                  className: "toast__reward",
                  bodyClassName: "toast__reward--body",
                  progressClassName: "toast__reward--progressBar",
                }
              );
            }

            elements.getElement(CardNumberElement).clear();
            elements.getElement(CardExpiryElement).clear();
            elements.getElement(CardCvcElement).clear();
            setSelectedPM(null);
            setLoading(false);
            setShowKarmaModel(false);
            setKarma(0);
            setShowCustomKarma(false);
            setCustomKarma(null);
          }
        }
      }
    } catch (error) {
      console.log(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );

      setLoading(false);
      toast.error(notificationMessage.somethingWentWrong);
    }
  };
  const saveTA2UnlockDetail = (videoId, userId) => {
    dispatch(
      saveTATVUnlockInfo({
        videoId,
        userId,
      })
    );
    dispatch(unlockTATVideo(videoId));
  };
  const renderKarma = () =>
    customKarma > 0 ? customKarma : KARMA_OPTIONS[karma];

  return (
    <KarmaStyled className={`${showKarmaModel ? "ShowKarmaModel" : null}`}>
      <div className="content">
        <div
          onClick={() => {
            setShowKarmaModel(false);
          }}
          className="close"
        >
          <img src={CloseIcon} alt="" />
        </div>

        <div className="content__title">
          <span className="uppercase">Give Karma to </span>{" "}
          <div className="lowercase">{creator?.username}</div>
        </div>

        <div className="content__main">
          <div className="content__main--user">
            <div className="left">
              <div className="avatar">{isBadgeOrAvatar(avatar)}</div>

              <div className="info">
                <div className="username">{creator?.username}</div>
                <div className="karma">
                  <div className="bold">Karma</div> ${renderKarma()}
                </div>
              </div>
            </div>

            <div className="right">
              <div className="sendMessage">
                <img src={MessageIcon} alt="" />
              </div>
            </div>
          </div>
          <div className="content__main--slider">
            <div className="content__main--slider-left">
              <input
                id="karmaInput"
                type="range"
                value={karma}
                min={0}
                defaultValue={0}
                max={5}
                step={1}
                onChange={(e) => {
                  setKarma(e.target.value);
                }}
                list="sliderInput-list"
                className="sliderInput"
              />

              <datalist id="sliderInput-list">
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </datalist>

              {video?.HasRewards && (
                <div
                  style={{
                    left: `${calculatePercentage(
                      rewardKarma,
                      KARMA_OPTIONS[KARMA_OPTIONS.length - 1]
                    )}%`,
                  }}
                  className={`content__main--slider-left-rewardUnlockPos ${
                    renderKarma() >= rewardKarma
                      ? "content__main--slider-left-rewardUnlockPos-unlocked"
                      : null
                  }`}
                >
                  <div className="bar"></div>
                  {renderKarma() >= rewardKarma ? (
                    <>
                      <img src={TickIcon} alt="" className="icon" />
                      <div className="text">Reward Unlocked</div>
                    </>
                  ) : (
                    <>
                      <img src={RewardIcon} alt="" className="icon" />
                      <div className="text">Reward Unlock</div>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="content__main--slider-right">
              <div className="sliderLabel">
                <img src={DonateIcon} alt="" />${KARMA_OPTIONS[karma]}
              </div>

              {video?.HasRewards && (
                <div
                  data-for="video-reward-tooltip"
                  data-tip="hello"
                  data-event="click"
                  className={`content__main--slider-right-rewardBtn ${
                    renderKarma() >= rewardKarma
                      ? "content__main--slider-right-rewardBtn-showAnimation"
                      : null
                  }`}
                >
                  {/* for animation */}
                  {Array.from({ length: 50 }).map((item, i) => (
                    <i key={i}></i>
                  ))}

                  <div className="content__main--slider-right-rewardBtn-icon">
                    <img src={RewardIcon} alt="" />
                  </div>
                  <div className="content__main--slider-right-rewardBtn-text">
                    Rewards
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            onClick={() => {
              setShowCustomKarma(!showCustomKarma);
            }}
            className="content__main--custom"
          >
            <img src={DonateIconWhite} alt="" />
            Custom Karma Tip!
          </div>

          {showCustomKarma && (
            <div className="content__main--customKarma">
              <Input
                type="number"
                id="customKarma"
                placeholder={`Give $ karma to ${creator?.username}`}
                value={customKarma}
                onChange={(e) => setCustomKarma(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className="content__payment">
          <div className="content__payment--title">Add a payment details</div>
          <div className="content__payment--form">
            <Row>
              <Col md={12}>
                <div className="fieldItem">
                  <CardNumberElement options={cardStyle} />
                </div>
              </Col>
              <Col
                style={{
                  paddingRight: !isMobile ? "4px" : "15px",
                }}
                md={6}
                sm={12}
              >
                <div className="fieldItem">
                  <CardExpiryElement options={cardStyle} />
                </div>
              </Col>
              <Col
                style={{
                  paddingLeft: !isMobile ? "4px" : "15px",
                }}
                md={6}
                sm={12}
              >
                <div className="fieldItem">
                  <CardCvcElement options={cardStyle} />
                </div>
              </Col>
            </Row>

            <label className="toggleBox">
              <Switch
                onColor="#28df99"
                offColor="#cc0000"
                checked={savePM}
                onChange={(e) => {
                  setSavePMToLocalstorage(true);
                  setSavePM(!savePM);
                }}
              />
              <span>Save this card for future payments</span>
            </label>
          </div>

          {paymentMethods?.data?.length > 0 && (
            <>
              <div className="content__payment--title">
                OR used saved cards!
              </div>

              <div className="content__payment--savedCards">
                {paymentMethods.data.map((item, i) => (
                  <div key={i} className="item">
                    <SavedCreditCard
                      selectedPM={selectedPM}
                      setSelectedPM={setSelectedPM}
                      item={item}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        <div className="content__desc">
          By giving karma to the creator, you are supporting them to create more
          good content in upcoming days. <span className="link">Read more</span>
        </div>

        <div className="content__footer">
          <button className="content__footer--button" onClick={submitHandler}>
            {loading ? "loading..." : <>Send ${renderKarma()} Karma</>}
          </button>
        </div>
      </div>

      <ReactTooltip
        getContent={(dataTip) => (
          <div className="content__main--slider-right-tooltip">
            Additional content rewards for this video will unlock when you give
            karma of at least {rewardKarma}$.
            <div onClick={() => {}} className="add">
              Give {rewardKarma}$ karma to unlock!
            </div>
          </div>
        )}
        effect="solid"
        arrowColor="#000"
        backgroundColor="#000"
        border={true}
        borderColor="rgba(255,255,255, .2)"
        textColor="#fff"
        arrowRadius="5"
        place={isMobile ? "left" : "bottom"}
        id="video-reward-tooltip"
        clickable={true}
      />
    </KarmaStyled>
  );
};

export default Karma;

const backgroundPan = keyframes`
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
`;

const bang = keyframes`
 from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

const KarmaStyled = styled.div`
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  transform-origin: center;
  opacity: 0;
  scale: 0;
  visibility: hidden;
  z-index: -1;
  transition: all 0.4s ease;
  background: linear-gradient(
    180deg,
    rgba(56, 56, 56, 0.06),
    rgba(56, 56, 56, 0.06)
  );
  backdrop-filter: blur(15px);
  font-family: ${(props) => props.theme.montserrat}, sans-serif;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #202020;
    padding: 2rem;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.09);
    color: #fff;
    border-radius: 0.8rem;
    min-width: 480px;
    width: 45%;
    height: 80vh;
    overflow-y: auto;

    .close {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      height: 1.8rem;
      width: 1.8rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 1rem;
        width: auto;
      }
    }

    &__title {
      font-size: 1.1rem;
      font-weight: 400;
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;

      .uppercase {
        text-transform: uppercase;
        padding-right: 0.6rem;
        font-size: 0.8rem;
        letter-spacing: 0.5px;
      }

      .lowercase {
        font-weight: 500;
        background: linear-gradient(
          to right,
          ${(props) => props.theme.primaryColor1},
          ${(props) => props.theme.primaryColor2},
          ${(props) => props.theme.primaryColor1}
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: ${backgroundPan} 3s linear infinite;
        background-size: 200%;
      }
    }

    &__desc {
      background-color: #181818;
      padding: 0.8rem 1.2rem;
      border-radius: 0.4rem;
      color: rgba(255, 255, 255, 0.65);
      font-size: 0.75rem;
      font-weight: 300;
      border: 1px solid rgba(255, 255, 255, 0.09);

      .link {
        color: ${(props) => props.theme.blue};
        text-decoration: underline;
      }
    }

    &__main {
      &--user {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
        }

        .right {
          .sendMessage {
            height: 2.7rem;
            width: 2.7rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid rgba(255, 255, 255, 0.09);
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
              border: 1px solid rgba(255, 255, 255, 0.6);

              img {
                transform: scale(1.15);
              }
            }

            img {
              height: 0.9rem;
              width: auto;
              transition: all 0.2s ease;
            }
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .username {
            font-weight: 400;
            font-size: 0.9rem;
            cursor: pointer;
            line-height: 1;
            margin-bottom: 0.2rem;
          }

          .karma {
            padding: 0.2rem 1.2rem;
            border-radius: 5rem;
            background: ${(props) => props.theme.primaryGradient};
            font-size: 0.65rem;
            font-weight: 300;
            display: flex;
            align-items: center;
            line-height: 1;

            .bold {
              font-weight: 400;
              font-family: brother-1816, sans-serif;
              text-transform: uppercase;
              padding-right: 0.4rem;
              font-size: 0.625;
            }
          }
        }

        .avatar {
          cursor: pointer;
          height: 2.2rem;
          width: 2.2rem;
          border-radius: 50%;
          position: relative;
          margin-right: 0.5rem;
          outline: 1px solid rgba(255, 255, 255, 0.5);

          .imageAvatar {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
            border-radius: 50%;
          }

          .imageBadge {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .badge {
              height: 38px;
              width: auto;
            }
          }
        }
      }

      &--slider {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;

        &-right {
          display: flex;
          flex-direction: column;
          align-items: center;

          .sliderLabel {
            line-height: 1;
            width: 4.5rem;
            background: #fff;
            height: 1.8rem;
            padding: 0 1.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            border-radius: 4px;
            font-size: 0.83rem;
            font-weight: 600;
            font-family: brother-1816, sans-serif;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.65);

            img {
              height: 1.2rem;
              width: auto;
              padding-right: 0.4rem;
            }

            &:after {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: -8px;
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-right: 10px solid #fff;
              border-bottom: 10px solid transparent;
              content: "";
            }
          }

          // tooltip styling
          &-tooltip {
            font-weight: 400;
            font-size: 0.8rem;
            user-select: none;

            .add {
              font-family: brother-1816, sans-serif;
              text-transform: uppercase;
              font-weight: 500;
              text-decoration: underline;
              font-size: 0.75rem;
              background: linear-gradient(
                to right,
                ${(props) => props.theme.primaryColor1},
                ${(props) => props.theme.primaryColor2},
                ${(props) => props.theme.primaryColor1}
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              animation: ${backgroundPan} 3s linear infinite;
              background-size: 200%;
              cursor: pointer;
            }
          }

          &-rewardBtn {
            margin-top: 0.5rem;
            padding: 0.25rem 0.35rem;
            display: flex;
            align-items: center;
            border-radius: 4px;
            color: #fff;
            line-height: 1;
            position: relative;
            transition: all 0.3s ease;
            z-index: 1;
            cursor: pointer;
            user-select: none;

            i {
              position: absolute;
              display: block;
              left: 50%;
              top: 0;
              width: 5px;
              height: 12.5px;
              background: ${(props) => props.theme.primaryGradient};
              opacity: 0;
            }

            &-showAnimation {
              i {
                animation: ${bang} 1.2s ease-out forwards;
                opacity: 0;

                &:nth-child(even) {
                  background: ${(props) => props.theme.primaryGradient};
                }

                &:nth-child(odd) {
                  background: rgba(255, 255, 255, 0.8);
                }

                &:nth-of-type(2) {
                  transform: translate3d(-41px, -53px, 0) rotate(178deg);
                }
                &:nth-of-type(3) {
                  transform: translate3d(-46px, 82px, 0) rotate(207deg);
                }
                &:nth-of-type(4) {
                  transform: translate3d(-41px, 19px, 0) rotate(357deg);
                }
                &:nth-of-type(5) {
                  transform: translate3d(24px, -45px, 0) rotate(208deg);
                }
                &:nth-of-type(6) {
                  transform: translate3d(18px, 48px, 0) rotate(240deg);
                }
                &:nth-of-type(7) {
                  transform: translate3d(74px, 49px, 0) rotate(340deg);
                }
                &:nth-of-type(8) {
                  transform: translate3d(24px, 17px, 0) rotate(210deg);
                }
                &:nth-of-type(9) {
                  transform: translate3d(-43px, 61px, 0) rotate(183deg);
                }
                &:nth-of-type(10) {
                  transform: translate3d(-68px, 21px, 0) rotate(209deg);
                }
                &:nth-of-type(11) {
                  transform: translate3d(-97px, 21px, 0) rotate(333deg);
                }
                &:nth-of-type(12) {
                  transform: translate3d(-79px, 1px, 0) rotate(46deg);
                }
                &:nth-of-type(13) {
                  transform: translate3d(38px, -33px, 0) rotate(294deg);
                }
                &:nth-of-type(14) {
                  transform: translate3d(18px, -4px, 0) rotate(152deg);
                }
                &:nth-of-type(15) {
                  transform: translate3d(23px, -90px, 0) rotate(68deg);
                }
                &:nth-of-type(16) {
                  transform: translate3d(-62px, -91px, 0) rotate(341deg);
                }
                &:nth-of-type(17) {
                  transform: translate3d(35px, -60px, 0) rotate(239deg);
                }
                &:nth-of-type(18) {
                  transform: translate3d(-70px, -50px, 0) rotate(78deg);
                }
                &:nth-of-type(19) {
                  transform: translate3d(46px, -43px, 0) rotate(358deg);
                }
                &:nth-of-type(20) {
                  transform: translate3d(44px, 89px, 0) rotate(209deg);
                }
                &:nth-of-type(21) {
                  transform: translate3d(84px, 44px, 0) rotate(257deg);
                }
                &:nth-of-type(22) {
                  transform: translate3d(27px, 87px, 0) rotate(148deg);
                }
                &:nth-of-type(23) {
                  transform: translate3d(-16px, 52px, 0) rotate(252deg);
                }
                &:nth-of-type(24) {
                  transform: translate3d(37px, -55px, 0) rotate(140deg);
                }
                &:nth-of-type(25) {
                  transform: translate3d(43px, -16px, 0) rotate(152deg);
                }
                &:nth-of-type(26) {
                  transform: translate3d(-62px, 96px, 0) rotate(116deg);
                }
                &:nth-of-type(27) {
                  transform: translate3d(79px, 86px, 0) rotate(121deg);
                }
                &:nth-of-type(28) {
                  transform: translate3d(16px, -93px, 0) rotate(318deg);
                }
                &:nth-of-type(29) {
                  transform: translate3d(-9px, 72px, 0) rotate(93deg);
                }
                &:nth-of-type(30) {
                  transform: translate3d(-31px, 98px, 0) rotate(2deg);
                }
                &:nth-of-type(31) {
                  transform: translate3d(50px, -52px, 0) rotate(299deg);
                }
                &:nth-of-type(32) {
                  transform: translate3d(-13px, 81px, 0) rotate(314deg);
                }
                &:nth-of-type(33) {
                  transform: translate3d(-59px, 18px, 0) rotate(1deg);
                }
                &:nth-of-type(34) {
                  transform: translate3d(75px, -38px, 0) rotate(65deg);
                }
                &:nth-of-type(35) {
                  transform: translate3d(24px, -86px, 0) rotate(105deg);
                }
                &:nth-of-type(36) {
                  transform: translate3d(87px, -62px, 0) rotate(317deg);
                }
                &:nth-of-type(37) {
                  transform: translate3d(24px, -64px, 0) rotate(9deg);
                }
                &:nth-of-type(38) {
                  transform: translate3d(84px, -85px, 0) rotate(135deg);
                }
                &:nth-of-type(39) {
                  transform: translate3d(-62px, -99px, 0) rotate(166deg);
                }
                &:nth-of-type(40) {
                  transform: translate3d(-82px, 10px, 0) rotate(58deg);
                }
                &:nth-of-type(41) {
                  transform: translate3d(42px, 99px, 0) rotate(210deg);
                }
                &:nth-of-type(42) {
                  transform: translate3d(-13px, -5px, 0) rotate(5deg);
                }
                &:nth-of-type(43) {
                  transform: translate3d(86px, -26px, 0) rotate(50deg);
                }
                &:nth-of-type(44) {
                  transform: translate3d(28px, 65px, 0) rotate(57deg);
                }
                &:nth-of-type(45) {
                  transform: translate3d(20px, 73px, 0) rotate(119deg);
                }
                &:nth-of-type(46) {
                  transform: translate3d(8px, 42px, 0) rotate(142deg);
                }
                &:nth-of-type(47) {
                  transform: translate3d(7px, 28px, 0) rotate(133deg);
                }
                &:nth-of-type(48) {
                  transform: translate3d(86px, 55px, 0) rotate(293deg);
                }
                &:nth-of-type(49) {
                  transform: translate3d(-53px, 62px, 0) rotate(145deg);
                }
                &:nth-of-type(50) {
                  transform: translate3d(-11px, 24px, 0) rotate(245deg);
                }
              }
            }

            &:after {
              position: absolute;
              content: "";
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              height: 2px;
              width: 50%;
              background-image: ${(props) => props.theme.gradientV2};
              transition: all 0.3s ease;
              border-radius: 2px;
            }

            &:hover:after {
              height: 100%;
              z-index: -1;
              background-image: ${(props) => props.theme.gradientV2};
              width: 100%;
            }

            &-icon {
              margin-right: 0.4rem;

              img {
                height: 0.7rem;
                width: auto;
              }
            }

            &-text {
              text-transform: uppercase;
              font-family: brother-1816, sans-serif;
              font-size: 0.6rem;
              font-weight: bold;
            }
          }
        }

        &-left {
          width: calc(100% - 5.5rem);
          position: relative;

          &:hover .content__main--slider-left-rewardUnlockPos {
            .icon {
              transform: translateX(0) translateY(-1px);
            }

            .text {
              transform: translateX(0);
            }
          }

          &-rewardUnlockPos {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 1rem;
            width: auto;
            min-width: 1rem;
            display: flex;
            align-items: center;
            padding-left: 10px;
            overflow: hidden;
            user-select: none;

            &-unlocked {
              .icon {
                transform: translateX(0%) translateY(-1px) !important;
                opacity: 1 !important;
              }

              .text {
                transform: translateX(0%) !important;
                opacity: 1 !important;
              }

              .bar {
                display: none !important;
              }
            }

            .bar {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 3px;
              background-color: rgba(255, 255, 255, 0.85);
            }

            .icon {
              height: 0.55rem;
              width: auto;
              margin-right: 0.2rem;
              transform: translateX(-200%) translateY(-1px);
              transition: all 0.3s ease;
              opacity: 0.85;
            }

            .text {
              font-family: brother-1816, sans-serif;
              font-size: 0.5rem;
              font-weight: 500;
              text-transform: uppercase;
              letter-spacing: 0.5px;
              transform: translateX(-200%);
              transition: all 0.3s ease;
              opacity: 0.85;
            }
          }

          .sliderInput {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            -webkit-appearance: none;
            outline: 0;
            border: 0;
            border-radius: 10rem;
            height: 1rem;
            width: 100%;
            background: ${(props) => props.theme.primaryGradient};
            cursor: pointer;

            &::-webkit-slider-thumb {
              appearance: none;
              width: 1.4rem;
              height: 1.4rem;
              border-radius: 50%;
              background: #fff;
              cursor: pointer;
              outline: 5px solid rgba(255, 255, 255, 0.4);
            }

            &::-moz-slider-thumb {
              appearance: none;
              width: 1.4rem;
              height: 1.4rem;
              border-radius: 50%;
              background: #fff;
              cursor: pointer;
              outline: 5px solid rgba(255, 255, 255, 0.4);
            }
          }
        }
      }

      &--custom {
        margin-bottom: 1rem;
        background-color: transparent;
        font-family: brother-1816, sans-serif;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.7);
        border: none;
        outline: none;
        font-weight: 400;
        font-size: 0.82rem;
        position: relative;
        padding: 5px 0;
        transition: all 0.4s ease;
        display: inline-flex;
        align-items: flex-start;
        cursor: pointer;

        img {
          height: 1.2rem;
          width: auto;
          padding-right: 0.5rem;
        }

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          width: 40%;
          background: ${(props) => props.theme.primaryGradient};
          transition: all 0.4s ease;
          z-index: -1;
        }

        &:hover:before {
          width: 100%;
          height: 100%;
        }

        &:hover {
          color: #fff;
          padding: 5px 1rem;
        }
      }

      &--customKarma {
        margin-bottom: 2rem;

        input {
          width: 100%;
          padding: 0.7rem 1rem;
          background-color: #3a3a3c;
          border-radius: 0.5rem;
          border: none;
          outline: none;
          font-size: 1rem;
          font-weight: 400;
          color: rgba();
          font-family: ${(props) => props.theme.montserrat};
          transition: all 0.25s ease;
          border: 3px solid transparent;
          color: #fff;

          &::placeholder {
            font-weight: 400;
            color: rgba(255, 255, 255, 0.4);
            letter-spacing: 0.3px;
            font-size: 0.85rem;
          }

          &:focus {
            border: 3px solid #f88946;
          }

          &:hover {
            border: 3px solid #f88946;
          }
        }
      }
    }

    &__payment {
      margin-bottom: 2rem;
      padding: 1rem;
      background-color: #181818;
      border-radius: 0.5rem;
      border: 1px solid rgba(255, 255, 255, 0.09);

      &--title {
        text-transform: uppercase;
        font-size: 0.925rem;
        color: rgba(255, 255, 255, 0.65);
        font-weight: 500;
        margin-bottom: 1rem;
      }

      &--form {
        .fieldItem {
          padding: 0.6rem 1rem;
          border-radius: 0.35rem;
          background-color: #fff;
          margin-bottom: 0.5rem;
          border: 3px solid transparent;
          transition: all 0.3s ease;

          &:hover {
            border: 3px solid rgb(249, 154, 45);
          }
        }

        .react-switch-bg {
          svg {
            height: 30px;
            width: 30px;
          }

          span {
            color: #fff !important;
          }
          .yes {
            margin-left: 0.3rem !important;
          }
          .no {
            margin-left: 0.3rem !important;
          }
        }

        .toggleBox {
          margin-top: 0.8rem;
          margin-bottom: 2rem;
          display: flex;
          align-items: center;

          span {
            margin-left: 0.7rem;
            font-size: 0.75rem;
            color: #999;
          }
        }
      }

      &--savedCards {
        .item {
          &:not(:last-child) {
            margin-bottom: 0.7rem;
          }
        }
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      margin-top: 2rem;

      &--button {
        font-family: ${(props) => props.theme.montserrat};
        font-weight: 600;
        font-size: 0.95rem;
        padding: 0.7rem 2rem;
        border-radius: 10rem;
        background-color: #fff;
        color: ${(props) => props.theme.primaryColor1};
        text-transform: uppercase;
        border: none;
        outline: none;
        transition: all 0.5s ease;
        letter-spacing: 2px;

        &:hover {
          background-color: ${(props) => props.theme.primaryColor1};
          color: #fff;
          letter-spacing: 0;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      min-width: 95%;
      width: 95%;
      padding: 1.5rem 1rem;

      .close {
        top: 0.4rem;
        right: 0.4rem;
        height: 1.5rem;
        width: 1.5rem;

        img {
          height: 0.9rem;
        }
      }

      &__title {
        font-size: 0.95rem;
        margin-bottom: 1.2rem;

        .uppercase {
          padding-right: 0.5rem;
          font-size: 0.9rem;
        }
      }

      &__desc {
        padding: 0.8rem 1rem;
        border-radius: 0.3rem;
        font-size: 0.7rem;
      }

      &--slider {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;

        .sliderInput {
          -webkit-appearance: none;
          outline: 0;
          border: 0;
          border-radius: 10rem;
          width: calc(100% - 5.5rem);
          height: 1rem;
          background: linear-gradient(
            270deg,
            #e62117,
            #e62117,
            #e62117,
            #e62117,
            #d63d65,
            #d63d65,
            #e68231,
            #e68231,
            #f7ca50,
            #f7ca50,
            #1de9b6,
            #00e5ff
          );

          &::-webkit-slider-thumb {
            appearance: none;
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 50%;
            background: #fff;
            cursor: pointer;
            transition: background 0.15s ease-in-out;
            outline: 5px solid rgba(255, 255, 255, 0.4);

            &:hover {
              background: ${(props) => props.theme.primaryGradient};
            }
          }

          &::-moz-slider-thumb {
            appearance: none;
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 50%;
            background: #fff;
            cursor: pointer;
            transition: background 0.15s ease-in-out;
            outline: 5px solid rgba(255, 255, 255, 0.4);

            &:hover {
              background: ${(props) => props.theme.primaryGradient};
            }
          }
        }

        .sliderLabel {
          width: 4.5rem;
          height: 1.5rem;
          padding: 0 0.8rem;
          font-size: 0.7rem;

          img {
            height: 1.1rem;
          }
        }
      }

      &__payment {
        padding: 0.5rem;

        &--title {
          font-size: 0.85rem;
        }

        &--form {
          .fieldItem {
            padding: 0.4rem 1rem;
          }
        }
      }

      &__footer {
        &--button {
          font-size: 0.75rem;
          padding: 0.5rem 1.8rem;
        }
      }
    }
  }
`;
