import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { GlobalContext } from "../../context/GlobalContext";
import CloseIcon from "../icons/close.svg";
import Login from "./Login/Login";
import Signup from "./Signup/Signup";
import FirstStep from "./FirstStep";
import Agreement from "./Signup/Agreement";
import ForgotPassword from "./ForgotPassword";
import Logo from "../Elements/Logo";
import { AuthContext } from "../../context/AuthContext";

const AuthModel = () => {
  const { showAuthenticationModel, setShowAuthenticationModel } =
    useContext(GlobalContext);
  const {
    authState,
    setAuthState,
    signupStep,
    setSignupStep,
    TOTAL_STEPS,
    validationError,
    setValidationError,
    showAgreementPage,
    setShowAgreementPage,
  } = useContext(AuthContext);

  useEffect(() => {
    if (validationError) {
      scrollToTop();
    }
  }, [validationError]);

  const scrollToTop = () => {
    const element = document.getElementById("authContent");
    element.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <AuthModelStyled
      onClick={() => setShowAuthenticationModel(false)}
      className={`${
        showAuthenticationModel ? "showAuthenticationModel__Opened" : null
      }`}
    >
      {showAgreementPage && (
        <div onClick={(e) => e.stopPropagation()} className="agreementContent">
          <Agreement close={() => setShowAgreementPage(false)} />
        </div>
      )}

      {!showAgreementPage && (
        <div
          id="authContent"
          onClick={(e) => e.stopPropagation()}
          className="authContent"
        >
          <div className="authContent__header">
            <img
              onClick={() => setShowAuthenticationModel(false)}
              className="authContent__header--close"
              src={CloseIcon}
              alt=""
            />
            <div className="authContent__header--title">
              {authState === "SIGNUP" ? (
                <>
                  Signup Process (Step {signupStep}/{TOTAL_STEPS})
                </>
              ) : (
                <div className="authContent__header--logo">
                  <Logo />
                </div>
              )}
            </div>
          </div>
          {validationError && validationError.message && (
            <div
              onClick={() => setValidationError(null)}
              className="authContent__validation"
            >
              <div className="icon">
                <img src={CloseIcon} alt="" />
              </div>
              <p>{validationError.message}</p>
            </div>
          )}
          <div className="authContent__form">
            {authState === "CHECK_EMAIL" && (
              <FirstStep switchStep={setAuthState} />
            )}
            {authState === "LOGIN" && <Login switchStep={setAuthState} />}
            {authState === "SIGNUP" && (
              <Signup
                switchStep={setAuthState}
                progress={signupStep}
                setProgress={setSignupStep}
              />
            )}
            {authState === "FORGOT_PASSWORD" && (
              <ForgotPassword switchStep={setAuthState} />
            )}
          </div>
        </div>
      )}
    </AuthModelStyled>
  );
};

export default AuthModel;

const AuthModelStyled = styled.div`
  position: fixed;
  z-index: 900;
  height: calc(100vh - 3.5rem);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: all 0.5s ease;
  background: linear-gradient(
    180deg,
    rgba(56, 56, 56, 0.2),
    rgba(56, 56, 56, 0.2)
  );
  backdrop-filter: blur(3px);
  transform: translateY(150%);
  opacity: 0;
  scale: 0;

  .paddingBox {
    padding: 1rem 2rem;
  }

  .agreementContent {
    position: absolute;
    bottom: 0;
    top: 0;
    width: 80vw;
    left: 50%;
    transform: translateX(-50%);
    height: 100vh;
    background-color: #202020;
    overflow-y: auto;
    font-family: ${(props) => props.theme.montserrat}, sans-serif;
  }

  .authContent {
    position: absolute;
    bottom: 0;
    top: 0;
    width: 45vw;
    left: 50%;
    transform: translateX(-50%);
    height: 100vh;
    background-color: #202020;
    overflow-y: auto;
    font-family: ${(props) => props.theme.montserrat}, sans-serif;

    &__header {
      width: 100%;
      height: 3.5rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 999;
      background-color: #202020;

      &--logo {
        height: 2.5rem;
      }

      &--close {
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);
        height: 1rem;
        width: auto;
        display: none;
      }

      &--title {
        font-size: 1.2rem;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6);
      }
    }

    &__validation {
      padding: 0.5rem 3rem 0.5rem 1rem;
      background: ${(props) => props.theme.primaryGradient};
      position: relative;

      .icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 3rem;
        height: 100%;
        background-color: transparent;
        transition: all 0.3s ease;
        cursor: pointer;

        img {
          height: 1rem;
          width: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      p {
        font-size: 0.9rem;
        line-height: 1.3;
        font-weight: 400;
      }

      &:hover .icon {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  /* responsive */
  @media screen and (max-width: 768px) {
    .authContent {
      width: 100vw;

      &__header {
        &--close {
          display: block;
          cursor: pointer;
        }
      }
    }

    .agreementContent {
      width: 100vw;
    }
  }

  @media screen and (max-width: 480px) {
    .authContent {
      &__form {
        padding: 2rem 1rem;
      }
    }
  }
`;
