import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Error from "../../Elements/Error";
import Loader from "../../Elements/Loader";
import { getMyMoments } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import MomentCard from "../../Cards/MomentCard";
import { Row, Col } from "react-grid-system";
import EditMomentForm from "../../UploadMomentComponents/EditMomentForm";
import ConfirmationModel from "../../ConfirmationModel";
import { GlobalContext } from "../../../context/GlobalContext";
import { deleteMoment, getMyMomentsHasMore } from "../../../actions";
import { toast } from "react-toastify";
import { notificationMessage } from "../../../utils/toastNotifications";
import { MOMENTS_OTHER, MOMENT_SELF } from "../../../utils/menuOptions";
import { RESET_MOMENT } from "../../../actions/types";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";
const MomentsTab = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const isSelf = useSelector((state) => state.profile.isMe);
  const { loading, myMoments, error } = useSelector((state) => state.moments);
  const [selectedMoment, setSelectedMoment] = useState(null);
  const { ref: trackerRef, inView: trackerIsVisible } = useInView();
  const [momentId, setMomentId] = useState(null);
  const [momentPage, setMomentPage] = useState(1);
  const [momentLimit, setMomentLimit] = useState(3);

  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  const {
    setDeleteMomentConfirmationModel,
    setShowEditMomentModel,
    deleteMomentConfirmationModel,
  } = useContext(GlobalContext);

  const {
    moment: uploadMoment,
    pageSize: length,
    deleteMessage,
    deleteError,
    updateMessage,
    updateError,
  } = useSelector((state) => state.moment);

  useEffect(() => {
    if (deleteMessage) {
      toast.success(notificationMessage.momentDeleted);
    }

    if (deleteError) {
      toast.error(deleteError);
    }

    if (updateError) {
      toast.error(updateError);
    }

    if (updateMessage) {
      toast.success(notificationMessage.momentEdited);
      setShowEditMomentModel(false);
    }

    dispatch({
      type: RESET_MOMENT,
    });
  }, [deleteMessage, deleteError, updateError, updateMessage]);

  useEffect(() => {
    const momentObj = {
      page: momentPage,
      limit: momentLimit,
    };
    if (profile?.id) {
      dispatch(getMyMoments(profile?.id, momentObj));
    }
  }, [profile, deleteMessage, uploadMoment, updateMessage]);

  const deleteMomentHandler = () => {
    dispatch(deleteMoment(momentId));
    setDeleteMomentConfirmationModel(false);
  };

  useEffect(() => {
    if (trackerIsVisible) {
      setMomentPage(momentPage + 1);
      const momentObj = {
        page: momentPage,
        limit: momentLimit,
      };
      dispatch(getMyMomentsHasMore(profile?.id, momentObj));
    }
  }, [trackerIsVisible]);

  return (
    <>
      <MomentsTabStyled>
        {loading && <Loader text="Please wait..." />}

        {!loading && error && <Error text="No moments found!" />}

        {!loading && !myMoments?.length && <Error text="No moments found!" />}

        {!loading && myMoments && myMoments.length > 0 && (
          <Row>
            {myMoments.map((item, i) => (
              <Col
                key={i}
                lg={3}
                md={3}
                sm={4}
                xs={4}
                style={{
                  paddingLeft: isMobile ? "4px" : "10px",
                  paddingRight: isMobile ? "4px" : "10px",
                }}
              >
                <MomentCard
                  item={item}
                  key={i}
                  isSelf={isSelf}
                  setSelectedMoment={setSelectedMoment}
                  setMomentId={setMomentId}
                  // menuOptions={profile?.isMe ? MOMENT_SELF : MOMENTS_OTHER}
                  menuOptions={isSelf ? MOMENT_SELF : MOMENTS_OTHER}
                />
              </Col>
            ))}
          </Row>
        )}
      </MomentsTabStyled>
      {<div ref={trackerRef} className="tracker" />}
      {/* edit moment form */}
      {selectedMoment && momentId && (
        <EditMomentForm
          selectedMoment={selectedMoment}
          close={() => setShowEditMomentModel(false)}
        />
      )}

      {/* delete moment confirmation model */}
      {selectedMoment && deleteMomentConfirmationModel && (
        <ConfirmationModel
          closeHandler={() => setDeleteMomentConfirmationModel(false)}
          title="Are you sure you want to delete this Moment ? You can't undo this action."
          deleteHandler={deleteMomentHandler}
        />
      )}
    </>
  );
};

export default MomentsTab;

const MomentsTabStyled = styled.div`
  width: 90%;
  margin: 0 auto;
  min-height: 100vh;
  padding: 2.5rem 0;
  font-family: ${(props) => props.theme.montserrat};

  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 1rem 0;
  }
`;
