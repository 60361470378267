import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import Slider from "react-slick";
import FeaturedCreatorCard from "../../Cards/FeaturedCreatorCard";
import VideoPlayer from "../../Models/VideoPlayer";

const SliderSettings = {
  arrows: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplaySpeed: 3000,
  centerPadding: "130px",
  centerMode: true,
  speed: 500,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 601,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "30px",
      },
    },
    {
      breakpoint: 481,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "30px",
      },
    },
  ],
};

const Creators = ({ data }) => {
  const [midDrag, setMidDrag] = useState(false);
  const [showIntro, setShowIntro] = useState(false);
  const [introUrl, setIntroUrl] = useState(null);

  if (!data) {
    return null;
  }

  return (
    <>
      <CreatorsStyled>
        <div className="title">
          <div className="title__sub">They make it work</div>
          <div className="title__main">
            Meet our <br /> <div className="title__main--focus">creators</div>
          </div>
        </div>
        <div className="creators">
          <Slider
            className="creators__slider"
            {...SliderSettings}
            beforeChange={() => setMidDrag(true)}
            afterChange={() => setMidDrag(false)}
          >
            {data.map((item, i) => (
              <div key={i} className="creators__slider--item">
                <FeaturedCreatorCard
                  item={item}
                  setShowIntro={setShowIntro}
                  setIntroUrl={setIntroUrl}
                />
              </div>
            ))}
          </Slider>
        </div>
      </CreatorsStyled>

      <VideoPlayer
        isOpen={showIntro}
        onClose={() => setShowIntro(false)}
        url={introUrl}
      />
    </>
  );
};

export default Creators;

const backgroundPan = keyframes`
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
`;

const CreatorsStyled = styled.div`
  padding: 2rem 0;
  border-bottom: 1px solid rgba(242, 242, 247, 0.2);
  position: relative;

  .title {
    margin: 0 auto;
    margin-bottom: 2.5rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-family: brother-1816, sans-serif;

    &__sub {
      font-size: 0.7rem;
      margin-bottom: 0.65rem;
      opacity: 0.75;
    }

    &__main {
      font-size: 2.75rem;
      font-weight: 800;
      line-height: 1;

      &--focus {
        background: linear-gradient(
          to right,
          ${(props) => props.theme.primaryColor1},
          ${(props) => props.theme.primaryColor2},
          ${(props) => props.theme.primaryColor1}
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: all 0.25s ease;
        animation: ${backgroundPan} 3s linear infinite;
        background-size: 200%;
      }
    }
  }

  .creators {
    &__slider {
      &--item {
        padding: 0 1rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .title {
      &__main {
        font-size: 2.25rem;
      }
    }
  }
`;
