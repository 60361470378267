import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Input from "../../../components/Elements/Input";
import Button from "../../../components/Elements/Button";
import { Row, Col } from "react-grid-system";

import { addSubCategoryOne } from "../../../actions";

const AddSubCategoryOne = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    subCategType: "",
    videoCategoryId: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = () => {
    dispatch(addSubCategoryOne(formData));
    onClose();
  };

  return (
    <AddSubCategoryOneModal open={isOpen}>
      <AddSubCategoryOneContent>
        <div className="title">Add Video Category</div>
        <div className="field">
          <Row className="row row__one">
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Id"
                  name="id"
                  id="id"
                  placeholder="Id"
                  type="number"
                  value={formData.id}
                  formData
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="subCategType"
                  name="subCategType"
                  id="subCategType"
                  placeholder="subCategType"
                  type="number"
                  value={formData.subCategType}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="Name"
                  name="name"
                  id="name"
                  placeholder="name"
                  type="text"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: "10px" }}>
              <div className="field">
                <Input
                  label="videoCategoryId"
                  name="videoCategoryId"
                  id="videoCategoryId"
                  placeholder="videoCategoryId"
                  type="number"
                  value={formData.videoCategoryId}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Row className="row row__one">
          <Col md={6} sm={6} xs={6}>
            <Button mode={"secondary"} className="submit" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <Button mode={"primary"} className="submit" onClick={handleSubmit}>
              Save
            </Button>
          </Col>
        </Row>
      </AddSubCategoryOneContent>
    </AddSubCategoryOneModal>
  );
};

export default AddSubCategoryOne;

const AddSubCategoryOneModal = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddSubCategoryOneContent = styled.div`
  background: #2c2c2e;
  color: white;
  border: 1px solid white;
  padding: 20px;
  max-width: 800px;
  width: 100%;

  .title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .submit {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdownBox {
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    margin-top: 3px;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;
