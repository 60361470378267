import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import BigText from "../../StyleComponents/BigText";
import Paragraph from "../../StyleComponents/Paragraph";
import VideoPlayer from "../../../Models/VideoPlayer";
import config from "../../../../config";
import { useSelector } from "react-redux";

const AboutUs = ({ data }) => {
  const { data: siteBranding } = useSelector((state) => state.branding);
  const [playIntroVideo, setPlayIntroVideo] = useState(false);

  if (!data) {
    return null;
  }

  return (
    <>
      <AboutUsStyled>
        <div className="content">
          <div className="content__left">
            <div
              onClick={() => setPlayIntroVideo(!playIntroVideo)}
              className="video"
            >
              <div className="video__thumbnail">
                <img
                  src={config.aws.CLOUDFRONT_URL + data?.thumbnail}
                  alt={data?.title}
                />
              </div>

              <div className="video__overlay"></div>

              <div className="playBtn">
                <img src="/assets/icons/play.svg" alt="Play Video" />
              </div>
            </div>
          </div>
          <div className="content__right">
            <BigText className="content__right--title">
              <div className="imp">{siteBranding?.site_name}:</div>{" "}
              {data?.title}
            </BigText>

            <Paragraph className="content__right--text">
              {data?.first_paragraph}
            </Paragraph>

            <Paragraph className="content__right--text">
              {data?.second_paragraph}
            </Paragraph>

            <button
              href={`${data?.button_url}`}
              className="content__right--button"
            >
              {data?.button_text}
            </button>
          </div>
        </div>
      </AboutUsStyled>

      <VideoPlayer
        isOpen={playIntroVideo}
        onClose={() => setPlayIntroVideo(false)}
        url={data?.video_url}
      />
    </>
  );
};

export default AboutUs;

const ripple = keyframes`
  0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
`;

const AboutUsStyled = styled.div`
  .content {
    padding-bottom: 7rem;
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left,
    &__right {
      width: 50%;
    }

    &__left {
      .video {
        width: 100%;
        height: 23rem;
        border-radius: 0.5rem;
        position: relative;
        overflow: hidden;

        &__thumbnail {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;

          img {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
          }
        }

        &__overlay {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.25);
        }

        .playBtn {
          position: absolute;
          top: 50%;
          left: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 5rem;
          height: 5rem;
          z-index: 2;
          -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
          background: ${(props) => props.theme.primaryGradient};
          border-radius: 50%;
          color: #fff;
          -webkit-box-shadow: 15px 25px 35px rgba(38, 42, 55, 0.2);
          box-shadow: 15px 25px 35px rgba(38, 42, 55, 0.2);
          cursor: pointer;

          &::after,
          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid #fff;
          }

          &::before {
            -webkit-animation: ${ripple} 2s linear infinite;
            animation: ${ripple} 2s linear infinite;
          }

          &::after {
            -webkit-animation: ${ripple} 2s linear 1s infinite;
            animation: ${ripple} 2s linear 1s infinite;
          }

          img {
            height: 1.8rem;
            width: 1.8rem;
          }
        }
      }
    }

    &__right {
      padding: 0 2rem;

      &--title {
        font-size: 2.2rem;
        color: #fff;
        font-style: normal;
        margin-bottom: 1.5rem;
        line-height: 1.3;

        .imp {
          background: -webkit-${(props) => props.theme.primaryGradient};
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      &--text {
        color: rgba(255, 255, 255, 0.7);
        font-weight: 300;
        line-height: 1.5;
        margin-bottom: 1.5rem;
      }

      &--button {
        background-color: transparent;
        font-family: brother-1816, sans-serif;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.7);
        border: none;
        outline: none;
        font-weight: 400;
        font-size: 0.95rem;
        position: relative;
        padding: 5px 0;
        transition: all 0.4s ease;

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          width: 25%;
          background: ${(props) => props.theme.primaryGradient};
          transition: all 0.4s ease;
          z-index: -1;
        }

        &:hover:before {
          width: 100%;
          height: 100%;
        }

        &:hover {
          color: #fff;
          padding: 5px 1rem;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      flex-direction: column;
      align-items: flex-start;

      &__left,
      &__right {
        width: 100%;
      }

      &__left {
        margin-bottom: 2rem;

        .video {
          height: 18rem;
        }
      }

      &__right {
        padding: 0;
      }
    }
  }
`;
