import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ADD_NEW_PAYMENT_METHOD, SET_DEFAULT_PAYMENT_METHOD } from '../../../actions/types';
import useInput from '../../../hooks/useInput';
import api from '../../../services/api';
import { darkTheme } from '../../../styles/theme';
import { notificationMessage } from '../../../utils/toastNotifications';

const EditCreditCardForm = ({ currentPaymentMethod, isDefault, close, setShowPaymentModal, setAsDefaultPM }) => {
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [defaultPM, setDefaultPM] = useState(isDefault);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const firstName = useInput(
    currentPaymentMethod?.billing_details?.name?.split(" ")[0] || ""
  );
  const lastName = useInput(
    currentPaymentMethod?.billing_details?.name?.split(" ")[1] || ""
  );

  const cardStyle = {
    style: {
      base: {
        color: darkTheme.white,
        fontFamily: "ingra, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: darkTheme.white,
        },
      },
      invalid: {
        color: darkTheme.red,
        iconColor: darkTheme.red,
      },
    },
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (!stripe || !elements) return;
      setIsLoading(true);
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: `${firstName.value.trim()} ${lastName.value.trim()}`,
        },
      });
      if (result.error) {
        toast.error(notificationMessage.incorrectPaymentDetails);
        setIsLoading(false);
        setError(result.error);
        return;
      }
      const addNewPaymentMethodResposne = await api.post(
        "stripe/addNewPaymentMethod",
        {
          paymentMethodId: result.paymentMethod.id,
          paymentMethodToDeleteId: currentPaymentMethod.id, // When credit card
          defaultPM,
        }
      );
      dispatch({
        type: ADD_NEW_PAYMENT_METHOD,
        payload: addNewPaymentMethodResposne.data,
      });
      if (defaultPM) {
        setAsDefaultPM(result.paymentMethod.id);
      }

      setShowPaymentModal(addNewPaymentMethodResposne.data);

      setIsLoading(false);
      toast.success(notificationMessage.paymentDetailsUpdated);
      close(true);
    } catch (e) {
      setIsLoading(false);
      toast.error(notificationMessage.somethingWentWrong);
    }
  };
  if (!stripe || !elements) return "Loading Stripe...";
  return (
    <Container id="add-card-form">
      <form id="add-card-form" onSubmit={handleSubmit}>
        <div className="card-element-row">
          <input
            type="text"
            placeholder="First name"
            value={firstName.value}
            onChange={firstName.onChange}
            className="card-one name-section"
          />
          <input
            type="text"
            placeholder="Last name"
            value={lastName.value}
            onChange={lastName.onChange}
            className="card-two name-section"
          />
        </div>

        <CardNumberElement options={cardStyle} className="card-element" />
        <div className="card-element-row">
          <CardExpiryElement options={cardStyle} className="card-one" />
          <CardCvcElement options={cardStyle} className="card-two" />
        </div>
        {error && (
          <ErrorComponent>
            <i className="fas fa-exclamation-circle" />
            {error.message}
          </ErrorComponent>
        )}
        <div>
          <label className="toggleBox">
            <Switch
              onColor="#f88b44"
              offColor="#1c1c1e"
              onChange={() => setDefaultPM(!defaultPM)}
              checked={defaultPM}
            />
            <span>Set as default Card</span>
          </label>
        </div>
        <div type="submit" style={{ marginTop: "10px" }}>
          <button className="stripe-submit" disabled={isLoading}>
            {isLoading ? "Processing..." : "Save"}
          </button>
        </div>
      </form>
    </Container>
  );
};

export default EditCreditCardForm;

const Container = styled.div`
  .card-element {
    border-radius: 3px;
    padding: 10px 20px;
    width: 100%;
    background: ${(props) => props.theme.black};
    border: 1px solid ${(props) => props.theme.black};
    border-radius: 10rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .name-section {
    margin-bottom: 0 !important ;
    color: ${(props) => props.theme.white} !important;
  }
  .card-element-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .card-one,
    .card-two {
      width: 48%;
      border-radius: 3px;
      padding: 10px 20px;
      background: ${(props) => props.theme.black};
      border: 1px solid ${(props) => props.theme.black};
      color: #bbc5cd;
      border-radius: 10rem;
      margin-bottom: 1rem;
    }
  }

  .stripe-submit {
    width: 100%;
    margin: 0 0 1rem 0;
    border-radius: 10rem;
    padding: 0.7rem 0;
    font-size: 1.1rem;
    color: #fff;
    background-color: ${(props) => props.theme.orange};
    border: none;
    outline: none;
  }
  .note {
    color: #fde5b8;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.2;
  }
  /* toggle */
  .react-switch-bg {
    svg {
      height: 30px;
      width: 30px;
    }

    span {
      color: #fff !important;
    }
    .yes {
      margin-left: 0.3rem !important;
    }
    .no {
      margin-left: 0.3rem !important;
    }
  }

  .toggleBox {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;

    span {
      margin-left: 0.7rem;
      font-size: 0.9rem;
      line-height: 1.2;
      color: #999;
    }
  }
`;

const ErrorComponent = styled.h2`
  font-size: 0.8rem;
  color: #fff;
  padding: 0.4rem 1rem;
  border-radius: 0.3rem;
  margin-bottom: 1rem;

  i {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
`;
