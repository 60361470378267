import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import MarketingBanner from "../components/NewHomeComponents/MarketingBanner/MarketingBanner";
import HomeCategoryFilter from "../components/HomeCategoryFilter";
import Moments from "../components/NewHomeComponents/Moments/Moments";
import Clips from "../components/NewHomeComponents/Clips/Clips";
import io from "socket.io-client";
import FeaturedHomeVideos from "../components/NewHomeComponents/FeaturedSection/Videos";
import FeaturedSeries from "../components/NewHomeComponents/FeaturedSection/Series";
import FeaturedThreads from "../components/NewHomeComponents/FeaturedSection/Threads";
import FeaturedCreators from "../components/NewHomeComponents/FeaturedSection/Creators";
import config from "../config";
import Helmet from "react-helmet";
import OwlLogo from ".././assets/Freeloderlogo.png";
import { useLocation } from "react-router-dom";
import { RESET_GET_ALL_MOMENTS } from "../actions/types";
import { useDispatch, useSelector } from "react-redux";
import { setAuthHeader } from "../services/api";
import { STATIC_CATEGORIES } from "../utils/common";
import {
  getMarketingBanners,
  getVideos,
  getFeatured,
  getTrendingVideos,
  getStaffPickVideos,
  getAllMoments,
  getUserStorageInfo,
  getAllActiveUser,
  getGuestMoments,
  getFeaturedSeries,
  getVideosV2,
  clearFilteredVideos,
  getHomeFeaturedCreators,
  getFeaturedVideosForSelectedCategories,
} from "../actions";
import {
  getHomeFeaturedThreads,
  getHomeFeaturedThreadsGuest,
} from "../actions/thread";
import { toast } from "react-toastify";
import { GlobalContext } from "../context/GlobalContext";
import { notificationMessage } from "../utils/toastNotifications";
import { useFetch } from "../hooks/useFetch";

// skeleton parts
import MarketingBannerSkeleton from "../skeletons/Home/MarketingBanner";
import FeaturedVideosSkeleton from "../skeletons/General/Section";
import FeaturedMomentsSkeleton from "../skeletons/General/Section";
import FeaturedCreatorSkeleton from "../skeletons/General/Section";
import FeaturedSeriesSkeleton from "../skeletons/General/Section";
import FeaturedThreadsSkeleton from "../skeletons/General/Section";
import VideosSkeleton from "../skeletons/General/Section";
import CategoryFilterSkeleton from "../skeletons/Home/CategoryFilter";
import DynamicMetaTags from "./DynamicMetaTags";
const ENDPOINT = config.sockets.socketBackendUrl;
let socket;

const NewHome = () => {
  const dispatch = useDispatch();
  const routePath = useLocation();
  const {
    moments,
    loading: momentsLoading,
    error: momentsError,
  } = useSelector((state) => state.moments);
  const video = useSelector((state) => state.video);
  const featuredVideos = useSelector((state) => state.featured);
  const { featuredSeries, loading: featuredSeriesLoading } = useSelector(
    (state) => state.featuredSeries
  );
  const { featuredThreads, loading: featuredThreadsLoading } = useSelector(
    (state) => state.featuredThreads
  );
  const { data: featuredCreators, loading: featuredCreatorsLoading } =
    useSelector((state) => state.featuredCreators);
  const { updateSuccess: profileUpdateSuccess } = useSelector(
    (state) => state.profile
  );

  const {
    moment: uploadMoment,
    deleteMessage,
    deleteError,
    updateMessage,
    updateError,
  } = useSelector((state) => state.moment);

  const {
    isFetching: videosIsFetching,
    videos,
    hasMore: videosHasMore,
    filteredVideos,
    isFiltering,
  } = useSelector((state) => state.videos);

  const { setShowEditMomentModel, setShowUploadModel } =
    useContext(GlobalContext);
  const onTop = () => {
    window.scrollTo(0, 0);
  };

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [currentMainCategory, setCurrentMainCategory] = useState({
    id: -1,
    name: "ALL",
  });
  const [currentStaticFilterId, setCurrentStaticFilterId] = useState(
    STATIC_CATEGORIES.ALL
  );
  const [clearSubFilters, setClearSubFilters] = useState(false);
  const banners = useSelector((state) => state.marketingBanner);
  const user = useSelector((state) => state.user);

  // videos state
  const [videoPage, setVideoPage] = useState(1);
  const [videoLimit, setVideoLimit] = useState(8);

  // categories
  const { loading: filterCategoriesLoading, data: filterCategories } = useFetch(
    `${config.api.url}videos/category`
  );

  const handleCategory = (mainCategory) => {
    setCurrentStaticFilterId(null);
    if (currentMainCategory !== mainCategory) {
      // reset moment state
      dispatch({
        type: RESET_GET_ALL_MOMENTS,
      });
    }
    setCurrentMainCategory(mainCategory);
    const filtered = selectedCategory.filter(
      (sc) => sc.videoCategoryId === mainCategory.id
    );
    setSelectedCategory([
      ...filtered,
      {
        videoCategoryId: mainCategory.id,
        subCategOneId: null,
        subCategTwoId: null,
      },
    ]);
  };

  useEffect(() => {
    if (profileUpdateSuccess === true) {
      dispatch(getFeaturedSeries());
      dispatch(getFeatured());
      dispatch(getVideos(user));
    }
  }, [profileUpdateSuccess]);

  useEffect(() => {
    setAuthHeader();
    dispatch(getAllActiveUser());
  }, []);

  useEffect(() => {
    dispatch(getFeaturedVideosForSelectedCategories(selectedCategory));
    // dispatch(getVideos(user, selectedCategory));
  }, [user, selectedCategory]);

  useEffect(() => {
    dispatch(clearFilteredVideos());
    setVideoPage(1);
    const videoObj = {
      page: selectedCategory.length ? 1 : videoPage,
      limit: videoLimit,
      categoryList: selectedCategory,
    };
    dispatch(getMarketingBanners(selectedCategory));
    dispatch(getVideosV2(videoObj));
  }, [selectedCategory]);

  useEffect(() => {
    if (!banners?.banners?.length) {
      dispatch(getMarketingBanners(selectedCategory));
    }
  }, [banners]);

  useEffect(() => {
    onTop();
  }, [routePath]);
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user && user.id) {
          dispatch(getUserStorageInfo());
          dispatch(getAllMoments(1, 10, selectedCategory));
        } else {
          await dispatch(getGuestMoments(5, null, selectedCategory));
        }
      } catch (error) {}
    };

    fetchData();
  }, [user, selectedCategory, dispatch]);

  useEffect(() => {
    dispatch(getFeaturedSeries(selectedCategory));
    dispatch(getHomeFeaturedCreators());
    if (!featuredVideos?.length) {
      if (selectedCategory?.length) {
        dispatch(getFeaturedVideosForSelectedCategories(selectedCategory));
      } else dispatch(getFeatured());
    }

    if (!user?.id) {
      dispatch(getHomeFeaturedThreadsGuest(selectedCategory));
    }

    if (user?.id) {
      dispatch(getHomeFeaturedThreads(selectedCategory));
    }
  }, [user, selectedCategory]);

  useEffect(() => {
    if (user.username) {
      socket = io(ENDPOINT, { transports: ["websocket"] });
      socket.on("connect", () => {
        socket.emit("userInfo", user, socket.id);
        socket.emit("userStatus", user);
        console.log("Socket connected");
      });
      socket.on("disconnect", () => {
        console.log("Socket Disconnected");
      });
    }
  }, []);
  useEffect(() => {
    if (deleteMessage) {
      toast.success(notificationMessage.momentDeleted);
    }

    if (deleteError) {
      toast.error(deleteError);
    }

    if (updateError) {
      toast.error(updateError);
    }

    if (updateMessage) {
      toast.success(notificationMessage.momentEdited);
      setShowEditMomentModel(false);
      setShowUploadModel(false);
    }
  }, [deleteMessage, deleteError, updateError, updateMessage]);

  const getAllVideos = () => {
    setCurrentStaticFilterId(STATIC_CATEGORIES.ALL);
    setSelectedCategory([]);
    setCurrentMainCategory([]);
    setClearSubFilters(true);
  };

  const getFeaturedVideos = () => {
    setCurrentStaticFilterId(STATIC_CATEGORIES.FEATURED);
    dispatch(getFeaturedVideosForSelectedCategories(selectedCategory));
    setCurrentMainCategory([]);
    setClearSubFilters(true);
  };

  const getTrending = () => {
    setCurrentStaticFilterId(STATIC_CATEGORIES.TRENDING);
    dispatch(getTrendingVideos());
    setCurrentMainCategory([]);
    setClearSubFilters(true);
  };

  const getStaffPicks = () => {
    setCurrentStaticFilterId(STATIC_CATEGORIES.STAFF_PICKS);
    dispatch(getStaffPickVideos());
    setCurrentMainCategory([]);
    setClearSubFilters(true);
  };

  const loadMoreVideosHandler = () => {
    setVideoPage(videoPage + 1);
    const videoObj = {
      page: videoPage + 1,
      limit: videoLimit,
      categoryList: selectedCategory,
    };

    dispatch(getVideosV2(videoObj));
  };
  return (
    <>
      <DynamicMetaTags
        title="Freeloader"
        description="Isutra"
        imageUrl="https://isutra.online/static/media/Freeloderlogo.a8f27e10.png"
      />
      {/* category filter */}
      {filterCategoriesLoading && !filterCategories?.data ? (
        <HomeSkeletonStyled>
          <CategoryFilterSkeleton />
        </HomeSkeletonStyled>
      ) : (
        <HomeCategoryStyled>
          <HomeCategoryFilter
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            currentMainCategory={currentMainCategory}
            handleCategory={handleCategory}
            getFeaturedVideos={getFeaturedVideos}
            currentStaticFilterId={currentStaticFilterId}
            getTrendingVideos={getTrending}
            getAllVideos={getAllVideos}
            clearSubFilters={clearSubFilters}
            getStaffPicks={getStaffPicks}
            categoryList={filterCategories}
          />
        </HomeCategoryStyled>
      )}
      <Helmet>
        <title>{video.title}</title>
        <meta name="title" content="Freeloader" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Freeloader" />
        <meta property="og:image" content={OwlLogo} />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:image" content={OwlLogo} />
        <meta property="twitter:title" content="Freeloader" />
      </Helmet>

      {/* marketing banner */}
      {!banners?.banners?.length ? (
        <MarketingBannerSkeleton />
      ) : (
        <MarketingBanner banners={banners.banners} />
      )}

      {/* feature creators */}
      <HomeStyled></HomeStyled>

      {featuredCreatorsLoading ? (
        <FeaturedVideosSkeleton sectionName="featured_moments" />
      ) : (
        <HomeStyled>
          <FeaturedCreators data={featuredCreators} />
        </HomeStyled>
      )}

      {/* feature videos */}
      {featuredVideos.featuredFetching ? (
        <FeaturedVideosSkeleton sectionName="featured_videos" />
      ) : (
        <HomeStyled>
          <FeaturedHomeVideos videos={featuredVideos} />
        </HomeStyled>
      )}

      {/* featured series */}
      {featuredSeriesLoading ? (
        <FeaturedSeriesSkeleton sectionName="featured_series" />
      ) : (
        <FeaturedSeries series={featuredSeries} />
      )}

      {/* featured threads */}
      {featuredThreadsLoading && !featuredThreads?.length ? (
        <FeaturedThreadsSkeleton sectionName="featured_series" />
      ) : (
        <FeaturedThreads threads={featuredThreads} />
      )}

      {/* featured moments */}
      {momentsLoading ? (
        <FeaturedMomentsSkeleton sectionName="featured_moments" />
      ) : (
        <Moments moments={moments} />
      )}

      {/* videos */}
      {videosIsFetching && !videos?.length ? (
        <HomeSkeletonStyled last>
          <VideosSkeleton sectionName="videos" />
        </HomeSkeletonStyled>
      ) : (
        <HomeStyled last>
          <Clips
            loadMoreHandler={loadMoreVideosHandler}
            isFetching={videosIsFetching}
            videos={isFiltering ? filteredVideos : videos}
            hasMore={videosHasMore}
            isFiltered={isFiltering}
          />
        </HomeStyled>
      )}
    </>
  );
};

export default NewHome;

const HomeCategoryStyled = styled.div`
  margin-top: 60px;
`;
const HomeSkeletonStyled = styled.div`
  margin-top: 3.75rem;
  padding: 0.5rem 0 0.5rem 0;
  margin-bottom: ${(props) => (props.last ? "5rem" : null)};
`;

const HomeStyled = styled.div`
  margin-bottom: ${(props) => (props.last ? "5rem" : null)};
`;
