import React from "react";
import styled, { keyframes } from "styled-components";
import CaretDownIcon from "../../../assets/Icons/caret-down.svg";

const InfoModal = ({ item, close, closeRef }) => {
  console.log("item");
  return (
    <InfoModalStyled ref={closeRef}>
      <div onClick={close} className="header">
        <img src={CaretDownIcon} alt="" className="closeIcon" onClick={close} />
        <div className="title">{item.displayName}</div>
      </div>
      <div className="description">{item.description}</div>
    </InfoModalStyled>
  );
};

export default InfoModal;

const openAnimation = keyframes`
  from {
    transform: translateY(5rem);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const InfoModalStyled = styled.div`
  text-align: center;
  position: absolute;
  bottom: 10%;
  left: 19%;
  z-index: 5;
  width: 63%;
  max-width: 500px;
  ${"" /* height: calc(92% - 4rem); */}
  background-color: #1c1c1e;
  border-radius: 0.5rem;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  animation: ${openAnimation} 0.35s ease-out;
  border: 1px solid rgba(112, 112, 112, 0.25);
  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .select {
      margin-top: 11px;
      font-family: ingra, sans-serif;
      font-size: 1rem;
      border-color: -internal-light-dark(
        rgb(118, 118, 118),
        rgb(133, 133, 133)
      );
      border-width: 2px;
      border-style: inset;
      padding: 1px 0 1px 0;
      background-color: field;
    }
  }
  .description {
    text-align: left;
  }

  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(112, 112, 112, 0.5);
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    cursor: pointer;

    .closeIcon {
      height: 0.5rem;
      width: auto;
      margin-right: 1rem;
    }

    .title {
      font-size: 0.9rem;
      font-weight: 500;
      text-transform: capitalize;
      color: #f2f2f7;
    }
  }

  .options {
    width: 100%;

    &__item {
      padding: 0.5rem 0;
      font-size: 0.85rem;
      font-weight: 600;
      color: #636366;
      border-bottom: 2px solid rgba(99, 99, 102, 0.5);
      cursor: pointer;
    }

    .active {
      color: #f2f2f7;
    }
  }

  @media screen and (max-width: 480px) {
    width: 95%;
    bottom: 10%;
    left: 5%;

    .header {
      .title {
        font-size: 0.8rem;
      }
    }

    .options {
      &__item {
        padding: 0.5rem 0;
        font-size: 0.8rem;
      }
    }
  }
`;
