import React, { useContext, useEffect, useState } from "react";
import MenuOptions from "./MenuOptions";
import Moment from "react-moment";
import CloseIcon from "../../assets/Icons/close.svg";
import { toast } from "react-toastify";
import { removeContentFromElement } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { notificationMessage } from "../../utils/toastNotifications";
import { useHistory } from "react-router-dom";
import { CardStyles } from "./CardStyles";
import { addDefaultImage } from "../../utils";
import ImageNotfoundPlaceholder from "../../assets/image_notfound_placeholder.svg";
import config from "../../config";
import RewardIcon from "../icons/reward.svg";
import {
  ADD_TO_STREAMS_OPTION,
  REMOVE_FROM_STREAMS_OPTION,
  VIDEO_OTHER,
} from "../../utils/menuOptions";
import { GlobalContext } from "../../context/GlobalContext";

const VideoCard = ({
  item,
  removeable,
  elementId,
  menuOptions,
  isSelf,
  setVideoId,
  setSelectedVideo,
  midDrag,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { showGlobalPlaylistModel, setShowGlobalPlaylistModel } = useContext(GlobalContext)

  const userChannels = useSelector((state) => state.user.channels);
  const VIDEOOPTIONS = [
    { icon: "", text: "Manage Video" },
    { icon: "", text: "Share Video" },
  ];
  const [clipOptions, setClipOptions] = useState(menuOptions);

  useEffect(() => {
    if (!isSelf) {
      const isSubscribedToUser = !userChannels
        ? false
        : userChannels.find((channel) => channel.id == item.User.id);
      if (isSubscribedToUser) {
        setClipOptions([...VIDEO_OTHER, REMOVE_FROM_STREAMS_OPTION]);
      } else {
        setClipOptions([...VIDEO_OTHER, ADD_TO_STREAMS_OPTION]);
      }
    }
  }, [userChannels]);

  const toHHMMSS = (secs) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    const result = [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");

    return (
      result +
      `${hours && !minutes ? " hour" : minutes && !hours ? " min" : " sec"}`
    );
  };

  const isBadgeOrAvatar = () => {
    const image = item?.User?.avatar
      ? item.User.avatar
      : item?.User?.badge
      ? item.User.badge
      : item?.userSettings?.VisitorBadge?.imgPath
      ? item.userSettings.VisitorBadge.imgPath
      : null;

    if (image && (image.includes(".png") || image.includes(".svg"))) {
      return (
        <div className="imageBadge">
          <img className="badge" src={image} alt="" />
        </div>
      );
    } else {
      return (
        <img
          onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
          className="imageAvatar"
          src={config.aws.CLOUDFRONT_URL + image}
          alt=""
        />
      );
    }
  };

  return (
    <CardStyles metaPadding={item?.HasRewards ? false : true}>
      {
        <div className="ribbon">
          <p>
            {item?.VideoAccessOverlay?.name}
            <span>
              {+item?.amount > 0 ? ` ($${(+item?.amount).toFixed(2)}) ` : ""}
            </span>
          </p>
        </div>
      }
      <div
        onClick={(e) => {
          e.stopPropagation();

          if (midDrag) {
            e.stopPropagation();
            return;
          }
          showGlobalPlaylistModel && setShowGlobalPlaylistModel(false)
          history.push(`/watch/${item.id}`);
        }}
        className={`thumbnail`}
      >
        <img
          onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
          src={config.aws.CLOUDFRONT_URL + item?.thumbnail}
          alt={item?.title}
        />

        <div className="length">
          <span>{toHHMMSS(item?.videoLength)}</span>
        </div>

        {item?.views && item.views > 0 && (
          <div className="views">
            {item.views + " "}
            Views
          </div>
        )}
      </div>

      {item?.HasRewards ? (
        <div className="rewards">
          <img src={RewardIcon} alt="" className="rewards__icon" />
          <span className="rewards__text">Contains Rewards</span>
        </div>
      ) : null}

      <div className="metaInfo">
        <div className="metaInfo__left">
          {!isSelf && (
            <div
              onClick={() => {
                history.push(`/channel/${item?.User?.username}`);
              }}
              className="metaInfo__left--avatar"
            >
              {isBadgeOrAvatar()}
            </div>
          )}

          <div className={`metaInfo__left--videoMeta `}>
            <div
              onClick={() => {
                showGlobalPlaylistModel && setShowGlobalPlaylistModel(false)
                history.push(`/watch/${item.id}`);
              }}
              className="title"
            >
              {item?.title}
            </div>

            <div className="seperatorInfo">
              <div
                onClick={() => {
                  history.push(`/channel/${item?.User?.username}`);
                }}
                className="bold"
              >
                {item?.User?.username}
              </div>

              <div className="seperator"></div>

              <div className="light">
                <Moment format="MMMM Do YYYY">{item?.createdAt}</Moment>
              </div>

              {/* location info for other users */}
              {!isSelf && item?.User?.userSettings && (
                <>
                  <div className="seperator"></div>
                  <div className="boldV2">
                    {item?.User?.userSettings?.outOfThisWorld
                      ? "Out of This World"
                      : `${item?.User?.userSettings?.country} ${
                          item?.User?.userSettings?.city &&
                          `, ${item.User.userSettings.city}`
                        } ${
                          item?.User?.userSettings?.state &&
                          `, ${item.User.userSettings.state}`
                        }`}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="menu">
          <MenuOptions
            item={item}
            setVideoId={setVideoId}
            setSelectedVideo={setSelectedVideo}
            options={menuOptions ? clipOptions : VIDEOOPTIONS}
          />
        </div>
      </div>

      {removeable && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            dispatch(removeContentFromElement(elementId, item.contentId));
            toast.success(notificationMessage.removedFromFeatured);
          }}
          className="close"
        >
          <img src={CloseIcon} alt="" />
        </div>
      )}
    </CardStyles>
  );
};

export default VideoCard;
