import React, { useEffect } from "react";
import InputV2 from "../../components/Elements/InputV2";
import Button from "../../components/Elements/Button";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { MarketingPageStyles } from "./Styles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RESET_MARKETING_PAGE_STATE } from "../../actions/marketing/type";
import { addMarketingFooterSection } from "../../actions/marketing";

const Schema = Yup.object().shape({
  social_facebook: Yup.string().required("Facebook link is required."),
  social_instagram: Yup.string().required("Facebook link is required."),
  social_twitter: Yup.string().required("Facebook link is required."),
});

const FooterForm = () => {
  const dispatch = useDispatch();
  const {
    data: marketingPageData,
    saveLoading,
    saveSuccess,
    saveError,
  } = useSelector((state) => state.marketingPage);

  const formik = useFormik({
    initialValues: {
      social_facebook: "",
      social_instagram: "",
      social_twitter: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      await saveFormHandler(values);
    },
  });

  useEffect(() => {
    if (saveSuccess) {
      toast.success("Content updated successfully.", {
        toastId: "footerSuccess",
      });
    }

    if (saveError) {
      toast.success("Something went worng. Please try again!", {
        toastId: "footerError",
      });
    }

    dispatch({
      type: RESET_MARKETING_PAGE_STATE,
    });
  }, [saveSuccess, saveError]);

  useEffect(() => {
    if (marketingPageData) {
      const footerSectionArr = marketingPageData?.["footer"];

      if (footerSectionArr?.length > 0) {
        const footerSection = footerSectionArr[0];

        formik.setValues({
          social_facebook: footerSection?.social_facebook,
          social_instagram: footerSection?.social_instagram,
          social_twitter: footerSection?.social_twitter,
        });
      }
    }
  }, [marketingPageData]);

  const saveFormHandler = async (values) => {
    if (saveLoading) {
      return;
    }

    try {
      const obj = {
        social_facebook: values.social_facebook,
        social_instagram: values.social_instagram,
        social_twitter: values.social_twitter,
      };

      dispatch(addMarketingFooterSection(obj));
    } catch (error) {
      toast.error("Something went wrong. Please try again!");
    }
  };

  return (
    <FormikProvider value={formik}>
      <MarketingPageStyles>
        <div className="form">
          <div className="form__item">
            <InputV2
              name="social_facebook"
              type={"text"}
              placeholder={"Add Facebook Page"}
              id={"social_facebook"}
              label={"Facebook Page"}
              value={formik.values.social_facebook}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formikError={
                formik.touched.social_facebook && formik.errors.social_facebook
              }
            />
          </div>

          <div className="form__item">
            <InputV2
              name="social_instagram"
              type={"text"}
              placeholder={"Add Instagram Page"}
              id={"social_instagram"}
              label={"Instagram Page"}
              value={formik.values.social_instagram}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formikError={
                formik.touched.social_instagram &&
                formik.errors.social_instagram
              }
            />
          </div>

          <div className="form__item">
            <InputV2
              name="social_twitter"
              type={"text"}
              placeholder={"Add Twitter Page"}
              id={"social_twitter"}
              label={"Twitter Page"}
              value={formik.values.social_twitter}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formikError={
                formik.touched.social_twitter && formik.errors.social_twitter
              }
            />
          </div>

          <div className="form__item">
            <Button
              onClick={() => formik.handleSubmit()}
              type={"submit"}
              className="form__item--submit"
              mode="primary"
              width="fluid"
              variant="round"
              size="medium"
            >
              {saveLoading ? "Please wait..." : "save changes"}
            </Button>
          </div>
        </div>
      </MarketingPageStyles>
    </FormikProvider>
  );
};

export default FooterForm;
