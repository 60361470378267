import React from "react";
import styled from "styled-components";
import FreeloaderLogo from "../../assets/logo-light.png";
import config from "../../config";
import { useSelector } from "react-redux";

const Logo = ({ onClick }) => {
  const { data: siteBranding } = useSelector((state) => state.branding);

  if (!siteBranding) {
    return (
      <LogoStyled onClick={onClick}>
        <img src={FreeloaderLogo} alt="" className="logo" />
      </LogoStyled>
    );
  }

  return (
    <LogoStyled onClick={onClick}>
      <img
        src={config.aws.CLOUDFRONT_URL + siteBranding?.logo_light}
        alt={siteBranding?.site_name}
        className="logo"
      />
      <h1>{siteBranding?.site_name}</h1>
    </LogoStyled>
  );
};

export default Logo;

const LogoStyled = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;

  .logo {
    height: 100%;
    width: auto;
  }

  h1 {
    font-family: "Montserrat", sans-serif;
    text-transform: lowercase;
    font-size: 1.25rem;
    color: white;
    font-weight: 400;

    &::first-letter {
      text-transform: uppercase;
    }
  }
`;
