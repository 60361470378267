import React, { useState, useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import CloseIcon from "../../assets/Icons/close.svg";
import Player from "react-player";
import { toast } from "react-toastify";
import { removeContentFromElement } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { notificationMessage } from "../../utils/toastNotifications";
import MenuOptions from "./MenuOptions";
import { GlobalContext } from "../../context/GlobalContext";
import { addDefaultImage } from "../../utils";
import ImageNotfoundPlaceholder from "../../assets/image_notfound_placeholder.svg";
import config from "../../config";
import {
  ADD_TO_STREAMS_OPTION,
  MOMENTS_OTHER,
  REMOVE_FROM_STREAMS_OPTION,
} from "../../utils/menuOptions";

const MOMENTOPTIONS = [
  { icon: "", text: "Manage Moment" },
  { icon: "", text: "Share Moment" },
  { icon: "", text: "Add to Streams" },
];

const MomentCard = ({
  item,
  removeable,
  elementId,
  setSelectedMoment,
  setMomentId,
  menuOptions,
  isSelf,
  midDrag,
  varient,
}) => {
  const dispatch = useDispatch();
  const [showVideo, setShowVideo] = useState(false);
  const [playing, setPlaying] = useState(true);
  const {
    showEditMomentModel,
    deleteMomentConfirmationModel,
    setShowMomentDetailsModel,
    setSelectedMoment: setGlobalSelectedMoment,
  } = useContext(GlobalContext);

  let timeout;

  const userChannels = useSelector((state) => state.user.channels);

  const [momentOptions, setMomentOptions] = useState(menuOptions);

  useEffect(() => {
    if (!isSelf) {
      const isSubscribedToUser = !userChannels
        ? false
        : userChannels.find((channel) => channel.id == item.User.id);
      if (isSubscribedToUser) {
        setMomentOptions([...MOMENTS_OTHER, REMOVE_FROM_STREAMS_OPTION]);
      } else {
        setMomentOptions([...MOMENTS_OTHER, ADD_TO_STREAMS_OPTION]);
      }
    }
  }, [userChannels]);

  useEffect(() => {
    if (deleteMomentConfirmationModel || showEditMomentModel) {
      clearTimeout(timeout);
      setShowVideo(false);
    }
  }, [deleteMomentConfirmationModel, showEditMomentModel]);

  const handleMomentDetail = (e) => {
    if (midDrag) {
      e.stopPropagation();
      return;
    }

    setPlaying(false);
    setGlobalSelectedMoment(item);
    setShowMomentDetailsModel(true);
  };

  return (
    <MomentCardStyled
      varient={varient}
      onMouseEnter={() => {
        timeout = setTimeout(() => {
          setShowVideo(true);
        }, [1500]);
      }}
      onMouseLeave={() => {
        clearTimeout(timeout);
        setShowVideo(false);
      }}
    >
      <img
        onClick={handleMomentDetail}
        src={config.aws.CLOUDFRONT_URL + item?.coverImgUrl}
        alt={item?.caption}
        className="thumbnail"
        onError={(e) => addDefaultImage(e, ImageNotfoundPlaceholder)}
      />
      <div onClick={handleMomentDetail} className="backdrop"></div>

      <div className="metaData">
        <h1 className="metaData__title">
          {item?.caption?.length > 50
            ? item?.caption.substring(0, 50) + "..."
            : item?.caption}
        </h1>

        <p className="metaData__small">{item?.User.username}</p>
      </div>

      {showVideo && (
        <Player
          onClick={handleMomentDetail}
          playing={playing}
          url={config.aws.CLOUDFRONT_URL + item?.videoUrl}
          width="100%"
          height="100%"
          controls={false}
          className="Player"
          muted={false}
          loop={true}
        />
      )}

      {removeable && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            dispatch(removeContentFromElement(elementId, item.contentId));
            toast.success(notificationMessage.removedFromFeatured);
          }}
          className="close"
        >
          <img src={CloseIcon} alt="" />
        </div>
      )}

      {!removeable && item && item.id && (
        <div className="dotMenu">
          <MenuOptions
            type="moment"
            options={menuOptions ? momentOptions : MOMENTOPTIONS}
            item={item}
            momentId={item.id}
            setSelectedMoment={setSelectedMoment}
            setMomentId={setMomentId}
          />
        </div>
      )}
    </MomentCardStyled>
  );
};

export default MomentCard;

const MomentCardStyled = styled.div`
  padding-bottom: 177.78%;
  height: 0;
  width: 100%;

  overflow: hidden;
  border-radius: 0.5rem;
  position: relative;
  margin: 0 auto;
  position: relative;
  margin-bottom: 2rem;
  cursor: pointer;

  .dotMenu {
    z-index: 5;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .close {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 4px solid #f9903d;
    background-color: #f9903d;
    cursor: pointer;
    transition: all 0.25s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    img {
      height: 0.8rem;
      width: auto;
    }
  }

  .Player {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: scale(1.25);
  }

  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    z-index: 2;
  }

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
    transition: all 0.2s ease;
    opacity: 0;
    visibility: hidden;
  }

  .metaData {
    position: absolute;
    z-index: 2;
    bottom: 0rem;
    left: 1rem;
    transition: all 0.2s ease;

    &__title {
      font-size: 1.2rem;
      line-height: 1.3;
      width: 90%;
    }

    &__small {
      font-size: 0.75rem;
      color: #fff;
      font-weight: 400;
      font-family: brother-1816, sans-serif;
      text-transform: uppercase;
      opacity: 0;
      transform: translateY(5px);
      transition: all 0.2s ease;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &:hover {
    .backdrop {
      background-color: rgba(0, 0, 0, 0.4);
      transform: scale(1.2);
      opacity: 1;
      visibility: visible;
    }

    .metaData {
      bottom: 1rem;

      &__title {
        margin-bottom: 0.5rem;
      }

      &__small {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  ${(props) =>
    props.varient &&
    props.varient === "small" &&
    css`
      .metaData {
        left: 0.5rem;

        &__title {
          font-size: 0.9rem;
        }

        &__small {
          font-size: 0.6rem;
        }
      }
    `}

  @media screen and (max-width: 768px) {
    height: 18rem;

    .metaData {
      &__title {
        font-size: 1.2rem;
      }

      &__small {
        font-size: 0.7rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    height: 14rem;
    margin-bottom: 1rem;

    .dotMenu {
      height: 1.5rem;
      width: 1.5rem;

      i {
        font-size: 0.8rem;
      }
    }

    .Player {
      transform: scaleX(1.25) scaleY(1.4);
    }

    .metaData {
      left: 0.4rem;
      bottom: -0.6rem;

      &__title {
        font-size: 0.9rem;
        width: 95%;
      }

      &__small {
        font-size: 0.6rem;
      }
    }
  }
`;
