const initialState = {
  threadsTableData: null,
  threadImagesTableData: null,
  updateThreadsTableData: null,
  updateThreadImagesTableData: null,
  error: null,
};

const threadsTable = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_THREADS_TABLE_SUCCESS":
      return {
        ...state,
        threadsTableData: action.payload,
        error: null,
      };
    case "FETCH_THREADS_TABLE_FAILURE":
      return {
        ...state,
        threadsTableData: null,
        error: action.error,
      };
    case "FETCH_THREAD_IMAGES_TABLE_SUCCESS":
      return {
        ...state,
        threadImagesTableData: action.payload,
        error: null,
      };

    case "FETCH_THREAD_IMAGES_TABLE_FAILURE":
      return {
        ...state,
        threadImagesTableData: null,
        error: action.error,
      };
    case "UPDATE_THREADS_TABLE_SUCCESS":
      return {
        ...state,
        updateThreadsTableData: action.payload,
        error: null,
      };
    case "UPDATE_THREADS_TABLE_FAILURE":
      return {
        ...state,
        updateThreadImagesTableData: null,
        error: action.error,
      };
    case "UPDATE_THREAD_IMAGES_TABLE_SUCCESS":
      return {
        ...state,
        updateThreadImagesTableData: action.payload,
        error: null,
      };
    case "UPDATE_THREAD_IMAGES_TABLE_FAILURE":
      return {
        ...state,
        updateThreadImagesTableData: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default threadsTable;
