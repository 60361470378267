import React, { useContext, useState, useRef } from "react";
import styled from "styled-components";
import BackIcon from "../../assets/Icons/back.svg";
import FilesIcon from "../icons/files.svg";
import PDFIcon from "../icons/pdf.svg";
import CloseIcon from "../icons/close.svg";
import Button from "../Elements/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Col, Row } from "react-grid-system";
import { GlobalContext } from "../../context/GlobalContext";
import { FileUploader } from "react-drag-drop-files";
import { makeStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { notificationMessage } from "../../utils/toastNotifications";
import { upload } from "../../utils";

const useStyles = makeStyles((theme) => ({
  Checkbox: {
    "& .MuiSvgIcon-root": {
      fill: "rgb(249,154,45)",
    },
    "& .MuiTypography-root": {
      fontFamily: "Montserrat",
      fontSize: ".88rem",
      color: "rgba(255, 255, 255, 0.7)",
      userSelect: "none",
    },
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const AddAdditionalContent = ({
  setIsCheckedHasReward,
  isCheckedHasReward,
  rewardAmount,
  setRewardAmount,
  handleTab,
  setRewardDescription,
  rewardDescription,
  setSelectedFiles,
  selectedFiles,
  actionType,
}) => {
  const dragListRef = useRef(null);
  const classes = useStyles();
  const { showAddAdditionalContentModel, setShowAddAdditionalContentModel } =
    useContext(GlobalContext);
  const [files, setFiles] = useState(selectedFiles || null);

  const [selectedFileIndex, setSelectedFileIndex] = useState(null);

  // const [showFileEditForm, setShowFileEditForm] = useState(false);
  const [fileName, setFileName] = useState();

  const [showPriceBox, setShowPriceBox] = useState(false);

  const onDragEndHandler = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(files, result.source.index, result.destination.index);

    setFiles(items);
    // setting the database
    const promises = items.map(async (item, index) => {
      let order = index + 1;

      // dispatch the update api
    });

    await Promise.all(promises);

    toast.success(notificationMessage.elementReorder);
  };

  // const onEditForm = (item) => {
  //   setShowFileEditForm(!showFileEditForm);
  // };
  const handleFileClose = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);

    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles.splice(index, 1);
    setSelectedFiles(updatedSelectedFiles);

    setSelectedFileIndex(null);
  };
  const onCloseForm = (index) => {
    handleFileClose(index);
  };

  const mainButton = () => {
    handleTab();
  };

  // const onUploadHandler = async (file) => {
  //   setFiles(file);
  //   setSelectedFiles(file);
  // };

  const onUploadHandler = async (newFiles) => {
    const mergedFiles = selectedFiles
      ? [...selectedFiles, ...newFiles]
      : newFiles;
    setFiles(mergedFiles);
    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...newFiles,
    ]);
  };

  const handleCheckbox = () => {
    // setShowPriceBox(!showPriceBox);
    // setShowPriceBox(!showPriceBox || !!rewardAmount);
    if (!!rewardAmount) {
      setShowPriceBox(true);
    } else {
      setShowPriceBox(!showPriceBox);
    }
    setIsCheckedHasReward(true);
  };

  const handlePrice = (e) => {
    // console.log(e.target.value);
    setRewardAmount(e.target.value);
  };

  return (
    <AddAdditionalContentStyled>
      <div className="header">
        <div
          onClick={() => {
            setShowAddAdditionalContentModel(false);
          }}
          className="close"
        >
          <img src={BackIcon} alt="" />
        </div>

        <div className="title">
          {actionType === "Edit" ? "Edit" : "Add"} additional content
        </div>
        <div>
          <div className="button" onClick={() => mainButton()}>
            next
          </div>
        </div>
      </div>

      <div className="content">
        <div className="content__rewardPrice">
          <div className="content__rewardPrice--checkbox">
            <div className="checkBox">
              <FormControlLabel
                className={classes.Checkbox}
                control={
                  <Checkbox
                    checked={showPriceBox}
                    onChange={() => handleCheckbox()}
                    name="showPriceBox"
                  />
                }
                label="Require tip to unlock?"
              />
            </div>

            {showPriceBox && (
              <>
                <input
                  type="number"
                  placeholder="Set a minimum tip amount to unlock this reward."
                  className="priceInput"
                  value={rewardAmount}
                  onChange={handlePrice}
                />

                <p className="text">
                  <span className="bold">Note:</span>
                  As a way to drive additional revenue for extra content on your
                  videos. You can set a minimum required tip to unlock these
                  additional files. Once the member sends the amount specificed
                  above, the downloadable content is unlocked.
                </p>
              </>
            )}
          </div>
        </div>

        <FileUploader
          hoverTitle="Drop single or multiple files here."
          name="files"
          multiple={true}
          handleChange={(files) => onUploadHandler(files)}
        >
          <div className="content__addFiles">
            <div className="content__addFiles--dragdrop">
              <img
                src={FilesIcon}
                alt=""
                className="content__addFiles--dragdrop-icon"
              />
              <div className="content__addFiles--dragdrop-text">
                Drag & drop, or <span className="highlight">browse</span> your
                files
              </div>
            </div>
          </div>
        </FileUploader>

        {files?.length > 0 && (
          <div className="content__uploadedFiles">
            <div className="content__editFileInfo--form-fieldItem">
              <textarea
                id="name"
                type="text"
                placeholder="Add a brief description of the file(s) you're attaching as a reward for this video."
                value={rewardDescription}
                onChange={(e) => setRewardDescription(e.target.value)}
              />
            </div>
            <div className="title">Uploaded Files({files.length})</div>

            <DragDropContext onDragEnd={(result) => onDragEndHandler(result)}>
              <Droppable droppableId="filesDroppable">
                {(provided, snapshot) => (
                  <div
                    className="content__uploadedFiles--list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <Row>
                      {Array.from({ length: files.length }).map((item, i) => (
                        <Col lg={6} md={12}>
                          <Draggable key={i} draggableId={`ID${i}`} index={i}>
                            {(provided, snapshot) => (
                              <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                style={{
                                  userSelect: "none",
                                  background: snapshot.isDragging
                                    ? "#383838"
                                    : null,
                                  ...provided.draggableProps.style,
                                }}
                                // onClick={() => onForm(files[i])}
                                className="content__uploadedFiles--list-item"
                              >
                                <div className="content__uploadedFiles--list-item-left">
                                  <img src={PDFIcon} alt="" className="icon" />
                                </div>
                                <div className="content__uploadedFiles--list-item-right">
                                  <div className="name">{files[i].name}</div>
                                </div>

                                <div className="edit">
                                  <img
                                    src={CloseIcon}
                                    alt=""
                                    className="edit__icon"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onCloseForm(i);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </Col>
                      ))}
                    </Row>

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <FileUploader
              name="moreFiles"
              multiple={true}
              handleChange={(moreFiles) => setFiles([...files, ...moreFiles])}
            >
              <Button
                mode="primary"
                width="fluid"
                variant="round"
                size="medium"
                className="content__uploadedFiles--list-btn"
              >
                Add More Files
              </Button>
            </FileUploader>
          </div>
        )}
      </div>
    </AddAdditionalContentStyled>
  );
};

export default AddAdditionalContent;

const AddAdditionalContentStyled = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #1c1c1e;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1001;
  font-family: ${(props) => props.theme.montserrat};

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 5rem;
    border-bottom: 1px solid rgba(112, 112, 112, 0.4);
    margin-bottom: 4rem;
    background-color: #1c1c1e;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 2rem;
        height: auto;
        cursor: pointer;
      }
    }

    .title {
      font-size: 1.2rem;
      font-weight: 400;
      text-transform: capitalize;
    }
    .button {
      border: 2px solid #fff;
      padding: 0.2rem 1.5rem;
      border-radius: 10rem;
      font-size: 1rem;
      background-color: transparent;
      color: #fff;
      font-family: ${(props) => props.theme.montserrat};
      font-weight: 400;
      text-transform: capitalize;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }

  .content {
    width: 80%;
    margin: 0 auto;

    .title {
      font-size: 1.2rem;
      font-weight: 400;
      text-transform: capitalize;
      margin-bottom: 1.5rem;
    }

    &__rewardPrice {
      background-color: #202020;
      padding: 1.5rem;
      border-radius: 0.25rem;
      border: 1px solid rgba(255, 255, 255, 0.07);
      margin-bottom: 3rem;
      min-width: 480px;
      width: 50%;
      cursor: pointer;

      .priceInput {
        margin-top: 1rem;
        width: 100%;
        padding: 0.8rem;
        background-color: #3a3a3c;
        color: #f2f2f7;
        border-radius: 0.4rem;
        border: none;
        outline: none;
        font-size: 0.8rem;
        font-weight: 400;
        color: #f2f2f7;
        font-family: ${(props) => props.theme.montserrat};
        transition: all 0.25s ease;
        border: 3px solid transparent;

        &::placeholder {
          font-weight: 400;
          color: rgba(255, 255, 255, 0.65);
          font-size: 0.85rem;
        }

        &:focus {
          border: 3px solid #f88946;
        }
      }

      .text {
        margin-top: 1rem;
        font-size: 0.725rem;
        color: rgba(255, 255, 255, 0.65);

        .bold {
          font-size: 0.7rem;
          font-weight: 600;
          text-transform: uppercase;
          padding-right: 0.35rem;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }

    &__addFiles {
      width: 100%;
      height: 18rem;
      width: 100%;
      background-color: #202020;
      min-height: 22vh;
      border-radius: 0.35rem;
      outline: 2px dashed rgba(255, 255, 255, 0.45);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;

      &--dragdrop {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;

        &-icon {
          height: 5rem;
          width: auto;
          margin-bottom: 1.5rem;
          opacity: 0.7;
        }

        &-text {
          width: 100%;
          text-align: center;

          .highlight {
            cursor: pointer;
            display: inline-block;
            padding: 0;
            margin: 0 0.35rem;
            font-weight: 500;
            background: linear-gradient(
              to right,
              ${(props) => props.theme.primaryColor1},
              ${(props) => props.theme.primaryColor2},
              ${(props) => props.theme.primaryColor1}
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: brother-1816, sans-serif;
            text-transform: uppercase;
            position: relative;

            &:after {
              position: absolute;
              content: "";
              left: 0;
              bottom: 0;
              height: 1.5px;
              width: 15%;
              background-image: ${(props) => props.theme.primaryGradient};
              transition: all 0.3s ease;
            }

            &:hover:after {
              width: 100%;
            }
          }
        }
      }
    }

    &__editFileInfo {
      &--form {
        background-color: #181818;
        min-width: 480px;
        width: 50%;
        padding: 1.5rem;
        border-radius: 0.35rem;
        border: 1px solid rgba(255, 255, 255, 0.07);
        margin-bottom: 3rem;

        &-fieldItem {
          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }

          input,
          textarea,
          select {
            width: 100%;
            padding: 0.8rem;
            background-color: #3a3a3c;
            color: #f2f2f7;
            border-radius: 0.4rem;
            border: none;
            outline: none;
            font-size: 0.8rem;
            font-weight: 400;
            color: #f2f2f7;
            font-family: ${(props) => props.theme.montserrat};
            transition: all 0.25s ease;
            border: 3px solid transparent;

            &::placeholder {
              font-weight: 400;
              color: #f2f2f7;
              font-size: 0.85rem;
            }

            &:focus {
              border: 3px solid #f88946;
            }
          }

          textarea {
            resize: none;
            height: 15rem;
          }
        }

        &-btn {
          padding: 0.7rem 0;
        }
      }
    }

    &__uploadedFiles {
      margin-bottom: 3rem;

      &--list {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-btn {
          margin-top: 1.5rem;
          font-weight: 500;
          padding: 0.75rem 0;
        }

        &-item {
          background-color: #181818;
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 4rem;
          color: #fff;
          border-radius: 0.25rem;
          border: 1px solid rgba(255, 255, 255, 0.07);
          padding: 0.5rem 1rem 0.5rem 0;
          cursor: pointer;
          position: relative;
          margin-bottom: 1rem;

          .edit {
            position: absolute;
            top: 0;
            right: 0;
            height: 1.75rem;
            width: 1.75rem;
            border-top-right-radius: 0.25rem;
            background: ${(props) => props.theme.primaryGradient};
            display: flex;
            align-items: center;
            justify-content: center;

            &__icon {
              height: 0.8rem;
              width: auto;
            }
          }

          &-left {
            width: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid rgba(255, 255, 255, 0.2);

            .icon {
              height: 1.35rem;
              width: auto;
            }
          }

          &-right {
            width: calc(100% - 4rem);
            padding-right: 1.75rem;

            .name {
              text-transform: capitalize;
              font-size: 0.82rem;
              font-weight: 500;
              line-height: 1;
              margin-bottom: 0.25rem;
            }

            .url {
              color: rgba(255, 255, 255, 0.5);
              font-size: 0.65rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .header {
      padding: 1rem 2rem;
      margin-bottom: 2.5rem;

      .close {
        img {
          width: 1.5rem;
        }
      }

      .title {
        font-size: 1rem;
      }
    }

    .content {
      width: 90%;

      .title {
        font-size: 1rem;
        margin-bottom: 1rem;
      }

      &__rewardPrice {
        padding: 1rem;
        margin-bottom: 2rem;
        min-width: 100%;
        width: 100%;

        .priceInput {
          font-size: 0.7rem;

          &::placeholder {
            font-size: 0.8rem;
          }
        }

        .text {
          font-size: 0.7rem;

          .bold {
            font-size: 0.65rem;
          }
        }
      }

      &__addFiles {
        height: 12rem;

        &--dragdrop {
          width: 90%;

          &-icon {
            height: 3.5rem;
            margin-bottom: 1rem;
          }

          &-text {
            font-size: 0.8rem;

            .highlight {
              margin: 0 0.3rem;
            }
          }
        }
      }

      &__editFileInfo {
        &--form {
          min-width: 100%;
          width: 100%;
          padding: 1rem;
          margin-bottom: 2rem;

          &-fieldItem {
            &:not(:last-child) {
              margin-bottom: 1rem;
            }

            input,
            textarea,
            select {
              font-size: 0.7rem;

              &::placeholder {
                font-size: 0.8rem;
              }
            }

            textarea {
              resize: none;
              height: 10rem;
            }
          }
        }
      }

      &__uploadedFiles {
        margin-bottom: 2rem;

        &--list {
          &-btn {
            margin-top: 1rem;
          }

          &-item {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 414px) {
    .header {
      padding: 1rem;
      margin-bottom: 2rem;

      .close {
        img {
          width: 1.35rem;
        }
      }

      .title {
        font-size: 0.85rem;
      }
    }

    .content {
      width: 90%;

      .title {
        font-size: 0.9rem;
        margin-bottom: 1rem;
      }

      &__rewardPrice {
        padding: 0.8rem;
        margin-bottom: 1.25rem;

        .priceInput {
          font-size: 0.65rem;

          &::placeholder {
            font-size: 0.75rem;
          }
        }

        .text {
          font-size: 0.65rem;

          .bold {
            font-size: 0.6rem;
          }
        }
      }

      &__addFiles {
        height: 10rem;

        &--dragdrop {
          width: 90%;

          &-icon {
            height: 2.5rem;
          }

          &-text {
            font-size: 0.75rem;

            .highlight {
              margin: 0 0.25rem;
            }
          }
        }
      }

      &__editFileInfo {
        &--form {
          padding: 0.8rem;
          margin-bottom: 1.25rem;

          &-fieldItem {
            &:not(:last-child) {
              margin-bottom: 0.5rem;
            }

            input,
            textarea,
            select {
              font-size: 0.65rem;

              &::placeholder {
                font-size: 0.75rem;
              }
            }

            textarea {
              height: 8rem;
            }
          }
        }
      }

      &__uploadedFiles {
        margin-bottom: 1.25rem;
      }
    }
  }
`;
