import React, { useContext, useEffect, useState } from "react";
import Button from "../../Elements/Button";
import ChevronLeftIcon from "../../icons/chevronleft.svg";
import Axios from "axios";
import config from "../../../config";
import TickIcon from "../../icons/tick-v2.svg";
import api from "../../../services/api";
import { AuthContext } from "../../../context/AuthContext";
import { useDispatch } from "react-redux";
import { getAllStorage } from "../../../actions";
import { elementScrollToTop } from "../../../utils";
import Loader from "../../Elements/Loader";
import { toast } from "react-toastify";

const DEFAULT_MEMBERSHIP_DATA = [
  {
    title: "Freeloader",
    description:
      "Watch and learn, tip content creators and explore the platform",
    buttonText: "free",
    order: 1,
    metadata: {
      buttonText: "free",
      subtitle: "Community",
      dataPlanIncluded: false,
      dataPlanText: null,
    },
    default_price: {
      unit_amount: 0,
      recurring: {
        interval: "year",
      },
    },
  },
  {
    name: "Co-op Member",
    description:
      "Create content Receive community support Build a national following Generate Income",
    price: 5,
    recurring_duration: "year",
    order: 2,
    metadata: {
      buttonText: "join now",
      subtitle: "Member Only Privileges",
      dataPlanIncluded: false,
      dataPlanText: null,
    },
    default_price: {
      unit_amount: 500,
      recurring: {
        interval: "year",
      },
    },
  },
  {
    name: "Warrior",
    subtitle: "Creators",
    description:
      "Create content Receive community support Build a national following Generate Income",
    metadata: {
      buttonText: "grab now",
      subtitle: "Member Only Privileges",
      dataPlanIncluded: true,
      dataPlanText: "2 GB Included",
      order: 3,
    },
    default_price: {
      unit_amount: 1500, //cents
      recurring: {
        interval: "year",
      },
    },
  },
];

const Membership = ({ setProgress }) => {
  const [loading, setLoading] = useState(false);
  const [memberships, setMemberships] = useState(null);
  const [stripeProducts, setStripeProducts] = useState(null);

  const dispatch = useDispatch();
  const {
    choosePackage,
    setChoosePackage,
    setBadges,
    setBadgesLoading,
    setValidationError,
  } = useContext(AuthContext);

  useEffect(() => {
    elementScrollToTop("authContent");
  }, []);

  useEffect(() => {
    (async () => {
      // await fetchMembershipData();
      await fetchStripeProductDetails();
    })();
  }, []);

  useEffect(() => {
    if (choosePackage) {
      if (choosePackage === "FREE") {
        fetchBadgesHandler("free");
      } else if (choosePackage === "BASIC") {
        fetchBadgesHandler("basic");
      } else if (choosePackage === "PREMIUM") {
        fetchBadgesHandler("premium");
      }
    }

    if (choosePackage) {
      if (choosePackage === "PREMIUM") {
        dispatch(getAllStorage());
      }
    }
  }, [choosePackage]);

  const fetchBadgesHandler = async (badgeParam) => {
    try {
      setBadgesLoading(true);

      const { data } = await Axios.get(`${config.api.url}badge/${badgeParam}`);
      setBadges(data.badges);

      setBadgesLoading(false);
    } catch (e) {
      setBadgesLoading(false);
    }
  };

  const nextHandler = () => {
    if (!choosePackage) {
      return setValidationError({
        type: "error",
        message: "Please select your membership plan",
      });
    }

    setValidationError(null);
    setProgress(3);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextHandler();
      document.getElementById("triggerBtn").click();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, []);

  const fetchMembershipData = async () => {
    try {
      setLoading(true);
      const res = await api.get("admin/memberships");
      setMemberships(res.data?.data || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMemberships(null);
    }
  };
  const fetchStripeProductDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get("stripe/getProducts");
      if (response.status == 200) {
        if (response?.data?.products?.length) {
          const prods = response?.data?.products;
          let sortedProducts = [];
          if (prods.length) {
            let aa = prods.filter(
              (item) => parseInt(item.metadata?.order) === 1
            );
            console.log(aa);
            sortedProducts = [
              ...prods.filter((item) => parseInt(item.metadata?.order) === 1),
              ...prods.filter((item) => parseInt(item.metadata?.order) === 2),
              ...prods.filter((item) => parseInt(item.metadata?.order) === 3),
            ];
          }
          setStripeProducts(sortedProducts);
        }
      }
    } catch (e) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="actionRow">
        <Button
          size="small"
          variant="outline"
          mode="secondary"
          iconLeft={true}
          onClick={() => setProgress(1)}
          className="actionRow__back"
        >
          <img src={ChevronLeftIcon} alt="" className="icon" />
          <span>Edit Personal Information</span>
        </Button>

        {!loading && (
          <Button
            onClick={nextHandler}
            className="actionRow__submit"
            id="triggerBtn"
          >
            Continue
          </Button>
        )}
      </div>

      <div className="paddingBox">
        <div className="title">Choose your membership plan</div>

        {loading && (
          <Loader text="Please wait while we fetch membership data..." />
        )}

        {!loading && (
          <div className="membership__list">
            {stripeProducts?.length ? (
              <>
                {stripeProducts?.map((item, i) => {
                  return (
                    <MembershipCard
                      setChoosePackage={setChoosePackage}
                      choosePackage={choosePackage}
                      key={i}
                      item={item}
                      cardName={
                        parseInt(item.metadata?.order) === 1
                          ? "FREE"
                          : parseInt(item.metadata?.order) === 2
                          ? "BASIC"
                          : parseInt(item.metadata?.order) === 3
                          ? "PREMIUM"
                          : null
                      }
                    />
                  );
                })}
              </>
            ) : (
              <>
                {DEFAULT_MEMBERSHIP_DATA?.map((item, i) => {
                  return (
                    <MembershipCard
                      setChoosePackage={setChoosePackage}
                      choosePackage={choosePackage}
                      key={i}
                      item={item}
                      cardName={
                        item?.order === 1
                          ? "FREE"
                          : item?.order === 2
                          ? "BASIC"
                          : item?.order === 3
                          ? "PREMIUM"
                          : null
                      }
                    />
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Membership;

const MembershipCard = ({
  item,
  cardName,
  setChoosePackage,
  choosePackage,
}) => {
  return (
    <div
      onClick={() => {
        setChoosePackage(cardName);
      }}
      className={`membership__list--item ${
        parseInt(item?.metadata?.order) === 1
          ? "basic"
          : parseInt(item?.metadata?.order) === 2
          ? "tribe"
          : parseInt(item?.metadata?.order) === 3
          ? "premium"
          : ""
      } ${choosePackage === cardName && "membership__list--item-selected"}`}
    >
      <div className="header">
        <div className="header__name">{item?.name}</div>
        <span className="header__small">{item?.metadata.subtitle}</span>
      </div>

      <p className="paragraph">{item?.description}</p>

      <Button variant={cardName !== "FREE" && "outline"}>
        {item?.metadata?.buttonText
          ? item.metadata?.buttonText
          : `$${(item?.default_price?.unit_amount / 100).toFixed(2)}/${
              item?.default_price?.recurring?.interval
            }`}
      </Button>
      {item?.metadata?.dataPlanIncluded === "true" && (
        <p className="paragraph">
          <div className="bold">
            {item?.data_plan_text ? item.data_plan_text : "(+ Data Plan)"}
          </div>
        </p>
      )}

      {choosePackage === cardName && (
        <div className="indicator">
          <img src={TickIcon} alt="" className="indicator__icon" />
        </div>
      )}
    </div>
  );
};
