import React from "react";
import styled, { keyframes } from "styled-components";
import { Row, Col } from "react-grid-system";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import ArrowDownIcon from "../../../icons/arrow-down.svg";
import Button from "../../StyleComponents/Button";

const Qna = ({ data }) => {
  if (!data?.length) {
    return null;
  }

  return (
    <QnaStyled>
      <div className="content">
        <Row>
          <Col lg={5} sm={12}>
            <div className="content__left">
              <div className="content__left--title">
                Any Questions? Find answers here.
              </div>

              <div className="content__left--text">
                Don’t find your answer here? just send us a message for any
                query.
              </div>

              <Button primary>Contact us</Button>
            </div>
          </Col>
          <Col lg={7} sm={12}>
            <div className="content__right">
              <Accordion className="accordion">
                {data.map((item, i) => (
                  <AccordionItem key={i} className="accordion__item">
                    <AccordionItemHeading className="accordion__item--header">
                      <AccordionItemButton className="accordion__item--header-title">
                        {item?.question}

                        <AccordionItemState>
                          {({ expanded }) => (
                            <div className={`icon ${expanded && "rotate"}`}>
                              <img src={ArrowDownIcon} alt="" />
                            </div>
                          )}
                        </AccordionItemState>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion__item--text">
                      {item?.answer}
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </Col>
        </Row>
      </div>
    </QnaStyled>
  );
};

export default Qna;

const accordion = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50%);
  }

  to {
    opacity: 1;
    transform: translateY(0);

  }
`;

const QnaStyled = styled.div`
  .content {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 7rem;

    &__left {
      padding-right: 2rem;

      &--title {
        font-size: 2.5rem;
        line-height: 1.2;
        margin-bottom: 1.5rem;
        font-weight: 500;
      }

      &--text {
        margin-bottom: 2rem;
        color: rgba(255, 255, 255, 0.7);
        font-weight: 300;
        line-height: 1.3;
      }
    }

    &__right {
      .accordion {
        &__item {
          /* border-bottom: 1px solid #fff; */
          font-family: ${(props) => props.theme.montserrat}, sans-serif;

          &--header {
            padding: 1rem 0.25rem;
            user-select: none;
            cursor: pointer;

            &-title {
              font-family: brother-1816, sans-serif;
              font-size: 1.2rem;
              font-weight: 300;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;

              .icon {
                height: 0.9rem;
                width: auto;
                transition: all 0.3s ease;
              }

              .rotate {
                transform: rotate(180deg);
              }
            }
          }

          &--text {
            padding: 1.5rem 0;
            animation: ${accordion} 0.3s linear;
            transform-origin: top;
            font-size: 1.05rem;
            font-weight: 300;
            line-height: 1.6;
            color: rgba(255, 255, 255, 0.7);
          }

          &:first-child {
            .accordion__item--header {
              border-top: 1px solid rgba(255, 255, 255, 0.2);
              border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }
          }

          &:not(:first-child) {
            .accordion__item--header {
              border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      padding-top: 0;

      &__left {
        margin-bottom: 2rem;
      }
    }
  }
`;
