import {
  FETCH_NOTIFICATION_TYPE_SUCCESS,
  FETCH_NOTIFICATION_TYPE_FAILURE,
  FETCH_NOTIFICATION_CATEGORY_FAILURE,
  FETCH_NOTIFICATION_CATEGORY_SUCCESS,
} from "../actions/types";
const initialState = {
  notificationData: null,
  notificationCategoryData: null,
  error: null,
};

const notificationTypesData = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_TYPE_SUCCESS:
      return {
        ...state,
        notificationData: action.payload,
        error: null,
      };
    case FETCH_NOTIFICATION_TYPE_FAILURE:
      return {
        ...state,
        notificationData: null,
        error: action.error,
      };
    case FETCH_NOTIFICATION_CATEGORY_SUCCESS:
      return {
        ...state,
        notificationCategoryData: action.payload,
        error: null,
      };
    case FETCH_NOTIFICATION_CATEGORY_FAILURE:
      return {
        ...state,
        notificationCategoryData: null,
        error: action.error,
      };

    default:
      return state;
  }
};

export default notificationTypesData;
