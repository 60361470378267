import api from "../../services/api";
import { uploadCover } from "../../utils";
import {
  GET_MARKETING_PAGE_REQUEST,
  GET_MARKETING_PAGE_SUCCESS,
  GET_MARKETING_PAGE_FAIL,
  ADD_MARKETING_FAQ_ITEM_REQUEST,
  ADD_MARKETING_FAQ_ITEM_SUCCESS,
  ADD_MARKETING_FAQ_ITEM_FAIL,
  ADD_MARKETING_WHYUS_SECTION_REQUEST,
  ADD_MARKETING_WHYUS_SECTION_SUCCESS,
  ADD_MARKETING_WHYUS_SECTION_FAIL,
  ADD_MARKETING_PRICING_SECTION_REQUEST,
  ADD_MARKETING_PRICING_SECTION_SUCCESS,
  ADD_MARKETING_PRICING_SECTION_FAIL,
  REMOVE_MARKETING_FAQ_ITEM_SUCCESS,
  ADD_MARKETING_STATS_SECTION_REQUEST,
  ADD_MARKETING_STATS_SECTION_SUCCESS,
  ADD_MARKETING_STATS_SECTION_FAIL,
  ADD_MARKETING_ABOUT_SECTION_REQUEST,
  ADD_MARKETING_ABOUT_SECTION_SUCCESS,
  ADD_MARKETING_ABOUT_SECTION_FAIL,
  ADD_MARKETING_FOOTER_SECTION_REQUEST,
  ADD_MARKETING_FOOTER_SECTION_SUCCESS,
  ADD_MARKETING_FOOTER_SECTION_FAIL,
} from "./type";

export const getMarketingPage = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_MARKETING_PAGE_REQUEST,
    });

    const res = await api.get(`marketing`);

    dispatch({
      type: GET_MARKETING_PAGE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_MARKETING_PAGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addMarketingWhyusSection = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_MARKETING_WHYUS_SECTION_REQUEST,
    });

    if (obj?.file) {
      uploadCover("image", obj.file)
        .then(async (iconRes) => {
          const { url: iconUrl } = iconRes;

          let res;
          if (obj?.id) {
            res = await api.patch(`marketing/why/${obj.id}`, {
              title: obj.title,
              text: obj.text,
              icon: iconUrl,
            });
          } else {
            res = await api.post(`marketing/why`, {
              title: obj.title,
              text: obj.text,
              icon: iconUrl,
            });
          }

          dispatch({
            type: ADD_MARKETING_WHYUS_SECTION_SUCCESS,
            payload: res.data.data,
          });
        })
        .catch((err) => {
          throw new Error();
        });
    } else {
      let res;
      if (obj?.id) {
        res = await api.patch(`marketing/why/${obj.id}`, {
          title: obj.title,
          text: obj.text,
        });
      } else {
        res = await api.post(`marketing/why`, {
          title: obj.title,
          text: obj.text,
          icon: "testing",
        });
      }

      dispatch({
        type: ADD_MARKETING_WHYUS_SECTION_SUCCESS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_MARKETING_WHYUS_SECTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addMarketingPricingSection = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_MARKETING_PRICING_SECTION_REQUEST,
    });

    let res;
    if (obj?.id) {
      res = await api.patch(`marketing/pricing/${obj.id}`, {
        ...obj,
      });
    } else {
      res = await api.post(`marketing/pricing`, {
        ...obj,
      });
    }

    dispatch({
      type: ADD_MARKETING_PRICING_SECTION_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: ADD_MARKETING_PRICING_SECTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addMarketingStatsSection = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_MARKETING_STATS_SECTION_REQUEST,
    });

    let res;
    if (obj?.id) {
      res = await api.patch(`marketing/stats/${obj.id}`, {
        ...obj,
      });
    } else {
      res = await api.post(`marketing/stats`, {
        ...obj,
      });
    }

    dispatch({
      type: ADD_MARKETING_STATS_SECTION_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: ADD_MARKETING_STATS_SECTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addFaqItem = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_MARKETING_FAQ_ITEM_REQUEST,
    });

    const res = await api.post(`marketing/faq`, {
      ...obj,
    });

    dispatch({
      type: ADD_MARKETING_FAQ_ITEM_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: ADD_MARKETING_FAQ_ITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeFaqItem = (id) => async (dispatch) => {
  await api.delete(`marketing/faq/${id}`);

  dispatch({
    type: REMOVE_MARKETING_FAQ_ITEM_SUCCESS,
    payload: id,
  });
};

export const addMarketingAboutSection = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_MARKETING_ABOUT_SECTION_REQUEST,
    });

    if (obj?.file) {
      uploadCover("image", obj.file)
        .then(async (thumbnailRes) => {
          const { url: thumbnail } = thumbnailRes;

          const res = await api.post(`marketing/about`, {
            ...obj,
            thumbnail,
          });

          dispatch({
            type: ADD_MARKETING_ABOUT_SECTION_SUCCESS,
            payload: res.data.data,
          });
        })
        .catch((err) => {
          throw new Error();
        });
    } else {
      const res = await api.post(`marketing/about`, {
        ...obj,
      });

      dispatch({
        type: ADD_MARKETING_ABOUT_SECTION_SUCCESS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_MARKETING_ABOUT_SECTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addMarketingFooterSection = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_MARKETING_FOOTER_SECTION_REQUEST,
    });

    const res = await api.post(`marketing/footer`, {
      ...obj,
    });

    dispatch({
      type: ADD_MARKETING_FOOTER_SECTION_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: ADD_MARKETING_FOOTER_SECTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
