import React from "react";
import styled from "styled-components";
import ArrowLeftIcon from "../../../icons/arrow-left.svg";
import CloseIcon from "../../../icons/close-black.svg";
import TickIcon from "../../../icons/tick-black.svg";
import Paragraph from "../../StyleComponents/Paragraph";

const MembershipCard = ({ data }) => {
  return (
    <MembershipCardStyled>
      <div className="content__item">
        <div
          className={`content__item--inner content__item--front content__item--front-${
            data.role === 0
              ? "1"
              : data.role === 1
              ? "2"
              : data.role === 2
              ? "3"
              : "1"
          }`}
        >
          <div className="title">
            {data.index === 0
              ? "Basic"
              : data?.index === 1
              ? "Standard"
              : data?.index === 2
              ? "Premium"
              : "Basi"}
          </div>
          <div className="tag">{data.plan_name}</div>

          <div className="priceBox">
            <div className="priceBox__price">${data?.price}</div>
            <div className="priceBox__time">/ {data?.duration}</div>
          </div>

          <div className="joinButton">
            Join Plan
            <img src={ArrowLeftIcon} alt="" />
          </div>

          <div className="features">
            {data?.benefits.map((item, i) => (
              <div key={i} className="features__item">
                <img src={TickIcon} alt="" />
                {item}
              </div>
            ))}
          </div>
        </div>
        <div className="content__item--inner content__item--back">
          <Paragraph className="text">{data?.description}</Paragraph>
          <div className="joinButton">Join Plan</div>
        </div>
      </div>
    </MembershipCardStyled>
  );
};

export default MembershipCard;

const MembershipCardStyled = styled.div`
  .content {
    &__item {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      height: 38rem;

      &--inner {
        height: 100%;
        padding: 1.5rem 1rem;
        transition: all 0.8s ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border-radius: 3px;
        overflow: hidden;
      }

      &--front {
        color: #1d1d1f;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          font-size: 1.3rem;
          font-weight: bold;
          text-transform: uppercase;
          font-family: brother-1816, sans-serif;
          margin-bottom: 0.5rem;
        }

        .tag {
          margin-bottom: 2rem;
          background-color: rgba(0, 0, 0, 0.05);
          color: #000;
          padding: 0.3rem 1rem;
          font-size: 0.6rem;
          text-transform: capitalize;
          border-radius: 4px;
          letter-spacing: 0.95px;
          font-weight: 500;
          border: 1px solid #000;
          font-family: "brother-1816", sans-serif;
          text-transform: uppercase;
        }

        .priceBox {
          display: flex;
          align-items: flex-end;
          line-height: 1;
          margin-bottom: 2rem;

          &__price {
            font-size: 3rem;
            line-height: 1;
            font-family: brother-1816, sans-serif;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: -0.09px;
          }

          &__time {
            line-height: 1;
            margin-bottom: 0.5rem;
            padding: 0 0.5rem;
            font-size: 0.95rem;
          }
        }

        .joinButton {
          font-family: "brother-1816", sans-serif;
          text-transform: uppercase;
          font-size: 0.7rem;
          text-transform: uppercase;
          padding: 0.4rem 2rem;
          border-radius: 10rem;
          outline: none;
          font-family: ${(props) => props.theme.montserrat};
          font-weight: 600;
          background: ${(props) => props.theme.primaryGradient};
          display: flex;
          align-items: center;
          color: #fff;
          margin-bottom: 2rem;

          img {
            height: 0.65rem;
            width: auto;
            margin-left: 0.5rem;
            transform: rotate(180deg);
          }
        }

        .features {
          width: 90%;
          margin: auto auto 1rem auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          &__item {
            &:not(:last-child) {
              margin-bottom: 0.8rem;
            }

            color: rgba(0, 0, 0, 0.7);
            font-size: 0.85rem;
            padding-bottom: 5px;
            border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
            width: 100%;
            text-align: center;

            &--notAvailable {
              text-decoration: line-through;
            }

            img {
              height: 0.6rem;
              width: auto;
              margin-right: 1rem;
            }
          }
        }

        &-1 {
          background-color: #fffccf;
        }

        &-2 {
          background-color: #ffedff;
        }

        &-3 {
          background-color: #ffe8eb;
        }
      }

      &--back {
        transform: rotateY(180deg);
        background: ${(props) => props.theme.primaryGradient};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .text {
          font-size: 1.02rem;
          line-height: 1.7;
        }

        .joinButton {
          border-radius: 2rem;
          transform: scale(1);
          transition: transform 1.8s cubic-bezier(0.19, 1, 0.22, 1);
          font-family: brother-1816, sans-serif;
          font-size: 0.85rem;
          text-transform: uppercase;
          font-weight: 400;
          background-color: transparent;
          height: 2.5rem;
          padding: 0 2rem;
          color: #fff;
          border: 1px solid #fff;
          position: relative;
          z-index: 1;
          display: flex;
          align-items: center;
          cursor: pointer;

          &::before {
            content: "";
            display: inline-block;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transition: all 0.4s;
            background-color: transparent;
            border-radius: 2rem;
          }

          &:hover::before {
            opacity: 0;
            transform: scaleX(1.5) scaleY(1.7);
            background: #fff;
          }
        }
      }

      &:hover .content__item--front {
        transform: rotateY(-180deg);
      }

      &:hover .content__item--back {
        transform: rotateY(0);
      }
    }
  }

  @media screen and (max-width: 480px) {
    .content {
      &__item {
        margin-bottom: 3rem;
      }
    }
  }
`;
