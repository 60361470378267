import { CHATCOUNT } from "../actions/types";

const chatCount = (state = [], action) => {
  switch (action.type) {
    case CHATCOUNT:
      if (action.payload?.receiverMessage) {
        return action.payload.receiverMessage;
      }

    default:
      return state;
  }
};

export default chatCount;
