import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AdminSidebar from "../AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { IoTrashSharp } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import Pagination from "../Pagination";
import { adminSeriesVideoData, deleteAdminSeriesVideo } from "../../actions";
import ConfirmationModel from "../../components/ConfirmationModel";
import SeriesVideoEditTable from "./SeriesVideoEditTable";

const SeriesVideoTable = () => {
  const seriesVideoData = useSelector(
    (state) => state.seriesTableData.seriesVideoData
  );
  // console.log(seriesVideoData);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [seriesData, setSeriesData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("videoId");
  const [sortOrder, setSortOrder] = useState("asc");
  const itemsPerPage = 5;

  useEffect(() => {
    dispatch(adminSeriesVideoData()).then(() => {});
  }, [dispatch]);

  //   serching------------------
  const filterData = () => {
    return seriesVideoData
      ? seriesVideoData.filter((row) => {
          const lowerSearch = search.toLowerCase();
          return (
            (row.seriesId &&
              row.seriesId.toLowerCase().includes(lowerSearch)) ||
            (row.videoId && row.videoId.toLowerCase().includes(lowerSearch)) ||
            (row.muxid && row.muxid.toLowerCase().includes(lowerSearch))
          );
        })
      : [];
  };
  // Pagination -------------
  const filteredData = filterData();
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //   Edit ------------------------
  const handleEdit = (row) => {
    setIsEditing(true);
    setSeriesData({
      id: row.id,
      videoId: row.videoId,
      seriesId: row.seriesId,
      order: row.order,
      muxid: row.muxid,
      createdAt: row.createdAt,
      updatedAt: row.updatedAt,
    });
  };

  //   Delete-------------------
  const handleDelete = (row) => {
    setShowDeleteConfirmation(true);
    setSeriesData({ id: row.id });
  };
  const handleFormChange = (field, value) => {
    setSeriesData({ ...seriesData, [field]: value });
  };

  const deleteVideoHandler = async () => {
    try {
      await dispatch(deleteAdminSeriesVideo(seriesData.id));
      // console.log(seriesData.id);
      setShowDeleteConfirmation(false);
      dispatch(adminSeriesVideoData());
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  // Sorting data ----------------------
  const sortData = () => {
    const sortedData = [...filteredData];
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortColumn].localeCompare(b[sortColumn]);
      } else {
        return b[sortColumn].localeCompare(a[sortColumn]);
      }
    });
    return sortedData;
  };
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="tableHeader">SR.No</TableCell>
          <TableCell
            className="tableHeader"
            onClick={() => handleSort("videoId")}
          >
            Video Id{" "}
            {sortColumn === "videoId" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>

          <TableCell
            className="tableHeader"
            onClick={() => handleSort("videoId")}
          >
            Series Id{" "}
            {sortColumn === "videoId" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell
            className="tableHeader"
            onClick={() => handleSort("videoId")}
          >
            Order{" "}
            {sortColumn === "videoId" && (
              <span>{sortOrder === "asc" ? "▲" : "▼"}</span>
            )}
          </TableCell>
          <TableCell className="tableHeader">Mux Id</TableCell>
          <TableCell className="tableHeader">Created At </TableCell>
          <TableCell className="tableHeader">Update At </TableCell>
          <TableCell className="tableHeader">Action </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      <SeriesDashboard>
        <AdminSidebar />
        <TableContainer component={Paper} className="tableContainer">
          <div>
            <h1 className="headingText">Search The SeriesVideo Table</h1>
            <input
              type="text"
              placeholder="Search The seies Video"
              className="searchBox"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <Table className="table">
            {renderTableHeader()}
            <TableBody>
              {sortData()
                .slice(startIndex, endIndex)
                .map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell className="tableCell">
                      {startIndex + index + 1}
                    </TableCell>
                    <TableCell className="tableCell">{row.videoId}</TableCell>
                    <TableCell className="tableCell">{row.seriesId}</TableCell>
                    <TableCell className="tableCell">{row.order}</TableCell>
                    <TableCell className="tableCellmuxId">
                      {row.muxid}
                    </TableCell>
                    <TableCell className="tableCell">{row.createdAt}</TableCell>
                    <TableCell className="tableCell">{row.updatedAt}</TableCell>
                    <TableCell className="tableCell">
                      <FiEdit
                        className="editButton"
                        size={28}
                        onClick={() => handleEdit(row)}
                      />
                      <IoTrashSharp
                        className="deleteButton"
                        size={28}
                        onClick={() => handleDelete(row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div style={{ marginTop: "7px", marginBottom: "35%" }}>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={seriesVideoData ? seriesVideoData.length : 0}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </TableContainer>
      </SeriesDashboard>
      {isEditing && (
        <SeriesVideoEditTable
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          seriesData={seriesData}
          onChange={handleFormChange}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationModel
          closeHandler={() => setShowDeleteConfirmation(false)}
          title="Are you sure you want to delete this series video ? You can't undo this action."
          deleteHandler={deleteVideoHandler}
        />
      )}
    </>
  );
};
export default SeriesVideoTable;

const SeriesDashboard = styled.div`
  display: flex;

  .tableCellmuxId {
    color: white;
    border: 1px solid;
    font-size: 13px;
    word-break: break-all;
  }
  .tableContainer {
    max-width: 100%;
    height: 100vh;
    background: #212123;
    padding-left: 2%;
    padding-right: 2%;
  }
  .table {
    background: #212123;
  }
  .tableHeader {
    background: #212123;
    color: white;
    border: 1px solid;
    font-size: 13px;
  }
  .tableCell {
    color: white;
    border: 1px solid;
    font-size: 13px;
  }
  .tableCellmuxId {
    color: white;
    border: 1px solid;
    font-size: 13px;
    word-break: break-all;
  }
  .editButton {
    color: #4f92f7;
    marginleft: 10px;
    transition: transform 0.2s;
  }
  .editButton:hover {
    transform: scale(1.3);
  }

  .deleteButton {
    color: #f54e4e;
    marginleft: 10px;
    transition: transform 0.2s;
  }
  .deleteButton:hover {
    transform: scale(1.3);
  }

  .headingText {
    font-size: 18px;
    margin-top: 10px;
    color: white;
  }
  .searchBox {
    background: #2c2c2e !important;
    color: white;
    margin-bottom: 2%;
    width: 100%;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.6rem 0.8rem;
    border-radius: 7px;
    border: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.9rem;

    &:hover {
      border: 3px solid rgb(249, 154, 45);
    }

    &:focus {
      border: 3px solid rgb(249, 154, 45);
    }

    &::placeholder {
      color: #cdc5c5;
    }
  }

  /* Responsive styling for the search box */
  @media (max-width: 768px) {
    .SearchBox {
      width: 100%;
    }
  }
`;
