const STRINGS = {
  STRIPE_PUBLIC_KEY: "pk_test_nrAHktA2rUOx22zahIeu8AGg007EgPdvGG",

  STRIPE_ONBOARDING:
    "https://dashboard.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_IxepZqCFNTXjFq2PXMN49WZn6JqMh4ec&scope=read_write&redirect_uri=https://isutra.app/",
  FILTER_TYPES: {
    TRANSACTIONS_FILTERS: 1,
    KARMA_FILTERS: 2,
  },

  TRANSACTION_ACTIONS: {
    TIP: "tip",
    PPV_UNLOCK: "ppv_unlock",
    PURCHASE_SERIES: "purchase_series",
    ISUTRA_MEMBERSHIP: "isutra_membership",
  },

  TRANSACTION_TYPES: {
    ISUTRA_MEMBERSHIP: 1,
    PURCHASED_PPV: 2,
    PURCHASED_SERIES: 3,
    PURCHASED_LIVE_CLASS: 4,
  },
};
export default STRINGS;
