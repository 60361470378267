import React, { useContext, useState, useEffect, useRef } from "react";
import BackIcon from "../../assets/Icons/back.svg";
import AddIcon from "../../assets/Icons/add.svg";
import Player from "../Player";
import useInput from "../../hooks/useInput";
import path from "path";
import api from "../../services/api";
import ReactTags from "react-tag-autocomplete";
import VideoCategories from "./VideoCategory";
import styled from "styled-components";
import PriceIcon from "../icons/price.svg";
import TimeIcon from "../icons/time.svg";
import VideoIcon from "../icons/video.svg";
import { GlobalContext } from "../../context/GlobalContext";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { upload, uploadCover } from "../../utils";
import {
  updateVideoV2,
  getVideoCategories,
  getVideoAccessOverlays,
  getHashtags,
  getAllSeriesOfUser,
  addVideoToSeries,
  getUserVideos,
  getUserStorageInfo,
  deleteAdditionalContent,
} from "../../actions";
import "../../styles/react-tag.css";
import { Video } from "video-metadata-thumbnails";
import { notificationMessage } from "../../utils/toastNotifications";
import Moment from "react-moment";
import Loader from "../Elements/Loader";
import Error from "../Elements/Error";
import { VIDEO_UPDATE_RESET } from "../../actions/types";
import config from "../../config";
import EditAdditionalContent from "../Models/EditAdditionalContent";

const EditClipForm = ({ close, selectedVideo, setSelectedVideo, videoId }) => {
  const videoCategories = useSelector((state) => state.video.videoCategories);
  const [defaultCategories, setDefaultCategories] = useState([]);
  const videoAccessOverlays = useSelector(
    (state) => state.videoOverlay.videoAccessOverlays
  );
  const videoHashtags = useSelector((state) => state.video.videoHashtags);
  const user = useSelector((state) => state.user);
  const {
    loading: getSeriesLoading,
    series: allSeries,
    error: getSeriesError,
  } = useSelector((state) => state.getAllSeriesOfUser);
  const { videoUpdateLoading, videoUpdateInfo } = useSelector(
    (state) => state.video
  );
  const profile = useSelector((state) => state.profile);

  const title = useInput(selectedVideo?.title || "");
  const ppvAmount = useInput(selectedVideo.amount);
  const description = useInput(selectedVideo?.description || "");
  const [keyVideoAccess, setKeyVideoAccess] = useState(
    selectedVideo.keyVideoAccess
  );
  const [tab, setTab] = useState("FORM");
  const [url, setUrl] = useState(selectedVideo?.url || "");
  const [publicId, setPublicId] = useState("");
  const [muxid, setMuxid] = useState("");
  const [videoLength, setVideoLength] = useState(
    selectedVideo?.videoLength || null
  );
  const [thumbnail, setThumbnail] = useState(selectedVideo?.thumbnail || "");
  const [filesize, setVideoSize] = useState("");
  const [isFeatured, setIsFeatured] = useState(
    selectedVideo?.featuredWarriorPortal || false
  );
  const [customThumbnail, setCustomThumbnail] = useState({
    url: "",
    publicId: "",
  });
  const {
    setNewCategoryRequestModel,
    setShowEditClipModel,
    showEditClipModel,
    videoForSeriesUpload,
    showEditAdditionalContentModel,
    setShowEditAdditionalContentModel,
  } = useContext(GlobalContext);

  const [hashTags, sethashTags] = useState(selectedVideo?.hashTags || []);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const reactTags = React.createRef();
  const [rewardAmount, setRewardAmount] = useState(selectedVideo.RewardsAmount);
  const [rewardDescription, setRewardDescription] = useState(
    selectedVideo.rewardDescription
  );
  const [selectedFiles, setSelectedFiles] = useState(
    selectedVideo.AdditionalContent
  );
  const [isCheckedHasReward, setIsCheckedHasReward] = useState(
    selectedVideo.HasRewards
  );
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [files, setFiles] = useState(selectedVideo.AdditionalContent);
  const dispatch = useDispatch();

  useEffect(async () => {
    if (!videoCategories?.length) {
      dispatch(getVideoCategories());
    }

    if (selectedVideo && selectedVideo.id) {
      await getCurrentVideoCategory(selectedVideo.id);
    }
  }, [selectedVideo]);

  useEffect(() => {
    if (videoUpdateInfo) {
      setSelectedVideo(null);
      setShowEditClipModel(false);
      toast.success(notificationMessage.videoUpdated);

      // update storage information
      dispatch(getUserStorageInfo());

      // user videos
      dispatch(getUserVideos({ showAll: true }, profile?.id));

      // if keyVideoAccess is 4 then add that video to series
      if (videoUpdateInfo.keyVideoAccess === 4 && selectedSeries) {
        dispatch(
          addVideoToSeries(selectedSeries?.id, {
            videoId: videoUpdateInfo.id,
            order: selectedSeries.videos.length + 1,
          })
        );
      }
      dispatch({
        type: VIDEO_UPDATE_RESET,
      });
    }
  }, [videoUpdateInfo]);

  useEffect(() => {
    if (keyVideoAccess === 4 && !videoForSeriesUpload.series) {
      // fetch all available series of user
      dispatch(getAllSeriesOfUser(user?.username));
    }
  }, [keyVideoAccess, videoForSeriesUpload]);

  const tempFunc = useRef();
  const newFunc = () => {
    dispatch(getVideoAccessOverlays());
    dispatch(getHashtags());
  };
  tempFunc.current = newFunc;
  useEffect(() => {
    tempFunc.current();
  }, []);

  const handleVideoAccessChange = (event) => {
    setKeyVideoAccess(+event.target.value);
  };
  const tempDataFunc = useRef();
  const newDataFunc = () => {
    if (thumbnail && !selectedVideo) {
      setTab("COMPLETE");
    }
  };
  tempDataFunc.current = newDataFunc;
  useEffect(() => {
    tempDataFunc.current();
  }, [thumbnail]);

  const completeVideoUpdate = async () => {
    if (!selectedCategory && !selectedCategory.length) {
      return toast.error(notificationMessage.categoryNeededError);
    }
    setShowEditAdditionalContentModel(false);
    dispatch(deleteAdditionalContent(deleteFiles));
    setSelectedFiles(files);
    const newfiles = [];
    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      if (!files[i].id) {
        const data = await upload("video", file);
        newfiles.push({
          documentname: file.name,
          path: data.url,
          Contentfilesize: file.size,
        });
      }
    }
    dispatch(
      updateVideoV2(selectedVideo.id, {
        userId: user.id,
        title: title.value,
        description: description.value,
        url: url ? url : selectedVideo.url,
        publicId,
        thumbnail,
        customThumbnail: customThumbnail.url,
        keyVideoAccess: +keyVideoAccess,
        filesize,
        hashTags: hashTags,
        ppvAmount: +ppvAmount.value,
        featuredWarriorPortal: isFeatured,
        videoLength,
        categoryList: selectedCategory,
        rewardDescription: rewardDescription,
        RewardsAmount: rewardAmount,
        selectedFiles: newfiles,
        muxid,
      })
    );
  };

  const history = useHistory();
  const myAccountLink = () => {
    setShowEditClipModel(false);
    history.push(`/channel/${user.username}?tab=myaccount`);
  };

  const CustomToastWithLink = () => (
    <div onClick={myAccountLink}>
      <p>
        You've used up all your video storage! Please upgrade to a larger video
        package OR remove content from your channel. Click this message to
        upgrade your subscription.
      </p>
    </div>
  );

  const autoGenerateThumbnail = (video, file, ext) => {
    try {
      video
        .getThumbnails({
          quality: 1,
          scale: 0.8,
        })
        .then(async (thumbnails) => {
          const blobthird = thumbnails[3].blob;

          const newfile = new File(
            [blobthird],
            file.name.replace(ext, ".jpg"),
            { type: "image/jpeg" }
          );
          const { url } = await uploadCover("image", newfile);
          setThumbnail(url);
        });
    } catch (error) {
      throw error;
    }
  };

  const handleEditVideo = async (e) => {
    try {
      const file = e.target.files[0];
      const userData = JSON.parse(localStorage.getItem("user"));
      const res = await api.get(`/users/space-left/${userData.id}`);

      if (!res.data.data) {
        return toast.error(CustomToastWithLink);
      } else {
        if (file) {
          setVideoSize(file.size);
          const size = file.size / 1000000;

          if (size > 10000) {
            setShowEditClipModel(false);
            return toast.error(notificationMessage.fileSizeLimit);
          }

          const data = await upload("video", file);
          setUrl(data.url);
          setPublicId(data.publicId);
          setMuxid(data.muxid);
          setVideoLength(Math.round(data.duration));

          const ext = path.extname(data.url);
          const video = new Video(file);
          autoGenerateThumbnail(video, file, ext);
          // if (!!customThumbnail.url) {
          //   setThumbnail(customThumbnail.url);
          // } else {
          //   const video = new Video(file);
          //   autoGenerateThumbnail(video, file, ext);
          // }
        }
      }
    } catch (e) {
      setTab("FORM");
      toast.dismiss();
      return toast.error(notificationMessage.oopsTryAgain);
    }
  };

  const onDelete = (i) => {
    const selectedHashtags = hashTags.slice(0);
    selectedHashtags.splice(i, 1);
    sethashTags(selectedHashtags);
  };

  const onAddition = (tag) => {
    const selectedHashtags = [].concat(hashTags, tag);
    sethashTags(selectedHashtags);
  };

  const onValidate = (tag) => {
    if (tag.name.length > 140) {
      toast.error(notificationMessage.hashTagLimit);
      return false;
    }

    if (/[^a-zA-Z0-9]/.test(tag.name)) {
      toast.error(notificationMessage.removeInvalidCharacter);
      return false;
    }

    if (hashTags.length > 0) {
      if (hashTags.length >= 20) {
        toast.error(notificationMessage.addHashtagUpto20);
        return false;
      }
      for (var i = 0; i < hashTags.length; i++) {
        if (
          hashTags[i].name.toString().toLowerCase() ===
          tag.name.toString().toLowerCase()
        ) {
          toast.error(notificationMessage.videoHashtag);
          return false;
        }
      }
    }
    return true;
  };

  const handleCustomThumbnailUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const { publicId, url } = await uploadCover("image", file);
      setCustomThumbnail({ url, publicId });
    }
  };

  const getCurrentVideoCategory = async (videoId) => {
    const categoryResponse = await api.get(
      `videos/getCategoryByVideo/${videoId}`
    );
    if (categoryResponse.data.success) {
      setSelectedCategory(categoryResponse.data.videoSubCategories);
      setDefaultCategories(categoryResponse.data.videoSubCategories);
    }
  };

  const handleTab = () => {
    console.log(rewardDescription);
  };

  return (
    <>
      <EditClipFormStyled
        className={`${showEditClipModel && "ShowEditClipForm"}`}
      >
        <div className="header">
          <div
            onClick={() => {
              setSelectedVideo(null);
              close();
            }}
            className="close"
          >
            <img src={BackIcon} alt="" />
          </div>

          <div className="title">
            {tab === "FORM" && selectedVideo && "Edit Video Details"}
          </div>

          {tab === "FORM" && selectedVideo && (
            <div className="button" onClick={completeVideoUpdate}>
              {videoUpdateLoading ? "Saving..." : "Save"}
            </div>
          )}
        </div>

        {tab === "FORM" && (
          <div className="form">
            <div className="form--fieldGroup">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                id="title"
                placeholder="Video title"
                value={title.value}
                onChange={title.onChange}
              />
            </div>

            <div className="form--fieldGroup">
              <label htmlFor="">Choose category</label>
              <VideoCategories
                videoCategories={videoCategories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                defaultCategories={defaultCategories}
              />

              <button
                onClick={() => {
                  setNewCategoryRequestModel(true);
                }}
                className="requestNewCategory"
              >
                Request New Category
              </button>
            </div>

            <div className="form--fieldGroup">
              <label htmlFor="">Video Access</label>

              {videoForSeriesUpload.series ? (
                <select value={4} onChange={handleVideoAccessChange}>
                  <option value={4}>Series Video</option>
                </select>
              ) : (
                <select
                  value={selectedVideo.keyVideoAccess}
                  onChange={handleVideoAccessChange}
                >
                  {videoAccessOverlays.length}
                  {videoAccessOverlays &&
                    videoAccessOverlays.length &&
                    videoAccessOverlays.map((vao, index) => {
                      return (
                        <option value={vao.keyVideoAccess} key={index}>
                          {vao.name}
                        </option>
                      );
                    })}
                </select>
              )}
            </div>

            {keyVideoAccess === 4 && videoForSeriesUpload.showSeriesList && (
              <>
                <div className="form--fieldGroup">
                  <div className="title">Available Series</div>

                  {getSeriesLoading && (
                    <Loader text="Please Wait while we are fetching all of your series" />
                  )}

                  {!getSeriesLoading && getSeriesError && (
                    <Error text="No series found! Please create ONE first" />
                  )}

                  {allSeries && allSeries.length > 0 && (
                    <>
                      {allSeries.map((item, i) => (
                        <div
                          onClick={() => setSelectedSeries(item)}
                          className={`seriesItem ${
                            selectedSeries?.id === item.id
                              ? "seriesItemActive"
                              : null
                          }`}
                        >
                          <img
                            src={config.aws.CLOUDFRONT_URL + item.thumbnail}
                            alt={item.title}
                            className="seriesItem__thumbnail"
                          />

                          <div className="seriesItem__info">
                            <div className="seriesItem__info--title">
                              {item.title}
                            </div>

                            <div className="seriesItem__info--list">
                              <div className="item">
                                <img
                                  src={PriceIcon}
                                  alt=""
                                  className="item__icon"
                                />
                                <span className="item__text">
                                  {item.price > 0 ? (
                                    <>${parseInt(item.price).toFixed(2)}</>
                                  ) : (
                                    "FREE"
                                  )}
                                </span>
                              </div>

                              <div className="dot"></div>

                              <div className="item">
                                <img
                                  src={VideoIcon}
                                  alt=""
                                  className="item__icon"
                                />
                                <span className="item__text">
                                  {item.videos.length} Videos
                                </span>
                              </div>

                              <div className="dot"></div>

                              <div className="item">
                                <img
                                  src={TimeIcon}
                                  alt=""
                                  className="item__icon"
                                />
                                <span className="item__text">
                                  <Moment fromNow>{item.createdAt}</Moment>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </>
            )}
            {keyVideoAccess && keyVideoAccess === 2 ? (
              <div className="form--fieldGroup">
                <label>
                  Pay Per View Amount
                  <span className="asterisk"> *</span>
                </label>
                <input
                  type="number"
                  placeholder="Enter Amount (Minimum $1.50)"
                  value={ppvAmount.value}
                  onChange={ppvAmount.onChange}
                />
              </div>
            ) : null}

            <div className="form--fieldGroup">
              <label>Video Hashtags</label>
              <ReactTags
                ref={reactTags}
                tags={hashTags}
                suggestions={videoHashtags}
                onDelete={onDelete.bind(this)}
                onAddition={onAddition.bind(this)}
                onValidate={onValidate.bind(this)}
                placeholderText="Enter new hashtag"
                allowNew={true}
              />
            </div>

            <div className="form--fieldGroup">
              <div className="title">Cover</div>

              <label htmlFor="uploadThumbnail" className="uploadThumbnail">
                <img src={AddIcon} alt="Add Icon" className="icon" />

                <p>
                  Upload any custom image for your Video! We'll auto-generate a
                  thumbnail from your video if you don't have one.
                </p>
              </label>

              <input
                style={{ display: "none" }}
                id="uploadThumbnail"
                type="file"
                accept="image/*"
                onChange={handleCustomThumbnailUpload}
              />

              {!!customThumbnail.url && (
                <div className="uploadedCoverPreview">
                  <img src={customThumbnail.url} alt="" />
                </div>
              )}
            </div>

            {selectedVideo && (
              <div className="form--fieldGroup">
                <div className="title">Update Video</div>
                <label htmlFor="uploadVideo" className="uploadThumbnail">
                  <img src={AddIcon} alt="Add Icon" className="icon" />

                  <p>Replace the current video with new one</p>
                </label>

                <input
                  style={{ display: "none" }}
                  id="uploadVideo"
                  type="file"
                  accept="video/*"
                  onChange={handleEditVideo}
                />

                {!!selectedVideo && (
                  <div className="uploadedCoverPreview">
                    <img src={thumbnail} alt="" />
                  </div>
                )}
              </div>
            )}

            <div className="form--fieldGroup">
              <label htmlFor="description">Description</label>

              <textarea
                id="description"
                placeholder="Tell viewers about your video"
                value={description.value}
                onChange={description.onChange}
              />
            </div>
            <div>
              <div
                onClick={() => {
                  // toggleAdditionalContentForm()

                  setShowEditAdditionalContentModel(true);
                  window.scrollTo(0, 0);
                }}
                className="aditionalContent"
              >
                <div className="contentButton">
                  <div>
                    <img
                      src={AddIcon}
                      alt="Add Icon"
                      className="icon"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                  <div style={{ marginTop: "-4px" }}>
                    Edit Additional Content
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </EditClipFormStyled>
      {showEditAdditionalContentModel && (
        <EditAdditionalContent
          actionType="Edit"
          setRewardAmount={setRewardAmount}
          rewardAmount={rewardAmount}
          isCheckedHasReward={selectedVideo.HasRewards}
          setIsCheckedHasReward={setIsCheckedHasReward}
          handleTab={completeVideoUpdate}
          setRewardDescription={setRewardDescription}
          rewardDescription={rewardDescription}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          deleteFiles={deleteFiles}
          setDeleteFiles={setDeleteFiles}
          setFiles={setFiles}
          files={files}
        ></EditAdditionalContent>
      )}
    </>
  );
};

export default EditClipForm;

const EditClipFormStyled = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #1c1c1e;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
  opacity: 0;
  transform: translateX(-100%);
  transition: all 1s ease;
  font-family: ${(props) => props.theme.montserrat};

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 5rem;
    border-bottom: 1px solid rgba(112, 112, 112, 0.4);
    margin-bottom: 2rem;
    background-color: #1c1c1e;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;

    .close {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 2rem;
        height: auto;
        cursor: pointer;
      }
    }

    .title {
      font-size: 1.2rem;
      font-weight: 400;
      text-transform: capitalize;
    }

    .group {
      display: flex;
      align-items: center;

      .button:not(:last-child) {
        margin-right: 0.5rem;
      }
    }

    .button {
      border: 2px solid #fff;
      padding: 0.2rem 1.5rem;
      border-radius: 10rem;
      font-size: 1rem;
      background-color: transparent;
      color: #fff;
      font-family: ${(props) => props.theme.montserrat};
      font-weight: 400;
      text-transform: capitalize;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }

  .videoPreviewBox {
    width: 50%;
    margin: 0 auto 5rem auto;
  }

  .form {
    width: 50%;
    margin: 0 auto 5rem auto;

    &--fieldGroup {
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .requestNewCategory {
        cursor: pointer;
        line-height: 1;
        font-family: brother-1816, sans-serif;
        padding: 0.4rem 1rem;
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 1px;
        font-weight: 500;
        color: #fff;
        border: none;
        outline: none;
        margin-top: 1rem;
        background: ${props => props.theme.primaryGradient};
        border-radius: 3px;
      }

      .uploadedCoverPreview {
        width: 100%;
        margin-top: 2rem;

        img {
          width: 100%;
          height: auto;
          border-radius: 1rem;
          background-size: cover;
          background-position: center;
          object-fit: cover;
        }
      }

      label {
        padding: 0 0 0.7rem 1rem;
        font-size: 1rem;
        font-weight: 400;
        text-transform: capitalize;
        color: #f2f2f7;
        letter-spacing: 1px;
      }

      input,
      textarea,
      select {
        width: 100%;
        padding: 1rem;
        background-color: #3a3a3c;
        color: #f2f2f7;
        border-radius: 0.5rem;
        border: none;
        outline: none;
        font-size: 1rem;
        font-weight: 400;
        color: #f2f2f7;
        font-family: ${(props) => props.theme.montserrat};
        transition: all 0.25s ease;
        border: 3px solid transparent;

        &::placeholder {
          font-weight: 300;
          color: #f2f2f7;
          letter-spacing: 0.3px;
        }

        &:focus {
          border: 3px solid #f88946;
        }
      }

      textarea {
        resize: none;
        height: 15rem;
      }

      .uploadThumbnail {
        width: 100%;
        min-height: 7rem;
        background-color: #3a3a3c;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        padding: 0 2rem;
        height: auto;

        img {
          height: 2.5rem;
          width: auto;
          margin-top: 2rem;
          margin-bottom: 1rem;
        }

        p {
          font-size: 0.85rem;
          font-weight: 300;
          color: #f2f2f7;
          line-height: 1.4;
          margin-bottom: 2rem;
          text-align: center;
        }
      }

      .title {
        padding: 0 0 0.7rem 1rem;
        font-size: 1rem;
        font-weight: 400;
        text-transform: capitalize;
        color: #f2f2f7;
        letter-spacing: 1px;
      }
    }

    .seriesItemActive {
      background: ${(props) => props.theme.gradient} !important;
      border: 1px solid transparent !important;
    }

    .seriesItem {
      width: 100%;
      height: 5rem;
      background: #202020;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 7px;
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      font-family: ${(props) => props.theme.montserrat}, sans-serif;
      cursor: pointer;

      &__thumbnail {
        width: 8rem;
        height: 100%;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        margin-right: 1rem;
      }

      &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;
        width: 100%;

        &--title {
          font-size: 0.85rem;
          line-height: 1.3;
          margin-top: 0.5rem;
        }

        &--list {
          display: flex;
          align-items: center;
          padding-bottom: 0.25rem;

          .item {
            display: flex;
            align-items: center;

            &__icon {
              height: 0.9rem;
              width: auto;
              margin-right: 0.25rem;
            }

            &__text {
              font-family: brother-1816, sans-serif;
              text-transform: uppercase;
              font-size: 0.75rem;
            }
          }

          .dot {
            height: 6px;
            width: 6px;
            background-color: #fff;
            border-radius: 50%;
            margin: 0 0.7rem;
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
  .aditionalContent {
    text-align: center;
    background-color: #3a3a3c;
    font-family: ${(props) => props.theme.montserrat};
    font-weight: 400;
    text-transform: capitalize;
    transition: all 0.2s ease;
    color: #f2f2f7;
    padding: 10px;
    margin-bottom: 3.5%;
    border-radius: 1rem;
  }
  .contentButton {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .header {
      padding: 1rem 2rem;
      margin-bottom: 1.5rem;

      .title {
        font-size: 1rem;
      }

      .button {
        font-size: 0.9rem;
      }
    }

    .form {
      width: 90%;

      &__description {
        .title {
          font-size: 1rem;
        }
      }

      input,
      textarea {
        font-size: 0.9rem;
      }

      textarea {
        height: 12rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .header {
      margin-bottom: 1rem;

      .button {
        font-size: 0.8rem;
      }
    }

    .videoPreviewBox {
      width: 90%;
    }

    .form {
      width: 90%;

      textarea {
        height: 10rem;
      }
    }
  }
`;

const ShowUploadIcon = styled.div`
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  .item {
    label {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .icon-box {
    cursor: pointer;
    height: 5rem;
    width: 5rem;
    background-color: #fff;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    i {
      font-size: 2.5rem;
      color: #777;
    }
  }
  span {
    font-size: 1.2rem;
    color: #fff;
    font-weight: 555;
  }
`;
